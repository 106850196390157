import React from 'react';
import { Spin, SpinProps } from 'antd';

import './index.less';

type ISbSpinProps = SpinProps;

const SbSpin: React.FC<ISbSpinProps> = ({ className, ...otherProps }) => {
  const classes = ['sb-spin'];
  if (className) classes.push(className);

  return <Spin className={classes.join(' ')} {...otherProps} />;
};

export default SbSpin;
