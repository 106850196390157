import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Log, User, UserManager } from 'oidc-client';
import { StatusCodes } from 'http-status-codes';

import { USER_ROLES } from '../constants';

import { CLIENT_CONFIG, getCurrentPath, getCurrentUrl } from './configUtil';
import { browserHistory } from './historyUtil';

const REQUIRED_ROLES = [
  USER_ROLES.owner,
  USER_ROLES.admin,
  USER_ROLES.botsAdmin,
  USER_ROLES.usersAdmin,
  USER_ROLES.inboxOperator,
  USER_ROLES.inboxSupervisor,
];
const EMPLOYEE_PREFIX = getCurrentPath('/employee/');
const CALLBACK_PREFIX = getCurrentPath('/auth/callback');
const LOGGED_OUT_PREFIX = getCurrentPath('/auth/logged-out');

export const COMPLEX_BOT_DEFAULT_PATH = '/agents';
export const SIMPLE_BOT_DEFAULT_PATH = '/simple-bots';
export const INBOX_DEFAULT_PATH = '/inbox';

declare global {
  interface Window {
    EB?: {
      getProfile: () => IUserProfile;
    };
  }
}

let currentManager: UserManager | null = null;
let currentState: string | null = null;
let currentUser: User | null = null;
let currentReloading = false;

Log.logger = console;

export interface IUserProfile {
  userId: string;
  userName: string;
  email: string;
  tenantId: string;
  originalHost?: string;
  accessToken: string;
  roles: string[];
}

interface IAuthArgs {
  state?: string;
}

const isReloading = (): boolean => {
  return currentReloading;
};

const getManager = async (): Promise<UserManager> => {
  let manager = currentManager;

  if (manager) {
    return manager;
  }

  const oidc = CLIENT_CONFIG.authentication.openIdConnect;
  const settings = {
    ...oidc,
    redirect_uri: oidc.redirect_uri || getCurrentUrl(CALLBACK_PREFIX),
    post_logout_redirect_uri: oidc.post_logout_redirect_uri || getCurrentUrl(LOGGED_OUT_PREFIX),
  };

  manager = new UserManager(settings);
  // eslint-disable-next-line require-atomic-updates
  currentManager = manager;
  return manager;
};

const getUser = (): User => {
  const user = currentUser;

  if (user) {
    return user;
  }

  throw new Error('User not logged in.');
};

const isAuthenticated = () => {
  const user = currentUser;

  if (!user) {
    return false;
  }

  return !user.expired;
};

const getProfile = (): IUserProfile => {
  const user = getUser();
  const tenantIdClaim = user.profile['elma.assistant.claims.tenant_id'];
  const roleClaim = user.profile.role;

  return {
    userId: user.profile.sub,
    userName: user.profile.name || user.profile.sub,
    email: user.profile.email || '',
    tenantId: Array.isArray(tenantIdClaim) ? tenantIdClaim[0] : tenantIdClaim,
    originalHost: user.profile['elma.assistant.claims.original_host'],
    accessToken: user.access_token,
    roles: Array.isArray(roleClaim) ? roleClaim : [roleClaim],
  };
};
window.EB = {
  getProfile,
};

const createArgs = (): IAuthArgs => {
  const { pathname, search, hash } = window.location;
  return { state: `${pathname}${search}${hash}` };
};

const loginUser = async (args?: IAuthArgs) => {
  currentReloading = true;
  args = args ?? createArgs();
  const manager = await getManager();
  await manager.signinRedirect(args);
};

const logoutUser = async (args?: IAuthArgs): Promise<void> => {
  currentReloading = true;
  args = args ?? createArgs();
  const manager = await getManager();
  await manager.signoutRedirect(args);
};

const clearSession = async () => {
  currentUser = null;
  const manager = await getManager();
  await manager.removeUser();
  await manager.clearStaleState();
};

const reloginUser = async (args?: IAuthArgs) => {
  await clearSession();
  await loginUser(args);
};

const isEmployeePage = () => {
  return window.location.pathname.startsWith(EMPLOYEE_PREFIX);
};

const isLoginCallback = () => {
  return window.location.pathname.startsWith(CALLBACK_PREFIX);
};

const isLogoutCallback = () => {
  return window.location.pathname.startsWith(LOGGED_OUT_PREFIX);
};

const withOfflineAccess = () => {
  return window.location.search.indexOf('offline_access') >= 0;
};

const isInvalidCallback = () => {
  return isLoginCallback() && !withOfflineAccess();
};

const isAuthCallback = () => {
  return isLoginCallback() || isLogoutCallback();
};

const processLoginCallback = async () => {
  const manager = await getManager();
  const user = await manager.signinRedirectCallback();
  currentState = user.state;
  currentUser = user;
};

const processLogoutCallback = async () => {
  const manager = await getManager();
  const response = await manager.signoutRedirectCallback();
  currentState = response.state;
  currentUser = null;
};

const processNormalRequest = async () => {
  const manager = await getManager();
  currentState = null;
  currentUser = await manager.getUser();
};

const hasRequiredRole = () => {
  const roleClaim = currentUser?.profile.role;
  const roles = Array.isArray(roleClaim) ? roleClaim : [roleClaim];
  return roles.some((role) => REQUIRED_ROLES.includes(role));
};

const updateBrowserHistory = () => {
  const state = currentState || SIMPLE_BOT_DEFAULT_PATH;
  browserHistory.replace(state);
};

const setupManager = async () => {
  const oidc = CLIENT_CONFIG.authentication.openIdConnect;
  const manager = await getManager();

  manager.events.addUserLoaded((user: User) => {
    currentUser = user;
  });

  manager.events.addUserUnloaded(() => {
    currentUser = null;
  });

  if (oidc.reloginOnSessionExpiration) {
    manager.events.addUserSignedOut(async () => {
      await reloginUser();
    });
  }

  if (oidc.reloginOnSilentRenewError) {
    manager.events.addSilentRenewError(async () => {
      await reloginUser();
    });
  }
};

const processAuth = async (): Promise<boolean> => {
  if (isEmployeePage()) {
    return true;
  }

  if (isInvalidCallback()) {
    return false;
  }

  if (isLoginCallback()) {
    await processLoginCallback();
  } else if (isLogoutCallback()) {
    await processLogoutCallback();
  } else {
    await processNormalRequest();
  }

  if (isAuthCallback()) {
    updateBrowserHistory();
  }

  if (!isAuthenticated()) {
    await loginUser();
    return false;
  }

  if (!hasRequiredRole()) {
    await logoutUser({});
    return false;
  }

  await setupManager();
  return true;
};

const setupAxios = (instance: AxiosInstance) => {
  const cancelSource = axios.CancelToken.source();

  instance.interceptors.request.use((options: AxiosRequestConfig) => {
    const token = currentUser?.access_token;
    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }
    return options;
  });

  instance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (err.response && err.response.status === StatusCodes.UNAUTHORIZED) {
        cancelSource.cancel('Unauthorized');
        await reloginUser();
      }
      throw err;
    }
  );
};

const overrideAxios = () => {
  const create = axios.create;

  axios.create = function (config?: AxiosRequestConfig): AxiosInstance {
    const result = create.call(this, config);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (axios.interceptors.request as any).handlers.forEach((handler: any) => {
      result.interceptors.request.use(handler.fulfilled, handler.rejected);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (axios.interceptors.response as any).handlers.forEach((handler: any) => {
      result.interceptors.response.use(handler.fulfilled, handler.rejected);
    });

    return result;
  };
};

setupAxios(axios);
overrideAxios();

export { isReloading, getProfile, logoutUser, processAuth };
