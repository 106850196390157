import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { InstanceModel, PublishStatusState } from '../../../../../kb-api';
import { instanceKbApi, reportKbApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import SbButton from '../../../components/common/SbButton';
import SbModal from '../../../components/common/SbModal';
import SbPanel from '../../../components/common/SbPanel';
import SbTypography from '../../../components/common/SbTypography';
import SbIcon from '../../../components/common/SbIcon';
import { renderReportRowCount } from '../../../../utils/stringUtil';

import InstanceStatusProgress from './InstanceStatusProgress';

enum ModalSteps {
  START = 'START',
  PROGRESS = 'PROGRESS',
  DIAGNOSTIC_SUCCESS = 'DIAGNOSTIC_SUCCESS',
  DIAGNOSTIC_WARNING = 'DIAGNOSTIC_WARNING',
  DIAGNOSTIC_ERROR = 'DIAGNOSTIC_ERROR',
}

interface IDiagnosticModalProps {
  instanceId: string;
  visible: boolean;
  onDataChanged: () => void;
  onCancel: () => void;
}

const DiagnosticModal: React.FC<IDiagnosticModalProps> = ({ instanceId, visible, onDataChanged, onCancel }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [publishRunning, setPublishRunning] = useState(false);
  const [modalStep, setModalStep] = useState(ModalSteps.START);
  const [reportRowCount, setReportRowCount] = useState(0);

  const buttonsDisabled = publishRunning || modalStep === ModalSteps.PROGRESS;
  const showDiagnosticResults =
    modalStep === ModalSteps.DIAGNOSTIC_SUCCESS ||
    modalStep === ModalSteps.DIAGNOSTIC_WARNING ||
    modalStep === ModalSteps.DIAGNOSTIC_ERROR;

  const onVisiblePropChange = () => {
    if (visible) {
      setPublishRunning(false);
      setModalStep(ModalSteps.START);
      setReportRowCount(0);
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onModalCancel = () => {
    if (buttonsDisabled) return;

    if (modalStep === ModalSteps.START) {
      onCancel();
    } else {
      onDataChanged();
    }
  };

  const onRunPublish = async () => {
    if (buttonsDisabled) return;

    setPublishRunning(true);
    try {
      await instanceKbApi.publishInstance(instanceId);
      setModalStep(ModalSteps.PROGRESS);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске диагностики',
        error: e,
      });
    }
    setPublishRunning(false);
  };

  const onPublishComplete = async (instance: InstanceModel) => {
    if (instance.status.state === PublishStatusState.Error) {
      setModalStep(ModalSteps.DIAGNOSTIC_ERROR);
      return;
    }

    try {
      const response = await reportKbApi.getActualReport(instance.id);
      if (response.data.reportRowCount) {
        setModalStep(ModalSteps.DIAGNOSTIC_WARNING);
        setReportRowCount(response.data.reportRowCount);
      } else {
        setModalStep(ModalSteps.DIAGNOSTIC_SUCCESS);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке результатов диагностики',
        error: e,
      });
    }
  };

  const footer = [];
  if (modalStep === ModalSteps.START) {
    footer.push(
      <SbButton key="run" loading={buttonsDisabled} sbSize="medium" onClick={onRunPublish}>
        Запустить
      </SbButton>
    );
  }
  if (!buttonsDisabled) {
    footer.push(
      <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onModalCancel}>
        Закрыть
      </SbButton>
    );
  }

  return (
    <SbModal
      destroyOnClose
      className="sb-knowledge-base-card__diagnostic-modal"
      footer={footer}
      sbSize="small"
      title="Диагностика базы знаний"
      visible={visible}
      width={569}
      onCancel={onModalCancel}
      onOk={() => {}}
    >
      {modalStep === ModalSteps.START && (
        <SbTypography>
          Диагностика поможет найти возможные проблемы с распознаванием вопросов. Хотите запустить диагностику?
        </SbTypography>
      )}
      {modalStep === ModalSteps.PROGRESS && (
        <InstanceStatusProgress instanceId={instanceId} onPublishComplete={onPublishComplete} />
      )}
      {showDiagnosticResults && (
        <div className="sb-knowledge-base-card__diagnostic-modal__results">
          <SbPanel sbType="help">
            <SbTypography>
              {modalStep === ModalSteps.DIAGNOSTIC_ERROR && (
                <h4>
                  <SbIcon iconName="attention" />
                  Возникла ошибка при публикации базы знаний
                </h4>
              )}
              {modalStep === ModalSteps.DIAGNOSTIC_WARNING && (
                <p className="sb-knowledge-base-card__diagnostic-modal__results__warning">
                  В ходе диагностики было найдено {renderReportRowCount(reportRowCount)}, которые
                  <br /> необходимо проверить. Все совпадения отмечены иконкой <SbIcon iconName="attention" size={20} />
                  <br /> на странице с записями.
                </p>
              )}
              {modalStep === ModalSteps.DIAGNOSTIC_SUCCESS && <h4>В ходе диагностики проблем не найдено.</h4>}
            </SbTypography>
          </SbPanel>
        </div>
      )}
    </SbModal>
  );
};

export default DiagnosticModal;
