import React, { ReactNode, useEffect, useState } from 'react';
import { Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import i18next from 'i18next';
import { useDebounce } from 'usehooks-ts';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';
import IbTypography from '../IbTypography';

const SEARCH_DELAY = 200; // ms
const OPTIONS_DEFAULT = [] as { value: string; label: ReactNode }[];
const STATUS_DEFAULT = 'default';
const SHOW_SEARCH_DEFAULT = false;

const MAIN_CLASS_NAME = 'ib-select';
const DROPDOWN_CLASS_NAME = `${MAIN_CLASS_NAME}__dropdown`;
const REMOVE_ICON_CLASS_NAME = `${MAIN_CLASS_NAME}__remove-icon`;

export interface IIbSelectProps {
  className?: string;
  dropdownClassName?: string;
  mode?: 'multiple' | 'tags';
  value?: SelectValue;
  options?: { value: string; label: ReactNode }[];
  status?: 'default' | 'success' | 'error';
  disabled?: boolean;
  placeholder?: string;
  showSearch?: boolean;
  allowClear?: boolean;
  onChange?: (value: SelectValue) => void;
  onSearch?: (searchValue: string) => void;
}

const IbSelect: React.FC<IIbSelectProps> = ({
  className,
  dropdownClassName,
  mode,
  value,
  options = OPTIONS_DEFAULT,
  status = STATUS_DEFAULT,
  disabled,
  placeholder,
  showSearch = SHOW_SEARCH_DEFAULT,
  allowClear,
  onChange,
  onSearch,
}) => {
  options = getDefaultIfUndefined(options, OPTIONS_DEFAULT);
  status = getDefaultIfUndefined(status, STATUS_DEFAULT);
  showSearch = getDefaultIfUndefined(showSearch, SHOW_SEARCH_DEFAULT);

  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, SEARCH_DELAY);
  const deferredSearchValue = searchValue ? debouncedSearchValue : '';

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${status}`];
  className && classes.push(className);

  const dropdownClasses = [DROPDOWN_CLASS_NAME];
  dropdownClassName && dropdownClasses.push(dropdownClassName);

  const onDeferredSearchValueChange = () => {
    onSearch?.(deferredSearchValue);
  };
  useEffect(onDeferredSearchValueChange, [deferredSearchValue]);

  return (
    <Select
      allowClear={allowClear}
      className={classes.join(' ')}
      clearIcon={<IbIcon iconName="close" size={20} />}
      disabled={disabled}
      dropdownClassName={dropdownClasses.join(' ')}
      filterOption={false}
      mode={mode}
      notFoundContent={
        <IbTypography.Paragraph disabled type="secondary">
          {i18next.t('No match found')}
        </IbTypography.Paragraph>
      }
      options={options}
      placeholder={placeholder}
      removeIcon={<IbIcon className={REMOVE_ICON_CLASS_NAME} iconName="close-small" size={16} />}
      searchValue={searchValue}
      showSearch={showSearch}
      suffixIcon={<IbIcon iconName={showSearch ? 'search' : 'down'} size={20} />}
      value={value}
      virtual={false}
      onChange={onChange}
      onSearch={showSearch ? setSearchValue : undefined}
    />
  );
};

export default IbSelect;
