import React, { memo, useEffect, useState, KeyboardEvent } from 'react';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import { DefaultActionGroupSchema, CloseConversationSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';
import { tryGetElementById } from '../../../utils';
import SbInput from '../../../../../simple-bot/components/common/SbInput';

interface ICloseConversationProps {
  index: number;
  action: CloseConversationSchema;
  group: DefaultActionGroupSchema;
}

const CloseConversation: React.FC<ICloseConversationProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [closingMessage, setClosingMessage] = useState(action.closingMessage);

  const resetState = () => {
    setClosingMessage(action.closingMessage);
  };

  useEffect(resetState, [action]);

  const onClosingMessageBlur = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id) as CloseConversationSchema;
    if (foundAction.closingMessage === closingMessage) return;
    foundAction.closingMessage = closingMessage;
    setScenarioStructure(newScenarioStructure);
  };

  const onClosingMessageInputPressEnter = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget?.blur();

  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="handle-x" size={20} />}
      index={index}
      title="Завершить беседу"
    >
      <SbInput
        placeholder="Сообщение при завершении беседы"
        value={closingMessage ?? ''}
        onBlur={onClosingMessageBlur}
        onChange={setClosingMessage}
        onPressEnter={onClosingMessageInputPressEnter}
      />
    </ActionWrapper>
  );
};

export default memo(CloseConversation);
