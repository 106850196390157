import React from 'react';

import SbButton from '../../../components/common/SbButton';

interface IDiagnosticButtonProps {
  dataUpdating: boolean;
  onRunDiagnostic: () => void;
}

const DiagnosticButton: React.FC<IDiagnosticButtonProps> = ({ dataUpdating, onRunDiagnostic }) => {
  const onButtonClick = async () => {
    if (dataUpdating) return;

    onRunDiagnostic();
  };

  return (
    <SbButton sbType="secondary" onClick={onButtonClick}>
      Диагностика
    </SbButton>
  );
};

export default DiagnosticButton;
