import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import SbButton from '../../../../components/common/SbButton';
import SbModal from '../../../../components/common/SbModal';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { AgentStageAccountModel } from '../../../../../../api';
import { agentStageAccountApi } from '../../../../../apis';

interface IChannelDeleteConfirmModalProps {
  channel?: AgentStageAccountModel;
  visible: boolean;
  onDataChanged: () => void;
  onCancel: () => void;
}

const ChannelDeleteConfirmModal: React.FC<IChannelDeleteConfirmModalProps> = ({
  channel,
  visible,
  onDataChanged,
  onCancel,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [deleting, setDeleting] = useState(false);

  const deleteChannel = async () => {
    if (!channel) return;
    try {
      await agentStageAccountApi.deleteAgentStageAccount(channel.id);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении канала',
        error: e,
      });
    }
  };

  const onVisiblePropChange = () => {
    if (visible) {
      setDeleting(false);
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onConfirm = async () => {
    if (deleting) return;

    setDeleting(true);
    await deleteChannel();
    setDeleting(false);
    onDataChanged();
  };

  const onModalCancel = () => !deleting && onCancel();

  return (
    <SbModal
      destroyOnClose
      footer={[
        <SbButton key="confirm" loading={deleting} sbSize="medium" onClick={onConfirm}>
          Удалить
        </SbButton>,
        <SbButton key="cancel" disabled={deleting} sbSize="medium" sbType="secondary" onClick={onModalCancel}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Подтвердите удаление канала"
      visible={visible}
      width={450}
      onCancel={onModalCancel}
      onOk={onConfirm}
    >
      Вы действительно хотите удалить канал?
    </SbModal>
  );
};

export default ChannelDeleteConfirmModal;
