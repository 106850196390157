import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';
import { ActivityModel } from '../../../../../../api';

interface IVariableChangedProps {
  activity: ActivityModel;
  onSelect: (activity: ActivityModel) => void;
}

const VariableChanged: React.FC<IVariableChangedProps> = ({ activity }) => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="code" size={16} />
      Изменено значение переменной «{activity.text}»
    </div>
  );
};

export default VariableChanged;
