import React from 'react';
import { Modal } from 'antd';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';
import IbTypography from '../IbTypography';
import IbSpin from '../IbSpin';

const VISIBLE_DEFAULT = false;
const LOADING_DEFAULT = false;
const TITLE_DEFAULT = undefined;
const FOOTER_DEFAULT = null;
const ON_CANCEL_DEFAULT = () => {};

const MAIN_CLASS_NAME = 'ib-confirm-modal';

export interface IIbConfirmModalProps {
  className?: string;
  visible?: boolean;
  loading?: boolean;
  title?: string;
  footer?: React.ReactNode;
  onCancel?: () => void;
}

const IbConfirmModal: React.FC<IIbConfirmModalProps> = ({
  children,
  className,
  visible = VISIBLE_DEFAULT,
  loading = LOADING_DEFAULT,
  title = TITLE_DEFAULT,
  footer = FOOTER_DEFAULT,
  onCancel = ON_CANCEL_DEFAULT,
}) => {
  visible = getDefaultIfUndefined(visible, VISIBLE_DEFAULT);
  loading = getDefaultIfUndefined(loading, LOADING_DEFAULT);
  title = getDefaultIfUndefined(title, TITLE_DEFAULT);
  onCancel = getDefaultIfUndefined(onCancel, ON_CANCEL_DEFAULT);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return (
    <Modal
      destroyOnClose
      closeIcon={
        <>
          <IbIcon iconName="close-small" />
          <IbIcon iconName="close" size={20} />
        </>
      }
      footer={footer}
      maskClosable={false}
      title={
        <>
          {loading && <IbSpin />}
          <IbTypography>
            <h4>{title}</h4>
          </IbTypography>
        </>
      }
      visible={visible}
      wrapClassName={classes.join(' ')}
      onCancel={onCancel}
    >
      {children}
    </Modal>
  );
};

export default IbConfirmModal;
