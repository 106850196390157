import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';

import './index.less';

import IbIcon from '../common/IbIcon';
import IbButton from '../common/IbButton';
import IbContextMenu from '../common/IbContextMenu';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbTypography from '../common/IbTypography';
import IbAvatar from '../common/IbAvatar';
import IbPopover from '../common/IbPopover';
import IbInput from '../common/IbInput';
import IbModal from '../common/IbModal';
import { InboxChatParticipantSubjectModel, InboxOperatorGroupModel, InboxParticipantModel } from '../../../../api';
import { IbSocialType } from '../common/IbSocial';
import IbSpin from '../common/IbSpin';
import { BotAvatarLogo } from '../../assets';
import { IbUserStatusType } from '../common/IbUserStatus';
import IbTabs from '../common/IbTabs';
import IbTabPane from '../common/IbTabPane';
import { includesIgnoreCase } from '../../../utils/stringUtil';

const SEARCH_DELAY = 200; // ms
const ANIMATION_DELAY = 200; // ms

const INTERACTION_DISABLED_DEFAULT = false;
const ACTIONS_DEFAULT = [] as IbChatInfoAction[];
const CONTACT_DEFAULT = undefined;
const OPERATOR_DEFAULT = undefined;
const OPERATOR_LIST_DEFAULT = [] as InboxParticipantModel[];
const GROUP_LIST_DEFAULT = [] as InboxOperatorGroupModel[];

const MAIN_CLASS_NAME = 'ib-chat-info';
const BACK_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__back-button`;
const CONTACT_INFO_CLASS_NAME = `${MAIN_CLASS_NAME}__contact-info`;
const CONTACT_INFO_DATA_CLASS_NAME = `${CONTACT_INFO_CLASS_NAME}__data`;
const MENU_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__menu-button`;
const ACTIONS_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__actions-button`;
const OPERATOR_CLASS_NAME = `${MAIN_CLASS_NAME}__operator`;
const EXPANDER_CLASS_NAME = `${MAIN_CLASS_NAME}__expander`;
const OPERATOR_DISABLED_CLASS_NAME = `${OPERATOR_CLASS_NAME}_disabled`;
const REASSIGN_LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__reassign-list`;
const REASSIGN_LIST_POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__reassign-list-popover`;
const REASSIGN_LIST_SPIN_WRAPPER_CLASS_NAME = `${REASSIGN_LIST_CLASS_NAME}__spin-wrapper`;
const REASSIGN_LIST_EMPTY_LIST_ENTRY_CLASS_NAME = `${REASSIGN_LIST_CLASS_NAME}__empty-list-entry`;

enum ReassignTabKeys {
  OPERATORS = 'operators',
  GROUPS = 'groups',
}

export interface IbChatInfoAction {
  icon: React.ReactNode;
  text: string;
  handler: () => void;
}

export interface IIbChatInfoProps {
  interactionDisabled?: boolean;
  actions?: IbChatInfoAction[];
  contact?: InboxChatParticipantSubjectModel;
  operator?: InboxChatParticipantSubjectModel;
  bot?: InboxChatParticipantSubjectModel;
  group?: InboxOperatorGroupModel;
  operatorList?: InboxParticipantModel[];
  operatorListLoading?: boolean;
  groupList: InboxOperatorGroupModel[];
  social?: IbSocialType;
  onBack: () => void;
  onOperatorsSearch: (search: string) => void;
  onOpenClientCard: () => void;
  onReassignOperator: (operatorId: string) => void;
  onReassignGroup: (groupId: string) => void;
  onFocusToMessageInput?: () => void;
}

const IbChatInfo: React.FC<IIbChatInfoProps> = ({
  interactionDisabled = INTERACTION_DISABLED_DEFAULT,
  actions = [],
  contact,
  operator,
  bot,
  group,
  operatorList = OPERATOR_LIST_DEFAULT,
  operatorListLoading,
  groupList,
  social,
  onBack,
  onOperatorsSearch,
  onOpenClientCard,
  onReassignOperator,
  onReassignGroup,
  onFocusToMessageInput,
}) => {
  interactionDisabled = getDefaultIfUndefined(interactionDisabled, INTERACTION_DISABLED_DEFAULT);
  actions = getDefaultIfUndefined(actions, ACTIONS_DEFAULT);
  contact = getDefaultIfUndefined(contact, CONTACT_DEFAULT);
  operator = getDefaultIfUndefined(operator, OPERATOR_DEFAULT);
  operatorList = getDefaultIfUndefined(operatorList, OPERATOR_LIST_DEFAULT);
  groupList = getDefaultIfUndefined(groupList, GROUP_LIST_DEFAULT);

  const { t } = useTranslation();

  const [reassignTabKey, setReassignTabKey] = useState(ReassignTabKeys.OPERATORS);
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [reassignPopoverVisible, setReassignPopoverVisible] = useState(false);
  const [reassignModalVisible, setReassignModalVisible] = useState(false);
  const [currentCheckedOperatorId, setCurrentCheckedOperatorId] = useState(operator?.id);
  const [currentCheckedGroupId, setCurrentCheckedGroupId] = useState(group?.id);
  const [operatorsSearchText, setOperatorsSearchText] = useState('');
  const [groupsSearchText, setGroupsSearchText] = useState('');

  const debouncedOperatorsSearchText = useDebounce(operatorsSearchText, SEARCH_DELAY);
  const deferredOperatorsSearchText = operatorsSearchText ? debouncedOperatorsSearchText : '';

  const operatorAssignAllowed = currentCheckedOperatorId && currentCheckedOperatorId != operator?.id;
  const groupAssignAllowed = currentCheckedGroupId && currentCheckedGroupId != group?.id;

  const filteredGroupList = groupList.filter((g) => includesIgnoreCase(g.name, groupsSearchText));

  const clearSearchContext = () => {
    // NOTE: сбрасываем только после того, как сработает анимация закрытия всплывыющего меню
    setTimeout(() => {
      setCurrentCheckedOperatorId(operator?.id);
      setOperatorsSearchText('');
      setCurrentCheckedGroupId(group?.id);
      setGroupsSearchText('');
    }, ANIMATION_DELAY);
  };

  const resetSearch = () => {
    setReassignPopoverVisible(false);
    setReassignModalVisible(false);
    clearSearchContext();
  };

  const onMenuButtonClick = () => setContextMenuVisible(true);

  const onContextMenuClose = () => {
    setContextMenuVisible(false);
    onFocusToMessageInput?.();
  };

  const onMenuItemSelect = (handler: () => void) => () => {
    setContextMenuVisible(false);
    handler();
  };

  const onReassignPopoverVisibleChange = (visible?: boolean) => {
    if (visible) return;

    setReassignPopoverVisible(visible || false);
    clearSearchContext();
    onFocusToMessageInput?.();
  };

  const onReassignModalCancel = () => {
    setReassignModalVisible(false);
    clearSearchContext();
    onFocusToMessageInput?.();
  };

  const onAssignBlockClick = () => !interactionDisabled && setReassignPopoverVisible(true);

  const onOperatorsSearchChange = (value: string) => setOperatorsSearchText(value);

  const onGroupsSearchChange = (value: string) => setGroupsSearchText(value);

  const onOperatorListItemClick = (operator: InboxParticipantModel) => () =>
    setCurrentCheckedOperatorId(operator.subject.id);

  const onGroupListItemClick = (group: InboxOperatorGroupModel) => () => setCurrentCheckedGroupId(group.id);

  const onOperatorReassignButtonClick = () => {
    resetSearch();

    if (currentCheckedOperatorId) {
      onReassignOperator(currentCheckedOperatorId);
    }
  };

  const onGroupReassignButtonClick = () => {
    resetSearch();

    if (currentCheckedGroupId) {
      onReassignGroup(currentCheckedGroupId);
    }
  };

  const onOpenReassignModal = () => setReassignModalVisible(true);

  const onReassignTabChange = (activeKey: string) => {
    const tabNewValue = activeKey as ReassignTabKeys;
    setReassignTabKey(tabNewValue);
  };

  const renderLoader = () => {
    return (
      <div className={REASSIGN_LIST_SPIN_WRAPPER_CLASS_NAME}>
        <IbSpin />
      </div>
    );
  };

  const renderOperatorEmptyList = () => (
    <div className={REASSIGN_LIST_EMPTY_LIST_ENTRY_CLASS_NAME}>
      <IbAvatar iconName="headset-one" size="x-small" />
      <IbTypography.Paragraph disabled type="secondary">
        {t('No operators found')}
      </IbTypography.Paragraph>
    </div>
  );

  const renderGroupEmptyList = () => (
    <div className={REASSIGN_LIST_EMPTY_LIST_ENTRY_CLASS_NAME}>
      <IbTypography.Paragraph disabled type="secondary">
        {t('No groups found')}
      </IbTypography.Paragraph>
    </div>
  );

  const renderOperatorList = () => (
    <ul>
      {operatorList.map((operator) => (
        <li key={operator.id}>
          <IbTypography onClick={onOperatorListItemClick(operator)}>
            <IbAvatar
              metadata={{ uid: operator.subject.id, text: operator.subject.person.name.fullName || '' }}
              size="x-small"
              userStatus={operator.status?.toLowerCase() as IbUserStatusType | undefined}
            />
            <IbTypography.Paragraph type="secondary">{operator.subject.person.name.fullName}</IbTypography.Paragraph>
            {currentCheckedOperatorId && currentCheckedOperatorId === operator.subject.id ? (
              <IbIcon iconName="check-one" size={20} />
            ) : null}
          </IbTypography>
        </li>
      ))}
    </ul>
  );

  const renderGroupList = () => (
    <ul>
      {filteredGroupList.map((group) => (
        <li key={group.id}>
          <IbTypography onClick={onGroupListItemClick(group)}>
            <IbTypography.Paragraph type="secondary">{group.name}</IbTypography.Paragraph>
            {currentCheckedGroupId && currentCheckedGroupId === group.id ? (
              <IbIcon iconName="check-one" size={20} />
            ) : null}
          </IbTypography>
        </li>
      ))}
    </ul>
  );

  const renderAssignListContent = () => (
    <IbTabs activeKey={reassignTabKey} onChange={onReassignTabChange}>
      <IbTabPane key={ReassignTabKeys.OPERATORS} tab={t('Operators')} tabKey={ReassignTabKeys.OPERATORS}>
        <div className={REASSIGN_LIST_CLASS_NAME}>
          <IbInput placeholder={t('Operator search')} value={operatorsSearchText} onChange={onOperatorsSearchChange} />
          {operatorListLoading
            ? renderLoader()
            : operatorList.length
            ? renderOperatorList()
            : renderOperatorEmptyList()}
          <IbButton
            disabled={!operatorAssignAllowed}
            icon={<IbIcon iconName="refresh" />}
            type="fill"
            onClick={onOperatorReassignButtonClick}
          >
            {t('Reassign')}
          </IbButton>
        </div>
      </IbTabPane>
      <IbTabPane key={ReassignTabKeys.GROUPS} tab={t('Groups')} tabKey={ReassignTabKeys.GROUPS}>
        <div className={REASSIGN_LIST_CLASS_NAME}>
          <IbInput placeholder={t('Group search')} value={groupsSearchText} onChange={onGroupsSearchChange} />
          {filteredGroupList.length ? renderGroupList() : renderGroupEmptyList()}
          <IbButton
            disabled={!groupAssignAllowed}
            icon={<IbIcon iconName="refresh" />}
            type="fill"
            onClick={onGroupReassignButtonClick}
          >
            {t('Reassign')}
          </IbButton>
        </div>
      </IbTabPane>
    </IbTabs>
  );

  const loadOperators = () => {
    onOperatorsSearch(deferredOperatorsSearchText);
  };
  useEffect(loadOperators, [deferredOperatorsSearchText]);

  const onOperatorPropChange = () => {
    setCurrentCheckedOperatorId(operator?.id);
  };
  useEffect(onOperatorPropChange, [operator]);

  const onGroupPropChange = () => {
    setCurrentCheckedGroupId(group?.id);
  };
  useEffect(onGroupPropChange, [group]);

  const onInteractionDisabledPropChange = () => {
    interactionDisabled && resetSearch();
  };
  useEffect(onInteractionDisabledPropChange, [interactionDisabled]);

  const renderMobileElements = () => (
    <>
      <IbButton
        className={BACK_BUTTON_CLASS_NAME}
        icon={<IbIcon iconName="arrow-left" />}
        type="icon"
        onClick={onBack}
      />
      <div className={CONTACT_INFO_CLASS_NAME} onClick={onOpenClientCard}>
        <IbAvatar
          imgSrc={contact?.person.avatar}
          metadata={{ uid: contact?.id || '', text: contact?.person.name.fullName || '' }}
          size="x-small"
          social={social}
        />
        <div className={CONTACT_INFO_DATA_CLASS_NAME}>
          <IbTypography.Paragraph strong>{contact?.person.name.fullName}</IbTypography.Paragraph>
          <IbTypography.Paragraph disabled type="descriptor">
            {t('Assigned')}: {bot ? t('Bot') : group ? group.name : operator?.person.name.fullName || '-'}
          </IbTypography.Paragraph>
        </div>
      </div>
      <IbContextMenu
        menuItems={[
          ...actions.map((action) => ({
            icon: action.icon,
            text: action.text,
            onSelect: onMenuItemSelect(action.handler),
          })),
          {
            icon: <IbIcon iconName="view-list" />,
            text: t('Open client card'),
            onSelect: onMenuItemSelect(onOpenClientCard),
          },
          {
            icon: <IbIcon iconName="refresh" />,
            text: t('Reassign'),
            onSelect: onMenuItemSelect(onOpenReassignModal),
          },
        ]}
        visible={contextMenuVisible}
        onClose={onContextMenuClose}
      >
        <IbButton
          className={MENU_BUTTON_CLASS_NAME}
          disabled={interactionDisabled}
          icon={<IbIcon iconName="more-one" />}
          type="icon"
          onClick={onMenuButtonClick}
        />
      </IbContextMenu>
      <IbModal title={t('Reassign')} visible={reassignModalVisible} onCancel={onReassignModalCancel}>
        {renderAssignListContent()}
      </IbModal>
    </>
  );

  const renderDesktopElements = () => (
    <>
      {!!actions?.length && (
        <IbButton
          actions={actions.slice(1).map((action) => ({
            icon: action.icon,
            text: action.text,
            onSelect: action.handler,
          }))}
          className={ACTIONS_BUTTON_CLASS_NAME}
          disabled={interactionDisabled}
          icon={actions[0].icon}
          onClick={actions[0].handler}
        >
          {actions[0].text}
        </IbButton>
      )}
      <div className={`${OPERATOR_CLASS_NAME} ${interactionDisabled ? OPERATOR_DISABLED_CLASS_NAME : null}`}>
        <IbPopover
          className={REASSIGN_LIST_POPOVER_CLASS_NAME}
          content={renderAssignListContent()}
          placement="bottomRight"
          trigger="click"
          visible={reassignPopoverVisible}
          onVisibleChange={onReassignPopoverVisibleChange}
        >
          <IbTypography onClick={onAssignBlockClick}>
            <IbTypography.Paragraph disabled type="secondary">
              {t('Assigned')}:
            </IbTypography.Paragraph>
            {bot ? (
              <>
                <IbAvatar size="small">
                  <BotAvatarLogo />
                </IbAvatar>
                <IbTypography.Paragraph type="secondary">{t('Bot')}</IbTypography.Paragraph>
              </>
            ) : operator ? (
              <>
                <IbAvatar
                  imgSrc={operator.person.avatar}
                  metadata={{ uid: operator.id, text: operator.person.name.fullName || '' }}
                  size="x-small"
                />
                <IbTypography.Paragraph type="secondary">{operator.person.name.fullName}</IbTypography.Paragraph>
              </>
            ) : group ? (
              <IbTypography.Paragraph type="secondary">{group.name}</IbTypography.Paragraph>
            ) : (
              <IbTypography.Paragraph type="secondary">{t('Operator not assigned')}</IbTypography.Paragraph>
            )}
            <IbIcon iconName="down" size={20} />
          </IbTypography>
        </IbPopover>
      </div>
      <div className={EXPANDER_CLASS_NAME}>
        <IbButton icon={<IbIcon iconName="right-bar" />} type="icon" onClick={onOpenClientCard} />
      </div>
    </>
  );

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderMobileElements()}
      {renderDesktopElements()}
    </div>
  );
};

export default IbChatInfo;
