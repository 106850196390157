import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Col, Row } from 'antd';
import './index.less';

import {
  BinaryConditionOperator,
  ConditionSchema,
  MainCaseActionSchema,
  SchemaKind,
} from '../../../../../../../../api';
import SbModal from '../../../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../../../simple-bot/components/common/SbButton';
import SbScroll from '../../../../../../../simple-bot/components/common/SbScroll';
import SbTypography from '../../../../../../../simple-bot/components/common/SbTypography';
import ConditionEditor from '../ConditionEditor';
import { generateId } from '../../../../../utils';

const MODAL_WIDTH = 1180;

interface SettingModalProps {
  visible: boolean;
  mainCase: MainCaseActionSchema;
  onChange: (mainCase: MainCaseActionSchema) => void;
  onClose: () => void;
}

const SettingModal: React.FC<SettingModalProps> = ({ visible, mainCase, onChange, onClose }) => {
  const [conditions, setConditions] = useState([] as ConditionSchema[]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setConditions(cloneDeep(mainCase.conditions || []));
  }, [visible, mainCase]);

  const onCreateButtonClick = () => {
    const newCondition = {
      id: generateId('CON'),
      $kind: SchemaKind.BinaryCondition,
      operator: BinaryConditionOperator.Equals,
      leftOperand: {
        id: generateId('OPD'),
        $kind: SchemaKind.VariableOperand,
        variableId: undefined,
      },
      rightOperand: {
        id: generateId('OPD'),
        $kind: SchemaKind.ConstantOperand,
        value: undefined,
        variableId: undefined,
      },
    };
    const newConditions = [...conditions, newCondition];
    setConditions(newConditions);
  };

  const onSaveButtonClick = () => {
    const newCase = {
      ...mainCase,
      conditions,
    };
    onChange(newCase);
    onClose();
  };

  const onCancelModalClick = () => onClose();

  const onCancelButtonClick = () => onClose();

  const onConditionChange = (condition: ConditionSchema) => {
    const newConditions = conditions.map((c) => (c.id === condition.id ? condition : c));
    setConditions(newConditions);
  };

  const onConditionDelete = (condition: ConditionSchema) => {
    const newConditions = conditions.filter((c) => c.id !== condition.id);
    setConditions(newConditions);
  };

  const renderEmptyConditions = () => {
    return (
      <SbTypography className="main-case-action-settings__conditions main-case-action-settings__conditions_empty">
        <h4>Условия отсутствуют</h4>
        <small>
          Для условия фраз нажмите кнопку <b>Добавить условие</b>
        </small>
      </SbTypography>
    );
  };

  const renderConditions = () => {
    return (
      <SbTypography className="main-case-action-settings__conditions main-case-action-settings__conditions_items">
        {conditions.map((condition) => (
          <ConditionEditor
            key={condition.id}
            condition={condition}
            onChange={onConditionChange}
            onDelete={onConditionDelete}
          />
        ))}
      </SbTypography>
    );
  };

  return (
    <SbModal
      footer={[
        <SbButton key="create" sbSize="medium" sbType="secondary" onClick={onCreateButtonClick}>
          Добавить условие
        </SbButton>,
        <SbButton key="save" sbSize="medium" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      maskClosable={false}
      sbSize="small"
      title="Настройка условий"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onCancelModalClick}
    >
      <Row className="sb-modal__row-stretch main-case-action-settings" gutter={[48, 20]} wrap={false}>
        <Col className="sb-modal__col-main" span={24}>
          <SbScroll>
            <Row className="sb-modal__row-stretch" gutter={[0, 0]} wrap={false}>
              <Col span={24}>{conditions.length ? renderConditions() : renderEmptyConditions()}</Col>
            </Row>
          </SbScroll>
        </Col>
      </Row>
    </SbModal>
  );
};

export default SettingModal;
