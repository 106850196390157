import { IEmoji } from '../../types';

export const Emojis: IEmoji[] = [
  { id: ':smile:', value: '😄' },
  { id: ':laughing:', value: '😆' },
  { id: ':blush:', value: '😊' },
  { id: ':smiley:', value: '😃' },
  { id: ':smirk:', value: '😏' },
  { id: ':heart_eyes:', value: '😍' },
  { id: ':kissing_heart:', value: '😘' },
  { id: ':kissing_closed_eyes:', value: '😚' },
  { id: ':flushed:', value: '😳' },
  { id: ':relieved:', value: '😌' },
  { id: ':satisfied:', value: '😆' },
  { id: ':grin:', value: '😁' },
  { id: ':wink:', value: '😉' },
  { id: ':stuck_out_tongue_winking_eye:', value: '😜' },
  { id: ':stuck_out_tongue_closed_eyes:', value: '😝' },
  { id: ':grinning:', value: '😀' },
  { id: ':kissing:', value: '😗' },
  { id: ':kissing_smiling_eyes:', value: '😙' },
  { id: ':stuck_out_tongue:', value: '😛' },
  { id: ':sleeping:', value: '😴' },
  { id: ':worried:', value: '😟' },
  { id: ':frowning:', value: '😦' },
  { id: ':anguished:', value: '😧' },
  { id: ':open_mouth:', value: '😮' },
  { id: ':grimacing:', value: '😬' },
  { id: ':confused:', value: '😕' },
  { id: ':hushed:', value: '😯' },
  { id: ':expressionless:', value: '😑' },
  { id: ':unamused:', value: '😒' },
  { id: ':sweat_smile:', value: '😅' },
  { id: ':sweat:', value: '😓' },
  { id: ':disappointed_relieved:', value: '😥' },
  { id: ':weary:', value: '😩' },
  { id: ':pensive:', value: '😔' },
  { id: ':disappointed:', value: '😞' },
  { id: ':fearful:', value: '😨' },
  { id: ':cold_sweat:', value: '😰' },
  { id: ':persevere:', value: '😣' },
  { id: ':cry:', value: '😢' },
  { id: ':sob:', value: '😭' },
  { id: ':joy:', value: '😂' },
  { id: ':astonished:', value: '😲' },
  { id: ':scream:', value: '😱' },
  { id: ':tired_face:', value: '😫' },
  { id: ':angry:', value: '😠' },
  { id: ':rage:', value: '😡' },
  { id: ':triumph:', value: '😤' },
  { id: ':sleepy:', value: '😪' },
  { id: ':yum:', value: '😋' },
  { id: ':mask:', value: '😷' },
  { id: ':sunglasses:', value: '😎' },
  { id: ':dizzy_face:', value: '😵' },
  { id: ':imp:', value: '👿' },
  { id: ':smiling_imp:', value: '😈' },
  { id: ':neutral_face:', value: '😐' },
  { id: ':no_mouth:', value: '😶' },
  { id: ':innocent:', value: '😇' },
  { id: ':alien:', value: '👽' },
  { id: ':yellow_heart:', value: '💛' },
  { id: ':blue_heart:', value: '💙' },
  { id: ':purple_heart:', value: '💜' },
  { id: ':heart:', value: '❤️' },
  { id: ':green_heart:', value: '💚' },
  { id: ':broken_heart:', value: '💔' },
  { id: ':heartbeat:', value: '💓' },
  { id: ':heartpulse:', value: '💗' },
  { id: ':two_hearts:', value: '💕' },
  { id: ':revolving_hearts:', value: '💞' },
  { id: ':cupid:', value: '💘' },
  { id: ':sparkling_heart:', value: '💖' },
  { id: ':sparkles:', value: '✨' },
  { id: ':star:', value: '⭐' },
  { id: ':star2:', value: '🌟' },
  { id: ':dizzy:', value: '💫' },
  { id: ':boom:', value: '💥' },
  { id: ':anger:', value: '💢' },
  { id: ':exclamation:', value: '❗' },
  { id: ':question:', value: '❓' },
  { id: ':grey_exclamation:', value: '❕' },
  { id: ':grey_question:', value: '❔' },
  { id: ':zzz:', value: '💤' },
  { id: ':dash:', value: '💨' },
  { id: ':sweat_drops:', value: '💦' },
  { id: ':notes:', value: '🎶' },
  { id: ':musical_note:', value: '🎵' },
  { id: ':fire:', value: '🔥' },
  { id: ':thumbsup:', value: '👍' },
  { id: ':thumbsdown:', value: '👎' },
  { id: ':ok_hand:', value: '👌' },
  { id: ':punch:', value: '👊' },
  { id: ':fist:', value: '✊' },
  { id: ':v:', value: '✌️' },
  { id: ':wave:', value: '👋' },
  { id: ':hand:', value: '✋' },
  { id: ':open_hands:', value: '👐' },
  { id: ':point_up:', value: '☝️' },
  { id: ':point_down:', value: '👇' },
  { id: ':point_left:', value: '👈' },
  { id: ':point_right:', value: '👉' },
  { id: ':raised_hands:', value: '🙌' },
  { id: ':pray:', value: '🙏' },
  { id: ':point_up_2:', value: '👆' },
  { id: ':clap:', value: '👏' },
  { id: ':muscle:', value: '💪' },
  { id: ':metal:', value: '🤘' },
  { id: ':walking:', value: '🚶' },
  { id: ':runner:', value: '🏃' },
  { id: ':couple:', value: '👫' },
  { id: ':family:', value: '👪' },
  { id: ':dancer:', value: '💃' },
  { id: ':dancers:', value: '👯' },
  { id: ':information_desk_person:', value: '💁' },
  { id: ':girl:', value: '👧' },
  { id: ':woman:', value: '👩' },
  { id: ':man:', value: '👨' },
  { id: ':baby:', value: '👶' },
  { id: ':older_woman:', value: '👵' },
  { id: ':older_man:', value: '👴' },
];

export const MAIN_CLASS_NAME = 'bot-answer-editor';
