import React, { memo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { DefaultActionGroupSchema, StartElma365ProcessActionSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../../utils';
import OutputConnection from '../../common/OutputConnection';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
} from '../../../../../recoil/scenarioStructure';
import StartElma365ProcessActionSettingsModal from '../../common/StartElma365ProcessActionSettingsModal';
import { VARIABLE_TAG_COLOR_CAUTION, VARIABLE_TAG_COLOR_DEFAULT } from '../../../../../simple-bot/const';
import SbTag from '../../../../../simple-bot/components/common/SbTag';

const ACTION_CLASS_NAME = 'start-elma365-process-action';
const CONTENT_CLASS_NAME = `${ACTION_CLASS_NAME}__content`;
const FAILURE_CONNECTION_CLASS_NAME = `${ACTION_CLASS_NAME}__failure-connection`;
const CONTENT_CARD_CLASS_NAME = `${CONTENT_CLASS_NAME}__card`;
const CONTENT_CARD_WARNING_CLASS_NAME = `${CONTENT_CLASS_NAME}__card_warning`;
const CARD_TITLE_CLASS_NAME = `${CONTENT_CARD_CLASS_NAME}__title`;
const CARD_PROP_CLASS_NAME = `${CONTENT_CARD_CLASS_NAME}__prop`;
const CARD_DIVIDER_CLASS_NAME = `${CONTENT_CARD_CLASS_NAME}__divider`;
const CARD_RESULT_CLASS_NAME = `${CONTENT_CARD_CLASS_NAME}__result`;

interface IStartElma365ProcessProps {
  index: number;
  action: StartElma365ProcessActionSchema;
  group: DefaultActionGroupSchema;
}

const StartElma365Process: React.FC<IStartElma365ProcessProps> = ({ action, index, group }) => {
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const variables = scenarioStructure?.variables || [];

  const propertiesHasIssues = action.formProperties.some((p) =>
    scenarioValidation?.hasIssue(p.id, EntityFieldPath.VariableId)
  );

  const resultPropertyHasIssue = scenarioValidation?.hasIssue(action.id, EntityFieldPath.VariableId);

  const relatedAppElementHasIssue = scenarioValidation?.hasIssue(
    action.id,
    EntityFieldPath.RelatedAppElementVariableId
  );

  const hasIssues = propertiesHasIssues || resultPropertyHasIssue || relatedAppElementHasIssue;

  const onSettingsModalOpen = () => setSettingsModalVisible(true);
  const onSettingsModalClose = () => setSettingsModalVisible(false);

  const onActionSettingsChange = (action: StartElma365ProcessActionSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id) as StartElma365ProcessActionSchema;
    Object.keys(action).forEach((key) => (foundAction[key] = action[key]));
    setScenarioStructure(newScenarioStructure);
  };

  const contentCardClasses = [CONTENT_CARD_CLASS_NAME];
  hasIssues && contentCardClasses.push(CONTENT_CARD_WARNING_CLASS_NAME);

  return (
    <ActionWrapper
      showStartElma365ProcessActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="play" size={20} />}
      index={index}
      title="Запуск бизнес-процесса"
    >
      <div className={CONTENT_CLASS_NAME}>
        {action.processTitle ? (
          <>
            <div className={contentCardClasses.join(' ')}>
              <div className={CARD_TITLE_CLASS_NAME} id={getElementId(action.id, EntityFieldPath.VariableId)}>
                {action.processTitle}
              </div>
              {action.formProperties
                .filter((p) => p.variableId)
                .map((p) => {
                  const variable = variables.find((v) => v.id === p.variableId);
                  if (!variable) return null;

                  return (
                    <div key={p.code} className={CARD_PROP_CLASS_NAME}>
                      <SbTag
                        color={
                          scenarioValidation?.hasIssue(p.id, EntityFieldPath.VariableId)
                            ? VARIABLE_TAG_COLOR_CAUTION
                            : VARIABLE_TAG_COLOR_DEFAULT
                        }
                        sbSize="x-small"
                        text={variable.name}
                      />
                      &nbsp;<b>-&gt;</b> <b>{p.name}</b>
                    </div>
                  );
                })}
              <div className={CARD_DIVIDER_CLASS_NAME} />
              <div className={CARD_RESULT_CLASS_NAME}>
                Результат:{' '}
                {action.variableId ? (
                  <SbTag
                    color={resultPropertyHasIssue ? VARIABLE_TAG_COLOR_CAUTION : VARIABLE_TAG_COLOR_DEFAULT}
                    sbSize="x-small"
                    text={variables.find((v) => v.id === action.variableId)?.name}
                  />
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className="dashed-button" onClick={onSettingsModalOpen}>
              Настроить запуск
            </div>
          </>
        ) : (
          <>
            <div>Выберите бизнес-процесс, по которому будет что-то происходить</div>
            <div className="dashed-button" onClick={onSettingsModalOpen}>
              Добавить бизнес-процесс
            </div>
          </>
        )}
        {relatedAppElementHasIssue && (
          <span id={getElementId(action.id, EntityFieldPath.RelatedAppElementVariableId)} />
        )}
        {action.formProperties.map((p) =>
          scenarioValidation?.hasIssue(p.id, EntityFieldPath.VariableId) ? (
            <span key={p.id} id={getElementId(p.id, EntityFieldPath.VariableId)} />
          ) : null
        )}
      </div>
      <div className={FAILURE_CONNECTION_CLASS_NAME}>
        <div>Куда переходить в случае ошибки:</div>
        <OutputConnection
          entity={action}
          groupId={group.id}
          id={getElementId(action.id, EntityFieldPath.OutputBindingId)}
          isValid={!scenarioValidation?.hasIssue(action.id, EntityFieldPath.OutputBindingId)}
        />
      </div>
      <StartElma365ProcessActionSettingsModal
        action={action}
        visible={settingsModalVisible}
        onChange={onActionSettingsChange}
        onClose={onSettingsModalClose}
      />
    </ActionWrapper>
  );
};

export default memo(StartElma365Process);
