import React from 'react';
import { Spin } from 'antd';
import ReactWebChat from 'botframework-webchat';
import { Flags } from 'react-feature-flags';

import { simpleBotWebChatStyleOptions } from '../../const';
import IbMessage from '../../../inbox/components/common/IbMessage';
import { FeatureFlagNames } from '../../../constants';

const QUOTED_MESSAGE_MAIN_CLASS_NAME = 'sb-quoted-message';
const QUOTED_MESSAGE_PLACEHOLDER_CLASS_NAME = `${QUOTED_MESSAGE_MAIN_CLASS_NAME}__placeholder`;
const QUOTED_MESSAGE_CONTAINER_CLASS_NAME = `${QUOTED_MESSAGE_MAIN_CLASS_NAME}__container`;
const QUOTED_MESSAGE_BORDER_CLASS_NAME = `${QUOTED_MESSAGE_CONTAINER_CLASS_NAME}__border`;

interface IContent {
  Text: string;
  Type: string;
}

interface IMention {
  MentionedUserId: string;
  MentionedParticipantId: string;
  StartPosition: number;
  EndPosition: number;
}

interface IQuotedMessage {
  MessageId: string;
  Content: IContent;
  Mentions: IMention[];
}

interface ISbWebChatContentProps {
  initializing: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  webChatProps: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const activityMiddleware = () => (next: any) => (...setupArgs: any) => {
  if (!next) {
    return () => null;
  }

  const component = next(...setupArgs);
  if (!component) {
    return () => null;
  }

  // TODO: сделать кастомное отображение сообщений, чтобы цитаты рендерились внутри баббла с реплаем
  const [card] = setupArgs;
  const quotes = (card.activity.inboxQuotedMessages ?? []) as IQuotedMessage[];
  return (...renderArgs: unknown[]) => (
    <div>
      {quotes.map((q) => {
        const renderMessage = (mentionLinksEnabled: boolean) => {
          return (
            <IbMessage
              content={{ text: q.Content.Text, type: q.Content.Type }}
              inline={false}
              mentionLinksEnabled={mentionLinksEnabled}
              mentions={q.Mentions?.map((m) => {
                return {
                  mentionedUserId: m.MentionedUserId,
                  mentionedParticipantId: m.MentionedParticipantId,
                  startPosition: m.StartPosition,
                  endPosition: m.EndPosition,
                };
              })}
            />
          );
        };
        return (
          <div key={q.MessageId} className={QUOTED_MESSAGE_MAIN_CLASS_NAME}>
            <div className={QUOTED_MESSAGE_PLACEHOLDER_CLASS_NAME}></div>
            <div className={QUOTED_MESSAGE_CONTAINER_CLASS_NAME}>
              <div className={QUOTED_MESSAGE_BORDER_CLASS_NAME}>
                <Flags
                  authorizedFlags={[FeatureFlagNames.USERS_MANAGEMENT]}
                  renderOff={() => renderMessage(false)}
                  renderOn={() => renderMessage(true)}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div>{component(...renderArgs)}</div>
    </div>
  );
};

const SbWebChatContent: React.FC<ISbWebChatContentProps> = ({ initializing, webChatProps }) => (
  <div className="sb-webchat__content">
    {initializing ? (
      <Spin className="sb-webchat__content__spinner" />
    ) : (
      <ReactWebChat
        activityMiddleware={activityMiddleware}
        {...{
          styleOptions: simpleBotWebChatStyleOptions,
          ...webChatProps,
        }}
      />
    )}
  </div>
);

export default SbWebChatContent;
