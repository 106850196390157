import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Row, Table, TablePaginationConfig, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { PlusCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { GroupModel } from '../../../api';
import { groupApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const GroupList: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listGroups, setListGroups] = useState([] as GroupModel[]);
  const history = useHistory();

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await groupApi.listGroups((pagination.current ?? 1) - 1, pagination.pageSize);
      setListGroups(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка групп',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize]);

  const onAddBtnClick = () => {
    history.push('/groups/add');
  };

  const onDeleteBtnClick = async (group: GroupModel) => {
    try {
      await groupApi.deleteGroup(group.id);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении группы',
        error: e,
      });
    }
    loadData();
  };

  const onEditBtnClick = (group: GroupModel) => {
    history.push(`/groups/edit/${group.id}`);
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      render: (_text: unknown, group: GroupModel) => (
        <Link
          to={{
            pathname: `/groups/${group.id}`,
          }}
        >
          {group.name}
        </Link>
      ),
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: (_text: unknown, group: GroupModel) => (
        <Row>
          <Tooltip placement="top" title="Изменить группу">
            <Button
              icon={<EditOutlined />}
              style={{ marginRight: 4 }}
              type="ghost"
              onClick={() => onEditBtnClick(group)}
            />
          </Tooltip>
          <Tooltip placement="top" title="Удалить группу">
            <Button danger icon={<DeleteOutlined />} type="ghost" onClick={() => onDeleteBtnClick(group)} />
          </Tooltip>
        </Row>
      ),
      ellipsis: true,
      width: '15%',
    },
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="1" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить
          </Button>,
        ]}
        title="Группы"
      />
      <Table
        columns={columns}
        dataSource={listGroups.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default GroupList;
