import React from 'react';
import { Tabs } from 'antd';
import { TabPaneProps } from 'antd/lib/tabs';

import './index.less';

const { TabPane } = Tabs;

const MAIN_CLASS_NAME = 'ib-tab-pane';

export interface IIbTabPaneProps extends TabPaneProps {
  key?: string | number;
}

const IbTabPane: React.FC<IIbTabPaneProps> = ({ key, children, className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return (
    <TabPane key={key} {...otherProps} className={classes.join(' ')}>
      {children}
    </TabPane>
  );
};

export default IbTabPane;
