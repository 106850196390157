import Queue from 'better-queue';

import { EventBus, EventType, IEventBus } from '../events';
import { delay } from '../../utils/reactUtil';

import {
  TaskType,
  ITaskData,
  ITaskDelays,
  ITaskEvent,
  ITaskFailureEvent,
  ITaskHandler,
  ITaskSuccessEvent,
  QueueCallback,
  ITaskContext,
} from './types';
import { ITaskStorage, TaskStorage } from './storage';

const DEBUG_RUN = false;
const DEBUG_PROCESS = false;

export interface ITaskQueue {
  run<T>(data: ITaskData<T>): Promise<unknown>;
  push<T>(data: ITaskData<T>): void;
}

export class TaskQueue implements ITaskQueue {
  private static readonly s_emptyDelays: ITaskDelays = {
    pending: 0,
    process: 0,
    success: 0,
    failure: 0,
  };

  private static readonly s_debugDelays: ITaskDelays = {
    pending: 2000,
    process: 1000,
    success: 1000,
    failure: 1000,
  };

  private static s_instance?: TaskQueue;

  private static readonly _handlerMap: Map<TaskType, () => ITaskHandler<unknown>> = new Map();

  private readonly _eventBus: IEventBus;
  private readonly _betterQueue: Queue;

  private readonly _runDelays: ITaskDelays;
  private readonly _processDelays: ITaskDelays;

  public constructor(eventBus?: IEventBus, taskStorage?: ITaskStorage) {
    this._eventBus = eventBus || EventBus.instance;

    const options = { store: taskStorage || TaskStorage.instance };
    this._betterQueue = new Queue((data, callback) => this.process(data, callback), options);

    this._runDelays = DEBUG_RUN ? TaskQueue.s_debugDelays : TaskQueue.s_emptyDelays;
    this._processDelays = DEBUG_PROCESS ? TaskQueue.s_debugDelays : TaskQueue.s_emptyDelays;
  }

  public static get instance(): TaskQueue {
    if (!TaskQueue.s_instance) {
      TaskQueue.s_instance = new TaskQueue(EventBus.instance, TaskStorage.instance);
    }

    return TaskQueue.s_instance;
  }

  public static bind<T>(type: TaskType, handler: () => ITaskHandler<T>): void {
    this._handlerMap.set(type, handler);
  }

  public async run<T>(data: ITaskData<T>): Promise<unknown> {
    const handler = TaskQueue.handler<T>(data.type);
    const progress = handler.estimate(data.content);
    const event: ITaskEvent<T> = { data, progress };
    this._eventBus.emit(EventType.QueueTaskPending, event);
    return await this.execute(data, handler, this._runDelays);
  }

  public push<T>(data: ITaskData<T>): void {
    const handler = TaskQueue.handler<T>(data.type);
    const progress = handler.estimate(data.content);
    const event: ITaskEvent<T> = { data, progress };
    this._eventBus.emit(EventType.QueueTaskPending, event);
    this._betterQueue.push(data);
  }

  private process<T>(data: ITaskData<T>, callback: QueueCallback): void {
    const handler = TaskQueue.handler<T>(data.type);
    this.execute(data, handler, this._processDelays)
      .then((result: unknown) => callback(undefined, result))
      .catch((error: unknown) => callback(error, undefined));
  }

  private async execute<T>(data: ITaskData<T>, handler: ITaskHandler<T>, delays: ITaskDelays): Promise<unknown> {
    let progress = handler.estimate(data.content);
    const context: ITaskContext = {
      notify: (p) => {
        progress = p;
        const progressEvent: ITaskEvent<T> = { data, progress };
        this._eventBus.emit(EventType.QueueTaskProgress, progressEvent);
      },
    };

    if (delays.pending) {
      await delay(delays.pending);
    }

    const processEvent: ITaskEvent<T> = { data, progress };
    this._eventBus.emit(EventType.QueueTaskProcess, processEvent);

    if (delays.process) {
      await delay(delays.process);
    }

    let response: unknown;
    try {
      response = await handler.process(data.content, context);
    } catch (e) {
      if (delays.failure) {
        await delay(delays.failure);
      }

      const connFailureEvent = { error: e };
      this._eventBus.emit(EventType.NetworkConnectionFailure, connFailureEvent);

      const taskFailureEvent: ITaskFailureEvent<T> = { data, progress, error: e };
      this._eventBus.emit(EventType.QueueTaskFailure, taskFailureEvent);
      throw e;
    }

    if (delays.success) {
      await delay(delays.success);
    }

    progress = { ...progress, current: progress.maximum };

    const connSuccessEvent = { result: response };
    this._eventBus.emit(EventType.NetworkConnectionSuccess, connSuccessEvent);

    const taskSuccessEvent: ITaskSuccessEvent<T> = { data, progress, result: response };
    this._eventBus.emit(EventType.QueueTaskSuccess, taskSuccessEvent);

    return response;
  }

  private static handler<T>(type: TaskType): ITaskHandler<T> {
    const handler = this._handlerMap.get(type);
    if (!handler) {
      throw Error(`Handler for task '${type}' not found.`);
    }
    return handler();
  }
}
