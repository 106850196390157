import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Space, Checkbox, Button, PageHeader, Popover } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { AgentStageModel, AgentStageUpdatingRequest } from '../../../../api';
import { AlertTypes } from '../../../constants';
import { agentStageApi } from '../../../apis';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const AgentStageEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const [form] = Form.useForm();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [agentStage, setAgentStage] = useState({} as AgentStageModel);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await agentStageApi.getAgentStage(id);
      setAgentStage(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных стадии бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const loadDataToForm = () => {
    form.resetFields();
  };
  useEffect(loadDataToForm, [agentStage]);

  const onFinish = async (values: AgentStageUpdatingRequest) => {
    setLoading(true);
    try {
      await agentStageApi.updateAgentStage(id, values);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Параметры стадии бота успешно изменены',
        description: '',
      });
      goBack();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении роли',
        error: e,
      });
      setLoading(false);
    }
  };

  const onBackBtnClick = () => {
    goBack();
  };

  return (
    <div>
      <PageHeader title="Изменение параметров стадии бота" />
      <Form form={form} initialValues={agentStage} layout="vertical" onFinish={onFinish}>
        <Form.Item name="dialogsVersioningEnabled" valuePropName="checked">
          <Checkbox>
            <Space>
              Использовать версионирование диалогов
              <Popover
                content="Версионирование диалогов необходимо для того, чтобы текущие активные диалоги могли продолжать корректно работать, даже если они были изменены или удалены в новой опубликованной версии."
                overlayStyle={{
                  maxWidth: '400px',
                }}
                placement="right"
                title="Версионирование диалогов"
              >
                <QuestionCircleFilled />
              </Popover>
            </Space>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={onBackBtnClick}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AgentStageEdit;
