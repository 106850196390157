import React from 'react';
import { useRecoilValue } from 'recoil';

import {
  currentScenarioStructureSelector,
  draggingBindingCurrentPositionSelector,
  draggingBindingStartPositionSelector,
  dragTargetSelector,
  inputConnectionPositionsSelector,
} from '../../../../recoil/scenarioStructure';
import { getBindingElementAttributes, getOutputOffsetX, tryGetElementById } from '../../utils';
import { IOutputConnectionPosition } from '../../types';

import Arrow from './Arrow';
import GroupPlaceholder from './GroupPlaceholder';

const DraggingBinding: React.FC = () => {
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const currentPosition = useRecoilValue(draggingBindingCurrentPositionSelector);
  const startPosition = useRecoilValue(draggingBindingStartPositionSelector);
  const dragTarget = useRecoilValue(dragTargetSelector);
  const dragTargetPosition = useRecoilValue(inputConnectionPositionsSelector(dragTarget?.id || ''));

  if (!scenarioStructure || !startPosition) return null;

  if (startPosition.positionX === currentPosition?.positionX && startPosition.positionY === currentPosition.positionY)
    return null;

  const sourcePosition: IOutputConnectionPosition = {
    id: startPosition.entityId,
    positionX: startPosition.positionX,
    positionY: startPosition.positionY,
  };
  const targetPosition =
    dragTarget && dragTargetPosition
      ? dragTargetPosition
      : {
          id: '',
          positionX: currentPosition?.positionX,
          positionY: currentPosition?.positionY,
          altPositionX: currentPosition?.positionX,
          altPositionY: currentPosition?.positionY,
        };

  const sourceEntity = tryGetElementById(scenarioStructure, sourcePosition.id);
  if (!sourceEntity) return null;

  const outputOffsetX = getOutputOffsetX(sourceEntity);
  const { svgTransform, pathDefinition } = getBindingElementAttributes(sourcePosition, targetPosition, outputOffsetX);
  const bindingArrowId = 'dragging-binding-arrow';

  return (
    <>
      <svg className="sb-binding-container sb-binding-container_highlighted" style={{ transform: svgTransform }}>
        <g className="sb-binding">
          <Arrow id={bindingArrowId} />
          <path className="sb-binding__visible" d={pathDefinition} markerEnd={`url(#${bindingArrowId}`} />
        </g>
      </svg>
      <GroupPlaceholder sourceEntity={sourceEntity} sourcePosition={sourcePosition} targetPosition={targetPosition} />
    </>
  );
};

export default DraggingBinding;
