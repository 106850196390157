import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { ExpandableConfig } from 'antd/lib/table/interface';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import {
  InboxOperatorGroupCreationRequest,
  InboxOperatorGroupModel,
  InboxOperatorGroupOperatorModel,
  InboxOperatorGroupUpdatingRequest,
  InboxParticipantModel,
  RoleModel,
  UserStatus,
} from '../../../../api';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import { IFormFieldValidationResult } from '../../../types';
import IbAvatar from '../common/IbAvatar';
import IbTable, { MOBILE_MAIN_CELL_CLASS_NAME } from '../common/IbTable';
import IbTag from '../common/IbTag';
import { formatRoleNames, includesIgnoreCase } from '../../../utils/stringUtil';
import { OPERATOR_GROUP_TAG_STYLE } from '../../../constants';

import AddOperatorsButton from './AddOperatorsButton';
import {
  ACTIONS_CELL_CLASS_NAME,
  ACTIONS_DATA_INDEX,
  ANIMATION_DELAY,
  CLEAR_LIST_BUTTON_CLASS_NAME,
  DELETE_BUTTON_CLASS_NAME,
  EXPANDED_ROW_ACTIONS_CLASS_NAME,
  FORM_ITEM_CLASS_NAME,
  FORM_SUBTITLE_CLASS_NAME,
  GROUPS_CELL_CLASS_NAME,
  GROUP_DEFAULT,
  MAIN_CLASS_NAME,
  NAME_CELL_CLASS_NAME,
  OPERATORS_TABLE_CLASS_NAME,
} from './const';

interface IOperatorGroupEditFormValidationResult {
  name: IFormFieldValidationResult;
}

interface IIbOperatorGroupEditModalProps {
  visible?: boolean;
  editGroup?: InboxOperatorGroupModel;
  allowDelete?: boolean;
  operatorList?: InboxParticipantModel[];
  operatorListLoading?: boolean;
  onOperatorsSearch: (search: string) => void;
  onAdd?: (request: InboxOperatorGroupCreationRequest) => Promise<void>;
  onEdit?: (request: InboxOperatorGroupUpdatingRequest) => Promise<void>;
  onDelete?: () => void;
  onCancel?: () => void;
}

const IbOperatorGroupEditModal: React.FC<IIbOperatorGroupEditModalProps> = ({
  visible,
  editGroup,
  allowDelete,
  operatorList,
  operatorListLoading,
  onOperatorsSearch,
  onAdd,
  onEdit,
  onDelete,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [saving, setSaving] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [group, setGroup] = useState(GROUP_DEFAULT);
  const [currentCheckedOperators, setCurrentCheckedOperators] = useState<InboxOperatorGroupOperatorModel[]>([]);

  const [groupOperatorsSearchText, setGroupOperatorsSearchText] = useState('');

  const validationResult: IOperatorGroupEditFormValidationResult = {
    name: {
      isValid: !!group.name,
      message: t('Enter name'),
    },
  };

  const formIsValid = validationResult.name.isValid;

  const filteredGroupOperators =
    group.operators?.filter((o) => includesIgnoreCase(o.operator.fullName, groupOperatorsSearchText)) || [];

  const resetCurrentCheckedOperators = (checkedOperators: InboxOperatorGroupOperatorModel[]) => {
    // NOTE: сбрасываем только после того, как сработает анимация закрытия всплывыющего меню
    setTimeout(() => {
      setCurrentCheckedOperators(checkedOperators);
    }, ANIMATION_DELAY);
  };

  const onSaveButtonClick = async () => {
    setShowValidation(true);

    if (!editGroup || !formIsValid) {
      return;
    }

    setSaving(true);
    await onEdit?.({
      name: group.name,
      description: group.description,
      authUserIds: group.operators.map((o) => o.operator.id),
    });
    setSaving(false);
    onCancel?.();
  };

  const onAddButtonClick = async () => {
    setShowValidation(true);

    if (!formIsValid) {
      return;
    }

    setSaving(true);
    await onAdd?.({
      name: group.name,
      description: group.description,
      authUserIds: group.operators.map((o) => o.operator.id),
    });
    setSaving(false);
    onCancel?.();
  };

  const onNameChange = (value: string) => setGroup({ ...group, name: value });
  const onDescriptionChange = (value: string) => setGroup({ ...group, description: value });

  const onGroupOperatorDelete = (groupOperator: InboxOperatorGroupOperatorModel) => () => {
    const newGroupOperators = group.operators.filter((o) => o.operator.id !== groupOperator.operator.id);
    setCurrentCheckedOperators(newGroupOperators);
    setGroup({ ...group, operators: newGroupOperators });
  };

  const onOperatorsPopoverClose = () => {
    resetCurrentCheckedOperators(group.operators);
  };

  const onOperatorListItemClick = (operator: InboxParticipantModel) => () => {
    if (currentCheckedOperators.some((o) => o.operator.id === operator.subject.id)) {
      setCurrentCheckedOperators(currentCheckedOperators.filter((o) => o.operator.id !== operator.subject.id));
      return;
    }

    setCurrentCheckedOperators([
      ...currentCheckedOperators,
      {
        operator: {
          id: operator.subject.id,
          fullName: operator.subject.person.name.fullName || '',
          familyName: operator.subject.person.name.familyName || '',
          givenName: operator.subject.person.name.givenName || '',
          middleName: operator.subject.person.name.middleName,
          shortName: operator.subject.person.name.shortName || '',
          login: operator.subject.person.name.nickName || '',
          logins: [],
          phoneNumber: operator.subject.person.contacts.primaryTel || '',
          status: operator.subject.person.status || UserStatus.Active,
          tenantNames: '',
          roles: operator.subject.person.roles?.map((r) => r as RoleModel) || [],
          password: '',
          email: operator.subject.person.contacts.email || '',
          createdOn: operator.createdOn,
        },
        groups: operator.operatorGroups.map((g) => ({
          id: g.id,
          name: g.name,
          description: g.description,
          operators: [],
        })),
      },
    ]);
  };

  const onOperatorListClear = () => {
    setCurrentCheckedOperators([]);
    setGroup({ ...group, operators: [] });
  };

  const onCheckedOperatorsSave = () => {
    setGroup({
      ...group,
      operators: currentCheckedOperators,
    });
  };

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setSaving(false);
    setShowValidation(false);
    setGroup(editGroup ? { ...editGroup } : GROUP_DEFAULT);
    setCurrentCheckedOperators(editGroup ? editGroup.operators : []);
    setGroupOperatorsSearchText('');
  };
  useEffect(onVisiblePropChange, [visible]);

  const columns: ColumnType<InboxOperatorGroupOperatorModel>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      title: t('Full name'),
      render: (_, record) => (
        <div className={NAME_CELL_CLASS_NAME}>
          <IbAvatar metadata={{ uid: record.operator.id, text: record.operator.fullName }} size="x-small" />
          <IbTypography.Paragraph type="secondary">{record.operator.fullName}</IbTypography.Paragraph>
        </div>
      ),
    },
    {
      title: t('Roles'),
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">
          {formatRoleNames(record.operator.roles.map((r) => r.name) || [])}
        </IbTypography.Paragraph>
      ),
    },
    {
      title: t('Groups'),
      render: (_, record) => (
        <div className={GROUPS_CELL_CLASS_NAME}>
          {record.groups.length
            ? record.groups.map((g) => <IbTag key={g.id} content={g.name} style={OPERATOR_GROUP_TAG_STYLE} />)
            : '—'}
        </div>
      ),
    },
    {
      className: ACTIONS_CELL_CLASS_NAME,
      title: (
        <IbButton type="link" onClick={onOperatorListClear}>
          {t('Clear list')}
        </IbButton>
      ),
      render: (_, record) => (
        <IbButton icon={<IbIcon iconName="delete" />} type="icon" onClick={onGroupOperatorDelete(record)} />
      ),
      dataIndex: ACTIONS_DATA_INDEX,
    },
  ];

  const expandableForMobile: ExpandableConfig<InboxOperatorGroupOperatorModel> = {
    expandedRowRender: (record) => {
      return (
        <>
          <ul>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Roles')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatRoleNames(record.operator.roles.map((r) => r.name) || [])}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Groups')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {record.groups.length
                  ? record.groups.map((g) => <IbTag key={g.id} content={g.name} style={OPERATOR_GROUP_TAG_STYLE} />)
                  : '—'}
              </IbTypography.Paragraph>
            </li>
          </ul>
          <div className={EXPANDED_ROW_ACTIONS_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="delete" />} type="link" onClick={onGroupOperatorDelete(record)}>
              {t('Delete')}
            </IbButton>
          </div>
        </>
      );
    },
  };

  const title = editGroup ? t('Edit group') : t('Add group');
  const footer = editGroup ? (
    <>
      <IbButton disabled={saving} onClick={onSaveButtonClick}>
        {t('Save')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
      {allowDelete && (
        <IbButton
          className={DELETE_BUTTON_CLASS_NAME}
          icon={<IbIcon iconName="delete" />}
          type="link"
          onClick={onDelete}
        >
          {t('Delete')}
        </IbButton>
      )}
    </>
  ) : (
    <>
      <IbButton disabled={saving} onClick={onAddButtonClick}>
        {t('Add')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
    </>
  );

  return (
    <IbModal
      className={MAIN_CLASS_NAME}
      footer={footer}
      loading={saving}
      position={IbModalPosition.FixedTall}
      title={title}
      visible={visible}
      onCancel={onCancel}
    >
      <IbTypography.Paragraph strong className={FORM_SUBTITLE_CLASS_NAME} type="secondary">
        {t('General info')}
      </IbTypography.Paragraph>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Name2')}</IbTypography.Paragraph>
        <IbInput
          status={showValidation && !validationResult.name.isValid ? 'error' : 'default'}
          value={group.name || undefined}
          onChange={onNameChange}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Description')}</IbTypography.Paragraph>
        <IbInput value={group.description || undefined} onChange={onDescriptionChange} />
      </div>
      <IbTypography.Paragraph strong className={FORM_SUBTITLE_CLASS_NAME} type="secondary">
        {t('Operators')}
        <AddOperatorsButton
          currentCheckedOperators={currentCheckedOperators}
          operatorList={operatorList}
          operatorListLoading={operatorListLoading}
          onCheckedOperatorsSave={onCheckedOperatorsSave}
          onOperatorListItemClick={onOperatorListItemClick}
          onOperatorsSearch={onOperatorsSearch}
          onPopoverClose={onOperatorsPopoverClose}
        />
        {!!group.operators?.length && (
          <IbButton className={CLEAR_LIST_BUTTON_CLASS_NAME} type="link" onClick={onOperatorListClear}>
            {t('Clear list')}
          </IbButton>
        )}
      </IbTypography.Paragraph>
      {!!group.operators?.length && (
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbInput
            placeholder={t('Operators search')}
            value={groupOperatorsSearchText}
            onChange={setGroupOperatorsSearchText}
          />
          <IbTable
            className={OPERATORS_TABLE_CLASS_NAME}
            columns={columns}
            dataSource={filteredGroupOperators.map((o) => ({ ...o, id: o.operator.id }))}
            emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
            expandableForMobile={expandableForMobile}
          />
        </div>
      )}
    </IbModal>
  );
};

export default IbOperatorGroupEditModal;
