import { selector, selectorFamily } from 'recoil';

import { BotStageType } from '../../../api';
import { ValidationStatus } from '../utils/validation';
import { lruLocalStorage } from '../../utils/localStorageUtil';

import { currentBotStageTypeState, currentScenarioValidationStatusState } from './atom';

export const currentBotStageTypeSelector = selectorFamily<BotStageType, string>({
  key: 'currentBotStageTypeSelector',
  get: (key) => ({ get }) => {
    const currentBotStageType = get(currentBotStageTypeState(key));
    if (currentBotStageType) return currentBotStageType;

    return lruLocalStorage.getCurrentBotStageType(key) || BotStageType.Origin;
  },
  set: (key) => ({ set }, newValue) => {
    set(currentBotStageTypeState(key), newValue);
    lruLocalStorage.setCurrentBotStageType(key, newValue as BotStageType);
  },
});

export const currentScenarioValidationStatusSelector = selector<ValidationStatus | undefined>({
  key: 'currentScenarioValidationStatusSelector',
  get: ({ get }) => get(currentScenarioValidationStatusState),
  set: ({ set }, newValue) => set(currentScenarioValidationStatusState, newValue),
});
