import React, { useState } from 'react';
import moment from 'moment/moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import './index.less';

import { alertsSelectorAdd } from '../../../recoil/alerts';
import { AlertTypes, SUBJECT_ROLES } from '../../../constants';
import { InboxChatParticipantStatus, InboxChatStatus } from '../../../../api';
import { getProfile } from '../../../utils/oidcUtil';
import {
  chatItemSelector,
  networkConnectionState,
  NetworkConnectionStatus,
  operatorGroupListSelector,
  participantItemsSelector,
  participantRangeSelector,
  participantStatsSelector,
} from '../../recoil';
import IbChatInfo, { IbChatInfoAction } from '../../components/IbChatInfo';
import IbIcon from '../../components/common/IbIcon';
import { InvocationName, ITaskData, TaskQueue, TaskType } from '../../tasks';
import { IInvocationSendTaskContent } from '../../tasks/invocations';
import { IbSocialType } from '../../components/common/IbSocial';
import { InboxParticipantFilter } from '../../storages/participants';
import { StatsStatus } from '../../storages';

interface IChatInfoProps {
  chatId: string;
  onBack: () => void;
  onOpenClientCard: () => void;
  onFocusToMessageInput?: () => void;
}

const ChatInfo: React.FC<IChatInfoProps> = ({ chatId, onBack, onOpenClientCard, onFocusToMessageInput }) => {
  const profile = getProfile();
  const queue = TaskQueue.instance;

  const { t } = useTranslation();

  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [networkConnection] = useRecoilState(networkConnectionState);
  const [operatorListSearch, setOperatorListSearch] = useState('');

  const searchFilter = new InboxParticipantFilter(operatorListSearch, undefined, [SUBJECT_ROLES.operator]);
  // NOTE: в participantRangeSelector происходит начальная загрузка данных
  useRecoilValue(participantRangeSelector(searchFilter));
  const operatorListStats = useRecoilValue(participantStatsSelector(searchFilter));
  const operatorList = useRecoilValue(participantItemsSelector(searchFilter));

  const groupList = useRecoilValue(operatorGroupListSelector);

  const chatItem = useRecoilValue(chatItemSelector(chatId));
  const contact = chatItem?.entity.participants?.find((p) => p.subject.role === SUBJECT_ROLES.user)?.subject;
  const operator = chatItem?.entity.participants?.find(
    (p) => p.subject.role === SUBJECT_ROLES.operator && p.status === InboxChatParticipantStatus.Assigned
  )?.subject;
  const bot = chatItem?.entity.participants?.find(
    (p) => p.subject.role === SUBJECT_ROLES.botEntry && p.status === InboxChatParticipantStatus.Assigned
  )?.subject;
  const assignBotActionIsAvailable =
    chatItem?.entity.channel?.routing.bot.enabled && !!chatItem?.entity.channel?.routing.bot.botEntry?.id;
  const group = chatItem?.entity.assignedOperatorGroup;

  const assignSessionToOperator = async (userId?: string) => {
    const id = v4();
    const task: ITaskData<IInvocationSendTaskContent> = {
      type: TaskType.InvocationSend,
      key: id,
      timestamp: moment().toISOString(),
      content: {
        chatId,
        entityId: userId,
        activityId: id,
        name: InvocationName.InboxAssignmentAssignOperator,
      },
    };

    try {
      await queue.run(task);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Chat assignment error.'),
        error: e,
      });
    }

    onFocusToMessageInput?.();
  };

  const assignSessionToBot = async () => {
    const id = v4();
    const task: ITaskData<IInvocationSendTaskContent> = {
      type: TaskType.InvocationSend,
      key: id,
      timestamp: moment().toISOString(),
      content: {
        chatId,
        activityId: id,
        name: InvocationName.InboxAssignmentAssignBot,
      },
    };

    try {
      await queue.run(task);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Chat bot assignment error.'),
        error: e,
      });
    }
  };

  const assignSessionToGroup = async (groupId?: string) => {
    const id = v4();
    const task: ITaskData<IInvocationSendTaskContent> = {
      type: TaskType.InvocationSend,
      key: id,
      timestamp: moment().toISOString(),
      content: {
        chatId,
        entityId: groupId,
        activityId: id,
        name: InvocationName.InboxAssignmentAssignOperatorGroup,
      },
    };

    try {
      await queue.run(task);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Chat assignment error.'),
        error: e,
      });
    }
  };

  const closeSession = async () => {
    const id = v4();
    const task: ITaskData<IInvocationSendTaskContent> = {
      key: id,
      type: TaskType.InvocationSend,
      timestamp: moment().toISOString(),
      content: {
        chatId,
        activityId: id,
        name: InvocationName.InboxAssignmentClose,
      },
    };

    try {
      await queue.run(task);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Chat closing error.'),
        error: e,
      });
    }
  };

  const returnSessionToQueue = async () => {
    const id = v4();
    const task: ITaskData<IInvocationSendTaskContent> = {
      key: id,
      type: TaskType.InvocationSend,
      timestamp: moment().toISOString(),
      content: {
        chatId,
        activityId: id,
        name: InvocationName.InboxAssignmentQueue,
      },
    };

    try {
      await queue.run(task);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Chat queuing error.'),
        error: e,
      });
    }
  };

  const getActions = () => {
    if (!chatItem) {
      return [];
    }

    const assignMeAction = {
      icon: <IbIcon iconName="next" />,
      text: t('Take to work'),
      handler: () => {
        assignSessionToOperator().finally();
      },
    } as IbChatInfoAction;

    const assignBotAction = {
      icon: <IbIcon iconName="robot-one" />,
      text: t('Assign to bot (action)'),
      handler: assignSessionToBot,
    } as IbChatInfoAction;

    const closeAction = {
      icon: <IbIcon iconName="check-one" />,
      text: t('Issue resolved'),
      handler: closeSession,
    } as IbChatInfoAction;

    const returnToQueueAction = {
      icon: <IbIcon iconName="return" />,
      text: t('Return to queue'),
      handler: returnSessionToQueue,
    } as IbChatInfoAction;

    switch (chatItem.entity.status) {
      case InboxChatStatus.Queued:
        return assignBotActionIsAvailable
          ? [assignMeAction, closeAction, assignBotAction]
          : [assignMeAction, closeAction];
      case InboxChatStatus.Assigned: {
        const assignedToBot = chatItem.entity.participants?.some(
          (p) => p.subject.role === SUBJECT_ROLES.botEntry && p.status === InboxChatParticipantStatus.Assigned
        );
        const assignedToMe = chatItem.entity.participants?.some(
          (p) => p.subject.id === profile.userId && p.status === InboxChatParticipantStatus.Assigned
        );

        if (assignedToBot) {
          return [closeAction, returnToQueueAction, assignMeAction];
        }

        if (assignedToMe) {
          return assignBotActionIsAvailable
            ? [closeAction, returnToQueueAction, assignBotAction]
            : [closeAction, returnToQueueAction];
        }

        return assignBotActionIsAvailable
          ? [closeAction, returnToQueueAction, assignMeAction, assignBotAction]
          : [closeAction, returnToQueueAction, assignMeAction];
      }
      case InboxChatStatus.Closed:
        return [returnToQueueAction, assignMeAction];
      default:
        return [];
    }
  };

  const onOperatorsSearch = (search?: string) => {
    setOperatorListSearch(search || '');
  };

  const onReassignOperator = (userId: string) => {
    assignSessionToOperator(userId).finally();
  };

  const onReassignGroup = (groupId: string) => {
    assignSessionToGroup(groupId).finally();
  };

  return (
    <IbChatInfo
      actions={getActions()}
      bot={bot}
      contact={contact}
      group={group}
      groupList={groupList}
      interactionDisabled={networkConnection.status === NetworkConnectionStatus.Off}
      operator={operator}
      operatorList={operatorList.map((i) => i.entity)}
      operatorListLoading={
        operatorListStats.status === StatsStatus.Unknown || (!operatorList.length && !!operatorListStats.itemCount)
      }
      social={chatItem?.entity.channelId as IbSocialType}
      onBack={onBack}
      onFocusToMessageInput={onFocusToMessageInput}
      onOpenClientCard={onOpenClientCard}
      onOperatorsSearch={onOperatorsSearch}
      onReassignGroup={onReassignGroup}
      onReassignOperator={onReassignOperator}
    />
  );
};

export default ChatInfo;
