import React from 'react';
import Editor, { EditorProps, Monaco } from '@monaco-editor/react';

import './index.less';

import { BotSchema, DefaultScenarioSchema } from '../../../../../api';

import { configureDefaultSettings, DEFINITIONS_URI, getTypeDefinitions, SCRIPT_LANGUAGE } from './utils';

interface ISbScriptEditorProps extends EditorProps {
  schema?: BotSchema | DefaultScenarioSchema;
  readOnly?: boolean;
}

/**
 * Открытый редактор скрипта может быть только один на странице, чтобы корректно работали описания типов
 */
const SbScriptEditor: React.FC<ISbScriptEditorProps> = ({ className, schema, readOnly, ...otherProps }) => {
  const classes = ['sb-script-editor'];
  if (className) {
    classes.push(className);
  }

  const options = {
    fixedOverflowWidgets: true,
    readOnly: readOnly,
    domReadOnly: readOnly,
  };

  const beforeMount = (monaco: Monaco) => {
    if (!monaco) return;

    configureDefaultSettings(monaco);

    const typeDefinitions = getTypeDefinitions(schema);
    const model = monaco.editor.getModel(monaco.Uri.parse(DEFINITIONS_URI));
    if (model) {
      model.setValue(typeDefinitions);
      return;
    }

    monaco.editor.createModel(typeDefinitions, SCRIPT_LANGUAGE, monaco.Uri.parse(DEFINITIONS_URI));
  };

  return (
    <Editor
      {...otherProps}
      beforeMount={beforeMount}
      className={classes.join(' ')}
      language={SCRIPT_LANGUAGE}
      options={options}
    />
  );
};

export default SbScriptEditor;
