import React from 'react';

import '../index.less';

const ErrorCard: React.FC = () => {
  return (
    <div className="sb-employee-auth-card">
      <div className="sb-employee-auth-card__title">Не удалось выполнить авторизацию.</div>
      <div className="sb-employee-auth-card__content">Попробуйте запросить повторный вход у бота.</div>
    </div>
  );
};

export default ErrorCard;
