import { FeatureFlag } from '../../api';
import { featureManagementApi } from '../apis';
import { FeatureFlagNames, USER_ROLES } from '../constants';

import { getProfile } from './oidcUtil';

const clientFeatureFlags: FeatureFlag[] = [{ name: FeatureFlagNames.HIDDEN, isActive: false }];

const getUserFeatureFlags: () => FeatureFlag[] = () => {
  const { roles } = getProfile();

  return [
    {
      name: FeatureFlagNames.ADMIN,
      isActive: roles.some((role) => [USER_ROLES.owner, USER_ROLES.admin].includes(role)),
    },
    {
      name: FeatureFlagNames.BOTS_ADMIN,
      isActive: roles.some((role) => [USER_ROLES.owner, USER_ROLES.admin, USER_ROLES.botsAdmin].includes(role)),
    },
    {
      name: FeatureFlagNames.USERS_ADMIN,
      isActive: roles.some((role) => [USER_ROLES.owner, USER_ROLES.admin, USER_ROLES.usersAdmin].includes(role)),
    },
    {
      name: FeatureFlagNames.INBOX_OPERATOR,
      isActive: roles.some((role) =>
        [USER_ROLES.owner, USER_ROLES.admin, USER_ROLES.inboxSupervisor, USER_ROLES.inboxOperator].includes(role)
      ),
    },
    {
      name: FeatureFlagNames.INBOX_SUPERVISOR,
      isActive: roles.some((role) => [USER_ROLES.owner, USER_ROLES.admin, USER_ROLES.inboxSupervisor].includes(role)),
    },
  ];
};

export const getFeatureFlags = async (): Promise<FeatureFlag[]> => {
  try {
    const response = await featureManagementApi.getFeatureFlags();
    return clientFeatureFlags.concat(getUserFeatureFlags()).concat(response.data);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Ошибка при загрузке списка доступного функционала', e);
    throw e;
  }
};
