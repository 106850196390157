import React, { useState } from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';
import IbContextMenu, { IIbContextMenuItem } from '../IbContextMenu';
import IbButton from '../IbButton';

const OPTIONS_DEFAULT = [] as ISortMode[];

const MAIN_CLASS_NAME = 'ib-sort-button';
const DROPDOWN_CLASS_NAME = `${MAIN_CLASS_NAME}__dropdown`;
const ICON_PLACEHOLDER_CLASS_NAME = `${MAIN_CLASS_NAME}__icon-placeholder`;

export interface ISortMode {
  label: string;
  value: string;
}

export interface IIbSortButtonProps {
  className?: string;
  dropdownClassName?: string;
  value?: string;
  options?: ISortMode[];
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const IbSortButton: React.FC<IIbSortButtonProps> = ({
  className,
  dropdownClassName,
  value,
  options = OPTIONS_DEFAULT,
  disabled,
  onChange,
}) => {
  options = getDefaultIfUndefined(options, OPTIONS_DEFAULT);

  const [menuVisible, setMenuVisible] = useState(false);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${status}`];
  className && classes.push(className);

  const dropdownClasses = [DROPDOWN_CLASS_NAME];
  dropdownClassName && dropdownClasses.push(dropdownClassName);

  const onButtonClick = () => setMenuVisible(true);

  const onMenuClose = () => setMenuVisible(false);

  const onMenuItemClick = (value: string) => {
    onChange?.(value);
  };

  const menuItems = options.map((option) => {
    const onMenuItemSelect = () => {
      onMenuItemClick(option.value);
    };
    return {
      icon:
        option.value === value ? (
          <IbIcon iconName="check" size={16} />
        ) : (
          <div className={ICON_PLACEHOLDER_CLASS_NAME} />
        ),
      text: option.label,
      onSelect: onMenuItemSelect,
    } as IIbContextMenuItem;
  });

  return (
    <IbContextMenu
      className={dropdownClasses.join(' ')}
      menuItems={menuItems}
      trigger="leftClick"
      visible={menuVisible}
      onClose={onMenuClose}
    >
      <IbButton
        className={classes.join(' ')}
        disabled={disabled}
        icon={<IbIcon iconName="alphabetical-sorting" />}
        type="icon-bordered"
        onClick={onButtonClick}
      />
    </IbContextMenu>
  );
};

export default IbSortButton;
