import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { i18n } from 'i18next';
import { Link } from 'react-router-dom';

import './index.less';

import IbTypography from '../common/IbTypography';
import IbIcon from '../common/IbIcon';
import IbSocial, { IbSocialType } from '../common/IbSocial';
import IbButton from '../common/IbButton';
import IbTable, { MOBILE_MAIN_CELL_CLASS_NAME } from '../common/IbTable';
import IbList from '../common/IbList';
import IbDivider from '../common/IbDivider';
import { InboxContactModel } from '../../../../api';
import { sortArrayByField } from '../../../utils/arrayUtil';
import { formatDateTimeAdaptive } from '../../../utils/stringUtil';

const MAIN_CLASS_NAME = 'ib-contact-channel-list';
const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
const CHANNELS_TABLE_CLASS_NAME = `${MAIN_CLASS_NAME}__channel-table`;
const NAME_CELL_CLASS_NAME = `${CHANNELS_TABLE_CLASS_NAME}__name-cell`;
const ACTIONS_CELL_CLASS_NAME = `${CHANNELS_TABLE_CLASS_NAME}__actions-cell`;
const CHANNEL_LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__channel-list`;
const CHANNEL_LIST_ITEM_CLASS_NAME = `${CHANNEL_LIST_CLASS_NAME}__item`;
const CHANNEL_LIST_ITEM_INFO_CLASS_NAME = `${CHANNEL_LIST_ITEM_CLASS_NAME}__info`;
const CHANNEL_LIST_ITEM_TITLE_CLASS_NAME = `${CHANNEL_LIST_ITEM_INFO_CLASS_NAME}__title`;
const ACTIONS_DATA_INDEX = 'actions';

export interface IChannelData {
  channelId: string;
  chatId: string;
  name: string;
  activeOn?: string;
}

export const getContactChannels = (contact: InboxContactModel, i18n: i18n): IChannelData[] =>
  sortArrayByField(contact.activities, 'anyOn', 'desc').map((a) => {
    return {
      channelId: a.channel.channelId,
      name: a.channel.displayName,
      activeOn: a.anyOn ? formatDateTimeAdaptive(a.anyOn, i18n.language, 'long') : '—',
      chatId: a.chatId,
    };
  });

export interface IIbContactChannelListProps {
  channels: IChannelData[];
  onSendMessageToChat?: (chatId: string) => void;
}

const IbContactChannelList: React.FC<IIbContactChannelListProps> = ({ channels, onSendMessageToChat }) => {
  const { t } = useTranslation();

  const onSendMessageClick = (chatId: string) => () => onSendMessageToChat?.(chatId);

  const columns: ColumnType<IChannelData>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      render: (_, record) => (
        <div className={NAME_CELL_CLASS_NAME}>
          <IbSocial social={record.channelId as IbSocialType} />
          <IbTypography.Paragraph type="secondary">{record.name}</IbTypography.Paragraph>
        </div>
      ),
    },
    {
      render: (_, record) => (
        <IbTypography.Paragraph disabled type="secondary">
          {record.activeOn}
        </IbTypography.Paragraph>
      ),
    },
    {
      className: ACTIONS_CELL_CLASS_NAME,
      render: (_, record) => (
        <IbButton icon={<IbIcon iconName="comment" />} type="icon" onClick={onSendMessageClick(record.chatId)} />
      ),
      dataIndex: ACTIONS_DATA_INDEX,
    },
  ];

  const renderChannelListItem = (item: unknown) => {
    const channel = item as IChannelData;
    return (
      <li className={CHANNEL_LIST_ITEM_CLASS_NAME}>
        <IbSocial social={channel.channelId as IbSocialType} />
        <div className={CHANNEL_LIST_ITEM_INFO_CLASS_NAME}>
          <IbTypography.Paragraph className={CHANNEL_LIST_ITEM_TITLE_CLASS_NAME} type="secondary">
            {channel.name}
          </IbTypography.Paragraph>
          {!!channel.activeOn && (
            <IbTypography.Paragraph disabled type="secondary">
              {channel.activeOn}
            </IbTypography.Paragraph>
          )}
        </div>
        <IbButton icon={<IbIcon iconName="comment" />} type="link" onClick={onSendMessageClick(channel.chatId)}>
          {t('Send message')}
        </IbButton>
      </li>
    );
  };

  const renderChannelList = () => {
    return (
      <>
        <IbTable
          className={CHANNELS_TABLE_CLASS_NAME}
          columns={columns}
          dataSource={channels}
          emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
        />
        <IbList className={CHANNEL_LIST_CLASS_NAME} dataSource={channels} renderItem={renderChannelListItem} />
      </>
    );
  };

  const renderEmptyList = () => {
    return (
      <>
        <IbDivider />
        <IbTypography.Paragraph disabled type="secondary">
          <Trans i18nKey="Channels empty">
            The list is empty. Send the client a link to the connected <Link to="/inbox/channels">channel</Link>.
          </Trans>
        </IbTypography.Paragraph>
      </>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbTypography className={HEADER_CLASS_NAME}>
        <IbTypography.Paragraph>{t('Activity')}</IbTypography.Paragraph>
      </IbTypography>
      {channels.length ? renderChannelList() : renderEmptyList()}
    </div>
  );
};

export default IbContactChannelList;
