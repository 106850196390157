import React, { useState } from 'react';
import { Button, Form, Input, Space, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { useSetRecoilState } from 'recoil';

import { AgentModel, BotVersionCreationRequest } from '../../../api';
import { botVersionApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const { TextArea } = Input;

export interface IBotVersionImportProps {
  agent: AgentModel;
  onImport: () => void;
}

const BotVersionImport: React.FC<IBotVersionImportProps> = ({ agent, onImport = () => {} }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [botZip, setBotZip] = useState<string | null>(null);
  const [botZipList, setBotZipList] = useState<RcFile[]>([]);

  const onFinish = async () => {
    setLoading(true);

    const newBotVersion: BotVersionCreationRequest = {
      agentId: agent.id,
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description') || '',
      file: botZip,
    };

    try {
      await botVersionApi.createBotVersion(newBotVersion);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлена новая версия бота',
        description: newBotVersion.name || '',
      });
      setBotZip(null);
      setBotZipList([]);
      form.setFieldsValue({
        name: '',
        description: '',
      });
      onImport();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при импорте версии бота',
        error: e,
      });
    }

    setLoading(false);
  };

  const beforeUpload = (file: RcFile) => {
    setBotZipList([file]);
    const reader = new FileReader();
    reader.onload = (e) => {
      e.target?.result && setBotZip(Buffer.from(e.target.result).toString('base64'));
    };
    reader.readAsArrayBuffer(file);

    return false;
  };

  const onRemove = () => {
    setBotZipList([]);
    setBotZip(null);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название' }]}>
        <Input placeholder="Название версии" />
      </Form.Item>
      <Form.Item label="Описание" name="description">
        <TextArea placeholder="Краткое описание версии" rows={4} />
      </Form.Item>
      <Form.Item label="Шаблон бота" name="botZip">
        <Upload accept=".zip" beforeUpload={beforeUpload} fileList={botZipList} name="botZip" onRemove={onRemove}>
          <Button icon={<UploadOutlined />}>Выберите шаблон бота</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button disabled={loading || !botZip} htmlType="submit" type="primary">
            Импорт
          </Button>
          {loading && <Spin />}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default BotVersionImport;
