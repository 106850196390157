import React, { MutableRefObject, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { Flags } from 'react-feature-flags';

import IbTypography from '../../components/common/IbTypography';
import IbButton from '../../components/common/IbButton';
import IbIcon from '../../components/common/IbIcon';
import { OperatorsEmptyLogo } from '../../assets';
import IbTable, { MOBILE_MAIN_CELL_CLASS_NAME } from '../../components/common/IbTable';
import {
  InboxParticipantInvitationRequest,
  InboxParticipantModel,
  InboxParticipantStatus,
  InboxParticipantUpdatingRequest,
  UserStatus,
} from '../../../../api';
import {
  currentOperatorSelector,
  inboxAlertsSelectorAdd,
  operatorGroupListFilterSelector,
  operatorGroupListSelector,
  operatorsPageSearchSelector,
  participantItemSelector,
  participantItemsSelector,
  participantRangeSelector,
} from '../../recoil';
import { FeatureFlagNames, OPERATOR_GROUP_TAG_STYLE, SUBJECT_ROLES } from '../../../constants';
import { InboxParticipantFilter } from '../../storages/participants';
import IbAvatar from '../../components/common/IbAvatar';
import { formatPhoneNumber, formatRoleNames } from '../../../utils/stringUtil';
import IbUserStatus, { IbUserStatusType } from '../../components/common/IbUserStatus';
import IbTag from '../../components/common/IbTag';
import IbOperatorEditModal from '../../components/IbOperatorEditModal';
import { inboxParticipantApi, userAuthApi } from '../../../apis';
import IbContextMenu, { IIbContextMenuItem } from '../../components/common/IbContextMenu';
import IbSpin from '../../components/common/IbSpin';
import { useQuery } from '../../../utils/urlUtil';

import {
  ACTIONS_CLASS_NAME,
  ACTIONS_DATA_INDEX,
  CONTENT_CLASS_NAME,
  EMPTY_CONTENT_CLASS_NAME,
  EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME,
  EXPANDED_ROW_ACTIONS_CLASS_NAME,
  GROUPS_CLASS_NAME,
  NAME_CLASS_NAME,
  PHONE_NUMBER_CLASS_NAME,
  STATUS_CLASS_NAME,
  STATUS_OFFLINE_CLASS_NAME,
  STATUS_ONLINE_CLASS_NAME,
  TABLE_CLASS_NAME,
} from './const';

export interface IOperatorsTabRef {
  showAddModal: () => void;
}

interface IOperatorsTabProps {
  tabRef: MutableRefObject<IOperatorsTabRef | undefined>;
  operatorListIsEmpty: boolean;
  operatorListIsLoading: boolean;
}

const SELECTED_OPERATOR_ID_PARAM = 'id';

const OperatorsTab: React.FC<IOperatorsTabProps> = ({ tabRef, operatorListIsEmpty, operatorListIsLoading }) => {
  const { t } = useTranslation();
  const query = useQuery();
  const { replace } = useHistory();

  const selectedOperatorId = query.get(SELECTED_OPERATOR_ID_PARAM);
  const selectedOperator = useRecoilValue(participantItemSelector(selectedOperatorId ?? ''));

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editOperator, setEditOperator] = useState<InboxParticipantModel>();
  const [contextMenuOperator, setContextMenuOperator] = useState<InboxParticipantModel>();
  const [operatorProcessing, setOperatorProcessing] = useState<InboxParticipantModel>();

  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);
  const currentOperator = useRecoilValue(currentOperatorSelector);

  const [operatorsPageSearch, setOperatorsPageSearch] = useRecoilState(operatorsPageSearchSelector);
  const searchFilter = new InboxParticipantFilter(operatorsPageSearch, undefined, [SUBJECT_ROLES.operator]);
  // NOTE: в participantRangeSelector происходит начальная загрузка данных
  useRecoilValue(participantRangeSelector(searchFilter));
  const operatorList = useRecoilValue(participantItemsSelector(searchFilter));

  const setGroupListFilter = useSetRecoilState(operatorGroupListFilterSelector);
  const groupList = useRecoilValue(operatorGroupListSelector);

  const closeEditModal = () => {
    setEditModalVisible(false);
    setEditOperator(undefined);

    replace({});
  };

  const openEditModal = (operator: InboxParticipantModel) => {
    setEditOperator(operator);
    setEditModalVisible(true);

    replace({
      search: `?${SELECTED_OPERATOR_ID_PARAM}=${operator.id}`,
    });
  };

  const closeOperatorContextMenu = () => setContextMenuOperator(undefined);

  const getActivityLabel = (operator: InboxParticipantModel) => {
    switch (operator.subject.person.status) {
      case UserStatus.Active:
        return t('Active');
      case UserStatus.Blocked:
        return t('Blocked');
      case UserStatus.Invited:
        return t('Invited');
      default:
        return '';
    }
  };

  const resetFilterPage = () => setOperatorsPageSearch('');

  const onEditModalCancel = () => closeEditModal();

  const onTableRowClick = (operator: InboxParticipantModel, column: ColumnType<InboxParticipantModel>) => {
    if (column.dataIndex === ACTIONS_DATA_INDEX) {
      return;
    }

    openEditModal(operator);
  };

  const onEditButtonClick = (operator: InboxParticipantModel) => () => openEditModal(operator);

  const onOperatorInvite = async (
    request: InboxParticipantInvitationRequest
  ): Promise<InboxParticipantModel | Error> => {
    try {
      const inviteResponse = await inboxParticipantApi.inviteInboxParticipant(request);

      if (inviteResponse.data.subject.person.invitationResult?.success == false) {
        addAlert({
          type: 'error',
          content: t(
            'The operator has been added, but there was an error sending an invitation by email. Try sending later.'
          ),
        });
      } else {
        addAlert({
          type: 'success',
          content: t('Operator invited successfully.'),
        });
      }

      resetFilterPage();
      setGroupListFilter({});

      return inviteResponse.data;
    } catch (e) {
      return e as Error;
    }
  };

  const onOperatorInvitationResend = (operator: InboxParticipantModel) => async () => {
    setOperatorProcessing(operator);
    try {
      const result = await userAuthApi.reInviteUser(operator.subject.id);
      if (result.data.success) {
        addAlert({
          content: t('Invitation sent successfully.'),
          type: 'success',
        });
      } else {
        addAlert({
          content: t('Error sending invitation by email. Try sending later.'),
          type: 'error',
        });
      }
    } catch (e) {
      // empty
    }
    setOperatorProcessing(undefined);
  };

  const onOperatorEdit = async (request: InboxParticipantUpdatingRequest) => {
    if (!editOperator) {
      return;
    }

    try {
      await inboxParticipantApi.updateInboxParticipant(editOperator.id, request);
      setGroupListFilter({});
    } catch (e) {
      // empty
    }
  };

  const onOperatorBlock = async () => {
    if (!editOperator) {
      return false;
    }

    try {
      if (editOperator.subject.person.status === UserStatus.Blocked) {
        await userAuthApi.unlockUser(editOperator.subject.id);
      } else {
        await userAuthApi.blockUser(editOperator.subject.id);
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  const onAddButtonClick = () => setEditModalVisible(true);

  const onRefPropChange = () => {
    if (tabRef) {
      tabRef.current = {
        showAddModal: () => setEditModalVisible(true),
      };
    }
  };
  useEffect(onRefPropChange, [tabRef]);

  const initSelectedOperatorAsync = async () => {
    if (!selectedOperator) return;
    setEditOperator(selectedOperator.entity);
    setEditModalVisible(true);
  };

  const initSelectedOperator = () => {
    initSelectedOperatorAsync().finally();
  };
  useEffect(initSelectedOperator, [selectedOperator]);

  const columns: ColumnType<InboxParticipantModel>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      title: t('Full name'),
      render: (_, record) => (
        <div className={NAME_CLASS_NAME}>
          <IbAvatar
            metadata={{ uid: record.subject.id, text: record.subject.person.name.fullName || '' }}
            size="x-small"
          />
          <IbTypography.Paragraph type="secondary">{record.subject.person.name.fullName}</IbTypography.Paragraph>
        </div>
      ),
    },
    {
      title: t('Phone number'),
      render: (_, record) => (
        <IbTypography.Paragraph className={PHONE_NUMBER_CLASS_NAME} type="secondary">
          {formatPhoneNumber(record.subject.person.contacts.primaryTel)}
        </IbTypography.Paragraph>
      ),
    },
    {
      title: t('E-mail'),
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">{record.subject.person.contacts.email}</IbTypography.Paragraph>
      ),
    },
    {
      title: t('Status'),
      render: (_, record) => (
        <IbTypography.Paragraph className={STATUS_CLASS_NAME} type="secondary">
          <IbUserStatus status={record.status.toLowerCase() as IbUserStatusType} />
          {record.status === InboxParticipantStatus.Online ? (
            <span className={STATUS_ONLINE_CLASS_NAME}>{t('Online')}</span>
          ) : (
            <span className={STATUS_OFFLINE_CLASS_NAME}>{t('Offline')}</span>
          )}
        </IbTypography.Paragraph>
      ),
    },
    {
      title: t('Activity'),
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">{getActivityLabel(record)}</IbTypography.Paragraph>
      ),
    },
    {
      title: t('Roles'),
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">
          {formatRoleNames(record.subject.person.roles.map((r) => r.name) || [])}
        </IbTypography.Paragraph>
      ),
    },
    {
      title: t('Groups'),
      render: (_, record) => (
        <div className={GROUPS_CLASS_NAME}>
          {record.operatorGroups.length
            ? record.operatorGroups.map((g) => <IbTag key={g.id} content={g.name} style={OPERATOR_GROUP_TAG_STYLE} />)
            : '—'}
        </div>
      ),
    },
    {
      className: ACTIONS_CLASS_NAME,
      title: '',
      render: (_, record) => {
        if (record.id === operatorProcessing?.id) {
          return <IbSpin />;
        }

        const onOpenMenu = () => setContextMenuOperator(record);

        const menuItems: IIbContextMenuItem[] = [
          {
            icon: <IbIcon iconName="send" />,
            text: t('Resend invitation'),
            onSelect: onOperatorInvitationResend(record),
            disabled: record.subject.person.status != UserStatus.Invited,
          },
        ];

        return (
          <IbContextMenu
            menuItems={menuItems}
            visible={contextMenuOperator?.id == record.id}
            onClose={closeOperatorContextMenu}
          >
            <IbButton icon={<IbIcon iconName="more-one" />} type="icon" onClick={onOpenMenu}></IbButton>
          </IbContextMenu>
        );
      },
      dataIndex: ACTIONS_DATA_INDEX,
    },
  ];

  const expandableForMobile: ExpandableConfig<InboxParticipantModel> = {
    expandedRowRender: (record) => {
      return (
        <>
          <ul>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Phone number')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatPhoneNumber(record.subject.person.contacts.primaryTel)}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('E-mail')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">{record.subject.person.contacts.email}</IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Status')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph className={STATUS_CLASS_NAME} type="secondary">
                <IbUserStatus status={record.status.toLowerCase() as IbUserStatusType} />
                {record.status === InboxParticipantStatus.Online ? (
                  <span className={STATUS_ONLINE_CLASS_NAME}>{t('Online')}</span>
                ) : (
                  <span className={STATUS_OFFLINE_CLASS_NAME}>{t('Offline')}</span>
                )}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Activity')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph className={STATUS_CLASS_NAME} type="secondary">
                {getActivityLabel(record)}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Roles')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph className={STATUS_CLASS_NAME} type="secondary">
                {formatRoleNames(record.subject.person.roles.map((r) => r.name) || [])}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Groups')}
              </IbTypography.Paragraph>
              <div className={GROUPS_CLASS_NAME}>
                {record.operatorGroups.length
                  ? record.operatorGroups.map((g) => (
                      <IbTag key={g.id} content={g.name} style={OPERATOR_GROUP_TAG_STYLE} />
                    ))
                  : '—'}
              </div>
            </li>
          </ul>
          <Flags authorizedFlags={[FeatureFlagNames.ADMIN]}>
            <div className={EXPANDED_ROW_ACTIONS_CLASS_NAME}>
              <IbButton icon={<IbIcon iconName="edit" />} type="link" onClick={onEditButtonClick(record)}>
                {t('Edit')}
              </IbButton>
              {record.subject.person.status == UserStatus.Invited && (
                <IbButton
                  disabled={record.id === operatorProcessing?.id}
                  icon={<IbIcon iconName="send" />}
                  type="link"
                  onClick={onOperatorInvitationResend(record)}
                >
                  {t('Resend invitation')}
                </IbButton>
              )}
            </div>
          </Flags>
        </>
      );
    },
  };

  const renderEditModal = (readonly: boolean) => (
    <IbOperatorEditModal
      allowEditGroups
      allowEditRoles
      allowBlock={currentOperator?.entity.subject.id !== editOperator?.subject.id}
      editOperator={editOperator}
      groupList={groupList}
      readonly={readonly}
      visible={editModalVisible}
      onBlock={onOperatorBlock}
      onCancel={onEditModalCancel}
      onEdit={onOperatorEdit}
      onInvite={onOperatorInvite}
    />
  );

  return (
    <>
      <div className={`${CONTENT_CLASS_NAME} ${operatorListIsEmpty ? EMPTY_CONTENT_CLASS_NAME : ''}`}>
        {operatorListIsEmpty ? (
          <div className={EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME}>
            <OperatorsEmptyLogo />
            <IbTypography>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Invite new users to ELMA Bot')}
                <br />
                {t('to process incoming messages')}
              </IbTypography.Paragraph>
            </IbTypography>
            <IbButton icon={<IbIcon iconName="add-one" />} onClick={onAddButtonClick}>
              {t('Invite')}
            </IbButton>
          </div>
        ) : (
          <IbTable
            className={TABLE_CLASS_NAME}
            columns={columns}
            dataSource={operatorList.map((o) => o.entity)}
            emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
            expandableForMobile={expandableForMobile}
            loading={operatorListIsLoading && !operatorList.length}
            onRowClick={onTableRowClick}
          />
        )}
      </div>
      <Flags
        authorizedFlags={[FeatureFlagNames.ADMIN]}
        renderOff={() => renderEditModal(true)}
        renderOn={() => renderEditModal(false)}
      />
    </>
  );
};

export default OperatorsTab;
