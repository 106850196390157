import { ReactNode } from 'react';

import { AgentStageAccountStatus, BotSortDirection, InboxMessageParticipantModel } from '../../../api';

export interface IInboxAlert {
  id?: string;
  type: 'success' | 'error' | 'custom';
  content: ReactNode;
  icon?: ReactNode;
}

export enum NetworkConnectionStatus {
  On = 'On',
  Off = 'Off',
}

export interface INetworkConnection {
  status: NetworkConnectionStatus;
  result?: unknown;
  error?: Error;
}

export interface PersonFilterParams {
  search?: string;
  tagIds?: string[];
  pageIndex: number;
  loadMore: boolean;
}

export interface BotFilterParams {
  search?: string;
  sort: BotSortDirection;
  pageIndex: number;
  loadMore: boolean;
}

export interface InboxChannelFilterParams {
  search?: string;
  status?: AgentStageAccountStatus;
  channelId?: string;
  operatorGroupId?: string;
  pageIndex: number;
  loadMore: boolean;
}

export interface ICarouselItem {
  index: number;
  url: string;
}

export interface IPreviewModalParams {
  isOpen: boolean;
  items: ICarouselItem[];
  selectedIndex?: number;
  senderParticipant?: InboxMessageParticipantModel;
  timestamp?: string;
  channelId?: string;
  onClose: () => void;
  onReply: () => void;
}
