import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Col, Row, Select } from 'antd';
import { RcFile } from 'antd/es/upload';
import { SelectValue } from 'antd/es/select';

import {
  KnowledgeData,
  KnowledgeDataType,
  KnowledgeSourceModel,
  KnowledgeSourceValidationRequest,
  KnowledgeSourceValidationResponse,
  SingleKnowledgeBaseModel,
  StageModel,
} from '../../../../../kb-api';
import { AlertTypes, ALLOWED_KNOWLEDGEBASE_FILE_TYPES, FileExtensions } from '../../../../constants';
import { knowledgeBaseKbApi, knowledgeSourceKbApi, versionKbApi } from '../../../../apis';
import { getGoogleDocType } from '../../../../utils/urlUtil';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbButton from '../../../components/common/SbButton';
import SbModal from '../../../components/common/SbModal';
import SbPanel from '../../../components/common/SbPanel';
import SbTypography from '../../../components/common/SbTypography';
import { ExcelIcon, GoogleDocIcon, GoogleSheetIcon, WordIcon } from '../../../assets';
import SbTabs from '../../../components/common/SbTabs';
import SbTabPane from '../../../components/common/SbTabPane';
import SbUpload from '../../../components/common/SbUpload';
import SbInput from '../../../components/common/SbInput';
import { fileExtensionToBinarySourceFormat, getFileExtension } from '../../../../utils/fileUtil';
import SbSelect from '../../../components/common/SbSelect';
import { getKnowledgeSourceIcon } from '../../../utils/render';
import { getKnowledgeSources } from '../../../utils/knowledgeBase';

enum TabPaneKeys {
  ADD = 'ADD',
  REPLACE = 'REPLACE',
}

interface IKnowledgeSourceImportModalProps {
  knowledgeBase: SingleKnowledgeBaseModel;
  visible: boolean;
  getDraftStage: () => Promise<StageModel | undefined>;
  onKnowledgeSourceUploaded: (knowledgeSource: KnowledgeSourceModel) => void;
  onCancel: () => void;
}

const KnowledgeSourceImportModal: React.FC<IKnowledgeSourceImportModalProps> = ({
  knowledgeBase,
  visible,
  getDraftStage,
  onKnowledgeSourceUploaded,
  onCancel,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [uploading, setUploading] = useState(false);
  const [currentTabPane, setCurrentTabPane] = useState(TabPaneKeys.ADD);
  const [url, setUrl] = useState('');
  const [selectedKnowledgeSource, setSelectedKnowledgeSource] = useState<KnowledgeSourceModel>();
  const [validationErrorMessage, setValidationErrorMessage] = useState<string>();

  const knowledgeSources = getKnowledgeSources(knowledgeBase);

  const onVisiblePropChange = () => {
    if (visible) {
      setUploading(false);
      setCurrentTabPane(TabPaneKeys.ADD);
      setUrl('');
      setValidationErrorMessage(undefined);
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onKnowledgeSourcesPropChange = () => {
    setSelectedKnowledgeSource(knowledgeSources.length ? knowledgeSources[0] : undefined);
  };
  useEffect(onKnowledgeSourcesPropChange, [knowledgeSources]);

  const onTabChange = (activeKey: string) => setCurrentTabPane(activeKey as TabPaneKeys);

  const onUrlChange = (value: string) => {
    setValidationErrorMessage(undefined);
    setUrl(value);
  };

  const validateKnowledgeData = async (knowledgeData: KnowledgeData): Promise<KnowledgeSourceValidationResponse> => {
    try {
      const validationRequest = {} as KnowledgeSourceValidationRequest;
      switch (knowledgeData.type) {
        case KnowledgeDataType.Binary:
          validationRequest.binaryData = knowledgeData.binaryData;
          break;
        case KnowledgeDataType.GoogleDocUrl:
          validationRequest.googleDocUrl = knowledgeData.url;
          break;
        case KnowledgeDataType.GoogleSpreadSheetUrl:
          validationRequest.googleSpreadSheetUrl = knowledgeData.url;
          break;
        default:
          break;
      }
      const response = await knowledgeSourceKbApi.validateKnowledgeSource(validationRequest);
      return response.data;
    } catch (e) {
      return {
        isValid: false,
        message: 'Не удалось проверить источник базы знаний',
      } as KnowledgeSourceValidationResponse;
    }
  };

  const addKnowledgeData = async (versionId: string, knowledgeData: KnowledgeData) => {
    try {
      const { data: knowledgeSource } = await versionKbApi.addKnowledgeSource(versionId, {
        knowledgeData,
      });
      onKnowledgeSourceUploaded(knowledgeSource);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении документа в базу знаний',
        error: e,
      });
    }
  };

  const getReplaceableKnowledgeSourceId = async (versionId: string) => {
    if (knowledgeBase.draftStage?.versionId === versionId) {
      return selectedKnowledgeSource?.id;
    }

    const knowledgeBaseResponse = await knowledgeBaseKbApi.getKnowledgeBase(knowledgeBase.entry.id);
    const { draftKnowledgeSources } = knowledgeBaseResponse.data;

    return draftKnowledgeSources.find((e) => e.sourceKnowledgeSourceId === selectedKnowledgeSource?.id)?.id;
  };

  const replaceKnowledgeData = async (versionId: string, knowledgeData: KnowledgeData) => {
    try {
      const replaceableKnowledgeSourceId = await getReplaceableKnowledgeSourceId(versionId);

      if (!replaceableKnowledgeSourceId) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при замене документа в базе знаний',
        });
        return;
      }

      const { data: knowledgeSource } = await versionKbApi.replaceKnowledgeSource(versionId, {
        replaceableKnowledgeSourceId,
        knowledgeData,
      });
      onKnowledgeSourceUploaded(knowledgeSource);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при замене документа в базе знаний',
        error: e,
      });
    }
  };

  const uploadKnowledgeData = async (knowledgeData: KnowledgeData) => {
    setUploading(true);

    const validationResult = await validateKnowledgeData(knowledgeData);
    if (!validationResult.isValid) {
      setValidationErrorMessage(validationResult.message || '');
      setUploading(false);
      return;
    }

    const draftStage = await getDraftStage();
    if (!draftStage) {
      setUploading(false);
      return;
    }

    if (currentTabPane === TabPaneKeys.ADD) {
      await addKnowledgeData(draftStage.versionId, knowledgeData);
    } else if (currentTabPane === TabPaneKeys.REPLACE) {
      await replaceKnowledgeData(draftStage.versionId, knowledgeData);
    }

    setUploading(false);
  };

  const onNextButtonClick = () => {
    if (!url) return;

    const type = getGoogleDocType(url);
    if (!type) {
      setValidationErrorMessage('Некорректный адрес Google документа');
      return;
    }

    uploadKnowledgeData({
      type: getGoogleDocType(url),
      url,
    }).finally();
  };

  const onFileUpload = (file: RcFile, base64Content: string) => {
    setValidationErrorMessage(undefined);
    uploadKnowledgeData({
      type: KnowledgeDataType.Binary,
      binaryData: {
        data: base64Content,
        format: fileExtensionToBinarySourceFormat(getFileExtension(file.name) as FileExtensions),
        name: file.name,
      },
    }).finally();
  };

  const onKnowledgeSourceChange = (value: SelectValue) =>
    setSelectedKnowledgeSource(knowledgeSources.find((e) => e.id === (value as string)));

  const onModalCancel = () => !uploading && onCancel();

  const renderValidationErrorMessage = () => (
    <p className="sb-knowledge-base-card__import-modal__validation-error">{validationErrorMessage}</p>
  );

  const renderForm = (withKnowledgeSourceSelector = false) => {
    return (
      <SbTypography>
        {withKnowledgeSourceSelector && (
          <>
            <small>Выберите источник, который хотите заменить</small>
            <SbSelect
              optionLabelProp="label"
              sbSize="small"
              sbType="light"
              value={selectedKnowledgeSource?.id}
              onChange={onKnowledgeSourceChange}
            >
              {knowledgeSources.map((knowledgeSource) => {
                const content = (
                  <div className="sb-select__option">
                    {getKnowledgeSourceIcon(knowledgeSource)}
                    {knowledgeSource.name}
                  </div>
                );
                return (
                  <Select.Option key={knowledgeSource.id} label={content} value={knowledgeSource.id}>
                    {content}
                  </Select.Option>
                );
              })}
            </SbSelect>
          </>
        )}
        <small>Загрузите файл с компьютера</small>
        <SbUpload accept={ALLOWED_KNOWLEDGEBASE_FILE_TYPES.join(',')} onFileUpload={onFileUpload} />
        {!url && renderValidationErrorMessage()}
        <small>или укажите ссылку на Google Documents</small>
        <SbInput sbSize="small" value={url} onChange={onUrlChange} />
        {url && renderValidationErrorMessage()}
      </SbTypography>
    );
  };

  return (
    <SbModal
      destroyOnClose
      className="sb-knowledge-base-card__import-modal"
      footer={[
        <SbButton key="next" disabled={uploading} sbSize="medium" onClick={onNextButtonClick}>
          Далее
        </SbButton>,
        <SbButton key="cancel" disabled={uploading} sbSize="medium" sbType="secondary" onClick={onModalCancel}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Импорт документов в базу знаний"
      visible={visible}
      width={569}
      onCancel={onModalCancel}
      onOk={() => {}}
    >
      <SbPanel sbType="help">
        <SbTypography>
          <h3>
            Загрузите файлы с вопросами и ответами и получите бота,
            <br /> который умеет автоматически давать ответы на заданные
            <br /> пользователями вопросы.
          </h3>
          <Row>
            <Col className="sb-knowledge-base-card__import-modal__examples" span={14}>
              <h4>Что можно загрузить:</h4>
              <ul>
                <li>Таблицы: Вопрос - Ответ</li>
                <li>Заголовки и параграфы</li>
              </ul>
              <h4>Что можно загрузить:</h4>
              <SbButton href={`${process.env.PUBLIC_URL}/files/С_уточнением.docx`} sbSize="medium" sbType="tertiary">
                С уточнением.docx
              </SbButton>
              <SbButton href={`${process.env.PUBLIC_URL}/files/Вопросы.xlsx`} sbSize="medium" sbType="tertiary">
                Вопросы.xlsx
              </SbButton>
            </Col>
            <Col className="sb-knowledge-base-card__import-modal__formats" span={10}>
              <p>Поддерживаемые форматы:</p>
              <p>
                <WordIcon /> Word
              </p>
              <p>
                <ExcelIcon /> Excel
              </p>
              <p>
                <GoogleDocIcon /> Google Docs
              </p>
              <p>
                <GoogleSheetIcon /> Google Sheets
              </p>
            </Col>
          </Row>
        </SbTypography>
      </SbPanel>
      <div className="sb-knowledge-base-card__import-modal__source-selector">
        <SbTabs activeKey={currentTabPane} onChange={onTabChange}>
          <SbTabPane key={TabPaneKeys.ADD} tab="Добавить источник">
            {renderForm()}
          </SbTabPane>
          <SbTabPane key={TabPaneKeys.REPLACE} disabled={!knowledgeSources.length} tab="Заменить источник">
            {renderForm(true)}
          </SbTabPane>
        </SbTabs>
      </div>
    </SbModal>
  );
};

export default KnowledgeSourceImportModal;
