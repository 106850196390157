import React, { useEffect, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState } from 'recoil';
import { Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { SelectValue } from 'antd/lib/select';

import { MergeInboxContactsSchema, VariableSchema, ContactIdentifierKind } from '../../../../../../api';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbScroll from '../../../../../simple-bot/components/common/SbScroll';
import { currentScenarioStructureSelector, variableUsagesSelector } from '../../../../../recoil/scenarioStructure';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbSwitch from '../../../../../simple-bot/components/common/SbSwitch';

import VariableSelectorField from './VariableSelectorField';

interface IMergeInboxContactsSettingsModalProps {
  action: MergeInboxContactsSchema;
  visible: boolean;
  onChange: (action: MergeInboxContactsSchema) => void;
  onClose: () => void;
}

const MODAL_WIDTH = 650;

const MergeInboxContactsSettingsModal: React.FC<IMergeInboxContactsSettingsModalProps> = ({
  action,
  visible,
  onChange,
  onClose,
}) => {
  const [identifierVariableId, setIdentifierVariableId] = useState(action.identifierVariableId);
  const [contactIdentifierKind, setContactIdentifierKind] = useState(action.contactIdentifierKind);

  const [resultVariableId, setResultVariableId] = useState(action.resultVariableId);
  const [mergeFields, setMergeFields] = useState(action.mergeFields);

  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const variables = scenarioStructure?.variables || [];

  const resultVariable = variables.find((v) => v.id === resultVariableId);
  const identifierVariable = variables.find((v) => v.id === identifierVariableId);

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const onSaveSettings = () => {
    onClose();

    if (
      identifierVariableId == action.identifierVariableId &&
      contactIdentifierKind == action.contactIdentifierKind &&
      resultVariableId == action.resultVariableId &&
      mergeFields == action.mergeFields
    ) {
      return;
    }

    onChange({
      ...action,
      identifierVariableId,
      contactIdentifierKind,
      resultVariableId,
      mergeFields,
    });
  };

  const onActionChange = () => {
    if (!visible) return;

    setIdentifierVariableId(action.identifierVariableId);
    setContactIdentifierKind(action.contactIdentifierKind);
    setResultVariableId(action.resultVariableId);
    setMergeFields(action.mergeFields);
  };
  useEffect(onActionChange, [action, visible]);

  const onContactIdentifierKindChange = (value: SelectValue) => {
    setContactIdentifierKind(value as ContactIdentifierKind);
  };

  const onMergeFieldsChange = () => {
    setMergeFields(!mergeFields);
  };

  const onVariablesChange = (newVariables: VariableSchema[]) => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.variables = newVariables;
    setScenarioStructure(newScenarioStructure);
  };

  return (
    <SbModal
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveSettings}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройки"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onClose}
      onOk={onSaveSettings}
    >
      <SbScroll>
        <SbTypography>
          <h3>Поле для поиска контакта</h3>
          <SbSelect sbSize="big" sbType="light" value={contactIdentifierKind} onChange={onContactIdentifierKindChange}>
            <Select.Option key={ContactIdentifierKind.Phone} value={ContactIdentifierKind.Phone}>
              Номер телефона
            </Select.Option>
            <Select.Option key={ContactIdentifierKind.Email} value={ContactIdentifierKind.Email}>
              Email
            </Select.Option>
          </SbSelect>
          <h3>Значение для поиска контакта</h3>
          <VariableSelectorField
            getVariableUsages={getVariableUsages}
            variable={identifierVariable}
            variables={variables}
            onVariableIdChange={setIdentifierVariableId}
            onVariablesChange={onVariablesChange}
          />
          <h3>Результат слияния контактов</h3>
          <VariableSelectorField
            getVariableUsages={getVariableUsages}
            variable={resultVariable}
            variables={variables}
            onVariableIdChange={setResultVariableId}
            onVariablesChange={onVariablesChange}
          />
          <h3>Сохранять значения полей</h3>
          <SbSwitch checked={mergeFields} onChange={onMergeFieldsChange} />
        </SbTypography>
      </SbScroll>
    </SbModal>
  );
};

export default MergeInboxContactsSettingsModal;
