import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';
import { Col, Row } from 'antd';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import './index.less';

import {
  BotContentFormat,
  BotEditionReferenceModel,
  BotStageModel,
  BotStageType,
  SingleBotModel,
} from '../../../../api';
import SbModal from '../common/SbModal';
import { botApi, botEditionApi, botStageApi } from '../../../apis';
import { AlertTypes, BOT_EDITION_EXPORT_FINISHED } from '../../../constants';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import SbSpin from '../common/SbSpin';
import SbPanel from '../common/SbPanel';
import SbProgressStatusModal, { SbProgressStatusModalStatus } from '../SbProgressStatusModal';
import SbTypography from '../common/SbTypography';
import SbTabs from '../common/SbTabs';
import SbTabPane from '../common/SbTabPane';
import { getIgnoreCaseStringComparer } from '../../../utils/stringUtil';
import SbScroll from '../common/SbScroll';
import SbButton from '../common/SbButton';
import SbIcon from '../common/SbIcon';
import { currentBotStageTypeSelector } from '../../recoil';
import { hubConnections } from '../../../utils/socketsUtil';
import { getErrorMessage } from '../../../utils/errorUtils';

const MODAL_WIDTH = 866;
const MODAL_CLASS_NAME = 'sb-bot-versions-modal';
const HISTORY_CLASS_NAME = `${MODAL_CLASS_NAME}__history`;
const CONTROLS_CLASS_NAME = `${MODAL_CLASS_NAME}__controls`;
const HISTORY_GROUP_CLASS_NAME = `${HISTORY_CLASS_NAME}__group`;
const HISTORY_GROUP_ITEM_CLASS_NAME = `${HISTORY_GROUP_CLASS_NAME}__item`;
const HISTORY_GROUP_ITEM_SELECTED_CLASS_NAME = `${HISTORY_GROUP_ITEM_CLASS_NAME}_selected`;
const GROUP_ITEM_TIME_CLASS_NAME = `${HISTORY_GROUP_ITEM_CLASS_NAME}__time`;
const GROUP_ITEM_DESCRIPTION_CLASS_NAME = `${HISTORY_GROUP_ITEM_CLASS_NAME}__description`;
const GROUP_ITEM_USER_CLASS_NAME = `${HISTORY_GROUP_ITEM_CLASS_NAME}__user`;

enum TabPaneKeys {
  ORIGIN = 'ORIGIN',
  DRAFT = 'DRAFT',
}

enum AddBotEditionToDraftConfirmModalStates {
  HIDDEN = 'HIDDEN',
  SHOW_CONFIRM_MESSAGE = 'SHOW_CONFIRM_MESSAGE',
  IN_PROGRESS = 'IN_PROGRESS',
  SHOW_RESULT_MESSAGE = 'SHOW_RESULT_MESSAGE',
}

interface ISbBotVersionsModalProps {
  botId: string;
  visible: boolean;
  onClose: () => void;
  onDraftStageAdded: (draftStage: BotStageModel) => void;
}

interface IBotEditionExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

const botEditionExportDefaultValue: IBotEditionExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

const SbBotVersionsModal: React.FC<ISbBotVersionsModalProps> = ({ botId, visible, onClose, onDraftStageAdded }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const setCurrentBotStageType = useSetRecoilState(currentBotStageTypeSelector(botId));

  const [currentTabPane, setCurrentTabPane] = useState(TabPaneKeys.ORIGIN);
  const [loading, setLoading] = useState(false);
  const [bot, setBot] = useState<SingleBotModel>();
  const [selectedBotEditionReference, setSelectedBotEditionReference] = useState<BotEditionReferenceModel>();
  const [addBotEditionToDraftConfirmModalState, setAddBotEditionToDraftConfirmModalState] = useState(
    AddBotEditionToDraftConfirmModalStates.HIDDEN
  );
  const [botEditionExport, setBotEditionExport] = useState(botEditionExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const draftStageExist = !!bot?.draftStage;
  const addBotEditionToDraftConfirmModalInProgress =
    addBotEditionToDraftConfirmModalState === AddBotEditionToDraftConfirmModalStates.IN_PROGRESS;
  const addBotEditionToDraftConfirmModalShowResult =
    addBotEditionToDraftConfirmModalState === AddBotEditionToDraftConfirmModalStates.SHOW_RESULT_MESSAGE;

  const exportStatus = botEditionExport.preparing
    ? SbProgressStatusModalStatus.InProgress
    : botEditionExport.errorMessage
    ? SbProgressStatusModalStatus.Error
    : SbProgressStatusModalStatus.Success;
  const exportTitle = botEditionExport.preparing
    ? 'Выполняется экспорт бота'
    : botEditionExport.errorMessage
    ? 'Во время экспорта бота произошла ошибка'
    : 'Экспорт бота прошел успешно';

  const onTabChange = (activeKey: string) => {
    setCurrentTabPane(activeKey as TabPaneKeys);
    setSelectedBotEditionReference(undefined);
  };

  const onModalCancel = () => onClose();

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await botApi.getBot(botId);
      setBot(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
    setLoading(false);
  };

  const onVisiblePropChange = () => {
    if (!visible) return;

    setSelectedBotEditionReference(undefined);
    setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.HIDDEN);
    loadDataAsync().finally();
  };
  useEffect(onVisiblePropChange, [visible]);

  const onHistoryGroupItemClick = (botEditionReference: BotEditionReferenceModel) => () =>
    setSelectedBotEditionReference(botEditionReference);

  const onAddBotEditionToDraftButtonClick = async () => {
    if (!selectedBotEditionReference) return;

    setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.SHOW_CONFIRM_MESSAGE);
  };

  const onCancelAddBotEditionToDraftConfirmModal = () =>
    setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.HIDDEN);

  const onConfirmAddBotEditionToDraftConfirmModal = async () => {
    if (!selectedBotEditionReference || !bot) return;

    setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.IN_PROGRESS);
    try {
      let draftStage = bot.draftStage;
      if (!draftStage) {
        const draftCreationResponse = await botStageApi.createDraftBotStage(bot.originStage.id);
        draftStage = draftCreationResponse.data;
        onDraftStageAdded(draftStage);
      }

      const { botEditionId } = selectedBotEditionReference;
      await botStageApi.addBotEdition(draftStage.id, { botEditionId });

      const botResponse = await botApi.getBot(botId);
      setBot(botResponse.data);

      setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.SHOW_RESULT_MESSAGE);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при записи версии в черновик',
        error: e,
      });
      setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.SHOW_CONFIRM_MESSAGE);
    }
  };

  const onGoToDraftButtonClick = () => {
    setCurrentBotStageType(BotStageType.Draft);
    setAddBotEditionToDraftConfirmModalState(AddBotEditionToDraftConfirmModalStates.HIDDEN);
    const botLocation = `/simple-bots/${botId}`;
    if (location.pathname !== botLocation) {
      push(botLocation);
    }
  };

  const onExportButtonClick = async () => {
    if (!selectedBotEditionReference) return;

    setBotEditionExport({
      ...botEditionExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await botEditionApi.runBotEditionExport(
        selectedBotEditionReference.botEditionId,
        BotContentFormat.Json
      );

      setBotEditionExport({
        ...botEditionExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      setBotEditionExport({
        ...botEditionExportDefaultValue,
        errorMessage: getErrorMessage(e as Error),
      });
    }
  };

  const botEditionExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (botEditionExport.requestId !== args?.requestId || !botEditionExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setBotEditionExport({
      ...botEditionExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToBotEditionExportEvents = () => {
    if (!botEditionExport.requestId || !conn) return;
    conn.on(BOT_EDITION_EXPORT_FINISHED, botEditionExportEventHandler);
    return () => conn.off(BOT_EDITION_EXPORT_FINISHED, botEditionExportEventHandler);
  };
  useEffect(subscribeToBotEditionExportEvents, [conn, botEditionExport.requestId]);

  const closeExportBotModal = () => {
    setExportModalVisible(false);
    setBotEditionExport(botEditionExportDefaultValue);
  };

  const onExportModalClose = () => closeExportBotModal();

  const renderBotEditionExportModalContent = () => {
    switch (exportStatus) {
      case SbProgressStatusModalStatus.InProgress:
        return (
          <div>
            <div>Это может занять некоторое время.</div>
            <div>Пожалуйста, подождите</div>
          </div>
        );
      case SbProgressStatusModalStatus.Success:
        return <a href={botEditionExport.fileUrl}>Ссылка на скачивание</a>;
      case SbProgressStatusModalStatus.Error:
        return <div>{botEditionExport.errorMessage}</div>;
    }
  };

  const renderTabContent = (botStage?: BotStageModel) => {
    if (!botStage) return null;

    const groups = groupBy(botStage.editionHistory, (editionRef) => moment(editionRef.createdOn).format('YYYY-MM-DD'));
    const days = Object.keys(groups)
      .sort(getIgnoreCaseStringComparer((v) => v))
      .reverse();

    return (
      <SbScroll>
        <div className={HISTORY_CLASS_NAME}>
          {days.map((day) => {
            const dayHistory = groups[day]
              .sort(getIgnoreCaseStringComparer((v) => moment(v.createdOn).format('YYYY-MM-DD HH:mm:ss.SSS')))
              .reverse();
            return (
              <div key={day} className={HISTORY_GROUP_CLASS_NAME}>
                <h3>{moment(day).format('LL')}</h3>
                {dayHistory.map((g) => {
                  const classes = [HISTORY_GROUP_ITEM_CLASS_NAME];
                  if (g.botEditionId === selectedBotEditionReference?.botEditionId) {
                    classes.push(HISTORY_GROUP_ITEM_SELECTED_CLASS_NAME);
                  }

                  return (
                    <div key={g.botEditionId} className={classes.join(' ')} onClick={onHistoryGroupItemClick(g)}>
                      <div className={GROUP_ITEM_TIME_CLASS_NAME}>{moment(g.createdOn).format('HH:mm:ss')}</div>
                      <div className={GROUP_ITEM_DESCRIPTION_CLASS_NAME} title={g.description}>
                        {g.description}
                      </div>
                      <div className={GROUP_ITEM_USER_CLASS_NAME} title={g.createdBy.fullName}>
                        {g.createdBy.fullName}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </SbScroll>
    );
  };

  return (
    <>
      <SbModal
        destroyOnClose
        className={MODAL_CLASS_NAME}
        footer={[]}
        sbSize="small"
        title={`История версий${bot ? ` “${bot.entry.name}”` : ''}`}
        visible={visible}
        width={MODAL_WIDTH}
        onCancel={onModalCancel}
      >
        {loading ? (
          <SbSpin />
        ) : (
          <Row className="sb-modal__row-stretch" gutter={[12, 12]} wrap={false}>
            <Col className="sb-modal__col-main" span={15}>
              {draftStageExist ? (
                <SbTabs activeKey={currentTabPane} onChange={onTabChange}>
                  <SbTabPane key={TabPaneKeys.ORIGIN} tab="Основной">
                    {renderTabContent(bot?.originStage)}
                  </SbTabPane>
                  <SbTabPane key={TabPaneKeys.DRAFT} tab="Черновик">
                    {renderTabContent(bot?.draftStage)}
                  </SbTabPane>
                </SbTabs>
              ) : (
                <>{renderTabContent(bot?.originStage)}</>
              )}
              <div className={CONTROLS_CLASS_NAME}>
                <SbButton
                  disabled={!selectedBotEditionReference}
                  sbSize="medium"
                  onClick={onAddBotEditionToDraftButtonClick}
                >
                  Записать версию в черновик
                </SbButton>
                <SbButton disabled={!selectedBotEditionReference} sbSize="medium" onClick={onExportButtonClick}>
                  Экспортировать версию
                </SbButton>
              </div>
            </Col>
            <Col span={9}>
              <SbPanel sbType="help" scrollable={false}>
                <SbTypography>
                  <h3>Работа с версиями</h3>
                  <ul>
                    <li>Версия появляется при сохранении</li>
                    <li>Версия может быть как у оригинала, так и у черновика. Они сохраняются независимо.</li>
                    <li>
                      При объединении черновика с оригиналом история версий черновика переписывает историю версий
                      оригинала с момента создания черновика.
                    </li>
                  </ul>
                </SbTypography>
              </SbPanel>
            </Col>
          </Row>
        )}
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" onClick={onCancelAddBotEditionToDraftConfirmModal}>
            Закрыть
          </SbButton>,
          addBotEditionToDraftConfirmModalShowResult ? (
            <SbButton key="go-to-draft" sbSize="medium" sbType="icon-secondary" onClick={onGoToDraftButtonClick}>
              Перейти в черновик
            </SbButton>
          ) : (
            <SbButton
              key="merge"
              disabled={addBotEditionToDraftConfirmModalInProgress}
              sbSize="medium"
              sbType="icon-secondary"
              onClick={onConfirmAddBotEditionToDraftConfirmModal}
            >
              {addBotEditionToDraftConfirmModalInProgress && <SbIcon spin iconName="loading-four" size={16} />}
              Да, восстановить версию в черновик
            </SbButton>
          ),
        ]}
        sbSize="small"
        title={addBotEditionToDraftConfirmModalShowResult ? 'Версия восстановлена' : 'Вы уверены?'}
        visible={addBotEditionToDraftConfirmModalState !== AddBotEditionToDraftConfirmModalStates.HIDDEN}
        width={580}
        onCancel={onCancelAddBotEditionToDraftConfirmModal}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">
            {addBotEditionToDraftConfirmModalShowResult
              ? `Версия от ${moment(selectedBotEditionReference?.createdOn).format('LL HH:mm:ss')} восстановлена.`
              : 'Восстановленная версия бота перезапишет текущий черновик и его историю версий.'}
          </p>
        </SbTypography>
      </SbModal>
      <SbProgressStatusModal
        header={exportTitle}
        status={exportStatus}
        visible={exportModalVisible}
        onCancel={onExportModalClose}
      >
        {renderBotEditionExportModalContent()}
      </SbProgressStatusModal>
    </>
  );
};

export default SbBotVersionsModal;
