import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import stc from 'string-to-color';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Table } from 'antd';

import './index.less';

import { CustomEventsPerDayModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';
import { onlyUnique } from '../../../../utils/stringUtil';

const CHART_SETTINGS = {
  barColors: {
    otherCustomEvents: '#CECECE',
  },
};

const getOtherCustomEventsCount = (e: CustomEventsPerDayModel) =>
  e.totalCount - Object.values(e.topEvents).reduce((partialSum, count) => partialSum + count, 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as CustomEventsPerDayModel;
  if (data.totalCount <= 0) {
    return null;
  }

  const otherCustomEventsCount = getOtherCustomEventsCount(data);

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        <div>Топ событий:</div>
        <ul>
          {Object.keys(data.topEvents).map((customEventName) =>
            data.topEvents[customEventName] > 0 ? (
              <li key={customEventName}>
                <i>{customEventName}</i>:&nbsp;<b>{data.topEvents[customEventName]}</b>
              </li>
            ) : null
          )}
          {otherCustomEventsCount > 0 && (
            <li>
              <i>Другие события</i>:&nbsp;<b>{otherCustomEventsCount}</b>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

interface ISbCustomEventsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbCustomEventsAnalytics: React.FC<ISbCustomEventsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CustomEventsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await analyticsReportsApi.getCustomEventsPerDayList(
        agentStageId,
        filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse),
        filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse)
      );
      setData(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по пользовательским событиям',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const uniqueCustomEvents = data.flatMap((dataItem) => Object.keys(dataItem.topEvents)).filter(onlyUnique);

  const otherCustomEventsExists = !!data.map(getOtherCustomEventsCount).find((count) => count > 0);

  return (
    <div className="sb-custom-events-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Пользовательские события</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата \\ Событие',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                ...uniqueCustomEvents.map((customEventName) => {
                  return {
                    title: customEventName,
                    key: 'eventCount',
                    render: (_: unknown, record: CustomEventsPerDayModel) => record.topEvents[customEventName] || 0,
                  };
                }),
                ...(otherCustomEventsExists
                  ? [
                      {
                        title: 'Другие события',
                        key: 'other',
                        render: (_: unknown, record: CustomEventsPerDayModel) => getOtherCustomEventsCount(record),
                      },
                    ]
                  : []),
              ]}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis dataKey={(e: CustomEventsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                {uniqueCustomEvents.map((customEventName) => {
                  const dataKey = (e: CustomEventsPerDayModel) => e.topEvents[customEventName] || 0;
                  return (
                    <Bar
                      key={customEventName}
                      dataKey={dataKey}
                      fill={stc(customEventName)}
                      isAnimationActive={isAnimationActive}
                      name={customEventName}
                    />
                  );
                })}
                {otherCustomEventsExists && (
                  <Bar
                    dataKey={(e: CustomEventsPerDayModel) => getOtherCustomEventsCount(e)}
                    fill={CHART_SETTINGS.barColors.otherCustomEvents}
                    isAnimationActive={isAnimationActive}
                    name="Другие события"
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbCustomEventsAnalytics;
