import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox';

import './index.less';

type ISbCheckboxProps = CheckboxProps;

const SbCheckbox: React.FC<ISbCheckboxProps> = ({ children, ...props }) => {
  return (
    <Checkbox className="sb-checkbox" {...props}>
      {children}
    </Checkbox>
  );
};

export default SbCheckbox;
