import cloneDeep from 'lodash/cloneDeep';

import { ActionSchema } from '../../../../api';

import {
  generateActionId,
  generateAttachmentId,
  generateButtonId,
  generateConditionId,
  generateElseCaseId,
  generateMainCaseId,
  generateMessageId,
  generateOperandId,
  generateProtocolId,
  generateSuggestionId,
  instanceOfActionSchemaWithMessages,
  instanceOfBinaryConditionSchema,
  instanceOfExternalSigninSchema,
  instanceOfSendEmailSchema,
  instanceOfSuggestionInputSchema,
  instanceOfSwitchActionSchema,
  instanceOfTransitSchema,
  instanceOfUnaryConditionSchema,
} from '.';

export const actionContainsChildEntity = (action: ActionSchema, entityId?: string): boolean =>
  [...(action.messages || []), ...(action.buttons || [])].some((e) => e.id === entityId);

export const cloneAction = (action: ActionSchema): ActionSchema => {
  const actionClone = cloneDeep(action);

  actionClone.id = generateActionId();

  if (instanceOfActionSchemaWithMessages(actionClone)) {
    actionClone.messages.forEach((message) => {
      message.id = generateMessageId();
      message.attachments.forEach((attachment) => (attachment.id = generateAttachmentId()));
    });
  }

  if (instanceOfSuggestionInputSchema(actionClone)) {
    actionClone.suggestions.forEach((suggestion) => (suggestion.id = generateSuggestionId()));
  }

  if (instanceOfTransitSchema(actionClone)) {
    actionClone.buttons.forEach((button) => {
      button.id = generateButtonId();
      button.outputBindingId = null;
    });
  }

  if (instanceOfExternalSigninSchema(actionClone)) {
    actionClone.protocol.id = generateProtocolId();
  }

  if (instanceOfSendEmailSchema(actionClone)) {
    actionClone.attachments.forEach((attachment) => (attachment.id = generateOperandId()));
    actionClone.bccRecipients.forEach((recipient) => (recipient.id = generateOperandId()));
    actionClone.ccRecipients.forEach((recipient) => (recipient.id = generateOperandId()));
    actionClone.recipients.forEach((recipient) => (recipient.id = generateOperandId()));
    actionClone.subject.id = generateOperandId();
  }

  if (instanceOfSwitchActionSchema(actionClone)) {
    actionClone.mainCases.forEach((mainCase) => {
      mainCase.id = generateMainCaseId();
      mainCase.conditions.forEach((condition) => {
        condition.id = generateConditionId();
        if (instanceOfBinaryConditionSchema(condition)) {
          condition.leftOperand.id = generateOperandId();
          condition.rightOperand.id = generateOperandId();
        }
        if (instanceOfUnaryConditionSchema(condition)) {
          condition.operand.id = generateOperandId();
        }
      });
    });
    actionClone.elseCase.id = generateElseCaseId();
  }

  return actionClone;
};
