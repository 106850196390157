import React, { memo, useState } from 'react';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import { ContactIdentifierKind, DefaultActionGroupSchema, MergeInboxContactsSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import EditButton from '../../common/EditButton';
import MergeInboxContactsSettingsModal from '../../common/MergeInboxContactsSettingsModal';
import SbTag from '../../../../../simple-bot/components/common/SbTag';
import { tryGetElementById } from '../../../utils';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';

import './index.less';

const VARIABLE_TAG_COLOR = '#E6E6E6'; // @sb-bot-answer-editor-variable-container-color

interface IMergeInboxContactsProps {
  index: number;
  action: MergeInboxContactsSchema;
  group: DefaultActionGroupSchema;
}

const MergeInboxContacts: React.FC<IMergeInboxContactsProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const variables = scenarioStructure?.variables || [];

  const resultVariable = variables.find((v) => v.id === action.resultVariableId);
  const identifierVariable = variables.find((v) => v.id === action.identifierVariableId);

  const onSetupButtonClick = () => {
    setSettingsModalVisible(true);
  };

  const onSigninSettingsModalClose = () => {
    setSettingsModalVisible(false);
  };

  const onActionChange = (action: MergeInboxContactsSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundGroup = tryGetElementById(newScenarioStructure, group.id) as DefaultActionGroupSchema;
    foundGroup.actions = foundGroup.actions.map((a) => (a.id === action.id ? action : a));
    setScenarioStructure(newScenarioStructure);
  };

  const getIdentifierKindName = (kind?: ContactIdentifierKind) => {
    switch (kind) {
      case ContactIdentifierKind.Phone:
        return 'Телефон';
      case ContactIdentifierKind.Email:
        return 'Email';
    }
    return '-';
  };

  const renderVariable = (name: string) => {
    return <SbTag color={VARIABLE_TAG_COLOR} sbSize="small" text={name} />;
  };

  return (
    <ActionWrapper
      showMergeInboxContactsSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="merge" size={20} />}
      index={index}
      title="Связать контакт"
    >
      <div className="sb-merge-inbox-contacts-action">
        <div className="sb-merge-inbox-contacts-action__field">
          <div className="sb-merge-inbox-contacts-action__field__label">Поле для поиска контакта:</div>
          <div>{getIdentifierKindName(action.contactIdentifierKind)}</div>
        </div>
        <div className="sb-merge-inbox-contacts-action__field">
          <div className="sb-merge-inbox-contacts-action__field__label">Значение для поиска контакта:</div>
          <div>{identifierVariable ? renderVariable(identifierVariable.name) : '-'}</div>
        </div>
        <div className="sb-merge-inbox-contacts-action__field">
          <div className="sb-merge-inbox-contacts-action__field__label">Результат слияния контактов:</div>
          <div>{resultVariable ? renderVariable(resultVariable.name) : '-'}</div>
        </div>
        <EditButton buttonHandler={onSetupButtonClick} title="Настроить" />
        <MergeInboxContactsSettingsModal
          action={action}
          visible={settingsModalVisible}
          onChange={onActionChange}
          onClose={onSigninSettingsModalClose}
        />
      </div>
    </ActionWrapper>
  );
};

export default memo(MergeInboxContacts);
