import tinycolor from 'tinycolor2';

import { LiveChatSettingsModel } from '../../../../api';
import { LIVE_CHAT_SETTINGS_DEFAULT } from '../../../constants';

import { WEB_CHAT_STYLE_OPTIONS } from './const';

const prepare = (size: string): string => (parseInt(size) === +size ? `${size}px` : size);

export const getTextColor = (bgColorHex: string): string => (tinycolor(bgColorHex).isLight() ? '#000000' : '#ffffff');

export const getToogleButtonBackground = (settings: LiveChatSettingsModel): string =>
  settings.toggleButton.background || LIVE_CHAT_SETTINGS_DEFAULT.toggleButton.background;

export const getToogleButtonSize = (settings: LiveChatSettingsModel): string =>
  prepare(settings.toggleButton.size || LIVE_CHAT_SETTINGS_DEFAULT.toggleButton.size);

export const getToogleButtonOffsetBottom = (): string => LIVE_CHAT_SETTINGS_DEFAULT.toggleButton.offset.bottom;

export const getToogleButtonOffsetRight = (): string => LIVE_CHAT_SETTINGS_DEFAULT.toggleButton.offset.right;

export const getToogleButtonIconColor = (settings: LiveChatSettingsModel): string =>
  getTextColor(getToogleButtonBackground(settings));

export const getChatTitle = (settings: LiveChatSettingsModel): string =>
  settings.chat.title || LIVE_CHAT_SETTINGS_DEFAULT.chat.title;

export const getChatPrimaryColor = (settings: LiveChatSettingsModel): string =>
  settings.chat.primaryColor || LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor;

export const getChatUserBubbleBackground = (settings: LiveChatSettingsModel): string =>
  settings.chat.userBubbleBackground || LIVE_CHAT_SETTINGS_DEFAULT.chat.userBubbleBackground;

export const getChatBackground = (settings: LiveChatSettingsModel): string =>
  settings.chat.background || LIVE_CHAT_SETTINGS_DEFAULT.chat.background;

export const getChatOffsetBottom = (settings: LiveChatSettingsModel): string =>
  `calc(${getToogleButtonOffsetBottom()} + ${getToogleButtonSize(settings)} + 10px)`;

export const getChatOffsetRight = (): string => getToogleButtonOffsetRight();

export const getChatMaxHeightBottom = (settings: LiveChatSettingsModel): string =>
  `calc(100% - ${getChatOffsetBottom(settings)} - 10px`;

export const getChatPrimaryTextColor = (settings: LiveChatSettingsModel): string =>
  getTextColor(getChatPrimaryColor(settings));

export const getChatUserBubbleTextColor = (settings: LiveChatSettingsModel): string =>
  getTextColor(getChatUserBubbleBackground(settings));

export const getWebChatStyleOptions = (settings: LiveChatSettingsModel): typeof WEB_CHAT_STYLE_OPTIONS => ({
  ...WEB_CHAT_STYLE_OPTIONS,
  bubbleFromUserBackground: getChatUserBubbleBackground(settings),
  bubbleFromUserTextColor: getChatUserBubbleTextColor(settings),
  suggestedActionBorderColor: getChatPrimaryColor(settings),
  suggestedActionBorderColorOnActive: getChatPrimaryColor(settings),
  suggestedActionBorderColorOnFocus: getChatPrimaryColor(settings),
  suggestedActionBorderColorOnHover: getChatPrimaryColor(settings),
  suggestedActionTextColor: getChatPrimaryColor(settings),
  suggestedActionTextColorOnActive: getChatPrimaryColor(settings),
  suggestedActionTextColorOnFocus: getChatPrimaryColor(settings),
  suggestedActionTextColorOnHover: getChatPrimaryColor(settings),
});
