import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { Elma365App, Elma365AppElementInputSchema, InputActionSchema } from '../../../../../../../../api';
import Elma365AppSelector from '../../../Elma365AppSelector';

interface IInputActionElma365AppSettingsTabProps {
  action: Elma365AppElementInputSchema;
  onChange: (action: InputActionSchema) => void;
}

const InputActionElma365AppSettingsTab: React.FC<IInputActionElma365AppSettingsTabProps> = ({ action, onChange }) => {
  const onAppSelectorChange = (app?: Elma365App) => {
    const newAction = cloneDeep(action);
    newAction.appCode = app?.code;
    newAction.appNamespace = app?.namespace;
    newAction.appTitle = app?.name;
    onChange(newAction);
  };

  return (
    <Elma365AppSelector
      appCode={action.appCode}
      appNamespace={action.appNamespace}
      onChange={onAppSelectorChange}
      onSelectedAppUpdated={() => {}}
    />
  );
};

export default InputActionElma365AppSettingsTab;
