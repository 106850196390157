import React from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Descriptions, List, Typography, Alert, Row, Switch } from 'antd';
import { LinkOutlined, PlaySquareOutlined, EditFilled, WechatFilled } from '@ant-design/icons';

import { AgentStageAccountModel, AgentStageModel, TrainStatus, SetupStatus, AgentStageAccountStatus } from '../../api';
import { getChannelName, getLivechatCodeSnippetScript } from '../utils/stringUtil';
import { Channels, FORM_FIELD_NAMES } from '../constants';
import { ViberIcon, TelegramIcon, Elma365Icon } from '../assets';
import { LIVE_CHAT_URL } from '../utils/configUtil';

import ConversationWidget from './ConversationWidget';

const { Paragraph } = Typography;
interface ChannelListProps {
  agentStage: AgentStageModel;
  channels: AgentStageAccountModel[];
  botId: string;
  onChannelChange: (newValue: AgentStageAccountModel) => void;
}

const ChannelList: React.FC<ChannelListProps> = ({ agentStage, channels, botId, onChannelChange }) => {
  const { push } = useHistory();

  const renderConversationsButton = (account: AgentStageAccountModel) => (
    <ConversationWidget
      agentStageAccountId={account.id}
      agentStageId={account.agentStageId}
      caption="Беседы бота в пределах канала"
      channelId={account.channelId}
    />
  );

  const renderOpenInEmulatorButton = (account: AgentStageAccountModel) => (
    <Button
      key="runChannelInEmulator"
      disabled={agentStage.trainResult?.status === TrainStatus.Error}
      href={account.redirectUrl || '#'}
      icon={<PlaySquareOutlined />}
      type="link"
    >
      Запустить беседу в эмуляторе
    </Button>
  );

  const renderEditButton = (account: AgentStageAccountModel) => {
    const onEditButtonClick = () => push(`/agents/${agentStage.id}/${account.channelId}/edit/${account.id}`);

    return (
      <Button
        key="edit-channel"
        icon={<EditFilled />}
        style={{ marginBottom: 16 }}
        type="default"
        onClick={onEditButtonClick}
      >
        Редактировать свойства
      </Button>
    );
  };

  const renderChannelError = (account: AgentStageAccountModel) => (
    <Alert
      showIcon
      description={
        <React.Fragment>
          <Paragraph>{account.setupResult?.errorMessage}</Paragraph>
        </React.Fragment>
      }
      message="Ошибка инициализации"
      style={{ marginBottom: 16 }}
      type={'error'}
    />
  );

  const iconProps = {
    height: 26,
    width: 26,
    style: { marginRight: 10 },
  };

  const onChannelStatusChange = (account: AgentStageAccountModel) => () => {
    const newStatus =
      account.status === AgentStageAccountStatus.Enabled
        ? AgentStageAccountStatus.Disabled
        : AgentStageAccountStatus.Enabled;

    account.status = newStatus;
    onChannelChange(account);
  };

  const getChannelTitle = (account: AgentStageAccountModel) => {
    const channelId = account.channelId;
    return (
      <Row align="middle">
        {channelId === Channels.VIBER && <ViberIcon {...iconProps} />}
        {channelId === Channels.TELEGRAM && <TelegramIcon {...iconProps} />}
        {channelId === Channels.ELMA365 && <Elma365Icon {...iconProps} />}
        {channelId === Channels.DIRECTLINE && <WechatFilled {...iconProps} />}
        {getChannelName(channelId, account.externalName, account.displayName)}
        {![Channels.DIRECTLINE, Channels.LIVECHAT].includes(channelId as Channels) && (
          <>
            &nbsp;
            <Switch
              checked={account.status === AgentStageAccountStatus.Enabled}
              checkedChildren="Вкл"
              unCheckedChildren="Выкл"
              onChange={onChannelStatusChange(account)}
            />
          </>
        )}
      </Row>
    );
  };

  return (
    <List
      dataSource={channels}
      grid={{ gutter: 24, lg: 3, md: 2, sm: 1, xs: 1 }}
      id="id"
      renderItem={(c: AgentStageAccountModel) => {
        let card;

        switch (c.channelId) {
          case Channels.VIBER:
          case Channels.TELEGRAM:
            card = (
              <Card extra={renderConversationsButton(c)} title={getChannelTitle(c)}>
                {c.setupResult?.status === SetupStatus.Error && renderChannelError(c)}
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="URI">
                    {agentStage.trainResult?.status === TrainStatus.Pending ? (
                      <Button loading type="link">
                        Идет публикация...
                      </Button>
                    ) : (
                      <Button
                        disabled={agentStage.trainResult?.status === TrainStatus.Error}
                        href={c.redirectUrl}
                        style={{ padding: 0 }}
                        type="link"
                      >
                        {c.botUri} <LinkOutlined />
                      </Button>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Token">
                    <Paragraph copyable style={{ margin: 0 }}>
                      {c.authToken}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="ID">{c.id}</Descriptions.Item>
                </Descriptions>
              </Card>
            );
            break;

          case Channels.ELMA365:
            card = (
              <Card extra={[renderConversationsButton(c), renderOpenInEmulatorButton(c)]} title={getChannelTitle(c)}>
                {c.setupResult?.status === SetupStatus.Error && renderChannelError(c)}
                {renderEditButton(c)}
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="URL для отправки сообщений">
                    <Paragraph copyable style={{ margin: 0, overflowWrap: 'anywhere' }}>
                      {c.callbackUrl}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="X-Token">
                    <Paragraph copyable style={{ margin: 0 }}>
                      {c.properties ? c.properties[FORM_FIELD_NAMES.xToken] : '(не указан)'}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="ID бота">
                    <Paragraph copyable style={{ margin: 0 }}>
                      {botId}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="ID стадии бота">
                    <Paragraph copyable style={{ margin: 0 }}>
                      {c.agentStageId}
                    </Paragraph>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            );
            break;

          case Channels.DIRECTLINE:
            card = (
              <Card extra={renderConversationsButton(c)} title={getChannelTitle(c)}>
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="URI">
                    {agentStage.trainResult?.status === TrainStatus.Pending ? (
                      <Button loading type="link">
                        Идет публикация...
                      </Button>
                    ) : (
                      <Button
                        disabled={agentStage.trainResult?.status === TrainStatus.Error}
                        href={c.redirectUrl}
                        style={{ padding: 0 }}
                        target="_blank"
                        type="link"
                      >
                        Перейти в веб-чат <LinkOutlined />
                      </Button>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ссылка">
                    <Paragraph copyable style={{ margin: 0 }}>
                      {c.redirectUrl}
                    </Paragraph>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            );
            break;

          case Channels.LIVECHAT: {
            const scriptTextJwt = getLivechatCodeSnippetScript(LIVE_CHAT_URL, c.id, 'jwt');
            const scriptTextUserData = getLivechatCodeSnippetScript(LIVE_CHAT_URL, c.id, 'userData');

            card = (
              <Card extra={renderConversationsButton(c)} title={getChannelTitle(c)}>
                {renderEditButton(c)}
                <Descriptions bordered column={1}>
                  <Descriptions.Item label="Код для вставки c JWT">
                    <Paragraph copyable={{ text: scriptTextJwt }} style={{ margin: 0 }}>
                      <pre>{scriptTextJwt}</pre>
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="Код для вставки с конкретными данными пользователя">
                    <Paragraph copyable={{ text: scriptTextUserData }} style={{ margin: 0 }}>
                      <pre>{scriptTextUserData}</pre>
                    </Paragraph>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            );
            break;
          }

          default:
            card = (
              <Card extra={renderConversationsButton(c)} title="Unknown channel">
                {c.setupResult?.status === SetupStatus.Error && renderChannelError(c)}
                <div>{c.channelId}</div>
                <div>ID: {c.id}</div>
                <div>
                  {agentStage.trainResult?.status === TrainStatus.Pending ? (
                    <Button loading type="link">
                      Идет публикация...
                    </Button>
                  ) : (
                    <Button
                      disabled={agentStage.trainResult?.status === TrainStatus.Error}
                      href={c.redirectUrl}
                      style={{ padding: 0 }}
                      type={'link'}
                    >
                      {c.botUri} <LinkOutlined />
                    </Button>
                  )}
                </div>
              </Card>
            );
            break;
        }

        return <List.Item>{card}</List.Item>;
      }}
    />
  );
};

export default ChannelList;
