import React, { memo, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import {
  DefaultTriggerGroupSchema,
  IntentReferenceSchema,
  IntentTriggerSchema,
  SchemaKind,
  StartTriggerSchema,
  TransitionTriggerSchema,
  UnknownIntentTriggerSchema,
  TerminalTriggerSchema,
  MenuButtonTriggerSchema,
  CommandTriggerSchema,
  ExternalEventTriggerSchema,
} from '../../../../../api';
import {
  botSettingsSelector,
  currentScenarioStructureSelector,
  selectedEntitySelector,
} from '../../../../recoil/scenarioStructure';
import {
  generateId,
  instanceOfIntentTriggerSchema,
  instanceOfStartTriggerSchema,
  instanceOfTransitionTriggerSchema,
  instanceOfUnknownIntentTriggerSchema,
  instanceOfTerminalTriggerSchema,
  instanceOfMenuButtonTriggerSchema,
  tryGetElementById,
  instanceOfCommandTriggerSchema,
  instanceOfExternalEventTriggerSchema,
} from '../../utils';
import IntentTrigger from '../triggers/IntentTrigger';
import StartTrigger from '../triggers/StartTrigger';
import TransitionTrigger from '../triggers/TransitionTrigger';
import UnknownIntentTrigger from '../triggers/UnknownIntentTrigger';
import TerminalTrigger from '../triggers/TerminalTrigger';
import MenuButtonTrigger from '../triggers/MenuButtonTrigger';
import ExternalEventTrigger from '../triggers/ExternalEventTrigger';
import GroupContainer from '../common/GroupContainer';
import AddButton from '../common/AddButton';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import IntentSelector from '../IntentSelector';
import { DEFAULT_INTENT_THRESHOLD } from '../../../../simple-bot/const';
import SbMenu from '../../../../simple-bot/components/common/SbMenu';
import CommandTrigger from '../triggers/CommandTrigger';

import Placeholder from './Placeholder';

interface DefaultTriggerGroupProps {
  group: DefaultTriggerGroupSchema;
}

const DefaultTriggerGroup: React.FC<DefaultTriggerGroupProps> = ({ group }) => {
  const [intentSelectorVisible, setIntentSelectorVisible] = useState(false);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const selectedEntity = useRecoilValue(selectedEntitySelector);
  const botSettings = useRecoilValue(botSettingsSelector);

  const childSelected = selectedEntity && group.triggers.some((t) => t.id === selectedEntity.id);

  const onStartTriggerActionSelect = () => {
    if (!scenarioStructure) return;
    if (group.triggers.some((t) => t.$kind === SchemaKind.StartTrigger)) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: StartTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.StartTrigger,
      outputBindingId: null,
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const onTransitionTriggerActionSelect = () => {
    if (!scenarioStructure) return;
    if (group.triggers.some((t) => t.$kind === SchemaKind.TransitionTrigger)) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: TransitionTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.TransitionTrigger,
      outputBindingId: null,
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const onUnknownIntentTriggerActionSelect = () => {
    if (!scenarioStructure) return;
    if (group.triggers.some((t) => t.$kind === SchemaKind.UnknownIntentTrigger)) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: UnknownIntentTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.UnknownIntentTrigger,
      outputBindingId: null,
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const onMenuButtonTriggerActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: MenuButtonTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.MenuButtonTrigger,
      outputBindingId: null,
      value: '',
      position: 0,
      category: null,
      scenarioId: scenarioStructure.id,
      actionGroupId: '',
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const onCommandTriggerActionSelect = () => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: CommandTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.CommandTrigger,
      outputBindingId: null,
      command: '',
    };

    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);
    setScenarioStructure(newScenarioStructure);
  };

  const onMenuButtonChange = (newValue: MenuButtonTriggerSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundButton = tryGetElementById(newScenarioStructure, newValue.id) as MenuButtonTriggerSchema;
    foundButton.value = newValue.value;
    foundButton.position = newValue.position;
    foundButton.category = newValue.category || null;
    setScenarioStructure(newScenarioStructure);
  };

  const onCommandValueChange = (newValue: CommandTriggerSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const command = tryGetElementById(newScenarioStructure, newValue.id) as CommandTriggerSchema;
    command.command = newValue.command;
    command.description = newValue.description;
    setScenarioStructure(newScenarioStructure);
  };

  const onTerminalTriggerActionSelect = () => {
    if (!scenarioStructure) return;
    if (group.triggers.some((t) => t.$kind === SchemaKind.TerminalTrigger)) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: TerminalTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.TerminalTrigger,
      outputBindingId: null,
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const onExternalEventTriggerActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: ExternalEventTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.ExternalEventTrigger,
      externalEventName: '',
      externalEventId: '',
      outputBindingId: null,
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const onExternalEventChange = (newValue: ExternalEventTriggerSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const externalEvent = tryGetElementById(newScenarioStructure, newValue.id) as ExternalEventTriggerSchema;
    externalEvent.externalEventName = newValue.externalEventName;
    externalEvent.externalEventId = newValue.externalEventId;
    externalEvent.variables = newValue.variables;
    setScenarioStructure(newScenarioStructure);
  };

  const onIntentTriggerActionSelect = () => {
    setIntentSelectorVisible(true);
  };

  const onIntentSelectorClose = () => {
    setIntentSelectorVisible(false);
  };

  const onIntentReferenceSelect = (intentReference: IntentReferenceSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newTrigger: IntentTriggerSchema = {
      id: generateId('TRR'),
      $kind: SchemaKind.IntentTrigger,
      intentReference,
      intentThreshold: botSettings?.recognition?.intentThreshold ?? DEFAULT_INTENT_THRESHOLD,
      outputBindingId: null,
    };
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.push(newTrigger);

    setScenarioStructure(newScenarioStructure);
  };

  const renderGroupActionsMenu = () => (
    <>
      <SbMenu.Item
        key="intents"
        icon={<SbIcon iconName="comments" size={18} />}
        sbType="dropdown"
        onClick={onIntentTriggerActionSelect}
      >
        Интент
      </SbMenu.Item>
      <SbMenu.Item
        key="start"
        disabled={group.triggers.some((t) => t.$kind === SchemaKind.StartTrigger)}
        icon={<SbIcon iconName="play" size={18} />}
        sbType="dropdown"
        onClick={onStartTriggerActionSelect}
      >
        Старт бота
      </SbMenu.Item>
      <SbMenu.Item
        key="transition"
        disabled={group.triggers.some((t) => t.$kind === SchemaKind.TransitionTrigger)}
        icon={<SbIcon iconName="s-turn-left" size={18} />}
        sbType="dropdown"
        onClick={onTransitionTriggerActionSelect}
      >
        Переход из др. сценария
      </SbMenu.Item>
      <SbMenu.Item
        key="unknownIntent"
        disabled={group.triggers.some((t) => t.$kind === SchemaKind.UnknownIntentTrigger)}
        icon={<SbIcon iconName="help" size={18} />}
        sbType="dropdown"
        onClick={onUnknownIntentTriggerActionSelect}
      >
        Неизвестный интент
      </SbMenu.Item>
      <SbMenu.Item
        key="terminal"
        disabled={group.triggers.some((t) => t.$kind === SchemaKind.TerminalTrigger)}
        icon={<SbIcon iconName="handle-x" size={18} />}
        sbType="dropdown"
        onClick={onTerminalTriggerActionSelect}
      >
        Триггер завершения
      </SbMenu.Item>
      <SbMenu.Item
        key="menuButton"
        icon={<SbIcon iconName="list-top" size={18} />}
        sbType="dropdown"
        onClick={onMenuButtonTriggerActionSelect}
      >
        Кнопка меню
      </SbMenu.Item>
      <SbMenu.Item
        key="command"
        icon={<SbIcon iconName="code-one" size={18} />}
        sbType="dropdown"
        onClick={onCommandTriggerActionSelect}
      >
        Команда
      </SbMenu.Item>
      <SbMenu.Item
        key="externalEvent"
        icon={<SbIcon iconName="share-three" size={18} />}
        sbType="dropdown"
        onClick={onExternalEventTriggerActionSelect}
      >
        Внешнее событие
      </SbMenu.Item>
    </>
  );

  return (
    <GroupContainer
      childSelected={childSelected}
      group={group}
      // isDraggedBy={
      //   // NOTE: для увеличения производительности вычисление свойства отключено.
      //   // В текущей реализации dragTarget перевычисляется при каждом наведении курсора на группу,
      //   // что вызывает рендеринг всех остальных групп и сильно замедляет перетаскивание и panning.
      //   // TODO: нужно придумать оптимизированный вариант или совсем отказаться от подсвечивания через isDraggedBy
      //   false /*(dragTarget && dragTarget.id === group.id && dragSource && dragSource.id !== group.id) ?? false*/
      // }
    >
      <div className="group-container__description">Выберите события, по которым будет запускаться этот сценарий</div>
      <Droppable droppableId={group.id} isDropDisabled={!selectedEntity}>
        {(provided, snapshot) => (
          <div className="group-elements-container" {...provided.droppableProps} ref={provided.innerRef}>
            {group.triggers.map((trigger, index) => {
              if (instanceOfIntentTriggerSchema(trigger)) {
                return <IntentTrigger key={trigger.id} group={group} index={index} trigger={trigger} />;
              }
              if (instanceOfStartTriggerSchema(trigger)) {
                return <StartTrigger key={trigger.id} group={group} index={index} trigger={trigger} />;
              }
              if (instanceOfTransitionTriggerSchema(trigger)) {
                return <TransitionTrigger key={trigger.id} group={group} index={index} trigger={trigger} />;
              }
              if (instanceOfUnknownIntentTriggerSchema(trigger)) {
                return <UnknownIntentTrigger key={trigger.id} group={group} index={index} trigger={trigger} />;
              }
              if (instanceOfTerminalTriggerSchema(trigger)) {
                return <TerminalTrigger key={trigger.id} group={group} index={index} trigger={trigger} />;
              }
              if (instanceOfMenuButtonTriggerSchema(trigger)) {
                return (
                  <MenuButtonTrigger
                    key={trigger.id}
                    group={group}
                    index={index}
                    trigger={trigger}
                    onTriggerValueChange={(newValue) => {
                      onMenuButtonChange(newValue);
                    }}
                  />
                );
              }
              if (instanceOfCommandTriggerSchema(trigger)) {
                return (
                  <CommandTrigger
                    key={trigger.id}
                    group={group}
                    index={index}
                    trigger={trigger}
                    onTriggerValueChange={(newValue) => {
                      onCommandValueChange(newValue);
                    }}
                  />
                );
              }
              if (instanceOfExternalEventTriggerSchema(trigger)) {
                return (
                  <ExternalEventTrigger
                    key={trigger.id}
                    group={group}
                    index={index}
                    trigger={trigger}
                    onTriggerValueChange={(newValue) => {
                      onExternalEventChange(newValue);
                    }}
                  />
                );
              }
              throw new Error(`Тип триггера ${trigger.$kind} не поддерживается.`);
            })}
            {provided.placeholder && <Placeholder provided={provided} snapshot={snapshot} />}
          </div>
        )}
      </Droppable>
      <AddButton groupId={group.id} menuContent={renderGroupActionsMenu()} title="Добавить триггер" />
      <IntentSelector
        caption="Добавить как триггер"
        visible={intentSelectorVisible}
        onClose={onIntentSelectorClose}
        onSelect={onIntentReferenceSelect}
      />
    </GroupContainer>
  );
};

export default memo(DefaultTriggerGroup);
