import { inboxParticipantApi } from '../../apis';
import { InboxParticipantActivityEvent, InboxParticipantStatus } from '../../../api';

import { IOperationProgress, ITaskHandler } from './types';

export interface IParticipantStatusUpdateTaskContent {
  participantId: string;
  status: InboxParticipantStatus;
}

export interface IParticipantActivityUpdateTaskContent {
  participantId: string;
  event: InboxParticipantActivityEvent;
}

export class ParticipantStatusUpdateTaskHandler implements ITaskHandler<IParticipantStatusUpdateTaskContent> {
  private static s_instance?: ParticipantStatusUpdateTaskHandler;

  public static get instance(): ParticipantStatusUpdateTaskHandler {
    if (!ParticipantStatusUpdateTaskHandler.s_instance) {
      ParticipantStatusUpdateTaskHandler.s_instance = new ParticipantStatusUpdateTaskHandler();
    }

    return ParticipantStatusUpdateTaskHandler.s_instance;
  }

  public estimate(): IOperationProgress {
    return { current: 0, maximum: 1 };
  }

  public async process(content: IParticipantStatusUpdateTaskContent): Promise<unknown> {
    return await inboxParticipantApi.setInboxParticipantStatus(content.participantId, { status: content.status });
  }
}

export class ParticipantActivityUpdateTaskHandler implements ITaskHandler<IParticipantActivityUpdateTaskContent> {
  private static s_instance?: ParticipantActivityUpdateTaskHandler;

  public static get instance(): ParticipantActivityUpdateTaskHandler {
    if (!ParticipantActivityUpdateTaskHandler.s_instance) {
      ParticipantActivityUpdateTaskHandler.s_instance = new ParticipantActivityUpdateTaskHandler();
    }

    return ParticipantActivityUpdateTaskHandler.s_instance;
  }

  public estimate(): IOperationProgress {
    return { current: 0, maximum: 1 };
  }

  public async process(content: IParticipantActivityUpdateTaskContent): Promise<unknown> {
    return await inboxParticipantApi.setInboxParticipantActivity(content.participantId, { event: content.event });
  }
}
