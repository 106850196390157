import { Collection } from 'dexie';

export interface IDbCollection<TItem, TKey> {
  reverse(): IDbCollection<TItem, TKey>;
  filter(filter: (item: TItem) => boolean): IDbCollection<TItem, TKey>;
  offset(n: number): IDbCollection<TItem, TKey>;
  limit(n: number): IDbCollection<TItem, TKey>;
  toArray(): Promise<TItem[]>;
}

export class DbCollection<TItem, TKey> implements IDbCollection<TItem, TKey> {
  private readonly _collection: Collection<TItem, TKey>;

  public constructor(collection: Collection<TItem, TKey>) {
    this._collection = collection;
  }

  public reverse(): IDbCollection<TItem, TKey> {
    return new DbCollection(this._collection.reverse());
  }

  public filter(filter: (item: TItem) => boolean): IDbCollection<TItem, TKey> {
    return new DbCollection(this._collection.filter(filter));
  }

  public offset(n: number): IDbCollection<TItem, TKey> {
    return new DbCollection(this._collection.offset(n));
  }

  public limit(n: number): IDbCollection<TItem, TKey> {
    return new DbCollection(this._collection.limit(n));
  }

  public toArray(): Promise<TItem[]> {
    return this._collection.toArray();
  }
}
