import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import moment from 'moment';
import { RcFile } from 'antd/es/upload';

import './index.less';

import {
  InboxAttachmentModel,
  InboxChatParticipantModel,
  InboxDirection,
  InboxFileCategory,
  InboxFileMediaGroup,
  InboxFileMediaType,
  InboxMentionModel,
  InboxMessageModel,
  InboxMessageStatus,
  InboxMessageType,
  InboxMessageParticipantStatus,
  InboxParticipantModel,
} from '../../../../api';
import { IEntityItem, IEntityRange, IEntityScroll, IEntityStats } from '../../storages';
import IbMessageInput, { IIbMessageInputRef } from '../../components/IbMessageInput';
import { ITaskOperation } from '../../tasks';
import { TEXT_FORMAT_TYPES } from '../../../constants';
import { IPreviewModalParams } from '../../recoil';

import IbMessageListItem, { estimateMessageHeight } from './IbMessageListItem';

const WHEEL_EVENT_TYPE = 'wheel';
const TOUCH_MOVE_EVENT_TYPE = 'touchmove';

const PADDING_SIZE = 8;
const DELTA_SCALE = -0.5;
const MIN_TOUCH_MOVEMENT_ELIGIBLE_FOR_INSPECTION = 20;

const MAIN_CLASS_NAME = 'ib-message-list';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const SCROLL_CLASS_NAME = `${CONTENT_CLASS_NAME}__scroll`;
const CANVAS_CLASS_NAME = `${SCROLL_CLASS_NAME}__canvas`;
const WINDOW_CLASS_NAME = `${CANVAS_CLASS_NAME}__window`;
const FOOTER_CLASS_NAME = `${MAIN_CLASS_NAME}__footer`;

const isVerticalMove = (dX: number, dY: number) =>
  Math.abs(dY) > MIN_TOUCH_MOVEMENT_ELIGIBLE_FOR_INSPECTION && Math.abs(dY) > Math.abs(dX);

export interface IIbMessageListProps {
  messageInputRef: MutableRefObject<IIbMessageInputRef | undefined>;
  messageItems: (IEntityItem<InboxMessageModel> | undefined)[];
  messageStats?: IEntityStats;
  messageRange?: IEntityRange;
  attachmentItems?: IEntityItem<InboxAttachmentModel>[];
  currentUserId: string;
  chatId?: string;
  channelId?: string;
  messagePosition?: { index: number };
  operatorList?: InboxParticipantModel[];
  operatorListLoading?: boolean;
  participants: InboxChatParticipantModel[];
  mentionLinksEnabled: boolean;
  quotedMessages?: IEntityItem<InboxMessageModel>[];
  tariffLimitExceeded?: boolean;
  renderMessage?: (
    currentUserId: string,
    entityItem: IEntityItem<InboxMessageModel> | undefined,
    virtualItem: VirtualItem
  ) => ReactNode | undefined;
  onMessageScroll?: (scroll: IEntityScroll) => void;
  onMessageSend?: (entity: InboxMessageModel) => void;
  onMessageResend?: (entity: InboxMessageModel, virtual: VirtualItem) => void;
  onMessageDelete?: (entity: InboxMessageModel, operation: ITaskOperation, virtual: VirtualItem) => void;
  onAttachmentUpload?: (entity: InboxAttachmentModel) => void;
  onAttachmentReUpload?: (entity: InboxAttachmentModel) => void;
  onAttachmentDelete?: (entity: InboxAttachmentModel, operation: ITaskOperation) => void;
  onAttachmentPreview?: (params: IPreviewModalParams) => void;
  onOperatorsSearch: (search: string) => void;
  onQuotedMessageAdd?: (entityItem: IEntityItem<InboxMessageModel>) => void;
  onQuotedMessageDelete?: () => void;
  onQuotedMessageClick?: (messageId: string, activityId: string) => void;
}

const IbMessageList: React.FC<IIbMessageListProps> = ({
  messageInputRef,
  messageItems,
  messageStats,
  messageRange,
  attachmentItems,
  currentUserId,
  chatId,
  channelId,
  messagePosition,
  operatorList,
  operatorListLoading,
  participants,
  mentionLinksEnabled,
  quotedMessages,
  tariffLimitExceeded,
  renderMessage,
  onMessageScroll,
  onMessageSend,
  onMessageResend,
  onMessageDelete,
  onAttachmentPreview,
  onAttachmentUpload,
  onAttachmentReUpload,
  onAttachmentDelete,
  onOperatorsSearch,
  onQuotedMessageAdd,
  onQuotedMessageDelete,
  onQuotedMessageClick,
}) => {
  const [newMessageIsInternal, setNewMessageIsInternal] = useState(false);

  const [scrolling, setScrolling] = useState(false);
  const [scrollingIsArmed, setScrollingIsArmed] = useState(false);
  const [touchInitialPosition, setTouchInitialPosition] = useState({ x: 0, y: 0 });
  const [scrollingIsActive, setScrollingIsActive] = useState(false);
  const [swipingIsActive, setSwipingIsActive] = useState(false);
  const [forcePreventSwiping, setForcePreventSwiping] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const onMouseWheel = (event: WheelEvent) => {
    // NOTE: не разрешается скролл списка сообщений, если пользователь уже начал свайп сообщения по горизонтали.
    if (!swipingIsActive) {
      scrollRef?.current?.scrollBy({ top: event.deltaY * DELTA_SCALE });
    }
    event.preventDefault();
  };

  const handleScrollTouchMove = (event: React.TouchEvent<HTMLDivElement> | TouchEvent) => {
    if (swipingIsActive) {
      // NOTE: предотвращает скролл списка сообщений, если пользователь уже начал свайп сообщения по горизонтали.
      event.preventDefault();
    }

    if (scrollingIsActive) {
      // NOTE: предотвращает случайный свайп сообщений по горизонтали, если пользователь уже начал скроллинг.
      // Используется фаза Capture, чтобы оно не доходило до дочерних элементов списка.
      event.stopPropagation();
      return false;
    }

    return true;
  };

  const onScrollTouchMoveCapture = (event: React.TouchEvent<HTMLDivElement>) => {
    handleScrollTouchMove(event);
  };

  const onScrollTouchMove = (event: TouchEvent) => {
    if (!handleScrollTouchMove(event)) return;

    const touch = event.changedTouches[0];

    const dX = touch.clientX - touchInitialPosition.x;
    const dY = touch.clientY - touchInitialPosition.y;

    // NOTE: свойство scrollingActive возможно еще не обновилось.
    // Такое может быть в начале жеста и пока непонятно, это свайп или скролл.
    if (isVerticalMove(dX, dY)) {
      // NOTE: если перемещение по вертикали больше, чем по горизонтали, скорее всего пользователь осуществляет скролл.
      // Отмена свайпа.
      event.stopPropagation();
    }
  };

  const onScrollTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setScrollingIsArmed(true);
    const touch = e.changedTouches[0];
    setTouchInitialPosition({ x: touch.clientX, y: touch.clientY });

    if (swipingIsActive) {
      // NOTE: предотвращение скролла, если уже осуществляется свайп.
      e.preventDefault();
    }
  };

  const onUserScroll = () => {
    // NOTE: Отмечаем что scroll выполняется пользователем и позицию нужно сохранить в range.
    setScrolling(true);
    if (swipingIsActive) {
      // NOTE: происходит одновременно скролл и свайп (оба действия преодолели порог срабатывания примерно в одно время).
      // В Chrome нельзя отменить скролл через e.preventDefault(), поэтому отменяется свайп.
      setForcePreventSwiping(true);
    }

    // NOTE: Проверка предотвращает повторный переход в режим прокрутки, когда тач уже завершился и скролл происходит по инерции.
    if (scrollingIsArmed && !swipingIsActive) {
      setScrollingIsActive(true);
    }
  };

  const onScrollTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (scrollingIsActive) {
      // NOTE: предотвращает открытие меню сообщения в конце скролла.
      e.stopPropagation();
    }

    setForcePreventSwiping(false);
  };

  const onScrollTouchEndCapture = (e: React.TouchEvent<HTMLDivElement>) => {
    if (scrollingIsActive) {
      // NOTE: предотвращает случайный свайп сообщений по горизонтали, если пользователь уже начал скроллинг.
      // Используется фаза срабатывания события Capture, чтобы оно не доходило до дочерних элементов списка.
      e.stopPropagation();
    }

    setScrollingIsActive(false);
    setScrollingIsArmed(false);
  };

  useEffect(() => {
    scrollRef?.current?.addEventListener(WHEEL_EVENT_TYPE, onMouseWheel, { passive: false });
    return () => scrollRef?.current?.removeEventListener(WHEEL_EVENT_TYPE, onMouseWheel);
  }, [swipingIsActive, scrollingIsActive]);

  useEffect(() => {
    scrollRef?.current?.addEventListener(TOUCH_MOVE_EVENT_TYPE, onScrollTouchMove, { passive: false });
    return () => scrollRef?.current?.removeEventListener(TOUCH_MOVE_EVENT_TYPE, onScrollTouchMove);
  }, [swipingIsActive, scrollingIsActive, touchInitialPosition]);

  const getItemKey = (index: number) => {
    const item = messageItems[index];
    return item ? item.entity.activity.id : index;
  };

  const estimateSize = (index: number) => {
    const entityItem = messageItems[index];
    if (!messageRange?.virtualItems.length) {
      return estimateMessageHeight(entityItem);
    }

    const shift = messageRange.virtualItems[0].index;
    if (index < shift) {
      return estimateMessageHeight(entityItem);
    }

    const virtualItem = messageRange.virtualItems[index - shift];
    return virtualItem?.size || estimateMessageHeight(entityItem);
  };

  const getScrollElement = () => scrollRef.current || null;

  const virtualizer = useVirtualizer({
    overscan: 5,
    count: messageStats?.itemCount || messageItems.length,
    initialOffset: messageRange?.scrollOffset,
    initialMeasurementsCache: messageRange?.virtualItems,
    scrollPaddingStart: PADDING_SIZE,
    scrollPaddingEnd: PADDING_SIZE,
    paddingStart: PADDING_SIZE,
    paddingEnd: PADDING_SIZE,
    getItemKey,
    estimateSize,
    getScrollElement,
  });

  useEffect(() => {
    // NOTE: При монтировании компонента происходит scroll с offset = 0 поэтому игнорируем его.
    if (!scrolling) {
      return;
    }

    if (!onMessageScroll) {
      return;
    }

    const scroll: IEntityScroll = {
      virtualItems: virtualizer.getVirtualItems(),
      scrollOffset: virtualizer.scrollOffset,
      totalSize: virtualizer.getTotalSize(),
    };
    onMessageScroll(scroll);
  }, [virtualizer.scrollOffset]);

  useEffect(() => {
    if (!messagePosition) {
      return;
    }

    virtualizer.scrollToIndex(messagePosition.index, { align: 'start' });
  }, [messagePosition]);

  useEffect(() => {
    if (!newMessageIsInternal && quotedMessages?.some((q) => q.entity.direction === InboxDirection.Internal)) {
      setNewMessageIsInternal(true);
    }
  }, [quotedMessages]);

  const onMessageInputSend = (text: string, mentions: InboxMentionModel[], quotedMessages: InboxMessageModel[]) => {
    if (!chatId) {
      return;
    }

    if (!onMessageSend) {
      return;
    }

    const utcNow = moment().toISOString();
    onMessageSend({
      id: '',
      tenantId: '',
      createdOn: utcNow,
      type: InboxMessageType.Content,
      name: '',
      status: InboxMessageStatus.Pending,
      direction: newMessageIsInternal ? InboxDirection.Internal : InboxDirection.Outbound,
      timestamp: utcNow,
      content: { text, type: TEXT_FORMAT_TYPES.plain },
      record: { id: '' },
      activity: { id: '' },
      chat: { id: chatId },
      session: { id: '' },
      participants: [],
      assignments: [],
      attachments: [],
      mentions: mentions,
      quotedMessages: quotedMessages,
      external: {},
      senderParticipant: {
        id: '',
        status: InboxMessageParticipantStatus.Undefined,
        subject: { id: '', role: '', fullName: '', shortName: '' },
      },
    });
  };

  const onAttachmentInputUpload = (file: RcFile) => {
    if (!chatId) {
      return;
    }

    if (!onAttachmentUpload) {
      return;
    }

    onAttachmentUpload({
      id: '',
      tenantId: '',
      createdOn: moment().toISOString(),
      file: {
        id: file.uid,
        name: file.name,
        path: '',
        size: file.size,
        title: '',
        extension: '',
        mimeType: file.type,
        mediaType: InboxFileMediaType.Unknown,
        mediaGroup: InboxFileMediaGroup.Unknown,
        category: InboxFileCategory.Unknown,
        metadata: { properties: {} },
        content: [{ file }],
      },
      thumbnails: [],
      external: { id: '' },
      record: { id: '' },
      activity: { id: '' },
      chat: { id: chatId },
    });
  };

  const onIsInternalChanged = () => {
    // NOTE: нельзя цитировать внутренние сообщения в общедоступном сообщении.
    if (newMessageIsInternal && quotedMessages?.some((q) => q.entity.direction === InboxDirection.Internal)) {
      return;
    }

    setNewMessageIsInternal(!newMessageIsInternal);
  };

  const onListItemSwipingIsActiveChanged = (isSwiping: boolean) => {
    setSwipingIsActive(isSwiping);
    setScrollingIsActive(false);
    setScrollingIsArmed(false);
  };

  const renderMessageItem = (virtualItem: VirtualItem) => {
    const entityItem = messageItems[virtualItem.index];
    if (!entityItem) {
      return null;
    }

    const enrichedItem = {
      ...entityItem,
      entity: {
        ...entityItem.entity,
        participants:
          entityItem.entity.participants.map((p) => ({
            ...p,
            subject: {
              ...p.subject,
              avatar: participants.find((participant) => participant.id === p.id)?.subject.person.avatar,
            },
          })) || [],
      },
    };

    const onMessageItemResend = () => onMessageResend?.(entityItem.entity, virtualItem);
    const onMessageItemDelete = () => onMessageDelete?.(entityItem.entity, entityItem.operation, virtualItem);

    // NOTE: сообщения хранятся в обратном порядке.
    const nextMessage = messageItems[virtualItem.index - 1];

    return (
      <div key={virtualItem.key} ref={virtualizer.measureElement} data-index={virtualItem.index}>
        {renderMessage?.(currentUserId, enrichedItem, virtualItem) || (
          <IbMessageListItem
            channelId={channelId}
            currentUserId={currentUserId}
            entityItem={enrichedItem}
            forcePreventSwiping={forcePreventSwiping}
            mentionLinksEnabled={mentionLinksEnabled}
            sameSenderAsNextMessage={
              nextMessage?.entity.senderParticipant?.id === entityItem.entity.senderParticipant?.id
            }
            virtualItem={virtualItem}
            onAttachmentDelete={onAttachmentDelete}
            onAttachmentPreview={onAttachmentPreview}
            onAttachmentReUpload={onAttachmentReUpload}
            onMessageDelete={onMessageItemDelete}
            onMessageQuote={onQuotedMessageAdd}
            onMessageResend={onMessageItemResend}
            onQuotedMessageClick={onQuotedMessageClick}
            onSwipingIsActiveChanged={onListItemSwipingIsActiveChanged}
          />
        )}
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={CONTENT_CLASS_NAME}>
        <div
          ref={scrollRef}
          className={SCROLL_CLASS_NAME}
          style={{ padding: `0px ${PADDING_SIZE}px` }}
          onScroll={onUserScroll}
          onTouchEnd={onScrollTouchEnd}
          onTouchEndCapture={onScrollTouchEndCapture}
          onTouchMoveCapture={onScrollTouchMoveCapture}
          onTouchStart={onScrollTouchStart}
        >
          <div className={CANVAS_CLASS_NAME} style={{ height: `${virtualizer.getTotalSize()}px` }}>
            <div
              className={WINDOW_CLASS_NAME}
              style={{ transform: `translateY(${virtualizer.getVirtualItems()[0]?.start || 0}px)` }}
            >
              {virtualizer.getVirtualItems().map(renderMessageItem)}
            </div>
          </div>
        </div>
      </div>
      <div className={FOOTER_CLASS_NAME}>
        <IbMessageInput
          attachments={attachmentItems}
          currentUserId={currentUserId}
          isInternal={newMessageIsInternal}
          mentionLinksEnabled={mentionLinksEnabled}
          messageInputRef={messageInputRef}
          operatorList={operatorList}
          operatorListLoading={operatorListLoading}
          quotedMessages={quotedMessages}
          tariffLimitExceeded={tariffLimitExceeded}
          onAttachmentDelete={onAttachmentDelete}
          onAttachmentPreview={onAttachmentPreview}
          onAttachmentReUpload={onAttachmentReUpload}
          onAttachmentUpload={onAttachmentInputUpload}
          onIsInternalChanged={onIsInternalChanged}
          onMessageSend={onMessageInputSend}
          onOperatorsSearch={onOperatorsSearch}
          onQuotedMessageClick={onQuotedMessageClick}
          onQuotedMessageDelete={onQuotedMessageDelete}
        />
      </div>
    </div>
  );
};

export default IbMessageList;
