import React, { CSSProperties } from 'react';
import { Radio } from 'antd';

import './index.less';

interface SbRadioButtonGroupProps {
  bordered?: boolean;
  style?: CSSProperties;
}

const SbRadioButtonGroup: React.FC<SbRadioButtonGroupProps> = ({ bordered = true, style, children }) => {
  const classes = ['sb-radio-button-group'];
  bordered && classes.push('bordered');
  return (
    <Radio.Group className={classes.join(' ')} style={style}>
      {children}
    </Radio.Group>
  );
};

export default SbRadioButtonGroup;
