import React, { ChangeEventHandler } from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const STATUS_DEFAULT = 'default';
const MAIN_CLASS_NAME = 'ib-textarea';
const DISABLED_CLASS_NAME = `${MAIN_CLASS_NAME}_disabled`;

export interface IIbTextAreaProps {
  className?: string;
  value?: null | string | number;
  status?: 'default' | 'success' | 'error';
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
  onChange?: (value: string) => void;
  onBlur?: () => void;
}

const IbTextArea: React.FC<IIbTextAreaProps> = ({
  className,
  value,
  status = STATUS_DEFAULT,
  disabled,
  placeholder,
  rows,
  onChange,
  onBlur,
}) => {
  status = getDefaultIfUndefined(status, STATUS_DEFAULT);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${status}`, disabled ? DISABLED_CLASS_NAME : null];
  className && classes.push(className);

  const onChangeInternal: ChangeEventHandler<HTMLTextAreaElement> = (event) => onChange?.(event.target.value);

  return (
    <div className={classes.join(' ')}>
      <textarea
        disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        value={value || undefined}
        onBlur={onBlur}
        onChange={onChangeInternal}
      />
    </div>
  );
};

export default IbTextArea;
