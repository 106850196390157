import React, {
  forwardRef,
  HTMLProps,
  MouseEventHandler,
  Fragment,
  useState,
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  KeyboardEventHandler,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Input, Menu } from 'antd';
import { Key } from 'ts-key-enum';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { BindingSchema, DefaultActionGroupSchema, DefaultTriggerGroupSchema } from '../../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  groupDraggingSelector,
  groupPositionsSelector,
  selectedEntitySelector,
} from '../../../../../recoil/scenarioStructure';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import ContextMenu from '../ContextMenu';
import {
  deleteBindingWithReferences,
  getElementId,
  instanceOfBindingSchema,
  instanceOfDefaultActionGroupSchema,
  instanceOfDefaultTriggerGroupSchema,
  instanceOfTransitSchema,
  tryGetElementById,
} from '../../../utils';

interface GroupContainerProps extends HTMLProps<HTMLDivElement> {
  group: DefaultTriggerGroupSchema | DefaultActionGroupSchema;
  childSelected?: boolean;
}

const GroupContainer = forwardRef<HTMLDivElement, GroupContainerProps>(
  ({ children, group, childSelected, onMouseLeave, onMouseEnter }, ref) => {
    const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
    const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
    const position = useRecoilValue(groupPositionsSelector(group.id));
    const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);
    const setGroupDraggingInfo = useSetRecoilState(groupDraggingSelector);

    const isTriggerGroup = instanceOfDefaultTriggerGroupSchema(group);

    const defaultTitle = group.$designer?.name || group.name || (isTriggerGroup ? 'Триггер' : '');
    const [title, setTitle] = useState(defaultTitle);
    const [titleIsEditing, setTitleIsEditing] = useState(false);

    const onGroupContainerMouseDown = () => setSelectedEntity(group);

    const onGroupHeaderMouseDown: MouseEventHandler = (e) => {
      setGroupDraggingInfo({
        dragStartPosition: { positionX: 0, positionY: 0, screenX: e.screenX, screenY: e.screenY },
        group,
      });
    };
    const onGroupHeaderClick = () => {
      setGroupDraggingInfo(undefined);
    };

    const onDeleteGroupMenuSelect = () => {
      if (!instanceOfDefaultActionGroupSchema(group)) return;
      if (!scenarioStructure) return;

      const newScenarioStructure = cloneDeep(scenarioStructure);
      newScenarioStructure.actionGroups = newScenarioStructure.actionGroups.filter((ag) => ag.id !== group.id);

      // NOTE: удаляем связи самой группы
      (group.inputBindingIds || []).forEach((bindingId) => {
        deleteBindingWithReferences(newScenarioStructure, bindingId);
      });
      deleteBindingWithReferences(newScenarioStructure, group.outputBindingId);

      // NOTE: удаляем связи действий внутри группы
      group.actions.forEach((action) => {
        if (instanceOfTransitSchema(action)) {
          action.buttons.forEach((btn) => {
            deleteBindingWithReferences(newScenarioStructure, btn.outputBindingId);
          });
        }
        deleteBindingWithReferences(newScenarioStructure, action.outputBindingId);
      });

      setScenarioStructure(newScenarioStructure);
    };

    const onGroupHeaderDoubleClick: MouseEventHandler<HTMLDivElement> = () => {
      setTitleIsEditing(true);
    };

    const onTitleInputBlur = () => {
      setTitleIsEditing(false);

      if (!instanceOfDefaultActionGroupSchema(group)) return;
      if (!scenarioStructure) return;

      const newScenarioStructure = cloneDeep(scenarioStructure);

      const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
      if (!foundGroup || foundGroup.$designer?.name === title) return;

      if (foundGroup.$designer) {
        foundGroup.$designer.name = title;
      } else {
        foundGroup.$designer = {
          id: foundGroup.id,
          name: title,
        };
      }

      setScenarioStructure(newScenarioStructure);
    };

    const onTitleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      setTitle(e.target.value);
    };

    const onTitleInputFocus: FocusEventHandler<HTMLInputElement> = (e) => {
      e.target.select();
    };

    const onTitleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === Key.Enter) {
        onTitleInputBlur();
        return;
      }
      if (e.key === Key.Escape) {
        setTitle(defaultTitle);
        setTitleIsEditing(false);
      }
    };

    const onTitlePencilClick = () => {
      setTitleIsEditing(true);
    };

    const renderGroupHeader = () => {
      const titleClasses = ['group-title'];

      if (!title || (scenarioStructure?.version && scenarioStructure.version < '1.1.0' && title === 'Новая группа')) {
        titleClasses.push('group-title_empty');
      }

      return (
        <Fragment>
          <div className="group-icon">
            <SbIcon iconName="vertical-tidy-up" />
          </div>
          <div className={titleClasses.join(' ')}>
            {titleIsEditing ? (
              <Input
                autoFocus
                value={title}
                onBlur={onTitleInputBlur}
                onChange={onTitleInputChange}
                onFocus={onTitleInputFocus}
                onKeyDown={onTitleInputKeyDown}
              />
            ) : (
              <>
                <SbIcon className="group-title__pencil" iconName="pencil" onClick={onTitlePencilClick} />
                <span title={title}>{title}</span>
              </>
            )}
          </div>
          <ContextMenu
            menuContent={
              <Menu>
                <Menu.Item key="delete" onClick={onDeleteGroupMenuSelect}>
                  <SbIcon iconName="close" />
                </Menu.Item>
              </Menu>
            }
          />
        </Fragment>
      );
    };

    const renderTriggerGroupHeader = () => {
      return (
        <Fragment>
          <div className="group-icon">
            <SbIcon iconName="lightning" />
          </div>
          <div className="group-title" title={title}>
            {title}
          </div>
        </Fragment>
      );
    };

    useEffect(() => {
      setTitle(defaultTitle);
    }, [defaultTitle]);

    const isSelected = group.id === selectedEntity?.id;

    let isInputBindingSelected = false;
    let isOutputBindingSelected = false;
    if (selectedEntity && instanceOfBindingSchema(selectedEntity)) {
      isInputBindingSelected = group.id === selectedEntity.targetEntityId;
      isOutputBindingSelected = group.id === selectedEntity.sourceEntityId;
    }

    let isRelatedEntitySelected = false;
    if (selectedEntity?.outputBindingId && scenarioStructure) {
      const binding = tryGetElementById(scenarioStructure, selectedEntity.outputBindingId) as BindingSchema;
      isRelatedEntitySelected = binding && binding.targetEntityId === group.id;
    }

    const classes = ['group-container'];
    if (isSelected || isInputBindingSelected || isOutputBindingSelected || isRelatedEntitySelected) {
      classes.push('group-container_selected');
    }
    if (scenarioValidation?.hasIssue(group.id, '')) {
      classes.push('group-container_warning');
    }
    if (isTriggerGroup) {
      classes.push('group-container_is-trigger-group');
    }
    if (childSelected) {
      classes.push('group-container_child-selected');
    }

    return (
      <div
        ref={ref}
        className={classes.join(' ')}
        id={getElementId(group.id)}
        style={{
          left: position.positionX,
          top: position.positionY,
        }}
        onMouseDown={onGroupContainerMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className="group-container__header"
          onClick={onGroupHeaderClick}
          onDoubleClick={onGroupHeaderDoubleClick}
          onMouseDown={onGroupHeaderMouseDown}
        >
          {isTriggerGroup ? renderTriggerGroupHeader() : renderGroupHeader()}
        </div>
        {children}
      </div>
    );
  }
);

export default GroupContainer;
