import { DefaultValue, selector, selectorFamily } from 'recoil';

import {
  ActionGroupSchema,
  BotSettingsSchema,
  BotStageType,
  DefaultScenarioSchema,
  EntitySchema,
  GroupEntryModel,
  IntentEntryModel,
  ScenarioEditionReferenceModel,
  ScenarioSchema,
  SingleBotModel,
  VariableSchema,
  VariableScope,
} from '../../../api';
import {
  ICapturedBindingInfo,
  IEntityPosition,
  IGroupDraggingInfo,
  IInputConnectionPosition,
  IOutputConnectionPosition,
  IPosition,
  IScenarioEntity,
  IWorkingSpaceTransform,
} from '../../components/ScenarioEditor/types';
import { groupsMarginX, groupsMarginY, groupWidth } from '../../components/ScenarioEditor/constants';
import { IValidatedStructure, IValidationIssue } from '../../simple-bot/utils/validation';
import { currentBotStageTypeSelector } from '../../simple-bot/recoil';

import {
  getVariableUsages,
  getGroupPosition,
  trySaveScenarioStructureToLocalStorage,
  getScenarioActions,
} from './utils';
import {
  capturedBindingState,
  currentScenarioStructureState,
  currentScenarioValidationResultState,
  dispatcherState,
  draggingBindingCurrentPositionState,
  draggingBindingStartPositionState,
  dragSourceState,
  dragTargetState,
  groupDraggingState,
  groupPlaceholderPositionState,
  groupPlaceholderPossiblePositionState,
  groupPositionsState,
  inputConnectionPositionsState,
  outputConnectionPositionsState,
  scenarioStructureStackIndexState,
  scenarioStructureStackState,
  selectedEntityState,
  systemIntentsState,
  workingSpaceTransformState,
  zoomState,
  workingSpaceTransformAnimationState,
  groupsMenuIsVisibleState,
  selectedValidationIssueState,
  systemIntentGroupsState,
  zoomForDraggableState,
  botState,
} from './atom';

const STACK_SIZE = 100;

export const systemIntentsSelector = selector<IntentEntryModel[]>({
  key: 'systemIntentsSelector',
  get: ({ get }) => get(systemIntentsState),
  set: ({ set }, newSystemIntents) => set(systemIntentsState, newSystemIntents),
});

export const systemIntentGroupsSelector = selector<GroupEntryModel[]>({
  key: 'systemIntentGroupsSelector',
  get: ({ get }) => get(systemIntentGroupsState),
  set: ({ set }, newSystemIntentGroups) => set(systemIntentGroupsState, newSystemIntentGroups),
});

export const botSelector = selector<SingleBotModel | undefined>({
  key: 'botSelector',
  get: ({ get }) => get(botState),
  set: ({ set }, newBot) => set(botState, newBot),
});

export const currentBotStageIdSelector = selector<string | undefined>({
  key: 'currentBotStageIdSelector',
  get: ({ get }) => {
    const bot = get(botSelector);
    if (!bot) return undefined;

    const currentBotStageType = get(currentBotStageTypeSelector(bot.entry.id));
    const isDraft = currentBotStageType === BotStageType.Draft;
    return isDraft ? bot.draftStage?.id : bot.originStage.id;
  },
});

export const currentScenarioStructureSelector = selector<DefaultScenarioSchema | undefined>({
  key: 'currentScenarioStructureSelector',
  get: ({ get }) => get(currentScenarioStructureState),
  set: ({ get, set }, newStructure) => {
    set(currentScenarioStructureState, newStructure);

    const currentBotStageId = get(currentBotStageIdSelector);
    trySaveScenarioStructureToLocalStorage(currentBotStageId, newStructure as ScenarioSchema);

    if (newStructure instanceof DefaultValue) return;

    let stack = [...get(scenarioStructureStackState)];
    const stackIndex = get(scenarioStructureStackIndexState);

    stack.splice(stackIndex + 1);
    stack.push(newStructure);
    stack = stack.slice(-STACK_SIZE);

    set(scenarioStructureStackState, stack);
    set(scenarioStructureStackIndexState, stack.length - 1);
  },
});

export const currentScenarioValidationResultSelector = selector<IValidatedStructure | undefined>({
  key: 'currentScenarioValidationResultSelector',
  get: ({ get }) => get(currentScenarioValidationResultState),
  set: ({ set }, newValue) => set(currentScenarioValidationResultState, newValue),
});

export const scenarioStructureStackSelectorCanUndo = selector<boolean>({
  key: 'scenarioStructureStackSelectorCanUndo',
  get: ({ get }) => {
    const stack = get(scenarioStructureStackState);
    const stackIndex = get(scenarioStructureStackIndexState);

    return !!stack[stackIndex - 1];
  },
});

export const scenarioStructureStackSelectorCanRedo = selector<boolean>({
  key: 'scenarioStructureStackSelectorCanRedo',
  get: ({ get }) => {
    const stack = get(scenarioStructureStackState);
    const stackIndex = get(scenarioStructureStackIndexState);

    return !!stack[stackIndex + 1];
  },
});

export const scenarioStructureStackSelector = selector<(DefaultScenarioSchema | undefined)[]>({
  key: 'scenarioStructureStackSelector',
  get: ({ get }) => get(scenarioStructureStackState),
  set: ({ get, set }, newStack) => {
    if (newStack instanceof DefaultValue) {
      const currentScenarioStructure = get(currentScenarioStructureState);
      set(scenarioStructureStackIndexState, 0);
      set(scenarioStructureStackState, [currentScenarioStructure]);
      return;
    }

    set(scenarioStructureStackState, newStack);
  },
});

export const scenariosSelector = selector<ScenarioEditionReferenceModel[]>({
  key: 'scenariosSelector',
  get: ({ get }) => {
    const bot = get(botSelector);
    if (!bot) return [];

    const currentBotStageType = get(currentBotStageTypeSelector(bot.entry.id));
    const isDraft = currentBotStageType === BotStageType.Draft;
    const botStage = isDraft ? bot.draftStage : bot.originStage;
    return botStage?.currentEdition?.scenarios || [];
  },
});

export const scenarioStructuresSelector = selector<DefaultScenarioSchema[]>({
  key: 'scenarioStructuresSelector',
  get: ({ get }) => {
    const bot = get(botSelector);
    if (!bot) return [];

    const currentScenarioStructure = get(currentScenarioStructureSelector);
    if (!currentScenarioStructure) return [];

    const currentBotStageType = get(currentBotStageTypeSelector(bot.entry.id));
    const isDraft = currentBotStageType === BotStageType.Draft;
    const botEdition = isDraft && bot.draftCurrentEdition ? bot.draftCurrentEdition : bot.originCurrentEdition;

    return botEdition.structure.scenarios
      .filter((structure) => structure.id !== currentScenarioStructure.id)
      .concat(currentScenarioStructure) as DefaultScenarioSchema[];
  },
});

export const botSettingsSelector = selector<BotSettingsSchema | undefined>({
  key: 'botSettingsSelector',
  get: ({ get }) => {
    const bot = get(botSelector);
    if (!bot) return undefined;

    const currentBotStageType = get(currentBotStageTypeSelector(bot.entry.id));
    const isDraft = currentBotStageType === BotStageType.Draft;
    return isDraft ? bot.draftCurrentEdition?.structure.settings : bot.originCurrentEdition.structure.settings;
  },
});

export const workingSpaceTransformSelector = selector<IWorkingSpaceTransform>({
  key: 'workingSpaceTransformSelector',
  get: ({ get }) => get(workingSpaceTransformState),
});

export const workingSpaceTransformAnimationSelector = selector<boolean>({
  key: 'workingSpaceTransformAnimationSelector',
  get: ({ get }) => get(workingSpaceTransformAnimationState),
  set: ({ set }, newValue) => set(workingSpaceTransformAnimationState, newValue),
});

export const zoomSelector = selector<number>({
  key: 'zoomSelector',
  get: ({ get }) => get(zoomState),
});

export const zoomForDraggableSelector = selector<number>({
  key: 'zoomForDraggableSelector',
  get: ({ get }) => get(zoomForDraggableState),
  set: ({ set }, newValue) => set(zoomForDraggableState, newValue),
});

export const outputConnectionPositionsSelector = selectorFamily<IOutputConnectionPosition | undefined, string>({
  key: 'outputConnectionPositionsSelector',
  get: (key) => ({ get }) => get(outputConnectionPositionsState(key)),
});

export const inputConnectionPositionsSelector = selectorFamily<IInputConnectionPosition | undefined, string>({
  key: 'inputConnectionPositionsSelector',
  get: (key) => ({ get }) => get(inputConnectionPositionsState(key)),
});

export const groupPositionsSelector = selectorFamily<IPosition, string>({
  key: 'groupPositionsSelector',
  get: (key) => ({ get }) => {
    const position = get(groupPositionsState(key));
    if (position) return position;

    const structure = get(currentScenarioStructureSelector);

    if (structure?.triggerGroup.id === key) {
      return getGroupPosition(structure?.triggerGroup, 0);
    }

    const group = structure?.actionGroups.find((g) => g.id === key);
    if (!group) {
      // eslint-disable-next-line no-console
      console.warn(`Группа (id=${key}) не найдена`);
      return { positionX: 0, positionY: 0 };
    }

    const index = structure?.actionGroups.indexOf(group) || 0;
    return getGroupPosition(group, index + 1);
  },
  set: (key) => ({ set }, newGroupPosition) => {
    set(groupPositionsState(key), newGroupPosition);
  },
});

export const selectedEntitySelector = selector<EntitySchema | undefined>({
  key: 'selectedEntitySelector',
  get: ({ get }) => get(selectedEntityState),
  set: ({ set, get }, newSelectedEntity) => {
    if (!(newSelectedEntity instanceof DefaultValue) && get(selectedEntityState)?.id === newSelectedEntity?.id) {
      return;
    }

    set(selectedEntityState, newSelectedEntity);

    if (newSelectedEntity && !(newSelectedEntity instanceof DefaultValue)) {
      const validationIssues = get(currentScenarioValidationResultState)?.issueMap.get(newSelectedEntity.id);
      if (validationIssues?.length) {
        validationIssues?.length && set(selectedValidationIssueState, validationIssues[0]);
        return;
      }
    }

    set(selectedValidationIssueState, undefined);
  },
});

export const selectedValidationIssueSelector = selector<IValidationIssue | undefined>({
  key: 'selectedValidationIssueSelector',
  get: ({ get }) => get(selectedValidationIssueState),
  set: ({ set, get }, newSelectedValidationIssue) => {
    if (
      !(newSelectedValidationIssue instanceof DefaultValue) &&
      get(selectedValidationIssueState) === newSelectedValidationIssue
    ) {
      return;
    }

    set(selectedValidationIssueState, newSelectedValidationIssue);

    if (!newSelectedValidationIssue || newSelectedValidationIssue instanceof DefaultValue) {
      return;
    }

    set(selectedEntityState, newSelectedValidationIssue.entity);
  },
});

export const dragSourceSelector = selector<EntitySchema | undefined>({
  key: 'dragSourceSelector',
  get: ({ get }) => get(dragSourceState),
  set: ({ set }, newDragSource) => {
    set(dragSourceState, newDragSource);
  },
});

export const dragTargetSelector = selector<EntitySchema | undefined>({
  key: 'dragTargetSelector',
  get: ({ get }) => get(dragTargetState),
  set: ({ set }, newDragTarget) => {
    set(dragTargetState, newDragTarget);
  },
});

export const groupDraggingSelector = selector<IGroupDraggingInfo | undefined>({
  key: 'groupDraggingSelector',
  get: ({ get }) => get(groupDraggingState),
  set: ({ get, set }, newGroupDraggingState) => {
    if (newGroupDraggingState) {
      const newGroupDraggingStateValue = newGroupDraggingState as IGroupDraggingInfo;
      const groupPosition = get(groupPositionsSelector(newGroupDraggingStateValue.group.id));
      newGroupDraggingStateValue.dragStartPosition.positionX = groupPosition.positionX;
      newGroupDraggingStateValue.dragStartPosition.positionY = groupPosition.positionY;
      set(groupDraggingState, newGroupDraggingStateValue);
      return;
    }

    set(groupDraggingState, undefined);
  },
});

export const draggingBindingStartPositionSelector = selector<IEntityPosition | undefined>({
  key: 'draggingBindingStartPositionSelector',
  get: ({ get }) => get(draggingBindingStartPositionState),
  set: ({ set }, newPosition) => {
    set(draggingBindingStartPositionState, newPosition);
  },
});

export const draggingBindingCurrentPositionSelector = selector<IPosition | undefined>({
  key: 'draggingBindingCurrentPositionSelector',
  get: ({ get }) => get(draggingBindingCurrentPositionState),
  set: ({ get, set }, newPosition) => {
    if (!newPosition || newPosition instanceof DefaultValue) {
      set(draggingBindingCurrentPositionState, undefined);
      return;
    }

    const dragSource = get(dragSourceSelector);
    const dragStartPosition = get(draggingBindingStartPositionState);

    // если нажали на dragHandle
    if (dragSource?.id && dragStartPosition) {
      // и начали тащить
      if (
        newPosition.positionX !== dragStartPosition.positionX ||
        newPosition.positionY !== dragStartPosition.positionY
      ) {
        const scenarioStructure = get(currentScenarioStructureSelector);
        const existingBinding = scenarioStructure?.bindings.find((binding) => binding.sourceEntityId === dragSource.id);
        // то если в этом dragHandle уже есть исходящая линия, удаляем ее
        if (existingBinding) {
          const { deleteBinding } = get(dispatcherState);
          deleteBinding(existingBinding.id).finally();
        }
      }
    }

    set(draggingBindingCurrentPositionState, newPosition);
  },
});

export const capturedBindingSelector = selector<ICapturedBindingInfo | undefined>({
  key: 'capturedBindingSelector',
  get: ({ get }) => get(capturedBindingState),
  set: ({ set }, capturedBindingInfo) => {
    set(capturedBindingState, capturedBindingInfo);
  },
});

export const groupPlaceholderPositionSelector = selector<IPosition | undefined>({
  key: 'groupPlaceholderPositionSelector',
  get: ({ get }) => get(groupPlaceholderPositionState),
  set: ({ set }, newPosition) => set(groupPlaceholderPositionState, newPosition),
});

export const groupPlaceholderPossiblePositionSelector = selector<IPosition | undefined>({
  key: 'groupPlaceholderPossiblePositionSelector',
  get: ({ get }) => get(groupPlaceholderPossiblePositionState),
  set: ({ set }, newPosition) => set(groupPlaceholderPossiblePositionState, newPosition),
});

export const groupsSelector = selector<ActionGroupSchema[]>({
  key: 'groupsSelector',
  get: ({ get }) => {
    const scenarioStructure = get(currentScenarioStructureSelector);
    return scenarioStructure?.actionGroups || [];
  },
});

export const positionForNewGroupSelector = selector<IPosition>({
  key: 'positionForNewGroupSelector',
  get: ({ get }) => {
    const result: IPosition = { positionX: 0, positionY: groupsMarginY };

    const scenarioStructure = get(currentScenarioStructureSelector);
    if (!scenarioStructure) return result;

    // NOTE: находим самую правую группу
    let offsetX = 0;
    const triggerGroupPosition = get(groupPositionsSelector(scenarioStructure.triggerGroup.id));
    offsetX = Math.max(offsetX, triggerGroupPosition.positionX + groupWidth);

    for (const group of scenarioStructure.actionGroups) {
      const groupPosition = get(groupPositionsSelector(group.id));
      offsetX = Math.max(offsetX, groupPosition.positionX + groupWidth);
    }

    result.positionX = offsetX + groupsMarginX;

    return result;
  },
});

export const groupsMenuIsVisibleSelector = selectorFamily<boolean, string>({
  key: 'groupsMenuIsVisibleSelector',
  get: (key) => ({ get }) => get(groupsMenuIsVisibleState(key)),
  set: (key) => ({ set }, newValue) => set(groupsMenuIsVisibleState(key), newValue),
});

export const variableUsagesSelector = selectorFamily<IScenarioEntity[], VariableSchema>({
  key: 'variableUsagesSelector',
  get: (key) => ({ get }) => {
    const variable = key;

    if (variable.scope == VariableScope.Scenario) {
      const currentScenarioStructure = get(currentScenarioStructureSelector);
      if (!currentScenarioStructure) return [];

      return getVariableUsages([currentScenarioStructure], variable);
    }

    const scenarioStructures = get(scenarioStructuresSelector);
    return getVariableUsages(scenarioStructures, variable);
  },
});

export const scenarioActionsSelector = selectorFamily<IScenarioEntity[], DefaultScenarioSchema>({
  key: 'scenarioActionsSelector',
  get: (key) => ({ get }) => {
    const scenario = key;
    const scenarioStructures = get(scenarioStructuresSelector);
    return getScenarioActions(scenarioStructures, scenario);
  },
});
