import React, { useState, useEffect } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { GroupUpdatingRequest } from '../../../api';
import { GroupModel } from '../../../api';
import { groupApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const GroupAdd: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState({} as GroupModel);
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const history = useHistory();

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await groupApi.getGroup(id);
      setGroup(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке группы',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const loadGroupToForm = () => {
    form.setFieldsValue({ name: group.name });
  };

  useEffect(loadGroupToForm, [group]);

  const onFinish = async () => {
    setLoading(true);

    const newGroup: GroupUpdatingRequest = {
      name: form.getFieldValue('name'),
    };

    try {
      await groupApi.updateGroup(id, newGroup);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Группа изменена',
        description: newGroup.name,
      });
      history.push(`/groups`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении группы',
        error: e,
      });
      setLoading(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/groups');
  };

  return (
    <div>
      <PageHeader title={`Группа ${group?.name || ''}`} />
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <Input placeholder="Название" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={onBackBtnClick}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GroupAdd;
