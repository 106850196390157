import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const TRIGGER_DEFAULT = undefined;
const PLACEMENT_DEFAULT = undefined;
const VISIBLE_DEFAULT = undefined;
const CONTENT_DEFAULT = undefined;
const ON_VISIBLE_CHANGE_DEFAULT = () => {};

const MAIN_CLASS_NAME = 'ib-popover';
export const POPOVER_MENU_CLASS_NAME = `${MAIN_CLASS_NAME}__menu`;

const MASK_CLASS_NAME = `${MAIN_CLASS_NAME}-mask`;
const MASK_VISIBLE_CLASS_NAME = `${MASK_CLASS_NAME}_visible`;

export interface IIbPopoverProps {
  className?: string;
  trigger?: string | string[];
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
  visible?: boolean;
  content?: React.ReactNode;
  onVisibleChange?: (visible?: boolean) => void;
}

const IbPopover: React.FC<IIbPopoverProps> = ({
  children,
  className,
  trigger,
  placement,
  visible,
  content,
  onVisibleChange = ON_VISIBLE_CHANGE_DEFAULT,
}) => {
  trigger = getDefaultIfUndefined(trigger, TRIGGER_DEFAULT);
  placement = getDefaultIfUndefined(placement, PLACEMENT_DEFAULT);
  visible = getDefaultIfUndefined(visible, VISIBLE_DEFAULT);
  content = getDefaultIfUndefined(content, CONTENT_DEFAULT);
  onVisibleChange = getDefaultIfUndefined(onVisibleChange, ON_VISIBLE_CHANGE_DEFAULT);

  const [maskVisible, setMaskVisible] = useState(visible || false);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  const maskClasses = [MASK_CLASS_NAME];
  maskVisible && maskClasses.push(MASK_VISIBLE_CLASS_NAME);

  const onPopoverMaskClick = () => {
    setMaskVisible(false);
    onVisibleChange(false);
  };

  const onInnerVisibleChange = (visible: boolean) => {
    setMaskVisible(visible);
    onVisibleChange(visible);
  };

  const onVisiblePropChange = () => setMaskVisible(visible || false);
  useEffect(onVisiblePropChange, [visible]);

  return (
    <>
      <Popover
        content={content}
        overlayClassName={classes.join(' ')}
        placement={placement}
        trigger={trigger}
        visible={visible}
        onVisibleChange={onInnerVisibleChange}
      >
        {children}
      </Popover>
      <div className={maskClasses.join(' ')} onClick={onPopoverMaskClick} />
    </>
  );
};

export default IbPopover;
