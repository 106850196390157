import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async-hook';

import { InstanceModel, PublishOperation, PublishStatus, PublishStatusState } from '../../../../../kb-api';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { hubConnections } from '../../../../utils/socketsUtil';
import { instanceKbApi } from '../../../../apis';
import { AlertTypes, INSTANCE_PUBLISHED, INSTANCE_STATUS_UPDATED } from '../../../../constants';
import SbProgress from '../../../components/common/SbProgress';

const MAX_PERCENT = 100;
const MIN_PERCENT = 0;

const isComplete = (status: PublishStatus) =>
  status.state === PublishStatusState.Success || status.state === PublishStatusState.Error;

const calcPercent = (status?: PublishStatus) => {
  if (!status?.state) return MIN_PERCENT;

  if (status.state === PublishStatusState.Pending) return MIN_PERCENT;
  if (isComplete(status)) return MAX_PERCENT;

  const calcOperationPercent = (operation?: PublishOperation) => {
    if (!operation) return MIN_PERCENT;

    const { currentProgress = MIN_PERCENT } = operation;
    const { maximumProgress = MIN_PERCENT } = operation;
    return maximumProgress === MIN_PERCENT ? MIN_PERCENT : Math.round((currentProgress / maximumProgress) * 100);
  };

  const percents = [
    calcOperationPercent(status.operations?.indexCreationOperation),
    calcOperationPercent(status.operations?.qnaEntriesUpdatingOperation),
    calcOperationPercent(status.operations?.reportGenerationOperation),
  ];
  const sumPercents = percents.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  return Math.round(sumPercents / percents.length);
};

interface IInstanceStatusProgressProps {
  instanceId: string;
  onPublishComplete: (instance: InstanceModel) => void;
  visible?: boolean;
}

const InstanceStatusProgress: React.FC<IInstanceStatusProgressProps> = ({
  instanceId,
  onPublishComplete,
  visible = true,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { result: conn } = useAsync(hubConnections.getKnowledgeBaseConnection, []);

  const [instance, setInstance] = useState<InstanceModel>();

  const percent = calcPercent(instance?.status);

  const loadInstanceDataAsync = async () => {
    try {
      const response = await instanceKbApi.getInstance(instanceId);
      const instance = response.data;
      setInstance(response.data);

      if (isComplete(instance.status)) {
        onPublishComplete(instance);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке экземпляра базы знаний',
        error: e,
      });
    }
  };

  const onInstanceIdPropChange = () => {
    loadInstanceDataAsync().finally();
  };
  useEffect(onInstanceIdPropChange, [instanceId]);

  const instanceEventHandler = (args: { instanceId: string }) => {
    if (instance?.id === args?.instanceId) {
      loadInstanceDataAsync().finally();
    }
  };
  const subscribeToInstanceEvents = () => {
    if (!instance || !conn) return;

    conn.on(INSTANCE_PUBLISHED, instanceEventHandler);
    conn.on(INSTANCE_STATUS_UPDATED, instanceEventHandler);

    return () => {
      conn.off(INSTANCE_PUBLISHED, instanceEventHandler);
      conn.off(INSTANCE_STATUS_UPDATED, instanceEventHandler);
    };
  };
  useEffect(subscribeToInstanceEvents, [conn, instance]);

  if (!visible) return null;

  return <SbProgress percent={percent} />;
};

export default InstanceStatusProgress;
