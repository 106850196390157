import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import { InboxChatModel, InboxChatParticipantSubjectModel, TagModel } from '../../../../api';
import IbAvatar from '../common/IbAvatar';
import IbTypography from '../common/IbTypography';
import IbSocial, { IbSocialType } from '../common/IbSocial';
import { formatDateTimeFull, formatLocation, formatPhoneNumber, getChannelName } from '../../../utils/stringUtil';
import IbTagsWidget from '../IbTagsWidget';

const MAIN_CLASS_NAME = 'ib-contact-info';
const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const CONTENT_BLOCK_CLASS_NAME = `${CONTENT_CLASS_NAME}__block`;
const BLOCK_ROW_CLASS_NAME = `${CONTENT_BLOCK_CLASS_NAME}__row`;
const CONTACT_TITLE_CLASS_NAME = `${CONTENT_BLOCK_CLASS_NAME}__contact-title`;
const TAGS_CLASS_NAME = `${CONTENT_BLOCK_CLASS_NAME}__tags`;

const INTERACTION_DISABLED_DEFAULT = false;

export interface IIbContactInfoProps {
  interactionDisabled?: boolean;
  allowTagsManagement?: boolean;
  chat?: InboxChatModel;
  contact?: InboxChatParticipantSubjectModel;
  operator?: InboxChatParticipantSubjectModel;
  tagList?: TagModel[];
  onEditContact?: () => Promise<void>;
  onEditContactTags?: (tagIds: string[]) => Promise<void>;
  onTagsManagement?: () => void;
  onBack?: () => void;
}

const IbContactInfo: React.FC<IIbContactInfoProps> = ({
  interactionDisabled = INTERACTION_DISABLED_DEFAULT,
  allowTagsManagement,
  chat,
  contact,
  operator,
  tagList,
  onEditContact,
  onEditContactTags,
  onTagsManagement,
  onBack,
}) => {
  interactionDisabled = getDefaultIfUndefined(interactionDisabled, INTERACTION_DISABLED_DEFAULT);
  chat = getDefaultIfUndefined(chat, undefined);
  contact = getDefaultIfUndefined(contact, undefined);
  operator = getDefaultIfUndefined(operator, undefined);

  const { t, i18n } = useTranslation();

  const onTagsChange = async (tagIds: string[]) => {
    await onEditContactTags?.(tagIds);
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={HEADER_CLASS_NAME}>
        <IbButton icon={<IbIcon iconName="arrow-left" />} type="icon" onClick={onBack} />
      </div>
      <div className={CONTENT_CLASS_NAME}>
        {!!contact && (
          <div className={CONTENT_BLOCK_CLASS_NAME}>
            <div className={CONTACT_TITLE_CLASS_NAME}>
              <IbAvatar
                imgSrc={contact.person.avatar}
                metadata={{ uid: contact.id, text: contact.person.name.fullName || '' }}
                size="large"
              />
              <IbTypography>
                <h4>{contact.person.name.fullName}</h4>
              </IbTypography>
            </div>
            {!!contact.person.name.nickName && (
              <div className={BLOCK_ROW_CLASS_NAME}>
                <IbTypography.Paragraph disabled type="secondary">
                  {t('Nickname')}
                </IbTypography.Paragraph>
                <IbTypography.Paragraph type="secondary">
                  <IbSocial social={chat?.channelId as IbSocialType} /> {contact.person.name.nickName}
                </IbTypography.Paragraph>
              </div>
            )}
            <div className={BLOCK_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Phone number')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatPhoneNumber(contact.person.contacts.primaryTel)}
              </IbTypography.Paragraph>
            </div>
            <div className={BLOCK_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('E-mail')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">{contact.person.contacts.email || '—'}</IbTypography.Paragraph>
            </div>
            <div className={BLOCK_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Country')}, {t('City')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatLocation(contact.person.location.country, contact.person.location.city, i18n)}
              </IbTypography.Paragraph>
            </div>
            <div className={TAGS_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Tags')}
              </IbTypography.Paragraph>
              <IbTagsWidget
                allowTagsManagement={allowTagsManagement}
                selectedTagIds={contact?.person.tags.map((t) => t.id)}
                tagList={tagList}
                onChange={onTagsChange}
                onTagsManagement={onTagsManagement}
              />
            </div>
            <IbButton
              disabled={interactionDisabled}
              icon={<IbIcon iconName="edit" />}
              type="fill"
              onClick={onEditContact}
            >
              {t('Edit (contact)')}
            </IbButton>
          </div>
        )}
        <div className={CONTENT_BLOCK_CLASS_NAME}>
          <IbTypography>
            <h4>{t('About chat')}</h4>
          </IbTypography>
          <div className={BLOCK_ROW_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="secondary">
              {t('Channel')}
            </IbTypography.Paragraph>
            <IbTypography.Paragraph type="secondary">
              <IbSocial social={chat?.channelId as IbSocialType} />{' '}
              {chat?.channel?.displayName || getChannelName(chat?.channelId)}
            </IbTypography.Paragraph>
          </div>
          <div className={BLOCK_ROW_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="secondary">
              {t('Creation date')}
            </IbTypography.Paragraph>
            <IbTypography.Paragraph type="secondary">
              {formatDateTimeFull(chat?.createdOn, i18n.language)}
            </IbTypography.Paragraph>
          </div>
          <div className={BLOCK_ROW_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="secondary">
              {t('Operator')}
            </IbTypography.Paragraph>
            <IbTypography.Paragraph type="secondary">{operator?.person.name.fullName || '—'}</IbTypography.Paragraph>
          </div>
          {!!chat?.liveChatConversationState?.startPageTitle && (
            <div className={BLOCK_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Page title')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {chat.liveChatConversationState.startPageTitle}
              </IbTypography.Paragraph>
            </div>
          )}
          {!!chat?.liveChatConversationState?.startPageUrl && (
            <div className={BLOCK_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Page Url')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {chat.liveChatConversationState.startPageUrl}
              </IbTypography.Paragraph>
            </div>
          )}
          {!!chat?.assignedOperatorGroup && (
            <div className={BLOCK_ROW_CLASS_NAME}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Group')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">{chat.assignedOperatorGroup.name}</IbTypography.Paragraph>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IbContactInfo;
