import { ConditionSchema, OperandSchema, VariableSchema, VariableType } from '../../../../../../../api';
import {
  instanceOfBinaryConditionSchema,
  instanceOfUnaryConditionSchema,
  instanceOfVariableOperandSchema,
} from '../../../../utils';

export type SelectItem = { value: string; label: string; type: VariableType };

export const SessionProfileSelectItems = [
  {
    value: 'authenticated',
    label: 'Аутентифицирован',
    type: VariableType.Boolean,
  },
  {
    value: 'fullName',
    label: 'ФИО',
    type: VariableType.PersonName,
  },
  {
    value: 'contacts',
    label: 'Контакты',
    type: VariableType.PersonContacts,
  },
];

export const PersonNameSelectItems = [
  {
    value: 'last',
    label: 'Фамилия',
    type: VariableType.String,
  },
  {
    value: 'first',
    label: 'Имя',
    type: VariableType.String,
  },
  {
    value: 'middle',
    label: 'Отчество',
    type: VariableType.String,
  },
];

export const PersonContactsSelectItems = [
  {
    value: 'email',
    label: 'E-mail',
    type: VariableType.String,
  },
  {
    value: 'phone',
    label: 'Телефон',
    type: VariableType.String,
  },
];

export const getVariableSelectItems = (variables?: VariableSchema[]): SelectItem[] =>
  (variables || []).map((v) => ({
    value: v.id,
    label: v.name,
    type: v.type,
  }));

export const getVariableSubSelectItems = (type: VariableType): SelectItem[] => {
  switch (type) {
    case VariableType.SessionProfile:
      return SessionProfileSelectItems;
    case VariableType.PersonName:
      return PersonNameSelectItems;
    case VariableType.PersonContacts:
      return PersonContactsSelectItems;
    default:
      return [];
  }
};

export const getVariableTreeSelectItems = (variables?: VariableSchema[]): SelectItem[] => {
  return [
    ...getVariableSelectItems(variables),
    ...SessionProfileSelectItems,
    ...PersonNameSelectItems,
    ...PersonContactsSelectItems,
  ];
};

export const getVariableListSelectItems = (variables?: VariableSchema[]): SelectItem[] => {
  const result = [...getVariableSelectItems(variables)];
  for (const variable of variables || []) {
    switch (variable.type) {
      case VariableType.SessionProfile:
        result.push(
          ...SessionProfileSelectItems.map((v) => ({
            value: `${variable.id}.${v.value}`,
            label: `${variable.name}.${v.label}`,
            type: v.type,
          }))
        );
        result.push(
          ...PersonNameSelectItems.map((v) => ({
            value: `${variable.id}.${SessionProfileSelectItems[1].value}.${v.value}`,
            label: `${variable.name}.${SessionProfileSelectItems[1].label}.${v.label}`,
            type: v.type,
          }))
        );
        result.push(
          ...PersonContactsSelectItems.map((v) => ({
            value: `${variable.id}.${SessionProfileSelectItems[2].value}.${v.value}`,
            label: `${variable.name}.${SessionProfileSelectItems[2].label}.${v.label}`,
            type: v.type,
          }))
        );
        break;

      case VariableType.PersonName:
        result.push(
          ...PersonNameSelectItems.map((v) => ({
            value: `${variable.id}.${v.value}`,
            label: `${variable.name}.${v.label}`,
            type: v.type,
          }))
        );
        break;
    }
  }
  return result;
};

export const tryGetVariableType = (
  entity: ConditionSchema | OperandSchema,
  selectItems: SelectItem[]
): VariableType | undefined => {
  const operands = [];
  if (instanceOfVariableOperandSchema(entity)) {
    operands.push(entity);
  }
  if (instanceOfUnaryConditionSchema(entity)) {
    operands.push(entity.operand);
  }
  if (instanceOfBinaryConditionSchema(entity)) {
    operands.push(entity.leftOperand);
    operands.push(entity.rightOperand);
  }
  for (const operand of operands) {
    if (!instanceOfVariableOperandSchema(operand)) {
      continue;
    }
    if (!operand.variableId) {
      continue;
    }
    const selectValue = operand.propertyPath ? `${operand.variableId}.${operand.propertyPath}` : operand.variableId;
    const selectItem = selectItems.find((i) => i.value === selectValue);
    if (selectItem) {
      return selectItem.type;
    }
  }
  return undefined;
};
