import React, { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import {
  BinaryConditionOperator,
  SchemaKind,
  ValidatorSchema,
  VariableOwner,
  VariableSchema,
  VariableScope,
  VariableState,
  VariableType,
} from '../../../../../api';
import SbModal from '../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../simple-bot/components/common/SbButton';
import SbTypography from '../../../../simple-bot/components/common/SbTypography';
import SbPanel from '../../../../simple-bot/components/common/SbPanel';
import {
  EntityFieldPath,
  generateId,
  instanceOfDateTimeTypeValidator,
  instanceOfDateTimeValueComparisonValidator,
  instanceOfLengthComparisonValidator,
  instanceOfNumberValueComparisonValidator,
} from '../../utils';
import { translitVariableName } from '../../../../utils/stringUtil';
import SbInput from '../../../../simple-bot/components/common/SbInput';
import SbSelect from '../../../../simple-bot/components/common/SbSelect';
import { VisibleVariableScopes, VisibleVariableTypes } from '../../constants';
import SbScroll from '../../../../simple-bot/components/common/SbScroll';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import { validateEntities } from '../../../../simple-bot/utils/validation';
import { IndexedStructure } from '../../../../simple-bot/utils/indexation';
import SbTooltip from '../../../../simple-bot/components/common/SbTooltip';

import NumberValueComparison from './NumberValueComparison';
import LengthComparison from './LengthComparison';
import DateTimeValidation from './DateTimeValidation';

const MODAL_WIDTH = 866;

const validatorCheckers = new Map<VariableType, (validator: ValidatorSchema) => boolean>([
  [VariableType.String, instanceOfLengthComparisonValidator],
  [VariableType.Number, instanceOfNumberValueComparisonValidator],
  [
    VariableType.DateTime,
    (validator: ValidatorSchema) =>
      instanceOfDateTimeTypeValidator(validator) || instanceOfDateTimeValueComparisonValidator(validator),
  ],
]);

interface IVariableEditorProps {
  visible: boolean;
  variables: VariableSchema[];
  variable?: VariableSchema;
  onChange?: (variable: VariableSchema) => void;
  onClose?: () => void;
}

const VariableEditor: React.FC<IVariableEditorProps> = ({
  visible,
  variables,
  variable,
  onChange = () => {},
  onClose = () => {},
}) => {
  const [variableId, setVariableId] = useState('');
  const [variableType, setVariableType] = useState<VariableType>();
  const [variableScope, setVariableScope] = useState<VariableScope>();
  const [variableName, setVariableName] = useState<string>();
  const [variableValidators, setVariableValidators] = useState([] as ValidatorSchema[]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setVariableId(variable?.id || generateId('VAR'));
    setVariableType(variable?.type);
    setVariableScope(variable?.scope);
    setVariableName(variable?.name);
    setVariableValidators(cloneDeep(variable?.validators || []));
  }, [visible, variable]);

  const isAcceptedValidator = (validator: ValidatorSchema) => {
    return variableType ? validatorCheckers.get(variableType)?.(validator) : false;
  };

  const canHaveValidators = () => {
    return variableType && validatorCheckers.has(variableType);
  };

  const filterValidators = () => {
    return variableValidators.filter(isAcceptedValidator);
  };

  const createValidator = () => {
    if (variableType === VariableType.String) {
      return {
        id: generateId('VAL'),
        $kind: SchemaKind.LengthComparisonValidator,
        operator: BinaryConditionOperator.LessOrEquals,
        length: 100,
      };
    }
    if (variableType === VariableType.Number) {
      return {
        id: generateId('VAL'),
        $kind: SchemaKind.NumberValueComparisonValidator,
        operator: BinaryConditionOperator.Greater,
        value: 0,
      };
    }
    if (variableType === VariableType.DateTime) {
      return {
        id: generateId('VAL'),
        $kind: SchemaKind.DateTimeValueComparisonValidator,
        operator: BinaryConditionOperator.Equals,
        value: moment().format(moment.HTML5_FMT.DATE),
      };
    }
    return null;
  };

  const getCurrentVariable = (): VariableSchema => {
    const translatedVariableName = translitVariableName(variableName);
    return {
      $kind: SchemaKind.Variable,
      id: variableId,
      code: translatedVariableName,
      name: variableName || '',
      type: variableType || VariableType.String,
      scope: variableScope || VariableScope.Scenario,
      owner: VariableOwner.User,
      validators: filterValidators(),
      state: VariableState.Exists,
    };
  };

  const validateVariable = (currentVariable: VariableSchema) => {
    const indexedStructure = new IndexedStructure();
    variables.forEach((v) => indexedStructure.indexEntitySchema(v));
    if (!variable) {
      indexedStructure.indexEntitySchema(currentVariable);
    }
    return validateEntities(indexedStructure, [currentVariable]);
  };

  const isNameInputValid = () => {
    const currentVariable = getCurrentVariable();
    const validationResult = validateVariable(currentVariable);
    return (
      !validationResult.hasIssue(currentVariable.id, EntityFieldPath.Name) &&
      !validationResult.hasIssue(currentVariable.id, EntityFieldPath.Code)
    );
  };

  const getNameInputTooltip = () => (!isNameInputValid() ? 'Переменная с таким именем уже существует' : '');

  const canSaveVariable = () => {
    return variableType && variableScope && variableName && isNameInputValid();
  };

  const saveVariable = () => {
    const currentVariable = getCurrentVariable();
    onChange(currentVariable);
  };

  const onTypeSelectChange = (value: SelectValue) => {
    setVariableType(value as VariableType);
  };

  const onScopeSelectChange = (value: SelectValue) => {
    setVariableScope(value as VariableScope);
  };

  const onNameInputChange = (value: string) => {
    setVariableName(value);
  };

  const onValidatorChange = (validator: ValidatorSchema, index: number) => {
    setVariableValidators(
      variableValidators.map((v, i) => {
        return i === index ? validator : v;
      })
    );
  };

  const onValidatorDelete = (index: number) => {
    setVariableValidators(variableValidators.filter((_, i) => i !== index));
  };

  const onValidatorAdd = () => {
    const validator = createValidator();
    if (validator) {
      setVariableValidators([...variableValidators, validator]);
    }
  };

  const onSaveButtonClick = () => {
    saveVariable();
    onClose();
  };

  const onCancelButtonClick = () => {
    onClose();
  };

  const onModalOk = () => {
    saveVariable();
    onClose();
  };

  const onModalCancel = () => {
    onClose();
  };

  const renderValidators = () => {
    return filterValidators().map((validator, index) => {
      if (instanceOfNumberValueComparisonValidator(validator)) {
        return (
          <NumberValueComparison
            key={index}
            validator={validator}
            onChange={(v) => onValidatorChange(v, index)}
            onDelete={() => onValidatorDelete(index)}
          />
        );
      }
      if (instanceOfLengthComparisonValidator(validator)) {
        return (
          <LengthComparison
            key={index}
            validator={validator}
            onChange={(v) => onValidatorChange(v, index)}
            onDelete={() => onValidatorDelete(index)}
          />
        );
      }
      if (validatorCheckers.get(VariableType.DateTime)?.(validator)) {
        return (
          <DateTimeValidation
            key={index}
            validator={validator}
            onChange={(v) => onValidatorChange(v, index)}
            onDelete={() => onValidatorDelete(index)}
          />
        );
      }
      return null;
    });
  };

  return (
    <SbModal
      destroyOnClose
      footer={[
        <SbButton key="save" disabled={!canSaveVariable()} sbSize="medium" sbType="primary" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      maskClosable={false}
      sbSize="small"
      title={variable ? 'Настройка переменной' : 'Добавление переменной'}
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
      onOk={onModalOk}
    >
      <Row className="sb-modal__row-stretch" gutter={[48, 20]} wrap={false}>
        <Col className="sb-modal__col-main" span={14}>
          <SbScroll>
            <Row gutter={[0, 26]}>
              <Col span={24}>
                <SbTypography>
                  <h3>Тип переменной</h3>
                  <SbSelect
                    placeholder="Выберите"
                    sbSize="big"
                    sbType="light"
                    value={variableType}
                    onChange={onTypeSelectChange}
                  >
                    {VisibleVariableTypes.map((t) => (
                      <Select.Option key={t.value} value={t.value}>
                        {t.label}
                      </Select.Option>
                    ))}
                  </SbSelect>
                </SbTypography>
              </Col>
            </Row>
            <Row gutter={[0, 26]}>
              <Col span={24}>
                <SbTypography>
                  <h3>Область видимости</h3>
                  <SbSelect
                    placeholder="Выберите"
                    sbSize="big"
                    sbType="light"
                    value={variableScope}
                    onChange={onScopeSelectChange}
                  >
                    {VisibleVariableScopes.map((s) => (
                      <Select.Option key={s.value} value={s.value}>
                        {s.label}
                      </Select.Option>
                    ))}
                  </SbSelect>
                </SbTypography>
              </Col>
            </Row>
            <Row gutter={[0, 11]}>
              <Col span={24}>
                <SbTypography>
                  <h3>Имя переменной</h3>
                  <SbTooltip placement="right" title={getNameInputTooltip()} visible={visible && !isNameInputValid()}>
                    <SbInput
                      isValid={isNameInputValid()}
                      placeholder="Укажите"
                      sbSize="big"
                      value={variableName}
                      onChange={onNameInputChange}
                    />
                  </SbTooltip>
                  <div className="sb-modal__subtitle">
                    <h3>
                      {canHaveValidators()
                        ? 'Ограничения'
                        : 'Ограничения поддерживаются только для типов "Дата/Время", "Текст" и "Число"'}
                    </h3>
                    {canHaveValidators() ? (
                      <div className="sb-modal__subtitle-actions">
                        <SbButton sbSize="medium" sbType="tertiary" onClick={onValidatorAdd}>
                          <SbIcon iconName="plus" size={16} />
                          Добавить ограничение
                        </SbButton>
                      </div>
                    ) : null}
                  </div>
                </SbTypography>
              </Col>
            </Row>
            <Row className="sb-modal__row-stretch" gutter={[0, 0]} wrap={false}>
              <Col span={24}>
                <SbTypography>{renderValidators()}</SbTypography>
              </Col>
            </Row>
          </SbScroll>
        </Col>
        <Col span={10}>
          <SbPanel sbType="help">
            <SbTypography>
              <h3>Ограничение ввода данных</h3>
              <p>
                Ограничение, которое вы здесь укажете, будет распространяться на ввод данных пользователем. Например,
                если вы настроите, что дата должна быть не позже, чем вчера, а пользователь введет сегодняшнюю дату, то
                бот ему предложит выбрать другую дату.
              </p>
              <h3>Переменные</h3>
              <p>
                Значение, введенное в этом поле, по умолчанию сохраняется в новую переменную. Вы можете ее переименовать
                или выбрать другую переменную из полного списка.
              </p>
            </SbTypography>
          </SbPanel>
        </Col>
      </Row>
    </SbModal>
  );
};

export default VariableEditor;
