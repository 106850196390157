import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import { Key } from 'ts-key-enum';

import './index.less';

import IbIcon from '../IbIcon';
import IbButton from '../IbButton';
import IbAvatar from '../IbAvatar';
import IbTypography from '../IbTypography';
import { BotAvatarLogo } from '../../../assets';
import { IbSocialType } from '../IbSocial';
import { formatDateTimeLong } from '../../../../utils/stringUtil';
import { ICarouselItem } from '../../../recoil';
import { InboxMessageParticipantModel } from '../../../../../api';
import IbSpin from '../IbSpin';
import { SUBJECT_ROLES } from '../../../../constants';

import Carousel from './Carousel';

const MAIN_CLASS_NAME = 'ib-preview-modal';
const OVERLAY_CLASS_NAME = `${MAIN_CLASS_NAME}__overlay`;
const BACKGROUND_CLASS_NAME = `${MAIN_CLASS_NAME}__background`;
const PREVIEW_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__preview-container`;
const PREVIEW_CONTAINER_FULL_HEIGHT_CLASS_NAME = `${PREVIEW_CONTAINER_CLASS_NAME}_full-height`;
const PREVIEW_CONTAINER_LOADING_CLASS_NAME = `${PREVIEW_CONTAINER_CLASS_NAME}_loading`;
const PREVIEW_CLASS_NAME = `${PREVIEW_CONTAINER_CLASS_NAME}__preview`;
const CONTROLS_CLASS_NAME = `${MAIN_CLASS_NAME}__controls`;
const CONTROLS_TOP_ROW_CLASS_NAME = `${CONTROLS_CLASS_NAME}__top-row`;
const CONTROLS_MIDDLE_ROW_CLASS_NAME = `${CONTROLS_CLASS_NAME}__middle-row`;
const LEFT_RIGHT_BUTTON_WRAPPER_CLASS_NAME = `${CONTROLS_MIDDLE_ROW_CLASS_NAME}__wrapper`;
const CONTROLS_BOTTOM_ROW_CLASS_NAME = `${CONTROLS_CLASS_NAME}__bottom-row`;
const CONTROLS_MOBILE_CLASS_NAME = `${CONTROLS_CLASS_NAME}_mobile`;
const CONTROLS_MOBILE_HIDDEN_CLASS_NAME = `${CONTROLS_MOBILE_CLASS_NAME}_hidden`;
const CONTROLS_TOP_ROW_MOBILE_CLASS_NAME = `${CONTROLS_MOBILE_CLASS_NAME}__top-row`;
const CONTROLS_TOP_ROW_MOBILE_SENDER_CLASS_NAME = `${CONTROLS_TOP_ROW_MOBILE_CLASS_NAME}__sender`;
const CONTROLS_BOTTOM_ROW_MOBILE_CLASS_NAME = `${CONTROLS_MOBILE_CLASS_NAME}__bottom-row`;
const CONTROLS_CAROUSEL_MOBILE_CLASS_NAME = `${CONTROLS_BOTTOM_ROW_MOBILE_CLASS_NAME}__carousel`;
const CONTROLS_TOOLBAR_MOBILE_CLASS_NAME = `${CONTROLS_BOTTOM_ROW_MOBILE_CLASS_NAME}__toolbar`;
const SENDER_CLASS_NAME = `${CONTROLS_BOTTOM_ROW_CLASS_NAME}__sender`;
const CAROUSEL_CLASS_NAME = `${CONTROLS_BOTTOM_ROW_CLASS_NAME}__carousel`;
const TOOLBAR_CLASS_NAME = `${CONTROLS_BOTTOM_ROW_CLASS_NAME}__toolbar`;
const SENDER_INFO_CLASS_NAME = `${SENDER_CLASS_NAME}__info`;

export interface IIbPreviewModalProps {
  isOpen: boolean;
  items?: ICarouselItem[];
  selectedIndex?: number;
  senderParticipant?: InboxMessageParticipantModel;
  timestamp?: string;
  channelId?: string;
  onClose: () => void;
  onReply: () => void;
}

const IbPreviewModal: React.FC<IIbPreviewModalProps> = ({
  isOpen,
  items,
  selectedIndex,
  senderParticipant,
  timestamp,
  channelId,
  onClose,
  onReply,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedItemIndex, setSelectedItemIndex] = useState(selectedIndex || 0);
  const [itemsLoadedState, setItemsLoadedState] = useState<boolean[]>([]);
  const [mobileControlsHidden, setMobileControlsHidden] = useState(false);

  const canLeftClick = selectedItemIndex > 0;
  const canRightClick = selectedItemIndex < (items?.length ?? 0) - 1;

  const isBotMessage = senderParticipant?.subject.role === SUBJECT_ROLES.botAccount;

  const onBackgroundClick = () => {
    onClose();
  };

  const onLeftClick = () => {
    canLeftClick && setSelectedItemIndex(selectedItemIndex - 1);
  };

  const onRightClick = () => {
    canRightClick && setSelectedItemIndex(selectedItemIndex + 1);
  };

  const onReplyClick = () => {
    onReply();
    onClose();
  };

  const onImageClick = () => {
    setMobileControlsHidden(!mobileControlsHidden);
  };

  const onImageLoad = () => {
    setItemsLoadedState(itemsLoadedState.map((value, index) => (index === selectedItemIndex ? true : value)));
  };

  const onCarouselItemSelect = (index: number) => {
    setSelectedItemIndex(index);
  };

  useEffect(() => {
    if (!isOpen) return;

    setSelectedItemIndex(selectedIndex ?? 0);
    setMobileControlsHidden(false);
  }, [isOpen]);

  useEffect(() => {
    setItemsLoadedState(items?.map(() => false) ?? []);
  }, [items]);

  const handleUserKeyPress = useCallback(
    (event) => {
      if (!isOpen) return;
      const { key } = event;
      switch (key) {
        case Key.ArrowLeft:
          onLeftClick();
          break;
        case Key.ArrowRight:
          onRightClick();
          break;
      }
    },
    [isOpen, selectedItemIndex]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  const handlers = useSwipeable({
    onSwipedLeft: () => onRightClick(),
    onSwipedRight: () => onLeftClick(),
  });

  const imageUrl = items?.[selectedItemIndex ?? 0]?.url ?? '';
  const multipleImages = (items?.length ?? 0) > 1;
  const selectedImageLoaded = itemsLoadedState[selectedItemIndex];

  const previewContainerClasses = [PREVIEW_CONTAINER_CLASS_NAME];
  !senderParticipant && items?.length === 1 && previewContainerClasses.push(PREVIEW_CONTAINER_FULL_HEIGHT_CLASS_NAME);
  !selectedImageLoaded && previewContainerClasses.push(PREVIEW_CONTAINER_LOADING_CLASS_NAME);

  const mobileControlsClasses = [CONTROLS_MOBILE_CLASS_NAME];
  mobileControlsHidden && mobileControlsClasses.push(CONTROLS_MOBILE_HIDDEN_CLASS_NAME);

  return (
    <Modal
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      className={MAIN_CLASS_NAME}
      isOpen={isOpen}
      overlayClassName={OVERLAY_CLASS_NAME}
      onRequestClose={onClose}
    >
      <div className={BACKGROUND_CLASS_NAME} onClick={onBackgroundClick} />
      <div className={previewContainerClasses.join(' ')} {...handlers}>
        {!selectedImageLoaded && <IbSpin />}
        <img
          alt=""
          className={PREVIEW_CLASS_NAME}
          role="presentation"
          src={imageUrl}
          onClick={onImageClick}
          onLoad={onImageLoad}
        />
      </div>
      <div className={CONTROLS_CLASS_NAME}>
        <div className={CONTROLS_TOP_ROW_CLASS_NAME}>
          <IbButton icon={<IbIcon iconName="close" />} type="icon" onClick={onClose} />
        </div>
        <div className={CONTROLS_MIDDLE_ROW_CLASS_NAME}>
          <div className={LEFT_RIGHT_BUTTON_WRAPPER_CLASS_NAME}>
            {canLeftClick && <IbButton icon={<IbIcon iconName="left" />} type="icon" onClick={onLeftClick} />}
          </div>
          <div className={LEFT_RIGHT_BUTTON_WRAPPER_CLASS_NAME}>
            {canRightClick && <IbButton icon={<IbIcon iconName="right" />} type="icon" onClick={onRightClick} />}
          </div>
        </div>
        <div className={CONTROLS_BOTTOM_ROW_CLASS_NAME}>
          {senderParticipant && (
            <div className={SENDER_CLASS_NAME}>
              {isBotMessage ? (
                <IbAvatar dark size="medium">
                  <BotAvatarLogo />
                </IbAvatar>
              ) : (
                <IbAvatar
                  dark
                  imgSrc={senderParticipant.subject.avatar}
                  size="medium"
                  social={channelId as IbSocialType}
                />
              )}
              <div className={SENDER_INFO_CLASS_NAME}>
                <IbTypography.Paragraph strong>
                  {isBotMessage ? t('Bot') : senderParticipant.subject.fullName}
                </IbTypography.Paragraph>

                {timestamp && (
                  <IbTypography.Paragraph type="secondary">
                    {formatDateTimeLong(timestamp, i18n.language)}
                  </IbTypography.Paragraph>
                )}
              </div>
            </div>
          )}
          <div className={CAROUSEL_CLASS_NAME}>
            {multipleImages && (
              <Carousel items={items ?? []} selectedIndex={selectedItemIndex} onSelect={onCarouselItemSelect} />
            )}
          </div>
          {senderParticipant && (
            <div className={TOOLBAR_CLASS_NAME}>
              <IbButton icon={<IbIcon iconName="next" />} type="icon" onClick={onReplyClick} />
              <a href={imageUrl}>
                <IbButton icon={<IbIcon iconName="download" />} type="icon" />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className={mobileControlsClasses.join(' ')}>
        <div className={CONTROLS_TOP_ROW_MOBILE_CLASS_NAME}>
          <div>
            <IbButton icon={<IbIcon iconName="arrow-left" />} type="icon" onClick={onClose} />
          </div>
          <div className={CONTROLS_TOP_ROW_MOBILE_SENDER_CLASS_NAME}>
            {(senderParticipant || isBotMessage) && (
              <IbTypography.Paragraph strong>
                {isBotMessage ? t('Bot') : senderParticipant?.subject.fullName}
              </IbTypography.Paragraph>
            )}
            {timestamp && (
              <IbTypography.Paragraph type="secondary">
                {formatDateTimeLong(timestamp, i18n.language)}
              </IbTypography.Paragraph>
            )}
          </div>
          <div />
        </div>

        <div className={CONTROLS_BOTTOM_ROW_MOBILE_CLASS_NAME}>
          <div className={CONTROLS_CAROUSEL_MOBILE_CLASS_NAME}>
            <div className={CAROUSEL_CLASS_NAME}>
              {multipleImages && (
                <Carousel items={items ?? []} selectedIndex={selectedItemIndex} onSelect={onCarouselItemSelect} />
              )}
            </div>
          </div>
          <div className={CONTROLS_TOOLBAR_MOBILE_CLASS_NAME}>
            {senderParticipant && <IbButton icon={<IbIcon iconName="next" />} type="icon" onClick={onReplyClick} />}
            <IbTypography.Paragraph type="secondary">
              {items?.length} {t('Photo.short')}
            </IbTypography.Paragraph>
            {senderParticipant && (
              <a href={imageUrl}>
                <IbButton icon={<IbIcon iconName="download" />} type="icon" />
              </a>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IbPreviewModal;
