import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { AxiosError } from 'axios';

import SbButton from '../../../../components/common/SbButton';
import SbIcon from '../../../../components/common/SbIcon';
import { elma365MetadataApi } from '../../../../../apis';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { AlertTypes } from '../../../../../constants';

enum CheckStates {
  PENDING = 'PENDING',
  CHECKING = 'CHECKING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface IElma365IntegrationCheckButtonProps {
  apiUrl?: string;
  xToken?: string;
}

const Elma365IntegrationCheckButton: React.FC<IElma365IntegrationCheckButtonProps> = ({ apiUrl, xToken }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [checkState, setCheckState] = useState(CheckStates.PENDING);

  const onCheckButtonClick = async () => {
    if (!apiUrl || !xToken) return;

    setCheckState(CheckStates.CHECKING);
    try {
      await elma365MetadataApi.getElma365NamespaceSchemeList(apiUrl, xToken);
      setCheckState(CheckStates.SUCCESS);
    } catch (e) {
      const errorDetail = (e as AxiosError).response?.data?.detail;
      const message =
        errorDetail.includes('401') || errorDetail.includes('Unauthorized')
          ? 'Нет доступа в API. Неверный Aдрес API или X-Token'
          : errorDetail.includes('null')
          ? 'Адрес API недоступен'
          : 'Ошибка при проверке настроек интеграции';
      addAlert({
        type: AlertTypes.ERROR,
        message,
        description: errorDetail || 'Внутренняя ошибка сервера',
      });
      setCheckState(CheckStates.ERROR);
    }
  };

  useEffect(() => setCheckState(CheckStates.PENDING), [apiUrl, xToken]);

  if (checkState === CheckStates.PENDING) {
    return (
      <SbButton sbSize="medium" sbType="tertiary" onClick={onCheckButtonClick}>
        Проверить настройки
      </SbButton>
    );
  }

  if (checkState === CheckStates.CHECKING) {
    return (
      <SbButton disabled icon={<SbIcon spin iconName="loading-four" size={16} />} sbSize="medium" sbType="tertiary">
        Идет проверка
      </SbButton>
    );
  }

  if (checkState === CheckStates.SUCCESS) {
    return (
      <SbButton
        className="sb-bot-settings-modal__integrations__check-success"
        icon={<SbIcon iconName="check" size={16} />}
        sbSize="medium"
        sbType="tertiary"
        onClick={onCheckButtonClick}
      >
        Настройки верны
      </SbButton>
    );
  }

  if (checkState === CheckStates.ERROR) {
    return (
      <SbButton
        className="sb-bot-settings-modal__integrations__check-error"
        icon={<SbIcon iconName="close" size={16} />}
        sbSize="medium"
        sbType="tertiary"
        onClick={onCheckButtonClick}
      >
        Возникла ошибка при проверке настроек
      </SbButton>
    );
  }

  return null;
};

export default Elma365IntegrationCheckButton;
