import moment from 'moment';
import React from 'react';

import { BinarySourceFormat, KnowledgeSourceModel, KnowledgeSourceType } from '../../../kb-api';
import { ExcelIcon, GoogleDocIcon, GoogleSheetIcon, WordIcon } from '../assets';
import { ANALYTICS_DATE_FORMATS } from '../const';

export const getKnowledgeSourceIcon = (knowledgeSource: KnowledgeSourceModel): JSX.Element | null => {
  if (knowledgeSource.type === KnowledgeSourceType.GoogleDocUrl) {
    return <GoogleDocIcon />;
  }

  if (knowledgeSource.type === KnowledgeSourceType.GoogleSpreadSheetUrl) {
    return <GoogleSheetIcon />;
  }

  if (
    knowledgeSource.type === KnowledgeSourceType.Binary &&
    (knowledgeSource.binarySource?.format === BinarySourceFormat.Xls ||
      knowledgeSource.binarySource?.format === BinarySourceFormat.Xlsx ||
      knowledgeSource.binarySource?.format === BinarySourceFormat.Csv)
  ) {
    return <ExcelIcon />;
  }

  if (
    knowledgeSource.type === KnowledgeSourceType.Binary &&
    (knowledgeSource.binarySource?.format === BinarySourceFormat.Doc ||
      knowledgeSource.binarySource?.format === BinarySourceFormat.Docx)
  ) {
    return <WordIcon />;
  }

  return null;
};

export const formatAnalyticsDateCompact = (value: string): string =>
  moment(value, ANALYTICS_DATE_FORMATS.parse).format(ANALYTICS_DATE_FORMATS.viewCompact);

export const formatAnalyticsDate = (value: string): string =>
  moment(value, ANALYTICS_DATE_FORMATS.parse).format(ANALYTICS_DATE_FORMATS.view);

export const formatAnalyticsPercent = (decimal: number): string => {
  const percent = (decimal * 100).toFixed(0);
  return +percent > 0 ? `${percent}%` : '0';
};
