import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState } from 'recoil';
import './index.less';

import { DefaultActionGroupSchema, DefaultScenarioSchema, MessageSchema } from '../../../../../api';
import { tryGetElementById } from '../../utils';
import { currentScenarioStructureSelector } from '../../../../recoil/scenarioStructure';
import { IMessagesContainer } from '../../types';
import MessagesEditor from '../common/MessagesEditor';

interface MessagesProps {
  group: DefaultActionGroupSchema;
  action: IMessagesContainer;
  placeholder: string;
}

const Messages: React.FC<MessagesProps> = ({ action, placeholder }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const findAction = (scenarioStructure: DefaultScenarioSchema): IMessagesContainer => {
    const foundAction = tryGetElementById(scenarioStructure, action.id);
    return (foundAction as unknown) as IMessagesContainer;
  };

  const onMessagesChanged = (newMessages: MessageSchema[]) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = findAction(newScenarioStructure);
    foundAction.messages = newMessages;

    setScenarioStructure(newScenarioStructure);
  };

  return (
    <MessagesEditor
      actionId={action.id}
      messages={action.messages}
      placeholder={placeholder}
      onMessagesChange={onMessagesChanged}
    />
  );
};

export default Messages;
