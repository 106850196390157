import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import { TagModel, TagStatus } from '../../../../api';
import IbButton from '../common/IbButton';
import { includesIgnoreCase, parseTranslateTransform } from '../../../utils/stringUtil';
import IbInput from '../common/IbInput';
import IbTag from '../common/IbTag';
import IbIcon from '../common/IbIcon';
import IbTypography from '../common/IbTypography';
import { EmptySearchLogo } from '../../assets';

import {
  DROPPABLE_ID,
  EMPTY_CONTENT_CLASS_NAME,
  TAG_LIST_CLASS_NAME,
  TAG_LIST_ITEM_ACTIONS_CLASS_NAME,
  TAG_LIST_ITEM_CLASS_NAME,
  TAG_LIST_ITEM_DRAGGING_CLASS_NAME,
  TAG_LIST_ITEM_DRAG_DISABLED_CLASS_NAME,
  TAG_LIST_ITEM_HIDDEN_CLASS_NAME,
  TAG_LIST_ITEM_TAG_CONTAINER_CLASS_NAME,
} from './const';

export interface ITagListProps {
  tagList?: TagModel[];
  onReorder: (sourceIndex: number, destinationIndex: number) => Promise<void>;
  onVisibilityToggle: (tag: TagModel) => Promise<void>;
  onEdit: (tag: TagModel) => void;
  onDelete: (tag: TagModel) => void;
}

const TagList: React.FC<ITagListProps> = ({ tagList, onReorder, onVisibilityToggle, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');

  const filteredTagList = (tagList || []).filter((tag) => includesIgnoreCase(tag.label, search));
  const isDragDisabled = !!search;
  const searchResultIsEmpty = !filteredTagList.length && !!tagList?.length;

  const onVisibilityButtonClick = (tag: TagModel) => async () => await onVisibilityToggle(tag);

  const onEditButtonClick = (tag: TagModel) => () => onEdit(tag);

  const onDeleteButtonClick = (tag: TagModel) => () => onDelete(tag);

  const onDragEnd = (result: DropResult) => {
    if (!tagList || !result.destination) {
      return;
    }

    onReorder(result.source.index, result.destination.index);
  };

  return (
    <>
      <IbInput placeholder={t('Tag search')} value={search} onChange={setSearch} />
      {searchResultIsEmpty ? (
        <div className={EMPTY_CONTENT_CLASS_NAME}>
          <EmptySearchLogo />
          <IbTypography.Paragraph disabled type="secondary">
            {t('Nothing was found.')}
            <br />
            {t('Change the request or clear the search field.')}
          </IbTypography.Paragraph>
        </div>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={DROPPABLE_ID}>
            {(provided) => (
              <ul {...provided.droppableProps} ref={provided.innerRef} className={TAG_LIST_CLASS_NAME}>
                {filteredTagList.map((tag, index) => (
                  <Draggable key={tag.id} draggableId={tag.id} index={index} isDragDisabled={isDragDisabled}>
                    {(provided, shapshot) => {
                      const { y } = parseTranslateTransform(provided.draggableProps.style?.transform);
                      const transform = `translate(0px, ${y}px)`;

                      const isHidden = tag.status === TagStatus.Hidden;

                      const itemClasses = [TAG_LIST_ITEM_CLASS_NAME];
                      isHidden && itemClasses.push(TAG_LIST_ITEM_HIDDEN_CLASS_NAME);
                      shapshot.isDragging && itemClasses.push(TAG_LIST_ITEM_DRAGGING_CLASS_NAME);
                      isDragDisabled && itemClasses.push(TAG_LIST_ITEM_DRAG_DISABLED_CLASS_NAME);

                      return (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={itemClasses.join(' ')}
                          style={{
                            ...provided.draggableProps.style,
                            transform,
                          }}
                        >
                          <IbIcon iconName="drag" />
                          <div className={TAG_LIST_ITEM_TAG_CONTAINER_CLASS_NAME}>
                            <IbTag content={tag.label} style={{ background: tag.color }} tooltipContent={tag.label} />
                          </div>
                          {!!tag.description && (
                            <IbTypography.Paragraph type="secondary">{tag.description}</IbTypography.Paragraph>
                          )}
                          <span className={TAG_LIST_ITEM_ACTIONS_CLASS_NAME}>
                            <IbButton
                              icon={<IbIcon iconName={isHidden ? 'preview-close-one' : 'preview-open'} />}
                              type="icon"
                              onClick={onVisibilityButtonClick(tag)}
                            />
                            <IbButton icon={<IbIcon iconName="edit" />} type="icon" onClick={onEditButtonClick(tag)} />
                            <IbButton
                              icon={<IbIcon iconName="delete" />}
                              type="icon"
                              onClick={onDeleteButtonClick(tag)}
                            />
                          </span>
                        </li>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default TagList;
