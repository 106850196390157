import React, { useState } from 'react';
import { SelectValue } from 'antd/lib/select';
import { useSetRecoilState } from 'recoil';
import { Select } from 'antd';

import { StageModel } from '../../../../../../kb-api';
import { ResultSelectionPriorityType, BotEditionModel, SchemaKind } from '../../../../../../api';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { ANSWER_SELECTION_PRIORITY_TYPES } from '../../../../const';
import { botStageApi, stageKbApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';
import SbTypography from '../../../../components/common/SbTypography';
import SbCheckbox from '../../../../components/common/SbCheckbox';
import SbButton from '../../../../components/common/SbButton';
import SbSlider from '../../../../components/common/SbSlider';
import SbSelect from '../../../../components/common/SbSelect';
import SbModal from '../../../../components/common/SbModal';

interface IStageSettingsModalProps {
  botStageId: string;
  botEdition: BotEditionModel;
  stage: StageModel;
  visible: boolean;
  onCancel: () => void;
  knowledgeBaseEnabled?: boolean;
  onDataChanged: () => Promise<void>;
}

const THRESHOLD_DEFAULT_VALUE = 0.8;
const THRESHOLD_MIN_VALUE = 0;
const THRESHOLD_MAX_VALUE = 1;
const THRESHOLD_STEP = 0.01;
const THRESHOLD_LOW_VALUE = 0.7;

const StageSettingsModal: React.FC<IStageSettingsModalProps> = ({
  botStageId,
  botEdition,
  stage,
  visible,
  onCancel,
  knowledgeBaseEnabled,
  onDataChanged,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const botSettings = botEdition.structure.settings;
  const knowledgeBaseBotSettings = botSettings.recognition.kb;
  const knowledgeBaseSettings = stage.recognizerSettings;

  const [searchParentsOnlyIfNoContextValue, setSearchParentsOnlyIfNoContextValue] = useState(
    knowledgeBaseBotSettings.searchParentsOnlyIfNoContext ??
      knowledgeBaseSettings?.searchParentsOnlyIfNoContext ??
      false
  );
  const [thresholdValue, setThresholdValue] = useState(
    knowledgeBaseBotSettings.threshold ?? knowledgeBaseSettings?.threshold ?? THRESHOLD_DEFAULT_VALUE
  );
  const [resultSelectionPriorityValue, setResultSelectionPriorityValue] = useState(
    botSettings.recognition.resultSelectionPriority ?? ResultSelectionPriorityType.Score
  );
  const [saving, setSaving] = useState(false);

  const onModalClose = () => {
    if (saving) return;
    onCancel();
  };

  const onSliderChange = (value: number) => {
    setThresholdValue(value);
  };

  const onCheckboxChange = () => setSearchParentsOnlyIfNoContextValue(!searchParentsOnlyIfNoContextValue);

  const onResultSelectionPriorityChange = (value: SelectValue) =>
    setResultSelectionPriorityValue(value as ResultSelectionPriorityType);

  const onModalSave = async () => {
    setSaving(true);
    try {
      await botStageApi.updateSettings(botStageId, {
        settings: {
          ...botSettings,
          id: botSettings.id,
          $kind: SchemaKind.BotSettings,
          recognition: {
            ...botSettings.recognition,
            kb: {
              enabled: knowledgeBaseEnabled,
              searchParentsOnlyIfNoContext: searchParentsOnlyIfNoContextValue,
              threshold: thresholdValue,
            },
            resultSelectionPriority: resultSelectionPriorityValue,
          },
        },
      });
      await onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении настроек бота',
        error: e,
      });
    }

    try {
      await stageKbApi.updateStage(stage.id, {
        recognizerSettings: {
          enabled: knowledgeBaseEnabled,
          searchParentsOnlyIfNoContext: searchParentsOnlyIfNoContextValue,
          threshold: thresholdValue,
        },
      });
      onCancel();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при обновлении настроек стадии базы знаний',
        error: e,
      });
    }
    setSaving(false);
  };

  const thresholdTipFormatter = (value?: number) => {
    if (value != undefined && value < THRESHOLD_LOW_VALUE) {
      return `Score: ${value}. Низкие значения могут привести к некорректной работе базы знаний`;
    }
    return `Score: ${value}`;
  };

  return (
    <SbModal
      destroyOnClose
      className="sb-knowledge-base-block__settings-modal"
      footer={[
        <SbButton key="confirm" loading={saving} sbSize="medium" onClick={onModalSave}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" disabled={saving} sbSize="medium" sbType="secondary" onClick={onModalClose}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройка Базы знаний"
      visible={visible}
      width={600}
      onCancel={onModalClose}
      onOk={onModalSave}
    >
      <SbTypography>
        <h3>Порог уверенности распознавания</h3>
        <small className="sb-knowledge-base-block__settings-modal__small-text">
          Минимально необходимое значение уверенности в том, что фраза похожа на искомую.
          <br />
          Если значение будет меньше порогового, то ответ не будет показан пользователю.
          <br />
          Возможные значения от 0 до 1, не рекомендуется устанавливать значение менее 0,7.
          <br />
          Чем больше значение, тем выше точность распознавания.
        </small>
      </SbTypography>
      <div className="sb-knowledge-base-block__settings-modal__slider">
        <span className="sb-knowledge-base-block__settings-modal__slider__text">Низкий</span>
        <SbSlider
          max={THRESHOLD_MAX_VALUE}
          min={THRESHOLD_MIN_VALUE}
          step={THRESHOLD_STEP}
          tipFormatter={thresholdTipFormatter}
          value={thresholdValue}
          onChange={onSliderChange}
        />
        <span className="sb-knowledge-base-block__settings-modal__slider__text">Высокий</span>
      </div>

      <div className="sb-knowledge-base-block__settings-modal__select">
        <SbTypography>
          <h3>Выбор ответа</h3>
          <SbSelect sbType="light" value={resultSelectionPriorityValue} onChange={onResultSelectionPriorityChange}>
            {ANSWER_SELECTION_PRIORITY_TYPES.map((s) => (
              <Select.Option key={s.value} value={s.value}>
                {s.label}
              </Select.Option>
            ))}
          </SbSelect>
        </SbTypography>
      </div>

      <SbCheckbox checked={searchParentsOnlyIfNoContextValue} onChange={onCheckboxChange}>
        Поиск без уточняющих вопросов
      </SbCheckbox>
    </SbModal>
  );
};

export default StageSettingsModal;
