import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { AgentStageAccountSetupResult, AgentStageAccountStatus, SetupStatus } from '../../../../../api';

enum ChannelStatus {
  DISABLED = 'DISABLED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  OK = 'OK',
}

const getChannelStatus = (enabledStatus: AgentStageAccountStatus, setupStatus: SetupStatus): ChannelStatus => {
  if (enabledStatus === AgentStageAccountStatus.Disabled) return ChannelStatus.DISABLED;

  switch (setupStatus) {
    case SetupStatus.Pending:
      return ChannelStatus.PENDING;
    case SetupStatus.Error:
      return ChannelStatus.ERROR;
    case SetupStatus.Success:
      return ChannelStatus.OK;
  }
};

const getChannelText = (status: ChannelStatus, t: (key: string) => string) => {
  switch (status) {
    case ChannelStatus.DISABLED:
      return t('Disabled');
    case ChannelStatus.PENDING:
      return t('Connecting...');
    case ChannelStatus.ERROR:
      return t('Error');
    case ChannelStatus.OK:
      return t('Connected');
  }
};

// TODO: использовать стили
const getChannelBackgroundColor = (status: ChannelStatus) => {
  switch (status) {
    case ChannelStatus.DISABLED:
      return '#E3E3E3';
    case ChannelStatus.PENDING:
      return '#FFE599';
    case ChannelStatus.ERROR:
      return '#ffaa99';
    case ChannelStatus.OK:
      return '#E3F5E6';
  }
};

// TODO: использовать стили
const getChannelTextColor = (status: ChannelStatus) => {
  switch (status) {
    case ChannelStatus.DISABLED:
      return '#A4A4A4';
    case ChannelStatus.PENDING:
      return '#997923';
    case ChannelStatus.ERROR:
      return '#993322';
    case ChannelStatus.OK:
      return '#48A15C';
  }
};

const MAIN_CLASS_NAME = 'ib-channel-status';
const X_SMALL_CLASS_NAME = `${MAIN_CLASS_NAME}_x-small`;
const MARKER_CLASS_NAME = `${MAIN_CLASS_NAME}__marker`;
const TEXT_CLASS_NAME = `${MAIN_CLASS_NAME}__text`;

interface IbChannelStatusProps {
  enabledStatus: AgentStageAccountStatus;
  setupResult: AgentStageAccountSetupResult;
}

const IbChannelStatus: React.FC<IbChannelStatusProps> = ({ enabledStatus, setupResult }) => {
  const { t } = useTranslation();

  const channelStatus = getChannelStatus(enabledStatus, setupResult.status ?? SetupStatus.Pending);

  const text = getChannelText(channelStatus, t);
  const backgroundColor = getChannelBackgroundColor(channelStatus);
  const textColor = getChannelTextColor(channelStatus);

  return (
    <div
      className={`${MAIN_CLASS_NAME} ${X_SMALL_CLASS_NAME}`}
      style={{ backgroundColor: backgroundColor, color: textColor }}
      title={
        setupResult.status === SetupStatus.Error && setupResult.errorMessage ? setupResult.errorMessage : undefined
      }
    >
      <div className={MARKER_CLASS_NAME} style={{ backgroundColor: textColor }} />
      <span className={TEXT_CLASS_NAME}>{text}</span>
    </div>
  );
};

export default IbChannelStatus;
