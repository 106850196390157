import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

interface IScenarioStartedProps {
  name: string | undefined | null;
}

const ScenarioStarted: React.FC<IScenarioStartedProps> = ({ name }) => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="play" size={16} />
      Запущен сценарий «{name}»
    </div>
  );
};

export default ScenarioStarted;
