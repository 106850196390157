import React from 'react';

import './index.less';

import SbWebChat from '../../components/SbWebChat';
import { WebChatMode } from '../../../constants';

const SimpleBotWebChat: React.FC = () => {
  const params = new URL(window.location.href);
  const botTitle = params.searchParams.get('title') || '';

  return (
    <div className="webchat-page">
      <SbWebChat mode={WebChatMode.Fullscreen} title={botTitle} webchatUrl={window.location.href} />
    </div>
  );
};

export default SimpleBotWebChat;
