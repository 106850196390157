import React, { MouseEventHandler, useState } from 'react';

import './index.less';

import { VariableSchema } from '../../../../../../../api';
import SbTooltip from '../../../../../../simple-bot/components/common/SbTooltip';
import { tagColorPalette } from '../../../../../../simple-bot/const';
import SbTag from '../../../../../../simple-bot/components/common/SbTag';
import VariableSelector from '../../../VariableSelector';
import { IScenarioEntity } from '../../../../types';

interface IVariableSelectorFieldProps {
  variable?: VariableSchema;
  variables: VariableSchema[];
  getVariableUsages: (variable: VariableSchema) => Promise<IScenarioEntity[]>;
  onVariableIdChange: (variableId?: string) => void;
  onVariablesChange: (newVariables: VariableSchema[]) => void;
}

const TAG_COLOR_OPTIONAL = tagColorPalette[0];

const MAIN_CLASS_NAME = 'variable-selector-field';
const VARIABLE_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__variable-button`;

const VariableSelectorField: React.FC<IVariableSelectorFieldProps> = ({
  variable,
  variables,
  getVariableUsages,
  onVariableIdChange,
  onVariablesChange,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const onVariablesSelectorSettingsModalOpen = () => {
    setTooltipIsOpen(false);
  };

  const onVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    setTooltipIsOpen(false);
    if (newVariables) onVariablesChange(newVariables);
    if (selectedVariable) {
      onVariableIdChange(selectedVariable.id);
    }
  };

  const onVariableDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onVariableIdChange(undefined);
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {variable ? (
        <SbTag color={TAG_COLOR_OPTIONAL} sbSize="medium" text={variable.name} onDelete={onVariableDelete} />
      ) : (
        <SbTooltip
          placement="right"
          title={
            <VariableSelector
              getVariableUsages={getVariableUsages}
              variables={variables}
              onChange={onVariableSelectorChange}
              onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
            />
          }
          trigger={['click']}
          visible={tooltipIsOpen}
          onVisibleChange={setTooltipIsOpen}
        >
          <button className={VARIABLE_BUTTON_CLASS_NAME}>
            Выбрать переменную<span>(x)</span>
          </button>
        </SbTooltip>
      )}
    </div>
  );
};

export default VariableSelectorField;
