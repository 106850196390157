import React from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';
import SbButton from '../../common/SbButton';
import SbIcon from '../../common/SbIcon';

interface ISbAddScenarioCardProps {
  onCardClick: () => void;
}

// TODO: добавить анимацию иконки кнопки при наведении курсора
const SbAddScenarioCard: React.FC<ISbAddScenarioCardProps> = ({ onCardClick }) => {
  const { t } = useTranslation();

  return (
    <Card hoverable className="sb-scenario-list-card sb-add-scenario-list-card" onClick={() => onCardClick()}>
      <div className="sb-add-scenario-list-card__container">
        <div className="sb-add-scenario-list-card__container__content">
          <SbButton icon={<SbIcon iconName="plus" />} sbType="primary" />
          <div className="sb-add-scenario-list-card__container__content__text">{t('Add scenario')}</div>
        </div>
      </div>
    </Card>
  );
};

export default SbAddScenarioCard;
