import React from 'react';

import SbButton from '../../../../components/common/SbButton';
import SbIcon from '../../../../components/common/SbIcon';
import { ActivityModel } from '../../../../../../api';

interface IErrorOccuredProps {
  activity: ActivityModel;
  onSelect: (activity: ActivityModel) => void;
}

const ErrorOccured: React.FC<IErrorOccuredProps> = ({ activity, onSelect }) => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  const onShowDetails = () => onSelect(activity);

  return (
    <div className={`${mainClass} ${mainClass}_error ${mainClass}_end`}>
      <SbIcon iconName="attention" size={16} />
      Во время работы бота произошла ошибка.
      <SbButton sbSize="medium" sbType="link" onClick={onShowDetails}>
        Подробнее
      </SbButton>
    </div>
  );
};

export default ErrorOccured;
