import React from 'react';
import { Card } from 'antd';

import { ListBotTemplateModel } from '../../../../api';
import SbIcon, { IconName } from '../common/SbIcon';

import './index.less';

interface ISbBotTemplateCardProps {
  botTemplate: ListBotTemplateModel;
  isSelected: boolean;
  onCardClick: () => void;
}

const SbBotTemplateCard: React.FC<ISbBotTemplateCardProps> = ({ botTemplate, isSelected, onCardClick }) => {
  const classes = ['sb-bot-template-card', isSelected ? 'sb-bot-template-card_selected' : ''];
  return (
    <Card hoverable className={classes.join(' ')} onClick={() => onCardClick()}>
      <div className="sb-bot-template-card__container">
        {botTemplate.iconPack === 'iconify' && botTemplate.iconCode && (
          <div className="sb-bot-template-card__container__icon">
            <SbIcon iconName={botTemplate.iconCode as IconName} size={30} />
          </div>
        )}
        <div className="sb-bot-template-card__container__title">{botTemplate.name}</div>
        <div className="sb-bot-template-card__container__description">{botTemplate.description}</div>
      </div>
    </Card>
  );
};

export default SbBotTemplateCard;
