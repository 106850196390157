import React, { useEffect, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { ExternalEventTriggerSchema, VariableSchema } from '../../../../../../../api';
import SbModal from '../../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../../simple-bot/components/common/SbButton';
import SbTypography from '../../../../../../simple-bot/components/common/SbTypography';
import SbInput from '../../../../../../simple-bot/components/common/SbInput';
import SbTooltip from '../../../../../../simple-bot/components/common/SbTooltip';
import VariableSelector from '../../../VariableSelector';
import { currentScenarioStructureSelector, variableUsagesSelector } from '../../../../../../recoil/scenarioStructure';
import SbTag from '../../../../../../simple-bot/components/common/SbTag';
import { VARIABLE_TAG_COLOR_DEFAULT } from '../../../../../../simple-bot/const';

const MODAL_WIDTH = 516;
const MAIN_CLASS = 'external-event-trigger-settings-modal';
const INPUT_MAX_LENGTH = 100;

interface SettingModalProps {
  visible: boolean;
  externalEventTrigger: ExternalEventTriggerSchema;
  onChange: (schema: ExternalEventTriggerSchema) => void;
  onModalClose: () => void;
}

const SettingModal: React.FC<SettingModalProps> = ({ visible, externalEventTrigger, onChange, onModalClose }) => {
  const [externalEventName, setExternalEventName] = useState(externalEventTrigger.externalEventName);
  const [externalEventId, setExternalEventId] = useState(externalEventTrigger.externalEventId);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [variablesTooltipIsOpen, setVariablesTooltipIsOpen] = useState(false);
  const [variables, setVariables] = useState(externalEventTrigger.variables ?? []);

  const allVariables = scenarioStructure?.variables || [];
  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const resetState = () => {
    if (!visible) return;

    setExternalEventName(externalEventTrigger.externalEventName);
    setExternalEventId(externalEventTrigger.externalEventId);
  };
  useEffect(resetState, [visible, externalEventTrigger]);

  const onSaveButtonClick = () =>
    onChange({
      ...externalEventTrigger,
      externalEventName,
      externalEventId,
      variables,
    });

  const onModalCancel = () => {
    setVariables(externalEventTrigger.variables ?? []);
    onModalClose();
  };

  const onCancelButtonClick = () => onModalCancel();

  const onNameChange = (value: string) => setExternalEventName(value);

  const onExternalEventIdChange = (value: string) => setExternalEventId(value);

  const onVariablesSelectorSettingsModalOpen = () => {
    setVariablesTooltipIsOpen(false);
  };

  const onVariableDelete = (index: number) => () => {
    const newVariables = [...variables];
    newVariables.splice(index, 1);
    setVariables(newVariables);
  };

  const onVariablesChange = (newVariables: VariableSchema[]) => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.variables = newVariables;
    setScenarioStructure(newScenarioStructure);
  };

  const onVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    setVariablesTooltipIsOpen(false);
    if (newVariables) onVariablesChange(newVariables);
    if (selectedVariable && !variables.some((variable) => selectedVariable.id === variable.id)) {
      const newVariables = [...variables];
      newVariables.push(selectedVariable);
      setVariables(newVariables);
    }
  };

  const onAllVariablesPropChange = () => {
    const newVariables = allVariables.filter((newVariable) =>
      variables.some((variable) => newVariable.id === variable.id)
    );
    if (
      newVariables?.length == externalEventTrigger.variables?.length &&
      !externalEventTrigger.variables?.every((variable) =>
        newVariables?.some(
          (newVariable) =>
            newVariable.name === variable.name &&
            newVariable.scope === variable.scope &&
            newVariable.type === variable.type
        )
      )
    ) {
      setVariables(newVariables);
      onChange({
        ...externalEventTrigger,
        variables: newVariables,
      });
    } else {
      setVariables(externalEventTrigger.variables ?? []);
    }
  };
  useEffect(onAllVariablesPropChange, [allVariables]);

  return (
    <SbModal
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройка внешнего события"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
    >
      <div className={MAIN_CLASS}>
        <SbTypography>
          <h3>Идентификатор</h3>
          <SbInput
            required
            isValid={!!externalEventId}
            maxLength={INPUT_MAX_LENGTH}
            value={externalEventId || ''}
            onChange={onExternalEventIdChange}
          />
          <h3>Имя</h3>
          <SbInput
            required
            isValid={!!externalEventName}
            maxLength={INPUT_MAX_LENGTH}
            value={externalEventName || ''}
            onChange={onNameChange}
          />
          <h3>Переменные</h3>
          <div className={`${MAIN_CLASS}__variables`}>
            {variables.map((variable, index) => (
              <SbTag
                key={variable.id}
                color={VARIABLE_TAG_COLOR_DEFAULT}
                sbSize="small"
                text={
                  <span>
                    <span>{variable.name}</span>
                  </span>
                }
                onDelete={onVariableDelete(index)}
              />
            ))}
          </div>
          <SbTooltip
            overlayInnerStyle={{ width: `280px` }}
            placement="right"
            title={
              <VariableSelector
                getVariableUsages={getVariableUsages}
                variables={allVariables}
                onChange={onVariableSelectorChange}
                onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
              />
            }
            trigger={['click']}
            visible={variablesTooltipIsOpen}
            onVisibleChange={setVariablesTooltipIsOpen}
          >
            <SbButton sbSize="medium" sbType="link">
              Добавить переменную
            </SbButton>
          </SbTooltip>
        </SbTypography>
      </div>
    </SbModal>
  );
};

export default SettingModal;
