import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';
import { ActivityModel } from '../../../../../../api';
import SbButton from '../../../../components/common/SbButton';

interface IScriptExecutedProps {
  activity: ActivityModel;
  onSelect: (activity: ActivityModel) => void;
}

const ScriptExecuted: React.FC<IScriptExecutedProps> = ({ activity, onSelect }) => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  const classes = [`${mainClass}`, `${mainClass}_end`];
  const status = activity.value?.response?.status;
  const onShowDetails = () => onSelect(activity);

  if (status === 'FAILURE') {
    classes.push(`${mainClass}_error`);
  }

  return (
    <div className={classes.join(' ')}>
      <SbIcon iconName="terminal" size={16} />
      {status === 'FAILURE' ? 'Выполнение скрипта завершилось ошибкой.' : 'Выполнен скрипт.'}
      <SbButton sbSize="medium" sbType="link" onClick={onShowDetails}>
        Подробнее
      </SbButton>
    </div>
  );
};

export default ScriptExecuted;
