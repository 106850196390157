import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { InstanceModel, KnowledgeSourceModel, PublishStatusState } from '../../../../../kb-api';
import { dataEntryKbApi, reportKbApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import SbButton from '../../../components/common/SbButton';
import SbModal from '../../../components/common/SbModal';
import SbPanel from '../../../components/common/SbPanel';
import SbTypography from '../../../components/common/SbTypography';
import SbIcon from '../../../components/common/SbIcon';
import { renderRecordCount, renderReportRowCount } from '../../../../utils/stringUtil';

import InstanceStatusProgress from './InstanceStatusProgress';

interface IKnowledgeSourceImportProgressModalProps {
  instanceId: string;
  knowledgeSource: KnowledgeSourceModel;
  visible: boolean;
  onDataChanged: () => void;
}

const KnowledgeSourceImportProgressModal: React.FC<IKnowledgeSourceImportProgressModalProps> = ({
  instanceId,
  knowledgeSource,
  visible,
  onDataChanged,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [showProgress, setShowProgress] = useState(true);
  const [showInstancePublishError, setShowInstancePublishError] = useState(false);
  const [importedDataEntryCount, setImportedDataEntryCount] = useState(0);
  const [reportRowCount, setReportRowCount] = useState(0);
  const [reportHasProblems, setReportHasProblems] = useState(false);

  const onVisiblePropChange = () => {
    if (visible) {
      setShowProgress(true);
      setShowInstancePublishError(false);
      setImportedDataEntryCount(0);
      setReportRowCount(0);
      setReportHasProblems(false);
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onModalCancel = () => !showProgress && onDataChanged();

  const onPublishComplete = async (instance: InstanceModel) => {
    if (instance.status.state === PublishStatusState.Error) {
      setShowInstancePublishError(true);
      setShowProgress(false);
      return;
    }

    try {
      const dataEntriesResponse = await dataEntryKbApi.searchDataEntries(
        undefined,
        instance.editionId,
        knowledgeSource.id
      );
      const reportResponse = await reportKbApi.getActualReport(instance.id);
      setReportHasProblems(!!reportResponse.data.reportRowCount);
      setReportRowCount(reportResponse.data.reportRowCount || 0);
      setImportedDataEntryCount(dataEntriesResponse.data.totalItemCount || 0);
      setShowProgress(false);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке результата импорта источника в базу знаний',
        error: e,
      });
    }
  };

  const footer = [];
  if (!showProgress) {
    footer.push(
      <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onModalCancel}>
        Закрыть
      </SbButton>
    );
  }

  return (
    <SbModal
      destroyOnClose
      className="sb-knowledge-base-card__import-progress-modal"
      footer={footer}
      sbSize="small"
      title="Импорт документов в базу знаний"
      visible={visible}
      width={569}
      onCancel={onModalCancel}
      onOk={() => {}}
    >
      {showProgress ? (
        <InstanceStatusProgress instanceId={instanceId} onPublishComplete={onPublishComplete} />
      ) : (
        <div className="sb-knowledge-base-card__import-progress-modal__import-result">
          <SbPanel sbType="help">
            <SbTypography>
              {showInstancePublishError ? (
                <h4>
                  <SbIcon iconName="attention" />
                  Возникла ошибка при публикации базы знаний
                </h4>
              ) : (
                <>
                  <h4>Найдено {renderRecordCount(importedDataEntryCount)} с вопросами и ответами.</h4>
                  {reportHasProblems && (
                    <p className="sb-knowledge-base-card__import-progress-modal__import-result__warning">
                      В ходе диагностики было найдено {renderReportRowCount(reportRowCount)}, которые
                      <br /> необходимо проверить. Все совпадения отмечены иконкой{' '}
                      <SbIcon iconName="attention" size={20} />
                      <br /> на странице с записями.
                    </p>
                  )}
                </>
              )}
            </SbTypography>
          </SbPanel>
        </div>
      )}
    </SbModal>
  );
};

export default KnowledgeSourceImportProgressModal;
