import React from 'react';
import { Dropdown } from 'antd';

import './index.less';

import SbIcon from '../SbIcon';

interface ISbContextMenuProps {
  menuContent: JSX.Element;
  iconSize?: number;
}

const SbContextMenu: React.FC<ISbContextMenuProps> = ({ menuContent, iconSize = 20, children }) => {
  return (
    <Dropdown overlay={menuContent} overlayClassName="sb-context-menu" trigger={['click']}>
      {children ? (
        children
      ) : (
        <div className="sb-context-menu__icon">
          <SbIcon iconName="more-one" size={iconSize} />
        </div>
      )}
    </Dropdown>
  );
};

export default SbContextMenu;
