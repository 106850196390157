import React from 'react';

import './index.less';

import { AgentStageAccountSetupResult, AgentStageAccountStatus, SetupStatus } from '../../../../../api';

enum ChannelStatus {
  DISABLED = 'DISABLED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  OK = 'OK',
}

const getChannelStatus = (enabledStatus: AgentStageAccountStatus, setupStatus: SetupStatus): ChannelStatus => {
  if (enabledStatus === AgentStageAccountStatus.Disabled) return ChannelStatus.DISABLED;

  switch (setupStatus) {
    case SetupStatus.Pending:
      return ChannelStatus.PENDING;
    case SetupStatus.Error:
      return ChannelStatus.ERROR;
    case SetupStatus.Success:
      return ChannelStatus.OK;
  }
};

const getChannelText = (status: ChannelStatus) => {
  switch (status) {
    case ChannelStatus.DISABLED:
      return 'Отключен';
    case ChannelStatus.PENDING:
      return 'Подключение...';
    case ChannelStatus.ERROR:
      return 'Ошибка';
    case ChannelStatus.OK:
      return 'Подключен';
  }
};

const getChannelBackgroundColor = (status: ChannelStatus) => {
  switch (status) {
    case ChannelStatus.DISABLED:
      return '#EAEAEA';
    case ChannelStatus.PENDING:
      return '#FFE599';
    case ChannelStatus.ERROR:
      return '#ffaa99';
    case ChannelStatus.OK:
      return '#DFF6C3';
  }
};

const getChannelTextColor = (status: ChannelStatus) => {
  switch (status) {
    case ChannelStatus.DISABLED:
      return '#A6A6A6';
    case ChannelStatus.PENDING:
      return '#997923';
    case ChannelStatus.ERROR:
      return '#993322';
    case ChannelStatus.OK:
      return '#1CBF4A';
  }
};

interface SbChannelStatusProps {
  enabledStatus: AgentStageAccountStatus;
  setupResult: AgentStageAccountSetupResult;
}

const SbChannelStatus: React.FC<SbChannelStatusProps> = ({ enabledStatus, setupResult }) => {
  const channelStatus = getChannelStatus(enabledStatus, setupResult.status ?? SetupStatus.Pending);

  const text = getChannelText(channelStatus);
  const backgroundColor = getChannelBackgroundColor(channelStatus);
  const textColor = getChannelTextColor(channelStatus);

  return (
    <div
      className="sb-channel-status"
      style={{ backgroundColor: backgroundColor, color: textColor }}
      title={
        setupResult.status === SetupStatus.Error && setupResult.errorMessage ? setupResult.errorMessage : undefined
      }
    >
      <div className="sb-channel-status__marker" style={{ backgroundColor: textColor }} />
      <span className="sb-channel-status__text">{text}</span>
    </div>
  );
};

export default SbChannelStatus;
