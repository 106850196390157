import React, { memo, useEffect, useState } from 'react';
import { Menu, Typography } from 'antd';
import copy from 'copy-to-clipboard';
import { useRecoilValue } from 'recoil';

import { ExternalEventTriggerSchema, DefaultTriggerGroupSchema } from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbTooltip from '../../../../../simple-bot/components/common/SbTooltip';
import SbTag from '../../../../../simple-bot/components/common/SbTag';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';
import { VARIABLE_TAG_COLOR_DEFAULT } from '../../../../../simple-bot/const';

import SettingModal from './SettingModal';

import './index.less';

const { Text, Title } = Typography;

const MAIN_CLASS = 'external-event-trigger';

interface IExternalEventTriggerProps {
  index: number;
  trigger: ExternalEventTriggerSchema;
  group: DefaultTriggerGroupSchema;
  onTriggerValueChange: (newValue: ExternalEventTriggerSchema) => void;
}

const ExternalEventTrigger: React.FC<IExternalEventTriggerProps> = ({
  index,
  trigger,
  group,
  onTriggerValueChange,
}) => {
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [variables, setVariables] = useState(trigger.variables);
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const allVariables = scenarioStructure?.variables;

  const onTriggerSetupSelect = () => {
    setSettingModalVisible(true);
  };

  const onSettingModalChange = (externalEventTrigger: ExternalEventTriggerSchema) => {
    setSettingModalVisible(false);
    setVariables(externalEventTrigger.variables);
    onTriggerValueChange({
      ...trigger,
      externalEventName: externalEventTrigger.externalEventName,
      externalEventId: externalEventTrigger.externalEventId,
      variables: externalEventTrigger.variables,
    });
  };

  const onSettingModalClose = () => {
    setSettingModalVisible(false);
  };

  const triggerMenuItem = (
    <Menu.Item key="setup" onClick={onTriggerSetupSelect}>
      <SbIcon iconName="setting-two" />
    </Menu.Item>
  );

  const onCopyExternalEventId = () => {
    copy(trigger.externalEventId);
  };

  const onAllVariablesPropChange = () => {
    const newVariables = allVariables?.filter((newVariable) =>
      variables?.some((variable) => newVariable.id === variable.id)
    );
    if (
      newVariables?.length == trigger.variables?.length &&
      !trigger.variables?.every((variable) =>
        newVariables?.some(
          (newVariable) =>
            newVariable.name === variable.name &&
            newVariable.scope === variable.scope &&
            newVariable.type === variable.type
        )
      )
    ) {
      setVariables(newVariables);
      onTriggerValueChange({
        ...trigger,
        variables: newVariables,
      });
    } else {
      setVariables(trigger.variables);
    }
  };
  useEffect(onAllVariablesPropChange, [allVariables]);

  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="share-three" size={20} />}
      index={index}
      menuItems={[triggerMenuItem]}
      title="Внешнее событие"
      trigger={trigger}
    >
      <div className={MAIN_CLASS}>
        <Title>{trigger.externalEventName}</Title>
        <Text>Этот сценарий запустится при вызове внешнего события.</Text>
        {!!trigger.externalEventId && (
          <div className="external-event-trigger__id">
            <SbInput disabled allowClear={false} sbSize="small" value={trigger.externalEventId} />
            <SbTooltip placement="top" title="Скопировать идентификатор">
              <SbButton
                disabled={!trigger.externalEventId}
                sbSize="medium"
                sbType="icon-secondary-32"
                onClick={onCopyExternalEventId}
              >
                <SbIcon iconName="link-one" />
              </SbButton>
            </SbTooltip>
          </div>
        )}
        <div className={`${MAIN_CLASS}__variables`}>
          {variables?.map((variable) => (
            <SbTag
              key={variable.id}
              color={VARIABLE_TAG_COLOR_DEFAULT}
              sbSize="small"
              text={
                <span>
                  <span>{variable.name}</span>
                </span>
              }
            />
          ))}
        </div>
        <SettingModal
          externalEventTrigger={trigger}
          visible={settingModalVisible}
          onChange={onSettingModalChange}
          onModalClose={onSettingModalClose}
        />
      </div>
    </TriggerWrapper>
  );
};

export default memo(ExternalEventTrigger);
