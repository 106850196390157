import React, { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import isEqual from 'lodash/isEqual';
import { Key } from 'ts-key-enum';
import { CallbackInterface, useRecoilCallback, useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import SbTag from '../../../../simple-bot/components/common/SbTag';
import { VariableIcon } from '../../assets';
import VariableSelector from '../VariableSelector';
import { currentScenarioStructureSelector, variableUsagesSelector } from '../../../../recoil/scenarioStructure';
import { OperandSchema, SchemaKind, VariableSchema } from '../../../../../api';
import SbTooltip from '../../../../simple-bot/components/common/SbTooltip';
import { generateId, instanceOfVariableOperandSchema } from '../../utils';
import { tagColorPalette } from '../../../../simple-bot/const';

const MAIN_CLASS_NAME = 'email-recipient-editor';
const TAG_COLOR_VAR = tagColorPalette[0];
const VAR_TOOLTIP_WIDTH = 280;

interface IEmailRecipientEditorProps {
  values: OperandSchema[];
  tagsColor: string;
  readOnly?: boolean;
  isValid?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (values: OperandSchema[]) => void;
}

const EmailRecipientEditor: React.FC<IEmailRecipientEditorProps> = ({
  values,
  onBlur,
  onFocus,
  tagsColor,
  isValid,
  readOnly = false,
  onChange = () => {},
}) => {
  const [localEmails, setLocalEmails] = useState([...values]);
  const [editingValue, setEditingValue] = useState('');
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const inputRef = useRef<Input>(null);

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const onValuesPropChange = () => {
    if (isEqual(values, localEmails)) return;
    setLocalEmails([...values]);
  };
  useEffect(onValuesPropChange, [values]);

  const onLocalValuesChange = () => onChange(localEmails);
  useEffect(onLocalValuesChange, [localEmails]);

  const tryUpdateLocalValues = () => {
    if (!editingValue) return;
    const newRecipient = {
      id: generateId('OPD'),
      $kind: SchemaKind.ConstantOperand,
      value: editingValue,
    };

    setLocalEmails([...localEmails, newRecipient]);
    setEditingValue('');
  };

  const classes = [MAIN_CLASS_NAME];
  if (readOnly) {
    classes.push(`${MAIN_CLASS_NAME}_read-only`);
  }
  if (isValid === false) {
    classes.push('sb-input_warning');
  }

  const onControlClick = () => inputRef.current?.focus();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => setEditingValue(event.target.value);

  const onInputBlur = () => {
    if (onBlur) {
      onBlur();
    }
    tryUpdateLocalValues();
  };

  const onInputPressEnter = () => tryUpdateLocalValues();

  const onVariablesSelectorSettingsModalOpen = () => setTooltipIsOpen(false);

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Backspace && !editingValue) {
      const newLocalValues = [...localEmails];
      newLocalValues.pop();
      setLocalEmails(newLocalValues);
    }
  };

  const onDeleteValue = (index: number) => () => {
    const newLocalValues = [...localEmails];
    newLocalValues.splice(index, 1);
    setLocalEmails(newLocalValues);
  };

  const onVariablesChange = (newVariables: VariableSchema[]) => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.variables = newVariables;
    setScenarioStructure(newScenarioStructure);
  };

  const onVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    setTooltipIsOpen(false);
    if (newVariables) onVariablesChange(newVariables);
    if (selectedVariable) {
      const newRecipient = {
        id: generateId('OPD'),
        $kind: SchemaKind.VariableOperand,
        variableId: selectedVariable.id,
        variableName: selectedVariable.name,
      };
      setLocalEmails([...localEmails, newRecipient]);
    }
  };

  return (
    <div className={classes.join(' ')} role="none">
      <div className={`${MAIN_CLASS_NAME}__inner`} onClick={onControlClick}>
        {localEmails.map((email, index) => (
          <SbTag
            key={index}
            color={instanceOfVariableOperandSchema(email) ? TAG_COLOR_VAR : tagsColor}
            text={instanceOfVariableOperandSchema(email) ? email.variableName : email.value}
            onDelete={!readOnly ? onDeleteValue(index) : undefined}
          />
        ))}
        {!readOnly && (
          <Input
            ref={inputRef}
            value={editingValue}
            onBlur={onInputBlur}
            onChange={onInputChange}
            onFocus={onFocus}
            onKeyDown={onInputKeyDown}
            onPressEnter={onInputPressEnter}
          />
        )}
      </div>
      <div className={`${MAIN_CLASS_NAME}__variable`}>
        <SbTooltip
          overlayInnerStyle={{ width: `${VAR_TOOLTIP_WIDTH}px` }}
          placement="right"
          title={
            <VariableSelector
              getVariableUsages={getVariableUsages}
              variables={scenarioStructure?.variables || []}
              onChange={onVariableSelectorChange}
              onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
            />
          }
          trigger={['click']}
          visible={tooltipIsOpen}
          onVisibleChange={setTooltipIsOpen}
        >
          <button>
            <VariableIcon />
          </button>
        </SbTooltip>
      </div>
    </div>
  );
};

export default EmailRecipientEditor;
