import React, { useState } from 'react';
import { PageHeader } from 'antd';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { KnowledgeBaseCreationRequest } from '../../../kb-api';
import { knowledgeBaseKbApi } from '../../apis';
import { AlertTypes } from '../../constants';
import KnowledgeCreatingForm, { IKnowledgeCreatingFormData } from '../../components/KnowledgeCreatingForm';
import { alertsSelectorAdd } from '../../recoil/alerts';

const KnowledgeBaseAdd: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);

  const onSubmitCreatingForm = async (data: IKnowledgeCreatingFormData) => {
    setLoading(true);

    const newKB: KnowledgeBaseCreationRequest = { ...data };

    try {
      const response = await knowledgeBaseKbApi.createKnowledgeBase(newKB);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлена новая база знаний',
        description: newKB.name,
      });
      push(`/knowledge-bases/${response.data.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении базы знаний',
        error: e,
      });
      setLoading(false);
    }
  };

  const onCancelCreatingForm = () => {
    push('/knowledge-bases');
  };

  return (
    <div>
      <PageHeader title="Новая база знаний" />
      <KnowledgeCreatingForm
        knowledgeDataRequired
        showSearchParentsOnlyIfNoContextSetting
        loading={loading}
        onCancel={onCancelCreatingForm}
        onSubmit={onSubmitCreatingForm}
      />
    </div>
  );
};

export default KnowledgeBaseAdd;
