import { getLiveChatUrl } from './configUtil';

export const tryAddLivechatStyles = (): void => {
  const href = getLiveChatUrl('/index.css');
  const existLink = document.querySelector(`[href="${href}"]`);

  if (existLink) {
    return;
  }

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  document.head.appendChild(link);
};
