import React, { memo } from 'react';

import Messages from '../../Messages';
import { DefaultActionGroupSchema, TextOutputSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

interface ITextOutputProps {
  index: number;
  action: TextOutputSchema;
  group: DefaultActionGroupSchema;
}

const TextOutput: React.FC<ITextOutputProps> = ({ action, index, group }) => {
  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="add-text-two" size={20} />}
      index={index}
      title="Сообщение чат-бота"
    >
      <Messages action={action} group={group} placeholder="Добавьте текст сообщения" />
    </ActionWrapper>
  );
};

export default memo(TextOutput);
