import React, { ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import isEqual from 'lodash/isEqual';
import { Key } from 'ts-key-enum';

import './index.less';

import SbTag from '../SbTag';

const MAIN_CLASS_NAME = 'sb-tags-input';

interface ISbTagsInputProps {
  values: string[];
  tagsColor: string;
  sbSize?: 'large' | 'medium' | 'small' | 'small-uncut' | 'x-small';
  readOnly?: boolean;
  editable?: boolean;
  onChange?: (values: string[]) => void;
}

const SbTagsInput: React.FC<ISbTagsInputProps> = ({
  values,
  tagsColor,
  sbSize = 'medium',
  readOnly = false,
  editable = false,
  onChange = () => {},
}) => {
  const [localValues, setLocalValues] = useState([...values]);
  const [editingValue, setEditingValue] = useState('');

  const inputRef = useRef<Input>(null);

  const onValuesPropChange = () => {
    if (isEqual(values, localValues)) return;
    setLocalValues([...values]);
  };
  useEffect(onValuesPropChange, [values]);

  const onLocalValuesChange = () => onChange(localValues);
  useEffect(onLocalValuesChange, [localValues]);

  const tryUpdateLocalValues = () => {
    if (!editingValue) return;

    setLocalValues([...localValues, editingValue]);
    setEditingValue('');
  };

  const onControlClick = () => inputRef.current?.focus();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => setEditingValue(event.target.value);

  const onInputBlur = () => tryUpdateLocalValues();

  const onInputPressEnter = () => tryUpdateLocalValues();

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Backspace && !editingValue) {
      const newLocalValues = [...localValues];
      newLocalValues.pop();
      setLocalValues(newLocalValues);
    }
  };

  const onDeleteValue = (index: number) => () => {
    const newLocalValues = [...localValues];
    newLocalValues.splice(index, 1);
    setLocalValues(newLocalValues);
  };

  const onEditValue = (index: number, value: string) => {
    const newLocalValues = [...localValues.slice(0, index), value, ...localValues.slice(index + 1)];
    setLocalValues(newLocalValues);
  };

  const classes = [MAIN_CLASS_NAME];
  if (readOnly) {
    classes.push(`${MAIN_CLASS_NAME}_read-only`);
  }

  return (
    <div className={classes.join(' ')} role="none" onClick={onControlClick}>
      {localValues.map((value, index) => (
        <SbTag
          key={index}
          color={tagsColor}
          sbSize={sbSize}
          text={value}
          onDelete={!readOnly ? onDeleteValue(index) : undefined}
          onEdit={!readOnly && editable ? (value) => onEditValue(index, value) : undefined}
        />
      ))}
      {!readOnly && (
        <Input
          ref={inputRef}
          value={editingValue}
          onBlur={onInputBlur}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          onPressEnter={onInputPressEnter}
        />
      )}
    </div>
  );
};

export default SbTagsInput;
