import React from 'react';

import { AgentStageAccountModel } from '../../../api';

import AgentAccountList from './AgentAccountList';

export interface IBotChannelsCheckboxProps {
  agentStageId: string | null;
  onAccountsChange: (accounts: AgentStageAccountModel[]) => void;
}

const BotChannelsSwitch: React.FC<IBotChannelsCheckboxProps> = ({
  agentStageId = null,
  onAccountsChange = () => {
    /*empty*/
  },
}) => {
  const renderAgentAccounts = (agentStageId: string, expanded: boolean) => {
    return <AgentAccountList agentStageId={agentStageId} visible={expanded} onAccountsChange={onAccountsChange} />;
  };

  return agentStageId ? renderAgentAccounts(agentStageId, true) : null;
};

export default BotChannelsSwitch;
