import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import {
  BotSchema,
  DefaultScenarioSchema,
  IntentEntryModel,
  ScenarioEditionReferenceModel,
  SingleBotModel,
} from '../../../../../../api';
import { ScenarioSortDirection } from '../../../../../simple-bot/types';
import { getIgnoreCaseStringComparer, includesIgnoreCase } from '../../../../../utils/stringUtil';
import SbAddScenarioCard from '../../../../../simple-bot/components/SbScenarioCard/SbAddScenarioCard';
import IbIcon from '../../../../components/common/IbIcon';
import IbButton from '../../../../components/common/IbButton';
import IbTypography from '../../../../components/common/IbTypography';

import ScenarioCard, { getScenarioTriggers } from './ScenarioCard';

const SKELETON_ROW_COUNT = 4;
const SKELETON_COLUMN_COUNT = 3;

const MAIN_CLASS_NAME = 'ib-scenario-list-block';
const GRID_CLASS_NAME = `${MAIN_CLASS_NAME}__grid`;
const GRID_ITEM_CLASS_NAME = `${GRID_CLASS_NAME}__item`;
const EMPTY_CLASS_NAME = `${MAIN_CLASS_NAME}__empty`;
const EMPTY_DESCRIPTION_CLASS_NAME = `${EMPTY_CLASS_NAME}__description`;
const ADD_SCENARIO_LIST_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__add-scenario-list-item`;
const SKELETON_CLASS_NAME = `${MAIN_CLASS_NAME}__skeleton`;
const SKELETON_ROW_CLASS_NAME = `${SKELETON_CLASS_NAME}__row`;

const filterScenarios = (
  scenarios: ScenarioEditionReferenceModel[],
  searchText: string,
  systemIntents: IntentEntryModel[],
  botSchema?: BotSchema
) => {
  if (!botSchema) {
    return [];
  }

  if (!searchText) {
    return scenarios;
  }

  return scenarios.filter((scenario) => {
    const scenarioStructure = botSchema.scenarios.find((s) => s.id === scenario.scenarioStructureId);
    const scenarioTriggers = scenarioStructure
      ? getScenarioTriggers(scenarioStructure as DefaultScenarioSchema, systemIntents)
      : undefined;

    return (
      includesIgnoreCase(scenario.name, searchText) ||
      (scenarioTriggers && scenarioTriggers.some((t) => includesIgnoreCase(t.name, searchText)))
    );
  });
};

interface IScenarioListProps {
  bot?: SingleBotModel;
  searchText: string;
  sortDirection: ScenarioSortDirection;
  systemIntents: IntentEntryModel[];
  loading: boolean;
  onAddScenario: () => void;
  onDataChanged: () => void;
}

const ScenariosList: React.FC<IScenarioListProps> = ({
  bot,
  searchText,
  sortDirection,
  systemIntents,
  loading,
  onAddScenario,
  onDataChanged,
}) => {
  const { t } = useTranslation();

  const botStage = bot?.originStage;
  const scenarios = filterScenarios(
    botStage?.currentEdition.scenarios ?? [],
    searchText,
    systemIntents,
    bot?.originCurrentEdition.structure
  );
  const noScenarios = bot && !loading && !scenarios.length;

  const getComparer = () => (a: ScenarioEditionReferenceModel, b: ScenarioEditionReferenceModel) => {
    switch (sortDirection) {
      case ScenarioSortDirection.NameAscending:
        return getIgnoreCaseStringComparer<ScenarioEditionReferenceModel>((s) => s.name)(a, b);
      case ScenarioSortDirection.ModifiedOnDescending:
        return getIgnoreCaseStringComparer<ScenarioEditionReferenceModel>((s) => s.modifiedOn)(b, a);
      default:
        return 0;
    }
  };

  const renderScenarioCard = (scenario: ScenarioEditionReferenceModel) => {
    if (!bot || !botStage) {
      return null;
    }

    return (
      <ScenarioCard
        botEntryId={bot.entry.id}
        botStageId={botStage.id ?? ''}
        botStructure={bot.originCurrentEdition.structure}
        scenario={scenario}
        searchText={searchText}
        systemIntents={systemIntents}
        onDataChanged={onDataChanged}
      />
    );
  };

  const skeleton = (
    <div className={SKELETON_CLASS_NAME}>
      {Array.from(Array(SKELETON_ROW_COUNT).keys()).map((_, row) => (
        <div key={row} className={SKELETON_ROW_CLASS_NAME}>
          {Array.from(Array(SKELETON_COLUMN_COUNT).keys()).map((_, col) => (
            <Skeleton key={col} />
          ))}
        </div>
      ))}
    </div>
  );

  const renderContent = () => {
    if (!bot || loading) {
      return skeleton;
    }

    if (noScenarios && searchText) {
      return (
        <IbTypography.Paragraph disabled className={EMPTY_CLASS_NAME}>
          {t('Scenarios not found')}
        </IbTypography.Paragraph>
      );
    }

    if (noScenarios) {
      return (
        <div className={EMPTY_CLASS_NAME}>
          <div className={EMPTY_DESCRIPTION_CLASS_NAME}>
            {t('Add scenario placeholder part 1')}
            <br /> {t('Add scenario placeholder part 2')}
            <br /> {t('Add scenario placeholder part 3')}
          </div>
          <IbButton icon={<IbIcon iconName="add-one" />} type="primary" onClick={onAddScenario}>
            {t('Add scenario')}
          </IbButton>
        </div>
      );
    }

    const addScenarioCard = (
      <div key="add-scenario-card" className={[ADD_SCENARIO_LIST_ITEM_CLASS_NAME, GRID_ITEM_CLASS_NAME].join(' ')}>
        <SbAddScenarioCard onCardClick={onAddScenario} />
      </div>
    );

    return (
      <div className={GRID_CLASS_NAME}>
        {!searchText && addScenarioCard}
        {[...scenarios].sort(getComparer()).map((scenario) => {
          return (
            <div key={scenario.scenarioStructureId} className={GRID_ITEM_CLASS_NAME}>
              {renderScenarioCard(scenario)}
            </div>
          );
        })}
      </div>
    );
  };

  return <div className={MAIN_CLASS_NAME}>{renderContent()}</div>;
};

export default ScenariosList;
