import React, { MutableRefObject, useState, KeyboardEvent, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DraftHandleValue, EditorCommand, EditorState, Modifier, getDefaultKeyBinding } from 'draft-js';
import { Key } from 'ts-key-enum';
import { Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import createMentionPlugin, { MentionData, MentionPluginStore } from '@draft-js-plugins/mention';
import { useDebounce } from 'usehooks-ts';

import './index.less';

import IbIcon from '../common/IbIcon';
import IbButton from '../common/IbButton';
import { EntityMutability, EntityType, editorStateToMarkdown } from '../../../utils/markdownEditor';
import { isTouchOnlyDevice } from '../../../utils/browserUtil';
import {
  InboxAttachmentModel,
  InboxFileLinkContentModel,
  InboxMentionModel,
  InboxMessageAttachmentModel,
  InboxMessageModel,
  InboxParticipantModel,
} from '../../../../api';
import IbTag from '../common/IbTag';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbAttachmentInput from '../IbAttachmentInput';
import IbQuotedMessage from '../IbQuotedMessage';
import { LAYOUT_WIDTH_BREAKPOINTS, MENTION_PREFIX } from '../../../constants';
import { IEntityItem } from '../../storages';
import { ITaskOperation } from '../../tasks';
import { IPreviewModalParams } from '../../recoil';
import { isPreviewAvailable, tryGetPreviewUrl, tryGetThumbnailUrl } from '../../utils/fileUtil';
import IbTariffLimitModal from '../IbTariffLimitModal';

import MentionEntry from './MentionEntry';
import SuggestionsPopover from './SuggestionsPopover';
import NoOperatorsPopover from './NoOperatorsPopover';

const EDITOR_SEND_MESSAGE_COMMAND = 'send-message-commnad';

const OPERATOR_LIST_DEFAULT = [] as InboxParticipantModel[];

const SEARCH_DELAY = 200; // ms
const POPPER_OFFSET_Y = 20;

const MAIN_CLASS_NAME = 'ib-message-input';
const INTERNAL_CLASS_NAME = 'ib-message-input_internal';
const QUOTED_MESSAGES_CLASS_NAME = `${MAIN_CLASS_NAME}__quoted-messages`;
const ATTACHMENTS_CLASS_NAME = `${MAIN_CLASS_NAME}__attachments`;
const CONTROLS_CLASS_NAME = `${MAIN_CLASS_NAME}__controls`;
const LEFT_CONTROLS_CLASS_NAME = `${CONTROLS_CLASS_NAME}__left`;
const VISIBILITY_BUTTON_DESKTOP_CLASS_NAME = `${LEFT_CONTROLS_CLASS_NAME}__visibility`;
const VISIBILITY_BUTTON_MOBILE_CLASS_NAME = `${LEFT_CONTROLS_CLASS_NAME}__visibility_mobile`;
const MENTION_CONTROL_CLASS_NAME = `${MAIN_CLASS_NAME}__mention`;

export const getPopperReferenceElement = (store: MentionPluginStore, windowWidth: number): HTMLElement | null => {
  const referenceElement = store.getReferenceElement();
  return windowWidth > LAYOUT_WIDTH_BREAKPOINTS.small
    ? referenceElement
    : referenceElement?.closest(`.${MAIN_CLASS_NAME}`) ?? null;
};

const getMentionPlugin = () =>
  createMentionPlugin({
    mentionComponent(mentionProps) {
      return <span className={MENTION_CONTROL_CLASS_NAME}>{mentionProps.children}</span>;
    },
    mentionPrefix: MENTION_PREFIX,
    entityMutability: EntityMutability.IMMUTABLE,
    supportWhitespace: true,
    popperOptions: {
      placement: 'top-start',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: () => [0, POPPER_OFFSET_Y],
          },
        },
      ],
    },
  });

interface IMentionEntity {
  data: MentionData;
  start: number;
  end: number;
}

export interface IIbMessageInputRef {
  focusToEditor: () => void;
}

export interface IIbMessageInputProps {
  messageInputRef: MutableRefObject<IIbMessageInputRef | undefined>;
  currentUserId: string;
  isInternal: boolean;
  mentionLinksEnabled: boolean;
  operatorList?: InboxParticipantModel[];
  operatorListLoading?: boolean;
  attachments?: IEntityItem<InboxAttachmentModel>[];
  quotedMessages?: IEntityItem<InboxMessageModel>[];
  tariffLimitExceeded?: boolean;
  onMessageSend?: (message: string, mentions: InboxMentionModel[], quotedMessages: InboxMessageModel[]) => void;
  onAttachmentUpload?: (file: RcFile) => void;
  onAttachmentReUpload?: (entity: InboxAttachmentModel) => void;
  onAttachmentDelete?: (entity: InboxAttachmentModel, operation: ITaskOperation) => void;
  onAttachmentPreview?: (params: IPreviewModalParams) => void;
  onQuotedMessageClick?: (messageId: string, activityId: string) => void;
  onQuotedMessageDelete?: (entity: InboxMessageModel) => void;
  onIsInternalChanged?: () => void;
  onOperatorsSearch?: (search: string) => void;
}

const IbMessageInput: React.FC<IIbMessageInputProps> = ({
  messageInputRef,
  currentUserId,
  isInternal,
  mentionLinksEnabled,
  operatorList,
  operatorListLoading,
  attachments,
  quotedMessages,
  tariffLimitExceeded,
  onAttachmentUpload,
  onAttachmentReUpload,
  onAttachmentDelete,
  onAttachmentPreview,
  onQuotedMessageClick,
  onQuotedMessageDelete,
  onMessageSend,
  onIsInternalChanged,
  onOperatorsSearch,
}) => {
  const classes = [MAIN_CLASS_NAME];

  isInternal && classes.push(INTERNAL_CLASS_NAME);

  operatorList = getDefaultIfUndefined(operatorList, OPERATOR_LIST_DEFAULT);

  const { t } = useTranslation();

  const [mentionPlugin] = useState(getMentionPlugin());

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [addedMentions, setAddedMentions] = useState<IMentionEntity[]>([]);
  const suggestions =
    operatorList
      ?.filter((o) => o.id !== currentUserId)
      .map((o) => {
        return {
          id: o.id,
          name: o.subject.person.name.fullName ?? '',
          status: o.status.toLowerCase(),
          subjectId: o.subject.id,
          participantId: o.id,
        };
      }) ?? [];

  const [open, setOpen] = useState(false);
  const [tariffModalVisible, setTariffModalVisible] = useState(false);

  const [operatorsSearchText, setOperatorsSearchText] = useState('');

  const debouncedOperatorsSearchText = useDebounce(operatorsSearchText, SEARCH_DELAY);
  const deferredOperatorsSearchText = operatorsSearchText ? debouncedOperatorsSearchText : '';

  const loadOperators = () => {
    onOperatorsSearch?.(deferredOperatorsSearchText);
  };
  useEffect(loadOperators, [deferredOperatorsSearchText]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    if (!_open && operatorsSearchText) {
      setOperatorsSearchText('');
    }
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    setOperatorsSearchText(value);
  }, []);

  const message = editorStateToMarkdown(editorState);
  const sendButtonIsVisible = !!attachments?.length || !!message || !!quotedMessages?.length;

  const resetEditor = () => {
    const newEditorState = EditorState.createEmpty();
    setEditorState(EditorState.moveFocusToEnd(newEditorState));
  };

  const trySendMessage = () => {
    if (!sendButtonIsVisible) {
      return;
    }

    if (tariffLimitExceeded) {
      setTariffModalVisible(true);
      return;
    }

    onMessageSend?.(
      message,
      addedMentions.map((m) => {
        return {
          mentionedUserId: m.data.mention.subjectId as string,
          mentionedParticipantId: m.data.mention.participantId,
          startPosition: m.start,
          endPosition: m.end,
        };
      }),
      quotedMessages?.map((m) => m.entity) ?? []
    );
    resetEditor();
  };

  const keyBindingFn = (e: KeyboardEvent): string | null | undefined => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'Escape') {
      return undefined;
    }

    if (!e.ctrlKey && e.key === Key.Enter && !isTouchOnlyDevice()) {
      return EDITOR_SEND_MESSAGE_COMMAND;
    }

    return getDefaultKeyBinding(e);
  };

  const onSendButtonClick = () => trySendMessage();
  const handleKeyCommand = (command: EditorCommand): DraftHandleValue => {
    if (command === EDITOR_SEND_MESSAGE_COMMAND) {
      trySendMessage();
      return 'handled';
    }
    return 'not-handled';
  };

  const insertText = (text: string, editorValue: EditorState) => {
    const currentContent = editorValue.getCurrentContent();
    const currentSelection = editorValue.getSelection();

    const newContent = Modifier.replaceText(currentContent, currentSelection, text);

    const newEditorState = EditorState.push(editorValue, newContent, 'insert-characters');
    return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
  };

  const sendTextToEditor = (text: string) => {
    setEditorState(insertText(text, editorState));
  };

  const onMentionButtonClick = () => {
    if (open) {
      setOpen(false);
      return;
    }

    const selectionState = editorState.getSelection();
    const start = selectionState.getStartOffset();

    let textToAdd = '';

    if (start > 0) {
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = editorState.getCurrentContent();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const charBeforeCursor = currentContentBlock.getText().slice(start - 1, start);

      if (charBeforeCursor !== ' ') {
        textToAdd += ' ';
      }
    }

    textToAdd += MENTION_PREFIX;
    sendTextToEditor(textToAdd);
    setOpen(true);
  };

  const onLockButtonClick = () => onIsInternalChanged?.();

  const onEditorChange = (newEditorState: EditorState) => {
    // HACK: исправлен ситуация, когда после отправки через Enter при вводе первого символа
    // неправильно устанавливается курсор
    // https://github.com/facebookarchive/draft-js/issues/1198#issuecomment-535651492
    const currentContentTextLength = editorState.getCurrentContent().getPlainText().length;
    const newContentTextLength = newEditorState.getCurrentContent().getPlainText().length;
    if (currentContentTextLength === 0 && newContentTextLength === 1) {
      // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
      setEditorState(EditorState.moveFocusToEnd(newEditorState));
    } else {
      setEditorState(newEditorState);
    }

    const contentState = newEditorState.getCurrentContent();

    const entities: IMentionEntity[] = [];
    contentState.getBlockMap().forEach((block) => {
      let currentEntity: MentionData;
      block?.findEntityRanges(
        (character) => {
          const charEntity = character.getEntity();
          if (charEntity) {
            const contentEntity = contentState.getEntity(charEntity);
            if (contentEntity.getType() === EntityType.MENTION) {
              currentEntity = contentEntity.getData();
              return true;
            }
          }

          return false;
        },
        (start, end) => {
          entities.push({ data: currentEntity, start, end });
        }
      );
    });
    setAddedMentions(entities);
  };

  const onAddMention = () => !isInternal && onIsInternalChanged?.();

  const beforeUpload = async (file: RcFile) => {
    onAttachmentUpload?.(file);

    return false;
  };

  const onTariffModalCancel = () => {
    setTariffModalVisible(false);
  };

  const renderQuotedMessages = () => {
    if (!quotedMessages?.length) {
      return null;
    }

    return (
      <div className={QUOTED_MESSAGES_CLASS_NAME}>
        {quotedMessages.map((quotedMessage, i) => {
          const onClick = () => onQuotedMessageClick?.(quotedMessage.entity.id, quotedMessage.entity.activity.id);
          const onDelete = () => onQuotedMessageDelete?.(quotedMessage.entity);
          return (
            <IbQuotedMessage
              key={i} // NOTE: переиспользование элемента при цитировании другого сообщения, чтобы не триггерить анимацию.
              inMessageInput
              attachments={quotedMessage.entity.attachments.map((a) => {
                return {
                  id: a.id,
                  external: a.external,
                  file: a.file,
                  thumbnails: a.thumbnails,
                } as InboxMessageAttachmentModel;
              })}
              content={quotedMessage.entity.content}
              direction={quotedMessage.entity.direction}
              mentionLinksEnabled={mentionLinksEnabled}
              mentions={quotedMessage.entity.mentions}
              senderFullname={quotedMessage.entity.senderParticipant?.subject.fullName}
              onClick={onClick}
              onDelete={onDelete}
            />
          );
        })}
      </div>
    );
  };

  const renderAttachments = () => {
    if (!attachments?.length) {
      return null;
    }

    return (
      <div className={ATTACHMENTS_CLASS_NAME}>
        {attachments.map((attachment) => {
          const fileLink = attachment.entity.file.content[0] as InboxFileLinkContentModel | undefined;

          const onDelete = () => onAttachmentDelete?.(attachment.entity, attachment.operation);
          const onReUpload = () => onAttachmentReUpload?.(attachment.entity);

          const previewAttachments = attachments?.filter((a) => isPreviewAvailable(a.entity.file)) ?? [];

          const onAttachmentInputItemPreview = () => {
            const params = {
              isOpen: true,
              items: previewAttachments.map((a, i) => {
                return {
                  index: i,
                  url: tryGetPreviewUrl(a.entity.file) ?? '',
                };
              }),
              selectedIndex: previewAttachments.indexOf(attachment),
            } as IPreviewModalParams;

            onAttachmentPreview?.(params);
          };

          const thumbnailUrl = tryGetThumbnailUrl(
            attachment.entity.file,
            attachment.entity.thumbnails.map((t) => t.file)
          );

          return (
            <IbAttachmentInput
              key={attachment.entity.file.id}
              category={attachment.entity.file.category}
              fileName={attachment.entity.file.name}
              fileUrl={fileLink?.url}
              loadingCurrent={attachment.operation.progress.current}
              loadingMaximum={attachment.operation.progress.maximum}
              loadingStatus={attachment.operation.status}
              mediaGroup={attachment.entity.file.mediaGroup}
              // TODO: отображать миниатюру вместо основного содержимого файла.
              thumbnailUrl={thumbnailUrl}
              onDelete={onDelete}
              onPreview={onAttachmentInputItemPreview}
              onReUpload={onReUpload}
            />
          );
        })}
      </div>
    );
  };

  const getPopoverContainer = () =>
    operatorListLoading || operatorsSearchText || (operatorList || []).length ? SuggestionsPopover : NoOperatorsPopover;

  return (
    <>
      <div className={classes.join(' ')}>
        {renderQuotedMessages()}
        {renderAttachments()}
        <Editor
          ref={(el) => {
            messageInputRef.current = { focusToEditor: () => el?.focus() };
          }}
          spellCheck
          stripPastedStyles
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          placeholder={t('Enter your message...')}
          plugins={plugins}
          onChange={onEditorChange}
        />
        <MentionSuggestions
          renderEmptyPopup
          entryComponent={MentionEntry}
          open={open}
          popoverContainer={getPopoverContainer()}
          suggestions={operatorListLoading ? [{ id: '', name: '', isLoading: true }] : suggestions}
          onAddMention={onAddMention}
          onOpenChange={onOpenChange}
          onSearchChange={onSearchChange}
        />
        <div className={CONTROLS_CLASS_NAME}>
          <div className={LEFT_CONTROLS_CLASS_NAME}>
            <Upload multiple beforeUpload={beforeUpload} fileList={[]}>
              <IbButton icon={<IbIcon iconName="paperclip" size={20} />} type="icon" />
            </Upload>
            <IbButton icon={<IbIcon iconName="slightly-smiling-face" size={20} />} type="icon" />
            <IbButton icon={<IbIcon iconName="view-list" size={20} />} type="icon" />
            <IbButton icon={<IbIcon iconName="at-sign" size={20} />} type="icon" onClick={onMentionButtonClick} />
            <div className={VISIBILITY_BUTTON_DESKTOP_CLASS_NAME}>
              {!isInternal && (
                <IbButton icon={<IbIcon iconName="lock" size={20} />} type="icon" onClick={onLockButtonClick} />
              )}
              {isInternal && (
                <IbTag
                  content={t('Only visible to operators')}
                  iconLeft={<IbIcon iconName="lock" size={20} />}
                  style="warning"
                  onClick={onLockButtonClick}
                />
              )}
            </div>
            <div className={VISIBILITY_BUTTON_MOBILE_CLASS_NAME}>
              <IbButton
                icon={<IbIcon iconName="lock" size={20} />}
                status={isInternal ? 'warning' : 'default'}
                type="icon"
                onClick={onLockButtonClick}
              />
            </div>
          </div>
          {sendButtonIsVisible && <IbButton icon={<IbIcon iconName="send" />} onClick={onSendButtonClick} />}
        </div>
      </div>
      <IbTariffLimitModal visible={tariffModalVisible} onCancel={onTariffModalCancel} />
    </>
  );
};

export default IbMessageInput;
