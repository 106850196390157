import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import createTrend from 'trendline';
import moment from 'moment';
import { useSetRecoilState } from 'recoil';

import './index.less';

import IbCard from '../../../../../components/common/IbCard';
import { ConversationsPerDayModel } from '../../../../../../../api';
import { ANALYTICS_DATE_FORMATS } from '../../../../../../simple-bot/const';
import { analyticsReportsApi } from '../../../../../../apis';
import { inboxAlertsSelectorAdd } from '../../../../../recoil';
import { AlertTypes } from '../../../../../../constants';
import { formatTrendSlope } from '../../../../../../utils/stringUtil';

interface IAnalyticsBlockData {
  conversationsPerDayList: ConversationsPerDayModel[];
}

const CHART_SETTINGS = {
  lineColors: {
    total: '#1CBF4A',
  } as { [x: string]: string },
};

const DEFAULT_X_STEP = 1;
const X_KEY = 'x';
const TOTAL_KEY = 'total';

const ANALYTICS_BLOCK_CHART_GRADIENT_ID = 'analytics-block-chart-gradient-id';

const MAIN_CLASS_NAME = 'ib-analytics-block';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const COUNT_CLASS_NAME = `${CONTENT_CLASS_NAME}__count`;
const CHART_CLASS_NAME = `${CONTENT_CLASS_NAME}__chart`;
const EXTRA_CLASS_NAME = `${MAIN_CLASS_NAME}__extra`;
const TREND_CLASS_NAME = `${EXTRA_CLASS_NAME}__trend`;
const NEGATIVE_TREND_CLASS_NAME = `${TREND_CLASS_NAME}_negative`;

interface IAnalyticsCardProps {
  agentStageId?: string;
  botId?: string;
}

const AnalyticsCard: React.FC<IAnalyticsCardProps> = ({ agentStageId, botId }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [analyticsBlockData, setAnalyticsBlockData] = useState<IAnalyticsBlockData>({
    conversationsPerDayList: [],
  });

  const goToAnalytics = () => {
    if (!botId) return;
    push(`/inbox/bots/${botId}/analytics`);
  };

  const conversationsPerDayList = analyticsBlockData.conversationsPerDayList;

  const loadDataAsync = async () => {
    if (!agentStageId) {
      return;
    }

    setLoading(true);

    try {
      const fromDate = moment().add(-6, 'd').startOf('d').format(ANALYTICS_DATE_FORMATS.parse);
      const toDate = moment().startOf('d').format(ANALYTICS_DATE_FORMATS.parse);

      const conversationsPerDayResponse = await analyticsReportsApi.getConversationsPerDayList(
        agentStageId,
        fromDate,
        toDate
      );

      setAnalyticsBlockData({
        conversationsPerDayList: conversationsPerDayResponse.data,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }

    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId]);

  const maxCount = Math.max(...conversationsPerDayList.map((e) => e.totalCount));
  const stepX =
    !conversationsPerDayList.length || maxCount === 0 ? DEFAULT_X_STEP : maxCount / conversationsPerDayList.length;
  const trendData = conversationsPerDayList.map((e, index) => ({
    [X_KEY]: index * stepX,
    [TOTAL_KEY]: e.totalCount,
  }));

  const trend = createTrend(trendData, X_KEY, TOTAL_KEY);
  const total = conversationsPerDayList.reduce((partialSum, a) => partialSum + a.totalCount, 0);

  const renderExtra = () => {
    return (
      <span className={`${TREND_CLASS_NAME} ${trend.slope < 0 ? NEGATIVE_TREND_CLASS_NAME : ''}`}>
        {formatTrendSlope(trend.slope)}
      </span>
    );
  };

  return (
    <IbCard extra={renderExtra()} loading={loading || !agentStageId} title={t('Analytics')} onClick={goToAnalytics}>
      <div className={MAIN_CLASS_NAME}>
        <div className={CONTENT_CLASS_NAME}>
          <div className={COUNT_CLASS_NAME}>
            <div>{t('Conversations per week')}</div>
            <span>{total}</span>
          </div>
          <div className={CHART_CLASS_NAME}>
            <ResponsiveContainer height="100%" width="100%">
              <AreaChart data={conversationsPerDayList}>
                <defs>
                  <linearGradient gradientTransform="rotate(90)" id={ANALYTICS_BLOCK_CHART_GRADIENT_ID}>
                    <stop offset="0%" stopColor="#3EC463" />
                    <stop offset="100%" stopColor="#FFFFFF00" />
                  </linearGradient>
                </defs>
                <Area
                  dataKey={(e: ConversationsPerDayModel) => e.totalCount}
                  fill={`url(#${ANALYTICS_BLOCK_CHART_GRADIENT_ID})`}
                  isAnimationActive={false}
                  stroke={CHART_SETTINGS.lineColors.total}
                  strokeWidth={2}
                  type="monotone"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </IbCard>
  );
};

export default AnalyticsCard;
