import React, { useState } from 'react';

import SbButton from '../../../../components/common/SbButton';
import { ActivityAttachment } from '../../../../../../api';
import SbIcon from '../../../../components/common/SbIcon';

const MAIN_CLASS_NAME = 'sb-dialogs-card__content__panel-container__messages';
const ATTACHMENTS_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__attachments-container`;
const ATTACHMENT_CLASS_NAME = `${ATTACHMENTS_CONTAINER_CLASS_NAME}__item`;
const BOT_SELECTOR_NAME = 'bot';
const MAX_VISIBLE_COUNT = 4;

interface IAttachmentProps {
  attachments: ActivityAttachment[];
  role: string;
}

const Attachments: React.FC<IAttachmentProps> = ({ attachments, role }) => {
  const [visibleAttachments, setVisibleAttachments] = useState(attachments.slice(0, MAX_VISIBLE_COUNT));
  const [canExpand, setCanExpand] = useState(attachments.length > MAX_VISIBLE_COUNT);
  const remainingAttachments = attachments.slice(MAX_VISIBLE_COUNT);

  const onExpand = () => {
    setVisibleAttachments([...visibleAttachments, ...remainingAttachments]);
    setCanExpand(false);
  };

  const onCollapse = () => {
    setVisibleAttachments(visibleAttachments.slice(0, MAX_VISIBLE_COUNT));
    setCanExpand(true);
  };

  return (
    <>
      <div
        className={`${ATTACHMENTS_CONTAINER_CLASS_NAME} ${
          role == BOT_SELECTOR_NAME ? `${ATTACHMENTS_CONTAINER_CLASS_NAME}_end` : ''
        }`}
      >
        {visibleAttachments.map((m) => (
          <div key={m.contentUrl ?? ''} className={ATTACHMENT_CLASS_NAME}>
            <a download href={m.contentUrl ?? ''}>
              <SbIcon iconName="paperclip" size={16} />
              {m.name}
            </a>
          </div>
        ))}
        {canExpand && (
          <SbButton sbSize="medium" sbType="link" onClick={onExpand}>
            {`+ ещё ${remainingAttachments.length}`}
          </SbButton>
        )}
        {!canExpand && attachments.length > MAX_VISIBLE_COUNT && (
          <SbButton sbSize="medium" sbType="link" onClick={onCollapse}>
            Cвернуть
          </SbButton>
        )}
      </div>
    </>
  );
};

export default Attachments;
