import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';

import './index.less';

import IbTypography from '../common/IbTypography';
import IbIcon from '../common/IbIcon';
import IbSocial, { IbSocialType } from '../common/IbSocial';
import IbButton from '../common/IbButton';
import IbTable, { MOBILE_MAIN_CELL_CLASS_NAME } from '../common/IbTable';
import { IChannelData } from '../IbContactChannelList';
import IbModal from '../common/IbModal';

const MAIN_CLASS_NAME = 'ib-send-message-to-channel-modal';
const CHANNELS_TABLE_CLASS_NAME = `${MAIN_CLASS_NAME}__channel-table`;
const NAME_CELL_CLASS_NAME = `${CHANNELS_TABLE_CLASS_NAME}__name-cell`;
const ICON_CELL_CLASS_NAME = `${CHANNELS_TABLE_CLASS_NAME}__icon-cell`;

export interface IIbSendMessageToChannelModalProps {
  visible: boolean;
  channels: IChannelData[];
  onSendMessageToChat?: (chatId: string) => void;
  onCancel?: () => void;
}

const IbSendMessageToChannelModal: React.FC<IIbSendMessageToChannelModalProps> = ({
  visible,
  channels,
  onSendMessageToChat,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [selectedChatId, setSelectedChatId] = useState(channels[0]?.chatId);

  const onSendMessageClick = () => {
    if (!selectedChatId) {
      return;
    }

    onSendMessageToChat?.(selectedChatId);
  };

  const onTableRowClick = (channel: IChannelData) => {
    setSelectedChatId(channel.chatId);
  };

  const onVisibleChanged = () => {
    if (visible) {
      setSelectedChatId(channels[0]?.chatId);
    }
  };
  useEffect(onVisibleChanged, [visible]);

  const columns: ColumnType<IChannelData>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      render: (_, record) => (
        <div className={NAME_CELL_CLASS_NAME}>
          <IbSocial social={record.channelId as IbSocialType} />
          <IbTypography.Paragraph type="secondary">{record.name}</IbTypography.Paragraph>
        </div>
      ),
    },
    {
      render: (_, record) => (
        <IbTypography.Paragraph disabled type="secondary">
          {record.activeOn}
        </IbTypography.Paragraph>
      ),
    },
    {
      render: (_, record) => (
        <div className={ICON_CELL_CLASS_NAME}>
          {record.chatId === selectedChatId && <IbIcon iconName="check-one" size={20} />}
        </div>
      ),
    },
  ];

  const footer = (
    <>
      <IbButton onClick={onSendMessageClick}>{t('Send message')}</IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
    </>
  );

  return (
    <IbModal
      className={MAIN_CLASS_NAME}
      footer={footer}
      title={
        <IbTypography>
          <h4>{t('Send message to channel')}</h4>
        </IbTypography>
      }
      visible={visible}
      onCancel={onCancel}
    >
      <IbTypography>
        <IbTypography.Paragraph>{t('Activity')}</IbTypography.Paragraph>
      </IbTypography>
      <IbTable
        className={CHANNELS_TABLE_CLASS_NAME}
        columns={columns}
        dataSource={channels}
        emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
        onRowClick={onTableRowClick}
      />
    </IbModal>
  );
};

export default IbSendMessageToChannelModal;
