import { IWorkingSpaceTransform, IZoomContext } from '../types';

export const calcContextualTransform: (
  currentTransform: IWorkingSpaceTransform,
  targetZoom: number,
  zoomContext?: IZoomContext
) => IWorkingSpaceTransform = (currentTransform, targetZoom, context) => {
  const transform = { ...currentTransform, zoom: targetZoom };
  const containerRect = context?.container?.getBoundingClientRect();
  if (!containerRect?.width || !containerRect?.height) {
    return transform;
  }

  const cursorOffsetX = context?.e ? context.e.x - containerRect.x : containerRect.width / 2;
  const cursorOffsetY = context?.e ? context.e.y - containerRect.y : containerRect.height / 2;
  const cursorPositionX = (cursorOffsetX - currentTransform.x) / currentTransform.zoom;
  const cursorPositionY = (cursorOffsetY - currentTransform.y) / currentTransform.zoom;
  const zoomDelta = targetZoom - currentTransform.zoom;
  const offsetX = cursorPositionX * zoomDelta;
  const offsetY = cursorPositionY * zoomDelta;

  transform.x -= offsetX;
  transform.y -= offsetY;
  return transform;
};
