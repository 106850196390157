import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Flags } from 'react-feature-flags';

import { FeatureFlagNames, SUBJECT_ROLES } from '../../../constants';
import { InboxChatParticipantStatus, InboxChatStatus, TariffStatus } from '../../../../api';
import { IEntityRange } from '../../storages';
import { InboxChatFilter } from '../../storages/chats';
import IbChatTabs, { IChatTabsData } from '../../components/IbChatTabs';
import { getProfile } from '../../../utils/oidcUtil';
import { ChatTabKeys } from '../../components/IbChatList';
import {
  chatItemsSelector,
  chatRangeSelector,
  chatStatsSelector,
  currentOperatorSelector,
  tagListSelector,
} from '../../recoil';
import IbAccountWidgetWrapper from '../../wrappers/IbAccountWidgetWrapper';
import { IIbTagsSearchData } from '../../components/IbTagsSearch';

interface IChatTabsProps {
  chatId?: string;
  tabKey?: ChatTabKeys;
  onSelect?: (chatId?: string) => void;
  onStatusChanged?: (status: string) => void;
}

const ChatTabs: React.FC<IChatTabsProps> = ({ chatId, tabKey, onSelect, onStatusChanged }) => {
  const profile = getProfile();

  const tagList = useRecoilValue(tagListSelector);
  const currentOperator = useRecoilValue(currentOperatorSelector)?.entity;
  const tariffLimitExceeded = currentOperator && currentOperator.tariffResult.status !== TariffStatus.Allowed;

  const [searchData, setSearchData] = useState({} as IIbTagsSearchData);

  const searchFilter = new InboxChatFilter(
    undefined,
    undefined,
    undefined,
    [SUBJECT_ROLES.user],
    searchData.selectedTagIds,
    searchData.searchValue
  );
  const searchItems = useRecoilValue(chatItemsSelector(searchFilter));
  const searchStats = useRecoilValue(chatStatsSelector(searchFilter));
  const [searchRange, setSearchRange] = useRecoilState(chatRangeSelector(searchFilter));

  const queueFilter = new InboxChatFilter([InboxChatStatus.Queued]);
  const queueItems = useRecoilValue(chatItemsSelector(queueFilter));
  const queueStats = useRecoilValue(chatStatsSelector(queueFilter));
  const [queueRange, setQueueRange] = useRecoilState(chatRangeSelector(queueFilter));

  const myFilter = new InboxChatFilter(
    undefined,
    [InboxChatParticipantStatus.Assigned, InboxChatParticipantStatus.Mentioned],
    [profile.userId],
    [SUBJECT_ROLES.operator]
  );
  const myItems = useRecoilValue(chatItemsSelector(myFilter));
  const myStats = useRecoilValue(chatStatsSelector(myFilter));
  const [myRange, setMyRange] = useRecoilState(chatRangeSelector(myFilter));

  const closedFilter = new InboxChatFilter([InboxChatStatus.Closed]);
  const closedItems = useRecoilValue(chatItemsSelector(closedFilter));
  const closedStats = useRecoilValue(chatStatsSelector(closedFilter));
  const [closedRange, setClosedRange] = useRecoilState(chatRangeSelector(closedFilter));

  const allFilter = new InboxChatFilter();
  const allItems = useRecoilValue(chatItemsSelector(allFilter));
  const allStats = useRecoilValue(chatStatsSelector(allFilter));
  const [allRange, setAllRange] = useRecoilState(chatRangeSelector(allFilter));

  const isSearch = !!searchFilter.searchText || !!searchFilter.tagIds?.length;

  const chatTabsData: IChatTabsData = {
    queueTab: {
      count: currentOperator?.statistics?.queuedChatCount,
      items: queueItems,
      stats: queueStats,
      range: queueRange,
      onScroll: (scroll) => setQueueRange(scroll as IEntityRange),
    },
    myTab: {
      count: currentOperator?.statistics?.assignedChatCount,
      items: myItems,
      stats: myStats,
      range: myRange,
      onScroll: (scroll) => setMyRange(scroll as IEntityRange),
    },
    closedTab: {
      items: closedItems,
      stats: closedStats,
      range: closedRange,
      onScroll: (scroll) => setClosedRange(scroll as IEntityRange),
    },
    allTab: {
      items: allItems,
      stats: allStats,
      range: allRange,
      onScroll: (scroll) => setAllRange(scroll as IEntityRange),
    },
    searchTab: isSearch
      ? {
          items: searchItems,
          stats: searchStats,
          range: searchRange,
          onScroll: (scroll) => setSearchRange(scroll as IEntityRange),
        }
      : undefined,
  };

  const renderChatTabs = (mentionLinksEnabled: boolean) => {
    return (
      <IbChatTabs
        accountWidget={<IbAccountWidgetWrapper />}
        chatTabsData={chatTabsData}
        currentUserId={profile.userId}
        mentionLinksEnabled={mentionLinksEnabled}
        searchData={searchData}
        selectedChatId={chatId}
        tabKey={tabKey}
        tagList={tagList}
        tariffLimitExceeded={tariffLimitExceeded}
        onChatSearch={setSearchData}
        onChatSelected={onSelect}
        onStatusChanged={onStatusChanged}
      />
    );
  };

  return (
    <Flags
      authorizedFlags={[FeatureFlagNames.USERS_MANAGEMENT]}
      renderOff={() => renderChatTabs(false)}
      renderOn={() => renderChatTabs(true)}
    />
  );
};

export default ChatTabs;
