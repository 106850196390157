import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async-hook';

import './index.less';

import IbCard from '../../../../../components/common/IbCard';
import {
  AgentModel,
  AgentStageAccountModel,
  AgentStageAccountSortDirection,
  SingleBotModel,
} from '../../../../../../../api';
import { AlertTypes, CHANNELS_PAGE_SIZE, CHANNEL_STATUS_UPDATED } from '../../../../../../constants';
import { hubConnections } from '../../../../../../utils/socketsUtil';
import { agentApi, agentStageAccountApi } from '../../../../../../apis';
import { getChannelDisplayName } from '../../../../../../utils/stringUtil';
import IbSocial, { IbSocialType } from '../../../../../components/common/IbSocial';
import IbTypography from '../../../../../components/common/IbTypography';
import IbChannelStatus from '../../../../../components/common/IbChannelStatus';
import { inboxAlertsSelectorAdd } from '../../../../../recoil';
import IbIcon from '../../../../../components/common/IbIcon';
import IbButton from '../../../../../components/common/IbButton';

const MAIN_CLASS_NAME = 'ib-channels-card';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const LIST_CLASS_NAME = `${CONTENT_CLASS_NAME}__list`;
const CHANNEL_CONTAINER_CLASS_NAME = `${LIST_CLASS_NAME}__channel-container`;
const CHANNEL_ICON_CLASS_NAME = `${CHANNEL_CONTAINER_CLASS_NAME}__icon`;
const CHANNEL_TITLE_CLASS_NAME = `${CHANNEL_CONTAINER_CLASS_NAME}__title`;
const CHANNEL_STATUS_CLASS_NAME = `${CHANNEL_CONTAINER_CLASS_NAME}__status`;
const EMPTY_LIST_MESSAGE_CLASS_NAME = `${CONTENT_CLASS_NAME}__empty-list-message`;

interface IChannelsCardProps {
  bot?: SingleBotModel;
}

const ChannelsCard: React.FC<IChannelsCardProps> = ({ bot }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState<AgentModel>();
  const [channels, setChannels] = useState<AgentStageAccountModel[]>([]);

  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const goToChannels = () => {
    push('/inbox/channels');
  };

  const loadChannelsDataAsync = async () => {
    if (!bot) {
      return;
    }

    setLoading(true);

    try {
      const agentResponse = await agentApi.getAgent(bot.entry.agentId);
      setAgent(agentResponse.data);

      const prodChannelsResponse = await agentStageAccountApi.searchAgentStageAccounts(
        undefined,
        agentResponse.data?.productionAgent.id,
        undefined,
        AgentStageAccountSortDirection.StatusDescending,
        0,
        CHANNELS_PAGE_SIZE
      );

      setChannels(prodChannelsResponse.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setLoading(false);
  };
  const loadChannelsData = () => {
    setLoading(true);
    loadChannelsDataAsync().finally();
  };
  useEffect(loadChannelsData, [bot]);

  const channelUpdatedEventHandler = (args: { agentId: string }) => {
    if (args.agentId === agent?.id) {
      loadChannelsDataAsync().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
    return () => conn.off(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
  };
  useEffect(subscribe, [conn]);

  const onAddChannelClick = () => {
    goToChannels();
  };

  const renderChannels = () => {
    return channels.map((channel) => {
      return (
        <div key={channel.id} className={CHANNEL_CONTAINER_CLASS_NAME}>
          <div className={CHANNEL_ICON_CLASS_NAME}>
            <IbSocial social={channel.channelId as IbSocialType} />
          </div>
          <div className={CHANNEL_TITLE_CLASS_NAME}>
            <IbTypography.Paragraph>{getChannelDisplayName(channel)}</IbTypography.Paragraph>
          </div>
          <div className={CHANNEL_STATUS_CLASS_NAME}>
            <IbChannelStatus enabledStatus={channel.status} setupResult={channel.setupResult} />
          </div>
        </div>
      );
    });
  };

  const renderContent = () => {
    if (!channels.length) {
      return (
        <>
          <div className={EMPTY_LIST_MESSAGE_CLASS_NAME}>{t("You don't have any messengers connected to the bot")}</div>
          <IbButton icon={<IbIcon iconName="add-one" />} type="secondary" onClick={onAddChannelClick}>
            {t('Add channel')}
          </IbButton>
        </>
      );
    }

    return <div className={LIST_CLASS_NAME}>{renderChannels()}</div>;
  };

  return (
    <IbCard loading={loading || !bot} title={t('Channels')} onClick={goToChannels}>
      <div className={MAIN_CLASS_NAME}>
        <div className={CONTENT_CLASS_NAME}>{renderContent()}</div>
      </div>
    </IbCard>
  );
};

export default ChannelsCard;
