import React, { ReactNode } from 'react';

import './index.less';

import { LogoElma } from '../../../assets';
import IbMainMenu from '../IbMainMenu';
import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const CHAT_SELECTED_DEFAULT = false;

const MAIN_CLASS_NAME = 'ib-sider';
const CHAT_SELECTED_CLASS_NAME = `${MAIN_CLASS_NAME}_chat-selected`;
const LOGO_CLASS_NAME = `${MAIN_CLASS_NAME}__logo`;
const MENU_CLASS_NAME = `${MAIN_CLASS_NAME}__menu`;
const ACCOUNT_CLASS_NAME = `${MAIN_CLASS_NAME}__account`;

interface IIbSiderProps {
  selectedItem: string;
  chatSelected?: boolean;
  accountWidget?: ReactNode;
  onGoToMainPage: () => void;
}

const IbSider: React.FC<IIbSiderProps> = ({ selectedItem, chatSelected, accountWidget, onGoToMainPage }) => {
  chatSelected = getDefaultIfUndefined(chatSelected, CHAT_SELECTED_DEFAULT);

  return (
    <div className={`${MAIN_CLASS_NAME} ${chatSelected ? CHAT_SELECTED_CLASS_NAME : ''}`}>
      <div className={LOGO_CLASS_NAME}>
        <LogoElma onClick={onGoToMainPage} />
      </div>
      <div className={MENU_CLASS_NAME}>
        <IbMainMenu selectedItem={selectedItem} />
      </div>
      <div className={ACCOUNT_CLASS_NAME}>{accountWidget}</div>
    </div>
  );
};

export default IbSider;
