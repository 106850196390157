import React from 'react';
import { Radio } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';

import './index.less';

const MAIN_CLASS_NAME = 'ib-radio-button';

export type IIbRadioButtonProps = RadioButtonProps;

const IbRadioButton: React.FC<IIbRadioButtonProps> = ({ className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <Radio.Button {...otherProps} className={classes.join(' ')} />;
};

export default IbRadioButton;
