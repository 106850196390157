import React from 'react';
import { useRecoilValue } from 'recoil';

import './index.less';

import Bindings from '../Bindings';
import {
  currentScenarioStructureSelector,
  workingSpaceTransformAnimationSelector,
  workingSpaceTransformSelector,
} from '../../../../recoil/scenarioStructure';
import DefaultTriggerGroup from '../DefaultTriggerGroup';
import DefaultActionGroup from '../DefaultActionGroup';
import { instanceOfDefaultActionGroupSchema, instanceOfDefaultTriggerGroupSchema } from '../../utils';

const WorkingSpace: React.FC = () => {
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const workingSpaceTransform = useRecoilValue(workingSpaceTransformSelector);
  const workingSpaceTransformAnimation = useRecoilValue(workingSpaceTransformAnimationSelector);

  if (!scenarioStructure) return null;

  const transform = `translate(${workingSpaceTransform.x}px, ${workingSpaceTransform.y}px) scale(${workingSpaceTransform.zoom})`;

  const renderTriggerGroup = () => {
    const triggerGroup = scenarioStructure.triggerGroup;
    if (instanceOfDefaultTriggerGroupSchema(triggerGroup)) {
      return <DefaultTriggerGroup group={triggerGroup} />;
    }
    throw new Error(`Тип группы триггеров ${triggerGroup.$kind} не поддерживается.`);
  };

  const renderActionGroups = () => {
    return (scenarioStructure.actionGroups || []).map((actionGroup) => {
      if (instanceOfDefaultActionGroupSchema(actionGroup)) {
        return <DefaultActionGroup key={actionGroup.id} group={actionGroup} />;
      }
      throw new Error(`Тип группы действия ${actionGroup.$kind} не поддерживается.`);
    });
  };

  const classes = ['scenario-editor-working-space'];
  if (workingSpaceTransformAnimation) {
    classes.push('scenario-editor-working-space_animated');
  }

  return (
    <div
      className={classes.join(' ')}
      role="none"
      style={{
        transform,
      }}
    >
      <Bindings bindings={scenarioStructure.bindings} />
      {renderTriggerGroup()}
      {renderActionGroups()}
    </div>
  );
};

export default WorkingSpace;
