import { i18n } from 'i18next';

import { ICountryInfo } from '../types';
import { LANGUAGE_DEFAULT } from '../i18n';

const LANGUAGE_LENGTH = 2;

export const tryGetCountryName = (info: ICountryInfo, language?: string): string | undefined => {
  if (!language) {
    return undefined;
  }

  let name = info.name[language];
  if (name) {
    return name;
  }

  if (language.length <= LANGUAGE_LENGTH) {
    return undefined;
  }

  language = language.substr(0, LANGUAGE_LENGTH);
  name = info.name[language];

  return name || undefined;
};

export const getCountryName = (info: ICountryInfo, i18n: i18n): string => {
  return (
    tryGetCountryName(info, i18n.language) ||
    tryGetCountryName(info, i18n.resolvedLanguage) ||
    i18n.languages.map((l) => tryGetCountryName(info, l)).filter((n) => n)[0] ||
    tryGetCountryName(info, LANGUAGE_DEFAULT) ||
    info.name.ru
  );
};
