import { DefaultValue, noWait, selector, selectorFamily } from 'recoil';
import { v4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import { EntityKey, IEntityItem, IEntityRange, IEntityStats, PageSizes } from '../storages';
import { InboxChatFilter } from '../storages/chats';
import { InboxMessageFilter } from '../storages/messages';
import { InboxAttachmentFilter } from '../storages/attachments';
import {
  InboxAttachmentModel,
  BotSortDirection,
  InboxChannelModelPaginationResponse,
  InboxChatModel,
  InboxMessageModel,
  InboxParticipantModel,
  InboxParticipantStatus,
  ListBotModel,
  TagModel,
  InboxOperatorGroupModel,
  ListBotModelPaginationResponse,
  InboxContactModelPaginationResponse,
} from '../../../api';
import { botApi, inboxChannelApi, inboxOperatorGroupApi, inboxContactApi, tagApi } from '../../apis';
import { RECOIL_LOADABLE_STATES } from '../../constants';
import { isReloading } from '../../utils/oidcUtil';
import { InboxParticipantFilter } from '../storages/participants';

import {
  IInboxAlert,
  INetworkConnection,
  InboxChannelFilterParams,
  PersonFilterParams,
  IPreviewModalParams,
  BotFilterParams,
} from './types';
import {
  networkConnectionState,
  messageItemsState,
  messageStatsState,
  messageRangeState,
  chatItemsState,
  chatStatsState,
  chatRangeState,
  chatItemState,
  contactListFilterState,
  attachmentItemsState,
  attachmentRangeState,
  currentActivityIdState,
  channelListFilterState,
  botListSearchState,
  inboxAlertsState,
  participantItemState,
  tagListSearchState,
  participantItemsState,
  participantRangeState,
  participantStatsState,
  operatorGroupListFilterState,
  operatorsPageSearchState,
  previewModalParamsState,
  botListFilterState,
} from './atom';

const CONTACT_PAGINATION_RESPONSE_DEFAULT_VALUE = {
  items: [],
  hasMore: false,
  pageIndex: 0,
} as InboxContactModelPaginationResponse;
const CONTACT_LIST_CACHE_SIZE = 3;

const BOT_PAGINATION_RESPONSE_DEFAULT_VALUE = {
  items: [],
  hasMore: false,
  pageIndex: 0,
} as ListBotModelPaginationResponse;
const BOT_LIST_CACHE_SIZE = 3;

const CHANNEL_PAGINATION_RESPONSE_DEFAULT_VALUE = {
  items: [],
  hasMore: false,
  pageIndex: 0,
} as InboxChannelModelPaginationResponse;
const CHANNEL_LIST_CACHE_SIZE = 3;

const BOT_LIST_DEFAULT_VALUE = [] as ListBotModel[];

const TAG_LIST_DEFAULT_VALUE = [] as TagModel[];

const OPERATOR_GROUP_LIST_DEFAULT_VALUE = [] as InboxOperatorGroupModel[];

export const inboxAlertsSelector = selector<IInboxAlert[]>({
  key: 'inboxAlertsSelector',
  get: ({ get }) => get(inboxAlertsState),
});

export const inboxAlertsSelectorAdd = selector<IInboxAlert>({
  key: 'inboxAlertsSelectorAdd',
  get: () => ({} as IInboxAlert),
  set: ({ set, get }, newAlert) => {
    if (isReloading() || newAlert instanceof DefaultValue) {
      return;
    }
    set(inboxAlertsState, [...get(inboxAlertsState), { ...newAlert, id: v4() }]);
  },
});

export const inboxAlertsSelectorRemove = selector<string>({
  key: 'inboxAlertsSelectorRemove',
  get: () => '',
  set: ({ set, get }, id) => {
    if (id instanceof DefaultValue) {
      return;
    }
    const filteredAlerts = get(inboxAlertsState).filter((item) => item.id !== id);
    set(inboxAlertsState, filteredAlerts);
  },
});

export const networkConnectionSelector = selector<INetworkConnection>({
  key: 'networkConnectionSelector',
  get: ({ get }) => get(networkConnectionState),
});

export const operatorsPageSearchSelector = selector<string>({
  key: 'operatorsPageSearchSelector',
  get: ({ get }) => get(operatorsPageSearchState),
  set: ({ set }, newValue) => set(operatorsPageSearchState, newValue),
});

export const participantItemSelector = selectorFamily<IEntityItem<InboxParticipantModel> | undefined, string>({
  key: 'participantItemSelector',
  get: (id) => ({ get }) => (id ? get(participantItemState(id)) : undefined),
});

export const participantItemsSelector = selectorFamily<IEntityItem<InboxParticipantModel>[], InboxParticipantFilter>({
  key: 'participantItemsSelector',
  get: (filter) => ({ get }) => get(participantItemsState(filter)),
});

export const participantStatsSelector = selectorFamily<IEntityStats, InboxParticipantFilter>({
  key: 'participantStatsSelector',
  get: (filter) => ({ get }) => get(participantStatsState(filter)),
});

export const participantRangeSelector = selectorFamily<IEntityRange, InboxParticipantFilter>({
  key: 'participantRangeSelector',
  get: (filter) => ({ get }) => get(participantRangeState(filter)),
  set: (filter) => ({ set }, newValue) => set(participantRangeState(filter), newValue),
});

export const messageItemsSelector = selectorFamily<IEntityItem<InboxMessageModel>[], InboxMessageFilter>({
  key: 'messageItemsSelector',
  get: (filter) => ({ get }) => get(messageItemsState(filter)),
});

export const messageStatsSelector = selectorFamily<IEntityStats, InboxMessageFilter>({
  key: 'messageStatsSelector',
  get: (filter) => ({ get }) => get(messageStatsState(filter)),
});

export const messageRangeSelector = selectorFamily<IEntityRange, InboxMessageFilter>({
  key: 'messageRangeSelector',
  get: (filter) => ({ get }) => get(messageRangeState(filter)),
  set: (filter) => ({ set }, newValue) => set(messageRangeState(filter), newValue),
});

export const chatItemSelector = selectorFamily<IEntityItem<InboxChatModel> | undefined, string>({
  key: 'chatItemSelector',
  get: (id) => ({ get }) => get(chatItemState(id)),
});

export const chatItemsSelector = selectorFamily<IEntityItem<InboxChatModel>[], InboxChatFilter>({
  key: 'chatItemsSelector',
  get: (filter) => ({ get }) => get(chatItemsState(filter)),
});

export const chatStatsSelector = selectorFamily<IEntityStats, InboxChatFilter>({
  key: 'chatStatsSelector',
  get: (filter) => ({ get }) => get(chatStatsState(filter)),
});

export const chatRangeSelector = selectorFamily<IEntityRange, InboxChatFilter>({
  key: 'chatRangeSelector',
  get: (filter) => ({ get }) => get(chatRangeState(filter)),
  set: (filter) => ({ set }, newValue) => set(chatRangeState(filter), newValue),
});

export const attachmentItemsSelector = selectorFamily<IEntityItem<InboxAttachmentModel>[], InboxAttachmentFilter>({
  key: 'attachmentItemsSelector',
  get: (filter) => ({ get }) => get(attachmentItemsState(filter)),
});

export const attachmentRangeSelector = selectorFamily<IEntityRange, InboxAttachmentFilter>({
  key: 'attachmentRangeSelector',
  get: (filter) => ({ get }) => get(attachmentRangeState(filter)),
  set: (filter) => ({ set }, newValue) => set(attachmentRangeState(filter), newValue),
});

export const currentOperatorSelector = selector<IEntityItem<InboxParticipantModel> | undefined>({
  key: 'currentOperatorSelector',
  get: ({ get }) => get(participantItemState(EntityKey.Me)),
});

export const currentOperatorStatusSelector = selector<InboxParticipantStatus | undefined>({
  key: 'currentOperatorStatusSelector',
  get: ({ get }) => get(participantItemState(EntityKey.Me))?.entity.status,
  set: ({ set, get }, newValue) => {
    const participantItem = get(participantItemState(EntityKey.Me));
    if (participantItem?.entity && newValue) {
      const copy = cloneDeep(participantItem);
      copy.entity.status = newValue as InboxParticipantStatus;
      set(participantItemState(EntityKey.Me), copy);
    }
  },
});

export const contactListFilterSelector = selector<PersonFilterParams>({
  key: 'contactListFilterSelector',
  get: ({ get }) => get(contactListFilterState),
  set: ({ set }, newValue) => set(contactListFilterState, newValue),
});

// NOTE: для хранения текущих данных во время загрузки с сервера
let contactPaginationResponseCurrentValue = CONTACT_PAGINATION_RESPONSE_DEFAULT_VALUE;
export const contactListSelectorAsync = selector<InboxContactModelPaginationResponse>({
  key: 'contactListSelectorAsync',
  get: async ({ get }) => {
    const filter = get(contactListFilterSelector);

    try {
      const response = await inboxContactApi.searchInboxContacts(
        filter.search,
        filter.tagIds,
        filter.pageIndex,
        PageSizes.storageContacts
      );
      contactPaginationResponseCurrentValue = filter.loadMore
        ? {
            ...response.data,
            items: [...(contactPaginationResponseCurrentValue.items || []), ...(response.data.items || [])],
          }
        : response.data;
      return contactPaginationResponseCurrentValue;
    } catch (e) {
      return contactPaginationResponseCurrentValue;
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru',
    maxSize: CONTACT_LIST_CACHE_SIZE,
  },
});

export const contactListSelector = selector<InboxContactModelPaginationResponse>({
  key: 'contactListSelector',
  get: ({ get }) => get(contactListSelectorAsync),
});

export const botListFilterSelector = selector<BotFilterParams>({
  key: 'botListFilterSelector',
  get: ({ get }) => get(botListFilterState),
  set: ({ set }, newValue) => set(botListFilterState, newValue),
});

// NOTE: для хранения текущих данных во время загрузки с сервера
let botPaginationResponseCurrentValue = BOT_PAGINATION_RESPONSE_DEFAULT_VALUE;
export const botListSelectorAsync = selector<ListBotModelPaginationResponse>({
  key: 'botListSelectorAsync',
  get: async ({ get }) => {
    const filter = get(botListFilterSelector);

    try {
      const response = await botApi.searchBots(filter.search, filter.sort, filter.pageIndex, PageSizes.storageBots);
      botPaginationResponseCurrentValue = filter.loadMore
        ? {
            ...response.data,
            items: [...(botPaginationResponseCurrentValue.items || []), ...(response.data.items || [])],
          }
        : response.data;
      return botPaginationResponseCurrentValue;
    } catch (e) {
      return botPaginationResponseCurrentValue;
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru',
    maxSize: BOT_LIST_CACHE_SIZE,
  },
});

export const botListSelector = selector<ListBotModelPaginationResponse>({
  key: 'botListSelector',
  get: ({ get }) => get(botListSelectorAsync),
});

export const currentActivityIdSelector = selectorFamily<string, string>({
  key: 'currentActivityIdSelector',
  get: (chatId) => ({ get }) => get(currentActivityIdState(chatId)),
  set: (chatId) => ({ set }, newValue) => set(currentActivityIdState(chatId), newValue),
});

export const channelListFilterSelector = selector<InboxChannelFilterParams>({
  key: 'channelListFilterSelector',
  get: ({ get }) => get(channelListFilterState),
  set: ({ set }, newValue) => set(channelListFilterState, newValue),
});

// NOTE: для хранения текущих данных во время загрузки с сервера
let channelPaginationResponseCurrentValue = CHANNEL_PAGINATION_RESPONSE_DEFAULT_VALUE;
export const channelListSelectorAsync = selector<InboxChannelModelPaginationResponse>({
  key: 'channelListSelectorAsync',
  get: async ({ get }) => {
    const filter = get(channelListFilterSelector);

    try {
      const response = await inboxChannelApi.searchInboxChannels(
        filter.search,
        filter.status,
        filter.channelId,
        filter.operatorGroupId,
        filter.pageIndex,
        PageSizes.storageChannels
      );
      channelPaginationResponseCurrentValue = filter.loadMore
        ? {
            ...response.data,
            items: [...(channelPaginationResponseCurrentValue.items || []), ...(response.data.items || [])],
          }
        : response.data;
      return channelPaginationResponseCurrentValue;
    } catch (e) {
      return channelPaginationResponseCurrentValue;
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru',
    maxSize: CHANNEL_LIST_CACHE_SIZE,
  },
});

export const channelListSelector = selector<InboxChannelModelPaginationResponse>({
  key: 'channelListSelector',
  get: ({ get }) => get(channelListSelectorAsync),
});

export const botListSearchSelector = selector<string>({
  key: 'botListSearchSelector',
  get: ({ get }) => get(botListSearchState),
  set: ({ set }, newValue) => set(botListSearchState, newValue),
});

export const botListSimpleSelectorAsync = selector<ListBotModel[]>({
  key: 'botListSimpleSelectorAsync',
  get: async ({ get }) => {
    const search = get(botListSearchSelector);

    try {
      const response = await botApi.searchBots(search, BotSortDirection.EntryNameAscending, 0, PageSizes.storageBots);
      return response.data.items || BOT_LIST_DEFAULT_VALUE;
    } catch {
      return BOT_LIST_DEFAULT_VALUE;
    }
  },
  cachePolicy_UNSTABLE: {
    eviction: 'lru',
    maxSize: BOT_LIST_CACHE_SIZE,
  },
});

// NOTE: для хранения текущих данных во время загрузки с сервера
let botListCurrentValue = BOT_LIST_DEFAULT_VALUE;
export const botListSimpleSelector = selector<ListBotModel[]>({
  key: 'botListSimpleSelector',
  get: ({ get }) => {
    const botListLoadable = get(noWait(botListSimpleSelectorAsync));
    if (botListLoadable.state === RECOIL_LOADABLE_STATES.hasValue) {
      botListCurrentValue = botListLoadable.contents;
    }
    return botListCurrentValue;
  },
});

export const tagListSearchSelector = selector<unknown>({
  key: 'tagListSearchSelector',
  get: ({ get }) => get(tagListSearchState),
  set: ({ set }, newValue) => set(tagListSearchState, newValue),
});

export const tagListSelectorAsync = selector<TagModel[]>({
  key: 'tagListSelectorAsync',
  get: async ({ get }) => {
    get(tagListSearchSelector);

    try {
      const response = await tagApi.getAllTags();
      return response.data || TAG_LIST_DEFAULT_VALUE;
    } catch {
      return TAG_LIST_DEFAULT_VALUE;
    }
  },
});

// NOTE: для хранения текущих данных во время загрузки с сервера
let tagListCurrentValue = TAG_LIST_DEFAULT_VALUE;
export const tagListSelector = selector<TagModel[]>({
  key: 'tagListSelector',
  get: ({ get }) => {
    const tagListLoadable = get(noWait(tagListSelectorAsync));
    if (tagListLoadable.state === RECOIL_LOADABLE_STATES.hasValue) {
      tagListCurrentValue = tagListLoadable.contents;
    }
    return tagListCurrentValue;
  },
});

export const previewModalParamsSelector = selector<IPreviewModalParams>({
  key: 'previewModalParamsSelector',
  get: ({ get }) => get(previewModalParamsState),
  set: ({ set }, newValue) => set(previewModalParamsState, newValue),
});

export const operatorGroupListFilterSelector = selector<unknown>({
  key: 'operatorGroupListFilterSelector',
  get: ({ get }) => get(operatorGroupListFilterState),
  set: ({ set }, newValue) => set(operatorGroupListFilterState, newValue),
});

export const operatorGroupListSelectorAsync = selector<InboxOperatorGroupModel[]>({
  key: 'operatorGroupListSelectorAsync',
  get: async ({ get }) => {
    get(operatorGroupListFilterSelector);

    try {
      const response = await inboxOperatorGroupApi.getAllInboxOperatorGroups();
      return response.data || OPERATOR_GROUP_LIST_DEFAULT_VALUE;
    } catch {
      return OPERATOR_GROUP_LIST_DEFAULT_VALUE;
    }
  },
});

// NOTE: для хранения текущих данных во время загрузки с сервера
let operatorGroupListCurrentValue = OPERATOR_GROUP_LIST_DEFAULT_VALUE;
export const operatorGroupListSelector = selector<InboxOperatorGroupModel[]>({
  key: 'operatorGroupListSelector',
  get: ({ get }) => {
    const operatorGroupListLoadable = get(noWait(operatorGroupListSelectorAsync));
    if (operatorGroupListLoadable.state === RECOIL_LOADABLE_STATES.hasValue) {
      operatorGroupListCurrentValue = operatorGroupListLoadable.contents;
    }
    return operatorGroupListCurrentValue;
  },
});
