import React, { memo } from 'react';

import { DefaultActionGroupSchema, TransferToOperatorSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

interface ITransferToOperatorProps {
  index: number;
  action: TransferToOperatorSchema;
  group: DefaultActionGroupSchema;
}

const TransferToOperator: React.FC<ITransferToOperatorProps> = ({ action, index, group }) => {
  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="avatar" size={20} />}
      index={index}
      title="Перевод на оператора"
    />
  );
};

export default memo(TransferToOperator);
