import { IInputConnectionPosition, IOutputConnectionPosition, IPosition } from '../types';
import { ActionGroupSchema, EntitySchema } from '../../../../api';
import { bindingOffsetX } from '../constants';

import { instanceOfButtonSchema, instanceOfDefaultActionGroupSchema } from './index';

export const getBindingArrowId = (bindingId: string): string => `binding-arrow-${bindingId}`;

export const getOutputOffsetX = (sourceEntity: EntitySchema): number =>
  instanceOfButtonSchema(sourceEntity)
    ? bindingOffsetX.output.button
    : instanceOfDefaultActionGroupSchema(sourceEntity as ActionGroupSchema)
    ? bindingOffsetX.output.group
    : bindingOffsetX.output.trigger;

export const isAlternateTarget = (sourcePositionX: number, targetPositionX: number, outputOffsetX: number): boolean =>
  targetPositionX - bindingOffsetX.input.default < sourcePositionX + outputOffsetX;

const getBindingPathDefinition = (
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  isAltTarget: boolean,
  outputOffsetX: number
): string => {
  if (isAltTarget) {
    const middleX = Math.max(startX, endX) + bindingOffsetX.input.alternate;
    const m = `M ${startX} ${startY}`;
    const c = `C ${middleX} ${startY} ${middleX} ${endY} ${endX} ${endY}`;
    return `${m} ${c}`;
  }

  // NOTE: смещаем опорные точки начала и конца
  const startOffsetX = startX + outputOffsetX;
  const endOffsetX = endX - bindingOffsetX.input.default;
  // NOTE: находим середину междуопорными точками
  const middleX = (startOffsetX + endOffsetX) / 2;
  const middleY = (endY + startY) / 2;
  // NOTE: вычисляем расстояние между опорными точками
  const dX = Math.abs(endOffsetX - startOffsetX);
  // NOTE: вычисляем смещение для средних опорных точек от середины
  const middleOffsetX = dX / 4;
  // NOTE: не даем выйти средним опорным точкам за пределы опорных точек начала и конца
  const firstMiddleTurnX = Math.max(startOffsetX, middleX - middleOffsetX);
  const secondMiddleTurnX = Math.min(endOffsetX, middleX + middleOffsetX);

  const m = `M ${startX} ${startY}`;
  const c1 = `C ${startOffsetX} ${startY} ${firstMiddleTurnX} ${startY} ${middleX} ${middleY}`;
  const c2 = `C ${secondMiddleTurnX} ${endY} ${endOffsetX} ${endY} ${endX} ${endY}`;

  return `${m} ${c1} ${c2}`;
};

export const getBindingElementAttributes = (
  sourcePosition: IOutputConnectionPosition,
  targetPosition: IInputConnectionPosition,
  outputOffsetX: number
): {
  svgTransform: string;
  pathDefinition: string;
  bindingStartPosition: IPosition;
} => {
  const sourcePositionX = sourcePosition?.positionX || 0;
  const sourcePositionY = sourcePosition?.positionY || 0;
  const targetPositionX = targetPosition?.positionX || 0;
  const targetPositionY = targetPosition?.positionY || 0;
  const targetAltPositionX = targetPosition?.altPositionX || 0;
  const targetAltPositionY = targetPosition?.altPositionY || 0;

  const isAltTarget = isAlternateTarget(sourcePositionX, targetPositionX, outputOffsetX);

  const absoluteStartX = sourcePositionX;
  const absoluteStartY = sourcePositionY;
  const absoluteEndX = isAltTarget ? targetAltPositionX : targetPositionX;
  const absoluteEndY = isAltTarget ? targetAltPositionY : targetPositionY;

  const bindingContainerPosition: IPosition = {
    positionX: Math.min(absoluteStartX, absoluteEndX),
    positionY: Math.min(absoluteStartY, absoluteEndY),
  };

  const startX = absoluteStartX - bindingContainerPosition.positionX;
  const startY = absoluteStartY - bindingContainerPosition.positionY;
  const endX = absoluteEndX - bindingContainerPosition.positionX;
  const endY = absoluteEndY - bindingContainerPosition.positionY;

  const svgTransform = `translate(${bindingContainerPosition.positionX}px, ${bindingContainerPosition.positionY}px)`;
  const pathDefinition = getBindingPathDefinition(startX, startY, endX, endY, isAltTarget, outputOffsetX);

  return {
    svgTransform,
    pathDefinition,
    bindingStartPosition: {
      positionX: startX,
      positionY: startY,
    },
  };
};
