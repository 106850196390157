import { VirtualItem } from '@tanstack/react-virtual';

import {
  EventBus,
  EventType,
  IDomainEvent,
  IEventBus,
  INetworkConnectionFailure,
  INetworkConnectionSuccess,
} from '../events';
import {
  ITaskData,
  ITaskEvent,
  ITaskOperation,
  ITaskQueue,
  ITaskSuccessEvent,
  OperationStatus,
  SimpleOperation,
  TaskQueue,
} from '../tasks';
import { getProfile, IUserProfile } from '../../utils/oidcUtil';

import {
  DeletionMode,
  IDeletionOptions,
  IEntityData,
  IEntityFilter,
  IEntityItem,
  IEntityPage,
  IEntityPaging,
  IEntityRange,
  IEntityResponse,
  IEntityScroll,
  IEntityStats,
  ICreationOptions,
  IStorageItemEvent,
  IStorageOptions,
  IStorageStateEvent,
  IStorageStateItemEvent,
  ITenantEntity,
  CreationMode,
  CreationState,
  StatsStatus,
  StorageDirection,
} from './types';
import { getDatabase } from './database';
import { DbTable, IDbTable } from './table';
import { IDbCollection } from './collection';

export enum EntityKey {
  Me = '_me',
}

export interface IEntityStorage<TEntity, TFilter extends IEntityFilter<TEntity, TFilter>> {
  readonly name: string;
  isMe(key: string): boolean | undefined;
  getKey(entity: TEntity): string;
  getItem(key: string): IEntityItem<TEntity> | undefined;
  getPage(filter: TFilter, index: number): IEntityPage<TEntity> | undefined;
  getItems(filter: TFilter): IEntityItem<TEntity>[];
  getPages(filter: TFilter): IEntityPage<TEntity>[];
  getStats(filter: TFilter): IEntityStats;
  getRange(filter: TFilter): IEntityRange;
  setRange(filter: TFilter, scroll: IEntityScroll): IEntityRange;
  findItem(key: string): Promise<IEntityItem<TEntity>>;
  findPage(filter: TFilter, index: number): Promise<IEntityPage<TEntity>>;
  sendItem(entity: TEntity, options: ICreationOptions, virtual?: VirtualItem): Promise<IEntityItem<TEntity>>;
  dropItem(
    entity: TEntity,
    operation: ITaskOperation,
    options: IDeletionOptions,
    virtual?: VirtualItem
  ): Promise<IEntityItem<TEntity>>;
  requestRange(filter: TFilter): Promise<IEntityPage<TEntity>[]>;
  refreshRange(filter: TFilter): Promise<void>;
}

export abstract class EntityStorage<TEntity, TFilter extends IEntityFilter<TEntity, TFilter>>
  implements IEntityStorage<TEntity, TFilter> {
  // FIXME: Поставить 1.0.0 при релизе.
  public static readonly version = '0.0.9';

  public static readonly unknownOperation: ITaskOperation = new SimpleOperation(OperationStatus.Unknown, {
    current: 0,
    maximum: 0,
  });

  public static readonly pendingOperation: ITaskOperation = new SimpleOperation(OperationStatus.Pending, {
    current: 0,
    maximum: 1,
  });

  public static readonly processOperation: ITaskOperation = new SimpleOperation(OperationStatus.Process, {
    current: 0,
    maximum: 1,
  });

  public static readonly successOperation: ITaskOperation = new SimpleOperation(OperationStatus.Success, {
    current: 1,
    maximum: 1,
  });

  public static readonly failureOperation: ITaskOperation = new SimpleOperation(OperationStatus.Failure, {
    current: 1,
    maximum: 1,
  });

  private static readonly s_operationEvents: { [key: string]: ITaskOperation } = {
    [EventType.QueueTaskPending]: EntityStorage.pendingOperation,
    [EventType.QueueTaskProcess]: EntityStorage.processOperation,
    [EventType.QueueTaskSuccess]: EntityStorage.successOperation,
    [EventType.QueueTaskFailure]: EntityStorage.failureOperation,
    [EventType.QueueTaskProgress]: EntityStorage.processOperation,
  };

  private _meKey?: string;

  private readonly _eventBus: IEventBus;
  private readonly _taskQueue: ITaskQueue;

  private readonly _filterMap: Map<string, TFilter>;

  private readonly _dataMap: Map<string, IEntityData<TEntity>>;
  private readonly _itemMap: Map<string, IEntityItem<TEntity>>;

  private readonly _itemsMap: Map<string, IEntityItem<TEntity>[]>;
  private readonly _pagesMap: Map<string, IEntityPage<TEntity>[]>;

  private readonly _dbTable: IDbTable<IEntityData<TEntity>, string>;

  private readonly _statsMap: Map<string, IEntityStats>;
  private readonly _statsPrefix: string;

  private readonly _rangeMap: Map<string, IEntityRange>;
  private readonly _rangePrefix: string;

  protected readonly userProfile: IUserProfile;

  public readonly name: string;
  public readonly options: IStorageOptions;

  protected constructor(
    name: string,
    options: IStorageOptions,
    userProfile?: IUserProfile,
    eventBus?: IEventBus,
    taskQueue?: ITaskQueue,
    dbTable?: IDbTable<IEntityData<TEntity>, string>
  ) {
    this.userProfile = userProfile || getProfile();

    this._eventBus = eventBus || EventBus.instance;
    this._eventBus.onSync(EventType.NetworkConnectionOn, () => this.onConnectionOn());
    this._eventBus.onAsync<ITaskEvent<unknown>>(EventType.QueueTask, (event, type) => this.onQueueTask(event, type));

    this._taskQueue = taskQueue || TaskQueue.instance;

    this._filterMap = new Map();

    this._dataMap = new Map();
    this._itemMap = new Map();

    this._itemsMap = new Map();
    this._pagesMap = new Map();

    if (dbTable) {
      this._dbTable = dbTable;
    } else {
      const database = getDatabase();
      const table = database.table<IEntityData<TEntity>, string>(name);
      this._dbTable = new DbTable<IEntityData<TEntity>, string>(table);
    }

    const database = this._dbTable.database;

    this._statsMap = new Map();
    this._statsPrefix = `${database.name}:${name}.stats`;
    if (this.withTenant()) {
      this._statsPrefix = `${this._statsPrefix}.${this.userProfile.tenantId}`;
    }
    this._statsPrefix = `${this._statsPrefix}:${JSON.stringify(options)}`;

    this._rangeMap = new Map();
    this._rangePrefix = `${database.name}:${name}.range`;
    if (this.withTenant()) {
      this._rangePrefix = `${this._rangePrefix}.${this.userProfile.tenantId}`;
    }
    this._rangePrefix = `${this._rangePrefix}:${JSON.stringify(options)}`;

    this.name = name;
    this.options = options;

    this.linkEvents().finally();
  }

  public isMe(key: string): boolean | undefined {
    if (!this._meKey) {
      return undefined;
    }

    return key === this._meKey;
  }

  public abstract getKey(entity: TEntity): string;

  public getItem(key: string): IEntityItem<TEntity> | undefined {
    if (key === EntityKey.Me) {
      if (this._meKey) {
        key = this._meKey;
      } else {
        return undefined;
      }
    }

    let item = this.getCacheItem(key);
    if (item) {
      return item;
    }

    const data = this.getCacheData(key);
    if (data) {
      item = this.buildItem(data);
    }

    return item;
  }

  public getPage(filter: TFilter, index: number): IEntityPage<TEntity> | undefined {
    const id = this.setFilter(filter);

    const pages = this._pagesMap.get(id);
    const page = pages?.[index];

    if (!page) {
      return page;
    }

    const items = this._itemsMap.get(id);
    const offset = index * this.options.pageSize;

    return {
      ...page,
      items: items?.slice(offset, offset + this.options.pageSize) || [],
    };
  }

  private setPage(filter: TFilter, page: IEntityPage<TEntity>): void {
    const id = this.setFilter(filter);

    const oldPages = this._pagesMap.get(id);
    const newPages = oldPages ? [...oldPages] : [];
    newPages[page.index] = { ...page, items: [] };
    this._pagesMap.set(id, newPages);

    if (!page.items.length) {
      return;
    }

    const keys = new Set<string>();

    for (const item of page.items) {
      const key = this.getKey(item.entity);
      keys.add(key);
    }

    const oldItems = this._itemsMap.get(id);
    const newItems: IEntityItem<TEntity>[] = [];

    const offset = page.index * this.options.pageSize;
    const length = Math.max(oldItems?.length || 0, offset + page.items.length);

    for (let index = 0; index < length; index++) {
      const shift = index - offset;
      if (shift >= 0 && shift < page.items.length) {
        newItems[index] = page.items[shift];
        continue;
      }

      const item = oldItems?.[index];
      if (!item) {
        continue;
      }

      const key = this.getKey(item.entity);
      if (keys.has(key)) {
        continue;
      }

      newItems[index] = item;
    }

    this._itemsMap.set(id, newItems);
  }

  public getItems(filter: TFilter): IEntityItem<TEntity>[] {
    const id = this.setFilter(filter);
    let items = this._itemsMap.get(id);

    if (items) {
      return items;
    }

    items = [];
    this._itemsMap.set(id, items);

    return items;
  }

  public getPages(filter: TFilter): IEntityPage<TEntity>[] {
    const id = this.setFilter(filter);
    let pages = this._pagesMap.get(id);

    if (pages) {
      return pages;
    }

    pages = [];
    this._pagesMap.set(id, pages);

    return pages;
  }

  public getStats(filter: TFilter): IEntityStats {
    const id = this.setFilter(filter);
    let stats = this._statsMap.get(id);

    if (stats?.version === EntityStorage.version) {
      return stats;
    }

    const key = `${this._statsPrefix}:${id}`;
    let data = localStorage.getItem(key);

    if (data) {
      stats = JSON.parse(data) as IEntityStats;

      if (stats.version === EntityStorage.version) {
        this._statsMap.set(id, stats);

        return stats;
      }
    }

    stats = { version: EntityStorage.version, status: StatsStatus.Unknown, itemCount: 0 };
    this._statsMap.set(id, stats);

    data = JSON.stringify(stats);
    localStorage.setItem(key, data);

    return stats;
  }

  private setStats(filter: TFilter, stats: IEntityStats): void {
    const id = this.setFilter(filter);
    this._statsMap.set(id, stats);

    const key = `${this._statsPrefix}:${id}`;
    const data = JSON.stringify(stats);
    localStorage.setItem(key, data);
  }

  public getRange(filter: TFilter): IEntityRange {
    const id = this.setFilter(filter);
    let range = this._rangeMap.get(id);

    if (range?.version === EntityStorage.version) {
      return range;
    }

    const key = `${this._rangePrefix}:${id}`;
    let data = localStorage.getItem(key);

    if (data) {
      range = JSON.parse(data) as IEntityRange;

      if (range.version === EntityStorage.version) {
        this._rangeMap.set(key, range);

        return range;
      }
    }

    range = {
      version: EntityStorage.version,
      virtualItems: [],
      scrollOffset: 0,
      totalSize: 0,
      itemIndex: 0,
      itemCount: 0,
      pageIndex: 0,
      pageCount: 1,
    };
    this._rangeMap.set(key, range);

    data = JSON.stringify(range);
    localStorage.setItem(key, data);

    return range;
  }

  public setRange(filter: TFilter, scroll: IEntityScroll): IEntityRange {
    const id = this.setFilter(filter);
    const itemIndex = scroll.virtualItems[0]?.index ?? 0;
    const itemCount = scroll.virtualItems.length;
    const pageIndex = Math.floor(itemIndex / this.options.pageSize);
    const pageCount = Math.ceil((itemIndex + itemCount) / this.options.pageSize) - pageIndex;

    const range = {
      version: EntityStorage.version,
      ...scroll,
      pageIndex,
      pageCount: Math.max(pageCount, 1),
      itemIndex,
      itemCount,
    };
    this._rangeMap.set(id, range);

    const key = `${this._rangePrefix}:${id}`;
    const data = JSON.stringify(range);
    localStorage.setItem(key, data);

    return range;
  }

  public async findItem(key: string): Promise<IEntityItem<TEntity>> {
    if (key === EntityKey.Me && this._meKey) {
      key = this._meKey;
    }

    let item = key !== EntityKey.Me ? this.getCacheItem(key) : undefined;
    if (item) {
      return item;
    }

    let entity: TEntity;
    try {
      entity = key === EntityKey.Me ? await this.loadMe() : await this.loadItem(key);
    } catch (e) {
      const offEvent: INetworkConnectionFailure = { error: e };
      this._eventBus.emit(EventType.NetworkConnectionFailure, offEvent);

      let data = await this.getTableData(key);
      if (
        data &&
        this.withTenant() &&
        ((data.entity as unknown) as ITenantEntity).tenantId !== this.userProfile.tenantId
      ) {
        data = undefined;
      }

      if (data) {
        if (key === EntityKey.Me) {
          this._meKey = this.getKey(data.entity);
        }

        item = this.buildItem(data);
        this.setCacheData(data);
        this.setCacheItem(item);
      } else {
        data = this.buildData(0, {} as TEntity);
        item = this.buildItem(data);
      }

      return item;
    }

    if (key === EntityKey.Me) {
      this._meKey = this.getKey(entity);
    }

    const onEvent: INetworkConnectionSuccess = { result: entity };
    this._eventBus.emit(EventType.NetworkConnectionSuccess, onEvent);

    const data = this.buildData(0, entity);
    item = this.buildItem(data);

    this.setCacheData(data);
    this.setCacheItem(item);
    await this.setTableData(data);

    return item;
  }

  public async findPage(filter: TFilter, index: number): Promise<IEntityPage<TEntity>> {
    let page = this.getPage(filter, index);
    if (page) {
      return page;
    }

    let response: IEntityResponse<TEntity> | undefined = undefined;
    try {
      const paging = { pageIndex: index, pageSize: this.options.pageSize };
      response = await this.loadPage(filter, paging);
    } catch (e) {
      const offEvent: INetworkConnectionFailure = { error: e };
      this._eventBus.emit(EventType.NetworkConnectionFailure, offEvent);
    }

    const offset = index * this.options.pageSize;
    const keys = new Set<string>();
    page = { index, items: [] };

    if (response) {
      const onEvent: INetworkConnectionSuccess = { result: response };
      this._eventBus.emit(EventType.NetworkConnectionSuccess, onEvent);

      const list: IEntityData<TEntity>[] = [];

      for (const entity of response.items) {
        const key = this.getKey(entity);
        const data = this.buildData(0, entity);
        const item = this.buildItem(data);

        keys.add(key);
        list.push(data);
        page.items.push(item);

        this.setCacheData(data);
        this.setCacheItem(item);
      }

      await this.putTableData(list);
    }

    const items = this.getItems(filter);

    if (items.length) {
      for (let shift = 0; shift <= this.options.pageSize; shift++) {
        const item = items[offset + shift];
        if (!item) {
          continue;
        }

        const key = this.getKey(item.entity);
        if (keys.has(key)) {
          continue;
        }

        keys.add(key);
        page.items.push(item);
      }
    }

    let query = this.applyOrder(this._dbTable);
    query = this.options.direction === StorageDirection.Backward ? query.reverse() : query;
    query = this.applyFilter(query, filter);

    if (this.withTenant()) {
      const tenantId = this.userProfile.tenantId;
      query = query.filter((item) => ((item.entity as unknown) as ITenantEntity).tenantId === tenantId);
    }

    const block = await query
      .offset(offset)
      .limit(this.options.pageSize + 1)
      .toArray();

    for (const data of block) {
      const key = this.getKey(data.entity);
      if (keys.has(key)) {
        continue;
      }

      const item = this.buildItem(data);

      keys.add(key);
      page.items.push(item);

      this.setCacheData(data);
      this.setCacheItem(item);
    }

    if (!index || page.items.length) {
      const oldStats = this.getStats(filter);
      const newStats = {
        ...oldStats,
        status: StatsStatus.Estimated,
        itemCount:
          page.items.length > this.options.pageSize || response?.hasMore
            ? offset + this.options.pageSize + 1
            : offset + page.items.length,
      };
      if (newStats.status !== oldStats.status || newStats.itemCount > oldStats.itemCount) {
        this.setStats(filter, newStats);
      }
    }

    if (page.items.length) {
      page.items.sort((x, y) => this.compareItem(x, y));
      if (page.items.length > this.options.pageSize) {
        page.items.splice(this.options.pageSize, page.items.length - this.options.pageSize);
      }
    }

    this.setPage(filter, page);

    return page;
  }

  public async sendItem(
    entity: TEntity,
    options: ICreationOptions,
    virtual?: VirtualItem
  ): Promise<IEntityItem<TEntity>> {
    if (options.state === CreationState.Initial && this.withTenant()) {
      ((entity as unknown) as ITenantEntity).tenantId = this.userProfile.tenantId;
    }

    const data = this.buildData(1, entity, EntityStorage.pendingOperation);
    const item =
      options.state === CreationState.Initial ? await this.createItem(data) : await this.updateItem(data, virtual);

    if (options.mode === CreationMode.None) {
      return item;
    }

    const task = this.buildCreationTask(entity);
    if (options.mode === CreationMode.Sync) {
      const taskResult = await this._taskQueue.run(task);
      const resultEntity = this.extractEntity(taskResult);

      if (resultEntity) {
        const resultData = this.buildData(0, resultEntity, EntityStorage.successOperation);
        return this.buildItem(resultData);
      }

      const key = this.getKey(entity);
      return this.getItem(key) || item;
    } else {
      this._taskQueue.push(task);
      return item;
    }
  }

  public async dropItem(
    entity: TEntity,
    operation: ITaskOperation,
    options: IDeletionOptions,
    virtual?: VirtualItem
  ): Promise<IEntityItem<TEntity>> {
    const data = this.buildData(1, entity, operation);
    const item = await this.deleteItem(data, virtual);

    if (options.mode === DeletionMode.None) {
      return item;
    }

    const task = this.buildDeletionTask(entity);
    if (options.mode === DeletionMode.Sync) {
      await this._taskQueue.run(task);
    } else {
      this._taskQueue.push(task);
    }

    return item;
  }

  public async requestRange(filter: TFilter): Promise<IEntityPage<TEntity>[]> {
    const pages: IEntityPage<TEntity>[] = [];
    const range = this.getRange(filter);

    for (let shift = 0; shift < range.pageCount; shift++) {
      const index = range.pageIndex + shift;
      const page = await this.findPage(filter, index);
      pages.push(page);
    }

    return pages;
  }

  public async refreshRange(filter: TFilter): Promise<void> {
    await this.requestRange(filter);

    const statsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStateStatsUpdated, statsEvent);

    const pagesEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStatePagesUpdated, pagesEvent);

    const itemsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStateItemsUpdated, itemsEvent);
  }

  protected async modifyItem(data: IEntityData<TEntity>, virtual?: VirtualItem): Promise<IEntityItem<TEntity>> {
    const item = this.buildItem(data);

    const current = this.replaceCacheData(data);
    this.setCacheItem(item);

    const oldFilters = current ? this.getFilters(current.entity) : [];
    const newFilters = this.getFilters(data.entity);

    const deletionFilters = oldFilters.filter((filter) => !newFilters.some((f) => f.equals(filter)));
    for (const filter of deletionFilters) {
      this.deleteStateItem(filter, item, virtual);
    }

    const updatingFilters = newFilters.filter((filter) => oldFilters.some((f) => f.equals(filter)));
    for (const filter of updatingFilters) {
      this.updateStateItem(filter, item, virtual);
    }

    const creationFilters = newFilters.filter((filter) => !oldFilters.some((f) => f.equals(filter)));
    for (const filter of creationFilters) {
      this.createStateItem(filter, item);
    }

    await this.setTableData(data);
    this.tableItemUpdated(item);

    return item;
  }

  protected async createItem(data: IEntityData<TEntity>): Promise<IEntityItem<TEntity>> {
    const item = this.buildItem(data);

    this.setCacheData(data);
    this.setCacheItem(item);

    const filters = this.getFilters(data.entity);
    for (const filter of filters) {
      this.createStateItem(filter, item);
    }

    await this.setTableData(data);
    this.tableItemCreated(item);

    return item;
  }

  protected async updateItem(data: IEntityData<TEntity>, virtual?: VirtualItem): Promise<IEntityItem<TEntity>> {
    const item = this.buildItem(data);

    this.setCacheData(data);
    this.setCacheItem(item);

    const filters = this.getFilters(data.entity);
    for (const filter of filters) {
      this.updateStateItem(filter, item, virtual);
    }

    await this.setTableData(data);
    this.tableItemUpdated(item);

    return item;
  }

  protected async deleteItem(data: IEntityData<TEntity>, virtual?: VirtualItem): Promise<IEntityItem<TEntity>> {
    const item = this.buildItem(data);

    this.removeCacheData(data);
    this.removeCacheItem(item);

    const filters = this.getFilters(data.entity);
    for (const filter of filters) {
      this.deleteStateItem(filter, item, virtual);
    }

    await this.removeTableData(data);
    this.tableItemDeleted(item);

    return item;
  }

  protected abstract linkEvents(): Promise<void>;

  protected abstract loadMe(): Promise<TEntity>;

  protected abstract loadItem(key: string): Promise<TEntity>;

  protected abstract loadPage(filter: TFilter, paging: IEntityPaging): Promise<IEntityResponse<TEntity>>;

  protected abstract applyOrder(
    table: IDbTable<IEntityData<TEntity>, string>
  ): IDbCollection<IEntityData<TEntity>, string>;

  private applyFilter(
    collection: IDbCollection<IEntityData<TEntity>, string>,
    filter: TFilter
  ): IDbCollection<IEntityData<TEntity>, string> {
    return collection.filter((data) => filter.satisfies(data.entity));
  }

  protected buildData(order: number, entity: TEntity, operation?: ITaskOperation): IEntityData<TEntity> {
    return operation ? { order, entity, operation } : { order, entity };
  }

  private buildItem(data: IEntityData<TEntity>): IEntityItem<TEntity> {
    return { ...data, operation: data.operation || EntityStorage.unknownOperation };
  }

  protected emitDomainEvents(entity: TEntity, serverTypes: string[], typeMap: { [key: string]: EventType }): void {
    for (const serverType of serverTypes) {
      const clientType = typeMap[serverType];
      if (!clientType) {
        continue;
      }

      const domainEvent: IDomainEvent<TEntity> = { entity };
      this._eventBus.emit(clientType, domainEvent);
    }
  }

  protected abstract buildCreationTask(entity: TEntity): ITaskData<unknown>;

  protected abstract buildDeletionTask(entity: TEntity): ITaskData<unknown>;

  protected abstract canProcess(task: ITaskData<unknown>): boolean;

  protected abstract extractEntity(result: unknown): TEntity | undefined;

  protected abstract compareEntity(x: TEntity, y: TEntity): number;

  protected abstract withTenant(): boolean;

  protected locateStateKey(filter: TFilter, key: string, virtual?: VirtualItem): number {
    const id = this.setFilter(filter);
    const items = this._itemsMap.get(id);

    if (!items?.length) {
      return -1;
    }

    if (virtual) {
      const index = virtual.index;
      const candidate = items[index];

      if (candidate && this.getKey(candidate.entity) === key) {
        return index;
      }
    }

    const range = this.getRange(filter);
    for (const virtual of range.virtualItems) {
      const index = virtual.index;
      const candidate = items[index];

      if (candidate && this.getKey(candidate.entity) === key) {
        return index;
      }
    }

    return items.findIndex((i) => i && this.getKey(i.entity) === key);
  }

  private compareItem(x: IEntityItem<TEntity>, y: IEntityItem<TEntity>): number {
    const result = x.order !== y.order ? x.order - y.order : this.compareEntity(x.entity, y.entity);
    return this.options.direction === StorageDirection.Backward ? -result : result;
  }

  private onConnectionOn(): void {
    this._itemMap.clear();
    this._pagesMap.clear();
  }

  private async onQueueTask(event: ITaskEvent<unknown>, type: string): Promise<void> {
    if (!this.canProcess(event.data)) {
      return;
    }

    let data = this.getCacheData(event.data.key);
    if (!data) {
      data = await this.getTableData(event.data.key);
      if (data && !data?.operation) {
        this.setCacheData(data);
      }
    }

    if (!data?.operation) {
      return;
    }

    if (type === EventType.QueueTaskSuccess) {
      const successEvent = event as ITaskSuccessEvent<unknown>;
      const entity = this.extractEntity(successEvent.result);
      if (entity) {
        data.order = 0;
        data.entity = entity;
      }
    }

    data.operation = { ...EntityStorage.s_operationEvents[type], progress: event.progress };
    await this.updateItem(data);
  }

  private createStateItem(filter: TFilter, item: IEntityItem<TEntity>): void {
    const oldStats = this.getStats(filter);
    const newStats = { ...oldStats, status: StatsStatus.Estimated, itemCount: oldStats.itemCount + 1 };
    this.setStats(filter, newStats);

    const statsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStateStatsUpdated, statsEvent);

    const index = -1 - this.locateStateItem(filter, item);
    this.addStateItem(filter, index, item);

    const pagesEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStatePagesUpdated, pagesEvent);

    const itemsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStateItemsUpdated, itemsEvent);

    const itemEvent: IStorageStateItemEvent<TEntity, TFilter> = { name: this.name, filter, item, index };
    this._eventBus.emit(EventType.StorageStateItemCreated, itemEvent);
  }

  private updateStateItem(filter: TFilter, item: IEntityItem<TEntity>, virtual?: VirtualItem): void {
    const key = this.getKey(item.entity);
    const oldIndex = this.locateStateKey(filter, key, virtual);
    let newIndex = this.locateStateItem(filter, item, virtual);

    if (newIndex < 0) {
      newIndex = -1 - newIndex;
      if (oldIndex >= 0 && newIndex > oldIndex) {
        newIndex--;
      }
    }

    if (oldIndex < 0) {
      this.addStateItem(filter, newIndex, item);
    } else if (oldIndex === newIndex) {
      this.setStateItem(filter, oldIndex, item);
    } else {
      this.moveStateItem(filter, oldIndex, newIndex, item);
    }

    const pagesEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStatePagesUpdated, pagesEvent);

    const itemsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
    this._eventBus.emit(EventType.StorageStateItemsUpdated, itemsEvent);

    const itemEvent: IStorageStateItemEvent<TEntity, TFilter> = { name: this.name, filter, item, index: newIndex };
    this._eventBus.emit(EventType.StorageStateItemUpdated, itemEvent);
  }

  private deleteStateItem(filter: TFilter, item: IEntityItem<TEntity>, virtual?: VirtualItem): void {
    const oldStats = this.getStats(filter);
    if (oldStats.itemCount) {
      const newStats = { ...oldStats, status: StatsStatus.Estimated, itemCount: oldStats.itemCount - 1 };
      this.setStats(filter, newStats);

      const statsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
      this._eventBus.emit(EventType.StorageStateStatsUpdated, statsEvent);
    }

    const key = this.getKey(item.entity);
    const index = this.locateStateKey(filter, key, virtual);

    if (index >= 0) {
      this.removeStateItem(filter, index);

      const pagesEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
      this._eventBus.emit(EventType.StorageStatePagesUpdated, pagesEvent);

      const itemsEvent: IStorageStateEvent<TFilter> = { name: this.name, filter };
      this._eventBus.emit(EventType.StorageStateItemsUpdated, itemsEvent);
    }

    const itemEvent: IStorageStateItemEvent<TEntity, TFilter> = { name: this.name, filter, item, index };
    this._eventBus.emit(EventType.StorageStateItemDeleted, itemEvent);
  }

  private tableItemCreated(item: IEntityItem<TEntity>): void {
    const event: IStorageItemEvent<TEntity> = { name: this.name, item };
    this._eventBus.emit(EventType.StorageTableItemCreated, event);
  }

  private tableItemUpdated(item: IEntityItem<TEntity>): void {
    const event: IStorageItemEvent<TEntity> = { name: this.name, item };
    this._eventBus.emit(EventType.StorageTableItemUpdated, event);
  }

  private tableItemDeleted(item: IEntityItem<TEntity>): void {
    const event: IStorageItemEvent<TEntity> = { name: this.name, item };
    this._eventBus.emit(EventType.StorageTableItemDeleted, event);
  }

  private getCacheData(key: string): IEntityData<TEntity> | undefined {
    return this._dataMap.get(key);
  }

  private setCacheData(data: IEntityData<TEntity>): void {
    const key = this.getKey(data.entity);
    this._dataMap.set(key, data);
  }

  private removeCacheData(data: IEntityData<TEntity>): void {
    const key = this.getKey(data.entity);
    this._dataMap.delete(key);
  }

  private replaceCacheData(data: IEntityData<TEntity>): IEntityData<TEntity> | undefined {
    const key = this.getKey(data.entity);
    const current = this._dataMap.get(key);
    this._dataMap.set(key, data);

    return current;
  }

  private getCacheItem(key: string): IEntityItem<TEntity> | undefined {
    return this._itemMap.get(key);
  }

  private setCacheItem(item: IEntityItem<TEntity>): void {
    const key = this.getKey(item.entity);
    this._itemMap.set(key, item);
  }

  private removeCacheItem(item: IEntityItem<TEntity>): void {
    const key = this.getKey(item.entity);
    this._itemMap.delete(key);
  }

  private async getTableData(key: string): Promise<IEntityData<TEntity> | undefined> {
    return await this._dbTable.get(key);
  }

  private async setTableData(data: IEntityData<TEntity>): Promise<void> {
    const key = this.getKey(data.entity);
    await this._dbTable.put(data, key);
  }

  private async putTableData(list: IEntityData<TEntity>[]): Promise<void> {
    if (list.length) {
      await this._dbTable.bulkPut(list);
    }
  }

  private async removeTableData(data: IEntityData<TEntity>): Promise<void> {
    const key = this.getKey(data.entity);
    await this._dbTable.delete(key);
  }

  private locateStateItem(filter: TFilter, item: IEntityItem<TEntity>, virtual?: VirtualItem): number {
    const id = this.setFilter(filter);
    const items = this._itemsMap.get(id);

    if (!items?.length) {
      return -1;
    }

    if (virtual) {
      const index = virtual.index;
      const candidate = items[index];

      if (candidate && !this.compareItem(candidate, item)) {
        return index;
      }
    }

    const range = this.getRange(filter);
    for (const virtual of range.virtualItems) {
      const index = virtual.index;
      const candidate = items[index];

      if (candidate && !this.compareItem(candidate, item)) {
        return index;
      }
    }

    for (let index = 0; index < items.length; index++) {
      const candidate = items[index];
      if (!candidate) {
        continue;
      }

      const difference = this.compareItem(candidate, item);

      if (!difference) {
        return index;
      }

      if (difference < 0) {
        continue;
      }

      return -1 - index;
    }

    return -1 - items.length;
  }

  private addStateItem(filter: TFilter, index: number, item: IEntityItem<TEntity>): void {
    const id = this.setFilter(filter);
    const oldItems = this._itemsMap.get(id);
    const newItems = oldItems ? [...oldItems] : [];
    newItems.splice(index, 0, item);
    this._itemsMap.set(id, newItems);
  }

  private setStateItem(filter: TFilter, index: number, item: IEntityItem<TEntity>): void {
    const id = this.setFilter(filter);
    const oldItems = this._itemsMap.get(id);
    const newItems = oldItems ? [...oldItems] : [];
    newItems[index] = item;
    this._itemsMap.set(id, newItems);
  }

  private moveStateItem(filter: TFilter, oldIndex: number, newIndex: number, item: IEntityItem<TEntity>): void {
    const id = this.setFilter(filter);
    const oldItems = this._itemsMap.get(id);
    const newItems = oldItems ? [...oldItems] : [];

    if (newItems.length) {
      newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, item);
    } else {
      newItems[newIndex] = item;
    }

    this._itemsMap.set(id, newItems);
  }

  private removeStateItem(filter: TFilter, index: number): void {
    const id = this.setFilter(filter);
    const oldItems = this._itemsMap.get(id);

    if (!oldItems?.length) {
      return;
    }

    const newItems = [...oldItems];
    newItems.splice(index, 1);
    this._itemsMap.set(id, newItems);
  }

  private setFilter(filter: TFilter): string {
    const id = filter.toJSON();
    this._filterMap.set(id, filter);
    return id;
  }

  private getFilters(entity: TEntity): TFilter[] {
    const filters = Array.from(this._filterMap.values());
    return filters.filter((f) => f.satisfies(entity));
  }
}
