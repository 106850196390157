import { RcFile } from 'antd/es/upload';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import { InboxAttachmentModel } from '../../../api';
import { inboxAttachmentApi } from '../../apis';
import { ITaskData, ITaskQueue, TaskType } from '../tasks';
import { IAttachmentDeleteTaskContent, IAttachmentUploadTaskContent } from '../tasks/attachments';
import { IEventBus } from '../events';
import { IUserProfile } from '../../utils/oidcUtil';

import { EntityFilter, IEntityData, IEntityPaging, IEntityResponse, StorageDirection } from './types';
import { EntityStorage } from './storage';
import { TableName, IDbTable, PageSizes } from './table';
import { IDbCollection } from './collection';

interface IInboxFileBlobContentModel {
  file: RcFile;
}

export class InboxAttachmentFilter extends EntityFilter<InboxAttachmentModel, InboxAttachmentFilter> {
  public readonly activityId?: string;

  public constructor(activityId?: string) {
    super();
    this.activityId = activityId;
  }

  public equals(filter: InboxAttachmentFilter): boolean {
    return this.activityId === filter.activityId;
  }

  public satisfies(entity: InboxAttachmentModel): boolean {
    return !this.activityId || entity.activity.id === this.activityId;
  }
}

export class InboxAttachmentStorage extends EntityStorage<InboxAttachmentModel, InboxAttachmentFilter> {
  private static s_instance?: InboxAttachmentStorage;

  public constructor(
    userProfile?: IUserProfile,
    eventBus?: IEventBus,
    taskQueue?: ITaskQueue,
    dbTable?: IDbTable<IEntityData<InboxAttachmentModel>, string>
  ) {
    super(
      TableName.StorageAttachments,
      { direction: StorageDirection.Forward, pageSize: PageSizes.storageAttachments },
      userProfile,
      eventBus,
      taskQueue,
      dbTable
    );
  }

  public static get instance(): InboxAttachmentStorage {
    if (!InboxAttachmentStorage.s_instance) {
      InboxAttachmentStorage.s_instance = new InboxAttachmentStorage();
    }

    return InboxAttachmentStorage.s_instance;
  }

  public getKey(entity: InboxAttachmentModel): string {
    return entity.external.id;
  }

  protected async linkEvents(): Promise<void> {
    return Promise.resolve();
  }

  protected loadMe(): Promise<InboxAttachmentModel> {
    throw new Error('Operation is not supported.');
  }

  protected async loadItem(key: string): Promise<InboxAttachmentModel> {
    const response = await inboxAttachmentApi.searchInboxAttachments(key, undefined, 0, 1);
    if (!response.data.items?.length) {
      throw new Error('Attachment has not found.');
    }

    return response.data.items[0];
  }

  protected async loadPage(
    filter: InboxAttachmentFilter,
    paging: IEntityPaging
  ): Promise<IEntityResponse<InboxAttachmentModel>> {
    const response = await inboxAttachmentApi.searchInboxAttachments(
      undefined,
      filter.activityId,
      paging.pageIndex,
      paging.pageSize
    );

    return response.data as IEntityResponse<InboxAttachmentModel>;
  }

  protected applyOrder(
    table: IDbTable<IEntityData<InboxAttachmentModel>, string>
  ): IDbCollection<IEntityData<InboxAttachmentModel>, string> {
    return table.orderBy(['order', 'entity.createdOn']);
  }

  protected buildCreationTask(entity: InboxAttachmentModel): ITaskData<IAttachmentUploadTaskContent> {
    return {
      key: entity.external.id,
      type: TaskType.AttachmentUpload,
      timestamp: moment().toISOString(),
      content: {
        activityId: entity.activity.id,
        fileId: entity.file.id,
        file: (entity.file.content[0] as IInboxFileBlobContentModel).file,
      },
    };
  }

  protected buildDeletionTask(entity: InboxAttachmentModel): ITaskData<IAttachmentDeleteTaskContent> {
    return {
      key: entity.external.id,
      type: TaskType.AttachmentDelete,
      timestamp: moment().toISOString(),
      content: {
        externalId: entity.external.id,
        activityId: entity.activity.id,
      },
    };
  }

  protected canProcess(task: ITaskData<unknown>): boolean {
    return task.type === TaskType.AttachmentUpload;
  }

  protected extractEntity(result: unknown): InboxAttachmentModel | undefined {
    const response = result as AxiosResponse<InboxAttachmentModel>;
    return response.data;
  }

  protected compareEntity(x: InboxAttachmentModel, y: InboxAttachmentModel): number {
    if (x.createdOn < y.createdOn) {
      return -1;
    }

    if (x.createdOn > y.createdOn) {
      return 1;
    }

    return 0;
  }

  protected withTenant(): boolean {
    return true;
  }
}
