import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { SelectValue } from 'antd/lib/select';

import { FileInputMode, InputActionSchema, MessageSchema, SchemaKind } from '../../../../../../../../api';
import MessagesEditor from '../../../MessagesEditor';
import SbSwitch from '../../../../../../../simple-bot/components/common/SbSwitch';
import SbInput from '../../../../../../../simple-bot/components/common/SbInput';
import SbScroll from '../../../../../../../simple-bot/components/common/SbScroll';
import SbSelect from '../../../../../../../simple-bot/components/common/SbSelect';
import { DEFAULT_INPUT_MAX_TURN_COUNT } from '../../../../../constants';
import {
  instanceOfDateTimeInputSchema,
  instanceOfElma365AppElementInputSchema,
  instanceOfElma365UserInputSchema,
  instanceOfFileInputSchema,
} from '../../../../../utils';

interface IInputActionGeneralSettingsTabProps {
  action: InputActionSchema;
  onChange: (action: InputActionSchema) => void;
}

const InputActionGeneralSettingsTab: React.FC<IInputActionGeneralSettingsTabProps> = ({ action, onChange }) => {
  const [required, setRequired] = useState(action.required);
  const [cancellable, setCancellable] = useState(action.cancellable);
  const [unrecognizedPromptMessages, setUnrecognizedPromptMessages] = useState(action.unrecognizedPromptMessages);
  const [defaultValueResponseMessages, setDefaultValueResponseMessages] = useState(action.defaultValueResponseMessages);
  const [customValidationErrorMessages, setCustomValidationErrorMessages] = useState(
    action.customValidationErrorMessages
  );
  const [defaultValue, setDefaultValue] = useState(action.defaultValue ?? '');
  const [maxTurnCount, setMaxTurnCount] = useState(action.maxTurnCount);
  const [canShowHelp, setCanShowHelp] = useState(action.canShowHelp);
  const [helpMessages, setHelpMessages] = useState(action.helpMessages);
  const [canTransferToOperator, setCanTransferToOperator] = useState(action.canTransferToOperator);
  const [alwaysPrompt, setAlwaysPrompt] = useState(action.alwaysPrompt);

  const onRequiredChange = () => {
    const newAction = cloneDeep(action);
    newAction.required = !newAction.required;
    onChange(newAction);
  };

  const onCancellableChange = () => {
    const newAction = cloneDeep(action);
    newAction.cancellable = !newAction.cancellable;
    onChange(newAction);
  };

  const onUnrecognizedPromptMessagesChange = (messages: MessageSchema[]) => {
    const newAction = cloneDeep(action);
    newAction.unrecognizedPromptMessages = messages;
    onChange(newAction);
  };

  const onDefaultValueResponseMessagesChange = (messages: MessageSchema[]) => {
    const newAction = cloneDeep(action);
    newAction.defaultValueResponseMessages = messages;
    onChange(newAction);
  };

  const onCustomValidationErrorMessagesChange = (messages: MessageSchema[]) => {
    const newAction = cloneDeep(action);
    newAction.customValidationErrorMessages = messages;
    onChange(newAction);
  };

  const onDefaultValueChange = (value: string) => {
    setDefaultValue(value);
  };

  const onDefaultValueBlur = () => {
    const newAction = cloneDeep(action);
    newAction.defaultValue = defaultValue;
    onChange(newAction);
  };

  const onFileInputModeChange = (value: SelectValue) => {
    const newAction = cloneDeep(action);
    newAction.fileInputMode = value as FileInputMode;
    onChange(newAction);
  };

  const onMaxTurnCountChange = (value: string) =>
    setMaxTurnCount(value ? Number.parseFloat(value) : DEFAULT_INPUT_MAX_TURN_COUNT);

  const onMaxTurnCountBlur = () => {
    const newAction = cloneDeep(action);
    newAction.maxTurnCount = maxTurnCount;
    onChange(newAction);
  };

  const onCanShowHelpChange = () => {
    const newAction = cloneDeep(action);
    newAction.canShowHelp = !newAction.canShowHelp;
    onChange(newAction);
  };

  const onHelpMessagesChange = (messages: MessageSchema[]) => {
    const newAction = cloneDeep(action);
    newAction.helpMessages = messages;
    onChange(newAction);
  };

  const onCanTransferToOperatorChange = () => {
    const newAction = cloneDeep(action);
    newAction.canTransferToOperator = !newAction.canTransferToOperator;
    onChange(newAction);
  };

  const onAlwaysPromptChange = () => {
    const newAction = cloneDeep(action);
    newAction.alwaysPrompt = !newAction.alwaysPrompt;
    onChange(newAction);
  };

  const onActionChanged = () => {
    setRequired(action.required);
    setCancellable(action.cancellable);
    setUnrecognizedPromptMessages(action.unrecognizedPromptMessages);
    setDefaultValueResponseMessages(action.defaultValueResponseMessages);
    setCustomValidationErrorMessages(action.customValidationErrorMessages);
    setDefaultValue(action.defaultValue ?? '');
    setCanShowHelp(action.canShowHelp);
    setHelpMessages(action.helpMessages);
    setCanTransferToOperator(action.canTransferToOperator);
    setAlwaysPrompt(action.alwaysPrompt);
    setMaxTurnCount(action.maxTurnCount);
  };
  useEffect(onActionChanged, [action]);

  const showAdditionalButtons =
    action.$kind !== SchemaKind.ChoiceTransit && action.$kind !== SchemaKind.SuggestionInput;

  const showDefaultValue =
    !instanceOfDateTimeInputSchema(action) &&
    !instanceOfElma365UserInputSchema(action) &&
    !instanceOfElma365AppElementInputSchema(action) &&
    !instanceOfFileInputSchema(action);

  const showUnrecognizedPromptSettings =
    !instanceOfElma365UserInputSchema(action) && !instanceOfElma365AppElementInputSchema(action);

  const showInvalidPromptSettings =
    !instanceOfElma365UserInputSchema(action) &&
    !instanceOfElma365AppElementInputSchema(action) &&
    !instanceOfFileInputSchema(action);

  return (
    <SbScroll>
      <div>
        {action.$kind === SchemaKind.FileInput && (
          <div className="field">
            <h4>Режим ввода файлов</h4>
            <SbSelect
              sbSize="big"
              sbType="light"
              value={action.fileInputMode ?? FileInputMode.Multiple}
              onChange={onFileInputModeChange}
            >
              <Select.Option key="single" value="single">
                Один файл
              </Select.Option>
              <Select.Option key="multiple" value="multiple">
                Несколько файлов
              </Select.Option>
              <Select.Option key="multipleWithConfirm" value="multipleWithConfirm">
                Несколько файлов с подтверждением загрузки
              </Select.Option>
            </SbSelect>
          </div>
        )}
        <div className="field">
          <SbSwitch checked={required} label="Обязательный ввод" onClick={onRequiredChange} />
        </div>
        <div className="field">
          <SbSwitch
            checked={cancellable}
            label={`Показать кнопку '${required ? 'Отмена' : 'Пропустить'}'`}
            onClick={onCancellableChange}
          />
        </div>
        {showUnrecognizedPromptSettings && (
          <div className="field">
            <h4>Сообщение при нераспознанном вводе</h4>
            <MessagesEditor
              actionId={`${action.id}-unrecognizedPromptMessages`}
              messages={unrecognizedPromptMessages}
              placeholder="Введите свой вариант сообщения при нераспознанном вводе"
              useScenarioEditorZoom={false}
              onMessagesChange={onUnrecognizedPromptMessagesChange}
            />
          </div>
        )}

        {showInvalidPromptSettings && (
          <div className="field">
            <h4>Сообщение при ошибке валидации</h4>
            <MessagesEditor
              actionId={`${action.id}-customValidationErrorMessages`}
              messages={customValidationErrorMessages}
              placeholder="Введите свой вариант сообщения при ошибке валидации"
              useScenarioEditorZoom={false}
              onMessagesChange={onCustomValidationErrorMessagesChange}
            />
          </div>
        )}

        <div className="field">
          <h4>Максимальное количество попыток ввода</h4>
          <SbInput
            allowClear
            placeholder="Введите максимальное количество попыток ввода"
            value={maxTurnCount?.toString() ?? ''}
            onBlur={onMaxTurnCountBlur}
            onChange={onMaxTurnCountChange}
          />
        </div>
        {showDefaultValue && (
          <div className="field">
            <h4>Значение по умолчанию</h4>
            {action.$kind !== SchemaKind.ConfirmTransit && (
              <SbInput
                allowClear
                placeholder="Введите значение по умолчанию"
                value={defaultValue}
                onBlur={onDefaultValueBlur}
                onChange={onDefaultValueChange}
              />
            )}
            {action.$kind === SchemaKind.ConfirmTransit && (
              <SbSelect
                placeholder="Выберите"
                sbSize="big"
                sbType="light"
                value={defaultValue}
                onChange={(value) => onDefaultValueChange(value as string)}
              >
                <Select.Option key="none" value="">
                  (не указано)
                </Select.Option>
                <Select.Option key="true" value="true">
                  Да
                </Select.Option>
                <Select.Option key="false" value="false">
                  Нет
                </Select.Option>
              </SbSelect>
            )}
          </div>
        )}
        {showDefaultValue && (
          <div className="field">
            <h4>Сообщение при применении значения по умолчанию</h4>
            <MessagesEditor
              actionId={`${action.id}-defaultValueResponseMessages`}
              messages={defaultValueResponseMessages}
              placeholder="Введите сообщение при применении значения по умолчанию"
              useScenarioEditorZoom={false}
              onMessagesChange={onDefaultValueResponseMessagesChange}
            />
          </div>
        )}
        {showAdditionalButtons && (
          <>
            <div className="field">
              <SbSwitch checked={canShowHelp} label="Показывать кнопку 'Помощь'" onClick={onCanShowHelpChange} />
            </div>
            {canShowHelp && (
              <div className="field">
                <h4>Сообщение при запросе помощи</h4>
                <MessagesEditor
                  required
                  actionId={`${action.id}-helpMessages`}
                  messages={helpMessages}
                  placeholder="Введите сообщение при запросе помощи"
                  useScenarioEditorZoom={false}
                  onMessagesChange={onHelpMessagesChange}
                />
              </div>
            )}
            <div className="field">
              <SbSwitch
                checked={canTransferToOperator}
                label="Показывать кнопку 'Оператор'"
                onClick={onCanTransferToOperatorChange}
              />
            </div>
          </>
        )}
        <div className="field">
          <h4>Спрашивать</h4>
          <SbSelect sbSize="big" sbType="light" value={alwaysPrompt ? 'true' : 'false'} onChange={onAlwaysPromptChange}>
            <Select.Option key="true" value="true">
              Каждый раз
            </Select.Option>
            <Select.Option key="false" value="false">
              Только когда значение не заполнено
            </Select.Option>
          </SbSelect>
        </div>
      </div>
    </SbScroll>
  );
};

export default InputActionGeneralSettingsTab;
