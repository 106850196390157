import { nanoid } from 'nanoid';

import {
  ChoiceTransitSchema,
  ConfirmTransitSchema,
  DefaultActionGroupSchema,
  DefaultScenarioSchema,
  DefaultTriggerGroupSchema,
  EntitySchema,
  IntentTriggerSchema,
  TextOutputSchema,
  SchemaKind,
  TransitActionSchema,
  TextInputSchema,
  NumberInputSchema,
  DateTimeInputSchema,
  EmailAddressInputSchema,
  PhoneNumberInputSchema,
  LinkInputSchema,
  LocationInputSchema,
  PersonNameInputSchema,
  NumberValueComparisonValidatorSchema,
  DateTimeValueComparisonValidatorSchema,
  DateTimeTypeValidatorSchema,
  CurrentDateTimeComparisonValidatorSchema,
  LengthComparisonValidatorSchema,
  StartTriggerSchema,
  EndScenarioOutputSchema,
  TransitionTriggerSchema,
  UnknownIntentTriggerSchema,
  TerminalTriggerSchema,
  MenuButtonTriggerSchema,
  SystemIntentReferenceSchema,
  UserIntentReferenceSchema,
  ButtonSchema,
  BindingSchema,
  SuggestionInputSchema,
  VariableSchema,
  OutputActionSchema,
  InputActionSchema,
  TransferToOperatorSchema,
  IntentContentSchema,
  MenuActionSchema,
  ScriptActionSchema,
  SwitchActionSchema,
  TriggerSchema,
  MainCaseActionSchema,
  ElseCaseActionSchema,
  CaseActionSchema,
  VariableOperandSchema,
  ConstantOperandSchema,
  UnaryConditionSchema,
  BinaryConditionSchema,
  Elma365UserInputSchema,
  Elma365AppElementInputSchema,
  Elma365EnumInputSchema,
  Elma365IntegrationSettingsSchema,
  StartElma365ProcessActionSchema,
  Elma365FormPropertySchema,
  CreateElma365AppElementActionSchema,
  BotSchema,
  ExternalSignInSchema,
  OAuth2LoginProtocolSchema,
  FileInputSchema,
  SendEmailSchema,
  SendSuccessfullServiceEventSchema,
  CloseConversationSchema,
  SendCustomEventSchema,
  CommandTriggerSchema,
  ExternalEventTriggerSchema,
  MergeInboxContactsSchema,
} from '../../../../api';
import { translitIntentName } from '../../../utils/stringUtil';
import { scenarioEditorContainerId } from '../constants';
import {
  IActionGroupsContainer,
  IActionsContainer,
  IBindingsContainer,
  IButtonsContainer,
  IConditionsContainer,
  IElseCaseContainer,
  IIntentsContainer,
  IMainCasesContainer,
  IMessagesContainer,
  IOutputBindingIdContainer,
  IScenariosContainer,
  ISuggestionsContainer,
  ITriggerGroupContainer,
  ITriggersContainer,
  IValidatorsContainer,
  IVariableIdContainer,
  IVariablesContainer,
} from '../types';

export * from './useEventListener';
export * from './htmlDom';
export * from './binding';
export * from './group';
export * from './action';
export * from './transform';

export enum EntityFieldPath {
  Root = '',
  Scenarios = 'scenarios',
  TriggerGroup = 'triggerGroup',
  ActionGroups = 'actionGroups',
  Intents = 'intents',
  Bindings = 'bindings',
  Variables = 'variables',
  Validators = 'validators',
  Triggers = 'triggers',
  Actions = 'actions',
  Messages = 'messages',
  Buttons = 'buttons',
  Suggestions = 'suggestions',
  Conditions = 'conditions',
  MainCases = 'mainCases',
  ElseCase = 'elseCase',
  VariableId = 'variableId',
  RelatedAppElementVariableId = 'relatedAppElementVariableId',
  OutputBindingId = 'outputBindingId',
  TransitionScenarioId = 'transitionScenarioId',
  Code = 'code',
  Name = 'name',
  Value = 'value',
  Command = 'command',
  ExternalEventId = 'externalEventId',
}

export const instanceOfScenariosContainer = (entity: EntitySchema): entity is IScenariosContainer =>
  EntityFieldPath.Scenarios in entity;

export const instanceOfTriggerGroupContainer = (entity: EntitySchema): entity is ITriggerGroupContainer =>
  EntityFieldPath.TriggerGroup in entity;

export const instanceOfActionGroupsContainer = (entity: EntitySchema): entity is IActionGroupsContainer =>
  EntityFieldPath.ActionGroups in entity;

export const instanceOfIntentsContainer = (entity: EntitySchema): entity is IIntentsContainer =>
  EntityFieldPath.Intents in entity;

export const instanceOfBindingsContainer = (entity: EntitySchema): entity is IBindingsContainer =>
  EntityFieldPath.Bindings in entity;

export const instanceOfVariablesContainer = (entity: EntitySchema): entity is IVariablesContainer =>
  EntityFieldPath.Variables in entity;

export const instanceOfValidatorsContainer = (entity: EntitySchema): entity is IValidatorsContainer =>
  EntityFieldPath.Validators in entity;

export const instanceOfTriggersContainer = (entity: EntitySchema): entity is ITriggersContainer =>
  EntityFieldPath.Triggers in entity;

export const instanceOfActionsContainer = (entity: EntitySchema): entity is IActionsContainer =>
  EntityFieldPath.Actions in entity;

export const instanceOfMessagesContainer = (entity: EntitySchema): entity is IMessagesContainer =>
  EntityFieldPath.Messages in entity;

export const instanceOfButtonsContainer = (entity: EntitySchema): entity is IButtonsContainer =>
  EntityFieldPath.Buttons in entity;

export const instanceOfSuggestionsContainer = (entity: EntitySchema): entity is ISuggestionsContainer =>
  EntityFieldPath.Suggestions in entity;

export const instanceOfConditionsContainer = (entity: EntitySchema): entity is IConditionsContainer =>
  EntityFieldPath.Conditions in entity;

export const instanceOfMainCasesContainer = (entity: EntitySchema): entity is IMainCasesContainer =>
  EntityFieldPath.MainCases in entity;

export const instanceOfElseCaseContainer = (entity: EntitySchema): entity is IElseCaseContainer =>
  EntityFieldPath.ElseCase in entity;

export const instanceOfVariableIdContainer = (entity: EntitySchema): entity is IVariableIdContainer =>
  EntityFieldPath.VariableId in entity;

export const instanceOfOutputBindingIdContainer = (entity: EntitySchema): entity is IOutputBindingIdContainer =>
  EntityFieldPath.OutputBindingId in entity;

export const instanceOfBotSchema = (entity: EntitySchema): entity is BotSchema => entity.$kind === SchemaKind.Bot;

export const instanceOfDefaultScenarioSchema = (entity: EntitySchema): entity is DefaultScenarioSchema =>
  entity.$kind === SchemaKind.DefaultScenario;

export const instanceOfDefaultTriggerGroupSchema = (entity: EntitySchema): entity is DefaultTriggerGroupSchema =>
  entity.$kind === SchemaKind.DefaultTriggerGroup;

export const instanceOfDefaultActionGroupSchema = (entity: EntitySchema): entity is DefaultActionGroupSchema =>
  entity.$kind === SchemaKind.DefaultActionGroup;

export const instanceOfIntentTriggerSchema = (entity: EntitySchema): entity is IntentTriggerSchema =>
  entity.$kind === SchemaKind.IntentTrigger;

export const instanceOfMenuButtonTriggerSchema = (entity: EntitySchema): entity is MenuButtonTriggerSchema =>
  entity.$kind === SchemaKind.MenuButtonTrigger;

export const instanceOfStartTriggerSchema = (entity: EntitySchema): entity is StartTriggerSchema =>
  entity.$kind === SchemaKind.StartTrigger;

export const instanceOfTerminalTriggerSchema = (entity: EntitySchema): entity is TerminalTriggerSchema =>
  entity.$kind === SchemaKind.TerminalTrigger;

export const instanceOfTimerTriggerSchema = (entity: EntitySchema): entity is MenuButtonTriggerSchema =>
  entity.$kind === SchemaKind.TimerTrigger;

export const instanceOfTransitionTriggerSchema = (entity: EntitySchema): entity is TransitionTriggerSchema =>
  entity.$kind === SchemaKind.TransitionTrigger;

export const instanceOfUnknownIntentTriggerSchema = (entity: EntitySchema): entity is UnknownIntentTriggerSchema =>
  entity.$kind === SchemaKind.UnknownIntentTrigger;

export const instanceOfCommandTriggerSchema = (entity: EntitySchema): entity is CommandTriggerSchema =>
  entity.$kind === SchemaKind.CommandTrigger;

export const instanceOfExternalEventTriggerSchema = (entity: EntitySchema): entity is ExternalEventTriggerSchema =>
  entity.$kind === SchemaKind.ExternalEventTrigger;

export const instanceOfTriggerSchema = (entity: EntitySchema): entity is TriggerSchema =>
  instanceOfIntentTriggerSchema(entity) ||
  instanceOfMenuButtonTriggerSchema(entity) ||
  instanceOfStartTriggerSchema(entity) ||
  instanceOfTerminalTriggerSchema(entity) ||
  instanceOfTimerTriggerSchema(entity) ||
  instanceOfTransitionTriggerSchema(entity) ||
  instanceOfUnknownIntentTriggerSchema(entity) ||
  instanceOfCommandTriggerSchema(entity) ||
  instanceOfExternalEventTriggerSchema(entity);

export const instanceOfTransitSchema = (entity: EntitySchema): entity is TransitActionSchema =>
  Array.isArray(entity.buttons);

export const instanceOfActionSchemaWithMessages = (
  entity: EntitySchema
): entity is OutputActionSchema | InputActionSchema => Array.isArray(entity.messages);

export const instanceOfTextInputSchema = (entity: EntitySchema): entity is TextInputSchema =>
  entity.$kind === SchemaKind.TextInput;

export const instanceOfNumberInputSchema = (entity: EntitySchema): entity is NumberInputSchema =>
  entity.$kind === SchemaKind.NumberInput;

export const instanceOfDateTimeInputSchema = (entity: EntitySchema): entity is DateTimeInputSchema =>
  entity.$kind === SchemaKind.DateTimeInput;

export const instanceOfEmailAddressInputSchema = (entity: EntitySchema): entity is EmailAddressInputSchema =>
  entity.$kind === SchemaKind.EmailAddressInput;

export const instanceOfPhoneNumberInputSchema = (entity: EntitySchema): entity is PhoneNumberInputSchema =>
  entity.$kind === SchemaKind.PhoneNumberInput;

export const instanceOfLinkInputSchema = (entity: EntitySchema): entity is LinkInputSchema =>
  entity.$kind === SchemaKind.LinkInput;

export const instanceOfLocationInputSchema = (entity: EntitySchema): entity is LocationInputSchema =>
  entity.$kind === SchemaKind.LocationInput;

export const instanceOfPersonNameInputSchema = (entity: EntitySchema): entity is PersonNameInputSchema =>
  entity.$kind === SchemaKind.PersonNameInput;

export const instanceOfSuggestionInputSchema = (entity: EntitySchema): entity is SuggestionInputSchema =>
  entity.$kind === SchemaKind.SuggestionInput;

export const instanceOfFileInputSchema = (entity: EntitySchema): entity is FileInputSchema =>
  entity.$kind === SchemaKind.FileInput;

export const instanceOfTextOutputSchema = (entity: EntitySchema): entity is TextOutputSchema =>
  entity.$kind === SchemaKind.TextOutput;

export const instanceOfConfirmTransitSchema = (entity: EntitySchema): entity is ConfirmTransitSchema =>
  entity.$kind === SchemaKind.ConfirmTransit;

export const instanceOfChoiceTransitSchema = (entity: EntitySchema): entity is ChoiceTransitSchema =>
  entity.$kind === SchemaKind.ChoiceTransit;

export const instanceOfEndScenarioOutputSchema = (entity: EntitySchema): entity is EndScenarioOutputSchema =>
  entity.$kind === SchemaKind.EndScenarioOutput;

export const instanceOfSystemIntentReferenceSchema = (entity: EntitySchema): entity is SystemIntentReferenceSchema =>
  entity.$kind === SchemaKind.SystemIntentReference;

export const instanceOfUserIntentReferenceSchema = (entity: EntitySchema): entity is UserIntentReferenceSchema =>
  entity.$kind === SchemaKind.UserIntentReference;

export const instanceOfTransferToOperatorSchema = (entity: EntitySchema): entity is TransferToOperatorSchema =>
  entity.$kind === SchemaKind.TransferToOperator;

export const instanceOfMenuActionSchema = (entity: EntitySchema): entity is MenuActionSchema =>
  entity.$kind === SchemaKind.MenuAction;

export const instanceOfScriptActionSchema = (entity: EntitySchema): entity is ScriptActionSchema =>
  entity.$kind === SchemaKind.ScriptAction;

export const instanceOfSwitchActionSchema = (entity: EntitySchema): entity is SwitchActionSchema =>
  entity.$kind === SchemaKind.SwitchAction;

export const instanceOfMainCaseActionSchema = (entity: EntitySchema): entity is MainCaseActionSchema =>
  entity.$kind === SchemaKind.MainCaseAction;

export const instanceOfElseCaseActionSchema = (entity: EntitySchema): entity is ElseCaseActionSchema =>
  entity.$kind === SchemaKind.ElseCaseAction;

export const instanceOfCaseActionSchema = (entity: EntitySchema): entity is CaseActionSchema =>
  instanceOfMainCaseActionSchema(entity) || instanceOfElseCaseActionSchema(entity);

export const instanceOfExternalSigninSchema = (entity: EntitySchema): entity is ExternalSignInSchema =>
  entity.$kind === SchemaKind.ExternalSignIn;

export const instanceOfOAuth2LoginProtocolSchema = (entity: EntitySchema): entity is OAuth2LoginProtocolSchema =>
  entity.$kind === SchemaKind.OAuth2LoginProtocol;

export const instanceOfStartElma365ProcessActionSchema = (
  entity: EntitySchema
): entity is StartElma365ProcessActionSchema => entity.$kind === SchemaKind.StartElma365Process;

export const instanceOfCreateElma365AppElementActionSchema = (
  entity: EntitySchema
): entity is CreateElma365AppElementActionSchema => entity.$kind === SchemaKind.CreateElma365AppElement;

export const instanceOfElma365UserInputSchema = (entity: EntitySchema): entity is Elma365UserInputSchema =>
  entity.$kind === SchemaKind.Elma365UserInput;

export const instanceOfElma365AppElementInputSchema = (entity: EntitySchema): entity is Elma365AppElementInputSchema =>
  entity.$kind === SchemaKind.Elma365AppElementInput;

export const instanceOfElma365EnumInputSchema = (entity: EntitySchema): entity is Elma365EnumInputSchema =>
  entity.$kind === SchemaKind.Elma365EnumInput;

export const instanceOfNumberValueComparisonValidator = (
  entity: EntitySchema
): entity is NumberValueComparisonValidatorSchema => entity.$kind === SchemaKind.NumberValueComparisonValidator;

export const instanceOfDateTimeValueComparisonValidator = (
  entity: EntitySchema
): entity is DateTimeValueComparisonValidatorSchema => entity.$kind === SchemaKind.DateTimeValueComparisonValidator;

export const instanceOfDateTimeTypeValidator = (entity: EntitySchema): entity is DateTimeTypeValidatorSchema =>
  entity.$kind === SchemaKind.DateTimeTypeValidator;

export const instanceOfCurrentDateTimeComparisonValidator = (
  entity: EntitySchema
): entity is CurrentDateTimeComparisonValidatorSchema => entity.$kind === SchemaKind.CurrentDateTimeComparisonValidator;

export const instanceOfLengthComparisonValidator = (entity: EntitySchema): entity is LengthComparisonValidatorSchema =>
  entity.$kind === SchemaKind.LengthComparisonValidator;

export const instanceOfButtonSchema = (entity: EntitySchema): entity is ButtonSchema =>
  entity.$kind === SchemaKind.Button;

export const instanceOfBindingSchema = (entity: EntitySchema): entity is BindingSchema =>
  entity.$kind === SchemaKind.Binding;

export const instanceOfVariableSchema = (entity: EntitySchema): entity is VariableSchema =>
  entity.$kind === SchemaKind.Variable;

export const instanceOfIntentContentSchema = (entity: EntitySchema): entity is IntentContentSchema =>
  entity.$kind === SchemaKind.IntentContent;

export const instanceOfConstantOperandSchema = (entity: EntitySchema): entity is ConstantOperandSchema =>
  entity.$kind === SchemaKind.ConstantOperand;

export const instanceOfVariableOperandSchema = (entity: EntitySchema): entity is VariableOperandSchema =>
  entity.$kind === SchemaKind.VariableOperand;

export const instanceOfUnaryConditionSchema = (entity: EntitySchema): entity is UnaryConditionSchema =>
  entity.$kind === SchemaKind.UnaryCondition;

export const instanceOfBinaryConditionSchema = (entity: EntitySchema): entity is BinaryConditionSchema =>
  entity.$kind === SchemaKind.BinaryCondition;

export const instanceOfElma365IntegrationSettingsSchema = (
  entity: EntitySchema
): entity is Elma365IntegrationSettingsSchema => entity.$kind === SchemaKind.Elma365IntegrationSettings;

export const instanceOfElma365FormPropertySchema = (entity: EntitySchema): entity is Elma365FormPropertySchema =>
  entity.$kind === SchemaKind.Elma365FormProperty;

export const instanceOfSendEmailSchema = (entity: EntitySchema): entity is SendEmailSchema =>
  entity.$kind === SchemaKind.SendEmail;

export const instanceOfSendSuccessfullServiceEventSchema = (
  entity: EntitySchema
): entity is SendSuccessfullServiceEventSchema => entity.$kind === SchemaKind.SendSuccessfullServiceEvent;

export const instanceOfCloseConversationSchema = (entity: EntitySchema): entity is CloseConversationSchema =>
  entity.$kind === SchemaKind.CloseConversation;

export const instanceOfSendCustomEventSchema = (entity: EntitySchema): entity is SendCustomEventSchema =>
  entity.$kind === SchemaKind.SendCustomEvent;

export const instanceOfMergeInboxContactsSchema = (entity: EntitySchema): entity is MergeInboxContactsSchema =>
  entity.$kind === SchemaKind.MergeInboxContacts;

export const instanceOfInputSchema = (entity: EntitySchema): entity is InputActionSchema =>
  instanceOfTextInputSchema(entity) ||
  instanceOfDateTimeInputSchema(entity) ||
  instanceOfElma365AppElementInputSchema(entity) ||
  instanceOfElma365EnumInputSchema(entity) ||
  instanceOfElma365UserInputSchema(entity) ||
  instanceOfEmailAddressInputSchema(entity) ||
  instanceOfFileInputSchema(entity) ||
  instanceOfLinkInputSchema(entity) ||
  instanceOfLocationInputSchema(entity) ||
  instanceOfNumberInputSchema(entity) ||
  instanceOfPersonNameInputSchema(entity) ||
  instanceOfPhoneNumberInputSchema(entity) ||
  instanceOfSuggestionInputSchema(entity) ||
  instanceOfTransitSchema(entity);

// todo: заменить более универсальным методом
export const tryGetElementById = (scenarioStructure: DefaultScenarioSchema, id: string): EntitySchema | undefined => {
  if (scenarioStructure.id === id) return scenarioStructure;

  if (scenarioStructure.triggerGroup.id === id) return scenarioStructure.triggerGroup;

  for (const trigger of (scenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers) {
    if (trigger.id === id) return trigger;
  }

  for (const group of scenarioStructure.actionGroups) {
    if (group.id === id) return group;

    for (const action of (group as DefaultActionGroupSchema).actions) {
      if (action.id === id) return action;

      if (
        instanceOfChoiceTransitSchema(action) ||
        instanceOfConfirmTransitSchema(action) ||
        instanceOfMenuActionSchema(action)
      ) {
        const button = action.buttons.find((b) => b.id === id);
        if (button) return button;
        const message = action.messages.find((m) => m.id === id);
        if (message) return message;
      }

      if (instanceOfTextOutputSchema(action)) {
        const message = action.messages.find((m) => m.id === id);
        if (message) return message;
      }

      if (instanceOfSuggestionInputSchema(action)) {
        const suggestion = action.suggestions.find((s) => s.id === id);
        if (suggestion) return suggestion;
      }

      if (instanceOfSwitchActionSchema(action)) {
        const mainCase = action.mainCases.find((c) => c.id === id);
        if (mainCase) return mainCase;
        if (action.elseCase.id === id) return action.elseCase;
      }
    }
  }

  const binding = scenarioStructure.bindings.find((b) => b.id === id);
  if (binding) return binding;

  return undefined;
};

export const deleteBindingWithReferences = (
  scenarioStructure: DefaultScenarioSchema,
  bindingId?: string | null
): void => {
  if (!bindingId) return;

  scenarioStructure.bindings = scenarioStructure.bindings.filter((b) => b.id !== bindingId);

  const deleteBindingReferences = (entity: EntitySchema, bindingId: string) => {
    Object.keys(entity).forEach((key) => {
      if (key === 'outputBindingId' && entity[key] === bindingId) {
        entity[key] = null;
      }
      if (key === 'inputBindingIds' && (entity[key] || []).includes(bindingId)) {
        entity[key] = (entity[key] || []).filter((bId: string) => bId !== bindingId);
      }
      if (Array.isArray(entity[key])) {
        for (const entityElement of entity[key]) {
          deleteBindingReferences(entityElement, bindingId);
        }
      }
      if (entity[key] instanceof Object) {
        deleteBindingReferences(entity[key], bindingId);
      }
    });
  };

  deleteBindingReferences(scenarioStructure.triggerGroup, bindingId);
  for (const actionGroup of scenarioStructure.actionGroups) {
    deleteBindingReferences(actionGroup, bindingId);
  }
};

export const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const generateId = (prefix: string): string => `${prefix}_${nanoid(10)}`.replace(/-/g, '_');
export const generateActionId = (): string => generateId('ACT');
export const generateMessageId = (): string => generateId('MSG');
export const generateAttachmentId = (): string => generateId('ATT');
export const generateSuggestionId = (): string => generateId('SGG');
export const generateButtonId = (): string => generateId('BTN');
export const generateProtocolId = (): string => generateId('AUT');
export const generateOperandId = (): string => generateId('OPD');
export const generateMainCaseId = (): string => generateId('MCS');
export const generateElseCaseId = (): string => generateId('ECS');
export const generateConditionId = (): string => generateId('CON');

export const getNewMessageId = (actionId: string): string => `${actionId}-new-message`;

export const getScenarioEditorContainerRect = (): DOMRect | undefined =>
  document.getElementById(scenarioEditorContainerId)?.getBoundingClientRect();

export const generateIntentId = (
  intentName: string,
  scenarioStructures: DefaultScenarioSchema[],
  currentIntentId?: string
): string => {
  const newIntentId = `user_${translitIntentName(intentName)}`;

  if (currentIntentId && currentIntentId.startsWith(newIntentId)) {
    return currentIntentId;
  }

  const sameNameIntents = scenarioStructures
    .map((s) => s.intents)
    .flatMap((i) => i)
    .filter((i) => i.id.startsWith(newIntentId));

  if (!sameNameIntents.length) {
    return newIntentId;
  }

  const maxSuffix = sameNameIntents.map((i) => +i.id.substring(newIntentId.length + 1)).sort((a, b) => b - a)[0];
  if (!maxSuffix) {
    return `${newIntentId}_1`;
  }

  return `${newIntentId}_${maxSuffix + 1}`;
};
