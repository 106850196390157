import { atom, atomFamily } from 'recoil';

import { BotStageType } from '../../../api';
import { ValidationStatus } from '../utils/validation';

export const currentBotStageTypeState = atomFamily<BotStageType | undefined, string>({
  key: 'currentBotStageTypeState',
  default: () => undefined,
});

export const currentScenarioValidationStatusState = atom<ValidationStatus | undefined>({
  key: 'currentScenarioValidationStatusState',
  default: undefined,
});
