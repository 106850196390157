import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

const TransitionTrigger: React.FC = () => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="s-turn-left" size={16} />
      Переход из другого сценария
    </div>
  );
};

export default TransitionTrigger;
