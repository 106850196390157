import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import IbTagsManagementModal from '../components/IbTagsManagementModal';
import { tagApi } from '../../apis';
import { TagCreationRequest, TagUpdatingRequest } from '../../../api';
import { tagListSearchSelector, tagListSelector } from '../recoil';

interface IbTagsManagementModalWrapperProps {
  visible?: boolean;
  onCancel?: () => void;
}

const IbTagsManagementModalWrapper: React.FC<IbTagsManagementModalWrapperProps> = ({ visible, onCancel }) => {
  const tagListExternal = useRecoilValue(tagListSelector);
  const setTagListSearch = useSetRecoilState(tagListSearchSelector);

  const [tagList, setTagList] = useState(tagListExternal);

  const onTagAdd = async (request: TagCreationRequest) => {
    try {
      await tagApi.createTag(request);
      setTagListSearch({});
      return true;
    } catch {
      return false;
    }
  };

  const onTagEdit = async (tagId: string, request: TagUpdatingRequest) => {
    if (tagList.find((t) => t.id === tagId)?.order !== request.order) {
      const tagListToOrder = cloneDeep(tagList);
      const tag = tagListToOrder.find((t) => t.id === tagId);
      if (tag) tag.order = request.order;
      setTagList(tagListToOrder.sort((a, b) => a.order - b.order));
    }

    try {
      await tagApi.updateTag(tagId, request);
      setTagListSearch({});
      return true;
    } catch {
      return false;
    }
  };

  const onTagDelete = async (tagId: string) => {
    try {
      await tagApi.deleteTag(tagId);
      setTagListSearch({});
      return true;
    } catch {
      return false;
    }
  };

  const onTagListExternalChange = () => {
    setTagList(tagListExternal);
  };
  useEffect(onTagListExternalChange, [tagListExternal]);

  return (
    <IbTagsManagementModal
      tagList={tagList}
      visible={visible}
      onAdd={onTagAdd}
      onCancel={onCancel}
      onDelete={onTagDelete}
      onEdit={onTagEdit}
    />
  );
};

export default IbTagsManagementModalWrapper;
