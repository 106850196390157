import React from 'react';

import './index.less';
import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const SIZE_DEFAULT = 'medium';

const MAIN_CLASS_NAME = 'ib-thumbnail';
const IMAGE_CLASS_NAME = `${MAIN_CLASS_NAME}__image`;
const INTERACTIVE_CLASS_NAME = `${MAIN_CLASS_NAME}_interactive`;

export interface IIbThumbnailProps {
  className?: string;
  fileName?: string;
  linkHref?: string;
  imgSrc: string;
  size?: 'small' | 'medium';
  onPreview?: () => void;
}

const IbThumbnail: React.FC<IIbThumbnailProps> = ({
  className,
  fileName,
  linkHref,
  imgSrc,
  size = SIZE_DEFAULT,
  onPreview,
}) => {
  size = getDefaultIfUndefined(size, SIZE_DEFAULT);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${size}`];
  className && classes.push(className);

  if (linkHref) {
    classes.push(INTERACTIVE_CLASS_NAME);
  }

  const onClick = () => {
    onPreview?.();
  };

  const onTouchEnd = (e: React.TouchEvent) => {
    onPreview?.();

    // NOTE: предотвращает открытие контекстного меню в обработчике onTouchEnd в IbMessageListItem в мобильном режиме
    onPreview && e.preventDefault();
  };

  return (
    <div className={classes.join(' ')} onClick={onClick} onTouchEnd={onTouchEnd}>
      <img alt={fileName || ''} className={IMAGE_CLASS_NAME} src={imgSrc} />
    </div>
  );
};

export default IbThumbnail;
