import React, { ReactNode } from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import './index.less';

import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbIcon from '../../components/common/IbIcon';
import IbTypography from '../../components/common/IbTypography';
import IbWebChat from '../../components/IbWebChat';
import { webchatModalSelector } from '../../../recoil/modals';

import IbSider from './IbSider';

const { Content } = Layout;

const NETWORK_CONNECTION_DISABLED_DEFAULT = false;
const CHAT_SELECTED_DEFAULT = false;

const MAIN_CLASS_NAME = 'ib-layout-base';
const ALERT_CLASS_NAME = `${MAIN_CLASS_NAME}__alert`;
const ALERT_EXTRA_CLASS_NAME = `${ALERT_CLASS_NAME}__extra`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const MAIN_SECTION_CLASS_NAME = `${CONTENT_CLASS_NAME}__main-section`;
const WEBCHAT_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__webchat-container`;

export interface IInboxLayoutBaseProps {
  selectedItem: string;
  networkConnectionDisabled?: boolean;
  chatSelected?: boolean;
  accountWidget?: ReactNode;
  onGoToMainPage: () => void;
}

const InboxLayoutBase: React.FC<IInboxLayoutBaseProps> = ({
  children,
  selectedItem,
  networkConnectionDisabled = NETWORK_CONNECTION_DISABLED_DEFAULT,
  chatSelected = CHAT_SELECTED_DEFAULT,
  accountWidget,
  onGoToMainPage,
}) => {
  networkConnectionDisabled = getDefaultIfUndefined(networkConnectionDisabled, NETWORK_CONNECTION_DISABLED_DEFAULT);
  chatSelected = getDefaultIfUndefined(chatSelected, CHAT_SELECTED_DEFAULT);

  const { t } = useTranslation();

  const webchatModalState = useRecoilValue(webchatModalSelector);
  const resetWebchatModalState = useResetRecoilState(webchatModalSelector);

  const onWebChatClose = () => resetWebchatModalState();

  const webchatComponent = webchatModalState && (
    <div className={WEBCHAT_CONTAINER_CLASS_NAME}>
      <IbWebChat
        subTitle={t('Test widget')}
        title={webchatModalState.title}
        webchatUrl={webchatModalState.webchatUrl}
        onClose={onWebChatClose}
      />
    </div>
  );

  return (
    <div className={MAIN_CLASS_NAME}>
      {networkConnectionDisabled && (
        <div className={ALERT_CLASS_NAME}>
          <IbIcon iconName="close-wifi" size={16} />
          <IbTypography>
            <IbTypography.Paragraph disabled type="descriptor">
              {t('No Internet connection')}
              <span className={ALERT_EXTRA_CLASS_NAME}>. {t('Check your connection and try again')}</span>
            </IbTypography.Paragraph>
          </IbTypography>
        </div>
      )}
      <div className={CONTENT_CLASS_NAME}>
        <IbSider
          accountWidget={accountWidget}
          chatSelected={chatSelected}
          selectedItem={selectedItem}
          onGoToMainPage={onGoToMainPage}
        />
        <Layout className={MAIN_SECTION_CLASS_NAME}>
          <Content>{children}</Content>
        </Layout>
      </div>
      {webchatComponent}
    </div>
  );
};

export default InboxLayoutBase;
