import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Space, Button, Typography, Divider, Select, Checkbox, Row, Col } from 'antd';
import { useSetRecoilState } from 'recoil';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import {
  AgentStageAccountModel,
  AgentStageAccountStatus,
  AgentStageAccountUpdatingRequest,
  LiveChatSecurityValidationRuleModel,
  LiveChatSecurityValidationRuleType,
  LiveChatSettingsModel,
} from '../../../../api';
import { AlertTypes, FORM_FIELD_NAMES, SETTINGS_PROPERTY_KEY } from '../../../constants';
import { agentStageAccountApi } from '../../../apis';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Title } = Typography;

const LiveChatChannelEdit: React.FC = () => {
  const { id, accountId } = useParams<{ id: string; accountId: string }>();
  const { goBack } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [existingAccount, setExistingAccount] = useState<AgentStageAccountModel>();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const loadExistingAccountAsync = async () => {
    const existingAccount = (await agentStageAccountApi.getAgentStageAccount(accountId)).data;
    const existingSettings = existingAccount?.properties?.[SETTINGS_PROPERTY_KEY] as LiveChatSettingsModel;
    form.setFieldsValue({
      [FORM_FIELD_NAMES.displayName]: existingAccount?.displayName,
      [FORM_FIELD_NAMES.chatTitle]: existingSettings?.chat.title,
      [FORM_FIELD_NAMES.chatPrimaryColor]: existingSettings?.chat.primaryColor,
      [FORM_FIELD_NAMES.chatUserBubbleBackgroundColor]: existingSettings?.chat.userBubbleBackground,
      [FORM_FIELD_NAMES.chatBackground]: existingSettings?.chat.background,
      [FORM_FIELD_NAMES.toggleButtonBackground]: existingSettings?.toggleButton.background,
      [FORM_FIELD_NAMES.toggleButtonSize]: existingSettings?.toggleButton.size,
      [FORM_FIELD_NAMES.toggleButtonOffsetBottom]: existingSettings?.toggleButton.offset.bottom,
      [FORM_FIELD_NAMES.toggleButtonOffsetRight]: existingSettings?.toggleButton.offset.right,
      [FORM_FIELD_NAMES.securityValidationRules]: (existingSettings?.security?.validationRules || []).map((e) => ({
        [FORM_FIELD_NAMES.securityValidationRuleEnabled]: e.enabled,
        [FORM_FIELD_NAMES.securityValidationRuleType]: e.type,
        [FORM_FIELD_NAMES.securityValidationRuleValue]: e.value,
      })),
    });
    setExistingAccount(existingAccount);
  };

  const loadExistingAccount = () => {
    loadExistingAccountAsync();
  };

  useEffect(loadExistingAccount, [accountId]);

  const onFinish = async () => {
    try {
      setLoading(true);

      const settings = {
        chat: {
          title: form.getFieldValue(FORM_FIELD_NAMES.chatTitle),
          primaryColor: form.getFieldValue(FORM_FIELD_NAMES.chatPrimaryColor),
          userBubbleBackground: form.getFieldValue(FORM_FIELD_NAMES.chatUserBubbleBackgroundColor),
          background: form.getFieldValue(FORM_FIELD_NAMES.chatBackground),
        },
        toggleButton: {
          background: form.getFieldValue(FORM_FIELD_NAMES.toggleButtonBackground),
          size: form.getFieldValue(FORM_FIELD_NAMES.toggleButtonSize),
          offset: {
            bottom: form.getFieldValue(FORM_FIELD_NAMES.toggleButtonOffsetBottom),
            right: form.getFieldValue(FORM_FIELD_NAMES.toggleButtonOffsetRight),
          },
        },
        security: {
          validationRules: (form.getFieldValue(FORM_FIELD_NAMES.securityValidationRules) || []).map(
            (e: { [x: string]: boolean | string }) =>
              ({
                enabled: e[FORM_FIELD_NAMES.securityValidationRuleEnabled],
                type: e[FORM_FIELD_NAMES.securityValidationRuleType],
                value: e[FORM_FIELD_NAMES.securityValidationRuleValue],
              } as LiveChatSecurityValidationRuleModel)
          ),
        },
      } as LiveChatSettingsModel;

      const request: AgentStageAccountUpdatingRequest = {
        agentStageId: id,
        status: existingAccount?.status ?? AgentStageAccountStatus.Enabled,
        displayName: form.getFieldValue(FORM_FIELD_NAMES.displayName),
        authToken: existingAccount?.authToken ?? '',
        properties: { [SETTINGS_PROPERTY_KEY]: settings },
      };

      await agentStageAccountApi.updateAgentStageAccount(accountId, request);

      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Канал сохранен',
        description: 'LiveChat',
      });
      goBack();
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();
      setError(errorText);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item help={error} validateStatus={error ? 'error' : undefined}>
          <Title level={4}>Редактировать свойства канала LiveChat</Title>
        </Form.Item>
        <Form.Item label="Отображаемое наименование" name={FORM_FIELD_NAMES.displayName}>
          <Input placeholder="Отображаемое наименование канала" />
        </Form.Item>
        <Divider orientation="left" type="horizontal">
          Чат
        </Divider>
        <Form.Item label="Заголовок" name={FORM_FIELD_NAMES.chatTitle}>
          <Input placeholder="Заголовок" />
        </Form.Item>
        <Form.Item label="Основной цвет элементов" name={FORM_FIELD_NAMES.chatPrimaryColor}>
          <Input placeholder="Основной цвет элементов" />
        </Form.Item>
        <Form.Item label="Цвет фона сообщений пользователя" name={FORM_FIELD_NAMES.chatUserBubbleBackgroundColor}>
          <Input placeholder="Цвет фона сообщений пользователя" />
        </Form.Item>
        <Form.Item label="Цвет фона" name={FORM_FIELD_NAMES.chatBackground}>
          <Input placeholder="Цвет фона" />
        </Form.Item>
        <Divider orientation="left" type="horizontal">
          Кнопка чата
        </Divider>
        <Form.Item label="Цвет" name={FORM_FIELD_NAMES.toggleButtonBackground}>
          <Input placeholder="Цвет" />
        </Form.Item>
        <Form.Item label="Размер" name={FORM_FIELD_NAMES.toggleButtonSize}>
          <Input placeholder="Размер" />
        </Form.Item>
        <Form.Item label="Отступ снизу" name={FORM_FIELD_NAMES.toggleButtonOffsetBottom}>
          <Input placeholder="Отступ снизу" />
        </Form.Item>
        <Form.Item label="Отступ справа" name={FORM_FIELD_NAMES.toggleButtonOffsetRight}>
          <Input placeholder="Отступ справа" />
        </Form.Item>
        <Divider orientation="left" type="horizontal">
          Безопасность
        </Divider>
        <Form.List name={FORM_FIELD_NAMES.securityValidationRules}>
          {(fields, { add, remove }) => (
            <Row>
              <Col span={24}>
                {fields.map((field, index) => (
                  <Row key={index} gutter={[16, 16]}>
                    <Col>
                      <Form.Item
                        name={[field.name, FORM_FIELD_NAMES.securityValidationRuleEnabled]}
                        valuePropName="checked"
                      >
                        <Checkbox>Включено</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col flex="380px">
                      <Form.Item
                        {...field}
                        name={[field.name, FORM_FIELD_NAMES.securityValidationRuleType]}
                        rules={[{ required: true, message: 'Выберите тип' }]}
                      >
                        <Select placeholder="Тип валидации запроса">
                          <Select.Option value={LiveChatSecurityValidationRuleType.Cors}>
                            Разрешены только указанные домены
                          </Select.Option>
                          <Select.Option value={LiveChatSecurityValidationRuleType.ValidateTokenBySecret}>
                            Валидация внешнего токена с помощью секрета
                          </Select.Option>
                          <Select.Option value={LiveChatSecurityValidationRuleType.ValidateTokenByPublicKey}>
                            Валидация внешнего токена по публичному ключу
                          </Select.Option>
                          <Select.Option value={LiveChatSecurityValidationRuleType.ValidateTokenByJwks}>
                            Валидация внешнего токена по jwks конфигурации
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item
                        {...field}
                        name={[field.name, FORM_FIELD_NAMES.securityValidationRuleValue]}
                        rules={[{ required: true, message: 'Введите значение' }]}
                      >
                        <Input placeholder="Значение" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <MinusCircleOutlined style={{ margin: 'auto' }} onClick={() => remove(field.name)} />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        block
                        icon={<PlusOutlined />}
                        type="dashed"
                        onClick={() =>
                          add({
                            [FORM_FIELD_NAMES.securityValidationRuleEnabled]: true,
                            [FORM_FIELD_NAMES.securityValidationRuleType]: LiveChatSecurityValidationRuleType.Cors,
                            [FORM_FIELD_NAMES.securityValidationRuleValue]: '',
                          })
                        }
                      >
                        Добавить правило
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Form.List>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={goBack}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LiveChatChannelEdit;
