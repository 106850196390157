import React, { KeyboardEvent, memo, useEffect, useState } from 'react';
import { Menu, Typography } from 'antd';
import { useRecoilValue } from 'recoil';

import { CommandTriggerSchema, DefaultTriggerGroupSchema } from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { EntityFieldPath, getElementId } from '../../../utils';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import { currentScenarioValidationResultSelector } from '../../../../../recoil/scenarioStructure';

import SettingModal from './SettingModal';
import './index.less';

const { Text } = Typography;

interface ICommandTriggerProps {
  index: number;
  trigger: CommandTriggerSchema;
  group: DefaultTriggerGroupSchema;
  onTriggerValueChange: (newValue: CommandTriggerSchema) => void;
}

const CommandTrigger: React.FC<ICommandTriggerProps> = ({ index, trigger, group, onTriggerValueChange }) => {
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [triggerValue, setTriggerValue] = useState(trigger.command);

  const resetState = () => {
    setTriggerValue(trigger.command);
  };

  useEffect(resetState, [trigger]);

  const onTriggerSetupSelect = () => {
    setSettingModalVisible(true);
  };

  const onSettingModalChange = (commandTrigger: CommandTriggerSchema) => {
    setSettingModalVisible(false);

    onTriggerValueChange({ ...trigger, command: commandTrigger.command, description: commandTrigger.description });
  };

  const onSettingModalClose = () => {
    setSettingModalVisible(false);
  };

  const onValueBlur = () => {
    onTriggerValueChange({ ...trigger, command: triggerValue });
  };

  const triggerMenuItem = (
    <Menu.Item key="setup" onClick={onTriggerSetupSelect}>
      <SbIcon iconName="setting-two" />
    </Menu.Item>
  );

  const onTriggerValuePressEnter = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget?.blur();

  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="code-one" size={20} />}
      index={index}
      menuItems={[triggerMenuItem]}
      title="Команда"
      trigger={trigger}
    >
      <div className="command-trigger">
        <Text>Этот сценарий запустится при выполнении команды:</Text>
        <SbInput
          id={getElementId(trigger.id, EntityFieldPath.Command)}
          isValid={!scenarioValidation?.hasIssue(trigger.id, EntityFieldPath.Command)}
          placeholder="Команда"
          value={triggerValue}
          onBlur={onValueBlur}
          onChange={setTriggerValue}
          onPressEnter={onTriggerValuePressEnter}
        />
        <Text>{trigger.description}</Text>
        <SettingModal
          commandTrigger={trigger}
          visible={settingModalVisible}
          onChange={onSettingModalChange}
          onModalClose={onSettingModalClose}
        />
      </div>
    </TriggerWrapper>
  );
};

export default memo(CommandTrigger);
