import { InboxParticipantModel } from '../../../api';
import { inboxParticipantApi } from '../../apis';
import { ITaskData, ITaskQueue } from '../tasks';
import { EventType, IEventBus } from '../events';
import { IUserProfile } from '../../utils/oidcUtil';
import { hubConnections } from '../../utils/socketsUtil';
import { INBOX_PARTICIPANT_CREATED, INBOX_PARTICIPANT_UPDATED } from '../../constants';
import { isSequencesEqual } from '../../utils/arrayUtil';
import { equalsIgnoreCase } from '../../utils/stringUtil';

import { EntityFilter, IEntityData, IEntityPaging, IEntityResponse, StorageDirection } from './types';
import { EntityStorage } from './storage';
import { TableName, IDbTable, PageSizes } from './table';
import { IDbCollection } from './collection';

const EVENT_TYPE_MAP = {
  'inbox.participant.chat.assigned': EventType.DomainInboxParticipantChatAssigned,
  'inbox.participant.chat.unassigned': EventType.DomainInboxParticipantChatUnAssigned,
};

interface IInboxParticipantArgs {
  eventTypes: string[];
  inboxParticipant: InboxParticipantModel;
}

export class InboxParticipantFilter extends EntityFilter<InboxParticipantModel, InboxParticipantFilter> {
  public readonly searchText?: string;
  public readonly subjectIds?: string[];
  public readonly subjectRoles?: string[];

  public constructor(searchText?: string, subjectIds?: string[], subjectRoles?: string[]) {
    super();
    this.searchText = searchText;
    this.subjectIds = subjectIds;
    this.subjectRoles = subjectRoles;
  }

  public equals(filter: InboxParticipantFilter): boolean {
    return (
      isSequencesEqual(this.subjectIds, filter.subjectIds) &&
      isSequencesEqual(this.subjectRoles, filter.subjectRoles) &&
      equalsIgnoreCase(this.searchText ?? '', filter.searchText ?? '')
    );
  }

  public satisfies(entity: InboxParticipantModel): boolean {
    if (
      this.searchText &&
      !entity.subject.person.name.fullName?.toLowerCase().includes(this.searchText.toLowerCase())
    ) {
      return false;
    }

    if (this.subjectIds?.length && !this.subjectIds?.includes(entity.subject.id)) {
      return false;
    }

    return !this.subjectRoles?.length || this.subjectRoles?.includes(entity.subject.role);
  }
}

export class InboxParticipantStorage extends EntityStorage<InboxParticipantModel, InboxParticipantFilter> {
  private static s_instance?: InboxParticipantStorage;

  public constructor(
    userProfile?: IUserProfile,
    eventBus?: IEventBus,
    taskQueue?: ITaskQueue,
    dbTable?: IDbTable<IEntityData<InboxParticipantModel>, string>
  ) {
    super(
      TableName.StorageParticipants,
      { direction: StorageDirection.Forward, pageSize: PageSizes.storageParticipants },
      userProfile,
      eventBus,
      taskQueue,
      dbTable
    );
  }

  public static get instance(): InboxParticipantStorage {
    if (!InboxParticipantStorage.s_instance) {
      InboxParticipantStorage.s_instance = new InboxParticipantStorage();
    }
    return InboxParticipantStorage.s_instance;
  }

  public getKey(entity: InboxParticipantModel): string {
    return entity.id;
  }

  protected async linkEvents(): Promise<void> {
    // NOTE: Не отписываемся от событий т.к. класс сейчас singleton (время жизни соответствует времени жизни приложения).
    const connection = await hubConnections.getBotManagerConnection();
    connection.on(INBOX_PARTICIPANT_CREATED, (args: IInboxParticipantArgs) => this.onCreate(args).finally());
    connection.on(INBOX_PARTICIPANT_UPDATED, (args: IInboxParticipantArgs) => this.onUpdate(args).finally());
  }

  protected async loadMe(): Promise<InboxParticipantModel> {
    const response = await inboxParticipantApi.getInboxMe();

    return response.data;
  }

  protected async loadItem(key: string): Promise<InboxParticipantModel> {
    const response = await inboxParticipantApi.getInboxParticipant(key);

    return response.data;
  }

  protected async loadPage(
    filter: InboxParticipantFilter,
    paging: IEntityPaging
  ): Promise<IEntityResponse<InboxParticipantModel>> {
    const response = await inboxParticipantApi.searchInboxParticipants(
      filter.searchText,
      undefined,
      filter.subjectIds,
      filter.subjectRoles,
      undefined,
      paging.pageIndex,
      paging.pageSize
    );

    return response.data as IEntityResponse<InboxParticipantModel>;
  }

  protected applyOrder(
    table: IDbTable<IEntityData<InboxParticipantModel>, string>
  ): IDbCollection<IEntityData<InboxParticipantModel>, string> {
    return table.orderBy(['order', 'entity.createdOn']);
  }

  protected buildCreationTask(): ITaskData<unknown> {
    throw new Error('Operation is not supported.');
  }

  protected buildDeletionTask(): ITaskData<unknown> {
    throw new Error('Operation is not supported.');
  }

  protected canProcess(): boolean {
    return false;
  }

  protected extractEntity(): InboxParticipantModel | undefined {
    return undefined;
  }

  protected compareEntity(x: InboxParticipantModel, y: InboxParticipantModel): number {
    if (x.createdOn < y.createdOn) {
      return -1;
    }

    if (x.createdOn > y.createdOn) {
      return 1;
    }

    return 0;
  }

  protected withTenant(): boolean {
    return true;
  }

  private async onCreate(args: IInboxParticipantArgs): Promise<void> {
    const entity = args.inboxParticipant;
    const data = this.buildData(0, entity);
    await this.createItem(data);
    this.emitDomainEvents(entity, args.eventTypes, EVENT_TYPE_MAP);
  }

  private async onUpdate(args: IInboxParticipantArgs): Promise<void> {
    const entity = args.inboxParticipant;
    const data = this.buildData(0, entity);
    await this.modifyItem(data);
    this.emitDomainEvents(entity, args.eventTypes, EVENT_TYPE_MAP);
  }
}
