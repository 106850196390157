export const MAIN_CLASS_NAME = 'ib-operators-page';
export const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
export const EMPTY_CONTENT_CLASS_NAME = `${CONTENT_CLASS_NAME}-empty`;
export const EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME = `${EMPTY_CONTENT_CLASS_NAME}__placeholder`;
export const TABLE_CLASS_NAME = `${CONTENT_CLASS_NAME}__table`;
export const ACTIONS_CLASS_NAME = `${TABLE_CLASS_NAME}__actions`;
export const OPERATORS_CLASS_NAME = `${TABLE_CLASS_NAME}__operators`;
export const NAME_CLASS_NAME = `${TABLE_CLASS_NAME}__name`;
export const PHONE_NUMBER_CLASS_NAME = `${TABLE_CLASS_NAME}__phone-number`;
export const STATUS_CLASS_NAME = `${TABLE_CLASS_NAME}__status`;
export const STATUS_ONLINE_CLASS_NAME = `${STATUS_CLASS_NAME}__online`;
export const STATUS_OFFLINE_CLASS_NAME = `${STATUS_CLASS_NAME}__offline`;
export const GROUPS_CLASS_NAME = `${TABLE_CLASS_NAME}__groups`;
export const EXPANDED_ROW_ACTIONS_CLASS_NAME = `${TABLE_CLASS_NAME}__expanded-row-actions`;
export const WITH_OPERATOR_LIST_CLASS_NAME = `${TABLE_CLASS_NAME}__with-operator-list`;

export const ACTIONS_DATA_INDEX = 'actions';
