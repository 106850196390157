import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import './index.less';

import IbTypography from '../common/IbTypography';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTabs from '../common/IbTabs';
import IbTabPane from '../common/IbTabPane';
import IbBadge from '../common/IbBadge';
import { InboxChatModel, TagModel } from '../../../../api';
import IbChatList, { ChatTabKeys } from '../IbChatList';
import { IEntityItem, IEntityRange, IEntityScroll, IEntityStats } from '../../storages';
import IbTagsSearch, { IIbTagsSearchData } from '../IbTagsSearch';
import { TARIFF_URL } from '../../../constants';

const MAIN_CLASS_NAME = 'ib-chat-tabs';
const TITLE_CLASS_NAME = `${MAIN_CLASS_NAME}__title`;
const MOBILE_TITLE_CLASS_NAME = `${TITLE_CLASS_NAME}-mobile`;
const SEARCH_CLASS_NAME = `${MAIN_CLASS_NAME}__search`;
const ALERT_CLASS_NAME = `${MAIN_CLASS_NAME}__alert`;
const TABS_CLASS_NAME = `${MAIN_CLASS_NAME}__tabs`;

export interface IChatTabData {
  items: (IEntityItem<InboxChatModel> | undefined)[];
  count?: number;
  stats?: IEntityStats;
  range?: IEntityRange;
  onScroll?: (scroll: IEntityScroll) => void;
}

export interface IChatTabsData {
  searchTab?: IChatTabData;
  queueTab?: IChatTabData;
  myTab?: IChatTabData;
  closedTab?: IChatTabData;
  allTab?: IChatTabData;
}

export interface IIbChatTabsProps {
  chatTabsData: IChatTabsData;
  currentUserId?: string;
  selectedChatId?: string;
  tabKey?: ChatTabKeys;
  tagList?: TagModel[];
  searchData?: IIbTagsSearchData;
  mentionLinksEnabled: boolean;
  accountWidget?: ReactNode;
  tariffLimitExceeded?: boolean;
  onChatSearch?: (data: IIbTagsSearchData) => void;
  onChatSelected?: (chatId?: string) => void;
  onStatusChanged?: (status: string) => void;
}

const IbChatTabs: React.FC<IIbChatTabsProps> = ({
  chatTabsData,
  currentUserId,
  selectedChatId,
  tabKey,
  tagList,
  searchData,
  mentionLinksEnabled,
  accountWidget,
  tariffLimitExceeded,
  onChatSearch,
  onChatSelected,
  onStatusChanged,
}) => {
  const { t } = useTranslation();

  const onTabChange = (activeKey: string) => {
    onStatusChanged?.(activeKey);
  };

  const renderList = (tabData: IChatTabData) => {
    const isSearch = tabData === chatTabsData.searchTab;
    return (
      <IbChatList
        currentUserId={currentUserId}
        items={tabData.items}
        mentionLinksEnabled={mentionLinksEnabled}
        range={tabData.range}
        searchText={isSearch ? searchData?.searchValue : undefined}
        selectedChatId={selectedChatId}
        stats={tabData.stats}
        tabKey={isSearch ? undefined : tabKey}
        onChatSelected={onChatSelected}
        onScroll={tabData.onScroll}
      />
    );
  };

  const renderSearchTab = () => {
    const searchTab = chatTabsData.searchTab;
    if (!searchTab) {
      return null;
    }

    return renderList(searchTab);
  };

  const renderQueueTab = () => {
    const queueTab = chatTabsData.queueTab;
    if (!queueTab) {
      return null;
    }

    return (
      <IbTabPane
        key={ChatTabKeys.Queue}
        tab={
          <>
            <span>{t('In queue')}</span>
            {!!queueTab.count && <IbBadge disabled={tabKey !== ChatTabKeys.Queue} value={queueTab.count} />}
          </>
        }
        tabKey={ChatTabKeys.Queue}
      >
        {renderList(queueTab)}
      </IbTabPane>
    );
  };

  const renderMyTab = () => {
    const myTab = chatTabsData.myTab;
    if (!myTab) {
      return null;
    }

    return (
      <IbTabPane
        key={ChatTabKeys.My}
        tab={
          <>
            <span>{t('My')}</span>
            {!!myTab.count && <IbBadge disabled={tabKey !== ChatTabKeys.My} value={myTab.count} />}
          </>
        }
        tabKey={ChatTabKeys.My}
      >
        {renderList(myTab)}
      </IbTabPane>
    );
  };

  const renderClosedTab = () => {
    const closedTab = chatTabsData.closedTab;
    if (!closedTab) {
      return null;
    }

    return (
      <IbTabPane key={ChatTabKeys.Closed} tab={t('Closed')} tabKey={ChatTabKeys.Closed}>
        {renderList(closedTab)}
      </IbTabPane>
    );
  };

  const renderAllTab = () => {
    const allTab = chatTabsData.allTab;
    if (!allTab) {
      return null;
    }

    return (
      <IbTabPane key={ChatTabKeys.All} tab={t('All')} tabKey={ChatTabKeys.All}>
        {renderList(allTab)}
      </IbTabPane>
    );
  };

  const renderTabs = () => {
    if (chatTabsData.searchTab) {
      return <div className={TABS_CLASS_NAME}>{renderSearchTab()}</div>;
    }

    return (
      <div className={TABS_CLASS_NAME}>
        <IbTabs activeKey={tabKey} onChange={onTabChange}>
          {renderQueueTab()}
          {renderMyTab()}
          {renderClosedTab()}
          {renderAllTab()}
        </IbTabs>
      </div>
    );
  };

  const renderAlert = () => {
    if (!tariffLimitExceeded) {
      return null;
    }

    return (
      <div className={ALERT_CLASS_NAME}>
        <IbIcon iconName="attention" size={16} />
        <IbTypography>
          <IbTypography.Paragraph disabled type="descriptor">
            <Trans i18nKey="Tariff limit exceeded 4">
              The monthly chat limit has been exceeded. Currently you cannot send messages; to remove restrictions,
              <a href={TARIFF_URL}>change the tariff</a>
            </Trans>
          </IbTypography.Paragraph>
        </IbTypography>
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={TITLE_CLASS_NAME}>
        <IbTypography>
          <h2>{t('Chats')}</h2>
        </IbTypography>
        <IbButton icon={<IbIcon iconName="add-one" />} type="icon" />
      </div>
      <div className={MOBILE_TITLE_CLASS_NAME}>
        {accountWidget}
        <IbTypography>
          <h4>{t('Chats')}</h4>
        </IbTypography>
        <IbButton icon={<IbIcon iconName="add-one" />} type="icon" />
        <IbButton icon={<IbIcon iconName="search" />} type="icon" />
      </div>
      {renderAlert()}
      <div className={SEARCH_CLASS_NAME}>
        <IbTagsSearch
          placeholder={t('Chat search')}
          searchData={searchData}
          tagList={tagList}
          onChange={onChatSearch}
        />
      </div>
      {renderTabs()}
    </div>
  );
};

export default IbChatTabs;
