import React, { memo } from 'react';
import { Typography } from 'antd';

import { DefaultTriggerGroupSchema, TransitionTriggerSchema } from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

const { Text } = Typography;

interface TransitionTriggerProps {
  index: number;
  trigger: TransitionTriggerSchema;
  group: DefaultTriggerGroupSchema;
}

const TransitionTrigger: React.FC<TransitionTriggerProps> = ({ trigger, index, group }) => {
  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="s-turn-left" size={20} />}
      index={index}
      menuItems={[]}
      title="Переход из др. сценария"
      trigger={trigger}
    >
      <Text>Этот сценарий запустится при переходе из другого сценария.</Text>
    </TriggerWrapper>
  );
};

export default memo(TransitionTrigger);
