import React, { memo, MouseEventHandler, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Menu } from 'antd';

import './index.less';

import { ActionGroupSchema, BindingSchema, DefaultActionGroupSchema } from '../../../../../../api';
import {
  capturedBindingSelector,
  dispatcherState,
  dragSourceSelector,
  groupsSelector,
  selectedEntitySelector,
} from '../../../../../recoil/scenarioStructure';
import { PlusIcon } from '../../../assets';
import { IEntityWithOutputBinding } from '../../../types';
import DropdownMenu from '../DropdownMenu';

import OutputConnectionPositionsUpdater from './OutputConnectionPositionsUpdater';

interface OutputConnectionProps {
  id: string;
  entity: IEntityWithOutputBinding;
  groupId: string;
  className?: string;
  isValid?: boolean;
}

const OutputConnection: React.FC<OutputConnectionProps> = ({ id, entity, groupId, className, isValid = true }) => {
  const { startDragFromHandle, stopDragFromHandle, addNewBinding, addNewGroup, addNewEndingGroup } = useRecoilValue(
    dispatcherState
  );
  const dragSource = useRecoilValue(dragSourceSelector);
  const selectedEntity = useRecoilValue(selectedEntitySelector);
  const capturedBinding = useRecoilValue(capturedBindingSelector);
  const groups = useRecoilValue(groupsSelector);

  const groupList = groups.filter((g) => g.id !== groupId);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const onMouseDown: MouseEventHandler = async (e) => {
    e.stopPropagation();
    setMenuIsVisible(false);
    await startDragFromHandle({ entity: entity, screenX: e.screenX, screenY: e.screenY });
  };

  const onMouseUp: MouseEventHandler = (e) => {
    e.stopPropagation();
    stopDragFromHandle();
  };

  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setMenuIsVisible(true);
  };

  const onGroupSelect = async (group: ActionGroupSchema) => {
    await addNewBinding(entity.id, group.id);
  };

  const onNewGroupSelect = async () => {
    await addNewGroup(entity.id);
  };

  const onEndingGroupSelect = async () => {
    await addNewEndingGroup(entity.id);
  };

  const isBindingDragging = dragSource?.id === entity.id;
  const isRelatedBindingSelected = (selectedEntity as BindingSchema)?.sourceEntityId === entity.id;
  const isEntitySelected =
    selectedEntity?.id === entity.id && (selectedEntity as IEntityWithOutputBinding)?.outputBindingId;
  const isRelatedGroupSelected =
    entity.outputBindingId &&
    (selectedEntity as DefaultActionGroupSchema)?.inputBindingIds?.includes(entity.outputBindingId);
  const isCapturedBindingDragging = capturedBinding?.binding.sourceEntityId === entity.id;

  const classes = ['output-connection'];
  if (className) {
    classes.push(className);
  }
  if (!isValid) {
    classes.push('output-connection_error');
  }
  if (entity.outputBindingId) {
    classes.push('output-connection_connected');
  }
  if (
    isBindingDragging ||
    isRelatedBindingSelected ||
    isEntitySelected ||
    isRelatedGroupSelected ||
    isCapturedBindingDragging
  ) {
    classes.push('output-connection_active');
  }

  return (
    <div
      ref={ref}
      className={classes.join(' ')}
      id={id}
      role="none"
      onClick={onClick}
      onDoubleClick={(e) => e.stopPropagation()}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      <PlusIcon />
      <DropdownMenu
        isVisible={menuIsVisible}
        placement="right"
        size="small"
        triggerElement={ref.current}
        width={200}
        onClose={() => setMenuIsVisible(false)}
      >
        <Menu>
          <Menu.ItemGroup title="Соединить с группой:">
            {groupList.map((group) => (
              <Menu.Item key={group.id} onClick={() => onGroupSelect(group)}>
                {group.$designer?.name || group.name}
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
          <Menu.Divider />
          <Menu.Item key="new-group" onClick={onNewGroupSelect}>
            Новый блок
          </Menu.Item>
          <Menu.Item key="ending-group" onClick={onEndingGroupSelect}>
            Завершающий блок
          </Menu.Item>
        </Menu>
      </DropdownMenu>
      <OutputConnectionPositionsUpdater entityId={entity.id} groupId={groupId} outputConnectionRef={ref} />
    </div>
  );
};

export default memo(OutputConnection);
