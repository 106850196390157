import { detect } from 'detect-browser';

const supportedBrowsers = ['chrome', 'safari', 'edge-chromium'];

export const isTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const isTouchOnlyDevice = (): boolean => {
  return matchMedia('(hover: none)').matches;
};

const isBrowserSupported = (): boolean => {
  const browser = detect();
  return browser?.type !== 'browser' || supportedBrowsers.includes(browser?.name);
};

export const getBrowserCompatibilityWarnings: () => string[] = () => {
  const touchOnly = isTouchOnlyDevice();
  const browserSupported = isBrowserSupported();

  const warnings = [];

  touchOnly && warnings.push('Устройства с сенсорным вводом не поддерживаются.');

  !browserSupported &&
    warnings.push(
      'Браузер не поддерживается. Рекомендуется использовать один из следующих браузеров: Chrome, Safari, Edge.'
    );

  warnings.length && warnings.push('Некоторые функции могут работать неправильно.');

  return warnings;
};
