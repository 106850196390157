import { VirtualItem } from '@tanstack/react-virtual';

import { ITaskOperation } from '../tasks';

export enum StorageDirection {
  Forward = 'Forward',
  Backward = 'Backward',
}

export enum StatsStatus {
  Unknown = 'Unknown',
  Estimated = 'Estimated',
}

export enum CreationMode {
  None = 'None',
  Sync = 'Sync',
  Async = 'Async',
}

export enum CreationState {
  Initial = 'Initial',
  Subsequent = 'Subsequent',
}

export enum DeletionMode {
  None = 'None',
  Sync = 'Sync',
  Async = 'Async',
}

export interface IStorageOptions {
  direction: StorageDirection;
  pageSize: number;
}

export interface ICreationOptions {
  mode: CreationMode;
  state: CreationState;
}

export interface IDeletionOptions {
  mode: DeletionMode;
}

export interface ITenantEntity {
  tenantId: string;
}

export interface IEntityFilter<TEntity, TFilter> {
  toJSON: () => string;
  equals: (filter: TFilter) => boolean;
  satisfies: (entity: TEntity) => boolean;
}

export interface IEntityStats {
  version: string;
  status: StatsStatus;
  itemCount: number;
}

export interface IEntityScroll {
  virtualItems: VirtualItem[];
  scrollOffset: number;
  totalSize: number;
}

export interface IEntityWindow {
  pageIndex: number;
  pageCount: number;
}

export interface IEntityRange extends IEntityScroll, IEntityWindow {
  version: string;
  itemIndex: number;
  itemCount: number;
}

export interface IEntityPaging {
  pageIndex: number;
  pageSize: number;
}

export interface IEntityResponse<TEntity> {
  items: TEntity[];
  hasMore: boolean;
}

export interface IEntityData<TEntity> {
  order: number;
  entity: TEntity;
  operation?: ITaskOperation;
}

export interface IEntityItem<TEntity> {
  order: number;
  entity: TEntity;
  operation: ITaskOperation;
}

export interface IEntityPage<TEntity> {
  index: number;
  items: IEntityItem<TEntity>[];
}

export interface IStorageEvent {
  name: string;
}

export interface IStorageStateEvent<TFilter> extends IStorageEvent {
  filter: TFilter;
}

export interface IStorageItemEvent<TEntity> extends IStorageEvent {
  item: IEntityItem<TEntity>;
}

export interface IStorageStateItemEvent<TEntity, TFilter>
  extends IStorageStateEvent<TFilter>,
    IStorageItemEvent<TEntity> {
  index: number;
}

export abstract class EntityFilter<TEntity, TFilter> implements IEntityFilter<TEntity, EntityFilter<TEntity, TFilter>> {
  public toJSON(): string {
    const source = (this as unknown) as { [key: string]: unknown };
    const target: { [key: string]: unknown } = {};

    for (const key of Object.keys(this)) {
      const value = source[key];

      if (value == null) {
        continue;
      }

      if (Array.isArray(value) && !value.length) {
        continue;
      }

      target[key] = value;
    }

    return JSON.stringify(target);
  }

  public abstract equals(filter: EntityFilter<TEntity, TFilter>): boolean;

  public abstract satisfies(entity: TEntity): boolean;
}
