import React from 'react';

import './index.less';

import { InboxChannelModel } from '../../../../api';

import IbChannelListItem from './IbChannelListItem';

const MAIN_CLASS_NAME = 'ib-channel-list';

const SKELETON_ITEM_COUNT = 4;

export interface IIbChannelListProps {
  items: {
    channel: InboxChannelModel;
    onSwitch: () => Promise<void>;
    onDelete: () => void;
    onEdit: () => void;
    onConfigureRouting: () => void;
    onDiagnosticsSelect: () => void;
  }[];
  loading: boolean;
}

const IbChannelList: React.FC<IIbChannelListProps> = ({ items, loading }) => {
  return (
    <div className={MAIN_CLASS_NAME}>
      {loading
        ? Array.from(Array(SKELETON_ITEM_COUNT).keys()).map((_, index) => (
            <IbChannelListItem
              key={index}
              loading
              channel={{ agentStageAccount: {} } as InboxChannelModel}
              onConfigureRouting={() => {}}
              onDelete={() => {}}
              onDiagnosticsSelect={() => {}}
              onEdit={() => {}}
              onSwitch={async () => {}}
            />
          ))
        : items.map((item, index) => <IbChannelListItem key={index} {...item} loading={false} />)}
    </div>
  );
};

export default IbChannelList;
