import React from 'react';
import { Typography } from 'antd';

import SbPanel from '../../../../components/common/SbPanel';
import { ActivityModel } from '../../../../../../api';
import SbIcon from '../../../../components/common/SbIcon';

const MAIN_CLASS = 'sb-dialogs-card__content__panel-container__info';

interface IErrorInfoProps {
  activity: ActivityModel;
}

const ErrorInfo: React.FC<IErrorInfoProps> = ({ activity }) => (
  <>
    <Typography.Title ellipsis level={4}>
      Подробнее об ошибке
    </Typography.Title>
    <SbPanel sbType="alert">
      <div className={`${MAIN_CLASS}__secondary-info`}>{activity.value.ClassName ?? activity.value.Source}</div>
      <div className={`${MAIN_CLASS}__text`}>{activity.value.Message}</div>
    </SbPanel>
    <div className={`${MAIN_CLASS}__additional-title`}>
      <SbIcon iconName="attention" size={20} />
      <div>Детали ошибки</div>
    </div>
    <div className={`${MAIN_CLASS}__error-details`}>{activity.value.StackTraceString ?? activity.value.StackTrace}</div>
  </>
);

export default ErrorInfo;
