import { RadioChangeEvent } from 'antd/lib/radio/interface';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import { PersonAccountModel, PersonMergingRequest, PersonModel, TagModel } from '../../../../api';
import IbButton from '../common/IbButton';
import IbTypography from '../common/IbTypography';
import IbTagsWidget from '../IbTagsWidget';
import IbRadioGroup from '../common/IbRadioGroup';
import IbRadioButton from '../common/IbRadioButton';
import IbAvatar from '../common/IbAvatar';
import { COUNTRY_LIST } from '../../../constants';
import { getCountryName } from '../../../utils/countryUtil';
import IbSocial, { IbSocialType } from '../common/IbSocial';
import IbIcon from '../common/IbIcon';
import IbList from '../common/IbList';

import {
  MAIN_CLASS_NAME,
  ONE_COLUMN_CLASS_NAME,
  TWO_COLUMN_CLASS_NAME,
  THREE_COLUMN_CLASS_NAME,
  FOUR_COLUMN_CLASS_NAME,
  FORM_ITEM_CLASS_NAME,
  PERSON_LIST_DEFAULT,
  FORM_CHANNEL_CLASS_NAME,
  FORM_CHANNEL_TITLE_CLASS_NAME,
  FORM_HEADER_CLASS_NAME,
  FORM_HEADER_TITLE_CLASS_NAME,
} from './const';

interface IIbContactMergeFieldOption {
  value: number;
  label: string;
  person: PersonModel;
}

export interface IIbContactMergeModalProps {
  visible?: boolean;
  personList?: PersonModel[];
  allowTagsManagement?: boolean;
  tagList?: TagModel[];
  onMerge?: (request: PersonMergingRequest, person: PersonModel) => Promise<void>;
  onTagsManagement?: () => void;
  onCancel?: () => void;
}

const IbContactMergeModal: React.FC<IIbContactMergeModalProps> = ({
  visible,
  personList = PERSON_LIST_DEFAULT,
  allowTagsManagement,
  tagList,
  onMerge,
  onTagsManagement,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();

  const [countryList] = useState(new Map(COUNTRY_LIST.map((c) => [c.alpha2, getCountryName(c, i18n)])));

  const [merging, setMerging] = useState(false);
  const [person, setPerson] = useState({} as PersonModel);

  const [avatarIndex, setAvatarIndex] = useState(0);
  const [fullNameIndex, setFullNameIndex] = useState(0);
  const [primaryTelIndex, setPrimaryTelIndex] = useState(0);
  const [eMailIndex, setEMailIndex] = useState(0);
  const [locationIndex, setLocationIndex] = useState(0);

  const accounts = personList.flatMap((p) => p.personAccounts || []);

  const onMergeButtonClick = async () => {
    setMerging(true);
    await onMerge?.(
      {
        sourceIds: personList.map((p) => p.id),
        targetId: person.id,
        fullName: person.fullName,
        lastName: person.lastName,
        firstName: person.firstName,
        middleName: person.middleName,
        primaryTel: person.primaryTel,
        secondaryTels: person.secondaryTels,
        eMail: person.eMail,
        country: person.country,
        city: person.city,
        tagIds: person.tags?.map((t) => t.id),
        additionalInfo: person.additionalInfo,
        avatarFileId: person.avatarFileId,
        groupIds: person.groupIds,
        accountIds: person.personAccounts?.map((a) => a.id) || [],
      },
      person
    );
    setMerging(false);
    onCancel?.();
  };

  const onTagsChange = async (tagIds: string[]) => {
    setPerson({ ...person, tags: tagIds.map((id) => ({ id } as TagModel)) });
  };

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setMerging(false);
    setPerson(
      personList.length
        ? {
            ...personList[0],
            tags: Array.from(new Set(personList.flatMap((p) => p.tags).map((t) => t.id))).map(
              (id) =>
                ({
                  id,
                } as TagModel)
            ),
            personAccounts: accounts,
            accountContacts: [],
          }
        : ({} as PersonModel)
    );

    setAvatarIndex(0);
    setFullNameIndex(0);
    setPrimaryTelIndex(0);
    setEMailIndex(0);
    setLocationIndex(0);
  };
  useEffect(onVisiblePropChange, [visible]);

  const avatarOptions = personList.map((p, i) => ({ value: i, label: p.avatarUrl || '', person: p }));
  const fullNameOptions = personList.map((p, i) => ({ value: i, label: p.fullName, person: p }));
  const primaryTelOptions = personList.map((p, i) => ({ value: i, label: p.primaryTel || '', person: p }));
  const eMailOptions = personList.map((p, i) => ({ value: i, label: p.eMail || '', person: p }));
  const locationOptions = personList.map((p, i) => ({
    value: i,
    label: [countryList.get(p.country || ''), p.city].filter((v) => v).join(', '),
    person: p,
  }));

  const onAvatarChange = (e: RadioChangeEvent) => {
    const index: number = e.target.value;
    const selectedPerson = personList[index];
    const newPerson = {
      ...person,
      avatarUrl: selectedPerson.avatarUrl,
      avatarFileId: selectedPerson.avatarFileId,
    };
    setPerson(newPerson);
    setAvatarIndex(index);
  };

  const onFullNameChange = (e: RadioChangeEvent) => {
    const index: number = e.target.value;
    const selectedPerson = personList[index];
    const newPerson = {
      ...person,
      fullName: selectedPerson.fullName,
      firstName: selectedPerson.firstName,
      lastName: selectedPerson.lastName,
      middleName: selectedPerson.middleName,
    };
    setPerson(newPerson);
    setFullNameIndex(index);
  };

  const onPrimaryTelChange = (e: RadioChangeEvent) => {
    const index: number = e.target.value;
    const selectedPerson = personList[index];
    const newPerson = {
      ...person,
      primaryTel: selectedPerson.primaryTel,
      secondaryTels: selectedPerson.secondaryTels,
    };
    setPerson(newPerson);
    setPrimaryTelIndex(index);
  };

  const onEMailChange = (e: RadioChangeEvent) => {
    const index: number = e.target.value;
    const selectedPerson = personList[index];
    const newPerson = {
      ...person,
      eMail: selectedPerson.eMail,
    };
    setPerson(newPerson);
    setEMailIndex(index);
  };

  const onLocationChange = (e: RadioChangeEvent) => {
    const index: number = e.target.value;
    const selectedPerson = personList[index];
    const newPerson = {
      ...person,
      country: selectedPerson.country,
      city: selectedPerson.city,
    };
    setPerson(newPerson);
    setLocationIndex(index);
  };

  const onDeleteChannelButtonClick = (account: PersonAccountModel) => () => {
    const newPerson = {
      ...person,
      personAccounts: person.personAccounts?.filter((a) => a.id !== account.id),
    };
    setPerson(newPerson);
  };

  const onRestoreChannelsButtonClick = () => {
    const newPerson = {
      ...person,
      personAccounts: accounts,
    };
    setPerson(newPerson);
  };

  const channelsHeader = (
    <div className={FORM_HEADER_CLASS_NAME}>
      <span className={FORM_HEADER_TITLE_CLASS_NAME}>{t('Channels')}</span>
      {person.personAccounts?.length !== accounts.length && (
        <IbButton type="link" onClick={onRestoreChannelsButtonClick}>
          {t('Cancel')}
        </IbButton>
      )}
    </div>
  );

  const windowFooter = (
    <>
      <IbButton disabled={merging} onClick={onMergeButtonClick}>
        {t('Merge')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
    </>
  );

  const renderAvatarOptions = (options: IIbContactMergeFieldOption[]) =>
    options.map((o) => (
      <IbRadioButton key={o.value} value={o.value}>
        <IbAvatar imgSrc={o.label} metadata={{ uid: o.person.id, text: o.person.fullName }} size="large" />
      </IbRadioButton>
    ));

  const renderGeneralOptions = (options: IIbContactMergeFieldOption[]) =>
    options.map((o) => (
      <IbRadioButton key={o.value} value={o.value}>
        {o.label}
      </IbRadioButton>
    ));

  const renderChannelItem = (item: unknown) => {
    const account = item as PersonAccountModel;
    return (
      <li className={FORM_CHANNEL_CLASS_NAME}>
        <IbSocial social={account.channelId as IbSocialType} />
        <span className={FORM_CHANNEL_TITLE_CLASS_NAME}>{account.channelId}</span>
        <IbButton icon={<IbIcon iconName="close-small" />} type="icon" onClick={onDeleteChannelButtonClick(account)} />
      </li>
    );
  };

  const renderChannelsEmpty = () => {
    return (
      <IbTypography.Paragraph type="secondary">
        <Trans i18nKey="Channels empty">
          The list is empty. Send the client a link to the connected <Link to="/inbox/channels">channel</Link>.
        </Trans>
      </IbTypography.Paragraph>
    );
  };

  const classes = [MAIN_CLASS_NAME];
  if (personList.length == 2) {
    classes.push(TWO_COLUMN_CLASS_NAME);
  } else if (personList.length == 3) {
    classes.push(THREE_COLUMN_CLASS_NAME);
  } else if (personList.length == 4) {
    classes.push(FOUR_COLUMN_CLASS_NAME);
  } else {
    classes.push(ONE_COLUMN_CLASS_NAME);
  }

  return (
    <IbModal
      className={classes.join(' ')}
      footer={windowFooter}
      loading={merging}
      position={IbModalPosition.FixedTall}
      title={t('Merge contacts')}
      visible={visible}
      onCancel={onCancel}
    >
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbRadioGroup buttonStyle="outline" optionType="default" value={avatarIndex} onChange={onAvatarChange}>
          {renderAvatarOptions(avatarOptions)}
        </IbRadioGroup>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Name')}</IbTypography.Paragraph>
        <IbRadioGroup buttonStyle="solid" optionType="button" value={fullNameIndex} onChange={onFullNameChange}>
          {renderGeneralOptions(fullNameOptions)}
        </IbRadioGroup>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Phone number')}</IbTypography.Paragraph>
        <IbRadioGroup buttonStyle="solid" optionType="button" value={primaryTelIndex} onChange={onPrimaryTelChange}>
          {renderGeneralOptions(primaryTelOptions)}
        </IbRadioGroup>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('E-mail')}</IbTypography.Paragraph>
        <IbRadioGroup buttonStyle="solid" optionType="button" value={eMailIndex} onChange={onEMailChange}>
          {renderGeneralOptions(eMailOptions)}
        </IbRadioGroup>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Country and city')}</IbTypography.Paragraph>
        <IbRadioGroup buttonStyle="solid" optionType="button" value={locationIndex} onChange={onLocationChange}>
          {renderGeneralOptions(locationOptions)}
        </IbRadioGroup>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbList
          dataSource={person.personAccounts || []}
          header={channelsHeader}
          locale={{ emptyText: renderChannelsEmpty() }}
          renderItem={renderChannelItem}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Tags')}</IbTypography.Paragraph>
        <IbTagsWidget
          allowTagsManagement={allowTagsManagement}
          selectedTagIds={person.tags?.map((tag) => tag.id)}
          tagList={tagList}
          onChange={onTagsChange}
          onTagsManagement={onTagsManagement}
        />
      </div>
    </IbModal>
  );
};

export default IbContactMergeModal;
