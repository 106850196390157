import React, { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';
import { Row, Col } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { Key } from 'ts-key-enum';
import { RcFile } from 'antd/lib/upload';

import './index.less';

import IbTypography from '../../../../components/common/IbTypography';
import {
  BotSchema,
  DefaultScenarioSchema,
  IntentEntryModel,
  ScenarioCancellationMode,
  ScenarioContentFormat,
  ScenarioEditionReferenceModel,
  ScenarioInterruptionMode,
  UpdateScenarioOperationType,
} from '../../../../../../api';
import { inboxAlertsSelectorAdd } from '../../../../recoil';
import { botStageApi } from '../../../../../apis';
import { AlertTypes, SCENARIO_EXPORT_FINISHED } from '../../../../../constants';
import IbScenarioCard, { IScenarioTrigger } from '../../../../components/IbScenarioCard';
import {
  instanceOfCommandTriggerSchema,
  instanceOfDefaultTriggerGroupSchema,
  instanceOfExternalEventTriggerSchema,
  instanceOfIntentTriggerSchema,
  instanceOfMenuButtonTriggerSchema,
  instanceOfStartTriggerSchema,
  instanceOfSystemIntentReferenceSchema,
  instanceOfTerminalTriggerSchema,
  instanceOfTransitionTriggerSchema,
  instanceOfUnknownIntentTriggerSchema,
  instanceOfUserIntentReferenceSchema,
} from '../../../../../components/ScenarioEditor/utils';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import i18n from '../../../../../i18n';
import { getErrorMessage, isInvalidCastError } from '../../../../../utils/errorUtils';
import { hubConnections } from '../../../../../utils/socketsUtil';
import IbProgressStatusModal, { IbProgressStatusModalStatus } from '../../../../components/IbProgressStatusModal';

interface ScenarioSettings {
  interruption: ScenarioInterruptionMode;
  cancellation: ScenarioCancellationMode;
}

enum ScenarioMode {
  Substitute = 'Substitute',
  Interrupting = 'Interrupting',
  Common = 'Common',
}

const ScenarioModeOptions = [
  { value: ScenarioMode.Substitute, label: i18n.t('Substitute') },
  { value: ScenarioMode.Interrupting, label: i18n.t('Interrupting') },
  { value: ScenarioMode.Common, label: i18n.t('Common') },
];

const getScenarioMode = (settings: ScenarioSettings) => {
  if (
    settings.cancellation === ScenarioCancellationMode.CancelAll &&
    settings.interruption === ScenarioInterruptionMode.InterruptActive
  ) {
    return ScenarioMode.Substitute;
  }

  if (settings.interruption === ScenarioInterruptionMode.InterruptActive) {
    return ScenarioMode.Interrupting;
  }

  return ScenarioMode.Common;
};

const getScenarioSettings = (scenarioMode: ScenarioMode): ScenarioSettings => {
  switch (scenarioMode) {
    case ScenarioMode.Substitute:
      return {
        interruption: ScenarioInterruptionMode.InterruptActive,
        cancellation: ScenarioCancellationMode.CancelAll,
      };
    case ScenarioMode.Interrupting:
      return {
        interruption: ScenarioInterruptionMode.InterruptActive,
        cancellation: ScenarioCancellationMode.Disabled,
      };
    case ScenarioMode.Common:
    default:
      return {
        interruption: ScenarioInterruptionMode.Disabled,
        cancellation: ScenarioCancellationMode.Disabled,
      };
  }
};

export const getScenarioTriggers: (
  scenarioStructure: DefaultScenarioSchema,
  systemIntents: IntentEntryModel[]
) => IScenarioTrigger[] = (scenarioStructure: DefaultScenarioSchema, systemIntents: IntentEntryModel[]) => {
  if (!instanceOfDefaultTriggerGroupSchema(scenarioStructure.triggerGroup)) {
    return [];
  }
  const scenarioTriggers = scenarioStructure.triggerGroup.triggers.map((trigger) => {
    if (instanceOfIntentTriggerSchema(trigger)) {
      if (instanceOfSystemIntentReferenceSchema(trigger.intentReference)) {
        const systemIntent = systemIntents.find(
          (systemIntent) => systemIntent.code === trigger.intentReference.intentCode
        );
        return {
          name: systemIntent?.name ?? trigger.intentReference.intentCode,
          iconName: 'comments',
        };
      }
      if (instanceOfUserIntentReferenceSchema(trigger.intentReference)) {
        const userIntent = scenarioStructure.intents.find((i) => i.id === trigger.intentReference.intentId);
        return {
          name: userIntent?.name ?? trigger.intentReference.intentId,
          iconName: 'comments',
        };
      }
    }

    if (instanceOfStartTriggerSchema(trigger)) {
      return {
        name: i18n.t('Bot start'),
        iconName: 'play',
      };
    }

    if (instanceOfMenuButtonTriggerSchema(trigger)) {
      return {
        name: trigger.value,
        iconName: 'list-top',
      };
    }

    if (instanceOfTerminalTriggerSchema(trigger)) {
      return {
        name: i18n.t('Terminal trigger'),
        iconName: 'handle-x',
      };
    }

    if (instanceOfTransitionTriggerSchema(trigger)) {
      return {
        name: i18n.t('Transition from other scenario'),
        iconName: 's-turn-left',
      };
    }

    if (instanceOfUnknownIntentTriggerSchema(trigger)) {
      return {
        name: i18n.t('Unknown intent'),
        iconName: 'help',
      };
    }

    if (instanceOfCommandTriggerSchema(trigger)) {
      return {
        name: trigger.command,
        iconName: 'setting-two',
      };
    }

    if (instanceOfExternalEventTriggerSchema(trigger)) {
      return {
        name: trigger.externalEventName,
        iconName: 'link-one',
      };
    }

    return undefined;
  });

  return scenarioTriggers.filter((st) => st) as IScenarioTrigger[];
};

interface IScenarioExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

interface IScenarioImport {
  processing: boolean;
  errorMessage: string;
}

const scenarioExportDefaultValue: IScenarioExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

const scenarioImportDefaultValue: IScenarioImport = {
  processing: false,
  errorMessage: '',
};

interface IScenarioCardProps {
  scenario: ScenarioEditionReferenceModel;
  systemIntents: IntentEntryModel[];
  botStructure: BotSchema;
  botStageId: string;
  botEntryId: string;
  searchText: string;
  onDataChanged: () => void;
}

const ScenarioCard: React.FC<IScenarioCardProps> = ({
  scenario,
  systemIntents,
  botStructure,
  botStageId,
  botEntryId,
  searchText,
  onDataChanged,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [toggling, setToggling] = useState(false);
  const [scenarioUsagesSearching, setScenarioUsagesSearching] = useState(false);
  const [scenarioUsages, setScenarioUsages] = useState<ScenarioEditionReferenceModel[]>([]);
  const [isDisableConfirmationModalVisible, setIsDisableConfirmationModalVisible] = useState(false);
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);
  const [title, setTitle] = useState(scenario.name);
  const [titleIsEditing, setTitleIsEditing] = useState(false);
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);
  const [scenarioMode, setScenarioMode] = useState(
    getScenarioMode({ interruption: scenario.interruption, cancellation: scenario.cancellation })
  );

  const [scenarioExport, setScenarioExport] = useState(scenarioExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [scenarioImport, setScenarioImport] = useState(scenarioImportDefaultValue);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const scenarioStructure = botStructure.scenarios.find((s) => s.id === scenario.scenarioStructureId);

  const exportStatus = scenarioExport.preparing
    ? IbProgressStatusModalStatus.InProgress
    : scenarioExport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const exportTitle = scenarioExport.preparing
    ? t('Scenario export in progress')
    : scenarioExport.errorMessage
    ? t('An error occurred while exporting the scenario')
    : t('Scenario export was successful');

  const importStatus = scenarioImport.processing
    ? IbProgressStatusModalStatus.InProgress
    : scenarioImport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const importTitle = scenarioImport.processing
    ? t('Scenario import in progress')
    : scenarioImport.errorMessage
    ? t('An error occurred while importing the scenario')
    : t('Scenario import was successful');

  const onTitleInputBlur = async () => {
    setTitleIsEditing(false);

    if (!title) {
      setTitle(scenario.name);
      return;
    }

    if (title === scenario.name) return;

    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.Rename,
        name: title,
      });
      onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Scenario updating error'),
      });
    }
  };

  const onTitleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setTitle(e.target.value);

  const onTitleInputFocus: FocusEventHandler<HTMLInputElement> = (e) => e.target.select();

  const onTitleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      onTitleInputBlur().finally();
    } else if (e.key === Key.Escape) {
      setTitle(scenario.name);
      setTitleIsEditing(false);
    }
  };

  const toggleScenario = async () => {
    setToggling(true);
    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.EnableDisable,
        enabled: !scenario.enabled,
      });
      onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Scenario updating error'),
      });
    }
    setToggling(false);
  };

  const onConfirmDisabling = async () => {
    setIsDisableConfirmationModalVisible(false);
    await toggleScenario();
  };

  const searchScenarioUsages = async () => {
    setScenarioUsagesSearching(true);
    try {
      const response = await botStageApi.getScenarioUsages(botStageId, scenario.scenarioStructureId);
      setScenarioUsages(response.data.scenarios);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Error while searching for scenario usages'),
      });
    }
    setScenarioUsagesSearching(false);
  };

  const scenarioTriggers = getScenarioTriggers(scenarioStructure as DefaultScenarioSchema, systemIntents);

  const onToggleScenario = async () => {
    if (scenario.enabled) {
      await searchScenarioUsages();
      setIsDisableConfirmationModalVisible(true);
      return;
    }

    await toggleScenario();
  };

  const openScenario = () => {
    push(`/inbox/bots/${botEntryId}/scenario/${scenario.scenarioStructureId}`);
  };

  const onDisableConfirmationModalClose = () => setIsDisableConfirmationModalVisible(false);

  const onDeleteConfirmationModalClose = () => setIsDeleteConfirmationModalVisible(false);

  const onDuplicateScenario = async () => {
    try {
      await botStageApi.duplicateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
      });
      onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Scenario duplication error'),
      });
    }
  };

  const onRenameScenario = () => setTitleIsEditing(true);

  const onExportScenario = async () => {
    setScenarioExport({
      ...scenarioExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await botStageApi.runScenarioExport(
        botStageId,
        scenario.scenarioStructureId,
        ScenarioContentFormat.Json
      );

      setScenarioExport({
        ...scenarioExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      setScenarioExport({
        ...scenarioExportDefaultValue,
        errorMessage: getErrorMessage(e as Error),
      });
    }
  };

  const scenarioExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (scenarioExport.requestId !== args?.requestId || !scenarioExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setScenarioExport({
      ...scenarioExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToScenarioExportEvents = () => {
    if (!scenarioExport.requestId || !conn) return;

    conn.on(SCENARIO_EXPORT_FINISHED, scenarioExportEventHandler);

    return () => {
      conn.off(SCENARIO_EXPORT_FINISHED, scenarioExportEventHandler);
    };
  };
  useEffect(subscribeToScenarioExportEvents, [conn, scenarioExport.requestId]);

  const closeExportScenarioModal = () => {
    setExportModalVisible(false);
    setScenarioExport(scenarioExportDefaultValue);
  };

  const onExportModalClose = () => closeExportScenarioModal();

  const onImportScenarioFileUpload = async (file: RcFile, base64Content: string) => {
    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.Import,
        scenarioFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
      setScenarioImport(scenarioImportDefaultValue);
    } catch (e) {
      const message = isInvalidCastError(e as Error)
        ? t('Uploaded file is not a valid scenario description file')
        : getErrorMessage(e as Error);

      setScenarioImport({
        processing: false,
        errorMessage: message,
      });
    }
  };

  const beforeImportScenarioFileUpload = () => {
    setScenarioImport({
      ...scenarioImportDefaultValue,
      processing: true,
    });
    setImportModalVisible(true);
  };

  const closeImportScenarioModal = () => {
    setImportModalVisible(false);
  };

  const onImportModalClose = () => {
    closeImportScenarioModal();
    if (importStatus == IbProgressStatusModalStatus.Success) {
      openScenario();
    }
  };

  const onChangeScenarioSettings = () => setIsSettingsModalVisible(true);

  const onDeleteScenario = async () => {
    await searchScenarioUsages();
    setIsDeleteConfirmationModalVisible(true);
  };

  const onConfirmDeletion = async () => {
    setIsDeleteConfirmationModalVisible(false);
    try {
      await botStageApi.deleteScenario(botStageId, scenario.scenarioStructureId);
      onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Scenario deletion error'),
      });
    }
  };

  const onSaveScenarioSettings = async () => {
    if (scenarioMode === getScenarioMode({ interruption: scenario.interruption, cancellation: scenario.cancellation }))
      return;

    setIsSettingsModalVisible(false);
    try {
      await botStageApi.updateScenario(botStageId, {
        scenarioStructureId: scenario.scenarioStructureId,
        operationType: UpdateScenarioOperationType.ChangeSettings,
        ...getScenarioSettings(scenarioMode),
      });
      onDataChanged();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Scenario updating error'),
      });
    }
  };

  const onSettingsModalClose = () => {
    setScenarioMode(getScenarioMode({ interruption: scenario.interruption, cancellation: scenario.cancellation }));
    setIsSettingsModalVisible(false);
  };

  const onScenarioModeChange = (value: SelectValue) => setScenarioMode(value as ScenarioMode);

  const renderScenarioExportModalContent = () => {
    switch (exportStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Success:
        return (
          <IbTypography>
            <IbTypography.Paragraph>
              <a href={scenarioExport.fileUrl}>{t('Download link')}</a>
            </IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{scenarioExport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  const renderScenarioImportModalContent = () => {
    switch (importStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{scenarioImport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  return (
    <>
      <IbScenarioCard
        key={scenario.scenarioEditionId}
        beforeImportScenarioFileUpload={beforeImportScenarioFileUpload}
        cancellationMode={scenario.cancellation}
        enabled={scenario.enabled}
        interruptionMode={scenario.interruption}
        modifiedOn={scenario.modifiedOn}
        scenarioName={scenario.name}
        searchText={searchText}
        title={title}
        titleIsEditing={titleIsEditing}
        toggling={toggling || scenarioUsagesSearching}
        triggers={scenarioTriggers}
        onChangeScenarioSettings={onChangeScenarioSettings}
        onDeleteScenario={onDeleteScenario}
        onDuplicateScenario={onDuplicateScenario}
        onExportScenario={onExportScenario}
        onImportScenarioFileUpload={onImportScenarioFileUpload}
        onRenameScenario={onRenameScenario}
        onScenarioClick={openScenario}
        onTitleInputBlur={onTitleInputBlur}
        onTitleInputChange={onTitleInputChange}
        onTitleInputFocus={onTitleInputFocus}
        onTitleInputKeyDown={onTitleInputKeyDown}
        onToggleScenario={onToggleScenario}
      />
      <SbModal
        footer={[
          <SbButton key="disable" sbSize="medium" sbType="primary" onClick={onConfirmDisabling}>
            {t('Disable')}
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onDisableConfirmationModalClose}>
            {t('Cancel')}
          </SbButton>,
        ]}
        sbSize="small"
        title={t('Disable scenario confirmation')}
        visible={isDisableConfirmationModalVisible}
        onCancel={onDisableConfirmationModalClose}
        onOk={onConfirmDisabling}
      >
        {!!scenarioUsages.length && (
          <IbTypography>
            <p className="sb-typography__paragraph_lead">
              {t(
                'Turning this scenario off can lead to a wrong bot behavior, because this scenario is being used in other scenarios:'
              )}
              <ul>
                {scenarioUsages.map((s) => (
                  <li key={s.scenarioStructureId}>{s.name}</li>
                ))}
              </ul>
            </p>
          </IbTypography>
        )}
        <div>
          {t('Do you really want to disable scenario')} <b>{scenario.name}</b>?
        </div>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="delete" sbSize="medium" sbType="primary" onClick={onConfirmDeletion}>
            {t('Delete')}
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onDeleteConfirmationModalClose}>
            {t('Cancel')}
          </SbButton>,
        ]}
        sbSize="small"
        title={t('Scenario deletion confirmation')}
        visible={isDeleteConfirmationModalVisible}
        onCancel={onDeleteConfirmationModalClose}
        onOk={onConfirmDeletion}
      >
        {!!scenarioUsages.length && (
          <SbTypography>
            <p className="sb-typography__paragraph_lead">
              {t(
                'Deleting this scenario can lead to a wrong bot behavior, because this scenario is being used in other scenarios:'
              )}
              <ul>
                {scenarioUsages.map((s) => (
                  <li key={s.scenarioStructureId}>{s.name}</li>
                ))}
              </ul>
            </p>
          </SbTypography>
        )}
        <div>
          {t('Do you really want to delete scenario')} <b>{scenario.name}</b>?
        </div>
      </SbModal>
      <SbModal
        footer={[
          <SbButton key="save" sbSize="medium" sbType="primary" onClick={onSaveScenarioSettings}>
            {t('Save')}
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onSettingsModalClose}>
            {t('Cancel')}
          </SbButton>,
        ]}
        sbSize="small"
        title={t('Scenario settings')}
        visible={isSettingsModalVisible}
        width={650}
        onCancel={onSettingsModalClose}
        onOk={onSaveScenarioSettings}
      >
        <Row gutter={[24, 24]}>
          <Col span={8}>
            <SbTypography>
              <h3>{t('Scenario type')}</h3>
              <SbSelect
                options={ScenarioModeOptions}
                sbSize="small"
                sbType="light"
                value={scenarioMode}
                onChange={onScenarioModeChange}
              />
            </SbTypography>
          </Col>
          <Col span={16}>
            <SbPanel sbType="help">
              <SbTypography>
                {scenarioMode === ScenarioMode.Substitute ? (
                  t(
                    'This type of scenario can run regardless of whether another scenario is currently running or not. If interrupted, the current scenario will stop running.'
                  )
                ) : scenarioMode === ScenarioMode.Interrupting ? (
                  t(
                    'This type of scenario can run regardless of whether another scenario is currently running or not. The current scenario will continue to run after this scenario is finished.'
                  )
                ) : (
                  <>
                    {t('This type of scenario cannot be substituting or interrupting to the other running scenarios.')}
                    <br />
                    &nbsp;
                  </>
                )}
              </SbTypography>
            </SbPanel>
          </Col>
        </Row>
      </SbModal>
      <IbProgressStatusModal
        header={exportTitle}
        status={exportStatus}
        visible={exportModalVisible}
        onCancel={onExportModalClose}
      >
        {renderScenarioExportModalContent()}
      </IbProgressStatusModal>
      <IbProgressStatusModal
        closable={importStatus != IbProgressStatusModalStatus.InProgress}
        header={importTitle}
        status={importStatus}
        visible={importModalVisible}
        onCancel={onImportModalClose}
      >
        {renderScenarioImportModalContent()}
      </IbProgressStatusModal>
    </>
  );
};

export default ScenarioCard;
