import React from 'react';
import { List, ListProps } from 'antd';

import './index.less';

const MAIN_CLASS_NAME = 'ib-list';

export type IIbListProps<T> = ListProps<T>;

const IbList: React.FC<IIbListProps<unknown>> = ({ className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <List {...otherProps} className={classes.join(' ')} />;
};

export default IbList;
