import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Row, Table, TablePaginationConfig } from 'antd';
import ReactMarkdown from 'react-markdown';
import { useSetRecoilState } from 'recoil';

import { InstanceModel, PublishStatusState, ReportModel, ReportRowModel } from '../../kb-api';
import { reportKbApi, reportRowKbApi } from '../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE, ReportRowRuleNames } from '../constants';
import { alertsSelectorAdd } from '../recoil/alerts';

export interface IPublishReportProps {
  instance?: InstanceModel | null;
  detailed?: boolean;
}

const PublishReport: React.FC<IPublishReportProps> = ({ instance, detailed = true }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [report, setReport] = useState<ReportModel>();
  const [reportRows, setReportRows] = useState<ReportRowModel[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadReportAsync = async () => {
    if (!instance?.id || !instance.knowledgeBaseId || instance.status?.state !== PublishStatusState.Success) return;
    try {
      const response = await reportKbApi.searchReports(undefined, instance.id, 0, 1);
      setReport(response.data.items?.pop());
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке отчета с метриками',
        error: e,
      });
    }
  };
  const loadReport = () => {
    loadReportAsync();
  };
  useEffect(loadReport, [instance]);

  const loadReportRowsAsync = async () => {
    if (!report?.id || !report.knowledgeBaseId) return;
    try {
      const response = await reportRowKbApi.searchReportRows(
        undefined,
        report.id,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setReportRows(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке отчета с метриками',
        error: e,
      });
    }
  };
  const loadReportRows = () => {
    loadReportRowsAsync();
  };
  useEffect(loadReportRows, [report, pagination.current, pagination.pageSize]);

  if (instance?.status?.state !== PublishStatusState.Success || reportRows.length === 0) {
    return null;
  }

  const columns = [
    {
      render: (_: unknown, item: ReportRowModel) => {
        switch (item.rule) {
          case ReportRowRuleNames.SAME_ANSWER_FULL_MATCH:
            return (
              <span>
                Фраза <b>{item.sourceQuestionText}</b> повторяется в одном ответе
              </span>
            );
          case ReportRowRuleNames.SAME_ANSWER_CLOSE_MATCH:
            return (
              <span>
                Фраза <b>{item.sourceQuestionText}</b> пересекается с фразой <b>{item.targetQuestionText}</b> в одном
                ответе
              </span>
            );
          case ReportRowRuleNames.OTHER_ANSWER_FULL_MATCH:
            return (
              <span>
                Фраза <b>{item.sourceQuestionText}</b> полностью совпадает с фразой <b>{item.targetQuestionText}</b> в
                двух разных ответах
              </span>
            );
          case ReportRowRuleNames.OTHER_ANSWER_CLOSE_MATCH:
            return (
              <span>
                Фраза <b>{item.sourceQuestionText}</b> из одного ответа пересекается с фразой{' '}
                <b>{item.targetQuestionText}</b> из другого ответа
              </span>
            );
          default:
            return null;
        }
      },
    },
  ];

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const renderRowDescription = (item: ReportRowModel) => {
    switch (item.rule) {
      case ReportRowRuleNames.SAME_ANSWER_FULL_MATCH:
      case ReportRowRuleNames.SAME_ANSWER_CLOSE_MATCH:
        return (
          <Row gutter={16}>
            <Col span={24}>
              <Card bordered={false} title="Исходный ответ">
                <ReactMarkdown>{item.sourceAnswerText || ''}</ReactMarkdown>
              </Card>
            </Col>
          </Row>
        );
      case ReportRowRuleNames.OTHER_ANSWER_FULL_MATCH:
      case ReportRowRuleNames.OTHER_ANSWER_CLOSE_MATCH:
        return (
          <Row gutter={16}>
            <Col span={12}>
              <Card bordered={false} title="Исходный ответ">
                <ReactMarkdown>{item.sourceAnswerText || ''}</ReactMarkdown>
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false} title="Ответ совпадающего вопроса">
                <ReactMarkdown>{item.targetAnswerText || ''}</ReactMarkdown>
              </Card>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  const description = detailed ? (
    <Table
      expandRowByClick
      columns={columns}
      dataSource={reportRows}
      expandedRowRender={renderRowDescription}
      pagination={pagination}
      rowKey="id"
      showHeader={false}
      onChange={onTableChange}
    />
  ) : null;

  return (
    <Alert
      showIcon
      description={description}
      message="В ходе диагностики на пересечения выявлены проблемы"
      type="warning"
    />
  );
};

export default PublishReport;
