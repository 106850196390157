import React from 'react';
import { Card } from 'antd';
import './index.less';

import { ListScenarioTemplateModel } from '../../../../api';
import SbIcon, { IconName } from '../common/SbIcon';
import SbTag from '../common/SbTag';
import SbScenarioModeTag from '../SbScenarioModeTag';

interface SbScenarioTemplateCardProps {
  scenarioTemplate: ListScenarioTemplateModel;
  onCardClick: (itemId: string) => void;
  color: string;
}

const SbScenarioTemplateCard: React.FC<SbScenarioTemplateCardProps> = ({ scenarioTemplate, onCardClick, color }) => (
  <Card hoverable className="sb-scenario-template-card" onClick={() => onCardClick(scenarioTemplate.id)}>
    <div className="sb-scenario-template-card__container">
      <div className="sb-scenario-template-card__top-row">
        <h3>{scenarioTemplate.name}</h3>
        {scenarioTemplate.iconPack === 'iconify' && (
          <SbIcon iconName={scenarioTemplate.iconCode as IconName} size={26} />
        )}
      </div>
      <div className="sb-scenario-template-card__description-row">{scenarioTemplate.description}</div>
      <div className="sb-scenario-template-card__tag-row">
        <SbTag color={color} text={scenarioTemplate.botTemplateName || ''} />
        <SbScenarioModeTag cancellation={scenarioTemplate.cancellation} interruption={scenarioTemplate.interruption} />
      </div>
    </div>
  </Card>
);

export default SbScenarioTemplateCard;
