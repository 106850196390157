import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RadioChangeEvent } from 'antd';
import { SelectValue } from 'antd/lib/select';

import { InboxChannelRoutingBotAssignmentType, InboxChannelRoutingBotModel } from '../../../../api';
import IbTypography from '../common/IbTypography';
import { botListSearchSelector, botListSimpleSelector } from '../../recoil';
import IbSwitch from '../common/IbSwitch';
import IbSelect from '../common/IbSelect';
import IbRadioGroup from '../common/IbRadioGroup';
import IbRadio from '../common/IbRadio';

import { BOT_SWITCH_CLASS_NAME, FORM_ITEM_CLASS_NAME } from './const';

interface IBotTabProps {
  bot: InboxChannelRoutingBotModel;
  onChange: (bot: InboxChannelRoutingBotModel) => void;
}

const BotTab: React.FC<IBotTabProps> = ({ bot, onChange }) => {
  const { t } = useTranslation();

  const [botListSearch, setBotListSearch] = useRecoilState(botListSearchSelector);
  const botList = useRecoilValue(botListSimpleSelector);

  let botEntryList = botList.map((b) => b.entry);

  if (bot.botEntry) {
    const botListContainBotFromRouting = botList.some((b) => b.entry.id === bot.botEntry?.id);
    const filteredBotList = botList.filter((b) => b.entry.id !== bot.botEntry?.id);

    botEntryList = botListContainBotFromRouting
      ? [bot.botEntry, ...filteredBotList.map((b) => b.entry)]
      : botListSearch
      ? botList.map((b) => b.entry)
      : [bot.botEntry, ...botList.map((b) => b.entry)];
  }

  const onBotEnabledChange = () =>
    onChange({
      ...bot,
      enabled: !bot.enabled,
    });

  const onBotEntryChange = (value: SelectValue) =>
    onChange({
      ...bot,
      botEntry: botList.find((b) => b.entry.id === value)?.entry,
    });

  const onBotEntrySearch = (searchValue: string) => setBotListSearch(searchValue || '');

  const onAssignmentTypeChange = (e: RadioChangeEvent) =>
    onChange({
      ...bot,
      assignmentType: e.target.value as InboxChannelRoutingBotAssignmentType,
    });

  return (
    <>
      <div className={FORM_ITEM_CLASS_NAME}>
        <div className={BOT_SWITCH_CLASS_NAME}>
          <IbSwitch checked={bot.enabled} onChange={onBotEnabledChange} />
          <IbTypography.Paragraph type="secondary">{bot.enabled ? t('Enabled') : t('Disabled')}</IbTypography.Paragraph>
        </div>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Select bot')}</IbTypography.Paragraph>
        <IbSelect
          allowClear
          showSearch
          options={botEntryList.map((b) => ({ value: b.id, label: b.name }))}
          placeholder={t('Select or start writing')}
          value={bot.botEntry?.id}
          onChange={onBotEntryChange}
          onSearch={onBotEntrySearch}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Assign to bot')}</IbTypography.Paragraph>
        <IbRadioGroup value={bot.assignmentType} onChange={onAssignmentTypeChange}>
          <IbRadio value={InboxChannelRoutingBotAssignmentType.Manually}>{t('Manually')}</IbRadio>
          <IbRadio value={InboxChannelRoutingBotAssignmentType.Always}>{t('Always')}</IbRadio>
          <IbRadio value={InboxChannelRoutingBotAssignmentType.NonWorkTime}>{t('Out of hours')}</IbRadio>
        </IbRadioGroup>
      </div>
    </>
  );
};

export default BotTab;
