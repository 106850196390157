import React from 'react';
import { Tooltip } from 'antd';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const PLACEMENT_DEFAULT = undefined;
const TITLE_DEFAULT = undefined;

const MAIN_CLASS_NAME = 'ib-tooltip';
const OVERLAY_CLASS_NAME = 'ib-tooltip-overlay';

export interface IIbTooltipProps {
  className?: string;
  overlayClassName?: string;
  placement?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
  title?: React.ReactNode;
}

const IbTooltip: React.FC<IIbTooltipProps> = ({ children, className, overlayClassName, placement, title }) => {
  placement = getDefaultIfUndefined(placement, PLACEMENT_DEFAULT);
  title = getDefaultIfUndefined(title, TITLE_DEFAULT);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  const overlayClasses = [OVERLAY_CLASS_NAME];
  overlayClassName && overlayClasses.push(overlayClassName);

  return (
    <Tooltip overlayClassName={overlayClasses.join(' ')} placement={placement} title={title}>
      <div className={classes.join(' ')}>{children}</div>
    </Tooltip>
  );
};

export default IbTooltip;
