export const isSequencesEqual = <T>(a?: T[], b?: T[]): boolean => {
  if (a === b) return true;
  if (!a?.length && !b?.length) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }

  return true;
};

export const getUndefinedIfEmpty = <T>(array?: T[]): T[] | undefined => (array?.length ? array : undefined);

export const sortArrayByField = <T>(array: T[], field: keyof T, order: 'asc' | 'desc' = 'asc'): T[] => {
  const sortedArray = [...array];
  return sortedArray.sort((a, b) => {
    const valueA = a[field];
    const valueB = b[field];

    let comparisonResult = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    if (order === 'desc') {
      comparisonResult *= -1;
    }

    return comparisonResult;
  });
};
