import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Select, Input, InputNumber, Checkbox, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { useSetRecoilState } from 'recoil';

import { AgentProactiveDialogModel } from '../../api';
import { agentApi } from '../apis';
import { AlertTypes } from '../constants';
import { getStageNameByType } from '../utils/stringUtil';
import { alertsSelectorAdd } from '../recoil/alerts';

const { TextArea } = Input;

export interface IProactiveDialogData {
  agentId: string;
  agentStageId: string;
  dialogId: string;
  dialogName: string;
  properties: { [key: string]: { type?: string | null; defaultValue?: string | number | boolean | null } };
}

export interface IProactiveDialogSelectProps {
  onProactiveDialogDataChange: (proactiveDialogData: IProactiveDialogData) => void;
}

interface IProactiveDialogOption {
  key: string;
  agentId: string;
  agentStageId: string;
  dialogId: string;
  dialogName: string;
  agentName: string;
  properties: {
    [key: string]: { type?: string | null; defaultValue?: string | null; displayName?: string | null };
  } | null;
}

const ProactiveDialogSelect: React.FC<IProactiveDialogSelectProps> = ({ onProactiveDialogDataChange = () => {} }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [agentsLoading, setAgentsLoading] = useState(false);
  const [proactiveDialogs, setProactiveDialogs] = useState([] as AgentProactiveDialogModel[]);
  const [dialogOptions, setDialogOptions] = useState([] as IProactiveDialogOption[]);
  const [selectedDialogKey, setSelectedDialogKey] = useState('');
  const [selectedDialogProperties, setSelectedDialogProperties] = useState(
    {} as {
      [key: string]: { type?: string | null; value?: string | number | boolean | null; displayName?: string | null };
    }
  );

  const loadAgentsAsync = async () => {
    setAgentsLoading(true);
    try {
      const response = await agentApi.searchProactiveDialogs();
      setProactiveDialogs(response.data || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка доступных ботов',
        error: e,
      });
    }
    setAgentsLoading(false);
  };
  const loadAgents = () => {
    loadAgentsAsync();
  };
  useEffect(loadAgents, []);

  const fillDialogsList = () => {
    const dialogs = proactiveDialogs.map((dialog) => {
      return {
        key: `${dialog.agentStageId}_${dialog.id}`,
        agentId: dialog.agentId || '',
        agentStageId: dialog.agentStageId || '',
        dialogId: dialog.id || '',
        dialogName: `${dialog.name || ''} (${getStageNameByType(dialog.agentStageType)})`,
        agentName: dialog.agentName || '',
        properties: dialog.properties || {},
      };
    });

    setDialogOptions(dialogs);
  };

  useEffect(fillDialogsList, [proactiveDialogs]);

  const fillPropertiesList = () => {
    const selectedDialog = dialogOptions.find((d) => d.key === selectedDialogKey) || ({} as IProactiveDialogOption);

    const dialogProperties = selectedDialog
      ? selectedDialog.properties ?? {}
      : ({} as {
          [key: string]: {
            type?: string | null;
            value?: string | number | boolean | null;
            displayName?: string | null;
          };
        });

    Object.entries(dialogProperties).forEach(([, value]) => {
      value.value = value.defaultValue ?? '';
      switch (value.type) {
        case 'number':
          value.value = +value.value;
          break;
        case 'boolean':
          value.value = Boolean(value.value);
      }
    });

    setSelectedDialogProperties(dialogProperties);
    onProactiveDialogDataChange({
      agentId: selectedDialog?.agentId,
      agentStageId: selectedDialog?.agentStageId,
      dialogId: selectedDialog?.dialogId,
      dialogName: selectedDialog?.dialogName,
      properties: dialogProperties,
    });
  };

  useEffect(fillPropertiesList, [selectedDialogKey]);

  const handleDialogChanged = (value: string) => {
    setSelectedDialogKey(value);
  };

  const handlePropertyValueChanged = (key: string, value: string | number | boolean) => {
    selectedDialogProperties[key].value = value;
    const selectedDialog = dialogOptions.find((d) => d.key === selectedDialogKey) || ({} as IProactiveDialogOption);
    onProactiveDialogDataChange({
      agentId: selectedDialog?.agentId,
      agentStageId: selectedDialog?.agentStageId,
      dialogId: selectedDialog?.dialogId,
      dialogName: selectedDialog?.dialogName,
      properties: selectedDialogProperties,
    });
  };

  const getPropertyField = (key: string, value: { type?: string | null; value?: string | number | boolean | null }) => {
    switch (value.type) {
      case 'string':
        return (
          <Input defaultValue={String(value.value)} onChange={(e) => handlePropertyValueChanged(key, e.target.value)} />
        );
      case 'text':
        return (
          <TextArea
            defaultValue={String(value.value)}
            rows={6}
            onChange={(e) => handlePropertyValueChanged(key, e.target.value)}
          />
        );
      case 'integer':
      case 'number':
        return (
          <InputNumber
            defaultValue={+(value?.value || 0)}
            precision={value.type === 'integer' ? 0 : undefined}
            onChange={(e) => handlePropertyValueChanged(key, e ?? 0)}
          />
        );
      case 'boolean':
        return (
          <Checkbox
            defaultChecked={Boolean(value?.value || false)}
            onChange={(e) => handlePropertyValueChanged(key, e.target.checked)}
          >
            {key}
          </Checkbox>
        );
      case 'datetime':
        return (
          <DatePicker
            showTime
            defaultValue={value?.value ? moment(value.value + '') : undefined}
            locale={locale}
            onChange={(moment) => handlePropertyValueChanged(key, moment?.format() ?? '')}
          />
        );
      default:
        return <p>Unknown type</p>;
    }
  };

  return (
    <Fragment>
      <Row className="ant-form-item">
        <Col>
          <Select
            loading={agentsLoading}
            placeholder="Тип рассылки"
            value={selectedDialogKey}
            onChange={handleDialogChanged}
          >
            {dialogOptions.map((d) => (
              <Select.Option key={d.key} value={d.key}>
                {`${d.agentName} - ${d.dialogName}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      {Object.entries(selectedDialogProperties).length > 0 && <h3>Параметры</h3>}
      {Object.entries(selectedDialogProperties).map(([key, value]) => (
        <Row key={key} className="ant-form-item">
          {value.type !== 'boolean' && (
            <Col className=".ant-form-item-label">
              <h4>{`${value.displayName || key}`}</h4>
            </Col>
          )}
          <Col>
            <div>{getPropertyField(key, value)}</div>
          </Col>
        </Row>
      ))}
    </Fragment>
  );
};

export default ProactiveDialogSelect;
