import React, { ReactNode } from 'react';
import { SpinProps, Table } from 'antd';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { ColumnType } from 'antd/lib/table/interface';
import { TableLayout } from 'rc-table/lib/interface';

import './index.less';

import SbScroll from '../SbScroll';

import EditableRow from './EditableRow';
import EditableCell from './EditableCell';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
interface ISbColumnType<RecordType extends object = any> extends ColumnType<RecordType> {
  editable?: boolean;
  onSave?: (record: RecordType) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
interface ISbTableProps<RecordType extends object = any> {
  dataSource?: RcTableProps<RecordType>['data'];
  columns?: ISbColumnType<RecordType>[];
  rowKey?: string;
  selectedRowKeys?: string[];
  header?: boolean;
  editable?: boolean;
  height?: number;
  onSelectRowKeys?: (rowKeys: string[]) => void;
  focusRowKey?: string;
  tableLayout?: TableLayout;
  emptyText?: ReactNode;
  scrollEnabled?: boolean;
  loading?: boolean | SpinProps;
}

const HEADER_HEIGHT = 48;

const SbTable: React.FC<ISbTableProps> = ({
  dataSource = [],
  columns = [],
  rowKey = 'key',
  selectedRowKeys = [],
  header = true,
  editable = false,
  height,
  onSelectRowKeys = () => {},
  focusRowKey,
  tableLayout,
  emptyText,
  scrollEnabled = true,
  loading,
}) => {
  const rowSelection = {
    selectedRowKeys,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRow = (record: any) => {
    return {
      onClick: () => {
        const recordKey = record[rowKey];
        const newSelectedRowKeys = selectedRowKeys.includes(recordKey) ? [] : [recordKey];
        onSelectRowKeys(newSelectedRowKeys);
      },
    };
  };

  const headerHeight = header ? HEADER_HEIGHT : 0;

  const mappedColumns = editable
    ? columns.map(
        (column) =>
          ({
            ...column,
            // eslint-disable-next-line @typescript-eslint/ban-types
            onCell: (record: object) => ({
              record,
              editable: column.editable,
              dataIndex: column.dataIndex,
              title: column.title,
              onSave: column.onSave,
              focusRowKey,
              rowKey,
            }),
          } as ISbColumnType)
      )
    : columns;

  const tableComponents = {
    header: {
      wrapper: () => null,
    },
    body: editable
      ? {
          row: EditableRow,
          cell: EditableCell,
        }
      : undefined,
  };

  const content =
    emptyText !== undefined && !dataSource?.length ? (
      <div className="sb-table__empty">{emptyText}</div>
    ) : (
      <Table
        columns={mappedColumns}
        components={tableComponents}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        rowKey={rowKey}
        rowSelection={rowSelection}
        tableLayout={tableLayout}
        onRow={onRow}
      />
    );

  return (
    <div className="sb-table">
      {header ? (
        <Table
          columns={columns}
          components={{
            body: {
              wrapper: () => null,
            },
          }}
          pagination={false}
          tableLayout={tableLayout}
        />
      ) : null}
      {scrollEnabled ? <SbScroll height={height ? height - headerHeight : undefined}>{content}</SbScroll> : content}
    </div>
  );
};

export default SbTable;
