import React, { memo, useEffect, useState, KeyboardEvent } from 'react';
import { Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useSetRecoilState } from 'recoil';

import './index.less';

import Messages from '../../Messages';
import {
  MenuActionSchema,
  MenuButtonSchema,
  MenuOptionsFillingMode,
  DefaultActionGroupSchema,
  SchemaKind,
} from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import AddButton from '../../common/AddButton';
import { currentScenarioStructureSelector, selectedEntitySelector } from '../../../../../recoil/scenarioStructure';
import { deleteBindingWithReferences, generateId, getElementId, tryGetElementById } from '../../../utils';
import MenuButton from '../../MenuButton';
import EditorSelect from '../../common/EditorSelect';

interface IMenuActionProps {
  index: number;
  action: MenuActionSchema;
  group: DefaultActionGroupSchema;
}

const getTitleByMenuOptionsFillingMode = (fillingMode: MenuOptionsFillingMode) => {
  switch (fillingMode) {
    case MenuOptionsFillingMode.Auto:
      return 'Авто';
    case MenuOptionsFillingMode.AutoByCategory:
      return 'Авто с категорией';
    case MenuOptionsFillingMode.Manual:
      return 'Настраиваемые';
    default:
      return fillingMode as string;
  }
};

const MenuAction: React.FC<IMenuActionProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const setSelectedEntity = useSetRecoilState(selectedEntitySelector);
  const [category, setCategory] = useState(action.category);

  const resetState = () => {
    setCategory(action.category);
  };

  useEffect(resetState, [action]);

  const onOptionsFillingModeChange = (newMode: MenuOptionsFillingMode) => () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id) as MenuActionSchema;

    if ((newMode as MenuOptionsFillingMode) !== MenuOptionsFillingMode.Manual) {
      foundAction.buttons.forEach((b) => {
        deleteBindingWithReferences(newScenarioStructure, b.outputBindingId);
      });
      foundAction.buttons = [];
    }

    foundAction.optionsFillingMode = newMode;
    foundAction.category = undefined;
    setCategory(undefined);

    setScenarioStructure(newScenarioStructure);
  };

  const onDeleteMenuButton = (button: MenuButtonSchema) => () => {
    if (!scenarioStructure) return;

    const newButtons = action.buttons.filter((b) => b.id !== button.id);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as MenuActionSchema).buttons = newButtons;

    deleteBindingWithReferences(newScenarioStructure, button.outputBindingId);

    setScenarioStructure(newScenarioStructure);
  };

  const addButtonHandler = () => {
    if (!scenarioStructure) return;

    const newButtons = [...action.buttons];
    const newButton: MenuButtonSchema = {
      id: generateId('BTN'),
      $kind: SchemaKind.MenuButton,
      value: 'Новый вариант',
      outputBindingId: null,
    };
    newButtons.push(newButton);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as MenuActionSchema).buttons = newButtons;

    setSelectedEntity(newButton);
    setScenarioStructure(newScenarioStructure);
  };

  const onCategoryBlur = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id) as MenuActionSchema;
    if (foundAction.category === category) return;
    foundAction.category = category;
    setScenarioStructure(newScenarioStructure);
  };

  const onCategoryInputPressEnter = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget?.blur();

  const menuActionContent = (
    <Menu>
      <Menu.Item key="auto" onClick={onOptionsFillingModeChange(MenuOptionsFillingMode.Auto)}>
        {getTitleByMenuOptionsFillingMode(MenuOptionsFillingMode.Auto)}
      </Menu.Item>
      <Menu.Item key="autoByCategory" onClick={onOptionsFillingModeChange(MenuOptionsFillingMode.AutoByCategory)}>
        {getTitleByMenuOptionsFillingMode(MenuOptionsFillingMode.AutoByCategory)}
      </Menu.Item>
      <Menu.Item key="manual" onClick={onOptionsFillingModeChange(MenuOptionsFillingMode.Manual)}>
        {getTitleByMenuOptionsFillingMode(MenuOptionsFillingMode.Manual)}
      </Menu.Item>
    </Menu>
  );

  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="list-two" size={20} />}
      index={index}
      title="Показать меню"
    >
      <div className="menu-action">
        <Messages action={action} group={group} placeholder="Добавьте текст сообщения" />
        <div className="menu-action__mode-settings">
          <div className="menu-action__mode-settings__title">
            <SbIcon iconName="layout-two" size={16} />
            <span>Элементы</span>
          </div>
          <EditorSelect
            isValid
            id={getElementId(action.id, 'type')}
            menuContent={menuActionContent}
            title={getTitleByMenuOptionsFillingMode(action.optionsFillingMode)}
          />
          {action.optionsFillingMode === MenuOptionsFillingMode.AutoByCategory && (
            <SbInput
              placeholder="Категория"
              value={category ?? ''}
              onBlur={onCategoryBlur}
              onChange={setCategory}
              onPressEnter={onCategoryInputPressEnter}
            />
          )}
        </div>
        <div className="user-answer">
          {action.optionsFillingMode === MenuOptionsFillingMode.Manual &&
            action.buttons.map((button) => (
              <MenuButton key={button.id} button={button} group={group} onDelete={onDeleteMenuButton(button)} />
            ))}
          {action.optionsFillingMode === MenuOptionsFillingMode.Manual && (
            <AddButton buttonHandler={addButtonHandler} title="Добавить кнопку" />
          )}
        </div>
      </div>
    </ActionWrapper>
  );
};

export default memo(MenuAction);
