import React, { MouseEventHandler, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { Elma365FormPropertySchema, VariableSchema } from '../../../../../../api';
import { currentScenarioStructureSelector, variableUsagesSelector } from '../../../../../recoil/scenarioStructure';
import SbTooltip from '../../../../../simple-bot/components/common/SbTooltip';
import VariableSelector from '../../VariableSelector';
import { getFormPropertyType } from '../../../../../simple-bot/utils/elma365';
import { VARIABLE_TAG_COLOR_CAUTION, VARIABLE_TAG_COLOR_DEFAULT } from '../../../../../simple-bot/const';
import SbTag from '../../../../../simple-bot/components/common/SbTag';
import { IndexedStructure } from '../../../../../simple-bot/utils/indexation';
import { validateEntities } from '../../../../../simple-bot/utils/validation';
import SbTable from '../../../../../simple-bot/components/common/SbTable';
import { ValidationRuleCodes } from '../../../constants';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

const FORM_PROPERTIES_CLASS_NAME = 'elma365-form-properties';
const PROPERTY_CLASS_NAME = `${FORM_PROPERTIES_CLASS_NAME}__property`;
const PROPERTY_NAME_CLASS_NAME = `${PROPERTY_CLASS_NAME}__name`;
const PROPERTY_TOOLTIP_CLASS_NAME = `${PROPERTY_CLASS_NAME}__tooltip`;
const PROPERTY_VARIABLE_CLASS_NAME = `${PROPERTY_CLASS_NAME}__variable`;
const PROPERTY_VARIABLE_CONFIGURE_CLASS_NAME = `${PROPERTY_VARIABLE_CLASS_NAME}__configure`;

export enum FormPropertiesParent {
  PROCESS = 'PROCESS',
  APPLICATION = 'APPLICATION',
}

interface IElma365FormPropertiesProps {
  formPropertiesParent: FormPropertiesParent;
  formProperties: Elma365FormPropertySchema[];
  onChange: (formProperties: Elma365FormPropertySchema[]) => void;
}

const Elma365FormProperties: React.FC<IElma365FormPropertiesProps> = ({
  formPropertiesParent,
  formProperties,
  onChange,
}) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const [openedTooltip, setOpenedTooltip] = useState<string>();

  const variables = scenarioStructure?.variables || [];

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const validateProperty = (property: Elma365FormPropertySchema) => {
    const indexedStructure = new IndexedStructure();
    variables.forEach((v) => indexedStructure.indexEntitySchema(v));
    indexedStructure.indexEntitySchema(property);
    return validateEntities(indexedStructure, [property]);
  };

  const trySaveVariables = (newVariables?: VariableSchema[]) => {
    if (Array.isArray(newVariables) && scenarioStructure) {
      const newScenarioStructure = cloneDeep(scenarioStructure);
      newScenarioStructure.variables = newVariables;
      setScenarioStructure(newScenarioStructure);
    }
  };

  const onVariablesSelectorChange = (formPropertyCode: string) => (
    newVariables?: VariableSchema[],
    selectedVariable?: VariableSchema
  ) => {
    setOpenedTooltip(undefined);

    const newFormProperties = cloneDeep(formProperties);
    const formProperty = newFormProperties.find((p) => p.code === formPropertyCode);
    if (formProperty && selectedVariable) {
      formProperty.variableId = selectedVariable.id;
      onChange(newFormProperties);
    }

    trySaveVariables(newVariables);
  };

  const onVariableDelete = (formPropertyCode: string): MouseEventHandler<HTMLDivElement> => (e) => {
    e.stopPropagation();
    setOpenedTooltip(undefined);

    const newFormProperties = cloneDeep(formProperties);
    const formProperty = newFormProperties.find((p) => p.code === formPropertyCode);
    if (formProperty) {
      formProperty.variableId = undefined;
      onChange(newFormProperties);
    }
  };

  const onVariablesSelectorSettingsModalOpen = () => setOpenedTooltip(undefined);

  const onTooltipVisibleChange = (tooltipName: string) => (value: boolean) =>
    setOpenedTooltip(value ? tooltipName : undefined);

  const tableColumns = [
    {
      title: formPropertiesParent === FormPropertiesParent.PROCESS ? 'Поле процесса' : 'Поле приложения',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, p: Elma365FormPropertySchema) => {
        const issueList = validateProperty(p).getVisibleIssueList();
        const requiredIssue = issueList.find(
          (issue) => issue.ruleResult.code === ValidationRuleCodes.Elma365FormPropertyVariableRequired
        );
        const matchesIssue = issueList.find(
          (issue) => issue.ruleResult.code === ValidationRuleCodes.Elma365FormPropertyVariableMatches
        );

        return (
          <span className={PROPERTY_NAME_CLASS_NAME}>
            <span title={p.name}>{p.name}</span>
            {p.required && <span className="scenario-editor-required-field">*</span>}
            {!!requiredIssue && (
              <SbTooltip placement="right" title={requiredIssue.ruleResult.message}>
                <SbIcon className="scenario-editor-validation-warning" iconName="attention" size={16} />
              </SbTooltip>
            )}
            {!!matchesIssue && (
              <SbTooltip placement="right" title={matchesIssue.ruleResult.message}>
                <SbIcon className="scenario-editor-validation-caution" iconName="attention" size={16} />
              </SbTooltip>
            )}
          </span>
        );
      },
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      render: (_: string, p: Elma365FormPropertySchema) => getFormPropertyType(p),
    },
    {
      title: 'Имя переменной бота',
      dataIndex: 'variable',
      key: 'variable',
      render: (_: string, p: Elma365FormPropertySchema) => {
        const variable = p.variableId ? variables.find((v) => v.id === p.variableId) : undefined;
        const issueList = validateProperty(p).getVisibleIssueList();
        const matchesIssue = issueList.find(
          (issue) => issue.ruleResult.code === ValidationRuleCodes.Elma365FormPropertyVariableMatches
        );

        return (
          <SbTooltip
            overlayClassName={PROPERTY_TOOLTIP_CLASS_NAME}
            placement="right"
            title={
              <VariableSelector
                getVariableUsages={getVariableUsages}
                variables={variables}
                onChange={onVariablesSelectorChange(p.code)}
                onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
              />
            }
            trigger={['click']}
            visible={openedTooltip === p.code}
            onVisibleChange={onTooltipVisibleChange(p.code)}
          >
            <div className={PROPERTY_VARIABLE_CLASS_NAME}>
              {variable ? (
                <SbTag
                  color={matchesIssue ? VARIABLE_TAG_COLOR_CAUTION : VARIABLE_TAG_COLOR_DEFAULT}
                  sbSize="small"
                  text={variable.name}
                  onDelete={onVariableDelete(p.code)}
                />
              ) : (
                <div className={PROPERTY_VARIABLE_CONFIGURE_CLASS_NAME}>Выбрать переменную</div>
              )}
            </div>
          </SbTooltip>
        );
      },
    },
  ];

  return (
    <div className={FORM_PROPERTIES_CLASS_NAME}>
      <SbTable
        columns={tableColumns}
        dataSource={formProperties}
        emptyText="Параметры отсутствуют"
        rowKey="code"
        scrollEnabled={false}
        tableLayout="fixed"
      />
    </div>
  );
};

export default Elma365FormProperties;
