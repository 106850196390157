import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Menu, Row, TablePaginationConfig, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router';
import { useAsync } from 'react-async-hook';
import { useDebounce } from 'usehooks-ts';

import './index.less';

import { AgentModel, AgentStageAccountModel, AgentStageAccountSortDirection } from '../../../../../api';
import { AlertTypes, Channels, CHANNEL_STATUS_UPDATED, DEFAULT_PAGE_SIZE } from '../../../../constants';
import { agentApi, agentStageAccountApi, botApi } from '../../../../apis';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbButton from '../../../components/common/SbButton';
import SbIcon, { IconName } from '../../../components/common/SbIcon';
import SbSpin from '../../../components/common/SbSpin';
import SbScroll from '../../../components/common/SbScroll';
import { hubConnections } from '../../../../utils/socketsUtil';
import AddChannelButton from '../../bots/SimpleBotCard/AddChannelButton';
import EditChannelModal from '../../bots/SimpleBotCard/EditChannelModal';
import SbContextMenu from '../../../components/common/SbContextMenu';
import SbInput from '../../../components/common/SbInput';
import SbPagination from '../../../components/common/SbPagination';

import ChannelListItem from './ChannelListItem';
import ChannelDeleteConfirmModal from './ChannelDeleteConfirmModal';

enum CurrentModal {
  NONE = 'NONE',
  ADD_OR_EDIT = 'ADD_OR_EDIT',
  DELETE_CONFIRM = 'DELETE_CONFIRM',
}

const SEARCH_DELAY = 200; //ms

const ChannelsCard: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const scrollContainer = useRef<HTMLElement>();

  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState<AgentModel>();
  const [channels, setChannels] = useState<AgentStageAccountModel[]>([]);
  const [channelsTotalCount, setChannelsTotalCount] = useState<number>();
  const [selectedChannel, setSelectedChannel] = useState<AgentStageAccountModel>();
  const [sortMode, setSortMode] = useState<AgentStageAccountSortDirection>(
    AgentStageAccountSortDirection.StatusDescending
  );
  const [currentModal, setCurrentModal] = useState(CurrentModal.NONE);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const [searchText, setSearchText] = useState('');
  const [addChannelModalSelectedChannelType, setAddChannelModalSelectedChannelType] = useState<Channels>();

  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const loadAllBotChannelsAsync = async () => {
    if (!agent) {
      setChannelsTotalCount(undefined);
      return;
    }

    try {
      const prodChannelsResponse = await agentStageAccountApi.searchAgentStageAccounts(
        undefined,
        agent.productionAgent.id,
        undefined,
        AgentStageAccountSortDirection.NameAscending,
        0,
        DEFAULT_PAGE_SIZE
      );
      setChannelsTotalCount(prodChannelsResponse.data.totalItemCount ?? undefined);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadAllBotChannels = () => {
    loadAllBotChannelsAsync().finally();
  };
  useEffect(loadAllBotChannels, [agent]);

  const loadChannelsDataAsync = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      const bot = botResponse.data;
      const agentResponse = await agentApi.getAgent(bot.entry.agentId);
      const prodChannelsResponse = await agentStageAccountApi.searchAgentStageAccounts(
        undefined,
        agentResponse.data?.productionAgent.id,
        deferredSearchText,
        sortMode,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );

      setAgent(agentResponse.data);
      setPagination({ ...pagination, total: prodChannelsResponse.data.totalItemCount || 0 });
      setChannels(prodChannelsResponse.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadChannelsData = () => {
    setLoading(true);
    loadChannelsDataAsync().finally();
  };
  useEffect(loadChannelsData, [id, pagination.current, pagination.pageSize, sortMode, deferredSearchText]);

  const channelUpdatedEventHandler = (args: { agentId: string }) => {
    if (args.agentId === agent?.id) {
      loadChannelsDataAsync().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
    return () => conn.off(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
  };
  useEffect(subscribe, [conn, agent]);

  const cancelModals = () => {
    setCurrentModal(CurrentModal.NONE);
    setSelectedChannel(undefined);
  };

  const onBackButtonClick = () => push(`/simple-bots/${id}`);

  const onAddChannel = (channelType: Channels) => {
    setAddChannelModalSelectedChannelType(channelType);
    setSelectedChannel(undefined);
    setCurrentModal(CurrentModal.ADD_OR_EDIT);
  };

  const onEditChannelModalClose = () => !loading && cancelModals();
  const onDeleteChannelModalClose = () => !loading && cancelModals();

  const onDataChanged = () => {
    cancelModals();
    setLoading(true);
    loadChannelsDataAsync().finally();
  };

  const onEditChannel = (channel: AgentStageAccountModel) => {
    if (loading) return;
    setAddChannelModalSelectedChannelType(undefined);
    setSelectedChannel(channel);
    setCurrentModal(CurrentModal.ADD_OR_EDIT);
  };

  const onDeleteChannel = (channel: AgentStageAccountModel) => {
    if (loading) return;
    setSelectedChannel(channel);
    setCurrentModal(CurrentModal.DELETE_CONFIRM);
  };

  const onSearchChange = (value: string) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };

  const renderSortingMenuItemChecked = (itemSortMode: AgentStageAccountSortDirection) => {
    return itemSortMode === sortMode ? <SbIcon iconName="check-small" /> : <div style={{ width: 24 }} />;
  };

  const onSortingModeChange = (newSortMode: AgentStageAccountSortDirection) => {
    setSortMode(newSortMode);
    setPagination({ ...pagination, current: 1 });
  };

  const onNameAscendingClick = () => {
    onSortingModeChange(AgentStageAccountSortDirection.NameAscending);
  };

  const onNameDescendingClick = () => {
    onSortingModeChange(AgentStageAccountSortDirection.NameDescending);
  };

  const onStatusDescendingClick = () => {
    onSortingModeChange(AgentStageAccountSortDirection.StatusDescending);
  };

  const sortingMenuContent = (
    <Menu>
      <Menu.Item
        key="sort-by-name-ascending"
        icon={renderSortingMenuItemChecked(AgentStageAccountSortDirection.NameAscending)}
        onClick={onNameAscendingClick}
      >
        По названию от А до Я
      </Menu.Item>
      <Menu.Item
        key="sort-by-name-descending"
        icon={renderSortingMenuItemChecked(AgentStageAccountSortDirection.NameDescending)}
        onClick={onNameDescendingClick}
      >
        По названию от Я до А
      </Menu.Item>
      <Menu.Item
        key="sort-by-status-descending"
        icon={renderSortingMenuItemChecked(AgentStageAccountSortDirection.StatusDescending)}
        onClick={onStatusDescendingClick}
      >
        По статусу
      </Menu.Item>
    </Menu>
  );

  const getCurrentSortingIconName: () => IconName = () => {
    switch (sortMode) {
      case AgentStageAccountSortDirection.NameAscending:
        return 'alphabetical-sorting';
      case AgentStageAccountSortDirection.NameDescending:
        return 'alphabetical-sorting-two';
      case AgentStageAccountSortDirection.StatusDescending:
        return 'checklist';
      default:
        return 'sort-amount-down';
    }
  };

  const onPaginationChange = (page: number) => setPagination({ ...pagination, current: page });

  const renderListContent = () => {
    if (!loading && !channels.length && !searchText) {
      return (
        <div className="sb-channels-card__content__empty-results">
          <h4>Нет подключенных каналов</h4>
          <p>
            На данный момент у вас нет ни одного
            <br />
            подключенного к боту мессенджера.
          </p>
          <AddChannelButton buttonType="secondary" onAddChannel={onAddChannel} />
        </div>
      );
    }

    if (!loading && !channels.length && searchText) {
      return (
        <div className="sb-channels-card__content__empty-results">
          <h4>Ничего не найдено</h4>
          <p>Нет каналов, удовлетворяющих условиям поиска</p>
        </div>
      );
    }

    return (
      <SbScroll containerRefElement={scrollContainer}>
        <div className="sb-channels-card__content__list-row__list">
          {channels.map((item) => {
            return (
              <ChannelListItem
                key={item.id}
                channel={item}
                onDataChanged={onDataChanged}
                onDelete={onDeleteChannel}
                onEdit={onEditChannel}
              />
            );
          })}
          {(pagination?.total ?? 0) > DEFAULT_PAGE_SIZE && (
            <Row align="middle" className="sb-channels-card__content__list-row__list__pagination" justify="center">
              <Col>
                <SbPagination
                  current={pagination.current}
                  defaultCurrent={1}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  pageSize={DEFAULT_PAGE_SIZE}
                  showSizeChanger={false}
                  total={pagination.total}
                  onChange={onPaginationChange}
                />
              </Col>
            </Row>
          )}
        </div>
      </SbScroll>
    );
  };

  return (
    <div className="sb-channels-card">
      <div className="sb-channels-card__header">
        <div className="sb-channels-card__header__left">
          <SbButton icon={<SbIcon iconName="left" />} sbType="icon-secondary-32" onClick={onBackButtonClick} />
          <div className="sb-channels-card__header__left__title">
            <Typography.Title ellipsis level={3}>
              Мессенджеры
            </Typography.Title>
            <Typography.Title ellipsis className="sb-channels-card__header__left__title__subtitle" level={4}>
              {channelsTotalCount !== undefined && <>{channelsTotalCount} подключено</>}
            </Typography.Title>
          </div>
        </div>
      </div>
      <div className="sb-channels-card__content">
        <Row align="middle" className="sb-channels-card__content__controls">
          <Col className="col-1">
            <Row align="middle" wrap={false}>
              <Col>
                <AddChannelButton buttonType="primary" onAddChannel={onAddChannel} />
              </Col>
            </Row>
          </Col>
          <Col className="col-1 sb-channels-card__content__controls__filter" flex="auto">
            <SbInput placeholder="Поиск по названию" sbSize="small" value={searchText} onChange={onSearchChange} />
          </Col>
          <Col className="col-1">
            <SbContextMenu menuContent={sortingMenuContent}>
              <div className="sb-channels-card__content__controls__sort">
                <SbButton icon={<SbIcon iconName={getCurrentSortingIconName()} />} sbType="icon-primary-48" />
              </div>
            </SbContextMenu>
          </Col>
        </Row>
        <Row className="sb-channels-card__content__list-row" wrap={false}>
          <Col span={24}>{renderListContent()}</Col>
          {loading && <SbSpin />}
        </Row>
        {agent && (
          <EditChannelModal
            agent={agent}
            channel={selectedChannel}
            channelType={addChannelModalSelectedChannelType}
            visible={currentModal === CurrentModal.ADD_OR_EDIT}
            onClose={onEditChannelModalClose}
            onDataChanged={onDataChanged}
          />
        )}

        <ChannelDeleteConfirmModal
          channel={selectedChannel}
          visible={currentModal === CurrentModal.DELETE_CONFIRM}
          onCancel={onDeleteChannelModalClose}
          onDataChanged={onDataChanged}
        />
      </div>
    </div>
  );
};

export default ChannelsCard;
