import React, { MouseEventHandler, useRef, useState } from 'react';
import { Menu } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import './index.less';

import { DefaultActionGroupSchema, MainCaseActionSchema } from '../../../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  selectedEntitySelector,
} from '../../../../../../recoil/scenarioStructure';
import ContextMenu from '../../../common/ContextMenu';
import OutputConnection from '../../../common/OutputConnection';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../../../utils';
import SbIcon from '../../../../../../simple-bot/components/common/SbIcon';

import SettingModal from './SettingModal';
import ConditionView from './ConditionView';

interface IMainCaseActionProps {
  mainCase: MainCaseActionSchema;
  group: DefaultActionGroupSchema;
  onDelete?: () => void;
}

const MainCaseAction: React.FC<IMainCaseActionProps> = ({ mainCase, group, onDelete }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const isCaseSelected = mainCase.id === selectedEntity?.id;

  const buttonRef = useRef(null);

  const onCaseMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setSelectedEntity(mainCase);
  };

  const menuIsVisibleChanged = (value: boolean) => {
    setMenuIsVisible(value);
  };

  const onSetupCaseClick = () => {
    setSettingModalVisible(true);
  };

  const onDeleteCaseClick = () => {
    onDelete?.();
  };

  const onSettingModalChange = (newAction: MainCaseActionSchema) => {
    setSettingModalVisible(false);

    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, mainCase.id) as MainCaseActionSchema;
    foundAction.conditions = newAction.conditions;

    setScenarioStructure(newScenarioStructure);
  };

  const onSettingModalClose = () => {
    setSettingModalVisible(false);
  };

  const classes = ['main-case-action'];
  isCaseSelected && classes.push('main-case-action_selected');

  const renderContent = () => {
    if (!mainCase.conditions.length) {
      return (
        <div className="main-case-action__content">
          <div ref={buttonRef} className="main-case-action__content__button" onClick={onSetupCaseClick}>
            Настроить
          </div>
          <div className="main-case-action__content__title">условия</div>
        </div>
      );
    }

    return (
      <div className="main-case-action__conditions">
        {mainCase.conditions.map((condition, index) => (
          <ConditionView key={condition.id} condition={condition} index={index} />
        ))}
      </div>
    );
  };

  return (
    <div aria-hidden="true" className={classes.join(' ')} id={getElementId(mainCase.id)} onMouseDown={onCaseMouseDown}>
      {renderContent()}
      <OutputConnection
        entity={mainCase}
        groupId={group.id}
        id={getElementId(mainCase.id, EntityFieldPath.OutputBindingId)}
        isValid={!scenarioValidation?.hasIssue(mainCase.id, EntityFieldPath.OutputBindingId)}
      />
      <ContextMenu
        menuContent={
          <Menu>
            <Menu.Item key="setup" onClick={onSetupCaseClick}>
              <SbIcon iconName="setting-two" />
            </Menu.Item>
            {onDelete && (
              <Menu.Item key="delete" onClick={onDeleteCaseClick}>
                <SbIcon iconName="close" />
              </Menu.Item>
            )}
          </Menu>
        }
        menuIsVisible={menuIsVisible}
        menuIsVisibleChanged={menuIsVisibleChanged}
      />
      <SettingModal
        mainCase={mainCase}
        visible={settingModalVisible}
        onChange={onSettingModalChange}
        onClose={onSettingModalClose}
      />
    </div>
  );
};

export default MainCaseAction;
