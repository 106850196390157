import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbPopover from '../common/IbPopover';
import IbTypography from '../common/IbTypography';
import IbTag from '../common/IbTag';
import IbInput from '../common/IbInput';
import IbIcon from '../common/IbIcon';
import IbButton from '../common/IbButton';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import { InboxOperatorGroupModel } from '../../../../api';
import { OPERATOR_GROUP_TAG_STYLE } from '../../../constants';
import { includesIgnoreCase } from '../../../utils/stringUtil';

import {
  ADD_BUTTON_CLASS_NAME,
  EMPTY_CONTENT_CLASS_NAME,
  MAIN_CLASS_NAME,
  POPOVER_CLASS_NAME,
  GROUP_LIST_CLASS_NAME,
  GROUP_LIST_ITEM_CLASS_NAME,
  GROUP_LIST_ITEM_SELECTED_CLASS_NAME,
  CONTENT_CLASS_NAME,
  EMPTY_SEARCH_TEXT_CLASS_NAME,
} from './const';

const READONLY_DEFAULT = false;
const GROUP_LIST_DEFAULT = [] as InboxOperatorGroupModel[];
const SELECTED_GROUPS_DEFAULT = [] as InboxOperatorGroupModel[];

export interface IIbOperatorGroupsWidgetProps {
  readonly?: boolean;
  groupList?: InboxOperatorGroupModel[];
  selectedGroups?: InboxOperatorGroupModel[];
  onChange?: (selectedGroups: InboxOperatorGroupModel[]) => void;
}

const IbOperatorGroupsWidget: React.FC<IIbOperatorGroupsWidgetProps> = ({
  readonly = READONLY_DEFAULT,
  groupList = GROUP_LIST_DEFAULT,
  selectedGroups = SELECTED_GROUPS_DEFAULT,
  onChange,
}) => {
  readonly = getDefaultIfUndefined(readonly, READONLY_DEFAULT);
  groupList = getDefaultIfUndefined(groupList, GROUP_LIST_DEFAULT);
  selectedGroups = getDefaultIfUndefined(selectedGroups, SELECTED_GROUPS_DEFAULT);

  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [innerSelectedGroups, setInnerSelectedGroups] = useState(selectedGroups);

  const filteredGroupList = groupList.filter((g) => includesIgnoreCase(g.name, searchText));
  const groupListIsEmpty = !groupList.length;

  const onGroupDelete = (group: InboxOperatorGroupModel) => () =>
    onChange?.(selectedGroups.filter((g) => g.id !== group.id));

  const onGroupListItemClick = (group: InboxOperatorGroupModel) => () => {
    if (innerSelectedGroups.some((g) => g.id === group.id)) {
      setInnerSelectedGroups(innerSelectedGroups.filter((g) => g.id !== group.id));
    } else {
      setInnerSelectedGroups([...innerSelectedGroups, group]);
    }
  };

  const onDoneButtonClick = () => {
    setPopoverVisible(false);
    onChange?.(innerSelectedGroups);
  };

  const onCloseButtonClick = () => {
    setPopoverVisible(false);
  };

  const onPopoverVisibleChange = (visible?: boolean) => {
    setPopoverVisible(visible || false);
    visible && setInnerSelectedGroups(selectedGroups);
  };

  const onSeletectedGroupsPropChange = () => {
    setInnerSelectedGroups(selectedGroups);
  };
  useEffect(onSeletectedGroupsPropChange, [selectedGroups]);

  const renderPopoverContent = () => {
    if (groupListIsEmpty) {
      return (
        <div className={EMPTY_CONTENT_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="secondary">
            {t('List of available groups is empty.')}
          </IbTypography.Paragraph>
          <IbTypography.Paragraph disabled type="secondary">
            {t('Create them in the appropriate tab, or invite an operator without being associated with a group.')}
          </IbTypography.Paragraph>
          <IbButton type="fill" onClick={onCloseButtonClick}>
            {t('Close')}
          </IbButton>
        </div>
      );
    }

    return (
      <div className={CONTENT_CLASS_NAME}>
        <IbInput value={searchText} onChange={setSearchText} />
        {!filteredGroupList.length ? (
          <div className={EMPTY_SEARCH_TEXT_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="secondary">
              {t('Nothing was found.')}
              <br />
              {t('Change the request or clear the search field.')}
            </IbTypography.Paragraph>
          </div>
        ) : (
          <div className={GROUP_LIST_CLASS_NAME}>
            {filteredGroupList.map((group) => {
              const itemClasses = [GROUP_LIST_ITEM_CLASS_NAME];
              innerSelectedGroups.some((g) => g.id === group.id) &&
                itemClasses.push(GROUP_LIST_ITEM_SELECTED_CLASS_NAME);

              return (
                <div key={group.id} className={itemClasses.join(' ')} onClick={onGroupListItemClick(group)}>
                  <IbIcon iconName="check-small" size={20} />
                  <IbTypography.Paragraph type="secondary">{group.name}</IbTypography.Paragraph>
                </div>
              );
            })}
          </div>
        )}
        <IbButton type="fill" onClick={onDoneButtonClick}>
          {t('Done')}
        </IbButton>
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {!readonly && (
        <IbPopover
          className={POPOVER_CLASS_NAME}
          content={renderPopoverContent()}
          placement="bottomLeft"
          trigger={['click']}
          visible={popoverVisible}
          onVisibleChange={onPopoverVisibleChange}
        >
          <div className={ADD_BUTTON_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="descriptor">
              {t('+ Add')}
            </IbTypography.Paragraph>
          </div>
        </IbPopover>
      )}
      {selectedGroups.map((group) => (
        <IbTag
          key={group.id}
          content={group.name}
          style={OPERATOR_GROUP_TAG_STYLE}
          tooltipContent={group.name}
          onDelete={readonly ? undefined : onGroupDelete(group)}
        />
      ))}
      {readonly && !selectedGroups.length && '—'}
    </div>
  );
};

export default IbOperatorGroupsWidget;
