import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { InputActionSchema } from '../../../../../../api';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import {
  instanceOfElma365AppElementInputSchema,
  instanceOfElma365EnumInputSchema,
  instanceOfPhoneNumberInputSchema,
} from '../../../utils';
import SbTabs from '../../../../../simple-bot/components/common/SbTabs';
import SbTabPane from '../../../../../simple-bot/components/common/SbTabPane';

import InputActionGeneralSettingsTab from './tabs/General';
import InputActionElma365AppSettingsTab from './tabs/Elma365App';
import InputActionElma365EnumSettingsTab from './tabs/Elma365Enum';
import InputActionTelegramPhoneNumberSettingsTab from './tabs/TelegramPhoneNumber';

export enum InputSettingsTabPaneKeys {
  GENERAL = 'GENERAL',
  ELMA365_APP = 'ELMA365_APP',
  ELMA365_ENUM = 'ELMA365_ENUM',
  TELEGRAM_PHONENUMBER = 'TELEGRAM_PHONENUMBER',
}

interface IInputActionSettingsModalProps {
  action: InputActionSchema;
  visible: boolean;
  tabKey?: InputSettingsTabPaneKeys;
  onChange: (action: InputActionSchema) => void;
  onClose: () => void;
}

const InputActionSettingsModal: React.FC<IInputActionSettingsModalProps> = ({
  action,
  visible,
  tabKey,
  onChange,
  onClose,
}) => {
  const [actionCopy, setActionCopy] = useState(cloneDeep(action));
  const [currentTabPane, setCurrentTabPane] = useState(tabKey ?? InputSettingsTabPaneKeys.GENERAL);

  const onSaveSettings = () => {
    onClose();
    onChange(actionCopy);
  };

  const onActionChange = (newAction: InputActionSchema) => {
    setActionCopy(newAction);
  };

  const reset = () => {
    if (!visible) return;

    if (tabKey) {
      setCurrentTabPane(tabKey);
    }
    setActionCopy(cloneDeep(action));
  };
  useEffect(reset, [visible, action]);

  const onTabChange = (activeKey: string) => setCurrentTabPane(activeKey as InputSettingsTabPaneKeys);

  const saveButtonDisabled =
    !actionCopy ||
    (actionCopy.canShowHelp &&
      (!actionCopy.helpMessages.length || actionCopy.helpMessages.every((m) => !m.content.length))) ||
    (actionCopy.maxTurnCount !== undefined && actionCopy.maxTurnCount < 0);

  const showTabs =
    instanceOfElma365AppElementInputSchema(actionCopy) ||
    instanceOfElma365EnumInputSchema(actionCopy) ||
    instanceOfPhoneNumberInputSchema(actionCopy);

  const renderGeneralInputActionSettings = () => {
    return <InputActionGeneralSettingsTab action={actionCopy} onChange={onActionChange} />;
  };

  return (
    <SbModal
      className="input-action-settings-modal"
      footer={[
        <SbButton key="save" disabled={saveButtonDisabled} sbSize="medium" onClick={onSaveSettings}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]}
      maskClosable={false}
      sbSize="small"
      title="Настройки ввода"
      visible={visible}
      onCancel={onClose}
      onOk={onSaveSettings}
    >
      {showTabs ? (
        <SbTabs activeKey={currentTabPane} onChange={onTabChange}>
          <SbTabPane key={InputSettingsTabPaneKeys.GENERAL} tab="Общие">
            {renderGeneralInputActionSettings()}
          </SbTabPane>
          {instanceOfElma365AppElementInputSchema(actionCopy) && (
            <SbTabPane key={InputSettingsTabPaneKeys.ELMA365_APP} tab="Приложение ELMA365">
              <InputActionElma365AppSettingsTab action={actionCopy} onChange={onActionChange} />
            </SbTabPane>
          )}
          {instanceOfElma365EnumInputSchema(actionCopy) && (
            <SbTabPane key={InputSettingsTabPaneKeys.ELMA365_ENUM} tab="Категория ELMA365">
              <InputActionElma365EnumSettingsTab action={actionCopy} onChange={onActionChange} />
            </SbTabPane>
          )}
          {instanceOfPhoneNumberInputSchema(actionCopy) && (
            <SbTabPane key={InputSettingsTabPaneKeys.TELEGRAM_PHONENUMBER} tab="Telegram">
              <InputActionTelegramPhoneNumberSettingsTab action={actionCopy} onChange={onActionChange} />
            </SbTabPane>
          )}
        </SbTabs>
      ) : (
        renderGeneralInputActionSettings()
      )}
    </SbModal>
  );
};

export default InputActionSettingsModal;
