import React, { MouseEventHandler, MouseEvent, useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbPopover, { POPOVER_MENU_CLASS_NAME } from '../IbPopover';
import IbIcon from '../IbIcon';
import IbSpin from '../IbSpin';

const TYPE_DEFAULT = 'primary';
const STATUS_DEFAULT = 'default';
const ACTIONS_DEFAULT = undefined;

const MAIN_CLASS_NAME = 'ib-button';
const ICON_ONLY_CLASS_NAME = `${MAIN_CLASS_NAME}_icon-only`;
const WITH_ACTIONS_CLASS_NAME = `${MAIN_CLASS_NAME}_with-actions`;
const POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}-popover`;
const POPOVER_EXPANDER_CLASS_NAME = `${MAIN_CLASS_NAME}__popover-expander`;
const POPOVER_EXPANDER_DIVIDER_CLASS_NAME = `${POPOVER_EXPANDER_CLASS_NAME}__divider`;

export interface IIbButtonProps {
  className?: string;
  type?: 'primary' | 'secondary' | 'fill' | 'link' | 'icon' | 'icon-bordered' | 'icon-round';
  status?: 'default' | 'success' | 'warning' | 'error';
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  actions?: {
    icon?: React.ReactNode;
    text: React.ReactNode;
    onSelect?: () => void;
  }[];
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const IbButton: React.FC<IIbButtonProps> = ({
  className,
  type = TYPE_DEFAULT,
  status = STATUS_DEFAULT,
  disabled,
  loading,
  icon,
  actions,
  onClick,
  children,
}) => {
  type = getDefaultIfUndefined(type, TYPE_DEFAULT);
  status = getDefaultIfUndefined(status, STATUS_DEFAULT);
  actions = getDefaultIfUndefined(actions, ACTIONS_DEFAULT);

  const ref = useRef(null);

  const withActions = actions?.length;

  const [popoverVisible, setPopoverVisible] = useState(false);

  const onPopoverVisibleChange = (visible?: boolean) => {
    withActions && setPopoverVisible(visible || false);
  };

  const onPopoverExpanderClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setPopoverVisible(!popoverVisible);
  };

  const onButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    if (e.defaultPrevented) return;

    setPopoverVisible(false);
    onClick?.(e);
  };

  const onClickOutside = () => setPopoverVisible(false);

  const classes = [
    MAIN_CLASS_NAME,
    `${MAIN_CLASS_NAME}_${type}`,
    `${MAIN_CLASS_NAME}_${status}`,
    children ? null : ICON_ONLY_CLASS_NAME,
    withActions ? WITH_ACTIONS_CLASS_NAME : null,
  ];
  className && classes.push(className);

  const renderActions = () => (
    <ul className={POPOVER_MENU_CLASS_NAME}>
      {actions?.map((action, index) => (
        <li key={index}>
          <div onClick={action.onSelect}>
            {action.icon}
            <span>{action.text}</span>
          </div>
        </li>
      ))}
    </ul>
  );

  const renderButton = () => (
    <button ref={ref} className={classes.join(' ')} disabled={disabled} onClick={onButtonClick}>
      {loading ? <IbSpin /> : icon}
      {children}
      {withActions && (
        <span className={POPOVER_EXPANDER_CLASS_NAME} onClick={onPopoverExpanderClick}>
          <span className={POPOVER_EXPANDER_DIVIDER_CLASS_NAME} />
          <IbIcon iconName="down" />
        </span>
      )}
    </button>
  );

  useOnClickOutside(ref, onClickOutside, 'mouseup');

  if (disabled) {
    // NOTE: Popover меняет размертку для кнопки с атрибутом disabled, поэтому рендерим без Popover
    return renderButton();
  }

  return (
    <IbPopover
      className={POPOVER_CLASS_NAME}
      content={renderActions()}
      placement="bottomLeft"
      trigger={[]}
      visible={popoverVisible}
      onVisibleChange={onPopoverVisibleChange}
    >
      {renderButton()}
    </IbPopover>
  );
};

export default IbButton;
