import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './index.less';

const MAIN_CLASS_NAME = 'ib-spin';

export interface IIbSpinProps {
  className?: string;
}

const IbSpin: React.FC<IIbSpinProps> = ({ className }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <Spin className={classes.join(' ')} indicator={<LoadingOutlined spin />} />;
};

export default IbSpin;
