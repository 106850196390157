import React, { useEffect, useState } from 'react';
import { PageHeader } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { SingleKnowledgeBaseModel, VersionCreationRequest } from '../../../kb-api';
import { knowledgeBaseKbApi, versionKbApi } from '../../apis';
import { AlertTypes } from '../../constants';
import KnowledgeCreatingForm, { IKnowledgeCreatingFormData } from '../../components/KnowledgeCreatingForm';
import { alertsSelectorAdd } from '../../recoil/alerts';

const VersionAdd: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);
  const [knowledgeBase, setKnowledgeBase] = useState<SingleKnowledgeBaseModel>();

  const pageTitle = knowledgeBase?.entry.name ? `Новая версия для "${knowledgeBase?.entry.name}"` : '';

  const loadDataAsync = async () => {
    try {
      const response = await knowledgeBaseKbApi.getKnowledgeBase(id);
      setKnowledgeBase(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке базы знаний',
        error: e,
      });
    }
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [id]);

  const onSubmitCreatingForm = async (data: IKnowledgeCreatingFormData) => {
    if (!knowledgeBase || !data.knowledgeData) {
      return;
    }

    setLoading(true);

    const newVersion: VersionCreationRequest = {
      ...data,
      knowledgeData: data.knowledgeData,
      knowledgeBaseId: id,
    };

    try {
      const response = await versionKbApi.createVersion(newVersion);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: `Добавлена новая версия в "${knowledgeBase.entry.name}"`,
        description: newVersion.name,
      });
      push(`/knowledge-bases/${id}/versions/${response.data.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении версии',
        error: e,
      });
      setLoading(false);
    }
  };

  const onCancelCreatingForm = () => {
    push(`/knowledge-bases/${id}`);
  };

  return (
    <div>
      <PageHeader title={pageTitle} />
      <KnowledgeCreatingForm
        knowledgeDataRequired
        loading={loading}
        showSearchParentsOnlyIfNoContextSetting={false}
        onCancel={onCancelCreatingForm}
        onSubmit={onSubmitCreatingForm}
      />
    </div>
  );
};

export default VersionAdd;
