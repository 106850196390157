import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { PageHeader, Button, Card, Typography, Row, Col, Pagination, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { PaginationConfig } from 'antd/lib/pagination';
import { useSetRecoilState } from 'recoil';

import { AgentModel } from '../../../api';
import { AlertTypes } from '../../constants';
import { agentApi } from '../../apis';
import { alertsSelectorAdd } from '../../recoil/alerts';

const { Paragraph } = Typography;

const AgentList: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [listBots, setListBots] = useState([] as AgentModel[]);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState<PaginationConfig>({
    current: 1,
    pageSize: 10,
  });

  const onPaginationChange = (page: number) => {
    setPagination({ ...pagination, current: page });
  };

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await agentApi.searchAgents((pagination.current || 1) - 1, pagination.pageSize);
      setListBots(response.data.items || []);
      setTotal(response.data.totalItemCount || 0);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка ботов',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination]);

  return (
    <Fragment>
      <div>
        <PageHeader title="Боты" />
      </div>
      <Spin spinning={loading}>
        <Row gutter={24}>
          <Col key="add-bot-button" flex="auto" style={{ marginBottom: 24 }}>
            <Button style={{ height: 203, width: '100%' }} type="dashed" onClick={() => push('/agents/add')}>
              <PlusOutlined /> Новый бот
            </Button>
          </Col>
          {listBots.map((item, index) => (
            <Col key={item.id || index} flex="auto" style={{ marginBottom: 24 }}>
              <Card
                hoverable
                actions={[
                  <a key="option1" href="/agents#" style={{ paddingRight: 8, paddingLeft: 8 }}>
                    Диалоги
                  </a>,
                  <a key="option2" href="/agents#" style={{ paddingRight: 8, paddingLeft: 8 }}>
                    Участники
                  </a>,
                ]}
              >
                <Card.Meta
                  description={
                    <Paragraph ellipsis={{ rows: 3 }} style={{ height: 64 }}>
                      {item.description}
                    </Paragraph>
                  }
                  title={<Link to={`/agents/${item.id}`}>{item.name}</Link>}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        showSizeChanger={false}
        total={total}
        onChange={onPaginationChange}
      />
    </Fragment>
  );
};

export default AgentList;
