import { MutableRefObject, useEffect, useRef } from 'react';

const useHorizontalScroll = (): MutableRefObject<HTMLDivElement | null> => {
  const elRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        el.scrollBy(e.deltaY, 0);
      };
      el.addEventListener('wheel', onWheel, { passive: false });
      return () => el.removeEventListener('wheel', onWheel);
    }
  }, []);
  return elRef as MutableRefObject<HTMLDivElement | null>;
};

export default useHorizontalScroll;
