import React from 'react';
import { RetweetOutlined } from '@ant-design/icons';

const TransferToOperatorInitiated: React.FC = () => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <RetweetOutlined size={16} />
      Беседа переведена на оператора
    </div>
  );
};

export default TransferToOperatorInitiated;
