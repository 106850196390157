import React, { useEffect, useRef, useState } from 'react';
import { CallbackInterface, useRecoilCallback } from 'recoil';
import ReactDOM from 'react-dom';
import { nanoid } from 'nanoid';

import './index.less';

import { zoomSelector } from '../../../../../recoil/scenarioStructure';
import { scenarioEditorContainerId } from '../../../constants';
import { useEventListener } from '../../../utils';

const DEFAULT_RIGHT_PLACEMENT_OFFSET = { left: 6, top: -20 };
const DOWN_PLACEMENT_OFFSET = { top: 4 };
const MIN_HEIGHT = 200;
const MIN_WIDTH = 50;

interface IDropdownMenuProps {
  className?: string;
  placement: 'right' | 'down';
  triggerElement?: HTMLElement | null;
  isVisible: boolean;
  width?: number | undefined;
  onClose: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  size?: 'small' | 'medium';
  rightPlacementOffset?: { left: number; top: number };
  useScenarioEditorZoom?: boolean;
}

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  children,
  className = '',
  placement,
  isVisible,
  onClose,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  triggerElement,
  width,
  size = 'medium',
  rightPlacementOffset = DEFAULT_RIGHT_PLACEMENT_OFFSET,
  useScenarioEditorZoom = true,
}) => {
  const [id] = useState(`dropdown-menu-${nanoid(10)}`);

  const menuElementRef = useRef<HTMLDivElement>(null);

  const handleOutsideAction = (event: Event) => {
    if (!isVisible) return;

    if (
      (event as WheelEvent).ctrlKey ||
      (!menuElementRef.current?.contains(event.target as Node) && !triggerElement?.contains(event.target as Node))
    ) {
      onClose();
    }
  };
  useEventListener('mousedown', handleOutsideAction);
  useEventListener('wheel', handleOutsideAction);

  const rerender = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    if (!isVisible || !triggerElement) {
      document.getElementById(id)?.remove();
      return;
    }

    const { snapshot } = callbackHelpers;
    const scenarioEditorZoom = await snapshot.getPromise(zoomSelector);
    const zoom = useScenarioEditorZoom ? scenarioEditorZoom : 1;

    const parentContainer = useScenarioEditorZoom ? document.getElementById(scenarioEditorContainerId) : document.body;

    if (!parentContainer) return;

    const triggerRect = triggerElement.getBoundingClientRect();
    const containerRect = parentContainer.getBoundingClientRect();
    const spaceBelowButton = (containerRect.bottom - triggerRect.bottom) / zoom;
    const maxHeight = `${Math.max(spaceBelowButton, MIN_HEIGHT)}px`;

    const dropdownPosition = { x: 0, y: 0 };
    dropdownPosition.x =
      placement === 'right' ? triggerRect.right + rightPlacementOffset.left * zoom : triggerRect.left;
    dropdownPosition.y =
      placement === 'right'
        ? triggerRect.top + rightPlacementOffset.top * zoom
        : triggerRect.bottom + DOWN_PLACEMENT_OFFSET.top * zoom;

    // NOTE: Проверяем, помещается ли сверху
    if (triggerRect.top < containerRect.top) {
      dropdownPosition.y = containerRect.top;
    }

    const dropdownWidth = width ? width : MIN_WIDTH;
    // NOTE: Проверяем, помещается ли справа
    if (triggerRect.right + dropdownWidth > containerRect.width) {
      dropdownPosition.x = triggerRect.left - dropdownWidth * zoom;
    }

    const transform = `translate(${dropdownPosition.x}px, ${dropdownPosition.y}px) scale(${zoom})`;

    let container = document.getElementById(id);
    if (!container) {
      container = document.createElement('div');
      container.id = id;
      parentContainer.appendChild(container);
    }

    ReactDOM.render(
      <div
        ref={menuElementRef}
        className={`dropdown-menu dropdown-menu_${size} dropdown-menu_${placement} ${className}`}
        role="none"
        style={{
          width: width ?? 'unset',
          maxHeight,
          transform,
        }}
        // HACK: исправлен клик по элементам меню, когда в меню есть группы
        onClickCapture={onClose}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>,
      container
    );
  });
  const onRerender = () => {
    rerender().finally();
  };
  useEffect(onRerender, [isVisible, children]);

  useEffect(() => () => document.getElementById(id)?.remove(), [id]);

  return null;
};

export default DropdownMenu;
