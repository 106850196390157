import React, { memo } from 'react';

import { bindingArrowSize } from '../../constants';

const bindingArrowRefX = bindingArrowSize.width - 1;
const bindingArrowRefY = bindingArrowSize.height / 2;

interface IArrowProps {
  id: string;
}

const Arrow: React.FC<IArrowProps> = ({ id }) => (
  <marker
    className="sb-binding__arrow"
    id={id}
    markerHeight={bindingArrowSize.height}
    markerUnits="userSpaceOnUse"
    markerWidth={bindingArrowSize.width}
    orient="auto"
    refX={bindingArrowRefX}
    refY={bindingArrowRefY}
    viewBox={`0 0 ${bindingArrowSize.width} ${bindingArrowSize.height}`}
  >
    <path d={`M 1 1 L ${bindingArrowRefX} ${bindingArrowRefY} L 1 ${bindingArrowSize.height - 1}`} />
  </marker>
);

export default memo(Arrow);
