import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Table } from 'antd';

import './index.less';

import { SuccessfullServiceEventsPerDayModel, SuccessfullServiceEventsPerScenarioModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import SbTable from '../../common/SbTable';
import SbBar from '../../common/SbBar';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';

const CHART_SETTINGS = {
  barColors: {
    userAnsweredNo: '#D5403C',
    userAnsweredYes: '#53A6FF',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as SuccessfullServiceEventsPerDayModel;
  if (data.userAnsweredNoCount <= 0 && data.userAnsweredYesCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        Положительных ответов: <b>{data.userAnsweredYesCount}</b>
      </div>
      <div>
        Отрицательных ответов: <b>{data.userAnsweredNoCount}</b>
      </div>
    </div>
  );
};

interface ISbSuccessfullServiceEventsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbSuccessfullServiceEventsAnalytics: React.FC<ISbSuccessfullServiceEventsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [eventsPerScenarioList, setEventsPerScenarioList] = useState<SuccessfullServiceEventsPerScenarioModel[]>([]);
  const [eventsPerDayList, setEventsPerDayList] = useState<SuccessfullServiceEventsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const formattedFromDate = filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);
      const formattedToDate = filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);

      const eventsPerScenarioResponse = await analyticsReportsApi.getSuccessfullServiceEventsPerScenarioList(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );
      const eventsPerDayResponse = await analyticsReportsApi.getSuccessfullServiceEventsPerDayList(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );

      setEventsPerScenarioList(eventsPerScenarioResponse.data);
      setEventsPerDayList(eventsPerDayResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по успешным обслуживаниям',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const countMax = Math.max(
    ...eventsPerScenarioList.map((e) => Math.max(e.userAnsweredYesCount, e.userAnsweredNoCount))
  );

  const tableColumns = [
    {
      title: 'Сценарий',
      dataIndex: 'scenarioName',
      key: 'scenarioName',
      render: (_: string, e: SuccessfullServiceEventsPerScenarioModel) => e.scenarioName,
    },
    {
      title: 'Положительных ответов',
      dataIndex: 'userAnsweredYesCount',
      key: 'userAnsweredYesCount',
      render: (_: string, e: SuccessfullServiceEventsPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.userAnsweredYesCount}</b>
          </span>
          <SbBar color={CHART_SETTINGS.barColors.userAnsweredYes} maxValue={countMax} value={e.userAnsweredYesCount} />
        </div>
      ),
    },
    {
      title: 'Отрицательных ответов',
      dataIndex: 'userAnsweredNoCount',
      key: 'userAnsweredNoCount',
      render: (_: string, e: SuccessfullServiceEventsPerScenarioModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.userAnsweredNoCount}</b>
          </span>
          <SbBar color={CHART_SETTINGS.barColors.userAnsweredNo} maxValue={countMax} value={e.userAnsweredNoCount} />
        </div>
      ),
    },
  ];

  return (
    <div className="sb-successfull-service-events-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Успешное обслуживание по сценариям</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content sb-analytics-card__content__block__content_with-table  ${
            loading ? 'sb-analytics-card__content__block__content_loading' : ''
          }`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                { title: 'Сценарий', dataIndex: 'scenarioName', key: 'scenarioName' },
                { title: 'Положительных ответов', dataIndex: 'userAnsweredYesCount', key: 'userAnsweredYesCount' },
                { title: 'Отрицательных ответов', dataIndex: 'userAnsweredNoCount', key: 'userAnsweredNoCount' },
              ]}
              dataSource={eventsPerScenarioList.sort((a, b) => b.userAnsweredYesCount - a.userAnsweredYesCount)}
              pagination={false}
            />
          ) : (
            <SbTable
              columns={tableColumns}
              dataSource={eventsPerScenarioList.sort((a, b) => b.userAnsweredYesCount - a.userAnsweredYesCount)}
              emptyText="Нет данных"
              rowKey="scenarioName"
              scrollEnabled={false}
              tableLayout="fixed"
            />
          )}
        </div>
      </div>
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Успешное обслуживание</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                { title: 'Положительных ответов', dataIndex: 'userAnsweredYesCount', key: 'userAnsweredYesCount' },
                { title: 'Отрицательных ответов', dataIndex: 'userAnsweredNoCount', key: 'userAnsweredNoCount' },
              ]}
              dataSource={eventsPerDayList}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={eventsPerDayList}>
                <XAxis
                  dataKey={(e: SuccessfullServiceEventsPerDayModel) => e.date}
                  tickFormatter={formatAnalyticsDateCompact}
                />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                <Bar
                  dataKey={(e: SuccessfullServiceEventsPerDayModel) => e.userAnsweredYesCount}
                  fill={CHART_SETTINGS.barColors.userAnsweredYes}
                  isAnimationActive={isAnimationActive}
                  name="Положительные ответы"
                />
                <Bar
                  dataKey={(e: SuccessfullServiceEventsPerDayModel) => e.userAnsweredNoCount}
                  fill={CHART_SETTINGS.barColors.userAnsweredNo}
                  isAnimationActive={isAnimationActive}
                  name="Отрицательные ответы"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbSuccessfullServiceEventsAnalytics;
