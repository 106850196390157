import React, { memo, RefObject, useEffect } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilValue } from 'recoil';

import { dispatcherState, groupPositionsSelector, zoomSelector } from '../../../../../recoil/scenarioStructure';
import { getElementRect } from '../../../utils';

interface IOutputConnectionPositionsUpdaterProps {
  outputConnectionRef: RefObject<HTMLDivElement>;
  entityId: string;
  groupId: string;
}

const OutputConnectionPositionsUpdater: React.FC<IOutputConnectionPositionsUpdaterProps> = ({
  outputConnectionRef,
  entityId,
  groupId,
}) => {
  const { outputConnectionPositionsAddOrUpdate } = useRecoilValue(dispatcherState);
  const groupPosition = useRecoilValue(groupPositionsSelector(groupId));

  const addOrUpdateOutputConnectionPositions = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { snapshot } = callbackHelpers;
    const zoom = await snapshot.getPromise(zoomSelector);

    const rect = outputConnectionRef.current?.getBoundingClientRect();
    if (!rect) return;

    const groupRect = getElementRect(groupId);
    const newPosX = (rect.left + (rect.width - 1) - (groupRect?.left || 0)) / zoom + groupPosition.positionX;
    const newPosY = (rect.top + rect.height / 2 - (groupRect?.top || 0)) / zoom + groupPosition.positionY;

    await outputConnectionPositionsAddOrUpdate({
      id: entityId,
      positionX: newPosX,
      positionY: newPosY,
    });
  });
  const onGroupPositionChange = () => {
    addOrUpdateOutputConnectionPositions().finally();
  };
  useEffect(onGroupPositionChange, [outputConnectionRef.current, groupPosition]);

  return null;
};

export default memo(OutputConnectionPositionsUpdater);
