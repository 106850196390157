import { InboxParticipantModel, InboxParticipantStatus, UserStatus } from '../../../../api';
import { SUBJECT_ROLES, USER_ROLES } from '../../../constants';
import { SOUND_CLOUD_OGG, SOUND_CHORD_OGG, SOUND_POLITE_OGG } from '../common/IbSound/const';

export const MAIN_CLASS_NAME = 'ib-operator-edit-modal';
export const INVITATION_CLASS_NAME = `${MAIN_CLASS_NAME}__invitation`;
export const ONE_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}_one-column`;
export const TWO_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}_two-column`;
export const FORM_CLASS_NAME = `${MAIN_CLASS_NAME}__form`;
export const FORM_BLOCK_CLASS_NAME = `${FORM_CLASS_NAME}__block`;
export const FORM_ITEM_CLASS_NAME = `${FORM_BLOCK_CLASS_NAME}__item`;
export const FORM_EXTRA_CLASS_NAME = `${FORM_BLOCK_CLASS_NAME}__extra`;
export const FORM_ITEM_CONTROLS_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__controls`;
export const FORM_ITEM_CHECKBOXES_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__checkboxes`;
export const FORM_ITEM_CHECKBOXES_ITEM_CLASS_NAME = `${FORM_ITEM_CHECKBOXES_CLASS_NAME}__item`;
export const BLOCK_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__block-button`;

export const OPERATOR_DEFAULT = {
  id: '',
  createdOn: '',
  modifiedOn: '',
  status: InboxParticipantStatus.Offline,
  subject: {
    id: '',
    person: {
      id: '',
      contacts: {
        primaryTel: '',
        secondaryTels: [],
        email: '',
      },
      location: {},
      name: '',
      roles: [{ name: USER_ROLES.inboxOperator }],
      avatar: '',
      status: UserStatus.Invited,
      tags: [],
    },
    role: SUBJECT_ROLES.operator,
  },
  tenantId: '',
  statistics: {
    assignedChatCount: 0,
    queuedChatCount: 0,
    lastActivity: {},
  },
  settings: {
    notifications: {
      enabled: true,
      chatQueuedSound: SOUND_CLOUD_OGG,
      chatAssignedSound: SOUND_CHORD_OGG,
      messageReceivedSound: SOUND_POLITE_OGG,
    },
  },
  operatorGroups: [],
} as InboxParticipantModel;

export const ALERT_CLOSING_TIMEOUT = 5000; // ms
