import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import './index.less';

import DropdownMenu from '../DropdownMenu';
import { groupsMenuIsVisibleSelector } from '../../../../../recoil/scenarioStructure';

interface IEditButtonProps {
  title: ReactNode;
  groupId?: string;
  menuContent?: ReactNode;
  buttonHandler?: () => void; // NOTE: если задан buttonHandler, то контекстное меню не отображается
}

const EditButton: React.FC<IEditButtonProps> = ({ title, groupId, menuContent, buttonHandler }) => {
  const [groupMenuIsVisible, setGroupMenuIsVisible] = useRecoilState(groupsMenuIsVisibleSelector(groupId || ''));
  const ref = useRef<HTMLDivElement>(null);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const onButtonClick = () => {
    if (buttonHandler) {
      buttonHandler();
    } else {
      setMenuIsVisible(!menuIsVisible);
    }
  };

  const onGroupMenuIsVisibleChange = () => {
    if (groupMenuIsVisible) {
      setMenuIsVisible(true);
      setGroupMenuIsVisible(false);
    }
  };
  useEffect(onGroupMenuIsVisibleChange, [groupMenuIsVisible]);

  return (
    <div className="edit-button">
      <div ref={ref} className="edit-button__button" onClick={onButtonClick}>
        {title}
      </div>
      {!buttonHandler && (
        <DropdownMenu
          isVisible={menuIsVisible}
          placement="down"
          triggerElement={ref.current}
          onClose={() => setMenuIsVisible(false)}
        >
          {menuContent}
        </DropdownMenu>
      )}
    </div>
  );
};

export default EditButton;
