import {
  BindingSchema,
  DefaultActionGroupSchema,
  DefaultScenarioSchema,
  DefaultTriggerGroupSchema,
  EntitySchema,
  ScenarioSchema,
  SchemaKind,
  VariableSchema,
} from '../../../api';
import { groupsMarginX, groupsMarginY, groupWidth } from '../../components/ScenarioEditor/constants';
import { IPosition, IScenarioEntity } from '../../components/ScenarioEditor/types';
import {
  deleteBindingWithReferences,
  generateId,
  instanceOfEndScenarioOutputSchema,
  instanceOfExternalEventTriggerSchema,
  instanceOfInputSchema,
  instanceOfMenuActionSchema,
  instanceOfScriptActionSchema,
  instanceOfSendEmailSchema,
  instanceOfTextOutputSchema,
  instanceOfVariableOperandSchema,
  tryGetElementById,
} from '../../components/ScenarioEditor/utils';
import { lruLocalStorage } from '../../utils/localStorageUtil';

export const getGroupPosition = (group: EntitySchema, index: number): IPosition => {
  const positionX =
    typeof group.$designer?.positionX === 'number'
      ? group.$designer.positionX
      : groupsMarginX + (groupWidth + groupsMarginX) * index;
  const positionY = typeof group.$designer?.positionY === 'number' ? group.$designer.positionY : groupsMarginY;
  return { positionX, positionY };
};

export const saveBinding = (
  saveScenarioStructure: (structure: DefaultScenarioSchema) => void,
  selectBinding: (binding: BindingSchema) => void,
  sourceEntityId: string,
  targetEntityId: string,
  scenarioStructure: DefaultScenarioSchema
): void => {
  const existBinding = scenarioStructure.bindings.find((b) => b.sourceEntityId === sourceEntityId);
  if (existBinding) {
    deleteBindingWithReferences(scenarioStructure, existBinding.id);
  }
  if (sourceEntityId === targetEntityId) return;

  const source = tryGetElementById(scenarioStructure, sourceEntityId);
  const target = tryGetElementById(scenarioStructure, targetEntityId);

  if (source && target) {
    const newBinding: BindingSchema = {
      id: generateId('BND'),
      $kind: SchemaKind.Binding,
      sourceEntityId,
      targetEntityId,
    };
    scenarioStructure.bindings.push(newBinding);

    source.outputBindingId = newBinding.id;
    target.inputBindingIds = [...Array.from(target.inputBindingIds || []), newBinding.id];

    saveScenarioStructure(scenarioStructure);
    selectBinding(newBinding);
  }
};

export const trySaveScenarioStructureToLocalStorage = (
  botStageId?: string,
  scenarioStructure?: ScenarioSchema
): void => {
  if (!botStageId || !scenarioStructure?.id) {
    return;
  }

  lruLocalStorage.setScenarioStructure(botStageId, scenarioStructure);
};

export const getVariableUsages = (
  scenarioStructures: DefaultScenarioSchema[],
  selectedVariable: VariableSchema
): IScenarioEntity[] => {
  return scenarioStructures.flatMap((structure) => {
    const actionGroups = structure.actionGroups as DefaultActionGroupSchema[];
    const actions = actionGroups.flatMap((group) => group.actions);
    const triggerGroup = structure.triggerGroup as DefaultTriggerGroupSchema;
    const triggers = triggerGroup.triggers;
    const entities = [...actions, ...triggers] as EntitySchema[];
    const filteredEntities = entities.filter(
      (entity) =>
        (instanceOfInputSchema(entity) && entity.variableId === selectedVariable.id) ||
        ((instanceOfTextOutputSchema(entity) || instanceOfInputSchema(entity) || instanceOfMenuActionSchema(entity)) &&
          entity.messages.some((message) => message.content.includes(selectedVariable.id))) ||
        (instanceOfScriptActionSchema(entity) &&
          (entity.content.includes('variables.' + selectedVariable.code) ||
            entity.content.includes("variables['" + selectedVariable.code + "']"))) ||
        (instanceOfExternalEventTriggerSchema(entity) &&
          entity.variables?.some((variable) => selectedVariable.id === variable.id)) ||
        (instanceOfSendEmailSchema(entity) &&
          (entity.body.includes(selectedVariable.id) ||
            instanceOfVariableOperandSchema(entity.subject) ||
            entity.recipients.some((recipient) => instanceOfVariableOperandSchema(recipient)) ||
            entity.ccRecipients.some((recipient) => instanceOfVariableOperandSchema(recipient)) ||
            entity.bccRecipients.some((recipient) => instanceOfVariableOperandSchema(recipient)) ||
            entity.attachments.some((attachment) => instanceOfVariableOperandSchema(attachment))))
    );
    return filteredEntities.map((entity) => ({ scenarioName: structure.name, item: entity }));
  });
};

export const getScenarioActions = (
  scenarioStructures: DefaultScenarioSchema[],
  selectedScenario: DefaultScenarioSchema
): IScenarioEntity[] => {
  return scenarioStructures.flatMap((structure) => {
    const groups = structure.actionGroups as DefaultActionGroupSchema[];
    const actions = groups.flatMap((group) => group.actions);
    const filteredActions = actions.filter(
      (action) => instanceOfEndScenarioOutputSchema(action) && action.transitionScenarioId === selectedScenario.id
    );
    return filteredActions.slice(0, 1).map((action) => ({ scenarioName: structure.name, item: action }));
  });
};

export const hasScenarioActions = (
  scenarioStructures: DefaultScenarioSchema[],
  selectedScenario: DefaultScenarioSchema
): boolean => {
  return !!getScenarioActions(scenarioStructures, selectedScenario).length;
};
