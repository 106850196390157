import React, { memo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useSetRecoilState } from 'recoil';
import './index.less';

import { DefaultActionGroupSchema, SchemaKind, SwitchActionSchema, MainCaseActionSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import { currentScenarioStructureSelector, selectedEntitySelector } from '../../../../../recoil/scenarioStructure';
import { deleteBindingWithReferences, generateId, tryGetElementById } from '../../../utils';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import AddButton from '../../common/AddButton';

import MainCaseAction from './MainCaseAction';
import ElseCaseAction from './ElseCaseAction';

interface ISwitchActionProps {
  index: number;
  action: SwitchActionSchema;
  group: DefaultActionGroupSchema;
}

const SwitchAction: React.FC<ISwitchActionProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const setSelectedEntity = useSetRecoilState(selectedEntitySelector);

  const onDeleteMainCase = (mainCase: MainCaseActionSchema) => () => {
    if (!scenarioStructure) return;

    const newCases = action.mainCases.filter((b) => b.id !== mainCase.id);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as SwitchActionSchema).mainCases = newCases;

    deleteBindingWithReferences(newScenarioStructure, mainCase.outputBindingId);

    setScenarioStructure(newScenarioStructure);
  };

  const onMainCaseCreate = () => {
    if (!scenarioStructure) return;

    const newCases = [...action.mainCases];
    const newCase: MainCaseActionSchema = {
      id: generateId('MCS'),
      $kind: SchemaKind.MainCaseAction,
      outputBindingId: null,
      conditions: [],
    };
    newCases.push(newCase);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as SwitchActionSchema).mainCases = newCases;

    setSelectedEntity(newCase);
    setScenarioStructure(newScenarioStructure);
  };

  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="link-right" size={20} />}
      index={index}
      title="Условное ветвление"
    >
      <div className="switch-action">
        {action.mainCases.map((mainCase) => (
          <MainCaseAction key={mainCase.id} group={group} mainCase={mainCase} onDelete={onDeleteMainCase(mainCase)} />
        ))}
        <AddButton buttonHandler={onMainCaseCreate} title="Добавить ветвление" />
        <ElseCaseAction key={action.elseCase.id} elseCase={action.elseCase} group={group} />
      </div>
    </ActionWrapper>
  );
};

export default memo(SwitchAction);
