import React, { useEffect, useState, Fragment } from 'react';
import { Avatar, Collapse, List, PageHeader, Progress, Row, Space, Steps, Table } from 'antd';
import { useParams } from 'react-router';
import { UserOutlined } from '@ant-design/icons';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import {
  CampaignJobDescription,
  CampaignJobModel,
  CampaignJobState,
  CampaignModel,
  DialogProgressState,
  PersonalCampaignResult,
  PersonModel,
} from '../../../api';
import { AlertTypes, CAMPAIGN_JOB_UPDATED } from '../../constants';
import { campaignApi, campaignJobApi } from '../../apis';
import { getChannelName, round } from '../../utils/stringUtil';
import { hubConnections } from '../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../recoil/alerts';

const { Panel } = Collapse;
const { Step } = Steps;

const CampaignCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({} as CampaignModel);
  const [jobs, setJobs] = useState([] as CampaignJobModel[]);
  const [currentJobDescription, setCurrentJobDescription] = useState({} as CampaignJobDescription);
  const [currentJobState, setCurrentJobState] = useState({} as CampaignJobState);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const campaignResponse = await campaignApi.getCampaign(id);
      setCampaign(campaignResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке рассылки',
        error: e,
      });
    }

    try {
      const jobsResponse = await campaignJobApi.listCampaignJobs(id, 0, 100);
      setJobs(jobsResponse.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка заданий рассылки',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const loadCampaignJobDescription = async (jobId: string) => {
    try {
      const response = await campaignJobApi.getCampaignJobDescription(jobId);
      setCurrentJobDescription(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке описания задания рассылки',
        error: e,
      });
    }
  };

  const loadCampaignJobState = async (jobId: string) => {
    try {
      const response = await campaignJobApi.getCampaignJobState(jobId);
      setCurrentJobState(response.data);
    } catch (e) {
      // TODO: перепроверить это место / разобраться, почему могут быть ошибки
      // eslint-disable-next-line no-console
      console.log(e);
      // addAlert({
      //   type: AlertTypes.ERROR,
      //   message: 'Ошибка при загрузке статуса задания рассылки',
      //   error: e
      // })
    }
  };

  const updateCampaignJob = () => {
    if (!jobs.length) return;

    const jobId = jobs[0].id;
    loadCampaignJobState(jobId);
    loadCampaignJobDescription(jobId);
  };
  useEffect(updateCampaignJob, [loading]);

  const campaignJobEventHandler = (args: { jobId: string }) => {
    if (jobs[0]?.id === args?.jobId) {
      loadCampaignJobState(args.jobId);
    }
  };
  const subscribe = () => {
    if (!jobs.length || !conn) return;

    conn.on(CAMPAIGN_JOB_UPDATED, campaignJobEventHandler);

    return () => {
      conn.off(CAMPAIGN_JOB_UPDATED, campaignJobEventHandler);
    };
  };
  useEffect(subscribe, [conn, jobs]);

  const allStates = currentJobDescription.states || {};
  const statesMap: { [channelId: string]: { [key: number]: DialogProgressState } } = {};

  for (const key in allStates) {
    const value = allStates[key];

    const channelStates: { [key: number]: DialogProgressState } = {};
    value.forEach((s: DialogProgressState) => {
      channelStates[s.number] = s;
    });

    statesMap[key] = channelStates;
  }

  interface IRecipientsData {
    [personId: string]: PersonalCampaignResult[];
  }
  const recipientState = currentJobState.recipientState || [];
  const recipients: IRecipientsData = recipientState.reduce((acc: IRecipientsData, cur: PersonalCampaignResult) => {
    if (acc[cur.personId]) {
      acc[cur.personId].push(cur);
    } else {
      acc[cur.personId] = [cur];
    }
    return acc;
  }, {} as IRecipientsData);

  // для расчетов общего процента берем сквозной список всех ботов и каналов всех сотрудников
  const total = recipientState.length || 1;
  const finished = recipientState.filter(
    (r) =>
      r.channelId &&
      statesMap[r.channelId] &&
      statesMap[r.channelId][r.progress]?.isFinal &&
      !statesMap[r.channelId][r.progress]?.isError
  ).length;
  const progressPercent = (finished / total) * 100;

  const renderPersonResults = (personResults: PersonalCampaignResult[]) => {
    const person = campaign.persons?.find((p) => p.id === personResults[0].personId) as PersonModel;
    const agentStages = [campaign.agentStageId];

    return (
      <List.Item>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Space>
            <Avatar icon={<UserOutlined />} size={52} />
            <Space direction="vertical">
              <span>{person?.fullName}</span>
              <span>{person?.primaryTel}</span>
            </Space>
          </Space>
          <Space>
            <Table
              dataSource={agentStages.map((item, index) => ({ key: index, agentStageId: item }))}
              pagination={false}
            >
              <Table.Column
                dataIndex="agentStageId"
                render={(agentStageId: string) => {
                  const name = campaign.agent?.name ?? '-';
                  if (campaign.agent?.productionAgentStageId === agentStageId) {
                    return `${name} (Релиз)`;
                  }
                  if (campaign.agent?.stagingAgentStageId === agentStageId) {
                    return `${name} (Тест)`;
                  }
                  return `${name} (Неизв.)`;
                }}
                title=""
              />
              {campaign.agentStageAccounts
                .filter((account) => statesMap[account.channelId])
                .map((account) => {
                  let maxStateNumber = Math.max(
                    ...allStates[account.channelId].filter((s) => !s.isError).map((s) => s.number)
                  );
                  maxStateNumber = Math.max(maxStateNumber, 1);
                  return (
                    <Table.Column
                      key={account.accountId}
                      align="center"
                      render={() => {
                        const personResult = personResults.find((pr) => pr.agentStageAccountId === account.accountId);

                        if (!personResult) return <span>Нет</span>;

                        if (!statesMap[account.channelId][personResult.progress]?.isError)
                          return (
                            <Progress
                              className="campaign-status"
                              format={() => statesMap[account.channelId][personResult.progress]?.name}
                              percent={((personResult?.progress || 0) / maxStateNumber) * 100}
                            />
                          );

                        return (
                          <Progress
                            className="campaign-status"
                            format={() => statesMap[account.channelId][personResult.progress]?.name}
                            percent={100}
                            status="exception"
                          />
                        );
                      }}
                      title={getChannelName(account.channelId, account.displayName, account.externalName)}
                    />
                  );
                })}
            </Table>
          </Space>
        </Space>
      </List.Item>
    );
  };

  const getProperties = () => {
    const job = jobs.length > 0 ? jobs[0] : null;
    if (!job) {
      return;
    }

    const jobResult = job?.results ? job.results[0] : null;
    const properties = (jobResult?.properties || {}) as {
      [key: string]: { type?: string | null; value?: string | number | boolean | null; displayName?: string | null };
    };
    return (
      <Table
        dataSource={Object.entries(properties).map(([key, value]) => ({
          key: key,
          displayName: value.displayName,
          type: value.type,
          value: value.value,
        }))}
        pagination={false}
      >
        <Table.Column dataIndex="key" title="Код" />
        <Table.Column dataIndex="displayName" title="Название" />
        <Table.Column dataIndex="type" title="Тип" />
        <Table.Column dataIndex="value" title="Значение" />
      </Table>
    );
  };

  if (loading) return null;
  return (
    <div>
      <PageHeader title={campaign.name} />
      {!jobs.length ? (
        <Space>Нет запущенных заданий ...</Space>
      ) : (
        <Collapse defaultActiveKey={['1', '2']}>
          <Panel key="1" header="Прогресс">
            <Row justify="center">
              <Progress format={(p) => `${round(p, 2)}%`} percent={progressPercent} type="circle" />
            </Row>
          </Panel>
          <Panel key="2" header="Получатели">
            <List dataSource={Object.values(recipients)} renderItem={renderPersonResults} />
          </Panel>
          <Panel key="3" header="Параметры">
            <h3>Последовательность</h3>
            {campaign.agentStageAccounts
              .filter((account) => statesMap[account.channelId])
              .map((account) => (
                <Fragment key={account.accountId}>
                  <br />
                  <h4>{getChannelName(account.channelId, account.displayName, account.externalName)}</h4>
                  <Steps current={-1} size="small" type="navigation">
                    {allStates[account.channelId]
                      .filter((s) => !s.isError)
                      .map((s, index) => (
                        <Step key={index} title={s.name} />
                      ))}
                  </Steps>
                  <br />
                </Fragment>
              ))}
            <h3>Параметры рассылки</h3>
            {getProperties()}
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default CampaignCard;
