import React, { useState, useEffect } from 'react';
import { PageHeader, Form, Input, Button, Space } from 'antd';
import { useHistory, useParams } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { KnowledgeBaseUpdatingRequest } from '../../../kb-api';
import { knowledgeBaseKbApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const { TextArea } = Input;

const KnowledgeBaseEdit: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();

  const goToKnowledgeBasePage = () => {
    push(`/knowledge-bases/${id}`);
  };

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await knowledgeBaseKbApi.getKnowledgeBase(id);
      form.setFieldsValue({
        name: response.data.entry.name,
        description: response.data.entry.description,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке базы знаний',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const onFinish = async () => {
    setLoading(true);

    const knowledgeBase: KnowledgeBaseUpdatingRequest = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    };

    try {
      await knowledgeBaseKbApi.updateKnowledgeBase(id, knowledgeBase);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'База знаний изменена',
        description: knowledgeBase.name,
      });
      goToKnowledgeBasePage();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении базы знаний',
        error: e,
      });
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader title="Редактирование базы знаний" />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <Input placeholder="Имя базы знаний" />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <TextArea placeholder="Назначение базы знаний" rows={4} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={goToKnowledgeBasePage}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default KnowledgeBaseEdit;
