import React, { useEffect, useState } from 'react';
import { Button, Space, Spin } from 'antd';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import {
  DraftModel,
  DraftTestRequest,
  InstanceModel,
  KnowledgeSourceModel,
  PublishStatusState,
} from '../../../../kb-api';
import { draftKbApi, instanceKbApi, knowledgeSourceKbApi } from '../../../apis';
import { AlertTypes, INSTANCE_PUBLISHED, INSTANCE_STATUS_UPDATED, KNOWLEDGE_SOURCE_PARSED } from '../../../constants';
import KBWebChat from '../../../components/KBWebChat';
import PublishStatusView from '../../../components/PublishStatusView';
import PublishReport from '../../../components/PublishReport';
import { hubConnections } from '../../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../../recoil/alerts';

export interface IDraftStatusViewProps {
  draftId?: string | null;
  testingChatTitle?: string | null;
  onKnowledgeSourceParsed?: () => void;
}

const DraftStatusView: React.FC<IDraftStatusViewProps> = ({
  draftId,
  testingChatTitle,
  onKnowledgeSourceParsed = () => {},
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getKnowledgeBaseConnection, []);
  const [publishing, setPublishing] = useState(false);
  const [draft, setDraft] = useState<DraftModel>();
  const [instance, setInstance] = useState<InstanceModel>();
  const [knowledgeSource, setKnowledgeSource] = useState<KnowledgeSourceModel>();

  const loadDraftAsync = async () => {
    if (!draftId) return;
    try {
      const response = await draftKbApi.getDraft(draftId);
      setDraft(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке черновика',
        error: e,
      });
    }
  };
  const loadDraft = () => {
    loadDraftAsync();
  };
  useEffect(loadDraft, [draftId]);

  const loadInstanceAsync = async () => {
    if (!draft?.instanceId) return;
    try {
      const response = await instanceKbApi.getInstance(draft.instanceId);
      setInstance(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке экземпляра',
        error: e,
      });
    }
  };
  const loadInstance = () => {
    loadInstanceAsync();
  };
  useEffect(loadInstance, [draft]);

  const loadKnowledgeSourceAsync = async () => {
    if (!draft?.editionId) return;
    try {
      // NOTE: пока для черновика поддерживается один источник данных
      const response = await knowledgeSourceKbApi.searchKnowledgeSources(undefined, draft.editionId, undefined, 0, 10);
      setKnowledgeSource(response.data?.items?.pop());
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке источника базы знаний',
        error: e,
      });
    }
  };
  const loadKnowledgeSource = () => {
    loadKnowledgeSourceAsync();
  };
  useEffect(loadKnowledgeSource, [draft]);

  const instanceEventHandler = (args: { instanceId: string }) => {
    if (instance?.id === args?.instanceId) {
      loadInstanceAsync();
    }
  };
  const subscribeToInstanceEvents = () => {
    if (!instance || !conn) return;

    conn.on(INSTANCE_PUBLISHED, instanceEventHandler);
    conn.on(INSTANCE_STATUS_UPDATED, instanceEventHandler);

    return () => {
      conn.off(INSTANCE_PUBLISHED, instanceEventHandler);
      conn.off(INSTANCE_STATUS_UPDATED, instanceEventHandler);
    };
  };
  useEffect(subscribeToInstanceEvents, [conn, instance]);

  const knowledgeSourceEventHandler = (args: { knowledgeSourceId: string }) => {
    if (knowledgeSource?.id === args?.knowledgeSourceId) {
      loadKnowledgeSourceAsync();
      onKnowledgeSourceParsed();
    }
  };
  const subscribeToKnowledgeSourceEvents = () => {
    if (!knowledgeSource || !conn) return;

    conn.on(KNOWLEDGE_SOURCE_PARSED, knowledgeSourceEventHandler);

    return () => {
      conn.off(KNOWLEDGE_SOURCE_PARSED, knowledgeSourceEventHandler);
    };
  };
  useEffect(subscribeToKnowledgeSourceEvents, [conn, knowledgeSource]);

  const onPublishButtonClick = async () => {
    if (!draft?.id) return;

    setPublishing(true);
    try {
      const request: DraftTestRequest = {
        model: {},
      };
      await draftKbApi.testDraft(draft?.id, request);
      await loadDraftAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при публикации черновика',
        error: e,
      });
    }
    setPublishing(false);
  };

  if (!draft || (draft?.instanceId && !instance) || !knowledgeSource) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <Button
          disabled={
            publishing ||
            instance?.status?.state === PublishStatusState.Pending ||
            instance?.status?.state === PublishStatusState.Processing
          }
          onClick={onPublishButtonClick}
        >
          Опубликовать
        </Button>
        <KBWebChat instance={instance} title={testingChatTitle} />
      </Space>
      <PublishStatusView detailed instance={instance} knowledgeSource={knowledgeSource} />
      <PublishReport detailed instance={instance} />
    </Space>
  );
};

export default DraftStatusView;
