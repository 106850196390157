import React, { memo, useRef } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import { Flags } from 'react-feature-flags';

import {
  ButtonType,
  ChoiceTransitSchema,
  CreateElma365AppElementActionSchema,
  DefaultActionGroupSchema,
  Elma365ProcessStartType,
  EndScenarioOutputSchema,
  FileInputMode,
  InputActionSchema,
  MenuActionSchema,
  MenuOptionsFillingMode,
  SchemaKind,
  ScriptActionSchema,
  SendEmailSchema,
  StartElma365ProcessActionSchema,
  SwitchActionSchema,
  TextOutputSchema,
  TransferToOperatorSchema,
  ExternalSignInSchema,
  SendSuccessfullServiceEventSchema,
  SuccessfullServiceEventName,
  CloseConversationSchema,
  SendCustomEventSchema,
  Elma365SessionType,
  MergeInboxContactsSchema,
} from '../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  dragTargetSelector,
  selectedEntitySelector,
} from '../../../../recoil/scenarioStructure';
import {
  actionContainsChildEntity,
  EntityFieldPath,
  generateId,
  getElementId,
  getNewMessageId,
  instanceOfChoiceTransitSchema,
  instanceOfCloseConversationSchema,
  instanceOfConfirmTransitSchema,
  instanceOfCreateElma365AppElementActionSchema,
  instanceOfDateTimeInputSchema,
  instanceOfElma365AppElementInputSchema,
  instanceOfElma365EnumInputSchema,
  instanceOfElma365UserInputSchema,
  instanceOfEmailAddressInputSchema,
  instanceOfEndScenarioOutputSchema,
  instanceOfExternalSigninSchema,
  instanceOfFileInputSchema,
  instanceOfLinkInputSchema,
  instanceOfLocationInputSchema,
  instanceOfMenuActionSchema,
  instanceOfMergeInboxContactsSchema,
  instanceOfNumberInputSchema,
  instanceOfPersonNameInputSchema,
  instanceOfPhoneNumberInputSchema,
  instanceOfScriptActionSchema,
  instanceOfSendCustomEventSchema,
  instanceOfSendEmailSchema,
  instanceOfSendSuccessfullServiceEventSchema,
  instanceOfStartElma365ProcessActionSchema,
  instanceOfSuggestionInputSchema,
  instanceOfSwitchActionSchema,
  instanceOfTextInputSchema,
  instanceOfTextOutputSchema,
  instanceOfTransferToOperatorSchema,
} from '../../utils';
import ConfirmTransit from '../actions/ConfirmTransit';
import ChoiceTransit from '../actions/ChoiceTransit';
import TextOutput from '../actions/TextOutput';
import TextInput from '../actions/TextInput';
import PhoneNumberInput from '../actions/PhoneNumberInput';
import LocationInput from '../actions/LocationInput';
import NumberInput from '../actions/NumberInput';
import DateTimeInput from '../actions/DateTimeInput';
import PersonNameInput from '../actions/PersonNameInput';
import LinkInput from '../actions/LinkInput';
import EmailAddressInput from '../actions/EmailAddressInput';
import EndScenarioOutput from '../actions/EndScenarioOutput';
import SuggestionInput from '../actions/SuggestionInput';
import TransferToOperator from '../actions/TransferToOperator';
import MenuAction from '../actions/MenuAction';
import ScriptAction from '../actions/ScriptAction';
import Elma365UserInput from '../actions/Elma365UserInput';
import Elma365AppElementInput from '../actions/Elma365AppElementInput';
import GroupContainer from '../common/GroupContainer';
import AddButton from '../common/AddButton';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import OutputConnection from '../common/OutputConnection';
import { DEFAULT_INPUT_MAX_TURN_COUNT } from '../../constants';
import SwitchAction from '../actions/SwitchAction';
import StartElma365Process from '../actions/StartElma365Process';
import CreateElma365AppElement from '../actions/CreateElma365AppElement';
import Elma365EnumInput from '../actions/Elma365EnumInput';
import FileInput from '../actions/FileInput';
import ExternalSignIn from '../actions/ExternalSignIn';
import { SCRIPT_EXECUTION_TIMEOUT_DEFAULT } from '../../../../simple-bot/const';
import SendEmail from '../actions/SendEmail';
import SbMenu from '../../../../simple-bot/components/common/SbMenu';
import { FeatureFlagNames } from '../../../../constants';
import SendSuccessfullServiceEvent from '../actions/SendSuccessfullServiceEvent';
import CloseConversation from '../actions/CloseConversation';
import SendCustomEvent from '../actions/SendCustomEvent';
import MergeInboxContacts from '../actions/MergeInboxContacts';

import InputConnectionPositionsUpdater from './InputConnectionPositionsUpdater';
import Placeholder from './Placeholder';

interface DefaultActionGroupProps {
  group: DefaultActionGroupSchema;
}

const DefaultActionGroup: React.FC<DefaultActionGroupProps> = ({ group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const setDragTarget = useSetRecoilState(dragTargetSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const groupRef = useRef<HTMLDivElement>(null);
  const rightInputHandleRef = useRef<HTMLDivElement>(null);
  const leftInputHandleRef = useRef<HTMLDivElement>(null);

  const childSelected =
    selectedEntity &&
    group.actions.some(
      // NOTE: startsWith для проверки когда добавляется пустое новое сообщение через getNewMessageId,
      // т.к. это сообщение сразу в структуру сценария не добавляется
      (action) => selectedEntity.id.startsWith(action.id) || actionContainsChildEntity(action, selectedEntity?.id)
    );

  const onInputActionSelect = (kind: SchemaKind) => () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: InputActionSchema = {
      id: generateId('ACT'),
      $kind: kind,
      messages: [],
      variableId: null,
      validators: [],
      required: true,
      cancellable: false,
      defaultValueResponseMessages: [],
      unrecognizedPromptMessages: [],
      customValidationErrorMessages: [],
      canShowHelp: false,
      helpMessages: [],
      canTransferToOperator: false,
      maxTurnCount: DEFAULT_INPUT_MAX_TURN_COUNT,
      alwaysPrompt: true,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    if (kind === SchemaKind.FileInput) {
      newAction.fileInputMode = FileInputMode.Multiple;
    }

    if (kind == SchemaKind.PhoneNumberInput) {
      newAction.contactRequired = false;
      newAction.contactButtonText = null;
    }

    setSelectedEntity({ id: getNewMessageId(newAction.id), $kind: SchemaKind.Message });
    setScenarioStructure(newScenarioStructure);
  };

  const onTextOutputActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: TextOutputSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.TextOutput,
      messages: [],
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setSelectedEntity({ id: getNewMessageId(newAction.id), $kind: SchemaKind.Message });
    setScenarioStructure(newScenarioStructure);
  };

  const onChoiceTransitActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: ChoiceTransitSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.ChoiceTransit,
      messages: [],
      validators: [],
      buttons: [],
      required: true,
      cancellable: false,
      defaultValueResponseMessages: [],
      unrecognizedPromptMessages: [],
      customValidationErrorMessages: [],
      canShowHelp: false,
      helpMessages: [],
      canTransferToOperator: false,
      maxTurnCount: DEFAULT_INPUT_MAX_TURN_COUNT,
      alwaysPrompt: true,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setSelectedEntity({ id: getNewMessageId(newAction.id), $kind: SchemaKind.Message });
    setScenarioStructure(newScenarioStructure);
  };

  const onConfirmTransitActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: ChoiceTransitSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.ConfirmTransit,
      messages: [],
      validators: [],
      buttons: [
        {
          id: generateId('BTN'),
          $kind: SchemaKind.Button,
          value: 'Да',
          synonyms: ['угу', 'дэ'],
          outputBindingId: null,
          type: ButtonType.Transit,
        },
        {
          id: generateId('BTN'),
          $kind: SchemaKind.Button,
          value: 'Нет',
          synonyms: ['неа', 'не'],
          outputBindingId: null,
          type: ButtonType.Transit,
        },
      ],
      required: true,
      cancellable: false,
      defaultValueResponseMessages: [],
      unrecognizedPromptMessages: [],
      customValidationErrorMessages: [],
      canShowHelp: false,
      helpMessages: [],
      canTransferToOperator: false,
      maxTurnCount: DEFAULT_INPUT_MAX_TURN_COUNT,
      alwaysPrompt: true,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setSelectedEntity({ id: getNewMessageId(newAction.id), $kind: SchemaKind.Message });
    setScenarioStructure(newScenarioStructure);
  };

  const onEndScenarioOutputActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const id = generateId('ACT');
    const newAction: EndScenarioOutputSchema = {
      id,
      $kind: SchemaKind.EndScenarioOutput,
      messages: [],
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onTransferToOperatorActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const id = generateId('ACT');
    const newAction: TransferToOperatorSchema = {
      id,
      $kind: SchemaKind.TransferToOperator,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onMenuActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const id = generateId('ACT');
    const newAction: MenuActionSchema = {
      id,
      $kind: SchemaKind.MenuAction,
      optionsFillingMode: MenuOptionsFillingMode.Auto,
      buttons: [],
      messages: [
        { $kind: SchemaKind.Message, id: generateId('MSG'), content: 'Выберите один из вариантов:', attachments: [] },
      ],
      validators: [],
      required: true,
      cancellable: false,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onStartElma365ProcessActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const id = generateId('SEP');
    const newAction: StartElma365ProcessActionSchema = {
      id,
      $kind: SchemaKind.StartElma365Process,
      startBy: Elma365ProcessStartType.ByStartEvent,
      formProperties: [],
      outputBindingId: undefined,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onCreateElma365AppElementActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const id = generateId('CAE');
    const newAction: CreateElma365AppElementActionSchema = {
      id,
      $kind: SchemaKind.CreateElma365AppElement,
      formProperties: [],
      outputBindingId: undefined,
      sessionType: Elma365SessionType.None,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onScriptActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const contentLines = [
      '// Примеры использования скрипта:',
      '//',
      '// 1. Входные переменные, передаваемые в скрипт:',
      '// console.log(context.input.variables.data_nachala_komandirovki);',
      "// console.log(context.input.variables['data_nachala_komandirovki']);",
      "// console.log(context.input.variables['Дата начала командировки']);",
      '//',
      '// 2. Выходные переменные, возвращаемые из скрипта:',
      '// context.output.variables.itogovoe_kolichestvo = 238',
      "// context.output.variables['itogovoe_kolichestvo'] = 238;",
      "// context.output.variables['Итоговое количество'] = 238;",
      '//',
      '// 3. Логирование:',
      "// context.output.logger.log('Сообщение', 'Параметр 1', 2, 3);",
      "// context.output.logger.error('Ошибка', 'Параметр 1', 2, 3);",
      "// context.output.logger.warn('Предупреждение', 'Параметр 1', 2, 3);",
      "// context.output.logger.debug('Отладка', 'Параметр 1', 2, 3);",
      "// context.output.logger.verbose('лог', 'Параметр 1', 2, 3);",
      '//',
      '// 4. Доступ к логам:',
      '// const level = context.output.logs[0].level;',
      '// const message = context.output.logs[0].message;',
      '// const param1 = context.output.logs[0].params[0];',
      '// const param2 = context.output.logs[0].params[1];',
    ];

    const id = generateId('ACT');
    const newAction: ScriptActionSchema = {
      id,
      $kind: SchemaKind.ScriptAction,
      content: contentLines.join('\n'),
      executionTimeout: SCRIPT_EXECUTION_TIMEOUT_DEFAULT,
      retryCount: 0,
      retryDelay: 1,
      outputBindingId: undefined,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onSwitchActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const id = generateId('ACT');
    const newAction: SwitchActionSchema = {
      id,
      $kind: SchemaKind.SwitchAction,
      mainCases: [],
      elseCase: {
        $kind: SchemaKind.ElseCaseAction,
        id: generateId('ECS'),
        outputBindingId: null,
      },
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onExternalSigninSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: ExternalSignInSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.ExternalSignIn,
      maxTurnCount: DEFAULT_INPUT_MAX_TURN_COUNT,
      prompt: 'Вы еще не завершили вход полностью. Выполните все необходимые действия в окне браузера.',
      text: 'Вход через OAuth',
      title: 'Войти',
      protocol: {
        id: generateId('AUT'),
        $kind: SchemaKind.OAuth2LoginProtocol,
        authorizeEndpoint: 'https://oauth.example.com/authorize',
        tokenEndpoint: 'https://oauth.example.com/token',
        jwksEndpoint: 'https://oauth.example.com/jwks',
        clientId: 'client',
        clientSecret: 'secret',
        scopes: 'openid',
      },
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onSendEmailActionSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: SendEmailSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.SendEmail,
      recipients: [],
      subject: {
        id: generateId('OPD'),
        $kind: SchemaKind.ConstantOperand,
        value: '',
      },
      body: '',
      bccRecipients: [],
      ccRecipients: [],
      attachments: [],
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onSendSuccessfullServiceEventSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: SendSuccessfullServiceEventSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.SendSuccessfullServiceEvent,
      eventName: SuccessfullServiceEventName.UserAnsweredYes,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onCloseConversationSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: CloseConversationSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.CloseConversation,
      closingMessage: '',
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onSendCustomEventSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: SendCustomEventSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.SendCustomEvent,
      eventName: 'Название события',
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const onMergeInboxContactsSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newAction: MergeInboxContactsSchema = {
      id: generateId('ACT'),
      $kind: SchemaKind.MergeInboxContacts,
      contactIdentifierKind: 'phone',
      mergeFields: false,
    };
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions.push(newAction);

    setScenarioStructure(newScenarioStructure);
  };

  const renderGroupActionsMenu = () => (
    <>
      <SbMenu.ItemGroup title="Основные">
        <SbMenu.Item
          key="text-output"
          icon={<SbIcon iconName="add-text-two" size={18} />}
          sbType="dropdown"
          onClick={onTextOutputActionSelect}
        >
          Сообщение чат-бота
        </SbMenu.Item>
        <SbMenu.Item
          key="choice-transit"
          icon={<SbIcon iconName="help" size={18} />}
          sbType="dropdown"
          onClick={onChoiceTransitActionSelect}
        >
          Вопрос. Текст + кнопки
        </SbMenu.Item>
        <SbMenu.Item
          key="text-input"
          icon={<SbIcon iconName="help" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.TextInput)}
        >
          Вопрос. Ввод текста
        </SbMenu.Item>
        <SbMenu.Item
          key="end-scenario-output"
          icon={<SbIcon iconName="handle-x" size={18} />}
          sbType="dropdown"
          onClick={onEndScenarioOutputActionSelect}
        >
          Завершение сценария
        </SbMenu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.CONVERSATION_CLOSING]}>
          <SbMenu.Item
            key="end-conversation"
            icon={<SbIcon iconName="handle-x" size={18} />}
            sbType="dropdown"
            onClick={onCloseConversationSelect}
          >
            Завершение беседы
          </SbMenu.Item>
        </Flags>
        <SbMenu.Item
          key="menu-action"
          icon={<SbIcon iconName="list-two" size={18} />}
          sbType="dropdown"
          onClick={onMenuActionSelect}
        >
          Показать меню
        </SbMenu.Item>
        <SbMenu.Item
          key="script-action"
          icon={<SbIcon iconName="code-brackets" size={18} />}
          sbType="dropdown"
          onClick={onScriptActionSelect}
        >
          Скрипт
        </SbMenu.Item>
        <SbMenu.Item
          key="switch-action"
          icon={<SbIcon iconName="link-right" size={18} />}
          sbType="dropdown"
          onClick={onSwitchActionSelect}
        >
          Условное ветвление
        </SbMenu.Item>
        <Flags authorizedFlags={[FeatureFlagNames.INBOX_DISPLAY]}>
          <SbMenu.Item
            key="merge-inbox-contacts-action"
            icon={<SbIcon iconName="merge" size={18} />}
            sbType="dropdown"
            onClick={onMergeInboxContactsSelect}
          >
            Связать контакт
          </SbMenu.Item>
        </Flags>
      </SbMenu.ItemGroup>
      <SbMenu.SubMenu title="Внешние системы">
        <SbMenu.Item
          key="transfer-to-operator"
          icon={<SbIcon iconName="avatar" size={18} />}
          sbType="dropdown"
          onClick={onTransferToOperatorActionSelect}
        >
          Перевод на оператора
        </SbMenu.Item>
        <SbMenu.Item
          key="external-signin"
          icon={<SbIcon iconName="user" size={18} />}
          sbType="dropdown"
          onClick={onExternalSigninSelect}
        >
          Вход через OAuth
        </SbMenu.Item>
        <SbMenu.Item
          key="send-email-action"
          icon={<SbIcon iconName="mail" size={18} />}
          sbType="dropdown"
          onClick={onSendEmailActionSelect}
        >
          Отправить Email
        </SbMenu.Item>
      </SbMenu.SubMenu>
      <SbMenu.SubMenu title="Запрос данных">
        <SbMenu.Item
          key="date-time-input"
          icon={<SbIcon iconName="calendar-three" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.DateTimeInput)}
        >
          Дата/Время
        </SbMenu.Item>
        <SbMenu.Item
          key="phone-number-input"
          icon={<SbIcon iconName="phone-telephone" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.PhoneNumberInput)}
        >
          Телефон
        </SbMenu.Item>
        <SbMenu.Item
          key="email-address-input"
          icon={<SbIcon iconName="at-sign" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.EmailAddressInput)}
        >
          Email
        </SbMenu.Item>
        <SbMenu.Item
          key="number-input"
          icon={<SbIcon iconName="pound-sign" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.NumberInput)}
        >
          Число
        </SbMenu.Item>
        <SbMenu.Item
          key="link-input"
          icon={<SbIcon iconName="link-one" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.LinkInput)}
        >
          Ссылка
        </SbMenu.Item>
        <SbMenu.Item
          key="location-input"
          icon={<SbIcon iconName="local" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.LocationInput)}
        >
          Местоположение
        </SbMenu.Item>
        <SbMenu.Item
          key="person-name-input"
          icon={<SbIcon iconName="user" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.PersonNameInput)}
        >
          ФИО
        </SbMenu.Item>
        <SbMenu.Item
          key="confirm-transit"
          icon={<SbIcon iconName="checklist" size={18} />}
          sbType="dropdown"
          onClick={onConfirmTransitActionSelect}
        >
          Вопрос. Да/Нет
        </SbMenu.Item>
        <SbMenu.Item
          key="file-input"
          icon={<SbIcon iconName="upload-logs" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.FileInput)}
        >
          Файл
        </SbMenu.Item>
      </SbMenu.SubMenu>
      <SbMenu.SubMenu title="ELMA 365">
        <SbMenu.Item
          key="start-elma365-process-action"
          icon={<SbIcon iconName="play" size={20} />}
          sbType="dropdown"
          onClick={onStartElma365ProcessActionSelect}
        >
          Запуск бизнес-процесса
        </SbMenu.Item>
        <SbMenu.Item
          key="create-elma365-app-element-action"
          icon={<SbIcon iconName="add-three" size={18} />}
          sbType="dropdown"
          onClick={onCreateElma365AppElementActionSelect}
        >
          Создание элемента приложения
        </SbMenu.Item>
        <SbMenu.Item
          key="elma365-user-input"
          icon={<SbIcon iconName="me" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.Elma365UserInput)}
        >
          Выбор пользователя
        </SbMenu.Item>
        <SbMenu.Item
          key="elma365-app-element-input"
          icon={<SbIcon iconName="send-to-back" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.Elma365AppElementInput)}
        >
          Выбор элемента приложения
        </SbMenu.Item>
        <SbMenu.Item
          key="elma365-enum-input"
          icon={<SbIcon iconName="list-view" size={18} />}
          sbType="dropdown"
          onClick={onInputActionSelect(SchemaKind.Elma365EnumInput)}
        >
          Выбор категории
        </SbMenu.Item>
      </SbMenu.SubMenu>
      <Flags authorizedFlags={[FeatureFlagNames.ANALYTICS]}>
        <SbMenu.SubMenu title="Аналитика">
          <SbMenu.Item
            key="send-successfull-service-event"
            icon={<SbIcon iconName="success" size={20} />}
            sbType="dropdown"
            onClick={onSendSuccessfullServiceEventSelect}
          >
            Событие успешного обслуживания
          </SbMenu.Item>
          <SbMenu.Item
            key="send-custom-event"
            icon={<SbIcon iconName="people-plus" size={20} />}
            sbType="dropdown"
            onClick={onSendCustomEventSelect}
          >
            Пользователькое событие
          </SbMenu.Item>
        </SbMenu.SubMenu>
      </Flags>
    </>
  );

  return (
    <GroupContainer
      ref={groupRef}
      childSelected={childSelected}
      // isDraggedBy={
      //   // NOTE: для увеличения производительности вычисление свойства отключено.
      //   // В текущей реализации dragTarget перевычисляется при каждом наведении курсора на группу,
      //   // что вызывает рендеринг всех остальных групп и сильно замедляет перетаскивание и panning.
      //   // TODO: нужно придумать оптимизированный вариант или совсем отказаться от подсвечивания через isDraggedBy
      //   false /*(dragTarget && dragTarget.id === group.id && dragSource && dragSource.id !== group.id) ?? false*/
      // }
      group={group}
      onMouseEnter={() => {
        setDragTarget(group);
      }}
      onMouseLeave={() => {
        setDragTarget(undefined);
      }}
    >
      <Droppable droppableId={group.id} isDropDisabled={!selectedEntity}>
        {(provided, snapshot) => (
          <div className="group-elements-container" {...provided.droppableProps} ref={provided.innerRef}>
            {group.actions.map((action, index) => {
              if (instanceOfTextInputSchema(action)) {
                return <TextInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfNumberInputSchema(action)) {
                return <NumberInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfDateTimeInputSchema(action)) {
                return <DateTimeInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfEmailAddressInputSchema(action)) {
                return <EmailAddressInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfPhoneNumberInputSchema(action)) {
                return <PhoneNumberInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfLinkInputSchema(action)) {
                return <LinkInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfLocationInputSchema(action)) {
                return <LocationInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfPersonNameInputSchema(action)) {
                return <PersonNameInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfSuggestionInputSchema(action)) {
                return <SuggestionInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfFileInputSchema(action)) {
                return <FileInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfTextOutputSchema(action)) {
                return <TextOutput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfConfirmTransitSchema(action)) {
                return <ConfirmTransit key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfChoiceTransitSchema(action)) {
                return <ChoiceTransit key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfEndScenarioOutputSchema(action)) {
                return <EndScenarioOutput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfTransferToOperatorSchema(action)) {
                return <TransferToOperator key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfMenuActionSchema(action)) {
                return <MenuAction key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfScriptActionSchema(action)) {
                return <ScriptAction key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfSwitchActionSchema(action)) {
                return <SwitchAction key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfStartElma365ProcessActionSchema(action)) {
                return <StartElma365Process key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfCreateElma365AppElementActionSchema(action)) {
                return <CreateElma365AppElement key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfElma365UserInputSchema(action)) {
                return <Elma365UserInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfElma365AppElementInputSchema(action)) {
                return <Elma365AppElementInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfElma365EnumInputSchema(action)) {
                return <Elma365EnumInput key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfExternalSigninSchema(action)) {
                return <ExternalSignIn key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfSendEmailSchema(action)) {
                return <SendEmail key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfSendSuccessfullServiceEventSchema(action)) {
                return <SendSuccessfullServiceEvent key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfCloseConversationSchema(action)) {
                return <CloseConversation key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfSendCustomEventSchema(action)) {
                return <SendCustomEvent key={action.id} action={action} group={group} index={index} />;
              }
              if (instanceOfMergeInboxContactsSchema(action)) {
                return <MergeInboxContacts key={action.id} action={action} group={group} index={index} />;
              }
              throw new Error(`Тип действия ${action.$kind} не поддерживается.`);
            })}
            {provided.placeholder && <Placeholder provided={provided} snapshot={snapshot} />}
          </div>
        )}
      </Droppable>
      <AddButton groupId={group.id} menuContent={renderGroupActionsMenu()} title="Добавить элемент" />
      <OutputConnection
        entity={group}
        groupId={group.id}
        id={getElementId(group.id, EntityFieldPath.OutputBindingId)}
        isValid={!scenarioValidation?.hasIssue(group.id, EntityFieldPath.OutputBindingId)}
      />
      <div ref={rightInputHandleRef} className="input-connection input-connection_right" />
      <div ref={leftInputHandleRef} className="input-connection input-connection_left" />
      <InputConnectionPositionsUpdater
        groupId={group.id}
        groupRef={groupRef}
        leftInputHandleRef={leftInputHandleRef}
        rightInputHandleRef={rightInputHandleRef}
      />
    </GroupContainer>
  );
};

export default memo(DefaultActionGroup);
