import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from '../../types';

export const AudioDefaultIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          clipRule="evenodd"
          d="M8.33203 0C5.57061 0 3.33203 2.23858 3.33203 5V35C3.33203 37.7614 5.57061 40 8.33203 40H31.6654C34.4268 40 36.6654 37.7614 36.6654 35V5C36.6654 4.94547 36.6645 4.89115 36.6628 4.83704V6.66667L29.9961 0H8.33203Z"
          fill="#EDFAFF"
          fillRule="evenodd"
        />
        <path clipRule="evenodd" d="M30 0V6.66667H36.6667L30 0Z" fill="#C2CDDE" fillRule="evenodd" />
        <path
          d="M20.0013 12.5V27.5C17.0846 27.5 14.9173 23.683 14.9173 23.683H12.5013C12.0411 23.683 11.668 23.3099 11.668 22.8496V17.0878C11.668 16.6276 12.0411 16.2545 12.5013 16.2545H14.9173C14.9173 16.2545 17.0846 12.5 20.0013 12.5Z"
          stroke="#075AB3"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M23.332 16.25C23.5917 16.4819 23.8271 16.7415 24.0337 17.0245C24.6398 17.8543 24.9987 18.8843 24.9987 20C24.9987 21.106 24.646 22.1278 24.0494 22.9539C23.839 23.2452 23.5983 23.5122 23.332 23.75"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M24.2656 27.161C26.7022 25.7066 28.334 23.044 28.334 20.0003C28.334 17.0038 26.7525 14.3767 24.3786 12.9082"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="40" transform="translate(3.33594)" width="33.3333" />
        </clipPath>
      </defs>
    </svg>
  );
});
