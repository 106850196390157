import React from 'react';
import { Col, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';

import { LengthComparisonValidatorSchema, BinaryConditionOperator } from '../../../../../../api';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import { LengthBinaryConditionOperators } from '../../../constants';
import { BUTTON_COLUMN_WIDTH, OPERATOR_COLUMN_WIDTH, TABLE_GUTTER_SIZE, TITLE_COLUMN_WIDTH } from '../const';

interface ILengthComparisonProps {
  validator: LengthComparisonValidatorSchema;
  onChange: (validator: LengthComparisonValidatorSchema) => void;
  onDelete: () => void;
}

const LengthComparison: React.FC<ILengthComparisonProps> = ({ validator, onChange, onDelete }) => {
  const onOperatorChange = (value: SelectValue) => {
    onChange({
      ...validator,
      operator: value as BinaryConditionOperator,
    });
  };

  const onLengthChange = (value?: string) => {
    onChange({
      ...validator,
      length: value ? parseInt(value) || 0 : 0,
    });
  };

  const onDeleteClick = () => {
    onDelete();
  };

  return (
    <Row align="middle" gutter={[TABLE_GUTTER_SIZE, TABLE_GUTTER_SIZE]} style={{ marginRight: 0 }}>
      <Col flex={`${TITLE_COLUMN_WIDTH}px`}>
        <h4>Значение</h4>
      </Col>
      <Col flex={`${OPERATOR_COLUMN_WIDTH}px`}>
        <SbSelect
          options={LengthBinaryConditionOperators}
          sbSize="big"
          sbType="light"
          style={{ maxWidth: `${OPERATOR_COLUMN_WIDTH - TABLE_GUTTER_SIZE}px` }}
          value={validator.operator}
          onChange={onOperatorChange}
        />
      </Col>
      <Col flex="1 1 0">
        <SbInput
          allowClear={!!validator.length}
          sbSize="big"
          value={validator.length.toString()}
          onChange={onLengthChange}
        />
      </Col>
      <Col flex={`${BUTTON_COLUMN_WIDTH}px`} style={{ paddingRight: '0' }}>
        <SbButton icon={<SbIcon iconName="close" size={21} />} sbType="icon-only" onClick={onDeleteClick} />
      </Col>
    </Row>
  );
};

export default LengthComparison;
