import React, { ReactElement } from 'react';

import './index.less';

import { Channels } from '../../../../constants';

import {
  TelegramIcon,
  TelegramSmallIcon,
  ViberIcon,
  ViberSmallIcon,
  ChatDeskIcon,
  ChatDeskSmallIcon,
  WhatsAppIcon,
  WhatsAppSmallIcon,
  WebChatIcon,
  WebChatSmallIcon,
  LiveChatSmallIcon,
  LiveChatIcon,
} from './icons';

const getChannelIcon = (type: Channels, size: 'normal' | 'small'): ReactElement => {
  if (size === 'small') {
    switch (type) {
      case Channels.TELEGRAM:
        return <TelegramSmallIcon />;
      case Channels.WHATSAPP:
        return <WhatsAppSmallIcon />;
      case Channels.VIBER:
        return <ViberSmallIcon />;
      case Channels.ELMA365:
        return <ChatDeskSmallIcon />;
      case Channels.WEBCHAT:
        return <WebChatSmallIcon />;
      case Channels.LIVECHAT:
        return <LiveChatSmallIcon />;
      default:
        return <WebChatSmallIcon />;
    }
  }

  switch (type) {
    case Channels.TELEGRAM:
      return <TelegramIcon />;
    case Channels.WHATSAPP:
      return <WhatsAppIcon />;
    case Channels.VIBER:
      return <ViberIcon />;
    case Channels.ELMA365:
      return <ChatDeskIcon />;
    case Channels.WEBCHAT:
      return <WebChatIcon />;
    case Channels.LIVECHAT:
      return <LiveChatIcon />;
    default:
      return <WebChatIcon />;
  }
};

interface SbChannelIconProps {
  type: Channels;
  size: 'normal' | 'small';
}

const SbChannelIcon: React.FC<SbChannelIconProps> = ({ type, size }) => {
  const className = 'sb-channel-icon';
  const classNames = [className];
  size === 'normal' && classNames.push(`${className}_normal`);
  size === 'small' && classNames.push(`${className}_small`);

  return <div className={classNames.join(' ')}>{getChannelIcon(type, size)}</div>;
};

export default SbChannelIcon;
