import { InboxChatModel, InboxChatParticipantStatus, InboxChatStatus } from '../../../api';
import { inboxChatApi } from '../../apis';
import { ITaskData, ITaskQueue } from '../tasks';
import { EventType, IEventBus } from '../events';
import { INBOX_CHAT_CREATED, INBOX_CHAT_UPDATED, SUBJECT_ROLES } from '../../constants';
import { hubConnections } from '../../utils/socketsUtil';
import { IUserProfile } from '../../utils/oidcUtil';
import { isSequencesEqual, getUndefinedIfEmpty } from '../../utils/arrayUtil';
import { includesIgnoreCase } from '../../utils/stringUtil';

import { EntityFilter, IEntityData, IEntityPaging, IEntityResponse, StorageDirection } from './types';
import { EntityStorage } from './storage';
import { TableName, IDbTable, PageSizes } from './table';
import { IDbCollection } from './collection';

const EVENT_TYPE_MAP = {
  'inbox.chat.queued': EventType.DomainInboxChatQueued,
  'inbox.chat.closed': EventType.DomainInboxChatClosed,
  'inbox.chat.bot.assigned': EventType.DomainInboxChatBotAssigned,
  'inbox.chat.operator.assigned': EventType.DomainInboxChatOperatorAssigned,
  'inbox.chat.message.received': EventType.DomainInboxChatMessageReceived,
  'inbox.chat.message.transferred': EventType.DomainInboxChatMessageTransferred,
  'inbox.chat.message.interchanged': EventType.DomainInboxChatMessageInterchanged,
};

interface IInboxChatArgs {
  eventTypes: string[];
  inboxChat: InboxChatModel;
}

export class InboxChatFilter extends EntityFilter<InboxChatModel, InboxChatFilter> {
  public readonly statuses?: InboxChatStatus[];
  public readonly participantStatuses?: InboxChatParticipantStatus[];
  public readonly subjectIds?: string[];
  public readonly subjectRoles?: string[];
  public readonly tagIds?: string[];
  public readonly searchText?: string;

  public constructor(
    statuses?: InboxChatStatus[],
    participantStatuses?: InboxChatParticipantStatus[],
    subjectIds?: string[],
    subjectRoles?: string[],
    tagIds?: string[],
    searchText?: string
  ) {
    super();
    this.statuses = getUndefinedIfEmpty(statuses);
    this.participantStatuses = getUndefinedIfEmpty(participantStatuses);
    this.subjectIds = getUndefinedIfEmpty(subjectIds);
    this.subjectRoles = getUndefinedIfEmpty(subjectRoles);
    this.tagIds = getUndefinedIfEmpty(tagIds);
    this.searchText = searchText?.trim().toLowerCase() || undefined;
  }

  public equals(filter: InboxChatFilter): boolean {
    return (
      isSequencesEqual(this.statuses, filter.statuses) &&
      isSequencesEqual(this.participantStatuses, filter.participantStatuses) &&
      isSequencesEqual(this.subjectIds, filter.subjectIds) &&
      isSequencesEqual(this.subjectRoles, filter.subjectRoles) &&
      isSequencesEqual(this.tagIds, filter.tagIds) &&
      this.searchText === filter.searchText
    );
  }

  public satisfies(entity: InboxChatModel): boolean {
    if (this.statuses?.length && !this.statuses?.includes(entity.status)) {
      return false;
    }

    if (
      (this.participantStatuses?.length || this.subjectIds?.length) &&
      !entity.participants?.some(
        (p) =>
          (!this.participantStatuses?.length || this.participantStatuses?.includes(p.status)) &&
          (!this.subjectIds?.length || this.subjectIds?.includes(p.subject.id))
      )
    ) {
      return false;
    }

    if (this.subjectRoles?.length && !entity.participants?.some((p) => this.subjectRoles?.includes(p.subject.role))) {
      return false;
    }

    if (this.tagIds?.length) {
      for (const tagId of this.tagIds) {
        if (
          !entity.participants.some(
            (p) => p.subject.role === SUBJECT_ROLES.user && p.subject.person.tags.some((t) => t.id === tagId)
          )
        ) {
          return false;
        }
      }
    }

    const searchText = this.searchText;
    return !(
      searchText &&
      !entity.participants?.some(
        (p) => p.subject.role === SUBJECT_ROLES.user && includesIgnoreCase(p.subject.person.name.fullName, searchText)
      )
    );
  }
}

export class InboxChatStorage extends EntityStorage<InboxChatModel, InboxChatFilter> {
  private static s_instance?: InboxChatStorage;

  public constructor(
    userProfile?: IUserProfile,
    eventBus?: IEventBus,
    taskQueue?: ITaskQueue,
    dbTable?: IDbTable<IEntityData<InboxChatModel>, string>
  ) {
    super(
      TableName.StorageChats,
      { direction: StorageDirection.Backward, pageSize: PageSizes.storageChats },
      userProfile,
      eventBus,
      taskQueue,
      dbTable
    );
  }

  public static get instance(): InboxChatStorage {
    if (!InboxChatStorage.s_instance) {
      InboxChatStorage.s_instance = new InboxChatStorage();
    }

    return InboxChatStorage.s_instance;
  }

  public getKey(entity: InboxChatModel): string {
    return entity.id;
  }

  protected async linkEvents(): Promise<void> {
    // NOTE: Не отписываемся от событий т.к. класс сейчас singleton (время жизни соответствует времени жизни приложения).
    const connection = await hubConnections.getBotManagerConnection();
    connection.on(INBOX_CHAT_CREATED, (args: IInboxChatArgs) => this.onCreate(args).finally());
    connection.on(INBOX_CHAT_UPDATED, (args: IInboxChatArgs) => this.onUpdate(args).finally());
  }

  protected loadMe(): Promise<InboxChatModel> {
    throw new Error('Operation is not supported.');
  }

  protected async loadItem(key: string): Promise<InboxChatModel> {
    const response = await inboxChatApi.getInboxChat(key);

    return response.data;
  }

  protected async loadPage(filter: InboxChatFilter, paging: IEntityPaging): Promise<IEntityResponse<InboxChatModel>> {
    const response = await inboxChatApi.searchInboxChats(
      filter.statuses,
      filter.participantStatuses,
      filter.subjectIds,
      filter.subjectRoles,
      filter.tagIds,
      filter.searchText,
      paging.pageIndex,
      paging.pageSize
    );

    return response.data as IEntityResponse<InboxChatModel>;
  }

  protected applyOrder(
    table: IDbTable<IEntityData<InboxChatModel>, string>
  ): IDbCollection<IEntityData<InboxChatModel>, string> {
    return table.orderBy(['order', 'entity.statistics.lastMessage.anyOn']);
  }

  protected buildCreationTask(): ITaskData<unknown> {
    throw new Error('Operation is not supported.');
  }

  protected buildDeletionTask(): ITaskData<unknown> {
    throw new Error('Operation is not supported.');
  }

  protected canProcess(): boolean {
    return false;
  }

  protected extractEntity(): InboxChatModel | undefined {
    return undefined;
  }

  protected compareEntity(x: InboxChatModel, y: InboxChatModel): number {
    if (!x.statistics.lastMessage.anyOn) {
      if (!y.statistics.lastMessage.anyOn) {
        return 0;
      }

      return -1;
    } else if (!y.statistics.lastMessage.anyOn) {
      return 1;
    }

    if (x.statistics.lastMessage.anyOn < y.statistics.lastMessage.anyOn) {
      return -1;
    }

    if (x.statistics.lastMessage.anyOn > y.statistics.lastMessage.anyOn) {
      return 1;
    }

    return 0;
  }

  protected withTenant(): boolean {
    return true;
  }

  private async onCreate(args: IInboxChatArgs): Promise<void> {
    const entity = args.inboxChat;
    const data = this.buildData(0, entity);
    await this.createItem(data);
    this.emitDomainEvents(entity, args.eventTypes, EVENT_TYPE_MAP);
  }

  private async onUpdate(args: IInboxChatArgs): Promise<void> {
    const entity = args.inboxChat;
    const data = this.buildData(0, entity);
    await this.modifyItem(data);
    this.emitDomainEvents(entity, args.eventTypes, EVENT_TYPE_MAP);
  }
}
