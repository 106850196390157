import React from 'react';
import { Form } from 'antd';

import './index.less';
import { EditableContext } from '../EditableCell';

const EditableRow: React.FC = ({ ...otherProps }) => {
  const [form] = Form.useForm();
  return (
    <Form component={false} form={form}>
      <EditableContext.Provider value={form}>
        <tr className="sb-table__editable-row" {...otherProps} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;
