import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Button, Divider, Input } from 'antd';
import Editor from '@draft-js-plugins/editor';
import { EditorCommand, EditorState, RichUtils } from 'draft-js';
import { CallbackInterface, useRecoilCallback } from 'recoil';

import './index.less';

import SbIcon from '../SbIcon';
import SbTooltip from '../SbTooltip';
import {
  addLink,
  BlockTypes,
  editorStateToMarkdown,
  EntityType,
  InlineStyles,
  toggleBlockType,
  toggleInlineStyle,
} from '../../../../utils/markdownEditor';
import { VariableSchema } from '../../../../../api';
import VariableSelector from '../../../../components/ScenarioEditor/components/VariableSelector';
import { VariableIcon } from '../../../../components/ScenarioEditor/assets';
import { variableUsagesSelector } from '../../../../recoil/scenarioStructure';

import { getDecorators, insertEntity, markdownToEditorState } from './utils';

const MAIN_CLASS_NAME = 'sb-markdown-editor';
const TOOLBAR_CLASS_NAME = `${MAIN_CLASS_NAME}__toolbar`;
const TOOLBAR_ACTIONS_CLASS_NAME = `${TOOLBAR_CLASS_NAME}__actions`;
const ACTION_BUTTON_CLASS_NAME = `${TOOLBAR_ACTIONS_CLASS_NAME}__button`;
const ACTION_BUTTON_SELECTED_CLASS_NAME = `${ACTION_BUTTON_CLASS_NAME}_selected`;
const VAR_TOOLTIP_WIDTH = 280;

interface ISbMarkdownEditorProps {
  value: string;
  search?: string;
  readOnly?: boolean;
  variables?: VariableSchema[];
  onVariablesChange?: (variables: VariableSchema[]) => void;
  isValid?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (value: string) => void;
}

const SbMarkdownEditor: React.FC<ISbMarkdownEditorProps> = ({
  value,
  search,
  isValid,
  variables,
  onBlur,
  onFocus,
  readOnly = false,
  onChange = () => {},
  onVariablesChange = () => {},
}) => {
  const [editorState, setEditorState] = useState(markdownToEditorState(value, readOnly, search, variables));
  const [linkTooltipVisible, setLinkTooltipVisible] = useState(false);
  const [variableTooltipVisible, setVariableTooltipVisible] = useState(false);
  const [link, setLink] = useState('');

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const onVariablesPropChange = () => setEditorState(markdownToEditorState(value, readOnly, search, variables));
  useEffect(onVariablesPropChange, [variables]);

  const currentInlineStyle = editorState.getCurrentInlineStyle();
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const startKey = selection.getStartKey();
  const block = content.getBlockForKey(startKey);
  const blockType = block.getType();

  const boldActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (currentInlineStyle.has(InlineStyles.BOLD)) boldActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const italicActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (currentInlineStyle.has(InlineStyles.ITALIC)) italicActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const h1ActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H1) h1ActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const h2ActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H2) h2ActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const h3ActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H3) h3ActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const h4ActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H4) h4ActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const h5ActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H5) h5ActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const h6ActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.H6) h6ActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const orderedListActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.ORDERED_LIST) orderedListActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const listActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.UNORDERED_LIST) listActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const linkActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (RichUtils.currentBlockContainsLink(editorState)) linkActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const blockquoteActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.BLOCKQUOTE) blockquoteActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const variableActionButtonClasses = [ACTION_BUTTON_CLASS_NAME];
  if (blockType === BlockTypes.VARIABLE) variableActionButtonClasses.push(ACTION_BUTTON_SELECTED_CLASS_NAME);

  const classes = [MAIN_CLASS_NAME];
  if (readOnly) {
    classes.push(`${MAIN_CLASS_NAME}_read-only`);
  }
  if (isValid === false) {
    classes.push(`${MAIN_CLASS_NAME}_warning`);
  }

  const onValuePropChange = () => {
    if (!readOnly) return;
    setEditorState(markdownToEditorState(value, readOnly, search));
  };
  useEffect(onValuePropChange, [value]);

  const onEditorStateChange = () => {
    if (!readOnly) {
      onChange(editorStateToMarkdown(editorState));
    }
  };
  useEffect(onEditorStateChange, [editorState]);

  const tryAddLink = () => {
    setLinkTooltipVisible(false);
    if (link) {
      setEditorState(addLink(editorState, link));
      setLink('');
    }
  };

  const onVariablesSelectorSettingsModalOpen = () => setVariableTooltipVisible(false);

  const onLinkInputBlur = () => tryAddLink();

  const onLinkInputPressEnter = () => tryAddLink();

  const onLinkInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setLink(e.target.value);

  const handleKeyCommand = (command: EditorCommand, editorState: EditorState) => {
    const newEditorState = RichUtils.handleKeyCommand(editorState, command);

    if (newEditorState) {
      setEditorState(newEditorState);
      return 'handled';
    }

    return 'not-handled';
  };

  const onVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    if (newVariables) onVariablesChange(newVariables);
    setVariableTooltipVisible(false);
    if (selectedVariable) setEditorState(insertEntity(editorState, `{${selectedVariable.id}}`, EntityType.VARIABLE));
  };

  const onUndo = () => setEditorState(EditorState.undo(editorState));
  const onRedo = () => setEditorState(EditorState.redo(editorState));
  const onBold = () => setEditorState(toggleInlineStyle(editorState, InlineStyles.BOLD));
  const onItalic = () => setEditorState(toggleInlineStyle(editorState, InlineStyles.ITALIC));
  const onH1 = () => setEditorState(toggleBlockType(editorState, BlockTypes.H1));
  const onH2 = () => setEditorState(toggleBlockType(editorState, BlockTypes.H2));
  const onH3 = () => setEditorState(toggleBlockType(editorState, BlockTypes.H3));
  const onH4 = () => setEditorState(toggleBlockType(editorState, BlockTypes.H4));
  const onH5 = () => setEditorState(toggleBlockType(editorState, BlockTypes.H5));
  const onH6 = () => setEditorState(toggleBlockType(editorState, BlockTypes.H6));
  const onOrderedList = () => setEditorState(toggleBlockType(editorState, BlockTypes.ORDERED_LIST));
  const onUnorderedList = () => setEditorState(toggleBlockType(editorState, BlockTypes.UNORDERED_LIST));
  const onLink = () => setLinkTooltipVisible(true);
  const onBlockquote = () => setEditorState(toggleBlockType(editorState, BlockTypes.BLOCKQUOTE));

  return (
    <div className={classes.join(' ')} role="none">
      <div className={TOOLBAR_CLASS_NAME}>
        <div className={TOOLBAR_ACTIONS_CLASS_NAME}>
          <Button className={ACTION_BUTTON_CLASS_NAME} type="link" onClick={onUndo}>
            <SbIcon iconName="undo" size={14} />
          </Button>
          <Button className={ACTION_BUTTON_CLASS_NAME} type="link" onClick={onRedo}>
            <SbIcon iconName="redo" size={14} />
          </Button>
          <Divider type="vertical" />
          <Button className={boldActionButtonClasses.join(' ')} type="link" onClick={onBold}>
            <SbIcon iconName="text-bold" size={14} />
          </Button>
          <Button className={italicActionButtonClasses.join(' ')} type="link" onClick={onItalic}>
            <SbIcon iconName="text-italic" size={14} />
          </Button>
          <Divider type="vertical" />
          <Button className={h1ActionButtonClasses.join(' ')} type="link" onClick={onH1}>
            H1
          </Button>
          <Button className={h2ActionButtonClasses.join(' ')} type="link" onClick={onH2}>
            H2
          </Button>
          <Button className={h3ActionButtonClasses.join(' ')} type="link" onClick={onH3}>
            H3
          </Button>
          <Button className={h4ActionButtonClasses.join(' ')} type="link" onClick={onH4}>
            H4
          </Button>
          <Button className={h5ActionButtonClasses.join(' ')} type="link" onClick={onH5}>
            H5
          </Button>
          <Button className={h6ActionButtonClasses.join(' ')} type="link" onClick={onH6}>
            H6
          </Button>
          <Divider type="vertical" />
          <Button className={orderedListActionButtonClasses.join(' ')} type="link" onClick={onOrderedList}>
            <SbIcon iconName="ordered-list" size={14} />
          </Button>
          <Button className={listActionButtonClasses.join(' ')} type="link" onClick={onUnorderedList}>
            <SbIcon iconName="list-two" size={14} />
          </Button>
          <Divider type="vertical" />
          <SbTooltip
            destroyTooltipOnHide
            placement="bottomRight"
            title={
              <Input.Group compact className={`${TOOLBAR_ACTIONS_CLASS_NAME}__link-tooltip-content`}>
                <Input
                  autoFocus
                  placeholder="Введите url"
                  value={link}
                  onBlur={onLinkInputBlur}
                  onChange={onLinkInputChange}
                  onPressEnter={onLinkInputPressEnter}
                />
                <Button type="primary">ОК</Button>
              </Input.Group>
            }
            visible={linkTooltipVisible}
          >
            <Button className={linkActionButtonClasses.join(' ')} type="link" onClick={onLink}>
              <SbIcon iconName="link-one" size={14} />
            </Button>
          </SbTooltip>
          <Button className={blockquoteActionButtonClasses.join(' ')} type="link" onClick={onBlockquote}>
            <SbIcon iconName="align-left-one" size={14} />
          </Button>
          {variables ? <Divider type="vertical" /> : undefined}
          {variables ? (
            <SbTooltip
              overlayInnerStyle={{ width: `${VAR_TOOLTIP_WIDTH}px` }}
              placement="right"
              title={
                <VariableSelector
                  getVariableUsages={getVariableUsages}
                  variables={variables || []}
                  onChange={onVariableSelectorChange}
                  onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
                />
              }
              trigger={['click']}
              visible={variableTooltipVisible}
              onVisibleChange={setVariableTooltipVisible}
            >
              <Button className={variableActionButtonClasses.join(' ')} type="link">
                <VariableIcon />
              </Button>
            </SbTooltip>
          ) : undefined}
        </div>
      </div>
      <Editor
        spellCheck
        stripPastedStyles
        decorators={getDecorators()}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
        readOnly={readOnly}
        onBlur={onBlur}
        onChange={setEditorState}
        onFocus={onFocus}
      />
    </div>
  );
};

export default SbMarkdownEditor;
