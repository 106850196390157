import React, { useEffect, useState } from 'react';
import { Button, Table, Row, PageHeader, TablePaginationConfig } from 'antd';
import { UsergroupDeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import { GroupModel, PersonModel } from '../../../api';
import { personApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE, PERSON_UPDATED } from '../../constants';
import AddGroupToPersonButton from '../../components/AddGroupToPersonButton';
import { hubConnections } from '../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../recoil/alerts';

const PersonGroups: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState({} as PersonModel);
  const [listGroups, setListGroups] = useState([] as GroupModel[]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await personApi.getPerson(id);
      setPerson(response.data);
      setPagination({ ...pagination, total: response.data.groupIds?.length || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке сотрудника',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const personEventHandler = (args: { person: PersonModel }) => {
    if (id !== args.person.id) {
      return;
    }

    setPerson(args.person);
    setPagination({ ...pagination, total: args.person.groupIds?.length || 0 });
  };
  const subscribe = () => {
    if (!person?.id || !conn) return;

    conn.on(PERSON_UPDATED, personEventHandler);

    return () => {
      conn.off(PERSON_UPDATED, personEventHandler);
    };
  };
  useEffect(subscribe, [conn, person?.id]);

  const populatePersonGroups = () => {
    setListGroups(person?.groups || []);
  };
  useEffect(populatePersonGroups, [person]);

  const removePersonFromGroupAsync = async (group: GroupModel) => {
    setLoading(true);

    try {
      await personApi.updatePerson(person.id, {
        ...person,
        groupIds: (person.groupIds || []).filter((groupId) => groupId !== group.id),
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении группы',
        error: e,
      });
    }

    setLoading(false);
  };

  const onRemoveBtnClick = (group: GroupModel) => {
    removePersonFromGroupAsync(group);
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      render: (_text: unknown, group: GroupModel) => (
        <Link
          to={{
            pathname: `/groups/${group.id}`,
          }}
        >
          {group.name}
        </Link>
      ),
    },
    {
      render: (_text: unknown, group: GroupModel) => (
        <Row align="middle" justify="end" style={{ width: '100%' }}>
          <Button danger icon={<UsergroupDeleteOutlined />} type="ghost" onClick={() => onRemoveBtnClick(group)} />
        </Row>
      ),
    },
  ];

  return (
    <div>
      <PageHeader extra={<AddGroupToPersonButton person={person} />} title={`Группы сотрудника ${person.fullName}`} />
      <Table
        columns={columns}
        dataSource={listGroups.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default PersonGroups;
