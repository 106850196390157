import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async-hook';

import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbTooltip from '../../../components/common/SbTooltip';
import { agentApi, botStageApi } from '../../../../apis';
import { BotStageModel, BotStageTestingResponse, BotStageType, PublishStage, TrainStatus } from '../../../../../api';
import { AlertTypes, BOT_PUBLISH_STATUS_UPDATED } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { hubConnections } from '../../../../utils/socketsUtil';
import SbWebChat from '../../../components/SbWebChat';

enum WebchatButtonState {
  Preparing = 'Preparing',
  Ready = 'Ready',
  WebchatOpened = 'WebchatOpened',
  Error = 'Error',
}

interface IWebchatButtonProps {
  botName?: string;
  botStage?: BotStageModel;
}

const WebchatButton: React.FC<IWebchatButtonProps> = ({ botName, botStage }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [testingState, setTestingState] = useState<BotStageTestingResponse>();
  const [testingInitialization, setTestingInitialization] = useState(false);
  const [userStartedTesting, setUserStartedTesting] = useState(false);
  const [webchatVisible, setWebchatVisible] = useState(false);
  const [openWebChatOnReady, setOpenWebChatOnReady] = useState(false);

  const buttonCurrentState =
    testingInitialization || testingState?.agentStage.trainResult.status === TrainStatus.Pending
      ? WebchatButtonState.Preparing
      : userStartedTesting && testingState?.agentStage.trainResult.status === TrainStatus.Error
      ? WebchatButtonState.Error
      : webchatVisible
      ? WebchatButtonState.WebchatOpened
      : WebchatButtonState.Ready;

  const isOriginStage = botStage?.type === BotStageType.Origin;

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const loadTestingState = async () => {
    if (!botStage) return;

    try {
      const response = await botStageApi.getBotStageTestingState(botStage.id);
      setTestingState(response.data);

      if (openWebChatOnReady && response.data.agentStage.trainResult.status === TrainStatus.Success) {
        setWebchatVisible(true);
      }
    } catch {
      // NOTE: если произошла ошибка, то скорей всего тестирование еще не запускалось
    }
  };
  const loadData = () => {
    loadTestingState().finally();
  };
  useEffect(loadData, [botStage]);

  const botPublishEventHandler = (args: { agentStageId: string }) => {
    if (testingState?.agentStage.id === args?.agentStageId) {
      loadTestingState().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
    return () => conn.off(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
  };
  useEffect(subscribe, [conn, testingState]);

  const testBotStage = async () => {
    if (!botStage) {
      return;
    }

    if (isOriginStage) {
      setWebchatVisible(true);
      return;
    }

    setTestingInitialization(true);

    try {
      const response = await botStageApi.testBotStage(botStage.id);
      setTestingState(response.data);
      setOpenWebChatOnReady(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске тестирования бота',
        error: e,
      });
    }
    setTestingInitialization(false);
  };

  const onWebChatButtonClick = async () => {
    if (!botStage) {
      return;
    }

    setUserStartedTesting(true);

    if (botStage.type !== BotStageType.Origin || testingState?.agentStage.trainResult.status === TrainStatus.Error) {
      await testBotStage();
      return;
    }

    setWebchatVisible(true);
  };

  const onWebchatClose = () => {
    setOpenWebChatOnReady(false);
    setWebchatVisible(false);
  };

  const onErrorButtonClick = async () => {
    if (!botStage) {
      return;
    }

    if (botStage.type === BotStageType.Origin) {
      await agentApi.retrain({ agentId: botStage.agentId, publishStage: PublishStage.NUMBER_2 });
      await loadTestingState();
      return;
    }

    await testBotStage();
  };

  const renderWebchatButton = () => {
    switch (buttonCurrentState) {
      case WebchatButtonState.Ready:
        return (
          <SbButton sbType="primary" onClick={onWebChatButtonClick}>
            {isOriginStage ? 'Тестировать бота' : 'Тестировать черновик бота'}
          </SbButton>
        );
      case WebchatButtonState.Preparing:
        return (
          <SbTooltip placement="bottomRight" title="Подготовка к запуску может занять некоторое время" trigger="hover">
            <SbButton loading icon={<SbIcon spin iconName="loading-four" />} sbType="icon-primary">
              Подготовка
            </SbButton>
          </SbTooltip>
        );
      case WebchatButtonState.WebchatOpened:
        return (
          <SbButton sbType="primary" onClick={onWebchatClose}>
            Закрыть веб-чат
          </SbButton>
        );
      case WebchatButtonState.Error:
        return (
          <SbTooltip
            placement="bottomRight"
            title="Ошибка при запуске бота. Нажмите кнопку, чтобы попробовать снова"
            trigger="hover"
          >
            <SbButton danger icon={<SbIcon iconName="attention" />} sbType="icon-primary" onClick={onErrorButtonClick}>
              Ошибка
            </SbButton>
          </SbTooltip>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {renderWebchatButton()}
      {webchatVisible && testingState?.webchatUrl && (
        <SbWebChat
          subTitle="Тестовый виджет"
          title={botName || ''}
          webchatUrl={testingState.webchatUrl}
          onClose={onWebchatClose}
        />
      )}
    </>
  );
};

export default WebchatButton;
