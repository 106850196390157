import React from 'react';

import './index.less';
import { findAllIgnoreCase } from '../../../../utils/stringUtil';

const MAIN_CLASS_NAME = 'ib-text-highlighter';
const SPAN_CLASS_NAME = `${MAIN_CLASS_NAME}__span`;
const HIGHLIGHTED_SPAN_CLASS_NAME = `${SPAN_CLASS_NAME}_highlighted`;

export interface IIbTextHighlighterProps {
  text?: string;
  highlightedText?: string;
}

const IbTextHighlighter: React.FC<IIbTextHighlighterProps> = ({ text, highlightedText }) => (
  <span className={MAIN_CLASS_NAME}>
    {highlightedText && text
      ? findAllIgnoreCase(text, highlightedText).map((s) => {
          const spanClassNames = [SPAN_CLASS_NAME];
          s.found && spanClassNames.push(HIGHLIGHTED_SPAN_CLASS_NAME);
          return (
            <span key={s.index} className={spanClassNames.join(' ')}>
              {s.value}
            </span>
          );
        })
      : text}
  </span>
);

export default IbTextHighlighter;
