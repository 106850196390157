import React, { memo } from 'react';

import Messages from '../../Messages';
import InputVariable from '../../InputVariable';
import { DefaultActionGroupSchema, FileInputSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

interface IFileInputProps {
  index: number;
  action: FileInputSchema;
  group: DefaultActionGroupSchema;
}

const FileInput: React.FC<IFileInputProps> = ({ action, index, group }) => {
  return (
    <ActionWrapper
      showInputActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="upload-logs" size={20} />}
      index={index}
      title="Запрос файла"
    >
      <Messages action={action} group={group} placeholder="Добавьте текст" />
      <InputVariable action={action} />
    </ActionWrapper>
  );
};

export default memo(FileInput);
