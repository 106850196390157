import React, { useState } from 'react';

import SbButton from '../../../components/common/SbButton';
import { SingleKnowledgeBaseModel } from '../../../../../kb-api';

interface IRollbackButtonProps {
  knowledgeBase?: SingleKnowledgeBaseModel;
  dataUpdating: boolean;
  onRollback: () => Promise<void>;
}

const RollbackButton: React.FC<IRollbackButtonProps> = ({ knowledgeBase, dataUpdating, onRollback }) => {
  const [loading, setLoading] = useState(false);

  if (!knowledgeBase?.draftStage) {
    return null;
  }

  const onButtonClick = async () => {
    if (dataUpdating) return;

    setLoading(true);
    await onRollback();
    setLoading(false);
  };

  return (
    <SbButton loading={loading} sbType="secondary" onClick={onButtonClick}>
      Отменить изменения
    </SbButton>
  );
};

export default RollbackButton;
