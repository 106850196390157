import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Divider, Radio, RadioChangeEvent } from 'antd';
import { useAsync } from 'react-async-hook';

import './index.less';

import {
  AgentModel,
  AgentStageAccountModel,
  AgentStageAccountSortDirection,
  SingleBotModel,
} from '../../../../../../api';
import { agentApi, agentStageAccountApi } from '../../../../../apis';
import { AlertTypes, Channels, CHANNELS_PAGE_SIZE, CHANNEL_STATUS_UPDATED } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import SbChannelStatus from '../../../../components/common/SbChannelStatus';
import SbChannelIcon from '../../../../components/common/SbChannelIcon';
import EditChannelModal from '../EditChannelModal';
import AddChannelButton from '../AddChannelButton';
import SbSpin from '../../../../components/common/SbSpin';
import { hubConnections } from '../../../../../utils/socketsUtil';
import { getChannelDisplayName } from '../../../../../utils/stringUtil';

interface IChannelsBlockProps {
  bot: SingleBotModel;
  onClickableChanged: (clickable: boolean) => void;
}

const ChannelsBlock: React.FC<IChannelsBlockProps> = ({ bot, onClickableChanged }) => {
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState<AgentModel>();
  const [channels, setChannels] = useState<AgentStageAccountModel[]>([]);
  const [sortMode, setSortMode] = useState(AgentStageAccountSortDirection.StatusDescending);

  const [addChannelModalVisible, setAddChannelModalVisible] = useState(false);
  const [addChannelModalSelectedChannel, setAddChannelModalSelectedChannel] = useState<Channels>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const loadChannelsDataAsync = async () => {
    try {
      const agentResponse = await agentApi.getAgent(bot.entry.agentId);
      setAgent(agentResponse.data);

      const prodChannelsResponse = await agentStageAccountApi.searchAgentStageAccounts(
        undefined,
        agentResponse.data?.productionAgent.id,
        undefined,
        sortMode,
        0,
        CHANNELS_PAGE_SIZE
      );

      setChannels(prodChannelsResponse.data.items || []);
      onClickableChanged((prodChannelsResponse.data.items?.length ?? 0) > 0);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadChannelsData = () => {
    setLoading(true);
    loadChannelsDataAsync().finally();
  };
  useEffect(loadChannelsData, [bot, sortMode]);

  const channelUpdatedEventHandler = (args: { agentId: string }) => {
    if (args.agentId === agent?.id) {
      loadChannelsDataAsync().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
    return () => conn.off(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
  };
  useEffect(subscribe, [conn]);

  const onSortModeChanged = (e: RadioChangeEvent) => setSortMode(e.target.value);

  const onAddChannel = (channelType: Channels) => {
    setAddChannelModalSelectedChannel(channelType);
    setAddChannelModalVisible(true);
  };

  const onDataChanged = () => {
    setLoading(true);
    loadChannelsDataAsync().finally();
  };

  const onAddChannelModalClose = () => setAddChannelModalVisible(false);

  const renderChannels = () => {
    return channels.map((channel) => {
      return (
        <div key={channel.id} className="sb-channels-block__content__list__channel-container">
          <div className="sb-channels-block__content__list__channel-container__icon">
            <SbChannelIcon size="normal" type={channel.channelId as Channels} />
          </div>
          <div className="sb-channels-block__content__list__channel-container__title">
            {getChannelDisplayName(channel)}
          </div>
          <div className="sb-channels-block__content__list__channel-container__status">
            <SbChannelStatus enabledStatus={channel.status} setupResult={channel.setupResult} />
          </div>
        </div>
      );
    });
  };

  const renderHeader = () => (
    <>
      <h2>Мессенджеры</h2>
      {channels.length > 0 && (
        <>
          <div className="sb-channels-block__header__buttons" role="none" onClick={(e) => e.stopPropagation()}>
            <AddChannelButton buttonType="tertiary" onAddChannel={onAddChannel} />
          </div>
          <div className="sb-channels-block__header__sort-panel" role="none" onClick={(e) => e.stopPropagation()}>
            <span className="sb-channels-block__header__sort-panel__text">Сортировать</span>
            <Radio.Group value={sortMode} onChange={onSortModeChanged}>
              <Radio.Button value={AgentStageAccountSortDirection.StatusDescending}>по статусу</Radio.Button>
              <Divider type="vertical" />
              <Radio.Button value={AgentStageAccountSortDirection.NameAscending}>по названию</Radio.Button>
            </Radio.Group>
          </div>
        </>
      )}
    </>
  );

  const renderContent = () => {
    if (loading)
      return (
        <div className="sb-channels-block__content__loading-block">
          <SbSpin />
        </div>
      );

    if (!channels.length) {
      return (
        <>
          <div className="sb-channels-block__content__empty-list-message">
            На данный момент у вас нет ни одного подключенного к боту мессенджера.
          </div>
          <AddChannelButton buttonType="primary" onAddChannel={onAddChannel} />
        </>
      );
    }

    return <div className="sb-channels-block__content__list">{renderChannels()}</div>;
  };

  return (
    <div className="sb-channels-block">
      <div className="sb-channels-block__header sb-bot-card__content__block__inner-wrapper__header">
        {renderHeader()}
      </div>
      <div className="sb-channels-block__content">{renderContent()}</div>
      {agent && (
        <div role="none" onClick={(e) => e.stopPropagation()}>
          <EditChannelModal
            agent={agent}
            channelType={addChannelModalSelectedChannel}
            visible={addChannelModalVisible}
            onClose={onAddChannelModalClose}
            onDataChanged={onDataChanged}
          />
        </div>
      )}
    </div>
  );
};

export default ChannelsBlock;
