export type QueueCallback = (error?: unknown, result?: unknown) => void;

export enum MessageName {
  InboxAssignmentRead = 'inbox.assignment.read',
  InboxAssignmentAssignOperator = 'inbox.assignment.assign.operator',
  InboxAssignmentAssignBot = 'inbox.assignment.assign.bot',
  InboxAssignmentAssignOperatorGroup = 'inbox.assignment.assign.operatorGroup',
  InboxAssignmentClose = 'inbox.assignment.close',
  InboxAssignmentQueue = 'inbox.assignment.queue',
}

export enum InvocationName {
  InboxAssignmentRead = 'inbox.assignment.read',
  InboxAssignmentAssignOperator = 'inbox.assignment.assign.operator',
  InboxAssignmentAssignBot = 'inbox.assignment.assign.bot',
  InboxAssignmentAssignOperatorGroup = 'inbox.assignment.assign.operatorGroup',
  InboxAssignmentClose = 'inbox.assignment.close',
  InboxAssignmentQueue = 'inbox.assignment.queue',
}

export enum TaskOrder {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export enum TaskType {
  None = 'None',
  MessageSend = 'MessageSend',
  InvocationSend = 'InvocationSend',
  AttachmentUpload = 'AttachmentUpload',
  AttachmentDelete = 'AttachmentDelete',
  ParticipantStatusUpdate = 'ParticipantStatusUpdate',
  ParticipantActivityUpdate = 'ParticipantActivityUpdate',
}

export interface ITaskData<T> {
  key: string;
  type: TaskType;
  timestamp: string;
  content: T;
}

export interface ITaskItem<T> {
  id: string;
  lockId: string;
  priority: number;
  data: ITaskData<T>;
}

export interface ITaskDelays {
  pending: number;
  process: number;
  success: number;
  failure: number;
}

export enum OperationType {
  Simple = 'Simple',
  Composite = 'Composite',
}

export enum OperationStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Process = 'Process',
  Success = 'Success',
  Failure = 'Failure',
}

export interface IOperationProgress {
  current: number;
  maximum: number;
}

export interface ITaskOperation {
  type: OperationType;
  status: OperationStatus;
  progress: IOperationProgress;
}

export abstract class TaskOperation implements ITaskOperation {
  readonly type: OperationType;
  readonly status: OperationStatus;
  readonly progress: IOperationProgress;

  protected constructor(type: OperationType, status: OperationStatus, progress: IOperationProgress) {
    this.type = type;
    this.status = status;
    this.progress = progress;
  }
}

export class SimpleOperation extends TaskOperation {
  constructor(status: OperationStatus, progress: IOperationProgress) {
    super(OperationType.Simple, status, progress);
  }
}

export class CompositeOperation extends TaskOperation {
  readonly children: ITaskOperation[];

  constructor(children: ITaskOperation[]) {
    let status: OperationStatus;
    if (children.some((c) => c.status === OperationStatus.Failure)) {
      status = OperationStatus.Failure;
    } else if (children.some((c) => c.status === OperationStatus.Process)) {
      status = OperationStatus.Process;
    } else if (children.every((c) => c.status === OperationStatus.Success)) {
      status = OperationStatus.Success;
    } else {
      status = OperationStatus.Pending;
    }

    const progress = {
      current: children.reduce((sum, p) => sum + p.progress.current, 0),
      maximum: children.reduce((sum, p) => sum + p.progress.maximum, 0),
    };

    super(OperationType.Composite, status, progress);

    this.children = children;
  }
}

export interface ITaskContext {
  notify: (progress: IOperationProgress) => void;
}

export interface ITaskEvent<T> {
  data: ITaskData<T>;
  progress: IOperationProgress;
}

export interface ITaskHandler<T> {
  estimate(content: T): IOperationProgress;
  process(content: T, context: ITaskContext): Promise<unknown>;
}

export interface ITaskSuccessEvent<T> extends ITaskEvent<T> {
  result: unknown;
}

export interface ITaskFailureEvent<T> extends ITaskEvent<T> {
  error: Error;
}
