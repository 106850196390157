import React from 'react';

import './index.less';

import { InboxFileCategory, InboxFileMediaGroup } from '../../../../../api';
import { UnknownDefaultIcon } from '../../../../assets/files/unknown/default';
import { DocumentDocIcon } from '../../../../assets/files/document/doc';
import { DocumentXlsIcon } from '../../../../assets/files/document/xls';
import { DocumentPdfIcon } from '../../../../assets/files/document/pdf';
import { ArchiveDefaultIcon } from '../../../../assets/files/archive/default';
import { ImageDefaultIcon } from '../../../../assets/files/image/default';
import { AudioDefaultIcon } from '../../../../assets/files/audio/default';
import { VideoDefaultIcon } from '../../../../assets/files/video/default';

const MAIN_CLASS_NAME = 'ib-file';
const INTERACTIVE_CLASS_NAME = `${MAIN_CLASS_NAME}_interactive`;

export interface IIbFileProps {
  className?: string;
  mediaGroup: InboxFileMediaGroup;
  category: InboxFileCategory;
  linkHref?: string;
}

const IbFile: React.FC<IIbFileProps> = ({ className, mediaGroup, category, linkHref }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  if (linkHref) {
    classes.push(INTERACTIVE_CLASS_NAME);
  }

  const renderContent = () => {
    switch (category) {
      case InboxFileCategory.Document:
        switch (mediaGroup) {
          case InboxFileMediaGroup.DocumentTextRich:
            return <DocumentDocIcon />;
          case InboxFileMediaGroup.DocumentSpreadsheet:
            return <DocumentXlsIcon />;
          case InboxFileMediaGroup.DocumentPublication:
            return <DocumentPdfIcon />;
          default:
            return <UnknownDefaultIcon />;
        }
      case InboxFileCategory.Archive:
        return <ArchiveDefaultIcon />;
      case InboxFileCategory.Image:
        return <ImageDefaultIcon />;
      case InboxFileCategory.Audio:
        return <AudioDefaultIcon />;
      case InboxFileCategory.Video:
        return <VideoDefaultIcon />;
      default:
        return <UnknownDefaultIcon />;
    }
  };

  return (
    <a className={classes.join(' ')} href={linkHref}>
      {renderContent()}
    </a>
  );
};

export default IbFile;
