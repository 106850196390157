import { InboxFileLinkContentModel, InboxFileMediaType, InboxFileModel } from '../../../api';

const VISIBLE_MEDIA_TYPES = new Set<InboxFileMediaType>([
  InboxFileMediaType.ImageBitmapBmp,
  InboxFileMediaType.ImageBitmapGif,
  InboxFileMediaType.ImageBitmapIco,
  InboxFileMediaType.ImageBitmapPng,
  InboxFileMediaType.ImageBitmapJpeg,
  InboxFileMediaType.ImageVectorSvg,
]);

export const isMediaVisible = (mediaType?: InboxFileMediaType): boolean =>
  !!mediaType && VISIBLE_MEDIA_TYPES.has(mediaType);

export const tryGetContentUrl = (file: InboxFileModel): string | undefined => {
  for (const content of file.content) {
    const link = content as InboxFileLinkContentModel;
    if (link.url) {
      return link.url;
    }
  }

  return undefined;
};

export const tryGetPreviewUrl = (file: InboxFileModel): string | undefined => {
  if (!isMediaVisible(file.mediaType)) {
    return undefined;
  }

  return tryGetContentUrl(file);
};

export const tryGetThumbnailUrl = (file: InboxFileModel, thumbnails: InboxFileModel[]): string | undefined => {
  if (!isMediaVisible(file.mediaType)) {
    return undefined;
  }

  for (const thumbnail of thumbnails) {
    const contentUrl = tryGetContentUrl(thumbnail);
    if (contentUrl) {
      return contentUrl;
    }
  }

  return tryGetContentUrl(file);
};

export const isPreviewAvailable = (file: InboxFileModel): boolean => !!tryGetPreviewUrl(file);

export const isThumbnailAvailable = (file: InboxFileModel, thumbnails: InboxFileModel[]): boolean =>
  !!tryGetThumbnailUrl(file, thumbnails);
