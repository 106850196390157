import React, { FocusEventHandler, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { SelectValue } from 'antd/es/select';

import './index.less';
import SbSelect, { ISbSelectProps } from '../SbSelect';
import SbSpin from '../SbSpin';

interface ISbDebounceSelectProps extends ISbSelectProps {
  fetchOptions: (search: string) => Promise<SelectValue[]>;
  debounceTimeout?: number;
}

const SbDebounceSelect: React.FC<ISbDebounceSelectProps> = ({ fetchOptions, debounceTimeout = 800, ...otherProps }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions.map((o) => o as never));
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const onFocus: FocusEventHandler<HTMLElement> = () => {
    if (!options.length) {
      debounceFetcher('');
    }
  };

  return (
    <SbSelect
      labelInValue
      filterOption={false}
      notFoundContent={fetching ? <SbSpin size="small" /> : null}
      onFocus={onFocus}
      onSearch={debounceFetcher}
      {...otherProps}
      options={options}
    />
  );
};

export default SbDebounceSelect;
