import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { useQuery } from '../../../utils/urlUtil';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import { botApi } from '../../../apis';
import { AlertTypes } from '../../../constants';

const AGENT_ID_PARAM = 'agentId';

const SimpleBotRouter: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();

  const query = useQuery();

  const redirectToBotPageAsync = async () => {
    try {
      const agentId = query.get(AGENT_ID_PARAM);
      if (!agentId) {
        push('/simple-bots');
        throw new Error('Не указан параметр agentId');
      }

      const response = await botApi.getBotByAgentId(agentId);
      push(`/simple-bots/${response.data.entry.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке бота',
        error: e,
      });
    }
  };

  const redirectToBotPage = () => {
    redirectToBotPageAsync().then();
  };
  useEffect(redirectToBotPage, []);

  return null;
};

export default SimpleBotRouter;
