import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { InboxFileCategory, InboxFileMediaGroup } from '../../../../api';
import IbThumbnail from '../common/IbThumbnail';
import IbFile from '../common/IbFile';
import IbTypography from '../common/IbTypography';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import { OperationStatus } from '../../tasks';
import IbProgress from '../common/IbProgress';
import IbContextMenu, { IIbContextMenuItem } from '../common/IbContextMenu';

const MENU_ICON_SIZE = 20;
const STATUS_ICON_SIZE = 16;

const MAIN_CLASS_NAME = 'ib-attachment-input';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const THUMBNAIL_CLASS_NAME = `${CONTENT_CLASS_NAME}__thumbnail`;
const BODY_CLASS_NAME = `${CONTENT_CLASS_NAME}__body`;
const FOOTER_CLASS_NAME = `${CONTENT_CLASS_NAME}__footer`;
const BUTTON_CLASS_NAME = `${CONTENT_CLASS_NAME}__button`;
const NAME_CLASS_NAME = `${FOOTER_CLASS_NAME}__name`;
const STATUS_CLASS_NAME = `${FOOTER_CLASS_NAME}__status`;

const CONTENT_FILE_CLASS_NAME = `${CONTENT_CLASS_NAME}_file`;
const CONTENT_THUMBNAIL_CLASS_NAME = `${CONTENT_CLASS_NAME}_thumbnail`;
const STATUS_FAILURE_CLASS_NAME = `${STATUS_CLASS_NAME}_failure`;

export interface IIbAttachmentInputProps {
  className?: string;
  fileName?: string;
  fileUrl?: string;
  thumbnailUrl?: string;
  loadingStatus?: OperationStatus;
  loadingCurrent?: number;
  loadingMaximum?: number;
  mediaGroup?: InboxFileMediaGroup;
  category?: InboxFileCategory;
  onReUpload?: () => void;
  onDelete?: () => void;
  onPreview?: () => void;
}

const IbAttachmentInput: React.FC<IIbAttachmentInputProps> = ({
  className,
  fileName,
  fileUrl,
  thumbnailUrl,
  loadingStatus,
  loadingCurrent,
  loadingMaximum,
  mediaGroup,
  category,
  onReUpload,
  onDelete,
  onPreview,
}) => {
  const { t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);

  const isLoading =
    loadingStatus && loadingStatus !== OperationStatus.Unknown && loadingStatus !== OperationStatus.Success;
  const isFailure = loadingStatus === OperationStatus.Failure;

  const onOpenMenu = () => {
    setMenuVisible(true);
  };

  const onCloseMenu = () => {
    setMenuVisible(false);
  };

  const renderLoading = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <IbProgress
        current={loadingCurrent || 0}
        iconName={isFailure ? 'refresh' : undefined}
        maximum={loadingMaximum || 1}
        theme={thumbnailUrl ? 'gray' : 'default'}
        onClick={isFailure ? onReUpload : undefined}
      />
    );
  };

  const renderFile = () => {
    if (thumbnailUrl || isLoading || !mediaGroup || !category) {
      return null;
    }

    return <IbFile category={category} mediaGroup={mediaGroup} />;
  };

  const renderName = () => {
    if (thumbnailUrl || !fileName) {
      return null;
    }

    return (
      <div className={NAME_CLASS_NAME}>
        <IbTypography>
          <IbTypography.Paragraph ellipsis type="descriptor">
            {fileName}
          </IbTypography.Paragraph>
        </IbTypography>
      </div>
    );
  };

  const renderStatus = () => {
    if (thumbnailUrl || !isFailure) {
      return null;
    }

    const onReUploadSelect = () => {
      onReUpload?.();
      onCloseMenu();
    };

    const onDeleteSelect = () => {
      onDelete?.();
      onCloseMenu();
    };

    const menuItems: IIbContextMenuItem[] = [
      {
        icon: <IbIcon iconName="refresh" size={MENU_ICON_SIZE} />,
        text: t('ResendAttachment'),
        onSelect: onReUploadSelect,
      },
      {
        icon: <IbIcon iconName="delete" size={MENU_ICON_SIZE} />,
        text: t('DeleteAttachment'),
        onSelect: onDeleteSelect,
      },
    ];

    return (
      <div className={`${STATUS_CLASS_NAME} ${STATUS_FAILURE_CLASS_NAME}`}>
        <IbContextMenu menuItems={menuItems} visible={menuVisible} onClose={onCloseMenu}>
          <IbButton onClick={onOpenMenu}>
            <IbIcon iconName="attention" size={STATUS_ICON_SIZE} theme="filled" />
          </IbButton>
        </IbContextMenu>
      </div>
    );
  };

  const renderBody = () => {
    if (!isLoading && (thumbnailUrl || !mediaGroup || !category)) {
      return null;
    }

    return (
      <div className={BODY_CLASS_NAME}>
        {renderLoading()}
        {renderFile()}
      </div>
    );
  };

  const renderFooter = () => {
    if (thumbnailUrl || (!fileName && !isFailure)) {
      return null;
    }

    return (
      <div className={FOOTER_CLASS_NAME}>
        {renderName()}
        {renderStatus()}
      </div>
    );
  };

  const renderThumbnail = () => {
    if (!thumbnailUrl) {
      return null;
    }

    return (
      <div className={THUMBNAIL_CLASS_NAME}>
        <IbThumbnail imgSrc={thumbnailUrl} linkHref={fileUrl} onPreview={onPreview} />
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div className={BUTTON_CLASS_NAME}>
        <IbButton icon={<IbIcon iconName="close" />} type="icon-round" onClick={onDelete}></IbButton>
      </div>
    );
  };

  const mainClasses = [MAIN_CLASS_NAME];
  className && mainClasses.push(className);

  const contentClasses = [CONTENT_CLASS_NAME];
  if (thumbnailUrl) {
    contentClasses.push(CONTENT_THUMBNAIL_CLASS_NAME);
  } else {
    contentClasses.push(CONTENT_FILE_CLASS_NAME);
  }

  return (
    <div className={mainClasses.join(' ')}>
      <div className={contentClasses.join(' ')}>
        {renderThumbnail()}
        {renderBody()}
        {renderFooter()}
        {renderButton()}
      </div>
    </div>
  );
};

export default IbAttachmentInput;
