import React from 'react';
import { Alert, Button, Dropdown, Menu, PageHeader, Typography } from 'antd';
import { useHistory } from 'react-router';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Flags } from 'react-feature-flags';

import { AgentStageAccountModel, AgentStageModel, TrainStatus } from '../../../../api';
import ConversationWidget from '../../../components/ConversationWidget';
import ChannelList from '../../../components/ChannelList';
import { FeatureFlagNames } from '../../../constants';

import WebChatDrawer from './WebChatDrawer';

const { Title, Paragraph } = Typography;

interface AgentStagePaneProps {
  agentStage: AgentStageModel;
  channels: AgentStageAccountModel[];
  botId: string;
  onRetrainRun: () => void;
  onChannelChange: (channel: AgentStageAccountModel) => void;
}

const AgentStagePane: React.FC<AgentStagePaneProps> = ({
  agentStage,
  botId,
  channels,
  onRetrainRun = () => {},
  onChannelChange,
}) => {
  const { push } = useHistory();

  const onEditStage = () => push(`/agents/stages/${agentStage.id}/edit`);

  const onAddViberChannel = () => push(`/agents/${agentStage.id}/viber/add`);

  const onAddTelegramChannel = () => push(`/agents/${agentStage.id}/telegram/add`);

  const onAddELMA365Channel = () => push(`/agents/${agentStage.id}/elma365/add`);

  const onAddLiveChatChannel = () => push(`/agents/${agentStage.id}/livechat/add`);

  const onEmulatorTestSelect = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(agentStage.emulatorUrl, '_self');
  };

  const renderPublicationButton = () => {
    const trainedCount = agentStage.trainResult?.generatedData?.filter((item) => item.omegaGenerated).length || 0;
    const count = agentStage.trainResult?.generatedData?.length || 1;
    const percent = Math.round((trainedCount / count) * 100);

    return (
      <Button key="publication" loading type="primary">
        Идет публикация...({percent} %)
      </Button>
    );
  };

  const renderTestingButton = () => {
    const menu = (
      <Menu>
        <Menu.Item
          key="emulator"
          disabled={agentStage.trainResult?.status === TrainStatus.Error}
          onClick={onEmulatorTestSelect}
        >
          в эмуляторе
        </Menu.Item>
        <WebChatDrawer agentStage={agentStage} />
      </Menu>
    );
    return (
      <Dropdown key="test" overlay={menu}>
        <Button icon={<PlayCircleOutlined />} type="primary">
          Тестировать
        </Button>
      </Dropdown>
    );
  };

  const headerExtraButtons = (
    <>
      <Button key="edit-stage" type="default" onClick={onEditStage}>
        Изменить параметры
      </Button>
      <Button key="add-viber" type="primary" onClick={onAddViberChannel}>
        + Добавить канал Viber
      </Button>
      <Button key="add-telegram" type="primary" onClick={onAddTelegramChannel}>
        + Добавить канал Telegram
      </Button>
      <Button key="add-elma365" type="primary" onClick={onAddELMA365Channel}>
        + Добавить линию ELMA365
      </Button>
      <Flags authorizedFlags={[FeatureFlagNames.LIVE_CHAT_CHANNEL]}>
        <Button key="add-livechat" type="primary" onClick={onAddLiveChatChannel}>
          + Добавить канал LiveChat
        </Button>
      </Flags>
      {agentStage.trainResult?.status === TrainStatus.Pending ? renderPublicationButton() : renderTestingButton()}
    </>
  );

  const onRetrainBtnClick = () => {
    onRetrainRun();
  };

  return (
    <div>
      {agentStage.trainResult?.status === TrainStatus.Error && (
        <Alert
          showIcon
          description={
            <React.Fragment>
              <Paragraph>{agentStage.trainResult?.errorMessage}</Paragraph>
              <Button type="link" onClick={onRetrainBtnClick}>
                Опубликовать заново
              </Button>
            </React.Fragment>
          }
          message="Ошибка публикации"
          type={'error'}
        />
      )}
      <PageHeader extra={headerExtraButtons} title="" />
      <ConversationWidget agentStageId={agentStage.id} caption="Все беседы бота" />
      <br />
      <Title level={4}>Каналы</Title>
      <div>
        <ChannelList agentStage={agentStage} botId={botId} channels={channels} onChannelChange={onChannelChange} />
      </div>
    </div>
  );
};

export default AgentStagePane;
