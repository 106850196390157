import React, { MouseEventHandler, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { Elma365ProcessStartType, StartElma365ProcessActionSchema, VariableSchema } from '../../../../../../api';
import { currentScenarioStructureSelector, variableUsagesSelector } from '../../../../../recoil/scenarioStructure';
import SbTooltip from '../../../../../simple-bot/components/common/SbTooltip';
import VariableSelector from '../../VariableSelector';
import { VARIABLE_TAG_COLOR_CAUTION, VARIABLE_TAG_COLOR_DEFAULT } from '../../../../../simple-bot/const';
import SbTag from '../../../../../simple-bot/components/common/SbTag';
import { IndexedStructure } from '../../../../../simple-bot/utils/indexation';
import { validateEntities } from '../../../../../simple-bot/utils/validation';
import { EntityFieldPath } from '../../../utils';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbTable from '../../../../../simple-bot/components/common/SbTable';

const MAIN_CLASS_NAME = 'elma365-related-app-element';
const CAPTION_CLASS_NAME = `${MAIN_CLASS_NAME}__caption`;
const VARIABLE_CLASS_NAME = `${MAIN_CLASS_NAME}__variable`;
const VARIABLE_CONFIGURE_CLASS_NAME = `${VARIABLE_CLASS_NAME}__configure`;

interface IElma365RelatedAppElementProps {
  action: StartElma365ProcessActionSchema;
  relatedAppElementVariableId?: string | null;
  onChange: (variableId?: string) => void;
}

const Elma365RelatedAppElement: React.FC<IElma365RelatedAppElementProps> = ({
  action,
  relatedAppElementVariableId,
  onChange,
}) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const variables = scenarioStructure?.variables || [];
  const variable = variables.find((v) => v.id === relatedAppElementVariableId);

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const validate = () => {
    const entity = { ...action, relatedAppElementVariableId, startBy: Elma365ProcessStartType.ByContext };
    const indexedStructure = new IndexedStructure();
    variables.forEach((v) => indexedStructure.indexEntitySchema(v));
    indexedStructure.indexEntitySchema(entity);
    return validateEntities(indexedStructure, [entity]);
  };
  const issue = validate()
    .getVisibleIssueList()
    .find((issue) => issue.fieldPath === EntityFieldPath.RelatedAppElementVariableId);

  const trySaveVariables = (newVariables?: VariableSchema[]) => {
    if (Array.isArray(newVariables) && scenarioStructure) {
      const newScenarioStructure = cloneDeep(scenarioStructure);
      newScenarioStructure.variables = newVariables;
      setScenarioStructure(newScenarioStructure);
    }
  };

  const onVariablesSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    setTooltipIsOpen(false);

    if (selectedVariable) {
      onChange(selectedVariable.id);
    }

    trySaveVariables(newVariables);
  };

  const onVariableDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setTooltipIsOpen(false);
    onChange(undefined);
  };

  const onVariablesSelectorSettingsModalOpen = () => setTooltipIsOpen(false);

  const tableColumns = [
    {
      dataIndex: 'name',
      key: 'name',
      render: () => (
        <span className={CAPTION_CLASS_NAME}>
          <b>Связанный элемент приложения</b>&nbsp;
          {!!issue && (
            <SbTooltip placement="right" title={issue.ruleResult.message}>
              <SbIcon className="scenario-editor-validation-caution" iconName="attention" size={16} />
            </SbTooltip>
          )}
        </span>
      ),
    },
    {
      dataIndex: 'type',
      key: 'type',
      render: () => null,
    },
    {
      dataIndex: 'variable',
      key: 'variable',
      render: () => (
        <SbTooltip
          placement="right"
          title={
            <VariableSelector
              getVariableUsages={getVariableUsages}
              variables={variables}
              onChange={onVariablesSelectorChange}
              onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
            />
          }
          trigger={['click']}
          visible={tooltipIsOpen}
          onVisibleChange={setTooltipIsOpen}
        >
          <div className={VARIABLE_CLASS_NAME}>
            {variable ? (
              <SbTag
                color={issue ? VARIABLE_TAG_COLOR_CAUTION : VARIABLE_TAG_COLOR_DEFAULT}
                sbSize="small"
                text={variable.name}
                onDelete={onVariableDelete}
              />
            ) : (
              <div className={VARIABLE_CONFIGURE_CLASS_NAME}>Выбрать переменную</div>
            )}
          </div>
        </SbTooltip>
      ),
    },
  ];

  return (
    <div className={MAIN_CLASS_NAME}>
      <SbTable
        columns={tableColumns}
        dataSource={[{}]}
        header={false}
        rowKey="id"
        scrollEnabled={false}
        tableLayout="fixed"
      />
    </div>
  );
};

export default Elma365RelatedAppElement;
