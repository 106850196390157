import { HttpTransportType, LiveChatSettingsModel } from '../../api';
import { UserStatus } from '../../auth-api';
import { ICountryInfo } from '../types';

export * from './notifications';
export * from './specialCases';
export * from './styles';
export * from './webChat';

// NOTE: Замеенить, когда будет страница.
export const TARIFF_URL = '/';

export const DEFAULT_PAGE_SIZE = 10;

export const CHANNELS_PAGE_SIZE = 100;

export enum ActionTypes {
  SET_DATA_LOADING = 'SET_DATA_LOADING',
  SET_ALERT = 'SET_ALERT',
  REMOVE_ALERT = 'REMOVE_ALERT',
}

export enum AlertTypes {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum Colors {
  SUCCESS = '#52c41a',
  INFO = '#1890ff',
  WARNING = '#faad14',
  ERROR = '#ff4d4f',
}

export enum Channels {
  VIBER = 'viber',
  TELEGRAM = 'telegram',
  ELMA365 = 'elma365',
  DIRECTLINE = 'directline',
  EMULATOR = 'emulator',
  WHATSAPP = 'whatsapp',
  WEBCHAT = 'webchat',
  LIVECHAT = 'livechat',
  INSTAGRAM = 'instagram',
}

export enum StageTypes {
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum FileExtensions {
  CSV = '.csv',
  XLS = '.xls',
  XLSX = '.xlsx',
  DOC = '.doc',
  DOCX = '.docx',
  PDF = '.pdf',
  JSON = '.json',
  ZIP = '.zip',
}

export const ALLOWED_KNOWLEDGEBASE_FILE_TYPES = [
  FileExtensions.CSV,
  FileExtensions.XLS,
  FileExtensions.XLSX,
  FileExtensions.DOCX,
];

export const ALLOWED_IMPORT_BOT_FILE_TYPES = [FileExtensions.JSON, FileExtensions.ZIP];

export const ALLOWED_IMPORT_SCENARIO_FILE_TYPES = [FileExtensions.JSON];

export enum FeatureFlagNames {
  // клиентские флаги
  HIDDEN = 'hidden',

  // серверные флаги
  ANALYTICS = 'Analytics',
  CONVERSATION_CLOSING = 'ConversationClosing',
  LOW_CONVERSATION_CLOSING_TIMEOUT_MIN_VALUE = 'LowConversationClosingTimeoutMinValue',
  USERS_MANAGEMENT = 'UsersManagement',
  TRAINING_MODEL_SELECTION = 'TrainingModelSelection',
  SIMPLE_BOT_CHANNELS_MANAGEMENT = 'SimpleBotChannelsManagement',
  LIVE_CHAT_CHANNEL = 'LiveChatChannel',
  INBOX_DISPLAY = 'InboxDisplay',

  // флаги для ролей пользователя
  ADMIN = 'Admin',
  BOTS_ADMIN = 'BotsAdmin',
  USERS_ADMIN = 'UsersAdmin',
  INBOX_OPERATOR = 'InboxOperator',
  INBOX_SUPERVISOR = 'InboxSupervisor',
}

export enum ReportRowRuleNames {
  SAME_ANSWER_FULL_MATCH = 'same-answer-full-match',
  SAME_ANSWER_CLOSE_MATCH = 'same-answer-close-match',
  OTHER_ANSWER_FULL_MATCH = 'other-answer-full-match',
  OTHER_ANSWER_CLOSE_MATCH = 'other-answer-close-match',
}

export const ROLE_NAMES_SEPARATOR = ',';

export const USER_STATUS_NAMES = {
  [UserStatus.Invited]: 'Приглашен',
  [UserStatus.Active]: 'Активный',
  [UserStatus.Blocked]: 'Заблокирован',
};

export const USER_ROLES = {
  owner: 'owner',
  admin: 'admin',
  employee: 'employee',
  botsAdmin: 'bots.admin',
  usersAdmin: 'users.admin',
  inboxOperator: 'inbox.operator',
  inboxSupervisor: 'inbox.supervisor',
};

export const SUBJECT_ROLES = {
  user: 'user',
  operator: 'operator',
  botEntry: 'bot.entry',
  botAccount: 'bot.account',
};

export const FORM_FIELD_NAMES = {
  xToken: 'xToken',
  displayName: 'displayName',
  authToken: 'authToken',
  toggleButtonBackground: 'toggleButtonBackground',
  toggleButtonSize: 'toggleButtonSize',
  toggleButtonOffsetBottom: 'toggleButtonOffsetBottom',
  toggleButtonOffsetRight: 'toggleButtonOffsetRight',
  chatTitle: 'chatTitle',
  chatPrimaryColor: 'chatPrimaryColor',
  chatUserBubbleBackgroundColor: 'chatUserBubbleBackgroundColor',
  chatBackground: 'chatBackground',
  securityValidationRules: 'securityValidationRules',
  securityValidationRuleType: 'securityValidationRuleType',
  securityValidationRuleValue: 'securityValidationRuleValue',
  securityValidationRuleEnabled: 'securityValidationRuleEnabled',
  accessToken: 'accessToken',
};

export const RECOIL_LOADABLE_STATES = {
  hasValue: 'hasValue',
  loading: 'loading',
  hasError: 'hasError',
};

export const BOTIUM_USER_ID = 'me';

export const LIVE_CHAT_SETTINGS_DEFAULT = {
  chat: {
    title: 'Бот',
    primaryColor: '#096DD9',
    userBubbleBackground: '#D7F2FF',
    background: '#FFFFFF',
  },
  toggleButton: {
    background: '#096DD9',
    size: '60px',
    offset: {
      bottom: '20px',
      right: '20px',
    },
  },
  transport: HttpTransportType.WebSockets,
} as LiveChatSettingsModel;

export const MENTION_PREFIX = '@';

export const TEXT_FORMAT_TYPES = {
  empty: '',
  plain: 'plain',
  markdown: 'markdown',
};

export const TAG_COLORS = [
  '#FF92CA',
  '#EC8F7F',
  '#92DDFF',
  '#7DD4C1',
  '#AAEB87',
  '#FFD67E',
  '#FFAA7A',
  '#9FBAFF',
  '#A189FF',
  '#B99FC8',
];

export const SETTINGS_PROPERTY_KEY = 'settings';

export const INLINE_BUTTONS_ENABLED_PROPERTY_KEY = 'inlineButtonsEnabled';

export const COLOR_BRIGHTNESS_THRESHOLD = 220;

export const COUNTRY_LIST = [
  { name: { en: 'Abkhazia', ru: 'Абхазия' }, alpha2: 'AB', alpha3: 'ABH', iso: 895 },
  { name: { en: 'Australia', ru: 'Австралия' }, alpha2: 'AU', alpha3: 'AUS', iso: 36 },
  { name: { en: 'Austria', ru: 'Австрия' }, alpha2: 'AT', alpha3: 'AUT', iso: 40 },
  { name: { en: 'Azerbaijan', ru: 'Азербайджан' }, alpha2: 'AZ', alpha3: 'AZE', iso: 31 },
  { name: { en: 'Albania', ru: 'Албания' }, alpha2: 'AL', alpha3: 'ALB', iso: 8 },
  { name: { en: 'Algeria', ru: 'Алжир' }, alpha2: 'DZ', alpha3: 'DZA', iso: 12 },
  { name: { en: 'American Samoa', ru: 'Американское Самоа' }, alpha2: 'AS', alpha3: 'ASM', iso: 16 },
  { name: { en: 'Anguilla', ru: 'Ангилья' }, alpha2: 'AI', alpha3: 'AIA', iso: 660 },
  { name: { en: 'Angola', ru: 'Ангола' }, alpha2: 'AO', alpha3: 'AGO', iso: 24 },
  { name: { en: 'Andorra', ru: 'Андорра' }, alpha2: 'AD', alpha3: 'AND', iso: 20 },
  { name: { en: 'Antarctica', ru: 'Антарктида' }, alpha2: 'AQ', alpha3: 'ATA', iso: 10 },
  { name: { en: 'Antigua and Barbuda', ru: 'Антигуа и Барбуда' }, alpha2: 'AG', alpha3: 'ATG', iso: 28 },
  { name: { en: 'Argentina', ru: 'Аргентина' }, alpha2: 'AR', alpha3: 'ARG', iso: 32 },
  { name: { en: 'Armenia', ru: 'Армения' }, alpha2: 'AM', alpha3: 'ARM', iso: 51 },
  { name: { en: 'Aruba', ru: 'Аруба' }, alpha2: 'AW', alpha3: 'ABW', iso: 533 },
  { name: { en: 'Afghanistan', ru: 'Афганистан' }, alpha2: 'AF', alpha3: 'AFG', iso: 4 },
  { name: { en: 'Bahamas', ru: 'Багамы' }, alpha2: 'BS', alpha3: 'BHS', iso: 44 },
  { name: { en: 'Bangladesh', ru: 'Бангладеш' }, alpha2: 'BD', alpha3: 'BGD', iso: 50 },
  { name: { en: 'Barbados', ru: 'Барбадос' }, alpha2: 'BB', alpha3: 'BRB', iso: 52 },
  { name: { en: 'Bahrain', ru: 'Бахрейн' }, alpha2: 'BH', alpha3: 'BHR', iso: 48 },
  { name: { en: 'Belarus', ru: 'Беларусь' }, alpha2: 'BY', alpha3: 'BLR', iso: 112 },
  { name: { en: 'Belize', ru: 'Белиз' }, alpha2: 'BZ', alpha3: 'BLZ', iso: 84 },
  { name: { en: 'Belgium', ru: 'Бельгия' }, alpha2: 'BE', alpha3: 'BEL', iso: 56 },
  { name: { en: 'Benin', ru: 'Бенин' }, alpha2: 'BJ', alpha3: 'BEN', iso: 204 },
  { name: { en: 'Bermuda', ru: 'Бермуды' }, alpha2: 'BM', alpha3: 'BMU', iso: 60 },
  { name: { en: 'Bulgaria', ru: 'Болгария' }, alpha2: 'BG', alpha3: 'BGR', iso: 100 },
  {
    name: { en: 'Bolivia, plurinational state of', ru: 'Боливия, Многонациональное Государство' },
    alpha2: 'BO',
    alpha3: 'BOL',
    iso: 68,
  },
  {
    name: { en: 'Bonaire, Sint Eustatius and Saba', ru: 'Бонайре, Саба и Синт-Эстатиус' },
    alpha2: 'BQ',
    alpha3: 'BES',
    iso: 535,
  },
  { name: { en: 'Bosnia and Herzegovina', ru: 'Босния и Герцеговина' }, alpha2: 'BA', alpha3: 'BIH', iso: 70 },
  { name: { en: 'Botswana', ru: 'Ботсвана' }, alpha2: 'BW', alpha3: 'BWA', iso: 72 },
  { name: { en: 'Brazil', ru: 'Бразилия' }, alpha2: 'BR', alpha3: 'BRA', iso: 76 },
  {
    name: { en: 'British Indian Ocean Territory', ru: 'Британская территория в Индийском океане' },
    alpha2: 'IO',
    alpha3: 'IOT',
    iso: 86,
  },
  { name: { en: 'Brunei Darussalam', ru: 'Бруней-Даруссалам' }, alpha2: 'BN', alpha3: 'BRN', iso: 96 },
  { name: { en: 'Burkina Faso', ru: 'Буркина-Фасо' }, alpha2: 'BF', alpha3: 'BFA', iso: 854 },
  { name: { en: 'Burundi', ru: 'Бурунди' }, alpha2: 'BI', alpha3: 'BDI', iso: 108 },
  { name: { en: 'Bhutan', ru: 'Бутан' }, alpha2: 'BT', alpha3: 'BTN', iso: 64 },
  { name: { en: 'Vanuatu', ru: 'Вануату' }, alpha2: 'VU', alpha3: 'VUT', iso: 548 },
  { name: { en: 'Hungary', ru: 'Венгрия' }, alpha2: 'HU', alpha3: 'HUN', iso: 348 },
  { name: { en: 'Venezuela', ru: 'Венесуэла Боливарианская Республика' }, alpha2: 'VE', alpha3: 'VEN', iso: 862 },
  {
    name: { en: 'Virgin Islands, British', ru: 'Виргинские острова, Британские' },
    alpha2: 'VG',
    alpha3: 'VGB',
    iso: 92,
  },
  { name: { en: 'Virgin Islands, U.S.', ru: 'Виргинские острова, США' }, alpha2: 'VI', alpha3: 'VIR', iso: 850 },
  { name: { en: 'Vietnam', ru: 'Вьетнам' }, alpha2: 'VN', alpha3: 'VNM', iso: 704 },
  { name: { en: 'Gabon', ru: 'Габон' }, alpha2: 'GA', alpha3: 'GAB', iso: 266 },
  { name: { en: 'Haiti', ru: 'Гаити' }, alpha2: 'HT', alpha3: 'HTI', iso: 332 },
  { name: { en: 'Guyana', ru: 'Гайана' }, alpha2: 'GY', alpha3: 'GUY', iso: 328 },
  { name: { en: 'Gambia', ru: 'Гамбия' }, alpha2: 'GM', alpha3: 'GMB', iso: 270 },
  { name: { en: 'Ghana', ru: 'Гана' }, alpha2: 'GH', alpha3: 'GHA', iso: 288 },
  { name: { en: 'Guadeloupe', ru: 'Гваделупа' }, alpha2: 'GP', alpha3: 'GLP', iso: 312 },
  { name: { en: 'Guatemala', ru: 'Гватемала' }, alpha2: 'GT', alpha3: 'GTM', iso: 320 },
  { name: { en: 'Guinea', ru: 'Гвинея' }, alpha2: 'GN', alpha3: 'GIN', iso: 324 },
  { name: { en: 'Guinea-Bissau', ru: 'Гвинея-Бисау' }, alpha2: 'GW', alpha3: 'GNB', iso: 624 },
  { name: { en: 'Germany', ru: 'Германия' }, alpha2: 'DE', alpha3: 'DEU', iso: 276 },
  { name: { en: 'Guernsey', ru: 'Гернси' }, alpha2: 'GG', alpha3: 'GGY', iso: 831 },
  { name: { en: 'Gibraltar', ru: 'Гибралтар' }, alpha2: 'GI', alpha3: 'GIB', iso: 292 },
  { name: { en: 'Honduras', ru: 'Гондурас' }, alpha2: 'HN', alpha3: 'HND', iso: 340 },
  { name: { en: 'Hong Kong', ru: 'Гонконг' }, alpha2: 'HK', alpha3: 'HKG', iso: 344 },
  { name: { en: 'Grenada', ru: 'Гренада' }, alpha2: 'GD', alpha3: 'GRD', iso: 308 },
  { name: { en: 'Greenland', ru: 'Гренландия' }, alpha2: 'GL', alpha3: 'GRL', iso: 304 },
  { name: { en: 'Greece', ru: 'Греция' }, alpha2: 'GR', alpha3: 'GRC', iso: 300 },
  { name: { en: 'Georgia', ru: 'Грузия' }, alpha2: 'GE', alpha3: 'GEO', iso: 268 },
  { name: { en: 'Guam', ru: 'Гуам' }, alpha2: 'GU', alpha3: 'GUM', iso: 316 },
  { name: { en: 'Denmark', ru: 'Дания' }, alpha2: 'DK', alpha3: 'DNK', iso: 208 },
  { name: { en: 'Jersey', ru: 'Джерси' }, alpha2: 'JE', alpha3: 'JEY', iso: 832 },
  { name: { en: 'Djibouti', ru: 'Джибути' }, alpha2: 'DJ', alpha3: 'DJI', iso: 262 },
  { name: { en: 'Dominica', ru: 'Доминика' }, alpha2: 'DM', alpha3: 'DMA', iso: 212 },
  { name: { en: 'Dominican Republic', ru: 'Доминиканская Республика' }, alpha2: 'DO', alpha3: 'DOM', iso: 214 },
  { name: { en: 'Egypt', ru: 'Египет' }, alpha2: 'EG', alpha3: 'EGY', iso: 818 },
  { name: { en: 'Zambia', ru: 'Замбия' }, alpha2: 'ZM', alpha3: 'ZMB', iso: 894 },
  { name: { en: 'Western Sahara', ru: 'Западная Сахара' }, alpha2: 'EH', alpha3: 'ESH', iso: 732 },
  { name: { en: 'Zimbabwe', ru: 'Зимбабве' }, alpha2: 'ZW', alpha3: 'ZWE', iso: 716 },
  { name: { en: 'Israel', ru: 'Израиль' }, alpha2: 'IL', alpha3: 'ISR', iso: 376 },
  { name: { en: 'India', ru: 'Индия' }, alpha2: 'IN', alpha3: 'IND', iso: 356 },
  { name: { en: 'Indonesia', ru: 'Индонезия' }, alpha2: 'ID', alpha3: 'IDN', iso: 360 },
  { name: { en: 'Jordan', ru: 'Иордания' }, alpha2: 'JO', alpha3: 'JOR', iso: 400 },
  { name: { en: 'Iraq', ru: 'Ирак' }, alpha2: 'IQ', alpha3: 'IRQ', iso: 368 },
  {
    name: { en: 'Iran, Islamic Republic of', ru: 'Иран, Исламская Республика' },
    alpha2: 'IR',
    alpha3: 'IRN',
    iso: 364,
  },
  { name: { en: 'Ireland', ru: 'Ирландия' }, alpha2: 'IE', alpha3: 'IRL', iso: 372 },
  { name: { en: 'Iceland', ru: 'Исландия' }, alpha2: 'IS', alpha3: 'ISL', iso: 352 },
  { name: { en: 'Spain', ru: 'Испания' }, alpha2: 'ES', alpha3: 'ESP', iso: 724 },
  { name: { en: 'Italy', ru: 'Италия' }, alpha2: 'IT', alpha3: 'ITA', iso: 380 },
  { name: { en: 'Yemen', ru: 'Йемен' }, alpha2: 'YE', alpha3: 'YEM', iso: 887 },
  { name: { en: 'Cape Verde', ru: 'Кабо-Верде' }, alpha2: 'CV', alpha3: 'CPV', iso: 132 },
  { name: { en: 'Kazakhstan', ru: 'Казахстан' }, alpha2: 'KZ', alpha3: 'KAZ', iso: 398 },
  { name: { en: 'Cambodia', ru: 'Камбоджа' }, alpha2: 'KH', alpha3: 'KHM', iso: 116 },
  { name: { en: 'Cameroon', ru: 'Камерун' }, alpha2: 'CM', alpha3: 'CMR', iso: 120 },
  { name: { en: 'Canada', ru: 'Канада' }, alpha2: 'CA', alpha3: 'CAN', iso: 124 },
  { name: { en: 'Qatar', ru: 'Катар' }, alpha2: 'QA', alpha3: 'QAT', iso: 634 },
  { name: { en: 'Kenya', ru: 'Кения' }, alpha2: 'KE', alpha3: 'KEN', iso: 404 },
  { name: { en: 'Cyprus', ru: 'Кипр' }, alpha2: 'CY', alpha3: 'CYP', iso: 196 },
  { name: { en: 'Kyrgyzstan', ru: 'Киргизия' }, alpha2: 'KG', alpha3: 'KGZ', iso: 417 },
  { name: { en: 'Kiribati', ru: 'Кирибати' }, alpha2: 'KI', alpha3: 'KIR', iso: 296 },
  { name: { en: 'China', ru: 'Китай' }, alpha2: 'CN', alpha3: 'CHN', iso: 156 },
  { name: { en: 'Cocos (Keeling) Islands', ru: 'Кокосовые (Килинг) острова' }, alpha2: 'CC', alpha3: 'CCK', iso: 166 },
  { name: { en: 'Colombia', ru: 'Колумбия' }, alpha2: 'CO', alpha3: 'COL', iso: 170 },
  { name: { en: 'Comoros', ru: 'Коморы' }, alpha2: 'KM', alpha3: 'COM', iso: 174 },
  { name: { en: 'Congo', ru: 'Конго' }, alpha2: 'CG', alpha3: 'COG', iso: 178 },
  {
    name: { en: 'Congo, Democratic Republic of the', ru: 'Конго, Демократическая Республика' },
    alpha2: 'CD',
    alpha3: 'COD',
    iso: 180,
  },
  {
    name: { en: "Korea, Democratic People's republic of", ru: 'Корея, Народно-Демократическая Республика' },
    alpha2: 'KP',
    alpha3: 'PRK',
    iso: 408,
  },
  { name: { en: 'Korea, Republic of', ru: 'Корея, Республика' }, alpha2: 'KR', alpha3: 'KOR', iso: 410 },
  { name: { en: 'Costa Rica', ru: 'Коста-Рика' }, alpha2: 'CR', alpha3: 'CRI', iso: 188 },
  { name: { en: "Cote d'Ivoire", ru: "Кот д'Ивуар" }, alpha2: 'CI', alpha3: 'CIV', iso: 384 },
  { name: { en: 'Cuba', ru: 'Куба' }, alpha2: 'CU', alpha3: 'CUB', iso: 192 },
  { name: { en: 'Kuwait', ru: 'Кувейт' }, alpha2: 'KW', alpha3: 'KWT', iso: 414 },
  { name: { en: 'Curaçao', ru: 'Кюрасао' }, alpha2: 'CW', alpha3: 'CUW', iso: 531 },
  { name: { en: "Lao People's Democratic Republic", ru: 'Лаос' }, alpha2: 'LA', alpha3: 'LAO', iso: 418 },
  { name: { en: 'Latvia', ru: 'Латвия' }, alpha2: 'LV', alpha3: 'LVA', iso: 428 },
  { name: { en: 'Lesotho', ru: 'Лесото' }, alpha2: 'LS', alpha3: 'LSO', iso: 426 },
  { name: { en: 'Lebanon', ru: 'Ливан' }, alpha2: 'LB', alpha3: 'LBN', iso: 422 },
  {
    name: { en: 'Libyan Arab Jamahiriya', ru: 'Ливийская Арабская Джамахирия' },
    alpha2: 'LY',
    alpha3: 'LBY',
    iso: 434,
  },
  { name: { en: 'Liberia', ru: 'Либерия' }, alpha2: 'LR', alpha3: 'LBR', iso: 430 },
  { name: { en: 'Liechtenstein', ru: 'Лихтенштейн' }, alpha2: 'LI', alpha3: 'LIE', iso: 438 },
  { name: { en: 'Lithuania', ru: 'Литва' }, alpha2: 'LT', alpha3: 'LTU', iso: 440 },
  { name: { en: 'Luxembourg', ru: 'Люксембург' }, alpha2: 'LU', alpha3: 'LUX', iso: 442 },
  { name: { en: 'Mauritius', ru: 'Маврикий' }, alpha2: 'MU', alpha3: 'MUS', iso: 480 },
  { name: { en: 'Mauritania', ru: 'Мавритания' }, alpha2: 'MR', alpha3: 'MRT', iso: 478 },
  { name: { en: 'Madagascar', ru: 'Мадагаскар' }, alpha2: 'MG', alpha3: 'MDG', iso: 450 },
  { name: { en: 'Mayotte', ru: 'Майотта' }, alpha2: 'YT', alpha3: 'MYT', iso: 175 },
  { name: { en: 'Macao', ru: 'Макао' }, alpha2: 'MO', alpha3: 'MAC', iso: 446 },
  { name: { en: 'Malawi', ru: 'Малави' }, alpha2: 'MW', alpha3: 'MWI', iso: 454 },
  { name: { en: 'Malaysia', ru: 'Малайзия' }, alpha2: 'MY', alpha3: 'MYS', iso: 458 },
  { name: { en: 'Mali', ru: 'Мали' }, alpha2: 'ML', alpha3: 'MLI', iso: 466 },
  {
    name: {
      en: 'United States Minor Outlying Islands',
      ru: 'Малые Тихоокеанские отдаленные острова Соединенных Штатов',
    },
    alpha2: 'UM',
    alpha3: 'UMI',
    iso: 581,
  },
  { name: { en: 'Maldives', ru: 'Мальдивы' }, alpha2: 'MV', alpha3: 'MDV', iso: 462 },
  { name: { en: 'Malta', ru: 'Мальта' }, alpha2: 'MT', alpha3: 'MLT', iso: 470 },
  { name: { en: 'Morocco', ru: 'Марокко' }, alpha2: 'MA', alpha3: 'MAR', iso: 504 },
  { name: { en: 'Martinique', ru: 'Мартиника' }, alpha2: 'MQ', alpha3: 'MTQ', iso: 474 },
  { name: { en: 'Marshall Islands', ru: 'Маршалловы острова' }, alpha2: 'MH', alpha3: 'MHL', iso: 584 },
  { name: { en: 'Mexico', ru: 'Мексика' }, alpha2: 'MX', alpha3: 'MEX', iso: 484 },
  {
    name: { en: 'Micronesia, Federated States of', ru: 'Микронезия, Федеративные Штаты' },
    alpha2: 'FM',
    alpha3: 'FSM',
    iso: 583,
  },
  { name: { en: 'Mozambique', ru: 'Мозамбик' }, alpha2: 'MZ', alpha3: 'MOZ', iso: 508 },
  { name: { en: 'Moldova', ru: 'Молдова, Республика' }, alpha2: 'MD', alpha3: 'MDA', iso: 498 },
  { name: { en: 'Monaco', ru: 'Монако' }, alpha2: 'MC', alpha3: 'MCO', iso: 492 },
  { name: { en: 'Mongolia', ru: 'Монголия' }, alpha2: 'MN', alpha3: 'MNG', iso: 496 },
  { name: { en: 'Montserrat', ru: 'Монтсеррат' }, alpha2: 'MS', alpha3: 'MSR', iso: 500 },
  { name: { en: 'Myanmar', ru: 'Мьянма' }, alpha2: 'MM', alpha3: 'MMR', iso: 104 },
  { name: { en: 'Namibia', ru: 'Намибия' }, alpha2: 'NA', alpha3: 'NAM', iso: 516 },
  { name: { en: 'Nauru', ru: 'Науру' }, alpha2: 'NR', alpha3: 'NRU', iso: 520 },
  { name: { en: 'Nepal', ru: 'Непал' }, alpha2: 'NP', alpha3: 'NPL', iso: 524 },
  { name: { en: 'Niger', ru: 'Нигер' }, alpha2: 'NE', alpha3: 'NER', iso: 562 },
  { name: { en: 'Nigeria', ru: 'Нигерия' }, alpha2: 'NG', alpha3: 'NGA', iso: 566 },
  { name: { en: 'Netherlands', ru: 'Нидерланды' }, alpha2: 'NL', alpha3: 'NLD', iso: 528 },
  { name: { en: 'Nicaragua', ru: 'Никарагуа' }, alpha2: 'NI', alpha3: 'NIC', iso: 558 },
  { name: { en: 'Niue', ru: 'Ниуэ' }, alpha2: 'NU', alpha3: 'NIU', iso: 570 },
  { name: { en: 'New Zealand', ru: 'Новая Зеландия' }, alpha2: 'NZ', alpha3: 'NZL', iso: 554 },
  { name: { en: 'New Caledonia', ru: 'Новая Каледония' }, alpha2: 'NC', alpha3: 'NCL', iso: 540 },
  { name: { en: 'Norway', ru: 'Норвегия' }, alpha2: 'NO', alpha3: 'NOR', iso: 578 },
  { name: { en: 'United Arab Emirates', ru: 'Объединенные Арабские Эмираты' }, alpha2: 'AE', alpha3: 'ARE', iso: 784 },
  { name: { en: 'Oman', ru: 'Оман' }, alpha2: 'OM', alpha3: 'OMN', iso: 512 },
  { name: { en: 'Bouvet Island', ru: 'Остров Буве' }, alpha2: 'BV', alpha3: 'BVT', iso: 74 },
  { name: { en: 'Isle of Man', ru: 'Остров Мэн' }, alpha2: 'IM', alpha3: 'IMN', iso: 833 },
  { name: { en: 'Norfolk Island', ru: 'Остров Норфолк' }, alpha2: 'NF', alpha3: 'NFK', iso: 574 },
  { name: { en: 'Christmas Island', ru: 'Остров Рождества' }, alpha2: 'CX', alpha3: 'CXR', iso: 162 },
  {
    name: { en: 'Heard Island and McDonald Islands', ru: 'Остров Херд и острова Макдональд' },
    alpha2: 'HM',
    alpha3: 'HMD',
    iso: 334,
  },
  { name: { en: 'Cayman Islands', ru: 'Острова Кайман' }, alpha2: 'KY', alpha3: 'CYM', iso: 136 },
  { name: { en: 'Cook Islands', ru: 'Острова Кука' }, alpha2: 'CK', alpha3: 'COK', iso: 184 },
  {
    name: { en: 'Turks and Caicos Islands', ru: 'Острова Теркс и Кайкос' },
    alpha2: 'TC',
    alpha3: 'TCA',
    iso: 796,
  },
  { name: { en: 'Pakistan', ru: 'Пакистан' }, alpha2: 'PK', alpha3: 'PAK', iso: 586 },
  { name: { en: 'Palau', ru: 'Палау' }, alpha2: 'PW', alpha3: 'PLW', iso: 585 },
  {
    name: { en: 'Palestinian Territory, Occupied', ru: 'Палестинская территория, оккупированная' },
    alpha2: 'PS',
    alpha3: 'PSE',
    iso: 275,
  },
  { name: { en: 'Panama', ru: 'Панама' }, alpha2: 'PA', alpha3: 'PAN', iso: 591 },
  {
    name: { en: 'Holy See (Vatican City State)', ru: 'Папский Престол (Государство-город Ватикан)' },
    alpha2: 'VA',
    alpha3: 'VAT',
    iso: 336,
  },
  { name: { en: 'Papua New Guinea', ru: 'Папуа-Новая Гвинея' }, alpha2: 'PG', alpha3: 'PNG', iso: 598 },
  { name: { en: 'Paraguay', ru: 'Парагвай' }, alpha2: 'PY', alpha3: 'PRY', iso: 600 },
  { name: { en: 'Peru', ru: 'Перу' }, alpha2: 'PE', alpha3: 'PER', iso: 604 },
  { name: { en: 'Pitcairn', ru: 'Питкерн' }, alpha2: 'PN', alpha3: 'PCN', iso: 612 },
  { name: { en: 'Poland', ru: 'Польша' }, alpha2: 'PL', alpha3: 'POL', iso: 616 },
  { name: { en: 'Portugal', ru: 'Португалия' }, alpha2: 'PT', alpha3: 'PRT', iso: 620 },
  { name: { en: 'Puerto Rico', ru: 'Пуэрто-Рико' }, alpha2: 'PR', alpha3: 'PRI', iso: 630 },
  {
    name: { en: 'Macedonia, The Former Yugoslav Republic Of', ru: 'Республика Македония' },
    alpha2: 'MK',
    alpha3: 'MKD',
    iso: 807,
  },
  { name: { en: 'Reunion', ru: 'Реюньон' }, alpha2: 'RE', alpha3: 'REU', iso: 638 },
  { name: { en: 'Russian Federation', ru: 'Россия' }, alpha2: 'RU', alpha3: 'RUS', iso: 643 },
  { name: { en: 'Rwanda', ru: 'Руанда' }, alpha2: 'RW', alpha3: 'RWA', iso: 646 },
  { name: { en: 'Romania', ru: 'Румыния' }, alpha2: 'RO', alpha3: 'ROU', iso: 642 },
  { name: { en: 'Samoa', ru: 'Самоа' }, alpha2: 'WS', alpha3: 'WSM', iso: 882 },
  { name: { en: 'San Marino', ru: 'Сан-Марино' }, alpha2: 'SM', alpha3: 'SMR', iso: 674 },
  { name: { en: 'Sao Tome and Principe', ru: 'Сан-Томе и Принсипи' }, alpha2: 'ST', alpha3: 'STP', iso: 678 },
  { name: { en: 'Saudi Arabia', ru: 'Саудовская Аравия' }, alpha2: 'SA', alpha3: 'SAU', iso: 682 },
  {
    name: {
      en: 'Saint Helena, Ascension And Tristan Da Cunha',
      ru: 'Святая Елена, Остров вознесения, Тристан-да-Кунья',
    },
    alpha2: 'SH',
    alpha3: 'SHN',
    iso: 654,
  },
  {
    name: { en: 'Northern Mariana Islands', ru: 'Северные Марианские острова' },
    alpha2: 'MP',
    alpha3: 'MNP',
    iso: 580,
  },
  { name: { en: 'Saint Barthélemy', ru: 'Сен-Бартельми' }, alpha2: 'BL', alpha3: 'BLM', iso: 652 },
  { name: { en: 'Saint Martin (French Part)', ru: 'Сен-Мартен' }, alpha2: 'MF', alpha3: 'MAF', iso: 663 },
  { name: { en: 'Senegal', ru: 'Сенегал' }, alpha2: 'SN', alpha3: 'SEN', iso: 686 },
  {
    name: { en: 'Saint Vincent and the Grenadines', ru: 'Сент-Винсент и Гренадины' },
    alpha2: 'VC',
    alpha3: 'VCT',
    iso: 670,
  },
  { name: { en: 'Saint Lucia', ru: 'Сент-Люсия' }, alpha2: 'LC', alpha3: 'LCA', iso: 662 },
  { name: { en: 'Saint Kitts and Nevis', ru: 'Сент-Китс и Невис' }, alpha2: 'KN', alpha3: 'KNA', iso: 659 },
  { name: { en: 'Saint Pierre and Miquelon', ru: 'Сент-Пьер и Микелон' }, alpha2: 'PM', alpha3: 'SPM', iso: 666 },
  { name: { en: 'Serbia', ru: 'Сербия' }, alpha2: 'RS', alpha3: 'SRB', iso: 688 },
  { name: { en: 'Seychelles', ru: 'Сейшелы' }, alpha2: 'SC', alpha3: 'SYC', iso: 690 },
  { name: { en: 'Singapore', ru: 'Сингапур' }, alpha2: 'SG', alpha3: 'SGP', iso: 702 },
  { name: { en: 'Sint Maarten', ru: 'Синт-Мартен' }, alpha2: 'SX', alpha3: 'SXM', iso: 534 },
  { name: { en: 'Syrian Arab Republic', ru: 'Сирийская Арабская Республика' }, alpha2: 'SY', alpha3: 'SYR', iso: 760 },
  { name: { en: 'Slovakia', ru: 'Словакия' }, alpha2: 'SK', alpha3: 'SVK', iso: 703 },
  { name: { en: 'Slovenia', ru: 'Словения' }, alpha2: 'SI', alpha3: 'SVN', iso: 705 },
  { name: { en: 'United Kingdom', ru: 'Соединенное Королевство' }, alpha2: 'GB', alpha3: 'GBR', iso: 826 },
  { name: { en: 'United States', ru: 'Соединенные Штаты' }, alpha2: 'US', alpha3: 'USA', iso: 840 },
  { name: { en: 'Solomon Islands', ru: 'Соломоновы острова' }, alpha2: 'SB', alpha3: 'SLB', iso: 90 },
  { name: { en: 'Somalia', ru: 'Сомали' }, alpha2: 'SO', alpha3: 'SOM', iso: 706 },
  { name: { en: 'Sudan', ru: 'Судан' }, alpha2: 'SD', alpha3: 'SDN', iso: 729 },
  { name: { en: 'Suriname', ru: 'Суринам' }, alpha2: 'SR', alpha3: 'SUR', iso: 740 },
  { name: { en: 'Sierra Leone', ru: 'Сьерра-Леоне' }, alpha2: 'SL', alpha3: 'SLE', iso: 694 },
  { name: { en: 'Tajikistan', ru: 'Таджикистан' }, alpha2: 'TJ', alpha3: 'TJK', iso: 762 },
  { name: { en: 'Thailand', ru: 'Таиланд' }, alpha2: 'TH', alpha3: 'THA', iso: 764 },
  { name: { en: 'Taiwan, Province of China', ru: 'Тайвань (Китай)' }, alpha2: 'TW', alpha3: 'TWN', iso: 158 },
  {
    name: { en: 'Tanzania, United Republic Of', ru: 'Танзания, Объединенная Республика' },
    alpha2: 'TZ',
    alpha3: 'TZA',
    iso: 834,
  },
  { name: { en: 'Timor-Leste', ru: 'Тимор-Лесте' }, alpha2: 'TL', alpha3: 'TLS', iso: 626 },
  { name: { en: 'Togo', ru: 'Того' }, alpha2: 'TG', alpha3: 'TGO', iso: 768 },
  { name: { en: 'Tokelau', ru: 'Токелау' }, alpha2: 'TK', alpha3: 'TKL', iso: 772 },
  { name: { en: 'Tonga', ru: 'Тонга' }, alpha2: 'TO', alpha3: 'TON', iso: 776 },
  { name: { en: 'Trinidad and Tobago', ru: 'Тринидад и Тобаго' }, alpha2: 'TT', alpha3: 'TTO', iso: 780 },
  { name: { en: 'Tuvalu', ru: 'Тувалу' }, alpha2: 'TV', alpha3: 'TUV', iso: 798 },
  { name: { en: 'Tunisia', ru: 'Тунис' }, alpha2: 'TN', alpha3: 'TUN', iso: 788 },
  { name: { en: 'Turkmenistan', ru: 'Туркмения' }, alpha2: 'TM', alpha3: 'TKM', iso: 795 },
  { name: { en: 'Turkey', ru: 'Турция' }, alpha2: 'TR', alpha3: 'TUR', iso: 792 },
  { name: { en: 'Uganda', ru: 'Уганда' }, alpha2: 'UG', alpha3: 'UGA', iso: 800 },
  { name: { en: 'Uzbekistan', ru: 'Узбекистан' }, alpha2: 'UZ', alpha3: 'UZB', iso: 860 },
  { name: { en: 'Ukraine', ru: 'Украина' }, alpha2: 'UA', alpha3: 'UKR', iso: 804 },
  { name: { en: 'Wallis and Futuna', ru: 'Уоллис и Футуна' }, alpha2: 'WF', alpha3: 'WLF', iso: 876 },
  { name: { en: 'Uruguay', ru: 'Уругвай' }, alpha2: 'UY', alpha3: 'URY', iso: 858 },
  { name: { en: 'Faroe Islands', ru: 'Фарерские острова' }, alpha2: 'FO', alpha3: 'FRO', iso: 234 },
  { name: { en: 'Fiji', ru: 'Фиджи' }, alpha2: 'FJ', alpha3: 'FJI', iso: 242 },
  { name: { en: 'Philippines', ru: 'Филиппины' }, alpha2: 'PH', alpha3: 'PHL', iso: 608 },
  { name: { en: 'Finland', ru: 'Финляндия' }, alpha2: 'FI', alpha3: 'FIN', iso: 246 },
  {
    name: { en: 'Falkland Islands (Malvinas)', ru: 'Фолклендские острова (Мальвинские)' },
    alpha2: 'FK',
    alpha3: 'FLK',
    iso: 238,
  },
  { name: { en: 'France', ru: 'Франция' }, alpha2: 'FR', alpha3: 'FRA', iso: 250 },
  { name: { en: 'French Guiana', ru: 'Французская Гвиана' }, alpha2: 'GF', alpha3: 'GUF', iso: 254 },
  { name: { en: 'French Polynesia', ru: 'Французская Полинезия' }, alpha2: 'PF', alpha3: 'PYF', iso: 258 },
  {
    name: { en: 'French Southern Territories', ru: 'Французские Южные территории' },
    alpha2: 'TF',
    alpha3: 'ATF',
    iso: 260,
  },
  { name: { en: 'Croatia', ru: 'Хорватия' }, alpha2: 'HR', alpha3: 'HRV', iso: 191 },
  {
    name: { en: 'Central African Republic', ru: 'Центрально-Африканская Республика' },
    alpha2: 'CF',
    alpha3: 'CAF',
    iso: 140,
  },
  { name: { en: 'Chad', ru: 'Чад' }, alpha2: 'TD', alpha3: 'TCD', iso: 148 },
  { name: { en: 'Montenegro', ru: 'Черногория' }, alpha2: 'ME', alpha3: 'MNE', iso: 499 },
  { name: { en: 'Czech Republic', ru: 'Чешская Республика' }, alpha2: 'CZ', alpha3: 'CZE', iso: 203 },
  { name: { en: 'Chile', ru: 'Чили' }, alpha2: 'CL', alpha3: 'CHL', iso: 152 },
  { name: { en: 'Switzerland', ru: 'Швейцария' }, alpha2: 'CH', alpha3: 'CHE', iso: 756 },
  { name: { en: 'Sweden', ru: 'Швеция' }, alpha2: 'SE', alpha3: 'SWE', iso: 752 },
  {
    name: { en: 'Svalbard and Jan Mayen', ru: 'Шпицберген и Ян Майен' },
    alpha2: 'SJ',
    alpha3: 'SJM',
    iso: 744,
  },
  { name: { en: 'Sri Lanka', ru: 'Шри-Ланка' }, alpha2: 'LK', alpha3: 'LKA', iso: 144 },
  { name: { en: 'Ecuador', ru: 'Эквадор' }, alpha2: 'EC', alpha3: 'ECU', iso: 218 },
  { name: { en: 'Equatorial Guinea', ru: 'Экваториальная Гвинея' }, alpha2: 'GQ', alpha3: 'GNQ', iso: 226 },
  { name: { en: 'Åland Islands', ru: 'Эландские острова' }, alpha2: 'AX', alpha3: 'ALA', iso: 248 },
  { name: { en: 'El Salvador', ru: 'Эль-Сальвадор' }, alpha2: 'SV', alpha3: 'SLV', iso: 222 },
  { name: { en: 'Eritrea', ru: 'Эритрея' }, alpha2: 'ER', alpha3: 'ERI', iso: 232 },
  { name: { en: 'Eswatini', ru: 'Эсватини' }, alpha2: 'SZ', alpha3: 'SWZ', iso: 748 },
  { name: { en: 'Estonia', ru: 'Эстония' }, alpha2: 'EE', alpha3: 'EST', iso: 233 },
  { name: { en: 'Ethiopia', ru: 'Эфиопия' }, alpha2: 'ET', alpha3: 'ETH', iso: 231 },
  { name: { en: 'South Africa', ru: 'Южная Африка' }, alpha2: 'ZA', alpha3: 'ZAF', iso: 710 },
  {
    name: { en: 'South Georgia and the South Sandwich Islands', ru: 'Южная Джорджия и Южные Сандвичевы острова' },
    alpha2: 'GS',
    alpha3: 'SGS',
    iso: 239,
  },
  { name: { en: 'South Ossetia', ru: 'Южная Осетия' }, alpha2: 'OS', alpha3: 'OST', iso: 896 },
  { name: { en: 'South Sudan', ru: 'Южный Судан' }, alpha2: 'SS', alpha3: 'SSD', iso: 728 },
  { name: { en: 'Jamaica', ru: 'Ямайка' }, alpha2: 'JM', alpha3: 'JAM', iso: 388 },
  { name: { en: 'Japan', ru: 'Япония' }, alpha2: 'JP', alpha3: 'JPN', iso: 392 },
] as ICountryInfo[];
