import React from 'react';
import ReactPlayer from 'react-player';

import './index.less';

import { Play } from './assets';

interface ISbVideoPlayerProps {
  id?: number;
  link?: string;
  height?: string;
  width?: string;
}
//NOTE: Добавить респонсив, минимальную ширину блоков. В документации написано, как сделать
const SbVideoPlayer: React.FC<ISbVideoPlayerProps> = ({ link, height, width }) => {
  return (
    <ReactPlayer
      controls
      light
      playing
      className="sb-bot-list-video"
      height={height}
      playIcon={<Play />}
      url={link}
      width={width}
    />
  );
};

export default SbVideoPlayer;
