import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSetRecoilState } from 'recoil';

import './index.less';

import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { UserInvitationRequest } from '../../../../../auth-api';
import { userAuthApi } from '../../../../apis';
import { AlertTypes, USER_ROLES } from '../../../../constants';
import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbModal from '../../../components/common/SbModal';
import SbScroll from '../../../components/common/SbScroll';
import SbInput from '../../../components/common/SbInput';
import SbPanel from '../../../components/common/SbPanel';
import SbTypography from '../../../components/common/SbTypography';
import { emailIsValid, formatFullName, formatShortName, getRoleNameLabel } from '../../../../utils/stringUtil';
import SbTagsSelect from '../../../components/common/SbTagsSelect';

const MODAL_WIDTH = 800;
const ROW_GUTTER = 24;
const NESTED_ROW_GUTTER = 12;
const LABEL_COLUMN_SPAN = 8;
const VALUE_COLUMN_SPAN = 16;

interface IInviteUserModalProps {
  visible: boolean;
  onClose: () => void;
  onInvite: () => Promise<void>;
}

const InviteUserModal: React.FC<IInviteUserModalProps> = ({ visible, onClose, onInvite }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [invited, setInvited] = useState(false);

  const [email, setEmail] = useState<string>();
  const [familyName, setFamilyName] = useState<string>();
  const [givenName, setGivenName] = useState<string>();
  const [middleName, setMiddleName] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [roleNames, setRoleNames] = useState<string[]>([]);

  const emailT = email?.trim();
  const familyNameT = familyName?.trim();
  const givenNameT = givenName?.trim();
  const middleNameT = middleName?.trim();
  const phoneNumberT = phoneNumber?.trim();

  const onRoleNamesChange = (values: string[]) =>
    setRoleNames(values.filter((v) => Object.values(USER_ROLES).includes(v)));

  const onVisiblePropChange = () => {
    if (!visible) return;

    setInvited(false);
    setEmail(undefined);
    setFamilyName(undefined);
    setGivenName(undefined);
    setMiddleName(undefined);
    setPhoneNumber(undefined);
    setRoleNames([]);
  };
  useEffect(onVisiblePropChange, [visible]);

  const onInviteButtonClick = async () => {
    if (!emailT || !emailIsValid(emailT)) return;

    setLoading(true);
    try {
      const meResponse = await userAuthApi.getMe();
      const me = meResponse.data;

      const invitationRequest: UserInvitationRequest = {
        login: emailT,
        email: emailT,
        phoneNumber: phoneNumberT,
        fullName: formatFullName(familyName, givenName, middleName),
        shortName: formatShortName(familyName, givenName, middleName),
        familyName: familyNameT,
        givenName: givenNameT,
        middleName: middleNameT,
        roleNames: roleNames.join(','),
        tenantNames: me.tenantNames,
      };
      await userAuthApi.inviteUser(invitationRequest);

      await onInvite();
      setInvited(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при отправке приглашения',
        error: e as Error,
      });
    }
    setLoading(false);
  };

  const modalButtons = invited
    ? [
        <SbButton key="close" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]
    : [
        <SbButton
          key="invite"
          icon={loading ? <SbIcon spin iconName="loading-four" size={16} /> : null}
          loading={loading}
          sbSize="medium"
          sbType="primary"
          onClick={onInviteButtonClick}
        >
          Выслать приглашение
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Отмена
        </SbButton>,
      ];

  return (
    <SbModal
      className="sb-invite-user-modal"
      footer={modalButtons}
      sbSize="small"
      title="Приглашение пользователя"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onClose}
    >
      <SbScroll>
        <div className="sb-invite-user-modal__content">
          {invited ? (
            <SbPanel sbType="help">
              <SbTypography>
                <h3>
                  Приглашение успешно отправлено на эл. почту <b>{emailT}</b>
                </h3>
              </SbTypography>
            </SbPanel>
          ) : (
            <>
              <Row gutter={ROW_GUTTER}>
                <Col span={LABEL_COLUMN_SPAN}>
                  <b>Эл. почта</b>
                </Col>
                <Col span={VALUE_COLUMN_SPAN}>
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col>
                      <SbInput
                        allowClear={false}
                        isValid={emailT === undefined || emailIsValid(emailT)}
                        placeholder="Эл. почта"
                        sbSize="small"
                        value={email}
                        onChange={setEmail}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={ROW_GUTTER}>
                <Col span={LABEL_COLUMN_SPAN}>
                  <b>Номер телефона</b>
                </Col>
                <Col span={VALUE_COLUMN_SPAN}>
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col>
                      <SbInput
                        allowClear={false}
                        placeholder="Номер телефона"
                        sbSize="small"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={ROW_GUTTER}>
                <Col span={LABEL_COLUMN_SPAN}>
                  <b>ФИО</b>
                </Col>
                <Col span={VALUE_COLUMN_SPAN}>
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col span={8}>
                      <SbInput
                        allowClear={false}
                        placeholder="Фамилия"
                        sbSize="small"
                        value={familyName}
                        onChange={setFamilyName}
                      />
                    </Col>
                    <Col span={8}>
                      <SbInput
                        allowClear={false}
                        placeholder="Имя"
                        sbSize="small"
                        value={givenName}
                        onChange={setGivenName}
                      />
                    </Col>
                    <Col span={8}>
                      <SbInput
                        allowClear={false}
                        placeholder="Отчество"
                        sbSize="small"
                        value={middleName}
                        onChange={setMiddleName}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={ROW_GUTTER}>
                <Col span={LABEL_COLUMN_SPAN}>
                  <b>Роли</b>
                </Col>
                <Col span={VALUE_COLUMN_SPAN}>
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col span={24}>
                      <SbTagsSelect
                        options={[
                          { value: USER_ROLES.admin, label: getRoleNameLabel(USER_ROLES.admin) },
                          { value: USER_ROLES.botsAdmin, label: getRoleNameLabel(USER_ROLES.botsAdmin) },
                          { value: USER_ROLES.usersAdmin, label: getRoleNameLabel(USER_ROLES.usersAdmin) },
                          { value: USER_ROLES.inboxOperator, label: getRoleNameLabel(USER_ROLES.inboxOperator) },
                          { value: USER_ROLES.inboxSupervisor, label: getRoleNameLabel(USER_ROLES.inboxSupervisor) },
                        ]}
                        value={roleNames}
                        onChange={onRoleNamesChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
      </SbScroll>
    </SbModal>
  );
};

export default InviteUserModal;
