import React from 'react';
import { Progress } from 'antd';

import './index.less';
import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon, { IbIconName } from '../IbIcon';

export type ProgressTheme = 'default' | 'blue' | 'gray';

const MAIN_CLASS_NAME = 'ib-progress';
const INTERACTIVE_CLASS_NAME = `${MAIN_CLASS_NAME}_interactive`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const BODY_CLASS_NAME = `${CONTENT_CLASS_NAME}__body`;
const ICON_CLASS_NAME = `${CONTENT_CLASS_NAME}__icon`;

const PROGRESS_WIDTH = 36;

export interface IIbProgressProps {
  className?: string;
  current: number;
  maximum: number;
  theme?: ProgressTheme;
  iconName?: IbIconName;
  onClick?: () => void;
}

const IbProgress: React.FC<IIbProgressProps> = ({ className, current, maximum, theme, iconName, onClick }) => {
  theme = getDefaultIfUndefined(theme, 'default');

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${theme}`];
  className && classes.push(className);
  iconName && classes.push(INTERACTIVE_CLASS_NAME);

  const renderBody = () => {
    const percent = maximum ? (current / maximum) * 100 : 0;

    return (
      <div className={BODY_CLASS_NAME}>
        <Progress percent={percent} showInfo={false} type="circle" width={PROGRESS_WIDTH} />
      </div>
    );
  };

  const renderIcon = () => {
    if (!iconName) {
      return null;
    }

    return (
      <div className={ICON_CLASS_NAME}>
        <IbIcon iconName={iconName} />
      </div>
    );
  };

  return (
    <button className={classes.join(' ')} onClick={onClick}>
      <div className={CONTENT_CLASS_NAME}>
        {renderBody()}
        {renderIcon()}
      </div>
    </button>
  );
};

export default IbProgress;
