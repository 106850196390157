import { useEffect } from 'react';

export function useEventListener(eventCode: string, eventHandler: (event: Event) => void): void {
  const updateEventListeners = () => {
    document.removeEventListener(eventCode, eventHandler, true);
    document.addEventListener(eventCode, eventHandler, true);
    return () => {
      document.removeEventListener(eventCode, eventHandler, true);
    };
  };

  useEffect(updateEventListeners);
}
