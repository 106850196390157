import axios from 'axios';
import { RcFile } from 'antd/es/upload';

import { getCurrentPath } from '../../utils/configUtil';
import { inboxAttachmentApi } from '../../apis';

import { IOperationProgress, ITaskContext, ITaskHandler } from './types';

interface IUploadProgressEvent {
  loaded: number;
  total: number;
}

export interface IAttachmentUploadTaskContent {
  activityId: string;
  fileId: string;
  file: RcFile;
}

export interface IAttachmentDeleteTaskContent {
  externalId: string;
  activityId: string;
}

export class AttachmentUploadTaskHandler implements ITaskHandler<IAttachmentUploadTaskContent> {
  private static s_instance?: AttachmentUploadTaskHandler;

  public static get instance(): AttachmentUploadTaskHandler {
    if (!AttachmentUploadTaskHandler.s_instance) {
      AttachmentUploadTaskHandler.s_instance = new AttachmentUploadTaskHandler();
    }

    return AttachmentUploadTaskHandler.s_instance;
  }

  public estimate(content: IAttachmentUploadTaskContent): IOperationProgress {
    return { current: 0, maximum: content.file.size };
  }

  public async process(content: IAttachmentUploadTaskContent, context: ITaskContext): Promise<unknown> {
    const onProgress = (uploadEvent: IUploadProgressEvent) => {
      const { loaded, total } = uploadEvent;
      const progress: IOperationProgress = { current: loaded, maximum: total };
      context.notify(progress);
    };

    const url = getCurrentPath('/api/v1/inbox/attachments');
    const data = new FormData();
    data.append('activityId', content.activityId);
    data.append('fileId', content.fileId);
    data.append('file', content.file);

    return axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    });
  }
}

export class AttachmentDeleteTaskHandler implements ITaskHandler<IAttachmentDeleteTaskContent> {
  private static s_instance?: AttachmentDeleteTaskHandler;

  public static get instance(): AttachmentDeleteTaskHandler {
    if (!AttachmentDeleteTaskHandler.s_instance) {
      AttachmentDeleteTaskHandler.s_instance = new AttachmentDeleteTaskHandler();
    }

    return AttachmentDeleteTaskHandler.s_instance;
  }

  public estimate(): IOperationProgress {
    return { current: 0, maximum: 1 };
  }

  public async process(content: IAttachmentDeleteTaskContent): Promise<unknown> {
    return await inboxAttachmentApi.deleteInboxAttachments(content.externalId, content.activityId);
  }
}
