import BetterQueue from 'better-queue';
import { v4 } from 'uuid';
import { Table } from 'dexie';

import { getDatabase, TableName } from '../storages';

import { ITaskData, ITaskItem, TaskOrder, TaskType } from './types';

enum LockId {
  None = '_none',
  Locked = '_locked',
}

type TaskMap = { [taskId: string]: ITaskData<unknown> };

export interface ITaskStorage extends BetterQueue.Store<ITaskData<unknown>> {
  getRunningTasks(callback: (error: unknown, tasks: TaskMap) => void): void;
}

export class TaskStorage implements ITaskStorage {
  private static s_emptyData: ITaskData<unknown> = {
    key: '',
    type: TaskType.None,
    timestamp: '',
    content: {},
  };

  private static s_instance?: TaskStorage;

  private _dbTable: Table<ITaskItem<unknown>, string>;

  private constructor() {
    const database = getDatabase();
    this._dbTable = database.table(TableName.QueueTasks);
  }

  public static get instance(): TaskStorage {
    if (!TaskStorage.s_instance) {
      TaskStorage.s_instance = new TaskStorage();
    }

    return TaskStorage.s_instance;
  }

  public connect(callback: (error: unknown, length: number) => void): void {
    this._dbTable
      .count()
      .then((count) => callback(undefined, count))
      .catch((error) => callback(error, 0));
  }

  public getTask(taskId: string, callback: (error: unknown, data: ITaskData<unknown>) => void): void {
    this._dbTable
      .get(taskId)
      .then((item) =>
        item ? callback(undefined, item.data) : callback(new Error('Task has not found.'), TaskStorage.s_emptyData)
      )
      .catch((error) => callback(error, TaskStorage.s_emptyData));
  }

  public deleteTask(taskId: string, callback: () => void): void {
    this._dbTable
      .delete(taskId)
      .then(() => callback())
      .catch(() => callback());
  }

  public putTask(taskId: string, data: ITaskData<unknown>, priority: number, callback: (error: unknown) => void): void {
    const item = {
      id: taskId,
      lockId: LockId.None,
      priority: priority || 0,
      data,
    };
    this._dbTable
      .put(item, taskId)
      .then(() => callback(undefined))
      .catch((error) => callback(error));
  }

  public takeFirstN(count: number, callback: (error: unknown, lockId: string) => void): void {
    this.lockTasks(count, TaskOrder.Ascending, callback);
  }

  public takeLastN(count: number, callback: (error: unknown, lockId: string) => void): void {
    this.lockTasks(count, TaskOrder.Descending, callback);
  }

  public getLock(lockId: string, callback: (error: unknown, tasks: TaskMap) => void): void {
    this.listTasks(lockId, callback);
  }

  public getRunningTasks(callback: (error: unknown, tasks: TaskMap) => void): void {
    this.listTasks(LockId.Locked, callback);
  }

  public releaseLock(lockId: string, callback: (error: unknown) => void): void {
    this._dbTable
      .where('lockId')
      .equals(lockId)
      .delete()
      .then(() => callback(undefined))
      .catch((error) => callback(error));
  }

  private lockTasks(count: number, order: TaskOrder, callback: (error: unknown, lockId: string) => void): void {
    this.getItems(LockId.None, count, order)
      .then((items) => {
        const lockId = v4();
        for (const item of items) {
          item.lockId = lockId;
        }
        this.putItems(items)
          .then(() => callback(undefined, lockId))
          .catch((error) => callback(error, LockId.None));
      })
      .catch((error) => callback(error, LockId.None));
  }

  private listTasks(lockId: string, callback: (error: unknown, tasks: TaskMap) => void): void {
    this.getItems(lockId)
      .then((items) => {
        const tasks: TaskMap = {};
        for (const item of items) {
          tasks[item.id] = item.data;
        }
        callback(undefined, tasks);
      })
      .catch((error) => callback(error, {}));
  }

  private getItems(lockId: string, count?: number, order?: TaskOrder): Promise<ITaskItem<unknown>[]> {
    let collection = this._dbTable.orderBy(['priority', 'data.timestamp']);

    switch (lockId) {
      case LockId.Locked:
        collection = collection.filter((item) => item.lockId !== LockId.None);
        break;

      default:
        collection = collection.filter((item) => item.lockId === lockId);
        break;
    }

    if (order === TaskOrder.Descending) {
      collection = collection.reverse();
    }

    if (count != null && count >= 0) {
      collection = collection.limit(count);
    }

    return collection.toArray();
  }

  private async putItems(items: ITaskItem<unknown>[]): Promise<void> {
    await this._dbTable.bulkPut(items);
  }
}
