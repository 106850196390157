import React, { useEffect, useState } from 'react';

import './index.less';
import useHorizontalScroll from '../../../../../hooks/useHorizontalScroll';
import { ICarouselItem } from '../../../../recoil';
import IbSpin from '../../IbSpin';

const MAIN_CLASS_NAME = 'ib-preview-modal-carousel';
const ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__item`;
const SELECTED_ITEM_CLASS_NAME = `${ITEM_CLASS_NAME}_selected`;
const LOADING_ITEM_CLASS_NAME = `${ITEM_CLASS_NAME}_loading`;
const ITEM_FADING_CLASS_NAME = `${ITEM_CLASS_NAME}__fading`;
const ITEM_SPINNER_WRAPPER_CLASS_NAME = `${ITEM_CLASS_NAME}__spinner-wrapper`;

export interface ICarouselProps {
  items: ICarouselItem[];
  selectedIndex: number;
  onSelect: (index: number) => void;
}

interface ICarouselItemState {
  loaded: boolean;
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

const getInitialItemsState = (items: ICarouselItem[]) =>
  items.map(() => {
    return { loaded: false, ref: React.createRef() } as ICarouselItemState;
  });

const Carousel: React.FC<ICarouselProps> = ({ items, selectedIndex, onSelect }) => {
  const scrollRef = useHorizontalScroll();

  const [itemsState, setItemsState] = useState<ICarouselItemState[]>(getInitialItemsState(items));

  useEffect(() => {
    setItemsState(getInitialItemsState(items));
  }, [items]);

  useEffect(() => {
    itemsState[selectedIndex].ref.current?.scrollIntoView({ block: 'center', behavior: 'auto' });
  }, [selectedIndex]);

  const renderItem = (item: ICarouselItem, index: number) => {
    const onItemClick = () => {
      onSelect(item.index);
    };

    const onImageLoad = () => {
      const newValue = [...itemsState];
      newValue[index].loaded = true;
      setItemsState(newValue);
    };

    const setItemRef = (el: HTMLDivElement | null) => {
      if (itemsState[item.index].ref.current !== null) return;

      const newValue = [...itemsState];
      newValue[item.index].ref.current = el;
      setItemsState(newValue);
    };

    const itemClasses = [ITEM_CLASS_NAME];
    selectedIndex === item.index && itemClasses.push(SELECTED_ITEM_CLASS_NAME);
    !itemsState[index].loaded && itemClasses.push(LOADING_ITEM_CLASS_NAME);

    return (
      <div key={item.index} ref={setItemRef} className={itemClasses.join(' ')} onClick={onItemClick}>
        <div className={ITEM_SPINNER_WRAPPER_CLASS_NAME}>
          <IbSpin />
        </div>
        <img alt="" src={item.url} onLoad={onImageLoad} />
        <div className={ITEM_FADING_CLASS_NAME} />
      </div>
    );
  };

  return (
    <div ref={scrollRef} className={MAIN_CLASS_NAME}>
      {items.map(renderItem)}
    </div>
  );
};

export default Carousel;
