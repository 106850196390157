import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAsync } from 'react-async-hook';

import './index.less';

import IbTypography from '../../components/common/IbTypography';
import IbButton from '../../components/common/IbButton';
import IbIcon from '../../components/common/IbIcon';
import { ChannelsEmptyLogo } from '../../assets';
import {
  AgentStageAccountStatus,
  InboxChannelCreationRequest,
  InboxChannelModel,
  InboxChannelModelPaginationResponse,
  InboxChannelUpdatingRequest,
} from '../../../../api';
import {
  channelListFilterSelector,
  channelListSelector,
  inboxAlertsSelectorAdd,
  operatorGroupListSelector,
} from '../../recoil';
import { CHANNEL_STATUS_UPDATED, Channels, RECOIL_LOADABLE_STATES } from '../../../constants';
import { inboxChannelApi } from '../../../apis';
import IbPageTitle from '../../components/IbPageTitle';
import IbSpin from '../../components/common/IbSpin';
import IbChannelList from '../../components/IbChannelList';
import IbChannelEditModal, { IChannelEditFormValidationResult } from '../../components/IbChannelEditModal';
import { tryGetValidationErrorMessage, isUnprocessableEntityError } from '../../../utils/errorUtils';
import IbChannelTuneModal, { IChannelRoutingFormValidationResult } from '../../components/IbChannelTuneModal';
import { hubConnections } from '../../../utils/socketsUtil';
import IbConfirmModal from '../../components/common/IbConfirmModal';
import { LIVE_CHAT_URL } from '../../../utils/configUtil';
import IbAccountWidgetWrapper from '../../wrappers/IbAccountWidgetWrapper';
import { IIbTagsSearchData } from '../../components/IbTagsSearch';
import IbChannelDiagnosticsModal from '../../components/IbChannelDiagnosticsModal';

const MAIN_CLASS_NAME = 'ib-channels-page';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const EMPTY_CONTENT_CLASS_NAME = `${CONTENT_CLASS_NAME}-empty`;
const EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME = `${EMPTY_CONTENT_CLASS_NAME}__placeholder`;
const SPIN_CLASS_NAME = `${CONTENT_CLASS_NAME}__spin`;

const SCROLL_THRESHOLD = '80px';
const SUCCESS_EDIT_VALIDATION_RESULT = {
  token: {
    isValid: true,
  },
  name: {
    isValid: true,
  },
} as IChannelEditFormValidationResult;
const SUCCESS_CONFIGURE_ROUTING_VALIDATION_RESULT = {
  maxChatCountPerOperator: {
    isValid: true,
  },
} as IChannelRoutingFormValidationResult;

interface ITelegramWebhookInfoResponse {
  isOk: boolean;
  result: unknown;
}

const getEditFormValidationResult = (error: Error) => {
  const tokenErrorMessage = tryGetValidationErrorMessage(error, 'AuthToken');
  return {
    ...SUCCESS_EDIT_VALIDATION_RESULT,
    token: {
      isValid: !tokenErrorMessage,
      message: tokenErrorMessage,
    },
  } as IChannelEditFormValidationResult;
};

const getRoutingFormValidationResult = (error: Error) => {
  const maxChatCountPerOperatorErrorMessage = tryGetValidationErrorMessage(error, 'MaxChatCountPerOperator');
  return {
    ...SUCCESS_CONFIGURE_ROUTING_VALIDATION_RESULT,
    token: {
      isValid: !maxChatCountPerOperatorErrorMessage,
      message: maxChatCountPerOperatorErrorMessage,
    },
  } as IChannelRoutingFormValidationResult;
};

const ChannelsPage: React.FC = () => {
  const { t } = useTranslation();

  const [livechatBasePath, setLivechatBasePath] = useState('');
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [configureRoutingModalVisible, setConfigureRoutingModalVisible] = useState(false);
  const [deleteChannel, setDeleteChannel] = useState<InboxChannelModel>();
  const [editChannel, setEditChannel] = useState<InboxChannelModel>();
  const [addedAgentStageAccountId, setAddedAgentStageAccountId] = useState<string>();
  const [configureRoutingChannel, setConfigureRoutingChannel] = useState<InboxChannelModel>();
  const [deleting, setDeleting] = useState(false);
  const [channelList, setChannelList] = useState([] as InboxChannelModel[]);
  const [channelListHasMore, setChannelListHasMore] = useState(false);
  const [diagnosticsModalVisible, setDiagnosticsModalVisible] = useState(false);
  const [diagnosticsChannel, setDiagnosticsChannel] = useState<InboxChannelModel>();
  const [webhookInfo, setWebhookInfo] = useState('');
  const [webhookInfoLoading, setWebhookInfoLoading] = useState(false);

  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [channelListFilter, setChannelListFilter] = useRecoilState(channelListFilterSelector);
  const channelListLoadable = useRecoilValueLoadable(channelListSelector);
  const channelListFilterIsSet =
    channelListFilter.search != undefined || channelListFilter.status != undefined || !!channelListFilter.channelId;
  const channelListIsLoading = channelListLoadable.state === RECOIL_LOADABLE_STATES.loading && !channelListFilterIsSet;
  const channelListIsEmpty =
    !channelListIsLoading && !channelListLoadable.contents.items?.length && !channelListFilterIsSet;

  const groupList = useRecoilValue(operatorGroupListSelector);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const closeEditModal = () => {
    setEditModalVisible(false);
    setEditChannel(undefined);
    setAddedAgentStageAccountId(undefined);
  };

  const closeConfigureRoutingModal = () => {
    setConfigureRoutingModalVisible(false);
    setConfigureRoutingChannel(undefined);
  };

  const closeDiagnosticsModal = () => {
    setDiagnosticsModalVisible(false);
    setDiagnosticsChannel(undefined);
  };

  const openEditModal = (channel: InboxChannelModel) => {
    setEditChannel(channel);
    setEditModalVisible(true);
  };

  const openConfigureRoutingModal = (channel: InboxChannelModel) => {
    setConfigureRoutingChannel(channel);
    setConfigureRoutingModalVisible(true);
  };

  const openDiagnosticsModal = (channel: InboxChannelModel) => {
    setDiagnosticsChannel(channel);
    setDiagnosticsModalVisible(true);
  };

  const loadNextPage = () => {
    if (channelListHasMore) {
      setChannelListFilter({ ...channelListFilter, pageIndex: channelListFilter.pageIndex + 1, loadMore: true });
    }
  };

  const resetFilter = () => setChannelListFilter({ pageIndex: 0, loadMore: false });

  const resetFilterPage = () => setChannelListFilter({ ...channelListFilter, pageIndex: 0, loadMore: false });

  const updateChannel = async (channelId: string) => {
    try {
      const channelResponse = await inboxChannelApi.getInboxChannel(channelId);
      setChannelList(channelList.map((c) => (c.id !== channelId ? c : channelResponse.data)));
    } catch {
      // empty
    }
  };

  const onAdd = () => {
    setEditChannel(undefined);
    setEditModalVisible(true);
  };

  const onEditModalCancel = () => closeEditModal();

  const onDiagnosticsModalCancel = () => closeDiagnosticsModal();

  const onConfigureRoutingModalCancel = () => closeConfigureRoutingModal();

  const onChannelDeletionSelect = (channel: InboxChannelModel) => () => setDeleteChannel(channel);

  const onChannelEditingSelect = (channel: InboxChannelModel) => () => openEditModal(channel);

  const onChannelConfigureRoutingSelect = (channel: InboxChannelModel) => () => openConfigureRoutingModal(channel);

  const onChannelDiagnosticsSelect = (channel: InboxChannelModel) => () => openDiagnosticsModal(channel);

  const onChannelAdd = async (
    request: InboxChannelCreationRequest
  ): Promise<IChannelEditFormValidationResult | undefined> => {
    try {
      const creationResponse = await inboxChannelApi.createInboxChannel(request);
      setAddedAgentStageAccountId(creationResponse.data.agentStageAccountId);
      resetFilterPage();
      return SUCCESS_EDIT_VALIDATION_RESULT;
    } catch (e) {
      const error = e as Error;
      if (isUnprocessableEntityError(error)) {
        return getEditFormValidationResult(error);
      }
    }
  };

  const onChannelEdit = async (
    request: InboxChannelUpdatingRequest
  ): Promise<IChannelEditFormValidationResult | undefined> => {
    if (!editChannel) {
      return;
    }

    try {
      await inboxChannelApi.updateInboxChannel(editChannel.id, request);
      await updateChannel(editChannel.id);
      return SUCCESS_EDIT_VALIDATION_RESULT;
    } catch (e) {
      const error = e as Error;
      if (isUnprocessableEntityError(error)) {
        return getEditFormValidationResult(error);
      }
    }
  };

  const onChannelConfigureRouting = async (
    request: InboxChannelUpdatingRequest
  ): Promise<IChannelRoutingFormValidationResult | undefined> => {
    if (!configureRoutingChannel) {
      return;
    }

    try {
      await inboxChannelApi.updateInboxChannel(configureRoutingChannel.id, request);
      await updateChannel(configureRoutingChannel.id);
      return SUCCESS_CONFIGURE_ROUTING_VALIDATION_RESULT;
    } catch (e) {
      const error = e as Error;
      if (isUnprocessableEntityError(error)) {
        return getRoutingFormValidationResult(error);
      }
    }
  };

  const onDeleteConfirm = async () => {
    if (!deleteChannel) {
      return;
    }

    setDeleting(true);
    try {
      await inboxChannelApi.deleteInboxChannel(deleteChannel.id);
      resetFilterPage();
    } catch (e) {
      // empty
    }
    setDeleting(false);

    setDeleteChannel(undefined);
    closeEditModal();
  };

  const onDeleteModalCancel = () => setDeleteChannel(undefined);

  const onDebouncedSearch = (value: IIbTagsSearchData) => {
    if ((channelListFilter.search || '') !== value.searchValue) {
      setChannelListFilter({
        loadMore: false,
        pageIndex: 0,
        search: value.searchValue,
      });
    }
  };

  const onChannelStatusSwitch = (channel: InboxChannelModel) => async () => {
    try {
      const newStatus =
        channel.status === AgentStageAccountStatus.Disabled
          ? AgentStageAccountStatus.Enabled
          : AgentStageAccountStatus.Disabled;

      const request = {
        authToken: channel.authToken,
        displayName: channel.displayName,
        status: newStatus,
        routing: channel.routing,
        properties: channel.properties,
      } as InboxChannelUpdatingRequest;
      await inboxChannelApi.updateInboxChannel(channel.id, request);

      await updateChannel(channel.id);
    } catch (e) {
      const error = e as Error;
      if (isUnprocessableEntityError(error)) {
        const errorResult = getEditFormValidationResult(error);
        addAlert({
          content: errorResult.token.message,
          type: 'error',
        });
        return;
      }
    }
  };

  const onChannelListLoadableStateChange = () => {
    if (channelListLoadable.state !== RECOIL_LOADABLE_STATES.loading) {
      const data = channelListLoadable.contents as InboxChannelModelPaginationResponse;
      setChannelList(data.items || []);
      setChannelListHasMore(!!data.hasMore);
    }
  };
  useEffect(onChannelListLoadableStateChange, [channelListLoadable.state]);

  const channelUpdatedEventHandler = (args: { agentStageAccountId: string }) => {
    const channel = channelList.find((c) => c.agentStageAccount.id === args.agentStageAccountId);
    if (channel) {
      updateChannel(channel.id).finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
    return () => conn.off(CHANNEL_STATUS_UPDATED, channelUpdatedEventHandler);
  };
  useEffect(subscribe, [conn]);

  const loadWebhookInfoAsync = async () => {
    if (!diagnosticsChannel || diagnosticsChannel.channelId !== Channels.TELEGRAM) {
      setWebhookInfo('');
      return;
    }

    setWebhookInfoLoading(true);

    try {
      const webhookInfoResponse = await inboxChannelApi.getInboxChannelWebhookInfo(diagnosticsChannel.id);
      setWebhookInfo(
        JSON.stringify(((webhookInfoResponse.data as unknown) as ITelegramWebhookInfoResponse).result, null, 2)
      );
    } catch (e) {
      const error = e as Error;
      addAlert({
        content: error.message,
        type: 'error',
      });
    }

    setWebhookInfoLoading(false);
  };
  const loadWebhookInfo = () => {
    loadWebhookInfoAsync().finally();
  };
  useEffect(loadWebhookInfo, [diagnosticsChannel]);

  const loadLivechatBasePath = () => {
    setLivechatBasePath(LIVE_CHAT_URL);
  };
  const onInit = () => {
    resetFilter();
    loadLivechatBasePath();
  };
  useEffect(onInit, []);

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbPageTitle
        hideTagsFiltering
        accountWidget={<IbAccountWidgetWrapper />}
        showSearchInput={!channelListIsEmpty}
        title={t('Channels')}
        onAdd={onAdd}
        onDebouncedSearch={onDebouncedSearch}
      />
      <div
        className={`${CONTENT_CLASS_NAME} ${channelListIsEmpty ? EMPTY_CONTENT_CLASS_NAME : ''}`}
        id={CONTENT_CLASS_NAME}
      >
        {channelListIsEmpty ? (
          <div className={EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME}>
            <ChannelsEmptyLogo />
            <IbTypography>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Add new channels')}
                <br />
                {t('and collect messages in one system')}
              </IbTypography.Paragraph>
            </IbTypography>
            <IbButton icon={<IbIcon iconName="add-one" />} onClick={onAdd}>
              {t('Add')}
            </IbButton>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={channelList.length}
            hasMore={channelListHasMore}
            loader={
              <div className={SPIN_CLASS_NAME}>
                <IbSpin />
                <IbTypography.Paragraph disabled type="secondary">
                  {t('Loading')}
                </IbTypography.Paragraph>
              </div>
            }
            next={loadNextPage}
            scrollableTarget={CONTENT_CLASS_NAME}
            scrollThreshold={SCROLL_THRESHOLD}
          >
            <IbChannelList
              items={channelList.map((channel) => ({
                channel,
                onSwitch: onChannelStatusSwitch(channel),
                onDelete: onChannelDeletionSelect(channel),
                onEdit: onChannelEditingSelect(channel),
                onConfigureRouting: onChannelConfigureRoutingSelect(channel),
                onDiagnosticsSelect: onChannelDiagnosticsSelect(channel),
              }))}
              loading={channelListIsLoading && !channelList.length}
            />
          </InfiniteScroll>
        )}
      </div>
      <IbChannelEditModal
        addedAgentStageAccountId={addedAgentStageAccountId}
        editChannel={editChannel}
        livechatBasePath={livechatBasePath}
        visible={editModalVisible}
        onAdd={onChannelAdd}
        onCancel={onEditModalCancel}
        onEdit={onChannelEdit}
      />
      <IbChannelTuneModal
        configureRoutingChannel={configureRoutingChannel}
        groupList={groupList}
        visible={configureRoutingModalVisible}
        onCancel={onConfigureRoutingModalCancel}
        onConfigureRouting={onChannelConfigureRouting}
      />
      <IbChannelDiagnosticsModal
        channel={diagnosticsChannel}
        loading={webhookInfoLoading}
        visible={diagnosticsModalVisible}
        webhookInfo={webhookInfo}
        onCancel={onDiagnosticsModalCancel}
      />
      <IbConfirmModal
        footer={
          <>
            <IbButton disabled={deleting} onClick={onDeleteConfirm}>
              {t('Delete')}
            </IbButton>
            <IbButton type="secondary" onClick={onDeleteModalCancel}>
              {t('Cancel')}
            </IbButton>
            <IbButton type="fill" onClick={onDeleteModalCancel}>
              {t('Cancel (verb)')}
            </IbButton>
          </>
        }
        title={t('Delete channel?')}
        visible={!!deleteChannel}
        onCancel={onDeleteModalCancel}
      >
        <IbTypography.Paragraph>{t('All data associated with this channel will be lost')}</IbTypography.Paragraph>
      </IbConfirmModal>
    </div>
  );
};

export default ChannelsPage;
