import {
  ButtonSchema,
  ButtonType,
  CreateElma365AppElementActionSchema,
  Elma365FormPropertySchema,
  Elma365ProcessStartType,
  EndScenarioOutputSchema,
  EntitySchema,
  EntityValidationResult,
  FieldValidationResult,
  IntentContentSchema,
  MenuButtonTriggerSchema,
  ScenarioSchema,
  StartElma365ProcessActionSchema,
  StructureType,
  StructureValidationResult,
  ValidationRuleResult,
  ValidationRuleStatus,
  VariableSchema,
  VariableType,
  TriggerSchema,
  CaseActionSchema,
  ExternalSignInSchema,
  SendEmailSchema,
  CommandTriggerSchema,
  ExternalEventTriggerSchema,
  MergeInboxContactsSchema,
} from '../../../api';
import {
  EntityFieldPath,
  instanceOfActionsContainer,
  instanceOfButtonSchema,
  instanceOfChoiceTransitSchema,
  instanceOfConfirmTransitSchema,
  instanceOfCreateElma365AppElementActionSchema,
  instanceOfDateTimeInputSchema,
  instanceOfElma365AppElementInputSchema,
  instanceOfElma365EnumInputSchema,
  instanceOfElma365FormPropertySchema,
  instanceOfElma365UserInputSchema,
  instanceOfEndScenarioOutputSchema,
  instanceOfIntentContentSchema,
  instanceOfMenuButtonTriggerSchema,
  instanceOfMessagesContainer,
  instanceOfNumberInputSchema,
  instanceOfPersonNameInputSchema,
  instanceOfStartElma365ProcessActionSchema,
  instanceOfTriggersContainer,
  instanceOfVariableIdContainer,
  instanceOfVariableSchema,
  instanceOfTriggerSchema,
  instanceOfCaseActionSchema,
  instanceOfExternalSigninSchema,
  instanceOfOAuth2LoginProtocolSchema,
  instanceOfFileInputSchema,
  instanceOfSendEmailSchema,
  instanceOfTransitionTriggerSchema,
  instanceOfCommandTriggerSchema,
  instanceOfExternalEventTriggerSchema,
  instanceOfMergeInboxContactsSchema,
} from '../../components/ScenarioEditor/utils';
import {
  IActionsContainer,
  IMessagesContainer,
  ITriggersContainer,
  IVariableIdContainer,
} from '../../components/ScenarioEditor/types';
import { botStageApi } from '../../apis';
import { ValidationRuleCodes } from '../../components/ScenarioEditor/constants';
import { equalsIgnoreCase } from '../../utils/stringUtil';
import { hasScenarioActions } from '../../recoil/scenarioStructure/utils';
import { NAME_FIELD_NAME } from '../const';

import { IIndexedStructure } from './indexation';
import { getMatchedVariableTypes } from './elma365';

export enum ValidationStatus {
  Validating = 'Validating',
  Validated = 'Validated',
}

export interface IValidationIssue {
  index: number;
  entity: EntitySchema;
  fieldPath: string;
  ruleResult: ValidationRuleResult;
}

export interface IValidatedStructure extends StructureValidationResult {
  issueList: IValidationIssue[];
  issueMap: Map<string, IValidationIssue[]>;
  hasIssue: (entityId: string, fieldPath: string) => boolean;
  getIssues: (entityId: string, fieldPath: string, ruleCode: string) => IValidationIssue[];
  getVisibleIssueList: () => IValidationIssue[];
}

interface IValidationRule<TEntity> {
  code: string;
  enabled: boolean;
  tryExecute(entity: TEntity, structure: IIndexedStructure): Map<string, ValidationRuleResult[]>;
}

abstract class ValidationRule<TEntity> implements IValidationRule<TEntity> {
  protected constructor(public code: string, public enabled: boolean) {}
  public tryExecute(entity: TEntity, structure: IIndexedStructure) {
    if (!this.enabled) {
      return new Map();
    }
    if (this.canExecute(entity)) {
      return this.execute(entity, structure);
    }
    return new Map();
  }
  protected abstract canExecute(entity: TEntity): boolean;
  protected abstract execute(entity: TEntity, structure: IIndexedStructure): Map<string, ValidationRuleResult[]>;
}

abstract class SingleFieldValidationRule<TEntity> extends ValidationRule<TEntity> {
  protected constructor(
    public code: string,
    public enabled: boolean,
    private fieldPath: string,
    private warningMessage: string
  ) {
    super(code, enabled);
  }
  protected abstract isValid(entity: TEntity, structure: IIndexedStructure): boolean;
  protected execute(entity: TEntity, structure: IIndexedStructure) {
    if (this.isValid(entity, structure)) {
      return new Map<string, ValidationRuleResult[]>([
        [
          this.fieldPath,
          [
            {
              code: this.code,
              status: ValidationRuleStatus.Success,
              message: '',
            },
          ],
        ],
      ]);
    }
    return new Map<string, ValidationRuleResult[]>([
      [
        this.fieldPath,
        [
          {
            code: this.code,
            status: ValidationRuleStatus.Warning,
            message: this.warningMessage,
          },
        ],
      ],
    ]);
  }
}

abstract class CompositeFieldValidationRule<TEntity extends EntitySchema> extends ValidationRule<TEntity> {
  protected constructor(public code: string, public enabled: boolean) {
    super(code, enabled);
  }
}

class GroupTriggersRequired extends SingleFieldValidationRule<ITriggersContainer> {
  constructor(enabled: boolean) {
    super('Group.Triggers.Required', enabled, EntityFieldPath.Root, 'Укажите триггеры запуска сценария');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfTriggersContainer(entity);
  }
  protected isValid(entity: ITriggersContainer) {
    return !!entity.triggers?.length;
  }
}

class GroupActionsRequired extends SingleFieldValidationRule<IActionsContainer> {
  constructor(enabled: boolean) {
    super('Group.Actions.Required', enabled, EntityFieldPath.Root, 'Укажите действия диалога');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfActionsContainer(entity);
  }
  protected isValid(entity: IActionsContainer) {
    return !!entity.actions.length;
  }
}

class ActionMessagesRequired extends SingleFieldValidationRule<IMessagesContainer> {
  constructor(enabled: boolean) {
    super('Action.Messages.Required', enabled, EntityFieldPath.Messages, 'Заполните ответы бота');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfMessagesContainer(entity) && !instanceOfEndScenarioOutputSchema(entity);
  }
  protected isValid(entity: IMessagesContainer) {
    return !!entity.messages.length;
  }
}

class InputVariableRequired extends SingleFieldValidationRule<IVariableIdContainer> {
  constructor(enabled: boolean) {
    super('Input.Variable.Required', enabled, EntityFieldPath.VariableId, 'Укажите переменную для сохранения данных');
  }
  protected canExecute(entity: EntitySchema) {
    return (
      instanceOfVariableIdContainer(entity) &&
      !instanceOfChoiceTransitSchema(entity) &&
      !instanceOfConfirmTransitSchema(entity) &&
      !instanceOfStartElma365ProcessActionSchema(entity) &&
      !instanceOfCreateElma365AppElementActionSchema(entity) &&
      !instanceOfElma365FormPropertySchema(entity)
    );
  }
  protected isValid(entity: IVariableIdContainer) {
    return !!entity.variableId;
  }
}

class InputVariableExists extends SingleFieldValidationRule<IVariableIdContainer> {
  constructor(enabled: boolean) {
    super('Input.Variable.Exists', enabled, EntityFieldPath.VariableId, 'Указанная переменная не существует');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfVariableIdContainer(entity);
  }
  protected isValid(entity: IVariableIdContainer, structure: IIndexedStructure) {
    return !entity.variableId || structure.variableMap.has(entity.variableId);
  }
}

class InputVariableMatches extends SingleFieldValidationRule<IVariableIdContainer> {
  constructor(enabled: boolean) {
    super(
      'Input.Variable.Matches',
      enabled,
      EntityFieldPath.VariableId,
      'Тип запрашиваемых данных не соответствует типу переменной'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return (
      instanceOfVariableIdContainer(entity) &&
      !instanceOfStartElma365ProcessActionSchema(entity) &&
      !instanceOfCreateElma365AppElementActionSchema(entity) &&
      !instanceOfElma365FormPropertySchema(entity) &&
      !instanceOfMergeInboxContactsSchema(entity)
    );
  }
  protected isValid(entity: IVariableIdContainer, structure: IIndexedStructure) {
    return InputVariableMatches.isValid(entity, structure);
  }
  private static isValid(entity: IVariableIdContainer, structure: IIndexedStructure) {
    if (!entity.variableId) {
      return true;
    }

    const variable = structure.variableMap.get(entity.variableId);
    if (!variable) {
      return true;
    }

    if (instanceOfConfirmTransitSchema(entity)) {
      return variable.type === VariableType.Boolean;
    }

    if (instanceOfNumberInputSchema(entity)) {
      return variable.type === VariableType.Number;
    }

    if (instanceOfDateTimeInputSchema(entity)) {
      return variable.type === VariableType.DateTime;
    }

    if (instanceOfPersonNameInputSchema(entity)) {
      return variable.type === VariableType.PersonName;
    }

    if (instanceOfElma365UserInputSchema(entity)) {
      return variable.type === VariableType.ComplexObject;
    }

    if (instanceOfElma365AppElementInputSchema(entity)) {
      return variable.type === VariableType.ComplexObject;
    }

    if (instanceOfElma365EnumInputSchema(entity)) {
      return variable.type === VariableType.ComplexObject;
    }

    if (instanceOfFileInputSchema(entity)) {
      return variable.type === VariableType.File;
    }

    return variable.type === VariableType.String;
  }
}

class ButtonBindingRequired extends SingleFieldValidationRule<ButtonSchema> {
  constructor(enabled: boolean) {
    super(
      'Button.Binding.Required',
      enabled,
      EntityFieldPath.OutputBindingId,
      'Необходимо соединить кнопку со следующим шагом сценария'
    );
  }
  protected canExecute(entity: EntitySchema): entity is ButtonSchema {
    return instanceOfButtonSchema(entity) && entity.type !== ButtonType.Url;
  }
  protected isValid(entity: ButtonSchema) {
    return !!entity.outputBindingId;
  }
}

class TriggerBindingRequired extends SingleFieldValidationRule<TriggerSchema> {
  constructor(enabled: boolean) {
    super(
      'Trigger.Binding.Required',
      enabled,
      EntityFieldPath.OutputBindingId,
      'Необходимо соединить триггер со следующим шагом сценария'
    );
  }
  protected canExecute(entity: EntitySchema): entity is TriggerSchema {
    return instanceOfTriggerSchema(entity);
  }
  protected isValid(entity: TriggerSchema) {
    return !!entity.outputBindingId;
  }
}

class TransitionTrigerRequired extends SingleFieldValidationRule<ITriggersContainer> {
  constructor(enabled: boolean) {
    super('Trigger.Transition.Required', enabled, EntityFieldPath.Root, 'Не указан переход из другого сценария');
  }
  protected canExecute(entity: EntitySchema): entity is ITriggersContainer {
    return instanceOfTriggersContainer(entity);
  }
  protected isValid(entity: ITriggersContainer, structure: IIndexedStructure) {
    if (!structure.scenarioStructure || !structure.scenarioStructures) {
      return true;
    }

    if (entity.triggers.some((t) => instanceOfTransitionTriggerSchema(t))) {
      return true;
    }

    return !hasScenarioActions(structure.scenarioStructures, structure.scenarioStructure);
  }
}

class CaseActionBindingRequired extends SingleFieldValidationRule<CaseActionSchema> {
  constructor(enabled: boolean) {
    super(
      'CaseAction.Binding.Required',
      enabled,
      EntityFieldPath.OutputBindingId,
      'Необходимо соединить условие со следующим шагом сценария'
    );
  }
  protected canExecute(entity: EntitySchema): entity is CaseActionSchema {
    return instanceOfCaseActionSchema(entity);
  }
  protected isValid(entity: CaseActionSchema) {
    return !!entity.outputBindingId;
  }
}

class EndScenarioTransitionRequired extends SingleFieldValidationRule<EndScenarioOutputSchema> {
  constructor(enabled: boolean) {
    super(
      'EndScenario.Transition.Required',
      enabled,
      EntityFieldPath.TransitionScenarioId,
      'Укажите сценарий для перехода'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfEndScenarioOutputSchema(entity);
  }
  protected isValid(entity: EndScenarioOutputSchema) {
    return !!entity.transitionScenarioId;
  }
}

class VariableNameUnique extends SingleFieldValidationRule<VariableSchema> {
  constructor(enabled: boolean) {
    super('Variable.Name.Unique', enabled, EntityFieldPath.Name, 'Переменная с таким именем уже существует');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfVariableSchema(entity) && !!entity.name;
  }
  protected isValid(entity: IVariableIdContainer, structure: IIndexedStructure) {
    return !entity.name || structure.variableList.every((v) => v.id === entity.id || v.name !== entity.name);
  }
}

class VariableCodeUnique extends SingleFieldValidationRule<VariableSchema> {
  constructor(enabled: boolean) {
    super('Variable.Code.Unique', enabled, EntityFieldPath.Name, 'Переменная с таким кодом уже существует');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfVariableSchema(entity) && !!entity.code;
  }
  protected isValid(entity: IVariableIdContainer, structure: IIndexedStructure) {
    return !entity.code || structure.variableList.every((v) => v.id === entity.id || v.code !== entity.code);
  }
}

class MenuButtonTriggerValueRequired extends SingleFieldValidationRule<MenuButtonTriggerSchema> {
  constructor(enabled: boolean) {
    super('MenuButtonTrigger.Value.Required', enabled, EntityFieldPath.Value, 'Заполните текст пункта меню');
  }
  protected canExecute(entity: EntitySchema): entity is MenuButtonTriggerSchema {
    return instanceOfMenuButtonTriggerSchema(entity);
  }
  protected isValid(entity: MenuButtonTriggerSchema) {
    return !!entity.value;
  }
}

class IntentContentDuplicateInScenario extends CompositeFieldValidationRule<IntentContentSchema> {
  constructor(enabled: boolean) {
    super(ValidationRuleCodes.IntentContentDuplicateInScenario, enabled);
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfIntentContentSchema(entity);
  }
  protected execute(entity: IntentContentSchema, structure: IIndexedStructure) {
    const validationRuleResults = new Map();

    const otherUserIntents = structure.entityList
      .filter((e) => e.id !== entity.id && instanceOfIntentContentSchema(e))
      .map((e) => e as IntentContentSchema);

    const nameValidationRuleResults: ValidationRuleResult[] = [];
    otherUserIntents.forEach((otherUserIntent) => {
      if (equalsIgnoreCase(otherUserIntent.name, entity.name)) {
        nameValidationRuleResults.push({
          code: this.code,
          status: ValidationRuleStatus.Warning,
          message: 'Интент с таким именем уже существует',
        });
      }
    });
    validationRuleResults.set(NAME_FIELD_NAME, nameValidationRuleResults);

    entity.utterances.forEach((utterance, utteranceIndex) => {
      if (!utterance) return;

      const fieldValidationRuleResults: ValidationRuleResult[] = [];

      if (entity.utterances.some((u, i) => i !== utteranceIndex && equalsIgnoreCase(u, utterance))) {
        fieldValidationRuleResults.push({
          code: this.code,
          status: ValidationRuleStatus.Warning,
          message: 'В списке уже есть такая фраза, удалите лишнюю',
        });
      }

      otherUserIntents.forEach((otherUserIntent) => {
        if (otherUserIntent.utterances.some((u) => equalsIgnoreCase(u, utterance))) {
          fieldValidationRuleResults.push({
            code: this.code,
            status: ValidationRuleStatus.Warning,
            message: `Совпадает с фразой интента "${otherUserIntent.name}"`,
          });
        }
      });

      validationRuleResults.set(`utterances[${utteranceIndex}]`, fieldValidationRuleResults);
    });

    return validationRuleResults;
  }
}

class Elma365FormPropertyVariableRequired extends SingleFieldValidationRule<Elma365FormPropertySchema> {
  constructor(enabled: boolean) {
    super('Elma365FormProperty.Variable.Required', enabled, EntityFieldPath.VariableId, 'Заполните обязательное поле');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfElma365FormPropertySchema(entity);
  }
  protected isValid(entity: Elma365FormPropertySchema) {
    return !entity.required || !!entity.variableId;
  }
}

class Elma365FormPropertyVariableMatches extends SingleFieldValidationRule<Elma365FormPropertySchema> {
  constructor(enabled: boolean) {
    super(
      'Elma365FormProperty.Variable.Matches',
      enabled,
      EntityFieldPath.VariableId,
      'Не верно выбран тип переменной'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfElma365FormPropertySchema(entity);
  }
  protected isValid(entity: Elma365FormPropertySchema, structure: IIndexedStructure) {
    if (!entity.variableId) {
      return true;
    }

    const variable = structure.variableMap.get(entity.variableId);
    if (!variable) {
      return true;
    }

    return getMatchedVariableTypes(entity).includes(variable.type);
  }
}

class StartElma365ProcessResultVariableMatches extends SingleFieldValidationRule<StartElma365ProcessActionSchema> {
  constructor(enabled: boolean) {
    super(
      'StartElma365Process.ResultVariable.Matches',
      enabled,
      EntityFieldPath.VariableId,
      'Не верно выбран тип переменной'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfStartElma365ProcessActionSchema(entity);
  }
  protected isValid(entity: StartElma365ProcessActionSchema, structure: IIndexedStructure) {
    if (!entity.variableId) {
      return true;
    }

    const variable = structure.variableMap.get(entity.variableId);
    if (!variable) {
      return true;
    }

    return variable.type === VariableType.ComplexObject;
  }
}

class CreateElma365AppElementResultVariableMatches extends SingleFieldValidationRule<
  CreateElma365AppElementActionSchema
> {
  constructor(enabled: boolean) {
    super(
      'CreateElma365AppElement.ResultVariable.Matches',
      enabled,
      EntityFieldPath.VariableId,
      'Не верно выбран тип переменной'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfCreateElma365AppElementActionSchema(entity);
  }
  protected isValid(entity: CreateElma365AppElementActionSchema, structure: IIndexedStructure) {
    if (!entity.variableId) {
      return true;
    }

    const variable = structure.variableMap.get(entity.variableId);
    if (!variable) {
      return true;
    }

    return variable.type === VariableType.ComplexObject;
  }
}

class StartElma365ProcessRelatedAppVariableMatches extends SingleFieldValidationRule<StartElma365ProcessActionSchema> {
  constructor(enabled: boolean) {
    super(
      'StartElma365Process.RelatedAppVariable.Matches',
      enabled,
      EntityFieldPath.RelatedAppElementVariableId,
      'Не верно выбран тип переменной'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfStartElma365ProcessActionSchema(entity);
  }
  protected isValid(entity: StartElma365ProcessActionSchema, structure: IIndexedStructure) {
    if (entity.startBy !== Elma365ProcessStartType.ByContext) {
      return true;
    }

    if (!entity.relatedAppElementVariableId) {
      return true;
    }

    const variable = structure.variableMap.get(entity.relatedAppElementVariableId);
    if (!variable) {
      return true;
    }

    return variable.type === VariableType.ComplexObject;
  }
}

class ExternalSigninParametersCompleted extends SingleFieldValidationRule<ExternalSignInSchema> {
  constructor(enabled: boolean) {
    super(
      'ExternalSignInSchema.Parameters.Completed',
      enabled,
      EntityFieldPath.Root,
      'Укажите все необходимые параметры входа'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfExternalSigninSchema(entity);
  }
  protected isValid(entity: ExternalSignInSchema) {
    if (!entity.prompt || !entity.title || !entity.text) {
      return false;
    }
    const protocol = entity.protocol;
    if (instanceOfOAuth2LoginProtocolSchema(protocol)) {
      return (
        !!protocol.authorizeEndpoint &&
        !!protocol.tokenEndpoint &&
        !!protocol.jwksEndpoint &&
        !!protocol.clientId &&
        !!protocol.clientSecret &&
        !!protocol.scopes
      );
    }
    return true;
  }
}

class SendEmailParametersCompleted extends SingleFieldValidationRule<SendEmailSchema> {
  constructor(enabled: boolean) {
    super(
      'SendEmailSchema.Parameters.Completed',
      enabled,
      EntityFieldPath.Root,
      'Заполните все необходимые параметры для отправки электронного сообщения'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfSendEmailSchema(entity);
  }
  protected isValid(entity: SendEmailSchema) {
    return !(!entity.body || (!entity.subject.value && !entity.subject.variableId) || !entity.recipients.length);
  }
}

class CommandTriggerCommandRequired extends SingleFieldValidationRule<CommandTriggerSchema> {
  constructor(enabled: boolean) {
    super(
      'CommandTrigger.Command.Required',
      enabled,
      EntityFieldPath.Command,
      'Заполните значение команды (текст, при вводе которого команда запускается)'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfCommandTriggerSchema(entity);
  }
  protected isValid(entity: CommandTriggerSchema) {
    return !!entity.command;
  }
}

class CommandTriggerCommandMatch extends SingleFieldValidationRule<CommandTriggerSchema> {
  constructor(enabled: boolean) {
    super(
      'CommandTrigger.Command.Match',
      enabled,
      EntityFieldPath.Command,
      'Название команды совпадает с названием системной команды /start'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfCommandTriggerSchema(entity);
  }
  protected isValid(entity: CommandTriggerSchema) {
    return entity.command !== '/start';
  }
}

class ExternalEventTriggerIdRequired extends SingleFieldValidationRule<ExternalEventTriggerSchema> {
  constructor(enabled: boolean) {
    super(
      'ExternalEventTrigger.ExternalEventId.Required',
      enabled,
      EntityFieldPath.Root,
      'Укажите идентификатор внешнего события'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfExternalEventTriggerSchema(entity);
  }
  protected isValid(entity: ExternalEventTriggerSchema) {
    return !!entity.externalEventId;
  }
}

class ExternalEventTriggerNameRequired extends SingleFieldValidationRule<ExternalEventTriggerSchema> {
  constructor(enabled: boolean) {
    super('ExternalEventTrigger.Name.Required', enabled, EntityFieldPath.Root, 'Укажите наименование внешнего события');
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfExternalEventTriggerSchema(entity);
  }
  protected isValid(entity: ExternalEventTriggerSchema) {
    return !!entity.externalEventName;
  }
}

class MergeInboxContactsIdentifierVariableRequired extends SingleFieldValidationRule<MergeInboxContactsSchema> {
  constructor(enabled: boolean) {
    super(
      'MergeInboxContacts.IdentifierVariable.Required',
      enabled,
      EntityFieldPath.Root,
      'Выберите переменную со значением для поиска контакта'
    );
  }
  protected canExecute(entity: EntitySchema) {
    return instanceOfMergeInboxContactsSchema(entity);
  }
  protected isValid(entity: MergeInboxContactsSchema) {
    return !!entity.identifierVariableId;
  }
}

const validationRules: IValidationRule<EntitySchema>[] = [
  new GroupTriggersRequired(true),
  new GroupActionsRequired(true),
  new TransitionTrigerRequired(true),
  new ActionMessagesRequired(true),
  new InputVariableRequired(true),
  new InputVariableExists(true),
  new InputVariableMatches(true),
  new ButtonBindingRequired(true),
  new TriggerBindingRequired(true),
  new CaseActionBindingRequired(true),
  new EndScenarioTransitionRequired(false), // NOTE: Пока отключено, т.к. не нарушает работу сценария.
  new VariableNameUnique(true),
  new VariableCodeUnique(true),
  new MenuButtonTriggerValueRequired(true),
  new IntentContentDuplicateInScenario(true),
  new Elma365FormPropertyVariableRequired(true),
  new Elma365FormPropertyVariableMatches(true),
  new StartElma365ProcessResultVariableMatches(true),
  new StartElma365ProcessRelatedAppVariableMatches(true),
  new CreateElma365AppElementResultVariableMatches(true),
  new ExternalSigninParametersCompleted(true),
  new SendEmailParametersCompleted(true),
  new CommandTriggerCommandRequired(true),
  new CommandTriggerCommandMatch(true),
  new ExternalEventTriggerIdRequired(true),
  new ExternalEventTriggerNameRequired(true),
  new MergeInboxContactsIdentifierVariableRequired(true),
];

const validateEntitiesOnClient = (
  indexedStructure: IIndexedStructure,
  entities: EntitySchema[]
): EntityValidationResult[] => {
  const entityResults: EntityValidationResult[] = [];

  for (const entity of entities) {
    const fieldMap = new Map<string, FieldValidationResult>();

    for (const validationRule of validationRules) {
      const ruleResults = validationRule.tryExecute(entity, indexedStructure);
      ruleResults.forEach((value, fieldPath) => {
        const fieldRuleResults = value.filter((v) => v.status !== ValidationRuleStatus.Success);
        if (!fieldRuleResults.length) {
          return;
        }

        const fieldResult = fieldMap.get(fieldPath);
        if (fieldResult) {
          fieldResult.ruleResults.push(...fieldRuleResults);
        } else {
          fieldMap.set(fieldPath, { fieldPath, ruleResults: fieldRuleResults });
        }
      });
    }

    if (!fieldMap.size) {
      continue;
    }

    const fieldResults = Array.from(fieldMap.values());
    const entityResult = { entityId: entity.id, fieldResults };
    entityResults.push(entityResult);
  }

  return entityResults;
};

const validateScenarioOnServer = async (
  scenarioStructure: ScenarioSchema,
  botStageId: string
): Promise<EntityValidationResult[]> => {
  const request = { scenarioStructure };
  const response = await botStageApi.validateScenario(botStageId, request);
  return response.data.result?.entityResults || [];
};

const mergeValidationResults = (
  indexedStructure: IIndexedStructure,
  resultGroups: EntityValidationResult[][]
): IValidatedStructure => {
  const entityMap = new Map<string, Map<string, ValidationRuleResult[]>>();

  for (const resultGroup of resultGroups) {
    for (const entityResult of resultGroup) {
      let fieldMap = entityMap.get(entityResult.entityId);
      if (!fieldMap) {
        fieldMap = new Map<string, ValidationRuleResult[]>();
        entityMap.set(entityResult.entityId, fieldMap);
      }

      for (const fieldResult of entityResult.fieldResults) {
        let ruleResults = fieldMap.get(fieldResult.fieldPath);
        if (!ruleResults) {
          ruleResults = [];
          fieldMap.set(fieldResult.fieldPath, ruleResults);
        }
        ruleResults.push(...fieldResult.ruleResults);
      }
    }
  }

  const entityResults: EntityValidationResult[] = [];
  const issueList: IValidationIssue[] = [];
  const issueMap = new Map<string, IValidationIssue[]>();
  let issueIndex = 0;

  entityMap.forEach((fieldMap, entityId) => {
    const entityResult: EntityValidationResult = {
      entityId,
      fieldResults: [],
    };
    const entityIssues: IValidationIssue[] = [];

    fieldMap.forEach((ruleResults, fieldPath) => {
      entityResult.fieldResults.push({
        fieldPath,
        ruleResults,
      });

      for (const ruleResult of ruleResults) {
        const entity = indexedStructure.entityMap.get(entityId);
        if (!entity) {
          throw Error(`Не найдена сущность с ID=${entityId}`);
        }

        const ruleIssue = {
          index: issueIndex++,
          entity,
          fieldPath,
          ruleResult,
        };

        entityIssues.push(ruleIssue);
        issueList.push(ruleIssue);
      }
    });

    entityResults.push(entityResult);
    issueMap.set(entityId, entityIssues);
  });

  return {
    structureType: StructureType.Scenario,
    entityResults,
    issueList,
    issueMap,
    hasIssue: (entityId: string, fieldPath: string) =>
      !!issueMap.get(entityId)?.some((issue) => issue.fieldPath === fieldPath),
    getIssues: (entityId: string, fieldPath: string, ruleCode: string) =>
      issueMap.get(entityId)?.filter((issue) => issue.fieldPath === fieldPath && issue.ruleResult.code === ruleCode) ||
      [],
    getVisibleIssueList: () =>
      issueList.filter(
        (issue) =>
          ![
            ValidationRuleCodes.IntentContentDuplicateInScenario as string,
            ValidationRuleCodes.IntentContentDuplicateInBot as string,
          ].includes(issue.ruleResult.code)
      ),
  };
};

export const validateEntities = (
  indexedStructure: IIndexedStructure,
  entities: EntitySchema[]
): IValidatedStructure => {
  const entityResults = validateEntitiesOnClient(indexedStructure, entities);
  return mergeValidationResults(indexedStructure, [entityResults]);
};

export const validateScenario = async (
  indexedStructure: IIndexedStructure,
  scenarioStructure: ScenarioSchema,
  botStageId: string
): Promise<IValidatedStructure> => {
  const serverResults = await validateScenarioOnServer(scenarioStructure, botStageId);
  const clientResults = validateEntitiesOnClient(indexedStructure, indexedStructure.entityList);
  return mergeValidationResults(indexedStructure, [clientResults, serverResults]);
};
