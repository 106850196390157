import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Flags } from 'react-feature-flags';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbContactInfo from '../../components/IbContactInfo';
import {
  InboxChatParticipantStatus,
  InboxContactModel,
  PersonMergingRequest,
  PersonModel,
  PersonUpdatingRequest,
} from '../../../../api';
import { FeatureFlagNames, SUBJECT_ROLES } from '../../../constants';
import {
  NetworkConnectionStatus,
  chatItemSelector,
  inboxAlertsSelectorAdd,
  networkConnectionSelector,
  tagListSelector,
} from '../../recoil';
import IbContactEditModal from '../../components/IbContactEditModal';
import { inboxContactApi, personApi } from '../../../apis';
import IbTagsManagementModalWrapper from '../../wrappers/IbTagsManagementModalWrapper';

const OTHER_PERSON_COUNT = 5;

interface IContactInfoProps {
  chatId: string;
  onBack?: () => void;
  onFocusToMessageInput?: () => void;
}

const ContactInfo: React.FC<IContactInfoProps> = ({ chatId, onBack, onFocusToMessageInput }) => {
  const { t } = useTranslation();

  const [contactEditModalVisible, setContactEditModalVisible] = useState(false);
  const [tagsManagementModalVisible, setTagsManagementModalVisible] = useState(false);
  const [editContact, setEditContact] = useState<InboxContactModel>();
  const [otherPersons, setOtherPersons] = useState<PersonModel[]>([]);
  const [otherPersonsLoading, setOtherPersonsLoading] = useState(false);
  const [otherPersonsSearch, setOtherPersonsSearch] = useState('');

  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);
  const networkConnection = useRecoilValue(networkConnectionSelector);
  const networkConnectionDisabled = networkConnection.status === NetworkConnectionStatus.Off;

  const chatItem = useRecoilValue(chatItemSelector(chatId));
  const contact = chatItem?.entity.participants?.find((p) => p.subject.role === SUBJECT_ROLES.user)?.subject;
  const operator = chatItem?.entity.participants?.find(
    (p) => p.subject.role === SUBJECT_ROLES.operator && p.status === InboxChatParticipantStatus.Assigned
  )?.subject;

  const tagList = useRecoilValue(tagListSelector);

  const onEditContact = async () => {
    if (!contact?.person.id) {
      return;
    }

    try {
      const contactResponse = await inboxContactApi.getInboxContact(contact.person.id);
      setEditContact(contactResponse.data);

      setContactEditModalVisible(true);
    } catch (e) {
      // empty
    }
  };

  const onEditContactTags = async (tagIds: string[]) => {
    if (!contact?.person.id) {
      return;
    }

    try {
      await personApi.updatePersonTags(contact.person.id, { tagIds });
    } catch (e) {
      addAlert({ type: 'error', content: t('Failed to update contact tag list. Please try again later') });
    }
  };

  const onPersonEdit = async (request: PersonUpdatingRequest) => {
    if (!contact?.person.id) {
      return;
    }

    try {
      await personApi.updatePerson(contact.person.id, request);
    } catch {
      // empty
    }
  };

  const onPersonMerge = async (request: PersonMergingRequest) => {
    if (!contact?.person.id) {
      return;
    }

    try {
      await personApi.mergePersons(request);
    } catch {
      // empty
    }
  };

  const onContactEditModalCancel = () => {
    setContactEditModalVisible(false);
    onFocusToMessageInput?.();
  };

  const onTagsManagement = () => setTagsManagementModalVisible(true);

  const onTagsManagementModalCancel = () => setTagsManagementModalVisible(false);

  const onOtherPersonsSearch = (search?: string) => {
    setOtherPersonsSearch(search || '');
  };

  const loadOtherPersonsAsync = async () => {
    setOtherPersonsLoading(true);
    try {
      const response = await personApi.searchPersons(otherPersonsSearch, undefined, 0, OTHER_PERSON_COUNT);
      setOtherPersons(response.data.items || []);
    } catch (e) {
      // empty
    }
    setOtherPersonsLoading(false);
  };
  const loadOtherPersons = () => {
    loadOtherPersonsAsync().finally();
  };
  useEffect(loadOtherPersons, [otherPersonsSearch]);

  const renderContactInfo = (allowTagsManagement: boolean) => (
    <IbContactInfo
      allowTagsManagement={allowTagsManagement}
      chat={chatItem?.entity}
      contact={contact}
      interactionDisabled={networkConnectionDisabled}
      operator={operator}
      tagList={tagList}
      onBack={onBack}
      onEditContact={onEditContact}
      onEditContactTags={onEditContactTags}
      onTagsManagement={onTagsManagement}
    />
  );

  const renderContactEditModal = (allowTagsManagement: boolean) => (
    <IbContactEditModal
      allowTagsManagement={allowTagsManagement}
      editContact={editContact}
      otherPersons={otherPersons}
      otherPersonsLoading={otherPersonsLoading}
      tagList={tagList}
      visible={contactEditModalVisible}
      onCancel={onContactEditModalCancel}
      onEdit={onPersonEdit}
      onMerge={onPersonMerge}
      onPersonsSearch={onOtherPersonsSearch}
      onTagsManagement={onTagsManagement}
    />
  );

  return (
    <>
      <Flags
        authorizedFlags={[FeatureFlagNames.INBOX_SUPERVISOR]}
        renderOff={() => (
          <>
            {renderContactInfo(false)}
            {renderContactEditModal(false)}
          </>
        )}
        renderOn={() => (
          <>
            {renderContactInfo(true)}
            {renderContactEditModal(true)}
          </>
        )}
      />
      <IbTagsManagementModalWrapper visible={tagsManagementModalVisible} onCancel={onTagsManagementModalCancel} />
    </>
  );
};

export default ContactInfo;
