import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { InputActionSchema, PhoneNumberInputSchema } from '../../../../../../../../api';
import SbInput from '../../../../../../../simple-bot/components/common/SbInput';
import SbScroll from '../../../../../../../simple-bot/components/common/SbScroll';
import SbSwitch from '../../../../../../../simple-bot/components/common/SbSwitch';

interface IInputActionTelegramPhoneNumberSettingsTabProps {
  action: PhoneNumberInputSchema;
  onChange: (action: InputActionSchema) => void;
}

const InputActionTelegramPhoneNumberSettingsTab: React.FC<IInputActionTelegramPhoneNumberSettingsTabProps> = ({
  action,
  onChange,
}) => {
  const [contactRequired, setContactRequired] = useState(action.contactRequired);
  const [contactButtonText, setContactButtonText] = useState(action.contactButtonText ?? '');

  const onContactRequiredChange = () => {
    const newAction = cloneDeep(action);
    newAction.contactRequired = !newAction.contactRequired;
    onChange(newAction);
  };

  const onContactButtonTextBlur = () => {
    const newAction = cloneDeep(action);
    newAction.contactButtonText = contactButtonText;
    onChange(newAction);
  };

  const onContactButtonTextChange = (text: string | null) => {
    const newAction = cloneDeep(action);
    newAction.contactButtonText = text;
    onChange(newAction);
  };

  const onActionChanged = () => {
    setContactRequired(action.contactRequired);
    setContactButtonText(action.contactButtonText ?? '');
  };
  useEffect(onActionChanged, [action]);

  return (
    <SbScroll>
      <div>
        <div className="field">
          <SbSwitch checked={contactRequired} label="Только подтвержденный номер" onClick={onContactRequiredChange} />
        </div>
        <div className="field">
          <h4>Название кнопки предоставления контакта</h4>
          <SbInput
            allowClear
            placeholder="Введите свой вариант названия кнопки"
            value={contactButtonText}
            onBlur={onContactButtonTextBlur}
            onChange={onContactButtonTextChange}
          />
        </div>
      </div>
    </SbScroll>
  );
};

export default InputActionTelegramPhoneNumberSettingsTab;
