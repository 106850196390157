import React from 'react';
import { Dropdown, Modal } from 'antd';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const VISIBLE_DEFAULT = false;
const MENU_ITEMS_DEFAULT = [] as IIbContextMenuItem[];
const ON_CLOSE_DEFAULT = () => {};

const MAIN_CLASS_NAME = 'ib-context-menu';
const MODAL_CLASS_NAME = `${MAIN_CLASS_NAME}__modal`;
const CONTEXT_MENU_CLASS_NAME = `${MAIN_CLASS_NAME}__context-menu`;
const MENU_ITEMS_CLASS_NAME = `${MAIN_CLASS_NAME}__items`;
const MENU_ITEM_CLASS_NAME = `${MENU_ITEMS_CLASS_NAME}__item`;
const MENU_ITEM_DISABLED_CLASS_NAME = `${MENU_ITEM_CLASS_NAME}_disabled`;

export interface IIbContextMenuItem {
  icon: React.ReactNode;
  text: React.ReactNode;
  onSelect?: () => void;
  disabled?: boolean;
}

export interface IIbContextMenuProps {
  className?: string;
  visible?: boolean;
  menuItems?: IIbContextMenuItem[];
  trigger?: 'leftClick' | 'rightClick' | 'both';
  onClose?: () => void;
}

const IbContextMenu: React.FC<IIbContextMenuProps> = ({
  children,
  className,
  visible = VISIBLE_DEFAULT,
  menuItems = [],
  trigger = 'both',
  onClose = ON_CLOSE_DEFAULT,
}) => {
  visible = getDefaultIfUndefined(visible, VISIBLE_DEFAULT);
  menuItems = getDefaultIfUndefined(menuItems, MENU_ITEMS_DEFAULT);
  onClose = getDefaultIfUndefined(onClose, ON_CLOSE_DEFAULT);

  const modalClasses = [MODAL_CLASS_NAME];
  className && modalClasses.push(className);

  const contextMenuClasses = [CONTEXT_MENU_CLASS_NAME];
  className && contextMenuClasses.push(className);

  const onMenuItemClick = (menuItem: IIbContextMenuItem) => (e: React.MouseEvent) => {
    if (menuItem.disabled) {
      // NOTE: клик по disabled элементу не должен закрывать меню.
      e.stopPropagation();
      return;
    }

    menuItem.onSelect?.();
    onClose();
  };

  const renderContent = () => {
    return (
      <ul className={MENU_ITEMS_CLASS_NAME}>
        {menuItems.map((menuItem, index) => (
          <li key={index}>
            <div
              className={`${MENU_ITEM_CLASS_NAME} ${menuItem.disabled ? MENU_ITEM_DISABLED_CLASS_NAME : ''}`}
              onClick={onMenuItemClick(menuItem)}
            >
              {menuItem.icon}
              <span>{menuItem.text}</span>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  let triggers = [] as ('contextMenu' | 'click' | 'hover')[];
  switch (trigger) {
    case 'leftClick':
      triggers = ['click'];
      break;
    case 'rightClick':
      triggers = ['contextMenu'];
      break;
    default:
      triggers = ['click', 'contextMenu'];
  }

  return (
    <>
      <Dropdown overlay={renderContent()} overlayClassName={contextMenuClasses.join(' ')} trigger={triggers}>
        {children}
      </Dropdown>
      <Modal
        closable={false}
        footer={null}
        mask={false}
        visible={visible}
        width={'unset'}
        wrapClassName={modalClasses.join(' ')}
        onCancel={onClose}
      >
        {renderContent()}
      </Modal>
    </>
  );
};

export default IbContextMenu;
