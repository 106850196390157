import React, { MouseEventHandler, useRef, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  variableUsagesSelector,
} from '../../../../recoil/scenarioStructure';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import VariableSelector from '../VariableSelector';
import { VariableSchema } from '../../../../../api';
import DropdownMenu from '../common/DropdownMenu';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../utils';
import { IVariableIdContainer } from '../../types';
import SbTag from '../../../../simple-bot/components/common/SbTag';
import { VARIABLE_TAG_COLOR_DEFAULT } from '../../../../simple-bot/const';

interface IInputVariableProps {
  action: IVariableIdContainer;
}

const InputVariable: React.FC<IInputVariableProps> = ({ action }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const buttonRef = useRef(null);

  const variable = action.variableId ? scenarioStructure?.variables.find((v) => v.id === action.variableId) : undefined;

  const onVariableChange = () => setTooltipVisible(true);

  const onVariablesSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    if (Array.isArray(newVariables)) newScenarioStructure.variables = newVariables;

    if (selectedVariable) {
      const foundAction = tryGetElementById(newScenarioStructure, action.id);
      (foundAction as IVariableIdContainer).variableId = selectedVariable.id;
    }

    setScenarioStructure(newScenarioStructure);
  };

  const onVariablesSelectorClose = () => setTooltipVisible(false);

  const onActionOutputVariableDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as IVariableIdContainer).variableId = undefined;

    setScenarioStructure(newScenarioStructure);
  };

  const classes = ['user-answer'];
  if (scenarioValidation?.hasIssue(action.id, EntityFieldPath.VariableId)) {
    classes.push('user-answer_warning');
  }

  return (
    <div className={classes.join(' ')} id={getElementId(action.id, EntityFieldPath.VariableId)}>
      <div className="user-answer__title">
        <SbIcon iconName="user" size={16} />
        <span>Ответ пользователя:</span>
      </div>
      <div className="input-settings">
        <div className="input-settings__item">
          <div className="input-settings__item-prefix">Сохранить в</div>
          <div ref={buttonRef} className="input-settings__item-value" onClick={onVariableChange}>
            {variable ? (
              <SbTag
                color={VARIABLE_TAG_COLOR_DEFAULT}
                sbSize="small"
                text={variable.name}
                onDelete={onActionOutputVariableDelete}
              />
            ) : (
              <div className="configure">Добавить</div>
            )}
          </div>
        </div>
      </div>
      <DropdownMenu
        isVisible={tooltipVisible}
        placement="right"
        size="small"
        triggerElement={buttonRef.current}
        width={235}
        onClose={() => setTooltipVisible(false)}
      >
        <VariableSelector
          getVariableUsages={getVariableUsages}
          variables={scenarioStructure?.variables || []}
          onChange={onVariablesSelectorChange}
          onClose={onVariablesSelectorClose}
        />
      </DropdownMenu>
    </div>
  );
};

export default InputVariable;
