import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LOCALE_PATH } from './utils/configUtil';

export const LANGUAGE_DEFAULT = 'ru';

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: LANGUAGE_DEFAULT,
    returnNull: false,
    cleanCode: true,
    backend: {
      loadPath: LOCALE_PATH,
    },
  });

export default i18n;
