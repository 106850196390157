import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

const PromptAccepted: React.FC = () => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_success ${mainClass}_end`}>
      <SbIcon iconName="check-one" size={16} />
      Подсказка принята оператором
    </div>
  );
};

export default PromptAccepted;
