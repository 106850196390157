import React, { memo } from 'react';
import { useRecoilValue } from 'recoil';
import { Key } from 'ts-key-enum';

import { BindingSchema } from '../../../../../api';
import { dispatcherState, selectedEntitySelector } from '../../../../recoil/scenarioStructure';
import { useEventListener } from '../../utils';

import Binding from './Binding';
import DraggingBinding from './DraggingBinding';

import './index.less';

export interface BindingsProps {
  bindings: BindingSchema[];
}

const Bindings: React.FC<BindingsProps> = ({ bindings }) => {
  const { deleteBinding } = useRecoilValue(dispatcherState);
  const selectedEntity = useRecoilValue(selectedEntitySelector);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!selectedEntity) return;

    switch (e.key) {
      case Key.Delete:
      case Key.Backspace:
        deleteBinding(selectedEntity.id).then();
        break;
      default:
        break;
    }
  };

  useEventListener('keydown', handleKeyDown as (e: Event) => void);

  return (
    <>
      {bindings.map((binding) => (
        <Binding key={binding.id} binding={binding} />
      ))}
      <DraggingBinding />
    </>
  );
};

export default memo(Bindings);
