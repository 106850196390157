import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { InboxChannelRoutingWorkTimeDayModel, InboxChannelRoutingWorkTimeModel } from '../../../../api';
import IbTypography from '../common/IbTypography';
import IbSwitch from '../common/IbSwitch';
import IbCheckbox from '../common/IbCheckbox';
import IbTimepicker from '../common/IbTimePicker';

import {
  FORM_ITEM_CLASS_NAME,
  TIMEPICKER_CLASS_NAME,
  WORKTIME_FORMAT,
  WORKTIME_LIST_CLASS_NAME,
  WORKTIME_MINUTE_STEP,
  WORKTIME_SWITCH_CLASS_NAME,
} from './const';

const formatToTimepicker = (time: string) => {
  const [hour, minute] = time.split(':');
  const initDate = moment(0);
  return initDate.add(hour, 'hour').add(minute, 'minute').format(WORKTIME_FORMAT);
};

const formatToApi = (time: string) => {
  const [hour, minute] = time.split(':');
  const initDate = moment(0);
  const res = initDate
    .add(-initDate.utcOffset() * 2, 'minute')
    .add(hour, 'hour')
    .add(minute, 'minute');
  return res.format(WORKTIME_FORMAT);
};

interface IWorkTimeTabProps {
  workTime: InboxChannelRoutingWorkTimeModel;
  onChange: (workTime: InboxChannelRoutingWorkTimeModel) => void;
}

const WorkTimeTab: React.FC<IWorkTimeTabProps> = ({ workTime, onChange }) => {
  const { t } = useTranslation();

  const onWorkTimeEnabledChange = () =>
    onChange({
      ...workTime,
      enabled: !workTime.enabled,
    });

  const onWorkTimeMondayEnabledChange = () =>
    onChange({
      ...workTime,
      monday: {
        ...workTime.monday,
        enabled: !workTime.monday.enabled,
      },
    });

  const onWorkTimeMondayStartChange = (value: string) =>
    onChange({
      ...workTime,
      monday: {
        ...workTime.monday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeMondayEndChange = (value: string) =>
    onChange({
      ...workTime,
      monday: {
        ...workTime.monday,
        end: formatToApi(value),
      },
    });

  const onWorkTimeTuesdayEnabledChange = () =>
    onChange({
      ...workTime,
      tuesday: {
        ...workTime.tuesday,
        enabled: !workTime.tuesday.enabled,
      },
    });

  const onWorkTimeTuesdayStartChange = (value: string) =>
    onChange({
      ...workTime,
      tuesday: {
        ...workTime.tuesday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeTuesdayEndChange = (value: string) =>
    onChange({
      ...workTime,
      tuesday: {
        ...workTime.tuesday,
        end: formatToApi(value),
      },
    });

  const onWorkTimeWednesdayEnabledChange = () =>
    onChange({
      ...workTime,
      wednesday: {
        ...workTime.wednesday,
        enabled: !workTime.wednesday.enabled,
      },
    });

  const onWorkTimeWednesdayStartChange = (value: string) =>
    onChange({
      ...workTime,
      wednesday: {
        ...workTime.wednesday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeWednesdayEndChange = (value: string) =>
    onChange({
      ...workTime,
      wednesday: {
        ...workTime.wednesday,
        end: formatToApi(value),
      },
    });

  const onWorkTimeThursdayEnabledChange = () =>
    onChange({
      ...workTime,
      thursday: {
        ...workTime.thursday,
        enabled: !workTime.thursday.enabled,
      },
    });

  const onWorkTimeThursdayStartChange = (value: string) =>
    onChange({
      ...workTime,
      thursday: {
        ...workTime.thursday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeThursdayEndChange = (value: string) =>
    onChange({
      ...workTime,
      thursday: {
        ...workTime.thursday,
        end: formatToApi(value),
      },
    });

  const onWorkTimeFridayEnabledChange = () =>
    onChange({
      ...workTime,
      friday: {
        ...workTime.friday,
        enabled: !workTime.friday.enabled,
      },
    });

  const onWorkTimeFridayStartChange = (value: string) =>
    onChange({
      ...workTime,
      friday: {
        ...workTime.friday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeFridayEndChange = (value: string) =>
    onChange({
      ...workTime,
      friday: {
        ...workTime.friday,
        end: formatToApi(value),
      },
    });

  const onWorkTimeSaturdayEnabledChange = () =>
    onChange({
      ...workTime,
      saturday: {
        ...workTime.saturday,
        enabled: !workTime.saturday.enabled,
      },
    });

  const onWorkTimeSaturdayStartChange = (value: string) =>
    onChange({
      ...workTime,
      saturday: {
        ...workTime.saturday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeSaturdayEndChange = (value: string) =>
    onChange({
      ...workTime,
      saturday: {
        ...workTime.saturday,
        end: formatToApi(value),
      },
    });

  const onWorkTimeSundayEnabledChange = () =>
    onChange({
      ...workTime,
      sunday: {
        ...workTime.sunday,
        enabled: !workTime.sunday.enabled,
      },
    });

  const onWorkTimeSundayStartChange = (value: string) =>
    onChange({
      ...workTime,
      sunday: {
        ...workTime.sunday,
        start: formatToApi(value),
      },
    });

  const onWorkTimeSundayEndChange = (value: string) =>
    onChange({
      ...workTime,
      sunday: {
        ...workTime.sunday,
        end: formatToApi(value),
      },
    });

  const renderWorkTimeDay = (
    label: string,
    workTimeDay: InboxChannelRoutingWorkTimeDayModel,
    onEnabledChange: () => void,
    onStartChange: (value: string) => void,
    onEndChange: (value: string) => void
  ) => (
    <li>
      <IbCheckbox value={workTimeDay.enabled} onChange={onEnabledChange} />
      <IbTypography.Paragraph type="secondary">{label}</IbTypography.Paragraph>
      <IbTimepicker
        className={TIMEPICKER_CLASS_NAME}
        disabled={!workTimeDay.enabled}
        format={WORKTIME_FORMAT}
        minuteStep={WORKTIME_MINUTE_STEP}
        value={formatToTimepicker(workTimeDay.start)}
        onChange={onStartChange}
      />
      <span>-</span>
      <IbTimepicker
        className={TIMEPICKER_CLASS_NAME}
        disabled={!workTimeDay.enabled}
        format={WORKTIME_FORMAT}
        minuteStep={WORKTIME_MINUTE_STEP}
        value={formatToTimepicker(workTimeDay.end)}
        onChange={onEndChange}
      />
    </li>
  );

  return (
    <>
      <div className={FORM_ITEM_CLASS_NAME}>
        <div className={WORKTIME_SWITCH_CLASS_NAME}>
          <IbSwitch checked={workTime.enabled} onChange={onWorkTimeEnabledChange} />
          <IbTypography.Paragraph type="secondary">{workTime.enabled ? t('On') : t('Off')}</IbTypography.Paragraph>
        </div>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph disabled type="secondary">
          {t('Set a schedule for receiving new requests')}
        </IbTypography.Paragraph>
        <ul className={WORKTIME_LIST_CLASS_NAME}>
          {renderWorkTimeDay(
            t('Monday'),
            workTime.monday,
            onWorkTimeMondayEnabledChange,
            onWorkTimeMondayStartChange,
            onWorkTimeMondayEndChange
          )}
          {renderWorkTimeDay(
            t('Tuesday'),
            workTime.tuesday,
            onWorkTimeTuesdayEnabledChange,
            onWorkTimeTuesdayStartChange,
            onWorkTimeTuesdayEndChange
          )}
          {renderWorkTimeDay(
            t('Wednesday'),
            workTime.wednesday,
            onWorkTimeWednesdayEnabledChange,
            onWorkTimeWednesdayStartChange,
            onWorkTimeWednesdayEndChange
          )}
          {renderWorkTimeDay(
            t('Thursday'),
            workTime.thursday,
            onWorkTimeThursdayEnabledChange,
            onWorkTimeThursdayStartChange,
            onWorkTimeThursdayEndChange
          )}
          {renderWorkTimeDay(
            t('Friday'),
            workTime.friday,
            onWorkTimeFridayEnabledChange,
            onWorkTimeFridayStartChange,
            onWorkTimeFridayEndChange
          )}
          {renderWorkTimeDay(
            t('Saturday'),
            workTime.saturday,
            onWorkTimeSaturdayEnabledChange,
            onWorkTimeSaturdayStartChange,
            onWorkTimeSaturdayEndChange
          )}
          {renderWorkTimeDay(
            t('Sunday'),
            workTime.sunday,
            onWorkTimeSundayEnabledChange,
            onWorkTimeSundayStartChange,
            onWorkTimeSundayEndChange
          )}
        </ul>
      </div>
    </>
  );
};

export default WorkTimeTab;
