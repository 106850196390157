import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

const UnknownIntent: React.FC = () => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="attention" size={16} />
      Неизвестный интент
    </div>
  );
};

export default UnknownIntent;
