import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { isTouchDevice } from '../../../../../utils/browserUtil';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import DropdownMenu from '../DropdownMenu';

import './index.less';

const RIGHT_PLACEMENT_OFFSET_FOR_CONTENT = { left: 6, top: 0 };
const RIGHT_PLACEMENT_OFFSET_FOR_ICON = { left: 6, top: -12 };

interface IContextMenuProps {
  trigger?: 'hover' | 'click';
  menuContent: ReactNode;
  content?: ReactNode;
  menuIsVisible?: boolean;
  menuIsVisibleChanged?: (value: boolean) => void;
}

const ContextMenu: React.FC<IContextMenuProps> = ({
  trigger = 'hover',
  menuContent,
  content,
  menuIsVisible = false,
  menuIsVisibleChanged,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const clearTimeoutTokenRef = useRef<NodeJS.Timeout>();

  const [localMenuIsVisible, setLocalMenuIsVisible] = useState(menuIsVisible);
  const [dropdownIsVisible, setDropdownIsVisible] = useState(menuIsVisible);

  const [buttonIsHovered, setButtonIsHovered] = useState(false);
  const [dropdownIsHovered, setDropdownIsHovered] = useState(false);

  const onMenuIsVisibleChange = () => {
    setLocalMenuIsVisible(menuIsVisible);
  };
  useEffect(onMenuIsVisibleChange, [menuIsVisible]);

  const onLocalMenuIsVisibleChange = () => {
    if (localMenuIsVisible !== menuIsVisible && trigger === 'click') {
      menuIsVisibleChanged?.(localMenuIsVisible);
    }
  };
  useEffect(onLocalMenuIsVisibleChange, [localMenuIsVisible]);

  const onIconClick = () => {
    setLocalMenuIsVisible(!localMenuIsVisible);

    if (trigger === 'hover') {
      const currentState = buttonIsHovered || dropdownIsHovered;
      setButtonIsHovered(!currentState);
      setDropdownIsHovered(!currentState);
    }
  };

  const onButtonMouseEnter = () => {
    setButtonIsHovered(true);
  };

  const onButtonMouseLeave = () => {
    setButtonIsHovered(false);
  };

  const onVisibilityChanged = () => {
    const newState =
      (trigger === 'click' && localMenuIsVisible) || (trigger === 'hover' && (buttonIsHovered || dropdownIsHovered));
    if (newState && clearTimeoutTokenRef.current) {
      clearTimeout(clearTimeoutTokenRef.current);
    }
    if (newState && !dropdownIsVisible) {
      setDropdownIsVisible(true);
    }
    if (!newState && dropdownIsVisible) {
      // NOTE: чтобы меню не закрывалось, пока мышь кратковременно покидает элемент по пути, устанавливаем через таймаут
      clearTimeoutTokenRef.current = setTimeout(() => {
        setDropdownIsVisible(false);
      }, 300);
    }
  };
  useEffect(onVisibilityChanged, [localMenuIsVisible, buttonIsHovered, dropdownIsHovered]);

  const classes = ['context-menu', `context-menu_${trigger}`];

  trigger === 'hover' && dropdownIsVisible && classes.push('context-menu_hovered');

  return (
    <div className={classes.join(' ')} onMouseEnter={onButtonMouseEnter} onMouseLeave={onButtonMouseLeave}>
      <div
        ref={ref}
        className="context-menu__content"
        role="none"
        onClick={() => {
          // NOTE: в режиме отображения по клику для обычных и тач-устройств подходит обычное событие onClick.
          trigger === 'click' && onIconClick();
        }}
        onMouseUp={() => {
          // NOTE: на тач-устройствах кроме событий тача по тапу приходит "лишнее" событие onMouseDown,
          // чтобы приложения, обрабатывающие только onMouseDown могли работать и на тач-устройствах.
          // Его не надо обрабатывать, чтобы не было двойного срабатывания.
          if (isTouchDevice()) return;
          trigger === 'hover' && onIconClick();
        }}
        onTouchStart={() => {
          trigger === 'hover' && onIconClick();
        }}
      >
        {content ? content : <SbIcon iconName="more-one" />}
      </div>
      <DropdownMenu
        className={content ? undefined : 'dropdown-menu_icon'}
        isVisible={dropdownIsVisible}
        placement="right"
        rightPlacementOffset={content ? RIGHT_PLACEMENT_OFFSET_FOR_CONTENT : RIGHT_PLACEMENT_OFFSET_FOR_ICON}
        triggerElement={ref.current}
        onClose={() => {
          setLocalMenuIsVisible(false);
          setDropdownIsVisible(false);
          setDropdownIsHovered(false);
        }}
        onMouseEnter={() => setDropdownIsHovered(true)}
        onMouseLeave={() => {
          setTimeout(() => {
            setDropdownIsHovered(false);
          }, 100);
        }}
      >
        {menuContent}
      </DropdownMenu>
    </div>
  );
};

export default ContextMenu;
