import React from 'react';

import './index.less';

import { InboxFileCategory, InboxFileMediaGroup, InboxFileMediaType } from '../../../../api';
import IbFile from '../common/IbFile';
import { formatFileSize } from '../../../utils/fileUtil';
import IbThumbnail from '../common/IbThumbnail';
import IbTypography from '../common/IbTypography';
import { OperationStatus } from '../../tasks';
import IbProgress from '../common/IbProgress';
import { isMediaVisible } from '../../utils/fileUtil';

const MAIN_CLASS_NAME = 'ib-attachment-item';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const INFO_CLASS_NAME = `${MAIN_CLASS_NAME}__info`;
const BODY_CLASS_NAME = `${CONTENT_CLASS_NAME}__body`;
const THUMBNAIL_CLASS_NAME = `${CONTENT_CLASS_NAME}__thumbnail`;

const CONTENT_FILE_CLASS_NAME = `${CONTENT_CLASS_NAME}_file`;
const CONTENT_THUMBNAIL_CLASS_NAME = `${CONTENT_CLASS_NAME}_thumbnail`;

export interface IIbAttachmentItemProps {
  className?: string;
  fileName?: string;
  fileSize?: number;
  fileUrl?: string;
  thumbnailUrl?: string;
  loadingStatus?: OperationStatus;
  loadingCurrent?: number;
  loadingMaximum?: number;
  mediaType?: InboxFileMediaType;
  mediaGroup?: InboxFileMediaGroup;
  category?: InboxFileCategory;
  onReUpload?: () => void;
  onDelete?: () => void;
  onPreview?: () => void;
}

const IbAttachmentItem: React.FC<IIbAttachmentItemProps> = ({
  className,
  fileName,
  fileSize,
  fileUrl,
  thumbnailUrl,
  loadingStatus,
  loadingCurrent,
  loadingMaximum,
  mediaType,
  mediaGroup,
  category,
  onReUpload,
  onDelete,
  onPreview,
}) => {
  const isLoading =
    loadingStatus && loadingStatus !== OperationStatus.Unknown && loadingStatus !== OperationStatus.Success;
  const isFailure = loadingStatus === OperationStatus.Failure;

  thumbnailUrl = thumbnailUrl ?? (isMediaVisible(mediaType) ? fileUrl : undefined);

  const renderLoading = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <IbProgress
        current={loadingCurrent || 0}
        iconName={isFailure ? 'refresh' : 'close'}
        maximum={loadingMaximum || 1}
        theme={thumbnailUrl ? 'gray' : 'blue'}
        onClick={isFailure ? onReUpload : onDelete}
      />
    );
  };

  const renderFile = () => {
    if (thumbnailUrl || isLoading || !mediaGroup || !category) {
      return null;
    }

    return <IbFile category={category} linkHref={fileUrl} mediaGroup={mediaGroup} />;
  };

  const renderName = () => {
    if (!fileName) {
      return null;
    }

    return (
      <IbTypography.Paragraph strong type="secondary">
        {fileName}
      </IbTypography.Paragraph>
    );
  };

  const renderSize = () => {
    if (!fileSize && fileSize !== 0) {
      return null;
    }

    return (
      <IbTypography.Paragraph light type="descriptor">
        {formatFileSize(fileSize)}
      </IbTypography.Paragraph>
    );
  };

  const renderThumbnail = () => {
    if (!thumbnailUrl) {
      return null;
    }

    return (
      <div className={THUMBNAIL_CLASS_NAME}>
        <IbThumbnail imgSrc={thumbnailUrl} linkHref={fileUrl} onPreview={onPreview} />
      </div>
    );
  };

  const renderBody = () => {
    if (!isLoading && (thumbnailUrl || !mediaGroup || !category)) {
      return null;
    }

    return (
      <div className={BODY_CLASS_NAME}>
        {renderLoading()}
        {renderFile()}
      </div>
    );
  };

  const renderContent = () => {
    if (!thumbnailUrl && !isLoading && (!mediaGroup || !category)) {
      return null;
    }

    const contentClasses = [CONTENT_CLASS_NAME];
    if (thumbnailUrl) {
      contentClasses.push(CONTENT_THUMBNAIL_CLASS_NAME);
    } else {
      contentClasses.push(CONTENT_FILE_CLASS_NAME);
    }

    return (
      <div className={contentClasses.join(' ')}>
        {renderThumbnail()}
        {renderBody()}
      </div>
    );
  };

  const renderInfo = () => {
    if (!fileName && !fileSize && fileSize !== 0) {
      return null;
    }

    return (
      <div className={INFO_CLASS_NAME}>
        <IbTypography>
          {renderName()}
          {renderSize()}
        </IbTypography>
      </div>
    );
  };

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return (
    <div className={classes.join(' ')}>
      {renderContent()}
      {renderInfo()}
    </div>
  );
};

export default IbAttachmentItem;
