import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from '../../types';

export const VideoDefaultIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          clipRule="evenodd"
          d="M8.33203 0C5.57061 0 3.33203 2.23858 3.33203 5V35C3.33203 37.7614 5.57061 40 8.33203 40H31.6654C34.4268 40 36.6654 37.7614 36.6654 35V5C36.6654 4.94547 36.6645 4.89115 36.6628 4.83704V6.66667L29.9961 0H8.33203Z"
          fill="#EDFAFF"
          fillRule="evenodd"
        />
        <path clipRule="evenodd" d="M30 0V6.66667H36.6667L30 0Z" fill="#C2CDDE" fillRule="evenodd" />
        <path
          d="M11.6641 14.1654C11.6641 13.7051 12.0372 13.332 12.4974 13.332H27.4974C27.9576 13.332 28.3307 13.7051 28.3307 14.1654V25.832C28.3307 26.2923 27.9576 26.6654 27.4974 26.6654H12.4974C12.0372 26.6654 11.6641 26.2923 11.6641 25.832V14.1654Z"
          stroke="#075AB3"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path d="M25 13.332V26.6654" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path d="M15 13.332V26.6654" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path
          d="M25.8359 17.5H28.3359"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M25.8359 22.5H28.3359"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M11.6641 17.5H14.1641"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M11.6641 16.668V18.3346"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path d="M13.75 13.332H16.25" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path d="M13.75 26.668H16.25" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path d="M23.75 13.332H26.25" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path d="M23.75 26.668H26.25" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        <path
          d="M11.6641 22.5H14.1641"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M11.6641 21.668V23.3346"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path d="M28.3359 21.668V23.3346" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M28.3359 16.668V18.3346" stroke="#075AB3" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M18.75 17.918L22.0833 20.0013L18.75 22.0846V17.918Z"
          stroke="#075AB3"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="40" transform="translate(3.33594)" width="33.3333" />
        </clipPath>
      </defs>
    </svg>
  );
});
