export const SOUND_NONE = 'None';
export const SOUND_ALARMED_OGG = 'Alarmed.ogg';
export const SOUND_BEEPER_OGG = 'Beeper.ogg';
export const SOUND_BELLIGERENT_OGG = 'Belligerent.ogg';
export const SOUND_CALM_OGG = 'Calm.ogg';
export const SOUND_CHORD_OGG = 'Chord.ogg';
export const SOUND_CHORD2_OGG = 'Chord2.ogg';
export const SOUND_CHORD2_REV_OGG = 'Chord2_Rev.ogg';
export const SOUND_CLOUD_OGG = 'Cloud.ogg';
export const SOUND_DOORBELL_OGG = 'Doorbell.ogg';
export const SOUND_ENHARPMENT_OGG = 'Enharpment.ogg';
export const SOUND_FLIT_FLUTE_OGG = 'Flit_Flute.ogg';
export const SOUND_GLASS_OGG = 'Glass.ogg';
export const SOUND_GLISTEN_OGG = 'Glisten.ogg';
export const SOUND_GLITCH_OGG = 'Glitch.ogg';
export const SOUND_INFORMATION_BELL_OGG = 'Information_Bell.ogg';
export const SOUND_INFORMATION_BLOCK_OGG = 'Information_Block.ogg';
export const SOUND_JINJA_OGG = 'Jinja.ogg';
export const SOUND_JINJA2_OGG = 'Jinja2.ogg';
export const SOUND_KOTO_OGG = 'Koto.ogg';
export const SOUND_MODULAR_OGG = 'Modular.ogg';
export const SOUND_NEWSFLASH_OGG = 'Newsflash.ogg';
export const SOUND_NEWSFLASH_BRIGHT_OGG = 'Newsflash_Bright.ogg';
export const SOUND_PIZZA_BOX_OGG = 'PizzaBox.ogg';
export const SOUND_POLITE_OGG = 'Polite.ogg';
export const SOUND_PONDEROUS_OGG = 'Ponderous.ogg';
export const SOUND_REVERIE_OGG = 'Reverie.ogg';
export const SOUND_SHARP_OGG = 'Sharp.ogg';
export const SOUND_SONAR_OGG = 'Sonar.ogg';
export const SOUND_TAPTAP_OGG = 'Taptap.ogg';
export const SOUND_TECH_OGG = 'Tech.ogg';
export const SOUND_UNPHASED_OGG = 'Unphased.ogg';
export const SOUND_UNSTRUNG_OGG = 'Unstrung.ogg';
export const SOUND_WAHS_UP_OGG = 'WahsUp.ogg';
export const SOUND_WHISTLERONIC_OGG = 'Whistleronic.ogg';
export const SOUND_WHISTLERONIC_DOWN_OGG = 'Whistleronic-Down.ogg';
export const SOUND_WOODBLOCK_OGG = 'Woodblock.ogg';

export const SOUND_OPTIONS = [
  { value: SOUND_NONE, label: SOUND_NONE },
  { value: SOUND_ALARMED_OGG, label: 'Alarmed' },
  { value: SOUND_BEEPER_OGG, label: 'Beeper' },
  { value: SOUND_BELLIGERENT_OGG, label: 'Belligerent' },
  { value: SOUND_CALM_OGG, label: 'Calm' },
  { value: SOUND_CHORD_OGG, label: 'Chord' },
  { value: SOUND_CHORD2_OGG, label: 'Chord 2' },
  { value: SOUND_CHORD2_REV_OGG, label: 'Chord 2 Rev' },
  { value: SOUND_CLOUD_OGG, label: 'Cloud' },
  { value: SOUND_DOORBELL_OGG, label: 'Doorbell' },
  { value: SOUND_ENHARPMENT_OGG, label: 'Enharpment' },
  { value: SOUND_FLIT_FLUTE_OGG, label: 'Flit Flute' },
  { value: SOUND_GLASS_OGG, label: 'Glass' },
  { value: SOUND_GLISTEN_OGG, label: 'Glisten' },
  { value: SOUND_GLITCH_OGG, label: 'Glitch' },
  { value: SOUND_INFORMATION_BELL_OGG, label: 'Information Bell' },
  { value: SOUND_INFORMATION_BLOCK_OGG, label: 'Information Block' },
  { value: SOUND_JINJA_OGG, label: 'Jinja' },
  { value: SOUND_JINJA2_OGG, label: 'Jinja 2' },
  { value: SOUND_KOTO_OGG, label: 'Koto' },
  { value: SOUND_MODULAR_OGG, label: 'Modular' },
  { value: SOUND_NEWSFLASH_OGG, label: 'Newsflash' },
  { value: SOUND_NEWSFLASH_BRIGHT_OGG, label: 'Newsflash Bright' },
  { value: SOUND_PIZZA_BOX_OGG, label: 'Pizza Box' },
  { value: SOUND_POLITE_OGG, label: 'Polite' },
  { value: SOUND_PONDEROUS_OGG, label: 'Ponderous' },
  { value: SOUND_REVERIE_OGG, label: 'Reverie' },
  { value: SOUND_SHARP_OGG, label: 'Sharp' },
  { value: SOUND_SONAR_OGG, label: 'Sonar' },
  { value: SOUND_TAPTAP_OGG, label: 'Taptap' },
  { value: SOUND_TECH_OGG, label: 'Tech' },
  { value: SOUND_UNPHASED_OGG, label: 'Unphased' },
  { value: SOUND_UNSTRUNG_OGG, label: 'Unstrung' },
  { value: SOUND_WAHS_UP_OGG, label: 'Wahs Up' },
  { value: SOUND_WHISTLERONIC_OGG, label: 'Whistleronic' },
  { value: SOUND_WHISTLERONIC_DOWN_OGG, label: 'Whistleronic Down' },
  { value: SOUND_WOODBLOCK_OGG, label: 'Woodblock' },
];

export const MAIN_CLASS_NAME = 'ib-sound';
