import React from 'react';
import { TimePicker } from 'antd';
import moment from 'moment';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';

const MAIN_CLASS_NAME = 'ib-timepicker';
const POPUP_CLASS_NAME = 'ib-timepicker-popup';

export interface IIbTimepickerProps {
  className?: string;
  popupClassName?: string;
  value?: string;
  format?: string;
  hourStep?: number;
  minuteStep?: number;
  secondStep?: number;
  disabled?: boolean;
  onChange?: (dateString: string) => void;
}

const IbTimepicker: React.FC<IIbTimepickerProps> = ({
  className,
  popupClassName,
  value,
  format,
  hourStep,
  minuteStep,
  secondStep,
  disabled,
  onChange,
}) => {
  value = getDefaultIfUndefined(value, undefined);
  format = getDefaultIfUndefined(format, undefined);
  hourStep = getDefaultIfUndefined(hourStep, undefined);
  minuteStep = getDefaultIfUndefined(minuteStep, undefined);
  secondStep = getDefaultIfUndefined(secondStep, undefined);
  disabled = getDefaultIfUndefined(disabled, undefined);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  const popupClasses = [POPUP_CLASS_NAME];
  popupClassName && popupClasses.push(popupClassName);

  const onInnerChange = (_: unknown, dateString: string) => onChange?.(dateString);

  return (
    <TimePicker
      className={classes.join(' ')}
      clearIcon={<IbIcon iconName="close-one" size={20} />}
      disabled={disabled}
      format={format}
      hourStep={hourStep}
      minuteStep={minuteStep}
      placeholder=""
      popupClassName={popupClasses.join(' ')}
      secondStep={secondStep}
      showNow={false}
      suffixIcon={<IbIcon iconName="time" size={20} />}
      value={value ? moment(value, format) : undefined}
      onChange={onInnerChange}
    />
  );
};

export default IbTimepicker;
