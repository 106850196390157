import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import './index.less';

import { BotStageModel, BotStageTestingResponse, BotStageType, PublishStage, TrainStatus } from '../../../../api';
import { inboxAlertsSelectorAdd } from '../../recoil';
import { hubConnections } from '../../../utils/socketsUtil';
import { AlertTypes, BOT_PUBLISH_STATUS_UPDATED } from '../../../constants';
import { agentApi, botStageApi } from '../../../apis';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTooltip from '../common/IbTooltip';
import IbWebChat from '../IbWebChat';

const MAIN_CLASS_NAME = 'ib-webchat-button';
const BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__button`;
const BUTTON_MOBILE_CLASS_NAME = `${BUTTON_CLASS_NAME}_mobile`;
const WEBCHAT_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__webchat-container`;

enum IbWebChatButtonState {
  Preparing = 'Preparing',
  Ready = 'Ready',
  WebchatOpened = 'WebchatOpened',
  Error = 'Error',
}

interface IIbWebChatButtonProps {
  botName?: string;
  botStage?: BotStageModel;
}

const IbWebChatButton: React.FC<IIbWebChatButtonProps> = ({ botName, botStage }) => {
  const { t } = useTranslation();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [testingState, setTestingState] = useState<BotStageTestingResponse>();
  const [testingInitialization, setTestingInitialization] = useState(false);
  const [userStartedTesting, setUserStartedTesting] = useState(false);
  const [webchatVisible, setWebchatVisible] = useState(false);
  const [openWebChatOnReady, setOpenWebChatOnReady] = useState(false);

  const buttonCurrentState =
    testingInitialization || testingState?.agentStage.trainResult.status === TrainStatus.Pending
      ? IbWebChatButtonState.Preparing
      : userStartedTesting && testingState?.agentStage.trainResult.status === TrainStatus.Error
      ? IbWebChatButtonState.Error
      : webchatVisible
      ? IbWebChatButtonState.WebchatOpened
      : IbWebChatButtonState.Ready;

  const isOriginStage = botStage?.type === BotStageType.Origin;

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const loadTestingState = async () => {
    if (!botStage) return;

    try {
      const response = await botStageApi.getBotStageTestingState(botStage.id);
      setTestingState(response.data);

      if (openWebChatOnReady && response.data.agentStage.trainResult.status === TrainStatus.Success) {
        setWebchatVisible(true);
      }
    } catch {
      // NOTE: если произошла ошибка, то скорей всего тестирование еще не запускалось
    }
  };
  const loadData = () => {
    loadTestingState().finally();
  };
  useEffect(loadData, [botStage]);

  const botPublishEventHandler = (args: { agentStageId: string }) => {
    if (testingState?.agentStage.id === args?.agentStageId) {
      loadTestingState().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
    return () => conn.off(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
  };
  useEffect(subscribe, [conn, testingState]);

  const testBotStage = async () => {
    if (!botStage) {
      return;
    }

    if (isOriginStage) {
      setWebchatVisible(true);
      return;
    }

    setTestingInitialization(true);

    try {
      const response = await botStageApi.testBotStage(botStage.id);
      setTestingState(response.data);
      setOpenWebChatOnReady(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Bot testing error'),
      });
    }
    setTestingInitialization(false);
  };

  const onWebChatButtonClick = async () => {
    if (!botStage) {
      return;
    }

    setUserStartedTesting(true);

    if (botStage.type !== BotStageType.Origin || testingState?.agentStage.trainResult.status === TrainStatus.Error) {
      await testBotStage();
      return;
    }

    setWebchatVisible(true);
  };

  const onWebchatClose = () => {
    setOpenWebChatOnReady(false);
    setWebchatVisible(false);
  };

  const onErrorButtonClick = async () => {
    if (!botStage) {
      return;
    }

    if (botStage.type === BotStageType.Origin) {
      await agentApi.retrain({ agentId: botStage.agentId, publishStage: PublishStage.NUMBER_2 });
      await loadTestingState();
      return;
    }

    await testBotStage();
  };

  const renderButton = (
    mobile: boolean,
    loading: boolean,
    text: string,
    onClick: () => void,
    icon?: React.ReactNode,
    status?: 'success' | 'error' | 'default' | 'warning'
  ) => {
    return (
      <IbButton
        className={mobile ? BUTTON_MOBILE_CLASS_NAME : BUTTON_CLASS_NAME}
        icon={icon ?? (mobile && !loading ? <IbIcon iconName="check-one" /> : null)}
        loading={loading}
        status={status}
        type={mobile ? 'link' : 'primary'}
        onClick={onClick}
      >
        {text}
      </IbButton>
    );
  };

  const renderWebchatButton = (mobile: boolean) => {
    switch (buttonCurrentState) {
      case IbWebChatButtonState.Ready:
        return renderButton(
          mobile,
          false,
          isOriginStage ? t('Test bot') : t('Test bot draft'),
          onWebChatButtonClick,
          undefined,
          undefined
        );
      case IbWebChatButtonState.Preparing:
        return (
          <IbTooltip placement="bottomRight" title={t('Bot testing preparation can take some time')}>
            {renderButton(mobile, true, t('Preparation'), () => {}, undefined, undefined)}
          </IbTooltip>
        );
      case IbWebChatButtonState.WebchatOpened:
        return renderButton(mobile, false, t('Close webchat'), onWebchatClose, undefined, undefined);
      case IbWebChatButtonState.Error:
        return (
          <IbTooltip placement="bottomRight" title={t('Bot starting error. Click to try again')}>
            {renderButton(mobile, false, t('Error'), onErrorButtonClick, <IbIcon iconName="attention" />, 'warning')}
          </IbTooltip>
        );

      default:
        return null;
    }
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderWebchatButton(false)}
      {renderWebchatButton(true)}
      {webchatVisible && testingState?.webchatUrl && (
        <div className={WEBCHAT_CONTAINER_CLASS_NAME}>
          <IbWebChat
            subTitle={t('Test widget')}
            title={botName || ''}
            webchatUrl={testingState.webchatUrl}
            onClose={onWebchatClose}
          />
        </div>
      )}
    </div>
  );
};

export default IbWebChatButton;
