import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { LabeledValue, SelectValue } from 'antd/es/select';
import { useSetRecoilState } from 'recoil';

import { DataEntryModel, DataType, QnAData, SingleKnowledgeBaseModel, StageModel } from '../../../../../kb-api';
import SbButton from '../../../components/common/SbButton';
import SbModal from '../../../components/common/SbModal';
import SbTypography from '../../../components/common/SbTypography';
import SbSelect from '../../../components/common/SbSelect';
import SbMarkdownEditor from '../../../components/common/SbMarkdownEditor';
import SbScroll from '../../../components/common/SbScroll';
import SbTagsInput from '../../../components/common/SbTagsInput';
import { getKnowledgeSourceIcon } from '../../../utils/render';
import SbDebounceSelect from '../../../components/common/SbDebounceSelect';
import { dataEntryKbApi, versionKbApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbSpin from '../../../components/common/SbSpin';
import SbTag from '../../../components/common/SbTag';
import { includesIgnoreCase } from '../../../../utils/stringUtil';
import { CHILD_DATA_ENTRIES_TAG_COLOR, QUESTIONS_TAG_COLOR_DEFAULT } from '../../../const';
import { getKnowledgeSources } from '../../../utils/knowledgeBase';

import InstanceStatusProgress from './InstanceStatusProgress';

const DATA_ENTRIES_PAGE_SIZE = 3;
const EMPTY_DATA_ENTRY = {
  id: '',
  editionId: '',
  knowledgeBaseId: '',
  type: DataType.QnA,
  data: {
    answer: '',
    questions: [],
  } as QnAData,
  childEntryIds: [],
} as DataEntryModel;

interface IDataEntryEditModalProps {
  knowledgeBase: SingleKnowledgeBaseModel;
  dataEntry?: DataEntryModel;
  visible: boolean;
  getDraftStage: () => Promise<StageModel | undefined>;
  getDataEntryIdByVersion: (versionId: string, dataEntryId: string) => Promise<string | undefined>;
  onCancel: () => void;
  onDataChanged: () => void;
}

const DataEntryEditModal: React.FC<IDataEntryEditModalProps> = ({
  knowledgeBase,
  dataEntry,
  visible,
  getDraftStage,
  getDataEntryIdByVersion,
  onDataChanged,
  onCancel,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [localDataEntry, setLocalDataEntry] = useState<DataEntryModel>({ ...EMPTY_DATA_ENTRY });
  const [childDataEntriesLoading, setChildDataEntriesLoading] = useState(false);
  const [childDataEntries, setChildDataEntries] = useState<DataEntryModel[]>([]);
  const [childSelectorDataEntryList, setChildSelectorDataEntryList] = useState<DataEntryModel[]>([]);

  const buttonsDisabled = saving || publishing;
  const isNew = !dataEntry;
  const modalTitle = isNew ? 'Новая запись' : 'Редактировать запись';
  const qnaData = localDataEntry ? (localDataEntry.data as QnAData) : undefined;
  const localDataEntryIsValid = qnaData?.questions?.length && qnaData?.answer;
  const draftInstance = knowledgeBase.draftInstance;
  const editionId = knowledgeBase.draftVersion?.editionId || knowledgeBase.originVersion.editionId;
  const knowledgeSources = getKnowledgeSources(knowledgeBase);

  const addDataEntry = async (versionId: string) => {
    try {
      await versionKbApi.addDataEntry(versionId, {
        type: DataType.QnA,
        data: localDataEntry.data,
        childEntryIds: localDataEntry.childEntryIds,
      });
      setPublishing(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении записи в базу знаний',
        error: e,
      });
    }
  };

  const updateDataEntry = async (versionId: string) => {
    if (!dataEntry?.id) return;

    try {
      const dataEntryId = await getDataEntryIdByVersion(versionId, dataEntry.id);

      if (!dataEntryId) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при обновлении записи в базе знаний',
        });
        return;
      }

      const childEntryIds: string[] = [];
      for (const childEntryId of localDataEntry.childEntryIds || []) {
        const id = await getDataEntryIdByVersion(versionId, childEntryId);
        if (id) {
          childEntryIds.push(id);
        }
      }

      await versionKbApi.updateDataEntry(versionId, dataEntryId, {
        type: DataType.QnA,
        data: localDataEntry.data,
        childEntryIds,
        isArchived: false,
      });
      setPublishing(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при обновлении записи в базе знаний',
        error: e,
      });
    }
  };

  const renderDataEntryLabel = (search: string, dataEntry: DataEntryModel) => {
    const qnaData = dataEntry.data as QnAData;
    const question = qnaData.questions?.find((q) => includesIgnoreCase(q, search)) || qnaData.questions?.[0] || '';
    const answer = qnaData.answer || '';
    return (
      <>
        <div className="sb-knowledge-base-card__data-entry-edit-modal__child-select-dropdown__question">
          <SbMarkdownEditor readOnly search={search} value={question} />
        </div>
        <SbMarkdownEditor readOnly search={search} value={answer} />
      </>
    );
  };

  const searchDataEntries = async (search: string) => {
    try {
      const response = await dataEntryKbApi.searchDataEntries(
        undefined,
        editionId,
        undefined,
        DataType.QnA,
        true,
        search,
        undefined,
        true,
        undefined,
        0,
        DATA_ENTRIES_PAGE_SIZE
      );
      const childSelectorDataEntryList = (response.data.items || [])
        .filter((e) => e.entry.id !== dataEntry?.id)
        .map((e) => e.entry);
      setChildSelectorDataEntryList(childSelectorDataEntryList);
      return childSelectorDataEntryList.map(
        (e) => ({ value: e.id, key: e.id, label: renderDataEntryLabel(search, e) } as SelectValue)
      );
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка записей редакции',
        error: e,
      });
    }

    return [];
  };

  const loadChildDataEntries = async () => {
    if (!dataEntry?.id) {
      setChildDataEntries([]);
      return;
    }

    setChildDataEntriesLoading(true);
    try {
      const response = await dataEntryKbApi.getChildren(dataEntry.id);
      setChildDataEntries(response.data || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка дочерних записей редакции',
        error: e,
      });
    }
    setChildDataEntriesLoading(false);
  };

  const onVisiblePropChange = () => {
    if (visible) {
      setSaving(false);
      setPublishing(false);
      setChildDataEntriesLoading(false);
      setChildDataEntries([]);
      loadChildDataEntries().finally();
      setLocalDataEntry(dataEntry || { ...EMPTY_DATA_ENTRY });
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onQuestionsChange = (values: string[]) =>
    setLocalDataEntry({
      ...localDataEntry,
      data: {
        ...localDataEntry.data,
        questions: values,
      },
    });

  const onAnswerChange = (value: string) =>
    setLocalDataEntry({
      ...localDataEntry,
      data: {
        ...localDataEntry.data,
        answer: value,
      },
    });

  const onChildEntrySelectChange = (value: SelectValue) => {
    const id = (value as LabeledValue).value as string;
    if (localDataEntry.childEntryIds?.includes(id)) return;

    const newChild = childSelectorDataEntryList.find((e) => e.id === id);
    if (newChild) {
      setChildDataEntries([...childDataEntries, newChild]);
      setLocalDataEntry({
        ...localDataEntry,
        childEntryIds: [...(localDataEntry.childEntryIds || []), id],
      });
    }
  };

  const onChildDataEntryDelete = (childDataEntry: DataEntryModel) => () => {
    setChildDataEntries(childDataEntries.filter((e) => e.id !== childDataEntry.id));
    setLocalDataEntry({
      ...localDataEntry,
      childEntryIds: localDataEntry.childEntryIds?.filter((id) => id !== childDataEntry.id),
    });
  };

  const onModalSave = async () => {
    if (!localDataEntry || !localDataEntryIsValid || buttonsDisabled) return;

    setSaving(true);

    const draftStage = await getDraftStage();
    if (!draftStage) {
      setSaving(false);
      return;
    }

    if (isNew) {
      await addDataEntry(draftStage.versionId);
    } else {
      await updateDataEntry(draftStage.versionId);
    }

    setSaving(false);
  };

  const onModalCancel = () => !buttonsDisabled && onCancel();

  const onPublishComplete = () => onDataChanged();

  return (
    <SbModal
      destroyOnClose
      className="sb-knowledge-base-card__data-entry-edit-modal"
      footer={[
        <SbButton key="save" loading={buttonsDisabled} sbSize="medium" onClick={onModalSave}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" disabled={buttonsDisabled} sbSize="medium" sbType="secondary" onClick={onModalCancel}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title={modalTitle}
      visible={visible}
      width={777}
      onCancel={onModalCancel}
      onOk={onModalSave}
    >
      <SbScroll>
        <SbTypography>
          <h3>Вопросы и фразы</h3>
          <small>
            Укажите вопросы и фразы, которыми может быть сформулирован этот же вопрос . Разделяйте фразы с помощью
            &quot;Enter&quot;.
          </small>
          <SbTagsInput
            editable
            sbSize="large"
            tagsColor={QUESTIONS_TAG_COLOR_DEFAULT}
            values={qnaData?.questions || []}
            onChange={onQuestionsChange}
          />
          <h3>Ответ</h3>
        </SbTypography>
        <SbMarkdownEditor value={qnaData?.answer || ''} onChange={onAnswerChange} />
        <SbTypography>
          <h3>Уточняющие вопросы</h3>
          <SbDebounceSelect
            showSearch
            className="sb-knowledge-base-card__data-entry-edit-modal__child-select"
            dropdownClassName="sb-knowledge-base-card__data-entry-edit-modal__child-select-dropdown"
            fetchOptions={searchDataEntries}
            sbType="light"
            value={{ value: '-', label: 'Выберите или начните писать' } as SelectValue}
            onChange={onChildEntrySelectChange}
          />
          <div className="sb-knowledge-base-card__data-entry-edit-modal__child-tags">
            {childDataEntriesLoading ? (
              <SbSpin />
            ) : (
              childDataEntries.map((childDataEntry) => (
                <SbTag
                  key={childDataEntry.id}
                  color={CHILD_DATA_ENTRIES_TAG_COLOR}
                  text={(childDataEntry.data as QnAData).questions?.[0] || ''}
                  onDelete={onChildDataEntryDelete(childDataEntry)}
                />
              ))
            )}
          </div>
          {dataEntry?.knowledgeSourceId && (
            <>
              <h3>Источник</h3>
              <SbSelect disabled optionLabelProp="label" sbType="light" value={dataEntry.knowledgeSourceId}>
                {knowledgeSources.map((knowledgeSource) => {
                  const content = (
                    <div className="sb-select__option">
                      {getKnowledgeSourceIcon(knowledgeSource)}
                      {knowledgeSource.name}
                    </div>
                  );
                  return (
                    <Select.Option key={knowledgeSource.id} label={content} value={knowledgeSource.id}>
                      {content}
                    </Select.Option>
                  );
                })}
              </SbSelect>
            </>
          )}
        </SbTypography>
        {publishing && draftInstance?.id && (
          <InstanceStatusProgress instanceId={draftInstance.id} visible={false} onPublishComplete={onPublishComplete} />
        )}
      </SbScroll>
    </SbModal>
  );
};

export default DataEntryEditModal;
