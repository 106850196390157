import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import {
  InboxChannelModel,
  InboxChannelRoutingBotModel,
  InboxChannelRoutingOperatorsModel,
  InboxChannelRoutingWorkTimeModel,
  InboxChannelUpdatingRequest,
  InboxOperatorGroupModel,
} from '../../../../api';
import IbButton from '../common/IbButton';
import { IFormFieldValidationResult } from '../../../types';
import IbTabs from '../common/IbTabs';
import IbTabPane from '../common/IbTabPane';
import { INBOX_CHANNEL_DEFAULT } from '../IbChannelEditModal';

import { MAIN_CLASS_NAME } from './const';
import OperatorsTab from './OperatorsTab';
import BotTab from './BotTab';
import WorkTimeTab from './WorkTimeTab';

enum RoutingTabKeys {
  OPERATORS = 'OPERATORS',
  BOT = 'BOT',
  WORK_TIME = 'WORK_TIME',
}

export interface IChannelRoutingFormValidationResult {
  maxChatCountPerOperator: IFormFieldValidationResult;
}

const formIsValid = (validationResult: IChannelRoutingFormValidationResult) =>
  validationResult.maxChatCountPerOperator.isValid;

interface IIbChannelTuneModalProps {
  visible?: boolean;
  configureRoutingChannel?: InboxChannelModel;
  groupList?: InboxOperatorGroupModel[];
  onConfigureRouting?: (
    request: InboxChannelUpdatingRequest
  ) => Promise<IChannelRoutingFormValidationResult | undefined>;
  onCancel?: () => void;
}

const IbChannelTuneModal: React.FC<IIbChannelTuneModalProps> = ({
  visible,
  configureRoutingChannel,
  groupList,
  onConfigureRouting,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(RoutingTabKeys.OPERATORS);
  const [saving, setSaving] = useState(false);
  const [, setShowValidation] = useState(false);
  const [channel, setChannel] = useState(INBOX_CHANNEL_DEFAULT);
  const [outsideValidation, setOutsideValidation] = useState<IChannelRoutingFormValidationResult>();

  const validationResult: IChannelRoutingFormValidationResult = outsideValidation
    ? outsideValidation
    : {
        maxChatCountPerOperator: {
          isValid:
            channel.routing.operators.maxChatCountPerOperator === undefined ||
            channel.routing.operators.maxChatCountPerOperator === null ||
            (typeof channel.routing.operators.maxChatCountPerOperator == 'number' &&
              channel.routing.operators.maxChatCountPerOperator >= 0),
          message: t('Enter a positive integer or leave it blank'),
        },
      };

  const classes = [MAIN_CLASS_NAME];

  const onSaveButtonClick = async () => {
    setShowValidation(true);

    if (!configureRoutingChannel || !formIsValid(validationResult)) {
      return;
    }

    setSaving(true);
    const outsideValidationResult = await onConfigureRouting?.({
      authToken: channel.authToken,
      displayName: channel.displayName,
      status: channel.status,
      properties: channel.properties,
      routing: channel.routing,
    });
    setSaving(false);

    if (!outsideValidationResult) {
      return;
    }

    if (formIsValid(outsideValidationResult)) {
      onCancel?.();
      return;
    }

    setOutsideValidation(outsideValidationResult);
  };

  const onTabChange = (tabKey: string) => setCurrentTab(tabKey as RoutingTabKeys);

  const onOperatorsTabChange = (operators: InboxChannelRoutingOperatorsModel) =>
    setChannel({
      ...channel,
      routing: {
        ...channel.routing,
        operators,
      },
    });

  const onBotTabChange = (bot: InboxChannelRoutingBotModel) =>
    setChannel({
      ...channel,
      routing: {
        ...channel.routing,
        bot,
      },
    });

  const onWorkTimeTabChange = (workTime: InboxChannelRoutingWorkTimeModel) =>
    setChannel({
      ...channel,
      routing: {
        ...channel.routing,
        workTime,
      },
    });

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setSaving(false);
    setShowValidation(false);
    configureRoutingChannel && setChannel(cloneDeep(configureRoutingChannel));
    setCurrentTab(RoutingTabKeys.OPERATORS);
  };
  useEffect(onVisiblePropChange, [visible]);

  const onChannelChange = () => setOutsideValidation(undefined);
  useEffect(onChannelChange, [channel]);

  const footer = (
    <>
      <IbButton disabled={saving} onClick={onSaveButtonClick}>
        {t('Save')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
    </>
  );

  return (
    <IbModal
      className={classes.join(' ')}
      footer={footer}
      loading={saving}
      position={IbModalPosition.Fixed}
      title={t('Routing settings')}
      visible={visible}
      onCancel={onCancel}
    >
      <IbTabs activeKey={currentTab} onChange={onTabChange}>
        <IbTabPane key={RoutingTabKeys.OPERATORS} tab={t('Operators')} tabKey={RoutingTabKeys.OPERATORS}>
          <OperatorsTab groupList={groupList} operators={channel.routing.operators} onChange={onOperatorsTabChange} />
        </IbTabPane>
        <IbTabPane key={RoutingTabKeys.BOT} tab={t('Bot')} tabKey={RoutingTabKeys.BOT}>
          <BotTab bot={channel.routing.bot} onChange={onBotTabChange} />
        </IbTabPane>
        <IbTabPane key={RoutingTabKeys.WORK_TIME} tab={t('Work time')} tabKey={RoutingTabKeys.WORK_TIME}>
          <WorkTimeTab workTime={channel.routing.workTime} onChange={onWorkTimeTabChange} />
        </IbTabPane>
      </IbTabs>
    </IbModal>
  );
};

export default IbChannelTuneModal;
