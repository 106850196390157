import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from './types';

export const DirectLineIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <circle cx="12" cy="12" fill="#FA8C16" r="12" />
        <path
          d="M5.3335 7H18.6668V17H13.6668L12.0002 18.6667L10.3335 17H5.3335V7Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.33333"
        />
        <path d="M11.6665 12H12.334" stroke="white" strokeLinecap="round" strokeWidth="1.33333" />
        <path d="M15.0005 12H15.6668" stroke="white" strokeLinecap="round" strokeWidth="1.33333" />
        <path d="M8.3335 12H8.9998" stroke="white" strokeLinecap="round" strokeWidth="1.33333" />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
});
