import React from 'react';
import { Tabs, TabsProps } from 'antd';

import './index.less';

const MAIN_CLASS_NAME = 'ib-tabs';

export type IIbTabsProps = TabsProps;

const IbTabs: React.FC<IIbTabsProps> = ({ children, className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return (
    <Tabs animated {...otherProps} className={classes.join(' ')}>
      {children}
    </Tabs>
  );
};

export default IbTabs;
