import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbMenuButton from '../../../components/common/IbMenuButton';
import IbIcon, { IbIconName } from '../../../components/common/IbIcon';
import IbContextMenu, { IIbContextMenuItem } from '../../../components/common/IbContextMenu';

const MAIN_CLASS_NAME = 'ib-main-menu';
const MAIN_CLASS_NAME_MOBILE = `${MAIN_CLASS_NAME}_mobile`;

interface IIbMainMenuProps {
  selectedItem: string;
}

const IbMainMenu: React.FC<IIbMainMenuProps> = ({ selectedItem }) => {
  const { t } = useTranslation();

  // NOTE: может быть undefined при рендеринге в Storybook
  const history = useHistory?.();

  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const menuItems: { displayName: string; iconName: IbIconName; menuItemCode: string }[] = [
    { displayName: t('Channels'), iconName: 'inbox', menuItemCode: 'channels' },
    { displayName: t('Chats'), iconName: 'comment', menuItemCode: 'chats' },
    { displayName: t('Contacts'), iconName: 'every-user', menuItemCode: 'contacts' },
    // { displayName: t('Mailing'), iconName: 'speaker-one', menuItemCode: 'mailing' },
    { displayName: t('Operators'), iconName: 'headset-one', menuItemCode: 'operators' },
    { displayName: t('Chat bots'), iconName: 'robot-one', menuItemCode: 'bots' },
  ];

  const mobileMenuItems: { displayName: string; iconName: IbIconName; menuItemCode: string }[] = [
    { displayName: t('Channels'), iconName: 'inbox', menuItemCode: 'channels' },
    { displayName: t('Chats'), iconName: 'comment', menuItemCode: 'chats' },
    { displayName: t('Contacts'), iconName: 'every-user', menuItemCode: 'contacts' },
    { displayName: t('Operators'), iconName: 'headset-one', menuItemCode: 'operators' },
    { displayName: t('More'), iconName: 'hamburger-button', menuItemCode: 'more' },
  ];

  const contextMenuItems = [
    {
      icon: <IbIcon iconName="speaker-one" />,
      text: t('Mailing'),
      onSelect: () => {
        history?.push('/inbox/mailing');
      },
    },
    {
      icon: <IbIcon iconName="robot-one" />,
      text: t('Chat bots'),
      onSelect: () => {
        history?.push('/inbox/bots');
      },
    },
  ] as IIbContextMenuItem[];

  const onMenuItemClick = (selectedItem: string) => () => {
    history?.push(`/inbox/${selectedItem}`);
  };

  const onMoreMenuItemClick = () => {
    setContextMenuVisible(true);
  };

  const onContextMenuClose = () => setContextMenuVisible(false);

  return (
    <>
      <div className={MAIN_CLASS_NAME}>
        {menuItems.map((item) => (
          <IbMenuButton
            key={item.menuItemCode}
            icon={<IbIcon iconName={item.iconName} />}
            selected={selectedItem == item.menuItemCode}
            text={item.displayName}
            onClick={onMenuItemClick(item.menuItemCode)}
          />
        ))}
      </div>
      <div className={MAIN_CLASS_NAME_MOBILE}>
        {mobileMenuItems.map((item) => {
          return item.menuItemCode === 'more' ? (
            <IbContextMenu
              key={item.menuItemCode}
              menuItems={contextMenuItems}
              visible={contextMenuVisible}
              onClose={onContextMenuClose}
            >
              <IbMenuButton
                icon={<IbIcon iconName={item.iconName} />}
                selected={!mobileMenuItems.some((i) => i.menuItemCode === selectedItem)}
                text={item.displayName}
                onClick={onMoreMenuItemClick}
              />
            </IbContextMenu>
          ) : (
            <IbMenuButton
              key={item.menuItemCode}
              icon={<IbIcon iconName={item.iconName} />}
              selected={selectedItem === item.menuItemCode}
              text={item.displayName}
              onClick={onMenuItemClick(item.menuItemCode)}
            />
          );
        })}
      </div>
    </>
  );
};

export default IbMainMenu;
