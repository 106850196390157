import React, { useMemo, useState } from 'react';
import ReactWebChat, { createStore } from 'botframework-webchat';

import { LiveChatSettingsModel } from '../../../../api';

import {
  PREVIEW_CHAT_CONTAINER_CLASS_NAME,
  PREVIEW_CLASS_NAME,
  PREVIEW_MAIN_CLASS_NAME,
  PREVIEW_MOBILE_VISIBLE_CLASS_NAME,
  PREVIEW_TITLE_BOT_ICON_CONTAINER_CLASS_NAME,
  PREVIEW_TITLE_CLASS_NAME,
  PREVIEW_TITLE_CLOSE_ICON_CONTAINER_CLASS_NAME,
  PREVIEW_TITLE_TEXT_CLASS_NAME,
  PREVIEW_TOGGLE_BUTTON_CLASS_NAME,
  PREVIEW_WEB_CHAT_CONTAINER_CLASS_NAME,
} from './const';
import {
  getChatBackground,
  getChatMaxHeightBottom,
  getChatOffsetBottom,
  getChatOffsetRight,
  getChatPrimaryColor,
  getChatPrimaryTextColor,
  getChatTitle,
  getToogleButtonBackground,
  getToogleButtonIconColor,
  getToogleButtonOffsetBottom,
  getToogleButtonOffsetRight,
  getToogleButtonSize,
  getWebChatStyleOptions,
} from './utils';
import { LiveChatPreviewDirectLine } from './directline';

interface ILiveChatPreviewProps {
  previewMobileVisible: boolean;
  settings: LiveChatSettingsModel;
}

const LiveChatPreview: React.FC<ILiveChatPreviewProps> = ({ previewMobileVisible, settings }) => {
  const [chatVisible, setChatVisible] = useState(true);

  const webChatProps = useMemo(
    () => ({
      directLine: new LiveChatPreviewDirectLine(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      store: createStore({}, () => (next: any) => (action: any) => {
        if (action.type === 'WEB_CHAT/SUBMIT_SEND_BOX' || action.type === 'WEB_CHAT/SEND_FILES') {
          return;
        }

        return next(action);
      }),
    }),
    []
  );

  const toggleChat = () => setChatVisible(!chatVisible);

  const style: React.CSSProperties = {
    background: getToogleButtonBackground(settings),
    width: getToogleButtonSize(settings),
    height: getToogleButtonSize(settings),
    bottom: getToogleButtonOffsetBottom(),
    right: getToogleButtonOffsetRight(),
  };

  const chatContainerStyle: React.CSSProperties = {
    background: getChatBackground(settings),
    bottom: getChatOffsetBottom(settings),
    right: getChatOffsetRight(),
    maxHeight: getChatMaxHeightBottom(settings),
  };

  const chatTitleStyle: React.CSSProperties = {
    background: getChatPrimaryColor(settings),
    color: getChatPrimaryTextColor(settings),
  };

  const chatTitleStatusStyle: React.CSSProperties = {
    color: `${getChatPrimaryTextColor(settings)}80`,
  };

  return (
    <div className={`${PREVIEW_CLASS_NAME} ${previewMobileVisible ? PREVIEW_MOBILE_VISIBLE_CLASS_NAME : ''}`}>
      <div className={PREVIEW_MAIN_CLASS_NAME}>
        <div className={PREVIEW_TOGGLE_BUTTON_CLASS_NAME} style={style} onClick={toggleChat}>
          {chatVisible ? (
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M 38.982422 6.9707031 A 2.0002 2.0002 0 0 0 37.585938 7.5859375 L 24 21.171875 L 10.414062 7.5859375 A 2.0002 2.0002 0 0 0 8.9785156 6.9804688 A 2.0002 2.0002 0 0 0 7.5859375 10.414062 L 21.171875 24 L 7.5859375 37.585938 A 2.0002 2.0002 0 1 0 10.414062 40.414062 L 24 26.828125 L 37.585938 40.414062 A 2.0002 2.0002 0 1 0 40.414062 37.585938 L 26.828125 24 L 40.414062 10.414062 A 2.0002 2.0002 0 0 0 38.982422 6.9707031 z"
                fill={getToogleButtonIconColor(settings)}
              />
            </svg>
          ) : (
            <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_213_131)">
                <path
                  d="M12.0002 1.5C5.37206 1.5 0.000181322 5.86406 0.000181322 11.25C0.000181322 13.4812 0.932994 15.525 2.47987 17.1703C1.78143 19.0172 0.328306 20.5828 0.304869 20.6016C-0.00450618 20.9297 -0.0888812 21.4078 0.0892438 21.8203C0.267369 22.2328 0.675181 22.5 1.12518 22.5C4.00799 22.5 6.28143 21.2953 7.64549 20.3297C9.00018 20.7563 10.4627 21 12.0002 21C18.6283 21 24.0002 16.6359 24.0002 11.25C24.0002 5.86406 18.6283 1.5 12.0002 1.5ZM12.0002 18.75C10.7486 18.75 9.51112 18.5578 8.32518 18.1828L7.26112 17.8453L6.34706 18.4922C5.67674 18.9656 4.75799 19.4953 3.65174 19.8516C3.99393 19.2844 4.32674 18.6469 4.58456 17.9672L5.08143 16.65L4.11581 15.6281C3.26737 14.7234 2.25018 13.2281 2.25018 11.25C2.25018 7.11563 6.62362 3.75 12.0002 3.75C17.3767 3.75 21.7502 7.11563 21.7502 11.25C21.7502 15.3844 17.3767 18.75 12.0002 18.75Z"
                  fill={getToogleButtonIconColor(settings)}
                />
              </g>
              <defs>
                <clipPath id="clip0_213_131">
                  <rect fill={getToogleButtonIconColor(settings)} height="24" width="24" />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
        {chatVisible ? (
          <div className={PREVIEW_CHAT_CONTAINER_CLASS_NAME} style={chatContainerStyle}>
            <div className={PREVIEW_TITLE_CLASS_NAME} style={chatTitleStyle}>
              <div className={PREVIEW_TITLE_BOT_ICON_CONTAINER_CLASS_NAME}>
                <svg fill="none" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.34315 20.8545C6.08102 21.2974 9.94133 21.5944 11.8983 21.7258V18.1235C10.2431 17.9969 6.68952 17.7438 5.23399 17.369C4.82508 17.2667 4.53787 16.8919 4.53787 16.4538V10.8654H0.94043V16.4538C0.94043 18.5324 2.34241 20.3433 4.34315 20.8545Z"
                    fill="#5AD2FF"
                  />
                  <path
                    d="M21.9897 0.959619C19.546 0.336517 15.9632 0.00549316 13.1641 0.00549316V3.60293C15.6662 3.60293 18.9375 3.89501 21.094 4.44996C21.5029 4.55219 21.7901 4.93189 21.7901 5.37001V10.8757H25.3876V5.36027C25.3924 3.28164 23.9953 1.47076 21.9897 0.959619Z"
                    fill="#FABE00"
                  />
                  <path
                    d="M13.1688 0V3.59744C10.6667 3.59744 7.39537 3.88952 5.23399 4.4396C4.82508 4.54669 4.53787 4.92153 4.53787 5.35965V10.8653L0.94043 7.26789V5.35965C0.94043 3.28102 2.33754 1.47013 4.34315 0.958993C6.78687 0.331023 10.3697 0 13.1688 0Z"
                    fill="#E62D32"
                  />
                  <path
                    clipRule="evenodd"
                    d="M22.0282 20.9494C22.03 20.9488 22.0318 20.9481 22.0335 20.9473C23.8639 20.1003 25.3924 18.8297 25.3924 16.4541V14.4583L21.795 10.8608V15.4856C21.7924 16.4982 21.7105 16.92 20.7728 17.384L11.8984 21.7311V26.0004L22.0282 20.9494Z"
                    fill="#5082E6"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <div className={PREVIEW_TITLE_TEXT_CLASS_NAME}>
                <h4>{getChatTitle(settings)}</h4>
                <h5 style={chatTitleStatusStyle}>В сети</h5>
              </div>
              <div className={PREVIEW_TITLE_CLOSE_ICON_CONTAINER_CLASS_NAME} onClick={toggleChat}>
                <svg height="24px" viewBox="0 0 48 48" width="24px" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M 38.982422 6.9707031 A 2.0002 2.0002 0 0 0 37.585938 7.5859375 L 24 21.171875 L 10.414062 7.5859375 A 2.0002 2.0002 0 0 0 8.9785156 6.9804688 A 2.0002 2.0002 0 0 0 7.5859375 10.414062 L 21.171875 24 L 7.5859375 37.585938 A 2.0002 2.0002 0 1 0 10.414062 40.414062 L 24 26.828125 L 37.585938 40.414062 A 2.0002 2.0002 0 1 0 40.414062 37.585938 L 26.828125 24 L 40.414062 10.414062 A 2.0002 2.0002 0 0 0 38.982422 6.9707031 z"
                    fill={getChatPrimaryTextColor(settings)}
                  />
                </svg>
              </div>
            </div>
            <div className={PREVIEW_WEB_CHAT_CONTAINER_CLASS_NAME}>
              <ReactWebChat styleOptions={getWebChatStyleOptions(settings)} {...webChatProps} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LiveChatPreview;
