import React, { useEffect, useState } from 'react';
import { Button, Space, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import { InstanceModel, KnowledgeSourceModel, VersionModel } from '../../kb-api';
import { instanceKbApi, knowledgeSourceKbApi, versionKbApi } from '../apis';
import { AlertTypes, INSTANCE_PUBLISHED, INSTANCE_STATUS_UPDATED, KNOWLEDGE_SOURCE_PARSED } from '../constants';
import { hubConnections } from '../utils/socketsUtil';
import { alertsSelectorAdd } from '../recoil/alerts';

import KBWebChat from './KBWebChat';
import PublishStatusView from './PublishStatusView';
import PublishReport from './PublishReport';

export interface IVersionStatusViewProps {
  versionId?: string | null;
  testingChatTitle?: string | null;
  showVersionTitle?: boolean;
  detailed?: boolean;
  onKnowledgeSourceParsed?: () => void;
}

const VersionStatusView: React.FC<IVersionStatusViewProps> = ({
  versionId,
  testingChatTitle,
  showVersionTitle = false,
  detailed = false,
  onKnowledgeSourceParsed = () => {},
}) => {
  const { push } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getKnowledgeBaseConnection, []);
  const [version, setVersion] = useState<VersionModel>();
  const [instance, setInstance] = useState<InstanceModel>();
  const [knowledgeSource, setKnowledgeSource] = useState<KnowledgeSourceModel>();

  const loadVersionAsync = async () => {
    if (!versionId) return;
    try {
      const response = await versionKbApi.getVersion(versionId);
      setVersion(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке версии',
        error: e,
      });
    }
  };
  const loadVersion = () => {
    loadVersionAsync();
  };
  useEffect(loadVersion, [versionId]);

  const loadInstanceAsync = async () => {
    if (!version?.instanceId) return;
    try {
      const response = await instanceKbApi.getInstance(version.instanceId);
      setInstance(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке экземпляра',
        error: e,
      });
    }
  };
  const loadInstance = () => {
    loadInstanceAsync();
  };
  useEffect(loadInstance, [version]);

  const loadKnowledgeSourceAsync = async () => {
    if (!version?.editionId) return;
    try {
      // NOTE: пока для редакции поддерживается один источник данных
      const response = await knowledgeSourceKbApi.searchKnowledgeSources(
        undefined,
        version.editionId,
        undefined,
        0,
        10
      );
      setKnowledgeSource(response.data?.items?.pop());
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке источника базы знаний',
        error: e,
      });
    }
  };
  const loadKnowledgeSource = () => {
    loadKnowledgeSourceAsync();
  };
  useEffect(loadKnowledgeSource, [version]);

  const instanceEventHandler = (args: { instanceId: string }) => {
    if (instance?.id === args?.instanceId) {
      loadInstanceAsync();
    }
  };
  const subscribeToInstanceEvents = () => {
    if (!instance || !conn) return;

    conn.on(INSTANCE_PUBLISHED, instanceEventHandler);
    conn.on(INSTANCE_STATUS_UPDATED, instanceEventHandler);

    return () => {
      conn.off(INSTANCE_PUBLISHED, instanceEventHandler);
      conn.off(INSTANCE_STATUS_UPDATED, instanceEventHandler);
    };
  };
  useEffect(subscribeToInstanceEvents, [conn, instance]);

  const knowledgeSourceEventHandler = (args: { knowledgeSourceId: string }) => {
    if (knowledgeSource?.id === args?.knowledgeSourceId) {
      loadKnowledgeSourceAsync();
      onKnowledgeSourceParsed();
    }
  };
  const subscribeToKnowledgeSourceEvents = () => {
    if (!knowledgeSource || !conn) return;

    conn.on(KNOWLEDGE_SOURCE_PARSED, knowledgeSourceEventHandler);

    return () => {
      conn.off(KNOWLEDGE_SOURCE_PARSED, knowledgeSourceEventHandler);
    };
  };
  useEffect(subscribeToKnowledgeSourceEvents, [conn, knowledgeSource]);

  if (!version || !instance) {
    return <Spin />;
  }

  const onVersionTitleClick = () => {
    push(`/knowledge-bases/${version.knowledgeBaseId}/versions/${version.id}`);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space>
        <KBWebChat instance={instance} title={testingChatTitle} />
        {showVersionTitle && (
          <span>
            <b>Версия:</b>
            <Button type="link" onClick={onVersionTitleClick}>
              {version?.name}
            </Button>
          </span>
        )}
      </Space>
      <PublishStatusView detailed={detailed} instance={instance} knowledgeSource={knowledgeSource} />
      <PublishReport detailed={detailed} instance={instance} />
    </Space>
  );
};

export default VersionStatusView;
