import React from 'react';

import './index.less';

const MAIN_CLASS_NAME = 'ib-menu-button';
const SELECTED_CLASS_NAME = `${MAIN_CLASS_NAME}_selected`;
const TEXT_CLASS_NAME = `${MAIN_CLASS_NAME}__text`;

export interface IIbMenuButtonProps {
  className?: string;
  icon: React.ReactNode;
  selected?: boolean;
  text?: string;
  onClick?: () => void;
}

const IbMenuButton: React.FC<IIbMenuButtonProps> = ({ className, icon, selected, text, onClick }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);
  selected && classes.push(SELECTED_CLASS_NAME);

  return (
    <div className={classes.join(' ')} title={text} onClick={onClick}>
      {icon}
      {!!text && <div className={TEXT_CLASS_NAME}>{text}</div>}
    </div>
  );
};

export default IbMenuButton;
