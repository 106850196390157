import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import './index.less';

import IbCard, { FOOTER_LABEL_CLASS_NAME } from '../../../../../components/common/IbCard';
import { BotEditionModel, SingleBotModel, UpdateBotOperationType } from '../../../../../../../api';
import {
  DataEntrySortDirection,
  DataType,
  KnowledgeBaseEntryType,
  ListDataEntryModel,
  SingleKnowledgeBaseModel,
} from '../../../../../../../kb-api';
import { inboxAlertsSelectorAdd } from '../../../../../recoil';
import { botApi, dataEntryKbApi, knowledgeBaseKbApi, stageKbApi } from '../../../../../../apis';
import { AlertTypes } from '../../../../../../constants';
import StageSettingsModal from '../../../../../../simple-bot/pages/bots/SimpleBotCard/KnowledgeBaseBlock/StageSettingsModal';
import { formatDateTimeAdaptive } from '../../../../../../utils/stringUtil';
import IbTypography from '../../../../../components/common/IbTypography';
import IbDivider from '../../../../../components/common/IbDivider';
import IbTag from '../../../../../components/common/IbTag';
import IbButton from '../../../../../components/common/IbButton';
import IbContextMenu, { IIbContextMenuItem } from '../../../../../components/common/IbContextMenu';
import IbIcon from '../../../../../components/common/IbIcon';
import IbSwitch from '../../../../../components/common/IbSwitch';

const MAX_VISIBLE_KB_ENTRIES = 10;

const MAIN_CLASS_NAME = 'ib-knowledge-base-card';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const EMPTY_CONTENT_CLASS_NAME = `${CONTENT_CLASS_NAME}__empty`;
const ENTRY_CLASS_NAME = `${CONTENT_CLASS_NAME}__entry`;
const SHOW_MORE_CLASS_NAME = `${CONTENT_CLASS_NAME}__show-more`;
const EXTRA_CLASS_NAME = `${MAIN_CLASS_NAME}__extra`;
const FOOTER_CLASS_NAME = `${MAIN_CLASS_NAME}__footer`;

interface IKnowledgeBaseCardProps {
  bot?: SingleBotModel;
  botEdition?: BotEditionModel;
  botStageId?: string;
  onDataChanged: () => Promise<void>;
}

const getKnowledgeBaseEnabledValue = (knowledgeBase?: SingleKnowledgeBaseModel) => {
  if (!knowledgeBase) return false;

  const { recognizerSettings } = knowledgeBase.originStage;
  return recognizerSettings ? recognizerSettings.enabled : true;
};

const KnowledgeBaseCard: React.FC<IKnowledgeBaseCardProps> = ({ bot, botEdition, botStageId, onDataChanged }) => {
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [knowledgeBase, setKnowledgeBase] = useState<SingleKnowledgeBaseModel>();
  const [loading, setLoading] = useState(false);
  const [toggling, setToggling] = useState(false);
  const [creating, setCreating] = useState(false);
  const [knowledgeBaseEnabled, setKnowledgeBaseEnabled] = useState(getKnowledgeBaseEnabledValue(knowledgeBase));
  const [stageSettingsModalVisible, setStageSettingsModalVisible] = useState(false);
  const [kbEntries, setKbEntries] = useState<ListDataEntryModel[]>([]);
  const [menuVisible, setMenuVisible] = useState(false);

  const goToKnowledgeBase = () => {
    if (!bot) return;
    push(`/inbox/bots/${bot.entry.id}/knowledge-base`);
  };

  const loadKnowledgeBaseData = async (knowledgeBaseId: string) => {
    try {
      const response = await knowledgeBaseKbApi.getKnowledgeBase(knowledgeBaseId);
      setKnowledgeBase(response.data);

      const kb = response.data;
      const version = kb?.draftVersion || kb?.originVersion;

      const reportInstance = kb?.draftInstance || kb?.originInstance;

      const filter = {
        knowledgeSourceId: undefined,
        onlyRootEntries: undefined,
      };

      const entriesResponse = await dataEntryKbApi.searchDataEntries(
        reportInstance?.id,
        version.editionId,
        filter.knowledgeSourceId,
        DataType.QnA,
        filter.onlyRootEntries,
        undefined,
        undefined,
        undefined,
        DataEntrySortDirection.AnswerAscending,
        0,
        1000
      );
      setKbEntries(entriesResponse.data.items ?? []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadDataAsync = async () => {
    if (!bot || !bot.entry.knowledgeBaseId) {
      return;
    }

    setLoading(true);
    try {
      await loadKnowledgeBaseData(bot.entry.knowledgeBaseId);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [bot]);

  const onKnowledgeBasePropChange = () => {
    setKnowledgeBaseEnabled(getKnowledgeBaseEnabledValue(knowledgeBase));
  };
  useEffect(onKnowledgeBasePropChange, [knowledgeBase]);

  const onToggleKnowledgeBase = async (checked: boolean, event: MouseEvent) => {
    event.stopPropagation();
    if (!knowledgeBase) return;

    setToggling(true);
    try {
      const enabled = !knowledgeBaseEnabled;
      await stageKbApi.updateStage(knowledgeBase.originStage.id, {
        recognizerSettings: {
          ...knowledgeBase.originStage.recognizerSettings,
          enabled,
        },
      });
      setKnowledgeBaseEnabled(enabled);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Knowledge base error'),
      });
    }
    setToggling(false);
  };

  const onCreateKnowledgeBase = async () => {
    if (!bot) return;

    setCreating(true);
    try {
      const knowledgeBaseCreationResponse = await knowledgeBaseKbApi.createKnowledgeBase({
        name: `${t('Knowledge base for bot')} '${bot.entry.name}'`,
        type: KnowledgeBaseEntryType.Default,
        searchParentsOnlyIfNoContext: false,
      });

      const knowledgeBaseId = knowledgeBaseCreationResponse.data.id;
      await botApi.updateBot(bot.entry.id, {
        knowledgeBaseId,
        operationType: UpdateBotOperationType.UpdateKnowledgeBase,
      });
      goToKnowledgeBase();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Knowledge base error'),
      });
    }
    setCreating(false);
  };

  const onCardClick = () => {
    if (bot && !loading && !creating && !toggling) {
      goToKnowledgeBase();
    }
  };

  const renderExtra = () => {
    if (!knowledgeBase || !bot || !botEdition || !botStageId) {
      return null;
    }

    const onOpenStageSettingsModal = () => {
      setStageSettingsModalVisible(true);
    };

    const onCloseStageSettingsModal = () => {
      setStageSettingsModalVisible(false);
    };

    const onMenuButtonClick = () => {
      setMenuVisible(true);
    };

    const onMenuClose = () => {
      setMenuVisible(false);
    };

    const contextMenuItems = [
      {
        text: t('Settings'),
        onSelect: onOpenStageSettingsModal,
      },
    ] as IIbContextMenuItem[];

    return (
      <div className={EXTRA_CLASS_NAME} onClick={(e) => e.stopPropagation()}>
        <IbSwitch
          checked={knowledgeBaseEnabled}
          loading={toggling}
          title={knowledgeBaseEnabled ? t('Enabled') : t('Disabled')}
          onClick={onToggleKnowledgeBase}
        />

        <IbContextMenu menuItems={contextMenuItems} visible={menuVisible} onClose={onMenuClose}>
          <IbButton icon={<IbIcon iconName="more-one" />} type="icon" onClick={onMenuButtonClick} />
        </IbContextMenu>
        <StageSettingsModal
          botEdition={botEdition}
          botStageId={botStageId}
          knowledgeBaseEnabled={knowledgeBaseEnabled}
          stage={knowledgeBase.originStage}
          visible={stageSettingsModalVisible}
          onCancel={onCloseStageSettingsModal}
          onDataChanged={onDataChanged}
        />
      </div>
    );
  };

  const renderFooter = () => {
    if (!knowledgeBase) return null;

    return (
      <div className={FOOTER_CLASS_NAME}>
        <IbTypography.Paragraph disabled type="secondary">
          <span>{t('RecordWithCount', { count: knowledgeBase.originDataEntryCount || 0 })}</span>
        </IbTypography.Paragraph>
        <IbDivider orientation="center" type="vertical" />
        <IbTypography.Paragraph disabled type="secondary">
          <span className={FOOTER_LABEL_CLASS_NAME}>{t('Modified')}</span>{' '}
          <span>{formatDateTimeAdaptive(knowledgeBase.originStage.modifiedOn, i18n.language, 'long')}</span>
        </IbTypography.Paragraph>
      </div>
    );
  };

  const renderKbEntry = (entry: ListDataEntryModel) => {
    const item = entry.entry.data as { questions: string[] };
    if (!item.questions.length) return null;
    return (
      <div className={ENTRY_CLASS_NAME}>
        <IbTag content={item.questions[0]} style={'success'} />
      </div>
    );
  };

  const visibleKbEntries =
    kbEntries.length > MAX_VISIBLE_KB_ENTRIES ? kbEntries.slice(0, MAX_VISIBLE_KB_ENTRIES) : kbEntries;
  const showMore = kbEntries.length > MAX_VISIBLE_KB_ENTRIES;

  const renderShowMoreEntries = () => {
    return (
      <div className={[ENTRY_CLASS_NAME, SHOW_MORE_CLASS_NAME].join(' ')} onClick={goToKnowledgeBase}>
        <IbButton type="link">
          +&nbsp; {t('MoreWithCount', { count: kbEntries.length - visibleKbEntries.length })}
        </IbButton>
      </div>
    );
  };

  const renderContent = () => {
    if (!knowledgeBase) {
      return (
        <div className={EMPTY_CONTENT_CLASS_NAME}>
          <IbTypography.Paragraph>
            {t(
              'Add questions and answers manually or upload from file(s) for the bot to be able to answer to the users questions'
            )}
          </IbTypography.Paragraph>
          <IbButton
            disabled={creating}
            icon={<IbIcon iconName="add-one" />}
            loading={creating}
            type="secondary"
            onClick={onCreateKnowledgeBase}
          >
            {t('Create from scratch')}
          </IbButton>
        </div>
      );
    }

    return (
      <div className={CONTENT_CLASS_NAME}>
        {visibleKbEntries.map((e) => {
          return renderKbEntry(e);
        })}
        {showMore && renderShowMoreEntries()}
      </div>
    );
  };

  return (
    <IbCard
      extra={renderExtra()}
      footer={renderFooter()}
      loading={!bot || loading || creating || toggling}
      title={knowledgeBase ? t('Knowledge base') : t("You don't have a knowledge base yet")}
      onClick={onCardClick}
    >
      <div className={MAIN_CLASS_NAME}>{renderContent()}</div>
    </IbCard>
  );
};

export default KnowledgeBaseCard;
