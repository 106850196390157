import { Menu } from 'antd';
import React, { MouseEvent as ReactMouseEvent, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import { dispatcherState, workingSpaceTransformSelector } from '../../../../recoil/scenarioStructure';
import { IBindingContextMenuState } from '../../types';
import { getScenarioEditorContainerRect, useEventListener } from '../../utils';
import { BindingSchema } from '../../../../../api';

interface BindingContextMenuProps {
  binding: BindingSchema;
  contextMenuState: IBindingContextMenuState;
  onClose: () => void;
}

const BindingContextMenu: React.FC<BindingContextMenuProps> = ({ binding, contextMenuState, onClose }) => {
  const { deleteBinding, focusToGroup } = useRecoilValue(dispatcherState);
  const workingSpaceTransform = useRecoilValue(workingSpaceTransformSelector);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (!ref.current?.contains(event.target as Node)) {
      onClose();
    }
  };
  useEventListener('mousedown', handleClickOutside);

  const containerRect = getScenarioEditorContainerRect();

  const left =
    (contextMenuState.screenPosX - (containerRect?.x || 0) - workingSpaceTransform.x) / workingSpaceTransform.zoom + 5;
  const top =
    (contextMenuState.screenPosY - (containerRect?.y || 0) - workingSpaceTransform.y) / workingSpaceTransform.zoom + 5;

  const onGoTo = async () => {
    await focusToGroup(binding.targetEntityId);
    onClose();
  };

  const onDelete = async () => {
    await deleteBinding(binding.id);
    onClose();
  };

  return (
    <div
      ref={ref}
      className="sb-binding-context-menu dropdown-menu"
      style={{ left, top }}
      onMouseDownCapture={(e: ReactMouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Menu>
        <Menu.Item key="goTo" onClick={onGoTo}>
          Перейти к связанному блоку
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="delete" onClick={onDelete}>
          Удалить
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default BindingContextMenu;
