import React from 'react';
import { Tooltip, TooltipProps } from 'antd';

import './index.less';

type ISbTooltipProps = TooltipProps & React.RefAttributes<unknown>;

const SbTooltip: React.FC<ISbTooltipProps> = ({ children, overlayClassName, ...props }) => {
  const overlayClassNames = ['sb-tooltip__overlay'];
  overlayClassName && overlayClassNames.push(overlayClassName);
  return (
    <Tooltip className="sb-tooltip" overlayClassName={overlayClassNames.join(' ')} {...props}>
      <div>{children}</div>
    </Tooltip>
  );
};

export default SbTooltip;
