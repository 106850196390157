import React, { useState } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { PersonCreationRequest } from '../../../api';
import { personApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';
import { formatFullName } from '../../utils/stringUtil';

const PersonAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const history = useHistory();

  const onFinish = async () => {
    const newPerson: PersonCreationRequest = {
      fullName: formatFullName(
        form.getFieldValue('lastName'),
        form.getFieldValue('firstName'),
        form.getFieldValue('middleName')
      ),
      firstName: form.getFieldValue('firstName'),
      lastName: form.getFieldValue('lastName'),
      middleName: form.getFieldValue('middleName'),
      eMail: form.getFieldValue('eMail'),
      primaryTel: form.getFieldValue('primaryTel'),
    };

    setSaveBtnDisabled(true);
    try {
      const response = await personApi.createPerson(newPerson);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлен новый работник',
        description: newPerson.fullName,
      });
      history.push(`/persons/${response.data.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении работника',
        error: e,
      });
      setSaveBtnDisabled(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/persons');
  };

  return (
    <div>
      <PageHeader title="Новый работник" />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Фамилия" name="lastName" rules={[{ required: true, message: 'Введите фамилию' }]}>
          <Input placeholder="Фамилия" />
        </Form.Item>
        <Form.Item label="Имя" name="firstName" rules={[{ required: true, message: 'Введите имя' }]}>
          <Input placeholder="Имя" />
        </Form.Item>
        <Form.Item label="Отчество" name="middleName">
          <Input placeholder="Отчество" />
        </Form.Item>
        <Form.Item label="Телефон" name="primaryTel" rules={[{ required: true, message: 'Введите номер телефона' }]}>
          <Input placeholder="Телефон" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="eMail"
          rules={[
            { required: true, message: 'Введите E-mail' },
            { type: 'email', message: 'Не корректный E-mail' },
          ]}
        >
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={saveBtnDisabled} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={onBackBtnClick}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PersonAdd;
