import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { v4 } from 'uuid';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import IdleJs from 'idle-js';

import './index.less';

import { InvocationName, ITaskData, TaskQueue, TaskType } from '../../tasks';
import { IInvocationSendTaskContent } from '../../tasks/invocations';
import { ChatTabKeys } from '../../components/IbChatList';
import { LAYOUT_WIDTH_BREAKPOINTS } from '../../../constants';
import { IParticipantActivityUpdateTaskContent } from '../../tasks/participants';
import { currentOperatorSelector, messageRangeSelector } from '../../recoil';
import { InboxParticipantActivityEvent } from '../../../../api';
import { IIbMessageInputRef } from '../../components/IbMessageInput';
import { InboxMessageFilter } from '../../storages/messages';

import ChatTabs from './ChatTabs';
import MessageList from './MessageList';
import ContactInfo from './ContactInfo';
import ChatInfo from './ChatInfo';
import NoSelectedChat from './NoSelectedChat';
import PreviewModal from './PreviewModal';
import ChatAlarm from './ChatAlarm';

const MAIN_CLASS_NAME = 'ib-inbox-chats-page';
const CONTENT_ROW_CLASS_NAME = `${MAIN_CLASS_NAME}__content-row`;
const CHAT_LIST_CLASS_NAME = `${CONTENT_ROW_CLASS_NAME}__chat-list`;
const CHAT_DETAILS_CLASS_NAME = `${CONTENT_ROW_CLASS_NAME}__chat-details`;
const MOBILE_COLUMN_CLASS_NAME = 'ib-column-mobile';
const FIXED_COLUMN_CLASS_NAME = 'ib-column-fixed';
const STRETCH_COLUMN_CLASS_NAME = 'ib-column-stretch';
const HIDABLE_COLUMN_CLASS_NAME = 'ib-column-hidable';
const HIDABLE_COLUMN_EXPANDED_CLASS_NAME = 'ib-column-hidable_expanded';

const IDLE_TIMEOUT = 10000; // ms
const MESSAGE_INPUT_FOCUS_DELAY = 200; // ms

const ChatsPage: React.FC = () => {
  const queue = TaskQueue.instance;

  const windowSize = useWindowSize();

  const { push } = useHistory();
  const { tabKey = ChatTabKeys.Queue, chatId } = useParams<{ tabKey: string; chatId?: string }>();
  const messageFilter = new InboxMessageFilter(chatId);

  const [clientCardExpanded, setClientCardExpanded] = useState(false);

  const currentOperator = useRecoilValue(currentOperatorSelector);
  const messageRange = useRecoilValue(messageRangeSelector(messageFilter));

  const clientCardRef = useRef(null);
  const messageInputRef = useRef<IIbMessageInputRef | undefined>();

  const setParticipantActivity = (event: InboxParticipantActivityEvent) => {
    if (!currentOperator) {
      return;
    }

    const id = v4();
    const task: ITaskData<IParticipantActivityUpdateTaskContent> = {
      key: id,
      type: TaskType.ParticipantActivityUpdate,
      timestamp: moment().toISOString(),
      content: {
        participantId: currentOperator.entity.id,
        event,
      },
    };

    queue.run(task).finally();
  };

  const sendInvocationRead = (activeChatId: string) => {
    const id = v4();
    const task: ITaskData<IInvocationSendTaskContent> = {
      key: id,
      type: TaskType.InvocationSend,
      timestamp: moment().toISOString(),
      content: {
        chatId: activeChatId,
        activityId: id,
        name: InvocationName.InboxAssignmentRead,
      },
    };

    queue.run(task).finally();
  };

  const setupIdle = () => {
    if (!currentOperator) {
      return;
    }

    const idle = new IdleJs({
      idle: IDLE_TIMEOUT,
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'],
      onIdle: () => setParticipantActivity(InboxParticipantActivityEvent.TabAbandoned),
      onActive: () => setParticipantActivity(InboxParticipantActivityEvent.TabActivated),
      onHide: () => setParticipantActivity(InboxParticipantActivityEvent.TabHidden),
      onShow: () => setParticipantActivity(InboxParticipantActivityEvent.TabShowed),
      keepTracking: true,
      startAtIdle: false,
    });

    idle.start();
  };
  useEffect(setupIdle, [currentOperator?.entity.id]);

  const setTabKey = (value: string) => {
    push(`/inbox/chats/${value}`);
  };

  const setChatId = (value: string) => {
    push(`/inbox/chats/${tabKey}/${value}`);
  };

  const focusToMessageInput = () => {
    setTimeout(() => {
      messageInputRef?.current?.focusToEditor();
    }, MESSAGE_INPUT_FOCUS_DELAY);
  };

  const onChatUnselected = () => {
    push(`/inbox/chats/${tabKey}`);
  };

  const onStatusChanged = (value: string) => {
    setTabKey(value);
  };

  const onChatSelected = (value?: string) => {
    if (!value) {
      return;
    }

    setChatId(value);
    focusToMessageInput();
  };

  const onChatChanged = () => {
    if (!chatId || messageRange.scrollOffset) {
      return;
    }

    sendInvocationRead(chatId);
  };

  const onScrollToLastMessage = () => {
    if (!chatId) {
      return;
    }

    sendInvocationRead(chatId);
  };

  const onOpenClientCard = () => setClientCardExpanded(true);

  const onCollapseClientCard = (event?: MouseEvent) => {
    const root = document.getElementById('root');
    if (!event || root?.contains(event.target as Node)) {
      setClientCardExpanded(false);
    }
  };

  useOnClickOutside(clientCardRef, onCollapseClientCard, 'mouseup');

  useEffect(onChatChanged, [chatId]);

  const renderChatList = () => {
    return (
      <div className={CHAT_LIST_CLASS_NAME}>
        <ChatTabs
          chatId={chatId}
          tabKey={tabKey as ChatTabKeys}
          onSelect={onChatSelected}
          onStatusChanged={onStatusChanged}
        />
      </div>
    );
  };

  const renderChatDetails = () => {
    if (chatId) {
      return (
        <div className={CHAT_DETAILS_CLASS_NAME}>
          <ChatInfo
            chatId={chatId}
            onBack={onChatUnselected}
            onFocusToMessageInput={focusToMessageInput}
            onOpenClientCard={onOpenClientCard}
          />
          <MessageList
            key={chatId}
            chatId={chatId}
            messageInputRef={messageInputRef}
            onFocusToMessageInput={focusToMessageInput}
            onScrollToEnd={onScrollToLastMessage}
          />
        </div>
      );
    }

    return <NoSelectedChat />;
  };

  const renderMobileContent = () => (chatId ? renderChatDetails() : renderChatList());

  const onContactInfoFocusToMessageInput = () => {
    !clientCardExpanded && focusToMessageInput();
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={CONTENT_ROW_CLASS_NAME}>
        {windowSize.width > LAYOUT_WIDTH_BREAKPOINTS.small ? (
          <>
            <div className={FIXED_COLUMN_CLASS_NAME}>{renderChatList()}</div>
            <div className={STRETCH_COLUMN_CLASS_NAME}>{renderChatDetails()}</div>
          </>
        ) : (
          <div className={MOBILE_COLUMN_CLASS_NAME}>{renderMobileContent()}</div>
        )}

        {!!chatId && (
          <div
            ref={clientCardRef}
            className={`${HIDABLE_COLUMN_CLASS_NAME} ${clientCardExpanded ? HIDABLE_COLUMN_EXPANDED_CLASS_NAME : ''}`}
          >
            <ContactInfo
              chatId={chatId}
              onBack={onCollapseClientCard}
              onFocusToMessageInput={onContactInfoFocusToMessageInput}
            />
          </div>
        )}
      </div>
      <PreviewModal />
      {!!chatId && <ChatAlarm chatId={chatId} />}
    </div>
  );
};

export default ChatsPage;
