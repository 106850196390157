import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Card, List, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { useSetRecoilState } from 'recoil';

import { DraftModel } from '../../../../kb-api';
import { draftKbApi } from '../../../apis';
import { AlertTypes } from '../../../constants';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Paragraph } = Typography;

export interface IDraftsListProps {
  knowledgeBaseId: string;
}

const DraftsList: React.FC<IDraftsListProps> = ({ knowledgeBaseId }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listDrafts, setListDrafts] = useState([] as DraftModel[]);
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    onChange: (page: number) => {
      setPagination({
        ...pagination,
        current: page,
      });
    },
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await draftKbApi.searchDrafts(knowledgeBaseId, pagination.current - 1, pagination.pageSize);
      setListDrafts(response.data.items || []);
      setTotal(response.data.totalItemCount || 0);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка черновиков',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination]);

  const onAddBtnClick = () => {
    history.push(`/knowledge-bases/${knowledgeBaseId}/drafts/add`);
  };

  const onDraftCardClick = (draft: DraftModel) => {
    history.push(`/knowledge-bases/${knowledgeBaseId}/drafts/${draft.id}`);
  };

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="add" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить черновик
          </Button>,
        ]}
        title="Мои черновики"
      />
      <List<DraftModel>
        dataSource={listDrafts}
        grid={{ gutter: 24, column: 3, lg: 2, md: 1, sm: 1, xs: 1 }}
        id="drafts-list"
        loading={loading}
        pagination={{ ...pagination, total }}
        renderItem={(item) => {
          return (
            <List.Item key={item.id || ''}>
              <Card hoverable title={item.name} onClick={() => onDraftCardClick(item)}>
                <Card.Meta
                  description={
                    <Paragraph className="item" ellipsis={{ rows: 3 }}>
                      {item.description}
                    </Paragraph>
                  }
                  title={
                    <Space>
                      <span>Дата изменения:</span>
                      <Moment date={item.modifiedOn} format={'HH:mm DD.MM.YYYY'} interval={0} />
                    </Space>
                  }
                />
              </Card>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default DraftsList;
