import { RcFile } from 'antd/es/upload';
import downloadFile from 'js-file-download';
import { AxiosResponse } from 'axios';

import { BinarySourceFormat } from '../../kb-api';
import { FileExtensions } from '../constants';

export const KB_FILE_SIZE = 1024;
export const MB_FILE_SIZE = KB_FILE_SIZE * KB_FILE_SIZE;
export const GB_FILE_SIZE = MB_FILE_SIZE * KB_FILE_SIZE;
export const TB_FILE_SIZE = GB_FILE_SIZE * KB_FILE_SIZE;
export const PB_FILE_SIZE = TB_FILE_SIZE * KB_FILE_SIZE;
export const EB_FILE_SIZE = PB_FILE_SIZE * KB_FILE_SIZE;
export const ZB_FILE_SIZE = EB_FILE_SIZE * KB_FILE_SIZE;
export const YB_FILE_SIZE = ZB_FILE_SIZE * KB_FILE_SIZE;

const FILE_SIZE_UNITS: { name: string; size: number }[] = [
  { name: 'B', size: 1 },
  { name: 'KB', size: KB_FILE_SIZE },
  { name: 'MB', size: MB_FILE_SIZE },
  { name: 'GB', size: GB_FILE_SIZE },
  { name: 'TB', size: TB_FILE_SIZE },
  { name: 'PB', size: PB_FILE_SIZE },
  { name: 'EB', size: EB_FILE_SIZE },
  { name: 'ZB', size: ZB_FILE_SIZE },
  { name: 'YB', size: YB_FILE_SIZE },
];

export const getBase64Content = (file: RcFile): Promise<string> =>
  new Promise<string>((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        const base64Content = Buffer.from(e.target.result).toString('base64');
        res(base64Content);
      } else {
        rej('Невозможно загрузить файл.');
      }
    };
    reader.readAsArrayBuffer(file);
  });

export const base64ContentToFormFile = (file: RcFile, base64Content: string): File =>
  new File([new Blob([Buffer.from(base64Content, 'base64')])], file.name, {
    type: file.type || 'application/octet-stream',
  });

export const getFileExtension = (fileName: string): string => `.${fileName.split('.').pop()}`;

export const binarySourceFormatToFileExtension = (format?: BinarySourceFormat): FileExtensions | undefined => {
  switch (format) {
    case BinarySourceFormat.Csv:
      return FileExtensions.CSV;
    case BinarySourceFormat.Xls:
      return FileExtensions.XLS;
    case BinarySourceFormat.Xlsx:
      return FileExtensions.XLSX;
    case BinarySourceFormat.Doc:
      return FileExtensions.DOC;
    case BinarySourceFormat.Docx:
      return FileExtensions.DOCX;
    case BinarySourceFormat.Pdf:
      return FileExtensions.PDF;
    default:
      return undefined;
  }
};

export const fileExtensionToBinarySourceFormat = (fileExtension?: FileExtensions): BinarySourceFormat | undefined => {
  switch (fileExtension) {
    case FileExtensions.CSV:
      return BinarySourceFormat.Csv;
    case FileExtensions.XLS:
      return BinarySourceFormat.Xls;
    case FileExtensions.XLSX:
      return BinarySourceFormat.Xlsx;
    case FileExtensions.DOC:
      return BinarySourceFormat.Doc;
    case FileExtensions.DOCX:
      return BinarySourceFormat.Docx;
    case FileExtensions.PDF:
      return BinarySourceFormat.Pdf;
    default:
      return undefined;
  }
};

export const downloadNamedFile = (response: AxiosResponse): void => {
  const contentDisposition = response.headers['content-disposition'];

  const fileNameUnicodeMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/);
  if (fileNameUnicodeMatch && fileNameUnicodeMatch.length) {
    downloadFile(response.data, decodeURIComponent(fileNameUnicodeMatch[1]));
    return;
  }

  const fileNameMatch = contentDisposition.match(/filename=(.+?);/);
  if (fileNameMatch && fileNameMatch.length) {
    downloadFile(response.data, fileNameMatch[1]);
    return;
  }

  downloadFile(response.data, 'unknown.data');
};

export const formatFileSize = (size: number): string => {
  let index = 0;
  for (; index < FILE_SIZE_UNITS.length; index++) {
    const unit = FILE_SIZE_UNITS[index];
    if (size < unit.size * KB_FILE_SIZE) {
      break;
    }
  }

  if (index >= FILE_SIZE_UNITS.length) {
    index = FILE_SIZE_UNITS.length - 1;
  }

  const unit = FILE_SIZE_UNITS[index];
  const value = size / unit.size;
  const digits = value && !Number.isInteger(value) ? Math.max(0, 2 - Math.floor(Math.log10(value))) : 0;

  return `${value.toFixed(digits)} ${unit.name}`;
};
