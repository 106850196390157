import React, { ReactNode } from 'react';
import { Input } from 'antd';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import IbTypography from '../IbTypography';
import IbDivider from '../IbDivider';
import IbTextHighlighter from '../IbTextHighlighter';

const MAIN_CLASS_NAME = 'ib-card';
const TOP_ROW_CLASS_NAME = `${MAIN_CLASS_NAME}__top-row`;
const TOP_ROW_EXTRA_CLASS_NAME = `${TOP_ROW_CLASS_NAME}__extra`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const FOOTER_CLASS_NAME = `${MAIN_CLASS_NAME}__footer`;
export const FOOTER_LABEL_CLASS_NAME = 'label';

export interface IIbCardProps {
  className?: string;
  title?: string;
  editingTitle?: string;
  extra?: ReactNode;
  footer?: ReactNode;
  searchText?: string;
  titleIsEditing?: boolean;
  loading?: boolean;
  onClick?: () => void;
  onTitleInputBlur?: () => void;
  onTitleInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTitleInputFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onTitleInputKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const IbCard: React.FC<IIbCardProps> = ({
  className,
  title,
  editingTitle,
  extra,
  footer,
  children,
  searchText,
  titleIsEditing,
  loading,
  onClick,
  onTitleInputBlur,
  onTitleInputChange,
  onTitleInputFocus,
  onTitleInputKeyDown,
}) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  title = title ?? '';

  const renderTitle = () => {
    if (loading) {
      return <Skeleton width={160} />;
    }

    return <IbTextHighlighter highlightedText={searchText} text={title} />;
  };

  return (
    <div className={classes.join(' ')} onClick={onClick}>
      <div className={TOP_ROW_CLASS_NAME}>
        <IbTypography>
          <h4>
            {titleIsEditing ? (
              <Input
                autoFocus
                value={editingTitle}
                onBlur={onTitleInputBlur}
                onChange={onTitleInputChange}
                onClick={(e) => e.stopPropagation()}
                onFocus={onTitleInputFocus}
                onKeyDown={onTitleInputKeyDown}
              />
            ) : (
              renderTitle()
            )}
          </h4>
        </IbTypography>
        <div className={TOP_ROW_EXTRA_CLASS_NAME}>{!loading && extra}</div>
      </div>
      <IbDivider orientation="center" type="horizontal" />
      <div className={CONTENT_CLASS_NAME}>{loading ? <Skeleton count={3} /> : children}</div>
      <div className={FOOTER_CLASS_NAME}>{!loading && footer}</div>
    </div>
  );
};

export default IbCard;
