import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

interface IIntentRecognizedProps {
  name: string | undefined | null;
}

const IntentRecognized: React.FC<IIntentRecognizedProps> = ({ name }) => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_success ${mainClass}_end`}>
      <SbIcon iconName="check-one" size={16} />
      Распознан интент «{name}»
    </div>
  );
};

export default IntentRecognized;
