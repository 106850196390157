import React from 'react';
import { Divider } from 'antd';

import './index.less';

const MAIN_CLASS_NAME = 'ib-divider';

export interface IIbDividerProps {
  className?: string;
  type?: 'horizontal' | 'vertical';
  orientation?: 'left' | 'right' | 'center';
}

const IbDivider: React.FC<IIbDividerProps> = ({ className, type, orientation }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <Divider className={classes.join(' ')} orientation={orientation} type={type} />;
};

export default IbDivider;
