import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { TagModel } from '../../../../api';
import IbTypography from '../common/IbTypography';
import IbTag from '../common/IbTag';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbTagsPopover from '../IbTagsPopover';

import { ADD_BUTTON_CLASS_NAME, MAIN_CLASS_NAME } from './const';

const TAG_LIST_DEFAULT = [] as TagModel[];
const SELECTED_TAG_IDS_DEFAULT = [] as string[];
const ALLOW_TAGS_MANAGEMENT_DEFAULT = false;

export interface IIbTagsWidgetProps {
  tagList?: TagModel[];
  selectedTagIds?: string[];
  allowTagsManagement?: boolean;
  onChange?: (selectedTagIds: string[]) => Promise<void>;
  onTagsManagement?: () => void;
}

const IbTagsWidget: React.FC<IIbTagsWidgetProps> = ({
  tagList = TAG_LIST_DEFAULT,
  selectedTagIds = SELECTED_TAG_IDS_DEFAULT,
  allowTagsManagement = ALLOW_TAGS_MANAGEMENT_DEFAULT,
  onChange,
  onTagsManagement,
}) => {
  tagList = getDefaultIfUndefined(tagList, TAG_LIST_DEFAULT);
  selectedTagIds = getDefaultIfUndefined(selectedTagIds, SELECTED_TAG_IDS_DEFAULT);
  allowTagsManagement = getDefaultIfUndefined(allowTagsManagement, ALLOW_TAGS_MANAGEMENT_DEFAULT);

  const { t } = useTranslation();

  const onTagDelete = (tag: TagModel) => async () => {
    await onChange?.(selectedTagIds.filter((id) => id !== tag.id));
  };

  const onPopoverChange = async (selectedTagIds: string[]) => {
    await onChange?.(selectedTagIds);
  };

  const onPopoverTagsManagement = () => {
    onTagsManagement?.();
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbTagsPopover
        allowTagsManagement={allowTagsManagement}
        selectedTagIds={selectedTagIds}
        tagList={tagList}
        onChange={onPopoverChange}
        onTagsManagement={onPopoverTagsManagement}
      >
        <div className={ADD_BUTTON_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="descriptor">
            {t('+ Add')}
          </IbTypography.Paragraph>
        </div>
      </IbTagsPopover>
      {tagList
        .filter((tag: TagModel) => selectedTagIds.includes(tag.id))
        .map((tag) => (
          <IbTag
            key={tag.id}
            content={tag.label}
            style={{ background: tag.color }}
            tooltipContent={tag.label}
            onDelete={onTagDelete(tag)}
          />
        ))}
    </div>
  );
};

export default IbTagsWidget;
