import { PersonModel } from '../../../../api';

export const MAIN_CLASS_NAME = 'ib-contact-merge-modal';
export const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;
export const FORM_HEADER_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__header`;
export const FORM_CHANNEL_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__channel`;
export const FORM_HEADER_TITLE_CLASS_NAME = `${FORM_HEADER_CLASS_NAME}__title`;
export const FORM_CHANNEL_TITLE_CLASS_NAME = `${FORM_CHANNEL_CLASS_NAME}__title`;
export const ONE_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}_one-column`;
export const TWO_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}_two-column`;
export const THREE_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}_three-column`;
export const FOUR_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}_four-column`;
export const PERSON_LIST_DEFAULT = [] as PersonModel[];
