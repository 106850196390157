import React, { MouseEventHandler, ReactNode, useState } from 'react';
import { Menu } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import './index.less';

import { groupWidth, usualPadding } from '../../../constants';
import { ActionSchema, DefaultActionGroupSchema, InputActionSchema } from '../../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  dispatcherState,
  selectedEntitySelector,
  zoomForDraggableSelector,
} from '../../../../../recoil/scenarioStructure';
import {
  deleteBindingWithReferences,
  getElementId,
  instanceOfCreateElma365AppElementActionSchema,
  instanceOfDefaultActionGroupSchema,
  instanceOfExternalSigninSchema,
  instanceOfSendEmailSchema,
  instanceOfScriptActionSchema,
  instanceOfStartElma365ProcessActionSchema,
  instanceOfTransitSchema,
  tryGetElementById,
  instanceOfMergeInboxContactsSchema,
} from '../../../utils';
import ContextMenu from '../../common/ContextMenu';
import { actionContainsChildEntity, cloneAction } from '../../../utils';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import InputActionSettingsModal from '../../common/InputActionSettingsModal';
import ScriptActionSettingsModal from '../../common/ScriptActionSettingsModal';
import StartElma365ProcessActionSettingsModal from '../../common/StartElma365ProcessActionSettingsModal';
import CreateElma365AppElementActionSettingsModal from '../../common/CreateElma365AppElementActionSettingsModal';
import ExternalSignInSettingsModal from '../../common/ExternalSignInSettingsModal';
import SendEmailSettingsModal from '../../common/SendEmailSettingsModal';
import { parseTranslateTransform } from '../../../../../utils/stringUtil';
import MergeInboxContactsSettingsModal from '../../common/MergeInboxContactsSettingsModal';

const groupElementWidth = groupWidth - usualPadding * 2 - 4;

interface ActionWrapperProps {
  index: number;
  action: ActionSchema;
  group: DefaultActionGroupSchema;
  icon: ReactNode;
  title: ReactNode | string;
  showInputActionSettingsMenu?: boolean;
  showScriptActionSettingsMenu?: boolean;
  showExternalSignInSettingsMenu?: boolean;
  showSendEmailSettingsMenu?: boolean;
  showStartElma365ProcessActionSettingsMenu?: boolean;
  showCreateElma365AppElementActionSettingsMenu?: boolean;
  showMergeInboxContactsSettingsMenu?: boolean;
}

const ActionWrapper: React.FC<ActionWrapperProps> = ({
  action,
  index,
  group,
  icon,
  title,
  showInputActionSettingsMenu = false,
  showScriptActionSettingsMenu = false,
  showExternalSignInSettingsMenu = false,
  showStartElma365ProcessActionSettingsMenu = false,
  showSendEmailSettingsMenu = false,
  showCreateElma365AppElementActionSettingsMenu = false,
  showMergeInboxContactsSettingsMenu = false,
  children,
}) => {
  const { updateZoomForDraggable } = useRecoilValue(dispatcherState);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);
  const zoomForDraggable = useRecoilValue(zoomForDraggableSelector);

  const [inputActionSettingsModalVisible, setInputActionSettingsModalVisible] = useState(false);
  const [scriptActionSettingsModalVisible, setScriptActionSettingsModalVisible] = useState(false);
  const [oauthSigninSettingsModalVisible, setOAuthSigninSettingsModalVisible] = useState(false);
  const [sendEmailSettingsModalVisible, setSendEmailSettingsModalVisible] = useState(false);
  const [startElma365ProcessActionSettingsModalVisible, setStartElma365ProcessActionSettingsModalVisible] = useState(
    false
  );
  const [
    createElma365AppElementActionSettingsModalVisible,
    setCreateElma365AppElementActionSettingsModalVisible,
  ] = useState(false);
  const [mergeInboxContactsSettingsModalVisible, setMergeInboxContactsSettingsModalVisible] = useState(false);

  const showActionSettingsMenu =
    showInputActionSettingsMenu ||
    showScriptActionSettingsMenu ||
    showExternalSignInSettingsMenu ||
    showSendEmailSettingsMenu ||
    showStartElma365ProcessActionSettingsMenu ||
    showCreateElma365AppElementActionSettingsMenu ||
    showMergeInboxContactsSettingsMenu;

  const onCopyActionMenuSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const actionCopy = cloneAction(action);

    const foundGroup = tryGetElementById(newScenarioStructure, group.id);
    if (foundGroup && instanceOfDefaultActionGroupSchema(foundGroup)) {
      const sourceActionIndex = foundGroup.actions.findIndex((a) => action.id === a.id);
      foundGroup.actions.splice(sourceActionIndex + 1, 0, actionCopy);
      setScenarioStructure(newScenarioStructure);
    }
  };

  const onDeleteActionMenuSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundGroup = newScenarioStructure.actionGroups.find((ag) => ag.id === group.id);
    (foundGroup as DefaultActionGroupSchema).actions = group.actions.filter((a) => a.id !== action.id);

    // NOTE: удаляем связи действий внутри группы
    if (instanceOfTransitSchema(action)) {
      action.buttons.forEach((btn) => {
        deleteBindingWithReferences(newScenarioStructure, btn.outputBindingId);
      });
    }
    deleteBindingWithReferences(newScenarioStructure, action.outputBindingId);

    setScenarioStructure(newScenarioStructure);
  };

  const onSettingsMenuSelect = () => {
    setSelectedEntity(action);
    setInputActionSettingsModalVisible(showInputActionSettingsMenu);
    setScriptActionSettingsModalVisible(showScriptActionSettingsMenu);
    setOAuthSigninSettingsModalVisible(showExternalSignInSettingsMenu);
    setStartElma365ProcessActionSettingsModalVisible(showStartElma365ProcessActionSettingsMenu);
    setSendEmailSettingsModalVisible(showSendEmailSettingsMenu);
    setCreateElma365AppElementActionSettingsModalVisible(showCreateElma365AppElementActionSettingsMenu);
    setMergeInboxContactsSettingsModalVisible(showMergeInboxContactsSettingsMenu);
  };

  const onInputActionSettingsModalClose = () => setInputActionSettingsModalVisible(false);

  const onScriptActionSettingsModalClose = () => setScriptActionSettingsModalVisible(false);

  const onOAuthSigninSettingsModalClose = () => setOAuthSigninSettingsModalVisible(false);

  const onSendEmailSettingsModalClose = () => setSendEmailSettingsModalVisible(false);

  const onStartElma365ProcessActionSettingsModalClose = () => setStartElma365ProcessActionSettingsModalVisible(false);

  const onCreateElma365AppElementActionSettingsModalClose = () =>
    setCreateElma365AppElementActionSettingsModalVisible(false);

  const onMergeInboxContactsSettingsModalClose = () => setMergeInboxContactsSettingsModalVisible(false);

  const onActionChange = (action: ActionSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id) as ActionSchema;
    Object.keys(action).forEach((key) => (foundAction[key] = action[key]));
    setScenarioStructure(newScenarioStructure);
  };

  const onMouseDown: MouseEventHandler = (e) => {
    e.stopPropagation();
    setSelectedEntity(action);
  };

  return (
    <Draggable key={action.id} draggableId={action.id} index={index}>
      {(provided, snapshot) => {
        const classes = ['group-element'];
        if (snapshot.isDragging) {
          classes.push('group-element_dragging');
          updateZoomForDraggable().finally();
        }
        if (action.id === selectedEntity?.id) {
          classes.push('group-element_selected');
        }
        if (scenarioValidation?.hasIssue(action.id, '')) {
          classes.push('group-element_warning');
        }
        if (actionContainsChildEntity(action, selectedEntity?.id)) {
          classes.push('group-element_child-selected');
        }

        const { x, y } = parseTranslateTransform(provided.draggableProps.style?.transform);
        const transform = `translate(${x / zoomForDraggable}px, ${y / zoomForDraggable}px)`;

        return (
          <div
            id={getElementId(action.id)}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={classes.join(' ')}
            style={{
              ...provided.draggableProps.style,
              transform,
              width: groupElementWidth,
            }}
            onMouseDown={onMouseDown}
          >
            <div className="group-element__header">
              <div className="group-element-icon" {...provided.dragHandleProps}>
                {icon}
              </div>
              <div
                className="group-element-title"
                title={typeof title === 'string' ? title : undefined}
                {...provided.dragHandleProps}
              >
                {title}
              </div>
              <ContextMenu
                menuContent={
                  <Menu>
                    <Menu.Item key="copy" onClick={onCopyActionMenuSelect}>
                      <SbIcon iconName="copy" />
                    </Menu.Item>
                    {showActionSettingsMenu && (
                      <Menu.Item key="settings" onClick={onSettingsMenuSelect}>
                        <SbIcon iconName="setting-two" />
                      </Menu.Item>
                    )}
                    <Menu.Item key="delete" onClick={onDeleteActionMenuSelect}>
                      <SbIcon iconName="close" />
                    </Menu.Item>
                  </Menu>
                }
              />
              <InputActionSettingsModal
                action={action as InputActionSchema}
                visible={inputActionSettingsModalVisible}
                onChange={onActionChange}
                onClose={onInputActionSettingsModalClose}
              />
              {instanceOfScriptActionSchema(action) && (
                <ScriptActionSettingsModal
                  action={action}
                  visible={scriptActionSettingsModalVisible}
                  onChange={onActionChange}
                  onClose={onScriptActionSettingsModalClose}
                />
              )}
              {instanceOfExternalSigninSchema(action) && (
                <ExternalSignInSettingsModal
                  action={action}
                  visible={oauthSigninSettingsModalVisible}
                  onChange={onActionChange}
                  onClose={onOAuthSigninSettingsModalClose}
                />
              )}
              {instanceOfSendEmailSchema(action) && (
                <SendEmailSettingsModal
                  action={action}
                  visible={sendEmailSettingsModalVisible}
                  onChange={onActionChange}
                  onClose={onSendEmailSettingsModalClose}
                />
              )}
              {instanceOfStartElma365ProcessActionSchema(action) && (
                <StartElma365ProcessActionSettingsModal
                  action={action}
                  visible={startElma365ProcessActionSettingsModalVisible}
                  onChange={onActionChange}
                  onClose={onStartElma365ProcessActionSettingsModalClose}
                />
              )}
              {instanceOfCreateElma365AppElementActionSchema(action) && (
                <CreateElma365AppElementActionSettingsModal
                  action={action}
                  visible={createElma365AppElementActionSettingsModalVisible}
                  onChange={onActionChange}
                  onClose={onCreateElma365AppElementActionSettingsModalClose}
                />
              )}
              {instanceOfMergeInboxContactsSchema(action) && (
                <MergeInboxContactsSettingsModal
                  action={action}
                  visible={mergeInboxContactsSettingsModalVisible}
                  onChange={onActionChange}
                  onClose={onMergeInboxContactsSettingsModalClose}
                />
              )}
            </div>
            {children}
          </div>
        );
      }}
    </Draggable>
  );
};

export default ActionWrapper;
