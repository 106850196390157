import React, { memo, useState } from 'react';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import Messages from '../../Messages';
import InputVariable from '../../InputVariable';
import { DefaultActionGroupSchema, Elma365AppElementInputSchema, InputActionSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import InputActionSettingsModal, { InputSettingsTabPaneKeys } from '../../common/InputActionSettingsModal';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';
import { tryGetElementById } from '../../../utils';

interface IElma365AppElementInputProps {
  index: number;
  action: Elma365AppElementInputSchema;
  group: DefaultActionGroupSchema;
}

const Elma365AppElementInput: React.FC<IElma365AppElementInputProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const onSettingsModalOpen = () => setSettingsModalVisible(true);
  const onSettingsModalClose = () => setSettingsModalVisible(false);

  const onActionSettingsChange = (action: InputActionSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id) as Elma365AppElementInputSchema;
    Object.keys(action).forEach((key) => (foundAction[key] = action[key]));
    setScenarioStructure(newScenarioStructure);
  };

  return (
    <ActionWrapper
      showInputActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="send-to-back" size={20} />}
      index={index}
      title="Выбор эл. прил. ELMA365"
    >
      <span className="elma365-app-element-input-action__content">
        {action.appTitle ? (
          <button onClick={onSettingsModalOpen}>{action.appTitle}</button>
        ) : (
          <>
            <button onClick={onSettingsModalOpen}>Выбрать</button> приложение
          </>
        )}
      </span>
      <Messages action={action} group={group} placeholder="Добавьте текст" />
      <InputVariable action={action} />
      <InputActionSettingsModal
        action={action}
        tabKey={InputSettingsTabPaneKeys.ELMA365_APP}
        visible={settingsModalVisible}
        onChange={onActionSettingsChange}
        onClose={onSettingsModalClose}
      />
    </ActionWrapper>
  );
};

export default memo(Elma365AppElementInput);
