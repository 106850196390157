import React from 'react';
import { Menu } from 'antd';
import { MenuItemGroupProps } from 'rc-menu/lib/MenuItemGroup';

import './index.less';

export type ISbItemGroupProps = MenuItemGroupProps;

const SbItemGroup: React.FC<ISbItemGroupProps> = ({ children, ...otherProps }) => {
  return (
    <Menu.ItemGroup className="sb-menu__item-group" {...otherProps}>
      {children}
    </Menu.ItemGroup>
  );
};

export default SbItemGroup;
