import React, { useState } from 'react';
import { Select } from 'antd';
import './index.less';
import { SelectProps, SelectValue } from 'antd/lib/select';

import SbIcon from '../SbIcon';

export interface ISbSelectProps extends SelectProps<SelectValue> {
  sbType?: 'default' | 'light';
  sbSize?: 'big' | 'small';
}

const SbSelect: React.FC<ISbSelectProps> = ({
  sbType = 'default',
  sbSize = 'big',
  open,
  suffixIcon,
  className,
  dropdownClassName,
  onChange,
  children,
  ...otherProps
}) => {
  const [currentOpen, setCurrentOpen] = useState(open);

  const classNames = [className, 'sb-select'];
  if (sbType) {
    classNames.push(`sb-select_type-${sbType}`);
  }
  if (sbSize) {
    classNames.push(`sb-select_size-${sbSize}`);
  }

  const dropdownClassNames = [dropdownClassName, 'sb-select__dropdown'];
  if (sbType) {
    dropdownClassNames.push(`sb-select__dropdown_type-${sbType}`);
  }
  if (sbSize) {
    dropdownClassNames.push(`sb-select__dropdown_size-${sbSize}`);
  }

  const onOpenChange = (value: boolean) => setCurrentOpen(value);

  const renderSuffixIcon = () => {
    if (suffixIcon) {
      return suffixIcon;
    }

    if (sbType === 'default') {
      return undefined;
    }

    return <SbIcon iconName={currentOpen ? 'up' : 'down'} size={sbSize === 'big' ? 30 : 20} />;
  };

  return (
    <Select
      className={classNames.join(' ')}
      clearIcon={<SbIcon iconName="handle-x" size={16} />}
      dropdownClassName={dropdownClassNames.join(' ')}
      suffixIcon={renderSuffixIcon()}
      onChange={onChange}
      {...otherProps}
      open={currentOpen}
      onDropdownVisibleChange={onOpenChange}
    >
      {children}
    </Select>
  );
};

export default SbSelect;
