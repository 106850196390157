import React from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib/menu';

import './index.less';
import SbItem, { ISbItemProps } from './SbItem';
import SbDivider, { ISbDividerProps } from './SbDivider';
import SbSubMenu, { ISbSubMenuProps } from './SbSubMenu';
import SbItemGroup, { ISbItemGroupProps } from './SbItemGroup';

export interface ISbMenuProps extends MenuProps {
  sbMenuType?: 'dropdown';
}

const SbMenu: React.FC<ISbMenuProps> & {
  Divider: React.FC<ISbDividerProps>;
  Item: React.FC<ISbItemProps>;
  SubMenu: React.FC<ISbSubMenuProps>;
  ItemGroup: React.FC<ISbItemGroupProps>;
} = ({ sbMenuType, children, ...otherProps }) => {
  const classNames = ['sb-menu'];
  if (sbMenuType) {
    classNames.push(`sb-menu_${sbMenuType}`);
  }

  return (
    <Menu className={classNames.join(' ')} {...otherProps}>
      {children}
    </Menu>
  );
};

SbMenu.Divider = SbDivider;
SbMenu.Item = SbItem;
SbMenu.SubMenu = SbSubMenu;
SbMenu.ItemGroup = SbItemGroup;

export default SbMenu;
