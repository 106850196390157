import React, { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Modal,
  Table,
  TablePaginationConfig,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { AlignLeftOutlined, DownloadOutlined, FormOutlined, UploadOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import downloadFile from 'js-file-download';
import moment from 'moment';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useSetRecoilState } from 'recoil';

import { AgentModel, AgentPublishRequest, BotVersionModel, PublishStage, TrainStatus } from '../../../api';
import { agentApi, botVersionApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';
import { getEditUrlWithBackUrl } from '../../utils/urlUtil';

import BotVersionImport from './BotVersionImport';
import BotVersionSearch from './BotVersionSearch';

const { TabPane } = Tabs;
const { Paragraph } = Typography;

export interface IBotVersionsProps {
  agent: AgentModel;
  onPublishRun: () => void;
}

const BotVersions: React.FC<IBotVersionsProps> = ({ agent, onPublishRun = () => {} }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [botVersionList, setBotVersionList] = useState([] as BotVersionModel[]);
  const [hashValue, setHashValue] = useState<string>();
  const [publishing, setPublishing] = useState(false);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const goToFirstPage = () => {
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await botVersionApi.searchBotVersions(
        agent.id,
        hashValue,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setBotVersionList(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка версий бота',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    if (!modalVisible) return;
    loadDataAsync();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize]);

  const onOpenModalBtnClick = () => {
    if (!agent) return;

    setModalVisible(true);
    loadDataAsync();
  };

  const onModalCancelBtnClick = () => {
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'Версия',
      dataIndex: 'name',
      ellipsis: true,
      width: '41%',
    },
    {
      title: 'Дата',
      dataIndex: 'createdOn',
      ellipsis: true,
      width: '20%',
      render: (_: unknown, item: BotVersionModel) => (
        <Moment date={item.createdOn} format={'HH:mm DD.MM.YYYY'} interval={0} />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'state',
      width: '18%',
      render: (_: unknown, item: BotVersionModel) => {
        return (
          <div>
            {item.isProduction && <Tag color="blue">Релиз</Tag>}
            {item.isStaging && <Tag color="orange">Тест</Tag>}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '21%',
      render: (_: unknown, item: BotVersionModel) => {
        const onMenuClick = async (info: MenuInfo) => {
          const publishStage = info.key === 'production' ? PublishStage.NUMBER_2 : PublishStage.NUMBER_1;

          if (item.isStaging && publishStage === PublishStage.NUMBER_1) {
            addAlert({
              type: AlertTypes.WARNING,
              message: `Версия "${item.name}" уже опубликована в тест`,
              description: '',
            });
            return;
          }

          if (item.isProduction && publishStage === PublishStage.NUMBER_2) {
            addAlert({
              type: AlertTypes.WARNING,
              message: `Версия "${item.name}" уже опубликована в релиз`,
              description: '',
            });
            return;
          }

          if (
            agent.stagingAgent?.trainResult?.status === TrainStatus.Pending &&
            publishStage === PublishStage.NUMBER_1
          ) {
            addAlert({
              type: AlertTypes.WARNING,
              message: 'Запуск публикации в тестовую версию не возможен пока идет обучение модели',
              description: '',
            });
            return;
          }

          if (
            agent.productionAgent?.trainResult?.status === TrainStatus.Pending &&
            publishStage === PublishStage.NUMBER_2
          ) {
            addAlert({
              type: AlertTypes.WARNING,
              message: 'Запуск публикации в релизную версию не возможен пока идет обучение модели',
              description: '',
            });
            return;
          }

          setPublishing(true);
          try {
            const request: AgentPublishRequest = {
              agentId: agent.id,
              versionId: item.id,
              publishStage,
            };
            await agentApi.publishBotFromVersion(request);
            addAlert({
              type: AlertTypes.SUCCESS,
              message: `Запрос на публикацию версии "${item.name}" отправлен`,
              description: '',
            });
            onPublishRun();
          } catch (e) {
            addAlert({
              type: AlertTypes.ERROR,
              message: 'Ошибка при публикации версии бота',
              error: e,
            });
          }
          setPublishing(false);

          goToFirstPage();
        };

        const menu = (
          <Menu onClick={onMenuClick}>
            <Menu.Item key="production">Опубликовать в релиз</Menu.Item>
            <Menu.Item key="staging">Опубликовать в тест</Menu.Item>
          </Menu>
        );

        const onDownloadClick = async () => {
          const response = await botVersionApi.getBotFiles(item.id, { responseType: 'blob' });
          const fileNameParts = [agent.name, item.name, moment(item.createdOn).format('HH.mm_DD.MM.YYYY'), item.hash];
          const fileName = `${fileNameParts.join('__')}.zip`;
          downloadFile(response.data, fileName);
        };

        return (
          <div onClick={(event) => event.stopPropagation()}>
            <Tooltip placement="top" title="Открыть версию в редакторе">
              <Button
                href={getEditUrlWithBackUrl(item.editUrl)}
                icon={<FormOutlined />}
                target="_self"
                type="primary"
              />
            </Tooltip>
            <Divider type="vertical" />
            <Dropdown overlay={menu}>
              <Button icon={<UploadOutlined />} type="primary" />
            </Dropdown>
            <Divider type="vertical" />
            <Tooltip placement="top" title="Скачать версию">
              <Button icon={<DownloadOutlined />} type="primary" onClick={onDownloadClick} />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const renderMoreData = (item: BotVersionModel) => {
    return (
      <React.Fragment>
        <Paragraph>
          <b>Описание:</b> {item.description || '-'}
        </Paragraph>
        <Paragraph>
          <b>Хэш:</b> {item.hash || '-'}
        </Paragraph>
      </React.Fragment>
    );
  };

  const onSearch = (newHash: string) => {
    if (hashValue === newHash) return;

    setHashValue(newHash);
    goToFirstPage();
  };

  const onClear = () => {
    if (!hashValue) return;

    setHashValue(undefined);
    goToFirstPage();
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <Button icon={<AlignLeftOutlined />} type="primary" onClick={onOpenModalBtnClick}>
        Список версий
      </Button>
      <Modal
        destroyOnClose
        closable={!publishing}
        footer={[
          <Button key="back" disabled={publishing} onClick={onModalCancelBtnClick}>
            Закрыть
          </Button>,
        ]}
        maskClosable={!publishing}
        title={`Список версий бота: ${agent.name}`}
        visible={modalVisible}
        width={900}
        onCancel={onModalCancel}
      >
        <Tabs defaultActiveKey="list">
          <TabPane key="list" tab="Список">
            <BotVersionSearch loading={loading} onClear={onClear} onSearch={onSearch} />
            <Table
              columns={columns}
              dataSource={botVersionList.map((item, i) => ({ key: i, ...item }))}
              expandable={{
                expandedRowRender: renderMoreData,
                expandRowByClick: true,
              }}
              loading={loading || publishing}
              pagination={pagination}
              onChange={onTableChange}
            />
          </TabPane>
          <TabPane key="import" tab="Импорт">
            <BotVersionImport agent={agent} onImport={goToFirstPage} />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default BotVersions;
