import { atom, atomFamily } from 'recoil';
import { HubConnection } from '@microsoft/signalr';
import { v4 } from 'uuid';

import {
  DATABASE_NAME,
  IEntityFilter,
  IEntityItem,
  IEntityRange,
  IEntityStats,
  IEntityStorage,
  IStorageItemEvent,
  IStorageStateEvent,
  EntityKey,
} from '../storages';
import { InboxChatStorage } from '../storages/chats';
import { InboxMessageStorage } from '../storages/messages';
import { InboxParticipantStorage } from '../storages/participants';
import { InboxAttachmentStorage } from '../storages/attachments';
import {
  EventBus,
  EventType,
  INetworkConnectionFailure,
  INetworkConnectionOff,
  INetworkConnectionOn,
  INetworkConnectionSuccess,
} from '../events';
import { hubConnections } from '../../utils/socketsUtil';
import { DEFAULT_ERROR_NAME, isNetworkError, NETWORK_ERROR_MESSAGE } from '../../utils/errorUtils';
import { TaskQueue, TaskType } from '../tasks';
import { MessageSendTaskHandler } from '../tasks/messages';
import { InvocationSendTaskHandler } from '../tasks/invocations';
import { AttachmentDeleteTaskHandler, AttachmentUploadTaskHandler } from '../tasks/attachments';
import { ParticipantActivityUpdateTaskHandler, ParticipantStatusUpdateTaskHandler } from '../tasks/participants';
import { BotSortDirection } from '../../../api';

import {
  IInboxAlert,
  INetworkConnection,
  InboxChannelFilterParams,
  NetworkConnectionStatus,
  PersonFilterParams,
  IPreviewModalParams,
  BotFilterParams,
} from './types';

export const inboxAlertsState = atom<IInboxAlert[]>({
  key: 'inboxAlertsState',
  default: [],
});

const itemAtomFamily = <TEntity, TFilter extends IEntityFilter<TEntity, TFilter>>(
  key: string,
  accessor: () => IEntityStorage<TEntity, TFilter>
) =>
  atomFamily<IEntityItem<TEntity> | undefined, string>({
    key,
    default: (id: string) => accessor().getItem(id),
    effects_UNSTABLE: (id: string) => [
      ({ setSelf, trigger }) => {
        const bus = EventBus.instance;
        const storage = accessor();

        if (trigger === 'get') {
          storage.findItem(id).then((item) => setSelf(item));
        }

        const subscriptions = [
          bus.onSync<IStorageItemEvent<TEntity>>(EventType.StorageTableItemUpdated, (event) => {
            if (storage.name !== event.name) {
              return;
            }

            const key = storage.getKey(event.item.entity);
            if (id == key) {
              setSelf(event.item);
            } else if (id === EntityKey.Me && storage.isMe(key)) {
              setSelf(event.item);
            }
          }),
          bus.onSync<INetworkConnectionOn>(EventType.NetworkConnectionOn, () => {
            storage.findItem(id).then((item) => setSelf(item));
          }),
        ];

        return () => {
          subscriptions.forEach((s) => s.unsubscribe());
        };
      },
    ],
  });

const itemsAtomFamily = <TEntity, TFilter extends IEntityFilter<TEntity, TFilter>>(
  key: string,
  accessor: () => IEntityStorage<TEntity, TFilter>
) =>
  atomFamily<IEntityItem<TEntity>[], TFilter>({
    key,
    default: (filter: TFilter) => accessor().getItems(filter),
    effects_UNSTABLE: (filter: TFilter) => [
      ({ setSelf }) => {
        const bus = EventBus.instance;
        const storage = accessor();

        const subscription = bus.onSync<IStorageStateEvent<TFilter>>(EventType.StorageStateItemsUpdated, (event) => {
          if (storage.name !== event.name) {
            return;
          }

          if (!filter.equals(event.filter)) {
            return;
          }

          const items = storage.getItems(filter);
          setSelf(items);
        });

        return () => {
          subscription.unsubscribe();
        };
      },
    ],
  });

const statsAtomFamily = <TEntity, TFilter extends IEntityFilter<TEntity, TFilter>>(
  key: string,
  accessor: () => IEntityStorage<TEntity, TFilter>
) =>
  atomFamily<IEntityStats, TFilter>({
    key,
    default: (filter: TFilter) => accessor().getStats(filter),
    effects_UNSTABLE: (filter: TFilter) => [
      ({ setSelf }) => {
        const bus = EventBus.instance;
        const storage = accessor();

        const subscription = bus.onSync<IStorageStateEvent<TFilter>>(EventType.StorageStateStatsUpdated, (event) => {
          if (storage.name !== event.name) {
            return;
          }

          if (!filter.equals(event.filter)) {
            return;
          }

          const stats = storage.getStats(filter);
          setSelf(stats);
        });

        return () => subscription.unsubscribe();
      },
    ],
  });

const rangeAtomFamily = <TEntity, TFilter extends IEntityFilter<TEntity, TFilter>>(
  key: string,
  accessor: () => IEntityStorage<TEntity, TFilter>
) =>
  atomFamily<IEntityRange, TFilter>({
    key,
    default: (filter: TFilter) => accessor().getRange(filter),
    effects_UNSTABLE: (filter: TFilter) => [
      ({ onSet, trigger }) => {
        const bus = EventBus.instance;
        const storage = accessor();

        if (trigger === 'get') {
          storage.refreshRange(filter).finally();
        }

        onSet((scroll) => {
          const oldRange = storage.getRange(filter);
          const newRange = storage.setRange(filter, scroll);
          if (oldRange.pageIndex !== newRange.pageIndex || oldRange.pageCount !== newRange.pageCount) {
            storage.refreshRange(filter).finally();
          }
        });

        const subscription = bus.onSync<INetworkConnectionOn>(EventType.NetworkConnectionOn, () => {
          storage.refreshRange(filter).finally();
        });

        return () => subscription.unsubscribe();
      },
    ],
  });

export const networkConnectionState = atom<INetworkConnection>({
  key: 'networkConnectionState',
  default: { status: NetworkConnectionStatus.On },
  effects_UNSTABLE: [
    ({ onSet }) => {
      onSet((newValue, oldValue) => {
        if ('status' in oldValue && newValue.status === oldValue.status) {
          return;
        }

        const bus = EventBus.instance;
        if (newValue.status === NetworkConnectionStatus.On) {
          const event: INetworkConnectionOn = { result: newValue.result };
          bus.emit(EventType.NetworkConnectionOn, event);
        } else if (newValue.status === NetworkConnectionStatus.Off) {
          const event: INetworkConnectionOff = { error: newValue.error };
          bus.emit(EventType.NetworkConnectionOff, event);
        }
      });
    },
    ({ setSelf }) => {
      const bus = EventBus.instance;

      const subscriptions = [
        bus.onSync<INetworkConnectionSuccess>(EventType.NetworkConnectionSuccess, (event) => {
          setSelf({ status: NetworkConnectionStatus.On, result: event.result });
        }),
        bus.onSync<INetworkConnectionFailure>(EventType.NetworkConnectionFailure, (event) => {
          if (isNetworkError(event.error)) {
            setSelf({ status: NetworkConnectionStatus.Off, error: event.error });
          }
        }),
      ];

      return () => {
        subscriptions.forEach((s) => s.unsubscribe());
      };
    },
    () => {
      const bus = EventBus.instance;

      const handleStart = (connection: HubConnection) => {
        const event: INetworkConnectionSuccess = { result: { connectionId: connection.connectionId } };
        bus.emit(EventType.NetworkConnectionSuccess, event);
      };

      const handleError = (connection: HubConnection, error: Error) => {
        const event: INetworkConnectionFailure = {
          error: { name: DEFAULT_ERROR_NAME, message: NETWORK_ERROR_MESSAGE, cause: error } as Error,
        };
        bus.emit(EventType.NetworkConnectionFailure, event);
      };

      const handleClose = (connection: HubConnection, error?: Error) => {
        const event: INetworkConnectionFailure = {
          error: {
            name: DEFAULT_ERROR_NAME,
            message: NETWORK_ERROR_MESSAGE,
            cause: error || new Error('Hub connection has closed'),
          } as Error,
        };
        bus.emit(EventType.NetworkConnectionFailure, event);
      };

      const handleReconnecting = (connection: HubConnection, error?: Error) => {
        const event: INetworkConnectionFailure = {
          error: {
            name: DEFAULT_ERROR_NAME,
            message: NETWORK_ERROR_MESSAGE,
            cause: error || new Error('Hub connection is reconnecting'),
          } as Error,
        };
        bus.emit(EventType.NetworkConnectionFailure, event);
      };

      const handleReconnected = (connection: HubConnection, connectionId?: string) => {
        const event: INetworkConnectionSuccess = { result: { connectionId: connectionId || connection.connectionId } };
        bus.emit(EventType.NetworkConnectionSuccess, event);
      };

      hubConnections.onStart(handleStart);
      hubConnections.onError(handleError);
      hubConnections.onClose(handleClose);
      hubConnections.onReconnecting(handleReconnecting);
      hubConnections.onReconnected(handleReconnected);

      return () => {
        hubConnections.offStart(handleStart);
        hubConnections.offError(handleError);
        hubConnections.offClose(handleClose);
        hubConnections.offReconnecting(handleReconnecting);
        hubConnections.offReconnected(handleReconnected);
      };
    },
  ],
});

export const operatorsPageSearchState = atom<string>({
  key: 'operatorsPageSearchState',
  default: '',
});

export const messageItemsState = itemsAtomFamily('messageItemsState', () => InboxMessageStorage.instance);
export const messageStatsState = statsAtomFamily('messageStatsState', () => InboxMessageStorage.instance);
export const messageRangeState = rangeAtomFamily('messageRangeState', () => InboxMessageStorage.instance);

export const chatItemState = itemAtomFamily('chatItemState', () => InboxChatStorage.instance);
export const chatItemsState = itemsAtomFamily('chatItemsState', () => InboxChatStorage.instance);
export const chatStatsState = statsAtomFamily('chatStatsState', () => InboxChatStorage.instance);
export const chatRangeState = rangeAtomFamily('chatRangeState', () => InboxChatStorage.instance);

export const attachmentItemsState = itemsAtomFamily('attachmentItemsState', () => InboxAttachmentStorage.instance);
export const attachmentRangeState = rangeAtomFamily('attachmentRangeState', () => InboxAttachmentStorage.instance);

export const participantItemState = itemAtomFamily('participantItemState', () => InboxParticipantStorage.instance);
export const participantItemsState = itemsAtomFamily('participantItemsState', () => InboxParticipantStorage.instance);
export const participantStatsState = statsAtomFamily('participantStatsState', () => InboxParticipantStorage.instance);
export const participantRangeState = rangeAtomFamily('participantRangeState', () => InboxParticipantStorage.instance);

export const contactListFilterState = atom<PersonFilterParams>({
  key: 'contactListFilterState',
  default: { pageIndex: 0, loadMore: false },
});

export const botListFilterState = atom<BotFilterParams>({
  key: 'botListFilterState',
  default: { pageIndex: 0, loadMore: false, sort: BotSortDirection.StageModifiedOnDescending },
});

const getCurrentActivityKey = (chatId: string) => `${DATABASE_NAME}:state.current:chat.${chatId}.activity.id`;
export const currentActivityIdState = atomFamily<string, string>({
  key: 'currentActivityIdState',
  default: (chatId) => {
    const key = getCurrentActivityKey(chatId);
    let value = localStorage.getItem(key);
    if (!value) {
      value = v4();
      localStorage.setItem(key, value);
    }
    return value;
  },
  effects_UNSTABLE: (chatId) => [
    ({ onSet }) => onSet((newValue) => localStorage.setItem(getCurrentActivityKey(chatId), newValue)),
  ],
});

TaskQueue.bind(TaskType.MessageSend, () => MessageSendTaskHandler.instance);
TaskQueue.bind(TaskType.InvocationSend, () => InvocationSendTaskHandler.instance);
TaskQueue.bind(TaskType.AttachmentUpload, () => AttachmentUploadTaskHandler.instance);
TaskQueue.bind(TaskType.AttachmentDelete, () => AttachmentDeleteTaskHandler.instance);
TaskQueue.bind(TaskType.ParticipantStatusUpdate, () => ParticipantStatusUpdateTaskHandler.instance);
TaskQueue.bind(TaskType.ParticipantActivityUpdate, () => ParticipantActivityUpdateTaskHandler.instance);

export const channelListFilterState = atom<InboxChannelFilterParams>({
  key: 'channelListFilterState',
  default: { pageIndex: 0, loadMore: false },
});

export const botListSearchState = atom<string>({
  key: 'botListSearchState',
  default: '',
});

export const tagListSearchState = atom<unknown>({
  key: 'tagListSearchState',
  default: {},
});

export const previewModalParamsState = atom<IPreviewModalParams>({
  key: 'previewModalParamsState',
  default: {
    isOpen: false,
    items: [],
    onClose: () => {},
    onReply: () => {},
  } as IPreviewModalParams,
});

export const operatorGroupListFilterState = atom<unknown>({
  key: 'operatorGroupListFilterState',
  default: {},
});
