import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Table, TablePaginationConfig } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { ListKnowledgeBaseModel } from '../../../kb-api';
import { knowledgeBaseKbApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const KnowledgeBaseList: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listKnowledgeBases, setListKnowledgeBases] = useState([] as ListKnowledgeBaseModel[]);
  const history = useHistory();

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await knowledgeBaseKbApi.searchKnowledgeBases(
        undefined,
        undefined,
        undefined,
        undefined,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setListKnowledgeBases(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка баз знаний',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize]);

  const onAddBtnClick = () => {
    history.push('/knowledge-bases/add');
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const onRow = (kb: ListKnowledgeBaseModel) => ({
    onClick: () => {
      history.push(`/knowledge-bases/${kb.entry.id}`);
    },
  });

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      width: '30%',
      render: (_: string, kb: ListKnowledgeBaseModel) => <Link to="#">{kb.entry.name}</Link>,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      ellipsis: true,
      width: '70%',
    },
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="add" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить
          </Button>,
        ]}
        title="Базы знаний"
      />
      <Table
        columns={columns}
        dataSource={listKnowledgeBases}
        loading={loading}
        pagination={pagination}
        rowKey="id"
        onChange={onTableChange}
        onRow={onRow}
      />
    </div>
  );
};

export default KnowledgeBaseList;
