import React, { memo, useEffect, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { IInputConnectionPosition, IOutputConnectionPosition, IPosition } from '../../types';
import {
  getOutputOffsetX,
  positionsOverlap,
  isAlternateTarget,
  positionOverlapWithSome,
  getElementRect,
} from '../../utils';
import { EntitySchema } from '../../../../../api';
import {
  currentScenarioStructureSelector,
  dragTargetSelector,
  groupPlaceholderPositionSelector,
  groupPlaceholderPossiblePositionSelector,
  groupPositionsSelector,
  zoomSelector,
} from '../../../../recoil/scenarioStructure';
import { groupPlaceholderHeight, groupPlaceholderMargin, groupWidth } from '../../constants';

const inputConnectionTopOffset = 30;

interface IGroupPlaceholderProps {
  sourceEntity: EntitySchema;
  sourcePosition: IOutputConnectionPosition;
  targetPosition: IInputConnectionPosition;
}

const GroupPlaceholder: React.FC<IGroupPlaceholderProps> = ({ sourceEntity, sourcePosition, targetPosition }) => {
  const dragTarget = useRecoilValue(dragTargetSelector);
  const [groupPlaceholderPosition, setGroupPlaceholderPosition] = useRecoilState(groupPlaceholderPositionSelector);
  const [groupPlaceholderPossiblePosition, setGroupPlaceholderPossiblePosition] = useRecoilState(
    groupPlaceholderPossiblePositionSelector
  );
  const resetGroupPlaceholderPosition = useResetRecoilState(groupPlaceholderPositionSelector);
  const resetGroupPlaceholderPossiblePosition = useResetRecoilState(groupPlaceholderPossiblePositionSelector);

  const [groupPositions, setGroupPositions] = useState([] as IPosition[]);
  const [possiblePositions, setPossiblePositions] = useState([] as IPosition[]);

  const onTargetPositionChange = () => {
    const sourcePositionX = sourcePosition?.positionX || 0;
    const targetPositionX = targetPosition?.positionX || 0;
    const targetPositionY = targetPosition?.positionY || 0;
    const targetAltPositionX = targetPosition?.altPositionX || 0;
    const targetAltPositionY = targetPosition?.altPositionY || 0;

    const outputOffsetX = getOutputOffsetX(sourceEntity);
    const isAltTarget = isAlternateTarget(sourcePositionX, targetPositionX, outputOffsetX);

    const x = isAltTarget ? targetAltPositionX : targetPositionX;
    const y = isAltTarget ? targetAltPositionY : targetPositionY;

    // NOTE: эмулируем смещения, которые соответствуют классу .group-container > .input-connection
    const offsetX = isAltTarget ? -groupWidth - 1 : 1;
    const offsetY = isAltTarget ? -groupPlaceholderHeight / 2 : -inputConnectionTopOffset - 2;

    const position: IPosition = {
      positionX: x + offsetX,
      positionY: y + offsetY,
      width: groupWidth,
      height: groupPlaceholderHeight,
    };

    for (const possiblePosition of possiblePositions) {
      if (positionsOverlap(position, possiblePosition)) {
        setGroupPlaceholderPosition(position);
        setGroupPlaceholderPossiblePosition(possiblePosition);
        return;
      }
    }

    for (const groupPosition of groupPositions) {
      if (positionsOverlap(position, groupPosition)) {
        resetGroupPlaceholderPosition();
        resetGroupPlaceholderPossiblePosition();
        return;
      }
    }

    setGroupPlaceholderPosition(position);
    resetGroupPlaceholderPossiblePosition();
  };
  useEffect(onTargetPositionChange, [targetPosition, groupPositions]);

  const updateGroupPositions = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { snapshot } = callbackHelpers;

    const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);
    const zoom = await snapshot.getPromise(zoomSelector);

    if (!scenarioStructure) return;

    const groupIds = [scenarioStructure.triggerGroup.id, ...scenarioStructure.actionGroups.map((g) => g.id)];
    const groupPositions = [] as IPosition[];
    const possiblePositions = [] as IPosition[];

    for (const groupId of groupIds) {
      const groupRect = getElementRect(groupId);
      if (!groupRect) continue;

      const groupPosition = await snapshot.getPromise(groupPositionsSelector(groupId));
      const groupHeight = groupRect.height / zoom;

      groupPositions.push({
        positionX: groupPosition.positionX,
        positionY: groupPosition.positionY,
        width: groupWidth,
        height: groupHeight,
      });
    }

    // NOTE: добавляем вокруг группы возможные места для плейсхолдера (справа, снизу и слева)
    for (const groupPosition of groupPositions) {
      const rightPossiblePosition: IPosition = {
        positionX: groupPosition.positionX + groupWidth + groupPlaceholderMargin,
        positionY: groupPosition.positionY,
        width: groupWidth,
        height: groupPlaceholderHeight,
      };
      if (!positionOverlapWithSome(rightPossiblePosition, groupPositions)) {
        possiblePositions.push(rightPossiblePosition);
      }

      const bottomPossiblePosition: IPosition = {
        positionX: groupPosition.positionX,
        positionY: groupPosition.positionY + (groupPosition.height || 0) + groupPlaceholderMargin,
        width: groupWidth,
        height: groupPlaceholderHeight,
      };
      if (!positionOverlapWithSome(bottomPossiblePosition, groupPositions)) {
        possiblePositions.push(bottomPossiblePosition);
      }

      const leftPossiblePosition: IPosition = {
        positionX: groupPosition.positionX - groupWidth - groupPlaceholderMargin,
        positionY: groupPosition.positionY,
        width: groupWidth,
        height: groupPlaceholderHeight,
      };
      if (!positionOverlapWithSome(leftPossiblePosition, groupPositions)) {
        possiblePositions.push(leftPossiblePosition);
      }
    }

    setGroupPositions(groupPositions);
    setPossiblePositions(possiblePositions);
  });

  const onComponentCreate = () => {
    updateGroupPositions().then();
    return () => {
      resetGroupPlaceholderPosition();
      resetGroupPlaceholderPossiblePosition();
    };
  };
  useEffect(onComponentCreate, []);

  if (!groupPlaceholderPosition || dragTarget) return null;

  return (
    <>
      <div
        className="sb-binding-group-placeholder"
        style={{ top: groupPlaceholderPosition.positionY, left: groupPlaceholderPosition.positionX }}
      >
        <div className="sb-binding-group-placeholder__header" />
        <div className="sb-binding-group-placeholder__content" />
      </div>
      {groupPlaceholderPossiblePosition && (
        <div
          className="sb-binding-group-placeholder-possible"
          style={{
            top: groupPlaceholderPossiblePosition.positionY,
            left: groupPlaceholderPossiblePosition.positionX,
          }}
        />
      )}
    </>
  );
};

export default memo(GroupPlaceholder);
