import { ScenarioEventCountModel } from '../../../api';

export const scenarioEventCountModelsAreEqual = (a: ScenarioEventCountModel, b: ScenarioEventCountModel): boolean =>
  a.scenarioName === b.scenarioName && a.scenarioStructureId === b.scenarioStructureId;

export const onlyUniqueScenarioEvent = (
  s: ScenarioEventCountModel,
  i: number,
  array: ScenarioEventCountModel[]
): boolean => array.findIndex((a) => scenarioEventCountModelsAreEqual(s, a)) === i;

export const ChannelNames = {
  directline: 'Веб-чат',
  livechat: 'Live Chat',
  emulator: 'Эмулятор',
  telegram: 'Telegram',
  viber: 'Viber',
  whatsapp: 'WhatsApp',
  elma365_telegram: 'ELMA365 (Telegram)',
  elma365_telegram_bot: 'ELMA365 (Telegram Bot)',
  elma365_viberbot: 'ELMA365 (Viber)',
  elma365_instagram: 'ELMA365 (Instagram)',
  elma365_vkontakte: 'ELMA365 (ВКонтакте)',
  elma365_whatsapp: 'ELMA365 (WhatsApp)',
  elma365_quick: 'ELMA365 (Quick)',
} as { [x: string]: string };
