import React, { memo } from 'react';

import Messages from '../../Messages';
import { ConfirmTransitSchema, DefaultActionGroupSchema, SchemaKind } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import ActionButton from '../../ActionButton';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import InputVariable from '../../InputVariable';

interface ConfirmTransitProps {
  index: number;
  action: ConfirmTransitSchema;
  group: DefaultActionGroupSchema;
}

const ConfirmTransit: React.FC<ConfirmTransitProps> = ({ action, index, group }) => {
  // NOTE: здесь не даем удалять и добавлять варианты

  return (
    <ActionWrapper
      showInputActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="checklist" size={20} />}
      index={index}
      title="Вопрос. Да/Нет"
    >
      <Messages action={action} group={group} placeholder="Добавьте текст вопроса" />
      <div className="user-answer">
        <div className="user-answer__title">
          <SbIcon iconName="user" size={16} />
          <span>Ответ пользователя:</span>
        </div>
        {action.buttons.map((button) => (
          <ActionButton key={button.id} button={button} group={group} parentKind={SchemaKind.ConfirmTransit} />
        ))}
      </div>
      <InputVariable action={action} />
    </ActionWrapper>
  );
};

export default memo(ConfirmTransit);
