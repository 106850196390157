import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';

import { InboxOperatorGroupOperatorModel, InboxParticipantModel } from '../../../../api';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import IbPopover from '../common/IbPopover';
import IbSpin from '../common/IbSpin';
import IbAvatar from '../common/IbAvatar';

import {
  ANIMATION_DELAY,
  OPERATOR_LIST_CLASS_NAME,
  OPERATOR_LIST_EMPTY_LIST_ENTRY_CLASS_NAME,
  OPERATOR_LIST_ITEM_CHECKED_CLASS_NAME,
  OPERATOR_LIST_POPOVER_CLASS_NAME,
  OPERATOR_LIST_SPIN_WRAPPER_CLASS_NAME,
  SEARCH_DELAY,
} from './const';

interface IAddOperatorsButtonProps {
  operatorList?: InboxParticipantModel[];
  operatorListLoading?: boolean;
  currentCheckedOperators?: InboxOperatorGroupOperatorModel[];
  onPopoverClose: () => void;
  onOperatorsSearch: (search: string) => void;
  onOperatorListItemClick: (operator: InboxParticipantModel) => () => void;
  onCheckedOperatorsSave: () => void;
}

const AddOperatorsButton: React.FC<IAddOperatorsButtonProps> = ({
  operatorList,
  operatorListLoading,
  currentCheckedOperators,
  onPopoverClose,
  onOperatorsSearch,
  onOperatorListItemClick,
  onCheckedOperatorsSave,
}) => {
  const { t } = useTranslation();

  const [operatorsPopoverVisible, setOperatorsPopoverVisible] = useState(false);
  const [operatorsSearchText, setOperatorsSearchText] = useState('');

  const debouncedOperatorsSearchText = useDebounce(operatorsSearchText, SEARCH_DELAY);
  const deferredOperatorsSearchText = operatorsSearchText ? debouncedOperatorsSearchText : '';

  const clearOperatorsSearchContext = () => {
    // NOTE: сбрасываем только после того, как сработает анимация закрытия всплывыющего меню
    setTimeout(() => {
      setOperatorsSearchText('');
    }, ANIMATION_DELAY);
  };

  const onOperatorsPopoverVisibleChange = (visible?: boolean) => {
    if (visible) return;

    setOperatorsPopoverVisible(visible || false);
    clearOperatorsSearchContext();
    onPopoverClose();
  };

  const onOperatorsBlockClick = () => setOperatorsPopoverVisible(true);

  const onOperatorsSearchChange = (value: string) => setOperatorsSearchText(value);

  const onOperatorsDoneButtonClick = () => {
    setOperatorsPopoverVisible(false);
    onCheckedOperatorsSave();
    clearOperatorsSearchContext();
  };

  const onDeferredOperatorsSearchTextChange = () => {
    onOperatorsSearch(deferredOperatorsSearchText);
  };
  useEffect(onDeferredOperatorsSearchTextChange, [deferredOperatorsSearchText]);

  const renderLoader = () => {
    return (
      <div className={OPERATOR_LIST_SPIN_WRAPPER_CLASS_NAME}>
        <IbSpin />
      </div>
    );
  };

  const renderOperatorEmptyList = () => {
    return (
      <div className={OPERATOR_LIST_EMPTY_LIST_ENTRY_CLASS_NAME}>
        <IbAvatar iconName="headset-one" size="x-small" />
        <IbTypography.Paragraph disabled type="secondary">
          {t('No operators found')}
        </IbTypography.Paragraph>
      </div>
    );
  };

  const renderOperatorList = () => {
    return (
      <ul>
        {operatorList?.map((operator) => (
          <li
            key={operator.id}
            className={
              currentCheckedOperators?.some((o) => o.operator.id === operator.subject.id)
                ? OPERATOR_LIST_ITEM_CHECKED_CLASS_NAME
                : ''
            }
          >
            <IbTypography onClick={onOperatorListItemClick(operator)}>
              <IbIcon iconName="check-small" size={20} />
              <IbAvatar
                metadata={{ uid: operator.subject.id, text: operator.subject.person.name.fullName || '' }}
                size="x-small"
              />
              <IbTypography.Paragraph type="secondary">{operator.subject.person.name.fullName}</IbTypography.Paragraph>
            </IbTypography>
          </li>
        ))}
      </ul>
    );
  };

  const renderOperatorListContent = () => (
    <div className={OPERATOR_LIST_CLASS_NAME}>
      <IbInput placeholder={t('Operators search')} value={operatorsSearchText} onChange={onOperatorsSearchChange} />
      {operatorListLoading ? renderLoader() : operatorList?.length ? renderOperatorList() : renderOperatorEmptyList()}
      <IbButton type="fill" onClick={onOperatorsDoneButtonClick}>
        {t('Done')}
      </IbButton>
    </div>
  );

  return (
    <IbPopover
      className={OPERATOR_LIST_POPOVER_CLASS_NAME}
      content={renderOperatorListContent()}
      placement="rightTop"
      trigger="click"
      visible={operatorsPopoverVisible}
      onVisibleChange={onOperatorsPopoverVisibleChange}
    >
      <IbButton icon={<IbIcon iconName="add-one" />} type="link" onClick={onOperatorsBlockClick} />
    </IbPopover>
  );
};

export default AddOperatorsButton;
