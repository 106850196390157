import React from 'react';
import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';

import './index.less';

import SbIcon from '../SbIcon';

type ISbCollapseProps = CollapseProps;

const SbCollapse: React.FC<ISbCollapseProps> = ({ children, className, ...otherProps }) => {
  const classes = ['sb-collapse'];
  className && classes.push(className);
  return (
    <Collapse
      accordion
      expandIcon={({ isActive }) => <SbIcon iconName={isActive ? 'up' : 'down'} />}
      expandIconPosition="right"
      {...otherProps}
      className={classes.join(' ')}
    >
      {children}
    </Collapse>
  );
};

type ISbCollapsePanelProps = CollapsePanelProps;

const SbCollapsePanel: React.FC<ISbCollapsePanelProps> = ({ children, className, ...otherProps }) => {
  const classes = ['sb-collapse__panel'];
  className && classes.push(className);
  return (
    <Collapse.Panel {...otherProps} className={classes.join(' ')}>
      {children}
    </Collapse.Panel>
  );
};

export { SbCollapsePanel };
export default SbCollapse;
