import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

const TerminalTrigger: React.FC = () => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="handle-x" size={16} />
      Триггер завершения
    </div>
  );
};

export default TerminalTrigger;
