import React from 'react';

import './index.less';

import IbModal from '../common/IbModal';
import IbTypography from '../common/IbTypography';
import IbIcon from '../common/IbIcon';
import IbSpin from '../common/IbSpin';

import {
  CONTENT_CLASS_NAME,
  CONTENT_HEADER_CLASS_NAME,
  CONTENT_SPIN_CLASS_NAME,
  MAIN_CLASS_NAME,
  CONTENT_ICON_CLASS_NAME,
  CONTENT_ICON_SUCCESS_CLASS_NAME,
  CONTENT_ICON_ERROR_CLASS_NAME,
} from './const';

export enum IbProgressStatusModalStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Error = 'Error',
}

const HEADER_DEFAULT = undefined;
const VISIBLE_DEFAULT = false;
const CLOSABLE_DEFAULT = true;
const STATUS_DEFAULT = IbProgressStatusModalStatus.InProgress;

export interface IIbProgressStatusModalProps {
  header?: string;
  visible?: boolean;
  closable?: boolean;
  status?: IbProgressStatusModalStatus;
  onCancel?: () => void;
}

const IbProgressStatusModal: React.FC<IIbProgressStatusModalProps> = ({
  children,
  header = HEADER_DEFAULT,
  visible = VISIBLE_DEFAULT,
  closable = CLOSABLE_DEFAULT,
  status = STATUS_DEFAULT,
  onCancel,
}) => {
  const iconClasses = [CONTENT_ICON_CLASS_NAME];

  switch (status) {
    case IbProgressStatusModalStatus.Success:
      iconClasses.push(CONTENT_ICON_SUCCESS_CLASS_NAME);
      break;
    case IbProgressStatusModalStatus.Error:
      iconClasses.push(CONTENT_ICON_ERROR_CLASS_NAME);
      break;
  }

  const renderStatusVisual = () => {
    switch (status) {
      case IbProgressStatusModalStatus.InProgress:
        return <IbSpin className={CONTENT_SPIN_CLASS_NAME} />;
      case IbProgressStatusModalStatus.Success:
        return <IbIcon className={iconClasses.join(' ')} iconName="check-one" size={64} />;
      case IbProgressStatusModalStatus.Error:
        return <IbIcon className={iconClasses.join(' ')} iconName="attention" size={64} />;
    }
  };

  return (
    <IbModal className={MAIN_CLASS_NAME} closable={closable} visible={visible} onCancel={onCancel}>
      <div className={CONTENT_CLASS_NAME}>
        {renderStatusVisual()}
        <IbTypography>
          <IbTypography.Paragraph className={CONTENT_HEADER_CLASS_NAME}>
            <h2>{header}</h2>
          </IbTypography.Paragraph>
        </IbTypography>
        {children}
      </div>
    </IbModal>
  );
};

export default IbProgressStatusModal;
