import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Space, Button, Typography } from 'antd';
import { v4 } from 'uuid';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountCreationRequest } from '../../../../api';
import { AlertTypes, Channels, FORM_FIELD_NAMES, LIVE_CHAT_SETTINGS_DEFAULT } from '../../../constants';
import { agentStageAccountApi } from '../../../apis';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Title } = Typography;

const LiveChatChannelAdd: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onFinish = async () => {
    try {
      setLoading(true);
      const request: AgentStageAccountCreationRequest = {
        agentStageId: id,
        displayName: form.getFieldValue(FORM_FIELD_NAMES.displayName),
        authToken: v4(),
        channelId: Channels.LIVECHAT,
        properties: {
          settings: LIVE_CHAT_SETTINGS_DEFAULT,
        },
      };

      await agentStageAccountApi.createAgentStageAccount(request);

      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлен канал',
        description: 'LiveChat',
      });
      goBack();
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();
      setError(errorText);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item help={error} validateStatus={error ? 'error' : undefined}>
          <Title level={4}>Добавление канала LiveChat</Title>
        </Form.Item>
        <Form.Item label="Отображаемое наименование" name={FORM_FIELD_NAMES.displayName}>
          <Input placeholder="Отображаемое наименование канала" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={goBack}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LiveChatChannelAdd;
