import React from 'react';

import './index.less';

const MAIN_CLASS_NAME = 'ib-no-selected-chat';

const NoSelectedChat: React.FC = () => {
  return (
    <div className={MAIN_CLASS_NAME}>
      <p>Выберите чат</p>
    </div>
  );
};

export default NoSelectedChat;
