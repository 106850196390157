import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbPopover from '../common/IbPopover';
import IbTypography from '../common/IbTypography';
import IbIcon from '../common/IbIcon';
import IbSocial, { IbSocialType } from '../common/IbSocial';
import IbButton from '../common/IbButton';
import { IChannelData } from '../IbContactChannelList';

const MAIN_CLASS_NAME = 'ib-contact-channel-selector';
const POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__popover`;
const TYPOGRAPHY_CLASS_NAME = `${MAIN_CLASS_NAME}__typography`;
const TYPOGRAPHY_EMPTY_CLASS_NAME = `${TYPOGRAPHY_CLASS_NAME}_empty`;
const POPOVER_EXPAND_ICON_CLASS_NAME = `${TYPOGRAPHY_CLASS_NAME}__popover-expand-icon`;
const POPOVER_EXPAND_ICON_MOBILE_CLASS_NAME = `${POPOVER_EXPAND_ICON_CLASS_NAME}_mobile`;
const CHANNEL_LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__channel-list`;

export interface IIbContactChannelSelectorProps {
  channels: IChannelData[];
  selectedChannel?: IChannelData;
  onSendMessageToChat?: (chatId: string) => void;
}

const IbContactChannelSelector: React.FC<IIbContactChannelSelectorProps> = ({
  channels,
  selectedChannel,
  onSendMessageToChat,
}) => {
  const { t } = useTranslation();

  const [popoverVisible, setPopoverVisible] = useState(false);
  const [currentSelectedChatId, setCurrentSelectedChatId] = useState(selectedChannel?.chatId);

  const mostRecentChannel = channels[0];

  const onPopoverVisibleChange = (value?: boolean) => setPopoverVisible(!!value);

  const onChannelListItemClick = (chatId: string) => () => setCurrentSelectedChatId(chatId);

  const onWriteToChannelButtonClick = () => {
    if (!currentSelectedChatId) {
      return;
    }

    onSendMessageToChat?.(currentSelectedChatId);
  };

  const renderChannelItem = (channel: IChannelData) => {
    return (
      <li key={channel.chatId}>
        <IbTypography onClick={onChannelListItemClick(channel.chatId)}>
          <IbSocial social={channel.channelId as IbSocialType} />
          <div>
            <IbTypography.Paragraph type="secondary">{channel.name}</IbTypography.Paragraph>
            {!!channel.activeOn && (
              <IbTypography.Paragraph disabled type="secondary">
                {channel.activeOn}
              </IbTypography.Paragraph>
            )}
          </div>
          {currentSelectedChatId === channel.chatId ? <IbIcon iconName="check-one" size={20} /> : null}
        </IbTypography>
      </li>
    );
  };

  const renderChannelList = () => {
    return <ul>{channels.map(renderChannelItem)}</ul>;
  };

  const renderPopoverContent = () => {
    return (
      <div className={CHANNEL_LIST_CLASS_NAME}>
        {renderChannelList()}
        <IbButton
          disabled={!currentSelectedChatId}
          icon={<IbIcon iconName="send" />}
          type="fill"
          onClick={onWriteToChannelButtonClick}
        >
          {t('Send message')}
        </IbButton>
      </div>
    );
  };

  const renderPopover = () => {
    return (
      <IbPopover
        className={POPOVER_CLASS_NAME}
        content={renderPopoverContent()}
        placement="bottomRight"
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={onPopoverVisibleChange}
      >
        <IbTypography className={TYPOGRAPHY_CLASS_NAME}>
          <IbSocial social={mostRecentChannel.channelId as IbSocialType} />
          <div>
            <IbTypography.Paragraph type="secondary">{mostRecentChannel.name}</IbTypography.Paragraph>
            {!!mostRecentChannel.activeOn && (
              <IbTypography.Paragraph disabled type="secondary">
                {mostRecentChannel.activeOn}
              </IbTypography.Paragraph>
            )}
          </div>
          <IbIcon className={POPOVER_EXPAND_ICON_CLASS_NAME} iconName="down" size={20} />
          <IbIcon className={POPOVER_EXPAND_ICON_MOBILE_CLASS_NAME} iconName="right" size={20} />
        </IbTypography>
      </IbPopover>
    );
  };

  const renderEmptyContent = () => {
    return (
      <IbTypography className={`${TYPOGRAPHY_CLASS_NAME} ${TYPOGRAPHY_EMPTY_CLASS_NAME}`}>
        <IbTypography.Paragraph disabled type="secondary">
          {t('No active channels')}
        </IbTypography.Paragraph>
      </IbTypography>
    );
  };

  return <div className={MAIN_CLASS_NAME}>{channels.length ? renderPopover() : renderEmptyContent()}</div>;
};

export default IbContactChannelSelector;
