import React, { memo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Menu } from 'antd';

import Messages from '../../Messages';
import {
  ButtonType,
  ChoiceTransitSchema,
  DefaultActionGroupSchema,
  SchemaKind,
  SuggestionInputSchema,
  SuggestionSchema,
} from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import { currentScenarioStructureSelector, selectedEntitySelector } from '../../../../../recoil/scenarioStructure';
import { generateId, getElementId, tryGetElementById } from '../../../utils';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import AddButton from '../../common/AddButton';
import EditorSelect from '../../common/EditorSelect';
import SuggestionButton from '../../SuggestionButton';
import InputVariable from '../../InputVariable';

interface ISuggestionInputProps {
  index: number;
  action: SuggestionInputSchema;
  group: DefaultActionGroupSchema;
}

const SuggestionInput: React.FC<ISuggestionInputProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const setSelectedEntity = useSetRecoilState(selectedEntitySelector);

  const onDeleteSuggestion = (suggestion: SuggestionSchema) => () => {
    if (!scenarioStructure) return;

    const newSuggestions = action.suggestions.filter((s) => s.id !== suggestion.id);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id) as SuggestionInputSchema;
    foundAction.suggestions = newSuggestions;

    setScenarioStructure(newScenarioStructure);
  };

  const addButtonHandler = () => {
    if (!scenarioStructure) return;

    const newSuggestions = [...action.suggestions];
    const newSuggestion: SuggestionSchema = {
      id: generateId('SGG'),
      $kind: SchemaKind.Suggestion,
      caption: 'Новый вариант',
    };
    newSuggestions.push(newSuggestion);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id) as SuggestionInputSchema;
    foundAction.suggestions = newSuggestions;

    setSelectedEntity(newSuggestion);
    setScenarioStructure(newScenarioStructure);
  };

  const onChoiceTransitSelect = () => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id);

    const choiceTransitAction = foundAction as ChoiceTransitSchema;
    const suggestionInputAction = foundAction as SuggestionInputSchema;

    choiceTransitAction.$kind = SchemaKind.ChoiceTransit;
    choiceTransitAction.buttons = suggestionInputAction.suggestions.map((s) => ({
      id: s.id,
      $kind: SchemaKind.Button,
      $designer: s.$designer,
      value: s.caption,
      synonyms: [],
      outputBindingId: null,
      type: ButtonType.Transit,
    }));
    // todo: надо ли сбрасывать валидаторы?
    choiceTransitAction.validators = [];
    suggestionInputAction.suggestions = [];

    setScenarioStructure(newScenarioStructure);
  };

  const typeSelectorTitle = 'Быстрый ответ';
  const typeSelectorMenuContent = (
    <Menu defaultSelectedKeys={[SchemaKind.SuggestionInput]}>
      <Menu.Item key={SchemaKind.ChoiceTransit} onClick={onChoiceTransitSelect}>
        Ответ-развилка (Кнопки)
      </Menu.Item>
      <Menu.Item key={SchemaKind.SuggestionInput}>{typeSelectorTitle}</Menu.Item>
    </Menu>
  );

  return (
    <ActionWrapper
      showInputActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="help" size={20} />}
      index={index}
      title="Вопрос. Текст + кнопки"
    >
      <Messages action={action} group={group} placeholder="Добавьте текст вопроса" />
      <div className="user-answer">
        <div className="user-answer__title">
          <SbIcon iconName="user" size={16} />
          <span>Ответ пользователя:</span>
        </div>
        <EditorSelect
          isValid
          id={getElementId(action.id, 'type')}
          menuContent={typeSelectorMenuContent}
          title={typeSelectorTitle}
        />
        {action.suggestions.map((suggestion) => (
          <SuggestionButton key={suggestion.id} suggestion={suggestion} onDelete={onDeleteSuggestion(suggestion)} />
        ))}
        <AddButton buttonHandler={addButtonHandler} title="Добавить быстрый ответ" />
      </div>
      <InputVariable action={action} />
    </ActionWrapper>
  );
};

export default memo(SuggestionInput);
