import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, PageHeader, Row, Spin, Typography } from 'antd';
import { useHistory } from 'react-router';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import './index.less';

import { AgentCreationMode, AgentCreationRequest, BotTemplateModel } from '../../../../api';
import { agentApi, botTemplatesApi } from '../../../apis';
import { AlertTypes } from '../../../constants';
import { alertsSelectorAdd } from '../../../recoil/alerts';

import WizardCreationForm from './WizardCreationForm';
import ImportZipCreationForm from './ImportZipCreationForm';

const cardStyle = { height: 215, width: 293, margin: 24 };
const { Paragraph } = Typography;

const AgentAdd: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { push } = useHistory();

  const [wizardVisible, setWizardVisible] = useState(false);
  const [importZipVisible, setImportZipVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [botTemplates, setBotTemplates] = useState<BotTemplateModel[]>([]);

  const createBot = async (request: AgentCreationRequest): Promise<boolean> => {
    if (loading) return false;

    setLoading(true);
    try {
      const response = await agentApi.createAgent(request);
      push(`/agents/${response.data.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении бота',
        error: e,
      });
      setLoading(false);
      return false;
    }

    return true;
  };

  const loadTemplatesAsync = async () => {
    setLoading(true);
    try {
      const response = await botTemplatesApi.getBotTemplates();
      setBotTemplates(response.data || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка шаблонов ботов',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadTemplates = () => {
    loadTemplatesAsync();
  };
  useEffect(loadTemplates, []);

  const onWizardFormSubmit = async (request: AgentCreationRequest): Promise<boolean> => {
    return await createBot(request);
  };

  const onImportZipFormSubmit = async (request: AgentCreationRequest): Promise<boolean> => {
    return await createBot(request);
  };

  const onCreateByWizardButtonClick = () => {
    setWizardVisible(true);
  };

  const onImportZipButtonClick = () => {
    setImportZipVisible(true);
  };

  const onBotTemplateSelect = async (botTemplate: BotTemplateModel) => {
    const request = {
      name: botTemplate.name,
      templateCode: botTemplate.code,
      creationMode: AgentCreationMode.NUMBER_1,
    } as AgentCreationRequest;
    await createBot(request);
  };

  const onFormCancel = () => {
    setWizardVisible(false);
    setImportZipVisible(false);
  };

  return (
    <Fragment>
      <PageHeader title="Создать бота" />
      <Spin spinning={loading}>
        {wizardVisible ? (
          <WizardCreationForm onCancel={onFormCancel} onSubmit={onWizardFormSubmit} />
        ) : importZipVisible ? (
          <ImportZipCreationForm onCancel={onFormCancel} onSubmit={onImportZipFormSubmit} />
        ) : (
          <Row wrap>
            <Col key="add-bot-button" flex="auto" style={cardStyle}>
              <Button style={{ height: '100%', width: '100%' }} type="dashed" onClick={onCreateByWizardButtonClick}>
                <PlusOutlined /> Создать с нуля
              </Button>
            </Col>
            <Col key="create-from-zip" flex="auto" style={cardStyle}>
              <Button style={{ height: '100%', width: '100%' }} type="dashed" onClick={onImportZipButtonClick}>
                <ImportOutlined /> Импорт из .zip
              </Button>
            </Col>
            {botTemplates.map((item, index) => (
              <Col key={item.code || index} flex="auto" style={cardStyle}>
                <Card hoverable style={{ height: '100%' }} onClick={() => onBotTemplateSelect(item)}>
                  <Card.Meta
                    description={
                      <Fragment>
                        <div>
                          <Button style={{ padding: 0 }} type="link">
                            {item.name}
                          </Button>
                        </div>
                        <div>
                          <Button disabled style={{ cursor: 'pointer', padding: 0, border: 'none' }} type="link">
                            Шаблон
                          </Button>
                          <Paragraph>{item.description}</Paragraph>
                        </div>
                      </Fragment>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Spin>
    </Fragment>
  );
};

export default AgentAdd;
