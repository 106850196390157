import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from '../../types';

export const UnknownDefaultIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          clipRule="evenodd"
          d="M8.33203 0C5.57061 0 3.33203 2.23858 3.33203 5V35C3.33203 37.7614 5.57061 40 8.33203 40H31.6654C34.4268 40 36.6654 37.7614 36.6654 35V5C36.6654 4.94547 36.6645 4.89115 36.6628 4.83704V6.66667L29.9961 0H8.33203Z"
          fill="#EDFAFF"
          fillRule="evenodd"
        />
        <path clipRule="evenodd" d="M30 0V6.66667H36.6667L30 0Z" fill="#C2CDDE" fillRule="evenodd" />
        <path
          d="M14.1654 28.3337H25.832C26.2923 28.3337 26.6654 27.9606 26.6654 27.5003V15.8337H22.4987V11.667H14.1654C13.7051 11.667 13.332 12.0401 13.332 12.5003V27.5003C13.332 27.9606 13.7051 28.3337 14.1654 28.3337Z"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M22.5 11.667L26.6667 15.8337"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M17 22H19.9167H22.8333"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M17 19H19.9167H22.8333"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M17 25H19.9167H22.8333"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="40" transform="translate(3.33594)" width="33.3333" />
        </clipPath>
      </defs>
    </svg>
  );
});
