import React from 'react';
import { Modal, ModalProps } from 'antd';

import './index.less';

import SbIcon from '../SbIcon';

const PADDING_HEIGHT = 60;

interface ISbModalProps extends ModalProps {
  sbSize: 'large' | 'small';
  height?: number;
}

const SbModal: React.FC<ISbModalProps> = ({ sbSize, height, className, children, ...otherProps }) => {
  const classes = ['sb-modal', `sb-modal_${sbSize}`];
  if (className) {
    classes.push(className);
  }
  return (
    <Modal
      maskClosable={false}
      {...otherProps}
      bodyStyle={
        height
          ? {
              height: height - PADDING_HEIGHT,
            }
          : undefined
      }
      className={classes.join(' ')}
      closeIcon={<SbIcon iconName="close" size={21} />}
    >
      {children}
    </Modal>
  );
};

export default SbModal;
