import React, { ChangeEventHandler } from 'react';
import Search from 'antd/es/input/Search';

import './index.less';

import SbIcon from '../SbIcon';

type SbSearchProps = {
  sbSize: 'big' | 'small';
  placeholder?: string;
  value?: string;
  isLoading?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
} & (
  | // NOTE: должно быть задано свойство onSearch или свойство onClear, но не оба одновременно
  {
      onSearch: (
        value: string,
        event?:
          | React.ChangeEvent<HTMLInputElement>
          | React.MouseEvent<HTMLElement>
          | React.KeyboardEvent<HTMLInputElement>
      ) => void;
      onClear?: never;
    }
  | { onSearch?: never; onClear: () => void }
);

const SbSearch: React.FC<SbSearchProps> = ({ sbSize, placeholder, value, isLoading, onChange, onSearch, onClear }) => {
  const showSearchIcon = !value || !!onSearch;
  const iconName = isLoading ? 'loading-four' : showSearchIcon ? 'search' : 'handle-x';
  const onIconClick = () => iconName === 'handle-x' && onClear?.();

  return (
    <Search
      className={`sb-search sb-search-${sbSize}`}
      enterButton={<SbIcon iconName={iconName} size={sbSize === 'big' ? 28 : 16} onClick={onIconClick} />}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
    />
  );
};

export default SbSearch;
