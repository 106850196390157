/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Key, ReactNode, useState } from 'react';
import { Table } from 'antd';
import { ColumnType, ExpandableConfig, TableRowSelection } from 'antd/lib/table/interface';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbCheckbox from '../IbCheckbox';
import IbButton from '../IbButton';
import IbIcon from '../IbIcon';
import IbSpin from '../IbSpin';
import IbTypography from '../IbTypography';

const EXPAND_ICON_COLUMN_INDEX = Number.MAX_VALUE;

const DATA_SOURCE_DEFAULT = [] as any[];
const COLUMNS_DEFAULT = [] as ColumnType<any>[];
const ROW_KEY_DEFAULT = 'id';
const SKELETON_ROW_COUNT = 4;
const SCROLL_THRESHOLD = '80px';

const MAIN_CLASS_NAME = 'ib-table';
const HEADER_CLASS_NAME = `${MAIN_CLASS_NAME}__header`;
const BODY_WRAPPER_CLASS_NAME = `${MAIN_CLASS_NAME}__body-wrapper`;
const SPIN_CLASS_NAME = `${BODY_WRAPPER_CLASS_NAME}__spin`;
const LAST_ROW_CLASS_NAME = `${MAIN_CLASS_NAME}__last-row`;
const LAST_EXPANDED_ROW_CLASS_NAME = `${MAIN_CLASS_NAME}__last-expanded-row`;
const ROW_EXPANDED_CLASS_NAME = `${MAIN_CLASS_NAME}__row-expanded`;
const ROW_CLICK_ACTIVE_CLASS_NAME = `${MAIN_CLASS_NAME}__row-click-active`;
const SKELETON_CLASS_NAME = `${MAIN_CLASS_NAME}__skeleton`;
const SKELETON_ROW_CLASS_NAME = `${SKELETON_CLASS_NAME}__row`;
export const MOBILE_MAIN_CELL_CLASS_NAME = `${MAIN_CLASS_NAME}__mobile-main-cell`;
export const MOBILE_ACTIONS_CELL_CLASS_NAME = `${MAIN_CLASS_NAME}__mobile-actions-cell`;

const getRowKey = (row: any, rowKey: string | ((row: any) => string)) =>
  typeof rowKey === 'function' ? rowKey(row) : row[rowKey];

export interface IIbTableScrollProps {
  next: () => void;
  hasMore: boolean;
  dataLength: number;
}

export interface IIbTableProps {
  className?: string;
  dataSource?: any[];
  columns?: ColumnType<any>[];
  rowKey?: string | ((data: any) => string);
  selectedRowKeys?: Key[];
  expandableForMobile?: ExpandableConfig<any>;
  loading?: boolean;
  scroll?: IIbTableScrollProps;
  emptyText?: ReactNode;
  onSelectedRowKeysChange?: (selectedRowKeys: Key[]) => void;
  onRowClick?: (data: any, column: ColumnType<any>) => void;
  onRowMouseDown?: (data: any, column: ColumnType<any>) => void;
}

const IbTable: React.FC<IIbTableProps> = ({
  className,
  dataSource = DATA_SOURCE_DEFAULT,
  columns = COLUMNS_DEFAULT,
  rowKey = ROW_KEY_DEFAULT,
  selectedRowKeys,
  expandableForMobile,
  loading,
  scroll,
  emptyText,
  onSelectedRowKeysChange,
  onRowClick,
  onRowMouseDown,
}) => {
  dataSource = getDefaultIfUndefined(dataSource, DATA_SOURCE_DEFAULT);
  columns = getDefaultIfUndefined(columns, COLUMNS_DEFAULT);
  rowKey = getDefaultIfUndefined(rowKey, ROW_KEY_DEFAULT);
  selectedRowKeys = getDefaultIfUndefined(selectedRowKeys, undefined);
  expandableForMobile = getDefaultIfUndefined(expandableForMobile, undefined);
  loading = getDefaultIfUndefined(loading, undefined);
  scroll = getDefaultIfUndefined(scroll, undefined);
  emptyText = getDefaultIfUndefined(emptyText, undefined);

  const { t } = useTranslation();
  const [scrollContainerId] = useState(v4());

  const [expandedRowKeys, setExpandedRowKeys] = useState(
    expandableForMobile?.expandedRowKeys || expandableForMobile?.defaultExpandedRowKeys || ([] as Key[])
  );

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  const preparedColumns: ColumnType<any>[] = columns.map((column) => {
    return {
      ...column,
      className: [column.className, onRowClick ? ROW_CLICK_ACTIVE_CLASS_NAME : ''].join(' '),
      onCell: (record) => ({
        onClick: () => {
          onRowClick?.(record, column);
        },
        onMouseDown: () => {
          onRowMouseDown?.(record, column);
        },
      }),
    };
  });

  const preparedExpandable: ExpandableConfig<any> | undefined = expandableForMobile
    ? {
        ...expandableForMobile,
        expandIcon: ({ expanded, onExpand, record }) => (
          <IbButton
            icon={<IbIcon iconName={expanded ? 'up' : 'down'} />}
            type="icon"
            onClick={(e) => onExpand(record, e)}
          />
        ),
        expandIconColumnIndex: EXPAND_ICON_COLUMN_INDEX,
        expandedRowClassName: (_, index) => (index + 1 === dataSource.length ? LAST_EXPANDED_ROW_CLASS_NAME : ''),
        onExpandedRowsChange: setExpandedRowKeys,
        expandedRowKeys,
      }
    : undefined;

  const onSelectChange = (record: any) => (value: boolean) => {
    if (!Array.isArray(selectedRowKeys)) {
      return;
    }

    const recordKey = getRowKey(record, rowKey);

    // NOTE: добавляем при необходимости
    if (value && !selectedRowKeys.includes(recordKey)) {
      const newSelectedRowKeys = [...selectedRowKeys];
      newSelectedRowKeys.push(recordKey);
      onSelectedRowKeysChange?.(newSelectedRowKeys);
      return;
    }

    // NOTE: удаляем при необходимости
    if (!value && selectedRowKeys.includes(recordKey)) {
      const newSelectedRowKeys = selectedRowKeys.filter((key) => key !== recordKey);
      onSelectedRowKeysChange?.(newSelectedRowKeys);
      return;
    }
  };

  const onSelectAllChange = (value: boolean) =>
    onSelectedRowKeysChange?.(value ? dataSource.map((e) => getRowKey(e, rowKey)) : []);

  const rowSelection: TableRowSelection<any> | undefined = selectedRowKeys
    ? {
        type: 'checkbox',
        renderCell: (value, record) => <IbCheckbox value={value} onChange={onSelectChange(record)} />,
        columnTitle: (
          <IbCheckbox
            value={dataSource.length > 0 && dataSource.length === selectedRowKeys.length}
            onChange={onSelectAllChange}
          />
        ),
        selectedRowKeys,
        onChange: onSelectedRowKeysChange,
      }
    : undefined;

  const rowClassNameFunc = (record: any, index: number): string => {
    const rowClasses = [];

    if (index + 1 === dataSource.length) {
      rowClasses.push(LAST_ROW_CLASS_NAME);
    }

    if (expandedRowKeys.includes(getRowKey(record, rowKey))) {
      rowClasses.push(ROW_EXPANDED_CLASS_NAME);
    }

    return rowClasses.join(' ');
  };

  const skeleton = (
    <div className={SKELETON_CLASS_NAME}>
      {Array.from(Array(SKELETON_ROW_COUNT).keys()).map((_, row) => (
        <div key={row} className={SKELETON_ROW_CLASS_NAME}>
          {columns.map((_, col) => (
            <Skeleton key={col} />
          ))}
        </div>
      ))}
    </div>
  );

  const tableBody = loading ? (
    skeleton
  ) : (
    <Table
      columns={preparedColumns}
      dataSource={dataSource}
      expandable={preparedExpandable}
      locale={{ emptyText }}
      pagination={false}
      rowClassName={rowClassNameFunc}
      rowKey={rowKey}
      rowSelection={rowSelection}
      scroll={{ x: undefined }}
    />
  );

  return (
    <div className={classes.join(' ')}>
      <Table
        className={HEADER_CLASS_NAME}
        columns={preparedColumns}
        dataSource={dataSource}
        expandable={preparedExpandable}
        pagination={false}
        rowKey={rowKey}
        rowSelection={rowSelection}
        scroll={{ x: undefined }}
      />
      <div className={BODY_WRAPPER_CLASS_NAME} id={scrollContainerId}>
        {scroll ? (
          <InfiniteScroll
            dataLength={scroll.dataLength}
            hasMore={scroll.hasMore}
            loader={
              <div className={SPIN_CLASS_NAME}>
                <IbSpin />
                <IbTypography.Paragraph disabled type="secondary">
                  {t('Loading')}
                </IbTypography.Paragraph>
              </div>
            }
            next={scroll.next}
            scrollableTarget={scrollContainerId}
            scrollThreshold={SCROLL_THRESHOLD}
          >
            {tableBody}
          </InfiniteScroll>
        ) : (
          tableBody
        )}
      </div>
    </div>
  );
};

export default IbTable;
