import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async-hook';

import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbTooltip from '../../../components/common/SbTooltip';
import { botStageApi } from '../../../../apis';
import {
  BotStageModel,
  BotStageTestingResponse,
  ScenarioEditionReferenceModel,
  ScenarioSchema,
  TrainStatus,
} from '../../../../../api';
import { AlertTypes, BOT_PUBLISH_STATUS_UPDATED } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import {
  currentScenarioStructureSelector,
  scenarioStructureStackSelectorCanRedo,
  scenarioStructureStackSelectorCanUndo,
} from '../../../../recoil/scenarioStructure';
import { hubConnections } from '../../../../utils/socketsUtil';
import SbWebChat from '../../../components/SbWebChat';

const WEBCHAT_AUTOSHOW_DELAY_MS = 5000; // 5s

enum TestButtonState {
  NotReady = 'NotReady',
  Preparing = 'Preparing',
  Ready = 'Ready',
  WebchatOpened = 'WebchatOpened',
  Error = 'Error',
}

interface ITestButtonProps {
  botName?: string;
  botStage?: BotStageModel;
  scenario?: ScenarioEditionReferenceModel;
}

const TestButton: React.FC<ITestButtonProps> = ({ botName, botStage, scenario }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const currentScenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const canUndo = useRecoilValue(scenarioStructureStackSelectorCanUndo);
  const canRedo = useRecoilValue(scenarioStructureStackSelectorCanRedo);

  const [testedScenarioStructure, setTestedScenarioStructure] = useState<ScenarioSchema>();
  const [testingState, setTestingState] = useState<BotStageTestingResponse>();
  const [testing, setTesting] = useState(false);
  const [webchatVisible, setWebchatVisible] = useState(false);
  const [testingReadyTimeout, setTestingReadyTimeout] = useState<NodeJS.Timeout>();

  const buttonCurrentState =
    testing || testingState?.agentStage.trainResult.status === TrainStatus.Pending
      ? TestButtonState.Preparing
      : webchatVisible
      ? TestButtonState.WebchatOpened
      : !testedScenarioStructure || testedScenarioStructure !== currentScenarioStructure
      ? TestButtonState.NotReady
      : testingState?.agentStage.trainResult.status === TrainStatus.Error
      ? TestButtonState.Error
      : TestButtonState.Ready;

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const loadTestingState = async () => {
    if (!botStage || !scenario) return;

    try {
      const response = await botStageApi.getScenarioTestingState(botStage.id, scenario.scenarioStructureId);
      setTestingState(response.data);
    } catch {
      // NOTE: если произошла ошибка, то скорей всего тестирование еще не запускалось
    }
  };

  const onCurrentScenarioStructureInit = () => {
    if (botStage && scenario && !canRedo && !canUndo && currentScenarioStructure) {
      loadTestingState().finally();
    }
  };
  useEffect(onCurrentScenarioStructureInit, [canUndo, canRedo, currentScenarioStructure]);

  const botPublishEventHandler = (args: { agentStageId: string }) => {
    if (testingState?.agentStage.id === args?.agentStageId) {
      loadTestingState().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
    return () => conn.off(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
  };
  useEffect(subscribe, [conn, testingState]);

  const onTestingStateChange = () => {
    if (testingReadyTimeout && buttonCurrentState === TestButtonState.Ready) {
      setWebchatVisible(true);
    }
  };
  useEffect(onTestingStateChange, [testingState]);

  const testScenario = async (forced = false) => {
    if (!botStage || !scenario || !currentScenarioStructure) {
      return;
    }

    if (!forced && currentScenarioStructure === testedScenarioStructure) {
      return;
    }

    setTesting(true);
    try {
      const response = await botStageApi.testScenario(botStage.id, {
        scenarioStructure: currentScenarioStructure,
      });
      setTestingState(response.data);
      setTestedScenarioStructure(currentScenarioStructure);

      testingReadyTimeout && clearTimeout(testingReadyTimeout);
      const timeout = setTimeout(() => {
        testingReadyTimeout && clearTimeout(testingReadyTimeout);
        setTestingReadyTimeout(undefined);
      }, WEBCHAT_AUTOSHOW_DELAY_MS);
      setTestingReadyTimeout(timeout);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске тестирования сценария',
        error: e,
      });
    }
    setTesting(false);
  };

  const onTestButtonClick = async () => {
    await testScenario();
  };

  const onErrorButtonClick = async () => {
    await testScenario(true);
  };

  const onStartTestingButtonClick = () => setWebchatVisible(true);

  const onFinishTestingButtonClick = () => setWebchatVisible(false);

  const onWebchatClose = () => setWebchatVisible(false);

  const onWebchatRestart = async () => await testScenario();

  const renderTestButton = () => {
    switch (buttonCurrentState) {
      case TestButtonState.NotReady:
        return (
          <SbButton sbType="secondary" onClick={onTestButtonClick}>
            Тестировать
          </SbButton>
        );
      case TestButtonState.Preparing:
        return (
          <SbTooltip visible placement="bottom" title="Подготовка к тестированию может занять некоторое время">
            <SbButton loading icon={<SbIcon spin iconName="loading-four" />} sbType="icon-secondary">
              Подготовка к тестированию
            </SbButton>
          </SbTooltip>
        );
      case TestButtonState.Ready:
        return (
          <SbButton sbType="secondary" onClick={onStartTestingButtonClick}>
            Начать тестирование
          </SbButton>
        );
      case TestButtonState.WebchatOpened:
        return (
          <SbButton sbType="secondary" onClick={onFinishTestingButtonClick}>
            Завершить тестирование
          </SbButton>
        );
      case TestButtonState.Error:
        return (
          <SbTooltip
            visible
            placement="bottom"
            title="Ошибка при запуске тестирования сценария. Нажмите кнопку, чтобы попробовать еще раз"
          >
            <SbButton
              danger
              icon={<SbIcon iconName="attention" />}
              sbType="icon-secondary"
              onClick={onErrorButtonClick}
            >
              Ошибка
            </SbButton>
          </SbTooltip>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderTestButton()}
      {webchatVisible && testingState?.webchatUrl && (
        <div className="sb-scenario-card__webchat-container">
          <SbWebChat
            subTitle="Тестовый виджет"
            title={botName || ''}
            webchatUrl={testingState.webchatUrl}
            onClose={onWebchatClose}
            onRestart={onWebchatRestart}
          />
        </div>
      )}
    </>
  );
};

export default TestButton;
