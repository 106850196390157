import React from 'react';

import '../index.less';

import SbButton from '../../../../components/common/SbButton';
import { useQuery } from '../../../../../utils/urlUtil';

const RETURN_URL_PARAM_NAME = 'returnUrl';

const LoggedInCard: React.FC = () => {
  const query = useQuery();
  const returnUrl = query.get(RETURN_URL_PARAM_NAME) || '#';

  return (
    <div className="sb-employee-auth-card">
      <div className="sb-employee-auth-card__title">Вы успешно авторизовались!</div>
      <div className="sb-employee-auth-card__content">
        <SbButton href={returnUrl} sbSize="big" sbType="primary">
          Вернуться в чат
        </SbButton>
      </div>
    </div>
  );
};

export default LoggedInCard;
