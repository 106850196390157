import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Avatar } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbCard from '../../../../../components/common/IbCard';
import { ConversationModel, ConversationSortDirection, ConversationStatus } from '../../../../../../../api';
import SbTag from '../../../../../../simple-bot/components/common/SbTag';
import { DIALOGS_COUNT_TAG_COLOR } from '../../../../../../simple-bot/const';
import SbIcon from '../../../../../../simple-bot/components/common/SbIcon';
import { inboxAlertsSelectorAdd } from '../../../../../recoil';
import { conversationApi } from '../../../../../../apis';
import { AlertTypes } from '../../../../../../constants';
import { formatDateTimeAdaptive } from '../../../../../../utils/stringUtil';

const MAIN_CLASS_NAME = 'ib-dialogs-card';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const INFO_CLASS_NAME = `${CONTENT_CLASS_NAME}__info`;
const TITLE_MAIN_CLASS_NAME = `${INFO_CLASS_NAME}__title-main`;
const TITLE_OPTIONAL_CLASS_NAME = `${INFO_CLASS_NAME}__title-optional`;

interface IDialogsBlockData {
  totalCount: number;
  activeCount: number;
  userCount: number;
  latestConversation?: ConversationModel;
}

interface IDialogsCardProps {
  agentStageId?: string;
  botId?: string;
}

const DialogsCard: React.FC<IDialogsCardProps> = ({ agentStageId, botId }) => {
  const { t, i18n } = useTranslation();
  const { push } = useHistory();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [dialogsBlockData, setDialogsBlockData] = useState<IDialogsBlockData>({
    totalCount: 0,
    activeCount: 0,
    userCount: 0,
    latestConversation: undefined,
  });

  const goToDialogs = () => {
    if (!botId) return;
    push(`/inbox/bots/${botId}/dialogs`);
  };

  const loadDataAsync = async () => {
    if (!agentStageId) {
      return;
    }

    setLoading(true);
    try {
      const sorting = ConversationSortDirection.LatestMessageOnDescending;
      const conversationsResponse = await conversationApi.searchConversations(
        undefined,
        agentStageId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        sorting,
        0,
        1
      );

      const conversationStatus = ConversationStatus.Closed;
      const closedConversationCountResponse = await conversationApi.getConversationCount(
        undefined,
        agentStageId,
        undefined,
        undefined,
        conversationStatus
      );

      const userCountResponse = await conversationApi.getConversationsUniqueUserCount(undefined, agentStageId);

      const totalCount = conversationsResponse.data.totalItemCount ?? 0;
      setDialogsBlockData({
        totalCount,
        activeCount: totalCount - closedConversationCountResponse.data,
        userCount: userCountResponse.data,
        latestConversation: totalCount ? conversationsResponse.data.items?.slice(-1)[0] : undefined,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }

    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId]);

  const renderContent = () => (
    <>
      <div className={INFO_CLASS_NAME}>
        <Avatar icon={<SbIcon iconName="comment" size={20} />} size={40} />
        <div>
          <div className={TITLE_MAIN_CLASS_NAME}>{t('DialogWithCount', { count: dialogsBlockData.totalCount })}</div>
          {dialogsBlockData.latestConversation && (
            <div className={TITLE_OPTIONAL_CLASS_NAME}>
              {t('Most recent:')}{' '}
              {formatDateTimeAdaptive(dialogsBlockData.latestConversation.latestMessageOn, i18n.language, 'long', true)}
            </div>
          )}
        </div>
      </div>
      <div className={INFO_CLASS_NAME}>
        <Avatar icon={<SbIcon iconName="user" size={20} />} size={40} />
        <div className={TITLE_MAIN_CLASS_NAME}>{t('UserWithCount', { count: dialogsBlockData.userCount })}</div>
      </div>
    </>
  );

  return (
    <IbCard
      extra={
        <SbTag
          color={DIALOGS_COUNT_TAG_COLOR}
          sbSize="small"
          text={t('ActiveDialogWithCount', { count: dialogsBlockData.activeCount })}
        />
      }
      loading={loading || !agentStageId}
      title={t('Dialogs')}
      onClick={goToDialogs}
    >
      <div className={MAIN_CLASS_NAME}>
        <div className={CONTENT_CLASS_NAME}>{renderContent()}</div>
      </div>
    </IbCard>
  );
};

export default DialogsCard;
