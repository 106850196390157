import React, { memo } from 'react';
import { t } from 'i18next';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import IbTypography from '../../common/IbTypography';
import IbBadge from '../../common/IbBadge';
import IbAvatar from '../../common/IbAvatar';
import { InboxChatStatus, InboxMentionModel, InboxMessageContentModel } from '../../../../../api';
import IbTag from '../../common/IbTag';
import { IbSocialType } from '../../common/IbSocial';
import IbMessage from '../../common/IbMessage';
import IbTextHighlighter from '../../common/IbTextHighlighter';

const MAIN_CLASS_NAME = 'ib-chat-list-item';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const CONTENT_TITLE_CLASS_NAME = `${CONTENT_CLASS_NAME}__title`;
const CONTENT_TITLE_PARTICIPANT_CLASS_NAME = `${CONTENT_TITLE_CLASS_NAME}__participant`;
const CONTENT_TITLE_TIME_CLASS_NAME = `${CONTENT_TITLE_CLASS_NAME}__time`;
const CONTENT_TEXT_ROW_CLASS_NAME = `${CONTENT_CLASS_NAME}__text-row`;
const CONTENT_TEXT_ROW_MESSAGE_CLASS_NAME = `${CONTENT_TEXT_ROW_CLASS_NAME}__message`;
const CONTENT_TEXT_ROW_BADGE_CLASS_NAME = `${CONTENT_TEXT_ROW_CLASS_NAME}__badge`;

const SELECTED_CLASS_NAME = `${MAIN_CLASS_NAME}_selected`;
const LOADING_CLASS_NAME = `${MAIN_CLASS_NAME}_loading`;

const AVATAR_SIZE = 48;

export interface IIbChatListItemProps {
  chatId?: string;
  loading?: boolean;
  selected?: boolean;
  title: string;
  date: string;
  fullDate: string;
  content: InboxMessageContentModel;
  avatarImgSrc?: string;
  sender?: string;
  social?: IbSocialType;
  status?: InboxChatStatus;
  unreadMessagesCount: number;
  hasUnreadMentions: boolean;
  mentions: InboxMentionModel[];
  searchText?: string;
  mentionLinksEnabled: boolean;
  avatarMetadataUid?: string;
  onSelect?: (chatId?: string) => void;
}

const IbChatListItem: React.FC<IIbChatListItemProps> = ({
  chatId,
  loading,
  selected,
  title,
  date,
  fullDate,
  content,
  avatarImgSrc,
  sender,
  social,
  status,
  unreadMessagesCount,
  hasUnreadMentions,
  mentions,
  searchText,
  mentionLinksEnabled,
  avatarMetadataUid,
  onSelect,
}) => {
  const classes = [MAIN_CLASS_NAME, selected ? SELECTED_CLASS_NAME : null, loading ? LOADING_CLASS_NAME : null];
  const isClosed = status === InboxChatStatus.Closed;

  const onChatClick = () => {
    onSelect?.(chatId);
  };

  return (
    <div className={classes.join(' ')} onClick={onChatClick}>
      {!loading ? (
        <IbAvatar
          imgSrc={avatarImgSrc}
          metadata={{ uid: avatarMetadataUid || '', text: title }}
          size="medium"
          social={social}
        />
      ) : (
        <Skeleton circle height={AVATAR_SIZE} width={AVATAR_SIZE} />
      )}
      <div className={CONTENT_CLASS_NAME}>
        <div className={CONTENT_TITLE_CLASS_NAME}>
          <div className={CONTENT_TITLE_PARTICIPANT_CLASS_NAME}>
            {!loading ? (
              <IbTypography.Paragraph strong>
                <IbTextHighlighter highlightedText={searchText} text={title} />
              </IbTypography.Paragraph>
            ) : (
              <Skeleton width="160px" />
            )}
          </div>
          <div className={CONTENT_TITLE_TIME_CLASS_NAME}>
            <IbTypography.Paragraph type="descriptor">
              <div title={fullDate}>{date}</div>
            </IbTypography.Paragraph>
          </div>
        </div>
        <div className={CONTENT_TEXT_ROW_CLASS_NAME}>
          <div className={CONTENT_TEXT_ROW_MESSAGE_CLASS_NAME}>
            {!loading && !!sender && (
              <IbTypography.Paragraph disabled type="secondary">
                {sender}:
              </IbTypography.Paragraph>
            )}
            {!loading ? (
              <IbMessage inline content={content} mentionLinksEnabled={mentionLinksEnabled} mentions={mentions} />
            ) : (
              <Skeleton />
            )}
          </div>
          {(isClosed || unreadMessagesCount > 0) && (
            <div className={CONTENT_TEXT_ROW_BADGE_CLASS_NAME}>
              {!isClosed && unreadMessagesCount > 0 && <IbBadge value={unreadMessagesCount} />}
              {isClosed && <IbTag content={t('Closed (chat badge)')} type="status" />}
            </div>
          )}
          {hasUnreadMentions && (
            <div className={CONTENT_TEXT_ROW_BADGE_CLASS_NAME}>
              <IbBadge disabled className="mention" value="@" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(IbChatListItem);
