export const MAIN_CLASS_NAME = 'ib-channel-tune-modal';
export const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;
export const DIALOGS_PER_OPERATOR_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__dialogs-per-operator`;
export const BOT_SWITCH_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__bot-switch`;
export const WORKTIME_SWITCH_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__worktime-switch`;
export const WORKTIME_LIST_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__worktime-list`;
export const TIMEPICKER_CLASS_NAME = `${WORKTIME_LIST_CLASS_NAME}__timepicker`;

export const WORKTIME_FORMAT = 'HH:mm';
export const WORKTIME_MINUTE_STEP = 10;
