import React, { memo } from 'react';
import { Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { DefaultActionGroupSchema, EndScenarioOutputSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  scenariosSelector,
} from '../../../../../recoil/scenarioStructure';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import EditorSelect from '../../common/EditorSelect';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../../utils';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import ContextMenu from '../../common/ContextMenu';

interface EndScenarioOutputProps {
  index: number;
  action: EndScenarioOutputSchema;
  group: DefaultActionGroupSchema;
}

const EndScenarioOutput: React.FC<EndScenarioOutputProps> = ({ action, index, group }) => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const scenarios = useRecoilValue(scenariosSelector);

  const saveTransitionScenarioId = (id: string | undefined) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as EndScenarioOutputSchema).transitionScenarioId = id;
    setScenarioStructure(newScenarioStructure);
  };

  const menuContent = (
    <Menu>
      <Menu.ItemGroup title="Перейти к сценарию:">
        {scenarios
          .filter((scenario) => scenario.scenarioStructureId !== scenarioStructure?.id)
          .map((scenario) => (
            <Menu.Item
              key={scenario.scenarioStructureId}
              onClick={() => saveTransitionScenarioId(scenario.scenarioStructureId)}
            >
              {scenario.name}
            </Menu.Item>
          ))}
      </Menu.ItemGroup>
      <Menu.Divider />
      <Menu.Item key="nothing" onClick={() => saveTransitionScenarioId(undefined)}>
        Ничего не делать
      </Menu.Item>
    </Menu>
  );

  const selectedScenario = scenarios.find((scenario) => scenario.scenarioStructureId === action.transitionScenarioId);
  const onOpenSelectedScenario = () => {
    if (selectedScenario) {
      push(`/simple-bots/${id}/scenario/${selectedScenario.scenarioStructureId}`);
    }
  };

  const tooltipContent = selectedScenario ? (
    <SbButton
      className="sb-end-scenario-output-open-scenario-button"
      sbSize="medium"
      sbType="tertiary"
      onClick={onOpenSelectedScenario}
    >
      Открыть сценарий
    </SbButton>
  ) : null;

  const editorSelect = (
    <EditorSelect
      id={getElementId(action.id, EntityFieldPath.TransitionScenarioId)}
      isValid={!scenarioValidation?.hasIssue(action.id, EntityFieldPath.TransitionScenarioId)}
      menuContent={menuContent}
      title={action.transitionScenarioId ? selectedScenario?.name || 'Несуществующий сценарий' : 'Ничего не делать'}
    />
  );

  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="handle-x" size={20} />}
      index={index}
      title="Завершение сценария"
    >
      {action.transitionScenarioId ? 'Перейти к сценарию:' : null}
      <div className="sb-end-scenario-output-select">
        <ContextMenu content={editorSelect} menuContent={tooltipContent} />
      </div>
    </ActionWrapper>
  );
};

export default memo(EndScenarioOutput);
