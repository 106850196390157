import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShareAltOutlined,
  TeamOutlined,
  UserOutlined,
  RobotOutlined,
  CommentOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { createElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { Flags } from 'react-feature-flags';

import { AboutModel, FeatureFlag } from '../../api';
import { applicationApi } from '../apis';
import { AlertTypes, FeatureFlagNames } from '../constants';
import UserInfo from '../components/UserInfo';
import { Logo, LogoElmaBot } from '../assets';
import { alertsSelectorAdd } from '../recoil/alerts';

const { Header, Content, Sider } = Layout;

const BaseLayout: React.FC = (props) => {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['']);
  const [appInfo, setAppInfo] = useState({ name: '', description: '', version: '' } as AboutModel);

  const menuItems = ['/agents', '/simple-bots', '/campaigns', '/persons', '/groups', '/knowledge-bases'];

  const onMenuItemClick = (menuItem: string) => () => {
    setSelectedKeys([menuItem]);
    history.push(menuItem);
  };

  const initSelectedKeys = () => {
    const { pathname } = window.location;

    setSelectedKeys(menuItems.filter((item) => pathname.indexOf(item) === 0));
  };
  useEffect(initSelectedKeys, []);

  const loadAppInfoAsync = async () => {
    try {
      const response = await applicationApi.getAboutInfo();
      setAppInfo(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных о приложении',
        error: e,
      });
    }
  };
  const loadAppInfo = () => {
    loadAppInfoAsync();
  };
  useEffect(loadAppInfo, []);

  const onLocationChanged = () => {
    setSelectedKeys(menuItems.filter((item) => location.pathname.indexOf(item) === 0));
  };

  useEffect(onLocationChanged, [location]);

  return (
    <Layout className="base-layout">
      <Sider collapsible className="base-layout-sider" collapsed={collapsed} theme="dark" trigger={null}>
        <div className="base-layout-logo-container">
          {collapsed ? (
            <Logo className="base-layout-logo-elma-bot" />
          ) : (
            <LogoElmaBot className="base-layout-logo-elma-bot" />
          )}
        </div>
        <Flags
          authorizedFlags={[FeatureFlagNames.BOTS_ADMIN, FeatureFlagNames.ADMIN]}
          renderOn={(activeFlags: FeatureFlag[]) => {
            const agentsMenuItemIsAvailable = !!activeFlags.find((af) => af.name === FeatureFlagNames.BOTS_ADMIN);
            const simpleBotMenuItemIsAvailable = !!activeFlags.find((af) => af.name === FeatureFlagNames.BOTS_ADMIN);
            const campaignsMenuItemIsAvailable = !!activeFlags.find((af) => af.name === FeatureFlagNames.ADMIN);
            const personsMenuItemIsAvailable = !!activeFlags.find((af) => af.name === FeatureFlagNames.ADMIN);
            const groupsMenuItemIsAvailable = !!activeFlags.find((af) => af.name === FeatureFlagNames.ADMIN);
            const knowledgeBasesMenuItemIsAvailable = !!activeFlags.find(
              (af) => af.name === FeatureFlagNames.BOTS_ADMIN
            );

            return (
              <Menu mode="inline" selectedKeys={selectedKeys} theme="dark">
                {agentsMenuItemIsAvailable ? (
                  <Menu.Item key="agents" icon={<RobotOutlined />} onClick={onMenuItemClick('/agents')}>
                    Боты
                  </Menu.Item>
                ) : null}

                {simpleBotMenuItemIsAvailable ? (
                  <Menu.Item key="simple-bots" icon={<RobotOutlined />} onClick={onMenuItemClick('/simple-bots')}>
                    Простые боты
                  </Menu.Item>
                ) : null}

                {campaignsMenuItemIsAvailable ? (
                  <Menu.Item key="campaigns" icon={<ShareAltOutlined />} onClick={onMenuItemClick('/campaigns')}>
                    Рассылки
                  </Menu.Item>
                ) : null}

                {personsMenuItemIsAvailable ? (
                  <Menu.Item key="persons" icon={<UserOutlined />} onClick={onMenuItemClick('/persons')}>
                    Работники
                  </Menu.Item>
                ) : null}

                {groupsMenuItemIsAvailable ? (
                  <Menu.Item key="groups" icon={<TeamOutlined />} onClick={onMenuItemClick('/groups')}>
                    Группы
                  </Menu.Item>
                ) : null}

                {knowledgeBasesMenuItemIsAvailable ? (
                  <Menu.Item
                    key="knowledge-bases"
                    icon={<CommentOutlined />}
                    onClick={onMenuItemClick('/knowledge-bases')}
                  >
                    Базы знаний
                  </Menu.Item>
                ) : null}
              </Menu>
            );
          }}
        />
      </Sider>
      <Layout className="base-layout-main">
        <Header className="base-layout-header">
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => setCollapsed(!collapsed),
          })}
          <code className="app-info">Version: {appInfo.version}</code>
          <UserInfo />
        </Header>
        <Layout className="base-layout-scrollable">
          <Layout className="base-layout-scrollable-content">
            <Content className="base-layout-content">{children}</Content>
          </Layout>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default BaseLayout;
