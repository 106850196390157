import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAsync } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useDebounce } from 'usehooks-ts';
import { RcFile } from 'antd/lib/upload';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import IbTypography from '../../../components/common/IbTypography';
import IbButton from '../../../components/common/IbButton';
import IbIcon from '../../../components/common/IbIcon';
import { botApi, botEditionApi, botStageApi, intentEntryApi } from '../../../../apis';
import {
  BotContentFormat,
  BotStageModel,
  BotTrainingModelModel,
  IntentEntryModel,
  SingleBotModel,
} from '../../../../../api';
import { inboxAlertsSelectorAdd } from '../../../recoil';
import { ALLOWED_IMPORT_BOT_FILE_TYPES, AlertTypes, BOT_EDITION_EXPORT_FINISHED } from '../../../../constants';
import SettingsModal from '../../../../simple-bot/pages/bots/SimpleBotCard/SettingsModal';
import SbBotVersionsModal from '../../../../simple-bot/components/SbBotVersionsModal';
import SbUpload from '../../../../simple-bot/components/common/SbUpload';
import IbWebChatButton from '../../../components/IbWebChatButton';
import IbInput from '../../../components/common/IbInput';
import IbSortButton from '../../../components/common/IbSortButton';
import { ScenarioSortDirection } from '../../../../simple-bot/types';
import SbModal from '../../../../simple-bot/components/common/SbModal';
import ScenarioAddModal, {
  SCENARIO_ADD_MODAL_WINDOW_WIDTH,
} from '../../../../simple-bot/components/SbScenarioAddModal';
import { getErrorMessage, isInvalidCastError } from '../../../../utils/errorUtils';
import ScenariosList from '../BotPage/ScenariosList';
import IbProgressStatusModal, { IbProgressStatusModalStatus } from '../../../components/IbProgressStatusModal';
import { hubConnections } from '../../../../utils/socketsUtil';

const MAIN_CLASS_NAME = 'ib-bot-scenarios-page';
const TOP_BAR_CLASS_NAME = `${MAIN_CLASS_NAME}__top-bar`;
const TOP_BAR_TITLE_CLASS_NAME = `${TOP_BAR_CLASS_NAME}__title`;
const TOP_BAR_TITLE_LINK_CLASS_NAME = `${TOP_BAR_TITLE_CLASS_NAME}__link`;
const TOP_BAR_BUTTONS_CLASS_NAME = `${TOP_BAR_CLASS_NAME}__buttons`;
const TOP_BAR_BUTTONS_UPLOAD_CLASS_NAME = `${TOP_BAR_BUTTONS_CLASS_NAME}__upload`;
const TOP_BAR_MOBILE_CLASS_NAME = `${TOP_BAR_CLASS_NAME}_mobile`;
const TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME = `${TOP_BAR_MOBILE_CLASS_NAME}__title-row`;
const TOP_BAR_MOBILE_TITLE_ROW_BUTTONS_CLASS_NAME = `${TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}__buttons`;
const TOP_BAR_MOBILE_ACTIONS_ROW_CLASS_NAME = `${TOP_BAR_MOBILE_CLASS_NAME}__actions-row`;
const BACK_BUTTON_CLASS_NAME = `${TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}__back-button`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;

const SEARCH_DELAY = 200; //ms
const SYSTEM_INTENTS_PAGE_SIZE = 10000;

interface IBotEditionExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

interface IBotEditionImport {
  processing: boolean;
  errorMessage: string;
}

const botEditionExportDefaultValue: IBotEditionExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

const botEditionImportDefaultValue: IBotEditionImport = {
  processing: false,
  errorMessage: '',
};

const BotScenariosPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [botLoading, setBotLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [bot, setBot] = useState<SingleBotModel>();
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [botVersionsModalVisible, setBotVersionsModalVisible] = useState(false);
  const [addScenarioModalVisible, setAddScenarioModalVisible] = useState(false);

  const [botEditionExport, setBotEditionExport] = useState(botEditionExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [botEditionImport, setBotEditionImport] = useState(botEditionImportDefaultValue);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const [trainingModels, setTrainingModels] = useState<BotTrainingModelModel[]>([]);
  const [systemIntents, setSystemIntents] = useState<IntentEntryModel[]>([]);

  const [scenarioSortDirection, setScenarioSortDirection] = useState(ScenarioSortDirection.ModifiedOnDescending);

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);

  const botStage = bot?.originStage;
  const botCurrentEdition = bot?.originCurrentEdition;

  const loading = botLoading || dataLoading;

  const exportStatus = botEditionExport.preparing
    ? IbProgressStatusModalStatus.InProgress
    : botEditionExport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const exportTitle = botEditionExport.preparing
    ? t('Bot export in progress')
    : botEditionExport.errorMessage
    ? t('An error occurred while exporting the bot')
    : t('Bot export was successful');

  const importStatus = botEditionImport.processing
    ? IbProgressStatusModalStatus.InProgress
    : botEditionImport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const importTitle = botEditionImport.processing
    ? t('Bot import in progress')
    : botEditionImport.errorMessage
    ? t('An error occurred while importing the bot')
    : t('Bot import was successful');

  const loadBotAsync = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      setBot(botResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadAdditionalDataAsync = async () => {
    try {
      const trainingModelsResponse = await botApi.getBotTrainingModels();
      setTrainingModels(trainingModelsResponse.data);

      const intentsResponse = await intentEntryApi.searchIntentEntries({}, 0, SYSTEM_INTENTS_PAGE_SIZE);
      setSystemIntents(intentsResponse.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadAllDataAsync = async () => {
    if (!id) return;
    setDataLoading(true);
    try {
      await loadAdditionalDataAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setDataLoading(false);
  };

  const loadAllData = () => {
    loadAllDataAsync().finally();
  };
  useEffect(loadAllData, []);

  const loadBotDataAsync = async () => {
    if (!id) return;
    setBotLoading(true);
    try {
      await loadBotAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setBotLoading(false);
  };

  const loadBotData = () => {
    loadBotDataAsync().finally();
  };
  useEffect(loadBotData, [id]);

  const onDataChanged = async () => {
    await loadBotDataAsync();
  };

  const goToBotCard = () => {
    push(`/inbox/bots/${id}`);
  };

  const goToBotsList = () => {
    push('/inbox/bots/');
  };

  const onSettingsButtonClick = () => !loading && setSettingsModalVisible(true);
  const onSettingsModalClose = () => setSettingsModalVisible(false);

  const onShowBotVersions = () => setBotVersionsModalVisible(true);
  const onBotVersionsModalClose = () => setBotVersionsModalVisible(false);

  const onBotVersionsModalDraftStageAdded = async () => {
    await loadBotDataAsync();
    setBotVersionsModalVisible(false);
  };

  const closeExportBotModal = () => {
    setExportModalVisible(false);
    setBotEditionExport(botEditionExportDefaultValue);
  };

  const closeImportBotModal = () => {
    setImportModalVisible(false);
  };

  const onExport = (botStage?: BotStageModel) => async () => {
    if (!botStage) return;

    setBotEditionExport({
      ...botEditionExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await botEditionApi.runBotEditionExport(
        botStage.currentEdition.botEditionId,
        BotContentFormat.Json
      );

      setBotEditionExport({
        ...botEditionExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      setBotEditionExport({
        ...botEditionExportDefaultValue,
        errorMessage: getErrorMessage(e as Error),
      });
    }
  };

  const botEditionExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (botEditionExport.requestId !== args?.requestId || !botEditionExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setBotEditionExport({
      ...botEditionExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToBotEditionExportEvents = () => {
    if (!botEditionExport.requestId || !conn) return;

    conn.on(BOT_EDITION_EXPORT_FINISHED, botEditionExportEventHandler);

    return () => {
      conn.off(BOT_EDITION_EXPORT_FINISHED, botEditionExportEventHandler);
    };
  };
  useEffect(subscribeToBotEditionExportEvents, [conn, botEditionExport.requestId]);

  const onExportModalClose = () => closeExportBotModal();

  const onImportModalClose = () => {
    closeImportBotModal();
    if (importStatus == IbProgressStatusModalStatus.Success) {
      loadBotData();
      loadAllData();
    }
  };

  const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
    if (!botStage) return;

    try {
      await botStageApi.importBot(botStage.id, {
        botFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
      setBotEditionImport(botEditionImportDefaultValue);
    } catch (e) {
      const message = isInvalidCastError(e as Error)
        ? t('Uploaded file is not a bot description file')
        : getErrorMessage(e as Error);

      setBotEditionImport({
        processing: false,
        errorMessage: message,
      });
    }
  };

  const beforeImportBotFileUpload = () => {
    setBotEditionImport({
      ...botEditionImportDefaultValue,
      processing: true,
    });
    setImportModalVisible(true);
  };

  const onAddScenario = () => setAddScenarioModalVisible(true);

  const onScenariosChanged = () => loadBotData();

  const onScenarioCreate = async (scenarioTemplateContent: string, scenarioName: string) => {
    if (!botStage) {
      return;
    }

    try {
      const structure = JSON.parse(scenarioTemplateContent);
      structure.name = scenarioName;

      const response = await botStageApi.createScenario(botStage.id, {
        scenarioName: structure.name,
        scenarioStructure: structure,
      });

      const scenariosCount = response.data.currentEdition.scenarios.length;
      const scenario = response.data.currentEdition.scenarios[scenariosCount - 1];

      push(`/inbox/bots/${bot?.entry.id}/scenario/${scenario.scenarioStructureId}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Scenario adding error'),
      });
    }
  };

  const renderTopBar = () => {
    const actions = [
      {
        onSelect: onShowBotVersions,
        icon: '',
        text: t('Version history'),
      },
      {
        onSelect: () => {
          if (bot?.originStage) {
            onExport(bot?.originStage)();
          }
        },
        icon: '',
        text: t('Export'),
      },
      {
        onSelect: () => {},
        icon: '',
        text: (
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            beforeUpload={beforeImportBotFileUpload}
            className={TOP_BAR_BUTTONS_UPLOAD_CLASS_NAME}
            onFileUpload={onImportBotFileUpload(bot?.originStage)}
          >
            {t('Import')}
          </SbUpload>
        ),
      },
    ];

    const onSortDirectionChange = (value: string) => {
      setScenarioSortDirection(value as ScenarioSortDirection);
    };

    const sortDirections = [
      {
        label: t('Recently modified first'),
        value: ScenarioSortDirection.ModifiedOnDescending,
      },
      {
        label: t('By name'),
        value: ScenarioSortDirection.NameAscending,
      },
    ];

    const renderBreadcrumbs = () => {
      return (
        <IbTypography>
          <h2>
            <span className={TOP_BAR_TITLE_LINK_CLASS_NAME} onClick={goToBotsList}>
              {t('Chat bots')}
            </span>
            <span>&nbsp;/&nbsp;</span>
            {!bot ? (
              <Skeleton inline height={30} width={200} />
            ) : (
              <span className={TOP_BAR_TITLE_LINK_CLASS_NAME} onClick={goToBotCard}>
                {bot.entry.name}
              </span>
            )}
            <span>&nbsp;/&nbsp;{t('Scenarios')}</span>
          </h2>
        </IbTypography>
      );
    };

    return (
      <div className={TOP_BAR_CLASS_NAME}>
        <div className={TOP_BAR_TITLE_CLASS_NAME}>{renderBreadcrumbs()}</div>
        <div className={TOP_BAR_BUTTONS_CLASS_NAME}>
          <IbButton
            actions={actions}
            icon={<IbIcon iconName="setting-two" />}
            type="secondary"
            onClick={onSettingsButtonClick}
          >
            {t('Settings')}
          </IbButton>
          <IbWebChatButton botName={bot?.entry.name} botStage={botStage} />
          {!!bot?.originStage?.currentEdition?.scenarios.length && (
            <>
              <IbInput allowClear value={searchText} onChange={setSearchText} />
              <IbSortButton options={sortDirections} value={scenarioSortDirection} onChange={onSortDirectionChange} />
            </>
          )}
        </div>
      </div>
    );
  };

  const renderTopBarMobile = () => {
    const onGoBack = () => goToBotCard();

    return (
      <div className={TOP_BAR_MOBILE_CLASS_NAME}>
        <div className={TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}>
          <IbButton
            className={BACK_BUTTON_CLASS_NAME}
            icon={<IbIcon iconName="arrow-left" />}
            type="icon"
            onClick={onGoBack}
          />
          <IbTypography>
            <h4>{t('Scenarios')}</h4>
          </IbTypography>
          <div className={TOP_BAR_MOBILE_TITLE_ROW_BUTTONS_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="add-one" />} type="link" onClick={onAddScenario} />
          </div>
        </div>
        <div className={TOP_BAR_MOBILE_ACTIONS_ROW_CLASS_NAME}>
          <IbButton icon={<IbIcon iconName="setting-two" />} type="link" onClick={onSettingsButtonClick}>
            {t('Settings')}
          </IbButton>
          <IbWebChatButton botName={bot?.entry.name} botStage={botStage} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={CONTENT_CLASS_NAME}>
        <ScenariosList
          bot={bot}
          loading={loading}
          searchText={debouncedSearchText}
          sortDirection={scenarioSortDirection}
          systemIntents={systemIntents}
          onAddScenario={onAddScenario}
          onDataChanged={onScenariosChanged}
        />
      </div>
    );
  };

  const onScenarioAddModalCancel = () => setAddScenarioModalVisible(false);

  const renderBotEditionExportModalContent = () => {
    switch (exportStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Success:
        return (
          <IbTypography>
            <IbTypography.Paragraph>
              <a href={botEditionExport.fileUrl}>{t('Download link')}</a>
            </IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{botEditionExport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  const renderBotEditionImportModalContent = () => {
    switch (importStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{botEditionImport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderTopBar()}
      {renderTopBarMobile()}
      {renderContent()}
      {botStage && botCurrentEdition && (
        <SettingsModal
          botEdition={botCurrentEdition}
          botStageId={botStage?.id}
          trainingModels={trainingModels}
          visible={settingsModalVisible}
          onClose={onSettingsModalClose}
          onDataChanged={onDataChanged}
        />
      )}
      {bot?.entry.id && (
        <SbBotVersionsModal
          botId={bot.entry.id}
          visible={botVersionsModalVisible}
          onClose={onBotVersionsModalClose}
          onDraftStageAdded={onBotVersionsModalDraftStageAdded}
        />
      )}
      {bot && (
        <SbModal
          destroyOnClose
          footer={[]}
          maskClosable={false}
          sbSize="large"
          visible={addScenarioModalVisible}
          width={SCENARIO_ADD_MODAL_WINDOW_WIDTH}
          onCancel={onScenarioAddModalCancel}
        >
          <ScenarioAddModal originBotTemplateCode={bot.entry.botTemplateCode} onScenarioCreate={onScenarioCreate} />
        </SbModal>
      )}
      <IbProgressStatusModal
        header={exportTitle}
        status={exportStatus}
        visible={exportModalVisible}
        onCancel={onExportModalClose}
      >
        {renderBotEditionExportModalContent()}
      </IbProgressStatusModal>
      <IbProgressStatusModal
        closable={importStatus != IbProgressStatusModalStatus.InProgress}
        header={importTitle}
        status={importStatus}
        visible={importModalVisible}
        onCancel={onImportModalClose}
      >
        {renderBotEditionImportModalContent()}
      </IbProgressStatusModal>
    </div>
  );
};

export default BotScenariosPage;
