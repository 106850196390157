import React from 'react';

import './index.less';
import SbScroll from '../SbScroll';

interface ISbPanelProps {
  sbType?: 'info' | 'help' | 'alert';
  height?: number;
  className?: string;
  scrollable?: boolean;
}

const SbPanel: React.FC<ISbPanelProps> = ({ sbType = 'info', height, className, scrollable = true, children }) => {
  const classes = ['sb-panel', `sb-panel-${sbType}`];
  className && classes.push(className);

  const content = (
    <div className={classes.join(' ')} style={{ minHeight: height }}>
      {children}
    </div>
  );

  return scrollable ? <SbScroll height={height}>{content}</SbScroll> : content;
};

export default SbPanel;
