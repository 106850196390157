import React from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const SIZE_DEFAULT = 'medium';
const STATUS_DEFAULT = 'offline';

const MAIN_CLASS_NAME = 'ib-user-status';

export type IbUserStatusType = 'offline' | 'online';

export interface IIbUserStatusProps {
  className?: string;
  size?: 'x-small' | 'small' | 'medium' | 'large';
  status?: IbUserStatusType;
}

const IbUserStatus: React.FC<IIbUserStatusProps> = ({ className, size = SIZE_DEFAULT, status = STATUS_DEFAULT }) => {
  size = getDefaultIfUndefined(size, SIZE_DEFAULT);
  status = getDefaultIfUndefined(status, STATUS_DEFAULT);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${size}`, `${MAIN_CLASS_NAME}_${status}`];
  className && classes.push(className);

  return <div className={classes.join(' ')}></div>;
};

export default IbUserStatus;
