import React, { MouseEventHandler, useEffect, useState } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';
import './index.less';
import { Button } from 'antd';
import { RcFile } from 'antd/es/upload';
import cloneDeep from 'lodash/cloneDeep';

import { OperandSchema, SchemaKind, SendEmailSchema, VariableSchema } from '../../../../../../api';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import SbScroll from '../../../../../simple-bot/components/common/SbScroll';
import SbTooltip from '../../../../../simple-bot/components/common/SbTooltip';
import SbMarkdownEditor from '../../../../../simple-bot/components/common/SbMarkdownEditor';
import { currentScenarioStructureSelector, variableUsagesSelector } from '../../../../../recoil/scenarioStructure';
import SbUpload from '../../../../../simple-bot/components/common/SbUpload';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { base64ContentToFormFile } from '../../../../../utils/fileUtil';
import { fileStorageApi } from '../../../../../apis';
import { generateId, instanceOfVariableOperandSchema } from '../../../utils';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { tagColorPalette } from '../../../../../simple-bot/const';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import EmailRecipientEditor from '../../EmailRecipientEditor';
import { VariableIcon } from '../../../assets';
import SbTag from '../../../../../simple-bot/components/common/SbTag';
import VariableSelector from '../../VariableSelector';

interface ISendEmailSettingsModalProps {
  action: SendEmailSchema;
  visible: boolean;
  onChange: (action: SendEmailSchema) => void;
  onClose: () => void;
}

const MODAL_WIDTH = 650;
const VAR_TOOLTIP_WIDTH = 280;
const TAG_COLOR_DEFAULT = tagColorPalette[1];
const TAG_COLOR_OPTIONAL = tagColorPalette[0];
const MAIN_CLASS_NAME = 'send-email-settings-modal';

const SendEmailSettingsModal: React.FC<ISendEmailSettingsModalProps> = ({ action, visible, onChange, onClose }) => {
  const [subject, setSubject] = useState(action.subject);
  const [body, setBody] = useState(action.body);
  const [recipients, setRecipients] = useState(action.recipients);
  const [ccRecipients, setCcRecipients] = useState(action.ccRecipients);
  const [bccRecipients, setBccRecipients] = useState(action.bccRecipients);
  const [ccVisible, setCcVisible] = useState(false);
  const [attachments, setAttachments] = useState(action.attachments);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [subjectTooltipIsOpen, setSubjectTooltipIsOpen] = useState(false);
  const [attachmentsTooltipIsOpen, setAttachmentsTooltipIsOpen] = useState(false);

  const variables = scenarioStructure?.variables || [];

  const getVariableUsages = useRecoilCallback(({ snapshot }: CallbackInterface) => async (variable: VariableSchema) =>
    await snapshot.getPromise(variableUsagesSelector(variable))
  );

  const onSaveSettings = () => {
    onClose();

    if (
      subject == action.subject &&
      body == action.body &&
      recipients == action.recipients &&
      ccRecipients == action.ccRecipients &&
      bccRecipients == action.bccRecipients &&
      attachments == action.attachments
    ) {
      return;
    }

    onChange({
      ...action,
      subject,
      body,
      recipients,
      ccRecipients,
      bccRecipients,
      attachments,
    });
  };

  const onBodyChange = (value: string) => setBody(value);
  const onRecipientsChange = (values: OperandSchema[]) => setRecipients(values);
  const onCcRecipientsChange = (values: OperandSchema[]) => setCcRecipients(values);
  const onBccRecipientsChange = (values: OperandSchema[]) => setBccRecipients(values);
  const onShowCcBcc = () => setCcVisible(!ccVisible);
  const onSubjectChange = (value: string) => {
    const newSubject = { ...subject, value };
    setSubject(newSubject);
  };
  const onClearSubject = () => {
    const newSubject = { ...subject, value: '' };
    setSubject(newSubject);
  };

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const onActionChange = () => {
    if (!visible) return;

    setSubject(action.subject);
    setBody(action.body);
    setRecipients(action.recipients);
    setCcRecipients(action.ccRecipients);
    setBccRecipients(action.bccRecipients);
    setCcVisible(false);
    setAttachments(action.attachments);
  };
  useEffect(onActionChange, [action, visible]);

  const onFileUpload = async (file: RcFile, base64Content: string) => {
    try {
      const formFile = base64ContentToFormFile(file, base64Content);
      const response = await fileStorageApi.uploadBotStaticFile(formFile);
      const newAttachments = [...attachments];
      newAttachments.push({
        id: generateId('OPD'),
        $kind: SchemaKind.ConstantOperand,
        value: {
          id: generateId('ATT'),
          $kind: SchemaKind.Attachment,
          fileId: response.data.id,
          name: file.name,
          contentType: file.type || 'application/octet-stream',
        },
      });
      setAttachments(newAttachments);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке файла',
        error: e,
      });
    }
  };

  const onVariablesSelectorSettingsModalOpen = () => {
    setSubjectTooltipIsOpen(false);
    setAttachmentsTooltipIsOpen(false);
  };

  const onVariablesChange = (newVariables: VariableSchema[]) => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.variables = newVariables;
    setScenarioStructure(newScenarioStructure);
  };

  const onAttachmentsVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    setAttachmentsTooltipIsOpen(false);
    if (newVariables) onVariablesChange(newVariables);
    if (selectedVariable) {
      const newAttachments = [...attachments];
      newAttachments.push({
        id: generateId('OPD'),
        $kind: SchemaKind.VariableOperand,
        variableId: selectedVariable.id,
        variableName: selectedVariable.name,
      });
      setAttachments(newAttachments);
    }
  };

  const onAttachmentDelete = (index: number) => () => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  const onSubjectVariableDelete: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    const newSubject = { ...subject, value: undefined, variableId: undefined, $kind: SchemaKind.ConstantOperand };
    setSubject(newSubject);
  };

  const onSubjectVariableSelectorChange = (newVariables?: VariableSchema[], selectedVariable?: VariableSchema) => {
    setSubjectTooltipIsOpen(false);
    if (newVariables) onVariablesChange(newVariables);
    if (selectedVariable) {
      const newSubject = {
        ...subject,
        $kind: SchemaKind.VariableOperand,
        variableId: selectedVariable.id,
        variableName: selectedVariable.name,
      };
      setSubject(newSubject);
    }
  };

  const suffix = subject.value ? (
    <button onClick={onClearSubject}>
      <SbIcon iconName="handle-x" size={20} />
    </button>
  ) : (
    <SbTooltip
      overlayInnerStyle={{ width: `${VAR_TOOLTIP_WIDTH}px` }}
      placement="right"
      title={
        <VariableSelector
          getVariableUsages={getVariableUsages}
          variables={variables}
          onChange={onSubjectVariableSelectorChange}
          onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
        />
      }
      trigger={['click']}
      visible={subjectTooltipIsOpen}
      onVisibleChange={setSubjectTooltipIsOpen}
    >
      <button className={`${MAIN_CLASS_NAME}__var-button`}>
        <VariableIcon />
      </button>
    </SbTooltip>
  );

  return (
    <SbModal
      className={MAIN_CLASS_NAME}
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveSettings}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройки сообщения"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onClose}
      onOk={onSaveSettings}
    >
      <SbScroll>
        <SbTypography>
          <h4>Кому</h4>
          <div className={`${MAIN_CLASS_NAME}__recipients`}>
            <EmailRecipientEditor
              isValid={!!recipients.length}
              tagsColor={TAG_COLOR_DEFAULT}
              values={recipients}
              onChange={onRecipientsChange}
            />
            <button className={`${MAIN_CLASS_NAME}__cc-button`} onClick={onShowCcBcc}>
              CC/BCC
            </button>
          </div>
          <div hidden={!ccVisible && !ccRecipients.length}>
            <h4>Копия</h4>
            <EmailRecipientEditor tagsColor={TAG_COLOR_DEFAULT} values={ccRecipients} onChange={onCcRecipientsChange} />
          </div>
          <div hidden={!ccVisible && !bccRecipients.length}>
            <h4>Скрытая копия</h4>
            <EmailRecipientEditor
              tagsColor={TAG_COLOR_DEFAULT}
              values={bccRecipients}
              onChange={onBccRecipientsChange}
            />
          </div>
          <h4>Тема</h4>
          {instanceOfVariableOperandSchema(subject) ? (
            <SbTag color={TAG_COLOR_OPTIONAL} text={subject.variableName} onDelete={onSubjectVariableDelete} />
          ) : (
            <SbInput
              allowClear
              customSuffix={suffix}
              isValid={!!subject.value}
              value={subject.value}
              onChange={onSubjectChange}
            />
          )}
          <h4>Текст сообщения</h4>
          <SbMarkdownEditor
            isValid={!!body}
            value={body}
            variables={variables}
            onChange={onBodyChange}
            onVariablesChange={onVariablesChange}
          />
          <h4>Вложения</h4>
          <div className={`${MAIN_CLASS_NAME}__attachments`}>
            {attachments.map((attachment, index) => (
              <SbTag
                key={attachment.id}
                color={instanceOfVariableOperandSchema(attachment) ? TAG_COLOR_OPTIONAL : TAG_COLOR_DEFAULT}
                sbSize="small"
                text={
                  <span className={`${MAIN_CLASS_NAME}__attachments__content`}>
                    <SbIcon iconName="paperclip" size={16} />
                    <span>
                      {instanceOfVariableOperandSchema(attachment) ? attachment.variableName : attachment.value.name}
                    </span>
                  </span>
                }
                onDelete={onAttachmentDelete(index)}
              />
            ))}
          </div>
          <div className={`${MAIN_CLASS_NAME}__send-attachments`}>
            <Button className={`${MAIN_CLASS_NAME}__send-attachments__button`} type="link">
              <SbUpload onFileUpload={onFileUpload}>
                <SbIcon iconName="paperclip" size={16} />
              </SbUpload>
            </Button>
            <SbTooltip
              className={`${MAIN_CLASS_NAME}__send-attachments__variable-selector`}
              overlayInnerStyle={{ width: `${VAR_TOOLTIP_WIDTH}px` }}
              placement="right"
              title={
                <VariableSelector
                  getVariableUsages={getVariableUsages}
                  variables={variables}
                  onChange={onAttachmentsVariableSelectorChange}
                  onSettingsModalOpen={onVariablesSelectorSettingsModalOpen}
                />
              }
              trigger={['click']}
              visible={attachmentsTooltipIsOpen}
              onVisibleChange={setAttachmentsTooltipIsOpen}
            >
              <button className={`${MAIN_CLASS_NAME}__var-button`}>
                <VariableIcon />
              </button>
            </SbTooltip>
          </div>
        </SbTypography>
      </SbScroll>
    </SbModal>
  );
};

export default SendEmailSettingsModal;
