export const MAIN_CLASS_NAME = 'ib-operator-groups-widget';

export const ADD_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__add-button`;
export const POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__popover`;
export const EMPTY_CONTENT_CLASS_NAME = `${POPOVER_CLASS_NAME}__empty-content`;
export const CONTENT_CLASS_NAME = `${POPOVER_CLASS_NAME}__content`;
export const EMPTY_SEARCH_TEXT_CLASS_NAME = `${CONTENT_CLASS_NAME}__empty-search-text`;
export const GROUP_LIST_CLASS_NAME = `${CONTENT_CLASS_NAME}__group-list`;
export const GROUP_LIST_ITEM_CLASS_NAME = `${GROUP_LIST_CLASS_NAME}__item`;
export const GROUP_LIST_ITEM_SELECTED_CLASS_NAME = `${GROUP_LIST_ITEM_CLASS_NAME}_selected`;
