import { SelectValue } from 'antd/lib/select';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import {
  InboxOperatorGroupModel,
  InboxParticipantInvitationRequest,
  InboxParticipantModel,
  InboxParticipantUpdatingRequest,
  PersonRole,
  UserStatus,
} from '../../../../api';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import { IFormFieldValidationResult } from '../../../types';
import {
  emailIsValid,
  formatFullName,
  formatDateFull,
  formatShortName,
  getRoleNameLabel,
  phoneNumberIsValid,
} from '../../../utils/stringUtil';
import IbCheckbox from '../common/IbCheckbox';
import { USER_ROLES } from '../../../constants';
import IbOperatorGroupsWidget from '../IbOperatorGroupsWidget';
import IbPhoneNumberInput from '../common/IbPhoneNumberInput';
import { hasDuplicateUserName, isAxiosError } from '../../../utils/errorUtils';
import IbAlert, { IIbAlertProps } from '../common/IbAlert';
import IbSelect from '../common/IbSelect';
import IbSound from '../common/IbSound';
import { SOUND_OPTIONS } from '../common/IbSound/const';

import {
  BLOCK_BUTTON_CLASS_NAME,
  OPERATOR_DEFAULT,
  MAIN_CLASS_NAME,
  ONE_COLUMN_CLASS_NAME,
  TWO_COLUMN_CLASS_NAME,
  FORM_CLASS_NAME,
  FORM_BLOCK_CLASS_NAME,
  FORM_ITEM_CLASS_NAME,
  FORM_ITEM_CHECKBOXES_CLASS_NAME,
  FORM_ITEM_CHECKBOXES_ITEM_CLASS_NAME,
  FORM_EXTRA_CLASS_NAME,
  ALERT_CLOSING_TIMEOUT,
  FORM_ITEM_CONTROLS_CLASS_NAME,
} from './const';

interface IOperatorEditFormValidationResult {
  familyName: IFormFieldValidationResult;
  givenName: IFormFieldValidationResult;
  eMail: IFormFieldValidationResult;
  primaryTel: IFormFieldValidationResult;
}

interface IIbOperatorEditModalProps {
  visible?: boolean;
  readonly?: boolean;
  allowBlock?: boolean;
  allowEditRoles?: boolean;
  allowEditGroups?: boolean;
  allowEditSettings?: boolean;
  editOperator?: InboxParticipantModel;
  groupList?: InboxOperatorGroupModel[];
  onInvite?: (request: InboxParticipantInvitationRequest) => Promise<InboxParticipantModel | Error>;
  onEdit?: (request: InboxParticipantUpdatingRequest) => Promise<void>;
  onBlock?: () => Promise<boolean>;
  onCancel?: () => void;
}

const IbOperatorEditModal: React.FC<IIbOperatorEditModalProps> = ({
  visible,
  readonly,
  allowBlock,
  allowEditRoles,
  allowEditGroups,
  allowEditSettings,
  editOperator,
  groupList,
  onInvite,
  onEdit,
  onBlock,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();

  const [alert, setAlert] = useState<IIbAlertProps>();
  const [alertHidingTimer, setAlertHidingTimer] = useState<NodeJS.Timeout>();

  const [saving, setSaving] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [emailDublicated, setEmailDublicated] = useState(false);
  const [operator, setOperator] = useState(OPERATOR_DEFAULT);

  const validationResult: IOperatorEditFormValidationResult = {
    familyName: {
      isValid: !!(operator.subject.person.name.familyName || '').trim(),
      message: t('Enter family name'),
    },
    givenName: {
      isValid: !!(operator.subject.person.name.givenName || '').trim(),
      message: t('Enter given name'),
    },
    eMail: {
      isValid:
        !!operator.subject.person.contacts.email &&
        emailIsValid(operator.subject.person.contacts.email) &&
        !emailDublicated,
      message: t('Enter valid e-mail'),
    },
    primaryTel: {
      isValid:
        !operator.subject.person.contacts.primaryTel || phoneNumberIsValid(operator.subject.person.contacts.primaryTel),
      message: t('Enter valid phone number'),
    },
  };

  const formIsValid =
    validationResult.familyName.isValid &&
    validationResult.givenName.isValid &&
    validationResult.eMail.isValid &&
    validationResult.primaryTel.isValid;

  const hideAlert = () => setAlert(undefined);

  const showInviteErrorAlert = (error: Error) => {
    if (hasDuplicateUserName(error)) {
      setEmailDublicated(true);
      setAlert({ type: 'error', onClose: hideAlert, children: t('A user with the specified email already exists') });
    }
  };

  const onSaveButtonClick = async () => {
    setShowValidation(true);

    if (!editOperator || !formIsValid) {
      return;
    }

    setSaving(true);
    await onEdit?.({
      fullName: formatFullName(
        operator.subject.person.name.familyName,
        operator.subject.person.name.givenName,
        operator.subject.person.name.middleName
      ),
      familyName: operator.subject.person.name.familyName || '',
      givenName: operator.subject.person.name.givenName || '',
      middleName: operator.subject.person.name.middleName,
      shortName: formatShortName(
        operator.subject.person.name.familyName,
        operator.subject.person.name.givenName,
        operator.subject.person.name.middleName
      ),
      email: operator.subject.person.contacts.email || '',
      phoneNumber: operator.subject.person.contacts.primaryTel,
      roleNames: operator.subject.person.roles.map((r) => r.name),
      operatorGroupIds: operator.operatorGroups.map((g) => g.id),
      settings: operator.settings,
    });
    setSaving(false);
    onCancel?.();
  };

  const onInviteButtonClick = async () => {
    setShowValidation(true);

    if (!formIsValid) {
      return;
    }

    setSaving(true);
    const inviteResult = await onInvite?.({
      fullName: formatFullName(
        operator.subject.person.name.familyName,
        operator.subject.person.name.givenName,
        operator.subject.person.name.middleName
      ),
      familyName: operator.subject.person.name.familyName || '',
      givenName: operator.subject.person.name.givenName || '',
      middleName: operator.subject.person.name.middleName,
      shortName: formatShortName(
        operator.subject.person.name.familyName,
        operator.subject.person.name.givenName,
        operator.subject.person.name.middleName
      ),
      email: operator.subject.person.contacts.email || '',
      phoneNumber: operator.subject.person.contacts.primaryTel,
      roleNames: operator.subject.person.roles.map((r) => r.name),
      operatorGroupIds: operator.operatorGroups.map((g) => g.id),
      settings: operator.settings,
    });
    setSaving(false);

    if (isAxiosError(inviteResult)) {
      showInviteErrorAlert(inviteResult as Error);
      return;
    }

    onCancel?.();
  };

  const onBlockButtonClick = async () => {
    setSaving(true);
    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          status: (await onBlock?.())
            ? operator.subject.person.status === UserStatus.Blocked
              ? UserStatus.Active
              : UserStatus.Blocked
            : operator.subject.person.status,
        },
      },
    });
    setSaving(false);
  };

  const onFamilyNameChange = (value: string) =>
    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          name: {
            ...operator.subject.person.name,
            familyName: value,
          },
        },
      },
    });

  const onGivenNameChange = (value: string) =>
    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          name: {
            ...operator.subject.person.name,
            givenName: value,
          },
        },
      },
    });

  const onMiddleNameChange = (value: string) =>
    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          name: {
            ...operator.subject.person.name,
            middleName: value,
          },
        },
      },
    });

  const onEmailChange = (value: string) => {
    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          contacts: {
            ...operator.subject.person.contacts,
            email: value,
          },
        },
      },
    });
    setEmailDublicated(false);
  };

  const onPrimaryTelChange = (value: string) =>
    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          contacts: {
            ...operator.subject.person.contacts,
            primaryTel: value,
          },
        },
      },
    });

  const onRoleToggle = (roleName: string) => () => {
    const roles = operator.subject.person.roles.some((r) => r.name === roleName)
      ? operator.subject.person.roles.filter((r) => r.name !== roleName)
      : [...operator.subject.person.roles, { name: roleName } as PersonRole];

    setOperator({
      ...operator,
      subject: {
        ...operator.subject,
        person: {
          ...operator.subject.person,
          roles,
        },
      },
    });
  };

  const onGroupsChange = (value: InboxOperatorGroupModel[]) =>
    setOperator({
      ...operator,
      operatorGroups: value,
    });

  const onNotificationsEnabledChanged = (value: boolean) =>
    setOperator({
      ...operator,
      settings: {
        ...operator.settings,
        notifications: {
          ...operator.settings.notifications,
          enabled: value,
        },
      },
    });

  const onChatQueuedSoundChange = (value?: SelectValue) =>
    setOperator({
      ...operator,
      settings: {
        ...operator.settings,
        notifications: {
          ...operator.settings.notifications,
          chatQueuedSound: value as string,
        },
      },
    });

  const onChatAssignedSoundChange = (value?: SelectValue) =>
    setOperator({
      ...operator,
      settings: {
        ...operator.settings,
        notifications: {
          ...operator.settings.notifications,
          chatAssignedSound: value as string,
        },
      },
    });

  const onMessageReceivedSoundChange = (value?: SelectValue) =>
    setOperator({
      ...operator,
      settings: {
        ...operator.settings,
        notifications: {
          ...operator.settings.notifications,
          messageReceivedSound: value as string,
        },
      },
    });

  const onAlertChange = () => {
    if (alert) {
      !!alertHidingTimer && clearTimeout(alertHidingTimer);
      setAlertHidingTimer(setTimeout(() => hideAlert(), ALERT_CLOSING_TIMEOUT));
    }
  };
  useEffect(onAlertChange, [alert]);

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setSaving(false);
    setShowValidation(false);
    setEmailDublicated(false);
    setOperator(editOperator ? { ...editOperator } : OPERATOR_DEFAULT);
  };
  useEffect(onVisiblePropChange, [visible]);

  const renderBlockButton = () =>
    allowBlock ? (
      <IbButton
        className={BLOCK_BUTTON_CLASS_NAME}
        icon={<IbIcon iconName={operator.subject.person.status === UserStatus.Blocked ? 'unlock' : 'lock'} />}
        type="link"
        onClick={onBlockButtonClick}
      >
        {operator.subject.person.status === UserStatus.Blocked ? t('Unlock') : t('Block')}
      </IbButton>
    ) : null;

  const title = readonly ? t('Operator') : editOperator ? t('Edit operator') : t('Invite operator');
  const footer = readonly ? (
    <>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Close')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Close')}
      </IbButton>
    </>
  ) : editOperator ? (
    <>
      <IbButton disabled={saving} onClick={onSaveButtonClick}>
        {t('Save')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
      {renderBlockButton()}
    </>
  ) : (
    <>
      <IbButton disabled={saving || !!operator.subject.person.invitationResult} onClick={onInviteButtonClick}>
        {t('Invite')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
    </>
  );

  const renderMainBlock = () => {
    return (
      <div className={FORM_BLOCK_CLASS_NAME}>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Family name')}</IbTypography.Paragraph>
          <IbInput
            disabled={readonly}
            status={showValidation && !validationResult.familyName.isValid ? 'error' : 'default'}
            value={operator.subject.person.name.familyName || undefined}
            onChange={onFamilyNameChange}
          />
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Given name')}</IbTypography.Paragraph>
          <IbInput
            disabled={readonly}
            status={showValidation && !validationResult.givenName.isValid ? 'error' : 'default'}
            value={operator.subject.person.name.givenName || undefined}
            onChange={onGivenNameChange}
          />
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Middle name')}</IbTypography.Paragraph>
          <IbInput
            disabled={readonly}
            value={operator.subject.person.name.middleName || undefined}
            onChange={onMiddleNameChange}
          />
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Phone number')}</IbTypography.Paragraph>
          <IbPhoneNumberInput
            disabled={readonly}
            status={showValidation && !validationResult.primaryTel.isValid ? 'error' : 'default'}
            value={operator.subject.person.contacts.primaryTel || undefined}
            onChange={onPrimaryTelChange}
          />
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('E-mail')}</IbTypography.Paragraph>
          <IbInput
            disabled={readonly || !!editOperator}
            status={showValidation && !validationResult.eMail.isValid ? 'error' : 'default'}
            value={operator.subject.person.contacts.email || undefined}
            onChange={onEmailChange}
          />
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Role')}</IbTypography.Paragraph>
          <div className={FORM_ITEM_CHECKBOXES_CLASS_NAME}>
            {[USER_ROLES.inboxOperator, USER_ROLES.inboxSupervisor, USER_ROLES.admin].map((roleName) => (
              <div key={roleName} className={FORM_ITEM_CHECKBOXES_ITEM_CLASS_NAME}>
                <IbCheckbox
                  disabled={readonly || !allowEditRoles}
                  value={operator.subject.person.roles.some((r) => r.name === roleName)}
                  onChange={onRoleToggle(roleName)}
                />
                <span>{getRoleNameLabel(roleName)}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Groups')}</IbTypography.Paragraph>
          <IbOperatorGroupsWidget
            groupList={groupList}
            readonly={readonly || !allowEditGroups}
            selectedGroups={operator.operatorGroups}
            onChange={onGroupsChange}
          />
        </div>
        {!!editOperator && (
          <div className={FORM_EXTRA_CLASS_NAME}>
            <IbTypography.Paragraph disabled type="descriptor">
              {t('Invitation date')}: {formatDateFull(editOperator.createdOn, i18n.language)}
            </IbTypography.Paragraph>
            <IbTypography.Paragraph disabled type="descriptor">
              {t('Edit date')}: {formatDateFull(editOperator.modifiedOn, i18n.language)}
            </IbTypography.Paragraph>
          </div>
        )}
      </div>
    );
  };

  const renderSettingsBlock = () => {
    if (!allowEditSettings) {
      return null;
    }

    return (
      <div className={FORM_BLOCK_CLASS_NAME}>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Sound notifications')}</IbTypography.Paragraph>
          <div className={FORM_ITEM_CHECKBOXES_CLASS_NAME}>
            <div className={FORM_ITEM_CHECKBOXES_ITEM_CLASS_NAME}>
              <IbCheckbox value={operator.settings.notifications.enabled} onChange={onNotificationsEnabledChanged} />
              <span>{t('Enabled (plural)')}</span>
            </div>
          </div>
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Chat queued sound')}</IbTypography.Paragraph>
          <div className={FORM_ITEM_CONTROLS_CLASS_NAME}>
            <IbSelect
              disabled={!operator.settings.notifications.enabled}
              options={SOUND_OPTIONS}
              value={operator.settings.notifications.chatQueuedSound || undefined}
              onChange={onChatQueuedSoundChange}
            />
            <IbSound
              interactive
              defaultValue={operator.settings.notifications.chatQueuedSound || undefined}
              disabled={!operator.settings.notifications.enabled}
            />
          </div>
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Chat assigned sound')}</IbTypography.Paragraph>
          <div className={FORM_ITEM_CONTROLS_CLASS_NAME}>
            <IbSelect
              disabled={!operator.settings.notifications.enabled}
              options={SOUND_OPTIONS}
              value={operator.settings.notifications.chatAssignedSound || undefined}
              onChange={onChatAssignedSoundChange}
            />
            <IbSound
              interactive
              defaultValue={operator.settings.notifications.chatAssignedSound}
              disabled={!operator.settings.notifications.enabled}
            />
          </div>
        </div>
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Message received sound')}</IbTypography.Paragraph>
          <div className={FORM_ITEM_CONTROLS_CLASS_NAME}>
            <IbSelect
              disabled={!operator.settings.notifications.enabled}
              options={SOUND_OPTIONS}
              value={operator.settings.notifications.messageReceivedSound || undefined}
              onChange={onMessageReceivedSoundChange}
            />
            <IbSound
              interactive
              defaultValue={operator.settings.notifications.messageReceivedSound}
              disabled={!operator.settings.notifications.enabled}
            />
          </div>
        </div>
      </div>
    );
  };

  const classes = [MAIN_CLASS_NAME, allowEditSettings ? TWO_COLUMN_CLASS_NAME : ONE_COLUMN_CLASS_NAME];

  return (
    <IbModal
      className={classes.join(' ')}
      footer={footer}
      loading={saving}
      position={IbModalPosition.FixedTall}
      title={title}
      visible={visible}
      onCancel={onCancel}
    >
      {!!alert && <IbAlert {...alert} />}
      <div className={FORM_CLASS_NAME}>
        {renderMainBlock()}
        {renderSettingsBlock()}
      </div>
      {renderBlockButton()}
    </IbModal>
  );
};

export default IbOperatorEditModal;
