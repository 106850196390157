import React from 'react';
import { Col, Radio, Row, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { RadioChangeEvent } from 'antd/lib/radio';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import moment, { Moment } from 'moment';
import './index.less';

import {
  BinaryConditionOperator,
  ConditionSchema,
  ConstantOperandSchema,
  OperandSchema,
  SchemaKind,
  UnaryConditionOperator,
  VariableOperandSchema,
  VariableType,
} from '../../../../../../../../api';
import SbSelect from '../../../../../../../simple-bot/components/common/SbSelect';
import SbButton from '../../../../../../../simple-bot/components/common/SbButton';
import SbIcon from '../../../../../../../simple-bot/components/common/SbIcon';
import SbInput from '../../../../../../../simple-bot/components/common/SbInput';
import { currentScenarioStructureSelector } from '../../../../../../../recoil/scenarioStructure';
import {
  BinaryConditionOperators,
  BooleanItems,
  ConditionOperators,
  UnaryConditionOperators,
} from '../../../../../constants';
import {
  generateId,
  instanceOfBinaryConditionSchema,
  instanceOfConstantOperandSchema,
  instanceOfUnaryConditionSchema,
  instanceOfVariableOperandSchema,
} from '../../../../../utils';
import {
  getVariableListSelectItems,
  getVariableSelectItems,
  getVariableSubSelectItems,
  getVariableTreeSelectItems,
  SelectItem,
  tryGetVariableType,
} from '../utils';
import SbDatePicker from '../../../../../../../simple-bot/components/common/SbDatePicker';
import { AlertTypes } from '../../../../../../../constants';
import { alertsSelectorAdd } from '../../../../../../../recoil/alerts';
import { VariableIcon } from '../../../../../assets';

const TABLE_GUTTER_SIZE = 12;
const RADIO_BUTTONS_WIDTH = 108;
const OPERATOR_COLUMN_WIDTH = 200;
const LEFT_OPERAND_COLUMN_WIDTH = 400;
const RIGHT_OPERAND_COLUMN_WIDTH = 400;
const BUTTON_COLUMN_WIDTH = 27;
const MAIN_CLASS_NAME = 'main-case-action-condition-editor';

const Option = { Select };

interface IConditionEditorProps {
  condition: ConditionSchema;
  onChange: (condition: ConditionSchema) => void;
  onDelete: (condition: ConditionSchema) => void;
}

export enum InputType {
  CONST = 'constant',
  VAR = 'variable',
}

const ConditionEditor: React.FC<IConditionEditorProps> = ({ condition, onChange, onDelete }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);

  const variableSelectItems = getVariableSelectItems(scenarioStructure?.variables);
  const variableListSelectItems = getVariableListSelectItems(scenarioStructure?.variables);
  const variableTreeSelectItems = getVariableTreeSelectItems(scenarioStructure?.variables);
  const getInputType = () =>
    instanceOfBinaryConditionSchema(condition) && instanceOfConstantOperandSchema(condition.rightOperand)
      ? InputType.CONST
      : InputType.VAR;

  const tryResetConstantOperand = (constantOperand: OperandSchema, variableOperand: OperandSchema) => {
    if (!instanceOfConstantOperandSchema(constantOperand)) {
      return constantOperand;
    }
    const variableType = tryGetVariableType(variableOperand, variableListSelectItems);
    switch (variableType) {
      case VariableType.String:
        constantOperand.value = '';
        break;
      case VariableType.Number:
        constantOperand.value = 0;
        break;
      case VariableType.Boolean:
        constantOperand.value = false;
        break;
      case VariableType.DateTime:
        constantOperand.value = moment().format(moment.HTML5_FMT.DATE);
        break;
      default:
        constantOperand.value = undefined;
        break;
    }
    return constantOperand;
  };

  const onConstantOperandChange = (operand: ConstantOperandSchema, value: unknown) => {
    if (instanceOfUnaryConditionSchema(condition)) {
      const newCondition = {
        ...condition,
        operand: {
          ...operand,
          value,
        },
      };
      onChange(newCondition);
      return;
    }

    if (instanceOfBinaryConditionSchema(condition)) {
      const newOperand = { ...operand, value, $kind: SchemaKind.ConstantOperand };
      const newCondition = {
        ...condition,
        leftOperand: operand === condition.leftOperand ? newOperand : condition.leftOperand,
        rightOperand: operand === condition.rightOperand ? newOperand : condition.rightOperand,
      };
      onChange(newCondition);
      return;
    }

    addAlert({
      type: AlertTypes.ERROR,
      message: `Неизвестный тип условия '${condition.$kind}'.`,
    });
  };

  const onStringConstantOperandChange = (operand: ConstantOperandSchema) => (value: string) => {
    onConstantOperandChange(operand, value);
  };

  const onNumberConstantOperandChange = (operand: ConstantOperandSchema) => (value: string) => {
    const numberValue = value ? parseInt(value) || 0 : 0;
    onConstantOperandChange(operand, numberValue);
  };

  const onBooleanConstantOperandChange = (operand: ConstantOperandSchema) => (value: SelectValue) => {
    const booleanValue = (value as string) === BooleanItems[1].value;
    onConstantOperandChange(operand, booleanValue);
  };

  const onDateTimeConstantOperandChange = (operand: ConstantOperandSchema) => (value: Moment | null) => {
    const dateTimeValue = (value || moment()).format(moment.HTML5_FMT.DATE);
    onConstantOperandChange(operand, dateTimeValue);
  };

  const onVariableOperandChange = (operand: VariableOperandSchema) => (value: SelectValue) => {
    const selectedValues = value as string[];
    let availableValues: string[] = [];
    for (let index = 0; index < selectedValues.length; index++) {
      const selectValues = selectedValues.slice(0, index + 1);
      const selectValue = selectValues.join('.');
      if (variableListSelectItems.some((i) => i.value === selectValue)) {
        availableValues = selectValues;
      }
    }

    const variableId = availableValues[0];
    const propertyPath = availableValues.length > 1 ? availableValues.slice(1).join('.') : undefined;
    if (instanceOfUnaryConditionSchema(condition)) {
      const newCondition = {
        ...condition,
        operand: {
          ...operand,
          variableId,
          propertyPath,
        },
      };
      onChange(newCondition);
      return;
    }

    if (instanceOfBinaryConditionSchema(condition)) {
      const newOperand = { ...operand, variableId, propertyPath, $kind: SchemaKind.VariableOperand };
      const newCondition = {
        ...condition,
        leftOperand:
          operand === condition.leftOperand ? newOperand : tryResetConstantOperand(condition.leftOperand, newOperand),
        rightOperand:
          operand === condition.rightOperand ? newOperand : tryResetConstantOperand(condition.rightOperand, newOperand),
      };
      onChange(newCondition);
      return;
    }

    addAlert({
      type: AlertTypes.ERROR,
      message: `Неизвестный тип условия '${condition.$kind}'.`,
    });
  };

  const onUnaryOperatorChange = (value: SelectValue) => {
    const operator = value as UnaryConditionOperator;
    if (instanceOfUnaryConditionSchema(condition)) {
      const newCondition = {
        ...condition,
        operator,
      };
      onChange(newCondition);
      return;
    }

    if (instanceOfBinaryConditionSchema(condition)) {
      const newCondition = {
        id: condition.id,
        $kind: SchemaKind.UnaryCondition,
        operator,
        operand: condition.leftOperand,
      };
      onChange(newCondition);
      return;
    }

    addAlert({
      type: AlertTypes.ERROR,
      message: `Неизвестный тип условия '${condition.$kind}'.`,
    });
  };

  const onBinaryOperatorChange = (value: SelectValue) => {
    const operator = value as BinaryConditionOperator;
    if (instanceOfUnaryConditionSchema(condition)) {
      const newCondition = {
        id: condition.id,
        $kind: SchemaKind.BinaryCondition,
        operator,
        leftOperand: condition.operand,
        rightOperand: {
          id: generateId('OPD'),
          $kind: SchemaKind.ConstantOperand,
          value: undefined,
          variableId: undefined,
        },
      };
      onChange(newCondition);
      return;
    }

    if (instanceOfBinaryConditionSchema(condition)) {
      const newCondition = {
        ...condition,
        operator,
        leftOperand: tryResetConstantOperand(condition.leftOperand, condition.rightOperand),
        rightOperand: tryResetConstantOperand(condition.rightOperand, condition.leftOperand),
      };
      onChange(newCondition);
      return;
    }

    addAlert({
      type: AlertTypes.ERROR,
      message: `Неизвестный тип условия '${condition.$kind}'.`,
    });
  };

  const onOperatorChange = (value: SelectValue) => {
    if (UnaryConditionOperators.find((o) => o.value === value)) {
      onUnaryOperatorChange(value);
      return;
    }

    if (BinaryConditionOperators.find((o) => o.value === value)) {
      onBinaryOperatorChange(value);
      return;
    }

    addAlert({
      type: AlertTypes.ERROR,
      message: `Неизвестный тип условия '${condition.$kind}'.`,
    });
  };

  const onInputTypeChange = (operand: OperandSchema) => (e: RadioChangeEvent) => {
    const newOperand =
      e.target.value == InputType.CONST
        ? { ...operand, value: '', $kind: SchemaKind.ConstantOperand }
        : { ...operand, variableId: undefined, propertyPath: undefined, $kind: SchemaKind.VariableOperand };
    const newCondition = {
      ...condition,
      rightOperand: newOperand,
    };
    onChange(newCondition);
  };

  const renderVariableOperand = (operand: VariableOperandSchema, width: number) => {
    const selectedValues: string[] = [];
    if (operand.variableId) {
      selectedValues.push(operand.variableId);
    }
    if (operand.propertyPath) {
      selectedValues.push(...operand.propertyPath.split('.'));
    }

    const selectedValue = selectedValues.join('.');
    const selectedItem = variableListSelectItems.find((i) => i.value === selectedValue);
    const availableItems = selectedItem ? getVariableSubSelectItems(selectedItem.type) : variableSelectItems;
    const selectItems = [
      ...selectedValues.map((v) => variableTreeSelectItems.find((i) => i.value === v) as SelectItem),
      ...availableItems.filter((i) => !selectedValues.includes(i.value)),
    ];

    const selectOptions = selectItems.map((selectItem) => {
      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Option key={selectItem.value} label={selectItem.label} title={selectItem.label} value={selectItem.value}>
          {selectItem.label}
        </Option>
      );
    });

    return (
      <SbSelect
        mode="tags"
        placeholder="Выберите переменную"
        sbSize="big"
        sbType="light"
        style={{ width: `${width - TABLE_GUTTER_SIZE}px` }}
        value={selectedValues}
        onChange={onVariableOperandChange(operand)}
      >
        {selectOptions}
      </SbSelect>
    );
  };

  const renderStringConstantOperand = (operand: ConstantOperandSchema, width: number) => {
    const stringValue = `${operand.value ?? ''}`;
    return (
      <SbInput
        placeholder="Укажите значение"
        style={{ width: `${width - TABLE_GUTTER_SIZE}px` }}
        value={stringValue}
        onChange={onStringConstantOperandChange(operand)}
      />
    );
  };

  const renderNumberConstantOperand = (operand: ConstantOperandSchema, width: number) => {
    const stringValue = operand.value ? `${operand.value}` : '0';
    return (
      <SbInput
        style={{ width: `${width - TABLE_GUTTER_SIZE}px` }}
        value={stringValue}
        onChange={onNumberConstantOperandChange(operand)}
      />
    );
  };

  const renderBooleanConstantOperand = (operand: ConstantOperandSchema, width: number) => {
    const stringValue = `${operand.value}`;
    const selectItem = BooleanItems.find((i) => i.value === stringValue) || BooleanItems[0];
    return (
      <SbSelect
        options={BooleanItems}
        placeholder="Укажите значение"
        sbType="light"
        style={{ width: `${width - TABLE_GUTTER_SIZE}px` }}
        value={selectItem.value}
        onChange={onBooleanConstantOperandChange(operand)}
      />
    );
  };

  const renderDateTimeConstantOperand = (operand: ConstantOperandSchema, width: number) => {
    const momentValue = operand.value ? moment(`${operand.value}`) : moment();
    return (
      <SbDatePicker
        format="DD.MM.YYYY"
        picker="date"
        style={{ width: `${width - TABLE_GUTTER_SIZE}px` }}
        value={momentValue}
        onChange={onDateTimeConstantOperandChange(operand)}
      />
    );
  };

  const renderConstantOperand = (operand: ConstantOperandSchema, width: number) => {
    const variableType = tryGetVariableType(condition, variableListSelectItems);
    if (variableType === VariableType.String) {
      return renderStringConstantOperand(operand, width);
    }
    if (variableType === VariableType.Number) {
      return renderNumberConstantOperand(operand, width);
    }
    if (variableType === VariableType.Boolean) {
      return renderBooleanConstantOperand(operand, width);
    }
    if (variableType === VariableType.DateTime) {
      return renderDateTimeConstantOperand(operand, width);
    }
  };

  const renderRightOperandMarkup = (operand: OperandSchema, width: number) => {
    return (
      <Col flex={`${width}px`}>
        <Radio.Group
          buttonStyle="solid"
          className={`${MAIN_CLASS_NAME}__radio-buttons`}
          value={getInputType()}
          onChange={onInputTypeChange(operand)}
        >
          <Radio.Button value={InputType.CONST}>
            <SbIcon iconName="add-text" size={20} style={{ display: 'block' }} />
          </Radio.Button>
          <div className={`${MAIN_CLASS_NAME}__radio-buttons__divider`} />
          <Radio.Button value={InputType.VAR}>
            <div className={`${MAIN_CLASS_NAME}__radio-buttons__variable-icon`}>
              <VariableIcon />
            </div>
          </Radio.Button>
          <div className={`${MAIN_CLASS_NAME}__radio-buttons__divider`} />
          <div hidden={getInputType() != InputType.CONST}>
            {renderConstantOperand(operand as ConstantOperandSchema, width)}
          </div>
          <div hidden={getInputType() != InputType.VAR}>
            {renderVariableOperand(operand as VariableOperandSchema, width)}
          </div>
        </Radio.Group>
      </Col>
    );
  };

  const renderRightOperand = (operand: OperandSchema, width: number) => {
    const variableType = tryGetVariableType(condition, variableListSelectItems);
    if (!variableType) {
      return <Col flex={`${width + RADIO_BUTTONS_WIDTH}px`} />;
    }
    if (
      variableType === VariableType.Boolean ||
      variableType === VariableType.DateTime ||
      variableType === VariableType.Number ||
      variableType === VariableType.String
    ) {
      return renderRightOperandMarkup(operand, width);
    }
  };

  const renderLeftOperand = (operand: OperandSchema, width: number) => {
    if (instanceOfConstantOperandSchema(operand)) {
      return renderConstantOperand(operand, width);
    }
    if (instanceOfVariableOperandSchema(operand)) {
      return <Col flex={`${width}px`}>{renderVariableOperand(operand, width)}</Col>;
    }
    throw new Error(`Неизвестный тип операнда '${operand.$kind}'.`);
  };

  const renderUnaryOperator = (operator: UnaryConditionOperator) => {
    return (
      <Col flex={`${OPERATOR_COLUMN_WIDTH}px`}>
        <SbSelect
          options={ConditionOperators}
          placeholder="Укажите оператор"
          sbSize="big"
          sbType="light"
          style={{ maxWidth: `${OPERATOR_COLUMN_WIDTH - TABLE_GUTTER_SIZE}px` }}
          value={operator}
          onChange={onOperatorChange}
        />
      </Col>
    );
  };

  const renderBinaryOperator = (operator: BinaryConditionOperator) => {
    return (
      <Col flex={`${OPERATOR_COLUMN_WIDTH}px`}>
        <SbSelect
          options={ConditionOperators}
          placeholder="Укажите оператор"
          sbSize="big"
          sbType="light"
          style={{ maxWidth: `${OPERATOR_COLUMN_WIDTH - TABLE_GUTTER_SIZE}px` }}
          value={operator}
          onChange={onOperatorChange}
        />
      </Col>
    );
  };

  const onDeleteClick = () => {
    onDelete(condition);
  };

  if (instanceOfUnaryConditionSchema(condition)) {
    return (
      <Row align="middle" className={MAIN_CLASS_NAME} gutter={[TABLE_GUTTER_SIZE, TABLE_GUTTER_SIZE]}>
        {renderLeftOperand(condition.operand, LEFT_OPERAND_COLUMN_WIDTH)}
        {renderUnaryOperator(condition.operator)}
        <Col flex={`${RIGHT_OPERAND_COLUMN_WIDTH}px`} />
        <Col flex={`${BUTTON_COLUMN_WIDTH}px`} style={{ paddingRight: '0' }}>
          <SbButton icon={<SbIcon iconName="close" size={21} />} sbType="icon-only" onClick={onDeleteClick} />
        </Col>
      </Row>
    );
  }

  if (instanceOfBinaryConditionSchema(condition)) {
    return (
      <Row align="middle" className={MAIN_CLASS_NAME} gutter={[TABLE_GUTTER_SIZE, TABLE_GUTTER_SIZE]}>
        {renderLeftOperand(condition.leftOperand, LEFT_OPERAND_COLUMN_WIDTH)}
        {renderBinaryOperator(condition.operator)}
        {renderRightOperand(condition.rightOperand, RIGHT_OPERAND_COLUMN_WIDTH)}
        <Col flex={`${BUTTON_COLUMN_WIDTH}px`} style={{ paddingRight: '0' }}>
          <SbButton icon={<SbIcon iconName="close" size={21} />} sbType="icon-only" onClick={onDeleteClick} />
        </Col>
      </Row>
    );
  }

  throw new Error(`Неизвестный тип условия '${condition.$kind}'.`);
};

export default ConditionEditor;
