import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import './index.less';

import { scenarioStructuresSelector } from '../../../../../recoil/scenarioStructure';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbTable from '../../../../../simple-bot/components/common/SbTable';
import { ScenarioActionNames } from '../../../constants';
import { IScenarioEntity } from '../../../types';

interface ITriggerViewProps {
  visible: boolean;
  actions: IScenarioEntity[];
  onClose?: () => void;
}

const TriggerView: React.FC<ITriggerViewProps> = ({ visible, actions, onClose = () => {} }) => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const scenarios = useRecoilValue(scenarioStructuresSelector);

  const onCancel = () => onClose();

  const onClickTransitionScenario = (action: IScenarioEntity) => {
    scenarios
      .filter((scenario) => scenario.name === action.scenarioName)
      .forEach((scenario) => push(`/simple-bots/${id}/scenario/${scenario.id}`));
  };

  const actionColumns = [
    {
      render: (_: string, action: IScenarioEntity) => {
        return (
          <div className="sb-trigger-view__table-row">
            <div className="sb-trigger-view__table-row__content">
              <div className="sb-trigger-view__table-row__col-name">
                <p>
                  <b>{action.scenarioName}</b>
                </p>
                <p>{ScenarioActionNames[action.item.$kind]}</p>
              </div>
              <div className="sb-trigger-view__table-row__col-buttons">
                <button
                  className="ant-btn sb-button sb-button_tertiary sb-button_medium sb-trigger-view__table-row__link"
                  onClick={() => onClickTransitionScenario(action)}
                >
                  Открыть сценарий
                </button>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <SbModal
      footer={[
        <SbButton key="ok" sbSize="medium" sbType="secondary" onClick={onCancel}>
          Закрыть
        </SbButton>,
      ]}
      sbSize="small"
      title="Список сценариев"
      visible={visible}
      onCancel={onCancel}
    >
      <p>Этот сценарий запустится при переходе из другого сценария</p>
      <SbTable columns={actionColumns} dataSource={actions} header={false} />
    </SbModal>
  );
};

export default TriggerView;
