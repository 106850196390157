import React, { useEffect, useRef, useState } from 'react';
import { Col, Menu, Popover, Row, Select, TablePaginationConfig, Typography } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useDebounce } from 'usehooks-ts';
import { SelectValue } from 'antd/es/select';

import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../../../constants';
import {
  DataEntryModel,
  DataEntrySortDirection,
  DataType,
  KnowledgeSourceModel,
  ListDataEntryModel,
  SingleKnowledgeBaseModel,
  StageModel,
} from '../../../../../kb-api';
import { dataEntryKbApi } from '../../../../apis';
import SbScroll from '../../../components/common/SbScroll';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import SbPagination from '../../../components/common/SbPagination';
import SbSpin from '../../../components/common/SbSpin';
import SbButton from '../../../components/common/SbButton';
import SbIcon, { IconName } from '../../../components/common/SbIcon';
import SbInput from '../../../components/common/SbInput';
import SbContextMenu from '../../../components/common/SbContextMenu';
import SbSelect from '../../../components/common/SbSelect';
import { getKnowledgeSources } from '../../../utils/knowledgeBase';
import { getKnowledgeSourceIcon } from '../../../utils/render';

import DataEntryItem from './DataEntryItem';

const SEARCH_DELAY = 200; //ms
const FILTER_MENU_OFFSET = [12, 8];
const CONTEXT_FILTER_VALUES = [
  {
    value: 'all',
    apiValue: undefined,
    label: 'Все',
  },
  {
    value: 'onlyRootEntries',
    apiValue: true,
    label: 'Без уточняющих',
  },
];

interface IDataEntryListProps {
  knowledgeBase?: SingleKnowledgeBaseModel;
  dataUpdating: boolean;
  onImportKnowledgeSource?: () => void;
  onEditRecord?: (dataEntry: DataEntryModel) => void;
  onDeleteRecord?: (dataEntry: DataEntryModel) => void;
  onAddRecord?: () => void;
  getDraftStage: () => Promise<StageModel | undefined>;
  getDataEntryIdByVersion: (versionId: string, dataEntryId: string) => Promise<string | undefined>;
  onDataChanged: () => void;
}

const DataEntryList: React.FC<IDataEntryListProps> = ({
  knowledgeBase,
  dataUpdating,
  onImportKnowledgeSource = () => {},
  onEditRecord = () => {},
  onDeleteRecord = () => {},
  onAddRecord = () => {},
  getDraftStage,
  getDataEntryIdByVersion,
  onDataChanged,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const scrollContainer = useRef<HTMLElement>();
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [filterPopoverVisible, setFilterPopoverVisible] = useState(false);

  const [listDataEntries, setListDataEntries] = useState([] as ListDataEntryModel[]);
  const [searchText, setSearchText] = useState('');
  const [selectedKnowledgeSource, setSelectedKnowledgeSource] = useState<KnowledgeSourceModel>();
  const [selectedContext, setSelectedContext] = useState<typeof CONTEXT_FILTER_VALUES[number]>();
  const [filter, setFilter] = useState<{ knowledgeSourceId?: string; onlyRootEntries?: boolean }>({
    knowledgeSourceId: undefined,
    onlyRootEntries: undefined,
  });
  const [sortMode, setSortMode] = useState(DataEntrySortDirection.AnswerAscending);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const version = knowledgeBase?.draftVersion || knowledgeBase?.originVersion;
  const reportInstance = knowledgeBase?.draftInstance || knowledgeBase?.originInstance;
  const knowledgeSources = getKnowledgeSources(knowledgeBase);

  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';

  const loadDataAsync = async (withLoader: boolean) => {
    withLoader && setLoading(true);
    try {
      let items = [] as ListDataEntryModel[];
      if (version?.editionId) {
        const response = await dataEntryKbApi.searchDataEntries(
          reportInstance?.id,
          version.editionId,
          filter.knowledgeSourceId,
          DataType.QnA,
          filter.onlyRootEntries,
          deferredSearchText,
          undefined,
          undefined,
          sortMode,
          (pagination.current ?? 1) - 1,
          pagination.pageSize
        );
        items = response.data.items || [];
        setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
      }
      setListDataEntries(items);
      withLoader && scrollContainer.current && (scrollContainer.current.scrollTop = 0);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка записей редакции',
        error: e,
      });
    }
    setDataLoaded(true);
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync(true).finally();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize, sortMode, deferredSearchText, filter]);

  const onVersionChange = () => {
    if (!dataLoaded) return;
    loadDataAsync(false).finally();
  };
  useEffect(onVersionChange, [version]);

  const onPaginationChange = (page: number) => setPagination({ ...pagination, current: page });

  const onKnowledgeSourcesChange = () => {
    if (selectedKnowledgeSource && !knowledgeSources.find((ks) => ks.id === selectedKnowledgeSource.id)) {
      const foundKnowledgeSource = knowledgeSources.find(
        (ks) => ks.sourceKnowledgeSourceId === selectedKnowledgeSource.id
      );
      setSelectedKnowledgeSource(foundKnowledgeSource);
      setFilter({ ...filter, knowledgeSourceId: foundKnowledgeSource?.id });
    }
  };
  useEffect(onKnowledgeSourcesChange, [knowledgeSources]);

  const addDocumentMenuContent = (
    <Menu>
      <Menu.Item key="add-record" onClick={onAddRecord}>
        Добавить запись
      </Menu.Item>
      <Menu.Item key="import-knowledge-source" onClick={onImportKnowledgeSource}>
        Импорт документа
      </Menu.Item>
    </Menu>
  );

  const getCurrentSortingIconName: () => IconName = () => {
    switch (sortMode) {
      case DataEntrySortDirection.AnswerAscending:
        return 'alphabetical-sorting';
      case DataEntrySortDirection.AnswerDescending:
        return 'alphabetical-sorting-two';
      case DataEntrySortDirection.CreatedOnDescending:
      case DataEntrySortDirection.ModifiedOnDescending:
        return 'sort-amount-down';
      case DataEntrySortDirection.CreatedOnAscending:
      case DataEntrySortDirection.ModifiedOnAscending:
        return 'sort-amount-up';
      default:
        return 'attention';
    }
  };

  const renderSortingMenuItemChecked = (itemSortMode: DataEntrySortDirection) => {
    return itemSortMode === sortMode ? <SbIcon iconName="check-small" /> : <div style={{ width: 24 }} />;
  };

  const onSortingModeChange = (newSortMode: DataEntrySortDirection) => {
    setSortMode(newSortMode);
    setPagination({ ...pagination, current: 1 });
  };

  const onAnswerAscendingClick = () => {
    onSortingModeChange(DataEntrySortDirection.AnswerAscending);
  };

  const onAnswerDescendingClick = () => {
    onSortingModeChange(DataEntrySortDirection.AnswerDescending);
  };

  const onCreatedOnDescendingClick = () => {
    onSortingModeChange(DataEntrySortDirection.CreatedOnDescending);
  };

  const onModifiedOnDescendingClick = () => {
    onSortingModeChange(DataEntrySortDirection.ModifiedOnDescending);
  };

  const onCreatedOnAscendingClick = () => {
    onSortingModeChange(DataEntrySortDirection.CreatedOnAscending);
  };

  const onModifiedOnAscendingClick = () => {
    onSortingModeChange(DataEntrySortDirection.ModifiedOnAscending);
  };

  const onFilterPopoverVisibleChange = (visible: boolean) => {
    setFilterPopoverVisible(visible);
    setSelectedKnowledgeSource(knowledgeSources.find((ks) => ks.id === filter.knowledgeSourceId));
    setSelectedContext(CONTEXT_FILTER_VALUES.find((e) => e.apiValue === filter.onlyRootEntries));
  };

  const onKnowledgeSourceChange = (value: SelectValue) =>
    setSelectedKnowledgeSource(knowledgeSources.find((e) => e.id === (value as string)));

  const onContextChange = (value: SelectValue) =>
    setSelectedContext(CONTEXT_FILTER_VALUES.find((e) => e.value === (value as string)));

  const onApplyFilter = () => {
    setPagination({ ...pagination, current: 1 });
    setFilter({ knowledgeSourceId: selectedKnowledgeSource?.id, onlyRootEntries: selectedContext?.apiValue });
    setFilterPopoverVisible(false);
  };

  const onResetFilter = () => {
    setPagination({ ...pagination, current: 1 });
    setFilter({ knowledgeSourceId: undefined, onlyRootEntries: undefined });
    setFilterPopoverVisible(false);
  };

  const sortingMenuContent = (
    <Menu>
      <Menu.Item
        key="sort-by-name-ascending"
        icon={renderSortingMenuItemChecked(DataEntrySortDirection.AnswerAscending)}
        onClick={onAnswerAscendingClick}
      >
        По тексту ответа от А до Я
      </Menu.Item>
      <Menu.Item
        key="sort-by-name-descending"
        icon={renderSortingMenuItemChecked(DataEntrySortDirection.AnswerDescending)}
        onClick={onAnswerDescendingClick}
      >
        По тексту ответа от Я до А
      </Menu.Item>
      <Menu.Item
        key="sort-most-recently-added"
        icon={renderSortingMenuItemChecked(DataEntrySortDirection.CreatedOnDescending)}
        onClick={onCreatedOnDescendingClick}
      >
        Последние добавленные
      </Menu.Item>
      <Menu.Item
        key="sort-most-recently-modified"
        icon={renderSortingMenuItemChecked(DataEntrySortDirection.ModifiedOnDescending)}
        onClick={onModifiedOnDescendingClick}
      >
        Последние измененные
      </Menu.Item>
      <Menu.Item
        key="sort-least-recently-added"
        icon={renderSortingMenuItemChecked(DataEntrySortDirection.CreatedOnAscending)}
        onClick={onCreatedOnAscendingClick}
      >
        Первые добавленные
      </Menu.Item>
      <Menu.Item
        key="sort-least-recently-modified"
        icon={renderSortingMenuItemChecked(DataEntrySortDirection.ModifiedOnAscending)}
        onClick={onModifiedOnAscendingClick}
      >
        Первые измененные
      </Menu.Item>
    </Menu>
  );

  const filterMenuContent = (
    <div>
      <h2>Фильтровать</h2>
      <h3>Источник</h3>
      <SbSelect
        optionLabelProp="label"
        sbSize="small"
        sbType="light"
        value={selectedKnowledgeSource?.id}
        onChange={onKnowledgeSourceChange}
      >
        {knowledgeSources.map((knowledgeSource) => {
          const content = (
            <div className="sb-select__option">
              {getKnowledgeSourceIcon(knowledgeSource)}
              {knowledgeSource.name}
            </div>
          );
          return (
            <Select.Option key={knowledgeSource.id} label={content} value={knowledgeSource.id}>
              {content}
            </Select.Option>
          );
        })}
      </SbSelect>
      <h3>Уточняющие вопросы</h3>
      <SbSelect sbSize="small" sbType="light" value={selectedContext?.value} onChange={onContextChange}>
        {CONTEXT_FILTER_VALUES.map((e) => (
          <Select.Option key={e.value} value={e.value}>
            {e.label}
          </Select.Option>
        ))}
      </SbSelect>
      <div className="sb-data-entry-list-filter-menu__buttons">
        <SbButton sbSize="medium" onClick={onApplyFilter}>
          Показать
        </SbButton>
        <SbButton sbSize="medium" sbType="secondary" onClick={onResetFilter}>
          Сбросить фильтр
        </SbButton>
      </div>
    </div>
  );

  const onSearchChange = (value: string) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
  };

  const renderFilterSuffix = () => {
    return (
      <Popover
        destroyTooltipOnHide
        align={{ offset: FILTER_MENU_OFFSET }}
        content={filterMenuContent}
        overlayClassName="sb-data-entry-list-filter-menu"
        placement="bottomRight"
        trigger={['click']}
        visible={filterPopoverVisible}
        onVisibleChange={onFilterPopoverVisibleChange}
      >
        <SbIcon
          className={filter.knowledgeSourceId || filter.onlyRootEntries !== undefined ? 'badge' : ''}
          iconName="filter"
        />
      </Popover>
    );
  };

  const renderListContent = () => {
    if (!loading && !listDataEntries.length && !searchText) {
      return (
        <div className="sb-knowledge-base-card__content__row__entries__empty-results">
          <h4>Вопросы и ответы не добавлены</h4>
          <p>
            Добавьте вопросы и ответы, чтобы бот мог
            <br />
            автоматически давать ответы на заданные
            <br />
            пользователями вопросы
            <br />
          </p>
          <SbButton icon={<SbIcon iconName="plus" />} sbType="icon-secondary" onClick={onAddRecord}>
            Добавить запись
          </SbButton>
        </div>
      );
    }

    if (!loading && !listDataEntries.length && searchText) {
      return (
        <div className="sb-knowledge-base-card__content__row__entries__empty-results">
          <h4>Ничего не найдено</h4>
          <p>Нет записей, удовлетворяющих условиям поиска</p>
        </div>
      );
    }

    return (
      <SbScroll containerRefElement={scrollContainer}>
        <div className="sb-knowledge-base-card__content__row__entries__list-row__list">
          {listDataEntries.map((item, index) => {
            return (
              <DataEntryItem
                key={index} // NOTE: ключ по индексу, т.к. для отслеживания публикации компонент не должен пересоздаваться
                dataUpdating={dataUpdating}
                getDataEntryIdByVersion={getDataEntryIdByVersion}
                getDraftStage={getDraftStage}
                knowledgeBase={knowledgeBase}
                listDataEntry={item}
                onDataChanged={onDataChanged}
                onDelete={onDeleteRecord}
                onEdit={onEditRecord}
              />
            );
          })}
          {(pagination?.total ?? 0) > DEFAULT_PAGE_SIZE && (
            <Row
              align="middle"
              className="sb-knowledge-base-card__content__row__entries__list-row__list__pagination"
              justify="center"
            >
              <Col>
                <SbPagination
                  current={pagination.current}
                  defaultCurrent={1}
                  defaultPageSize={DEFAULT_PAGE_SIZE}
                  pageSize={DEFAULT_PAGE_SIZE}
                  showSizeChanger={false}
                  total={pagination.total}
                  onChange={onPaginationChange}
                />
              </Col>
            </Row>
          )}
        </div>
      </SbScroll>
    );
  };

  return (
    <>
      <Row align="middle" className="sb-knowledge-base-card__content__row__entries__controls-row">
        <Col className="col-1">
          <Row align="middle" wrap={false}>
            <Col>
              <Typography.Title level={3}>Вопросы и ответы</Typography.Title>
            </Col>
            <Col>
              <SbContextMenu menuContent={addDocumentMenuContent}>
                <div className="sb-knowledge-base-card__content__row__entries__controls-row__add-button">
                  <SbButton icon={<SbIcon iconName="plus" />} sbType="icon-primary-48" />
                </div>
              </SbContextMenu>
            </Col>
          </Row>
        </Col>
        <Col className="col-1 sb-knowledge-base-card__content__row__entries__controls-row__filter" flex="auto">
          <SbInput
            customSuffix={renderFilterSuffix()}
            placeholder="Поиск"
            sbSize="small"
            value={searchText}
            onChange={onSearchChange}
          />
        </Col>
        <Col className="col-1">
          <SbContextMenu menuContent={sortingMenuContent}>
            <div className="sb-knowledge-base-card__content__row__entries__controls-row__sort">
              <SbButton icon={<SbIcon iconName={getCurrentSortingIconName()} />} sbType="icon-primary-48" />
            </div>
          </SbContextMenu>
        </Col>
      </Row>
      <Row className="sb-knowledge-base-card__content__row__entries__list-row" wrap={false}>
        <Col span={24}>{renderListContent()}</Col>
        {loading && <SbSpin />}
      </Row>
    </>
  );
};

export default DataEntryList;
