import React from 'react';

import './index.less';

const MAIN_CLASS_NAME = 'ib-badge';
const DISABLED_CLASS_NAME = `${MAIN_CLASS_NAME}_disabled`;

export interface IIbBadgeProps {
  className?: string;
  value?: string | number;
  disabled?: boolean;
}

const IbBadge: React.FC<IIbBadgeProps> = ({ className, value, disabled }) => {
  const classes = [MAIN_CLASS_NAME, disabled ? DISABLED_CLASS_NAME : null];
  className && classes.push(className);

  return <span className={classes.join(' ')}>{value || '-'}</span>;
};

export default IbBadge;
