import React, { MouseEvent } from 'react';
import { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention';

import '@draft-js-plugins/mention/lib/plugin.css';

import './index.less';

import IbAvatar from '../../common/IbAvatar';

const MAIN_CLASS_NAME = 'mention-entry';
const CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__container`;
const MENTION_NAME_CLASS_NAME = `${CONTAINER_CLASS_NAME}__name`;

export interface IMentionEntryProps {
  className?: string;
  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  selectMention(mention: MentionData | null): void;
}

const MentionEntry: React.FC<IMentionEntryProps> = (props: IMentionEntryProps) => {
  const { mention, ...parentProps } = props;
  const divProps: Partial<IMentionEntryProps> = { ...parentProps };
  delete divProps.theme;
  delete divProps.searchValue;
  delete divProps.isFocused;
  delete divProps.selectMention;

  return (
    <div className={MAIN_CLASS_NAME} {...divProps}>
      <div className={CONTAINER_CLASS_NAME}>
        <IbAvatar
          imgSrc={mention.avatar}
          metadata={{ uid: mention.subjectId, text: mention.name }}
          size="x-small"
          userStatus={mention.status}
        />
        <div className={MENTION_NAME_CLASS_NAME}>{mention.name}</div>
      </div>
    </div>
  );
};

export default MentionEntry;
