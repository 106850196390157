import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { Input, Menu } from 'antd';
import { Key } from 'ts-key-enum';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import { ButtonSchema, ButtonType, DefaultActionGroupSchema, SchemaKind } from '../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  selectedEntitySelector,
} from '../../../../recoil/scenarioStructure';
import ContextMenu from '../common/ContextMenu';
import OutputConnection from '../common/OutputConnection';
import { deleteBindingWithReferences, EntityFieldPath, getElementId, tryGetElementById } from '../../utils';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';

import SettingModal from './SettingModal';

interface ActionButtonProps {
  button: ButtonSchema;
  group: DefaultActionGroupSchema;
  parentKind: SchemaKind;
  onDelete?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ button, group, parentKind, onDelete }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const [buttonValue, setButtonValue] = useState(button.value);
  const [buttonIsEditing, setButtonIsEditing] = useState(false);

  const isButtonSelected = () => button.id === selectedEntity?.id;

  const onButtonMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setSelectedEntity(button);
  };

  const onButtonDoubleClick = () => {
    if (settingModalVisible) {
      return;
    }

    setButtonIsEditing(true);
  };

  const onButtonValueChange = () => {
    setButtonValue(button.value);
  };
  useEffect(onButtonValueChange, [button.value]);

  const onComponentCreate = () => {
    if (isButtonSelected()) {
      setButtonIsEditing(true);
    }
  };
  useEffect(onComponentCreate, []);

  const menuIsVisibleChanged = (value: boolean) => {
    setMenuIsVisible(value);
  };

  const onButtonSetupSelect = () => {
    setSettingModalVisible(true);
  };

  const onButtonDeleteSelect = () => {
    onDelete?.();
  };

  const onSettingModalChange = (newButton: ButtonSchema) => {
    setSettingModalVisible(false);

    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundButton = tryGetElementById(newScenarioStructure, button.id) as ButtonSchema;
    foundButton.value = newButton.value;
    foundButton.synonyms = newButton.synonyms;
    foundButton.type = newButton.type;
    foundButton.url = newButton.url;

    if (newButton.type === ButtonType.Url) {
      deleteBindingWithReferences(newScenarioStructure, foundButton.outputBindingId);
    }

    setScenarioStructure(newScenarioStructure);
  };

  const onSettingModalClose = () => {
    setSettingModalVisible(false);
  };

  const saveButtonValue = () => {
    setButtonIsEditing(false);

    if (buttonValue === button.value) return;

    if (!buttonValue) {
      setButtonValue(button.value);
      return;
    }

    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundButton = tryGetElementById(newScenarioStructure, button.id) as ButtonSchema;
    foundButton.value = buttonValue;
    setScenarioStructure(newScenarioStructure);
  };

  const onButtonInputBlur = () => {
    saveButtonValue();
  };

  const onButtonInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setButtonValue(e.target.value);
  };

  const onButtonInputFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.select();
  };

  const onButtonInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      saveButtonValue();
      return;
    }
    if (e.key === Key.Escape) {
      setButtonValue(button.value);
      setButtonIsEditing(false);
    }
  };

  const classes = ['action-button'];
  isButtonSelected() && classes.push('action-button_selected');
  buttonIsEditing && classes.push('action-button_editing');

  return (
    <div
      aria-hidden="true"
      className={classes.join(' ')}
      id={getElementId(button.id)}
      onDoubleClick={onButtonDoubleClick}
      onMouseDown={onButtonMouseDown}
    >
      <div className="action-button__content">
        <div className="action-button__text">
          {buttonIsEditing ? (
            <Input
              autoFocus
              value={buttonValue}
              onBlur={onButtonInputBlur}
              onChange={onButtonInputChange}
              onClick={(e) => e.stopPropagation()}
              onFocus={onButtonInputFocus}
              onKeyDown={onButtonInputKeyDown}
              onMouseDown={(e) => e.stopPropagation()}
            />
          ) : (
            <div className="action-button__text__title">
              {button.type === ButtonType.Url && <SbIcon iconName="link-one" size={16} />}
              <span title={buttonValue}>{buttonValue}</span>
            </div>
          )}
        </div>
        {!!button.synonyms.length && !buttonIsEditing && (
          <div className="sb-badge">
            <span>{button.synonyms.length}</span>
          </div>
        )}
      </div>
      {button.type === ButtonType.Transit && (
        <OutputConnection
          className={buttonIsEditing ? 'editing' : undefined}
          entity={button}
          groupId={group.id}
          id={getElementId(button.id, EntityFieldPath.OutputBindingId)}
          isValid={!scenarioValidation?.hasIssue(button.id, EntityFieldPath.OutputBindingId)}
        />
      )}
      <ContextMenu
        menuContent={
          <Menu>
            <Menu.Item key="setup" onClick={onButtonSetupSelect}>
              <SbIcon iconName="setting-two" />
            </Menu.Item>
            {onDelete && (
              <Menu.Item key="delete" onClick={onButtonDeleteSelect}>
                <SbIcon iconName="close" />
              </Menu.Item>
            )}
          </Menu>
        }
        menuIsVisible={menuIsVisible}
        menuIsVisibleChanged={menuIsVisibleChanged}
      />
      <SettingModal
        button={button}
        parentKind={parentKind}
        visible={settingModalVisible}
        onChange={onSettingModalChange}
        onModalClose={onSettingModalClose}
      />
    </div>
  );
};

export default ActionButton;
