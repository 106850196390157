export enum EventType {
  Any = '*',
  All = '**',
  Network = 'network.**',
  NetworkConnection = 'network.connection.**',
  NetworkConnectionSuccess = 'network.connection.success',
  NetworkConnectionFailure = 'network.connection.failure',
  NetworkConnectionOn = 'network.connection.on',
  NetworkConnectionOff = 'network.connection.off',
  Queue = 'queue.**',
  QueueTask = 'queue.task.**',
  QueueTaskPending = 'queue.task.pending',
  QueueTaskProcess = 'queue.task.process',
  QueueTaskSuccess = 'queue.task.success',
  QueueTaskFailure = 'queue.task.failure',
  QueueTaskProgress = 'queue.task.progress',
  Storage = 'storage.**',
  StorageState = 'storage.state.**',
  StorageStateItems = 'storage.state.items.**',
  StorageStateItemsUpdated = 'storage.state.items.updated',
  StorageStatePages = 'storage.state.pages.**',
  StorageStatePagesUpdated = 'storage.state.pages.updated',
  StorageStateStats = 'storage.state.stats.**',
  StorageStateStatsUpdated = 'storage.state.stats.updated',
  StorageStateItem = 'storage.state.item.**',
  StorageStateItemCreated = 'storage.state.item.created',
  StorageStateItemUpdated = 'storage.state.item.updated',
  StorageStateItemDeleted = 'storage.state.item.deleted',
  StorageTable = 'storage.table.**',
  StorageTableItem = 'storage.table.item.**',
  StorageTableItemCreated = 'storage.table.item.created',
  StorageTableItemUpdated = 'storage.table.item.updated',
  StorageTableItemDeleted = 'storage.table.item.deleted',
  Domain = 'domain.**',
  DomainInbox = 'domain.inbox.**',
  DomainInboxParticipant = 'domain.inbox.participant.**',
  DomainInboxParticipantChat = 'domain.inbox.participant.chat.**',
  DomainInboxParticipantChatAssigned = 'domain.inbox.participant.chat.assigned',
  DomainInboxParticipantChatUnAssigned = 'domain.inbox.participant.chat.unassigned',
  DomainInboxChat = 'domain.inbox.chat.**',
  DomainInboxChatClosed = 'domain.inbox.chat.closed',
  DomainInboxChatQueued = 'domain.inbox.chat.queued',
  DomainInboxChatBot = 'domain.inbox.chat.bot.**',
  DomainInboxChatBotAssigned = 'domain.inbox.chat.bot.assigned',
  DomainInboxChatOperator = 'domain.inbox.chat.operator.**',
  DomainInboxChatOperatorAssigned = 'domain.inbox.chat.operator.assigned',
  DomainInboxChatMessage = 'domain.inbox.chat.message.**',
  DomainInboxChatMessageReceived = 'domain.inbox.chat.message.received',
  DomainInboxChatMessageTransferred = 'domain.inbox.chat.message.transferred',
  DomainInboxChatMessageInterchanged = 'domain.inbox.chat.message.interchanged',
  DomainInboxMessage = 'domain.inbox.message.**',
  DomainInboxMessageStarted = 'domain.inbox.message.started',
  DomainInboxMessageFinished = 'domain.inbox.message.finished',
}

export interface IEventItem<TData> {
  type: string;
  data: TData;
}

export interface INetworkConnectionSuccess {
  result: unknown;
}

export interface INetworkConnectionFailure {
  error: Error;
}

export interface INetworkConnectionOn {
  result?: unknown;
}

export interface INetworkConnectionOff {
  error?: Error;
}

export interface IDomainEvent<TEntity> {
  entity: TEntity;
}

export type SyncEventHandler<TData> = (data: TData, type: string) => void;

export type AsyncEventHandler<TData> = (data: TData, type: string) => Promise<void>;
