import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';
import { useDebounce } from 'usehooks-ts';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import {
  InboxContactModel,
  PersonCreationRequest,
  PersonMergingRequest,
  PersonModel,
  PersonUpdatingRequest,
  TagModel,
} from '../../../../api';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import { emailIsValid, includesIgnoreCase, phoneNumberIsValid } from '../../../utils/stringUtil';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import { IFormFieldValidationResult } from '../../../types';
import IbTagsWidget from '../IbTagsWidget';
import IbPhoneNumberInput from '../common/IbPhoneNumberInput';
import IbSelect from '../common/IbSelect';
import { COUNTRY_LIST } from '../../../constants';
import IbPopover from '../common/IbPopover';
import IbSpin from '../common/IbSpin';
import IbAvatar from '../common/IbAvatar';
import IbContactMergeModal from '../IbContactMergeModal';
import { getCountryName } from '../../../utils/countryUtil';
import IbContactChannelList, { getContactChannels } from '../IbContactChannelList';

import {
  SEARCH_DELAY,
  ANIMATION_DELAY,
  MAIN_CLASS_NAME,
  TITLE_CLASS_NAME,
  FORM_ITEM_CLASS_NAME,
  DELETE_BUTTON_CLASS_NAME,
  PERSON_LIST_COUNT,
  PERSON_LIST_DEFAULT,
  PERSON_LIST_CLASS_NAME,
  PERSON_LIST_EMPTY_LIST_ENTRY_CLASS_NAME,
  PERSON_LIST_SPIN_WRAPPER_CLASS_NAME,
  PERSON_LIST_POPOVER_CLASS_NAME,
} from './const';

interface IContactEditFormValidationResult {
  fullName: IFormFieldValidationResult;
  eMail: IFormFieldValidationResult;
  primaryTel: IFormFieldValidationResult;
}

export interface IIbContactEditModalProps {
  visible?: boolean;
  otherPersons?: PersonModel[];
  otherPersonsLoading?: boolean;
  editContact?: InboxContactModel;
  allowDelete?: boolean;
  allowTagsManagement?: boolean;
  tagList?: TagModel[];
  onAdd?: (request: PersonCreationRequest) => Promise<void>;
  onEdit?: (request: PersonUpdatingRequest) => Promise<void>;
  onMerge?: (request: PersonMergingRequest) => Promise<void>;
  onDelete?: () => void;
  onTagsManagement?: () => void;
  onPersonsSearch?: (search: string) => void;
  onSendMessage?: (chatId: string) => void;
  onCancel?: () => void;
}

const IbContactEditModal: React.FC<IIbContactEditModalProps> = ({
  visible,
  otherPersons = PERSON_LIST_DEFAULT,
  otherPersonsLoading,
  editContact,
  allowDelete,
  allowTagsManagement,
  tagList,
  onAdd,
  onEdit,
  onMerge,
  onDelete,
  onTagsManagement,
  onPersonsSearch,
  onSendMessage,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();

  const [countryList, setCountryList] = useState(
    COUNTRY_LIST.map((c) => ({
      label: getCountryName(c, i18n),
      value: c.alpha2,
    }))
  );
  const [saving, setSaving] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [person, setPerson] = useState({} as PersonModel);
  const [selectedPersons, setSelectedPersons] = useState<PersonModel[]>([]);
  const [personsSearchText, setPersonsSearchText] = useState('');
  const [personsPopoverVisible, setPersonsPopoverVisible] = useState(false);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);

  const availablePersons = otherPersons.filter((p) => p.id !== person.id).slice(0, PERSON_LIST_COUNT);
  const debouncedPersonsSearchText = useDebounce(personsSearchText, SEARCH_DELAY);
  const deferredPersonsSearchText = personsSearchText ? debouncedPersonsSearchText : '';

  const loadPersons = () => {
    onPersonsSearch?.(deferredPersonsSearchText);
  };
  useEffect(loadPersons, [deferredPersonsSearchText]);

  const validationResult: IContactEditFormValidationResult = {
    fullName: {
      isValid: !!person.fullName,
      message: t('Enter full name'),
    },
    eMail: {
      isValid: !person.eMail || emailIsValid(person.eMail),
      message: t('Enter valid e-mail'),
    },
    primaryTel: {
      isValid: !person.primaryTel || phoneNumberIsValid(person.primaryTel),
      message: t('Enter valid phone number'),
    },
  };

  const formIsValid =
    validationResult.fullName.isValid && validationResult.eMail.isValid && validationResult.primaryTel.isValid;

  const onSaveButtonClick = async () => {
    setShowValidation(true);

    if (!editContact || !formIsValid) {
      return;
    }

    setSaving(true);
    await onEdit?.({
      fullName: person.fullName,
      lastName: person.lastName,
      firstName: person.firstName,
      middleName: person.middleName,
      primaryTel: person.primaryTel,
      secondaryTels: person.secondaryTels,
      eMail: person.eMail,
      country: person.country,
      city: person.city,
      tagIds: person.tags?.map((t) => t.id),
      additionalInfo: person.additionalInfo,
      avatarFileId: person.avatarFileId,
      groupIds: person.groupIds,
    });
    setSaving(false);
    onCancel?.();
  };

  const onAddButtonClick = async () => {
    setShowValidation(true);

    if (!formIsValid) {
      return;
    }

    setSaving(true);
    await onAdd?.({
      fullName: person.fullName,
      lastName: person.lastName,
      firstName: person.firstName,
      middleName: person.middleName,
      primaryTel: person.primaryTel,
      secondaryTels: person.secondaryTels,
      eMail: person.eMail,
      country: person.country,
      city: person.city,
      tagIds: person.tags?.map((t) => t.id),
      additionalInfo: person.additionalInfo,
      avatarFileId: person.avatarFileId,
    });
    setSaving(false);
    onCancel?.();
  };

  const onCountrySearch = (searchText: string) =>
    setCountryList(
      COUNTRY_LIST.filter((c) => includesIgnoreCase(getCountryName(c, i18n), searchText)).map((c) => ({
        label: getCountryName(c, i18n),
        value: c.alpha2,
      }))
    );

  const onFullNameChange = (value: string) => setPerson({ ...person, fullName: value });
  const onPrimaryTelChange = (value: string) => setPerson({ ...person, primaryTel: value });
  const onEmailChange = (value: string) => setPerson({ ...person, eMail: value });
  const onCountryChange = (value: SelectValue) => setPerson({ ...person, country: value as string });
  const onCityChange = (value: string) => setPerson({ ...person, city: value });
  const onTagsChange = async (tagIds: string[]) => {
    setPerson({ ...person, tags: tagIds.map((id) => ({ id } as TagModel)) });
  };

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setSaving(false);
    setShowValidation(false);
    setPerson(editContact ? { ...editContact.person } : ({} as PersonModel));
  };
  useEffect(onVisiblePropChange, [visible]);

  const clearPersonsPopoverContext = () => {
    // NOTE: сбрасываем только после того, как сработает анимация закрытия всплывыющего меню
    setTimeout(() => {
      setSelectedPersons([]);
      setPersonsSearchText('');
    }, ANIMATION_DELAY);
  };

  const onPersonsPopoverButtonClick = () => {
    setPersonsPopoverVisible(!personsPopoverVisible);
  };

  const onPersonsPopoverVisibleChange = (visible?: boolean) => {
    if (visible) return;

    setPersonsPopoverVisible(visible || false);
    clearPersonsPopoverContext();
  };

  const onPersonsSearchChange = (value: string) => {
    setPersonsSearchText(value);
  };

  const onPersonItemClick = (person: PersonModel) => () =>
    setSelectedPersons(
      selectedPersons.some((p) => p.id === person.id)
        ? selectedPersons.filter((p) => p.id !== person.id)
        : [...selectedPersons, person]
    );

  const onPersonsMergeButtonClick = () => {
    setPersonsPopoverVisible(false);
    setMergeModalVisible(true);
  };

  const onMergeModalMerge = async (request: PersonMergingRequest, mergedPerson: PersonModel) => {
    await onMerge?.(request);
    setPerson({ ...mergedPerson });
  };

  const onMergeModalCancel = () => {
    setMergeModalVisible(false);
    clearPersonsPopoverContext();
  };

  const renderPersonListLoader = () => {
    return (
      <div className={PERSON_LIST_SPIN_WRAPPER_CLASS_NAME}>
        <IbSpin />
      </div>
    );
  };

  const renderPersonEmptyList = () => {
    return (
      <div className={PERSON_LIST_EMPTY_LIST_ENTRY_CLASS_NAME}>
        <IbAvatar iconName="every-user" size="x-small" />
        <IbTypography.Paragraph disabled type="secondary">
          {t('No contacts found')}
        </IbTypography.Paragraph>
      </div>
    );
  };

  const renderPersonFullList = () => {
    return (
      <ul>
        {availablePersons.map((person) => (
          <li key={person.id}>
            <IbTypography onClick={onPersonItemClick(person)}>
              <IbAvatar
                imgSrc={person.avatarUrl}
                metadata={{ uid: person.id, text: person.fullName || '' }}
                size="x-small"
              />
              <IbTypography.Paragraph type="secondary">{person.fullName}</IbTypography.Paragraph>
              {selectedPersons.some((p) => p.id === person.id) ? <IbIcon iconName="check-one" size={20} /> : null}
            </IbTypography>
          </li>
        ))}
      </ul>
    );
  };

  const renderPersonList = () => {
    return (
      <div className={PERSON_LIST_CLASS_NAME}>
        <IbInput placeholder={t('Contact search')} value={personsSearchText} onChange={onPersonsSearchChange} />
        {otherPersonsLoading
          ? renderPersonListLoader()
          : availablePersons.length
          ? renderPersonFullList()
          : renderPersonEmptyList()}
        <IbButton disabled={!selectedPersons.length} type="fill" onClick={onPersonsMergeButtonClick}>
          {t('Merge')}
        </IbButton>
      </div>
    );
  };

  const title = editContact ? (
    <div className={TITLE_CLASS_NAME}>
      <span>{t('Edit contact')}</span>
      <IbPopover
        className={PERSON_LIST_POPOVER_CLASS_NAME}
        content={renderPersonList()}
        placement="bottomLeft"
        trigger="click"
        visible={personsPopoverVisible}
        onVisibleChange={onPersonsPopoverVisibleChange}
      >
        <IbButton icon={<IbIcon iconName="link-two" />} type="link" onClick={onPersonsPopoverButtonClick}>
          {t('Merge')}
        </IbButton>
      </IbPopover>
    </div>
  ) : (
    t('Add contact')
  );

  const footer = editContact ? (
    <>
      <IbButton disabled={saving} onClick={onSaveButtonClick}>
        {t('Save')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
      {allowDelete && (
        <IbButton
          className={DELETE_BUTTON_CLASS_NAME}
          icon={<IbIcon iconName="delete" />}
          type="link"
          onClick={onDelete}
        >
          {t('Delete')}
        </IbButton>
      )}
    </>
  ) : (
    <>
      <IbButton disabled={saving} onClick={onAddButtonClick}>
        {t('Add')}
      </IbButton>
      <IbButton type="secondary" onClick={onCancel}>
        {t('Cancel')}
      </IbButton>
      <IbButton type="fill" onClick={onCancel}>
        {t('Cancel (verb)')}
      </IbButton>
    </>
  );

  const channels = editContact ? getContactChannels(editContact, i18n) : [];

  return (
    <IbModal
      className={MAIN_CLASS_NAME}
      footer={footer}
      loading={saving}
      position={IbModalPosition.FixedTall}
      title={title}
      visible={visible}
      onCancel={onCancel}
    >
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Full name')}</IbTypography.Paragraph>
        <IbInput
          status={showValidation && !validationResult.fullName.isValid ? 'error' : 'default'}
          value={person.fullName || undefined}
          onChange={onFullNameChange}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Phone number')}</IbTypography.Paragraph>
        <IbPhoneNumberInput
          status={showValidation && !validationResult.primaryTel.isValid ? 'error' : 'default'}
          value={person.primaryTel || undefined}
          onChange={onPrimaryTelChange}
        />
        {showValidation && !validationResult.primaryTel.isValid && (
          <IbTypography.Paragraph error type="descriptor">
            {t('Incorrect phone number length or incorrect country code')}
          </IbTypography.Paragraph>
        )}
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('E-mail')}</IbTypography.Paragraph>
        <IbInput
          status={showValidation && !validationResult.eMail.isValid ? 'error' : 'default'}
          value={person.eMail || undefined}
          onChange={onEmailChange}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Country')}</IbTypography.Paragraph>
        <IbSelect
          showSearch
          options={countryList}
          value={person.country || undefined}
          onChange={onCountryChange}
          onSearch={onCountrySearch}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('City')}</IbTypography.Paragraph>
        <IbInput value={person.city || undefined} onChange={onCityChange} />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbContactChannelList channels={channels} onSendMessageToChat={onSendMessage} />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Tags')}</IbTypography.Paragraph>
        <IbTagsWidget
          allowTagsManagement={allowTagsManagement}
          selectedTagIds={person.tags?.map((tag) => tag.id)}
          tagList={tagList}
          onChange={onTagsChange}
          onTagsManagement={onTagsManagement}
        />
      </div>
      <IbContactMergeModal
        allowTagsManagement={allowTagsManagement}
        personList={[person, ...selectedPersons]}
        tagList={tagList}
        visible={mergeModalVisible}
        onCancel={onMergeModalCancel}
        onMerge={onMergeModalMerge}
        onTagsManagement={onTagsManagement}
      />
    </IbModal>
  );
};

export default IbContactEditModal;
