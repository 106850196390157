import React from 'react';

import './index.less';

type ISbProgressProps = {
  percent?: number;
  className?: string;
};

const SbProgress: React.FC<ISbProgressProps> = ({ className, percent }) => {
  const classes = ['sb-progress'];
  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')}>
      <div className="sb-progress__frame" />
      <div className="sb-progress__bar" style={{ width: `${percent}%` }} />
      <div className="sb-progress__value">{percent}%</div>
    </div>
  );
};

export default SbProgress;
