import React, { memo, useState } from 'react';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import { DefaultActionGroupSchema, SendEmailSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import EditButton from '../../common/EditButton';
import SendEmailSettingsModal from '../../common/SendEmailSettingsModal';
import { tryGetElementById } from '../../../utils';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';

import './index.less';

interface ISendEmailProps {
  index: number;
  action: SendEmailSchema;
  group: DefaultActionGroupSchema;
}

const SendEmail: React.FC<ISendEmailProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);

  const onSetupButtonClick = () => {
    setSettingsModalVisible(true);
  };

  const onSigninSettingsModalClose = () => {
    setSettingsModalVisible(false);
  };

  const onActionChange = (action: SendEmailSchema) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundGroup = tryGetElementById(newScenarioStructure, group.id) as DefaultActionGroupSchema;
    foundGroup.actions = foundGroup.actions.map((a) => (a.id === action.id ? action : a));
    setScenarioStructure(newScenarioStructure);
  };

  return (
    <ActionWrapper
      showSendEmailSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="mail" size={20} />}
      index={index}
      title="Отправить Email"
    >
      <EditButton buttonHandler={onSetupButtonClick} title="Настроить сообщение" />
      <SendEmailSettingsModal
        action={action}
        visible={settingsModalVisible}
        onChange={onActionChange}
        onClose={onSigninSettingsModalClose}
      />
    </ActionWrapper>
  );
};

export default memo(SendEmail);
