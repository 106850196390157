import React from 'react';
import { Button, Form, Input } from 'antd';

export interface IBotVersionSearchProps {
  loading: boolean;
  onSearch: (hash: string) => void;
  onClear: () => void;
}

const BotVersionSearch: React.FC<IBotVersionSearchProps> = ({
  loading = false,
  onSearch = () => {},
  onClear = () => {},
}) => {
  const [form] = Form.useForm();

  const onFinish = () => {
    const hash = (form.getFieldValue('hash') as string).trim();
    if (hash) {
      onSearch(hash);
    } else {
      onClear();
    }
  };

  const onClearButtonClick = () => {
    form.setFieldsValue({ hash: '' });
    onClear();
  };

  return (
    <Form form={form} layout="inline" style={{ marginBottom: 16 }} onFinish={onFinish}>
      <Form.Item label="Хэш" name="hash" style={{ flexGrow: 1 }}>
        <Input placeholder="Хэш" />
      </Form.Item>
      <Form.Item>
        <Button disabled={loading} htmlType="submit" type="primary">
          Поиск
        </Button>
      </Form.Item>
      <Form.Item style={{ marginRight: 0 }}>
        <Button disabled={loading} type="default" onClick={onClearButtonClick}>
          Очистить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BotVersionSearch;
