import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';

import './index.less';

export const EditableContext = React.createContext<FormInstance | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
interface IEditableCellProps<RecordType extends object = any> {
  editable?: boolean;
  children?: React.ReactNode;
  dataIndex: string;
  record?: RecordType;
  onSave?: (record: RecordType) => void;
  focusRowKey?: string;
  rowKey?: string;
}

const EditableCell: React.FC<IEditableCellProps> = ({
  editable = false,
  children,
  dataIndex,
  record,
  onSave = () => {},
  focusRowKey,
  rowKey = 'key',
  ...otherProps
}) => {
  const [editing, setEditing] = useState(record && record[rowKey] === focusRowKey);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext) as FormInstance;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const onSaveAsync = async () => {
    const values = await form.validateFields();
    toggleEdit();
    onSave({ ...record, ...values });
  };

  if (editable && editing) {
    return (
      <td className="sb-table__editable-cell sb-table__editable-cell_edit-mode">
        <Form.Item name={dataIndex}>
          <Input ref={inputRef} onBlur={onSaveAsync} onPressEnter={onSaveAsync} />
        </Form.Item>
      </td>
    );
  }

  if (editable) {
    return (
      <td className="sb-table__editable-cell sb-table__editable-cell_read-mode" role="gridcell" onClick={toggleEdit}>
        {children}
      </td>
    );
  }

  return <td {...otherProps}>{children}</td>;
};

export default EditableCell;
