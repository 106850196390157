import React from 'react';
import { useRecoilState } from 'recoil';

import IbPreviewModal from '../../components/common/IbPreviewModal';
import { previewModalParamsSelector } from '../../recoil';

const PreviewModal: React.FC = () => {
  const [previewModalParams, setPreviewModalParams] = useRecoilState(previewModalParamsSelector);

  const hidePreviewModal = () => {
    const state = { ...previewModalParams };
    state.isOpen = false;
    setPreviewModalParams(state);
  };

  return (
    <IbPreviewModal
      channelId={previewModalParams.channelId}
      isOpen={previewModalParams.isOpen}
      items={previewModalParams.items}
      selectedIndex={previewModalParams.selectedIndex}
      senderParticipant={previewModalParams.senderParticipant}
      timestamp={previewModalParams.timestamp}
      onClose={hidePreviewModal}
      onReply={previewModalParams.onReply}
    />
  );
};

export default PreviewModal;
