import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';

import { ActivityModel } from '../../../../../../api';
import SbIcon from '../../../../components/common/SbIcon';

const MAIN_CLASS = 'sb-dialogs-card__content__panel-container__info';

interface IVariableChangeInfoProps {
  activity: ActivityModel;
}

const VariableChangeInfo: React.FC<IVariableChangeInfoProps> = ({ activity }) => {
  const isArr = Array.isArray(activity.value?.VarNewValue);
  const date = isArr ? activity.value?.VarNewValue[0]?.Value : undefined;
  const isAttachment = !activity.value?.VarOldValue?.contentUrl && !activity.value?.VarOldValue?.length;
  return (
    <>
      <Typography.Title ellipsis level={4}>
        Подробнее о переменной
      </Typography.Title>
      <>
        <div className={`${MAIN_CLASS}__additional-title`}>
          <SbIcon iconName="code-one" size={20} />
          <div>Предыдущее значение</div>
        </div>
        {activity.value?.VarOldValue?.contentUrl && (
          <a download href={activity.value.VarOldValue.contentUrl ?? ''}>
            <SbIcon iconName="paperclip" size={16} />
            {activity.value.VarOldValue.name}
          </a>
        )}
        {!!activity.value?.OldValue?.length && (
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>{moment(activity.value.VarOldValue[0])}</div>
          </div>
        )}
        {isAttachment && (
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>{activity.value?.VarOldValue ?? 'null'}</div>
          </div>
        )}
        <div className={`${MAIN_CLASS}__additional-title`}>
          <SbIcon iconName="code-one" size={20} />
          <div>Новое значение</div>
        </div>
        {activity.value?.VarNewValue?.contentUrl && (
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>
              <a
                download
                href={activity.value.VarNewValue.contentUrl ?? ''}
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
              >
                <SbIcon iconName="paperclip" size={16} />
                {activity.value.VarNewValue.name}
              </a>
            </div>
          </div>
        )}
        {activity.value?.VarNewValue?.first && (
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>{activity.value.VarNewValue.first}</div>
          </div>
        )}
        {activity.value?.VarNewValue?.contentUrl === undefined &&
          date === undefined &&
          activity.value?.VarNewValue?.first === undefined && (
            <div className={`${MAIN_CLASS}__border-frame`}>
              <div className={`${MAIN_CLASS}__border-frame__text`}>{activity.value?.VarNewValue ?? 'null'}</div>
            </div>
          )}
        {date && (
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>{date}</div>
          </div>
        )}
      </>
    </>
  );
};

export default VariableChangeInfo;
