import React, { useEffect, useState } from 'react';
import { List, Table, TablePaginationConfig } from 'antd';
import ReactMarkdown from 'react-markdown';
import { ExpandableConfig } from 'rc-table/lib/interface';
import { useSetRecoilState } from 'recoil';

import { AlertTypes, DEFAULT_PAGE_SIZE } from '../constants';
import { DataEntryModel, DataType, KnowledgeSourceModel } from '../../kb-api';
import { dataEntryKbApi } from '../apis';
import { QnAData } from '../types';
import { alertsSelectorAdd } from '../recoil/alerts';

interface QnATableProps {
  dataEntryId?: string | null;
  editionId?: string;
  knowledgeSource?: KnowledgeSourceModel;
}

const QnATable: React.FC<QnATableProps> = ({ dataEntryId, editionId, knowledgeSource }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listDataEntries, setListDataEntries] = useState([] as DataEntryModel[]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      let items = [] as DataEntryModel[];
      if (dataEntryId) {
        const response = await dataEntryKbApi.getChildren(dataEntryId);
        items = response.data;
      } else if (editionId) {
        const response = await dataEntryKbApi.searchDataEntries(
          undefined,
          editionId,
          undefined,
          DataType.QnA,
          true,
          undefined,
          undefined,
          undefined,
          undefined,
          (pagination.current ?? 1) - 1,
          pagination.pageSize
        );
        items = (response.data.items || []).map((e) => e.entry);
        setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
      }
      setListDataEntries(items);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка записей редакции',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize, editionId, knowledgeSource]);

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'Вопросы',
      dataIndex: 'data',
      width: '20%',
      render: (_: unknown, item: DataEntryModel) => (
        <List>
          {(item.data as QnAData).questions?.map((question, i) => (
            <List.Item key={i}>{question}</List.Item>
          ))}
        </List>
      ),
    },
    {
      title: 'Ответы',
      dataIndex: 'data',
      render: (_: unknown, item: DataEntryModel) => (
        <ReactMarkdown>{(item.data as QnAData).answer || ''}</ReactMarkdown>
      ),
    },
  ];

  const expandable: ExpandableConfig<DataEntryModel> = {
    expandedRowRender: (record) => {
      return <QnATable dataEntryId={record.id} />;
    },
    rowExpandable: (record) => !!record.childEntryIds?.length,
    expandRowByClick: true,
  };

  return (
    <Table
      columns={columns}
      dataSource={listDataEntries}
      expandable={expandable}
      loading={loading}
      pagination={dataEntryId ? false : pagination}
      rowKey="id"
      onChange={onTableChange}
    />
  );
};

export default QnATable;
