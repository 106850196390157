import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import './index.less';

import IbTypography from '../common/IbTypography';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import {
  InboxMentionModel,
  InboxMessageAttachmentModel,
  InboxMessageContentModel,
  InboxDirection,
} from '../../../../api';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbMessage from '../common/IbMessage';
import IbThumbnail from '../common/IbThumbnail';
import { isPreviewAvailable, tryGetPreviewUrl, tryGetThumbnailUrl } from '../../utils/fileUtil';

const MAIN_CLASS_NAME = 'ib-quoted-message';
const MAIN_APPEAR_CLASS_NAME = `${MAIN_CLASS_NAME}_appear`;
const MAIN_APPEAR_DONE_CLASS_NAME = `${MAIN_APPEAR_CLASS_NAME}_done`;
const CLICKABLE_CLASS_NAME = `${MAIN_CLASS_NAME}_clickable`;
const INTERNAL_CLASS_NAME = `${MAIN_CLASS_NAME}_internal`;
const WITH_ATTACHMENTS_CLASS_NAME = `${MAIN_CLASS_NAME}_with-attachments`;
const THUMBNAIL_CLASS_NAME = `${MAIN_CLASS_NAME}__thumbnail`;
const BODY_CLASS_NAME = `${MAIN_CLASS_NAME}__body`;
const SENDER_CLASS_NAME = `${BODY_CLASS_NAME}__sender`;
const ATTACHMENTS_CLASS_NAME = `${BODY_CLASS_NAME}__attachments`;
const TEXT_CLASS_NAME = `${BODY_CLASS_NAME}__text`;
const BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__button`;

const APPEAR_ANIMATION_TIMEOUT = 0; //ms

export interface IIbQuotedMessageProps {
  content?: InboxMessageContentModel;
  senderFullname?: string;
  direction?: InboxDirection;
  attachments?: InboxMessageAttachmentModel[];
  mentions?: InboxMentionModel[];
  mentionLinksEnabled: boolean;
  inMessageInput?: boolean;
  onClick?: () => void;
  onDelete?: () => void;
}

const IbQuotedMessage: React.FC<IIbQuotedMessageProps> = ({
  content,
  senderFullname,
  direction,
  attachments = [],
  mentions = [],
  mentionLinksEnabled,
  inMessageInput,
  onClick,
  onDelete,
}) => {
  const { t } = useTranslation();

  content = getDefaultIfUndefined(content, undefined);
  senderFullname = getDefaultIfUndefined(senderFullname, undefined);
  attachments = getDefaultIfUndefined(attachments, []) as InboxMessageAttachmentModel[];
  mentions = getDefaultIfUndefined(mentions, []);
  onClick = getDefaultIfUndefined(onClick, undefined);
  onDelete = getDefaultIfUndefined(onDelete, () => {});

  const nodeRef = useRef(null);

  const classes = [MAIN_CLASS_NAME];
  onClick && classes.push(CLICKABLE_CLASS_NAME);
  direction === InboxDirection.Internal && classes.push(INTERNAL_CLASS_NAME);
  attachments.length && classes.push(WITH_ATTACHMENTS_CLASS_NAME);

  const firstAttachment = attachments[0];
  const firstAttachmentHasPreview = firstAttachment && isPreviewAvailable(firstAttachment.file);

  const renderContent = () => {
    if (content?.text) {
      return (
        <IbTypography.Paragraph className={TEXT_CLASS_NAME} disabled={!!attachments?.length} type="secondary">
          {firstAttachment && !firstAttachmentHasPreview && <IbIcon iconName="paperclip" size={16} />}
          <IbMessage
            content={content}
            inline={false}
            mentionLinksEnabled={mentionLinksEnabled}
            mentions={mentions ?? []}
          />
        </IbTypography.Paragraph>
      );
    }

    if (firstAttachmentHasPreview) {
      return (
        <IbTypography.Paragraph disabled type="secondary">
          {t('Photo')}
        </IbTypography.Paragraph>
      );
    }

    if (firstAttachment) {
      return (
        <div className={ATTACHMENTS_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="secondary">
            {firstAttachment.file?.name}
          </IbTypography.Paragraph>
        </div>
      );
    }

    return null;
  };

  const firstAttachmentPreviewUrl = firstAttachment && tryGetPreviewUrl(firstAttachment.file);
  const firstAttachmentThumbnailUrl =
    firstAttachment &&
    tryGetThumbnailUrl(
      firstAttachment.file,
      firstAttachment.thumbnails.map((t) => t.file)
    );

  return (
    <CSSTransition
      appear={inMessageInput}
      classNames={{ appear: MAIN_APPEAR_CLASS_NAME, appearDone: MAIN_APPEAR_DONE_CLASS_NAME }}
      in={inMessageInput}
      nodeRef={nodeRef}
      timeout={APPEAR_ANIMATION_TIMEOUT}
    >
      <div ref={nodeRef} className={classes.join(' ')} onClick={onClick}>
        {firstAttachmentHasPreview && firstAttachmentThumbnailUrl && (
          <div className={THUMBNAIL_CLASS_NAME}>
            <IbThumbnail imgSrc={firstAttachmentThumbnailUrl} linkHref={firstAttachmentPreviewUrl} size="small" />
          </div>
        )}
        <div className={BODY_CLASS_NAME}>
          {senderFullname && (
            <IbTypography.Paragraph strong className={SENDER_CLASS_NAME} type="secondary">
              {senderFullname}
            </IbTypography.Paragraph>
          )}
          {renderContent()}
        </div>
        {inMessageInput && (
          <div className={BUTTON_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="close" />} type="icon" onClick={onDelete} />
          </div>
        )}
      </div>
    </CSSTransition>
  );
};

export default IbQuotedMessage;
