import React, { useEffect, useState } from 'react';
import { PageHeader, TablePaginationConfig } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useDebounce } from 'usehooks-ts';
import { useHistory } from 'react-router';
import moment from 'moment';
import { Flags } from 'react-feature-flags';

import './index.less';

import SbPagination from '../../../components/common/SbPagination';
import { AlertTypes, DEFAULT_PAGE_SIZE, FeatureFlagNames, USER_STATUS_NAMES } from '../../../../constants';
import SbTable from '../../../components/common/SbTable';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { userAuthApi } from '../../../../apis';
import { UserModel, UserStatus } from '../../../../../auth-api';
import SbButton from '../../../components/common/SbButton';
import SbInput from '../../../components/common/SbInput';
import SbSpin from '../../../components/common/SbSpin';
import { useQuery } from '../../../../utils/urlUtil';
import { ROLE_NAME_TAG_COLOR } from '../../../const';
import SbTag from '../../../components/common/SbTag';
import SbUserModal from '../../../components/SbUserModal';
import { getProfile } from '../../../../utils/oidcUtil';
import { getRoleNameLabel } from '../../../../utils/stringUtil';

import InviteUserModal from './InviteUserModal';

const SEARCH_DELAY = 200; // ms
const SELECTED_USER_ID_PARAM = 'selectedUserId';
const CREATED_ON_FORMAT = 'DD.MM.YYYY HH:mm';

const UserList: React.FC = () => {
  const profile = getProfile();

  const query = useQuery();
  const { replace } = useHistory();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [searchText, setSearchText] = useState('');
  const [sortMode] = useState(''); // todo: добавить сортировку
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const [inviteUserModalVisible, setInviteUserModalVisible] = useState(false);

  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await userAuthApi.searchUsers(
        undefined,
        deferredSearchText,
        profile.tenantId,
        undefined,
        undefined,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
      setUsers(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка пользователей',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize, sortMode, deferredSearchText]);

  const initSelectedUser = () => {
    const selectedUserId = query.get(SELECTED_USER_ID_PARAM);
    selectedUserId && setSelectedUserId(selectedUserId);
  };
  useEffect(initSelectedUser, []);

  const onUserSelect = (rowKeys: string[]) => {
    if (!rowKeys.length) {
      return;
    }

    const userId = rowKeys[0];
    setSelectedUserId(userId);
    replace({
      search: `?${SELECTED_USER_ID_PARAM}=${userId}`,
    });
  };

  const onUserModalClose = () => {
    setSelectedUserId(undefined);
    replace({
      search: '',
    });
  };

  const onUserModalChange = async () => {
    await loadDataAsync();
  };

  const onAddUserButtonClick = () => setInviteUserModalVisible(true);

  const onInviteUserModalClose = () => setInviteUserModalVisible(false);

  const onInviteUserModalInvite = async () => {
    await loadDataAsync();
  };

  const onPaginationChange = (page: number) => setPagination({ ...pagination, current: page });

  const tableColumns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Логин',
      dataIndex: 'login',
      key: 'login',
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdOn',
      key: 'createdOn',
      render: (_: string, user: UserModel) => moment(user.createdOn).format(CREATED_ON_FORMAT),
    },
    {
      title: 'Роли',
      dataIndex: 'roleNames',
      key: 'roleNames',
      render: (_: string, user: UserModel) => (
        <div className="sb-user-list__content__table__roles">
          {user.roles.length
            ? user.roles.map((role, index) => (
                <SbTag key={index} color={ROLE_NAME_TAG_COLOR} sbSize="x-small" text={getRoleNameLabel(role.name)} />
              ))
            : '-'}
        </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_: string, user: UserModel) => (
        <span className={user.status === UserStatus.Blocked ? 'sb-user-list__content__table__status_warning' : ''}>
          {USER_STATUS_NAMES[user.status]}
        </span>
      ),
    },
  ];

  return (
    <div className="sb-user-list">
      <div className="sb-user-list__header">
        <PageHeader title="Пользователи" />
        <Flags authorizedFlags={[FeatureFlagNames.USERS_ADMIN]}>
          <SbButton sbSize="big" onClick={onAddUserButtonClick}>
            Добавить пользователя
          </SbButton>
        </Flags>
        <SbInput placeholder="Поиск" value={searchText} onChange={setSearchText} />
      </div>
      <div className="sb-user-list__content">
        <SbTable
          columns={tableColumns}
          dataSource={users}
          emptyText={loading ? '' : 'Пользователи не найдены'}
          loading={loading ? { indicator: <SbSpin /> } : false}
          rowKey="id"
          scrollEnabled={false}
          tableLayout="fixed"
          onSelectRowKeys={onUserSelect}
        />
        <SbUserModal
          userId={selectedUserId}
          visible={!!selectedUserId}
          onChange={onUserModalChange}
          onClose={onUserModalClose}
        />
      </div>
      <SbPagination
        current={pagination.current}
        defaultCurrent={1}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        pageSize={DEFAULT_PAGE_SIZE}
        showSizeChanger={false}
        total={pagination.total}
        onChange={onPaginationChange}
      />
      <InviteUserModal
        visible={inviteUserModalVisible}
        onClose={onInviteUserModalClose}
        onInvite={onInviteUserModalInvite}
      />
    </div>
  );
};

export default UserList;
