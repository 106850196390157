import { Layout } from 'antd';
import React from 'react';

const { Content } = Layout;

const EmptyLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>{children}</Content>
    </Layout>
  );
};

export default EmptyLayout;
