import { Moment } from 'moment';

export enum ScenarioSortDirection {
  ModifiedOnDescending = 'ModifiedOnDescending',
  NameAscending = 'NameAscending',
}

export enum Elma365FormPropertyDataTypes {
  STRING = 'string',
  TEXT = 'text',
  ROLE = 'role',
  MARKDOWN = 'markdown',
  INTEGER = 'integer',
  FLOAT = 'float',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  BOOLEAN = 'boolean',
  LINK = 'link',
  TABLE = 'table',
  FULL_NAME = 'full_name',
  DATETIME = 'datetime',
  DATE = 'date',
  TIME = 'time',
  ENUM = 'enum',
  EMAIL = 'email',
  PHONE = 'phone',
  ACCOUNT = 'account',
  MONEY = 'money',
  SYS_USER = 'sys_user',
  SYS_COLLECTION = 'sys_collection',
  REF_ITEM = 'ref_item',
  FILE = 'file',
}

export enum AnalyticsViewModes {
  TABLE = 'TABLE',
  CHART = 'CHART',
}

export interface AnalyticsDateFilter {
  fromDate: Moment;
  toDate: Moment;
}
