import React from 'react';
import { Slider } from 'antd';
import { SliderSingleProps } from 'antd/lib/slider';

import './index.less';

type ISbSliderProps = SliderSingleProps;

const SbSlider: React.FC<ISbSliderProps> = (props) => {
  return <Slider className="sb-slider" {...props} />;
};

export default SbSlider;
