import i18n from 'i18next';

import { TagModel } from '../../../../api';
import { IFormFieldValidationResult } from '../../../types';

export interface ITagEditFormValidationResult {
  label: IFormFieldValidationResult;
}

export const validateTagEditForm = (tag?: TagModel): ITagEditFormValidationResult => ({
  label: {
    isValid: !!tag?.label,
    message: i18n.t('Enter tag label'),
  },
});
