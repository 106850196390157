import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Select, Row, Col } from 'antd';
import { SelectValue } from 'antd/es/select';

import './index.less';

import {
  Elma365BpmTemplate,
  ContextElement,
  Elma365IntegrationSettingsSchema,
  Elma365ProcessStartType,
  StartElma365ProcessActionSchema,
  Elma365Namespace,
} from '../../../../../../api';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbScroll from '../../../../../simple-bot/components/common/SbScroll';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import Elma365FormProperties, { FormPropertiesParent } from '../Elma365FormProperties';
import Elma365ResultProperty from '../Elma365ResultProperty';
import { elma365BpmTemplateApi, elma365MetadataApi } from '../../../../../apis';
import SbSpin from '../../../../../simple-bot/components/common/SbSpin';
import {
  COMMENT_FIELD_CODE,
  isAllowedField,
  mapContextElementToFormProperty,
  NAME_FIELD_CODE,
  TitleGenerateMethods,
} from '../../../../../simple-bot/utils/elma365';
import Elma365RelatedAppElement from '../Elma365RelatedAppElement';
import { botSettingsSelector } from '../../../../../recoil/scenarioStructure';
import { instanceOfElma365IntegrationSettingsSchema } from '../../../utils';

const NAME_FIELD_CONTEXT = {
  code: NAME_FIELD_CODE,
  required: false,
  type: 'string',
  view: {
    name: 'Название',
    system: true,
  },
} as ContextElement;
const COMMENT_FIELD_CONTEXT = {
  code: COMMENT_FIELD_CODE,
  required: false,
  type: 'string',
  view: {
    name: 'Комментарий',
    system: true,
  },
} as ContextElement;
const START_PROCESS_ITEM_TYPE = 'start';

const filterBpmTemplateList = (bpmTemplateList: Elma365BpmTemplate[]) =>
  bpmTemplateList.filter((t) => !t.draft && !t.readonly);

const getBpmTemplateId = (namespace?: string | null, code?: string | null) =>
  namespace && code ? `${namespace}|${code}` : undefined;

const getNamespaceAndCode = (namespaceWithCode: string) => namespaceWithCode.split('|');

interface IStartElma365ProcessActionSettingsModalProps {
  action: StartElma365ProcessActionSchema;
  visible: boolean;
  onChange: (action: StartElma365ProcessActionSchema) => void;
  onClose: () => void;
}

const StartElma365ProcessActionSettingsModal: React.FC<IStartElma365ProcessActionSettingsModalProps> = ({
  action,
  visible,
  onChange,
  onClose,
}) => {
  const botSettings = useRecoilValue(botSettingsSelector);

  const [namespaceListAll, setNamespaceListAll] = useState<Elma365Namespace[]>();
  const [namespaceListLoading, setNamespaceListLoading] = useState(false);
  const [selectedNamespace, setSelectedNamespace] = useState<Elma365Namespace>();
  const [bpmTemplateListUnavailable, setBpmTemplateListUnavailable] = useState(false);
  const [bpmTemplateListAll, setBpmTemplateListAll] = useState<Elma365BpmTemplate[]>([]);
  const [selectedBpmTemplate, setSelectedBpmTemplate] = useState<Elma365BpmTemplate>();
  const [bpmTemplateListLoading, setBpmTemplateListLoading] = useState(false);
  const [bpmTemplateCode, setBpmTemplateCode] = useState(action.bpmTemplateCode);
  const [bpmTemplateNamespace, setBpmTemplateNamespace] = useState(action.bpmTemplateNamespace);
  const [startBy, setStartBy] = useState(action.startBy);
  const [formProperties, setFormProperties] = useState(action.formProperties);
  const [relatedAppElementVariableId, setRelatedAppElementVariableId] = useState(action.relatedAppElementVariableId);
  const [variableId, setVariableId] = useState(action.variableId);
  const [bpmTemplateVersion, setBpmTemplateVersion] = useState(action.bpmTemplateVersion);

  const integrationSettings = botSettings?.integrations.find((i) =>
    instanceOfElma365IntegrationSettingsSchema(i)
  ) as Elma365IntegrationSettingsSchema;
  const integrationSettingsNotSet = !integrationSettings?.apiUrl || !integrationSettings?.xToken;

  const bpmTemplateList = filterBpmTemplateList(bpmTemplateListAll);
  const bpmTemplateHasBeenUpdated =
    selectedBpmTemplate &&
    getBpmTemplateId(action.bpmTemplateNamespace, action.bpmTemplateCode) ===
      getBpmTemplateId(selectedBpmTemplate.namespace, selectedBpmTemplate.code) &&
    bpmTemplateVersion !== selectedBpmTemplate.version;

  const initFormProperties = (startBy: Elma365ProcessStartType, bpmTemplate?: Elma365BpmTemplate) => {
    if (!bpmTemplate || !bpmTemplate.process) {
      setFormProperties([]);
      return;
    }

    const startProcessItem = Object.values(bpmTemplate.process.items).find(
      (item) => item.type === START_PROCESS_ITEM_TYPE
    );

    const allowedFields = (startBy === Elma365ProcessStartType.ByStartEvent
      ? (startProcessItem?.settings.formFields || []).map((ff) => {
          const context =
            ff.code === NAME_FIELD_CODE
              ? NAME_FIELD_CONTEXT
              : ff.code === COMMENT_FIELD_CODE
              ? COMMENT_FIELD_CONTEXT
              : (bpmTemplate.context || []).find((e) => e.code === ff.code);
          return { ...context, required: ff.required, readonly: ff.readonly } as ContextElement;
        })
      : [NAME_FIELD_CONTEXT, COMMENT_FIELD_CONTEXT, ...bpmTemplate.context]
    ).filter((f) =>
      isAllowedField(
        f,
        startProcessItem?.settings?.titleGenerateMethod as TitleGenerateMethods,
        startProcessItem?.settings?.titleTemplate
      )
    );

    setFormProperties(allowedFields.map((f) => mapContextElementToFormProperty(f, formProperties)));
  };

  const tryLoadNamespaceList = async () => {
    if (integrationSettingsNotSet) {
      setNamespaceListAll([]);
      return;
    }

    setNamespaceListLoading(true);
    try {
      const response = await elma365MetadataApi.getElma365NamespaceSchemeList(
        integrationSettings.apiUrl,
        integrationSettings.xToken
      );
      setNamespaceListAll(response.data);

      const selectedNamespace = response.data.find((t) => action.bpmTemplateNamespace?.includes(t.code));
      setSelectedNamespace(selectedNamespace);
    } catch (e) {
      setNamespaceListAll([]);
    }
    setNamespaceListLoading(false);
  };

  const tryLoadBpmTemplateList = async () => {
    if (integrationSettingsNotSet) {
      setBpmTemplateListAll([]);
      return;
    }

    setBpmTemplateListLoading(true);
    if (selectedNamespace) {
      try {
        const response = await elma365MetadataApi.getElma365BpmTemplateSchemeList(
          integrationSettings.apiUrl,
          integrationSettings.xToken,
          selectedNamespace.code
        );
        setBpmTemplateListAll(response.data);
      } catch (e) {
        setBpmTemplateListAll([]);
        setBpmTemplateListUnavailable(true);
      }
    }

    if (!namespaceListAll?.length) {
      try {
        const response = await elma365BpmTemplateApi.getElma365BpmTemplateList(
          integrationSettings.apiUrl,
          integrationSettings.xToken
        );
        setBpmTemplateListAll(response.data);
      } catch (e) {
        setBpmTemplateListAll([]);
        setBpmTemplateListUnavailable(true);
      }
    }
    setBpmTemplateListLoading(false);
  };

  const tryLoadBpmTemplate = async () => {
    if (integrationSettingsNotSet) return;

    let selectedBpmTemplate: Elma365BpmTemplate | undefined = undefined;
    if (bpmTemplateNamespace && bpmTemplateCode) {
      try {
        const response = await elma365MetadataApi.getElma365BpmTemplateScheme(
          integrationSettings.apiUrl,
          integrationSettings.xToken,
          bpmTemplateNamespace,
          bpmTemplateCode
        );
        selectedBpmTemplate = response.data;
      } catch (e) {
        //empty
      }
    }

    if (bpmTemplateList.length && !selectedBpmTemplate) {
      selectedBpmTemplate = bpmTemplateList.find(
        (t) => t.namespace === bpmTemplateNamespace && t.code === bpmTemplateCode
      );
    }

    setSelectedBpmTemplate(selectedBpmTemplate);
    setBpmTemplateVersion(selectedBpmTemplate?.version);
    initFormProperties(startBy, selectedBpmTemplate);
  };

  const onSaveSettings = () => {
    onChange({
      ...action,
      processTitle: selectedBpmTemplate?.__name || action.processTitle,
      bpmTemplateCode,
      bpmTemplateNamespace,
      bpmTemplateVersion: selectedBpmTemplate?.version || action.bpmTemplateVersion,
      startBy,
      formProperties,
      relatedAppElementVariableId,
      variableId,
    });
    onClose();
  };

  const onVisibleChange = () => {
    if (!visible) return;

    setBpmTemplateCode(action.bpmTemplateCode);
    setBpmTemplateNamespace(action.bpmTemplateNamespace);
    setStartBy(action.startBy);
    setFormProperties(action.formProperties);
    setRelatedAppElementVariableId(action.relatedAppElementVariableId);
    setVariableId(action.variableId);
    setBpmTemplateVersion(action.bpmTemplateVersion);
    setBpmTemplateListUnavailable(false);
    tryLoadNamespaceList().finally();
  };
  useEffect(onVisibleChange, [visible]);

  const loadBpmTemplates = () => {
    if (selectedNamespace || namespaceListAll) {
      tryLoadBpmTemplateList().finally();
    }
  };
  useEffect(loadBpmTemplates, [selectedNamespace, namespaceListAll]);

  const loadBpmTemplate = () => {
    tryLoadBpmTemplate().finally();
  };
  useEffect(loadBpmTemplate, [bpmTemplateNamespace, bpmTemplateCode]);

  const onNamespaceChange = (value: SelectValue) => {
    const namespaceWithCode = value as string;
    const [namespace, code] = getNamespaceAndCode(namespaceWithCode);
    setSelectedNamespace(namespaceListAll?.find((n) => n.namespace === namespace && n.code === code));
    setBpmTemplateCode(undefined);
    setBpmTemplateNamespace(undefined);
  };

  const onBpmTemplateChange = (value: SelectValue) => {
    const bpmTemplateId = value as string;
    const [bpmTemplateNamespace, bpmTemplateCode] = bpmTemplateId.split('|');
    setBpmTemplateCode(bpmTemplateCode);
    setBpmTemplateNamespace(bpmTemplateNamespace);
  };

  const onStartByChange = (value: SelectValue) => {
    const startBy = value as Elma365ProcessStartType;
    setStartBy(startBy);
    initFormProperties(startBy, selectedBpmTemplate);
  };

  const onForceUpdateFormProperties = () => {
    setBpmTemplateVersion(selectedBpmTemplate?.version);
    initFormProperties(startBy, selectedBpmTemplate);
  };

  return (
    <SbModal
      className="start-elma365-process-action-settings-modal"
      footer={[
        integrationSettingsNotSet || bpmTemplateListUnavailable ? null : (
          <SbButton key="save" sbSize="medium" onClick={onSaveSettings}>
            Сохранить
          </SbButton>
        ),
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]}
      maskClosable={false}
      sbSize="small"
      title="Настройка запуска бизнес-процесса"
      visible={visible}
      width={884}
      onCancel={onClose}
      onOk={onSaveSettings}
    >
      {integrationSettingsNotSet ? (
        <SbPanel sbType="help" scrollable={false}>
          Настройте параметры интеграции ELMA365 на странице бота.
        </SbPanel>
      ) : bpmTemplateListUnavailable ? (
        <SbPanel sbType="help" scrollable={false}>
          Возникла ошибка при загрузке списка бизнес-процессов.
          <br />
          Возможные причины:
          <ul>
            <li>Неверно настроены параметры интеграции. Проверьте настройки.</li>
            <li>Адрес ELMA365 недоступен. Повторите попытку позже.</li>
          </ul>
        </SbPanel>
      ) : (
        <Row className="sb-modal__row-stretch" gutter={[12, 12]} wrap={false}>
          <Col className="sb-modal__col-main" span={15}>
            <SbScroll>
              <div>
                {!!namespaceListAll?.length && (
                  <>
                    <h4>Раздел:</h4>
                    {namespaceListLoading ? (
                      <SbSpin />
                    ) : (
                      <SbSelect
                        placeholder="Выберите раздел"
                        sbType="light"
                        value={
                          selectedNamespace
                            ? getBpmTemplateId(selectedNamespace?.namespace, selectedNamespace?.code)
                            : undefined
                        }
                        onChange={onNamespaceChange}
                      >
                        {namespaceListAll.map((t) => (
                          <Select.Option
                            key={getBpmTemplateId(t.namespace, t.code)}
                            value={getBpmTemplateId(t.namespace, t.code) as string}
                          >
                            {t.name}
                          </Select.Option>
                        ))}
                      </SbSelect>
                    )}
                  </>
                )}
                {(selectedNamespace || !namespaceListAll?.length) && (
                  <>
                    <h4>Бизнес-процесс:</h4>
                    {bpmTemplateListLoading ? (
                      <SbSpin />
                    ) : (
                      <SbSelect
                        placeholder="Выберите бизнес-процесс"
                        sbType="light"
                        value={
                          selectedBpmTemplate
                            ? getBpmTemplateId(selectedBpmTemplate.namespace, selectedBpmTemplate.code)
                            : undefined
                        }
                        onChange={onBpmTemplateChange}
                      >
                        {bpmTemplateList.map((bpmTemplate) => (
                          <Select.Option
                            key={getBpmTemplateId(bpmTemplate.namespace, bpmTemplate.code)}
                            value={getBpmTemplateId(bpmTemplate.namespace, bpmTemplate.code) as string}
                          >
                            {bpmTemplate.__name}
                          </Select.Option>
                        ))}
                      </SbSelect>
                    )}
                  </>
                )}
                {bpmTemplateHasBeenUpdated && (
                  <SbPanel
                    className="start-elma365-process-action-settings-modal__process-updated-info"
                    sbType="help"
                    scrollable={false}
                  >
                    <SbTypography>
                      <p>Выбранный бизнес-процесс обновился.</p>
                      <p>
                        Нажмите <button onClick={onForceUpdateFormProperties}>обновить</button> для загрузки параметров.
                      </p>
                    </SbTypography>
                  </SbPanel>
                )}
                <h4>Запускать с помощью:</h4>
                <SbSelect sbType="light" value={startBy} onChange={onStartByChange}>
                  <Select.Option
                    key={Elma365ProcessStartType.ByStartEvent}
                    value={Elma365ProcessStartType.ByStartEvent}
                  >
                    Стартового события
                  </Select.Option>
                  <Select.Option key={Elma365ProcessStartType.ByContext} value={Elma365ProcessStartType.ByContext}>
                    Контекста
                  </Select.Option>
                </SbSelect>
                <Elma365FormProperties
                  formProperties={formProperties}
                  formPropertiesParent={FormPropertiesParent.PROCESS}
                  onChange={setFormProperties}
                />
                {startBy === Elma365ProcessStartType.ByContext && (
                  <Elma365RelatedAppElement
                    action={action}
                    relatedAppElementVariableId={relatedAppElementVariableId}
                    onChange={setRelatedAppElementVariableId}
                  />
                )}
                <Elma365ResultProperty action={action} variableId={variableId} onChange={setVariableId} />
              </div>
            </SbScroll>
          </Col>
          <Col span={9}>
            <SbPanel sbType="help">
              <SbTypography>
                <h3>Запускать с помощью</h3>
                <p>
                  При выборе запуска через стартовое событие, можно задать поля процесса только из формы стартового
                  события. Запуск через контекст позволяет задать все доступные поля, а так же добавить связанный
                  элемент приложения.
                </p>
                <h3>Поля процесса</h3>
                <p>В каждое поле процесса можно записать значение из переменной бота.</p>
                <h3>Связанный элемент приложения</h3>
                <p>Это элемент приложения, на основе которого запускается бизнес-процесс.</p>
                <h3>Результат запуска</h3>
                <p>В выбранную переменную бота будут записаны данные запущенного экземпляра бизнес-процесса.</p>
              </SbTypography>
            </SbPanel>
          </Col>
        </Row>
      )}
    </SbModal>
  );
};

export default StartElma365ProcessActionSettingsModal;
