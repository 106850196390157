import React from 'react';
import { Space, Typography } from 'antd';
import { CheckCircleTwoTone, CloseSquareTwoTone, ClockCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';

import { AccountContactState } from '../../api';
import { Colors } from '../constants';

const { Text } = Typography;

export interface ISubscriptionStateProps {
  state: AccountContactState | undefined;
}

const SubscriptionState: React.FC<ISubscriptionStateProps> = (props) => {
  const { state } = props;

  return (
    <Space>
      {state === undefined && <>...</>}
      {state === AccountContactState.NUMBER_0 && (
        <>
          <CloseSquareOutlined />
          <Text strong>Нет</Text>
        </>
      )}
      {state === AccountContactState.NUMBER_1 && (
        <>
          <ClockCircleOutlined />
          <Text strong>Приглашен</Text>
        </>
      )}
      {state === AccountContactState.NUMBER_2 && (
        <>
          <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} />
          <Text strong>Подтвержден</Text>
        </>
      )}
      {state === AccountContactState.NUMBER_3 && (
        <>
          <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} />
          <Text strong>Подписан</Text>
        </>
      )}
      {state === AccountContactState.NUMBER_4 && (
        <>
          <CloseSquareTwoTone twoToneColor={Colors.WARNING} />
          <Text strong>Отписан</Text>
        </>
      )}
    </Space>
  );
};

export default SubscriptionState;
