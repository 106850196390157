import React, { useState } from 'react';
import { Button, Form, Input, PageHeader, Space } from 'antd';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { GroupCreationRequest } from '../../../api';
import { groupApi } from '../../apis';
import { AlertTypes } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const GroupAdd: React.FC = () => {
  const [form] = Form.useForm();
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const history = useHistory();

  const onFinish = async () => {
    const newGroup: GroupCreationRequest = {
      name: form.getFieldValue('name'),
    };

    setSaveBtnDisabled(true);
    try {
      const response = await groupApi.createGroup(newGroup);
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлена новая группа',
        description: newGroup.name,
      });
      history.push(`/groups/${response.data.id}`);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении группы',
        error: e,
      });
      setSaveBtnDisabled(false);
    }
  };

  const onBackBtnClick = () => {
    history.push('/groups');
  };

  return (
    <div>
      <PageHeader title="Новая группа" />
      <Form form={form} onFinish={onFinish}>
        <Form.Item name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <Input placeholder="Название" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={saveBtnDisabled} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={onBackBtnClick}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GroupAdd;
