import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from '../../types';

export const ArchiveDefaultIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          clipRule="evenodd"
          d="M8.33203 0C5.57061 0 3.33203 2.23858 3.33203 5V35C3.33203 37.7614 5.57061 40 8.33203 40H31.6654C34.4268 40 36.6654 37.7614 36.6654 35V5C36.6654 4.94547 36.6645 4.89115 36.6628 4.83704V6.66667L29.9961 0H8.33203Z"
          fill="#EDFAFF"
          fillRule="evenodd"
        />
        <path clipRule="evenodd" d="M30 0V6.66667H36.6667L30 0Z" fill="#C2CDDE" fillRule="evenodd" />
        <path
          d="M14.168 25.833V28.333H25.8346V25.833"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M25.8346 18.3337V15.8337L22.5013 11.667H14.168V18.3337"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M21.668 11.667V15.8337H25.8346"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path d="M16.668 15H18.3346" stroke="#075AB3" strokeLinecap="round" strokeWidth="1.2" />
        <path
          d="M27.5013 18.333H12.5013C12.0411 18.333 11.668 18.7061 11.668 19.1663V24.9997C11.668 25.4599 12.0411 25.833 12.5013 25.833H27.5013C27.9615 25.833 28.3346 25.4599 28.3346 24.9997V19.1663C28.3346 18.7061 27.9615 18.333 27.5013 18.333Z"
          stroke="#075AB3"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M14.582 20.417H17.082L14.582 23.7503H17.082"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path d="M20 20.417V23.7503" stroke="#075AB3" strokeLinecap="round" strokeWidth="1.2" />
        <path d="M22.918 20.417V23.7503" stroke="#075AB3" strokeLinecap="round" strokeWidth="1.2" />
        <path
          d="M22.918 20.417H24.3763C24.9516 20.417 25.418 20.8834 25.418 21.4587C25.418 22.034 24.9516 22.5003 24.3763 22.5003H22.918"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="40" transform="translate(3.33594)" width="33.3333" />
        </clipPath>
      </defs>
    </svg>
  );
});
