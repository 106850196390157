import { inboxChatApi } from '../../apis';

import { IOperationProgress, ITaskHandler } from './types';

export interface IInvocationSendTaskContent {
  chatId: string;
  activityId: string;
  name: string;
  entityId?: string;
}

export class InvocationSendTaskHandler implements ITaskHandler<IInvocationSendTaskContent> {
  private static s_instance?: InvocationSendTaskHandler;

  public static get instance(): InvocationSendTaskHandler {
    if (!InvocationSendTaskHandler.s_instance) {
      InvocationSendTaskHandler.s_instance = new InvocationSendTaskHandler();
    }

    return InvocationSendTaskHandler.s_instance;
  }

  public estimate(): IOperationProgress {
    return { current: 0, maximum: 1 };
  }

  public async process(content: IInvocationSendTaskContent): Promise<unknown> {
    return await inboxChatApi.sendInboxInvocation(content.chatId, {
      activityId: content.activityId,
      name: content.name,
      entityId: content.entityId,
    });
  }
}
