import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAsync } from 'react-async-hook';
import { useHistory } from 'react-router';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import SbButton from '../../../components/common/SbButton';
import SbIcon from '../../../components/common/SbIcon';
import SbTooltip from '../../../components/common/SbTooltip';
import { agentApi, botApi, botStageApi } from '../../../../apis';
import {
  BotStageModel,
  BotStageTestingResponse,
  BotStageType,
  PublishStage,
  ScenarioEditionReferenceModel,
  ScenarioSchema,
  TrainStatus,
  UpdateScenarioOperationType,
} from '../../../../../api';
import { AlertTypes, BOT_PUBLISH_STATUS_UPDATED } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import {
  currentScenarioStructureSelector,
  scenarioStructureStackSelectorCanRedo,
  scenarioStructureStackSelectorCanUndo,
} from '../../../../recoil/scenarioStructure';
import SbModal from '../../../components/common/SbModal';
import SbTypography from '../../../components/common/SbTypography';
import { hubConnections } from '../../../../utils/socketsUtil';

enum SaveButtonState {
  NoChanges = 'NoChanges',
  Saving = 'Saving',
  Preparing = 'Preparing',
  HaveUnsavedChanges = 'HaveUnsavedChanges',
  Error = 'Error',
}

interface ISaveButtonProps {
  botStage?: BotStageModel;
  title: string;
  scenario?: ScenarioEditionReferenceModel;
  onBotStageChanged: (botStage: BotStageModel) => void;
  onScenarioReloadNeeded: () => Promise<void>;
}

const SaveButton: React.FC<ISaveButtonProps> = ({
  botStage,
  scenario,
  onBotStageChanged,
  title,
  onScenarioReloadNeeded,
}) => {
  const { push, block } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const currentScenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const setCurrentScenarioStructure = useSetRecoilState(currentScenarioStructureSelector);
  const canUndo = useRecoilValue(scenarioStructureStackSelectorCanUndo);
  const canRedo = useRecoilValue(scenarioStructureStackSelectorCanRedo);

  const [blockedPath, setBlockedPath] = useState('');
  const [forceClose, setForceClose] = useState(false);
  const [closingModalVisible, setClosingModalVisible] = useState(false);
  const [confirmSavingModalVisible, setConfirmSavingModalVisible] = useState(false);
  const [savedScenarioStructure, setSavedScenarioStructure] = useState<ScenarioSchema>();
  const [testingState, setTestingState] = useState<BotStageTestingResponse>();
  const [saving, setSaving] = useState(false);
  const [userClicked, setUserClicked] = useState(false);
  const [currentTitle, setCurrentTitle] = useState(scenario?.name ?? title);

  const buttonCurrentState =
    saving || confirmSavingModalVisible
      ? SaveButtonState.Saving
      : (savedScenarioStructure && !isEqual(savedScenarioStructure, currentScenarioStructure)) ||
        (title && currentTitle && !isEqual(title, currentTitle))
      ? SaveButtonState.HaveUnsavedChanges
      : testingState?.agentStage.trainResult.status === TrainStatus.Pending
      ? SaveButtonState.Preparing
      : botStage?.type === BotStageType.Origin &&
        testingState?.agentStage.trainResult.status === TrainStatus.Error &&
        userClicked
      ? SaveButtonState.Error
      : SaveButtonState.NoChanges;

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const loadTestingState = async () => {
    if (!botStage) return;

    try {
      const response = await botStageApi.getBotStageTestingState(botStage.id);
      setTestingState(response.data);
    } catch {
      // NOTE: если произошла ошибка, то скорей всего тестирование еще не запускалось, актуально для черновиков
    }
  };

  const onCurrentScenarioStructureInit = () => {
    if (botStage && scenario && !canRedo && !canUndo && currentScenarioStructure) {
      setSavedScenarioStructure(currentScenarioStructure);
      setCurrentTitle(currentScenarioStructure.name);
      loadTestingState().finally();
    }
  };
  useEffect(onCurrentScenarioStructureInit, [canUndo, canRedo, currentScenarioStructure]);

  useEffect(() => {
    const unblock = block((prompt) => {
      if (forceClose) return;
      if (buttonCurrentState === SaveButtonState.HaveUnsavedChanges) {
        setClosingModalVisible(true);
        setBlockedPath(prompt.pathname);
        return false;
      }
    });

    return () => unblock();
  });

  const onTitleChange = (title: string) => {
    if (!currentScenarioStructure) return;
    const newScenarioStructure = cloneDeep(currentScenarioStructure);
    newScenarioStructure.name = title;
    setCurrentScenarioStructure(newScenarioStructure);
    setSavedScenarioStructure(newScenarioStructure);
  };

  const botPublishEventHandler = (args: { agentStageId: string }) => {
    if (testingState?.agentStage.id === args?.agentStageId) {
      loadTestingState().finally();
    }
  };
  const subscribe = () => {
    if (!conn) return;
    conn.on(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
    return () => conn.off(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
  };
  useEffect(subscribe, [conn, testingState]);

  const verifyConcurrentChanges = async () => {
    if (!botStage || !scenario) return false;

    const actualBotResponse = await botApi.getBot(botStage.botEntryId);

    const actualBotStage =
      actualBotResponse.data.originStage.id === botStage.id
        ? actualBotResponse.data.originStage
        : actualBotResponse.data.draftStage;

    const actualScenario = actualBotStage?.currentEdition.scenarios.find(
      (s) => s.scenarioStructureId === scenario.scenarioStructureId
    );
    if (!actualScenario) {
      // todo: дать возможность пользователю все равно как то сохранить сценарий
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Сценарий был удален другим пользователем',
        description: 'Необходимо создать новый сценарий',
      });
      return false;
    }

    // NOTE: Сценарий мог быть сохранен другим пользователем.
    if (scenario.scenarioEditionId !== actualScenario.scenarioEditionId) {
      setConfirmSavingModalVisible(true);
      return false;
    }

    return true;
  };

  const saveScenarioAsync = async (force = false) => {
    if (!botStage || !scenario || !currentScenarioStructure) {
      return;
    }

    if (currentScenarioStructure === savedScenarioStructure && title === scenario.name) {
      return;
    }

    setSaving(true);
    if (!force && !(await verifyConcurrentChanges())) {
      setSaving(false);
      return;
    }

    if (currentScenarioStructure !== savedScenarioStructure) {
      try {
        const response = await botStageApi.updateScenario(botStage.id, {
          scenarioStructureId: scenario.scenarioStructureId,
          operationType: UpdateScenarioOperationType.Edit,
          scenarioStructure: currentScenarioStructure,
        });
        onBotStageChanged(response.data);

        setSavedScenarioStructure(currentScenarioStructure);
        await loadTestingState();
      } catch (e) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при сохранении сценария',
          error: e,
        });
      }
    }

    if (title !== scenario.name) {
      try {
        const response = await botStageApi.updateScenario(botStage.id, {
          scenarioStructureId: scenario.scenarioStructureId,
          operationType: UpdateScenarioOperationType.Rename,
          name: title,
        });
        onBotStageChanged(response.data);
        onTitleChange(title);

        setCurrentTitle(title);
      } catch (e) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при изменении названия сценария',
          error: e,
        });
      }
    }

    setSaving(false);
  };

  const onSaveButtonClick = async () => {
    await saveScenarioAsync();
    setUserClicked(true);
  };

  const onErrorButtonClick = async () => {
    if (botStage?.type === BotStageType.Origin) {
      await agentApi.retrain({ agentId: botStage.agentId, publishStage: PublishStage.NUMBER_2 });
      await loadTestingState();
    }
  };

  const onConfirmSavingClick = async () => {
    setConfirmSavingModalVisible(false);
    await saveScenarioAsync(true);
  };

  const onReloadScenarioClick = async () => {
    setConfirmSavingModalVisible(false);
    await onScenarioReloadNeeded();
  };

  const onCancelSavingClick = () => setConfirmSavingModalVisible(false);

  const onCloseWithoutSavingButtonClick = () => {
    setForceClose(true);
    setClosingModalVisible(false);
    setTimeout(() => push(blockedPath));
    setTimeout(() => setForceClose(false));
  };

  const onCancelClosingButtonClick = () => setClosingModalVisible(false);

  const renderSaveButton = () => {
    switch (buttonCurrentState) {
      case SaveButtonState.NoChanges:
        return <SbButton>Сохранить</SbButton>;
      case SaveButtonState.HaveUnsavedChanges:
        return <SbButton onClick={onSaveButtonClick}>Сохранить*</SbButton>;
      case SaveButtonState.Saving:
        return (
          <SbButton loading icon={<SbIcon spin iconName="loading-four" />} sbType="icon-primary">
            Сохранение
          </SbButton>
        );
      case SaveButtonState.Preparing:
        return (
          <SbTooltip placement="bottomRight" title="Подготовка может занять некоторое время" trigger="hover">
            <SbButton loading icon={<SbIcon spin iconName="loading-four" />} sbType="icon-primary">
              Подготовка
            </SbButton>
          </SbTooltip>
        );
      case SaveButtonState.Error:
        return (
          <SbTooltip
            visible
            placement="bottomRight"
            title="Ошибка при сохранении сценария. Нажмите кнопку, чтобы попробовать снова"
          >
            <SbButton danger icon={<SbIcon iconName="attention" />} sbType="icon-primary" onClick={onErrorButtonClick}>
              Ошибка
            </SbButton>
          </SbTooltip>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {renderSaveButton()}
      <SbModal
        footer={[
          <SbButton key="save" sbSize="medium" sbType="primary" onClick={onConfirmSavingClick}>
            Перезаписать чужие изменения
          </SbButton>,
          <SbButton key="reload" sbSize="medium" sbType="primary" onClick={onReloadScenarioClick}>
            Загрузить последнюю версию
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelSavingClick}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Другой пользователь внёс изменения"
        visible={confirmSavingModalVisible}
        width={596}
        onCancel={onCancelSavingClick}
        onOk={onConfirmSavingClick}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">
            Кто-то отредактировал и сохранил изменения, пока вы правили сценарий. Загрузка последней версии приведет к
            потере текущих изменений.
          </p>
        </SbTypography>
      </SbModal>
      <SbModal
        footer={[
          <SbButton
            key="close-without-saving"
            sbSize="medium"
            sbType="secondary"
            onClick={onCloseWithoutSavingButtonClick}
          >
            Закрыть без сохранения
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" onClick={onCancelClosingButtonClick}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Закрыть сценарий?"
        visible={closingModalVisible}
        width={480}
        onCancel={onCancelClosingButtonClick}
        onOk={onCloseWithoutSavingButtonClick}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">Изменения не будут сохранены.</p>
        </SbTypography>
      </SbModal>
    </>
  );
};

export default SaveButton;
