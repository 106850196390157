import { LocalStorageLRU } from '@cocalc/local-storage-lru';

import { BotStageType, ScenarioSchema } from '../../api';

class LRULocalStorage {
  private readonly skipBrowserCheckKey = 'skipBrowserCheck';
  private readonly currentBotStageTypeKeyPrefix = 'currentBotStageType_';
  private readonly scenarioStructureKeyPrefix = 'scenarioStructure_';

  private readonly storage: LocalStorageLRU;

  constructor() {
    this.storage = new LocalStorageLRU({
      isCandidate: (key) => key.startsWith(this.scenarioStructureKeyPrefix),
    });
  }

  getSkipBrowserCheck(): boolean {
    return !!this.storage.get(this.skipBrowserCheckKey);
  }

  setSkipBrowserCheck(value: boolean) {
    this.storage.set(this.skipBrowserCheckKey, value);
  }

  getCurrentBotStageType(botId: string): BotStageType | null | undefined {
    return this.storage.get(this.getCurrentBotStageTypeStorageKey(botId)) as BotStageType;
  }

  setCurrentBotStageType(botId: string, value: BotStageType) {
    this.storage.set(this.getCurrentBotStageTypeStorageKey(botId), value);
  }

  getScenarioStructure(botStageId: string, scenarioId: string): ScenarioSchema | null | undefined {
    return this.storage.get(this.getScenarioStructureStorageKey(botStageId, scenarioId)) as ScenarioSchema;
  }

  setScenarioStructure(botStageId: string, value: ScenarioSchema) {
    this.storage.set(this.getScenarioStructureStorageKey(botStageId, value.id), value);
  }

  deleteScenarioStructure(botStageId: string, scenarioId: string) {
    this.storage.delete(this.getScenarioStructureStorageKey(botStageId, scenarioId));
  }

  private getCurrentBotStageTypeStorageKey = (botId: string) => `${this.currentBotStageTypeKeyPrefix}${botId}`;

  private getScenarioStructureStorageKey = (botStageId: string, scenarioId: string) =>
    `${this.scenarioStructureKeyPrefix}${botStageId}_${scenarioId}`;
}

const lruLocalStorage = new LRULocalStorage();

export { lruLocalStorage };
