import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Table, TablePaginationConfig } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { CampaignModel } from '../../../api';
import { campaignApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

const CampaignList: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listCampaigns, setListCampaigns] = useState([] as CampaignModel[]);
  const history = useHistory();

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await campaignApi.listCampaigns((pagination.current ?? 1) - 1, pagination.pageSize);
      setListCampaigns(response.data.items || []);
      setPagination({ ...pagination, total: response.data.items?.length || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка рассылок',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize]);

  const onAddBtnClick = () => {
    history.push('/campaigns/add');
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'Заголовок',
      dataIndex: 'name',
      render: (_text: unknown, campaign: CampaignModel) => (
        <Link
          to={{
            pathname: `/campaigns/${campaign.id}`,
          }}
        >
          {campaign.name}
        </Link>
      ),
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdOn',
      render: (createdOn: string) => new Date(createdOn).toLocaleString(),
      ellipsis: true,
    },
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="1" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить
          </Button>,
        ]}
        title="Рассылки"
      />
      <Table
        columns={columns}
        dataSource={listCampaigns.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default CampaignList;
