import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import './index.less';

import { SingleBotModel } from '../../../../../../api';
import { agentApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';
import { inboxAlertsSelectorAdd } from '../../../../recoil';

import AnalyticsCard from './AnalyticsCard';
import DialogsCard from './DialogsCard';
import ChannelsCard from './ChannelsCard';
import ScenariosCard from './ScenariosCard';
import KnowledgeBaseCard from './KnowledgeBaseCard';

const MAIN_CLASS_NAME = 'ib-bot-cards';
const CARD_WRAPPER_ONE_COLUMN_CLASS_NAME = `${MAIN_CLASS_NAME}__wrapper-one-column`;
const CARD_WRAPPER_TWO_COLUMNS_CLASS_NAME = `${MAIN_CLASS_NAME}__wrapper-two-columns`;
const CARD_WRAPPER_FREE_HEIGHT_CLASS_NAME = `${MAIN_CLASS_NAME}__wrapper-free-height`;

interface IBotCardsProps {
  bot?: SingleBotModel;
  onDataChanged: () => Promise<void>;
}

const BotCards: React.FC<IBotCardsProps> = ({ bot, onDataChanged }) => {
  const { t } = useTranslation();
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [agentStageId, setAgentStageId] = useState<string | undefined>();

  const botStage = bot?.originStage;
  const botCurrentEdition = bot?.originCurrentEdition;

  const loadDataAsync = async () => {
    if (!bot) {
      return;
    }

    try {
      const agentResponse = await agentApi.getAgent(bot.originStage.agentId);
      const agentStageId = agentResponse.data.productionAgent.id;
      setAgentStageId(agentStageId);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [bot]);

  return (
    <div className={MAIN_CLASS_NAME}>
      <div className={CARD_WRAPPER_ONE_COLUMN_CLASS_NAME}>
        <AnalyticsCard agentStageId={agentStageId} botId={bot?.entry.id} />
      </div>
      <div className={CARD_WRAPPER_ONE_COLUMN_CLASS_NAME}>
        <DialogsCard agentStageId={agentStageId} botId={bot?.entry.id} />
      </div>
      <div className={CARD_WRAPPER_TWO_COLUMNS_CLASS_NAME}>
        <ChannelsCard bot={bot} />
      </div>
      <div className={[CARD_WRAPPER_TWO_COLUMNS_CLASS_NAME, CARD_WRAPPER_FREE_HEIGHT_CLASS_NAME].join(' ')}>
        <ScenariosCard bot={bot} />
      </div>
      <div className={[CARD_WRAPPER_TWO_COLUMNS_CLASS_NAME, CARD_WRAPPER_FREE_HEIGHT_CLASS_NAME].join(' ')}>
        <KnowledgeBaseCard
          bot={bot}
          botEdition={botCurrentEdition}
          botStageId={botStage?.id}
          onDataChanged={onDataChanged}
        />
      </div>
    </div>
  );
};

export default BotCards;
