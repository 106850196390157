import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';
import moment from 'moment';

import { InboxParticipantStatus, InboxParticipantUpdatingRequest } from '../../../api';
import { currentOperatorSelector, currentOperatorStatusSelector, operatorGroupListSelector } from '../recoil';
import IbAccountWidget from '../components/IbAccountWidget';
import { ITaskData, TaskQueue, TaskType } from '../tasks';
import { IParticipantStatusUpdateTaskContent } from '../tasks/participants';
import { inboxParticipantApi } from '../../apis';
import { INBOX_DEFAULT_PATH, logoutUser } from '../../utils/oidcUtil';

const IbAccountWidgetWrapper: React.FC = () => {
  const queue = TaskQueue.instance;
  const setCurrentOperatorStatus = useSetRecoilState(currentOperatorStatusSelector);
  const currentOperator = useRecoilValue(currentOperatorSelector)?.entity;
  const groupList = useRecoilValue(operatorGroupListSelector);

  const onCurrentOperatorStatusChange = async (status: InboxParticipantStatus) => {
    if (!currentOperator) {
      return;
    }

    setCurrentOperatorStatus(status);

    const task: ITaskData<IParticipantStatusUpdateTaskContent> = {
      key: v4(),
      timestamp: moment().toISOString(),
      type: TaskType.ParticipantStatusUpdate,
      content: {
        participantId: currentOperator?.id,
        status: status,
      },
    };

    await queue.run(task);
  };

  const onCurrentOperatorEdit = async (request: InboxParticipantUpdatingRequest) => {
    if (!currentOperator) {
      return;
    }

    try {
      await inboxParticipantApi.updateInboxParticipant(currentOperator.id, request);
    } catch (e) {
      // empty
    }
  };

  const onInboxLogout = async () => await logoutUser({ state: INBOX_DEFAULT_PATH });

  return (
    <IbAccountWidget
      currentOperator={currentOperator}
      groupList={groupList}
      onCurrentOperatorEdit={onCurrentOperatorEdit}
      onCurrentOperatorStatusChange={onCurrentOperatorStatusChange}
      onLogout={onInboxLogout}
    />
  );
};

export default IbAccountWidgetWrapper;
