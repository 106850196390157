import React, { ReactNode } from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbUserStatus, { IbUserStatusType } from '../IbUserStatus';
import IbSocial, { IbSocialType } from '../IbSocial';
import IbIcon, { IbIconName } from '../IbIcon';
import IbTypography from '../IbTypography';
import IbTooltip from '../IbTooltip';
import { getInitials } from '../../../../utils/stringUtil';

export type IbAvatarSize = 'x-small' | 'small' | 'medium' | 'large';

const COLORS = [
  '#FF92CA',
  '#FF92A5',
  '#EC8F7F',
  '#B6D490',
  '#85CB97',
  '#7DD4C1',
  '#AAEB87',
  '#FFD67E',
  '#FFAA7A',
  '#DBAC8B',
  '#92DDFF',
  '#9FBAFF',
  '#A189FF',
  '#B1BBCE',
  '#AEAEAE',
  '#B99FC8',
];
const SIZE_DEFAULT = 'medium';

const MAIN_CLASS_NAME = 'ib-avatar';
const ICON_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__icon-container`;
const CHILDREN_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__children-container`;
const IMG_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__img-container`;
const IMG_CLASS_NAME = `${IMG_CONTAINER_CLASS_NAME}__img`;
const META_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__meta-container`;
const USER_STATUS_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__status-container`;
const SOCIAL_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__social-container`;
const CLICKABLE_CLASS_NAME = `${MAIN_CLASS_NAME}_clickable`;
const DARK_BACKGROUND_CLASS_NAME = `${MAIN_CLASS_NAME}_dark`;

const getColor = (uid: string): string => {
  let sum = 0;
  for (let i = 0; i < uid.length; i++) {
    sum += uid.charCodeAt(i);
  }

  return COLORS[sum % COLORS.length];
};

export interface IIbAvatarProps {
  className?: string;
  size?: IbAvatarSize;
  imgSrc?: string | null;
  iconName?: IbIconName | null;
  metadata?: {
    uid: string;
    text: string;
  };
  tooltipContent?: ReactNode;
  userStatus?: IbUserStatusType;
  social?: IbSocialType;
  dark?: boolean;
  onClick?: () => void;
}

const IbAvatar: React.FC<IIbAvatarProps> = ({
  className,
  children,
  size = SIZE_DEFAULT,
  imgSrc,
  iconName,
  metadata,
  tooltipContent,
  userStatus,
  social,
  dark,
  onClick,
}) => {
  size = getDefaultIfUndefined(size, SIZE_DEFAULT);
  userStatus = getDefaultIfUndefined(userStatus, undefined);
  social = getDefaultIfUndefined(social, undefined);

  const classes = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${size}`];
  className && classes.push(className);

  onClick && classes.push(CLICKABLE_CLASS_NAME);
  dark && classes.push(DARK_BACKGROUND_CLASS_NAME);

  const renderContent = () => {
    if (iconName) {
      return (
        <div className={ICON_CONTAINER_CLASS_NAME}>
          <IbIcon iconName={iconName as IbIconName} size={16} />
        </div>
      );
    }

    if (children) {
      return <div className={CHILDREN_CONTAINER_CLASS_NAME}>{children}</div>;
    }

    if (imgSrc) {
      return (
        <div className={IMG_CONTAINER_CLASS_NAME}>
          <img alt="" className={IMG_CLASS_NAME} src={imgSrc} />
        </div>
      );
    }

    if (metadata) {
      const background = getColor(metadata.uid);
      const initials = getInitials(metadata.text);
      return (
        <div className={META_CONTAINER_CLASS_NAME} style={{ background }}>
          {size === 'x-small' && (
            <IbTypography.Paragraph strong type="descriptor">
              {initials}
            </IbTypography.Paragraph>
          )}
          {size === 'small' && (
            <IbTypography.Paragraph strong type="secondary">
              {initials}
            </IbTypography.Paragraph>
          )}
          {size === 'medium' && (
            <IbTypography>
              <h4>{initials}</h4>
            </IbTypography>
          )}
          {size === 'large' && (
            <IbTypography>
              <h2>{initials}</h2>
            </IbTypography>
          )}
        </div>
      );
    }

    return <div className={ICON_CONTAINER_CLASS_NAME}></div>;
  };

  const renderUserStatus = () =>
    !!userStatus && (
      <div className={USER_STATUS_CONTAINER_CLASS_NAME}>
        <IbUserStatus size={size} status={userStatus} />
      </div>
    );

  const renderSocial = () =>
    social ? (
      <div className={SOCIAL_CONTAINER_CLASS_NAME}>
        <IbSocial social={social} />
      </div>
    ) : null;

  const renderAvatar = () => (
    <div className={classes.join(' ')} onClick={onClick}>
      {renderContent()}
      {renderUserStatus()}
      {renderSocial()}
    </div>
  );

  return tooltipContent ? <IbTooltip title={tooltipContent}>{renderAvatar()}</IbTooltip> : renderAvatar();
};

export default IbAvatar;
