import React, { MouseEventHandler, ReactNode, useState } from 'react';
import { Menu } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { CallbackInterface, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import { groupWidth, usualPadding } from '../../../constants';
import { BindingSchema, DefaultScenarioSchema, DefaultTriggerGroupSchema, TriggerSchema } from '../../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  dispatcherState,
  selectedEntitySelector,
  scenarioActionsSelector,
  zoomForDraggableSelector,
} from '../../../../../recoil/scenarioStructure';
import {
  EntityFieldPath,
  deleteBindingWithReferences,
  getElementId,
  instanceOfTransitionTriggerSchema,
} from '../../../utils';
import ContextMenu from '../../common/ContextMenu';
import OutputConnection from '../../common/OutputConnection';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import TriggerView from '../TriggerViewModal';
import { IScenarioEntity } from '../../../types';
import { parseTranslateTransform } from '../../../../../utils/stringUtil';

const groupElementWidth = groupWidth - usualPadding * 2 - 4;

interface TriggerWrapperProps {
  index: number;
  trigger: TriggerSchema;
  group: DefaultTriggerGroupSchema;
  icon: ReactNode;
  title: ReactNode | string;
  menuItems: ReactNode[];
}

const TriggerWrapper: React.FC<TriggerWrapperProps> = ({ trigger, index, group, icon, title, menuItems, children }) => {
  const { updateZoomForDraggable } = useRecoilValue(dispatcherState);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);
  const zoomForDraggable = useRecoilValue(zoomForDraggableSelector);
  const [triggerViewModalVisible, setTriggerViewModalVisible] = useState(false);
  const [triggerViewActions, setTriggerViewActions] = useState([] as IScenarioEntity[]);

  const getScenarioActions = useRecoilCallback(
    ({ snapshot }: CallbackInterface) => async (scenario: DefaultScenarioSchema) =>
      await snapshot.getPromise(scenarioActionsSelector(scenario))
  );

  const onViewTriggerMenuSelect = async () => {
    if (!scenarioStructure) return;

    setTriggerViewActions(await getScenarioActions(scenarioStructure));
    setTriggerViewModalVisible(true);
  };
  const onViewTriggerModalClose = () => setTriggerViewModalVisible(false);

  const onDeleteTriggerMenuSelect = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers = group.triggers.filter(
      (t) => t.id !== trigger.id
    );

    deleteBindingWithReferences(newScenarioStructure, trigger.outputBindingId);

    setScenarioStructure(newScenarioStructure);
  };

  const onMouseDown: MouseEventHandler = (e) => {
    e.stopPropagation();
    setSelectedEntity(trigger);
  };

  return (
    <Draggable key={trigger.id} draggableId={trigger.id} index={index}>
      {(provided, snapshot) => {
        const isSelected = trigger.id === selectedEntity?.id;
        const isOutputBindingSelected = trigger.id === (selectedEntity as BindingSchema)?.sourceEntityId;

        const classes = ['group-element', 'group-element_is-trigger'];
        if (snapshot.isDragging) {
          updateZoomForDraggable().finally();
          classes.push('group-element_dragging');
        }
        if (isSelected || isOutputBindingSelected) {
          classes.push('group-element_selected');
        }
        if (scenarioValidation?.hasIssue(trigger.id, EntityFieldPath.Root)) {
          classes.push('group-element_warning');
        }

        const { x, y } = parseTranslateTransform(provided.draggableProps.style?.transform);
        const transform = `translate(${x / zoomForDraggable}px, ${y / zoomForDraggable}px)`;

        return (
          <div
            id={getElementId(trigger.id)}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={classes.join(' ')}
            style={{
              ...provided.draggableProps.style,
              transform,
              width: groupElementWidth,
            }}
            onMouseDown={onMouseDown}
          >
            <div className="group-element__header">
              <div className="group-element-icon" {...provided.dragHandleProps}>
                {icon}
              </div>
              <div
                className="group-element-title"
                title={typeof title === 'string' ? title : undefined}
                {...provided.dragHandleProps}
              >
                {title}
              </div>
              <ContextMenu
                menuContent={
                  <Menu>
                    {(menuItems || []).map((mi) => mi)}
                    {instanceOfTransitionTriggerSchema(trigger) && (
                      <Menu.Item key="view" onClick={onViewTriggerMenuSelect}>
                        <SbIcon iconName="link-one" />
                      </Menu.Item>
                    )}
                    <Menu.Item key="delete" onClick={onDeleteTriggerMenuSelect}>
                      <SbIcon iconName="close" />
                    </Menu.Item>
                  </Menu>
                }
              />
              <TriggerView
                actions={triggerViewActions}
                visible={triggerViewModalVisible}
                onClose={onViewTriggerModalClose}
              />
            </div>
            {children}
            <OutputConnection
              entity={trigger}
              groupId={group.id}
              id={getElementId(trigger.id, EntityFieldPath.OutputBindingId)}
              isValid={!scenarioValidation?.hasIssue(trigger.id, EntityFieldPath.OutputBindingId)}
            />
          </div>
        );
      }}
    </Draggable>
  );
};

export default TriggerWrapper;
