import React, { useEffect, useState } from 'react';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useSetRecoilState } from 'recoil';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import './index.less';

import { ConversationsPerDayModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';
import { ChannelNames } from '../../../utils/analytics';

const CHART_SETTINGS = {
  stackId: 'stackId',
  barColors: {
    total: '#53A6FF',
    directline: '#58C1A1',
    livechat: '#5082E6',
    telegram: '#0088CC',
    viber: '#574E98',
    whatsapp: '#00A884',
    elma365_telegram: '#0088CA',
    elma365_telegram_bot: '#0088CF',
    elma365_viberbot: '#574E92',
    elma365_instagram: '#5AD2FF',
    elma365_vkontakte: '#E62D32',
    elma365_whatsapp: '#FABE00',
    elma365_quick: '#233255',
  } as { [x: string]: string },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as ConversationsPerDayModel;
  if (data.totalCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        Количество бесед: <b>{data.totalCount}</b>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContentWithChannels: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as ConversationsPerDayModel;
  if (data.totalCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      {Object.keys(data.channelCount).map((channelFullId) => {
        return (
          <div key={channelFullId}>
            {ChannelNames[channelFullId]}: <b>{data.channelCount[channelFullId]}</b>
          </div>
        );
      })}
    </div>
  );
};

interface ISbConversationsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbConversationsAnalytics: React.FC<ISbConversationsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ConversationsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await analyticsReportsApi.getConversationsPerDayList(
        agentStageId,
        filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse),
        filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse)
      );
      setData(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по количеству бесед',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const existChannelFullIds = Array.from(new Set(data.flatMap((e) => Object.keys(e.channelCount))));

  return (
    <div className="sb-conversations-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Беседы</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                {
                  title: 'Всего бесед',
                  dataIndex: 'totalCount',
                  key: 'totalCount',
                },
              ]}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis dataKey={(e: ConversationsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Bar
                  dataKey={(e: ConversationsPerDayModel) => e.totalCount}
                  fill={CHART_SETTINGS.barColors.total}
                  isAnimationActive={isAnimationActive}
                  name="Количество бесед"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Беседы по каналам</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={([
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                ...existChannelFullIds.map((channelFullId) => ({
                  title: ChannelNames[channelFullId],
                  key: channelFullId,
                  render: (_: unknown, record: ConversationsPerDayModel) => record.channelCount[channelFullId] || 0,
                })),
              ] as ColumnsType<ConversationsPerDayModel>).filter((e) => !!e)}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis dataKey={(e: ConversationsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContentWithChannels />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                {existChannelFullIds.map((channelFullId) => (
                  <Bar
                    key={channelFullId}
                    dataKey={(e: ConversationsPerDayModel) => e.channelCount[channelFullId] || 0}
                    fill={CHART_SETTINGS.barColors[channelFullId]}
                    isAnimationActive={isAnimationActive}
                    name={ChannelNames[channelFullId]}
                    stackId={CHART_SETTINGS.stackId}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbConversationsAnalytics;
