import React, { forwardRef, RefAttributes, useImperativeHandle } from 'react';
import useSound from 'use-sound';
import { PlayFunction } from 'use-sound/dist/types';

import './index.less';

import IbIcon from '../IbIcon';
import IbButton from '../IbButton';
import soundAlarmedOgg from '../../../assets/audio/Alarmed.ogg';
import soundBeeperOgg from '../../../assets/audio/Beeper.ogg';
import soundBelligerentOgg from '../../../assets/audio/Belligerent.ogg';
import soundCalmOgg from '../../../assets/audio/Calm.ogg';
import soundChordOgg from '../../../assets/audio/Chord.ogg';
import soundChord2Ogg from '../../../assets/audio/Chord2.ogg';
import soundChord2RevOgg from '../../../assets/audio/Chord2_Rev.ogg';
import soundCloudOgg from '../../../assets/audio/Cloud.ogg';
import soundDoorbellOgg from '../../../assets/audio/Doorbell.ogg';
import soundEnharpmentOgg from '../../../assets/audio/Enharpment.ogg';
import soundFlitFluteOgg from '../../../assets/audio/Flit_Flute.ogg';
import soundGlassOgg from '../../../assets/audio/Glass.ogg';
import soundGlistenOgg from '../../../assets/audio/Glisten.ogg';
import soundGlitchOgg from '../../../assets/audio/Glitch.ogg';
import soundInformationBellOgg from '../../../assets/audio/Information_Bell.ogg';
import soundInformationBlockOgg from '../../../assets/audio/Information_Block.ogg';
import soundJinjaOgg from '../../../assets/audio/Jinja.ogg';
import soundJinja2Ogg from '../../../assets/audio/Jinja2.ogg';
import soundKotoOgg from '../../../assets/audio/Koto.ogg';
import soundModularOgg from '../../../assets/audio/Modular.ogg';
import soundNewsflashOgg from '../../../assets/audio/Newsflash.ogg';
import soundNewsflashBrightOgg from '../../../assets/audio/Newsflash_Bright.ogg';
import soundPizzaBoxOgg from '../../../assets/audio/PizzaBox.ogg';
import soundPoliteOgg from '../../../assets/audio/Polite.ogg';
import soundPonderousOgg from '../../../assets/audio/Ponderous.ogg';
import soundReverieOgg from '../../../assets/audio/Reverie.ogg';
import soundSharpOgg from '../../../assets/audio/Sharp.ogg';
import soundSonarOgg from '../../../assets/audio/Sonar.ogg';
import soundTaptapOgg from '../../../assets/audio/Taptap.ogg';
import soundTechOgg from '../../../assets/audio/Tech.ogg';
import soundUnphasedOgg from '../../../assets/audio/Unphased.ogg';
import soundUnstrungOgg from '../../../assets/audio/Unstrung.ogg';
import soundWahsUpOgg from '../../../assets/audio/WahsUp.ogg';
import soundWhistleronicOgg from '../../../assets/audio/Whistleronic.ogg';
import soundWhistleronicDownOgg from '../../../assets/audio/Whistleronic-Down.ogg';
import soundWoodblockOgg from '../../../assets/audio/Woodblock.ogg';

import {
  MAIN_CLASS_NAME,
  SOUND_NONE,
  SOUND_ALARMED_OGG,
  SOUND_BEEPER_OGG,
  SOUND_BELLIGERENT_OGG,
  SOUND_CALM_OGG,
  SOUND_CHORD_OGG,
  SOUND_CHORD2_OGG,
  SOUND_CHORD2_REV_OGG,
  SOUND_CLOUD_OGG,
  SOUND_DOORBELL_OGG,
  SOUND_ENHARPMENT_OGG,
  SOUND_FLIT_FLUTE_OGG,
  SOUND_GLASS_OGG,
  SOUND_GLISTEN_OGG,
  SOUND_GLITCH_OGG,
  SOUND_INFORMATION_BELL_OGG,
  SOUND_INFORMATION_BLOCK_OGG,
  SOUND_JINJA_OGG,
  SOUND_JINJA2_OGG,
  SOUND_KOTO_OGG,
  SOUND_MODULAR_OGG,
  SOUND_NEWSFLASH_OGG,
  SOUND_NEWSFLASH_BRIGHT_OGG,
  SOUND_PIZZA_BOX_OGG,
  SOUND_POLITE_OGG,
  SOUND_PONDEROUS_OGG,
  SOUND_REVERIE_OGG,
  SOUND_SHARP_OGG,
  SOUND_SONAR_OGG,
  SOUND_TAPTAP_OGG,
  SOUND_TECH_OGG,
  SOUND_UNPHASED_OGG,
  SOUND_UNSTRUNG_OGG,
  SOUND_WAHS_UP_OGG,
  SOUND_WHISTLERONIC_OGG,
  SOUND_WHISTLERONIC_DOWN_OGG,
  SOUND_WOODBLOCK_OGG,
} from './const';

export interface IIbSoundProps {
  defaultValue?: string | null;
  interactive?: boolean;
  disabled?: boolean;
}

export interface IIbSoundRef {
  play(value?: string | null): void;
}

const IbSound: React.ForwardRefExoticComponent<IIbSoundProps & RefAttributes<IIbSoundRef>> = forwardRef(
  ({ defaultValue, interactive, disabled }, ref) => {
    const [playSoundAlarmedOgg] = useSound(soundAlarmedOgg);
    const [playSoundBeeperOgg] = useSound(soundBeeperOgg);
    const [playSoundBelligerentOgg] = useSound(soundBelligerentOgg);
    const [playSoundCalmOgg] = useSound(soundCalmOgg);
    const [playSoundChordOgg] = useSound(soundChordOgg);
    const [playSoundChord2Ogg] = useSound(soundChord2Ogg);
    const [playSoundChord2RevOgg] = useSound(soundChord2RevOgg);
    const [playSoundCloudOgg] = useSound(soundCloudOgg);
    const [playSoundDoorbellOgg] = useSound(soundDoorbellOgg);
    const [playSoundEnharpmentOgg] = useSound(soundEnharpmentOgg);
    const [playSoundFlitFluteOgg] = useSound(soundFlitFluteOgg);
    const [playSoundGlassOgg] = useSound(soundGlassOgg);
    const [playSoundGlistenOgg] = useSound(soundGlistenOgg);
    const [playSoundGlitchOgg] = useSound(soundGlitchOgg);
    const [playSoundInformationBellOgg] = useSound(soundInformationBellOgg);
    const [playSoundInformationBlockOgg] = useSound(soundInformationBlockOgg);
    const [playSoundJinjaOgg] = useSound(soundJinjaOgg);
    const [playSoundJinja2Ogg] = useSound(soundJinja2Ogg);
    const [playSoundKotoOgg] = useSound(soundKotoOgg);
    const [playSoundModularOgg] = useSound(soundModularOgg);
    const [playSoundNewsflashOgg] = useSound(soundNewsflashOgg);
    const [playSoundNewsflashBrightOgg] = useSound(soundNewsflashBrightOgg);
    const [playSoundPizzaBoxOgg] = useSound(soundPizzaBoxOgg);
    const [playSoundPoliteOgg] = useSound(soundPoliteOgg);
    const [playSoundPonderousOgg] = useSound(soundPonderousOgg);
    const [playSoundReverieOgg] = useSound(soundReverieOgg);
    const [playSoundSharpOgg] = useSound(soundSharpOgg);
    const [playSoundSonarOgg] = useSound(soundSonarOgg);
    const [playSoundTaptapOgg] = useSound(soundTaptapOgg);
    const [playSoundTechOgg] = useSound(soundTechOgg);
    const [playSoundUnphasedOgg] = useSound(soundUnphasedOgg);
    const [playSoundUnstrungOgg] = useSound(soundUnstrungOgg);
    const [playSoundWahsUpOgg] = useSound(soundWahsUpOgg);
    const [playSoundWhistleronicOgg] = useSound(soundWhistleronicOgg);
    const [playSoundWhistleronicDownOgg] = useSound(soundWhistleronicDownOgg);
    const [playSoundWoodblockOgg] = useSound(soundWoodblockOgg);

    const soundMap: { [key: string]: PlayFunction } = {
      [SOUND_ALARMED_OGG]: playSoundAlarmedOgg,
      [SOUND_BEEPER_OGG]: playSoundBeeperOgg,
      [SOUND_BELLIGERENT_OGG]: playSoundBelligerentOgg,
      [SOUND_CALM_OGG]: playSoundCalmOgg,
      [SOUND_CHORD_OGG]: playSoundChordOgg,
      [SOUND_CHORD2_OGG]: playSoundChord2Ogg,
      [SOUND_CHORD2_REV_OGG]: playSoundChord2RevOgg,
      [SOUND_CLOUD_OGG]: playSoundCloudOgg,
      [SOUND_DOORBELL_OGG]: playSoundDoorbellOgg,
      [SOUND_ENHARPMENT_OGG]: playSoundEnharpmentOgg,
      [SOUND_FLIT_FLUTE_OGG]: playSoundFlitFluteOgg,
      [SOUND_GLASS_OGG]: playSoundGlassOgg,
      [SOUND_GLISTEN_OGG]: playSoundGlistenOgg,
      [SOUND_GLITCH_OGG]: playSoundGlitchOgg,
      [SOUND_INFORMATION_BELL_OGG]: playSoundInformationBellOgg,
      [SOUND_INFORMATION_BLOCK_OGG]: playSoundInformationBlockOgg,
      [SOUND_JINJA_OGG]: playSoundJinjaOgg,
      [SOUND_JINJA2_OGG]: playSoundJinja2Ogg,
      [SOUND_KOTO_OGG]: playSoundKotoOgg,
      [SOUND_MODULAR_OGG]: playSoundModularOgg,
      [SOUND_PONDEROUS_OGG]: playSoundPonderousOgg,
      [SOUND_NEWSFLASH_OGG]: playSoundNewsflashOgg,
      [SOUND_NEWSFLASH_BRIGHT_OGG]: playSoundNewsflashBrightOgg,
      [SOUND_PIZZA_BOX_OGG]: playSoundPizzaBoxOgg,
      [SOUND_POLITE_OGG]: playSoundPoliteOgg,
      [SOUND_REVERIE_OGG]: playSoundReverieOgg,
      [SOUND_SHARP_OGG]: playSoundSharpOgg,
      [SOUND_SONAR_OGG]: playSoundSonarOgg,
      [SOUND_TAPTAP_OGG]: playSoundTaptapOgg,
      [SOUND_TECH_OGG]: playSoundTechOgg,
      [SOUND_UNPHASED_OGG]: playSoundUnphasedOgg,
      [SOUND_UNSTRUNG_OGG]: playSoundUnstrungOgg,
      [SOUND_WAHS_UP_OGG]: playSoundWahsUpOgg,
      [SOUND_WHISTLERONIC_OGG]: playSoundWhistleronicOgg,
      [SOUND_WHISTLERONIC_DOWN_OGG]: playSoundWhistleronicDownOgg,
      [SOUND_WOODBLOCK_OGG]: playSoundWoodblockOgg,
    };

    const hasValue = (value?: string | null) => !!value && value !== SOUND_NONE;

    const isDisabled = (value?: string | null) => disabled || !hasValue(value);

    const playSound = (value?: string | null) => {
      if (!value || isDisabled(value)) {
        return;
      }

      const sound = soundMap[value];
      if (!sound) {
        return;
      }

      sound();
    };

    useImperativeHandle(ref, () => ({
      play(value?: string | null) {
        playSound(value || defaultValue);
      },
    }));

    if (!interactive) {
      return null;
    }

    const onButtonClick = () => {
      playSound(defaultValue);
    };

    return (
      <div className={MAIN_CLASS_NAME}>
        <IbButton disabled={isDisabled(defaultValue)} type="icon-bordered" onClick={onButtonClick}>
          <IbIcon iconName="play-one" />
        </IbButton>
      </div>
    );
  }
);

export default IbSound;
