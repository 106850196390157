import React, { memo, RefObject, useEffect } from 'react';
import { CallbackInterface, useRecoilCallback, useRecoilValue } from 'recoil';

import { dispatcherState, groupPositionsSelector, zoomSelector } from '../../../../recoil/scenarioStructure';

interface IInputConnectionPositionsUpdaterProps {
  groupRef: RefObject<HTMLDivElement>;
  leftInputHandleRef: RefObject<HTMLDivElement>;
  rightInputHandleRef: RefObject<HTMLDivElement>;
  groupId: string;
}

const InputConnectionPositionsUpdater: React.FC<IInputConnectionPositionsUpdaterProps> = ({
  groupRef,
  leftInputHandleRef,
  rightInputHandleRef,
  groupId,
}) => {
  const { inputConnectionPositionsAddOrUpdate } = useRecoilValue(dispatcherState);
  const groupPosition = useRecoilValue(groupPositionsSelector(groupId));

  const addOrUpdateInputConnectionPositions = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { snapshot } = callbackHelpers;
    const zoom = await snapshot.getPromise(zoomSelector);

    const groupRect = groupRef.current?.getBoundingClientRect();
    const leftRect = leftInputHandleRef.current?.getBoundingClientRect();
    const rightRect = rightInputHandleRef.current?.getBoundingClientRect();
    if (!groupRect || !leftRect || !rightRect) return;

    const getPosX = (rect: DOMRect) =>
      (rect.left + rect.width / 2 - (groupRect?.left || 0)) / zoom + groupPosition.positionX;
    const getPosY = (rect: DOMRect) =>
      (rect.top + rect.height / 2 - (groupRect?.top || 0)) / zoom + groupPosition.positionY;

    await inputConnectionPositionsAddOrUpdate({
      id: groupId,
      positionX: getPosX(leftRect),
      positionY: getPosY(leftRect),
      altPositionX: getPosX(rightRect),
      altPositionY: getPosY(rightRect),
    });
  });
  const onGroupPositionChange = () => {
    addOrUpdateInputConnectionPositions().finally();
  };
  useEffect(onGroupPositionChange, [
    groupRef.current,
    leftInputHandleRef.current,
    rightInputHandleRef.current,
    groupPosition,
  ]);

  return null;
};

export default memo(InputConnectionPositionsUpdater);
