import React, { useEffect, useState } from 'react';

import './index.less';

import { ExternalSignInSchema, OAuth2LoginProtocolSchema } from '../../../../../../api';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import SbScroll from '../../../../../simple-bot/components/common/SbScroll';
import SbTooltip from '../../../../../simple-bot/components/common/SbTooltip';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import { DEFAULT_INPUT_MAX_TURN_COUNT } from '../../../constants';
import { getProfile } from '../../../../../utils/oidcUtil';

interface IExternalSignInSettingsModalProps {
  action: ExternalSignInSchema;
  visible: boolean;
  onChange: (action: ExternalSignInSchema) => void;
  onClose: () => void;
}

const MODAL_WIDTH = 548;
const DEFAULT_DOMAIN_ZONE = 'elma365.ru';

export enum TooltipFields {
  NONE = '',
  PROMPT = 'prompt',
  TEXT = 'text',
  TITLE = 'title',
  AUTHORIZE_ENDPOINT = 'authorizeEndpoint',
  TOKEN_ENDPOINT = 'tokenEndpoint',
  JWKS_ENDPOINT = 'jwksEndpoint',
  CLIENT_ID = 'clientId',
  CLIENT_SECRET = 'clientSecret',
  SCOPES = 'scopes',
}

const ExternalSignInSettingsModal: React.FC<IExternalSignInSettingsModalProps> = ({
  action,
  visible,
  onChange,
  onClose,
}) => {
  // NOTE: Пока UI реализован для OAuth 2.0.
  const protocol = action.protocol as OAuth2LoginProtocolSchema;

  const [tooltipField, setTooltipField] = useState(TooltipFields.NONE);

  const [maxTurnCount, setMaxTurnCount] = useState(action.maxTurnCount);
  const [prompt, setPrompt] = useState(action.prompt);
  const [text, setText] = useState(action.text);
  const [title, setTitle] = useState(action.title);
  const [authorizeEndpoint, setAuthorizeEndpoint] = useState(protocol.authorizeEndpoint);
  const [tokenEndpoint, setTokenEndpoint] = useState(protocol.tokenEndpoint);
  const [jwksEndpoint, setJwksEndpoint] = useState(protocol.jwksEndpoint);
  const [clientId, setClientId] = useState(protocol.clientId);
  const [clientSecret, setClientSecret] = useState(protocol.clientSecret);
  const [scopes, setScopes] = useState(protocol.scopes);

  const userProfile = getProfile();
  const domainZone = userProfile.originalHost
    ? userProfile.originalHost.endsWith(DEFAULT_DOMAIN_ZONE)
      ? userProfile.originalHost.split('.').slice(1).join('.')
      : userProfile.originalHost
    : DEFAULT_DOMAIN_ZONE;

  const showTooltip = (field: TooltipFields) => () => setTooltipField(field);
  const hideTooltip = () => setTooltipField(TooltipFields.NONE);

  const onModalClose = () => {
    onClose();
  };

  const onSaveSettings = () => {
    onModalClose();

    if (
      maxTurnCount === action.maxTurnCount &&
      prompt === action.prompt &&
      text === action.title &&
      title === action.title &&
      authorizeEndpoint === protocol.authorizeEndpoint &&
      tokenEndpoint === protocol.tokenEndpoint &&
      jwksEndpoint === protocol.jwksEndpoint &&
      clientId === protocol.clientId &&
      clientSecret === protocol.clientSecret &&
      scopes === protocol.scopes
    ) {
      return;
    }

    onChange({
      ...action,
      maxTurnCount,
      prompt,
      text,
      title,
      protocol: {
        ...protocol,
        authorizeEndpoint,
        tokenEndpoint,
        jwksEndpoint,
        clientId,
        clientSecret,
        scopes,
      },
    });
  };

  const onMaxTurnCountChange = (value: string) =>
    setMaxTurnCount(value ? Number.parseFloat(value) : DEFAULT_INPUT_MAX_TURN_COUNT);
  const onPromptChange = (value: string) => setPrompt(value);
  const onTextChange = (value: string) => setText(value);
  const onTitleChange = (value: string) => setTitle(value);
  const onAuthorizeEndpointChange = (value: string) => setAuthorizeEndpoint(value);
  const onTokenEndpointChange = (value: string) => setTokenEndpoint(value);
  const onJwksEndpointChange = (value: string) => setJwksEndpoint(value);
  const onClientIdChange = (value: string) => setClientId(value);
  const onClientSecretChange = (value: string) => setClientSecret(value);
  const onScopesChange = (value: string) => setScopes(value);

  const onActionChange = () => {
    if (!visible) return;

    setMaxTurnCount(action.maxTurnCount);
    setPrompt(action.prompt);
    setText(action.text);
    setTitle(action.title);
    setAuthorizeEndpoint(protocol.authorizeEndpoint);
    setTokenEndpoint(protocol.tokenEndpoint);
    setJwksEndpoint(protocol.jwksEndpoint);
    setClientId(protocol.clientId);
    setClientSecret(protocol.clientSecret);
    setScopes(protocol.scopes);
  };
  useEffect(onActionChange, [action, visible]);

  return (
    <SbModal
      className="external-sign-in-settings-modal"
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveSettings}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onModalClose}>
          Закрыть
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройки поставщика"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalClose}
      onOk={onSaveSettings}
    >
      <SbScroll>
        <SbTypography>
          <h4>Максимальное количество попыток ввода</h4>
          <SbInput
            allowClear
            placeholder="Введите максимальное количество попыток ввода"
            value={maxTurnCount?.toString() ?? ''}
            onChange={onMaxTurnCountChange}
          />
          <h4>Подсказка о незавершенном входе</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={
              prompt
                ? ''
                : 'Укажите подсказку о незавершенном входе, например, "Вы еще не завершили вход полностью. Выполните все необходимые действия в окне браузера."'
            }
            visible={tooltipField == TooltipFields.PROMPT && !prompt}
          >
            <SbInput
              allowClear
              isValid={!!prompt}
              placeholder="Введите подсказку о незавершенном входе"
              value={prompt}
              onBlur={hideTooltip}
              onChange={onPromptChange}
              onFocus={showTooltip(TooltipFields.PROMPT)}
            />
          </SbTooltip>
          <h4>Заголовок карточки</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={text ? '' : 'Укажите заголовок карточки, например, "Вход через OAuth"'}
            visible={tooltipField == TooltipFields.TEXT && !text}
          >
            <SbInput
              allowClear
              isValid={!!text}
              placeholder="Введите заголовок карточки"
              value={text}
              onBlur={hideTooltip}
              onChange={onTextChange}
              onFocus={showTooltip(TooltipFields.TEXT)}
            />
          </SbTooltip>
          <h4>Текст кнопки</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={title ? '' : 'Укажите текст кнопки, например, "Войти"'}
            visible={tooltipField == TooltipFields.TITLE && !title}
          >
            <SbInput
              allowClear
              isValid={!!title}
              placeholder="Введите текст кнопки"
              value={title}
              onBlur={hideTooltip}
              onChange={onTitleChange}
              onFocus={showTooltip(TooltipFields.TITLE)}
            />
          </SbTooltip>
          <h4>Конечная точка авторизации</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={
              authorizeEndpoint
                ? ''
                : `Укажите конечную точка авторизации, например, "https://${domainZone}/ext-api/oidc/oauth2/auth"`
            }
            visible={tooltipField == TooltipFields.AUTHORIZE_ENDPOINT && !authorizeEndpoint}
          >
            <SbInput
              allowClear
              isValid={!!authorizeEndpoint}
              placeholder="Введите конечную точку авторизации"
              value={authorizeEndpoint}
              onBlur={hideTooltip}
              onChange={onAuthorizeEndpointChange}
              onFocus={showTooltip(TooltipFields.AUTHORIZE_ENDPOINT)}
            />
          </SbTooltip>
          <h4>Конечная точка токенов</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={
              tokenEndpoint
                ? ''
                : `Укажите конечную точка токенов, например, "https://${domainZone}/ext-api/oidc/oauth2/token"`
            }
            visible={tooltipField == TooltipFields.TOKEN_ENDPOINT && !tokenEndpoint}
          >
            <SbInput
              allowClear
              isValid={!!tokenEndpoint}
              placeholder="Введите конечную точку токенов"
              value={tokenEndpoint}
              onBlur={hideTooltip}
              onChange={onTokenEndpointChange}
              onFocus={showTooltip(TooltipFields.TOKEN_ENDPOINT)}
            />
          </SbTooltip>
          <h4>Конечная точка набора ключей JWKS</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={
              jwksEndpoint
                ? ''
                : `Укажите конечную точка набора ключей JWKS, например, "https://${domainZone}/ext-api/oidc/.well-known/jwks.json"`
            }
            visible={tooltipField == TooltipFields.JWKS_ENDPOINT && !jwksEndpoint}
          >
            <SbInput
              allowClear
              isValid={!!jwksEndpoint}
              placeholder="Введите конечную точку набора ключей JWKS"
              value={jwksEndpoint}
              onBlur={hideTooltip}
              onChange={onJwksEndpointChange}
              onFocus={showTooltip(TooltipFields.JWKS_ENDPOINT)}
            />
          </SbTooltip>
          <h4>Идентификатор клиента</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={clientId ? '' : 'Укажите идентификатор клиента'}
            visible={tooltipField == TooltipFields.CLIENT_ID && !clientId}
          >
            <SbInput
              allowClear
              isValid={!!clientId}
              placeholder="Введите идентификатор клиента"
              value={clientId}
              onBlur={hideTooltip}
              onChange={onClientIdChange}
              onFocus={showTooltip(TooltipFields.CLIENT_ID)}
            />
          </SbTooltip>
          <h4>Секретный код (пароль) клиента</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={clientSecret ? '' : 'Укажите секретный код (пароль) клиента'}
            visible={tooltipField == TooltipFields.CLIENT_SECRET && !clientSecret}
          >
            <SbInput.Password
              allowClear
              isValid={!!clientSecret}
              placeholder="Введите секретный код (пароль) клиента"
              value={clientSecret}
              onBlur={hideTooltip}
              onChange={onClientSecretChange}
              onFocus={showTooltip(TooltipFields.CLIENT_SECRET)}
            />
          </SbTooltip>
          <h4>Набор запрашиваемых областей</h4>
          <SbTooltip
            overlayClassName="external-sign-in-settings-tooltip"
            placement="right"
            title={scopes ? '' : 'Укажите набор запрашиваемых областей, например, "openid email phone"'}
            visible={tooltipField == TooltipFields.SCOPES && !scopes}
          >
            <SbInput
              allowClear
              isValid={!!scopes}
              placeholder="Введите набор запрашиваемых областей"
              value={scopes}
              onBlur={hideTooltip}
              onChange={onScopesChange}
              onFocus={showTooltip(TooltipFields.SCOPES)}
            />
          </SbTooltip>
        </SbTypography>
      </SbScroll>
    </SbModal>
  );
};

export default ExternalSignInSettingsModal;
