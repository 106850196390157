import { CSSProperties } from 'react';

// NOTE: ключевые точки соответствуют переменным @ib-breakpoint-... в variables.less
export const LAYOUT_WIDTH_BREAKPOINTS = {
  xSmall: 359,
  small: 1023,
  medium: 1279,
};

export const OPERATOR_GROUP_TAG_STYLE = {
  background: '#E3F5E6',
  color: '#48A15C',
} as CSSProperties;
