import React from 'react';
import { useTranslation } from 'react-i18next';
import Editor from '@monaco-editor/react';

import './index.less';

import IbModal, { IbModalPosition } from '../common/IbModal';
import { InboxChannelModel } from '../../../../api';
import IbButton from '../common/IbButton';
import { Channels } from '../../../constants';

const MAIN_CLASS_NAME = 'ib-channel-diagnostics-modal';
const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;

const MONACO_EDITOR_OPTIONS = {
  readOnly: true,
  minimap: { enabled: false },
  scrollBeyondLastLine: false,
  wordWrap: 'on',
};

interface IIbChannelDiagnosticsModalProps {
  visible: boolean;
  loading: boolean;
  channel?: InboxChannelModel;
  webhookInfo: string;
  onCancel?: () => void;
}

const IbChannelDiagnosticsModal: React.FC<IIbChannelDiagnosticsModalProps> = ({
  visible,
  loading,
  channel,
  webhookInfo,
  onCancel,
}) => {
  const { t } = useTranslation();

  const renderModalContent = () => {
    return (
      channel?.channelId === Channels.TELEGRAM &&
      webhookInfo && (
        <div className={FORM_ITEM_CLASS_NAME}>
          <Editor height="100%" language="json" options={MONACO_EDITOR_OPTIONS} value={webhookInfo} />
        </div>
      )
    );
  };

  const renderFooter = () => {
    return (
      <>
        <IbButton type="secondary" onClick={onCancel}>
          {t('Close')}
        </IbButton>
        <IbButton type="fill" onClick={onCancel}>
          {t('Close')}
        </IbButton>
      </>
    );
  };

  return (
    <IbModal
      className={MAIN_CLASS_NAME}
      footer={renderFooter()}
      loading={loading}
      position={IbModalPosition.FixedTall}
      title={t('Channel diagnostics')}
      visible={visible}
      onCancel={onCancel}
    >
      {renderModalContent()}
    </IbModal>
  );
};

export default IbChannelDiagnosticsModal;
