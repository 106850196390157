import React, { memo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Menu } from 'antd';

import Messages from '../../Messages';
import {
  ButtonSchema,
  ButtonType,
  ChoiceTransitSchema,
  DefaultActionGroupSchema,
  SchemaKind,
  SuggestionInputSchema,
} from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import ActionButton from '../../ActionButton';
import { currentScenarioStructureSelector, selectedEntitySelector } from '../../../../../recoil/scenarioStructure';
import { deleteBindingWithReferences, generateId, tryGetElementById } from '../../../utils';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import AddButton from '../../common/AddButton';
import EditorSelect from '../../common/EditorSelect';
import InputVariable from '../../InputVariable';

interface ChoiceTransitProps {
  index: number;
  action: ChoiceTransitSchema;
  group: DefaultActionGroupSchema;
}

const ChoiceTransit: React.FC<ChoiceTransitProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const setSelectedEntity = useSetRecoilState(selectedEntitySelector);

  const onDeleteActionButton = (button: ButtonSchema) => () => {
    if (!scenarioStructure) return;

    const newButtons = action.buttons.filter((b) => b.id !== button.id);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as ChoiceTransitSchema).buttons = newButtons;

    deleteBindingWithReferences(newScenarioStructure, button.outputBindingId);

    setScenarioStructure(newScenarioStructure);
  };

  const addButtonHandler = () => {
    if (!scenarioStructure) return;

    const newButtons = [...action.buttons];
    const newButton: ButtonSchema = {
      id: generateId('BTN'),
      $kind: SchemaKind.Button,
      value: 'Новый вариант',
      type: ButtonType.Transit,
      synonyms: [],
      outputBindingId: null,
    };
    newButtons.push(newButton);

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    (foundAction as ChoiceTransitSchema).buttons = newButtons;

    setSelectedEntity(newButton);
    setScenarioStructure(newScenarioStructure);
  };

  const onSuggestionInputSelect = () => {
    if (!scenarioStructure) return;
    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id);

    const choiceTransitAction = foundAction as ChoiceTransitSchema;
    const suggestionInputAction = foundAction as SuggestionInputSchema;

    choiceTransitAction.buttons.forEach((b) => deleteBindingWithReferences(newScenarioStructure, b.outputBindingId));

    suggestionInputAction.$kind = SchemaKind.SuggestionInput;
    suggestionInputAction.suggestions = choiceTransitAction.buttons.map((b) => ({
      id: b.id,
      $kind: SchemaKind.Suggestion,
      $designer: b.$designer,
      caption: b.value,
    }));
    // todo: надо ли сбрасывать валидаторы?
    suggestionInputAction.validators = [];
    choiceTransitAction.buttons = [];

    setScenarioStructure(newScenarioStructure);
  };

  const typeSelectorTitle = 'Ответ-развилка (Кнопки)';
  const typeSelectorMenuContent = (
    <Menu defaultSelectedKeys={[SchemaKind.ChoiceTransit]}>
      <Menu.Item key={SchemaKind.ChoiceTransit}>{typeSelectorTitle}</Menu.Item>
      <Menu.Item key={SchemaKind.SuggestionInput} onClick={onSuggestionInputSelect}>
        Быстрый ответ
      </Menu.Item>
    </Menu>
  );

  return (
    <ActionWrapper
      showInputActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="help" size={20} />}
      index={index}
      title="Вопрос. Текст + кнопки"
    >
      <Messages action={action} group={group} placeholder="Добавьте текст вопроса" />
      <div className="user-answer">
        <div className="user-answer__title">
          <SbIcon iconName="user" size={16} />
          <span>Ответ пользователя:</span>
        </div>
        <EditorSelect
          isValid
          id={`${action.id}_type}`}
          menuContent={typeSelectorMenuContent}
          title={typeSelectorTitle}
        />
        {action.buttons.map((button) => (
          <ActionButton
            key={button.id}
            button={button}
            group={group}
            parentKind={SchemaKind.ChoiceTransit}
            onDelete={onDeleteActionButton(button)}
          />
        ))}
        <AddButton buttonHandler={addButtonHandler} title="Добавить кнопку" />
      </div>
      <InputVariable action={action} />
    </ActionWrapper>
  );
};

export default memo(ChoiceTransit);
