import React, { ReactNode, useEffect, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import './index.less';

import IbIcon from '../common/IbIcon';
import IbButton from '../common/IbButton';
import IbTypography from '../common/IbTypography';
import IbTagsSearch, { IIbTagsSearchData } from '../IbTagsSearch';
import IbSortButton, { ISortMode } from '../common/IbSortButton';
import { TagModel } from '../../../../api';

const MAIN_CLASS_NAME = 'ib-page-title';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const CONTENT_DESKTOP_CLASS_NAME = `${CONTENT_CLASS_NAME}_desktop`;
const CONTENT_MOBILE_CLASS_NAME = `${CONTENT_CLASS_NAME}_mobile`;

const SEARCH_DELAY = 200; // ms

export interface IIbPageTitleProps {
  title: string;
  showSearchInput?: boolean;
  hideTagsFiltering?: boolean;
  accountWidget?: ReactNode;
  desktopContent?: ReactNode;
  mobileContent?: ReactNode;
  tagList?: TagModel[];
  sortModes?: ISortMode[];
  selectedSortMode?: string;
  onSortModeChanged?: (value: string) => void;
  onDebouncedSearch?: (data: IIbTagsSearchData) => void;
  onAdd: () => void;
}

const IbPageTitle: React.FC<IIbPageTitleProps> = ({
  title,
  showSearchInput,
  hideTagsFiltering,
  accountWidget,
  desktopContent,
  mobileContent,
  tagList,
  sortModes,
  selectedSortMode,
  onSortModeChanged,
  onDebouncedSearch,
  onAdd,
}) => {
  const [searchData, setSearchData] = useState({} as IIbTagsSearchData);

  const debouncedSearchData = useDebounce(searchData, SEARCH_DELAY);

  const onDebouncedSearchDataChange = () => onDebouncedSearch?.(debouncedSearchData);
  useEffect(onDebouncedSearchDataChange, [debouncedSearchData]);

  return (
    <IbTypography className={MAIN_CLASS_NAME}>
      {accountWidget}
      <h2>{title}</h2>
      <h4>{title}</h4>
      <IbButton icon={<IbIcon iconName="add-one" />} type="link" onClick={onAdd} />
      {desktopContent && <div className={`${CONTENT_CLASS_NAME} ${CONTENT_DESKTOP_CLASS_NAME}`}>{desktopContent}</div>}
      {mobileContent && <div className={`${CONTENT_CLASS_NAME} ${CONTENT_MOBILE_CLASS_NAME}`}>{mobileContent}</div>}
      {!!showSearchInput && (
        <IbTagsSearch
          hideTagsFiltering={hideTagsFiltering}
          searchData={searchData}
          tagList={tagList}
          onChange={setSearchData}
        />
      )}
      {!!showSearchInput && !!sortModes?.length && (
        <IbSortButton options={sortModes} value={selectedSortMode} onChange={onSortModeChanged} />
      )}
    </IbTypography>
  );
};

export default IbPageTitle;
