import { ActionSchema, DefaultActionGroupSchema, SchemaKind } from '../../../../api';
import { IPosition } from '../types';

import { generateId } from './index';

export const generateNewGroup = (
  positionX: number,
  positionY: number,
  groupName = '',
  actions = [] as ActionSchema[]
): DefaultActionGroupSchema => {
  const newGroupId = generateId('ACG');

  return {
    id: newGroupId,
    name: groupName,
    actions,
    $designer: {
      id: newGroupId,
      positionX,
      positionY,
      name: groupName,
    },
    $kind: SchemaKind.DefaultActionGroup,
    inputBindingIds: [],
  } as DefaultActionGroupSchema;
};

export const positionsOverlap = (pos1: IPosition, pos2: IPosition): boolean => {
  if (!pos1.height || !pos1.width || !pos2.height || !pos2.width) return false;

  return !(
    pos1.positionY + pos1.height < pos2.positionY ||
    pos1.positionY > pos2.positionY + pos2.height ||
    pos1.positionX + pos1.width < pos2.positionX ||
    pos1.positionX > pos2.positionX + pos2.width
  );
};

export const positionOverlapWithSome = (position: IPosition, positions: IPosition[]): boolean => {
  for (const pos of positions) {
    if (positionsOverlap(position, pos)) return true;
  }

  return false;
};
