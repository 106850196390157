import React, { useEffect, useState } from 'react';

import './index.less';

import { MenuButtonTriggerSchema } from '../../../../../../../api';
import SbModal from '../../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../../simple-bot/components/common/SbButton';
import SbTypography from '../../../../../../simple-bot/components/common/SbTypography';
import SbInput from '../../../../../../simple-bot/components/common/SbInput';

const MODAL_WIDTH = 516;

interface SettingModalProps {
  visible: boolean;
  menuButtonTrigger: MenuButtonTriggerSchema;
  onChange: (schema: MenuButtonTriggerSchema) => void;
  onModalClose: () => void;
}

const SettingModal: React.FC<SettingModalProps> = ({ visible, menuButtonTrigger, onChange, onModalClose }) => {
  const [menuButtonPosition, setMenuButtonPosition] = useState(menuButtonTrigger.position);
  const [menuButtonCategory, setMenuButtonCategory] = useState(menuButtonTrigger.category);

  const resetState = () => {
    if (!visible) return;

    setMenuButtonPosition(menuButtonTrigger.position);
    setMenuButtonCategory(menuButtonTrigger.category);
  };
  useEffect(resetState, [visible, menuButtonTrigger]);

  const onSaveButtonClick = () =>
    onChange({
      ...menuButtonTrigger,
      position: menuButtonPosition,
      category: menuButtonCategory,
    });

  const onModalCancel = () => onModalClose();

  const onCancelButtonClick = () => onModalClose();

  const onCategoryChange = (value?: string) => setMenuButtonCategory(value || null);

  const onPositionChange = (value?: string) => {
    setMenuButtonPosition(value ? parseInt(value) || 0 : 0);
  };

  return (
    <SbModal
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройка пункта меню"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
    >
      <div className="button-settings">
        <SbTypography>
          <h3>Категория</h3>
          <SbInput allowClear value={menuButtonCategory ?? ''} onChange={onCategoryChange} />
          <h3>Позиция</h3>
          <SbInput
            allowClear={false}
            value={menuButtonPosition.toString()}
            onChange={(value) => onPositionChange(value)}
          />
        </SbTypography>
      </div>
    </SbModal>
  );
};

export default SettingModal;
