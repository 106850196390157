import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

const StartTrigger: React.FC = () => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="play" size={16} />
      Старт бота
    </div>
  );
};

export default StartTrigger;
