import { Card, Col, Divider, Menu, Row, Space } from 'antd';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import downloadFile from 'js-file-download';

import {
  DataEntryDiagnosticsModel,
  DataEntryModel,
  ListDataEntryModel,
  QnAData,
  SingleKnowledgeBaseModel,
  StageModel,
} from '../../../../../kb-api';
import SbTag from '../../../components/common/SbTag';
import SbContextMenu from '../../../components/common/SbContextMenu';
import SbButton from '../../../components/common/SbButton';
import { knowledgeSourceKbApi, dataEntryKbApi, versionKbApi } from '../../../../apis';
import { CHILD_DATA_ENTRIES_TAG_COLOR, QUESTIONS_TAG_COLOR_DEFAULT, QUESTIONS_TAG_COLOR_WARNING } from '../../../const';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { AlertTypes, ReportRowRuleNames } from '../../../../constants';
import SbMarkdownEditor from '../../../components/common/SbMarkdownEditor';
import SbSwitch from '../../../components/common/SbSwitch';
import { getKnowledgeSources } from '../../../utils/knowledgeBase';
import SbIcon from '../../../components/common/SbIcon';
import SbTooltip from '../../../components/common/SbTooltip';

import InstanceStatusProgress from './InstanceStatusProgress';

interface IDataEntryItemProps {
  listDataEntry: ListDataEntryModel;
  knowledgeBase?: SingleKnowledgeBaseModel;
  dataUpdating: boolean;
  onEdit?: (dataEntry: DataEntryModel) => void;
  onDelete?: (dataEntry: DataEntryModel) => void;
  getDraftStage: () => Promise<StageModel | undefined>;
  getDataEntryIdByVersion: (versionId: string, dataEntryId: string) => Promise<string | undefined>;
  onDataChanged: () => void;
}

const DataEntryItem: React.FC<IDataEntryItemProps> = ({
  listDataEntry,
  knowledgeBase,
  dataUpdating,
  onEdit = () => {},
  onDelete = () => {},
  getDraftStage,
  getDataEntryIdByVersion,
  onDataChanged,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [enabled, setEnabled] = useState(listDataEntry.entry.enabled);
  const [childrenDataEntries, setChildrenDataEntries] = useState([] as DataEntryModel[]);

  const toggling = saving || publishing;
  const draftInstance = knowledgeBase?.draftInstance;
  const knowledgeSources = getKnowledgeSources(knowledgeBase);
  const qnAData = listDataEntry.entry.data as QnAData;

  const onEditSelect = () => onEdit(listDataEntry.entry);
  const onDeleteSelect = () => onDelete(listDataEntry.entry);

  const loadChildEntriesDataAsync = async () => {
    if (!listDataEntry.entry.childEntryIds?.length) {
      setChildrenDataEntries([]);
      return;
    }

    try {
      const response = await dataEntryKbApi.getChildren(listDataEntry.entry.id);
      setChildrenDataEntries(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка записей редакции',
        error: e,
      });
    }
  };
  const loadChildEntriesData = () => {
    loadChildEntriesDataAsync().finally();
  };
  useEffect(loadChildEntriesData, [listDataEntry.entry.childEntryIds]);

  const onDataEntryEnabledChange = () => {
    if (toggling) return;
    setEnabled(listDataEntry.entry.enabled);
  };
  useEffect(onDataEntryEnabledChange, [listDataEntry.entry.enabled]);

  const knowledgeSource = knowledgeSources.find((source) => source.id === listDataEntry.entry.knowledgeSourceId);

  const onSourceClick: MouseEventHandler<HTMLElement> = async (e) => {
    e.stopPropagation();

    if (knowledgeSource?.binarySource && knowledgeSource.name) {
      const response = await knowledgeSourceKbApi.getKnowledgeSourceFile(knowledgeSource.id, { responseType: 'blob' });
      downloadFile(response.data, knowledgeSource.name);
    }

    if (knowledgeSource?.url) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(knowledgeSource.url);
    }
  };

  const onChildEntryClick: (childEntry: DataEntryModel) => MouseEventHandler<HTMLDivElement> = (childEntry) => (e) => {
    e.stopPropagation();
    onEdit(childEntry);
  };

  const onQuestionsContainerClick = () => onEdit(listDataEntry.entry);

  const onToggleEnabledField = async () => {
    if (toggling || dataUpdating) return;

    setSaving(true);

    const draftStage = await getDraftStage();
    if (!draftStage) {
      setSaving(false);
      return;
    }

    try {
      const dataEntryId = await getDataEntryIdByVersion(draftStage.versionId, listDataEntry.entry.id);

      if (!dataEntryId) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при обновлении записи в базе знаний',
        });
        return;
      }

      const newEnabled = !enabled;
      await versionKbApi.updateDataEntry(draftStage.versionId, dataEntryId, {
        type: listDataEntry.entry.type,
        data: listDataEntry.entry.data,
        childEntryIds: listDataEntry.entry.childEntryIds,
        enabled: newEnabled,
        isArchived: false,
      });
      setEnabled(newEnabled);
      setPublishing(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при обновлении записи в базе знаний',
        error: e,
      });
    }

    setSaving(false);
  };

  const onPublishComplete = () => {
    setPublishing(false);
    onDataChanged();
  };

  const contextMenuContent = (
    <Menu>
      <Menu.Item key="edit" onClick={onEditSelect}>
        Редактировать
      </Menu.Item>
      <Menu.Item key="delete" onClick={onDeleteSelect}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  const renderQuestionTooltipContent = (questionIndex: number, diagnostics: DataEntryDiagnosticsModel) => {
    let tooltipContent = null;
    const { relatedDataEntry, actualReportRow } = diagnostics;
    const relatedQna = relatedDataEntry.data as QnAData;
    const relatedQuestionFromSource = relatedQna.questions?.[actualReportRow.sourceQuestionId ?? -1];
    const relatedQuestionFromTarget = relatedQna.questions?.[actualReportRow.targetQuestionId ?? -1];
    const relatedQuestion =
      listDataEntry.entry.id === relatedDataEntry.id
        ? actualReportRow.sourceQuestionId === questionIndex
          ? relatedQuestionFromTarget
          : relatedQuestionFromSource
        : relatedDataEntry.id === actualReportRow.sourceAnswerId
        ? relatedQuestionFromSource
        : relatedQuestionFromTarget;

    const onQuestionClick: MouseEventHandler<HTMLElement> = (e) => {
      e.stopPropagation();
      onEdit(relatedDataEntry);
    };

    if (diagnostics.actualReportRow.rule === ReportRowRuleNames.SAME_ANSWER_FULL_MATCH) {
      tooltipContent = (
        <>
          Совпадает с фразой{' '}
          <SbButton sbType="link" size="small">
            {relatedQuestion}
          </SbButton>
        </>
      );
    } else if (diagnostics.actualReportRow.rule === ReportRowRuleNames.SAME_ANSWER_CLOSE_MATCH) {
      tooltipContent = (
        <>
          Похожа на фразу{' '}
          <SbButton sbType="link" size="small">
            {relatedQuestion}
          </SbButton>
        </>
      );
    } else if (diagnostics.actualReportRow.rule === ReportRowRuleNames.OTHER_ANSWER_FULL_MATCH) {
      tooltipContent = (
        <>
          Совпадает с фразой{' '}
          <SbButton sbType="link" size="small" onClick={onQuestionClick}>
            {relatedQuestion}
          </SbButton>{' '}
          из другой записи
        </>
      );
    } else if (diagnostics.actualReportRow.rule === ReportRowRuleNames.OTHER_ANSWER_CLOSE_MATCH) {
      tooltipContent = (
        <>
          Похожа на фразу{' '}
          <SbButton sbType="link" size="small" onClick={onQuestionClick}>
            {relatedQuestion}
          </SbButton>{' '}
          из другой записи
        </>
      );
    }

    return tooltipContent;
  };

  const renderQuestions = () => (
    <Space
      wrap
      className="sb-knowledge-base-card__content__row__entries__list-row__list__item__title__questions-container"
      onClick={onQuestionsContainerClick}
    >
      {qnAData.questions?.map((question, questionIndex) => {
        const diagnostics = listDataEntry.diagnostics.find((e) => e.questionIndex === questionIndex);
        if (!diagnostics) {
          return <SbTag key={questionIndex} color={QUESTIONS_TAG_COLOR_DEFAULT} sbSize="small-uncut" text={question} />;
        }

        const tooltipContent = renderQuestionTooltipContent(questionIndex, diagnostics);
        const tagContent = (
          <span className="sb-knowledge-base-card__content__row__entries__list-row__list__item__title__question-warning">
            {question}
            <SbTooltip
              overlayClassName="sb-question-warning-tooltip"
              placement="right"
              title={tooltipContent ? <div onClick={(e) => e.stopPropagation()}>{tooltipContent}</div> : null}
            >
              <SbIcon iconName="attention" size={16} />
            </SbTooltip>
          </span>
        );

        return <SbTag key={questionIndex} color={QUESTIONS_TAG_COLOR_WARNING} sbSize="small-uncut" text={tagContent} />;
      })}
    </Space>
  );

  return (
    <Card hoverable className="sb-knowledge-base-card__content__row__entries__list-row__list__item">
      <Row
        className="sb-knowledge-base-card__content__row__entries__list-row__list__item__title"
        justify="space-between"
      >
        <Col span="auto">{renderQuestions()}</Col>
        <Col
          className="sb-knowledge-base-card__content__row__entries__list-row__list__item__title__controls"
          onClick={(e) => e.stopPropagation()}
        >
          <Row wrap={false}>
            <SbSwitch checked={enabled} loading={toggling} onClick={onToggleEnabledField} />
            <SbContextMenu menuContent={contextMenuContent} />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="sb-knowledge-base-card__content__row__entries__list-row__list__item__answer">
            <SbMarkdownEditor readOnly value={qnAData.answer || ''} />
          </div>
        </Col>
      </Row>
      <Divider />
      <Row align="stretch" justify="center" wrap={false}>
        <Col flex="auto">
          {!!childrenDataEntries.length && (
            <Space wrap align="center">
              <span>
                <strong>Уточняющие вопросы:</strong>
              </span>
              {childrenDataEntries.map((childEntry) => {
                const questions = (childEntry?.data as QnAData)?.questions;
                const question = questions?.length ? questions[0] : '';
                return (
                  <div key={childEntry.id} onClick={onChildEntryClick(childEntry)}>
                    <SbTag hoverable color={CHILD_DATA_ENTRIES_TAG_COLOR} sbSize="small" text={question} />
                  </div>
                );
              })}
            </Space>
          )}
        </Col>
        {knowledgeSource?.name && (
          <Col>
            <div className="sb-knowledge-base-card__content__row__entries__list-row__list__item__source">
              <strong>Источник:</strong>
              <SbButton sbType="link" onClick={onSourceClick}>
                {knowledgeSource.name}
              </SbButton>
            </div>
          </Col>
        )}
      </Row>
      {publishing && draftInstance?.id && (
        <InstanceStatusProgress instanceId={draftInstance.id} visible={false} onPublishComplete={onPublishComplete} />
      )}
    </Card>
  );
};

export default DataEntryItem;
