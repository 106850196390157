import React from 'react';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DatePicker } from 'antd';

import './index.less';

import SbIcon from '../../SbIcon';

const { RangePicker } = DatePicker;

export type ISbRangePickerProps = RangePickerProps;

const SbRangePicker: React.FC<ISbRangePickerProps> = (props) => (
  <RangePicker
    allowClear={false}
    className="sb-range-picker sb-date-picker"
    dropdownClassName="sb-range-picker__dropdown sb-date-picker__dropdown"
    suffixIcon={<SbIcon iconName="calendar-three" size="20" />}
    {...props}
  />
);

export default SbRangePicker;
