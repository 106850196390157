import React from 'react';
import { Radio } from 'antd';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';

import './index.less';

type SbRadioButtonProps = RadioButtonProps;

const SbRadioButton: React.FC<SbRadioButtonProps> = ({ children, ...otherProps }) => (
  <Radio.Button checked={false} className="sb-radio-button" {...otherProps}>
    {children}
  </Radio.Button>
);

export default SbRadioButton;
