import React, { useEffect, useState } from 'react';
import { Button, PageHeader, Table, TablePaginationConfig } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { PersonModel } from '../../../api';
import { personApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import SendInviteButton from '../../components/SendInviteButton';
import { alertsSelectorAdd } from '../../recoil/alerts';

const PersonList: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listPersons, setListPersons] = useState([] as PersonModel[]);
  const history = useHistory();

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await personApi.searchPersons(
        undefined,
        undefined,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setListPersons(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка работников',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination.current, pagination.pageSize]);

  const onAddBtnClick = () => {
    history.push('/persons/add');
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fio',
      render: (_text: unknown, person: PersonModel) => (
        <Link
          to={{
            pathname: `/persons/${person.id}`,
          }}
        >
          {person.fullName}
        </Link>
      ),
    },
    {
      title: 'Телефон',
      dataIndex: 'primaryTel',
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'E-mail',
      dataIndex: 'eMail',
      ellipsis: true,
    },
    {
      title: 'Действия',
      dataIndex: 'actions',
      render: (_text: unknown, person: PersonModel) => <SendInviteButton person={person} showButtonWithText={false} />,
      ellipsis: true,
      width: '15%',
    },
  ];

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="1" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить
          </Button>,
        ]}
        title="Работники"
      />
      <Table
        columns={columns}
        dataSource={listPersons.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default PersonList;
