import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbModal from '../common/IbModal';
import IbTypography from '../common/IbTypography';
import IbIcon from '../common/IbIcon';
import { TARIFF_URL } from '../../../constants';

import { CONTENT_CLASS_NAME, CONTENT_HEADER_CLASS_NAME, CONTENT_ICON_CLASS_NAME, MAIN_CLASS_NAME } from './const';

export interface IIbTariffLimitModalProps {
  visible?: boolean;
  onCancel?: () => void;
}

const IbTariffLimitModal: React.FC<IIbTariffLimitModalProps> = ({ visible, onCancel }) => {
  const { t } = useTranslation();

  return (
    <IbModal className={MAIN_CLASS_NAME} visible={visible} onCancel={onCancel}>
      <div className={CONTENT_CLASS_NAME}>
        <IbIcon className={CONTENT_ICON_CLASS_NAME} iconName="attention" size={64} />
        <IbTypography>
          <IbTypography.Paragraph className={CONTENT_HEADER_CLASS_NAME}>
            <h2>{t('Tariff limit exceeded 1')}</h2>
          </IbTypography.Paragraph>
          <IbTypography.Paragraph>{t('Tariff limit exceeded 2')}</IbTypography.Paragraph>
          <IbTypography.Paragraph>
            <a href={TARIFF_URL}>{t('Tariff limit exceeded 3')}</a>
          </IbTypography.Paragraph>
        </IbTypography>
      </div>
    </IbModal>
  );
};

export default IbTariffLimitModal;
