import {
  AgentApi,
  AgentStageApi,
  AgentStageAccountApi,
  CampaignApi,
  CampaignJobApi,
  CommandApi,
  PersonApi,
  GroupApi,
  ConversationApi,
  ActivityApi,
  ApplicationApi,
  BotVersionApi,
  TestInstanceApi,
  BotTemplatesApi,
  DirectLineTranscriptApi,
  IntentEntryApi,
  GroupEntryApi,
  BotApi,
  BotStageApi,
  BotEditionApi,
  BotTemplateApi,
  ScenarioTemplateApi,
  Elma365MetadataApi,
  Elma365BpmTemplateApi,
  Elma365AppApi,
  FileStorageApi,
  IntentEditionApi,
  AnalyticsReportsApi,
  FeatureManagementApi,
  ConversationExportApi,
  DirectLineTokenApi,
  InboxChatApi,
  InboxParticipantApi,
  InboxMessageApi,
  InboxAttachmentApi,
  InboxChannelApi,
  TagApi,
  InboxOperatorGroupApi,
  InboxContactApi,
} from '../api';
import {
  DataEntryKbApi,
  DraftKbApi,
  InstanceKbApi,
  KnowledgeBaseKbApi,
  KnowledgeSourceKbApi,
  ReportKbApi,
  ReportRowKbApi,
  StageKbApi,
  TemplateKbApi,
  VersionKbApi,
} from '../kb-api';
import { UserAuthApi, TenantAuthApi } from '../auth-api';

import { BASE_PATH, KNOWLEDGE_BASE_URL, AUTH_SERVER_URL } from './utils/configUtil';

export const personApi = new PersonApi(undefined, BASE_PATH);
export const groupApi = new GroupApi(undefined, BASE_PATH);
export const campaignApi = new CampaignApi(undefined, BASE_PATH);
export const campaignJobApi = new CampaignJobApi(undefined, BASE_PATH);
export const agentApi = new AgentApi(undefined, BASE_PATH);
export const agentStageApi = new AgentStageApi(undefined, BASE_PATH);
export const agentStageAccountApi = new AgentStageAccountApi(undefined, BASE_PATH);
export const commandApi = new CommandApi(undefined, BASE_PATH);
export const conversationApi = new ConversationApi(undefined, BASE_PATH);
export const activityApi = new ActivityApi(undefined, BASE_PATH);
export const applicationApi = new ApplicationApi(undefined, BASE_PATH);
export const botVersionApi = new BotVersionApi(undefined, BASE_PATH);
export const testInstanceApi = new TestInstanceApi(undefined, BASE_PATH);
export const botTemplatesApi = new BotTemplatesApi(undefined, BASE_PATH);
export const directLineTranscriptApi = new DirectLineTranscriptApi(undefined, BASE_PATH);
export const intentEntryApi = new IntentEntryApi(undefined, BASE_PATH);
export const groupEntryApi = new GroupEntryApi(undefined, BASE_PATH);
export const botApi = new BotApi(undefined, BASE_PATH);
export const botStageApi = new BotStageApi(undefined, BASE_PATH);
export const botEditionApi = new BotEditionApi(undefined, BASE_PATH);
export const botTemplateApi = new BotTemplateApi(undefined, BASE_PATH);
export const scenarioTemplateApi = new ScenarioTemplateApi(undefined, BASE_PATH);
export const elma365MetadataApi = new Elma365MetadataApi(undefined, BASE_PATH);
export const elma365BpmTemplateApi = new Elma365BpmTemplateApi(undefined, BASE_PATH);
export const elma365AppApi = new Elma365AppApi(undefined, BASE_PATH);
export const fileStorageApi = new FileStorageApi(undefined, BASE_PATH);
export const intentEditionApi = new IntentEditionApi(undefined, BASE_PATH);
export const analyticsReportsApi = new AnalyticsReportsApi(undefined, BASE_PATH);
export const featureManagementApi = new FeatureManagementApi(undefined, BASE_PATH);
export const conversationExportApi = new ConversationExportApi(undefined, BASE_PATH);
export const directLineTokenApi = new DirectLineTokenApi(undefined, BASE_PATH);
export const inboxChatApi = new InboxChatApi(undefined, BASE_PATH);
export const inboxParticipantApi = new InboxParticipantApi(undefined, BASE_PATH);
export const inboxMessageApi = new InboxMessageApi(undefined, BASE_PATH);
export const inboxAttachmentApi = new InboxAttachmentApi(undefined, BASE_PATH);
export const inboxChannelApi = new InboxChannelApi(undefined, BASE_PATH);
export const tagApi = new TagApi(undefined, BASE_PATH);
export const inboxOperatorGroupApi = new InboxOperatorGroupApi(undefined, BASE_PATH);
export const inboxContactApi = new InboxContactApi(undefined, BASE_PATH);

export const knowledgeBaseKbApi = new KnowledgeBaseKbApi(undefined, KNOWLEDGE_BASE_URL);
export const stageKbApi = new StageKbApi(undefined, KNOWLEDGE_BASE_URL);
export const versionKbApi = new VersionKbApi(undefined, KNOWLEDGE_BASE_URL);
export const dataEntryKbApi = new DataEntryKbApi(undefined, KNOWLEDGE_BASE_URL);
export const instanceKbApi = new InstanceKbApi(undefined, KNOWLEDGE_BASE_URL);
export const knowledgeSourceKbApi = new KnowledgeSourceKbApi(undefined, KNOWLEDGE_BASE_URL);
export const draftKbApi = new DraftKbApi(undefined, KNOWLEDGE_BASE_URL);
export const reportKbApi = new ReportKbApi(undefined, KNOWLEDGE_BASE_URL);
export const reportRowKbApi = new ReportRowKbApi(undefined, KNOWLEDGE_BASE_URL);
export const templateKbApi = new TemplateKbApi(undefined, KNOWLEDGE_BASE_URL);

export const userAuthApi = new UserAuthApi(undefined, AUTH_SERVER_URL);
export const tenantAuthApi = new TenantAuthApi(undefined, AUTH_SERVER_URL);
