import { PersonModel } from '../../../../api';

export const SEARCH_DELAY = 200; // ms
export const ANIMATION_DELAY = 200; // ms
export const PERSON_LIST_COUNT = 4;

export const PERSON_LIST_DEFAULT = [] as PersonModel[];

export const MAIN_CLASS_NAME = 'ib-contact-edit-modal';
export const TITLE_CLASS_NAME = `${MAIN_CLASS_NAME}__title`;
export const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;
export const DELETE_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__delete-button`;
export const PERSON_LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__person-list`;
export const PERSON_LIST_POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__person-list-popover`;
export const PERSON_LIST_SPIN_WRAPPER_CLASS_NAME = `${PERSON_LIST_CLASS_NAME}__spin-wrapper`;
export const PERSON_LIST_EMPTY_LIST_ENTRY_CLASS_NAME = `${PERSON_LIST_CLASS_NAME}__empty-list-entry`;
