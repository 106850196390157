import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import './index.less';

import IbCard from '../../../../../components/common/IbCard';
import IbButton from '../../../../../components/common/IbButton';
import { SingleBotModel } from '../../../../../../../api';
import IbTypography from '../../../../../components/common/IbTypography';
import IbBadge from '../../../../../components/common/IbBadge';
import IbIcon from '../../../../../components/common/IbIcon';
import IbDivider from '../../../../../components/common/IbDivider';
import { formatDateTimeAdaptive } from '../../../../../../utils/stringUtil';
import { sortArrayByField } from '../../../../../../utils/arrayUtil';

const MAX_SCENARIOS_VISIBLE = 12;

const MAIN_CLASS_NAME = 'ib-scenarios-card';
const GRID_CLASS_NAME = `${MAIN_CLASS_NAME}__grid`;
const SCENARIO_ITEM_CLASS_NAME = `${GRID_CLASS_NAME}__scenario-item`;
const SCENARIO_ITEM_STATUS_CLASS_NAME = `${SCENARIO_ITEM_CLASS_NAME}__status`;
const SCENARIO_ITEM_STATUS_ENABLED_CLASS_NAME = `${SCENARIO_ITEM_STATUS_CLASS_NAME}_enabled`;
const SHOW_MORE_SCENARIOS_CLASS_NAME = `${GRID_CLASS_NAME}__show-more`;
const FOOTER_CLASS_NAME = `${MAIN_CLASS_NAME}__footer`;
const EXTRA_CLASS_NAME = `${MAIN_CLASS_NAME}__extra`;
const EXTRA_MOBILE_CLASS_NAME = `${EXTRA_CLASS_NAME}_mobile`;

interface IScenariosCardProps {
  bot?: SingleBotModel;
}

const ScenariosCard: React.FC<IScenariosCardProps> = ({ bot }) => {
  const { t, i18n } = useTranslation();
  const { push } = useHistory();

  const botStage = bot?.originStage;
  const scenarios = botStage?.currentEdition.scenarios ?? [];

  const goToScenarios = () => {
    if (!bot) return;
    push(`/inbox/bots/${bot.entry.id}/scenarios`);
  };

  const renderExtra = () => {
    return (
      <>
        <IbButton className={EXTRA_CLASS_NAME} icon={<IbIcon iconName="edit" />} type="link" onClick={goToScenarios}>
          {t('Edit scenarios')}
        </IbButton>
        <IbButton
          className={EXTRA_MOBILE_CLASS_NAME}
          icon={<IbIcon iconName="edit" />}
          type="link"
          onClick={goToScenarios}
        />
      </>
    );
  };

  const renderContent = () => {
    const showMoreScenariosVisible = scenarios.length > MAX_SCENARIOS_VISIBLE;
    const maxScenariosVisibleCount = showMoreScenariosVisible ? MAX_SCENARIOS_VISIBLE - 1 : scenarios.length;

    const renderShowMoreScenarios = () => {
      return (
        <div className={[SCENARIO_ITEM_CLASS_NAME, SHOW_MORE_SCENARIOS_CLASS_NAME].join(' ')} onClick={goToScenarios}>
          <IbBadge value={`+${scenarios.length - maxScenariosVisibleCount}`} />
          <IbButton type="link">{t('Show all')}</IbButton>
        </div>
      );
    };

    return (
      <div className={GRID_CLASS_NAME}>
        {scenarios.slice(0, maxScenariosVisibleCount).map((scenario) => {
          const statusClasses = [SCENARIO_ITEM_STATUS_CLASS_NAME];
          scenario.enabled && statusClasses.push(SCENARIO_ITEM_STATUS_ENABLED_CLASS_NAME);
          return (
            <div key={scenario.scenarioEditionId} className={SCENARIO_ITEM_CLASS_NAME}>
              <div className={statusClasses.join(' ')} />
              <IbTypography.Paragraph>
                <div title={scenario.name}>{scenario.name}</div>
              </IbTypography.Paragraph>
            </div>
          );
        })}
        {showMoreScenariosVisible && renderShowMoreScenarios()}
      </div>
    );
  };

  const renderFooter = () => {
    const modifiedOn = sortArrayByField(scenarios, 'modifiedOn', 'desc')[0]?.modifiedOn;
    return (
      <div className={FOOTER_CLASS_NAME}>
        <IbTypography.Paragraph disabled type="secondary">
          {t('ScenarioWithCount', { count: scenarios.length })}
        </IbTypography.Paragraph>
        {!!scenarios.length && (
          <>
            <IbDivider orientation="center" type="vertical" />
            <IbTypography.Paragraph disabled type="secondary">
              {`${t('Last modified')} ${formatDateTimeAdaptive(modifiedOn, i18n.language, 'short', true)}`}
            </IbTypography.Paragraph>
          </>
        )}
      </div>
    );
  };

  return (
    <IbCard
      className={MAIN_CLASS_NAME}
      extra={renderExtra()}
      footer={renderFooter()}
      loading={!bot}
      title={t('Scenarios')}
      onClick={goToScenarios}
    >
      {renderContent()}
    </IbCard>
  );
};

export default ScenariosCard;
