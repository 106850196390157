import React from 'react';
import { Tabs } from 'antd';

import { KnowledgeSourceModel } from '../../kb-api';

import QnATable from './QnATable';

const { TabPane } = Tabs;

export interface IDataEntryListProps {
  editionId?: string;
  knowledgeSource?: KnowledgeSourceModel;
}

const DataEntryList: React.FC<IDataEntryListProps> = ({ editionId, knowledgeSource }) => {
  return (
    <Tabs defaultActiveKey="qna">
      <TabPane key="qna" tab="Q&A">
        <QnATable editionId={editionId} knowledgeSource={knowledgeSource} />
      </TabPane>
    </Tabs>
  );
};

export default DataEntryList;
