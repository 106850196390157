import React from 'react';
import { ResponsiveContainer, AreaChart, Area } from 'recharts';
import createTrend from 'trendline';

import './index.less';

import { ConversationsPerDayModel } from '../../../../../../api';

const CHART_SETTINGS = {
  lineColors: {
    total: '#1CBF4A',
  } as { [x: string]: string },
};

const DEFAULT_X_STEP = 1;
const X_KEY = 'x';
const TOTAL_KEY = 'total';

const ANALYTICS_BLOCK_CHART_GRADIENT_ID = 'analytics-block-chart-gradient-id';

const formatTrendSlope = (slope?: number) => {
  if (slope === undefined) return '-';

  const percentSlope = slope * 100;
  const fractionDigits = 1;
  const fixedPercentSlope = +percentSlope.toFixed(fractionDigits);
  const prefix = fixedPercentSlope > 0 ? '↑+' : fixedPercentSlope < 0 ? '↓' : '';
  const suffix = fixedPercentSlope === 0 ? '' : '%';

  if (fixedPercentSlope === Math.round(fixedPercentSlope)) return `${prefix}${Math.round(fixedPercentSlope)}${suffix}`;

  return `${prefix}${fixedPercentSlope}${suffix}`;
};

interface IAnalyticsBlockProps {
  conversationsPerDayList: ConversationsPerDayModel[];
}

const AnalyticsBlock: React.FC<IAnalyticsBlockProps> = ({ conversationsPerDayList }) => {
  const maxCount = Math.max(...conversationsPerDayList.map((e) => e.totalCount));
  const stepX =
    !conversationsPerDayList.length || maxCount === 0 ? DEFAULT_X_STEP : maxCount / conversationsPerDayList.length;
  const trendData = conversationsPerDayList.map((e, index) => ({
    [X_KEY]: index * stepX,
    [TOTAL_KEY]: e.totalCount,
  }));

  const trend = createTrend(trendData, X_KEY, TOTAL_KEY);
  const total = conversationsPerDayList.reduce((partialSum, a) => partialSum + a.totalCount, 0);

  return (
    <div className="sb-analytics-block">
      <div className="sb-analytics-block__header sb-bot-card__content__block__inner-wrapper__header">
        <h2>Аналитика</h2>
        <span
          className={`sb-analytics-block__header__trend ${
            trend.slope < 0 ? 'sb-analytics-block__header__trend_negative' : ''
          }`}
        >
          {formatTrendSlope(trend.slope)}
        </span>
      </div>
      <div className="sb-analytics-block__content">
        <div className="sb-analytics-block__content__count">
          <div>Бесед за неделю</div>
          <span>{total}</span>
        </div>
        <div className="sb-analytics-block__content__chart">
          <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={conversationsPerDayList}>
              <defs>
                <linearGradient gradientTransform="rotate(90)" id={ANALYTICS_BLOCK_CHART_GRADIENT_ID}>
                  <stop offset="0%" stopColor="#3EC463" />
                  <stop offset="100%" stopColor="#FFFFFF00" />
                </linearGradient>
              </defs>
              <Area
                dataKey={(e: ConversationsPerDayModel) => e.totalCount}
                fill={`url(#${ANALYTICS_BLOCK_CHART_GRADIENT_ID})`}
                isAnimationActive={false}
                stroke={CHART_SETTINGS.lineColors.total}
                strokeWidth={2}
                type="monotone"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsBlock;
