import React from 'react';

import SbIcon from '../../../../components/common/SbIcon';

interface IExternalEventTriggerProps {
  id: string;
}

const ExternalEventTrigger: React.FC<IExternalEventTriggerProps> = ({ id }) => {
  const mainClass = 'sb-dialogs-card__content__panel-container__messages__event';
  return (
    <div className={`${mainClass} ${mainClass}_end`}>
      <SbIcon iconName="share-three" size={16} />
      Внешнее событие «{id}»
    </div>
  );
};

export default ExternalEventTrigger;
