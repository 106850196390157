import React, { useState } from 'react';
import { Button, Row, Tooltip, List } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import ObjectID from 'bson-objectid';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountModel, PersonModel } from '../../../api';
import { commandApi } from '../../apis';
import { AlertTypes, Channels } from '../../constants';
import { ViberIcon, TelegramIcon, Elma365Icon } from '../../assets';
import { alertsSelectorAdd } from '../../recoil/alerts';

export interface IAgentAccountListItemProps {
  person: PersonModel;
  agentStageAccount: AgentStageAccountModel;
}

const AgentAccountListItem: React.FC<IAgentAccountListItemProps> = ({ person, agentStageAccount }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const sendInviteAsync = async () => {
    if (!person.eMail) {
      return;
    }

    setBtnDisabled(true);
    try {
      await commandApi.invitePerson({
        agentStageAccountId: agentStageAccount.id,
        channelId: agentStageAccount.channelId,
        emailAddress: person.eMail,
        personId: person.id,
        correlationId: ObjectID.generate(), // todo: перенести генерацию на сервер
      });
      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Приглашение отправлено',
        description: '',
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при отправке приглашения работнику',
        error: e,
      });
    }
    setBtnDisabled(false);
  };

  const onInviteBtnClick = () => {
    sendInviteAsync();
  };

  const iconProps = {
    height: 26,
    width: 26,
    style: { marginRight: 10 },
  };

  return (
    <List.Item>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        <Row align="middle">
          {agentStageAccount.channelId === Channels.VIBER && <ViberIcon {...iconProps} />}
          {agentStageAccount.channelId === Channels.TELEGRAM && <TelegramIcon {...iconProps} />}
          {agentStageAccount.channelId === Channels.ELMA365 && <Elma365Icon {...iconProps} />}
          <a href={agentStageAccount.redirectUrl || '#'}>
            {agentStageAccount.displayName ||
              (agentStageAccount.channelId === Channels.ELMA365 ? 'ELMA365' : agentStageAccount.externalName)}
          </a>
        </Row>
        <Tooltip placement="top" title="Отправить приглашение">
          <Button disabled={btnDisabled} icon={<MailOutlined />} type="primary" onClick={onInviteBtnClick} />
        </Tooltip>
      </Row>
    </List.Item>
  );
};

export default AgentAccountListItem;
