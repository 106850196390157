import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Input } from 'antd';

import './index.less';
import SbIcon from '../../SbIcon';

export interface ISbPasswordProps {
  id?: string;
  sbSize?: 'big' | 'small';
  placeholder?: string;
  value?: string;
  required?: boolean;
  isValid?: boolean;
  style?: React.CSSProperties;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  onFocus?: () => void;
  allowClear?: boolean;
  disabled?: boolean;
  onPressEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

const SbPassword: React.FC<ISbPasswordProps> = ({
  id,
  sbSize = 'big',
  placeholder,
  value,
  required,
  isValid,
  style,
  onBlur,
  onChange = () => {},
  onFocus,
  allowClear = true,
  disabled,
  onPressEnter,
}) => {
  const classes = ['sb-password'];
  if (sbSize) {
    classes.push(`sb-password_size-${sbSize}`);
  }
  if (isValid === false) {
    classes.push('sb-password_warning');
  }

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const onClearButtonClick = () => {
    onChange('');
  };

  const clearButton =
    value && allowClear ? (
      <button className="sb-password__clear" onClick={onClearButtonClick}>
        <SbIcon iconName="handle-x" size={20} />
      </button>
    ) : undefined;

  const renderIcon = (visible: boolean) => <SbIcon iconName={visible ? 'preview-open' : 'preview-close'} size={20} />;

  return (
    <div className={classes.join(' ')}>
      <Input.Password
        className="sb-password__content"
        disabled={disabled}
        iconRender={renderIcon}
        id={id}
        placeholder={placeholder}
        required={required}
        style={style}
        value={value}
        onBlur={onBlur}
        onChange={onInputChange}
        onFocus={onFocus}
        onPressEnter={onPressEnter}
      />
      {clearButton}
    </div>
  );
};

export default SbPassword;
