import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { Input, Menu } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import { Key } from 'ts-key-enum';

import './index.less';

import { DefaultActionGroupSchema, MenuButtonSchema } from '../../../../../api';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  selectedEntitySelector,
} from '../../../../recoil/scenarioStructure';
import ContextMenu from '../common/ContextMenu';
import OutputConnection from '../common/OutputConnection';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../utils';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';

interface IMenuButtonProps {
  button: MenuButtonSchema;
  group: DefaultActionGroupSchema;
  onDelete?: () => void;
}

const MenuButton: React.FC<IMenuButtonProps> = ({ button, group, onDelete }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const [buttonValue, setButtonValue] = useState(button.value);
  const [buttonIsEditing, setButtonIsEditing] = useState(false);

  const isButtonSelected = () => button.id === selectedEntity?.id;

  const onButtonMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setSelectedEntity(button);
  };

  const onButtonDoubleClick = () => {
    setButtonIsEditing(true);
  };

  const onButtonValueChange = () => {
    setButtonValue(button.value);
  };
  useEffect(onButtonValueChange, [button.value]);

  const onComponentCreate = () => {
    if (isButtonSelected()) {
      setButtonIsEditing(true);
    }
  };
  useEffect(onComponentCreate, []);

  const menuIsVisibleChanged = (value: boolean) => {
    setMenuIsVisible(value);
  };

  const onButtonDeleteSelect = () => {
    onDelete?.();
  };

  const onButtonInputBlur = () => {
    setButtonIsEditing(false);

    if (buttonValue === button.value) return;

    if (!buttonValue) {
      setButtonValue(button.value);
      return;
    }

    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundButton = tryGetElementById(newScenarioStructure, button.id) as MenuButtonSchema;
    foundButton.value = buttonValue;

    setScenarioStructure(newScenarioStructure);
  };

  const onButtonInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setButtonValue(e.target.value);
  };

  const onButtonInputFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.select();
  };

  const onButtonInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      onButtonInputBlur();
      return;
    }
    if (e.key === Key.Escape) {
      setButtonValue(button.value);
      setButtonIsEditing(false);
    }
  };

  const classes = ['menu-button'];
  isButtonSelected() && classes.push('menu-button_selected');
  buttonIsEditing && classes.push('menu-button_editing');

  return (
    <div
      aria-hidden="true"
      className={classes.join(' ')}
      id={getElementId(button.id)}
      onDoubleClick={onButtonDoubleClick}
      onMouseDown={onButtonMouseDown}
    >
      <div className="menu-button__content">
        <div className="menu-button__text">
          {buttonIsEditing ? (
            <Input
              autoFocus
              value={buttonValue}
              onBlur={onButtonInputBlur}
              onChange={onButtonInputChange}
              onClick={(e) => e.stopPropagation()}
              onFocus={onButtonInputFocus}
              onKeyDown={onButtonInputKeyDown}
              onMouseDown={(e) => e.stopPropagation()}
            />
          ) : (
            <span title={buttonValue}>{buttonValue}</span>
          )}
        </div>
      </div>
      <OutputConnection
        className={buttonIsEditing ? 'editing' : undefined}
        entity={button}
        groupId={group.id}
        id={getElementId(button.id, EntityFieldPath.OutputBindingId)}
        isValid={!scenarioValidation?.hasIssue(button.id, EntityFieldPath.OutputBindingId)}
      />
      <ContextMenu
        menuContent={
          <Menu>
            {onDelete && (
              <Menu.Item key="delete" onClick={onButtonDeleteSelect}>
                <SbIcon iconName="close" />
              </Menu.Item>
            )}
          </Menu>
        }
        menuIsVisible={menuIsVisible}
        menuIsVisibleChanged={menuIsVisibleChanged}
      />
    </div>
  );
};

export default MenuButton;
