import React, { memo } from 'react';
import { Typography } from 'antd';

import { DefaultTriggerGroupSchema, StartTriggerSchema } from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

const { Text } = Typography;

interface IStartTriggerProps {
  index: number;
  trigger: StartTriggerSchema;
  group: DefaultTriggerGroupSchema;
}

const StartTrigger: React.FC<IStartTriggerProps> = ({ index, trigger, group }) => {
  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="play" size={20} />}
      index={index}
      menuItems={[]}
      title="Старт бота"
      trigger={trigger}
    >
      <Text>Этот сценарий запустится при старте бота.</Text>
    </TriggerWrapper>
  );
};

export default memo(StartTrigger);
