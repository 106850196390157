import React, { useEffect, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { Card, Divider, List, PageHeader, Radio, RadioChangeEvent } from 'antd';

import './index.less';

import SbButton from '../../../components/common/SbButton';
import SbBotListCard from '../../../components/SbBotListCard';
import SbVideoPlayer from '../../../components/common/SbVideoPlayer';
import SbModal from '../../../components/common/SbModal';
import SbIcon from '../../../components/common/SbIcon';
import { BotSortDirection } from '../../../../../api';
import { ListBotModel } from '../../../../../api';
import { botApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import CreateBotWizard from '../CreateBotWizard';
import { useQuery } from '../../../../utils/urlUtil';
import { equalsIgnoreCase } from '../../../../utils/stringUtil';
import { webchatModalSelector } from '../../../../recoil/modals';
import SbSpin from '../../../components/common/SbSpin';

import data from './assets/videos.json';
import { Arrow } from './assets';

const CREATE_BOT_WIZARD_MODAL_WIDTH = 1364;
const SHOW_CREATE_BOT_MODAL_PARAM = 'showCreateBotModal';

const SimpleBotList: React.FC = () => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const query = useQuery();
  const resetWebchatModalState = useResetRecoilState(webchatModalSelector);

  const [loading, setLoading] = useState(false);
  const [bots, setBots] = useState([] as ListBotModel[]);

  const [sortMode, setSortMode] = useState(BotSortDirection.StageModifiedOnDescending);

  const [videoVisible, setVideoVisible] = useState(false);
  const toggleVisible = () => setVideoVisible((value) => !value);

  const [isCreateBotWizardModalVisible, setIsCreateBotWizardModalVisible] = useState(
    equalsIgnoreCase(query.get(SHOW_CREATE_BOT_MODAL_PARAM) || '', 'true')
  );

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await botApi.searchBots(undefined, sortMode, 0, 1000);
      setBots(response.data.items || []);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка ботов',
        description: e.toString(),
      });
    }
    setLoading(false);
  };

  const onSortModeChanged = (e: RadioChangeEvent) => setSortMode(e.target.value);
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [sortMode]);

  // NOTE: закрытие вебчата при уходе со страницы
  useEffect(() => () => resetWebchatModalState(), [resetWebchatModalState]);

  const onBotChanged = (bot: ListBotModel) => {
    const newBots = [...bots];
    const foundBot = newBots.find((b) => b.entry.id === bot.entry.id);
    if (!foundBot) return;

    foundBot.entry = bot.entry;
    foundBot.originStage = bot.originStage;
    foundBot.draftStage = bot.draftStage;

    setBots(newBots);
  };

  return (
    <div className="sb-bot-list">
      {!!data.videos.length && (
        <div className="sb-bot-list__videos">
          <div className="sb-bot-list__videos__header">
            <span className="sb-bot-list__videos__title">Обучение</span>
            <SbButton sbSize="medium" sbType="tertiary" onClick={toggleVisible}>
              <span className="sb-bot-list__videos__text">Посмотреть всё</span>
              <SbIcon iconName={videoVisible ? 'down' : 'right'} size={16} />
            </SbButton>
          </div>
          <div className={'sb-bot-list__videos__wrapper ' + (videoVisible ? 'sb-bot-list__videos__wrapper_open' : '')}>
            <List
              dataSource={data.videos}
              grid={{ gutter: 26 }}
              renderItem={(item) => {
                return (
                  <>
                    <List.Item key={item.id}>
                      <SbVideoPlayer height="140px" link={item.link} width="270px" />
                    </List.Item>
                  </>
                );
              }}
            />
          </div>
        </div>
      )}
      <div className="sb-bot-list__top">
        <div className="sb-bot-list__wrapper">
          <PageHeader className="sb-bot-list__header" title="Ваши чат-боты" />
          <SbButton sbSize="big" onClick={() => setIsCreateBotWizardModalVisible(true)}>
            Создать нового бота
          </SbButton>
        </div>
        {!!bots.length && (
          <div className="sb-bot-list__sort-panel">
            <span className="sb-bot-list__sort-panel__text">Сортировать:</span>
            <Radio.Group value={sortMode} onChange={onSortModeChanged}>
              <Radio.Button value={BotSortDirection.StageModifiedOnDescending}>по дате изменения</Radio.Button>
              <Divider type="vertical" />
              <Radio.Button value={BotSortDirection.EntryNameAscending}>по названию</Radio.Button>
            </Radio.Group>
          </div>
        )}
      </div>
      {!!bots.length && (
        <div className="sb-bot-list__cards-container">
          <List<ListBotModel>
            dataSource={bots}
            grid={{ gutter: 20, xxl: 4, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
            loading={loading}
            renderItem={(item) => (
              <List.Item key={item.entry.id}>
                <SbBotListCard bot={item} onBotChanged={onBotChanged} />
              </List.Item>
            )}
          />
        </div>
      )}
      {!loading && !bots.length && (
        <div className="sb-bot-list__empty">
          <div className="sb-bot-list__empty-wrapper">
            <Arrow className="sb-bot-list__arrow" />
            <h3 className="sb-bot-list__empty-title">Сейчас у вас нет чат-ботов</h3>
            <span className="sb-bot-list__empty-text">
              Чтобы создать первого бота, <br />
              нажмите эту кнопку.
            </span>
          </div>
          <Card className="sb-bot-list-card">
            <div className="sb-bot-list-card__container">
              <div className="sb-bot-list-card__top">
                <h3 className="sb-bot-list-card__top__title">
                  <span className="sb-bot-list-card__top__title-text">Ваш первый бот</span>
                </h3>
                <div className="sb-bot-list-card__top__icon" role="none">
                  <SbIcon iconName="more-one" />
                </div>
              </div>
              <div className="sb-bot-list-card__content">
                <div className="sb-bot-list-card__scenario-count">2 сценария</div>
                <Divider className="sb-bot-list-card__divider" type="vertical" />
                <div className="sb-bot-list-card__scenario-date">Новый</div>
              </div>
            </div>
            <div className="sb-bot-list-card__state sb-bot-list-card__state_on">Подключен</div>
          </Card>
        </div>
      )}
      {loading && <SbSpin />}
      <SbModal
        destroyOnClose
        footer={[]}
        sbSize="large"
        visible={isCreateBotWizardModalVisible}
        width={CREATE_BOT_WIZARD_MODAL_WIDTH}
        onCancel={() => {
          setIsCreateBotWizardModalVisible(false);
        }}
      >
        <CreateBotWizard onClose={() => setIsCreateBotWizardModalVisible(false)} />
      </SbModal>
    </div>
  );
};

export default SimpleBotList;
