import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button/button';

import './index.less';

interface SbButtonProps extends ButtonProps {
  sbType?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'text-only'
    | 'icon-only'
    | 'icon-primary'
    | 'icon-secondary'
    | 'icon-primary-48'
    | 'icon-primary-filled-48'
    | 'icon-secondary-48'
    | 'icon-primary-32'
    | 'icon-secondary-32'
    | 'link';
  sbSize?: 'big' | 'medium';
}

const SbButton: React.FC<SbButtonProps> = ({
  sbType = 'primary',
  sbSize = 'big',
  danger = false,
  children,
  className,
  ...otherProps
}) => {
  const classes = ['sb-button', `sb-button_${sbType}`, `sb-button_${sbSize}`];

  danger && classes.push('sb-button_danger');
  className && classes.push(className);

  return (
    <Button className={classes.join(' ')} {...otherProps}>
      {children}
    </Button>
  );
};

export default SbButton;
