import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flags } from 'react-feature-flags';

import './index.less';

import {
  InboxOperatorGroupModel,
  InboxParticipantModel,
  InboxParticipantStatus,
  InboxParticipantUpdatingRequest,
} from '../../../../api';
import IbPopover from '../common/IbPopover';
import IbTypography from '../common/IbTypography';
import IbIcon from '../common/IbIcon';
import IbAvatar, { IbAvatarSize } from '../common/IbAvatar';
import IbDivider from '../common/IbDivider';
import IbSwitch from '../common/IbSwitch';
import { IbUserStatusType } from '../common/IbUserStatus';
import IbOperatorEditModal from '../IbOperatorEditModal';
import { FeatureFlagNames } from '../../../constants';

const MAIN_CLASS_NAME = 'ib-account-widget';
const USER_INFO_POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__user-info-popover`;
const USER_INFO_POPOVER_CONTENT_CLASS_NAME = `${USER_INFO_POPOVER_CLASS_NAME}__content`;
const USER_INFO_POPOVER_CONTENT_INFO_AREA_CLASS_NAME = `${USER_INFO_POPOVER_CONTENT_CLASS_NAME}__info-area`;
const USER_INFO_POPOVER_CONTENT_INFO_AREA_TEXT_PART_CLASS_NAME = `${USER_INFO_POPOVER_CONTENT_INFO_AREA_CLASS_NAME}__text-part`;
const USER_INFO_POPOVER_CONTENT_STATUS_AREA_CLASS_NAME = `${USER_INFO_POPOVER_CONTENT_CLASS_NAME}__status-area`;
const USER_INFO_POPOVER_CONTENT_BUTTONS_AREA_CLASS_NAME = `${USER_INFO_POPOVER_CONTENT_CLASS_NAME}__buttons-area`;

export interface IIbAccountWidgetProps {
  currentOperator?: InboxParticipantModel;
  groupList?: InboxOperatorGroupModel[];
  onCurrentOperatorStatusChange?: (status: InboxParticipantStatus) => void;
  onCurrentOperatorEdit?: (request: InboxParticipantUpdatingRequest) => Promise<void>;
  onLogout?: () => Promise<void>;
}

const IbAccountWidget: React.FC<IIbAccountWidgetProps> = ({
  currentOperator,
  groupList,
  onCurrentOperatorStatusChange,
  onCurrentOperatorEdit,
  onLogout,
}) => {
  const { t } = useTranslation();

  const [userInfoPopoverVisible, setUserInfoPopoverVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const onToggleStatusClick = (checked: boolean) =>
    onCurrentOperatorStatusChange?.(checked ? InboxParticipantStatus.Online : InboxParticipantStatus.Offline);

  const onUserInfoPopoverVisibleChange = (visible?: boolean) => setUserInfoPopoverVisible(visible || false);

  const onAvatarClick = () => setUserInfoPopoverVisible(true);

  const onEditAccountClick = () => {
    setUserInfoPopoverVisible(false);
    setEditModalVisible(true);
  };

  const onEditModalCancel = () => setEditModalVisible(false);

  const renderOperatorEditModal = (isAdminAuthorize: boolean) => (
    <IbOperatorEditModal
      allowEditSettings
      allowBlock={false}
      allowEditGroups={isAdminAuthorize}
      allowEditRoles={isAdminAuthorize}
      editOperator={currentOperator}
      groupList={groupList}
      visible={editModalVisible}
      onCancel={onEditModalCancel}
      onEdit={onCurrentOperatorEdit}
    />
  );

  const renderUserInfoContent = () => {
    return (
      <div className={USER_INFO_POPOVER_CONTENT_CLASS_NAME}>
        <div className={USER_INFO_POPOVER_CONTENT_INFO_AREA_CLASS_NAME}>
          <IbAvatar
            metadata={{
              uid: currentOperator?.subject.id || '',
              text: currentOperator?.subject.person.name.fullName || '',
            }}
            size="medium"
          />
          <div className={USER_INFO_POPOVER_CONTENT_INFO_AREA_TEXT_PART_CLASS_NAME}>
            <IbTypography.Paragraph strong type="secondary">
              {currentOperator?.subject.person.name.fullName || ''}
            </IbTypography.Paragraph>
            <IbTypography.Paragraph disabled type="secondary">
              {currentOperator?.subject.person.contacts.email || ''}
            </IbTypography.Paragraph>
          </div>
        </div>
        <IbDivider orientation="center" type="horizontal" />
        <div className={USER_INFO_POPOVER_CONTENT_STATUS_AREA_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="secondary">
            {t('My status')}
          </IbTypography.Paragraph>
          <IbSwitch
            checked={currentOperator?.status === InboxParticipantStatus.Online}
            onChange={onToggleStatusClick}
          />
          <IbTypography.Paragraph type="secondary">
            {currentOperator?.status === InboxParticipantStatus.Online ? t('Online') : t('Offline')}
          </IbTypography.Paragraph>
        </div>
        <IbDivider orientation="center" type="horizontal" />
        <div className={USER_INFO_POPOVER_CONTENT_BUTTONS_AREA_CLASS_NAME}>
          <ul>
            <li key={0}>
              <div onClick={onEditAccountClick}>
                <IbIcon iconName="setting-two" size={20} />
                <span>{t('Account settings')}</span>
              </div>
            </li>
            <li key={1}>
              <div>
                <IbIcon iconName="help" size={20} />
                <span>{t('Help')}</span>
              </div>
            </li>
            <li key={2}>
              <div onClick={onLogout}>
                <IbIcon iconName="logout" size={20} />
                <span>{t('Log out')}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderAvatar = (size: IbAvatarSize) => (
    <IbAvatar
      metadata={{
        uid: currentOperator?.subject.id || '',
        text: currentOperator?.subject.person.name.fullName || '',
      }}
      size={size}
      userStatus={currentOperator?.status?.toLowerCase() as IbUserStatusType | undefined}
      onClick={onAvatarClick}
    />
  );

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbPopover
        className={USER_INFO_POPOVER_CLASS_NAME}
        content={renderUserInfoContent()}
        placement="rightBottom"
        trigger="click"
        visible={userInfoPopoverVisible}
        onVisibleChange={onUserInfoPopoverVisibleChange}
      >
        {renderAvatar('medium')}
        {renderAvatar('x-small')}
      </IbPopover>
      <Flags
        authorizedFlags={[FeatureFlagNames.ADMIN]}
        renderOff={() => renderOperatorEditModal(false)}
        renderOn={() => renderOperatorEditModal(true)}
      />
    </div>
  );
};

export default IbAccountWidget;
