import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from './types';

export const WhatsAppIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M0 23.3356L1.67301 17.5373C0.59788 15.7587 0.0299535 13.7193 0.0308854 11.6404C0.0308854 5.22134 5.38267 0 11.9611 0C18.5401 0 23.8919 5.22134 23.8919 11.6404C23.8919 18.0588 18.5401 23.2802 11.9611 23.2802C9.91163 23.2802 7.90413 22.7685 6.12856 21.7966L0 23.3356Z"
          fill="#33CE52"
        />
        <path
          d="M8.61132 6.22783L7.84037 6.18699C7.59937 6.17365 7.36237 6.25295 7.17781 6.40868C6.8037 6.72546 6.20582 7.33802 6.02226 8.13727C5.74838 9.32797 6.17202 10.7864 7.26697 12.2449C8.36191 13.7034 10.4026 16.037 14.0115 17.034C15.174 17.3548 16.0889 17.1384 16.7946 16.6974C17.0688 16.5253 17.3058 16.3001 17.4919 16.035C17.6779 15.7699 17.8091 15.4703 17.8779 15.1537L18.0008 14.5925C18.0195 14.5051 18.0075 14.414 17.9668 14.3345C17.9262 14.255 17.8593 14.192 17.7776 14.1561L15.1717 12.9829C15.0896 12.9459 14.9976 12.9372 14.91 12.9582C14.8225 12.9792 14.7444 13.0287 14.688 13.099L13.6647 14.3941C13.6265 14.4422 13.5742 14.4772 13.5153 14.4943C13.4563 14.5114 13.3935 14.5099 13.3355 14.4898C12.6351 14.2494 10.2878 13.2892 9 10.867C8.97254 10.8158 8.96144 10.7574 8.96822 10.6997C8.97499 10.642 8.99931 10.5877 9.03788 10.5443L10.0157 9.4394C10.0644 9.38468 10.0964 9.3171 10.1079 9.24471C10.1193 9.17231 10.1098 9.09815 10.0804 9.03102L8.95688 6.46411C8.92677 6.39656 8.87852 6.33872 8.81751 6.297C8.7565 6.25529 8.68512 6.23134 8.61132 6.22783Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
});
