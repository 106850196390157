import React from 'react';
import { Menu } from 'antd';
import { DividerProps } from 'rc-menu/lib/Divider';

import './index.less';

export type ISbDividerProps = DividerProps;

const SbDivider: React.FC<ISbDividerProps> = (props) => <Menu.Divider className="sb-menu__divider" {...props} />;

export default SbDivider;
