import { Card, Col, Divider, Menu, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';

import './index.less';

import SbContextMenu from '../../../../components/common/SbContextMenu';
import { agentStageAccountApi } from '../../../../../apis';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import { AlertTypes, Channels, INLINE_BUTTONS_ENABLED_PROPERTY_KEY } from '../../../../../constants';
import SbSwitch from '../../../../components/common/SbSwitch';
import {
  AgentStageAccountModel,
  AgentStageAccountStatus,
  AgentStageAccountUpdatingRequest,
} from '../../../../../../api';
import SbChannelStatus from '../../../../components/common/SbChannelStatus';
import SbChannelIcon from '../../../../components/common/SbChannelIcon';
import SbButton from '../../../../components/common/SbButton';
import { getChannelDisplayName, getChannelLink } from '../../../../../utils/stringUtil';
import SbTag from '../../../../components/common/SbTag';

const { Paragraph } = Typography;

const CHANNEL_PROPERTIES_CLASS_NAME = 'sb-channels-card__content__list-row__list__item__properties';
const CHANNEL_PROPERTIES_LABEL_CLASS_NAME = `${CHANNEL_PROPERTIES_CLASS_NAME}__label`;
const CHANNEL_PROPERTIES_VALUE_CLASS_NAME = `${CHANNEL_PROPERTIES_CLASS_NAME}__value`;

interface IChannelListItemProps {
  channel: AgentStageAccountModel;
  onEdit: (dataEntry: AgentStageAccountModel) => void;
  onDelete: (dataEntry: AgentStageAccountModel) => void;
  onDataChanged: () => void;
}

const ChannelListItem: React.FC<IChannelListItemProps> = ({ channel, onEdit, onDelete, onDataChanged }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [saving, setSaving] = useState(false);

  const onEditSelect = () => onEdit(channel);
  const onDeleteSelect = () => onDelete(channel);

  const showXToken = channel?.channelId == Channels.ELMA365;
  const showAuthToken = [Channels.TELEGRAM, Channels.VIBER].includes(channel?.channelId as Channels);
  const showBotUri = [Channels.TELEGRAM, Channels.VIBER, Channels.WHATSAPP].includes(channel?.channelId as Channels);
  const showCallbackUrl = channel?.channelId == Channels.WHATSAPP;
  const showInlineOption = channel?.channelId == Channels.TELEGRAM;

  const onToggleEnabledField = async () => {
    if (!channel) return;
    try {
      setSaving(true);

      const newStatus =
        channel.status === AgentStageAccountStatus.Enabled
          ? AgentStageAccountStatus.Disabled
          : AgentStageAccountStatus.Enabled;

      const request: AgentStageAccountUpdatingRequest = {
        agentStageId: channel.agentStageId,
        status: newStatus,
        displayName: channel.displayName ?? '',
        authToken: channel.authToken,
        properties: channel.properties,
      };

      await agentStageAccountApi.updateAgentStageAccount(channel.id, request);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();

      addAlert({
        type: AlertTypes.ERROR,
        message: 'Не удалось сохранить канал',
        description: errorText,
      });
    }
    setSaving(false);
    onDataChanged();
  };

  const contextMenuContent = (
    <Menu>
      <Menu.Item key="edit" onClick={onEditSelect}>
        Редактировать
      </Menu.Item>
      <Menu.Item key="edit" onClick={onDeleteSelect}>
        Удалить
      </Menu.Item>
    </Menu>
  );

  const onChannelLinkClick = () => {
    const uri = getChannelLink(channel);
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    uri && window.open(uri, '_blank');
  };

  const renderChannelProperties = () => {
    return (
      <div className={CHANNEL_PROPERTIES_CLASS_NAME}>
        {showXToken && (
          <>
            <div className={CHANNEL_PROPERTIES_LABEL_CLASS_NAME}>Токен</div>
            <div className={CHANNEL_PROPERTIES_VALUE_CLASS_NAME}>
              {channel.properties?.xToken && (
                <Paragraph copyable ellipsis>
                  {channel.properties?.xToken}
                </Paragraph>
              )}
            </div>
          </>
        )}

        {showAuthToken && (
          <>
            <div className={CHANNEL_PROPERTIES_LABEL_CLASS_NAME}>Токен</div>
            <div className={CHANNEL_PROPERTIES_VALUE_CLASS_NAME}>
              {channel.authToken && (
                <Paragraph copyable ellipsis>
                  {channel.authToken}
                </Paragraph>
              )}
            </div>
          </>
        )}

        {showBotUri && (
          <>
            <div className={CHANNEL_PROPERTIES_LABEL_CLASS_NAME}>Подключение к боту</div>
            <div className={CHANNEL_PROPERTIES_VALUE_CLASS_NAME}>
              <Paragraph ellipsis>
                <SbButton sbType="link" size="small" onClick={onChannelLinkClick}>
                  {getChannelLink(channel)}
                </SbButton>
              </Paragraph>
            </div>
          </>
        )}

        {showCallbackUrl && (
          <>
            <div className={CHANNEL_PROPERTIES_LABEL_CLASS_NAME}>Адрес обратного вызова</div>
            <div className={CHANNEL_PROPERTIES_VALUE_CLASS_NAME}>
              {channel.authToken && (
                <Paragraph copyable ellipsis>
                  {channel.callbackUrl}
                </Paragraph>
              )}
            </div>
          </>
        )}

        {showInlineOption && (
          <>
            <div className={CHANNEL_PROPERTIES_LABEL_CLASS_NAME}>Использовать Inline кнопки</div>
            <div className={CHANNEL_PROPERTIES_VALUE_CLASS_NAME}>
              <Paragraph ellipsis>
                {channel?.properties?.[INLINE_BUTTONS_ENABLED_PROPERTY_KEY] ? (
                  <SbTag color="#DFF6C3" sbSize="x-small" text="Включено" />
                ) : (
                  <SbTag color="#EAEAEA" sbSize="x-small" text="Выключено" />
                )}
              </Paragraph>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Card hoverable className="sb-channels-card__content__list-row__list__item">
      <Row className="sb-channels-card__content__list-row__list__item__title" justify="space-between">
        <Col span="auto">
          <div className="sb-channels-card__content__list-row__list__item__title__name">
            <SbChannelIcon size="normal" type={channel.channelId as Channels} />
            {getChannelDisplayName(channel)}
          </div>
        </Col>
        <Col
          className="sb-channels-card__content__list-row__list__item__title__controls"
          onClick={(e) => e.stopPropagation()}
        >
          <Row wrap={false}>
            <SbChannelStatus enabledStatus={channel.status} setupResult={channel.setupResult} />
            <SbSwitch
              checked={channel.status === AgentStageAccountStatus.Enabled}
              loading={saving}
              size="default"
              onClick={onToggleEnabledField}
            />
            <SbContextMenu menuContent={contextMenuContent} />
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>{renderChannelProperties()}</Col>
      </Row>
    </Card>
  );
};

export default ChannelListItem;
