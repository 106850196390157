import React, { useEffect, useState } from 'react';

import { CommandTriggerSchema } from '../../../../../../../api';
import SbModal from '../../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../../simple-bot/components/common/SbButton';
import SbTypography from '../../../../../../simple-bot/components/common/SbTypography';
import SbInput from '../../../../../../simple-bot/components/common/SbInput';

import './index.less';

const MODAL_WIDTH = 516;

interface SettingModalProps {
  visible: boolean;
  commandTrigger: CommandTriggerSchema;
  onChange: (schema: CommandTriggerSchema) => void;
  onModalClose: () => void;
}

const SettingModal: React.FC<SettingModalProps> = ({ visible, commandTrigger, onChange, onModalClose }) => {
  const [command, setCommand] = useState(commandTrigger.command);
  const [description, setDescription] = useState(commandTrigger.description);

  const resetState = () => {
    if (!visible) return;

    setCommand(commandTrigger.command);
    setDescription(commandTrigger.description);
  };
  useEffect(resetState, [visible, commandTrigger]);

  const onSaveButtonClick = () =>
    onChange({
      ...commandTrigger,
      command,
      description,
    });

  const onModalCancel = () => onModalClose();

  const onCancelButtonClick = () => onModalClose();

  const onDescriptionChange = (value: string) => setDescription(value);

  return (
    <SbModal
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройка команды"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
    >
      <div className="button-settings">
        <SbTypography>
          <h3>Описание</h3>
          <SbInput value={description || ''} onChange={onDescriptionChange} />
        </SbTypography>
      </div>
    </SbModal>
  );
};

export default SettingModal;
