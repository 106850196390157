import React, { useState, MouseEvent, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useRecoilValue } from 'recoil';
import isEqual from 'lodash/isEqual';

import BotAnswerEditor from '../../BotAnswerEditor';
import { AttachmentSchema, MessageSchema, SchemaKind, VariableSchema } from '../../../../../../api';
import { EntityFieldPath, generateId, getElementId, getNewMessageId } from '../../../utils';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  selectedEntitySelector,
} from '../../../../../recoil/scenarioStructure';

interface IMessagesEditorProps {
  actionId: string;
  messages: MessageSchema[];
  placeholder: string;
  required?: boolean;
  onMessagesChange: (newValue: MessageSchema[]) => void;
  useScenarioEditorZoom?: boolean;
}

const MessagesEditor: React.FC<IMessagesEditorProps> = ({
  actionId,
  messages,
  placeholder,
  required = false,
  onMessagesChange,
  useScenarioEditorZoom,
}) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const [editingIndex, setEditingIndex] = useState<number>();
  const [currentEditingContent, setCurrentEditingContent] = useState('');
  const [currentEditingAttachments, setCurrentEditingAttachments] = useState<AttachmentSchema[]>([]);
  const [newMessage, setNewMessage] = useState<MessageSchema>();

  const localMessages = [...messages];
  newMessage && localMessages.push(newMessage);

  const messageIsEmpty = !currentEditingContent && !currentEditingAttachments.length;

  const deleteMessage = (index: number) => {
    const copy = cloneDeep(messages);
    copy.splice(index, 1);

    onMessagesChange(copy);
  };

  const addMessage = (messageContent: string, messageAttachments: AttachmentSchema[]) => {
    const newMessages = [...messages];
    newMessages.push({
      id: generateId('MSG'),
      $kind: SchemaKind.Message,
      content: messageContent,
      attachments: messageAttachments,
    });

    onMessagesChange(newMessages);
  };

  const updateMessage = (index: number, messageContent: string, messageAttachments: AttachmentSchema[]) => {
    const copy = cloneDeep(messages);
    copy[index].content = messageContent;
    copy[index].attachments = messageAttachments;

    onMessagesChange(copy);
  };

  const setFirstMessageEditing = () => {
    const message: MessageSchema = {
      id: getNewMessageId(actionId),
      $kind: SchemaKind.Message,
      content: '',
      attachments: [],
    };
    setNewMessage(message);
    setEditingIndex(0);
    setCurrentEditingContent('');
    setCurrentEditingAttachments([]);
    setSelectedEntity(message);
  };

  const onComponentCreate = () => {
    if (!messages.length && selectedEntity?.id === getNewMessageId(actionId)) {
      setFirstMessageEditing();
    }
  };
  useEffect(onComponentCreate, []);

  const onEditorFocus = (index: number) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (editingIndex === undefined) {
      setSelectedEntity(localMessages[index]);
      setEditingIndex(index);
      setCurrentEditingContent(localMessages[index].content);
      setCurrentEditingAttachments(localMessages[index].attachments);
    }
  };

  const onEditorBlur = () => {
    if (editingIndex === undefined) return;

    if (newMessage && !messageIsEmpty) {
      addMessage(currentEditingContent, currentEditingAttachments);
    } else if (messageIsEmpty) {
      deleteMessage(editingIndex);
    } else if (
      currentEditingContent !== localMessages[editingIndex].content ||
      !isEqual(currentEditingAttachments, localMessages[editingIndex].attachments)
    ) {
      updateMessage(editingIndex, currentEditingContent, currentEditingAttachments);
    }

    setEditingIndex(undefined);
    setCurrentEditingContent('');
    setCurrentEditingAttachments([]);
    setNewMessage(undefined);
  };

  const onEditorAnswerChange = (index: number) => (value: string) => {
    if (editingIndex === index) {
      setCurrentEditingContent(value);
    }
  };

  const onEditorAttachmentsChange = (index: number) => (attachments: AttachmentSchema[]) => {
    if (editingIndex === index) {
      setCurrentEditingAttachments(attachments);
    }
  };

  const onEditorVariablesChange = (newVariables: VariableSchema[]) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.variables = newVariables;
    setScenarioStructure(newScenarioStructure);
  };

  const onEditorAddAnswer = () => {
    if (editingIndex !== undefined && newMessage && currentEditingContent) {
      addMessage(currentEditingContent, currentEditingAttachments);

      setNewMessage({
        id: getNewMessageId(actionId),
        $kind: SchemaKind.Message,
        content: '',
        attachments: [],
      });

      setEditingIndex(editingIndex + 1);
      setCurrentEditingContent('');
      setCurrentEditingAttachments([]);
      return;
    }

    setNewMessage({
      id: getNewMessageId(actionId),
      $kind: SchemaKind.Message,
      content: '',
      attachments: [],
    });

    setEditingIndex(messages.length);
    setCurrentEditingContent('');
    setCurrentEditingAttachments([]);
  };

  const onEditorDeleteAnswer = (index: number) => () => {
    setEditingIndex(undefined);
    setCurrentEditingContent('');
    setCurrentEditingAttachments([]);

    if (newMessage) {
      setNewMessage(undefined);
      return;
    }

    deleteMessage(index);
  };

  const onPlaceholderClick = () => setFirstMessageEditing();

  const classes = ['answers'];
  if (
    (actionId && scenarioValidation?.hasIssue(actionId, EntityFieldPath.Messages)) ||
    (required && (!localMessages.length || !localMessages.some((m) => m.content.length)))
  ) {
    classes.push('answers_warning');
  }

  return (
    <div className={classes.join(' ')} id={getElementId(actionId, EntityFieldPath.Messages)}>
      {!localMessages.length && (
        <div className="placeholder" role="none" onClick={onPlaceholderClick}>
          {placeholder}
        </div>
      )}
      {localMessages.map((message, index) => (
        <div key={message.id + '_' + index} className="answer-wrapper">
          {localMessages.length > 1 && <div className="answer-title">Вариант {index + 1}:</div>}
          <BotAnswerEditor
            attachments={editingIndex === index ? currentEditingAttachments : message.attachments}
            placeholder={placeholder}
            readOnly={editingIndex !== index}
            useScenarioEditorZoom={useScenarioEditorZoom}
            value={editingIndex === index ? currentEditingContent : message.content}
            variables={scenarioStructure?.variables || []}
            onAddAnswer={onEditorAddAnswer}
            onAnswerChange={onEditorAnswerChange(index)}
            onAttachmentsChange={onEditorAttachmentsChange(index)}
            onBlur={onEditorBlur}
            onDeleteAnswer={onEditorDeleteAnswer(index)}
            onFocus={onEditorFocus(index)}
            onVariablesChange={onEditorVariablesChange}
          />
        </div>
      ))}
    </div>
  );
};

export default MessagesEditor;
