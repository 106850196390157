import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { DataEntryModel, SingleKnowledgeBaseModel, StageModel } from '../../../../../kb-api';
import SbButton from '../../../components/common/SbButton';
import SbModal from '../../../components/common/SbModal';
import { versionKbApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';

import InstanceStatusProgress from './InstanceStatusProgress';

interface IDataEntryDeleteConfirmModalProps {
  knowledgeBase: SingleKnowledgeBaseModel;
  dataEntry?: DataEntryModel;
  visible: boolean;
  getDraftStage: () => Promise<StageModel | undefined>;
  getDataEntryIdByVersion: (versionId: string, dataEntryId: string) => Promise<string | undefined>;
  onDataChanged: () => void;
  onCancel: () => void;
}

const DataEntryDeleteConfirmModal: React.FC<IDataEntryDeleteConfirmModalProps> = ({
  knowledgeBase,
  dataEntry,
  visible,
  getDraftStage,
  getDataEntryIdByVersion,
  onDataChanged,
  onCancel,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [deleting, setDeleting] = useState(false);
  const [publishing, setPublishing] = useState(false);

  const buttonsDisabled = deleting || publishing;
  const draftInstance = knowledgeBase.draftInstance;

  const deleteDataEntry = async (versionId: string) => {
    if (!dataEntry?.id) return;

    try {
      const dataEntryId = await getDataEntryIdByVersion(versionId, dataEntry.id);

      if (!dataEntryId) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Ошибка при удалении записи в базе знаний',
        });
        return;
      }

      await versionKbApi.updateDataEntry(versionId, dataEntryId, {
        type: dataEntry.type,
        data: dataEntry.data,
        childEntryIds: dataEntry.childEntryIds,
        isArchived: true,
      });
      setPublishing(true);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при удалении записи в базе знаний',
        error: e,
      });
    }
  };

  const onVisiblePropChange = () => {
    if (visible) {
      setDeleting(false);
      setPublishing(false);
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onConfirm = async () => {
    if (buttonsDisabled) return;

    setDeleting(true);

    const draftStage = await getDraftStage();
    if (!draftStage) {
      setDeleting(false);
      return;
    }

    await deleteDataEntry(draftStage.versionId);

    setDeleting(false);
  };

  const onModalCancel = () => !buttonsDisabled && onCancel();

  const onPublishComplete = () => onDataChanged();

  return (
    <SbModal
      destroyOnClose
      footer={[
        <SbButton key="confirm" loading={buttonsDisabled} sbSize="medium" onClick={onConfirm}>
          Удалить
        </SbButton>,
        <SbButton key="cancel" disabled={buttonsDisabled} sbSize="medium" sbType="secondary" onClick={onModalCancel}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Подтвердите удаление записи"
      visible={visible}
      width={450}
      onCancel={onModalCancel}
      onOk={onConfirm}
    >
      Вы действительно хотите удалить запись?
      {publishing && draftInstance?.id && (
        <InstanceStatusProgress instanceId={draftInstance.id} visible={false} onPublishComplete={onPublishComplete} />
      )}
    </SbModal>
  );
};

export default DataEntryDeleteConfirmModal;
