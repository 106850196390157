import React from 'react';
import { Alert, Steps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  InstanceModel,
  KnowledgeSourceModel,
  ParsingStatusState,
  PublishOperation,
  PublishStatusState,
} from '../../kb-api';

const { Step } = Steps;
const MAX_PERCENT = 100;

const getOperationProgressPercent = (operation: PublishOperation | undefined): number => {
  if (operation?.maximumProgress === 0) return MAX_PERCENT;
  if (!operation?.maximumProgress || !operation?.currentProgress) return 0;
  return Math.round((operation.currentProgress / operation.maximumProgress) * 100);
};

export interface IPublishStatusViewProps {
  knowledgeSource?: KnowledgeSourceModel;
  instance?: InstanceModel;
  detailed?: boolean;
}

const PublishStatusView: React.FC<IPublishStatusViewProps> = ({ knowledgeSource, instance, detailed = false }) => {
  if (knowledgeSource?.parsingStatus?.state === ParsingStatusState.Error) {
    return (
      <Alert
        showIcon
        description={knowledgeSource.parsingStatus?.message}
        message="Возникла ошибка при разборе файла"
        type="error"
      />
    );
  }

  if (instance?.status?.state === PublishStatusState.Error) {
    return (
      <Alert
        showIcon
        description={instance.status?.message}
        message="Возникла ошибка при публикации версии"
        type="error"
      />
    );
  }

  const parsingOperationPercent =
    !knowledgeSource || knowledgeSource?.parsingStatus?.state === ParsingStatusState.Success ? 100 : 0;
  const indexCreationOperationPercent = getOperationProgressPercent(
    instance?.status?.operations?.indexCreationOperation
  );
  const qnaEntriesUpdatingOperationPercent = getOperationProgressPercent(
    instance?.status?.operations?.qnaEntriesUpdatingOperation
  );
  const reportGenerationOperationPercent = getOperationProgressPercent(
    instance?.status?.operations?.reportGenerationOperation
  );

  let type: 'success' | 'info' | 'warning' | 'error' = 'info';
  let message = 'Идет публикация...';

  if (!instance) {
    type = 'warning';
    message = 'Публикация еще не запускалась';
  } else if (
    parsingOperationPercent === 100 &&
    indexCreationOperationPercent === 100 &&
    qnaEntriesUpdatingOperationPercent === 100 &&
    reportGenerationOperationPercent === 100
  ) {
    type = 'success';
    message = 'Версия опубликована успешно';
  }

  const current =
    parsingOperationPercent < 100
      ? 0
      : indexCreationOperationPercent < 100
      ? 1
      : qnaEntriesUpdatingOperationPercent < 100
      ? 2
      : reportGenerationOperationPercent < 100
      ? 3
      : 4;

  const getIcon = (step: number) => {
    if (!instance && current > 0) {
      return null;
    }
    return current === step ? <LoadingOutlined /> : null;
  };

  const getStatus = (step: number): 'finish' | 'process' | 'wait' => {
    if (current > step) return 'finish';
    if (current < step) return 'wait';
    if (!instance && current > 0) return 'wait';
    return 'process';
  };

  const description = detailed ? (
    <Steps direction="horizontal" size="small">
      <Step
        description={`${parsingOperationPercent} %`}
        icon={getIcon(0)}
        status={getStatus(0)}
        title="Разбор исходного файла"
      />
      <Step
        description={`${indexCreationOperationPercent} %`}
        icon={getIcon(1)}
        status={getStatus(1)}
        title="Создание индекса"
      />
      <Step
        description={`${qnaEntriesUpdatingOperationPercent} %`}
        icon={getIcon(2)}
        status={getStatus(2)}
        title="Загрузка данных"
      />
      <Step
        description={`${reportGenerationOperationPercent} %`}
        icon={getIcon(3)}
        status={getStatus(3)}
        title="Формирование отчета"
      />
    </Steps>
  ) : (
    ''
  );

  return <Alert showIcon description={description} message={message} type={type} />;
};

export default PublishStatusView;
