import React from 'react';
import { Pagination, PaginationProps } from 'antd';

import './index.less';

type ISbPaginationProps = PaginationProps;

const SbPagination: React.FC<ISbPaginationProps> = ({ ...otherProps }) => {
  return <Pagination className="sb-pagination" {...otherProps} />;
};

export default SbPagination;
