import React from 'react';

const MAIN_CLASS_NAME = 'sb-dialogs-card__content__panel-container__messages';
const BUTTONS_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__buttons-container`;
const BUTTON_CLASS_NAME = `${BUTTONS_CONTAINER_CLASS_NAME}__item`;

interface ISuggestedActionsProps {
  items: string[];
  selectedItem?: string | null;
}

const SuggestedActions: React.FC<ISuggestedActionsProps> = ({ items, selectedItem }) => {
  return (
    <div className={BUTTONS_CONTAINER_CLASS_NAME}>
      {items.map((m) => (
        <div key={m} className={`${BUTTON_CLASS_NAME} ${selectedItem == m ? `${BUTTON_CLASS_NAME}_selected` : ''}`}>
          <div className={`${BUTTON_CLASS_NAME}__text`}>{m}</div>
        </div>
      ))}
    </div>
  );
};

export default SuggestedActions;
