import React from 'react';

import { ScenarioCancellationMode, ScenarioInterruptionMode } from '../../../../api';
import {
  SUBSTITUTE_DESCRIPTION,
  SUBSTITUTE_TAG_COLOR,
  INTERRUPTING_DESCRIPTION,
  INTERRUPTING_TAG_COLOR,
} from '../../const';
import SbTag from '../common/SbTag';
import SbTooltip from '../common/SbTooltip';

interface ISbScenarioModeTagProps {
  interruption: ScenarioInterruptionMode;
  cancellation: ScenarioCancellationMode;
}

const SbScenarioModeTag: React.FC<ISbScenarioModeTagProps> = ({ interruption, cancellation }) => {
  if (
    cancellation === ScenarioCancellationMode.CancelAll &&
    interruption === ScenarioInterruptionMode.InterruptActive
  ) {
    return (
      <SbTooltip placement="rightTop" title={SUBSTITUTE_DESCRIPTION}>
        <SbTag color={SUBSTITUTE_TAG_COLOR} sbSize="small" text="Замещающий" />
      </SbTooltip>
    );
  }

  if (interruption === ScenarioInterruptionMode.InterruptActive) {
    return (
      <SbTooltip placement="rightTop" title={INTERRUPTING_DESCRIPTION}>
        <SbTag color={INTERRUPTING_TAG_COLOR} sbSize="small" text="Сквозной" />
      </SbTooltip>
    );
  }

  return null;
};

export default SbScenarioModeTag;
