import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Popover, Select, Space, Spin, Typography, Upload } from 'antd';
import { FileExcelOutlined, FileWordOutlined, QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';

import {
  BinarySourceFormat,
  KnowledgeBaseEntryType,
  KnowledgeData,
  KnowledgeDataType,
  TemplateInfo,
} from '../../kb-api';
import { fileExtensionToBinarySourceFormat, getBase64Content, getFileExtension } from '../utils/fileUtil';
import { ALLOWED_KNOWLEDGEBASE_FILE_TYPES, FileExtensions } from '../constants';

const { TextArea } = Input;
const { Paragraph } = Typography;

const noneKnowledgeDataType = 'noneKnowledgeDataType';

export interface IKnowledgeCreatingFormData {
  name: string;
  description: string;
  searchParentsOnlyIfNoContext: boolean;
  knowledgeData?: KnowledgeData;
  type: KnowledgeBaseEntryType;
  templateInfo?: TemplateInfo;
}

export interface IKnowledgeCreatingFormProps {
  knowledgeDataRequired: boolean;
  showSearchParentsOnlyIfNoContextSetting: boolean;
  loading: boolean;
  onSubmit: (data: IKnowledgeCreatingFormData) => void;
  onCancel: () => void;
}

const KnowledgeCreatingForm: React.FC<IKnowledgeCreatingFormProps> = ({
  knowledgeDataRequired = false,
  showSearchParentsOnlyIfNoContextSetting = false,
  loading = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm();
  const [knowledgeDataType, setKnowledgeDataType] = useState<KnowledgeDataType>();
  const [file, setFile] = useState<string>();
  const [fileError, setFileError] = useState<string>();
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [format, setFormat] = useState<BinarySourceFormat>();

  const isUrlKnowledgeDataType = () =>
    knowledgeDataType === KnowledgeDataType.WebPageUrl ||
    knowledgeDataType === KnowledgeDataType.ResourceUrl ||
    knowledgeDataType === KnowledgeDataType.ElmaResourceUrl ||
    knowledgeDataType === KnowledgeDataType.GoogleDocUrl ||
    knowledgeDataType === KnowledgeDataType.GoogleSpreadSheetUrl;

  const formIsValid = () => {
    if (!knowledgeDataType && !knowledgeDataRequired) {
      return true;
    }
    if (knowledgeDataType === KnowledgeDataType.Binary) {
      if (fileError) {
        return false;
      }
      if (!file) {
        setFileError('Выберите файл');
        return false;
      }
      return true;
    }
    if (isUrlKnowledgeDataType()) {
      return true;
    }

    return false;
  };

  const onFinish = async () => {
    if (!formIsValid()) {
      return;
    }

    const knowledgeData =
      knowledgeDataType?.toString() === noneKnowledgeDataType
        ? undefined
        : ({
            type: knowledgeDataType,
            binaryData:
              knowledgeDataType === KnowledgeDataType.Binary
                ? {
                    data: file,
                    format,
                    name: fileList.length ? fileList[0].name : undefined,
                  }
                : undefined,
            url: isUrlKnowledgeDataType() ? form.getFieldValue('url') : undefined,
            editionId: undefined,
          } as KnowledgeData);

    const data: IKnowledgeCreatingFormData = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      searchParentsOnlyIfNoContext: form.getFieldValue('searchParentsOnlyIfNoContext'),
      type: KnowledgeBaseEntryType.Default,
      knowledgeData,
    };

    onSubmit(data);
  };

  const clearFileUploadData = () => {
    setFile(undefined);
    setFileError(undefined);
    setFileList([]);
    setFormat(undefined);
  };

  const onChangeKnowledgeDataType = (value: KnowledgeDataType) => {
    if (knowledgeDataType === value) {
      return;
    }
    setKnowledgeDataType(value);

    clearFileUploadData();
  };

  const beforeUpload = async (file: RcFile) => {
    const extension = getFileExtension(file.name) as FileExtensions;
    if (!ALLOWED_KNOWLEDGEBASE_FILE_TYPES.includes(extension)) {
      setFileError('Не поддерживаемый тип файла. Выберите .csv, .xls, .xlsx или .docx файл.');
      return Upload.LIST_IGNORE;
    }
    const binarySourceFormat = fileExtensionToBinarySourceFormat(extension);
    setFormat(binarySourceFormat);

    const binaryContent = await getBase64Content(file);
    setFile(binaryContent);

    setFileList([file]);
    setFileError(undefined);
    return false;
  };

  const onRemove = () => {
    clearFileUploadData();
  };

  const renderFileUploadField = () => (
    <Form.Item help={fileError} name="file" validateStatus={fileError ? 'error' : undefined}>
      <Paragraph>
        <Space>
          <span>Примеры файлов для загрузки:</span>
          <a download href={`${process.env.PUBLIC_URL}/files/Вопросы.xlsx`}>
            <FileExcelOutlined /> Вопросы.xlsx
          </a>
          <a download href={`${process.env.PUBLIC_URL}/files/С_уточнением.docx`}>
            <FileWordOutlined /> С уточнением.docx
          </a>
        </Space>
      </Paragraph>
      <Upload
        accept={ALLOWED_KNOWLEDGEBASE_FILE_TYPES.join(',')}
        beforeUpload={beforeUpload}
        fileList={fileList}
        name="file"
        onRemove={onRemove}
      >
        <Button icon={<UploadOutlined />}>Выберите файл</Button>
      </Upload>
    </Form.Item>
  );

  const renderUrlField = () => {
    const placeholder =
      knowledgeDataType === KnowledgeDataType.WebPageUrl
        ? 'Ссылка на веб-страницу'
        : knowledgeDataType === KnowledgeDataType.ResourceUrl
        ? 'Ссылка на ресурс'
        : knowledgeDataType === KnowledgeDataType.ElmaResourceUrl
        ? 'Ссылка на ресурс ELMA'
        : knowledgeDataType === KnowledgeDataType.GoogleDocUrl
        ? 'Ссылка на Google Doc'
        : knowledgeDataType === KnowledgeDataType.GoogleSpreadSheetUrl
        ? 'Ссылка на Google SpreadSheet'
        : '';

    const urlExample =
      knowledgeDataType === KnowledgeDataType.GoogleDocUrl ? (
        <Paragraph>
          <span>Пример Google Doc ссылки:</span>
          <Button
            href="https://docs.google.com/document/d/1W57lrd26e-Ur92Gych7FawJUJDyoWG2DHbiOvF6qcSE"
            target="_blank"
            type="link"
          >
            https://docs.google.com/document/d/1W57lrd26e-Ur92Gych7FawJUJDyoWG2DHbiOvF6qcSE
          </Button>
        </Paragraph>
      ) : knowledgeDataType === KnowledgeDataType.GoogleSpreadSheetUrl ? (
        <Paragraph>
          <span>Пример Google SpreadSheet ссылки:</span>
          <Button
            href="https://docs.google.com/spreadsheets/d/1UUWHE4-Gj8YjmI12rygxn-pW76QrE3DCFEB2VzwsP-k"
            target="_blank"
            type="link"
          >
            https://docs.google.com/spreadsheets/d/1UUWHE4-Gj8YjmI12rygxn-pW76QrE3DCFEB2VzwsP-k
          </Button>
        </Paragraph>
      ) : null;

    return (
      <Form.Item
        extra={urlExample}
        label="Url"
        name="url"
        rules={[
          { required: true, message: 'Введите url' },
          { type: 'url', message: 'Не допустимая ссылка' },
        ]}
      >
        <Input placeholder={placeholder} />
      </Form.Item>
    );
  };

  return (
    <div>
      <Form form={form} initialValues={{ searchParentsOnlyIfNoContext: false }} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <Input placeholder="Имя" />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <TextArea placeholder="Назначение" rows={4} />
        </Form.Item>
        <Form.Item
          label="Источник данных"
          name="dataSource"
          rules={[{ required: knowledgeDataRequired, message: 'Выберите источник данных' }]}
        >
          <Select placeholder="Выберите источник данных" value={knowledgeDataType} onChange={onChangeKnowledgeDataType}>
            {!knowledgeDataRequired && <Select.Option value={noneKnowledgeDataType}>-</Select.Option>}
            <Select.Option value={KnowledgeDataType.Binary}>Файл</Select.Option>
            {/*<Select.Option value={KnowledgeDataType.Reference}>*/}
            {/*  Ссылка на редакцию*/}
            {/*</Select.Option>*/}
            <Select.Option value={KnowledgeDataType.WebPageUrl}>Ссылка на веб-страницу</Select.Option>
            <Select.Option value={KnowledgeDataType.ResourceUrl}>Ссылка на ресурс</Select.Option>
            <Select.Option value={KnowledgeDataType.ElmaResourceUrl}>Ссылка на ресурс ELMA</Select.Option>
            <Select.Option value={KnowledgeDataType.GoogleDocUrl}>Ссылка на Google Doc</Select.Option>
            <Select.Option value={KnowledgeDataType.GoogleSpreadSheetUrl}>Ссылка на Google SpreadSheet</Select.Option>
          </Select>
        </Form.Item>
        {knowledgeDataType === KnowledgeDataType.Binary && renderFileUploadField()}
        {isUrlKnowledgeDataType() && renderUrlField()}
        {showSearchParentsOnlyIfNoContextSetting && (
          <Form.Item
            help="Откл. - поиск будет осуществляться по всем вопросам. Вкл. - только по корневым вопросам, уточняющие вопросы при запросе без контекста показываться не будут."
            name="searchParentsOnlyIfNoContext"
            valuePropName="checked"
          >
            <Checkbox>
              <Space>
                Поиск без уточняющих вопросов (только по корневым)
                <Popover
                  content="Откл. - поиск будет осуществляться по всем вопросам. Вкл. - только по корневым вопросам, уточняющие вопросы при запросе без контекста показываться не будут."
                  overlayStyle={{ maxWidth: 410 }}
                  placement="right"
                  title="Поиск по уточняющим вопросам"
                >
                  <QuestionCircleFilled />
                </Popover>
              </Space>
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item style={{ marginTop: 20 }}>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button disabled={loading} onClick={onCancel}>
              Отмена
            </Button>
            {loading && <Spin />}
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default KnowledgeCreatingForm;
