import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import stc from 'string-to-color';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Table } from 'antd';

import './index.less';

import { ScenarioStartsPerDayModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';
import { onlyUniqueScenarioEvent, scenarioEventCountModelsAreEqual } from '../../../utils/analytics';

const CHART_SETTINGS = {
  stackId: 'stackId',
  barColors: {
    otherScenarios: '#CECECE',
  },
};

const getOtherScenarioStartsCount = (e: ScenarioStartsPerDayModel) =>
  e.totalScenarioStartCount - e.topScenarioStarts.reduce((partialSum, a) => partialSum + a.eventCount, 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as ScenarioStartsPerDayModel;
  if (data.totalScenarioStartCount <= 0) {
    return null;
  }

  const otherScenarioStartsCount = getOtherScenarioStartsCount(data);

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        <div>Топ сценариев:</div>
        <ul>
          {data.topScenarioStarts.map((e, index) =>
            e.eventCount > 0 ? (
              <li key={index}>
                <i>{e.scenarioName}</i>:&nbsp;<b>{e.eventCount}</b>
              </li>
            ) : null
          )}
          {otherScenarioStartsCount > 0 && (
            <li>
              <i>Другие сценарии</i>:&nbsp;<b>{otherScenarioStartsCount}</b>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

interface ISbScenarioStartsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbScenarioStartsAnalytics: React.FC<ISbScenarioStartsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ScenarioStartsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await analyticsReportsApi.getScenarioStartsPerDayList(
        agentStageId,
        filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse),
        filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse)
      );
      setData(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по стартам сценариев',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const uniqueScenarios = data.flatMap((dataItem) => dataItem.topScenarioStarts).filter(onlyUniqueScenarioEvent);

  const otherScenarioStartsExists = !!data.map(getOtherScenarioStartsCount).find((count) => count > 0);

  return (
    <div className="sb-scenario-starts-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Старты сценариев</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата \\ Сценарий',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                ...uniqueScenarios.map((s) => {
                  return {
                    title: s.scenarioName,
                    key: 'eventCount',
                    render: (_: unknown, record: ScenarioStartsPerDayModel) => {
                      const item = record.topScenarioStarts.find((e) => scenarioEventCountModelsAreEqual(e, s));
                      return item ? item.eventCount : 0;
                    },
                  };
                }),
                ...(otherScenarioStartsExists
                  ? [
                      {
                        title: 'Другие сценарии',
                        key: 'other',
                        render: (_: unknown, record: ScenarioStartsPerDayModel) => getOtherScenarioStartsCount(record),
                      },
                    ]
                  : []),
              ]}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis dataKey={(e: ScenarioStartsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                {uniqueScenarios.map((scenarioEvent) => {
                  const dataKey = (e: ScenarioStartsPerDayModel) => {
                    const ss = e.topScenarioStarts.find((ss) => scenarioEventCountModelsAreEqual(ss, scenarioEvent));
                    return ss ? ss.eventCount : 0;
                  };
                  return (
                    <Bar
                      key={`${scenarioEvent.scenarioName}-${scenarioEvent.scenarioStructureId}`}
                      dataKey={dataKey}
                      fill={stc(scenarioEvent.scenarioName)}
                      isAnimationActive={isAnimationActive}
                      name={scenarioEvent.scenarioName}
                      stackId={CHART_SETTINGS.stackId}
                    />
                  );
                })}
                {otherScenarioStartsExists && (
                  <Bar
                    dataKey={(e: ScenarioStartsPerDayModel) => getOtherScenarioStartsCount(e)}
                    fill={CHART_SETTINGS.barColors.otherScenarios}
                    isAnimationActive={isAnimationActive}
                    name="Другие сценарии"
                    stackId={CHART_SETTINGS.stackId}
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbScenarioStartsAnalytics;
