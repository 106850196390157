import React from 'react';
import { Row, Col, List, Switch } from 'antd';

import { AgentStageAccountModel } from '../../../api';
import { getChannelName } from '../../utils/stringUtil';

export interface IAgentAccountListItemProps {
  account: AgentStageAccountModel;
  accounts: AgentStageAccountModel[];
  onAccountsChange: (accounts: AgentStageAccountModel[]) => void;
}

const AgentAccountListItem: React.FC<IAgentAccountListItemProps> = ({
  account,
  accounts,
  onAccountsChange = () => {
    /*empty*/
  },
}) => {
  const onSwitchChange = (value: boolean) => {
    if (value) {
      onAccountsChange([...accounts, account]);
    } else {
      onAccountsChange(accounts.filter((a) => a.id !== account.id));
    }
  };

  const checked = accounts.findIndex((a) => a.id === account.id) > -1;

  return (
    <List.Item>
      <Row align="middle" justify="space-between" style={{ width: '100%' }}>
        <Col span={24}>
          <p>{getChannelName(account.channelId, account.externalName, account.displayName)}: </p>
          <a href={account.redirectUrl}>{account.botUri}</a>
          <Switch checked={checked} style={{ marginLeft: 24 }} onChange={onSwitchChange} />
        </Col>
      </Row>
    </List.Item>
  );
};

export default AgentAccountListItem;
