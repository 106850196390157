import React from 'react';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useRecoilValue } from 'recoil';

import { selectedEntitySelector, zoomSelector } from '../../../../recoil/scenarioStructure';

interface IPlaceholderProps {
  provided: DroppableProvided;
  snapshot: DroppableStateSnapshot;
}

const Placeholder: React.FC<IPlaceholderProps> = ({ provided, snapshot }) => {
  const selectedEntity = useRecoilValue(selectedEntitySelector);
  const zoom = useRecoilValue(zoomSelector);

  const height = selectedEntity ? document.getElementById(selectedEntity.id)?.getBoundingClientRect().height || 0 : 0;
  return (
    <div
      className="placeholder-container"
      style={{
        height: snapshot.isDraggingOver && snapshot.isUsingPlaceholder ? height / zoom : 0,
      }}
    >
      {provided.placeholder /* NOTE: библиотека драгндропа ищет этот placeholder и кидает warning, если не находит */}
    </div>
  );
};

export default Placeholder;
