import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { RcFile } from 'antd/lib/upload';
import { useAsync } from 'react-async-hook';
import Skeleton from 'react-loading-skeleton';

import './index.less';

import IbTypography from '../../../components/common/IbTypography';
import IbButton from '../../../components/common/IbButton';
import IbIcon from '../../../components/common/IbIcon';
import { botApi, botEditionApi, botStageApi } from '../../../../apis';
import { BotContentFormat, BotStageModel, BotTrainingModelModel, SingleBotModel } from '../../../../../api';
import { inboxAlertsSelectorAdd } from '../../../recoil';
import { AlertTypes, ALLOWED_IMPORT_BOT_FILE_TYPES, BOT_EDITION_EXPORT_FINISHED } from '../../../../constants';
import SettingsModal from '../../../../simple-bot/pages/bots/SimpleBotCard/SettingsModal';
import SbBotVersionsModal from '../../../../simple-bot/components/SbBotVersionsModal';
import SbUpload from '../../../../simple-bot/components/common/SbUpload';
import IbWebChatButton from '../../../components/IbWebChatButton';
import { hubConnections } from '../../../../utils/socketsUtil';
import IbProgressStatusModal, { IbProgressStatusModalStatus } from '../../../components/IbProgressStatusModal';
import { getErrorMessage, isInvalidCastError } from '../../../../utils/errorUtils';

import BotCards from './BotCards';

const MAIN_CLASS_NAME = 'ib-bot-page';
const TOP_BAR_CLASS_NAME = `${MAIN_CLASS_NAME}__top-bar`;
const TOP_BAR_TITLE_CLASS_NAME = `${TOP_BAR_CLASS_NAME}__title`;
const TOP_BAR_TITLE_LINK_CLASS_NAME = `${TOP_BAR_TITLE_CLASS_NAME}__link`;
const TOP_BAR_BUTTONS_CLASS_NAME = `${TOP_BAR_CLASS_NAME}__buttons`;
const TOP_BAR_BUTTONS_UPLOAD_CLASS_NAME = `${TOP_BAR_BUTTONS_CLASS_NAME}__upload`;
const TOP_BAR_MOBILE_CLASS_NAME = `${TOP_BAR_CLASS_NAME}_mobile`;
const TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME = `${TOP_BAR_MOBILE_CLASS_NAME}__title-row`;
const TOP_BAR_MOBILE_ACTIONS_ROW_CLASS_NAME = `${TOP_BAR_MOBILE_CLASS_NAME}__actions-row`;
const BACK_BUTTON_CLASS_NAME = `${TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}__back-button`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;

interface IBotEditionExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

interface IBotEditionImport {
  processing: boolean;
  errorMessage: string;
}

const botEditionExportDefaultValue: IBotEditionExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

const botEditionImportDefaultValue: IBotEditionImport = {
  processing: false,
  errorMessage: '',
};

const BotPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { push } = useHistory();
  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const addAlert = useSetRecoilState(inboxAlertsSelectorAdd);

  const [botLoading, setBotLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [bot, setBot] = useState<SingleBotModel>();
  const [settingsModalVisible, setSettingsModalVisible] = useState(false);
  const [botVersionsModalVisible, setBotVersionsModalVisible] = useState(false);

  const [botEditionExport, setBotEditionExport] = useState(botEditionExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);
  const [botEditionImport, setBotEditionImport] = useState(botEditionImportDefaultValue);
  const [importModalVisible, setImportModalVisible] = useState(false);

  const [trainingModels, setTrainingModels] = useState<BotTrainingModelModel[]>([]);

  const botStage = bot?.originStage;
  const botCurrentEdition = bot?.originCurrentEdition;

  const loading = botLoading || dataLoading;

  const exportStatus = botEditionExport.preparing
    ? IbProgressStatusModalStatus.InProgress
    : botEditionExport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const exportTitle = botEditionExport.preparing
    ? t('Bot export in progress')
    : botEditionExport.errorMessage
    ? t('An error occurred while exporting the bot')
    : t('Bot export was successful');

  const importStatus = botEditionImport.processing
    ? IbProgressStatusModalStatus.InProgress
    : botEditionImport.errorMessage
    ? IbProgressStatusModalStatus.Error
    : IbProgressStatusModalStatus.Success;
  const importTitle = botEditionImport.processing
    ? t('Bot import in progress')
    : botEditionImport.errorMessage
    ? t('An error occurred while importing the bot')
    : t('Bot import was successful');

  const loadBotAsync = async () => {
    try {
      const botResponse = await botApi.getBot(id);
      setBot(botResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadAdditionalDataAsync = async () => {
    try {
      const trainingModelsResponse = await botApi.getBotTrainingModels();
      setTrainingModels(trainingModelsResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
  };

  const loadAllDataAsync = async () => {
    if (!id) return;
    setDataLoading(true);
    try {
      await loadAdditionalDataAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setDataLoading(false);
  };

  const loadAllData = () => {
    loadAllDataAsync().finally();
  };
  useEffect(loadAllData, []);

  const loadBotDataAsync = async () => {
    if (!id) return;
    setBotLoading(true);
    try {
      await loadBotAsync();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        content: t('Data downloading error'),
      });
    }
    setBotLoading(false);
  };

  const loadBotData = () => {
    loadBotDataAsync().finally();
  };
  useEffect(loadBotData, [id]);

  const onDataChanged = async () => {
    await loadBotDataAsync();
  };

  const goToBotsList = () => {
    push('/inbox/bots/');
  };

  const onSettingsButtonClick = () => !loading && setSettingsModalVisible(true);
  const onSettingsModalClose = () => setSettingsModalVisible(false);

  const onShowBotVersions = () => setBotVersionsModalVisible(true);
  const onBotVersionsModalClose = () => setBotVersionsModalVisible(false);

  const onBotVersionsModalDraftStageAdded = async () => {
    await loadBotDataAsync();
    setBotVersionsModalVisible(false);
  };

  const closeExportBotModal = () => {
    setExportModalVisible(false);
    setBotEditionExport(botEditionExportDefaultValue);
  };

  const closeImportBotModal = () => {
    setImportModalVisible(false);
  };

  const onExport = (botStage: BotStageModel) => async () => {
    if (!botStage) return;

    setBotEditionExport({
      ...botEditionExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await botEditionApi.runBotEditionExport(
        botStage.currentEdition.botEditionId,
        BotContentFormat.Json
      );

      setBotEditionExport({
        ...botEditionExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      setBotEditionExport({
        ...botEditionExportDefaultValue,
        errorMessage: getErrorMessage(e as Error),
      });
    }
  };

  const botEditionExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (botEditionExport.requestId !== args?.requestId || !botEditionExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setBotEditionExport({
      ...botEditionExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToBotEditionExportEvents = () => {
    if (!botEditionExport.requestId || !conn) return;

    conn.on(BOT_EDITION_EXPORT_FINISHED, botEditionExportEventHandler);

    return () => {
      conn.off(BOT_EDITION_EXPORT_FINISHED, botEditionExportEventHandler);
    };
  };
  useEffect(subscribeToBotEditionExportEvents, [conn, botEditionExport.requestId]);

  const onExportModalClose = () => closeExportBotModal();

  const onImportModalClose = () => {
    closeImportBotModal();
    if (importStatus == IbProgressStatusModalStatus.Success) {
      loadBotData();
      loadAllData();
    }
  };

  const onImportBotFileUpload = (botStage?: BotStageModel) => async (file: RcFile, base64Content: string) => {
    if (!botStage) return;

    try {
      await botStageApi.importBot(botStage.id, {
        botFile: {
          fileName: file.name,
          mimeType: file.type,
          content: base64Content,
        },
      });
      setBotEditionImport(botEditionImportDefaultValue);
    } catch (e) {
      const message = isInvalidCastError(e as Error)
        ? t('Uploaded file is not a bot description file')
        : getErrorMessage(e as Error);

      setBotEditionImport({
        processing: false,
        errorMessage: message,
      });
    }
  };

  const beforeImportBotFileUpload = () => {
    setBotEditionImport({
      ...botEditionImportDefaultValue,
      processing: true,
    });
    setImportModalVisible(true);
  };

  const renderTopBar = () => {
    const actions = [
      {
        onSelect: onShowBotVersions,
        icon: '',
        text: t('Version history'),
      },
      {
        onSelect: () => {
          if (bot?.originStage) {
            onExport(bot?.originStage)();
          }
        },
        icon: '',
        text: t('Export'),
      },
      {
        onSelect: () => {},
        icon: '',
        text: (
          <SbUpload
            accept={ALLOWED_IMPORT_BOT_FILE_TYPES.join(',')}
            beforeUpload={beforeImportBotFileUpload}
            className={TOP_BAR_BUTTONS_UPLOAD_CLASS_NAME}
            onFileUpload={onImportBotFileUpload(bot?.originStage)}
          >
            {t('Import')}
          </SbUpload>
        ),
      },
    ];

    const renderBreadcrumbs = () => {
      return (
        <IbTypography>
          <h2>
            <span className={TOP_BAR_TITLE_LINK_CLASS_NAME} onClick={goToBotsList}>
              {t('Chat bots')}
            </span>
            <span>&nbsp;/&nbsp;</span>
            {!bot ? <Skeleton inline height={30} width={200} /> : <span>{bot.entry.name}</span>}
          </h2>
        </IbTypography>
      );
    };

    return (
      <div className={TOP_BAR_CLASS_NAME}>
        <div className={TOP_BAR_TITLE_CLASS_NAME}>{renderBreadcrumbs()}</div>
        <div className={TOP_BAR_BUTTONS_CLASS_NAME}>
          <IbButton
            actions={actions}
            icon={<IbIcon iconName="setting-two" />}
            type="secondary"
            onClick={onSettingsButtonClick}
          >
            {t('Settings')}
          </IbButton>
          <IbWebChatButton botName={bot?.entry.name} botStage={botStage} />
        </div>
      </div>
    );
  };

  const renderTopBarMobile = () => {
    const onGoBack = () => goToBotsList();

    return (
      <div className={TOP_BAR_MOBILE_CLASS_NAME}>
        <div className={TOP_BAR_MOBILE_TITLE_ROW_CLASS_NAME}>
          <IbButton
            className={BACK_BUTTON_CLASS_NAME}
            icon={<IbIcon iconName="arrow-left" />}
            type="icon"
            onClick={onGoBack}
          />
          <IbTypography>
            <h4>{bot?.entry.name}</h4>
          </IbTypography>
        </div>
        <div className={TOP_BAR_MOBILE_ACTIONS_ROW_CLASS_NAME}>
          <IbButton icon={<IbIcon iconName="setting-two" />} type="link" onClick={onSettingsButtonClick}>
            {t('Settings')}
          </IbButton>
          <IbWebChatButton botName={bot?.entry.name} botStage={botStage} />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={CONTENT_CLASS_NAME}>
        <BotCards bot={bot} onDataChanged={onDataChanged} />
      </div>
    );
  };

  const renderBotEditionExportModalContent = () => {
    switch (exportStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Success:
        return (
          <IbTypography>
            <IbTypography.Paragraph>
              <a href={botEditionExport.fileUrl}>{t('Download link')}</a>
            </IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{botEditionExport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  const renderBotEditionImportModalContent = () => {
    switch (importStatus) {
      case IbProgressStatusModalStatus.InProgress:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{t('This may take some time')}</IbTypography.Paragraph>
            <IbTypography.Paragraph>{t('Please wait')}</IbTypography.Paragraph>
          </IbTypography>
        );
      case IbProgressStatusModalStatus.Error:
        return (
          <IbTypography>
            <IbTypography.Paragraph>{botEditionImport.errorMessage}</IbTypography.Paragraph>
          </IbTypography>
        );
    }
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderTopBar()}
      {renderTopBarMobile()}
      {renderContent()}
      {botStage && botCurrentEdition && (
        <SettingsModal
          botEdition={botCurrentEdition}
          botStageId={botStage?.id}
          trainingModels={trainingModels}
          visible={settingsModalVisible}
          onClose={onSettingsModalClose}
          onDataChanged={onDataChanged}
        />
      )}
      {bot?.entry.id && (
        <SbBotVersionsModal
          botId={bot.entry.id}
          visible={botVersionsModalVisible}
          onClose={onBotVersionsModalClose}
          onDraftStageAdded={onBotVersionsModalDraftStageAdded}
        />
      )}
      <IbProgressStatusModal
        header={exportTitle}
        status={exportStatus}
        visible={exportModalVisible}
        onCancel={onExportModalClose}
      >
        {renderBotEditionExportModalContent()}
      </IbProgressStatusModal>
      <IbProgressStatusModal
        closable={importStatus != IbProgressStatusModalStatus.InProgress}
        header={importTitle}
        status={importStatus}
        visible={importModalVisible}
        onCancel={onImportModalClose}
      >
        {renderBotEditionImportModalContent()}
      </IbProgressStatusModal>
    </div>
  );
};

export default BotPage;
