import React from 'react';
import { useRecoilValue } from 'recoil';

import './index.less';

import SbRadioButton from '../../../../simple-bot/components/common/SbRadioButton';
import SbRadioButtonGroup from '../../../../simple-bot/components/common/SbRadioButtonGroup';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import { dispatcherState } from '../../../../recoil/scenarioStructure';
import { ELMA365_HELP_URL } from '../../../../simple-bot/const';

interface ControlsProps {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onAdd: () => void;
}

const Controls: React.FC<ControlsProps> = ({ onZoomIn, onZoomOut, onAdd }) => {
  const { resetWorkingSpaceTransform } = useRecoilValue(dispatcherState);

  const onAddButtonClick = () => onAdd?.();

  const onPlusButtonClick = () => onZoomIn?.();

  const onMinusButtonClick = () => onZoomOut?.();

  const onNormalizeButtonClick = () => resetWorkingSpaceTransform();

  const onHelpButtonClick = () => {
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(ELMA365_HELP_URL, '_blank');
  };

  return (
    <div className="scenario-editor-controls">
      <SbRadioButtonGroup>
        <SbRadioButton onClick={onAddButtonClick}>
          <SbIcon iconName="add-one" size={20} />
        </SbRadioButton>
      </SbRadioButtonGroup>
      <SbRadioButtonGroup style={{ marginTop: 20 }}>
        <SbRadioButton onClick={onPlusButtonClick}>
          <SbIcon iconName="plus" size={20} />
        </SbRadioButton>
        <SbRadioButton onClick={onMinusButtonClick}>
          <SbIcon iconName="minus" size={20} />
        </SbRadioButton>
      </SbRadioButtonGroup>
      <SbRadioButtonGroup style={{ marginTop: 20 }}>
        <SbRadioButton onClick={onNormalizeButtonClick}>
          <SbIcon iconName="click-to-fold" size={20} />
        </SbRadioButton>
      </SbRadioButtonGroup>
      <SbRadioButtonGroup style={{ marginTop: 20 }}>
        <SbRadioButton onClick={onHelpButtonClick}>
          <SbIcon iconName="help" size={20} />
        </SbRadioButton>
      </SbRadioButtonGroup>
    </div>
  );
};

export default Controls;
