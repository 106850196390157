import React, { useState } from 'react';
import { HexColorInput, HexAlphaColorPicker } from 'react-colorful';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';
import IbPopover from '../IbPopover';
import { equalsIgnoreCase, isLightColor } from '../../../../utils/stringUtil';

const ADDITIONAL_COLOR_BLOCK_KEY = 'additional';

const COLOR_LIST_DEFAULT = [] as string[];
const COLOR_DEFAULT = undefined;
const ENABLE_CUSTOM_COLOR_DEFAULT = false;

const MAIN_CLASS_NAME = 'ib-color-picker';
const SELECTED_CLASS_NAME = `${MAIN_CLASS_NAME}__selected`;
const COLOR_BLOCK_CLASS_NAME = `${MAIN_CLASS_NAME}__color-block`;
const IS_LIGHT_CLASS_NAME = `${MAIN_CLASS_NAME}__is-light`;
const CUSTOM_CLASS_NAME = `${MAIN_CLASS_NAME}__custom`;
const DETECTION_CLASS_NAME = `${MAIN_CLASS_NAME}__detection`;
const DETECTION_ICON_WRAPPER_CLASS_NAME = `${DETECTION_CLASS_NAME}__icon-wrapper`;
const POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__popover`;

export interface IIbColorPickerProps {
  colorList: string[];
  color?: string;
  enableCustomColor?: boolean;
  onChange?: (color: string) => void;
}

const IbColorPicker: React.FC<IIbColorPickerProps> = ({
  colorList = COLOR_LIST_DEFAULT,
  color = COLOR_DEFAULT,
  enableCustomColor = ENABLE_CUSTOM_COLOR_DEFAULT,
  onChange,
}) => {
  colorList = getDefaultIfUndefined(colorList, COLOR_LIST_DEFAULT);
  color = getDefaultIfUndefined(color, COLOR_DEFAULT);

  const [additionalColor, setAdditionalColor] = useState<string | undefined>(
    colorList.some((c) => equalsIgnoreCase(c, color || '')) ? undefined : color
  );

  const classes = [MAIN_CLASS_NAME];

  const onColorBlockClick = (color: string) => () => onChange?.(color);

  const onPopoverColorChange = (newColor: string) => {
    onChange?.(newColor);

    if (!colorList.some((c) => equalsIgnoreCase(c, newColor))) {
      setAdditionalColor(newColor);
    }
  };

  const renderPopoverContent = () => (
    <div className={POPOVER_CLASS_NAME}>
      <HexAlphaColorPicker color={color} onChange={onPopoverColorChange} />
      <HexColorInput alpha prefixed color={color} onChange={onPopoverColorChange} />
    </div>
  );

  const renderColorBlock = (key: string | number, blockColor: string, onClick: () => void) => {
    const classes = [];
    equalsIgnoreCase(blockColor, color || '') && classes.push(SELECTED_CLASS_NAME);
    isLightColor(blockColor) && classes.push(IS_LIGHT_CLASS_NAME);
    key === ADDITIONAL_COLOR_BLOCK_KEY && classes.push(CUSTOM_CLASS_NAME);

    return (
      <li key={key} className={classes.join(' ')}>
        <div className={COLOR_BLOCK_CLASS_NAME} style={{ background: blockColor }} onClick={onClick}>
          <IbIcon iconName="check-one" />
        </div>
      </li>
    );
  };

  return (
    <ul className={classes.join(' ')}>
      {colorList.map((c, index) => renderColorBlock(index, c, onColorBlockClick(c)))}
      {additionalColor &&
        renderColorBlock(ADDITIONAL_COLOR_BLOCK_KEY, additionalColor, onColorBlockClick(additionalColor))}
      {enableCustomColor && (
        <li key="custom" className={DETECTION_CLASS_NAME}>
          <IbPopover content={renderPopoverContent()} placement="bottomLeft" trigger={['click']}>
            <div className={`${COLOR_BLOCK_CLASS_NAME} ${DETECTION_ICON_WRAPPER_CLASS_NAME}`}>
              <IbIcon iconName="detection" />
            </div>
          </IbPopover>
        </li>
      )}
    </ul>
  );
};

export default IbColorPicker;
