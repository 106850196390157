import React from 'react';

import './index.less';

type ISbBarProps = {
  value: number;
  maxValue: number;
  color?: string;
  className?: string;
};

const SbBar: React.FC<ISbBarProps> = ({ value, maxValue, color, className }) => {
  const classes = ['sb-bar'];
  if (className) classes.push(className);

  const percent = maxValue > 0 ? (value / maxValue) * 100 : 0;

  return (
    <div className={classes.join(' ')}>
      <div className="sb-bar__frame" />
      <div className="sb-bar__bar" style={{ width: `${percent}%`, background: color }} />
    </div>
  );
};

export default SbBar;
