import React from 'react';
import { Radio, RadioProps } from 'antd';

import './index.less';

const MAIN_CLASS_NAME = 'ib-radio';

export type IIbRadioProps = RadioProps;

const IbRadio: React.FC<IIbRadioProps> = ({ className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <Radio {...otherProps} className={classes.join(' ')} />;
};

export default IbRadio;
