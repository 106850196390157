import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import stc from 'string-to-color';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Table } from 'antd';

import './index.less';

import { MenuItemSelectionCountModel, MenuItemSelectionsPerDayModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';

const CHART_SETTINGS = {
  stackId: 'stackId',
  barColors: {
    otherMenuItems: '#CECECE',
  },
};

const menuItemSelectionCountModelsAreEqual = (a: MenuItemSelectionCountModel, b: MenuItemSelectionCountModel) =>
  a.name === b.name && a.mode === b.mode;
const onlyUnique = (s: MenuItemSelectionCountModel, i: number, array: MenuItemSelectionCountModel[]): boolean =>
  array.findIndex((a) => menuItemSelectionCountModelsAreEqual(s, a)) === i;

const getOtherMenuItemSelectionsCount = (e: MenuItemSelectionsPerDayModel) =>
  e.totalCount - e.topMenuItems.reduce((partialSum, a) => partialSum + a.count, 0);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as MenuItemSelectionsPerDayModel;
  if (data.totalCount <= 0) {
    return null;
  }

  const otherMenuItemsCount = getOtherMenuItemSelectionsCount(data);

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        <div>Топ кнопок меню:</div>
        <ul>
          {data.topMenuItems.map((e, index) => (
            <li key={index}>
              <i>{e.name}</i>:&nbsp;<b>{e.count}</b>
            </li>
          ))}
          {otherMenuItemsCount > 0 && (
            <li>
              <i>Другие кнопки меню</i>:&nbsp;<b>{otherMenuItemsCount}</b>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

interface ISbMenuItemSelectionsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbMenuItemSelectionsAnalytics: React.FC<ISbMenuItemSelectionsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MenuItemSelectionsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await analyticsReportsApi.getMenuItemSelectionsPerDayList(
        agentStageId,
        filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse),
        filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse)
      );
      setData(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по нажатиям кнопок меню',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const uniqueMenuItems = data.flatMap((dataItem) => dataItem.topMenuItems).filter(onlyUnique);

  const otherOtherMenuItemsSelectionsExists = !!data.map(getOtherMenuItemSelectionsCount).find((count) => count > 0);

  return (
    <div className="sb-menu-item-selections-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Нажатия кнопок меню</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата \\ Кнопка меню',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                ...uniqueMenuItems.map((mi) => {
                  return {
                    title: mi.name,
                    key: 'count',
                    render: (_: unknown, record: MenuItemSelectionsPerDayModel) => {
                      const item = record.topMenuItems.find((e) => menuItemSelectionCountModelsAreEqual(e, mi));
                      return item ? item.count : 0;
                    },
                  };
                }),
                ...(otherOtherMenuItemsSelectionsExists
                  ? [
                      {
                        title: 'Другие кнопки',
                        key: 'other',
                        render: (_: unknown, record: MenuItemSelectionsPerDayModel) =>
                          getOtherMenuItemSelectionsCount(record),
                      },
                    ]
                  : []),
              ]}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis
                  dataKey={(e: MenuItemSelectionsPerDayModel) => e.date}
                  tickFormatter={formatAnalyticsDateCompact}
                />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                {uniqueMenuItems.map((menuItem) => {
                  const dataKey = (e: MenuItemSelectionsPerDayModel) => {
                    const mis = e.topMenuItems.find((mis) => menuItemSelectionCountModelsAreEqual(mis, menuItem));
                    return mis ? mis.count : 0;
                  };
                  return (
                    <Bar
                      key={`${menuItem.name}-${menuItem.mode}`}
                      dataKey={dataKey}
                      fill={stc(menuItem.name)}
                      isAnimationActive={isAnimationActive}
                      name={menuItem.name}
                      stackId={CHART_SETTINGS.stackId}
                    />
                  );
                })}
                {otherOtherMenuItemsSelectionsExists ?? (
                  <Bar
                    dataKey={(e: MenuItemSelectionsPerDayModel) => getOtherMenuItemSelectionsCount(e)}
                    fill={CHART_SETTINGS.barColors.otherMenuItems}
                    isAnimationActive={isAnimationActive}
                    name="Другие кнопки"
                    stackId={CHART_SETTINGS.stackId}
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbMenuItemSelectionsAnalytics;
