import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import IbModal from '../common/IbModal';
import { TagCreationRequest, TagModel, TagStatus, TagUpdatingRequest } from '../../../../api';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import { EmptyLogo } from '../../assets';
import IbTypography from '../common/IbTypography';
import IbConfirmModal from '../common/IbConfirmModal';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbAlert, { IIbAlertProps } from '../common/IbAlert';
import IbSpin from '../common/IbSpin';
import { TAG_COLORS } from '../../../constants';

import {
  ALERT_CLOSING_TIMEOUT,
  CREATE_BUTTON_FOOTER_CLASS_NAME,
  DELETE_BUTTON_FOOTER_CLASS_NAME,
  EMPTY_CONTENT_CLASS_NAME,
  MAIN_CLASS_NAME,
} from './const';
import TagList from './TagList';
import TagEditForm from './TagEditForm';
import { validateTagEditForm } from './utils';

const VISIBLE_DEFAULT = false;
const TAG_LIST_DEFAULT = [] as TagModel[];

enum ModalStates {
  LIST = 'LIST',
  CREATION = 'CREATION',
  EDITING = 'EDITING',
}

export interface IIbTagsManagementModalProps {
  visible?: boolean;
  tagList?: TagModel[];
  onAdd?: (request: TagCreationRequest) => Promise<boolean>;
  onEdit?: (tagId: string, request: TagUpdatingRequest) => Promise<boolean>;
  onDelete?: (tagId: string) => Promise<boolean>;
  onCancel?: () => void;
}

const IbTagsManagementModal: React.FC<IIbTagsManagementModalProps> = ({
  visible = VISIBLE_DEFAULT,
  tagList = TAG_LIST_DEFAULT,
  onAdd,
  onEdit,
  onDelete,
  onCancel,
}) => {
  tagList = getDefaultIfUndefined(tagList, TAG_LIST_DEFAULT);
  visible = getDefaultIfUndefined(visible, VISIBLE_DEFAULT);

  const { t } = useTranslation();

  const [modalState, setModalState] = useState(ModalStates.LIST);
  const [saving, setSaving] = useState(false);
  const [editTag, setEditTag] = useState<TagModel>();
  const [deleteTag, setDeleteTag] = useState<TagModel>();
  const [showValidation, setShowValidation] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const [alert, setAlert] = useState<IIbAlertProps>();
  const [alertHidingTimer, setAlertHidingTimer] = useState<NodeJS.Timeout>();

  const tagListIsEmpty = !(tagList || []).length;
  const editFormValidationResult = validateTagEditForm(editTag);
  const editFormIsValid = editFormValidationResult.label.isValid;

  const showCreationForm = () => {
    setShowValidation(false);
    setEditTag({ id: '', label: '', color: TAG_COLORS[0], status: TagStatus.Visible, order: 0 });
    setModalState(ModalStates.CREATION);
  };

  const cancelEditForm = () => {
    setEditTag(undefined);
    setModalState(ModalStates.LIST);
    setShowValidation(false);
  };

  const showDeleteConfirmation = (tag: TagModel) => {
    setDeleteTag(tag);
    setDeleteConfirmVisible(true);
  };

  const cancelDeleteConfirmation = () => {
    setDeleteTag(undefined);
    setDeleteConfirmVisible(false);
  };

  const hideAlert = () => setAlert(undefined);

  const onSaveButtonClick = async () => {
    setShowValidation(true);

    if (!editTag || !editFormIsValid) {
      return;
    }

    setSaving(true);
    if (
      await onEdit?.(editTag.id, {
        label: editTag.label,
        description: editTag.description,
        color: editTag.color,
        status: editTag.status,
        order: editTag.order,
      })
    ) {
      setAlert({ type: 'success', onClose: hideAlert });
    } else {
      setAlert({ type: 'error', onClose: hideAlert });
    }
    setSaving(false);
    setModalState(ModalStates.LIST);
  };

  const onAddButtonClick = async () => {
    setShowValidation(true);

    if (!editTag || !editFormIsValid) {
      return;
    }

    setSaving(true);
    if (
      await onAdd?.({
        label: editTag.label,
        description: editTag.description,
        color: editTag.color,
        status: editTag.status,
        order: Math.round(Math.max(...[...tagList.map((tag) => tag.order), 0])) + 1,
      })
    ) {
      setAlert({ type: 'success', onClose: hideAlert });
    } else {
      setAlert({ type: 'error', onClose: hideAlert });
    }
    setSaving(false);
    setModalState(ModalStates.LIST);
  };

  const onTagListVisibilityToggle = async (tag: TagModel) => {
    setSaving(true);
    if (
      await onEdit?.(tag.id, {
        label: tag.label,
        description: tag.description,
        color: tag.color,
        status: tag.status === TagStatus.Hidden ? TagStatus.Visible : TagStatus.Hidden,
        order: tag.order,
      })
    ) {
      setAlert({ type: 'success', onClose: hideAlert });
    } else {
      setAlert({ type: 'error', onClose: hideAlert });
    }
    setSaving(false);
  };

  const onDeleteConfirmButtonClick = async () => {
    if (!deleteTag) {
      return;
    }

    setModalState(ModalStates.LIST);
    setDeleteConfirmVisible(false);

    setSaving(true);
    if (await onDelete?.(deleteTag?.id)) {
      setAlert({ type: 'success', onClose: hideAlert });
    } else {
      setAlert({ type: 'error', onClose: hideAlert });
    }
    setSaving(false);

    setDeleteTag(undefined);
    setEditTag(undefined);
  };

  const onDeleteButtonClick = () => editTag && showDeleteConfirmation(editTag);

  const onTagEditFormDelete = () => editTag && showDeleteConfirmation(editTag);

  const onTagListDelete = (tag: TagModel) => showDeleteConfirmation(tag);

  const onTagListEdit = (tag: TagModel) => {
    setShowValidation(false);
    setEditTag(tag);
    setModalState(ModalStates.EDITING);
  };

  const onTagListReorder = async (sourceIndex: number, destinationIndex: number) => {
    const sourceTag = tagList[sourceIndex];
    const destinationTag = tagList[destinationIndex];
    if (!sourceTag || !destinationTag || sourceTag.id === destinationTag.id) {
      return;
    }

    // todo: доработать для ситуации когда не будет хватать разрядности числа
    let newOrder = 0;
    if (sourceTag.order > destinationTag.order) {
      const beforeDestinationTag = tagList[destinationIndex - 1];
      newOrder = beforeDestinationTag
        ? (beforeDestinationTag.order + destinationTag.order) / 2
        : Math.round(destinationTag.order) - 1;
    } else {
      const nextDestinationTag = tagList[destinationIndex + 1];
      newOrder = nextDestinationTag
        ? (nextDestinationTag.order + destinationTag.order) / 2
        : Math.round(destinationTag.order) + 1;
    }

    setSaving(true);
    if (
      await onEdit?.(sourceTag.id, {
        label: sourceTag.label,
        description: sourceTag.description,
        color: sourceTag.color,
        status: sourceTag.status,
        order: newOrder,
      })
    ) {
      setAlert({ type: 'success', onClose: hideAlert });
    } else {
      setAlert({ type: 'error', onClose: hideAlert });
    }
    setSaving(false);
  };

  const onCreateButtonClick = () => showCreationForm();

  const onCancelCreation = () => cancelEditForm();

  const onCancelEditing = () => cancelEditForm();

  const onCancelDeleteConfirmation = () => cancelDeleteConfirmation();

  const onModalCancel = () => {
    switch (modalState) {
      case ModalStates.LIST:
        onCancel?.();
        break;
      case ModalStates.CREATION:
      case ModalStates.EDITING:
        cancelEditForm();
        break;
    }
  };

  const onVisiblePropChange = () => {
    if (!visible) {
      return;
    }

    setSaving(false);
    setModalState(ModalStates.LIST);
    setShowValidation(false);
    setEditTag(undefined);
    setDeleteTag(undefined);
  };
  useEffect(onVisiblePropChange, [visible]);

  const onAlertChange = () => {
    if (alert) {
      !!alertHidingTimer && clearTimeout(alertHidingTimer);
      setAlertHidingTimer(setTimeout(() => hideAlert(), ALERT_CLOSING_TIMEOUT));
    }
  };
  useEffect(onAlertChange, [alert]);

  const renderTitle = () => {
    switch (modalState) {
      case ModalStates.LIST:
        return t('Tags management');
      case ModalStates.CREATION:
        return t('Add tag');
      case ModalStates.EDITING:
        return t('Edit tag');
    }
  };

  const renderFooter = () => {
    switch (modalState) {
      case ModalStates.LIST:
        return tagListIsEmpty ? null : (
          <>
            <IbButton
              key="create-tag"
              className={CREATE_BUTTON_FOOTER_CLASS_NAME}
              type="link"
              onClick={onCreateButtonClick}
            >
              {t('+ Create new tag')}
            </IbButton>
            <IbButton key="create-tag-2" icon={<IbIcon iconName="add-one" />} type="fill" onClick={onCreateButtonClick}>
              {t('Create new tag')}
            </IbButton>
          </>
        );
      case ModalStates.CREATION:
        return (
          <>
            <IbButton key="add-tag" disabled={saving} onClick={onAddButtonClick}>
              {t('Add')}
            </IbButton>
            <IbButton key="cancel-creation" type="secondary" onClick={onCancelCreation}>
              {t('Cancel')}
            </IbButton>
            <IbButton key="cancel-creation-2" type="fill" onClick={onCancelCreation}>
              {t('Cancel (verb)')}
            </IbButton>
          </>
        );
      case ModalStates.EDITING:
        return (
          <>
            <IbButton key="save-tag" disabled={saving} onClick={onSaveButtonClick}>
              {t('Save')}
            </IbButton>
            <IbButton key="cancel-updating" type="secondary" onClick={onCancelEditing}>
              {t('Cancel')}
            </IbButton>
            <IbButton key="cancel-updating-2" type="fill" onClick={onCancelEditing}>
              {t('Cancel (verb)')}
            </IbButton>
            <IbButton
              key="delete-tag"
              className={DELETE_BUTTON_FOOTER_CLASS_NAME}
              icon={<IbIcon iconName="delete" />}
              type="link"
              onClick={onDeleteButtonClick}
            >
              {t('Delete')}
            </IbButton>
          </>
        );
    }
  };

  const renderContent = () => {
    switch (modalState) {
      case ModalStates.LIST:
        return tagListIsEmpty ? (
          <div className={EMPTY_CONTENT_CLASS_NAME}>
            <EmptyLogo />
            <IbTypography.Paragraph disabled type="secondary">
              {t('Here you can create and customize')}
              <br />
              {t('a custom tag set')}
            </IbTypography.Paragraph>
            <IbButton icon={<IbIcon iconName="add-one" />} type="fill" onClick={onCreateButtonClick}>
              {t('Create new tag')}
            </IbButton>
          </div>
        ) : (
          <TagList
            tagList={tagList}
            onDelete={onTagListDelete}
            onEdit={onTagListEdit}
            onReorder={onTagListReorder}
            onVisibilityToggle={onTagListVisibilityToggle}
          />
        );
      case ModalStates.CREATION:
        return editTag ? <TagEditForm showValidation={showValidation} tag={editTag} onEdit={setEditTag} /> : null;
      case ModalStates.EDITING:
        return editTag ? (
          <TagEditForm
            showValidation={showValidation}
            tag={editTag}
            onDelete={onTagEditFormDelete}
            onEdit={setEditTag}
          />
        ) : null;
    }
  };

  const renderAlert = () => {
    if (saving) {
      return (
        <IbAlert type="custom">
          <IbSpin />
        </IbAlert>
      );
    }

    if (alert) {
      return (
        <IbAlert {...alert}>
          {alert.type === 'success'
            ? t('Changes saved')
            : alert.type === 'error'
            ? t('An error occurred while saving changes')
            : null}
        </IbAlert>
      );
    }

    return null;
  };

  return (
    <>
      <IbModal
        className={MAIN_CLASS_NAME}
        footer={renderFooter()}
        title={renderTitle()}
        visible={visible}
        onCancel={onModalCancel}
      >
        {renderContent()}
        {renderAlert()}
      </IbModal>
      <IbConfirmModal
        footer={
          <>
            <IbButton key="delete-tag-confirm" disabled={saving} onClick={onDeleteConfirmButtonClick}>
              {t('Delete')}
            </IbButton>
            <IbButton key="cancel-delete-confirmation" type="secondary" onClick={onCancelDeleteConfirmation}>
              {t('Cancel')}
            </IbButton>
            <IbButton key="cancel-delete-confirmation-2" type="fill" onClick={onCancelDeleteConfirmation}>
              {t('Cancel (verb)')}
            </IbButton>
          </>
        }
        title={t('Delete tag?')}
        visible={deleteConfirmVisible}
        onCancel={onCancelDeleteConfirmation}
      >
        <IbTypography.Paragraph type="secondary">
          {t('Are you sure you want to remove the tag and its content?')}
          <br />
          {t('The tag will be removed from all chats and contacts')}
        </IbTypography.Paragraph>
      </IbConfirmModal>
    </>
  );
};

export default IbTagsManagementModal;
