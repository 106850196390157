import React from 'react';
import { Space, Typography } from 'antd';
import { CheckCircleTwoTone, ClockCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';

import { PersonAccountState } from '../../api';
import { Colors } from '../constants';

const { Text } = Typography;

export interface IInviteStateProps {
  state: PersonAccountState | undefined;
}

const InviteState: React.FC<IInviteStateProps> = (props) => {
  const { state } = props;

  return (
    <Space>
      {state === undefined && <>...</>}
      {state === PersonAccountState.NUMBER_0 && (
        <>
          <CloseSquareOutlined />
          <Text strong>Нет</Text>
        </>
      )}
      {state === PersonAccountState.NUMBER_1 && (
        <>
          <ClockCircleOutlined />
          <Text strong>Приглашен</Text>
        </>
      )}
      {state === PersonAccountState.NUMBER_2 && (
        <>
          <CheckCircleTwoTone twoToneColor={Colors.SUCCESS} />
          <Text strong>Подтвержден</Text>
        </>
      )}
    </Space>
  );
};

export default InviteState;
