import React, { MutableRefObject, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { VirtualItem } from '@tanstack/react-virtual';
import { v4 } from 'uuid';
import { Flags } from 'react-feature-flags';

import { InboxAttachmentModel, InboxMessageModel, InboxMessageParticipantStatus, TariffStatus } from '../../../../api';
import {
  IEntityItem,
  IEntityRange,
  IEntityScroll,
  IStorageStateItemEvent,
  StatsStatus,
  TableName,
} from '../../storages';
import { InboxMessageFilter } from '../../storages/messages';
import { InboxAttachmentFilter } from '../../storages/attachments';
import { EventBus, EventType } from '../../events';
import {
  attachmentItemsSelector,
  attachmentRangeSelector,
  chatItemSelector,
  currentActivityIdSelector,
  messageItemsSelector,
  messageRangeSelector,
  messageStatsSelector,
  sendMessage,
  resendMessage,
  deleteMessage,
  getMessageIndex,
  uploadAttachment,
  reUploadAttachment,
  deleteAttachment,
  participantRangeSelector,
  participantItemsSelector,
  participantStatsSelector,
  IPreviewModalParams,
  previewModalParamsSelector,
  currentOperatorSelector,
} from '../../recoil';
import { ITaskOperation, OperationStatus } from '../../tasks';
import { getProfile } from '../../../utils/oidcUtil';
import IbMessageList from '../../components/IbMessageList';
import { FeatureFlagNames } from '../../../constants';
import { SUBJECT_ROLES } from '../../../constants';
import { InboxParticipantFilter } from '../../storages/participants';
import { IIbMessageInputRef } from '../../components/IbMessageInput';

import MessageItem from './MessageItem';

interface IMessageListProps {
  messageInputRef: MutableRefObject<IIbMessageInputRef | undefined>;
  chatId: string;
  onFocusToMessageInput?: () => void;
  onScrollToEnd?: () => void;
}

const MessageList: React.FC<IMessageListProps> = ({
  chatId,
  messageInputRef,
  onFocusToMessageInput,
  onScrollToEnd,
}) => {
  const profile = getProfile();
  const bus = EventBus.instance;

  const [activityId, setActivityId] = useRecoilState(currentActivityIdSelector(chatId));

  const currentOperator = useRecoilValue(currentOperatorSelector);
  const tariffLimitExceeded = currentOperator && currentOperator.entity.tariffResult.status !== TariffStatus.Allowed;

  const messageFilter = new InboxMessageFilter(chatId);
  const attachmentFilter = new InboxAttachmentFilter(activityId);

  const [mentionListSearch, setMentionListSearch] = useState('');
  const searchFilter = new InboxParticipantFilter(mentionListSearch, undefined, [SUBJECT_ROLES.operator]);
  // NOTE: в participantRangeSelector происходит начальная загрузка данных
  useRecoilValue(participantRangeSelector(searchFilter));
  const operatorList = useRecoilValue(participantItemsSelector(searchFilter));
  const operatorListStats = useRecoilValue(participantStatsSelector(searchFilter));

  const chatItem = useRecoilValue(chatItemSelector(chatId));

  const messageItems = useRecoilValue(messageItemsSelector(messageFilter));
  const messageStats = useRecoilValue(messageStatsSelector(messageFilter));
  const [messageRange, setMessageRange] = useRecoilState(messageRangeSelector(messageFilter));

  const attachmentItems = useRecoilValue(attachmentItemsSelector(attachmentFilter));
  const [attachmentRange, setAttachmentRange] = useRecoilState(attachmentRangeSelector(attachmentFilter));

  const [messagePosition, setMessagePosition] = useState<{ index: number }>();

  const [quotedMessages, setQuotedMessages] = useState<IEntityItem<InboxMessageModel>[]>([]);

  const setPreviewModalParams = useSetRecoilState(previewModalParamsSelector);

  const onMessageCreated = async (event: IStorageStateItemEvent<InboxMessageModel, InboxMessageFilter>) => {
    if (event.name != TableName.StorageMessages) {
      return;
    }

    if (!messageFilter.equals(event.filter)) {
      return;
    }

    setMessagePosition({ index: event.index });
  };

  const onMessageScroll = (scroll: IEntityScroll) => {
    setMessageRange(scroll as IEntityRange);
    if (!scroll.scrollOffset) {
      onScrollToEnd?.();
    }
  };

  const onMessageSend = async (entity: InboxMessageModel) => {
    const currentParticipantId = chatItem?.entity.participants?.find(
      (p) => p.subject.role === SUBJECT_ROLES.operator && p.subject.id === profile.userId
    )?.id;
    if (currentParticipantId && entity.senderParticipant) {
      entity.senderParticipant.id = currentParticipantId;
    }

    if (chatItem?.entity.participants) {
      entity.participants = chatItem?.entity.participants.map((p) => {
        return {
          id: p.id,
          status: InboxMessageParticipantStatus.Undefined,
          subject: {
            id: p.subject.id,
            fullName: p.subject.person.name.fullName ?? '',
            shortName: p.subject.person.name.shortName ?? '',
            role: p.subject.role,
          },
        };
      });
    }

    entity.activity.id = activityId;
    setActivityId(v4());
    setQuotedMessages([]);
    await sendMessage(entity);
  };

  const onMessageResend = async (entity: InboxMessageModel, virtual: VirtualItem) => {
    await resendMessage(entity, virtual);
  };

  const onMessageDelete = async (entity: InboxMessageModel, operation: ITaskOperation, virtual: VirtualItem) => {
    await deleteMessage(entity, operation, virtual);
  };

  const onQuotedMessageAdd = (item: IEntityItem<InboxMessageModel>) => {
    setQuotedMessages([item]);
    onFocusToMessageInput?.();
  };

  const onQuotedMessageDelete = () => {
    setQuotedMessages([]);
    onFocusToMessageInput?.();
  };

  const onQuotedMessageClick = async (messageId: string, activityId: string) => {
    const index = await getMessageIndex(messageId, activityId, chatId);
    setMessagePosition({ index });
  };

  const onAttachmentUpload = async (entity: InboxAttachmentModel) => {
    entity.activity.id = activityId;
    entity.external.id = `${activityId}/${entity.file.id}`;
    setAttachmentRange({ ...attachmentRange, virtualItems: [...attachmentRange.virtualItems, {} as VirtualItem] });
    await uploadAttachment(entity);
  };

  const onAttachmentReUpload = async (entity: InboxAttachmentModel) => {
    await reUploadAttachment(entity);
  };

  const onAttachmentDelete = async (entity: InboxAttachmentModel, operation: ITaskOperation) => {
    setAttachmentRange({ ...attachmentRange, virtualItems: attachmentRange.virtualItems.slice(0, -1) });
    await deleteAttachment(entity, operation);
  };

  const onOperatorsSearch = (search?: string) => {
    setMentionListSearch(search || '');
  };

  const onAttachmentPreview = (params: IPreviewModalParams) => {
    setPreviewModalParams(params);
  };

  useEffect(() => {
    onFocusToMessageInput?.();
    const subscriptions = [bus.onSync(EventType.StorageStateItemCreated, onMessageCreated)];

    return () => subscriptions.forEach((s) => s.unsubscribe());
  }, [chatId]);

  const renderMessage = (
    currentUserId: string,
    entityItem: IEntityItem<InboxMessageModel> | undefined,
    virtualItem: VirtualItem
  ) => {
    if (!entityItem || entityItem?.operation.status === OperationStatus.Unknown) {
      return undefined;
    }

    const onMessageItemResend = () => onMessageResend?.(entityItem.entity, virtualItem);
    const onMessageItemDelete = () => onMessageDelete?.(entityItem.entity, entityItem.operation, virtualItem);

    return (
      <MessageItem
        currentUserId={currentUserId}
        entityItem={entityItem}
        virtualItem={virtualItem}
        onAttachmentDelete={onAttachmentDelete}
        onAttachmentPreview={onAttachmentPreview}
        onAttachmentReUpload={onAttachmentReUpload}
        onMessageDelete={onMessageItemDelete}
        onMessageResend={onMessageItemResend}
      />
    );
  };

  const renderMessageList = (mentionLinksEnabled: boolean) => {
    return (
      <IbMessageList
        attachmentItems={attachmentItems}
        channelId={chatItem?.entity.channelId}
        chatId={chatId}
        currentUserId={profile.userId}
        mentionLinksEnabled={mentionLinksEnabled}
        messageInputRef={messageInputRef}
        messageItems={messageItems}
        messagePosition={messagePosition}
        messageRange={messageRange}
        messageStats={messageStats}
        operatorList={operatorList.map((i) => i.entity)}
        operatorListLoading={
          operatorListStats.status === StatsStatus.Unknown || (!operatorList.length && !!operatorListStats.itemCount)
        }
        participants={chatItem?.entity.participants || []}
        quotedMessages={quotedMessages}
        renderMessage={renderMessage}
        tariffLimitExceeded={tariffLimitExceeded}
        onAttachmentDelete={onAttachmentDelete}
        onAttachmentPreview={onAttachmentPreview}
        onAttachmentReUpload={onAttachmentReUpload}
        onAttachmentUpload={onAttachmentUpload}
        onMessageDelete={onMessageDelete}
        onMessageResend={onMessageResend}
        onMessageScroll={onMessageScroll}
        onMessageSend={onMessageSend}
        onOperatorsSearch={onOperatorsSearch}
        onQuotedMessageAdd={onQuotedMessageAdd}
        onQuotedMessageClick={onQuotedMessageClick}
        onQuotedMessageDelete={onQuotedMessageDelete}
      />
    );
  };

  return (
    <Flags
      authorizedFlags={[FeatureFlagNames.USERS_MANAGEMENT]}
      renderOff={() => renderMessageList(false)}
      renderOn={() => renderMessageList(true)}
    />
  );
};

export default MessageList;
