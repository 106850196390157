import React from 'react';
import { Radio, RadioGroupProps } from 'antd';

import './index.less';

const MAIN_CLASS_NAME = 'ib-radio-group';

export type IIbRadioGroupProps = RadioGroupProps;

const IbRadioGroup: React.FC<IIbRadioGroupProps> = ({ className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <Radio.Group {...otherProps} className={classes.join(' ')} />;
};

export default IbRadioGroup;
