import React from 'react';

import SbModal from '../../../components/common/SbModal';

import InstanceStatusProgress from './InstanceStatusProgress';

interface IInstancePublishModalProps {
  instanceId: string;
  visible: boolean;
  onCancel: () => void;
}

const InstancePublishModal: React.FC<IInstancePublishModalProps> = ({ instanceId, visible, onCancel }) => {
  const onPublishComplete = () => onCancel();

  return (
    <SbModal
      destroyOnClose
      footer={[]}
      sbSize="small"
      title="Подождите, идет синхронизация"
      visible={visible}
      width={569}
      onCancel={() => {}}
      onOk={() => {}}
    >
      <InstanceStatusProgress instanceId={instanceId} onPublishComplete={onPublishComplete} />
    </SbModal>
  );
};

export default InstancePublishModal;
