import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { Input } from 'antd';
import { nanoid } from 'nanoid';
import './index.less';

import SbScroll from '../SbScroll';

interface ISbTextAreaProps {
  value?: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
}

const SbTextArea: React.FC<ISbTextAreaProps> = ({ value, onChange = () => {}, autoFocus }) => {
  const [id] = useState(`sb-textarea-${nanoid(10)}`);

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    onChange(event.target.value);
  };

  const onComponentCreate = () => {
    if (!autoFocus) return;

    const el = document.getElementById(id) as HTMLTextAreaElement;
    if (el) {
      el.focus();
      el.setSelectionRange(value?.length || 0, value?.length || 0);
    }
  };
  useEffect(onComponentCreate, []);

  return (
    <div className="sb-textarea">
      <SbScroll>
        <div>
          <Input.TextArea autoSize id={id} value={value} onChange={onTextAreaChange} />
        </div>
      </SbScroll>
    </div>
  );
};

export default SbTextArea;
