import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

import './index.less';

import { getDefaultIfUndefined } from '../../../../utils/typeUtil';
import IbIcon from '../IbIcon';
import IbTooltip from '../IbTooltip';

const TYPE_DEFAULT = undefined;
const STYLE_DEFAULT = 'default';
const MAIN_CLASS_NAME = 'ib-tag';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const CLOSE_ICON_CLASS_NAME = `${MAIN_CLASS_NAME}__close-icon`;

export interface IIbTagProps {
  content: ReactNode;
  tooltipContent?: ReactNode;
  type?: 'status';
  style?: 'default' | 'success' | 'warning' | 'error' | CSSProperties;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  onClick?: () => void;
  onDelete?: () => void;
}

const IbTag: React.FC<IIbTagProps> = ({
  content,
  tooltipContent,
  type = TYPE_DEFAULT,
  style = STYLE_DEFAULT,
  iconLeft,
  iconRight,
  onClick,
  onDelete,
}) => {
  type = getDefaultIfUndefined(type, TYPE_DEFAULT);
  style = getDefaultIfUndefined(style, STYLE_DEFAULT);

  const onTagClick: MouseEventHandler = () => {
    onClick?.();
  };

  const styleIsCustom = typeof style !== 'string';

  const classes = [
    MAIN_CLASS_NAME,
    type ? `${MAIN_CLASS_NAME}_${type}` : '',
    !styleIsCustom ? `${MAIN_CLASS_NAME}_${style}` : null,
  ];

  const renderTag = () => (
    <div
      className={classes.join(' ')}
      style={styleIsCustom ? (style as CSSProperties) : undefined}
      onClick={onTagClick}
    >
      {iconLeft}
      <span className={CONTENT_CLASS_NAME}>{content}</span>
      {iconRight}
      {onDelete && <IbIcon className={CLOSE_ICON_CLASS_NAME} iconName="close-small" size={16} onClick={onDelete} />}
    </div>
  );

  return tooltipContent ? <IbTooltip title={tooltipContent}>{renderTag()}</IbTooltip> : renderTag();
};

export default IbTag;
