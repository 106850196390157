import React, { ReactNode } from 'react';
import { List } from 'antd';

import './index.less';

interface ISbListProps {
  dataSource: string[];
  emptyText?: ReactNode;
}

const SbList: React.FC<ISbListProps> = ({ dataSource, emptyText }) => {
  return (
    <div className="sb-list">
      {emptyText !== undefined && !dataSource.length ? (
        <div className="sb-list__empty">{emptyText}</div>
      ) : (
        <List
          bordered
          className="sb-list"
          dataSource={dataSource}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      )}
    </div>
  );
};

export default SbList;
