import React from 'react';
import { Menu } from 'antd';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';

import './index.less';

export type ISbSubMenuProps = SubMenuProps;

const SbSubMenu: React.FC<ISbSubMenuProps> = ({ children, ...otherProps }) => {
  return (
    <Menu.SubMenu className="sb-menu__sub-menu" {...otherProps}>
      {children}
    </Menu.SubMenu>
  );
};

export default SbSubMenu;
