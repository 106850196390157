import React from 'react';
import { Tabs, TabsProps } from 'antd';

import './index.less';

type ISbTabsProps = TabsProps;

const SbTabs: React.FC<ISbTabsProps> = ({ children, ...otherProps }) => (
  <Tabs animated className="sb-tabs" {...otherProps}>
    {children}
  </Tabs>
);

export default SbTabs;
