import React from 'react';
import { Col, Row } from 'antd';
import moment, { Moment } from 'moment';
import { SelectValue } from 'antd/lib/select';

import { BinaryConditionOperator, DateTimeValueType, SchemaKind, ValidatorSchema } from '../../../../../../api';
import SbDatePicker from '../../../../../simple-bot/components/common/SbDatePicker';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import {
  DateTimeBinaryConditionOperators,
  DateTimeConditionOperators,
  DateTimeUnaryConditionOperators,
} from '../../../constants';
import { BUTTON_COLUMN_WIDTH, OPERATOR_COLUMN_WIDTH, TABLE_GUTTER_SIZE, TITLE_COLUMN_WIDTH } from '../const';
import { instanceOfDateTimeTypeValidator, instanceOfDateTimeValueComparisonValidator } from '../../../utils';

// TODO: Поддержка времени и диапазонов.

interface IDateTimeValidationProps {
  validator: ValidatorSchema;
  onChange: (validator: ValidatorSchema) => void;
  onDelete: () => void;
}

const DateTimeValidation: React.FC<IDateTimeValidationProps> = ({ validator, onChange, onDelete }) => {
  const onDateTimeUnaryOperatorChange = (value: SelectValue) => {
    const expectedType = value as DateTimeValueType;
    if (instanceOfDateTimeTypeValidator(validator)) {
      const newValidator = {
        ...validator,
        expectedType,
      };
      onChange(newValidator);
      return;
    }

    if (instanceOfDateTimeValueComparisonValidator(validator)) {
      const newValidator = {
        id: validator.id,
        $kind: SchemaKind.DateTimeTypeValidator,
        expectedType,
      };
      onChange(newValidator);
      return;
    }
  };

  const onDateTimeBinaryOperatorChange = (value: SelectValue) => {
    const operator = value as BinaryConditionOperator;
    if (instanceOfDateTimeTypeValidator(validator)) {
      const newValidator = {
        id: validator.id,
        $kind: SchemaKind.DateTimeValueComparisonValidator,
        operator,
        value: moment().format(moment.HTML5_FMT.DATE),
      };
      onChange(newValidator);
      return;
    }

    if (instanceOfDateTimeValueComparisonValidator(validator)) {
      const newValidator = {
        ...validator,
        operator,
      };
      onChange(newValidator);
      return;
    }
  };

  const onOperatorChange = (value: SelectValue) => {
    if (DateTimeUnaryConditionOperators.find((o) => o.value === value)) {
      onDateTimeUnaryOperatorChange(value);
      return;
    }

    if (DateTimeBinaryConditionOperators.find((o) => o.value === value)) {
      onDateTimeBinaryOperatorChange(value);
      return;
    }
  };

  const onValueChange = (value: Moment | null) => {
    onChange({
      ...validator,
      value: (value || moment()).format(moment.HTML5_FMT.DATE),
    });
  };

  const onDeleteClick = () => {
    onDelete();
  };

  return (
    <Row align="middle" gutter={[TABLE_GUTTER_SIZE, TABLE_GUTTER_SIZE]} style={{ marginRight: 0 }}>
      <Col flex={`${TITLE_COLUMN_WIDTH}px`}>
        <h4>Значение</h4>
      </Col>
      <Col flex={`${OPERATOR_COLUMN_WIDTH}px`}>
        <SbSelect
          options={DateTimeConditionOperators}
          sbSize="big"
          sbType="light"
          style={{ maxWidth: `${OPERATOR_COLUMN_WIDTH - TABLE_GUTTER_SIZE}px` }}
          value={instanceOfDateTimeValueComparisonValidator(validator) ? validator.operator : validator.expectedType}
          onChange={onOperatorChange}
        />
      </Col>
      <Col flex="1 1 0">
        {instanceOfDateTimeValueComparisonValidator(validator) && (
          <SbDatePicker format="DD.MM.YYYY" picker="date" value={moment(validator.value)} onChange={onValueChange} />
        )}
      </Col>
      <Col flex={`${BUTTON_COLUMN_WIDTH}px`} style={{ paddingRight: '0' }}>
        <SbButton icon={<SbIcon iconName="close" size={21} />} sbType="icon-only" onClick={onDeleteClick} />
      </Col>
    </Row>
  );
};

export default DateTimeValidation;
