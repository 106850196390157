import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  ProFormCheckbox,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  StepsForm,
  ProFormSwitch,
  ProFormUploadDragger,
} from '@ant-design/pro-form';
import { FormInstance } from 'antd/es/form';
import { Typography, Button, Upload, Spin, Space, Row, Col, Modal } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FileExcelOutlined, FileWordOutlined, UploadOutlined } from '@ant-design/icons';
import truncate from 'lodash/truncate';
import { useSetRecoilState } from 'recoil';

import { AgentCreationMode, AgentCreationRequest } from '../../../../api';
import { KnowledgeSourceValidationRequest, TemplateModel } from '../../../../kb-api';
import { knowledgeSourceKbApi, templateKbApi } from '../../../apis';
import { AlertTypes, ALLOWED_KNOWLEDGEBASE_FILE_TYPES, FileExtensions } from '../../../constants';
import { fileExtensionToBinarySourceFormat, getBase64Content, getFileExtension } from '../../../utils/fileUtil';
import { ChatSampleIcon, KbChatSampleIcon } from '../../../assets';
import { alertsSelectorAdd } from '../../../recoil/alerts';

interface IWizardCreationFormProps {
  onCancel: () => void;
  onSubmit: (request: AgentCreationRequest) => Promise<boolean>;
}

const { Paragraph } = Typography;
const errorMessageLength = 50;

const defaultGreeting = 'Привет, я бот службы поддержки, чем могу помочь?';
const transferToOperatorByUser = 'transferToOperatorByUser';
const transferToOperatorInUnknownIntent = 'transferToOperatorInUnknownIntent';

const WizardCreationForm: React.FC<IWizardCreationFormProps> = ({ onCancel = () => {}, onSubmit = () => false }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const formRef = useRef<FormInstance>();

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [request, setRequest] = useState<AgentCreationRequest>({
    creationMode: AgentCreationMode.NUMBER_0,
    name: '',
    config: {
      greeting: defaultGreeting,
      transferToOperatorByUser: false,
      transferToOperatorInUnknownIntent: false,
      knowledgeData: undefined,
      chitchatEnabled: false,
      chitchatCode: undefined,
    },
  });

  const [fileHelp, setFileHelp] = useState<ReactNode>();
  const [validating, setValidating] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);

  const [chitchatCharacters, setChitchatCharacters] = useState<TemplateModel[]>([]);

  const loadChitchatCharactersAsync = async () => {
    if (chitchatCharacters.length || loading) return;
    setLoading(true);
    try {
      const response = await templateKbApi.getChitchatTemplates();
      setChitchatCharacters(response.data || []);
      if (response.data?.length) {
        setRequest({
          ...request,
          config: {
            ...request.config,
            chitchatCode: response.data[0].code,
          },
        } as AgentCreationRequest);
      }
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка chitchat шаблонов',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadChitchatCharacters = () => {
    loadChitchatCharactersAsync();
  };
  useEffect(loadChitchatCharacters, []);

  const showValidatingError = (error: ReactNode) => {
    Modal.error({
      title: 'Возникла ошибка при проверке файла!',
      content: error,
      okText: 'Ok',
    });
  };

  const onSettingsFinish = async () => {
    const { name, greeting, transferToOperator, chitchatEnabled, chitchatCode } = formRef.current?.getFieldsValue();
    setRequest({
      ...request,
      name,
      config: {
        ...request.config,
        greeting: greeting,
        transferToOperatorByUser: transferToOperator.includes(transferToOperatorByUser),
        transferToOperatorInUnknownIntent: transferToOperator.includes(transferToOperatorInUnknownIntent),
        chitchatEnabled,
        chitchatCode,
      },
    } as AgentCreationRequest);
    return true;
  };

  const onFaqFinish = async () => {
    setSubmitting(true);
    const submitResult = await onSubmit(request);
    if (!submitResult) setSubmitting(false);
    return true;
  };

  const onValuesChange = (fieldsValue: { chitchatEnabled?: boolean }) => {
    const { chitchatEnabled } = fieldsValue;
    if (chitchatEnabled !== undefined) {
      setRequest({
        ...request,
        config: {
          chitchatEnabled,
        },
      } as AgentCreationRequest);
    }
  };

  const beforeUploadFile = async (file: RcFile) => {
    setValidating(true);
    setFileHelp(<Spin spinning={false}>Подождите, выполняется проверка файла...</Spin>);
    const extension = getFileExtension(file.name) as FileExtensions;
    if (!ALLOWED_KNOWLEDGEBASE_FILE_TYPES.includes(extension)) {
      setValidating(false);
      setFileHelp(undefined);
      showValidatingError(
        <span>
          Формат &quot;{extension}&quot; не поддерживается. Доступные форматы файлов:{' '}
          {ALLOWED_KNOWLEDGEBASE_FILE_TYPES.join(', ')}
        </span>
      );
      return Upload.LIST_IGNORE;
    }

    const binarySourceFormat = fileExtensionToBinarySourceFormat(extension);
    const base64Content = await getBase64Content(file);

    try {
      const validationRequest: KnowledgeSourceValidationRequest = {
        binaryData: {
          data: base64Content,
          format: binarySourceFormat,
          name: file.name,
        },
      };
      const response = await knowledgeSourceKbApi.validateKnowledgeSource(validationRequest);

      if (response.data.isValid) {
        setRequest({
          ...request,
          config: {
            ...request.config,
            knowledgeData: validationRequest.binaryData,
          },
        } as AgentCreationRequest);
        setFileList([file]);
      } else {
        showValidatingError(<span>Некорректное содержимое файла.</span>);
      }
    } catch (e) {
      showValidatingError(
        <span>Не удалось проверить файл: {truncate((e as Error).message || '', { length: errorMessageLength })}</span>
      );
    } finally {
      setValidating(false);
      setFileHelp(undefined);
    }

    return false;
  };

  const onRemoveFile = () => {
    setRequest({
      ...request,
      config: {
        ...request.config,
        knowledgeData: undefined,
      },
    } as AgentCreationRequest);
    setFileList([]);
  };

  const onBackButtonClick = (callback?: () => void) => {
    callback?.();
  };

  const onSubmitButtonClick = (callback?: () => void) => {
    callback?.();
  };

  return (
    <div className="wizard-form-container">
      <StepsForm
        formRef={formRef}
        submitter={{
          render: (props) => [
            <Button key="back" onClick={() => onBackButtonClick(props.step > 0 ? props.onPre : onCancel)}>
              Назад
            </Button>,
            <Button
              key="submit"
              loading={submitting}
              type="primary"
              onClick={() => onSubmitButtonClick(props.onSubmit)}
            >
              {props.step === 1 ? 'Создать' : 'Далее'}
            </Button>,
          ],
        }}
      >
        <StepsForm.StepForm
          initialValues={{
            name: request.name,
            greeting: request.config?.greeting,
            transferToOperator: [
              request.config?.transferToOperatorByUser && transferToOperatorByUser,
              request.config?.transferToOperatorInUnknownIntent && transferToOperatorInUnknownIntent,
            ].filter((e) => e),
            chitchatEnabled: request.config?.chitchatEnabled,
            chitchatCode: request.config?.chitchatCode,
          }}
          name="settings"
          title=""
          onFinish={onSettingsFinish}
          onValuesChange={onValuesChange}
        >
          <Row>
            <Col className="settings-form" lg={{ span: 12 }} xl={{ span: 8 }} xs={{ span: 24 }}>
              <ProFormText
                label={<b>Название</b>}
                name="name"
                placeholder="Название бота"
                rules={[{ required: true, message: 'Введите название бота' }]}
                width="xl"
              />
              <ProFormTextArea
                label={<b>Приветствие</b>}
                name="greeting"
                placeholder="Приветственное сообщение бота при начале беседы"
                tooltip={{
                  overlayClassName: 'wizard-tooltip',
                  placement: 'right',
                  overlay: (
                    <div>
                      <div className="wizard-tooltip__header">Что такое приветствие?</div>
                      <div className="wizard-tooltip__content">
                        Это сообщение, которое будет показано пользователю после начала беседы
                      </div>
                    </div>
                  ),
                }}
                width="xl"
              />
              <ProFormCheckbox.Group
                label={<b>Перевод на оператора</b>}
                layout="vertical"
                name="transferToOperator"
                options={[
                  { label: 'По просьбе пользователя', value: transferToOperatorByUser },
                  { label: 'При нераспознанном намерении', value: transferToOperatorInUnknownIntent },
                ]}
              />
              <div className="wizard-switch-wrapper">
                <ProFormSwitch
                  label={<b style={{ fontSize: 16 }}>Chitchat</b>}
                  name="chitchatEnabled"
                  tooltip={{
                    overlayClassName: 'wizard-tooltip',
                    placement: 'right',
                    overlay: (
                      <div>
                        <div className="wizard-tooltip__header">Что такое сhitchat?</div>
                        <div className="wizard-tooltip__content">
                          Это набор бесед на разговорные темы (например, пользователь может спросить, как дела, сделать
                          комплимент боту, похвалить или покритиковать бота и т.д.). Данная функция позволяет оживить
                          ответы бота и разнообразить общение.
                        </div>
                      </div>
                    ),
                  }}
                />
              </div>
              <ProFormSelect
                allowClear={false}
                label="Характер"
                name="chitchatCode"
                options={chitchatCharacters.map((c) => ({ value: c.code, label: c.name }))}
                placeholder="Выберите характер"
                rules={[
                  {
                    required: request.config?.chitchatEnabled,
                    message: 'Выберите характер chitchat`а',
                  },
                ]}
              />
            </Col>
            <Col
              lg={{ span: 10 }}
              md={{ span: 0 }}
              sm={{ span: 0 }}
              xl={{ span: 12 }}
              xs={{ span: 0 }}
              xxl={{ span: 12 }}
            >
              <ChatSampleIcon style={{ maxWidth: '100%' }} />
            </Col>
          </Row>
        </StepsForm.StepForm>
        <StepsForm.StepForm name="faq" title="" onFinish={onFaqFinish}>
          <div className="wizard-kb-description-card">
            <Row>
              <Col
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xl={{ span: 12 }}
                xs={{ span: 24 }}
                xxl={{ span: 12 }}
              >
                <b>Вопросы и ответы</b>
                <Paragraph style={{ marginTop: '12px' }}>
                  Загрузите файлы с вопросами и ответами
                  <br />и получите бота, который умеет автоматически давать ответы на заданные пользователями
                  <br /> вопросы. Примеры:
                </Paragraph>
                <Paragraph>
                  <Space wrap>
                    <a download href={`${process.env.PUBLIC_URL}/files/Вопросы.xlsx`}>
                      <FileExcelOutlined /> Вопросы.xlsx
                    </a>
                    <a download href={`${process.env.PUBLIC_URL}/files/С_уточнением.docx`}>
                      <FileWordOutlined /> С уточнением.docx
                    </a>
                  </Space>
                </Paragraph>
              </Col>
              <Col
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xl={{ span: 12 }}
                xs={{ span: 0 }}
                xxl={{ span: 12 }}
              >
                <KbChatSampleIcon style={{ maxWidth: '100%', padding: '0 12px' }} />
              </Col>
            </Row>
          </div>
          <div className="wizard-upload-button-wrapper">
            <ProFormUploadDragger
              accept={ALLOWED_KNOWLEDGEBASE_FILE_TYPES.join(',')}
              description=""
              fieldProps={{
                name: 'file',
                listType: 'picture-card',
                beforeUpload: beforeUploadFile,
                onRemove: onRemoveFile,
                maxCount: 1,
                fileList,
              }}
              help={fileHelp}
              icon={<UploadOutlined />}
              name="knowledgeBaseFile"
              style={{ marginBottom: 24 }}
              title={
                <span>
                  <span className="description">Выберите файл</span> или перетащите его сюда, в формате DOCX или XLSX
                </span>
              }
              validateStatus={validating ? 'validating' : undefined}
            />
          </div>
        </StepsForm.StepForm>
      </StepsForm>
    </div>
  );
};

export default WizardCreationForm;
