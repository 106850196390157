export const isString = (value: unknown): boolean => {
  if (value == null) {
    return false;
  }
  return typeof value === 'string' || value instanceof String;
};

export const isNumber = (value: unknown): boolean => {
  if (value == null) {
    return false;
  }
  return typeof value === 'number' || value instanceof Number;
};

export const isBoolean = (value: unknown): boolean => {
  if (value == null) {
    return false;
  }
  return typeof value === 'boolean' || value instanceof Boolean;
};

export const getDefaultIfUndefined = <T>(value: T, defaultValue: T): T =>
  value == undefined || (typeof value === 'string' && value === 'undefined') ? defaultValue : value;
