import { CheckSmall } from '@icon-park/react';
import React, { useEffect, useState } from 'react';

import './index.less';

const MAIN_CLASS_NAME = 'ib-checkbox';
const DISABLED_CLASS_NAME = `${MAIN_CLASS_NAME}_disabled`;
const CHECKED_CLASS_NAME = `${MAIN_CLASS_NAME}_checked`;

export interface IIbCheckboxProps {
  className?: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

const IbCheckbox: React.FC<IIbCheckboxProps> = ({ className, value, disabled, onChange }) => {
  const [checked, setChecked] = useState(value);

  const classes = [MAIN_CLASS_NAME, disabled ? DISABLED_CLASS_NAME : null, value ? CHECKED_CLASS_NAME : null];
  className && classes.push(className);

  const onChangeInternal = () => {
    const newValue = !checked;
    setChecked(newValue);
    onChange?.(newValue);
  };

  const onValuePropChange = () => {
    setChecked(value);
  };
  useEffect(onValuePropChange, [value]);

  return (
    <div className={classes.join(' ')}>
      <CheckSmall />
      <input checked={checked} disabled={disabled} type="checkbox" onChange={onChangeInternal} />
    </div>
  );
};

export default IbCheckbox;
