import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { v4 } from 'uuid';

import './index.less';

import SbModal from '../../../../components/common/SbModal';
import SbButton from '../../../../components/common/SbButton';
import SbTypography from '../../../../components/common/SbTypography';
import SbInput from '../../../../components/common/SbInput';
import {
  AgentModel,
  AgentStageAccountCreationRequest,
  AgentStageAccountModel,
  AgentStageAccountStatus,
  AgentStageAccountUpdatingRequest,
} from '../../../../../../api';
import { agentStageAccountApi } from '../../../../../apis';
import { AlertTypes, Channels, INLINE_BUTTONS_ENABLED_PROPERTY_KEY, FORM_FIELD_NAMES } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import SbIcon from '../../../../components/common/SbIcon';
import SbPanel from '../../../../components/common/SbPanel';
import { getChannelDisplayName, getChannelName } from '../../../../../utils/stringUtil';
import SbSwitch from '../../../../components/common/SbSwitch';

interface IEditChannelModalProps {
  channel?: AgentStageAccountModel;
  agent: AgentModel;
  visible: boolean;
  channelType?: Channels;
  onClose: () => void;
  onDataChanged: () => void;
}

const DISPLAY_NAME_MAX_LENGTH = 75;
const TOKEN_MAX_LENGTH = 10000;

const EditChannelModal: React.FC<IEditChannelModalProps> = ({
  channel,
  agent,
  channelType,
  visible,
  onClose,
  onDataChanged,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [saving, setSaving] = useState(false);
  const [xToken, setXToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [inlineButtonsEnabled, setInlineButtonsEnabled] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [errors, setErrors] = useState<Record<string, unknown>>();

  const isNew = !channel;

  const modalTitle = isNew
    ? 'Подключить ' + getChannelName(channelType)
    : 'Редактировать канал ' + (channel ? getChannelDisplayName(channel) : '');

  const showXToken = channel?.channelId == Channels.ELMA365 || channelType === Channels.ELMA365;

  const showAuthToken =
    (channel && [Channels.TELEGRAM, Channels.VIBER, Channels.WHATSAPP].includes(channel.channelId as Channels)) ||
    (channelType && [Channels.TELEGRAM, Channels.VIBER, Channels.WHATSAPP].includes(channelType));

  const showAccessToken = channel?.channelId == Channels.WHATSAPP || channelType == Channels.WHATSAPP;

  const authTokenLabel =
    channel?.channelId == Channels.WHATSAPP || channelType == Channels.WHATSAPP ? 'Phone Number Id' : 'Токен';

  const showInlineOption = channel?.channelId == Channels.TELEGRAM;

  const onVisiblePropChange = () => {
    if (visible) {
      setSaving(false);
      setXToken(isNew ? '' : channel?.properties?.xToken ?? '');
      setAccessToken(isNew ? '' : channel?.properties?.accessToken ?? '');
      setAuthToken(isNew ? '' : channel?.authToken ?? '');
      setDisplayName(isNew ? '' : channel?.displayName ?? '');
      setInlineButtonsEnabled(isNew ? false : channel?.properties?.[INLINE_BUTTONS_ENABLED_PROPERTY_KEY] ?? false);
    }
    setErrors(undefined);
  };
  useEffect(onVisiblePropChange, [visible]);

  const onModalCancel = () => !saving && onClose();

  const trySetInlineButtons = () => setInlineButtonsEnabled(!inlineButtonsEnabled);

  const addChannel = async () => {
    if (!channelType) return;

    const properties: { [key: string]: unknown } = {};
    let authTokenValue = authToken;

    if (channelType === Channels.ELMA365) {
      properties[FORM_FIELD_NAMES.xToken] = xToken;
      authTokenValue = v4();
    }

    if (channelType === Channels.WHATSAPP) {
      properties[FORM_FIELD_NAMES.accessToken] = accessToken;
    }

    properties[INLINE_BUTTONS_ENABLED_PROPERTY_KEY] = inlineButtonsEnabled;

    try {
      setSaving(true);
      const request: AgentStageAccountCreationRequest = {
        agentStageId: agent.productionAgent.id,
        displayName: displayName,
        authToken: authTokenValue,
        channelId: channelType,
        properties,
      };

      await agentStageAccountApi.createAgentStageAccount(request);

      onClose();
      onDataChanged();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();
      setErrors(errors);

      if (errorText) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Не удалось добавить канал',
          description: errorText,
        });
      }
    }
    setSaving(false);
  };

  const updateChannel = async () => {
    if (!channel) return;
    try {
      setSaving(true);
      const request: AgentStageAccountUpdatingRequest = {
        agentStageId: channel.agentStageId,
        status: channel.status ?? AgentStageAccountStatus.Enabled,
        displayName: displayName,
        authToken: authToken,
        properties: {
          [FORM_FIELD_NAMES.xToken]: xToken,
          [FORM_FIELD_NAMES.accessToken]: accessToken,
          [INLINE_BUTTONS_ENABLED_PROPERTY_KEY]: inlineButtonsEnabled,
        },
      };

      await agentStageAccountApi.updateAgentStageAccount(channel.id, request);

      onClose();
      onDataChanged();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();

      setErrors(errors);

      if (errorText) {
        addAlert({
          type: AlertTypes.ERROR,
          message: 'Не удалось сохранить канал',
          description: errorText,
        });
      }
    }
    setSaving(false);
  };

  const onSave = async () => {
    if (saving) return;
    if (isNew) {
      await addChannel();
    } else {
      await updateChannel();
    }
  };

  const onDisplayNameBlur = () => {
    if (displayName && errors?.DisplayName) {
      setErrors({ ...errors, DisplayName: undefined });
    }
  };

  const onAuthTokenBlur = () => {
    if (authToken && errors?.AuthToken) {
      setErrors({ ...errors, AuthToken: undefined });
    }
  };

  const renderTelegramHelp = () => (
    <>
      <h4>Инструкция к подключению</h4>
      <ol>
        <li>
          Откройте любой Telegram-клиент и начните диалог с ботом <strong>@BotFather</strong>
        </li>
        <li>
          Введите команду <strong>/newbot</strong> для создания нового бота.
        </li>
        <li>Придумайте и введите имя вашего бота. (Например — ELMA BOT)</li>
        <li>
          Придумайте и введите <strong>username</strong> вашего бота. Оно обязательно должно оканчиваться на слово bot.
        </li>
        <li>
          Когда бот будет создан, вам сообщат секретный токен.
          <br /> Нажмите на него, чтобы скопировать, а затем вставьте сюда (поле <strong>&quot;Токен&quot;</strong>):
        </li>
      </ol>
    </>
  );

  const renderViberHelp = () => (
    <>
      <h4>Инструкция к подключению</h4>
      <ol>
        <li>
          Зайдите на{' '}
          <SbButton sbType="link" onClick={() => window.open('https://partners.viber.com', '_blank')}>
            страницу
          </SbButton>{' '}
          настройки ботов Viber и авторизуйтесь
        </li>
        <li>
          Перейдите в раздел <strong>Create Bot Account</strong>, заполните информацию о боте и нажмите{' '}
          <strong>Create</strong>
        </li>
        <li>
          Когда бот будет создан, вам сообщат секретный токен.
          <br /> Нажмите кнопку <strong>Copy</strong>, чтобы скопировать его, а затем вставьте сюда (поле{' '}
          <strong>&quot;Токен&quot;</strong>):
        </li>
      </ol>
    </>
  );

  const renderChatDeskHelp = () => (
    <>
      <h4>Инструкция к подключению</h4>
      <ol>
        <li>
          Зайдите на страницу администрирования ELMA ChatDesk и перейдите в раздел <strong>&quot;Токены&quot;</strong>
        </li>
        <li>
          Выберите нужный токен, скопируйте, а затем вставьте сюда (поле <strong>&quot;Токен&quot;</strong>):
        </li>
      </ol>
    </>
  );

  const renderHelp = () => {
    switch (channelType) {
      case Channels.TELEGRAM:
        return renderTelegramHelp();
      case Channels.VIBER:
        return renderViberHelp();
      case Channels.ELMA365:
        return renderChatDeskHelp();
    }

    return null;
  };

  const renderHelpPanel = () => {
    if (!isNew) return null;
    const helpContent = renderHelp();

    if (!helpContent) return null;
    return (
      <SbPanel sbType="help" scrollable={false}>
        {helpContent}
      </SbPanel>
    );
  };

  return (
    <SbModal
      destroyOnClose
      className="sb-edit-channel-modal"
      footer={[
        <SbButton
          key="save"
          disabled={saving}
          icon={saving ? <SbIcon spin iconName="loading-four" size={16} /> : null}
          sbSize="medium"
          sbType="icon-primary"
          onClick={onSave}
        >
          {isNew ? 'Подключить' : 'Сохранить'}
        </SbButton>,
        <SbButton key="cancel" disabled={saving} sbSize="medium" sbType="secondary" onClick={onModalCancel}>
          Отмена
        </SbButton>,
      ]}
      keyboard={false}
      maskClosable={false}
      sbSize="small"
      title={modalTitle}
      visible={visible}
      width="50%"
      onCancel={onModalCancel}
      onOk={onSave}
    >
      <div className="sb-edit-channel-modal__content">
        <SbTypography>
          {renderHelpPanel()}
          <h3>Название</h3>
          <SbInput
            errorText={errors?.DisplayName as string}
            isValid={!(errors && errors.DisplayName)}
            maxLength={DISPLAY_NAME_MAX_LENGTH}
            sbSize="big"
            value={displayName}
            onBlur={onDisplayNameBlur}
            onChange={setDisplayName}
          />
          {showXToken && (
            <>
              <h3>Токен</h3>
              <SbInput
                maxLength={TOKEN_MAX_LENGTH}
                sbSize="big"
                style={{ marginBottom: '16px' }}
                value={xToken}
                onChange={setXToken}
              />
            </>
          )}
          {showAuthToken && (
            <>
              <h3>{authTokenLabel}</h3>
              <SbInput
                errorText={errors?.AuthToken as string}
                isValid={!(errors && errors.AuthToken)}
                maxLength={TOKEN_MAX_LENGTH}
                sbSize="big"
                style={{ marginBottom: '16px' }}
                value={authToken}
                onBlur={onAuthTokenBlur}
                onChange={setAuthToken}
              />
            </>
          )}
          {showAccessToken && (
            <>
              <h3>Access Token</h3>
              <SbInput sbSize="big" value={accessToken} onChange={setAccessToken} />
            </>
          )}
          {showInlineOption && (
            <>
              <SbSwitch
                checked={inlineButtonsEnabled}
                label="Использовать Inline кнопки"
                onChange={trySetInlineButtons}
              />
            </>
          )}
        </SbTypography>
      </div>
    </SbModal>
  );
};

export default EditChannelModal;
