import React from 'react';
import { Steps } from 'antd';
import { StepsProps } from 'antd/lib/steps';

import './index.less';

const { Step } = Steps;

export interface ISbStep {
  title: string;
  disabled?: boolean;
}

type ISbStepsProps = StepsProps & {
  steps: ISbStep[];
};

const SbSwitch: React.FC<ISbStepsProps> = ({ steps, ...otherProps }) => (
  <Steps className="sb-steps" {...otherProps}>
    {steps.map((step, index) => (
      <Step
        key={step.title}
        disabled={step.disabled ?? false}
        icon={<span className="sb-steps__icon">{index + 1}</span>}
        title={<div className="sb-steps__title">{step.title}</div>}
      />
    ))}
  </Steps>
);

export default SbSwitch;
