import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSetRecoilState } from 'recoil';
import { Flags } from 'react-feature-flags';

import './index.less';

import SbModal from '../common/SbModal';
import SbButton from '../common/SbButton';
import SbScroll from '../common/SbScroll';
import { RoleModel, UserModel, UserStatus, UserUpdatingRequest } from '../../../../auth-api';
import { userAuthApi } from '../../../apis';
import { getProfile } from '../../../utils/oidcUtil';
import { AlertTypes, FeatureFlagNames, USER_ROLES, USER_STATUS_NAMES } from '../../../constants';
import { alertsSelectorAdd } from '../../../recoil/alerts';
import { ROLE_NAME_TAG_COLOR } from '../../const';
import SbTag from '../common/SbTag';
import SbSpin from '../common/SbSpin';
import SbIcon from '../common/SbIcon';
import SbInput from '../common/SbInput';
import SbTypography from '../common/SbTypography';
import SbTagsSelect from '../common/SbTagsSelect';
import { formatFullName, formatShortName, getRoleNameLabel } from '../../../utils/stringUtil';

const MODAL_WIDTH = 800;
const ROW_GUTTER = 24;
const NESTED_ROW_GUTTER = 12;
const LABEL_COLUMN_SPAN = 8;
const VALUE_COLUMN_SPAN = 16;

interface ISbUserModalProps {
  visible: boolean;
  onClose: () => void;
  userId?: string;
  onChange?: () => Promise<void>;
}

const SbUserModal: React.FC<ISbUserModalProps> = ({ visible, onClose, userId: userIdProp, onChange }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const profile = getProfile();
  const userId = userIdProp || profile.userId;
  const userSelfEditing = userId === profile.userId;

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState<UserModel>();

  const [blockModalVisible, setBlockModalVisible] = useState(false);
  const [statusSaving, setStatusSaving] = useState(false);

  const [saving, setSaving] = useState(false);
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [roleNames, setRoleNames] = useState<string[]>([]);

  const familyNameT = familyName.trim();
  const givenNameT = givenName.trim();
  const middleNameT = middleName.trim();
  const phoneNumberT = phoneNumber.trim();

  const userIsBlocked = user?.status === UserStatus.Blocked;

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = userSelfEditing ? await userAuthApi.getMe() : await userAuthApi.getUser(userId);
      setUser(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных пользователя',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  const onVisiblePropChange = () => {
    if (visible) {
      loadData();
    } else {
      setUser(undefined);
      setEditMode(false);
    }
  };
  useEffect(onVisiblePropChange, [visible]);

  const onEditButtonClick = () => {
    if (!user) return;

    setFamilyName(user.familyName || '');
    setGivenName(user.givenName || '');
    setMiddleName(user.middleName || '');
    setPhoneNumber(user.phoneNumber || '');
    setRoleNames(user.roles.map((r) => r.name) || []);
    setEditMode(true);
  };

  const onSaveButtonClick = async () => {
    if (!user) return;

    setSaving(true);
    try {
      const updatingRequest: UserUpdatingRequest = {
        login: user.login || '',
        email: user.email || '',
        phoneNumber: phoneNumberT,
        fullName: formatFullName(familyName, givenName, middleName),
        shortName: formatShortName(familyName, givenName, middleName),
        familyName: familyNameT,
        givenName: givenNameT,
        middleName: middleNameT,
        roleNames: roleNames.join(','),
        tenantNames: user.tenantNames,
        logins: user.logins,
      };

      if (userSelfEditing) {
        await userAuthApi.updateMe(updatingRequest);
      } else {
        await userAuthApi.updateUser(userId, updatingRequest);
      }

      setUser({
        ...user,
        phoneNumber: phoneNumberT,
        fullName: updatingRequest.fullName,
        shortName: updatingRequest.shortName || '',
        familyName: familyNameT,
        givenName: givenNameT,
        middleName: middleNameT,
        roles: roleNames.map((roleName) => ({ name: roleName } as RoleModel)),
      });
      setEditMode(false);

      await onChange?.();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при сохранении пользователя',
        error: e,
      });
    }
    setSaving(false);
  };

  const onCancelButtonClick = () => setEditMode(false);

  const onBlockButtonClick = () => setBlockModalVisible(true);

  const onBlockModalClose = () => !statusSaving && setBlockModalVisible(false);

  const onBlockModalOkButtonClick = async () => {
    if (!user) return;

    setStatusSaving(true);
    let newStatus = user.status;

    try {
      if (userIsBlocked) {
        await userAuthApi.unlockUser(userId);
        newStatus = UserStatus.Active;
      } else {
        await userAuthApi.blockUser(userId);
        newStatus = UserStatus.Blocked;
      }

      await onChange?.();
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при изменении статуса пользователя',
        error: e,
      });
    }

    setStatusSaving(false);
    setBlockModalVisible(false);
    setUser({
      ...user,
      status: newStatus,
    });
  };

  const onRoleNamesChange = (values: string[]) =>
    setRoleNames(values.filter((v) => Object.values(USER_ROLES).includes(v)));

  const blockButton = userSelfEditing ? null : (
    <Flags authorizedFlags={[FeatureFlagNames.USERS_ADMIN]}>
      <SbButton key="block" sbSize="medium" sbType="secondary" onClick={onBlockButtonClick}>
        {loading ? '' : userIsBlocked ? 'Разблокировать' : 'Блокировать'}
      </SbButton>
    </Flags>
  );

  const editButton = (
    <Flags
      authorizedFlags={[FeatureFlagNames.USERS_ADMIN]}
      renderOff={() =>
        userSelfEditing ? (
          <SbButton key="edit" sbSize="medium" sbType="primary" onClick={onEditButtonClick}>
            Редактировать
          </SbButton>
        ) : null
      }
      renderOn={() => (
        <SbButton key="edit" sbSize="medium" sbType="primary" onClick={onEditButtonClick}>
          Редактировать
        </SbButton>
      )}
    />
  );

  const modalButtons = editMode ? (
    <>
      <SbButton
        key="save"
        icon={saving ? <SbIcon spin iconName="loading-four" size={16} /> : null}
        loading={saving}
        sbSize="medium"
        sbType="primary"
        onClick={onSaveButtonClick}
      >
        Сохранить
      </SbButton>
      <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
        Отмена
      </SbButton>
    </>
  ) : (
    <>
      {editButton}
      {blockButton}
      <SbButton key="close" sbSize="medium" sbType="secondary" onClick={onClose}>
        Закрыть
      </SbButton>
    </>
  );

  return (
    <>
      <SbModal
        className="sb-user-modal"
        footer={modalButtons}
        sbSize="small"
        title={user?.fullName}
        visible={visible}
        width={MODAL_WIDTH}
        onCancel={onClose}
      >
        <SbScroll>
          <div className={`sb-user-modal__content ${loading ? 'sb-user-modal__content_loading' : ''}`}>
            <SbSpin />
            <Row gutter={ROW_GUTTER}>
              <Col span={LABEL_COLUMN_SPAN}>
                <b>ФИО</b>
              </Col>
              <Col span={VALUE_COLUMN_SPAN}>
                {editMode ? (
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col span={8}>
                      <SbInput
                        allowClear={false}
                        isValid={!!familyNameT}
                        placeholder="Фамилия"
                        sbSize="small"
                        value={familyName}
                        onChange={setFamilyName}
                      />
                    </Col>
                    <Col span={8}>
                      <SbInput
                        allowClear={false}
                        isValid={!!givenNameT}
                        placeholder="Имя"
                        sbSize="small"
                        value={givenName}
                        onChange={setGivenName}
                      />
                    </Col>
                    <Col span={8}>
                      <SbInput
                        allowClear={false}
                        placeholder="Отчество"
                        sbSize="small"
                        value={middleName}
                        onChange={setMiddleName}
                      />
                    </Col>
                  </Row>
                ) : (
                  user?.fullName
                )}
              </Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
              <Col span={LABEL_COLUMN_SPAN}>
                <b>Эл. почта</b>
              </Col>
              <Col span={VALUE_COLUMN_SPAN}>{user?.email}</Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
              <Col span={LABEL_COLUMN_SPAN}>
                <b>Логин</b>
              </Col>
              <Col span={VALUE_COLUMN_SPAN}>{user?.login}</Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
              <Col span={LABEL_COLUMN_SPAN}>
                <b>Номер телефона</b>
              </Col>
              <Col span={VALUE_COLUMN_SPAN}>
                {editMode ? (
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col>
                      <SbInput
                        allowClear={false}
                        isValid={!!phoneNumberT}
                        placeholder="Номер телефона"
                        sbSize="small"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                      />
                    </Col>
                  </Row>
                ) : (
                  user?.phoneNumber
                )}
              </Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
              <Col span={LABEL_COLUMN_SPAN}>
                <b>Роли</b>
              </Col>
              <Col span={VALUE_COLUMN_SPAN}>
                {editMode ? (
                  <Row gutter={NESTED_ROW_GUTTER}>
                    <Col span={24}>
                      <SbTagsSelect
                        options={[
                          { value: USER_ROLES.admin, label: getRoleNameLabel(USER_ROLES.admin) },
                          { value: USER_ROLES.botsAdmin, label: getRoleNameLabel(USER_ROLES.botsAdmin) },
                          { value: USER_ROLES.usersAdmin, label: getRoleNameLabel(USER_ROLES.usersAdmin) },
                          { value: USER_ROLES.inboxOperator, label: getRoleNameLabel(USER_ROLES.inboxOperator) },
                          { value: USER_ROLES.inboxSupervisor, label: getRoleNameLabel(USER_ROLES.inboxSupervisor) },
                        ]}
                        value={roleNames}
                        onChange={onRoleNamesChange}
                      />
                    </Col>
                  </Row>
                ) : user?.roles.length ? (
                  user.roles.map((role, index) => (
                    <SbTag
                      key={index}
                      color={ROLE_NAME_TAG_COLOR}
                      sbSize="small"
                      text={getRoleNameLabel(role.name) || role.name}
                    />
                  ))
                ) : (
                  '-'
                )}
              </Col>
            </Row>
            <Row gutter={ROW_GUTTER}>
              <Col span={LABEL_COLUMN_SPAN}>
                <b>Статус</b>
              </Col>
              <Col span={VALUE_COLUMN_SPAN}>{user?.status ? USER_STATUS_NAMES[user.status] : '-'}</Col>
            </Row>
          </div>
        </SbScroll>
      </SbModal>
      <SbModal
        footer={[
          <SbButton
            key="ok"
            icon={statusSaving ? <SbIcon spin iconName="loading-four" size={16} /> : null}
            loading={statusSaving}
            sbSize="medium"
            sbType="primary"
            onClick={onBlockModalOkButtonClick}
          >
            Ок
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onBlockModalClose}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title={userIsBlocked ? 'Разблокировка пользователя' : 'Блокировка пользователя'}
        visible={blockModalVisible}
        onCancel={onBlockModalClose}
      >
        <SbTypography>
          {userIsBlocked
            ? 'Вы действительно хотите разблокировать пользователя?'
            : 'Вы действительно хотите заблокировать пользователя?'}
        </SbTypography>
      </SbModal>
    </>
  );
};

export default SbUserModal;
