import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from '../../types';

export const DocumentPdfIcon = memo((props: IGraphicsProps) => {
  const clipPath1Id = v4();
  const clipPath2Id = v4();
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPath1Id})`}>
        <g clipPath={`url(#${clipPath2Id})`}>
          <path
            clipRule="evenodd"
            d="M8.33594 0.00390625C5.57451 0.00390625 3.33594 2.24248 3.33594 5.00391V35.0039C3.33594 37.7653 5.57451 40.0039 8.33594 40.0039H31.6693C34.4307 40.0039 36.6693 37.7653 36.6693 35.0039V6.66927L30.0039 0.00390625H8.33594Z"
            fill="#EDFAFF"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M36.6654 6.66927V6.67057H30V0.00390625L36.6654 6.66927Z"
            fill="#C2CDDE"
            fillRule="evenodd"
          />
        </g>
        <rect fill="#F14848" height="16.6667" rx="1.66667" width="36.6667" x="1.66406" y="16.668" />
        <path
          d="M7.92649 29.668V19.971H11.5629C12.3078 19.971 12.9328 20.1099 13.4379 20.3877C13.9461 20.6654 14.3296 21.0474 14.5884 21.5335C14.8504 22.0165 14.9814 22.5657 14.9814 23.1812C14.9814 23.8031 14.8504 24.3555 14.5884 24.8384C14.3264 25.3214 13.9397 25.7017 13.4284 25.9795C12.917 26.2541 12.2873 26.3915 11.5392 26.3915H9.12914V24.9473H11.3024C11.738 24.9473 12.0947 24.8716 12.3725 24.7201C12.6503 24.5685 12.8555 24.3602 12.988 24.0951C13.1238 23.8299 13.1916 23.5253 13.1916 23.1812C13.1916 22.8372 13.1238 22.5341 12.988 22.2721C12.8555 22.0101 12.6487 21.8065 12.3678 21.6613C12.09 21.513 11.7317 21.4388 11.293 21.4388H9.68312V29.668H7.92649ZM19.7932 29.668H16.5072V19.971H19.8595C20.8222 19.971 21.6493 20.1651 22.3406 20.5534C23.035 20.9385 23.5685 21.4925 23.9409 22.2153C24.3134 22.9382 24.4996 23.8031 24.4996 24.81C24.4996 25.8201 24.3118 26.6882 23.9362 27.4142C23.5637 28.1402 23.0255 28.6973 22.3216 29.0856C21.6209 29.4738 20.7781 29.668 19.7932 29.668ZM18.2638 28.1481H19.708C20.3835 28.1481 20.9469 28.025 21.3983 27.7788C21.8497 27.5294 22.189 27.1585 22.4163 26.6661C22.6436 26.1705 22.7572 25.5518 22.7572 24.81C22.7572 24.0682 22.6436 23.4527 22.4163 22.9634C22.189 22.471 21.8529 22.1033 21.4078 21.8602C20.9659 21.614 20.4166 21.4909 19.7601 21.4909H18.2638V28.1481ZM26.1687 29.668V19.971H32.3808V21.4435H27.9253V24.0761H31.9547V25.5487H27.9253V29.668H26.1687Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={clipPath1Id}>
          <rect fill="white" height="40" width="40" />
        </clipPath>
        <clipPath id={clipPath2Id}>
          <rect fill="white" height="40" transform="translate(3.33203 0.00195312)" width="33.3333" />
        </clipPath>
      </defs>
    </svg>
  );
});
