import { Table } from 'dexie';

import { IDbInfo } from './database';
import { DbCollection, IDbCollection } from './collection';

export enum TableName {
  QueueTasks = 'queue.tasks',
  StorageParticipants = 'storage.participants',
  StorageChats = 'storage.chats',
  StorageMessages = 'storage.messages',
  StorageAttachments = 'storage.attachments',
}

export interface IDbTable<TItem, TKey> {
  readonly database: IDbInfo;
  get(key: TKey): Promise<TItem | undefined>;
  put(item: TItem, key?: TKey): Promise<TKey>;
  bulkPut(items: TItem[]): Promise<TKey>;
  delete(key: TKey): Promise<void>;
  orderBy(index: string | string[]): IDbCollection<TItem, TKey>;
}

export class DbTable<TItem, TKey> implements IDbTable<TItem, TKey> {
  public readonly database: IDbInfo;

  private readonly _table: Table<TItem, TKey>;

  constructor(table: Table<TItem, TKey>) {
    this.database = table.db;

    this._table = table;
  }

  public get(key: TKey): Promise<TItem | undefined> {
    return this._table.get(key);
  }

  public put(item: TItem, key?: TKey): Promise<TKey> {
    return this._table.put(item, key);
  }

  public bulkPut(items: TItem[]): Promise<TKey> {
    return this._table.bulkPut(items);
  }

  public delete(key: TKey): Promise<void> {
    return this._table.delete(key);
  }

  public orderBy(index: string | string[]): IDbCollection<TItem, TKey> {
    return new DbCollection(this._table.orderBy(index));
  }
}

export const PageSizes = {
  storageParticipants: 100,
  storageChats: 10,
  storageMessages: 20,
  storageAttachments: 20,
  storageChannels: 18, // NOTE: кратно трем колонкам в десктопе
  storageContacts: 20,
  storageBots: 20,
  storageOperatorGroups: 20,
};
