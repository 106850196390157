import { atom, atomFamily } from 'recoil';

import { SingleBotModel, DefaultScenarioSchema, EntitySchema, GroupEntryModel, IntentEntryModel } from '../../../api';
import {
  IGroupDraggingInfo,
  IPosition,
  ICapturedBindingInfo,
  IInputConnectionPosition,
  IOutputConnectionPosition,
  IEntityPosition,
  IWorkingSpaceTransform,
} from '../../components/ScenarioEditor/types';
import { IValidatedStructure, IValidationIssue } from '../../simple-bot/utils/validation';

import { Dispatcher } from './dispatcher';

const DEFAULT_WORKING_SPACE_TRANSFORM = { x: 0, y: 0, zoom: 0.75 };

export const dispatcherState = atom<Dispatcher>({
  key: 'dispatcherState',
  default: {} as Dispatcher,
});

export const systemIntentsState = atom<IntentEntryModel[]>({
  key: 'systemIntentsState',
  default: [],
});

export const systemIntentGroupsState = atom<GroupEntryModel[]>({
  key: 'systemIntentGroupsState',
  default: [],
});

export const currentScenarioStructureState = atom<DefaultScenarioSchema | undefined>({
  key: 'currentScenarioStructureState',
  default: undefined,
});

export const currentScenarioValidationResultState = atom<IValidatedStructure | undefined>({
  key: 'currentScenarioValidationResultState',
  default: undefined,
});

export const scenarioStructureStackState = atom<(DefaultScenarioSchema | undefined)[]>({
  key: 'scenarioStructureStackState',
  default: [],
});

export const scenarioStructureStackIndexState = atom<number>({
  key: 'scenarioStructureStackIndexState',
  default: -1,
});

export const botState = atom<SingleBotModel | undefined>({
  key: 'botState',
  default: undefined,
});

export const workingSpaceTransformState = atom<IWorkingSpaceTransform>({
  key: 'workingSpaceTransformState',
  default: DEFAULT_WORKING_SPACE_TRANSFORM,
});

export const workingSpaceTransformAnimationState = atom<boolean>({
  key: 'workingSpaceTransformAnimationState',
  default: false,
});

// NOTE: Zoom хранится в отдельном атоме для повышения производительности panning'а
export const zoomState = atom<number>({
  key: 'zoomState',
  default: DEFAULT_WORKING_SPACE_TRANSFORM.zoom,
});

// NOTE: зум для Draggable элементов хранится в отдельном атоме для повышения производительности panning'а
export const zoomForDraggableState = atom<number>({
  key: 'zoomForDraggableState',
  default: DEFAULT_WORKING_SPACE_TRANSFORM.zoom,
});

export const outputConnectionPositionsState = atomFamily<IOutputConnectionPosition | undefined, string>({
  key: 'outputConnectionPositionsState',
  default: () => {
    return undefined;
  },
});

export const inputConnectionPositionsState = atomFamily<IInputConnectionPosition | undefined, string>({
  key: 'inputConnectionPositionsState',
  default: () => {
    return undefined;
  },
});

export const groupPositionsState = atomFamily<IPosition | undefined, string>({
  key: 'groupPositionsState',
  default: () => {
    return undefined;
  },
});

export const selectedEntityState = atom<EntitySchema | undefined>({
  key: 'selectedEntityState',
  default: undefined,
});

export const selectedValidationIssueState = atom<IValidationIssue | undefined>({
  key: 'selectedValidationIssueState',
  default: undefined,
});

export const dragSourceState = atom<EntitySchema | undefined>({
  key: 'dragSourceState',
  default: undefined,
});

export const dragTargetState = atom<EntitySchema | undefined>({
  key: 'dragTargetState',
  default: undefined,
});

export const draggingBindingStartPositionState = atom<IEntityPosition | undefined>({
  key: 'draggingBindingStartPositionState',
  default: undefined,
});

export const draggingBindingCurrentPositionState = atom<IPosition | undefined>({
  key: 'draggingBindingCurrentPositionState',
  default: undefined,
});

export const groupDraggingState = atom<IGroupDraggingInfo | undefined>({
  key: 'groupDraggingState',
  default: undefined,
});

export const capturedBindingState = atom<ICapturedBindingInfo | undefined>({
  key: 'capturedBindingState',
  default: undefined,
});

export const groupPlaceholderPositionState = atom<IPosition | undefined>({
  key: 'groupPlaceholderPositionState',
  default: undefined,
});

export const groupPlaceholderPossiblePositionState = atom<IPosition | undefined>({
  key: 'groupPlaceholderPossiblePositionState',
  default: undefined,
});

export const groupsMenuIsVisibleState = atomFamily<boolean, string>({
  key: 'groupsMenuIsVisibleState',
  default: () => {
    return false;
  },
});
