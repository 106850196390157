import React from 'react';
import { Card } from 'antd';

import { ListScenarioTemplateModel } from '../../../../../api';
import SbIcon from '../../common/SbIcon';

import './index.less';

interface ISbSelectScenarioCardProps {
  scenario: ListScenarioTemplateModel;
  isSelected: boolean;
  onCardClick: () => void;
}

const SbSelectScenarioCard: React.FC<ISbSelectScenarioCardProps> = ({
  scenario,
  isSelected,
  onCardClick = () => {},
}) => {
  const classes = ['sb-select-scenario-card', isSelected ? 'sb-select-scenario-card_selected' : ''];
  return (
    <Card hoverable className={classes.join(' ')} onClick={() => onCardClick()}>
      <div className="sb-select-scenario-card__container">
        <div className="sb-select-scenario-card__container__first-row">
          <div className="sb-select-scenario-card__container__first-row__title">{scenario.name}</div>
          <div className="sb-checkmark">{isSelected && <SbIcon iconName="check-small" size={20} />}</div>
        </div>
        <div className="sb-select-scenario-card__container__description">{scenario.description}</div>
      </div>
    </Card>
  );
};

export default SbSelectScenarioCard;
