import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from '../../types';

export const ImageDefaultIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          clipRule="evenodd"
          d="M8.33203 0C5.57061 0 3.33203 2.23858 3.33203 5V35C3.33203 37.7614 5.57061 40 8.33203 40H31.6654C34.4268 40 36.6654 37.7614 36.6654 35V5C36.6654 4.94547 36.6645 4.89115 36.6628 4.83704V6.66667L29.9961 0H8.33203Z"
          fill="#EDFAFF"
          fillRule="evenodd"
        />
        <path clipRule="evenodd" d="M30 0V6.66667H36.6667L30 0Z" fill="#C2CDDE" fillRule="evenodd" />
        <path
          d="M14.1693 28.3346H25.8359C26.2962 28.3346 26.6693 27.9616 26.6693 27.5013V15.8346H22.5026V11.668H14.1693C13.709 11.668 13.3359 12.0411 13.3359 12.5013V27.5013C13.3359 27.9616 13.709 28.3346 14.1693 28.3346Z"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M22.5 11.668L26.6667 15.8346"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M17.5026 18.7513C18.4231 18.7513 19.1693 18.0051 19.1693 17.0846C19.1693 16.1642 18.4231 15.418 17.5026 15.418C16.5821 15.418 15.8359 16.1642 15.8359 17.0846C15.8359 18.0051 16.5821 18.7513 17.5026 18.7513Z"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
        <path
          d="M16.25 21.6667V25.4167H23.75V18.75L19.7873 23.125L16.25 21.6667Z"
          stroke="#075AB3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="40" transform="translate(3.33594)" width="33.3333" />
        </clipPath>
      </defs>
    </svg>
  );
});
