import axios, { AxiosError, AxiosInstance } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { EventBus, EventType } from '../inbox/events';

export const DEFAULT_ERROR_NAME = 'Error';
export const SERVER_ERROR_MESSAGE = 'Внутренняя ошибка сервера';
export const NETWORK_ERROR_MESSAGE = 'Network Error';
const IDENTITY_ERROR_CODES = {
  DuplicateUserName: 'identity_DuplicateUserName',
};

export const isNetworkError = (error?: Error): boolean => {
  return error?.message === NETWORK_ERROR_MESSAGE;
};

export const isAxiosError = (error?: unknown): boolean => {
  if (!error) {
    return false;
  }

  const axiosError = error as AxiosError;
  return axiosError.isAxiosError;
};

export const isUnprocessableEntityError = (error: Error): boolean => {
  const axiosError = error as AxiosError;
  return axiosError.isAxiosError && axiosError.response?.status === StatusCodes.UNPROCESSABLE_ENTITY;
};

export const isInvalidCastError = (error: Error): boolean => {
  const axiosError = error as AxiosError;
  return axiosError.response?.data?.title === 'InvalidCast';
};

export const tryGetValidationErrorMessage = (error: Error, fieldName: string): string | undefined =>
  (error as AxiosError)?.response?.data?.errors?.[fieldName]?.[0];

const tryExtractErrorsFirstMessage = (errorData?: { [key: string]: string }) => {
  let errors = Object.values(errorData?.errors || {});

  if (!errors.length) {
    errors = Object.keys(errorData || {})
      .map((key) => (key.startsWith('identity_') ? errorData?.[key] : ''))
      .filter((str) => !!str)
      .map((str) => str as string);
  }

  return errors.length ? errors[0]?.toString() : errorData;
};

export const getErrorMessage = (error: Error): string => {
  let message = '';

  const axiosError = error as AxiosError;
  if (axiosError.isAxiosError && axiosError.response) {
    message = isUnprocessableEntityError(error)
      ? tryExtractErrorsFirstMessage(axiosError.response.data)
      : axiosError.response.status <= StatusCodes.INTERNAL_SERVER_ERROR
      ? axiosError.response.data.detail || axiosError.response.data || SERVER_ERROR_MESSAGE
      : SERVER_ERROR_MESSAGE;
  }

  return message || error.message || String(error);
};

export const hasDuplicateUserName = (error: Error): boolean => {
  const axiosError = error as AxiosError;
  return (
    axiosError.isAxiosError &&
    isUnprocessableEntityError(error) &&
    axiosError.response?.data[IDENTITY_ERROR_CODES.DuplicateUserName]
  );
};

const addInboxNetworkConnectionChecker = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (res) => {
      EventBus.instance.emit(EventType.NetworkConnectionSuccess, { result: res });
      return res;
    },
    (error) => {
      if (isNetworkError(error)) {
        EventBus.instance.emit(EventType.NetworkConnectionFailure, { error });
      } else {
        EventBus.instance.emit(EventType.NetworkConnectionSuccess, { result: error });
      }
      throw error;
    }
  );
};

addInboxNetworkConnectionChecker(axios);
