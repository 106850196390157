import React from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

import './index.less';

const MAIN_CLASS_NAME = 'ib-switch';

export type IIbSwitchProps = SwitchProps;

const IbSwitch: React.FC<IIbSwitchProps> = ({ className, ...otherProps }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  return <Switch {...otherProps} className={classes.join(' ')} />;
};

export default IbSwitch;
