import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';

import {
  InboxChannelOperatorsRoutingType,
  InboxChannelRoutingOperatorsModel,
  InboxOperatorGroupModel,
} from '../../../../api';
import IbButton from '../common/IbButton';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import IbSelect from '../common/IbSelect';
import IbOperatorGroupsWidget from '../IbOperatorGroupsWidget';
import { includesIgnoreCase } from '../../../utils/stringUtil';

import { DIALOGS_PER_OPERATOR_CLASS_NAME, FORM_ITEM_CLASS_NAME } from './const';

interface IOperatorsTabProps {
  operators: InboxChannelRoutingOperatorsModel;
  groupList?: InboxOperatorGroupModel[];
  onChange: (operators: InboxChannelRoutingOperatorsModel) => void;
}

const OperatorsTab: React.FC<IOperatorsTabProps> = ({ operators, groupList, onChange }) => {
  const { t } = useTranslation();

  const [defaultGroupSearchText, setDefaultGroupSearchText] = useState('');

  const defaultGroup = {
    id: 'default-operator-group-id',
    name: t('All operators'),
  } as InboxOperatorGroupModel;

  const routingTypeIsAuto = operators.routingType === InboxChannelOperatorsRoutingType.Auto;
  const groupListWithDefault = [defaultGroup, ...(groupList || [])] as InboxOperatorGroupModel[];
  const filteredGroupList = groupListWithDefault.filter((g) => includesIgnoreCase(g.name, defaultGroupSearchText));

  const onDefaultGroupSearch = (searchText: string) => setDefaultGroupSearchText(searchText);

  const onRoutingTypeChange = (value: SelectValue) =>
    onChange({
      ...operators,
      routingType: value as InboxChannelOperatorsRoutingType,
    });

  const onMaxChatCountPerOperatorChange = (value: string) => {
    const parsedValue = Number.parseInt(value);

    onChange({
      ...operators,
      maxChatCountPerOperator: value.length ? (Number.isNaN(parsedValue) ? 0 : parsedValue || 0) : undefined,
    });
  };

  const onDecreaseButtonClick = () =>
    onChange({
      ...operators,
      maxChatCountPerOperator: operators.maxChatCountPerOperator ? operators.maxChatCountPerOperator - 1 : 0,
    });

  const onIncreaseButtonClick = () =>
    onChange({
      ...operators,
      maxChatCountPerOperator: operators.maxChatCountPerOperator ? operators.maxChatCountPerOperator + 1 : 1,
    });

  const onDefaultGroupChange = (value: SelectValue) => {
    const groupId = value as string;
    const group = groupId === defaultGroup.id ? undefined : groupList?.find((g) => g.id === groupId);
    onChange({
      ...operators,
      defaultGroup: group ? { ...group, operators: [] } : undefined,
    });
  };

  const onAdditionalGroupsChange = (groups: InboxOperatorGroupModel[]) => {
    onChange({
      ...operators,
      additionalGroups: groups.map((g) => ({ ...g, operators: [] })),
    });
  };

  return (
    <>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Operator routing type')}</IbTypography.Paragraph>
        <IbSelect
          options={[
            { value: InboxChannelOperatorsRoutingType.Initiative, label: t('Initiative') },
            { value: InboxChannelOperatorsRoutingType.Auto, label: t('Auto') },
          ]}
          value={operators.routingType}
          onChange={onRoutingTypeChange}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Dialogues per operator')}</IbTypography.Paragraph>
        <div className={DIALOGS_PER_OPERATOR_CLASS_NAME}>
          <IbButton disabled={!routingTypeIsAuto} type="fill" onClick={onDecreaseButtonClick}>
            -
          </IbButton>
          <IbInput
            disabled={!routingTypeIsAuto}
            value={operators.maxChatCountPerOperator?.toString()}
            onChange={onMaxChatCountPerOperatorChange}
          />
          <IbButton disabled={!routingTypeIsAuto} type="fill" onClick={onIncreaseButtonClick}>
            +
          </IbButton>
        </div>
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Default group')}</IbTypography.Paragraph>
        <IbSelect
          showSearch
          options={filteredGroupList.map((group) => ({ value: group.id, label: group.name }))}
          value={operators.defaultGroup?.id || defaultGroup.id}
          onChange={onDefaultGroupChange}
          onSearch={onDefaultGroupSearch}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Additional groups')}</IbTypography.Paragraph>
        <IbOperatorGroupsWidget
          groupList={groupList}
          readonly={false}
          selectedGroups={operators.additionalGroups}
          onChange={onAdditionalGroupsChange}
        />
      </div>
    </>
  );
};

export default OperatorsTab;
