import React from 'react';
import { Menu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';

import './index.less';

export interface ISbItemProps extends MenuItemProps {
  sbType?: 'link' | 'dropdown';
}

const SbItem: React.FC<ISbItemProps> = ({ sbType, children, ...otherProps }) => {
  const classNames = ['sb-menu__item'];
  if (sbType) {
    classNames.push(`sb-menu__item_${sbType}`);
  }

  return (
    <Menu.Item className={classNames.join(' ')} {...otherProps}>
      {children}
    </Menu.Item>
  );
};

export default SbItem;
