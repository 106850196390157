import React, { ReactNode, useRef, useState } from 'react';
import './index.less';

import DropdownMenu from '../DropdownMenu';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

interface IEditorSelectProps {
  id: string;
  title: ReactNode;
  isValid: boolean;
  menuContent?: ReactNode;
}

const EditorSelect: React.FC<IEditorSelectProps> = ({ id, title, isValid, menuContent }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const onButtonClick = () => {
    setMenuIsVisible(!menuIsVisible);
  };

  const classes = ['editor-select'];
  if (!isValid) {
    classes.push('editor-select_warning');
  }

  return (
    <div className={classes.join(' ')} id={id}>
      <div ref={ref} className="editor-select__select" onClick={onButtonClick}>
        <span>{title}</span>
        {menuIsVisible ? <SbIcon iconName="up" size={20} /> : <SbIcon iconName="down" size={20} />}
      </div>
      <DropdownMenu
        isVisible={menuIsVisible}
        placement="down"
        size="small"
        triggerElement={ref.current}
        onClose={() => setMenuIsVisible(false)}
      >
        {menuContent}
      </DropdownMenu>
    </div>
  );
};

export default EditorSelect;
