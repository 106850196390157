import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import SbButton from '../../../components/common/SbButton';
import { SingleKnowledgeBaseModel } from '../../../../../kb-api';
import SbTypography from '../../../components/common/SbTypography';
import SbModal from '../../../components/common/SbModal';

interface ISaveButtonProps {
  knowledgeBase?: SingleKnowledgeBaseModel;
  dataUpdating: boolean;
  onSave: () => Promise<void>;
  onRollback: () => Promise<void>;
}

const SaveButton: React.FC<ISaveButtonProps> = ({ knowledgeBase, dataUpdating, onSave, onRollback }) => {
  const { push, block } = useHistory();

  const [blockedPath, setBlockedPath] = useState('');
  const [forceClose, setForceClose] = useState(false);
  const [closingModalVisible, setClosingModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [closing, setClosing] = useState(false);

  const hasChanges = !!knowledgeBase?.draftStage;

  const onSaveButtonClick = async () => {
    if (!hasChanges || dataUpdating) return;

    setLoading(true);
    await onSave();
    setLoading(false);
  };

  useEffect(() => {
    const unblock = block((prompt) => {
      if (forceClose) return;
      if (hasChanges) {
        setClosingModalVisible(true);
        setBlockedPath(prompt.pathname);
        return false;
      }
    });

    return () => unblock();
  });

  const onCancelClosingButtonClick = () => setClosingModalVisible(false);

  const onCloseWithoutSavingButtonClick = async () => {
    setForceClose(true);
    setClosing(true);
    await onRollback();
    setClosing(false);
    setTimeout(() => push(blockedPath));
  };

  return (
    <>
      <SbButton loading={loading} onClick={onSaveButtonClick}>
        Сохранить{hasChanges ? '*' : ''}
      </SbButton>
      <SbModal
        footer={[
          <SbButton
            key="close-without-saving"
            disabled={closing}
            sbSize="medium"
            sbType="secondary"
            onClick={onCloseWithoutSavingButtonClick}
          >
            Закрыть без сохранения
          </SbButton>,
          <SbButton key="cancel" sbSize="medium" onClick={onCancelClosingButtonClick}>
            Отмена
          </SbButton>,
        ]}
        sbSize="small"
        title="Закрыть карточку базы знаний?"
        visible={closingModalVisible}
        width={480}
        onCancel={onCancelClosingButtonClick}
        onOk={onCloseWithoutSavingButtonClick}
      >
        <SbTypography>
          <p className="sb-typography__paragraph_lead">Изменения не будут сохранены.</p>
        </SbTypography>
      </SbModal>
    </>
  );
};

export default SaveButton;
