import React, { useState } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { Flags } from 'react-feature-flags';

import './index.less';

import SbIcon from '../common/SbIcon';
import { getProfile, logoutUser, SIMPLE_BOT_DEFAULT_PATH } from '../../../utils/oidcUtil';
import SbUserModal from '../SbUserModal';
import { FeatureFlagNames } from '../../../constants';

const onLogoutClick = async () => {
  await logoutUser({ state: SIMPLE_BOT_DEFAULT_PATH });
};

const onSupportClick = (url?: string) => () => {
  // eslint-disable-next-line security/detect-non-literal-fs-filename
  url && window.open(url, '_blank');
};

const acronymize = (text: string) => {
  return text
    .split(/\s/)
    .map((word) => {
      return word.length ? word.charAt(0) : '';
    })
    .join('');
};

const SbUserInfo: React.FC = () => {
  const [userModalVisible, setUserModalVisible] = useState(false);

  const profile = getProfile();
  const originalHost = profile.originalHost;
  const supportUrl = originalHost ? `https://${originalHost}/messages/support` : undefined;

  const onSettingsClick = () => setUserModalVisible(true);
  const onUserModalClose = () => setUserModalVisible(false);

  const userMenu = (
    <Menu>
      <Flags authorizedFlags={[FeatureFlagNames.USERS_MANAGEMENT]}>
        <Menu.Item key="settings" icon={<SbIcon iconName="setting-two" />} onClick={onSettingsClick}>
          Настройки
        </Menu.Item>
      </Flags>
      {supportUrl && (
        <Menu.Item key="support" icon={<SbIcon iconName="lifebuoy" />} onClick={onSupportClick(supportUrl)}>
          Техническая поддержка
        </Menu.Item>
      )}
      <Menu.Item key="logout" icon={<SbIcon iconName="logout" />} onClick={onLogoutClick}>
        Выйти
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={userMenu} overlayClassName="sb-user-profile-menu" trigger={['click']}>
        <div className="sb-user-profile">
          <Avatar size="large">{acronymize(profile.userName)}</Avatar>
          <SbIcon iconName="down" />
        </div>
      </Dropdown>
      <SbUserModal visible={userModalVisible} onClose={onUserModalClose} />
    </>
  );
};

export default SbUserInfo;
