import { LIVE_CHAT_SETTINGS_DEFAULT } from '../../../constants';

export const MAIN_CLASS_NAME = 'ib-channel-edit-modal';
export const WIDE_CLASS_NAME = `${MAIN_CLASS_NAME}_wide`;
export const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;
export const FORM_ITEM_SWITCH_CLASS_NAME = `${FORM_ITEM_CLASS_NAME}__switch`;
export const FORM_GROUP_CLASS_NAME = `${MAIN_CLASS_NAME}__form-group`;
export const CODE_SNIPPET_CLASS_NAME = `${MAIN_CLASS_NAME}__code-snippet`;
export const INSTRUCTION_CLASS_NAME = `${MAIN_CLASS_NAME}__instruction`;
export const CHANNEL_SELECTION_CLASS_NAME = `${MAIN_CLASS_NAME}_channel-selection`;
export const CHANNEL_SELECTION_LIST_CLASS_NAME = `${CHANNEL_SELECTION_CLASS_NAME}__list`;
export const CHANNEL_SELECTION_LIST_ITEM_CLASS_NAME = `${CHANNEL_SELECTION_LIST_CLASS_NAME}__item`;

export const LIVECHAT_CLASS_NAME = `${MAIN_CLASS_NAME}__livechat`;
export const SETTINGS_CLASS_NAME = `${LIVECHAT_CLASS_NAME}__settings`;
export const PREVIEW_MOBILE_TOGGLE_CLASS_NAME = `${LIVECHAT_CLASS_NAME}__preview-mobile-toggle`;
export const PREVIEW_CLASS_NAME = `${LIVECHAT_CLASS_NAME}__preview`;
export const PREVIEW_MOBILE_VISIBLE_CLASS_NAME = `${PREVIEW_CLASS_NAME}_mobile-visible`;
export const SUBTITLE_CLASS_NAME = `${SETTINGS_CLASS_NAME}__subtitle`;

export const DESIGN_COLOR_LIST = ['#096DD9', '#48A15C', '#FCA73B', '#262626', '#FF2D38'];
export const BACKGROUND_COLOR_LIST = ['#FFFFFF', '#F7F7F7', '#FFF9ED', '#EDFAFF', '#F3FAF4'];
export const USER_BUBBLE_BACKGROUND_COLOR_LIST = ['#D7F2FF', '#E3F5E6', '#FFF2D5', '#E3E3E3', '#FFDEE0'];
export const SHOW_CODE_COPIED_TIMEOUT = 2000; // ms
export const CODE_TEXTAREA_ROWS = 15;

export const PREVIEW_MAIN_CLASS_NAME = 'elma-bot-live-chat';
export const PREVIEW_TOGGLE_BUTTON_CLASS_NAME = `${PREVIEW_MAIN_CLASS_NAME}__toggle-button`;
export const PREVIEW_CHAT_CONTAINER_CLASS_NAME = `${PREVIEW_MAIN_CLASS_NAME}__chat-container`;
export const PREVIEW_WEB_CHAT_CONTAINER_CLASS_NAME = `${PREVIEW_CHAT_CONTAINER_CLASS_NAME}__web-chat`;
export const PREVIEW_TITLE_CLASS_NAME = `${PREVIEW_CHAT_CONTAINER_CLASS_NAME}__title`;
export const PREVIEW_TITLE_BOT_ICON_CONTAINER_CLASS_NAME = `${PREVIEW_TITLE_CLASS_NAME}__bot-icon`;
export const PREVIEW_TITLE_TEXT_CLASS_NAME = `${PREVIEW_TITLE_CLASS_NAME}__text`;
export const PREVIEW_TITLE_CLOSE_ICON_CONTAINER_CLASS_NAME = `${PREVIEW_TITLE_CLASS_NAME}__close-icon`;

const BOT_AVATAR_SVG = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M10.2431 21.7482C11.3888 22.0402 13.9336 22.2359 15.2236 22.3226V19.9479C14.1325 19.8644 11.7899 19.6976 10.8304 19.4505C10.5608 19.3831 10.3715 19.136 10.3715 18.8472V15.1632H8V18.8472C8 20.2174 8.92421 21.4112 10.2431 21.7482Z" fill="#5AD2FF"/><path d="M21.876 8.63248C20.2651 8.22172 17.9032 8.0035 16.058 8.0035V10.375C17.7075 10.375 19.864 10.5675 21.2856 10.9334C21.5551 11.0008 21.7445 11.2511 21.7445 11.5399V15.1693H24.116V11.5335C24.1192 10.1632 23.1982 8.96943 21.876 8.63248Z" fill="#FABE00"/><path d="M16.0612 8V10.3715C14.4117 10.3715 12.2552 10.564 10.8304 10.9267C10.5608 10.9973 10.3715 11.2444 10.3715 11.5332V15.1626L8 12.7911V11.5332C8 10.1629 8.921 8.96914 10.2431 8.63219C11.8541 8.21822 14.216 8 16.0612 8Z" fill="#E62D32"/><path fill-rule="evenodd" clip-rule="evenodd" d="M21.9015 21.8103C21.9027 21.8098 21.9038 21.8094 21.905 21.8089C23.1116 21.2505 24.1192 20.4129 24.1192 18.8469V17.5312L21.7477 15.1597V18.2084C21.746 18.8759 21.692 19.1539 21.0738 19.4599L15.2237 22.3256V25.1399L21.9015 21.8103Z" fill="#5082E6"/><circle cx="16" cy="16" r="15.5" stroke="#E7E7E7"/></svg>`;
const ENCODED_BOT_AVATAR_SVG = window.btoa(BOT_AVATAR_SVG);
export const WEB_CHAT_STYLE_OPTIONS = {
  primaryFont: 'Calibri, "Helvetica Neue", Arial, "sans-serif"',
  backgroundColor: 'transparent',
  bubbleBackground: '#E3E3E3',
  bubbleBorderWidth: 0,
  bubbleBorderRadius: 10,
  bubbleFromUserBackground: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  bubbleFromUserTextColor: '#FFFFFF',
  bubbleFromUserBorderWidth: 0,
  bubbleFromUserBorderRadius: 10,
  hideUploadButton: false,
  avatarSize: 36,
  avatarBorderRadius: '50%',
  botAvatarInitials: '',
  botAvatarImage: `data:image/svg+xml;base64,${ENCODED_BOT_AVATAR_SVG}`,
  botAvatarBackgroundColor: '#FFFFFF',
  sendBoxBackground: '#FFFFFF',
  sendBoxPlaceholderColor: '#A4A4A4',
  sendBoxButtonColor: '#A4A4A4',
  sendBoxButtonColorOnDisabled: '#CCCCCC',
  sendBoxButtonColorOnFocus: '#A4A4A4',
  sendBoxButtonColorOnHover: '#A4A4A4',
  sendBoxBorderTop: 'solid 1px #E3E3E3',
  sendBoxBorderBottom: 'none',
  sendBoxBorderLeft: 'none',
  sendBoxBorderRight: 'none',
  sendTimeout: 60000, // ms
  suggestedActionBorderRadius: 20,
  suggestedActionBorderColor: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionBorderColorOnActive: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionBorderColorOnFocus: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionBorderColorOnHover: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionTextColor: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionTextColorOnActive: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionTextColorOnFocus: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionTextColorOnHover: LIVE_CHAT_SETTINGS_DEFAULT.chat.primaryColor,
  suggestedActionBackgroundColor: '#FFFFFF',
  suggestedActionBackgroundColorOnActive: '#FFFFFF',
  suggestedActionBackgroundColorOnFocus: '#FFFFFF',
  suggestedActionBackgroundColorOnHover: '#FFFFFF',
};
