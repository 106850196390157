import React from 'react';
import { AtomicBlockUtils, convertToRaw, DraftDecorator, EditorState, Modifier, RichUtils } from 'draft-js';
import { draftjsToMd } from 'draftjs-md-converter';

const extraMarkdownDict = {
  BOLD: '**',
  ITALIC: '_',
};

export enum InlineStyles {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
}

export enum BlockTypes {
  H1 = 'header-one',
  H2 = 'header-two',
  H3 = 'header-three',
  H4 = 'header-four',
  H5 = 'header-five',
  H6 = 'header-six',
  ORDERED_LIST = 'ordered-list-item',
  UNORDERED_LIST = 'unordered-list-item',
  BLOCKQUOTE = 'blockquote',
  VARIABLE = 'variable',
}

export enum EntityType {
  LINK = 'LINK',
  IMAGE = 'IMAGE',
  VARIABLE = 'VARIABLE',
  MENTION = 'mention', // NOTE: в плагине draftjs/mentions используется написание строчными буквами
}

export enum EntityMutability {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
}

export const editorStateToMarkdown = (editorState: EditorState): string => {
  const content = editorState.getCurrentContent();
  const rawObject = convertToRaw(content);
  // NOTE: чтобы конвертер не падал, т.к. иногда изображение удаляется некорректно
  rawObject.blocks = rawObject.blocks.filter((b) => b.type !== 'atomic' || b.text !== '');
  return draftjsToMd(rawObject, extraMarkdownDict);
};

export const getLinkDecorator: () => DraftDecorator = () => ({
  strategy: (block, callback, contentState) => {
    block.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityType.LINK;
    }, callback);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return <a href={url}>{props.children}</a>;
  },
});

export const getImageDecorator: () => DraftDecorator = () => ({
  strategy: (block, callback, contentState) => {
    block.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityType.IMAGE;
    }, callback);
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: (props: any) => {
    const { url, fileName } = props.contentState.getEntity(props.entityKey).getData();
    return <img alt={fileName} src={url} width="220" />;
  },
});

export const toggleInlineStyle = (editorState: EditorState, style: string): EditorState => {
  const currentSelection = editorState.getSelection();
  const styledEditorState = RichUtils.toggleInlineStyle(editorState, style);
  const restoredEditorState = EditorState.acceptSelection(styledEditorState, currentSelection);
  return EditorState.forceSelection(restoredEditorState, restoredEditorState.getSelection());
};

export const toggleBlockType = (editorState: EditorState, blockType: string): EditorState => {
  const currentSelection = editorState.getSelection();
  const styledEditorState = RichUtils.toggleBlockType(editorState, blockType);
  const restoredEditorState = EditorState.acceptSelection(styledEditorState, currentSelection);
  return EditorState.forceSelection(restoredEditorState, restoredEditorState.getSelection());
};

export const addLink = (editorState: EditorState, link: string): EditorState => {
  const currentSelection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(EntityType.LINK, EntityMutability.MUTABLE, { url: link });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, currentSelection, entityKey);
  const newEditorState = EditorState.push(editorState, contentStateWithLink, 'insert-characters');
  return RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
};

export const addImage = (editorState: EditorState, url: string, fileName: string): EditorState => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(EntityType.IMAGE, EntityMutability.IMMUTABLE, {
    url,
    fileName,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
};
