import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './index.less';

import { TagModel, TagStatus } from '../../../../api';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import { includesIgnoreCase } from '../../../utils/stringUtil';
import IbIcon from '../common/IbIcon';
import IbButton from '../common/IbButton';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';

import {
  BUTTONS_CLASS_NAME,
  EMPTY_LIST_CLASS_NAME,
  EMPTY_SEARCH_LIST_CLASS_NAME,
  MAIN_CLASS_NAME,
  TAG_LIST_CLASS_NAME,
  TAG_LIST_ITEM_CLASS_NAME,
  TAG_LIST_ITEM_SELECTED_CLASS_NAME,
  TAG_LIST_ITEM_TAG_CLASS_NAME,
} from './const';

const TAG_LIST_DEFAULT = [] as TagModel[];
const SELECTED_TAG_IDS_DEFAULT = [] as string[];
const ALLOW_TAGS_MANAGEMENT_DEFAULT = false;

export interface IIbTagsSelectorProps {
  tagList?: TagModel[];
  selectedTagIds?: string[];
  allowTagsManagement?: boolean;
  onChange?: (selectedTagIds: string[]) => Promise<void>;
  onTagsManagement?: () => void;
}

const IbTagsSelector: React.FC<IIbTagsSelectorProps> = ({
  tagList = TAG_LIST_DEFAULT,
  selectedTagIds = SELECTED_TAG_IDS_DEFAULT,
  allowTagsManagement = ALLOW_TAGS_MANAGEMENT_DEFAULT,
  onChange,
  onTagsManagement,
}) => {
  tagList = getDefaultIfUndefined(tagList, TAG_LIST_DEFAULT);
  selectedTagIds = getDefaultIfUndefined(selectedTagIds, SELECTED_TAG_IDS_DEFAULT);
  allowTagsManagement = getDefaultIfUndefined(allowTagsManagement, ALLOW_TAGS_MANAGEMENT_DEFAULT);

  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [innerSelectedTagIds, setInnerSelectedTagIds] = useState(selectedTagIds);

  const filteredTagList = (tagList || []).filter(
    (tag) => tag.status === TagStatus.Visible && includesIgnoreCase(tag.label, search)
  );

  const onTagListItemClick = (tag: TagModel) => () => {
    if (innerSelectedTagIds.includes(tag.id)) {
      setInnerSelectedTagIds(innerSelectedTagIds.filter((id) => id !== tag.id));
    } else {
      setInnerSelectedTagIds([...innerSelectedTagIds, tag.id]);
    }
  };

  const onDoneButtonClick = async () => {
    await onChange?.(innerSelectedTagIds);
  };

  const onTagsManagementButtonClick = () => {
    onTagsManagement?.();
  };

  const onSelectedTagIdsPropChange = () => {
    setInnerSelectedTagIds(selectedTagIds);
  };
  useEffect(onSelectedTagIdsPropChange, [selectedTagIds]);

  if (!tagList.length) {
    return (
      <div className={MAIN_CLASS_NAME}>
        <div className={EMPTY_LIST_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="secondary">
            {t('List of available tags is empty.')}
          </IbTypography.Paragraph>
          {allowTagsManagement && (
            <>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Create new tags in the administration section.')}
              </IbTypography.Paragraph>
              <IbButton icon={<IbIcon iconName="setting-two" />} type="fill" onClick={onTagsManagementButtonClick}>
                {t('Tags administration')}
              </IbButton>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbInput placeholder={t('Tag search')} value={search} onChange={setSearch} />
      {!filteredTagList.length ? (
        <div className={EMPTY_SEARCH_LIST_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="secondary">
            {t('Nothing was found.')}
            <br />
            {t('Change the request or clear the search field.')}
          </IbTypography.Paragraph>
        </div>
      ) : (
        <div className={TAG_LIST_CLASS_NAME}>
          {filteredTagList.map((tag) => {
            const itemClasses = [TAG_LIST_ITEM_CLASS_NAME];
            innerSelectedTagIds.includes(tag.id) && itemClasses.push(TAG_LIST_ITEM_SELECTED_CLASS_NAME);

            return (
              <div key={tag.id} className={itemClasses.join(' ')} onClick={onTagListItemClick(tag)}>
                <IbIcon iconName="check-small" size={20} />
                <span className={TAG_LIST_ITEM_TAG_CLASS_NAME} style={{ background: tag.color }} />
                <IbTypography.Paragraph type="secondary">{tag.label}</IbTypography.Paragraph>
              </div>
            );
          })}
        </div>
      )}
      <div className={BUTTONS_CLASS_NAME}>
        <IbButton type="secondary" onClick={onDoneButtonClick}>
          {t('Done')}
        </IbButton>
        {allowTagsManagement && (
          <IbButton icon={<IbIcon iconName="setting-two" />} type="fill" onClick={onTagsManagementButtonClick} />
        )}
      </div>
    </div>
  );
};

export default IbTagsSelector;
