import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Checkbox, Col, Divider, PageHeader, Row, Spin, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { CommentOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { AlertTypes } from '../../../constants';
import { knowledgeBaseKbApi } from '../../../apis';
import { SingleKnowledgeBaseModel } from '../../../../kb-api';
import VersionStatusView from '../../../components/VersionStatusView';
import { alertsSelectorAdd } from '../../../recoil/alerts';

import VersionsList from './VersionsList';
import DraftsList from './DraftsList';

const { Paragraph } = Typography;

const KnowledgeBaseCard: React.FC = () => {
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [knowledgeBase, setKnowledgeBase] = useState<SingleKnowledgeBaseModel>();

  const loadDataAsync = async () => {
    try {
      const response = await knowledgeBaseKbApi.getKnowledgeBase(id);
      setKnowledgeBase(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке базы знаний',
        error: e,
      });
    }
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [id]);

  if (!knowledgeBase) {
    return <Spin />;
  }

  const onEdit = () => push(`/knowledge-bases/${knowledgeBase.entry.id}/edit`);

  const onPublish = () => {
    loadDataAsync().finally();
  };

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="edit" onClick={onEdit}>
            Редактировать
          </Button>,
        ]}
        title={
          <Fragment>
            <CommentOutlined /> {knowledgeBase.entry.name}
          </Fragment>
        }
      />
      <Paragraph>{knowledgeBase.entry.description || 'Без описания'}</Paragraph>
      <Row gutter={24}>
        <Col md={{ span: 12 }} xs={{ span: 24 }}>
          <Card bordered={false} title={knowledgeBase.originStage.name}>
            <Paragraph>
              <Checkbox disabled checked={knowledgeBase.originStage.recognizerSettings?.searchParentsOnlyIfNoContext}>
                Поиск без уточняющих вопросов (только по корневым)
              </Checkbox>
            </Paragraph>
            <VersionStatusView
              showVersionTitle
              testingChatTitle={knowledgeBase.originStage.name}
              versionId={knowledgeBase.originStage.versionId}
            />
          </Card>
        </Col>
        {knowledgeBase.draftStage && (
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <Card bordered={false} title={knowledgeBase.draftStage.name}>
              <Paragraph>
                <Checkbox disabled checked={knowledgeBase.draftStage.recognizerSettings?.searchParentsOnlyIfNoContext}>
                  Поиск без уточняющих вопросов (только по корневым)
                </Checkbox>
              </Paragraph>
              <VersionStatusView
                showVersionTitle
                testingChatTitle={knowledgeBase.draftStage.name}
                versionId={knowledgeBase.draftStage.versionId}
              />
            </Card>
          </Col>
        )}
      </Row>
      <Divider />
      <VersionsList
        draftStage={knowledgeBase.draftStage}
        knowledgeBaseId={id}
        originStage={knowledgeBase.originStage}
        onPublish={onPublish}
      />
      <Divider />
      <DraftsList knowledgeBaseId={id} />
    </div>
  );
};

export default KnowledgeBaseCard;
