import Dexie from 'dexie';

import { InboxParticipantModel } from '../../../api';
import { SOUND_CHORD_OGG, SOUND_CLOUD_OGG, SOUND_POLITE_OGG } from '../components/common/IbSound/const';

import { TableName } from './table';
import { IEntityItem } from './types';

export interface IDbInfo {
  readonly name: string;
}

export const DATABASE_NAME = 'elma.assistant.inbox';

let database: Dexie | undefined = undefined;

type IndexPath = string | string[];

const buildSchema = (key: string, indexes: IndexPath[]) => {
  if (!indexes.length) {
    return key;
  }

  const result = [key];
  for (const index of indexes) {
    result.push(',');
    if (Array.isArray(index)) {
      result.push('[');
      const parts = index as string[];
      for (const part of parts) {
        result.push(part);
        result.push('+');
      }
      if (parts.length) {
        result.pop();
      }
      result.push(']');
    } else {
      result.push(index);
    }
  }
  return result.join('');
};

export const getDatabase = (): Dexie => {
  if (database) {
    return database;
  }

  database = new Dexie(DATABASE_NAME);

  database.version(1).stores({
    [TableName.QueueTasks]: buildSchema('id', [['priority', 'data.timestamp'], 'lockId']),
    [TableName.StorageParticipants]: buildSchema('entity.id', [
      ['order', 'entity.createdOn'],
      'entity.subject.id',
      'entity.subject.role',
    ]),
    [TableName.StorageChats]: buildSchema('entity.id', [['order', 'entity.statistics.lastMessageOn'], 'entity.status']),
    [TableName.StorageMessages]: buildSchema('entity.activity.id', [['order', 'entity.timestamp'], 'entity.chat.id']),
  });

  database.version(2).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности чатов перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageChats).clear();
  });

  database.version(3).stores({
    [TableName.StorageChats]: buildSchema('entity.id', [
      ['order', 'entity.statistics.lastMessage.lastMessageOn'],
      'entity.status',
    ]),
  });

  database.version(4).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
  });

  database
    .version(5)
    .stores({
      [TableName.StorageAttachments]: buildSchema('entity.external.id', [['order', 'entity.createdOn']]),
    })
    .upgrade((trans) => {
      trans.table(TableName.QueueTasks).clear();
    });

  database.version(6).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  database.version(7).stores({
    [TableName.StorageChats]: buildSchema('entity.id', [
      ['order', 'entity.statistics.lastMessage.anyOn'],
      'entity.status',
    ]),
  });

  database.version(8).stores({
    [TableName.StorageMessages]: buildSchema('entity.activity.id', [['order', 'entity.createdOn'], 'entity.chat.id']),
  });

  database.version(9).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  database.version(10).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  database.version(11).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  database.version(12).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  database.version(13).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  database.version(14).upgrade((trans) => {
    trans.table(TableName.StorageParticipants).each((item: IEntityItem<InboxParticipantModel>) => {
      item.entity.settings = {
        notifications: {
          enabled: true,
          chatQueuedSound: SOUND_CLOUD_OGG,
          chatAssignedSound: SOUND_CHORD_OGG,
          messageReceivedSound: SOUND_POLITE_OGG,
        },
      };
    });
  });

  database.version(15).upgrade((trans) => {
    // NOTE: удаляем содержимое локального хранилища, чтобы сущности перезагрузились с сервера с новой схемой.
    trans.table(TableName.StorageParticipants).clear();
    trans.table(TableName.StorageChats).clear();
    trans.table(TableName.StorageMessages).clear();
    trans.table(TableName.StorageAttachments).clear();
  });

  return database;
};
