import React, { useState } from 'react';

import './index.less';

import { TagModel } from '../../../../api';
import IbPopover from '../common/IbPopover';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbTagsSelector from '../IbTagsSelector';

import { MAIN_CLASS_NAME } from './const';

const TAG_LIST_DEFAULT = [] as TagModel[];
const SELECTED_TAG_IDS_DEFAULT = [] as string[];
const ALLOW_TAGS_MANAGEMENT_DEFAULT = false;

export interface IIbTagsPopoverProps {
  children?: React.ReactNode;
  tagList?: TagModel[];
  selectedTagIds?: string[];
  allowTagsManagement?: boolean;
  onChange?: (selectedTagIds: string[]) => Promise<void>;
  onTagsManagement?: () => void;
}

const IbTagsPopover: React.FC<IIbTagsPopoverProps> = ({
  children,
  tagList = TAG_LIST_DEFAULT,
  selectedTagIds = SELECTED_TAG_IDS_DEFAULT,
  allowTagsManagement = ALLOW_TAGS_MANAGEMENT_DEFAULT,
  onChange,
  onTagsManagement,
}) => {
  tagList = getDefaultIfUndefined(tagList, TAG_LIST_DEFAULT);
  selectedTagIds = getDefaultIfUndefined(selectedTagIds, SELECTED_TAG_IDS_DEFAULT);
  allowTagsManagement = getDefaultIfUndefined(allowTagsManagement, ALLOW_TAGS_MANAGEMENT_DEFAULT);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const onSelectorChange = async (selectedTagIds: string[]) => {
    setPopoverVisible(false);
    await onChange?.(selectedTagIds);
  };

  const onSelectorTagsManagement = () => {
    onTagsManagement?.();
    setPopoverVisible(false);
  };

  const onPopoverVisibleChange = (visible?: boolean) => {
    setPopoverVisible(visible || false);
  };

  const renderPopoverContent = () => {
    return (
      <IbTagsSelector
        allowTagsManagement={allowTagsManagement}
        selectedTagIds={selectedTagIds}
        tagList={tagList}
        onChange={onSelectorChange}
        onTagsManagement={onSelectorTagsManagement}
      />
    );
  };

  return (
    <IbPopover
      className={MAIN_CLASS_NAME}
      content={renderPopoverContent()}
      placement="bottomLeft"
      trigger={['click']}
      visible={popoverVisible}
      onVisibleChange={onPopoverVisibleChange}
    >
      {children}
    </IbPopover>
  );
};

export default IbTagsPopover;
