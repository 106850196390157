import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, PageHeader, Tag, Row, Col, Card, List, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useSetRecoilState } from 'recoil';

import { StageModel, StagePublishRequest, StageType, VersionModel } from '../../../../kb-api';
import { stageKbApi, versionKbApi } from '../../../apis';
import { AlertTypes } from '../../../constants';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Paragraph } = Typography;

export interface IVersionsListProps {
  knowledgeBaseId: string;
  originStage?: StageModel;
  draftStage?: StageModel;
  onPublish: () => void;
}

const VersionsList: React.FC<IVersionsListProps> = ({ knowledgeBaseId, originStage, draftStage, onPublish }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [loading, setLoading] = useState(false);
  const [listVersions, setListVersions] = useState([] as VersionModel[]);
  const [total, setTotal] = useState(0);
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 9,
    onChange: (page: number) => {
      setPagination({
        ...pagination,
        current: page,
      });
    },
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await versionKbApi.searchVersions(
        knowledgeBaseId,
        undefined,
        pagination.current - 1,
        pagination.pageSize
      );
      setListVersions(response.data.items || []);
      setTotal(response.data.totalItemCount || 0);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка версий',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, [pagination]);

  const onAddBtnClick = () => {
    history.push(`/knowledge-bases/${knowledgeBaseId}/versions/add`);
  };

  const onVersionCardClick = (version: VersionModel) => {
    history.push(`/knowledge-bases/${knowledgeBaseId}/versions/${version.id}`);
  };

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="add" icon={<PlusCircleOutlined />} type="primary" onClick={onAddBtnClick}>
            Добавить версию
          </Button>,
        ]}
        title="Версии"
      />
      <List<VersionModel>
        dataSource={listVersions}
        grid={{ gutter: 24, column: 3, lg: 2, md: 1, sm: 1, xs: 1 }}
        id="versions-list"
        loading={loading}
        pagination={{ ...pagination, total }}
        renderItem={(item) => {
          const onMenuClick = async (info: MenuInfo) => {
            info.domEvent.stopPropagation();
            if (!item.id) return;
            const stageType = info.key as StageType;

            if (draftStage?.versionId === item.id && stageType === StageType.Draft) {
              addAlert({
                type: AlertTypes.WARNING,
                message: `Версия "${item.name}" уже опубликована в тест`,
                description: '',
              });
              return;
            }

            if (originStage?.versionId === item.id && stageType === StageType.Origin) {
              addAlert({
                type: AlertTypes.WARNING,
                message: `Версия "${item.name}" уже опубликована в релиз`,
                description: '',
              });
              return;
            }

            setLoading(true);
            try {
              const request: StagePublishRequest = {
                versionId: item.id,
              };
              const stageId = stageType === StageType.Origin ? originStage?.id : draftStage?.id;
              await stageKbApi.publishStage(stageId || '-', request);
              addAlert({
                type: AlertTypes.SUCCESS,
                message: `Публикация версии "${item.name}" успешно завершена`,
                description: '',
              });
            } catch (e) {
              addAlert({
                type: AlertTypes.ERROR,
                message: 'Ошибка при публикации версии',
                error: e,
              });
            }
            onPublish();
            loadDataAsync();
          };

          const menu = (
            <Menu onClick={onMenuClick}>
              <Menu.Item key={StageType.Origin}>Опубликовать в релиз</Menu.Item>
              <Menu.Item key={StageType.Draft} disabled={!draftStage}>
                Опубликовать в тест
              </Menu.Item>
            </Menu>
          );

          return (
            <List.Item key={item.id || ''}>
              <Card
                hoverable
                extra={[
                  <Dropdown key="publish" overlay={menu}>
                    <Button icon={<UploadOutlined />} type="primary" onClick={(e) => e.stopPropagation()} />
                  </Dropdown>,
                ]}
                title={item.name}
                onClick={() => onVersionCardClick(item)}
              >
                <Card.Meta
                  description={
                    <Paragraph className="item" ellipsis={{ rows: 3 }}>
                      {item.description}
                    </Paragraph>
                  }
                  title={
                    <Row>
                      <Col span={14}>
                        <Moment date={item.createdOn} format={'HH:mm DD.MM.YYYY'} interval={0} />
                      </Col>
                      <Col span={10}>
                        {item.id === originStage?.versionId && <Tag color="blue">Релиз</Tag>}
                        {item.id === draftStage?.versionId && <Tag color="orange">Тест</Tag>}
                      </Col>
                    </Row>
                  }
                />
              </Card>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default VersionsList;
