import React, { useEffect, useState } from 'react';
import { Button, Table, Tag, Typography, Space, TablePaginationConfig } from 'antd';
import { PlaySquareOutlined } from '@ant-design/icons';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import ConversationWidget from '../../../components/ConversationWidget';
import { TrainStatus, AgentTestInstanceResponse } from '../../../../api';
import { AlertTypes } from '../../../constants';
import { testInstanceApi } from '../../../apis';
import FormattedDateTime from '../../../components/FormattedDateTime';
import { BOT_PUBLISH_STATUS_UPDATED } from '../../../constants';
import { hubConnections } from '../../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Title } = Typography;

interface AgentTestInstancesPaneProps {
  agentId: string;
}

const PAGE_SIZE = 5;

const AgentTestInstancesPane: React.FC<AgentTestInstancesPaneProps> = ({ agentId }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const [loading, setLoading] = useState(false);
  const [agentTestInstances, setAgentTestInstances] = useState([] as AgentTestInstanceResponse[]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: 0,
  });

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await testInstanceApi.searchAgentTestInstances(
        agentId,
        undefined,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setAgentTestInstances(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка тестовых ботов',
        error: e,
      });
    }
    setLoading(false);
  };

  const loadData = () => {
    loadDataAsync();
  };

  useEffect(loadData, [agentId, pagination.current, pagination.pageSize]);

  const botPublishEventHandler = (args: { agentId: string }) => {
    if (agentId === args?.agentId) {
      loadDataAsync();
    }
  };
  const subscribe = () => {
    if (!agentId || !conn) return;

    conn.on(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);

    return () => {
      conn.off(BOT_PUBLISH_STATUS_UPDATED, botPublishEventHandler);
    };
  };
  useEffect(subscribe, [conn, agentId]);

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const renderTrainStatus = (_: unknown, agentTestInstance: AgentTestInstanceResponse) => {
    let statusText: string;
    switch (agentTestInstance.trainResult?.status) {
      case TrainStatus.Pending:
        statusText = 'На обучении';
        break;
      case TrainStatus.Success:
        statusText = 'Завершено';
        break;
      case TrainStatus.Error:
        statusText = `Ошибка: ${agentTestInstance.trainResult?.errorMessage}`;
        break;
      default:
        statusText = '';
    }
    return <div>{statusText}</div>;
  };

  const columns = [];

  columns.push({
    title: 'Дата изменения',
    dataIndex: 'modifiedOn',
    width: '170px',
    render: (_: unknown, testInstance: AgentTestInstanceResponse) => (
      <FormattedDateTime value={testInstance.modifiedOn} />
    ),
  });

  columns.push({
    title: 'Статус',
    dataIndex: 'state',
    render: (_: unknown, testInstance: AgentTestInstanceResponse) => {
      return (
        <div>
          {testInstance.botVersionIsProduction && <Tag color="blue">Релиз</Tag>}
          {testInstance.botVersionIsStaging && <Tag color="orange">Тест</Tag>}
        </div>
      );
    },
  });

  columns.push({
    title: 'Версия',
    dataIndex: 'botVersionName',
    width: '15%',
  });

  columns.push({
    title: 'Статус обучения',
    width: '85%',
    render: renderTrainStatus,
  });

  columns.push({
    title: '',
    render: (_: unknown, agentTestInstance: AgentTestInstanceResponse) => (
      <Space>
        <ConversationWidget agentStageId={agentTestInstance.agentStageId} caption="Беседы" />
        <Button
          key="runEmulator"
          disabled={agentTestInstance.trainResult?.status !== TrainStatus.Success}
          href={agentTestInstance.emulatorUrl || '#'}
          icon={<PlaySquareOutlined />}
          type="link"
        >
          Запустить в эмуляторе
        </Button>
      </Space>
    ),
    width: '10%',
  });

  return (
    <div style={{ paddingTop: '12px' }}>
      <Title level={4} style={{ fontSize: 18, marginBottom: '16px' }}>
        Боты, запущенные из редактора
      </Title>
      <Table
        columns={columns}
        dataSource={agentTestInstances.map((item, i) => ({ key: i, ...item }))}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default AgentTestInstancesPane;
