import React, { useState } from 'react';
import { Button, Form, Input, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';

import { AgentCreationMode, AgentCreationRequest } from '../../../../api';

interface IImportZipCreationFormProps {
  onCancel: () => void;
  onSubmit: (request: AgentCreationRequest) => Promise<boolean>;
}

const ImportZipCreationForm: React.FC<IImportZipCreationFormProps> = ({
  onCancel = () => {},
  onSubmit = () => false,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [botZip, setBotZip] = useState<string | null>(null);
  const [botZipList, setBotZipList] = useState<RcFile[]>([]);

  const canCreateBot = botZip && form.getFieldValue('name');

  const onFinish = async () => {
    if (!canCreateBot) return;

    setSubmitting(true);

    const request: AgentCreationRequest = {
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      creationMode: AgentCreationMode.NUMBER_1,
      botZip: botZip,
    };

    const submitResult = await onSubmit(request);
    if (!submitResult) setSubmitting(false);
  };

  const beforeUpload = (file: RcFile) => {
    setBotZipList([file]);
    const reader = new FileReader();
    reader.onload = (e) => {
      e.target?.result && setBotZip(Buffer.from(e.target.result).toString('base64'));
    };
    reader.readAsArrayBuffer(file);

    return false;
  };

  const onRemove = () => {
    setBotZipList([]);
    setBotZip(null);
  };

  const onBackButtonClick = () => {
    onCancel();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название' }]}>
        <Input placeholder="Имя бота" />
      </Form.Item>
      <Form.Item label="Описание" name="description">
        <Input.TextArea placeholder="Назначение бота" rows={4} />
      </Form.Item>
      <Form.Item label="Шаблон бота" name="botTemplate">
        <Upload accept=".zip" beforeUpload={beforeUpload} fileList={botZipList} name="botZip" onRemove={onRemove}>
          <Button icon={<UploadOutlined />}>Выберите шаблон бота</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Space>
          <Button onClick={onBackButtonClick}>Назад</Button>
          <Button disabled={!canCreateBot} htmlType="submit" loading={submitting} type="primary">
            Создать
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ImportZipCreationForm;
