import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Divider, Space, Table } from 'antd';

import './index.less';

import { IntentsPerDayModel, OmegaIntentCountModel, TopIntentsModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { formatAnalyticsDate, formatAnalyticsDateCompact, formatAnalyticsPercent } from '../../../utils/render';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import SbBar from '../../common/SbBar';
import SbTable from '../../common/SbTable';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';

const CHART_SETTINGS = {
  barColors: {
    unknownIntent: '#BCBCBC',
    omega: '#53BA80',
    sigma: '#8683EF',
  },
};

const TABLE_SETTINGS = {
  omegaColor: '#53A6FF',
};

const getTotal = (e: IntentsPerDayModel) => e.unknownIntentCount + e.omegaIntentCount + e.sigmaAnswerCount;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as IntentsPerDayModel;
  const total = getTotal(data);
  if (total <= 0) {
    return null;
  }

  const unknownPercent = formatAnalyticsPercent(data.unknownIntentCount / total);
  const omegaPercent = formatAnalyticsPercent(data.omegaIntentCount / total);
  const sigmaPercent = formatAnalyticsPercent(data.sigmaAnswerCount / total);

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        <div>
          Распознано интентов: <b>{omegaPercent}</b>
        </div>
        {data.omegaIntentCount > 0 && (
          <ul>
            {data.topOmegaIntents.map((e, index) => (
              <li key={index}>
                <i>{e.intentName}</i>:&nbsp;<b>{e.count}</b>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        <div>
          Распознано вопросов БЗ: <b>{sigmaPercent}</b>
        </div>
        {data.sigmaAnswerCount > 0 && (
          <ul>
            {data.topSigmaAnswers.map((e, index) => (
              <li key={index}>
                <i>{e.question}</i>:&nbsp;<b>{e.count}</b>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        Не распознано: <b>{unknownPercent}</b>
      </div>
    </div>
  );
};

interface ISbIntentsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbIntentsAnalytics: React.FC<ISbIntentsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [intentsPerDayList, setIntentsPerDayList] = useState<IntentsPerDayModel[]>([]);
  const [topIntents, setTopIntents] = useState<TopIntentsModel>();

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const formattedFromDate = filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);
      const formattedToDate = filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);

      const intentsPerDayResponse = await analyticsReportsApi.getIntentsPerDayList(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );
      const topIntentsResponse = await analyticsReportsApi.getTopIntents(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );

      setIntentsPerDayList(intentsPerDayResponse.data);
      setTopIntents(topIntentsResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по интентам',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const omegaCountMax = Math.max(...(topIntents?.omegaIntents || []).map((e) => e.count));

  const tableColumns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, e: OmegaIntentCountModel) => e.intentName,
    },
    {
      title: 'Распознаваний',
      dataIndex: 'count',
      key: 'count',
      render: (_: string, e: OmegaIntentCountModel) => (
        <div className="sb-analytics-card__content__block__content_with-table__table-cell">
          <span>
            <b>{e.count}</b>
          </span>
          <SbBar color={TABLE_SETTINGS.omegaColor} maxValue={omegaCountMax} value={e.count} />
        </div>
      ),
    },
  ];

  const renderTableMoreData = (item: IntentsPerDayModel) => (
    <Space direction="horizontal" size="large" split={<Divider />}>
      {item.omegaIntentCount > 0 && (
        <Table
          bordered
          columns={[
            {
              title: 'Интент',
              dataIndex: 'intentName',
              key: 'intentName',
            },
            {
              title: 'Количество распознаваний',
              dataIndex: 'count',
              key: 'count',
            },
          ]}
          dataSource={item.topOmegaIntents}
          pagination={false}
        />
      )}
      {item.sigmaAnswerCount > 0 && (
        <Table
          bordered
          columns={[
            {
              title: 'Вопрос БЗ',
              dataIndex: 'question',
              key: 'question',
            },
            {
              title: 'Количество распознаваний',
              dataIndex: 'count',
              key: 'count',
            },
          ]}
          dataSource={item.topSigmaAnswers}
          pagination={false}
        />
      )}
    </Space>
  );

  return (
    <div className="sb-intents-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Распознавание</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                {
                  title: 'Распознано интентов',
                  dataIndex: 'omegaIntentCount',
                  key: 'omegaIntentCount',
                },
                {
                  title: 'Распознано вопросов БЗ',
                  dataIndex: 'sigmaAnswerCount',
                  key: 'sigmaAnswerCount',
                },
                {
                  title: 'Не распознано',
                  dataIndex: 'unknownIntentCount',
                  key: 'unknownIntentCount',
                },
              ]}
              dataSource={intentsPerDayList}
              expandable={{
                expandedRowRender: renderTableMoreData,
                expandRowByClick: true,
                rowExpandable: (record) => record.omegaIntentCount + record.sigmaAnswerCount > 0,
              }}
              pagination={false}
              rowKey="date"
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={intentsPerDayList}>
                <XAxis dataKey={(e: IntentsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickFormatter={formatAnalyticsPercent} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                <Bar
                  dataKey={(e: IntentsPerDayModel) => {
                    const total = getTotal(e);
                    return total > 0 ? e.omegaIntentCount / total : 0;
                  }}
                  fill={CHART_SETTINGS.barColors.omega}
                  isAnimationActive={isAnimationActive}
                  name="Интенты"
                />
                <Bar
                  dataKey={(e: IntentsPerDayModel) => {
                    const total = getTotal(e);
                    return total > 0 ? e.sigmaAnswerCount / total : 0;
                  }}
                  fill={CHART_SETTINGS.barColors.sigma}
                  isAnimationActive={isAnimationActive}
                  name="Вопросы БЗ"
                />
                <Bar
                  dataKey={(e: IntentsPerDayModel) => {
                    const total = getTotal(e);
                    return total > 0 ? e.unknownIntentCount / total : 0;
                  }}
                  fill={CHART_SETTINGS.barColors.unknownIntent}
                  isAnimationActive={isAnimationActive}
                  name="Не распознано"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Топ 5 интентов</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content sb-analytics-card__content__block__content_with-table  ${
            loading ? 'sb-analytics-card__content__block__content_loading' : ''
          }`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                { title: 'Интент', dataIndex: 'intentName', key: 'intentName' },
                { title: 'Распознаваний', dataIndex: 'count', key: 'count' },
              ]}
              dataSource={topIntents?.omegaIntents.sort((a, b) => b.count - a.count) || []}
              pagination={false}
            />
          ) : (
            <SbTable
              columns={tableColumns}
              dataSource={topIntents?.omegaIntents.sort((a, b) => b.count - a.count) || []}
              emptyText="Нет данных"
              rowKey="intentName"
              scrollEnabled={false}
              tableLayout="fixed"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SbIntentsAnalytics;
