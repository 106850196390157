import { InboxOperatorGroupModel } from '../../../../api';

export const MAIN_CLASS_NAME = 'ib-operator-group-edit-modal';
export const CLEAR_LIST_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__clear-list-button`;
export const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;
export const FORM_SUBTITLE_CLASS_NAME = `${MAIN_CLASS_NAME}__form-subtitle`;
export const DELETE_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__delete-button`;
export const OPERATORS_TABLE_CLASS_NAME = `${MAIN_CLASS_NAME}__operators-table`;
export const NAME_CELL_CLASS_NAME = `${OPERATORS_TABLE_CLASS_NAME}__name-cell`;
export const GROUPS_CELL_CLASS_NAME = `${OPERATORS_TABLE_CLASS_NAME}__groups-cell`;
export const ACTIONS_CELL_CLASS_NAME = `${OPERATORS_TABLE_CLASS_NAME}__actions-cell`;
export const EXPANDED_ROW_ACTIONS_CLASS_NAME = `${OPERATORS_TABLE_CLASS_NAME}__expanded-row-actions`;

export const OPERATOR_LIST_POPOVER_CLASS_NAME = `${MAIN_CLASS_NAME}__operator-list-popover`;
export const OPERATOR_LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__operator-list`;
export const OPERATOR_LIST_SPIN_WRAPPER_CLASS_NAME = `${OPERATOR_LIST_CLASS_NAME}__spin-wrapper`;
export const OPERATOR_LIST_EMPTY_LIST_ENTRY_CLASS_NAME = `${OPERATOR_LIST_CLASS_NAME}__empty-list-entry`;
export const OPERATOR_LIST_ITEM_CHECKED_CLASS_NAME = `${OPERATOR_LIST_CLASS_NAME}__item-checked`;

export const SEARCH_DELAY = 200; // ms
export const ANIMATION_DELAY = 200; // ms
export const ACTIONS_DATA_INDEX = 'actions';
export const GROUP_DEFAULT = { id: '', name: '', operators: [] } as InboxOperatorGroupModel;
