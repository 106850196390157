import React, { ReactNode } from 'react';

import './index.less';

import IbIcon from '../IbIcon';
import IbButton from '../IbButton';
import IbTypography from '../IbTypography';

const MAIN_CLASS_NAME = 'ib-alert';
const ICON_CONTAINER_CLASS_NAME = `${MAIN_CLASS_NAME}__icon-container`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const SUCCESS_ICON_CLASS_NAME = `${ICON_CONTAINER_CLASS_NAME}__success`;
const ERROR_ICON_CLASS_NAME = `${ICON_CONTAINER_CLASS_NAME}__error`;

export interface IIbAlertProps {
  children?: ReactNode;
  className?: string;
  type: 'success' | 'error' | 'custom';
  icon?: ReactNode;
  onClose?: () => void;
}

const IbAlert: React.FC<IIbAlertProps> = ({ className, type, icon, onClose, children }) => {
  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);

  const renderIcon = () => {
    switch (type) {
      case 'success':
        return <IbIcon className={SUCCESS_ICON_CLASS_NAME} iconName="check-one" />;
      case 'error':
        return <IbIcon className={ERROR_ICON_CLASS_NAME} iconName="attention" />;
      case 'custom':
        return icon;
    }
  };

  const renderedIcon = renderIcon();

  return (
    <div className={classes.join(' ')}>
      {!!renderedIcon && <div className={ICON_CONTAINER_CLASS_NAME}>{renderedIcon}</div>}
      <IbTypography.Paragraph className={CONTENT_CLASS_NAME} type="secondary">
        {children}
      </IbTypography.Paragraph>
      {!!onClose && <IbButton icon={<IbIcon iconName="close-small" />} type="icon" onClick={onClose} />}
    </div>
  );
};

export default IbAlert;
