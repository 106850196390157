import React, { ChangeEventHandler, UIEventHandler, useRef, useState } from 'react';

import './index.less';

import { TagModel } from '../../../../api';
import IbTag from '../common/IbTag';
import { getDefaultIfUndefined } from '../../../utils/typeUtil';
import IbIcon from '../common/IbIcon';
import IbTagsPopover from '../IbTagsPopover';

import {
  CLEAR_BUTTON_CLASS_NAME,
  FILTER_BUTTON_CLASS_NAME,
  MAIN_CLASS_NAME,
  WINDOW_CLASS_NAME,
  CONTENT_CLASS_NAME,
  INPUT_CLASS_NAME,
  SCROLL_CLASS_NAME,
  CONTROL_CLASS_NAME,
} from './const';

export interface IIbTagsSearchData {
  selectedTagIds?: string[];
  searchValue?: string;
}

export interface IIbTagsSearchProps {
  placeholder?: string;
  tagList?: TagModel[];
  hideTagsFiltering?: boolean;
  allowTagsManagement?: boolean;
  searchData?: IIbTagsSearchData;
  onChange?: (data: IIbTagsSearchData) => void;
  onTagsManagement?: () => void;
}

const TAG_LIST_DEFAULT = [] as TagModel[];
const ALLOW_TAGS_MANAGEMENT_DEFAULT = false;
const HIDE_TAGS_FILTERING_DEFAULT = false;
const SEARCH_DATA_DEFAULT = { selectedTagIds: [], searchValue: '' } as IIbTagsSearchData;

const IbTagsSearch: React.FC<IIbTagsSearchProps> = ({
  placeholder,
  tagList = TAG_LIST_DEFAULT,
  hideTagsFiltering = HIDE_TAGS_FILTERING_DEFAULT,
  allowTagsManagement = ALLOW_TAGS_MANAGEMENT_DEFAULT,
  searchData = SEARCH_DATA_DEFAULT,
  onChange,
  onTagsManagement,
}) => {
  tagList = getDefaultIfUndefined(tagList, TAG_LIST_DEFAULT);
  hideTagsFiltering = getDefaultIfUndefined(hideTagsFiltering, HIDE_TAGS_FILTERING_DEFAULT);
  allowTagsManagement = getDefaultIfUndefined(allowTagsManagement, ALLOW_TAGS_MANAGEMENT_DEFAULT);
  searchData = getDefaultIfUndefined(searchData, SEARCH_DATA_DEFAULT);

  const [scrolling, setScrolling] = useState(false);

  const windowRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const contentClientWidth = contentRef.current?.clientWidth ?? 0;
  const contentScrollWidth = contentRef.current?.scrollWidth ?? 0;
  const scrollClientWidth = scrollRef.current?.clientWidth ?? 0;

  const isEmpty = !searchData?.searchValue && !searchData?.selectedTagIds?.length;

  const focusToInput = () => {
    inputRef.current?.focus();
  };

  const scrollToRight = () => {
    const target = scrollRef.current;
    if (!target) {
      return;
    }

    window.setTimeout(() => {
      target.scrollTo(target.scrollWidth - target.clientWidth, 0);
    });
  };

  const onTagDelete = (tag: TagModel) => () => {
    const newData = { ...searchData, selectedTagIds: searchData?.selectedTagIds?.filter((id) => id !== tag.id) };
    onChange?.(newData);
  };

  const onPopoverChange = (selectedTagIds: string[]): Promise<void> => {
    const newData = { ...searchData, selectedTagIds };
    onChange?.(newData);
    focusToInput();
    scrollToRight();
    return Promise.resolve();
  };

  const onPopoverTagsManagement = () => {
    onTagsManagement?.();
  };

  const onContentInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newData = { ...searchData, searchValue: event.currentTarget.value };
    onChange?.(newData);
  };

  const onClearButtonClick = () => {
    onChange?.(SEARCH_DATA_DEFAULT);
    focusToInput();
  };

  const onWindowScroll: UIEventHandler<HTMLDivElement> = (event) => {
    if (scrolling) {
      return;
    }

    const ratio = contentClientWidth ? scrollClientWidth / contentClientWidth : 0;
    const position = event.currentTarget.scrollLeft * ratio;

    setScrolling(true);
    scrollRef.current?.scroll(position, 0);
    window.requestAnimationFrame(() => setScrolling(false));
  };

  const onScrollScroll: UIEventHandler<HTMLDivElement> = (event) => {
    if (scrolling) {
      return;
    }

    const ratio = scrollClientWidth ? contentClientWidth / scrollClientWidth : 0;
    const position = event.currentTarget.scrollLeft * ratio;

    setScrolling(true);
    windowRef.current?.scroll(position, 0);
    window.requestAnimationFrame(() => setScrolling(false));
  };

  const renderClearButton = () => {
    if (isEmpty) {
      return null;
    }

    return (
      <button className={CLEAR_BUTTON_CLASS_NAME} onClick={onClearButtonClick}>
        <IbIcon iconName="close" size={16} />
      </button>
    );
  };

  const renderFilterButton = () => {
    return (
      <div className={FILTER_BUTTON_CLASS_NAME}>
        <IbIcon iconName="filter" />
      </div>
    );
  };

  const renderWindow = () => {
    return (
      <div ref={windowRef} className={WINDOW_CLASS_NAME} onScroll={onWindowScroll}>
        <div ref={contentRef} className={CONTENT_CLASS_NAME}>
          {tagList
            .filter((tag: TagModel) => searchData?.selectedTagIds?.includes(tag.id))
            .map((tag) => (
              <IbTag
                key={tag.id}
                content={tag.label}
                style={{ background: tag.color }}
                tooltipContent={tag.label}
                onDelete={onTagDelete(tag)}
              />
            ))}
          <div className={INPUT_CLASS_NAME}>
            <input
              ref={inputRef}
              placeholder={isEmpty ? placeholder : undefined}
              value={searchData?.searchValue}
              onChange={onContentInputChange}
            />
            <span>{searchData?.searchValue}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderPopover = () => {
    return (
      <IbTagsPopover
        allowTagsManagement={allowTagsManagement}
        selectedTagIds={searchData?.selectedTagIds}
        tagList={tagList}
        onChange={onPopoverChange}
        onTagsManagement={onPopoverTagsManagement}
      >
        {renderFilterButton()}
      </IbTagsPopover>
    );
  };

  const renderControl = () => {
    return (
      <div className={CONTROL_CLASS_NAME}>
        {renderWindow()}
        {renderClearButton()}
        {!hideTagsFiltering && renderPopover()}
      </div>
    );
  };

  const renderScroll = () => {
    const ratio = contentClientWidth ? contentScrollWidth / contentClientWidth : 0;
    const width = scrollClientWidth * ratio;

    return (
      <div ref={scrollRef} className={SCROLL_CLASS_NAME} onScroll={onScrollScroll}>
        <div style={{ width: `${width}px` }} />
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderControl()}
      {renderScroll()}
    </div>
  );
};

export default IbTagsSearch;
