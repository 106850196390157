import React, { ReactNode, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import './index.less';

import SbMenu from '../../../../../simple-bot/components/common/SbMenu';
import { groupsMenuIsVisibleSelector } from '../../../../../recoil/scenarioStructure';

interface AddButtonProps {
  title: ReactNode;
  groupId?: string;
  menuContent?: ReactNode;
  buttonHandler?: () => void; // NOTE: если задан buttonHandler, то контекстное меню не отображается
}

const AddButton: React.FC<AddButtonProps> = ({ title, groupId, menuContent, buttonHandler }) => {
  const [groupMenuIsVisible, setGroupMenuIsVisible] = useRecoilState(groupsMenuIsVisibleSelector(groupId || ''));

  const onButtonClick = () => {
    if (buttonHandler) {
      buttonHandler();
    }
  };

  const onGroupMenuIsVisibleChange = () => {
    if (groupMenuIsVisible) {
      setGroupMenuIsVisible(false);
    }
  };
  useEffect(onGroupMenuIsVisibleChange, [groupMenuIsVisible]);

  return (
    <div className="add-button" onClick={onButtonClick}>
      <SbMenu className="add-button__menu" sbMenuType="dropdown" selectable={false} triggerSubMenuAction="click">
        <SbMenu.SubMenu className="add-button__button dashed-button" title={title}>
          {!buttonHandler && menuContent}
        </SbMenu.SubMenu>
      </SbMenu>
    </div>
  );
};

export default AddButton;
