import { Layout } from 'antd';
import React from 'react';

import SbBrowserUnsupportedModal from '../../components/SbBrowserUnsupportedModal';

const { Content } = Layout;

const SimpleBotEmptyLayout: React.FC = (props) => {
  const { children } = props;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content>{children}</Content>
      <SbBrowserUnsupportedModal />
    </Layout>
  );
};

export default SimpleBotEmptyLayout;
