import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from './types';

export const ViberIcon = memo((props: IGraphicsProps) => {
  const clipPathId = v4();
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          clipRule="evenodd"
          d="M23.2827 13.1052C22.592 18.5404 18.5222 18.8841 17.7715 19.1189C17.4515 19.2192 14.4825 19.9407 10.7514 19.7033C10.7514 19.7033 7.96943 22.9799 7.1011 23.8325C6.96551 23.9649 6.8072 24.0184 6.69966 23.9937C6.55005 23.9582 6.50863 23.7844 6.51131 23.5323L6.53402 19.0567V19.0553C1.10031 17.5808 1.41625 12.04 1.4777 9.1385C1.53982 6.23768 2.09755 3.86045 3.75538 2.26226C6.73373 -0.37242 12.8694 0.020776 12.8694 0.020776C18.0507 0.0435118 20.5341 1.56681 21.1098 2.07703C23.0215 3.67522 23.9947 7.50153 23.2827 13.1052V13.1052Z"
          fill="#665CAC"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M15.5016 9.23757C15.4138 9.23842 15.3291 9.20554 15.2648 9.14569C15.2006 9.08585 15.1617 9.00361 15.1562 8.91592C15.0981 7.76776 14.543 7.20605 13.4136 7.14587C13.3687 7.14415 13.3246 7.13357 13.2838 7.11474C13.2431 7.0959 13.2064 7.06919 13.176 7.03613C13.1456 7.00307 13.122 6.96431 13.1066 6.9221C13.0912 6.87988 13.0843 6.83503 13.0863 6.79012C13.0892 6.74519 13.1009 6.70126 13.1207 6.66087C13.1406 6.62047 13.1682 6.58439 13.202 6.5547C13.2359 6.52501 13.2752 6.50229 13.3178 6.48784C13.3604 6.47339 13.4054 6.46749 13.4503 6.47048C14.9425 6.54872 15.7707 7.38326 15.8475 8.88249C15.8493 8.92739 15.8422 8.9722 15.8267 9.01435C15.8111 9.0565 15.7874 9.09515 15.7568 9.12809C15.7263 9.16102 15.6895 9.18759 15.6487 9.20626C15.6079 9.22494 15.5638 9.23535 15.5189 9.2369L15.5016 9.23757Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M17.2828 9.81971H17.2748C17.1842 9.81883 17.0977 9.782 17.0342 9.71731C16.9707 9.65262 16.9354 9.56535 16.9362 9.47467C16.9629 8.31781 16.6242 7.38163 15.9022 6.61129C15.1828 5.84362 14.1963 5.42435 12.8837 5.33073C12.7932 5.32522 12.7086 5.28395 12.6485 5.21598C12.5883 5.14801 12.5576 5.05892 12.5631 4.9683C12.5715 4.87783 12.615 4.79428 12.6844 4.73572C12.7538 4.67716 12.8434 4.64828 12.9338 4.65534C14.4107 4.761 15.5816 5.26587 16.4138 6.15457C17.2494 7.04595 17.6595 8.16803 17.6282 9.48871C17.6249 9.57799 17.587 9.66249 17.5225 9.72428C17.458 9.78607 17.3721 9.8203 17.2828 9.81971Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M19.099 10.5136C19.0084 10.5149 18.9211 10.4802 18.856 10.4172C18.7909 10.3542 18.7534 10.2679 18.7516 10.1773C18.7356 8.13775 18.1084 6.5128 16.8874 5.34927C15.6644 4.18439 14.1181 3.58724 12.292 3.5752C12.247 3.57556 12.2024 3.567 12.1608 3.55002C12.1192 3.53304 12.0813 3.50797 12.0494 3.47627C12.0175 3.44456 11.9921 3.40685 11.9748 3.3653C11.9576 3.32375 11.9487 3.27918 11.9487 3.23417C11.9494 3.18938 11.9589 3.14517 11.9766 3.10406C11.9944 3.06295 12.0201 3.02574 12.0522 2.99457C12.0843 2.9634 12.1223 2.93887 12.1639 2.92238C12.2055 2.90589 12.2499 2.89777 12.2947 2.89848H12.2967C14.3072 2.91185 16.0144 3.57387 17.371 4.86446C18.7289 6.15973 19.4276 7.94583 19.4443 10.1726C19.4438 10.2633 19.4073 10.3502 19.343 10.4141C19.2786 10.478 19.1916 10.5138 19.101 10.5136H19.099ZM13.4996 13.4292C13.4996 13.4292 13.9872 13.472 14.2477 13.1543L14.7594 12.5264C15.0058 12.2148 15.601 12.0162 16.1847 12.3325C16.9556 12.7585 17.9528 13.4272 18.6361 14.0504C19.0128 14.36 19.101 14.8167 18.8445 15.2995L18.8418 15.3096C18.5572 15.7896 18.1983 16.2214 17.7784 16.5888L17.7684 16.5955C17.2661 17.0047 16.689 17.2421 16.0719 17.0462L16.0598 17.0301C14.9511 16.7239 12.29 15.3985 10.5941 14.0778C7.82011 11.9406 5.84835 8.41392 5.30331 6.52417L5.28594 6.5128C5.08556 5.90763 5.33069 5.34392 5.74682 4.85443L5.75484 4.84573C6.13405 4.43216 6.57637 4.08142 7.06534 3.80657L7.07536 3.80457C7.5683 3.55381 8.03586 3.63873 8.35446 4.00718C8.77326 4.4298 9.55943 5.44623 10.1111 6.40247C10.4358 6.97086 10.2327 7.55397 9.91411 7.79336L9.27088 8.29288C8.94693 8.549 8.98901 9.02511 8.98901 9.02511C8.98901 9.02511 9.94083 12.5425 13.4996 13.4292Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
});
