import numberToWordsRu from 'number-to-words-ru';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import i18next, { i18n } from 'i18next';
import tinycolor from 'tinycolor2';
import { isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

import { AgentStageAccountModel } from '../../api';
import { COLOR_BRIGHTNESS_THRESHOLD, COUNTRY_LIST, Channels, StageTypes, USER_ROLES } from '../constants';
import { ITextSpan } from '../types';

import { PUBLIC_URL } from './configUtil';
import { getCountryName } from './countryUtil';

const cyrillicToTranslit = new CyrillicToTranslit();

const DATE_LONG_OPTIONS = { day: '2-digit', month: 'long', year: 'numeric' } as Intl.DateTimeFormatOptions;
const DATE_SHORT_OPTIONS = { day: '2-digit', month: '2-digit' } as Intl.DateTimeFormatOptions;
const TIME_SHORT_OPTIONS = { hour: '2-digit', minute: '2-digit' } as Intl.DateTimeFormatOptions;

export const equalsIgnoreCase = (x: string, y: string): boolean => x.toLowerCase() === y.toLowerCase();

export const isWhiteSpace = (value: string): boolean => value.trim().length === 0;

export const includesIgnoreCase = (value: string | null | undefined, query: string): boolean =>
  (value || '').toLowerCase().includes(query.toLowerCase());

export const indexOfIgnoreCase = (value: string | null | undefined, query: string, position?: number): number =>
  (value || '').toLowerCase().indexOf(query.toLowerCase(), position);

export const findAllIgnoreCase = (value: string | null | undefined, query: string, position?: number): ITextSpan[] => {
  const results: ITextSpan[] = [];
  if (!value || !query) {
    return results;
  }

  let start = position ?? 0;
  while (start < value.length) {
    const index = indexOfIgnoreCase(value, query, start);
    if (index < 0) {
      results.push({
        found: false,
        value: value.substr(start),
        index: start,
        length: value.length - start,
      });
      return results;
    }

    if (index > start) {
      results.push({
        found: false,
        value: value.substr(start, index - start),
        index: start,
        length: index - start,
      });
    }

    results.push({
      found: true,
      value: value.substr(index, query.length),
      index,
      length: query.length,
    });
    start = index + query.length;
  }

  return results;
};

export const translitVariableName = (name?: string): string => {
  const translatedName = cyrillicToTranslit.transform(name || '', '_');
  return translatedName.replace(/[^0-9a-zA-Z_$]/g, '_').toLowerCase();
};

export const translitIntentName = (name?: string): string => {
  const translatedName = cyrillicToTranslit.transform(name || '', '_');
  return translatedName.replace(/[^0-9a-zA-Z_]/g, '_').toLowerCase();
};

export const round = (number: number | undefined, digits: number): number => parseFloat(number?.toFixed(digits) || '0');

// todo: временная функция для названий каналов
export const getChannelName = (
  channelId?: string | null,
  externalFullName?: string | null,
  displayName?: string | null
): string => {
  const name = displayName || externalFullName;
  switch (channelId) {
    case Channels.VIBER:
      return 'Viber' + (name ? ` (${name})` : '');
    case Channels.TELEGRAM:
      return 'Telegram' + (name ? ` (${name})` : '');
    case Channels.ELMA365:
      return 'Линия ELMA 365' + (displayName ? ` (${displayName})` : '');
    case Channels.DIRECTLINE:
      return i18next.t('Webchat');
    case Channels.EMULATOR:
      return i18next.t('Emulator');
    case Channels.WEBCHAT:
      return 'Webchat';
    case Channels.WHATSAPP:
      return 'WhatsApp';
    case Channels.LIVECHAT:
      return `LiveChat${displayName ? ` (${displayName})` : ''}`;
    default:
      return i18next.t('Unknown channel');
  }
};

export const getChannelDisplayName = (channel: AgentStageAccountModel): string =>
  channel.displayName || getChannelName(channel.channelId, null, null);

export const getChannelLink = (channel: AgentStageAccountModel): string | undefined => {
  switch (channel.channelId) {
    case Channels.VIBER:
      return `viber://pa?chatURI=${channel.botUri}`;
    case Channels.TELEGRAM:
      return `https://t.me/${channel.botUri}`;
    case Channels.WHATSAPP:
      return channel.redirectUrl;
    default:
      return undefined;
  }
};

export const getStageNameByType = (stageType?: string | null): string => {
  switch (stageType) {
    case StageTypes.STAGING:
      return 'Тест';
    case StageTypes.PRODUCTION:
      return 'Релиз';
    default:
      return 'Неизв. тип';
  }
};

export const renderActiveDialogCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['активный', 'активных', 'активных'],
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderDialogCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['диалог', 'дилога', 'диалогов'],
      currencyNameDeclensions: {
        nominative: ['диалог', 'дилоги'],
        genitive: ['диалога', 'диалогов'],
        dative: ['диалогу', 'диалогам'],
        accusative: ['диалог', 'дилоги'],
        instrumental: ['диалогом', 'диалогами'],
        prepositional: ['диалоге', 'диалогах'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderUserCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['пользователь', 'пользователя', 'пользователей'],
      currencyNameDeclensions: {
        nominative: ['пользователь', 'пользователи'],
        genitive: ['пользователя', 'пользователей'],
        dative: ['пользователю', 'пользователям'],
        accusative: ['пользователя', 'пользователей'],
        instrumental: ['пользователем', 'пользователями'],
        prepositional: ['пользователе', 'пользователях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderMessageCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['сообщение', 'сообщения', 'сообщений'],
      currencyNameDeclensions: {
        nominative: ['сообщение', 'сообщения'],
        genitive: ['сообщения', 'сообщений'],
        dative: ['сообщению', 'сообщениям'],
        accusative: ['сообщение', 'сообщения'],
        instrumental: ['сообщением', 'сообщениями'],
        prepositional: ['сообщении', 'сообщениях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderRecordCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['запись', 'записи', 'записей'],
      currencyNameDeclensions: {
        nominative: ['запись', 'записи'],
        genitive: ['записи', 'записей'],
        dative: ['записи', 'записям'],
        accusative: ['запись', 'записи'],
        instrumental: ['записью', 'записями'],
        prepositional: ['записи', 'записях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const renderReportRowCount = (count: number): string =>
  numberToWordsRu.convert(count, {
    currency: {
      currencyNameCases: ['совпадение', 'совпадения', 'совпадений'],
      currencyNameDeclensions: {
        nominative: ['совпадение', 'совпадения'],
        genitive: ['совпадения', 'совпадений'],
        dative: ['совпадению', 'совпадениям'],
        accusative: ['совпадение', 'совпадения'],
        instrumental: ['совпадением', 'совпадениями'],
        prepositional: ['совпадении', 'совпадениях'],
      },
      currencyNounGender: {
        integer: 0,
        fractionalPart: 1,
      },
      fractionalPartMinLength: 0,
    },
    showNumberParts: {
      fractional: false,
    },
    convertNumbertToWords: {
      integer: false,
    },
    showCurrency: {
      fractional: false,
    },
  });

export const getIgnoreCaseStringComparer = <T>(mapper: (v: T) => string) => (a: T, b: T): number => {
  const mappedA = mapper(a);
  const mappedB = mapper(b);

  if (mappedA.toLowerCase() < mappedB.toLowerCase()) return -1;
  if (mappedA.toLowerCase() > mappedB.toLowerCase()) return 1;
  return 0;
};

export const onlyUnique = (s: string, i: number, a: string[]): boolean => a.indexOf(s) === i;

export const emailIsValid = (email: string): boolean =>
  /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(email);

export const phoneNumberIsValid = (phoneNumber: string): boolean => isValidPhoneNumber(phoneNumber);

export const formatDateFull = (dateTime: string | null | undefined, locale: string): string => {
  const date = dateTime ? new Date(dateTime) : undefined;
  return date?.toLocaleDateString([locale]) || '—';
};

export const formatTimeShort = (dateTime: string | null | undefined, locale: string): string => {
  const date = dateTime ? new Date(dateTime) : undefined;
  return date?.toLocaleTimeString([locale], TIME_SHORT_OPTIONS) || '—';
};

export const formatDateTimeLong = (dateString: string | null | undefined, locale: string): string => {
  const date = dateString ? new Date(dateString) : undefined;
  return `${date?.toLocaleDateString([locale], DATE_LONG_OPTIONS)} - ${date?.toLocaleTimeString([locale])}`;
};

export const formatDateTimeFull = (dateString: string | null | undefined, locale: string): string => {
  const date = dateString ? new Date(dateString) : undefined;
  return `${date?.toLocaleDateString([locale])} - ${date?.toLocaleTimeString([locale])}`;
};

export const formatDateTimeAdaptive = (
  dateTime: string | null | undefined,
  locale: string,
  weekday: 'long' | 'short',
  alwaysShowDateAndTime?: boolean
): string => {
  const msPerDay = 24 * 60 * 60 * 1000;
  const msPerWeek = 7 * msPerDay;
  const date = dateTime ? new Date(dateTime) : undefined;
  const lastMidnight = new Date(new Date().setHours(0, 0, 0, 0));
  const secondLastMidnight = new Date(lastMidnight.getTime() - msPerDay);
  const weekAgoMidnight = new Date(lastMidnight.getTime() - msPerWeek);

  if (alwaysShowDateAndTime) {
    const formatTime = () => date?.toLocaleTimeString([locale], TIME_SHORT_OPTIONS);
    return date
      ? date >= lastMidnight
        ? `${i18next.t('Today')}, ${formatTime()}`
        : date >= secondLastMidnight
        ? `${i18next.t('Yesterday')}, ${formatTime()}`
        : date >= weekAgoMidnight
        ? `${date.toLocaleDateString([locale], { weekday })}, ${formatTime()}`
        : `${date.toLocaleDateString([locale], DATE_SHORT_OPTIONS)}, ${formatTime()}`
      : '';
  }

  return date
    ? date >= lastMidnight
      ? date.toLocaleTimeString([locale], TIME_SHORT_OPTIONS)
      : date >= weekAgoMidnight
      ? date.toLocaleDateString([locale], { weekday })
      : date.toLocaleDateString([locale], DATE_SHORT_OPTIONS)
    : '';
};

export const formatPhoneNumber = (e164String: string | null | undefined): string => {
  return e164String ? formatPhoneNumberIntl(e164String) : '—';
};

export const formatCountry = (country: string | null | undefined, i18n: i18n): string => {
  if (!country) return '';

  const foundCountry = COUNTRY_LIST.find((c) => equalsIgnoreCase(c.alpha2, country));
  return foundCountry ? getCountryName(foundCountry, i18n) : country;
};

export const formatLocation = (
  country: string | null | undefined,
  city: string | null | undefined,
  i18n: i18n
): string => {
  const formattedCountry = formatCountry(country, i18n);

  if (formattedCountry && city) {
    return `${formattedCountry}, ${city}`;
  }

  return formattedCountry || city || '—';
};

export const formatFullName = (
  lastName?: string | null,
  firstName?: string | null,
  middleName?: string | null
): string => {
  const lastNameT = (lastName || '').trim();
  const firstNameT = (firstName || '').trim();
  const middleNameT = (middleName || '').trim();

  if (lastNameT && firstNameT && middleNameT) {
    return `${lastNameT} ${firstNameT} ${middleNameT}`;
  }

  if (lastNameT && firstNameT) {
    return `${lastNameT} ${firstNameT}`;
  }

  return lastNameT;
};

export const formatShortName = (
  lastName?: string | null,
  firstName?: string | null,
  middleName?: string | null
): string => {
  const lastNameT = (lastName || '').trim();
  const firstNameT = (firstName || '').trim();
  const middleNameT = (middleName || '').trim();

  if (lastNameT && firstNameT && middleNameT) {
    return `${lastNameT} ${firstNameT.substring(0, 1)}.${middleNameT.substring(0, 1)}.`;
  }

  if (lastNameT && firstNameT) {
    return `${lastNameT} ${firstNameT.substring(0, 1)}.`;
  }

  return lastNameT;
};

export const parseTranslateTransform = (translateTransform?: string): { x: number; y: number } => {
  // NOTE: проверка на небезопасный regex выключена, т.к. размер входных данных не зависит от пользователя
  // eslint-disable-next-line security/detect-unsafe-regex
  const [x, y] = translateTransform?.match(/[+-]? *(?:\d+(?:\.\d*)?|\.\d+)(?:[eE][+-]?\d+)?/g) || [0, 0];
  return {
    x: +x,
    y: +y,
  };
};

export const getLivechatCodeSnippetScript = (
  livechatBasePath: string,
  agentStageAccountId: string,
  mode: 'jwt' | 'userData'
): string => {
  const optionsJwt = 'getJwt: function(){return "YOUR_JWT_TOKEN"},';
  const optionsUserData = 'user: { id: "YOUR_USER_ID" },';

  const getScriptText = (options: string) =>
    `<script id="elma-bot-livechat-wrapper" type="text/javascript">
  !function(){
    var t=document.createElement("script");
    t.id="elma-bot-livechat-widget";
    t.type="text/javascript";
    t.async=true;
    t.src="${livechatBasePath}/index.js";
    t.onload=function(){
      var options = {
        botManagerUrl: "${PUBLIC_URL}",
        agentStageAccountId: "${agentStageAccountId}",
        ${options}
        //
        // Дополнительные опции:
        //
        // Показывать кнопку чата. По умолчанию true.
        // showChatButton: true,
        //
        // Функция, которая будет вызываться при поступлении новых сообщений.
        // message: сообщение.
        // chatIsOpen: открыт ли чат.
        // onNewMessage: (message, chatIsOpen) => {
        //   if (!chatIsOpen) console.log(message);
        // },
        //
        // Функция, которая будет вызываться при открытии и закрытии окна чата.
        // chatIsOpen: открыт ли чат.
        // onToggleChat: (chatIsOpen) => {
        //   console.log(chatIsOpen ? "Чат открыт" : "Чат закрыт");
        // },
        //
        // CSS-селектор элемента на странице, который должен быть использован в качестве контейнера для чата.
        // Если подходящих элементов несколько, будет использован первый из них.
        // Если это свойство не указано, чат будет отображаться поверх страницы в собственном контейнере.
        // chatContainerSelector: "#chat-div-id",
        //
        // Способ определения размеров чата внутри родительского элемента, указанного с помощью chatContainerSelector.
        // Возможные значения:
        // "default": чат использует свои стандартные фиксированные размеры. Значение по умолчанию.
        // "stretch": чат растягивается на всю ширину и высоту контейнера.
        // chatLayout: "default",
      };
      const chatControl = EBLW.init(options);
      //
      // Дополнительные функции, доступные через объект, возвращаемый методом EBLW.init:
      //
      // Открыть окно чата.
      // chatControl.openChat();
      //
      // Закрыть окно чата.
      // chatControl.closeChat();
      //
      // Переключить окно чата.
      // chatControl.toggleChat();
    };
    document.head.appendChild(t);
  }();
</script>`;

  switch (mode) {
    case 'jwt':
      return getScriptText(optionsJwt);
    case 'userData':
      return getScriptText(optionsUserData);
    default:
      return '';
  }
};

export const isLightColor = (color: string): boolean => tinycolor(color).getBrightness() > COLOR_BRIGHTNESS_THRESHOLD;

export const getRoleNameLabel = (roleName: string): string => {
  switch (roleName.trim()) {
    case USER_ROLES.owner:
      return i18next.t('Owner');
    case USER_ROLES.admin:
      return i18next.t('Administrator');
    case USER_ROLES.employee:
      return i18next.t('Employee');
    case USER_ROLES.botsAdmin:
      return i18next.t('Bots admin');
    case USER_ROLES.usersAdmin:
      return i18next.t('Users admin');
    case USER_ROLES.inboxOperator:
      return i18next.t('Operator');
    case USER_ROLES.inboxSupervisor:
      return i18next.t('Supervisor');
    default:
      return '';
  }
};

export const formatRoleNames = (roleNames: string[]): string =>
  roleNames
    .map((roleName, index) => (index === 0 ? getRoleNameLabel(roleName) : getRoleNameLabel(roleName).toLowerCase()))
    .join(', ');

export const getInitials = (text: string): string => {
  const splittedText = text.split(' ');
  return `${splittedText[0]?.charAt(0).toUpperCase() || ''}${splittedText[1]?.charAt(0).toUpperCase() || ''}`;
};

export const formatTrendSlope = (slope?: number): string => {
  if (slope === undefined) return '-';

  const percentSlope = slope * 100;
  const fractionDigits = 1;
  const fixedPercentSlope = +percentSlope.toFixed(fractionDigits);
  const prefix = fixedPercentSlope > 0 ? '↑+' : fixedPercentSlope < 0 ? '↓' : '';
  const suffix = fixedPercentSlope === 0 ? '' : '%';

  if (fixedPercentSlope === Math.round(fixedPercentSlope)) return `${prefix}${Math.round(fixedPercentSlope)}${suffix}`;

  return `${prefix}${fixedPercentSlope}${suffix}`;
};

export const prepareForMarkdown = (str?: string | null): string => str?.trim().replace(/\n/g, '\n&nbsp;') || '';
