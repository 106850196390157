import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VirtualItem } from '@tanstack/react-virtual';
import { CSSTransition } from 'react-transition-group';

import './index.less';

import {
  InboxAttachmentModel,
  InboxDirection,
  InboxMessageAttachmentModel,
  InboxMessageModel,
  InboxMessageParticipantStatus,
  InboxMessageStatus,
  InboxMessageType,
} from '../../../../../api';
import { IEntityItem } from '../../../storages';
import IbAvatar from '../../common/IbAvatar';
import { ITaskOperation, MessageName, OperationStatus } from '../../../tasks';
import IbButton from '../../common/IbButton';
import IbIcon from '../../common/IbIcon';
import IbContextMenu, { IIbContextMenuItem } from '../../common/IbContextMenu';
import { BOTIUM_USER_ID, SUBJECT_ROLES, TEXT_FORMAT_TYPES } from '../../../../constants';
import IbMessage from '../../common/IbMessage';
import IbAttachmentItem from '../../IbAttachmentItem';
import IbTypography from '../../common/IbTypography';
import IbQuotedMessage from '../../IbQuotedMessage';
import { BotAvatarLogo } from '../../../assets';
import { isTouchOnlyDevice } from '../../../../utils/browserUtil';
import { IPreviewModalParams } from '../../../recoil';
import {
  isPreviewAvailable,
  isThumbnailAvailable,
  tryGetContentUrl,
  tryGetPreviewUrl,
  tryGetThumbnailUrl,
} from '../../../utils/fileUtil';
import { formatTimeShort } from '../../../../utils/stringUtil';

const MENU_ICON_SIZE = 20;
const STATUS_ICON_SIZE = 16;

const MAIN_CLASS_NAME = 'ib-message-list-item';
const REPLY_ARROW_CLASS_NAME = `${MAIN_CLASS_NAME}__reply-arrow`;
const REPLY_ARROW_ENTER_DONE_CLASS_NAME = `${REPLY_ARROW_CLASS_NAME}_enter-done`;
const SWIPING_CLASS_NAME = `${MAIN_CLASS_NAME}_swiping`;
const PARTICIPANT_CLASS_NAME = `${MAIN_CLASS_NAME}__participant`;
const PARTICIPANT_AVATAR_CLASS_NAME = `${PARTICIPANT_CLASS_NAME}__avatar`;
const TITLE_CLASS_NAME = `${MAIN_CLASS_NAME}__title`;
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const QUOTED_MESSAGES_CLASS_NAME = `${CONTENT_CLASS_NAME}__quoted-messages`;
const ATTACHMENTS_CLASS_NAME = `${CONTENT_CLASS_NAME}__attachments`;
const ATTACHMENTS_HORIZONTAL_CLASS_NAME = `${ATTACHMENTS_CLASS_NAME}_horizontal`;
const ATTACHMENTS_VERTICAL_CLASS_NAME = `${ATTACHMENTS_CLASS_NAME}_vertical`;
const ATTACHMENTS_ITEM_CLASS_NAME = `${ATTACHMENTS_CLASS_NAME}__item`;
const BODY_CLASS_NAME = `${CONTENT_CLASS_NAME}__body`;
const FOOTER_CLASS_NAME = `${BODY_CLASS_NAME}__footer`;
const TIMESTAMP_CLASS_NAME = `${FOOTER_CLASS_NAME}__timestamp`;
const TIMESTAMP_TEXT_CLASS_NAME = `${TIMESTAMP_CLASS_NAME}__text`;
const STATUS_CLASS_NAME = `${FOOTER_CLASS_NAME}__status`;
const FAILURE_CLASS_NAME = `${STATUS_CLASS_NAME}_failure`;
const LOADING_CLASS_NAME = `${MAIN_CLASS_NAME}_loading`;
const HISTORY_CLASS_NAME = `${MAIN_CLASS_NAME}_history`;

const CONTENT_MESSAGE_HEIGHT = 72;
const HISTORY_MESSAGE_HEIGHT = 46;
const DEFAULT_MESSAGE_HEIGHT = Math.max(CONTENT_MESSAGE_HEIGHT, HISTORY_MESSAGE_HEIGHT);

const MESSAGE_SWIPE_ACTIVATION_THRESHOLD = 16;
const MESSAGE_SWIPE_SWITCHING_THRESHOLD = 28;
const MESSAGE_SWIPE_BOUND = 36;
const MESSAGE_SWIPE_ANIMATION_TIMEOUT = 0; //ms

const getContentType = (message: InboxMessageModel, isBotMessage: boolean) => {
  if (message.direction == InboxDirection.Inbound) {
    return TEXT_FORMAT_TYPES.plain;
  }

  if ([TEXT_FORMAT_TYPES.plain, TEXT_FORMAT_TYPES.markdown].includes(message.content.type)) {
    return message.content.type;
  }

  if (isBotMessage) {
    return TEXT_FORMAT_TYPES.markdown;
  }

  return TEXT_FORMAT_TYPES.plain;
};

export const estimateMessageHeight = (item?: IEntityItem<InboxMessageModel>): number => {
  switch (item?.entity.type) {
    case InboxMessageType.Content:
      return CONTENT_MESSAGE_HEIGHT;
    case InboxMessageType.History:
      return HISTORY_MESSAGE_HEIGHT;
    default:
      return DEFAULT_MESSAGE_HEIGHT;
  }
};

export interface IIbMessageListItemProps {
  channelId?: string;
  currentUserId: string;
  entityItem?: IEntityItem<InboxMessageModel>;
  virtualItem: VirtualItem;
  attachmentItems?: IEntityItem<InboxAttachmentModel>[];
  mentionLinksEnabled: boolean;
  sameSenderAsNextMessage?: boolean;
  forcePreventSwiping?: boolean;
  onMessageResend?: () => void;
  onMessageDelete?: () => void;
  onMessageQuote?: (entityItem: IEntityItem<InboxMessageModel>) => void;
  onAttachmentReUpload?: (entity: InboxAttachmentModel) => void;
  onAttachmentDelete?: (entity: InboxAttachmentModel, operation: ITaskOperation) => void;
  onAttachmentPreview?: (params: IPreviewModalParams) => void;
  onQuotedMessageClick?: (messageId: string, activityId: string) => void;
  onSwipingIsActiveChanged?: (swipingActive: boolean) => void;
}

const IbMessageListItem: React.FC<IIbMessageListItemProps> = ({
  channelId,
  currentUserId,
  entityItem,
  virtualItem,
  attachmentItems,
  mentionLinksEnabled,
  sameSenderAsNextMessage,
  forcePreventSwiping,
  onMessageResend,
  onMessageDelete,
  onMessageQuote,
  onAttachmentReUpload,
  onAttachmentDelete,
  onAttachmentPreview,
  onQuotedMessageClick,
  onSwipingIsActiveChanged,
}) => {
  const replyArrowRef = useRef(null);

  const { i18n, t } = useTranslation();
  const [menuVisible, setMenuVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const [draggingAfterTouchStarted, setDraggingAfterTouchStarted] = useState(true);
  const [dragWrapperOffsetX, setDragWrapperOffsetX] = useState(0);
  const [dragWrapperInitialClientX, setDragWrapperInitialClientX] = useState(0);
  const [swipingIsActive, setSwipingIsActive] = useState(false);

  if (!entityItem) {
    return (
      <div
        className={`${MAIN_CLASS_NAME} ${LOADING_CLASS_NAME}`}
        style={{ height: `${virtualItem.size || DEFAULT_MESSAGE_HEIGHT}px` }}
      ></div>
    );
  }

  const updateSwipingIsActive = (value: boolean) => {
    setSwipingIsActive(value);
    onSwipingIsActiveChanged?.(value);
  };

  const onDragWrapperTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.changedTouches[0];
    setDragWrapperInitialClientX(touch.clientX);
  };

  const onDragWrapperTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (!draggingAfterTouchStarted) {
      setDraggingAfterTouchStarted(true);
    }
    const touch = e.changedTouches[0];
    const translateX = Math.min(0, Math.max(-MESSAGE_SWIPE_BOUND, touch.clientX - dragWrapperInitialClientX));

    if (swipingIsActive) {
      setDragWrapperOffsetX(translateX);
    }

    if (!swipingIsActive && translateX < -MESSAGE_SWIPE_ACTIVATION_THRESHOLD) {
      updateSwipingIsActive(true);
      setDragWrapperOffsetX(translateX);
    }

    if (forcePreventSwiping) {
      updateSwipingIsActive(false);
      setDragWrapperOffsetX(translateX);
    }
  };

  const onDragWrapperTouchEnd = () => {
    if (dragWrapperOffsetX <= -MESSAGE_SWIPE_SWITCHING_THRESHOLD && !forcePreventSwiping) {
      onMessageQuote?.(entityItem);
    }
    updateSwipingIsActive(false);
    setDragWrapperOffsetX(0);
  };

  const { entity, operation } = entityItem;

  const withAttachmentInfo =
    attachmentItems?.some(
      (i) =>
        !isThumbnailAvailable(
          i.entity.file,
          i.entity.thumbnails.map((t) => t.file)
        )
    ) ||
    entity.attachments.some(
      (a) =>
        !isThumbnailAvailable(
          a.file,
          a.thumbnails.map((t) => t.file)
        )
    );

  const currentParticipantId = entity.participants?.find(
    (p) => p.subject.role === SUBJECT_ROLES.operator && p.subject.id === currentUserId
  )?.id;

  const isBotMessage =
    entity.participants?.find((p) => p.id === entity.senderParticipant?.id)?.subject.role === SUBJECT_ROLES.botAccount;

  const isInternal = entity.direction === InboxDirection.Internal;
  const isMyMessage =
    entity.senderParticipant &&
    (entity.senderParticipant.id === BOTIUM_USER_ID || entity.senderParticipant.id === currentParticipantId);

  const baseDirection =
    isBotMessage || !isMyMessage ? InboxDirection.Inbound.toLowerCase() : entity.direction.toLowerCase();
  const userDirection = isInternal
    ? (isMyMessage ? InboxDirection.Outbound : InboxDirection.Inbound).toLowerCase()
    : baseDirection;

  const contentDirection = (isInternal
    ? InboxDirection.Internal
    : isBotMessage || isMyMessage
    ? InboxDirection.Outbound
    : InboxDirection.Inbound
  ).toLowerCase();

  const onOpenMenu = () => {
    setMenuVisible(true);
  };

  const onCloseMenu = () => {
    setMenuVisible(false);
  };

  const onCloseMobileMenu = () => {
    setMobileMenuVisible(false);
  };

  const onResendSelect = () => {
    onMessageResend?.();
    onCloseMenu();
  };

  const onDeleteSelect = () => {
    onMessageDelete?.();
    onCloseMenu();
  };

  const onBubbleTouchStart = () => setDraggingAfterTouchStarted(false);

  const onBubbleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (
      draggingAfterTouchStarted ||
      menuVisible ||
      operation.status === OperationStatus.Pending ||
      operation.status === OperationStatus.Failure ||
      !isTouchOnlyDevice() ||
      e.defaultPrevented
    ) {
      return;
    }

    setMobileMenuVisible(true);
    e.preventDefault();
  };

  const onBubbleClick = () => {
    if (isTouchOnlyDevice()) {
      return;
    }

    setMobileMenuVisible(true);
  };

  const renderText = () => {
    if (entity.name === MessageName.InboxAssignmentRead) {
      const participant = entity.assignments[0];
      return t('ChatRead', { name: participant?.subject.shortName });
    }

    if (entity.name === MessageName.InboxAssignmentAssignOperator) {
      const participant = entity.assignments.find((p) => p.status === InboxMessageParticipantStatus.Assigned);
      return t('ChatAssignedToOperator', { name: participant?.subject.shortName || participant?.subject.fullName });
    }

    if (entity.name === MessageName.InboxAssignmentAssignOperatorGroup) {
      return t('ChatAssignedToOperatorGroup', { name: entity.assignedOperatorGroup?.name || '—' });
    }

    if (entity.name === MessageName.InboxAssignmentAssignBot) {
      return t('ChatAssignedToBot');
    }

    if (entity.name === MessageName.InboxAssignmentClose) {
      return t('ChatClosed');
    }

    if (entity.name === MessageName.InboxAssignmentQueue) {
      return t('ChatQueued');
    }

    if (!entity.content.text && !entity.mentions.length) {
      return null;
    }

    return (
      <IbMessage
        content={{ text: entity.content.text, type: getContentType(entity, isBotMessage) }}
        inline={false}
        mentionLinksEnabled={mentionLinksEnabled}
        mentions={entity.mentions}
      />
    );
  };

  const renderTitle = () => <div className={TITLE_CLASS_NAME}>{renderText()}</div>;

  const renderParticipant = () => {
    const participant = entity.participants.find((p) => p.id === entity.senderParticipant?.id);

    if (isMyMessage) return null;

    if (isBotMessage) {
      return (
        <div className={PARTICIPANT_CLASS_NAME}>
          <div className={PARTICIPANT_AVATAR_CLASS_NAME}>
            <IbAvatar size="small">
              <BotAvatarLogo />
            </IbAvatar>
          </div>
        </div>
      );
    }

    return (
      <div className={PARTICIPANT_CLASS_NAME}>
        {!sameSenderAsNextMessage && (
          <IbAvatar
            imgSrc={participant?.subject.avatar}
            metadata={{ uid: participant?.subject.id || '', text: participant?.subject.fullName || '' }}
            size="small"
          />
        )}
      </div>
    );
  };

  const renderSenderName = () => {
    if (isMyMessage || isBotMessage) return null;

    const participant = entity.participants.find((p) => p.id === entity.senderParticipant?.id);
    if (participant?.subject.role === SUBJECT_ROLES.user) return null;

    return (
      participant && (
        <IbTypography.Paragraph strong type="secondary">
          {participant.subject.fullName}
        </IbTypography.Paragraph>
      )
    );
  };

  const renderMessageAttachment = (messageAttachment: InboxMessageAttachmentModel) => {
    const contentUrl = tryGetContentUrl(messageAttachment.file);
    const thumbnailUrl = tryGetThumbnailUrl(
      messageAttachment.file,
      messageAttachment.thumbnails.map((t) => t.file)
    );
    const participant = entity.participants.find((p) => p.id === entity.senderParticipant?.id);
    const previewAttachments = entity.attachments?.filter((a) => isPreviewAvailable(a.file)) ?? [];

    const onAttachmentItemPreview = () => {
      const params = {
        isOpen: true,
        items: previewAttachments.map((a, i) => {
          return { index: i, url: tryGetPreviewUrl(a.file) ?? '' };
        }),
        onReply: () => {
          onMessageQuote?.(entityItem);
        },
        channelId: channelId,
        selectedIndex: previewAttachments.indexOf(messageAttachment),
        senderParticipant: participant,
        timestamp: entity.timestamp,
      } as IPreviewModalParams;

      onAttachmentPreview?.(params);
    };

    return (
      <div key={messageAttachment.external.id} className={ATTACHMENTS_ITEM_CLASS_NAME}>
        <IbAttachmentItem
          category={messageAttachment.file.category}
          fileName={withAttachmentInfo ? messageAttachment.file.name : undefined}
          fileSize={withAttachmentInfo ? messageAttachment.file.size : undefined}
          fileUrl={contentUrl}
          mediaGroup={messageAttachment.file.mediaGroup}
          mediaType={messageAttachment.file.mediaType}
          thumbnailUrl={thumbnailUrl}
          onPreview={onAttachmentItemPreview}
        />
      </div>
    );
  };

  const renderAttachmentItem = (attachmentItem: IEntityItem<InboxAttachmentModel>) => {
    const thumbnailUrl = tryGetThumbnailUrl(
      attachmentItem.entity.file,
      attachmentItem.entity.thumbnails.map((t) => t.file)
    );

    const onAttachmentItemReUpload = () => onAttachmentReUpload?.(attachmentItem.entity);
    const onAttachmentItemDelete = () => onAttachmentDelete?.(attachmentItem.entity, attachmentItem.operation);

    const participant = entity.participants.find((p) => p.id === entity.senderParticipant?.id);
    const previewAttachments = entity.attachments?.filter((a) => isPreviewAvailable(a.file)) ?? [];

    const onAttachmentItemPreview = () => {
      const params = {
        isOpen: true,
        items: previewAttachments.map((a, i) => {
          return { index: i, url: tryGetPreviewUrl(a.file) ?? '' };
        }),
        onReply: () => {
          onMessageQuote?.(entityItem);
        },
        channelId: channelId,
        selectedIndex: previewAttachments.indexOf(attachmentItem.entity),
        senderParticipant: participant,
        timestamp: entity.timestamp,
      } as IPreviewModalParams;

      onAttachmentPreview?.(params);
    };

    return (
      <div key={attachmentItem.entity.external.id} className={ATTACHMENTS_ITEM_CLASS_NAME}>
        <IbAttachmentItem
          category={attachmentItem.entity.file.category}
          fileName={withAttachmentInfo ? attachmentItem.entity.file.name : undefined}
          fileSize={withAttachmentInfo ? attachmentItem.entity.file.size : undefined}
          loadingCurrent={attachmentItem.operation.progress.current}
          loadingMaximum={attachmentItem.operation.progress.maximum}
          loadingStatus={attachmentItem.operation.status}
          mediaGroup={attachmentItem.entity.file.mediaGroup}
          mediaType={attachmentItem.entity.file.mediaType}
          thumbnailUrl={thumbnailUrl}
          onDelete={onAttachmentItemDelete}
          onPreview={onAttachmentItemPreview}
          onReUpload={onAttachmentItemReUpload}
        />
      </div>
    );
  };

  const renderAttachments = () => {
    if (!attachmentItems?.length && !entity.attachments.length) {
      return null;
    }

    const attachmentClasses = [
      ATTACHMENTS_CLASS_NAME,
      withAttachmentInfo ? ATTACHMENTS_VERTICAL_CLASS_NAME : ATTACHMENTS_HORIZONTAL_CLASS_NAME,
    ];

    return (
      <div className={attachmentClasses.join(' ')}>
        {attachmentItems ? attachmentItems.map(renderAttachmentItem) : entity.attachments.map(renderMessageAttachment)}
      </div>
    );
  };

  const renderQuotedMessages = () => {
    if (!entity.quotedMessages.length) {
      return null;
    }
    return (
      <div className={QUOTED_MESSAGES_CLASS_NAME}>
        {entity.quotedMessages.map((m) => {
          const onClick = () => onQuotedMessageClick?.(m.id, m.activity.id);
          return (
            <IbQuotedMessage
              key={m.id}
              attachments={m.attachments}
              content={m.content}
              direction={m.direction}
              mentionLinksEnabled={mentionLinksEnabled}
              mentions={m.mentions}
              senderFullname={m.senderParticipant?.subject.fullName}
              onClick={onClick}
            />
          );
        })}
      </div>
    );
  };

  const renderTimestamp = () => {
    const timestamp = formatTimeShort(entity.timestamp, i18n.language);
    return (
      <div className={TIMESTAMP_CLASS_NAME}>
        {isInternal && <IbIcon className="lock-icon" iconName="lock" size={16} />}
        <span className={TIMESTAMP_TEXT_CLASS_NAME}>{timestamp}</span>
      </div>
    );
  };

  const renderIcon = () => {
    if (!isMyMessage) {
      return null;
    }

    const menuItems = [
      {
        icon: <IbIcon iconName="refresh" size={MENU_ICON_SIZE} />,
        text: t('ResendMessage'),
        onSelect: onResendSelect,
      },
      {
        icon: <IbIcon iconName="delete" size={MENU_ICON_SIZE} />,
        text: t('DeleteMessage'),
        onSelect: onDeleteSelect,
      },
    ] as IIbContextMenuItem[];

    switch (operation.status) {
      case OperationStatus.Pending:
      case OperationStatus.Process:
        return <IbIcon iconName="time" size={STATUS_ICON_SIZE} />;
      case OperationStatus.Failure:
        return (
          <IbContextMenu menuItems={menuItems} trigger="leftClick" visible={menuVisible} onClose={onCloseMenu}>
            <div className={FAILURE_CLASS_NAME}>
              <IbButton onClick={onOpenMenu}>
                <IbIcon iconName="attention" size={STATUS_ICON_SIZE} theme="filled" />
              </IbButton>
            </div>
          </IbContextMenu>
        );
    }

    if (entity.status !== InboxMessageStatus.Ready) {
      return <IbIcon iconName="time" size={STATUS_ICON_SIZE} />;
    }

    return <IbIcon iconName="check-small" size={STATUS_ICON_SIZE} />;
  };

  const renderStatus = () => <div className={STATUS_CLASS_NAME}>{renderIcon()}</div>;

  const renderFooter = () => (
    <div className={`${FOOTER_CLASS_NAME} ${FOOTER_CLASS_NAME}_${userDirection}`}>
      {renderTimestamp()}
      {renderStatus()}
    </div>
  );

  const renderBody = () => {
    return (
      <div className={BODY_CLASS_NAME}>
        {renderText()}
        {renderFooter()}
      </div>
    );
  };

  const renderContent = () => {
    const menuItems = [
      {
        icon: <IbIcon iconName="next" size={MENU_ICON_SIZE} />,
        text: t('Reply'),
        onSelect: () => {
          onMessageQuote?.(entityItem);
        },
      },
    ];

    const contentClasses = [
      CONTENT_CLASS_NAME,
      `${CONTENT_CLASS_NAME}_${baseDirection} ${CONTENT_CLASS_NAME}_${contentDirection}`,
    ];
    return (
      <IbContextMenu menuItems={menuItems} trigger="rightClick" visible={mobileMenuVisible} onClose={onCloseMobileMenu}>
        <div
          className={contentClasses.join(' ')}
          onClick={onBubbleClick}
          onTouchEnd={onBubbleTouchEnd}
          onTouchStart={onBubbleTouchStart}
        >
          {renderSenderName()}
          {renderQuotedMessages()}
          {renderAttachments()}
          {renderBody()}
        </div>
      </IbContextMenu>
    );
  };

  if (entity.type === InboxMessageType.Content) {
    const mainClasses = [MAIN_CLASS_NAME, `${MAIN_CLASS_NAME}_${userDirection}`];
    swipingIsActive && !forcePreventSwiping && mainClasses.push(SWIPING_CLASS_NAME);

    const transform = `translate(${forcePreventSwiping ? 0 : dragWrapperOffsetX}px, 0px)`;

    return (
      <div
        className={mainClasses.join(' ')}
        style={{ transform }}
        onTouchEnd={onDragWrapperTouchEnd}
        onTouchMove={onDragWrapperTouchMove}
        onTouchStart={onDragWrapperTouchStart}
      >
        {!isMyMessage && renderParticipant()}
        {renderContent()}
        {!forcePreventSwiping && (
          <CSSTransition
            classNames={{ enterDone: REPLY_ARROW_ENTER_DONE_CLASS_NAME }}
            in={dragWrapperOffsetX <= -MESSAGE_SWIPE_ACTIVATION_THRESHOLD}
            nodeRef={replyArrowRef}
            timeout={MESSAGE_SWIPE_ANIMATION_TIMEOUT}
          >
            <div ref={replyArrowRef} className={REPLY_ARROW_CLASS_NAME}>
              <IbIcon iconName="next" size={20} />
            </div>
          </CSSTransition>
        )}
      </div>
    );
  }

  if (entity.type === InboxMessageType.History) {
    return <div className={`${MAIN_CLASS_NAME} ${HISTORY_CLASS_NAME}`}>{renderTitle()}</div>;
  }

  return null;
};

export default memo(
  IbMessageListItem,
  (prevProps, nextProps) =>
    prevProps.entityItem?.entity?.id === nextProps.entityItem?.entity?.id &&
    prevProps.entityItem?.entity?.status === nextProps.entityItem?.entity?.status &&
    prevProps.entityItem?.operation === nextProps.entityItem?.operation &&
    prevProps.virtualItem.key === nextProps.virtualItem.key &&
    prevProps.virtualItem.size === nextProps.virtualItem.size &&
    prevProps.forcePreventSwiping === nextProps.forcePreventSwiping
);
