import React, { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { useRecoilState, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { Flags } from 'react-feature-flags';
import { useHistory } from 'react-router';

import './index.less';

import IbTypography from '../../components/common/IbTypography';
import IbButton from '../../components/common/IbButton';
import IbIcon from '../../components/common/IbIcon';
import { ContactsEmptyLogo } from '../../assets';
import IbAvatar from '../../components/common/IbAvatar';
import IbTable, { IIbTableScrollProps, MOBILE_MAIN_CELL_CLASS_NAME } from '../../components/common/IbTable';
import {
  InboxContactModel,
  InboxContactModelPaginationResponse,
  PersonCreationRequest,
  PersonMergingRequest,
  PersonModel,
  PersonUpdatingRequest,
} from '../../../../api';
import { findAllIgnoreCase, formatLocation, formatPhoneNumber } from '../../../utils/stringUtil';
import { contactListFilterSelector, contactListSelector, tagListSelector } from '../../recoil';
import { FeatureFlagNames, RECOIL_LOADABLE_STATES } from '../../../constants';
import IbTag from '../../components/common/IbTag';
import IbSocial, { IbSocialType } from '../../components/common/IbSocial';
import IbCheckbox from '../../components/common/IbCheckbox';
import { inboxContactApi, personApi } from '../../../apis';
import IbContactEditModal from '../../components/IbContactEditModal';
import IbPageTitle from '../../components/IbPageTitle';
import IbConfirmModal from '../../components/common/IbConfirmModal';
import IbTagsManagementModalWrapper from '../../wrappers/IbTagsManagementModalWrapper';
import IbAccountWidgetWrapper from '../../wrappers/IbAccountWidgetWrapper';
import IbContactMergeModal from '../../components/IbContactMergeModal';
import { IIbTagsSearchData } from '../../components/IbTagsSearch';
import IbContactChannelSelector from '../../components/IbContactChannelSelector';
import IbContextMenu, { IIbContextMenuItem } from '../../components/common/IbContextMenu';
import IbSendMessageToChannelModal from '../../components/IbSendMessageToChannelModal';
import { getContactChannels } from '../../components/IbContactChannelList';

const MAIN_CLASS_NAME = 'ib-contacts-page';
const CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__content`;
const EMPTY_CONTENT_CLASS_NAME = `${CONTENT_CLASS_NAME}-empty`;
const EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME = `${EMPTY_CONTENT_CLASS_NAME}__placeholder`;
const TABLE_HEADER_CLASS_NAME = `${CONTENT_CLASS_NAME}__table-header`;
const TABLE_HEADER_ACTIONS_CLASS_NAME = `${TABLE_HEADER_CLASS_NAME}__actions`;
const TABLE_CLASS_NAME = `${CONTENT_CLASS_NAME}__table`;
const NAME_CLASS_NAME = `${TABLE_CLASS_NAME}__name`;
const PHONE_NUMBER_CLASS_NAME = `${TABLE_CLASS_NAME}__phone-number`;
const TAGS_CLASS_NAME = `${TABLE_CLASS_NAME}__tags`;
const CHANNELS_CLASS_NAME = `${TABLE_CLASS_NAME}__channels`;
const ACTIONS_CLASS_NAME = `${TABLE_CLASS_NAME}__actions`;
const EXPANDED_ROW_ACTIONS_CLASS_NAME = `${TABLE_CLASS_NAME}__expanded-row-actions`;
const FOUND_CLASS_NAME = 'found';

const ACTIONS_DATA_INDEX = 'actions';
const CHANNELS_DATA_INDEX = 'channels';
const OTHER_CONTACT_COUNT = 5;

// TODO: Вынести подсветку результатов поиска в отдельный компонент.
const renderSearchableText = (text: string, searchText?: string) => {
  if (!searchText) {
    return <span>{text}</span>;
  }

  const spans = findAllIgnoreCase(text, searchText);
  return (
    <span>
      {spans.map((s) => (s.found ? <span className={FOUND_CLASS_NAME}>{s.value}</span> : <span>{s.value}</span>))}
    </span>
  );
};

const ContactsPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { push } = useHistory();

  const [searchData, setSearchData] = useState({} as IIbTagsSearchData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([] as Key[]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [mergeModalVisible, setMergeModalVisible] = useState(false);
  const [deletePerson, setDeletePerson] = useState<PersonModel>();
  const [editContact, setEditContact] = useState<InboxContactModel>();
  const [chatWithContact, setChatWithContact] = useState<InboxContactModel>();
  const [deleting, setDeleting] = useState(false);
  const [contactList, setContactList] = useState([] as InboxContactModel[]);
  const [contactListHasMore, setContactListHasMore] = useState(false);
  const [contactListCount, setContactListCount] = useState(0);
  const [tagsManagementModalVisible, setTagsManagementModalVisible] = useState(false);
  const [otherContacts, setOtherContacts] = useState<InboxContactModel[]>([]);
  const [otherContactsLoading, setOtherContactsLoading] = useState(false);
  const [otherContactsSearch, setOtherContactsSearch] = useState('');
  const [openedMenuPersonId, setOpenedMenuPersonId] = useState<string>();

  const [contactListFilter, setContactListFilter] = useRecoilState(contactListFilterSelector);
  const contactListLoadable = useRecoilValueLoadable(contactListSelector);
  const contactListFilterIsSet = contactListFilter.search || !!contactListFilter.tagIds?.length;
  const contactListIsLoading = contactListLoadable.state === RECOIL_LOADABLE_STATES.loading && !contactListFilterIsSet;
  const contactListIsEmpty =
    !contactListIsLoading && !contactListLoadable.contents.items?.length && !contactListFilterIsSet;

  const tagList = useRecoilValue(tagListSelector);

  const channels = chatWithContact ? getContactChannels(chatWithContact, i18n) : [];

  const getSelectedPersons = () =>
    contactList.filter((c) => selectedRowKeys.includes(c.person.id)).map((c) => c.person);

  const closeAddEditModal = () => {
    setEditModalVisible(false);
    setEditContact(undefined);
  };

  const openAddEditModal = (contact: InboxContactModel) => {
    setEditContact(contact);
    setEditModalVisible(true);
  };

  const openSendMessageModal = (contact: InboxContactModel) => {
    setChatWithContact(contact);
  };

  const loadNextPage = () => {
    if (contactListHasMore) {
      setContactListFilter({ ...contactListFilter, pageIndex: contactListFilter.pageIndex + 1, loadMore: true });
    }
  };

  const resetFilter = () => setContactListFilter({ pageIndex: 0, loadMore: false });

  const resetFilterPage = () => setContactListFilter({ ...contactListFilter, pageIndex: 0, loadMore: false });

  const onAdd = () => {
    setEditContact(undefined);
    setEditModalVisible(true);
  };

  const onMerge = () => {
    setMergeModalVisible(true);
  };

  const onEditModalCancel = () => closeAddEditModal();

  const onMergeModalCancel = () => setMergeModalVisible(false);

  const onPersonDelete = (person: PersonModel) => () => setDeletePerson(person);

  const onSelectedRowKeysCheckboxChange = (value: boolean) =>
    setSelectedRowKeys(value ? contactList.map((c) => c.person.id) : []);

  const onTableRowClick = (contact: InboxContactModel, column: ColumnType<InboxContactModel>) => {
    if ([ACTIONS_DATA_INDEX, CHANNELS_DATA_INDEX].some((di) => di === column.dataIndex)) {
      return;
    }

    openAddEditModal(contact);
  };

  const onEditButtonClick = (contact: InboxContactModel) => () => openAddEditModal(contact);

  const onPersonAdd = async (request: PersonCreationRequest) => {
    try {
      await personApi.createPerson(request);
      resetFilterPage();
    } catch (e) {
      // empty
    }
  };

  const onSendMessage = (chatId: string) => push(`/inbox/chats/all/${chatId}`);

  const onPersonEdit = async (request: PersonUpdatingRequest) => {
    if (!editContact) {
      return;
    }

    try {
      await personApi.updatePerson(editContact.person.id, request);
      resetFilterPage();
    } catch (e) {
      // empty
    }
  };

  const onPersonMerge = async (request: PersonMergingRequest) => {
    try {
      await personApi.mergePersons(request);
      resetFilterPage();
    } catch (e) {
      // empty
    }
  };

  const onDeleteConfirm = async () => {
    if (!deletePerson) {
      return;
    }

    setDeleting(true);
    try {
      await personApi.deletePerson(deletePerson.id);
      resetFilterPage();
    } catch (e) {
      // empty
    }
    setDeleting(false);

    setDeletePerson(undefined);
    closeAddEditModal();
    setSelectedRowKeys([]);
  };

  const onDeleteModalCancel = () => setDeletePerson(undefined);

  const onDebouncedSearch = (value: IIbTagsSearchData) => {
    setSearchData(value);
    setContactListFilter({
      loadMore: false,
      pageIndex: 0,
      search: value.searchValue,
      tagIds: value.selectedTagIds,
    });
  };

  const onTagsManagement = () => setTagsManagementModalVisible(true);

  const onTagsManagementModalCancel = () => setTagsManagementModalVisible(false);

  const onSendMessageToChannelModalCancel = () => setChatWithContact(undefined);

  const onOpenMenu = (personId: string) => {
    setOpenedMenuPersonId(personId);
  };

  const onCloseMenu = () => {
    setOpenedMenuPersonId(undefined);
  };

  const onOtherContactsSearch = (search?: string) => {
    setOtherContactsSearch(search || '');
  };

  const loadOtherContactsAsync = async () => {
    setOtherContactsLoading(true);
    try {
      const response = await inboxContactApi.searchInboxContacts(
        otherContactsSearch,
        undefined,
        0,
        OTHER_CONTACT_COUNT
      );
      setOtherContacts(response.data.items || []);
    } catch (e) {
      // empty
    }
    setOtherContactsLoading(false);
  };
  const loadOtherContacts = () => {
    loadOtherContactsAsync().finally();
  };
  useEffect(loadOtherContacts, [otherContactsSearch]);

  const onContactListLoadableStateChange = () => {
    if (contactListLoadable.state !== RECOIL_LOADABLE_STATES.loading) {
      const data = contactListLoadable.contents as InboxContactModelPaginationResponse;
      setContactList(data.items || []);
      setContactListCount(data.totalItemCount || 0);
      setContactListHasMore(!!data.hasMore);
    }
  };
  useEffect(onContactListLoadableStateChange, [contactListLoadable.state]);

  const onInit = () => () => resetFilter();
  useEffect(onInit, []);

  const getContactActions = (contact: InboxContactModel) => {
    const actions: IIbContextMenuItem[] = [
      {
        disabled: !contact.activities.length,
        icon: <IbIcon iconName="comment" />,
        text: t('Send message to channel'),
        onSelect: () => openSendMessageModal(contact),
      },
      {
        icon: <IbIcon iconName="edit" />,
        text: t('Edit'),
        onSelect: () => openAddEditModal(contact),
      },
      {
        icon: <IbIcon iconName="delete" />,
        text: t('Delete'),
        onSelect: onPersonDelete(contact.person),
      },
    ];
    return actions;
  };

  const columns: ColumnType<InboxContactModel>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      title: t('Name'),
      render: (_, record) => (
        <div className={NAME_CLASS_NAME}>
          <IbAvatar
            imgSrc={record.person.avatarUrl}
            metadata={{ uid: record.person.id, text: record.person.fullName }}
            size="x-small"
          />
          <IbTypography.Paragraph type="secondary">
            {renderSearchableText(record.person.fullName, searchData.searchValue)}
          </IbTypography.Paragraph>
        </div>
      ),
    },
    {
      title: t('Activity'),
      render: (_, record) => {
        const channels = getContactChannels(record, i18n);

        return (
          <IbContactChannelSelector
            channels={channels}
            selectedChannel={channels[0]}
            onSendMessageToChat={onSendMessage}
          />
        );
      },
      dataIndex: CHANNELS_DATA_INDEX,
    },
    {
      title: t('Phone number'),
      render: (_, record) => (
        <IbTypography.Paragraph className={PHONE_NUMBER_CLASS_NAME} type="secondary">
          {formatPhoneNumber(record.person.primaryTel)}
        </IbTypography.Paragraph>
      ),
    },
    {
      title: t('E-mail'),
      render: (_, record) => <IbTypography.Paragraph type="secondary">{record.person.eMail}</IbTypography.Paragraph>,
    },
    {
      title: t('Tags'),
      render: (_, record) => (
        <span className={TAGS_CLASS_NAME}>
          {record.person.tags?.map((tag) => (
            <IbTag key={tag.id} content={tag.label} style={{ background: tag.color }} tooltipContent={tag.label} />
          ))}
        </span>
      ),
    },
    {
      title: t('Channels'),
      render: (_, record) => (
        <span className={CHANNELS_CLASS_NAME}>
          {record.person.personAccounts?.map((personAccount) => (
            <IbSocial key={personAccount.id} social={personAccount.channelId as IbSocialType} />
          ))}
        </span>
      ),
    },
    {
      title: `${t('Country')}, ${t('City')}`,
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">
          {formatLocation(record.person.country, record.person.city, i18n)}
        </IbTypography.Paragraph>
      ),
    },
    {
      className: ACTIONS_CLASS_NAME,
      title: '',
      render: (_, record) => {
        const onMenuButtonClick = () => onOpenMenu(record.person.id);
        return (
          <IbContextMenu
            menuItems={getContactActions(record)}
            visible={openedMenuPersonId === record.person.id}
            onClose={onCloseMenu}
          >
            <IbButton icon={<IbIcon iconName="more-one" />} type="icon" onClick={onMenuButtonClick} />
          </IbContextMenu>
        );
      },
      dataIndex: ACTIONS_DATA_INDEX,
    },
  ];

  const expandableForMobile: ExpandableConfig<InboxContactModel> = {
    expandedRowRender: (record) => {
      const channels = getContactChannels(record, i18n);
      return (
        <>
          <ul>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Activity')}
              </IbTypography.Paragraph>
              <IbContactChannelSelector
                channels={channels}
                selectedChannel={channels[0]}
                onSendMessageToChat={onSendMessage}
              />
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Phone number')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatPhoneNumber(record.person.primaryTel)}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('E-mail')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">{record.person.eMail}</IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Tags')}
              </IbTypography.Paragraph>
              <div className={TAGS_CLASS_NAME}>
                {record.person.tags?.map((tag) => (
                  <IbTag
                    key={tag.id}
                    content={tag.label}
                    style={{ background: tag.color }}
                    tooltipContent={tag.label}
                  />
                ))}
              </div>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Channels')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {record.person.personAccounts?.map((personAccount) => (
                  <IbSocial key={personAccount.id} social={personAccount.channelId as IbSocialType} />
                ))}
              </IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Country')}, {t('City')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {formatLocation(record.person.country, record.person.city, i18n)}
              </IbTypography.Paragraph>
            </li>
          </ul>
          <div className={EXPANDED_ROW_ACTIONS_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="edit" />} type="link" onClick={onEditButtonClick(record)}>
              {t('Edit')}
            </IbButton>
            <IbButton icon={<IbIcon iconName="delete" />} type="link" onClick={onPersonDelete(record.person)}>
              {t('Delete')}
            </IbButton>
          </div>
        </>
      );
    },
  };

  const scroll = {
    dataLength: contactList.length,
    hasMore: contactListHasMore,
    next: loadNextPage,
  } as IIbTableScrollProps;

  const renderContactEditModal = (allowTagsManagement: boolean) => (
    <IbContactEditModal
      allowDelete
      allowTagsManagement={allowTagsManagement}
      editContact={editContact}
      otherPersons={otherContacts.map((c) => c.person)}
      otherPersonsLoading={otherContactsLoading}
      tagList={tagList}
      visible={editModalVisible}
      onAdd={onPersonAdd}
      onCancel={onEditModalCancel}
      onDelete={editContact ? onPersonDelete(editContact.person) : () => {}}
      onEdit={onPersonEdit}
      onMerge={onPersonMerge}
      onPersonsSearch={onOtherContactsSearch}
      onSendMessage={onSendMessage}
      onTagsManagement={onTagsManagement}
    />
  );

  const renderContactMergeModal = (allowTagsManagement: boolean) => (
    <IbContactMergeModal
      allowTagsManagement={allowTagsManagement}
      personList={getSelectedPersons()}
      tagList={tagList}
      visible={mergeModalVisible}
      onCancel={onMergeModalCancel}
      onMerge={onPersonMerge}
      onTagsManagement={onTagsManagement}
    />
  );

  const desktopTitleContent = (
    <IbButton
      disabled={selectedRowKeys.length <= 1}
      icon={<IbIcon iconName="link-two" />}
      type="secondary"
      onClick={onMerge}
    >
      {t('Merge')}
    </IbButton>
  );

  const mobileTitleContent = (
    <IbButton
      disabled={selectedRowKeys.length <= 1}
      icon={<IbIcon iconName="link-two" />}
      type="link"
      onClick={onMerge}
    />
  );

  return (
    <div className={MAIN_CLASS_NAME}>
      <IbPageTitle
        accountWidget={<IbAccountWidgetWrapper />}
        desktopContent={desktopTitleContent}
        mobileContent={mobileTitleContent}
        showSearchInput={!contactListIsEmpty}
        tagList={tagList}
        title={t('Contacts')}
        onAdd={onAdd}
        onDebouncedSearch={onDebouncedSearch}
      >
        <IbButton icon={<IbIcon iconName="link-two" />} type="secondary" onClick={onMerge}>
          {t('Merge')}
        </IbButton>
      </IbPageTitle>
      <div className={`${CONTENT_CLASS_NAME} ${contactListIsEmpty ? EMPTY_CONTENT_CLASS_NAME : ''}`}>
        {contactListIsEmpty ? (
          <div className={EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME}>
            <ContactsEmptyLogo />
            <IbTypography>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Add your current clients')}
                <br />
                {t('and process messages in one system')}
              </IbTypography.Paragraph>
            </IbTypography>
            <IbButton icon={<IbIcon iconName="add-one" />} onClick={onAdd}>
              {t('Add')}
            </IbButton>
          </div>
        ) : (
          <>
            <div className={TABLE_HEADER_CLASS_NAME}>
              <IbCheckbox
                value={selectedRowKeys.length > 0 && selectedRowKeys.length === contactList.length}
                onChange={onSelectedRowKeysCheckboxChange}
              />
              {selectedRowKeys.length ? (
                <IbTypography.Paragraph disabled type="secondary">
                  {t('Selected')}: {selectedRowKeys.length}
                </IbTypography.Paragraph>
              ) : (
                <IbTypography.Paragraph disabled type="secondary">
                  {t('Count')}: {contactListCount}
                </IbTypography.Paragraph>
              )}
              <div className={TABLE_HEADER_ACTIONS_CLASS_NAME}>
                <IbButton icon={<IbIcon iconName="filter" />} type="icon" />
              </div>
            </div>
            <IbTable
              className={TABLE_CLASS_NAME}
              columns={columns}
              dataSource={contactList}
              emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
              expandableForMobile={expandableForMobile}
              loading={contactListIsLoading && !contactListFilter.loadMore && !contactList.length}
              rowKey={(record: InboxContactModel) => {
                return record.person.id;
              }}
              scroll={scroll}
              selectedRowKeys={selectedRowKeys}
              onRowClick={onTableRowClick}
              onSelectedRowKeysChange={setSelectedRowKeys}
            />
          </>
        )}
      </div>
      <Flags
        authorizedFlags={[FeatureFlagNames.INBOX_SUPERVISOR]}
        renderOff={() => renderContactEditModal(false)}
        renderOn={() => renderContactEditModal(true)}
      />
      <Flags
        authorizedFlags={[FeatureFlagNames.INBOX_SUPERVISOR]}
        renderOff={() => renderContactMergeModal(false)}
        renderOn={() => renderContactMergeModal(true)}
      />
      <IbConfirmModal
        footer={
          <>
            <IbButton disabled={deleting} onClick={onDeleteConfirm}>
              {t('Delete')}
            </IbButton>
            <IbButton type="secondary" onClick={onDeleteModalCancel}>
              {t('Cancel')}
            </IbButton>
            <IbButton type="fill" onClick={onDeleteModalCancel}>
              {t('Cancel (verb)')}
            </IbButton>
          </>
        }
        title={t('Delete contact?')}
        visible={!!deletePerson}
        onCancel={onDeleteModalCancel}
      >
        <IbTypography.Paragraph type="secondary">
          {t('All data associated with this user will be lost')}
        </IbTypography.Paragraph>
      </IbConfirmModal>
      <IbTagsManagementModalWrapper visible={tagsManagementModalVisible} onCancel={onTagsManagementModalCancel} />
      <IbSendMessageToChannelModal
        channels={channels}
        visible={!!chatWithContact}
        onCancel={onSendMessageToChannelModalCancel}
        onSendMessageToChat={onSendMessage}
      />
    </div>
  );
};

export default ContactsPage;
