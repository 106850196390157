import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VirtualItem } from '@tanstack/react-virtual';
import { Flags } from 'react-feature-flags';

import './index.less';

import { InboxAttachmentModel, InboxMessageModel } from '../../../../api';
import { IEntityItem } from '../../storages';
import IbMessageListItem from '../../components/IbMessageList/IbMessageListItem';
import { IPreviewModalParams, attachmentItemsSelector, attachmentRangeSelector } from '../../recoil';
import { InboxAttachmentFilter } from '../../storages/attachments';
import { ITaskOperation } from '../../tasks';
import { FeatureFlagNames } from '../../../constants';

export interface IMessageItemProps {
  currentUserId: string;
  entityItem: IEntityItem<InboxMessageModel>;
  virtualItem: VirtualItem;
  onMessageResend: () => void;
  onMessageDelete: () => void;
  onAttachmentReUpload?: (entity: InboxAttachmentModel) => void;
  onAttachmentDelete?: (entity: InboxAttachmentModel, operation: ITaskOperation) => void;
  onAttachmentPreview?: (params: IPreviewModalParams) => void;
}

const MessageItem: React.FC<IMessageItemProps> = ({
  currentUserId,
  entityItem,
  virtualItem,
  onMessageResend,
  onMessageDelete,
  onAttachmentReUpload,
  onAttachmentDelete,
  onAttachmentPreview,
}) => {
  const attachmentFilter = new InboxAttachmentFilter(entityItem.entity.activity.id);
  const attachmentItems = useRecoilValue(attachmentItemsSelector(attachmentFilter));
  const [,] = useRecoilState(attachmentRangeSelector(attachmentFilter));

  const renderMessageListItem = (mentionLinksEnabled: boolean) => {
    return (
      <IbMessageListItem
        attachmentItems={attachmentItems}
        currentUserId={currentUserId}
        entityItem={entityItem}
        mentionLinksEnabled={mentionLinksEnabled}
        virtualItem={virtualItem}
        onAttachmentDelete={onAttachmentDelete}
        onAttachmentPreview={onAttachmentPreview}
        onAttachmentReUpload={onAttachmentReUpload}
        onMessageDelete={onMessageDelete}
        onMessageResend={onMessageResend}
      />
    );
  };

  return (
    <Flags
      authorizedFlags={[FeatureFlagNames.USERS_MANAGEMENT]}
      renderOff={() => renderMessageListItem(false)}
      renderOn={() => renderMessageListItem(true)}
    />
  );
};

export default MessageItem;
