import { inboxChatApi } from '../../apis';
import {
  InboxChatAttachmentModel,
  InboxFileLinkContentModel,
  InboxFileModel,
  InboxMentionModel,
  InboxDirection,
} from '../../../api';
import { CreationMode, CreationState } from '../storages';
import { InboxAttachmentFilter, InboxAttachmentStorage } from '../storages/attachments';

import { IOperationProgress, ITaskHandler, OperationStatus } from './types';

export interface IMessageSendTaskContent {
  chatId: string;
  activityId: string;
  text: string;
  type: string;
  direction: InboxDirection;
  timestamp: string;
  mentions: InboxMentionModel[];
  quotedMessagesIds: string[];
}

export class MessageSendTaskHandler implements ITaskHandler<IMessageSendTaskContent> {
  private static s_instance?: MessageSendTaskHandler;

  public static get instance(): MessageSendTaskHandler {
    if (!MessageSendTaskHandler.s_instance) {
      MessageSendTaskHandler.s_instance = new MessageSendTaskHandler();
    }

    return MessageSendTaskHandler.s_instance;
  }

  public estimate(): IOperationProgress {
    return { current: 0, maximum: 1 };
  }

  public async process(content: IMessageSendTaskContent): Promise<unknown> {
    const attachmentStorage = InboxAttachmentStorage.instance;
    const attachmentFilter = new InboxAttachmentFilter(content.activityId);
    const attachmentPages = await attachmentStorage.requestRange(attachmentFilter);
    const chatAttachments: InboxChatAttachmentModel[] = [];

    for (const attachmentPage of attachmentPages) {
      for (const attachmentItem of attachmentPage.items) {
        let attachmentFile: InboxFileModel;
        const options = { mode: CreationMode.Sync, state: CreationState.Subsequent };

        if (attachmentItem.operation.status === OperationStatus.Failure) {
          const attachmentResult = await attachmentStorage.sendItem(attachmentItem.entity, options);
          attachmentFile = attachmentResult.entity.file;
        } else {
          attachmentFile = attachmentItem.entity.file;
        }

        chatAttachments.push({
          fileId: attachmentFile.id,
          fileName: attachmentFile.name,
          contentType: attachmentFile.mimeType,
          contentUrl: (attachmentFile.content[0] as InboxFileLinkContentModel).url,
        });
      }
    }

    return await inboxChatApi.sendInboxMessage(content.chatId, {
      activityId: content.activityId,
      content: { text: content.text, type: content.type },
      direction: content.direction,
      timestamp: content.timestamp,
      mentions: content.mentions,
      attachments: chatAttachments,
      quotedMessagesIds: content.quotedMessagesIds,
    });
  }
}
