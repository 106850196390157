export const MAIN_CLASS_NAME = 'ib-tags-management-modal';

export const EMPTY_CONTENT_CLASS_NAME = `${MAIN_CLASS_NAME}__empty-content`;
export const TAG_LIST_CLASS_NAME = `${MAIN_CLASS_NAME}__tag-list`;
export const TAG_LIST_ITEM_CLASS_NAME = `${TAG_LIST_CLASS_NAME}__item`;
export const TAG_LIST_ITEM_HIDDEN_CLASS_NAME = `${TAG_LIST_ITEM_CLASS_NAME}_hidden`;
export const TAG_LIST_ITEM_DRAGGING_CLASS_NAME = `${TAG_LIST_ITEM_CLASS_NAME}_dragging`;
export const TAG_LIST_ITEM_DRAG_DISABLED_CLASS_NAME = `${TAG_LIST_ITEM_CLASS_NAME}_drag-disabled`;
export const TAG_LIST_ITEM_TAG_CONTAINER_CLASS_NAME = `${TAG_LIST_ITEM_CLASS_NAME}__tag-container`;
export const TAG_LIST_ITEM_ACTIONS_CLASS_NAME = `${TAG_LIST_ITEM_CLASS_NAME}__actions`;

export const FORM_ITEM_CLASS_NAME = `${MAIN_CLASS_NAME}__form-item`;
export const COLOR_PICKER_CLASS_NAME = `${MAIN_CLASS_NAME}__color-picker`;
export const COLOR_PICKER_CONTROL_CLASS_NAME = `${COLOR_PICKER_CLASS_NAME}__control`;
export const COLOR_PICKER_PREVIEW_CLASS_NAME = `${COLOR_PICKER_CLASS_NAME}__preview`;
export const COLOR_PICKER_PREVIEW_CONTAINER_CLASS_NAME = `${COLOR_PICKER_PREVIEW_CLASS_NAME}__container`;
export const DELETE_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__delete-button`;
export const CREATE_BUTTON_CLASS_NAME = `${MAIN_CLASS_NAME}__create-button`;
export const DELETE_BUTTON_FOOTER_CLASS_NAME = `${DELETE_BUTTON_CLASS_NAME}_footer`;
export const CREATE_BUTTON_FOOTER_CLASS_NAME = `${CREATE_BUTTON_CLASS_NAME}_footer`;

export const DROPPABLE_ID = 'ib-tags-management-droppable';

export const ALERT_CLOSING_TIMEOUT = 2000; // ms
