import React from 'react';
import { useTranslation } from 'react-i18next';

import { TagModel } from '../../../../api';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import IbColorPicker from '../common/IbColorPicker';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTag from '../common/IbTag';
import { TAG_COLORS } from '../../../constants';

import {
  COLOR_PICKER_CLASS_NAME,
  COLOR_PICKER_CONTROL_CLASS_NAME,
  COLOR_PICKER_PREVIEW_CLASS_NAME,
  COLOR_PICKER_PREVIEW_CONTAINER_CLASS_NAME,
  DELETE_BUTTON_CLASS_NAME,
  FORM_ITEM_CLASS_NAME,
} from './const';
import { validateTagEditForm } from './utils';

interface ITagEditFormProps {
  tag: TagModel;
  showValidation: boolean;
  onEdit: (tag: TagModel) => void;
  onDelete?: () => void;
}

const TagEditForm: React.FC<ITagEditFormProps> = ({ tag, showValidation, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const validationResult = validateTagEditForm(tag);

  const onLabelChange = (value: string) => onEdit({ ...tag, label: value });
  const onDescriptionChange = (value: string) => onEdit({ ...tag, description: value });
  const onColorChange = (value: string) => onEdit({ ...tag, color: value });
  const onDeleteButtonClick = () => onDelete?.();

  return (
    <>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Name2')}</IbTypography.Paragraph>
        <IbInput
          status={showValidation && !validationResult.label.isValid ? 'error' : 'default'}
          value={tag.label || undefined}
          onChange={onLabelChange}
        />
      </div>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Description (optional)')}</IbTypography.Paragraph>
        <IbInput value={tag.description || undefined} onChange={onDescriptionChange} />
      </div>
      <div className={COLOR_PICKER_CLASS_NAME}>
        <div className={COLOR_PICKER_CONTROL_CLASS_NAME}>
          <IbTypography.Paragraph type="secondary">{t('Select tag color')}</IbTypography.Paragraph>
          <IbColorPicker color={tag.color} colorList={TAG_COLORS} onChange={onColorChange} />
        </div>
        <div className={COLOR_PICKER_PREVIEW_CLASS_NAME}>
          <IbTypography.Paragraph disabled type="secondary">
            {t('Preview')}
          </IbTypography.Paragraph>
          <div className={COLOR_PICKER_PREVIEW_CONTAINER_CLASS_NAME}>
            <IbTag content={tag.label || t('Name')} style={{ background: tag.color }} />
          </div>
        </div>
      </div>
      {onDelete && (
        <div className={FORM_ITEM_CLASS_NAME}>
          <IbButton
            key="delete-tag"
            className={DELETE_BUTTON_CLASS_NAME}
            icon={<IbIcon iconName="delete" />}
            type="link"
            onClick={onDeleteButtonClick}
          >
            {t('Delete')}
          </IbButton>
        </div>
      )}
    </>
  );
};

export default TagEditForm;
