import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import Select, { SelectValue } from 'antd/lib/select';

import './index.less';

import { ButtonSchema, ButtonType, SchemaKind } from '../../../../../../api';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbTextArea from '../../../../../simple-bot/components/common/SbTextArea';
import SbTable from '../../../../../simple-bot/components/common/SbTable';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import { NEW_LINE_DIVIDER } from '../../../../../constants/editors';

const MODAL_WIDTH = 916;

interface ISynonymRecord {
  key: string;
  value: string;
}

interface SettingModalProps {
  visible: boolean;
  button: ButtonSchema;
  parentKind: SchemaKind;
  onChange: (schema: ButtonSchema) => void;
  onModalClose: () => void;
}

const SettingModal: React.FC<SettingModalProps> = ({ visible, button, parentKind, onChange, onModalClose }) => {
  const [tableFocusRowKey, setTableFocusRowKey] = useState<string>();
  const [editAsList, setEditAsList] = useState(true);
  const [synonyms, setSynonyms] = useState(
    button.synonyms.map((s, i) => ({ key: i.toString(), value: s } as ISynonymRecord))
  );
  const [buttonValue, setButtonValue] = useState(button.value);
  const [buttonType, setButtonType] = useState(button.type);
  const [url, setUrl] = useState(button.url);

  const resetState = () => {
    if (!visible) return;

    setButtonValue(button.value);
    setSynonyms(button.synonyms.map((s, i) => ({ key: i.toString(), value: s } as ISynonymRecord)));
    setEditAsList(true);
    setButtonType(button.type);
    setUrl(button.url);
  };
  useEffect(resetState, [visible, button]);

  const onSaveButtonClick = () =>
    onChange({
      ...button,
      synonyms: synonyms.filter((s) => s.value).map((s) => s.value),
      value: buttonValue,
      type: buttonType,
      url: buttonType === ButtonType.Url ? url : undefined,
    });

  const onModalCancel = () => onModalClose();

  const onCancelButtonClick = () => onModalClose();

  const onValueChange = (value: string) => setButtonValue(value);

  const onSynonymsChange = (value: string) =>
    setSynonyms(
      value.split(NEW_LINE_DIVIDER).map((s, i) => ({
        key: i.toString(),
        value: s,
      }))
    );

  const onAddSynonymButtonClick = () => {
    const key = nanoid(10);
    setTableFocusRowKey(key);
    setSynonyms([{ key, value: '' }, ...synonyms]);
  };

  const onEditModeButtonClick = () => {
    setTableFocusRowKey(undefined);
    setEditAsList(!editAsList);
  };

  const onRemoveSynonymButtonClick = (synonym: ISynonymRecord) =>
    setSynonyms(synonyms.filter((s) => s.key !== synonym.key));

  const onSaveSynonym = (synonym: ISynonymRecord) =>
    setSynonyms(synonyms.map((s) => (s.key === synonym.key ? synonym : s)));

  const onButtonTypeChange = (value: SelectValue) => {
    setButtonType(value as ButtonType);
  };

  const onUrlChange = (value: string) => {
    setUrl(value);
  };

  const columns = [
    {
      dataIndex: 'value',
      key: 'value',
      editable: true,
      onSave: onSaveSynonym,
      // eslint-disable-next-line @typescript-eslint/ban-types
      render: (_: object, synonym: ISynonymRecord) => (
        <div>
          <span>{synonym.value}</span>
          <SbButton sbSize="medium" sbType="link" onClick={() => onRemoveSynonymButtonClick(synonym)}>
            Удалить вариант
          </SbButton>
        </div>
      ),
    },
  ];

  return (
    <SbModal
      footer={[
        <SbButton key="save" disabled={!buttonValue} sbSize="medium" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройка кнопки"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
    >
      <div className="button-settings">
        <div className="button-settings__main">
          <div className="sb-modal__subtitle">
            <h3>Альтернативный текст</h3>
            <div className="sb-modal__subtitle-actions">
              {editAsList && (
                <SbButton sbSize="medium" sbType="tertiary" onClick={onAddSynonymButtonClick}>
                  <SbIcon iconName="plus" size={16} />
                  Добавить вариант
                </SbButton>
              )}
              <SbButton sbSize="medium" sbType="tertiary" onClick={onEditModeButtonClick}>
                <SbIcon iconName={editAsList ? 'edit' : 'align-text-both'} size={16} />
                {editAsList ? 'Редактировать список' : 'Вернуться к списку'}
              </SbButton>
            </div>
          </div>
          <div className="button-settings__main__alt-texts-container">
            {editAsList ? (
              <SbTable
                editable
                columns={columns}
                dataSource={synonyms}
                emptyText={
                  <SbTypography>
                    <h4>Альтернативные варианты отсутствуют</h4>
                    <small>
                      Для ввода альтернативных вариантов нажмите кнопку <b>+ Добавить вариант</b>
                    </small>
                  </SbTypography>
                }
                focusRowKey={tableFocusRowKey}
                header={false}
              />
            ) : (
              <SbTextArea
                autoFocus
                value={synonyms.map((s) => s.value).join(NEW_LINE_DIVIDER)}
                onChange={onSynonymsChange}
              />
            )}
          </div>
          <SbTypography>
            <h3>Основной текст</h3>
            <SbInput allowClear={false} value={buttonValue} onChange={onValueChange} />
            {parentKind === SchemaKind.ChoiceTransit && (
              <>
                <h3>Тип кнопки</h3>
                <SbSelect sbType="light" value={buttonType} onChange={onButtonTypeChange}>
                  <Select.Option key={ButtonType.Transit} value={ButtonType.Transit}>
                    Обычная
                  </Select.Option>
                  <Select.Option key={ButtonType.Url} value={ButtonType.Url}>
                    Ссылка
                  </Select.Option>
                </SbSelect>
                {buttonType === ButtonType.Url && (
                  <SbInput
                    allowClear={false}
                    placeholder="Введите адрес ссылки"
                    value={url ?? ''}
                    onChange={onUrlChange}
                  />
                )}
              </>
            )}
          </SbTypography>
        </div>
        <div className="button-settings__description">
          <SbPanel sbType="help">
            <SbTypography>
              <h3>Текст кнопки</h3>
              <p>
                Бывает, что пользователь вместо клика на кнопку может написать текст, который будет совпадать по смыслу
                с текстом кнопки. Вы можете предусмотреть эти слова в поле “Альтернативный текст”
              </p>
            </SbTypography>
          </SbPanel>
        </div>
      </div>
    </SbModal>
  );
};

export default SettingModal;
