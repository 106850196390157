/* eslint-disable react-hooks/rules-of-hooks */
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilCallback, CallbackInterface } from 'recoil';
import throttle from 'lodash/throttle';

import {
  BindingSchema,
  DefaultActionGroupSchema,
  DefaultScenarioSchema,
  EndScenarioOutputSchema,
  SchemaKind,
} from '../../../api';
import {
  IEntityPosition,
  IInitDragInfo,
  IInputConnectionPosition,
  IOutputConnectionPosition,
  IPosition,
  IWorkingSpaceTransform,
} from '../../components/ScenarioEditor/types';
import {
  deleteBindingWithReferences,
  generateId,
  generateNewGroup,
  getElementId,
  getElementRect,
  getScenarioEditorContainerRect,
  tryGetElementById,
} from '../../components/ScenarioEditor/utils';
import { groupsMarginX, groupsMarginY, groupWidth } from '../../components/ScenarioEditor/constants';
import { ANIMATION_TIMEOUT } from '../../simple-bot/const';
import { currentScenarioValidationStatusSelector } from '../../simple-bot/recoil';

import {
  currentScenarioStructureState,
  groupDraggingState,
  inputConnectionPositionsState,
  outputConnectionPositionsState,
  scenarioStructureStackIndexState,
  scenarioStructureStackState,
  workingSpaceTransformState,
  zoomState,
} from './atom';
import {
  currentScenarioStructureSelector,
  dragSourceSelector,
  dragTargetSelector,
  draggingBindingCurrentPositionSelector,
  groupPositionsSelector,
  scenarioStructureStackSelector,
  scenarioStructureStackSelectorCanRedo,
  scenarioStructureStackSelectorCanUndo,
  workingSpaceTransformSelector,
  selectedEntitySelector,
  groupPlaceholderPositionSelector,
  draggingBindingStartPositionSelector,
  capturedBindingSelector,
  groupPlaceholderPossiblePositionSelector,
  positionForNewGroupSelector,
  groupsMenuIsVisibleSelector,
  currentScenarioValidationResultSelector,
  workingSpaceTransformAnimationSelector,
  zoomSelector,
  zoomForDraggableSelector,
  currentBotStageIdSelector,
} from './selector';
import { saveBinding, trySaveScenarioStructureToLocalStorage } from './utils';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const scenarioEditorDispatcher = () => {
  const outputConnectionPositionsAddOrUpdate = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (newPosition: IOutputConnectionPosition) => {
      const { set, snapshot } = callbackHelpers;

      const position = await snapshot.getPromise(outputConnectionPositionsState(newPosition.id));

      if (
        position &&
        position.id === newPosition.id &&
        position.positionX === newPosition.positionX &&
        position.positionY === newPosition.positionY
      ) {
        return;
      }

      set(outputConnectionPositionsState(newPosition.id), newPosition);
    }
  );

  const inputConnectionPositionsAddOrUpdate = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (newPosition: IInputConnectionPosition) => {
      const { set, snapshot } = callbackHelpers;

      const position = await snapshot.getPromise(inputConnectionPositionsState(newPosition.id));

      if (
        position &&
        position.id === newPosition.id &&
        position.positionX === newPosition.positionX &&
        position.positionY === newPosition.positionY &&
        position.altPositionX === newPosition.altPositionX &&
        position.altPositionY === newPosition.altPositionY
      ) {
        return;
      }

      set(inputConnectionPositionsState(newPosition.id), newPosition);
    }
  );

  const trySaveCurrentGroupPosition = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { set, snapshot } = callbackHelpers;

    const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);

    if (!scenarioStructure) return;

    const groupDraggingInfo = await snapshot.getPromise(groupDraggingState);
    const draggedGroup = groupDraggingInfo?.group;
    if (!draggedGroup) {
      return;
    }

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundGroup =
      newScenarioStructure.triggerGroup.id === draggedGroup.id
        ? newScenarioStructure.triggerGroup
        : newScenarioStructure.actionGroups.find((ag) => ag.id === draggedGroup.id);
    if (!foundGroup) return;

    const { positionX, positionY } = await snapshot.getPromise(groupPositionsSelector(draggedGroup.id));

    if (foundGroup.$designer?.positionX === positionX && foundGroup.$designer?.positionY === positionY) {
      return;
    }

    foundGroup.$designer = {
      id: foundGroup.$designer?.id || foundGroup.id,
      ...foundGroup.$designer,
      positionX,
      positionY,
    };
    set(currentScenarioStructureSelector, newScenarioStructure);
  });

  const trySaveNewBinding = useRecoilCallback((callbackHelpers: CallbackInterface) => async (dragSourceId: string) => {
    const { set, reset, snapshot } = callbackHelpers;

    const dragTarget = await snapshot.getPromise(dragTargetSelector);
    const groupPlaceholderPosition = await snapshot.getPromise(groupPlaceholderPositionSelector);
    const groupPlaceholderPossiblePosition = await snapshot.getPromise(groupPlaceholderPossiblePositionSelector);
    const scenarioStructure = cloneDeep(await snapshot.getPromise(currentScenarioStructureSelector));

    if (scenarioStructure) {
      const saveStructure = (structure: DefaultScenarioSchema) => {
        set(currentScenarioStructureSelector, structure);
      };
      const selectBinding = (binding: BindingSchema) => {
        set(selectedEntitySelector, binding);
      };

      if (dragTarget) {
        saveBinding(saveStructure, selectBinding, dragSourceId, dragTarget.id, scenarioStructure);
      } else if (groupPlaceholderPossiblePosition || groupPlaceholderPosition) {
        const positionX = groupPlaceholderPossiblePosition?.positionX || groupPlaceholderPosition?.positionX || 0;
        const positionY = groupPlaceholderPossiblePosition?.positionY || groupPlaceholderPosition?.positionY || 0;
        const newGroup = generateNewGroup(positionX, positionY);
        scenarioStructure.actionGroups.push(newGroup);
        saveBinding(saveStructure, () => {}, dragSourceId, newGroup.id, scenarioStructure);
        set(selectedEntitySelector, newGroup);
        set(groupsMenuIsVisibleSelector(newGroup.id), true);
      }
    }

    reset(dragSourceSelector);
    reset(dragTargetSelector);
    reset(groupPlaceholderPositionSelector);
    reset(groupPlaceholderPossiblePositionSelector);
    reset(draggingBindingCurrentPositionSelector);
    reset(draggingBindingStartPositionSelector);
    reset(capturedBindingSelector);
  });

  const addNewBinding = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (sourceEntityId: string, targetEntityId: string) => {
      const { set, snapshot } = callbackHelpers;

      const scenarioStructure = cloneDeep(await snapshot.getPromise(currentScenarioStructureSelector));
      if (!scenarioStructure) return;

      const saveStructure = (structure: DefaultScenarioSchema) => {
        set(currentScenarioStructureSelector, structure);
      };
      const selectBinding = (binding: BindingSchema) => {
        set(selectedEntitySelector, binding);
      };
      saveBinding(saveStructure, selectBinding, sourceEntityId, targetEntityId, scenarioStructure);
    }
  );

  const startDragFromHandle = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (initDragInfo: IInitDragInfo) => {
      const { set, snapshot } = callbackHelpers;

      const outputPosition = await snapshot.getPromise(outputConnectionPositionsState(initDragInfo.entity.id));
      const position: IEntityPosition = {
        entityId: initDragInfo.entity.id,
        positionX: outputPosition?.positionX || 0,
        positionY: outputPosition?.positionY || 0,
        screenX: initDragInfo.screenX,
        screenY: initDragInfo.screenY,
      };
      set(dragSourceSelector, initDragInfo.entity);
      set(draggingBindingStartPositionSelector, position);
      set(dragTargetSelector, undefined);
      set(draggingBindingCurrentPositionSelector, position);
    }
  );

  const stopDragFromHandle = useRecoilCallback((callbackHelpers: CallbackInterface) => () => {
    const { reset } = callbackHelpers;
    reset(dragSourceSelector);
    reset(draggingBindingStartPositionSelector);
    reset(dragTargetSelector);
    reset(draggingBindingCurrentPositionSelector);
  });

  const deleteBinding = useRecoilCallback((callbackHelpers: CallbackInterface) => async (bindingToDeleteId: string) => {
    const { set, reset, snapshot } = callbackHelpers;

    const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);

    if (!scenarioStructure || !scenarioStructure.bindings.some((binding) => binding.id === bindingToDeleteId)) {
      return;
    }

    const newScenarioStructure = cloneDeep(scenarioStructure);
    deleteBindingWithReferences(newScenarioStructure, bindingToDeleteId);

    set(currentScenarioStructureSelector, newScenarioStructure);
    reset(selectedEntitySelector);
  });

  const setNewDraggingPosition = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (position: IPosition) => {
      const { set, snapshot } = callbackHelpers;
      const groupDraggingInfo = await snapshot.getPromise(groupDraggingState);
      const draggedGroup = groupDraggingInfo?.group;

      if (!draggedGroup) {
        return;
      }

      set(groupPositionsSelector(draggedGroup.id), position);
    }
  );

  const resetWorkingSpaceTransform = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { reset } = callbackHelpers;

    reset(workingSpaceTransformState);
    reset(zoomState);
  });

  const setWorkingSpaceTransform = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (newTransform: IWorkingSpaceTransform) => {
      const { set, snapshot } = callbackHelpers;

      const currentWorkingSpaceTransform = await snapshot.getPromise(workingSpaceTransformSelector);
      if (
        currentWorkingSpaceTransform.x === newTransform.x &&
        currentWorkingSpaceTransform.y === newTransform.y &&
        currentWorkingSpaceTransform.zoom === newTransform.zoom
      ) {
        return;
      }

      set(workingSpaceTransformState, newTransform);
      set(zoomState, newTransform.zoom);
    }
  );

  const throttledSetWorkingSpaceTransform = throttle(setWorkingSpaceTransform, 5);

  const resetSupportingData = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { reset, snapshot } = callbackHelpers;

    const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);
    if (scenarioStructure) {
      reset(groupPositionsSelector(scenarioStructure.triggerGroup.id));
      scenarioStructure.actionGroups.forEach((group) => {
        reset(groupPositionsSelector(group.id));
      });
    }

    reset(selectedEntitySelector);
    reset(dragSourceSelector);
    reset(dragTargetSelector);
    reset(scenarioStructureStackSelector);
    reset(groupPlaceholderPositionSelector);
    reset(groupPlaceholderPossiblePositionSelector);
    reset(workingSpaceTransformAnimationSelector);
    reset(currentScenarioValidationStatusSelector);
    reset(currentScenarioValidationResultSelector);
    await resetWorkingSpaceTransform();
  });

  const undo = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { set, reset, snapshot } = callbackHelpers;

    const canUndo = await snapshot.getPromise(scenarioStructureStackSelectorCanUndo);
    if (!canUndo) return;

    const stack = [...(await snapshot.getPromise(scenarioStructureStackState))];
    const stackIndex = await snapshot.getPromise(scenarioStructureStackIndexState);
    const scenarioStructure = stack[stackIndex - 1];

    set(scenarioStructureStackIndexState, stackIndex - 1);
    set(currentScenarioStructureState, scenarioStructure);

    const currentBotStageId = await snapshot.getPromise(currentBotStageIdSelector);
    trySaveScenarioStructureToLocalStorage(currentBotStageId, scenarioStructure);

    if (scenarioStructure) {
      reset(groupPositionsSelector(scenarioStructure.triggerGroup.id));
      scenarioStructure.actionGroups.forEach((group) => {
        reset(groupPositionsSelector(group.id));
      });
    }
  });

  const redo = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { set, reset, snapshot } = callbackHelpers;

    const canRedo = await snapshot.getPromise(scenarioStructureStackSelectorCanRedo);
    if (!canRedo) return;

    const stack = [...(await snapshot.getPromise(scenarioStructureStackState))];
    const stackIndex = await snapshot.getPromise(scenarioStructureStackIndexState);
    const scenarioStructure = stack[stackIndex + 1];

    set(scenarioStructureStackIndexState, stackIndex + 1);
    set(currentScenarioStructureState, scenarioStructure);

    const currentBotStageId = await snapshot.getPromise(currentBotStageIdSelector);
    trySaveScenarioStructureToLocalStorage(currentBotStageId, scenarioStructure);

    if (scenarioStructure) {
      reset(groupPositionsSelector(scenarioStructure.triggerGroup.id));
      scenarioStructure.actionGroups.forEach((group) => {
        reset(groupPositionsSelector(group.id));
      });
    }
  });

  // NOTE: фокус на новой группе
  // фокусировка происходит если новая группа выходит за область видимости
  const focusToNewGroup = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (newGroup: DefaultActionGroupSchema, showMenu = true) => {
      const { set, snapshot } = callbackHelpers;

      const groupPositionX = newGroup.$designer?.positionX;
      const groupPositionY = newGroup.$designer?.positionY;
      if (typeof groupPositionX !== 'number' || typeof groupPositionY !== 'number') return;

      set(workingSpaceTransformAnimationSelector, true);

      const containerRect = getScenarioEditorContainerRect();

      const workingSpaceTransform = await snapshot.getPromise(workingSpaceTransformSelector);
      const { width, height } = containerRect || { width: groupWidth, height: 0 };
      const transform = { ...workingSpaceTransform };
      const { zoom } = transform;

      const normalizedWidth = width / zoom;
      const normalizedHeight = height / zoom;

      const normalizedGroupPositionX = groupPositionX + workingSpaceTransform.x / zoom;
      const normalizedGroupPositionY = groupPositionY + workingSpaceTransform.y / zoom;

      const groupIsVisibleHorizontal =
        normalizedGroupPositionX - groupsMarginX >= 0 &&
        normalizedGroupPositionX + groupWidth + groupsMarginX < normalizedWidth;

      const groupIsVisibleVertical =
        normalizedGroupPositionY - groupsMarginY >= 0 &&
        normalizedGroupPositionY + normalizedHeight / 2 < normalizedHeight;

      if (groupIsVisibleHorizontal && groupIsVisibleVertical) {
        showMenu && set(groupsMenuIsVisibleSelector(newGroup.id), true);
        set(workingSpaceTransformAnimationSelector, false);
        return;
      }

      if (!groupIsVisibleHorizontal) {
        transform.x = width - (groupPositionX + groupWidth + groupsMarginX) * zoom;
      }
      if (!groupIsVisibleVertical) {
        transform.y = 0;
      }

      await setWorkingSpaceTransform(transform);

      // NOTE: чтобы успела сработать анимация из класса .scenario-editor-working-space_animated
      setTimeout(() => {
        showMenu && set(groupsMenuIsVisibleSelector(newGroup.id), true);
        set(workingSpaceTransformAnimationSelector, false);
      }, ANIMATION_TIMEOUT);
    }
  );

  const addNewGroup = useRecoilCallback((callbackHelpers: CallbackInterface) => async (sourceEntityId?: string) => {
    const { set, snapshot } = callbackHelpers;

    const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);
    if (!scenarioStructure) return;

    // NOTE: вычисляем расположение для новой группы
    const { positionX: groupPositionX, positionY: groupPositionY } = await snapshot.getPromise(
      positionForNewGroupSelector
    );

    // NOTE: добавляем группу
    const newGroup = generateNewGroup(groupPositionX, groupPositionY);
    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.actionGroups.push(newGroup);

    // NOTE: добавляем стрелку при необходимости
    if (sourceEntityId) {
      const saveStructure = (structure: DefaultScenarioSchema) => {
        set(currentScenarioStructureSelector, structure);
      };
      saveBinding(saveStructure, () => {}, sourceEntityId, newGroup.id, newScenarioStructure);
    } else {
      set(currentScenarioStructureSelector, newScenarioStructure);
    }

    set(selectedEntitySelector, newGroup);

    await focusToNewGroup(newGroup);
  });

  const addNewEndingGroup = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (sourceEntityId: string) => {
      const { set, snapshot } = callbackHelpers;

      const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);
      if (!scenarioStructure) return;

      // NOTE: вычисляем расположение для новой группы
      const { positionX: groupPositionX, positionY: groupPositionY } = await snapshot.getPromise(
        positionForNewGroupSelector
      );

      // NOTE: добавляем группу
      const endScenario: EndScenarioOutputSchema = {
        id: generateId('ACT'),
        $kind: SchemaKind.EndScenarioOutput,
        messages: [],
      };
      const newGroup = generateNewGroup(groupPositionX, groupPositionY, 'Завершение сценария', [endScenario]);
      const newScenarioStructure = cloneDeep(scenarioStructure);
      newScenarioStructure.actionGroups.push(newGroup);

      // NOTE: добавляем стрелку
      const saveStructure = (structure: DefaultScenarioSchema) => {
        set(currentScenarioStructureSelector, structure);
      };
      saveBinding(saveStructure, () => {}, sourceEntityId, newGroup.id, newScenarioStructure);

      set(selectedEntitySelector, newGroup);

      await focusToNewGroup(newGroup, false);
    }
  );

  // NOTE: фокус на группе
  // выбранная группа всегда смещается в центр экрана
  const focusToGroup = useRecoilCallback((callbackHelpers: CallbackInterface) => async (groupId: string) => {
    const { set, snapshot } = callbackHelpers;

    const scenarioStructure = await snapshot.getPromise(currentScenarioStructureSelector);
    if (!scenarioStructure) return;

    const containerRect = getScenarioEditorContainerRect();
    if (!containerRect) return;

    const group = tryGetElementById(scenarioStructure, groupId);
    if (!group) return;

    const groupRect = getElementRect(groupId);
    if (!groupRect) return;

    set(workingSpaceTransformAnimationSelector, true);

    const groupPosition = await snapshot.getPromise(groupPositionsSelector(group.id));
    const workingSpaceTransform = await snapshot.getPromise(workingSpaceTransformSelector);

    const { width, height } = containerRect;
    const transform = { ...workingSpaceTransform };
    const { zoom } = transform;

    const offsetX = (groupWidth / 2) * zoom;
    const offsetY = Math.min(height / 2 - groupsMarginY, groupRect.height / 2);
    transform.x = -groupPosition.positionX * zoom + width / 2 - offsetX;
    transform.y = -groupPosition.positionY * zoom + height / 2 - offsetY;

    await setWorkingSpaceTransform(transform);

    set(selectedEntitySelector, group);

    // NOTE: чтобы успела сработать анимация из класса .scenario-editor-working-space_animated
    setTimeout(() => set(workingSpaceTransformAnimationSelector, false), ANIMATION_TIMEOUT);
  });

  const focusToElement = useRecoilCallback(
    (callbackHelpers: CallbackInterface) => async (entityId: string, fieldPath: string) => {
      const { set, snapshot } = callbackHelpers;

      const elementId = getElementId(entityId, fieldPath);
      const elementRect = getElementRect(elementId);
      if (!elementRect) {
        return;
      }

      const containerRect = getScenarioEditorContainerRect();
      if (!containerRect) {
        return;
      }

      set(workingSpaceTransformAnimationSelector, true);

      const workingSpaceTransform = await snapshot.getPromise(workingSpaceTransformSelector);
      const transform = { ...workingSpaceTransform };

      transform.x += containerRect.x + containerRect.width / 2.0 - (elementRect.x + elementRect.width / 2.0);
      transform.y += containerRect.y + containerRect.height / 2.0 - (elementRect.y + elementRect.height / 2.0);

      await setWorkingSpaceTransform(transform);

      // NOTE: чтобы успела сработать анимация из класса .scenario-editor-working-space_animated
      setTimeout(() => {
        set(workingSpaceTransformAnimationSelector, false);
      }, ANIMATION_TIMEOUT);
    }
  );

  const updateZoomForDraggable = useRecoilCallback((callbackHelpers: CallbackInterface) => async () => {
    const { set, snapshot } = callbackHelpers;
    const zoom = await snapshot.getPromise(zoomSelector);
    set(zoomForDraggableSelector, zoom);
  });

  return {
    outputConnectionPositionsAddOrUpdate,
    inputConnectionPositionsAddOrUpdate,
    trySaveCurrentGroupPosition,
    trySaveNewBinding,
    addNewBinding,
    startDragFromHandle,
    stopDragFromHandle,
    deleteBinding,
    setNewDraggingPosition,
    resetSupportingData,
    undo,
    redo,
    resetWorkingSpaceTransform,
    setWorkingSpaceTransform,
    throttledSetWorkingSpaceTransform,
    addNewGroup,
    addNewEndingGroup,
    focusToGroup,
    focusToElement,
    updateZoomForDraggable,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createDispatcher = () => {
  return {
    ...scenarioEditorDispatcher(),
  };
};

export default createDispatcher;
export type Dispatcher = ReturnType<typeof createDispatcher>;
