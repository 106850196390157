import React, { useEffect, useState } from 'react';
import { Button, Modal, Table, Row, TablePaginationConfig } from 'antd';
import { Link } from 'react-router-dom';
import { UserAddOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';

import { GroupModel, PersonModel } from '../../../api';
import { personApi } from '../../apis';
import { AlertTypes, DEFAULT_PAGE_SIZE } from '../../constants';
import { alertsSelectorAdd } from '../../recoil/alerts';

export interface IAddPersonToGroupButtonProps {
  group: GroupModel;
}

const AddPersonToGroupButton: React.FC<IAddPersonToGroupButtonProps> = ({ group }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [modalVisible, setModalVisible] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [personsLoading, setPersonsLoading] = useState(false);
  const [listPersons, setListPersons] = useState([] as PersonModel[]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: 0,
  });

  const loadPersonsAsync = async () => {
    setPersonsLoading(true);
    try {
      const response = await personApi.searchPersons(
        undefined,
        undefined,
        (pagination.current ?? 1) - 1,
        pagination.pageSize
      );
      setListPersons(response.data.items || []);
      setPagination({ ...pagination, total: response.data.totalItemCount || 0 });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке списка сотрудников',
        error: e,
      });
    }
    setPersonsLoading(false);
  };
  const loadPersons = () => {
    if (!modalVisible) return;
    loadPersonsAsync();
  };
  useEffect(loadPersons, [pagination.current, pagination.pageSize]);

  const onOpenModalBtnClick = () => {
    if (!group) return;

    setModalVisible(true);
    loadPersonsAsync();
  };

  const onModalCancelBtnClick = () => {
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  const addPersonToGroupAsync = async (person: PersonModel) => {
    setBtnDisabled(true);
    try {
      const newGroupIds = person.groupIds?.map((g) => g);
      newGroupIds?.push(group.id);
      group.persons?.push(person);
      await personApi.updatePerson(person.id, {
        ...person,
        groupIds: newGroupIds,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при добавлении сотрудника в группу',
        error: e,
      });
    }
    setBtnDisabled(false);
  };

  const onAddBtnClick = (person: PersonModel) => {
    addPersonToGroupAsync(person);
  };

  const onTableChange = (tablePagination: TablePaginationConfig) => setPagination(tablePagination);

  const getAddBtnDisabled = (person: PersonModel) => {
    return !person || btnDisabled || group.persons?.some((p) => p.id === person.id);
  };

  const columns = [
    {
      title: 'ФИО',
      dataIndex: 'fio',
      render: (_text: unknown, person: PersonModel) => (
        <Link
          to={{
            pathname: `/persons/${person.id}`,
          }}
        >
          {person.fullName}
        </Link>
      ),
    },
    {
      render: (_text: unknown, person: PersonModel) => (
        <Row align="middle" justify="end" style={{ width: '100%' }}>
          <Button
            disabled={getAddBtnDisabled(person)}
            icon={<UserAddOutlined />}
            type="primary"
            onClick={() => onAddBtnClick(person)}
          >
            Добавить
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <div>
      <Button icon={<UserAddOutlined />} type="primary" onClick={onOpenModalBtnClick}>
        Добавить сотрудника
      </Button>
      <Modal
        destroyOnClose
        footer={[
          <Row key="buttons" align="middle" justify="end" style={{ width: '100%' }}>
            <Button key="back" onClick={onModalCancelBtnClick}>
              Отмена
            </Button>
          </Row>,
        ]}
        title="Добавить сотрудника"
        visible={modalVisible}
        onCancel={onModalCancel}
      >
        <Table
          columns={columns}
          dataSource={listPersons.map((item, i) => ({ key: i, ...item }))}
          loading={personsLoading}
          pagination={pagination}
          onChange={onTableChange}
        />
      </Modal>
    </div>
  );
};

export default AddPersonToGroupButton;
