import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  useState,
} from 'react';
import { Input } from 'antd';
import { Key } from 'ts-key-enum';

import './index.less';

import SbIcon from '../SbIcon';

interface SbTagProps {
  text: ReactNode;
  color: string;
  hoverable?: boolean;
  sbSize?: 'large' | 'medium' | 'small' | 'small-uncut' | 'x-small';
  onEdit?: (text: string) => void;
  onDelete?: MouseEventHandler<HTMLDivElement>;
}

const SbTag: React.FC<SbTagProps> = ({ text, color, hoverable = false, sbSize = 'medium', onEdit, onDelete }) => {
  const textIsString = typeof text === 'string';
  const [tagIsEditing, setTagIsEditing] = useState(false);
  const [tagText, setTagText] = useState(typeof text === 'string' ? text : '');
  const classes = ['sb-tag', `sb-tag_${sbSize}`];
  if (hoverable) {
    classes.push('sb-tag_hoverable');
  }
  if (tagIsEditing) {
    classes.push('sb-tag_editing');
  }

  const onTitleInputBlur = () => {
    if (onEdit) {
      onEdit(tagText);
    }
    setTagIsEditing(false);
  };

  const onTitleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => setTagText(e.target.value);

  const onTitleInputFocus: FocusEventHandler<HTMLInputElement> = (e) => e.target.select();

  const onTitleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === Key.Enter) {
      onTitleInputBlur();
    } else if (e.key === Key.Escape) {
      setTagText(typeof text === 'string' ? text : '');
      setTagIsEditing(false);
    }
  };

  const onEditValue = () => setTagIsEditing(true);

  return (
    <div className={classes.join(' ')} style={{ backgroundColor: color }}>
      {tagIsEditing && textIsString ? (
        <Input
          autoFocus
          value={tagText}
          onBlur={onTitleInputBlur}
          onChange={onTitleInputChange}
          onClick={(e) => e.stopPropagation()}
          onFocus={onTitleInputFocus}
          onKeyDown={onTitleInputKeyDown}
        />
      ) : (
        <span className={`sb-tag_${sbSize}__text`}>{text}</span>
      )}
      {onEdit && textIsString && <SbIcon iconName="edit" size={16} onClick={onEditValue} />}
      {onDelete && <SbIcon iconName="close" size={16} onClick={onDelete} />}
    </div>
  );
};

export default SbTag;
