import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import stc from 'string-to-color';
import { Table } from 'antd';

import './index.less';

import { EscalationsPerDayModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';
import { onlyUniqueScenarioEvent, scenarioEventCountModelsAreEqual } from '../../../utils/analytics';

const CHART_SETTINGS = {
  stackId: 'stackId',
  barColors: {
    escalations: '#8683EF',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as EscalationsPerDayModel;

  if (data.totalEscalationCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        Всего бесед: <b>{data.conversationCount}</b>
      </div>
      <div>
        Всего бесед с переводом на оператора: <b>{data.uniqueEscalationCount}</b>
      </div>
      <div>
        Всего переводов на оператора: <b>{data.totalEscalationCount}</b>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContentByScenario: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as EscalationsPerDayModel;

  if (data.totalEscalationCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        <div>Переводов на оператора:</div>
        <ul>
          {data.topScenarioEscalations.map((e, index) =>
            e.eventCount > 0 ? (
              <li key={index}>
                <i>{e.scenarioName}</i>:&nbsp;<b>{e.eventCount}</b>
              </li>
            ) : null
          )}
        </ul>
      </div>
    </div>
  );
};

interface ISbEscalationsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbEscalationsAnalytics: React.FC<ISbEscalationsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<EscalationsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await analyticsReportsApi.getEsalationsPerDayList(
        agentStageId,
        filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse),
        filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse)
      );
      setData(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по переводам на оператора',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  const uniqueScenarios = data.flatMap((dataItem) => dataItem.topScenarioEscalations).filter(onlyUniqueScenarioEvent);

  return (
    <div className="sb-escalations-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Переводы на оператора</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                {
                  title: 'Всего бесед',
                  dataIndex: 'conversationCount',
                  key: 'conversationCount',
                },
                {
                  title: 'Всего бесед с переводом на оператора',
                  dataIndex: 'uniqueEscalationCount',
                  key: 'uniqueEscalationCount',
                },
                {
                  title: 'Всего переводов на оператора',
                  dataIndex: 'totalEscalationCount',
                  key: 'totalEscalationCount',
                },
              ]}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis dataKey={(e: EscalationsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                <Bar
                  dataKey={(e: EscalationsPerDayModel) => e.totalEscalationCount}
                  fill={CHART_SETTINGS.barColors.escalations}
                  isAnimationActive={isAnimationActive}
                  name="Переводы на оператора"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>Переводы на оператора по сценариям</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode === AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата \\ Сценарий',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                ...uniqueScenarios.map((s) => {
                  return {
                    title: s.scenarioName,
                    key: 'count',
                    render: (_: unknown, record: EscalationsPerDayModel) => {
                      const item = record.topScenarioEscalations.find((e) => scenarioEventCountModelsAreEqual(e, s));
                      return item ? item.eventCount : 0;
                    },
                  };
                }),
              ]}
              dataSource={data}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={data}>
                <XAxis dataKey={(e: EscalationsPerDayModel) => e.date} tickFormatter={formatAnalyticsDateCompact} />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContentByScenario />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                {uniqueScenarios.map((scenarioEscalation) => {
                  const dataKey = (e: EscalationsPerDayModel) => {
                    const se = e.topScenarioEscalations.find((se) =>
                      scenarioEventCountModelsAreEqual(se, scenarioEscalation)
                    );
                    return se ? se.eventCount : 0;
                  };
                  return (
                    <Bar
                      key={`${scenarioEscalation.scenarioName}-${scenarioEscalation.scenarioStructureId}`}
                      dataKey={dataKey}
                      fill={stc(scenarioEscalation.scenarioName)}
                      isAnimationActive={isAnimationActive}
                      name={scenarioEscalation.scenarioName}
                    />
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbEscalationsAnalytics;
