import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import './index.less';

import IbAlert from '../common/IbAlert';
import { inboxAlertsSelector, inboxAlertsSelectorRemove } from '../../recoil';

const MAIN_CLASS_NAME = 'ib-alerts';

const ALERT_CLOSING_TIMEOUT = 5000;

const IbAlerts: React.FC = () => {
  const alerts = useRecoilValue(inboxAlertsSelector);
  const removeAlert = useSetRecoilState(inboxAlertsSelectorRemove);

  const onAlertClose = (alertId?: string) => () => alertId && removeAlert(alertId);

  const closeAlerts = () => {
    alerts.forEach((alert) => {
      setTimeout(() => {
        alert.id && removeAlert(alert.id);
      }, ALERT_CLOSING_TIMEOUT);
    });
  };

  useEffect(closeAlerts, [alerts]);

  return (
    <div className={MAIN_CLASS_NAME}>
      {alerts.map((alert) => (
        <IbAlert key={alert.id} icon={alert.icon} type={alert.type} onClose={onAlertClose(alert.id)}>
          {alert.content}
        </IbAlert>
      ))}
    </div>
  );
};

export default IbAlerts;
