import moment from 'moment/moment';

import { InboxDirection, InboxMessageModel, InboxMessageType } from '../../../api';
import { inboxMessageApi } from '../../apis';
import { ITaskData, ITaskQueue, TaskType } from '../tasks';
import { IMessageSendTaskContent } from '../tasks/messages';
import { hubConnections } from '../../utils/socketsUtil';
import { INBOX_MESSAGE_CREATED, INBOX_MESSAGE_UPDATED } from '../../constants';
import { EventType, IEventBus } from '../events';
import { IUserProfile } from '../../utils/oidcUtil';

import { EntityFilter, IEntityData, IEntityPaging, IEntityResponse, StorageDirection } from './types';
import { EntityStorage } from './storage';
import { TableName, IDbTable, PageSizes } from './table';
import { IDbCollection } from './collection';

const EVENT_TYPE_MAP = {
  'inbox.message.started': EventType.DomainInboxMessageStarted,
  'inbox.message.finished': EventType.DomainInboxMessageFinished,
};

interface IInboxMessageArgs {
  eventTypes: string[];
  inboxMessage: InboxMessageModel;
}

export class InboxMessageFilter extends EntityFilter<InboxMessageModel, InboxMessageFilter> {
  public readonly chatId?: string;

  public constructor(chatId?: string) {
    super();
    this.chatId = chatId;
  }

  public equals(filter: InboxMessageFilter): boolean {
    return this.chatId === filter.chatId;
  }

  public satisfies(entity: InboxMessageModel): boolean {
    return !this.chatId || entity.chat.id === this.chatId;
  }
}

export class InboxMessageStorage extends EntityStorage<InboxMessageModel, InboxMessageFilter> {
  private static s_instance?: InboxMessageStorage;

  public constructor(
    userProfile?: IUserProfile,
    eventBus?: IEventBus,
    taskQueue?: ITaskQueue,
    dbTable?: IDbTable<IEntityData<InboxMessageModel>, string>
  ) {
    super(
      TableName.StorageMessages,
      { direction: StorageDirection.Backward, pageSize: PageSizes.storageMessages },
      userProfile,
      eventBus,
      taskQueue,
      dbTable
    );
  }

  public static get instance(): InboxMessageStorage {
    if (!InboxMessageStorage.s_instance) {
      InboxMessageStorage.s_instance = new InboxMessageStorage();
    }

    return InboxMessageStorage.s_instance;
  }

  public getKey(entity: InboxMessageModel): string {
    return entity.activity.id;
  }

  public async getIndex(messageId: string, activityId: string, chatId: string): Promise<number> {
    const index = this.locateStateKey(new InboxMessageFilter(chatId), activityId);
    if (index >= 0) {
      return index;
    }

    const response = await inboxMessageApi.getInboxMessageIndex(messageId);
    return response.data;
  }

  protected async linkEvents(): Promise<void> {
    // NOTE: Не отписываемся от событий т.к. класс сейчас singleton (время жизни соответствует времени жизни приложения).
    const connection = await hubConnections.getBotManagerConnection();
    connection.on(INBOX_MESSAGE_CREATED, (args: IInboxMessageArgs) => this.onCreate(args).finally());
    connection.on(INBOX_MESSAGE_UPDATED, (args: IInboxMessageArgs) => this.onUpdate(args).finally());
  }

  protected loadMe(): Promise<InboxMessageModel> {
    throw new Error('Operation is not supported.');
  }

  protected async loadItem(key: string): Promise<InboxMessageModel> {
    const response = await inboxMessageApi.searchInboxMessages(undefined, undefined, key, undefined, 0, 1);
    if (!response.data.items?.length) {
      throw new Error('Message has not found.');
    }

    return response.data.items[0];
  }

  protected async loadPage(
    filter: InboxMessageFilter,
    paging: IEntityPaging
  ): Promise<IEntityResponse<InboxMessageModel>> {
    const response = await inboxMessageApi.searchInboxMessages(
      undefined,
      undefined,
      undefined,
      filter.chatId,
      paging.pageIndex,
      paging.pageSize
    );

    return response.data as IEntityResponse<InboxMessageModel>;
  }

  protected applyOrder(
    table: IDbTable<IEntityData<InboxMessageModel>, string>
  ): IDbCollection<IEntityData<InboxMessageModel>, string> {
    return table.orderBy(['order', 'entity.createdOn']);
  }

  protected buildCreationTask(entity: InboxMessageModel): ITaskData<IMessageSendTaskContent> {
    const utcNow = moment().toISOString();
    return {
      key: entity.activity.id,
      type: TaskType.MessageSend,
      timestamp: utcNow,
      content: {
        chatId: entity.chat.id,
        activityId: entity.activity.id,
        text: entity.content.text,
        type: entity.content.type,
        direction: entity.direction,
        timestamp: utcNow,
        mentions: entity.mentions,
        quotedMessagesIds: entity.quotedMessages.map((qm) => qm.id),
      },
    };
  }

  protected buildDeletionTask(): ITaskData<unknown> {
    throw new Error('Operation is not supported.');
  }

  protected canProcess(task: ITaskData<unknown>): boolean {
    return task.type == TaskType.MessageSend;
  }

  protected extractEntity(): InboxMessageModel | undefined {
    return undefined;
  }

  protected compareEntity(x: InboxMessageModel, y: InboxMessageModel): number {
    if (x.createdOn < y.createdOn) {
      return -1;
    }

    if (x.createdOn > y.createdOn) {
      return 1;
    }

    return 0;
  }

  protected withTenant(): boolean {
    return true;
  }

  private async onCreate(args: IInboxMessageArgs): Promise<void> {
    const entity = args.inboxMessage;
    const data = this.buildData(0, entity);
    // FIXME: Сделать более надежную проверку, когда будут участники сообщений.
    if (
      entity.type !== InboxMessageType.Content ||
      entity.direction === InboxDirection.Inbound ||
      entity.activity.id.startsWith('g_')
    ) {
      await this.createItem(data);
    } else {
      await this.updateItem(data);
    }
    this.emitDomainEvents(entity, args.eventTypes, EVENT_TYPE_MAP);
  }

  private async onUpdate(args: IInboxMessageArgs): Promise<void> {
    const entity = args.inboxMessage;
    const data = this.buildData(0, entity);
    await this.updateItem(data);
    this.emitDomainEvents(entity, args.eventTypes, EVENT_TYPE_MAP);
  }
}
