import React, { ChangeEventHandler, FocusEventHandler, MouseEventHandler, useEffect, useState } from 'react';
import { Input, Menu } from 'antd';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import './index.less';

import { SuggestionSchema } from '../../../../../api';
import { currentScenarioStructureSelector, selectedEntitySelector } from '../../../../recoil/scenarioStructure';
import ContextMenu from '../common/ContextMenu';
import { tryGetElementById } from '../../utils';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';

interface ISuggestionButtonProps {
  suggestion: SuggestionSchema;
  onDelete: () => void;
}

const SuggestionButton: React.FC<ISuggestionButtonProps> = ({ suggestion, onDelete }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const [suggestionCaption, setSuggestionCaption] = useState(suggestion.caption);
  const [isEditing, setIsEditing] = useState(false);

  const isSuggestionSelected = () => suggestion.id === selectedEntity?.id;

  const onButtonMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setSelectedEntity(suggestion);
  };

  const onButtonDoubleClick = () => setIsEditing(true);

  const onSuggestionCaptionChange = () => setSuggestionCaption(suggestion.caption);
  useEffect(onSuggestionCaptionChange, [suggestion.caption]);

  const onComponentCreate = () => {
    if (suggestion.id === selectedEntity?.id) {
      setIsEditing(true);
    }
  };
  useEffect(onComponentCreate, []);

  const menuIsVisibleChanged = (value: boolean) => setMenuIsVisible(value);

  const onSuggestionDeleteSelect = () => onDelete();

  const onSuggestionInputBlur = () => {
    setIsEditing(false);

    if (suggestionCaption === suggestion.value) return;

    if (!suggestionCaption) {
      setSuggestionCaption(suggestion.value);
      return;
    }

    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundSuggestion = tryGetElementById(newScenarioStructure, suggestion.id) as SuggestionSchema;
    foundSuggestion.caption = suggestionCaption;
    setScenarioStructure(newScenarioStructure);
  };

  const onSuggestionInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSuggestionCaption(e.target.value);
  };

  const onSuggestionInputFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.select();
  };

  const classes = ['suggestion'];
  isSuggestionSelected() && classes.push('suggestion_selected');
  isEditing && classes.push('suggestion_editing');

  return (
    <div
      aria-hidden="true"
      className={classes.join(' ')}
      onDoubleClick={onButtonDoubleClick}
      onMouseDown={onButtonMouseDown}
    >
      <div className="suggestion__content">
        {isEditing ? (
          <Input
            autoFocus
            value={suggestionCaption}
            onBlur={onSuggestionInputBlur}
            onChange={onSuggestionInputChange}
            onClick={(e) => e.stopPropagation()}
            onFocus={onSuggestionInputFocus}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ) : (
          <span>{suggestionCaption}</span>
        )}
      </div>
      <ContextMenu
        menuContent={
          <Menu>
            <Menu.Item key="delete" onClick={onSuggestionDeleteSelect}>
              <SbIcon iconName="close" />
            </Menu.Item>
          </Menu>
        }
        menuIsVisible={menuIsVisible}
        menuIsVisibleChanged={menuIsVisibleChanged}
      />
    </div>
  );
};

export default SuggestionButton;
