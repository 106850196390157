import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Col, Row, Select } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { SelectValue } from 'antd/lib/select';

import './index.less';

import {
  CreateElma365AppElementActionSchema,
  Elma365App,
  Elma365IntegrationSettingsSchema,
  Elma365SessionType,
} from '../../../../../../api';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbScroll from '../../../../../simple-bot/components/common/SbScroll';
import SbSelect from '../../../../../simple-bot/components/common/SbSelect';
import SbPanel from '../../../../../simple-bot/components/common/SbPanel';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import Elma365FormProperties, { FormPropertiesParent } from '../Elma365FormProperties';
import Elma365ResultProperty from '../Elma365ResultProperty';
import { isAllowedField, mapContextElementToFormProperty } from '../../../../../simple-bot/utils/elma365';
import { botSettingsSelector } from '../../../../../recoil/scenarioStructure';
import { instanceOfElma365IntegrationSettingsSchema } from '../../../utils';
import Elma365AppSelector from '../Elma365AppSelector';

enum AppTypes {
  EVENT = 'EVENT',
}
const EVENT_TYPE_APP_FIELDS = [
  { code: '__name', name: 'Название' },
  { code: '__startAt', name: 'Дата начала' },
  { code: '__endAt', name: 'Дата окончания' },
  { code: '__participants', name: 'Участники' },
  { code: '__place', name: 'Место' },
];

interface ICreateElma365AppElementActionSettingsModalProps {
  action: CreateElma365AppElementActionSchema;
  visible: boolean;
  onChange: (action: CreateElma365AppElementActionSchema) => void;
  onClose: () => void;
}

const Elma365SessionTypeSources = [
  { value: Elma365SessionType.None, label: 'Нет' },
  { value: Elma365SessionType.RegisterMessage, label: 'Фиксация обращений' },
  { value: Elma365SessionType.BindAccount, label: 'Учетная запись' },
];

const CreateElma365AppElementActionSettingsModal: React.FC<ICreateElma365AppElementActionSettingsModalProps> = ({
  action,
  visible,
  onChange,
  onClose,
}) => {
  const botSettings = useRecoilValue(botSettingsSelector);

  const [formProperties, setFormProperties] = useState(action.formProperties);
  const [variableId, setVariableId] = useState(action.variableId);
  const [appUpdatedAt, setAppUpdatedAt] = useState(action.appUpdatedAt);
  const [app, setApp] = useState<Elma365App>();

  const integrationSettings = botSettings?.integrations.find((i) =>
    instanceOfElma365IntegrationSettingsSchema(i)
  ) as Elma365IntegrationSettingsSchema;
  const integrationSettingsNotSet = !integrationSettings?.apiUrl || !integrationSettings?.xToken;

  const appHasBeenUpdated =
    app &&
    appUpdatedAt &&
    action.appCode === app.code &&
    action.appNamespace === app.namespace &&
    new Date(app.__updatedAt).toISOString() !== new Date(appUpdatedAt).toISOString();

  const initFormProperties = (app?: Elma365App) => {
    if (!app) {
      setFormProperties([]);
      return;
    }

    const allowedFields =
      app.type === AppTypes.EVENT // NOTE: для приложения с типом "Событие" не приходят настройки из api
        ? (app.fields || [])
            .filter((f) => EVENT_TYPE_APP_FIELDS.map((af) => af.code).includes(f.code))
            .map((f) => ({
              ...f,
              view: { ...f.view, name: EVENT_TYPE_APP_FIELDS.find((af) => af.code === f.code)?.name || '-' },
            }))
        : (app.fields || []).filter((f) =>
            isAllowedField(
              f,
              app.settings?.nameSettings?.titleGenerateMethod,
              app.settings?.nameSettings?.titleTemplate
            )
          );
    setFormProperties(allowedFields.map((f) => mapContextElementToFormProperty(f, formProperties)));
  };

  const onSaveSettings = () => {
    onChange({
      ...action,
      appTitle: app?.name,
      appCode: app?.code,
      appNamespace: app?.namespace,
      appUpdatedAt: app?.__updatedAt,
      formProperties,
      variableId,
    });
    onClose();
  };

  const onVisibleChange = () => {
    if (!visible) return;

    setFormProperties(action.formProperties);
    setVariableId(action.variableId);
    setAppUpdatedAt(action.appUpdatedAt);
  };
  useEffect(onVisibleChange, [visible]);

  const onAppSelectorChange = (app?: Elma365App) => {
    setApp(app);
    initFormProperties(app);
  };

  const onAppSelectorSelectedAppUpdated = (app?: Elma365App) => setApp(app);

  const onForceUpdateFormProperties = () => {
    setAppUpdatedAt(app?.__updatedAt);
    initFormProperties(app);
  };

  const onSessionTypeChange = (value: SelectValue) => {
    const newAction = cloneDeep(action);
    newAction.sessionType = value as Elma365SessionType;

    onChange(newAction);
  };

  return (
    <SbModal
      className="create-elma365-app-element-action-settings-modal"
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveSettings}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]}
      maskClosable={false}
      sbSize="small"
      title="Настройка создания элемента приложения"
      visible={visible}
      width={884}
      onCancel={onClose}
      onOk={onSaveSettings}
    >
      {integrationSettingsNotSet ? (
        <SbPanel sbType="help" scrollable={false}>
          Настройте параметры интеграции ELMA365 на странице бота.
        </SbPanel>
      ) : (
        <Row className="sb-modal__row-stretch" gutter={[12, 12]} wrap={false}>
          <Col className="sb-modal__col-main" span={15}>
            <SbScroll>
              <div>
                <Elma365AppSelector
                  appCode={action.appCode}
                  appNamespace={action.appNamespace}
                  onChange={onAppSelectorChange}
                  onSelectedAppUpdated={onAppSelectorSelectedAppUpdated}
                />
                {appHasBeenUpdated && (
                  <SbPanel
                    className="create-elma365-app-element-action-settings-modal__app-updated-info"
                    sbType="help"
                    scrollable={false}
                  >
                    <SbTypography>
                      <p>Приложение обновилось.</p>
                      <p>
                        Нажмите <button onClick={onForceUpdateFormProperties}>обновить</button> для загрузки параметров.
                      </p>
                    </SbTypography>
                  </SbPanel>
                )}
                {app && (
                  <>
                    <Elma365FormProperties
                      formProperties={formProperties}
                      formPropertiesParent={FormPropertiesParent.APPLICATION}
                      onChange={setFormProperties}
                    />
                    <Elma365ResultProperty action={action} variableId={variableId} onChange={setVariableId} />
                  </>
                )}
              </div>
              <div className="sb-elma365-app-selector" style={{ marginRight: '20px' }}>
                <h4>Тип привязки к сессии:</h4>
                <SbSelect
                  placeholder="Выберите тип привязки к сессии"
                  sbSize="big"
                  sbType="light"
                  value={action.sessionType}
                  onChange={onSessionTypeChange}
                >
                  {Elma365SessionTypeSources.map((t) => (
                    <Select.Option key={t.value} value={t.value}>
                      {t.label}
                    </Select.Option>
                  ))}
                </SbSelect>
              </div>
            </SbScroll>
          </Col>
          <Col span={9}>
            <SbPanel sbType="help">
              <SbTypography>
                <h3>Поля приложения</h3>
                <p>В каждое поле приложения можно записать значение из переменной бота.</p>
                <h3>Результат создания элемента приложения</h3>
                <p>В выбранную переменную бота будут записаны данные созданного элемента приложения.</p>
              </SbTypography>
            </SbPanel>
          </Col>
        </Row>
      )}
    </SbModal>
  );
};

export default CreateElma365AppElementActionSettingsModal;
