import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory, useParams } from 'react-router-dom';

import './index.less';

import IbPageTitle from '../../components/IbPageTitle';
import IbTabs from '../../components/common/IbTabs';
import IbTabPane from '../../components/common/IbTabPane';
import {
  operatorGroupListFilterSelector,
  operatorGroupListSelector,
  operatorsPageSearchSelector,
  participantItemsSelector,
  participantRangeSelector,
  participantStatsSelector,
} from '../../recoil';
import { SUBJECT_ROLES } from '../../../constants';
import { InboxParticipantFilter } from '../../storages/participants';
import { StatsStatus } from '../../storages';
import IbAccountWidgetWrapper from '../../wrappers/IbAccountWidgetWrapper';
import { IIbTagsSearchData } from '../../components/IbTagsSearch';

import { MAIN_CLASS_NAME } from './const';
import GroupsTab, { IGroupsTabRef } from './GroupsTab';
import OperatorsTab, { IOperatorsTabRef } from './OperatorsTab';

enum OperatorsPageTabKeys {
  OPERATORS = 'operators',
  GROUPS = 'groups',
}

const OperatorsPage: React.FC = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { tabKey = OperatorsPageTabKeys.OPERATORS } = useParams<{ tabKey: string }>();

  const operatorTabRef = useRef<IOperatorsTabRef | undefined>();
  const groupTabRef = useRef<IGroupsTabRef | undefined>();

  const [operatorsPageSearch, setOperatorsPageSearch] = useRecoilState(operatorsPageSearchSelector);
  const searchFilter = new InboxParticipantFilter(operatorsPageSearch, undefined, [SUBJECT_ROLES.operator]);
  // NOTE: в participantRangeSelector происходит начальная загрузка данных
  useRecoilValue(participantRangeSelector(searchFilter));
  const operatorListStats = useRecoilValue(participantStatsSelector(searchFilter));
  const operatorList = useRecoilValue(participantItemsSelector(searchFilter));
  const operatorListIsLoading =
    operatorListStats.status === StatsStatus.Unknown || (!operatorList.length && !!operatorListStats.itemCount);
  const operatorListIsEmpty = !operatorListIsLoading && !operatorList.length && !operatorsPageSearch;

  const [groupSearchText, setGroupSearchText] = useState('');
  const setGroupListFilter = useSetRecoilState(operatorGroupListFilterSelector);
  const groupList = useRecoilValue(operatorGroupListSelector);
  const groupListIsEmpty = !groupList.length;

  const operatorsTitle = t('Operator list');
  const groupsTitle = t('Operator groups');

  const onOperatorsPageTitleAdd = () => operatorTabRef.current?.showAddModal();

  const onGroupsPageTitleAdd = () => groupTabRef.current?.showAddModal();

  const resetOperatorFilter = () => setOperatorsPageSearch('');
  const resetGroupFilter = () => setGroupListFilter({});

  const onTabChange = (tabKey: string) => {
    if ((tabKey as OperatorsPageTabKeys) === OperatorsPageTabKeys.GROUPS) {
      resetGroupFilter();
    }

    push(`/inbox/operators/${tabKey}`);
  };

  const onOperatorsPageDebouncedSearch = (value: IIbTagsSearchData) => {
    setOperatorsPageSearch(value.searchValue ?? '');
  };

  const onGroupsPageDebouncedSearch = (value: IIbTagsSearchData) => {
    setGroupSearchText(value.searchValue ?? '');
  };

  const onInit = () => {
    resetGroupFilter();
    resetOperatorFilter();
  };
  useEffect(onInit, []);

  const renderPageTitle = () => {
    switch (tabKey) {
      case OperatorsPageTabKeys.OPERATORS:
        return (
          <IbPageTitle
            key="page-title-for-operators"
            hideTagsFiltering
            accountWidget={<IbAccountWidgetWrapper />}
            showSearchInput={!operatorListIsEmpty}
            title={operatorsTitle}
            onAdd={onOperatorsPageTitleAdd}
            onDebouncedSearch={onOperatorsPageDebouncedSearch}
          />
        );
      case OperatorsPageTabKeys.GROUPS:
        return (
          <IbPageTitle
            key="page-title-for-groups"
            hideTagsFiltering
            accountWidget={<IbAccountWidgetWrapper />}
            showSearchInput={!groupListIsEmpty}
            title={groupsTitle}
            onAdd={onGroupsPageTitleAdd}
            onDebouncedSearch={onGroupsPageDebouncedSearch}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      {renderPageTitle()}
      <IbTabs activeKey={tabKey} onChange={onTabChange}>
        <IbTabPane key={OperatorsPageTabKeys.OPERATORS} tab={operatorsTitle} tabKey={OperatorsPageTabKeys.OPERATORS}>
          <OperatorsTab
            operatorListIsEmpty={operatorListIsEmpty}
            operatorListIsLoading={operatorListIsLoading}
            tabRef={operatorTabRef}
          />
        </IbTabPane>
        <IbTabPane key={OperatorsPageTabKeys.GROUPS} tab={groupsTitle} tabKey={OperatorsPageTabKeys.GROUPS}>
          <GroupsTab searchText={groupSearchText} tabRef={groupTabRef} />
        </IbTabPane>
      </IbTabs>
    </div>
  );
};

export default OperatorsPage;
