import React, { useEffect, useState } from 'react';

import './index.less';

import { ScriptActionSchema } from '../../../../../../api';
import SbButton from '../../../../../simple-bot/components/common/SbButton';
import SbModal from '../../../../../simple-bot/components/common/SbModal';
import SbTypography from '../../../../../simple-bot/components/common/SbTypography';
import SbInput from '../../../../../simple-bot/components/common/SbInput';

interface IScriptActionSettingsModalProps {
  action: ScriptActionSchema;
  visible: boolean;
  onChange: (action: ScriptActionSchema) => void;
  onClose: () => void;
}

const ScriptActionSettingsModal: React.FC<IScriptActionSettingsModalProps> = ({
  action,
  visible,
  onChange,
  onClose,
}) => {
  const [executionTimeout, setExecutionTimeout] = useState(action.executionTimeout);
  const [retryCount, setRetryCount] = useState(action.retryCount);
  const [retryDelay, setRetryDelay] = useState(action.retryDelay);

  const onSaveSettings = () => {
    onClose();

    if (
      executionTimeout === action.executionTimeout &&
      retryCount === action.retryCount &&
      retryDelay === action.retryDelay
    ) {
      return;
    }

    onChange({
      ...action,
      executionTimeout,
      retryCount,
      retryDelay,
    });
  };

  const onActionPropChange = () => {
    if (visible) return;

    setExecutionTimeout(action.executionTimeout);
    setRetryCount(action.retryCount);
    setRetryDelay(action.retryDelay);
  };
  useEffect(onActionPropChange, [action]);

  const onExecutionTimeoutChange = (value?: string) => {
    setExecutionTimeout(value ? parseInt(value) || 0 : 0);
  };

  const onRetryCountChange = (value?: string) => {
    setRetryCount(value ? parseInt(value) || 0 : 0);
  };

  const onRetryDelayChange = (value?: string) => {
    setRetryDelay(value ? parseInt(value) || 0 : 0);
  };

  return (
    <SbModal
      className="script-action-settings-modal"
      footer={[
        <SbButton key="save" sbSize="medium" onClick={onSaveSettings}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onClose}>
          Закрыть
        </SbButton>,
      ]}
      sbSize="small"
      title="Настройки скрипта"
      visible={visible}
      onCancel={onClose}
      onOk={onSaveSettings}
    >
      <SbTypography>
        <h3>Максимальное время выполнения скрипта в секундах</h3>
        <SbInput sbSize="big" value={executionTimeout?.toString()} onChange={onExecutionTimeoutChange} />
        <h3>Количество повторных попыток выполнения скрипта</h3>
        <SbInput sbSize="big" value={retryCount?.toString()} onChange={onRetryCountChange} />
        <h3>Задержка времени в секундах между повторными попытками</h3>
        <SbInput sbSize="big" value={retryDelay?.toString()} onChange={onRetryDelayChange} />
      </SbTypography>
    </SbModal>
  );
};

export default ScriptActionSettingsModal;
