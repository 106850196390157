import React, { memo, useState } from 'react';
import { Menu, Typography } from 'antd';
import { useRecoilValue } from 'recoil';

import './index.less';

import {
  DefaultTriggerGroupSchema,
  IntentTriggerSchema,
  SystemIntentReferenceSchema,
  UserIntentReferenceSchema,
} from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import {
  currentScenarioStructureSelector,
  systemIntentGroupsSelector,
  systemIntentsSelector,
} from '../../../../../recoil/scenarioStructure';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { instanceOfSystemIntentReferenceSchema, instanceOfUserIntentReferenceSchema } from '../../../utils';
import IntentEditor from '../../IntentEditor';
import SbTag from '../../../../../simple-bot/components/common/SbTag';
import { INTENT_CATEGORY_TAG_COLOR } from '../../../constants';

const { Title } = Typography;

interface IIntentTriggerProps {
  index: number;
  trigger: IntentTriggerSchema;
  group: DefaultTriggerGroupSchema;
}

const IntentTrigger: React.FC<IIntentTriggerProps> = ({ index, trigger, group }) => {
  const systemIntents = useRecoilValue(systemIntentsSelector);
  const systemIntentGroups = useRecoilValue(systemIntentGroupsSelector);
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);

  const [intentEditorVisible, setIntentEditorVisible] = useState(false);

  const getSystemTriggerContent = (reference: SystemIntentReferenceSchema) => {
    const intent = systemIntents.find(
      (i) =>
        i.groupCode === reference.groupCode && i.code === reference.intentCode && i.currentSemVer === reference.semVer
    );
    if (!intent) return 'Интент не найден';

    const group = systemIntentGroups.find((g) => g.id === intent.groupEntryId);
    return (
      <div className="sb-intent-trigger-content">
        <p>
          <b>{intent.name}</b>
          <br />
          {intent.description}
        </p>
        <SbTag color={INTENT_CATEGORY_TAG_COLOR} text={group?.name || ''} />
      </div>
    );
  };

  const getUserTriggerContent = (reference: UserIntentReferenceSchema) => {
    const intent = scenarioStructure?.intents.find((i) => i.id === reference.intentId);
    return intent?.name || 'Интент не найден';
  };

  const getTriggerContent = () => {
    if (instanceOfSystemIntentReferenceSchema(trigger.intentReference)) {
      return getSystemTriggerContent(trigger.intentReference);
    } else if (instanceOfUserIntentReferenceSchema(trigger.intentReference)) {
      return getUserTriggerContent(trigger.intentReference);
    } else {
      throw new Error('Неизвестный тип интента.');
    }
  };

  const onEditIntentMenuSelect = () => {
    setIntentEditorVisible(true);
  };

  const onIntentEditorClose = () => {
    setIntentEditorVisible(false);
  };

  const renderMenuItems = () => {
    if (!instanceOfUserIntentReferenceSchema(trigger.intentReference)) {
      return [];
    }

    return [
      <Menu.Item key="edit" onClick={onEditIntentMenuSelect}>
        <SbIcon iconName="setting-two" />
      </Menu.Item>,
    ];
  };

  const renderIntentEditor = () => {
    if (!instanceOfUserIntentReferenceSchema(trigger.intentReference)) {
      return null;
    }

    const intent = scenarioStructure?.intents?.find((i) => i.id === trigger.intentReference.intentId);
    if (!intent) {
      return null;
    }

    return <IntentEditor intent={intent} visible={intentEditorVisible} onClose={onIntentEditorClose} />;
  };

  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="comments" size={20} />}
      index={index}
      menuItems={renderMenuItems()}
      title="Интент"
      trigger={trigger}
    >
      <Title>{getTriggerContent()}</Title>
      {renderIntentEditor()}
    </TriggerWrapper>
  );
};

export default memo(IntentTrigger);
