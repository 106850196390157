import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { ReadOutlined } from '@ant-design/icons';

import ConversationList from '../ConversationList';

export interface IConversationWidgetProps {
  caption: string;
  channelId?: string;
  agentStageId?: string | null;
  agentStageAccountId?: string;
  personId?: string;
}

const ConversationWidget: React.FC<IConversationWidgetProps> = ({
  caption,
  channelId,
  agentStageId,
  agentStageAccountId,
  personId,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onOpenModalBtnClick = () => {
    setModalVisible(true);
  };

  const onModalCancelBtnClick = () => {
    setModalVisible(false);
  };

  const onModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <Button icon={<ReadOutlined />} type="link" onClick={onOpenModalBtnClick}>
        {caption}
      </Button>
      <Modal
        destroyOnClose
        footer={[
          <Button key="back" onClick={onModalCancelBtnClick}>
            Закрыть
          </Button>,
        ]}
        style={{ minWidth: '800px' }}
        title="Список бесед"
        visible={modalVisible}
        width={'90%'}
        onCancel={onModalCancel}
      >
        <ConversationList
          agentStageAccountId={agentStageAccountId}
          agentStageId={agentStageId}
          channelId={channelId}
          personId={personId}
        />
      </Modal>
    </div>
  );
};

export default ConversationWidget;
