import React, { useCallback, MouseEvent, useRef, MouseEventHandler, useEffect } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import './index.less';

import {
  dispatcherState,
  currentScenarioStructureSelector,
  workingSpaceTransformSelector,
  groupDraggingState,
  draggingBindingCurrentPositionSelector,
  capturedBindingSelector,
  outputConnectionPositionsSelector,
  selectedEntitySelector,
  draggingBindingStartPositionSelector,
} from '../../recoil/scenarioStructure';
import { DefaultScenarioSchema } from '../../../api';
import { useKeyPress } from '../../utils/reactUtil';

import {
  calcContextualTransform,
  instanceOfDefaultActionGroupSchema,
  instanceOfDefaultTriggerGroupSchema,
  reorder,
  useEventListener,
} from './utils';
import WorkingSpace from './components/WorkingSpace';
import { IEntityPosition, IPosition, IZoomContext } from './types';
import { scenarioEditorContainerId } from './constants';
import Controls from './components/Controls';
import ScenarioValidation from './components/ScenarioValidation';

const ZOOM_VALUES = [0.1, 0.17, 0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1, 1.1, 1.25, 1.5, 1.75, 2, 2.5, 3, 4, 5];

const tryGetGroupById = (scenarioStructure: DefaultScenarioSchema, id: string) => {
  if (scenarioStructure.triggerGroup.id === id) {
    return scenarioStructure.triggerGroup;
  }
  return scenarioStructure.actionGroups.find((ag) => ag.id === id);
};

enum ZoomTypes {
  IN = 'IN',
  OUT = 'OUT',
}

const ScenarioEditor: React.FC = () => {
  const {
    trySaveCurrentGroupPosition,
    trySaveNewBinding,
    setNewDraggingPosition,
    deleteBinding,
    throttledSetWorkingSpaceTransform,
    addNewGroup,
  } = useRecoilValue(dispatcherState);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const selectedEntity = useRecoilValue(selectedEntitySelector);
  const resetSelectedEntity = useResetRecoilState(selectedEntitySelector);
  const workingSpaceTransform = useRecoilValue(workingSpaceTransformSelector);

  const [groupDraggingInfo, setGroupDraggingInfo] = useRecoilState(groupDraggingState);
  const [draggingBindingStartPosition, setDraggingBindingStartPosition] = useRecoilState(
    draggingBindingStartPositionSelector
  );
  const setDraggingBindingCurrentPosition = useSetRecoilState(draggingBindingCurrentPositionSelector);
  const capturedBinding = useRecoilValue(capturedBindingSelector);
  const capturedBindingPosition = useRecoilValue(
    outputConnectionPositionsSelector(capturedBinding?.binding.sourceEntityId || '')
  );

  const spacePressed = useKeyPress('Space');

  const ref = useRef<HTMLDivElement>(null);
  const areaRef = useRef<HTMLDivElement>(null);
  const paneRef = useRef<HTMLDivElement>(null);

  const dragStartPosition = groupDraggingInfo?.dragStartPosition;

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }
    if (!scenarioStructure) return;
    if (!selectedEntity) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const sourceGroup = tryGetGroupById(newScenarioStructure, source.droppableId);
    const destinationGroup = tryGetGroupById(newScenarioStructure, destination.droppableId);
    if (!sourceGroup || !destinationGroup) return;

    if (source.droppableId === destination.droppableId) {
      if (instanceOfDefaultActionGroupSchema(sourceGroup)) {
        sourceGroup.actions = reorder(sourceGroup.actions, source.index, destination.index);
      } else if (instanceOfDefaultTriggerGroupSchema(sourceGroup)) {
        sourceGroup.triggers = reorder(sourceGroup.triggers, source.index, destination.index);
      }
      setScenarioStructure(newScenarioStructure);
      return;
    }

    // NOTE: не даем перемещать элементы между разными типами групп
    if (sourceGroup.$kind !== destinationGroup.$kind) return;

    if (instanceOfDefaultActionGroupSchema(sourceGroup)) {
      sourceGroup.actions = sourceGroup.actions.filter((a) => a.id !== selectedEntity.id);
    } else if (instanceOfDefaultTriggerGroupSchema(sourceGroup)) {
      sourceGroup.triggers = sourceGroup.triggers.filter((t) => t.id !== selectedEntity.id);
    }

    if (instanceOfDefaultActionGroupSchema(destinationGroup)) {
      destinationGroup.actions.splice(destination.index, 0, selectedEntity);
    } else if (instanceOfDefaultTriggerGroupSchema(destinationGroup)) {
      destinationGroup.triggers.splice(destination.index, 0, selectedEntity);
    }

    setScenarioStructure(newScenarioStructure);
  };

  const mouseUpEventListener = async () => {
    if (dragStartPosition) {
      await trySaveCurrentGroupPosition();
      setGroupDraggingInfo(undefined);
    }
    if (draggingBindingStartPosition) {
      await trySaveNewBinding(draggingBindingStartPosition.entityId);
    } else if (capturedBinding) {
      await trySaveNewBinding(capturedBinding.binding.sourceEntityId);
    }
  };
  const mouseUpEventListenerCallback = useCallback(mouseUpEventListener, [
    dragStartPosition,
    draggingBindingStartPosition,
    capturedBinding,
  ]);

  const getCurrentAreaPosition = (startPosition: IPosition, currentScreenX: number, currentScreenY: number) => {
    const offsetX = (currentScreenX - (startPosition.screenX || 0)) / workingSpaceTransform.zoom;
    const offsetY = (currentScreenY - (startPosition.screenY || 0)) / workingSpaceTransform.zoom;
    return { positionX: startPosition.positionX + offsetX, positionY: startPosition.positionY + offsetY };
  };

  const onAreaMouseMove = async (e: MouseEvent) => {
    if (draggingBindingStartPosition) {
      const newPosition = getCurrentAreaPosition(draggingBindingStartPosition, e.screenX, e.screenY);
      setDraggingBindingCurrentPosition(newPosition);
    }
    if (dragStartPosition) {
      const newPosition = getCurrentAreaPosition(dragStartPosition, e.screenX, e.screenY);
      await setNewDraggingPosition(newPosition);
    }

    if (capturedBinding && capturedBindingPosition) {
      const startPosition: IEntityPosition = {
        entityId: capturedBinding.binding.sourceEntityId,
        positionX: capturedBindingPosition.positionX || 0,
        positionY: capturedBindingPosition.positionY || 0,
      };
      setDraggingBindingStartPosition(startPosition);

      const currentPosition = {
        positionX:
          startPosition.positionX +
          (capturedBinding.offsetX + e.clientX - capturedBinding.screenX) / workingSpaceTransform.zoom,
        positionY:
          startPosition.positionY +
          (capturedBinding.offsetY + e.clientY - capturedBinding.screenY) / workingSpaceTransform.zoom,
      };

      setDraggingBindingCurrentPosition(currentPosition);

      await deleteBinding(capturedBinding.binding.id);
    }
  };

  const onPaneMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (
      e.buttons !== 1 ||
      e.target !== paneRef.current ||
      selectedEntity ||
      capturedBinding ||
      draggingBindingStartPosition
    ) {
      return;
    }

    throttledSetWorkingSpaceTransform({
      ...workingSpaceTransform,
      x: workingSpaceTransform.x + e.movementX,
      y: workingSpaceTransform.y + e.movementY,
    });
  };

  const onPaneMouseDown = () => resetSelectedEntity();

  const handleClickOutside = (event: Event) => {
    const root = document.getElementById('root');
    if (!ref.current?.contains(event.target as Node) && root?.contains(event.target as Node)) {
      resetSelectedEntity();
    }
  };
  useEventListener('mousedown', handleClickOutside);

  const handleMouseUp = () => {
    mouseUpEventListenerCallback().finally();
  };
  useEventListener('mouseup', handleMouseUp);

  const zoom = async (zoomType: ZoomTypes, e?: WheelEvent) => {
    const index = ZOOM_VALUES.indexOf(workingSpaceTransform.zoom);
    let nextIndex = zoomType === ZoomTypes.OUT ? index - 1 : index + 1;
    nextIndex = Math.max(Math.min(nextIndex, ZOOM_VALUES.length - 1), 0);

    const zoomContext: IZoomContext = { e, container: ref.current };
    const newTransform = calcContextualTransform(workingSpaceTransform, ZOOM_VALUES[nextIndex], zoomContext);
    await throttledSetWorkingSpaceTransform(newTransform);
  };

  const scrollVertical = (delta: number) =>
    throttledSetWorkingSpaceTransform({ ...workingSpaceTransform, y: workingSpaceTransform.y - delta });

  const scrollHorizontal = (delta: number) =>
    throttledSetWorkingSpaceTransform({ ...workingSpaceTransform, x: workingSpaceTransform.x - delta });

  const onAreaWheel = async (e: WheelEvent) => {
    e.preventDefault();

    if (e.deltaX !== 0) {
      await scrollHorizontal(e.deltaX);
    } else if (e.ctrlKey && e.deltaY !== 0) {
      await zoom(e.deltaY > 0 ? ZoomTypes.OUT : ZoomTypes.IN, e);
    } else if (e.shiftKey) {
      await scrollHorizontal(e.deltaY);
    } else {
      await scrollVertical(e.deltaY);
    }
  };

  const updateAreaRefListeners = () => {
    areaRef.current?.removeEventListener('wheel', onAreaWheel);
    areaRef.current?.addEventListener('wheel', onAreaWheel, { passive: false });

    return () => {
      areaRef.current?.removeEventListener('wheel', onAreaWheel);
    };
  };
  useEffect(updateAreaRefListeners);

  const onControlsZoomOut = () => zoom(ZoomTypes.OUT);
  const onControlsZoomIn = () => zoom(ZoomTypes.IN);

  const paneClasses = ['scenario-editor__pane'];
  if (spacePressed) paneClasses.push('scenario-editor__pane_active');

  return (
    <div ref={ref} className="scenario-editor-container" id={scenarioEditorContainerId} role="none">
      <DragDropContext onDragEnd={onDragEnd}>
        <div ref={areaRef} className="scenario-editor" onMouseMove={onAreaMouseMove}>
          <div
            ref={paneRef}
            className={paneClasses.join(' ')}
            onMouseDown={onPaneMouseDown}
            onMouseMove={onPaneMouseMove}
          />
          <WorkingSpace />
          <div className="scenario-editor__validation-container">
            <ScenarioValidation />
          </div>
          <div className="scenario-editor__controls-container">
            <Controls onAdd={addNewGroup} onZoomIn={onControlsZoomIn} onZoomOut={onControlsZoomOut} />
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

export default ScenarioEditor;
