import { VirtualItem } from '@tanstack/react-virtual';

import { InboxMessageStorage } from '../storages/messages';
import { DeletionMode, IEntityItem, CreationMode, CreationState } from '../storages';
import { InboxAttachmentModel, InboxMessageModel } from '../../../api';
import { ITaskOperation } from '../tasks';
import { InboxAttachmentStorage } from '../storages/attachments';

export const sendMessage = async (entity: InboxMessageModel): Promise<IEntityItem<InboxMessageModel>> => {
  const storage = InboxMessageStorage.instance;
  const options = { mode: CreationMode.Async, state: CreationState.Initial };
  return await storage.sendItem(entity, options);
};

export const resendMessage = async (
  entity: InboxMessageModel,
  virtual: VirtualItem
): Promise<IEntityItem<InboxMessageModel>> => {
  const storage = InboxMessageStorage.instance;
  const options = { mode: CreationMode.Async, state: CreationState.Subsequent };
  return await storage.sendItem(entity, options, virtual);
};

export const deleteMessage = async (
  entity: InboxMessageModel,
  operation: ITaskOperation,
  virtual: VirtualItem
): Promise<IEntityItem<InboxMessageModel>> => {
  const storage = InboxMessageStorage.instance;
  const options = { mode: DeletionMode.None };
  return await storage.dropItem(entity, operation, options, virtual);
};

export const uploadAttachment = async (entity: InboxAttachmentModel): Promise<IEntityItem<InboxAttachmentModel>> => {
  const storage = InboxAttachmentStorage.instance;
  const options = { mode: CreationMode.Async, state: CreationState.Initial };
  return await storage.sendItem(entity, options);
};

export const reUploadAttachment = async (entity: InboxAttachmentModel): Promise<IEntityItem<InboxAttachmentModel>> => {
  const storage = InboxAttachmentStorage.instance;
  const options = { mode: CreationMode.Async, state: CreationState.Subsequent };
  return await storage.sendItem(entity, options);
};

export const deleteAttachment = async (
  entity: InboxAttachmentModel,
  operation: ITaskOperation
): Promise<IEntityItem<InboxAttachmentModel>> => {
  const storage = InboxAttachmentStorage.instance;
  const options = { mode: DeletionMode.Async };
  return await storage.dropItem(entity, operation, options);
};

export const getMessageIndex = async (messageId: string, activityId: string, chatId: string): Promise<number> => {
  const storage = InboxMessageStorage.instance;
  return await storage.getIndex(messageId, activityId, chatId);
};
