import React from 'react';

import './index.less';

import { DirectLineIcon, InstagramIcon, LiveChatIcon, TelegramIcon, ViberIcon, WhatsAppIcon } from '../../../../assets';
import { getDefaultIfUndefined } from '../../../../utils/typeUtil';

const MAIN_CLASS_NAME = 'ib-social';

export type IbSocialType = 'telegram' | 'instagram' | 'whatsapp' | 'viber' | 'directline' | 'livechat';

export interface IIbSocialProps {
  className?: string;
  social?: IbSocialType;
}

const IbSocial: React.FC<IIbSocialProps> = ({ className, social }) => {
  social = getDefaultIfUndefined(social, undefined);

  const classes = [MAIN_CLASS_NAME];
  className && classes.push(className);
  switch (social) {
    case 'telegram':
      return <TelegramIcon className={classes.join(' ')} />;
    case 'instagram':
      return <InstagramIcon className={classes.join(' ')} />;
    case 'whatsapp':
      return <WhatsAppIcon className={classes.join(' ')} />;
    case 'viber':
      return <ViberIcon className={classes.join(' ')} />;
    case 'directline':
      return <DirectLineIcon className={classes.join(' ')} />;
    case 'livechat':
      return <LiveChatIcon className={classes.join(' ')} />;
    default:
      return null;
  }
};

export default IbSocial;
