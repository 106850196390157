import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

import { ListBotTemplateModel } from '../../../../../../api';
import { botTemplateApi } from '../../../../../apis';
import { AlertTypes } from '../../../../../constants';
import { alertsSelectorAdd } from '../../../../../recoil/alerts';
import SbBotTemplateCard from '../../../../components/SbBotTemplateCard';
import SbScroll from '../../../../components/common/SbScroll';

import './index.less';

interface IBotTemplateSelectionStepProps {
  selectedBotTemplate?: ListBotTemplateModel;
  onSelectedBotTemplateChanged: (botTemplate: ListBotTemplateModel) => void;
}

const BotTemplateSelectionStep: React.FC<IBotTemplateSelectionStepProps> = ({
  selectedBotTemplate,
  onSelectedBotTemplateChanged,
}) => {
  const { t } = useTranslation();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(true);
  const [botTemplates, setBotTemplates] = useState<ListBotTemplateModel[]>([]);

  const loadBotTemplatesAsync = async () => {
    setLoading(true);
    try {
      const response = await botTemplateApi.getAllBotTemplates();
      setBotTemplates(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: t('Data downloading error'),
        error: e,
      });
    }
    setLoading(false);
  };

  const loadBotTemplates = () => {
    // noinspection JSIgnoredPromiseFromCall
    loadBotTemplatesAsync();
  };
  useEffect(loadBotTemplates, []);

  return (
    <div className="sb-create-bot-wizard__content__bot-template-selection-step">
      <SbScroll>
        <List<ListBotTemplateModel>
          className="sb-create-bot-wizard__content__list"
          dataSource={botTemplates}
          grid={{ gutter: 20, xxl: 4, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
          loading={loading}
          renderItem={(item) => {
            return (
              <List.Item key={item.code}>
                <SbBotTemplateCard
                  botTemplate={item}
                  isSelected={item.id === selectedBotTemplate?.id}
                  onCardClick={() => {
                    onSelectedBotTemplateChanged(item);
                  }}
                />
              </List.Item>
            );
          }}
        />
      </SbScroll>
    </div>
  );
};

export default BotTemplateSelectionStep;
