import { DefaultScenarioSchema, EntitySchema, VariableSchema } from '../../../api';
import {
  instanceOfActionGroupsContainer,
  instanceOfActionsContainer,
  instanceOfBindingsContainer,
  instanceOfButtonsContainer,
  instanceOfConditionsContainer,
  instanceOfCreateElma365AppElementActionSchema,
  instanceOfElseCaseContainer,
  instanceOfIntentsContainer,
  instanceOfMainCasesContainer,
  instanceOfMessagesContainer,
  instanceOfScenariosContainer,
  instanceOfStartElma365ProcessActionSchema,
  instanceOfSuggestionsContainer,
  instanceOfTriggerGroupContainer,
  instanceOfTriggersContainer,
  instanceOfValidatorsContainer,
  instanceOfVariableSchema,
  instanceOfVariablesContainer,
} from '../../components/ScenarioEditor/utils';

export interface IIndexedStructure {
  scenarioStructure?: DefaultScenarioSchema;
  scenarioStructures?: DefaultScenarioSchema[];
  entityMap: Map<string, EntitySchema>;
  entityList: EntitySchema[];
  variableMap: Map<string, VariableSchema>;
  variableList: VariableSchema[];
}

// FIXME: Выделить IElementContainer интерфейсы и заюзать type guards для них.
export class IndexedStructure implements IIndexedStructure {
  scenarioStructure?: DefaultScenarioSchema;
  scenarioStructures?: DefaultScenarioSchema[];
  entityMap: Map<string, EntitySchema>;
  entityList: EntitySchema[];
  variableMap: Map<string, VariableSchema>;
  variableList: VariableSchema[];

  constructor(scenarioStructure?: DefaultScenarioSchema, scenarioStructures?: DefaultScenarioSchema[]) {
    this.scenarioStructure = scenarioStructure;
    this.scenarioStructures = scenarioStructures;
    this.entityMap = new Map<string, EntitySchema>();
    this.entityList = [];
    this.variableMap = new Map<string, VariableSchema>();
    this.variableList = [];
  }

  indexEntitySchema(entity: EntitySchema): void {
    if (this.entityMap.has(entity.id)) {
      return;
    }

    this.entityMap.set(entity.id, entity);
    this.entityList.push(entity);

    if (instanceOfScenariosContainer(entity)) {
      entity.scenarios.forEach((scenario) => this.indexEntitySchema(scenario));
    }

    if (instanceOfTriggerGroupContainer(entity)) {
      this.indexEntitySchema(entity.triggerGroup);
    }

    if (instanceOfActionGroupsContainer(entity)) {
      entity.actionGroups.forEach((actionGroup) => this.indexEntitySchema(actionGroup));
    }

    if (instanceOfIntentsContainer(entity)) {
      entity.intents.forEach((intent) => this.indexEntitySchema(intent));
    }

    if (instanceOfBindingsContainer(entity)) {
      entity.bindings.forEach((binding) => this.indexEntitySchema(binding));
    }

    if (instanceOfVariablesContainer(entity)) {
      entity.variables.forEach((variable) => this.indexEntitySchema(variable));
    }

    if (instanceOfValidatorsContainer(entity)) {
      entity.validators.forEach((validator) => this.indexEntitySchema(validator));
    }

    if (instanceOfTriggersContainer(entity)) {
      entity.triggers.forEach((trigger) => this.indexEntitySchema(trigger));
    }

    if (instanceOfActionsContainer(entity)) {
      entity.actions.forEach((action) => this.indexEntitySchema(action));
    }

    if (instanceOfMessagesContainer(entity)) {
      entity.messages.forEach((message) => this.indexEntitySchema(message));
    }

    if (instanceOfButtonsContainer(entity)) {
      entity.buttons.forEach((button) => this.indexEntitySchema(button));
    }

    if (instanceOfSuggestionsContainer(entity)) {
      entity.suggestions.forEach((suggestion) => this.indexEntitySchema(suggestion));
    }

    if (instanceOfConditionsContainer(entity)) {
      entity.conditions.forEach((condition) => this.indexEntitySchema(condition));
    }

    if (instanceOfMainCasesContainer(entity)) {
      entity.mainCases.forEach((mainCase) => this.indexEntitySchema(mainCase));
    }

    if (instanceOfElseCaseContainer(entity)) {
      this.indexEntitySchema(entity.elseCase);
    }

    if (instanceOfVariableSchema(entity)) {
      this.variableMap.set(entity.id, entity);
      this.variableList.push(entity);
    }

    if (instanceOfStartElma365ProcessActionSchema(entity) || instanceOfCreateElma365AppElementActionSchema(entity)) {
      entity.formProperties.forEach((formProperty) => this.indexEntitySchema(formProperty));
    }
  }
}
