import React, { KeyboardEvent, memo, useEffect, useState } from 'react';
import { Menu, Typography } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useRecoilValue } from 'recoil';
import './index.less';

import { DefaultTriggerGroupSchema, MenuButtonTriggerSchema } from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import SbInput from '../../../../../simple-bot/components/common/SbInput';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../../utils';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
} from '../../../../../recoil/scenarioStructure';

import SettingModal from './SettingModal';

const { Text } = Typography;

interface IMenuButtonTriggerProps {
  index: number;
  trigger: MenuButtonTriggerSchema;
  group: DefaultTriggerGroupSchema;
  onTriggerValueChange: (newValue: MenuButtonTriggerSchema) => void;
}

const MenuButtonTrigger: React.FC<IMenuButtonTriggerProps> = ({ index, trigger, group, onTriggerValueChange }) => {
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [settingModalVisible, setSettingModalVisible] = useState(false);
  const [triggerValue, setTriggerValue] = useState(trigger.value);

  const resetState = () => {
    setTriggerValue(trigger.value);
  };

  useEffect(resetState, [trigger]);

  const onTriggerSetupSelect = () => {
    setSettingModalVisible(true);
  };

  const onSettingModalChange = (newMenuButton: MenuButtonTriggerSchema) => {
    setSettingModalVisible(false);

    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundButton = tryGetElementById(newScenarioStructure, trigger.id) as MenuButtonTriggerSchema;

    foundButton.position = newMenuButton.position;
    foundButton.category = newMenuButton.category;
    setScenarioStructure(newScenarioStructure);
  };

  const onSettingModalClose = () => {
    setSettingModalVisible(false);
  };

  const onValueBlur = () => {
    onTriggerValueChange({ ...trigger, value: triggerValue });
  };

  const triggerMenuItem = (
    <Menu.Item key="setup" onClick={onTriggerSetupSelect}>
      <SbIcon iconName="setting-two" />
    </Menu.Item>
  );

  const onTriggerValuePressEnter = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget?.blur();

  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="list-top" size={20} />}
      index={index}
      menuItems={[triggerMenuItem]}
      title="Кнопка меню"
      trigger={trigger}
    >
      <div className="menu-button-trigger">
        <Text>Этот сценарий запустится при выборе соответствующего пункта меню.</Text>
        <div className="field-label">
          <span>Название:</span>
        </div>
        <SbInput
          allowClear={false}
          id={getElementId(trigger.id, EntityFieldPath.Value)}
          isValid={!scenarioValidation?.hasIssue(trigger.id, EntityFieldPath.Value)}
          placeholder="Название пункта меню"
          value={triggerValue}
          onBlur={onValueBlur}
          onChange={setTriggerValue}
          onPressEnter={onTriggerValuePressEnter}
        />
        <SettingModal
          menuButtonTrigger={trigger}
          visible={settingModalVisible}
          onChange={onSettingModalChange}
          onModalClose={onSettingModalClose}
        />
      </div>
    </TriggerWrapper>
  );
};

export default memo(MenuButtonTrigger);
