import React, { useState } from 'react';
import { Upload, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';

import './index.less';

import { getBase64Content } from '../../../../utils/fileUtil';

const DEFAULT_HEIGHT = 80;

interface ISbUploadProps extends UploadProps<unknown> {
  onFileUpload: (file: RcFile, base64Content: string) => void;
}

const SbUpload: React.FC<ISbUploadProps> = ({ className, onFileUpload, beforeUpload, children, ...otherProps }) => {
  const [fileName, setFileName] = useState<string>();

  const beforeFileUpload = async (file: RcFile, fileList: RcFile[]) => {
    if (beforeUpload) {
      await beforeUpload(file, fileList);
    }
    const base64Content = await getBase64Content(file);
    onFileUpload(file, base64Content);
    setFileName(file.name);
  };

  const classes = ['sb-upload'];
  if (className) classes.push(className);

  return (
    <Upload.Dragger
      height={DEFAULT_HEIGHT}
      {...otherProps}
      beforeUpload={beforeFileUpload}
      className={classes.join(' ')}
      fileList={[]}
    >
      {children || (
        <div className="sb-upload__content">
          {fileName ? (
            fileName
          ) : (
            <div>
              Перетащите сюда файл или <br />
              <span>выберите с компьютера</span>
            </div>
          )}
        </div>
      )}
    </Upload.Dragger>
  );
};

export default SbUpload;
