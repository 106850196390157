import React, { MouseEventHandler } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import './index.less';

import { DefaultActionGroupSchema, ElseCaseActionSchema } from '../../../../../../../api';
import {
  currentScenarioValidationResultSelector,
  selectedEntitySelector,
} from '../../../../../../recoil/scenarioStructure';
import OutputConnection from '../../../common/OutputConnection';
import { EntityFieldPath, getElementId } from '../../../../utils';

interface IElseCaseActionProps {
  elseCase: ElseCaseActionSchema;
  group: DefaultActionGroupSchema;
}

const ElseCaseAction: React.FC<IElseCaseActionProps> = ({ elseCase, group }) => {
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const isCaseSelected = elseCase.id === selectedEntity?.id;

  const onCaseMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setSelectedEntity(elseCase);
  };

  const className = 'else-case-action';
  const classes = [className];
  isCaseSelected && classes.push(`${className}_selected`);

  return (
    <div aria-hidden="true" className={classes.join(' ')} id={getElementId(elseCase.id)} onMouseDown={onCaseMouseDown}>
      <div className={`${className}__content`}>Иначе</div>
      <OutputConnection
        entity={elseCase}
        groupId={group.id}
        id={getElementId(elseCase.id, EntityFieldPath.OutputBindingId)}
        isValid={!scenarioValidation?.hasIssue(elseCase.id, EntityFieldPath.OutputBindingId)}
      />
    </div>
  );
};

export default ElseCaseAction;
