import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Flags } from 'react-feature-flags';
import { useTranslation } from 'react-i18next';

import './index.less';

import { LogoElmaBotBlack } from '../../../assets';
import SbScroll from '../../components/common/SbScroll';
import SbTabPane from '../../components/common/SbTabPane';
import SbTabs from '../../components/common/SbTabs';
import SbUserInfo from '../../components/SbUserInfo';
import SbBrowserUnsupportedModal from '../../components/SbBrowserUnsupportedModal';
import { webchatModalSelector } from '../../../recoil/modals';
import SbWebChat from '../../components/SbWebChat';
import { ELMA365_HELP_URL } from '../../const';
import { FeatureFlagNames } from '../../../constants';
import { FeatureFlag } from '../../../../api';

const { Header, Content } = Layout;

enum TabPaneKeys {
  BOTS = 'BOTS',
  HELP = 'HELP',
  USERS = 'USERS',
  INBOX = 'INBOX',
}

const SimpleBotBaseLayout: React.FC = ({ children }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const webchatModalState = useRecoilValue(webchatModalSelector);
  const resetWebchatModalState = useResetRecoilState(webchatModalSelector);

  const [currentTabPane, setCurrentTabPane] = useState(TabPaneKeys.BOTS);

  const webchatComponent = webchatModalState && (
    <div className="sb-layout__webchat-container">
      <SbWebChat
        subTitle="Тестовый виджет"
        title={webchatModalState.title}
        webchatUrl={webchatModalState.webchatUrl}
        onClose={() => {
          resetWebchatModalState();
        }}
      />
    </div>
  );

  const initCurrentTabPane = () => {
    const { pathname } = window.location;

    if (pathname.startsWith('/users')) {
      setCurrentTabPane(TabPaneKeys.USERS);
      return;
    }

    if (pathname.startsWith('/inbox')) {
      setCurrentTabPane(TabPaneKeys.INBOX);
      return;
    }

    setCurrentTabPane(TabPaneKeys.BOTS);
  };
  useEffect(initCurrentTabPane, [window.location.pathname]);

  const onLogoClick = () => push('/simple-bots');

  const onTabChange = (activeKey: string) => {
    const tabPane = activeKey as TabPaneKeys;

    switch (tabPane) {
      case TabPaneKeys.BOTS:
        push('/simple-bots');
        return;
      case TabPaneKeys.USERS:
        push('/users');
        return;
      case TabPaneKeys.INBOX:
        push('/inbox');
        return;
      default:
    }
  };

  return (
    <Layout className="sb-layout">
      <Layout className="sb-layout-main">
        <Header className="sb-layout__header">
          <div className="sb-layout__header__container">
            <div className="sb-layout__header__logo" onClick={onLogoClick}>
              <LogoElmaBotBlack className="sb-layout__header__logo__elma-bot" />
            </div>
            <div className="sb-layout__header__menu">
              <Flags
                authorizedFlags={[
                  FeatureFlagNames.BOTS_ADMIN,
                  FeatureFlagNames.USERS_MANAGEMENT,
                  FeatureFlagNames.INBOX_DISPLAY,
                  FeatureFlagNames.INBOX_OPERATOR,
                ]}
                renderOn={(activeFlags: FeatureFlag[]) => {
                  const botsTabPaneIsAvailable = !!activeFlags.find((af) => af.name === FeatureFlagNames.BOTS_ADMIN);
                  const usersTabPaneIsAvailable = !!activeFlags.find(
                    (af) => af.name === FeatureFlagNames.USERS_MANAGEMENT
                  );
                  const inboxTabPaneIsAvailable =
                    !!activeFlags.find((af) => af.name === FeatureFlagNames.INBOX_DISPLAY) &&
                    !!activeFlags.find((af) => af.name === FeatureFlagNames.INBOX_OPERATOR);

                  return (
                    <SbTabs destroyInactiveTabPane activeKey={currentTabPane} onChange={onTabChange}>
                      {botsTabPaneIsAvailable ? <SbTabPane key={TabPaneKeys.BOTS} tab={t('Chat bots')} /> : null}
                      {usersTabPaneIsAvailable ? <SbTabPane key={TabPaneKeys.USERS} tab={t('Users')} /> : null}
                      {inboxTabPaneIsAvailable ? <SbTabPane key={TabPaneKeys.INBOX} tab="Инбокс" /> : null}
                      <SbTabPane
                        key="help"
                        tab={
                          <a href={ELMA365_HELP_URL} rel="noreferrer" target="_blank">
                            {t('Help')}
                          </a>
                        }
                      />
                    </SbTabs>
                  );
                }}
              />
            </div>
            <div className="sb-layout__header__profile">
              <SbUserInfo />
            </div>
          </div>
        </Header>
        <Layout className="sb-layout-scrollable">
          <Layout className="sb-layout-scrollable-content">
            <SbScroll>
              <Content className="sb-layout-content">{children}</Content>
            </SbScroll>
          </Layout>
        </Layout>
      </Layout>
      {webchatComponent}
      <SbBrowserUnsupportedModal />
    </Layout>
  );
};

export default SimpleBotBaseLayout;
