import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { UserOutlined, DownOutlined, LogoutOutlined } from '@ant-design/icons';

import { COMPLEX_BOT_DEFAULT_PATH, getProfile, logoutUser } from '../utils/oidcUtil';

const logoutClick = async () => {
  await logoutUser({ state: COMPLEX_BOT_DEFAULT_PATH });
};

const UserInfo: React.FC = () => {
  const profile = getProfile();

  const userMenu = (
    <Menu>
      <Menu.Item icon={<LogoutOutlined />} onClick={logoutClick}>
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={userMenu}>
      <Button>
        <UserOutlined /> {profile.userName} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default UserInfo;
