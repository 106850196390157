import React, { useState, ReactElement } from 'react';
import { Button, Popover, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export interface IDetailsButtonProps {
  content?: ReactElement;
}

const DetailsButton: React.FC<IDetailsButtonProps> = ({ content }) => {
  const [visible, setVisible] = useState(false);

  const onCloseButtonClick = () => {
    setVisible(false);
  };

  const onMoreInfoButtonClick = () => {
    setVisible(true);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const title = (
    <Space align="end" direction="vertical" style={{ width: '100%' }}>
      <Button icon={<CloseOutlined />} type="link" onClick={onCloseButtonClick} />
    </Space>
  );

  return (
    <Popover
      content={content}
      placement="topLeft"
      title={title}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button type="link" onClick={onMoreInfoButtonClick}>
        Подробнее
      </Button>
    </Popover>
  );
};

export default DetailsButton;
