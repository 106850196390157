import React, { useEffect, useState } from 'react';

import { getBrowserCompatibilityWarnings } from '../../../utils/browserUtil';
import SbButton from '../common/SbButton';
import SbModal from '../common/SbModal';
import { lruLocalStorage } from '../../../utils/localStorageUtil';

const SbBrowserUnsupportedModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState('');

  const checkBrowserCompatibility = () => {
    if (lruLocalStorage.getSkipBrowserCheck()) {
      return;
    }

    const warnings = getBrowserCompatibilityWarnings();

    if (!warnings.length) {
      return;
    }

    setText(warnings.join(' '));
    setIsVisible(true);
  };

  useEffect(checkBrowserCompatibility, []);

  const onClose = () => {
    setIsVisible(false);
    lruLocalStorage.setSkipBrowserCheck(true);
  };

  return (
    <SbModal
      footer={[
        <SbButton key="ok" sbSize="medium" sbType="primary" onClick={onClose}>
          Ок
        </SbButton>,
      ]}
      sbSize="small"
      title="Предупреждение"
      visible={isVisible}
      onCancel={onClose}
      onOk={onClose}
    >
      <div>{text}</div>
    </SbModal>
  );
};

export default SbBrowserUnsupportedModal;
