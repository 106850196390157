import { selector } from 'recoil';

import { IWebchatModalState } from '../../types';

import { webchatModalState } from './atom';

export const webchatModalSelector = selector<IWebchatModalState | undefined>({
  key: 'webchatModalSelector',
  get: ({ get }) => get(webchatModalState),
  set: ({ set }, value) => {
    set(webchatModalState, value);
  },
});
