import React, { CSSProperties } from 'react';

import './index.less';

interface ISbTypographyProps {
  className?: string;
  style?: CSSProperties;
}

const SbTypography: React.FC<ISbTypographyProps> = ({ className, style, children }) => {
  const classNames = ['sb-typography'];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')} style={style}>
      {children}
    </div>
  );
};

export default SbTypography;
