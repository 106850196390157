import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Form, Input, Space, Button, Typography } from 'antd';
import { v4 } from 'uuid';
import { useSetRecoilState } from 'recoil';

import { AgentStageAccountCreationRequest } from '../../../../api';
import { AlertTypes, Channels, FORM_FIELD_NAMES } from '../../../constants';
import { agentStageAccountApi } from '../../../apis';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Title } = Typography;

const ELMA365ChannelAdd: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useHistory();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Record<string, unknown>>();
  const [error, setError] = useState<string>();

  const onFinish = async () => {
    try {
      setLoading(true);
      const request: AgentStageAccountCreationRequest = {
        agentStageId: id,
        displayName: form.getFieldValue(FORM_FIELD_NAMES.displayName),
        authToken: v4(),
        channelId: Channels.ELMA365,
        properties: { [FORM_FIELD_NAMES.xToken]: form.getFieldValue(FORM_FIELD_NAMES.xToken) },
      };

      await agentStageAccountApi.createAgentStageAccount(request);

      addAlert({
        type: AlertTypes.SUCCESS,
        message: 'Добавлена линия',
        description: 'ELMA365',
      });
      goBack();
    } catch (e) {
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errors = (e as any).response?.data?.errors;
      const errorText = errors ? undefined : (e as Error).toString();
      setErrors(errors);
      setError(errorText);
    }
  };

  return (
    <div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item help={error} validateStatus={error ? 'error' : undefined}>
          <Title level={4}>Добавление линии ELMA365</Title>
        </Form.Item>
        <Form.Item label="Отображаемое наименование" name={FORM_FIELD_NAMES.displayName}>
          <Input placeholder="Отображаемое наименование канала" />
        </Form.Item>
        <Form.Item
          help={errors?.XToken ? 'Некорректный X-Token' : undefined}
          label="X-Token ELMA365"
          name={FORM_FIELD_NAMES.xToken}
          rules={[{ required: false, message: 'Введите токен' }]}
          validateStatus={errors?.XToken ? 'error' : undefined}
        >
          <Input placeholder="Токен авторизации ELMA365" />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button disabled={loading} htmlType="submit" type="primary">
              Сохранить
            </Button>
            <Button onClick={goBack}>Отмена</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ELMA365ChannelAdd;
