import React from 'react';
import moment from 'moment/moment';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { ActivityDirection, ActivityModel, InboxDirection } from '../../../../../../api';
import SbIcon from '../../../../components/common/SbIcon';
import { prepareForMarkdown } from '../../../../../utils/stringUtil';
import { markdownSchema } from '../../../../utils/dialogs';

import Attachments from './Attachments';
import SuggestedActions from './SuggestedActions';

const MAIN_CLASS_NAME = 'sb-dialogs-card__content__panel-container__messages';
const USER_SELECTOR_NAME = 'user';
const BOT_SELECTOR_NAME = 'bot';
const OPERATOR_SELECTOR_NAME = 'operator';

interface IMessageBubbleProps {
  activity: ActivityModel;
  direction: ActivityDirection | undefined;
  isSelected: boolean;
  inboxDirection: InboxDirection;
  onSelect: (activity: ActivityModel) => void;
  getMenuItemSelected: (activity: ActivityModel) => ActivityModel | undefined;
}

const MessageBubble: React.FC<IMessageBubbleProps> = ({
  activity,
  direction,
  isSelected,
  inboxDirection,
  onSelect,
  getMenuItemSelected,
}) => {
  // TODO: изменить механизм определения того, что собщение от оператора.
  // Сейчас подходящего свойства в activity нет, поэтому пока считается, что сообщения от оператора == внутренние сообщения
  const role =
    inboxDirection === InboxDirection.Internal
      ? OPERATOR_SELECTOR_NAME
      : direction == ActivityDirection.NUMBER_0
      ? USER_SELECTOR_NAME
      : BOT_SELECTOR_NAME;
  const messageContainerClass = `${MAIN_CLASS_NAME}__${role}-message-container`;

  const messageContainerClasses = [messageContainerClass];
  inboxDirection === InboxDirection.Internal && messageContainerClasses.push(`${messageContainerClass}_internal`);

  const onMessageClick = () => onSelect(activity);

  const menuItemSelected = getMenuItemSelected(activity);

  return (
    <>
      <div className={messageContainerClasses.join(' ')}>
        <div
          className={`${messageContainerClass}__message ${
            isSelected ? `${messageContainerClass}__message_selected` : ''
          }`}
          onClick={onMessageClick}
        >
          <div className={`${messageContainerClass}__message__text`}>
            <Markdown
              rehypePlugins={[rehypeRaw, rehypeSanitize]}
              remarkPlugins={[remarkBreaks]}
              remarkRehypeOptions={markdownSchema}
            >
              {prepareForMarkdown(activity.text) || '(пустое сообщение)'}
            </Markdown>
          </div>
          <span className={`${messageContainerClass}__message__time`}>
            {inboxDirection === InboxDirection.Internal && <SbIcon iconName="lock" size={16} />}
            {moment(activity.date).format('LT')}
          </span>
        </div>
      </div>
      {!!activity.attachments?.length && <Attachments attachments={activity.attachments} role={role} />}
      {!!activity.suggestedActions?.length && (
        <SuggestedActions items={activity.suggestedActions} selectedItem={menuItemSelected?.text} />
      )}
    </>
  );
};

export default MessageBubble;
