import { useEffect, useRef, useState } from 'react';

// NOTE: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useInterval = (callback: () => void, delay: number): void => {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const delay = (n: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, n));

const cancellablePromise = (promise: Promise<unknown>) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error })
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

const useCancellablePromises = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pendingPromises = useRef<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const appendPendingPromise = (promise: any) => (pendingPromises.current = [...pendingPromises.current, promise]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const removePendingPromise = (promise: any) =>
    (pendingPromises.current = pendingPromises.current.filter((p) => p !== promise));

  const clearPendingPromises = () => pendingPromises.current.map((p) => p.cancel());

  return {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };
};

export const useClickPreventionOnDoubleClick = (
  onClick: () => void,
  onDoubleClick: () => void
): [() => Promise<void>, () => void] => {
  const api = useCancellablePromises();

  const handleClick = () => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick();
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = () => {
    api.clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};

export const useKeyPress = (targetCode: string): boolean => {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  const downHandler = ({ code }: KeyboardEvent) => {
    if (code === targetCode) {
      setKeyPressed(true);
    }
  };

  const upHandler = ({ code }: KeyboardEvent) => {
    if (code === targetCode) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
  return keyPressed;
};
