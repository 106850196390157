import React, { memo } from 'react';
import { v4 } from 'uuid';

import { IGraphicsProps } from './types';

export const InstagramIcon = memo((props: IGraphicsProps) => {
  const gradient1Id = v4();
  const gradient2Id = v4();
  const gradient3Id = v4();
  const clipPathId = v4();
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M18.8571 0H5.14286C2.30254 0 0 2.30254 0 5.14286V18.8571C0 21.6975 2.30254 24 5.14286 24H18.8571C21.6975 24 24 21.6975 24 18.8571V5.14286C24 2.30254 21.6975 0 18.8571 0Z"
          fill={`url(#${gradient1Id})`}
        />
        <path
          d="M18.8571 0H5.14286C2.30254 0 0 2.30254 0 5.14286V18.8571C0 21.6975 2.30254 24 5.14286 24H18.8571C21.6975 24 24 21.6975 24 18.8571V5.14286C24 2.30254 21.6975 0 18.8571 0Z"
          fill={`url(#${gradient2Id})`}
        />
        <path
          d="M18.8571 0H5.14286C2.30254 0 0 2.30254 0 5.14286V18.8571C0 21.6975 2.30254 24 5.14286 24H18.8571C21.6975 24 24 21.6975 24 18.8571V5.14286C24 2.30254 21.6975 0 18.8571 0Z"
          fill={`url(#${gradient3Id})`}
        />
        <path
          d="M18.0011 7.28571C18.0011 7.99577 17.4255 8.57143 16.7154 8.57143C16.0053 8.57143 15.4297 7.99577 15.4297 7.28571C15.4297 6.57563 16.0053 6 16.7154 6C17.4255 6 18.0011 6.57563 18.0011 7.28571Z"
          fill="white"
        />
        <path
          clipRule="evenodd"
          d="M12.0006 16.2853C14.3675 16.2853 16.2863 14.3665 16.2863 11.9996C16.2863 9.63267 14.3675 7.71387 12.0006 7.71387C9.63364 7.71387 7.71484 9.63267 7.71484 11.9996C7.71484 14.3665 9.63364 16.2853 12.0006 16.2853ZM12.0006 14.571C13.4208 14.571 14.572 13.4198 14.572 11.9996C14.572 10.5794 13.4208 9.42815 12.0006 9.42815C10.5804 9.42815 9.42913 10.5794 9.42913 11.9996C9.42913 13.4198 10.5804 14.571 12.0006 14.571Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M3.42969 11.6573C3.42969 8.77703 3.42969 7.33685 3.99022 6.23676C4.48329 5.26907 5.27004 4.48231 6.23774 3.98925C7.33783 3.42871 8.778 3.42871 11.6583 3.42871H12.344C15.2242 3.42871 16.6644 3.42871 17.7645 3.98925C18.7322 4.48231 19.5189 5.26907 20.012 6.23676C20.5725 7.33685 20.5725 8.77703 20.5725 11.6573V12.343C20.5725 15.2233 20.5725 16.6634 20.012 17.7635C19.5189 18.7312 18.7322 19.518 17.7645 20.011C16.6644 20.5716 15.2242 20.5716 12.344 20.5716H11.6583C8.778 20.5716 7.33783 20.5716 6.23774 20.011C5.27004 19.518 4.48329 18.7312 3.99022 17.7635C3.42969 16.6634 3.42969 15.2233 3.42969 12.343V11.6573ZM11.6583 5.143H12.344C13.8124 5.143 14.8106 5.14433 15.5822 5.20737C16.3338 5.26877 16.7181 5.38007 16.9863 5.51669C17.6313 5.8454 18.1558 6.3699 18.4845 7.015C18.6212 7.28311 18.7325 7.66745 18.7939 8.41908C18.8569 9.19068 18.8583 10.1888 18.8583 11.6573V12.343C18.8583 13.8115 18.8569 14.8096 18.7939 15.5812C18.7325 16.3328 18.6212 16.7172 18.4845 16.9853C18.1558 17.6304 17.6313 18.1549 16.9863 18.4836C16.7181 18.6202 16.3338 18.7315 15.5822 18.7929C14.8106 18.8559 13.8124 18.8573 12.344 18.8573H11.6583C10.1898 18.8573 9.19166 18.8559 8.42006 18.7929C7.66843 18.7315 7.28409 18.6202 7.01597 18.4836C6.37088 18.1549 5.84638 17.6304 5.51766 16.9853C5.38105 16.7172 5.26975 16.3328 5.20834 15.5812C5.14531 14.8096 5.14397 13.8115 5.14397 12.343V11.6573C5.14397 10.1888 5.14531 9.19068 5.20834 8.41908C5.26975 7.66745 5.38105 7.28311 5.51766 7.015C5.84638 6.3699 6.37088 5.8454 7.01597 5.51669C7.28409 5.38007 7.66843 5.26877 8.42006 5.20737C9.19166 5.14433 10.1898 5.143 11.6583 5.143Z"
          fill="white"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(8.57143 18) rotate(-55.3758) scale(21.8739)"
          gradientUnits="userSpaceOnUse"
          id={gradient1Id}
          r="1"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(7.71429 24.8571) rotate(-65.1363) scale(19.3665)"
          gradientUnits="userSpaceOnUse"
          id={gradient2Id}
          r="1"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          cx="0"
          cy="0"
          gradientTransform="translate(-1.28571 0.857143) rotate(-8.1301) scale(33.3351 7.13002)"
          gradientUnits="userSpaceOnUse"
          id={gradient3Id}
          r="1"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
        <clipPath id={clipPathId}>
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
});
