import React, { MouseEvent as ReactMouseEvent, useRef, memo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { BindingSchema, DefaultActionGroupSchema } from '../../../../../api';
import {
  dispatcherState,
  capturedBindingSelector,
  outputConnectionPositionsSelector,
  inputConnectionPositionsSelector,
  selectedEntitySelector,
  currentScenarioStructureSelector,
} from '../../../../recoil/scenarioStructure';
import { IBindingContextMenuState, IEntityWithOutputBinding } from '../../types';
import { getBindingArrowId, getBindingElementAttributes, getOutputOffsetX, tryGetElementById } from '../../utils';

import Arrow from './Arrow';
import BindingContextMenu from './BindingContextMenu';

interface BindingProps {
  binding: BindingSchema;
}

const Binding: React.FC<BindingProps> = ({ binding }) => {
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const sourcePosition = useRecoilValue(outputConnectionPositionsSelector(binding.sourceEntityId));
  const targetPosition = useRecoilValue(inputConnectionPositionsSelector(binding.targetEntityId));
  const { deleteBinding } = useRecoilValue(dispatcherState);
  const setCapturedBinding = useSetRecoilState(capturedBindingSelector);
  const [selectedEntity, setSelectedEntity] = useRecoilState(selectedEntitySelector);

  const startPositionRef = useRef<SVGCircleElement>(null);

  const [contextMenuState, setContextMenuState] = useState<IBindingContextMenuState>();

  if (!scenarioStructure || !sourcePosition || !targetPosition) return null;

  const sourceEntity = tryGetElementById(scenarioStructure, sourcePosition.id);
  if (!sourceEntity) return null;

  const outputOffsetX = getOutputOffsetX(sourceEntity);
  const { svgTransform, pathDefinition, bindingStartPosition } = getBindingElementAttributes(
    sourcePosition,
    targetPosition,
    outputOffsetX
  );
  const bindingArrowId = getBindingArrowId(binding.id);

  const onBindingDoubleClick = async () => {
    await deleteBinding(binding.id);
  };

  const onBindingContextMenu = (e: ReactMouseEvent) => {
    e.preventDefault();
    setContextMenuState({ screenPosX: e.clientX, screenPosY: e.clientY });
  };

  const onBindingMouseDown = (e: ReactMouseEvent) => {
    setSelectedEntity(binding);

    if (!startPositionRef.current || e.nativeEvent.button) return;

    const startRect = startPositionRef.current.getBoundingClientRect();
    setCapturedBinding({
      binding,
      offsetX: e.clientX - startRect.x,
      offsetY: e.clientY - startRect.y,
      screenX: e.clientX,
      screenY: e.clientY,
    });
  };

  const isSelected = selectedEntity?.id === binding.id;
  const isSourceSelected = (selectedEntity as IEntityWithOutputBinding)?.outputBindingId === binding.id;
  const isTargetSelected = (selectedEntity as DefaultActionGroupSchema)?.inputBindingIds?.includes(binding.id);

  const classes = ['sb-binding-container'];
  if (isSelected || isSourceSelected || isTargetSelected) {
    classes.push('sb-binding-container_highlighted');
  }

  return (
    <>
      <svg className={classes.join(' ')} style={{ transform: svgTransform }}>
        <g className="sb-binding">
          <Arrow id={bindingArrowId} />
          <path className="sb-binding__visible" d={pathDefinition} markerEnd={`url(#${bindingArrowId})`} />
          <path
            className="sb-binding__subsidiary"
            d={pathDefinition}
            onContextMenu={onBindingContextMenu}
            onDoubleClick={onBindingDoubleClick}
            onMouseDown={onBindingMouseDown}
          />
          {/* NOTE: блок, с помощью которого определяются экранные координаты начала стрелки при её перетаскивании*/}
          <circle
            ref={startPositionRef}
            className="sb-binding__start-position"
            cx={bindingStartPosition.positionX}
            cy={bindingStartPosition.positionY}
            r={0}
          />
        </g>
      </svg>
      {contextMenuState && (
        <BindingContextMenu
          binding={binding}
          contextMenuState={contextMenuState}
          onClose={() => setContextMenuState(undefined)}
        />
      )}
    </>
  );
};

export default memo(Binding);
