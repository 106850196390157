import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Col, Row } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';

import './index.less';

import SbModal from '../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../simple-bot/components/common/SbButton';
import {
  currentBotStageIdSelector,
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  scenarioStructuresSelector,
} from '../../../../recoil/scenarioStructure';
import { DefaultTriggerGroupSchema, IntentContentSchema } from '../../../../../api';
import SbPanel from '../../../../simple-bot/components/common/SbPanel';
import SbTable from '../../../../simple-bot/components/common/SbTable';
import SbInput from '../../../../simple-bot/components/common/SbInput';
import SbTypography from '../../../../simple-bot/components/common/SbTypography';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import SbTextArea from '../../../../simple-bot/components/common/SbTextArea';
import { ValidationRuleCodes } from '../../constants';
import SbTooltip from '../../../../simple-bot/components/common/SbTooltip';
import { validateScenario } from '../../../../simple-bot/utils/validation';
import { IndexedStructure } from '../../../../simple-bot/utils/indexation';
import { AlertTypes } from '../../../../constants';
import { NEW_LINE_DIVIDER } from '../../../../constants/editors';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { NAME_FIELD_NAME } from '../../../../simple-bot/const';
import { generateIntentId, instanceOfIntentTriggerSchema, instanceOfUserIntentReferenceSchema } from '../../utils';

interface IIntentEditorProps {
  visible: boolean;
  intent: IntentContentSchema;
  onClose: () => void;
}

interface IUtteranceRecord {
  key: string;
  value: string;
}

const MODAL_WIDTH = 866;

const IntentEditor: React.FC<IIntentEditorProps> = ({ visible, intent, onClose }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const botStageId = useRecoilValue(currentBotStageIdSelector);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const scenarioStructures = useRecoilValue(scenarioStructuresSelector);

  const [localScenarioValidation, setLocalScenarioValidation] = useState(scenarioValidation);
  const [tableFocusRowKey, setTableFocusRowKey] = useState<string>();
  const [intentName, setIntentName] = useState('');
  const [utterances, setUtterances] = useState([] as IUtteranceRecord[]);
  const [editAsList, setEditAsList] = useState(true);

  const nameDuplicatesInScenario =
    localScenarioValidation?.getIssues(
      intent.id,
      NAME_FIELD_NAME,
      ValidationRuleCodes.IntentContentDuplicateInScenario
    ) || [];
  const nameDuplicatesInBot =
    localScenarioValidation?.getIssues(intent.id, NAME_FIELD_NAME, ValidationRuleCodes.IntentContentDuplicateInBot) ||
    [];
  const nameIssues = [...nameDuplicatesInScenario, ...nameDuplicatesInBot];

  const hasUtteranceIssues = utterances
    .map((_, index) => !!localScenarioValidation?.hasIssue(intent.id, `utterances[${index}]`))
    .some((value) => value);

  const saveIntent = () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const newIntentId = generateIntentId(intentName, scenarioStructures, intent.id);
    newScenarioStructure.intents = scenarioStructure.intents.map((i) =>
      i.id !== intent.id
        ? i
        : {
            ...i,
            id: newIntentId,
            name: intentName,
            utterances: utterances.map((u) => u.value),
          }
    );
    (newScenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers
      .filter(
        (t) =>
          instanceOfIntentTriggerSchema(t) &&
          instanceOfUserIntentReferenceSchema(t.intentReference) &&
          t.intentReference.intentId === intent.id
      )
      .forEach((t) => {
        t.intentReference.intentId = newIntentId;
      });

    setScenarioStructure(newScenarioStructure);
  };

  const validateStructure = async (name: string, utterances: IUtteranceRecord[]) => {
    if (!scenarioStructure || !botStageId) return;

    try {
      const newScenarioStructure = cloneDeep(scenarioStructure);
      newScenarioStructure.intents = scenarioStructure.intents.map((i) =>
        i.id !== intent.id ? i : { ...i, name, utterances: utterances.map((u) => u.value) }
      );

      const indexedStructure = new IndexedStructure(newScenarioStructure, scenarioStructures);
      indexedStructure.indexEntitySchema(newScenarioStructure);

      const validationResult = await validateScenario(indexedStructure, newScenarioStructure, botStageId);
      setLocalScenarioValidation(validationResult);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при выполнении проверки сценария',
        error: e,
      });
    }
  };

  const onModalOk = () => {
    saveIntent();
    onClose();
  };

  const onModalCancel = () => {
    onClose();
  };

  const onSaveButtonClick = () => {
    saveIntent();
    onClose();
  };

  const onCancelButtonClick = () => {
    onClose();
  };

  const onIntentNameChange = (value: string) => {
    setIntentName(value);
  };

  const onIntentNameBlur = () => {
    validateStructure(intentName, utterances).finally();
  };

  const onAddUtteranceButtonClick = () => {
    const key = nanoid(10);
    setTableFocusRowKey(key);
    const newUtterances = [{ key, value: '' }, ...utterances];
    setUtterances(newUtterances);
    validateStructure(intentName, newUtterances).finally();
  };

  const onRemoveUtteranceButtonClick = (utterance: IUtteranceRecord) => {
    const newUtterances = utterances.filter((u) => u.key !== utterance.key);
    setUtterances(newUtterances);
    validateStructure(intentName, newUtterances).finally();
  };

  const onSaveUtterance = (utterance: IUtteranceRecord) => {
    const newUtterance: IUtteranceRecord = { ...utterance, value: utterance.value || '' };
    const newUtterances = utterances.map((u) => (u.key === newUtterance.key ? newUtterance : u));
    setUtterances(newUtterances);
    validateStructure(intentName, newUtterances).finally();
  };

  const columns = [
    {
      dataIndex: 'value',
      key: 'value',
      editable: true,
      onSave: onSaveUtterance,
      render: (_: unknown, utterance: IUtteranceRecord, index: number) => {
        const duplicatesInScenario =
          localScenarioValidation?.getIssues(
            intent.id,
            `utterances[${index}]`,
            ValidationRuleCodes.IntentContentDuplicateInScenario
          ) || [];
        const duplicatesInBot =
          localScenarioValidation?.getIssues(
            intent.id,
            `utterances[${index}]`,
            ValidationRuleCodes.IntentContentDuplicateInBot
          ) || [];
        const issues = [...duplicatesInScenario, ...duplicatesInBot];
        return (
          <div className="sb-intent-editor__table-row">
            <span>{utterance.value}</span>
            <SbButton sbSize="medium" sbType="link" onClick={() => onRemoveUtteranceButtonClick(utterance)}>
              Удалить фразу
            </SbButton>
            {!!issues?.length && (
              <SbTooltip placement="right" title={issues[0].ruleResult.message}>
                <SbIcon iconName="attention" size={16} />
              </SbTooltip>
            )}
          </div>
        );
      },
    },
  ];

  const resetState = () => {
    if (!visible) {
      return;
    }

    setLocalScenarioValidation(scenarioValidation);
    setEditAsList(true);
    setIntentName(intent.name);
    setUtterances(
      intent.utterances.map((value, index) => {
        return { key: index.toString(), value };
      })
    );
  };
  useEffect(resetState, [visible, intent]);

  const onEditModeButtonClick = () => {
    setTableFocusRowKey(undefined);
    setEditAsList(!editAsList);
  };

  const onUtterancesChange = (value: string) => {
    const newUtterances = value.split(NEW_LINE_DIVIDER).map((u, i) => ({
      key: i.toString(),
      value: u,
    }));
    setUtterances(newUtterances);
    validateStructure(intentName, newUtterances).finally();
  };

  return (
    <SbModal
      footer={[
        <SbButton key="save" disabled={!intentName} sbSize="medium" sbType="primary" onClick={onSaveButtonClick}>
          Сохранить
        </SbButton>,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      maskClosable={false}
      sbSize="small"
      title="Редактирование интента"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
      onOk={onModalOk}
    >
      <Row className="sb-intent-editor sb-modal__row-stretch" gutter={[20, 20]} wrap={false}>
        <Col className="sb-modal__col-main" span={14}>
          <Row gutter={[0, 12]} wrap={false}>
            <Col span={24}>
              <SbTypography>
                <h3>
                  Название интента
                  {!!nameIssues?.length && (
                    <SbTooltip placement="right" title={nameIssues[0].ruleResult.message}>
                      <SbIcon iconName="attention" size={16} />
                    </SbTooltip>
                  )}
                </h3>
                <small>Придумайте понятное название для группы фраз, вызывающих сценарий</small>
                <SbInput
                  placeholder="Укажите название интента"
                  sbSize="big"
                  value={intentName}
                  onBlur={onIntentNameBlur}
                  onChange={onIntentNameChange}
                />
                <div className="sb-modal__subtitle">
                  <h3>Слова и фразы{hasUtteranceIssues && <SbIcon iconName="attention" size={16} />}</h3>
                  <div className="sb-modal__subtitle-actions">
                    {editAsList && (
                      <SbButton sbSize="medium" sbType="tertiary" onClick={onAddUtteranceButtonClick}>
                        <SbIcon iconName="plus" size={16} />
                        Добавить фразу
                      </SbButton>
                    )}
                    {editAsList ? (
                      <SbButton sbSize="medium" sbType="tertiary" onClick={onEditModeButtonClick}>
                        <SbIcon iconName="edit" size={16} />
                        Редактировать список
                      </SbButton>
                    ) : (
                      <SbButton sbSize="medium" sbType="tertiary" onClick={onEditModeButtonClick}>
                        <SbIcon iconName="align-text-both" size={16} />
                        Вернуться к списку
                      </SbButton>
                    )}
                  </div>
                </div>
                <small>Укажите фразы, которые будут запускать сценарий</small>
              </SbTypography>
            </Col>
          </Row>
          <Row className="sb-modal__row-stretch" gutter={[0, 0]} wrap={false}>
            <Col span={24}>
              {editAsList ? (
                <SbTable
                  editable
                  columns={columns}
                  dataSource={utterances}
                  emptyText={
                    <SbTypography>
                      <h4>Фразы отсутствуют</h4>
                      <small>
                        Для ввода фраз нажмите кнопку <b>+ Добавить фразу</b>
                      </small>
                    </SbTypography>
                  }
                  focusRowKey={tableFocusRowKey}
                  header={false}
                />
              ) : (
                <SbTextArea
                  autoFocus
                  value={utterances.map((u) => u.value).join(NEW_LINE_DIVIDER)}
                  onChange={onUtterancesChange}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <SbPanel sbType="help">
            <SbTypography>
              <h3>Как подбирать фразы</h3>
              <ul>
                <li>
                  Интент - это наборы фраз, которые в определенном контексте представляют собой одно и то же намерение
                  пользователя.
                </li>
                <li>
                  Представьте, как человек мог бы сформулировать свой вопрос. Не пишите одни и те же слова с разными
                  окончаниями, а также не меняйте порядок слов - с фразами работает искусственный интеллект и он
                  понимает, что это будет значить одно и то же.
                </li>
                <li>Например: “Привет” и “Приветы” - это для системы одно и то же.</li>
                <li>“Привет” и “Здравствуйте” - это разные слова.</li>
                <li>Интенты не должны пересекаться с другими сценариями, иначе возникнет конфликт.</li>
              </ul>
            </SbTypography>
          </SbPanel>
        </Col>
      </Row>
    </SbModal>
  );
};

export default IntentEditor;
