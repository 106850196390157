import React from 'react';
import Moment from 'react-moment';

export interface IFormattedDateTimeProps {
  value: string | undefined;
}

const FormattedDateTime: React.FC<IFormattedDateTimeProps> = ({ value }) => {
  const format = 'HH:mm DD.MM.YYYY';
  return <Moment date={value} format={format} interval={0} />;
};

export default FormattedDateTime;
