import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectValue } from 'antd/lib/select';

import {
  HttpTransportType,
  LiveChatSecurityValidationRuleModel,
  LiveChatSecurityValidationRuleType,
  LiveChatSettingsModel,
} from '../../../../api';
import IbTypography from '../common/IbTypography';
import IbInput from '../common/IbInput';
import IbColorPicker from '../common/IbColorPicker';
import IbSwitch from '../common/IbSwitch';
import IbSelect from '../common/IbSelect';
import IbButton from '../common/IbButton';
import IbIcon from '../common/IbIcon';
import IbTextArea from '../common/IbTextArea';
import IbTabs from '../common/IbTabs';
import IbTabPane from '../common/IbTabPane';

import {
  BACKGROUND_COLOR_LIST,
  DESIGN_COLOR_LIST,
  FORM_GROUP_CLASS_NAME,
  FORM_ITEM_CLASS_NAME,
  FORM_ITEM_SWITCH_CLASS_NAME,
  LIVECHAT_CLASS_NAME,
  PREVIEW_MOBILE_TOGGLE_CLASS_NAME,
  SETTINGS_CLASS_NAME,
  SUBTITLE_CLASS_NAME,
  USER_BUBBLE_BACKGROUND_COLOR_LIST,
} from './const';
import LiveChatPreview from './LiveChatPreview';
import { getTextColor } from './utils';

enum LiveChatSettingsTabKeys {
  INTEGRATION = 'INTEGRATION',
  WIDGET = 'WIDGET',
  WIDGET_BUTTON = 'WIDGET_BUTTON',
  SECURITY = 'SECURITY',
  TRANSPORT = 'TRANSPORT',
}

const isCorsValidationRule = (r: LiveChatSecurityValidationRuleModel) =>
  r.type === LiveChatSecurityValidationRuleType.Cors;
const isTokenValidationRule = (r: LiveChatSecurityValidationRuleModel) =>
  r.type === LiveChatSecurityValidationRuleType.ValidateTokenByJwks ||
  r.type === LiveChatSecurityValidationRuleType.ValidateTokenByPublicKey ||
  r.type === LiveChatSecurityValidationRuleType.ValidateTokenBySecret;

interface ILiveChatSettingsProps {
  showValidation: boolean;
  nameIsValid: boolean;
  name: string;
  codeSnippet: ReactNode;
  settings: LiveChatSettingsModel;
  onNameChange: (name: string) => void;
  onSettingsChange: (settings: LiveChatSettingsModel) => void;
}

const LiveChatSettings: React.FC<ILiveChatSettingsProps> = ({
  showValidation,
  nameIsValid,
  name,
  settings,
  codeSnippet,
  onNameChange,
  onSettingsChange,
}) => {
  const { t } = useTranslation();

  const [previewMobileVisible, setPreviewMobileVisible] = useState(false);
  const [settingsTabKey, setSettingsTabKey] = useState(
    codeSnippet ? LiveChatSettingsTabKeys.INTEGRATION : LiveChatSettingsTabKeys.SECURITY
  );

  const security = settings.security || { validationRules: [] };

  const corsValidationRule = security.validationRules.find(isCorsValidationRule) || {
    enabled: false,
    type: LiveChatSecurityValidationRuleType.Cors,
    value: '',
  };

  const tokenValidationRule = security.validationRules.find(isTokenValidationRule) || {
    enabled: false,
    type: LiveChatSecurityValidationRuleType.ValidateTokenBySecret,
    value: '',
  };

  const onSettingsTabChange = (activeKey: string) => {
    const tabNewValue = activeKey as LiveChatSettingsTabKeys;
    setSettingsTabKey(tabNewValue);
  };

  const onChatTitleChange = (title: string) =>
    onSettingsChange({
      ...settings,
      chat: {
        ...settings.chat,
        title,
      },
    });

  const onChatPrimaryColorChange = (color: string) =>
    onSettingsChange({
      ...settings,
      chat: {
        ...settings.chat,
        primaryColor: color,
      },
    });

  const onChatUserBubbleBackgroundChange = (color: string) =>
    onSettingsChange({
      ...settings,
      chat: {
        ...settings.chat,
        userBubbleBackground: color,
      },
    });

  const onChatBackgroundChange = (color: string) =>
    onSettingsChange({
      ...settings,
      chat: {
        ...settings.chat,
        background: color,
      },
    });

  const onToggleButtonOffsetBottomChange = (bottom: string) =>
    onSettingsChange({
      ...settings,
      toggleButton: {
        ...settings.toggleButton,
        offset: {
          ...settings.toggleButton.offset,
          bottom,
        },
      },
    });

  const onToggleButtonOffsetRightChange = (right: string) =>
    onSettingsChange({
      ...settings,
      toggleButton: {
        ...settings.toggleButton,
        offset: {
          ...settings.toggleButton.offset,
          right,
        },
      },
    });

  const onToggleButtonSizeChange = (size: string) =>
    onSettingsChange({
      ...settings,
      toggleButton: {
        ...settings.toggleButton,
        size,
      },
    });

  const onToggleButtonBackgroundChange = (background: string) =>
    onSettingsChange({
      ...settings,
      toggleButton: {
        ...settings.toggleButton,
        background,
      },
    });

  const onCorsValidationRuleEnabledChange = () =>
    onSettingsChange({
      ...settings,
      security: {
        ...security,
        validationRules: security.validationRules
          .filter((r) => !isCorsValidationRule(r))
          .concat([
            {
              ...corsValidationRule,
              enabled: !corsValidationRule.enabled,
            },
          ]),
      },
    });

  const onWebSocketsEnabledChange = (checked: boolean) =>
    onSettingsChange({
      ...settings,
      transport: checked ? HttpTransportType.WebSockets : HttpTransportType.LongPolling,
    });

  const onCorsValidationRuleValueChange = (value: string) =>
    onSettingsChange({
      ...settings,
      security: {
        ...security,
        validationRules: security.validationRules
          .filter((r) => !isCorsValidationRule(r))
          .concat([
            {
              ...corsValidationRule,
              value,
            },
          ]),
      },
    });

  const onTokenValidationRuleEnabledChange = () =>
    onSettingsChange({
      ...settings,
      security: {
        ...security,
        validationRules: security.validationRules
          .filter((r) => !isTokenValidationRule(r))
          .concat([
            {
              ...tokenValidationRule,
              enabled: !tokenValidationRule.enabled,
            },
          ]),
      },
    });

  const onTokenValidationRuleTypeChange = (type: SelectValue) =>
    onSettingsChange({
      ...settings,
      security: {
        ...security,
        validationRules: security.validationRules
          .filter((r) => !isTokenValidationRule(r))
          .concat([
            {
              ...tokenValidationRule,
              type: type as LiveChatSecurityValidationRuleType,
              value: '',
            },
          ]),
      },
    });

  const onTokenValidationRuleValueChange = (value: string) =>
    onSettingsChange({
      ...settings,
      security: {
        ...security,
        validationRules: security.validationRules
          .filter((r) => !isTokenValidationRule(r))
          .concat([
            {
              ...tokenValidationRule,
              value,
            },
          ]),
      },
    });

  const onJwksHelpClick = () => {
    window.open('https://is.docs.wso2.com/en/latest/references/concepts/authentication/jwks/', '_blank');
  };

  const onMobilePreviewToggleClick = () => setPreviewMobileVisible(!previewMobileVisible);

  const renderSettings = () => (
    <div className={SETTINGS_CLASS_NAME}>
      <IbTypography.Paragraph strong className={SUBTITLE_CLASS_NAME} type="secondary">
        {t('Name2')}
      </IbTypography.Paragraph>
      <div className={FORM_ITEM_CLASS_NAME}>
        <IbTypography.Paragraph type="secondary">{t('Channel name')}</IbTypography.Paragraph>
        <IbInput status={showValidation && !nameIsValid ? 'error' : 'default'} value={name} onChange={onNameChange} />
      </div>

      <IbTabs activeKey={settingsTabKey} onChange={onSettingsTabChange}>
        {codeSnippet && (
          <IbTabPane
            key={LiveChatSettingsTabKeys.INTEGRATION}
            tab={t('Integration')}
            tabKey={LiveChatSettingsTabKeys.INTEGRATION}
          >
            {codeSnippet}
          </IbTabPane>
        )}
        <IbTabPane key={LiveChatSettingsTabKeys.WIDGET} tab={t('Widget')} tabKey={LiveChatSettingsTabKeys.WIDGET}>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Widget title')}</IbTypography.Paragraph>
            <IbInput value={settings.chat.title} onChange={onChatTitleChange} />
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Design color')}</IbTypography.Paragraph>
            <IbColorPicker
              enableCustomColor
              color={settings.chat.primaryColor}
              colorList={DESIGN_COLOR_LIST}
              onChange={onChatPrimaryColorChange}
            />
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('User bubble background color')}</IbTypography.Paragraph>
            <IbColorPicker
              enableCustomColor
              color={settings.chat.userBubbleBackground}
              colorList={USER_BUBBLE_BACKGROUND_COLOR_LIST}
              onChange={onChatUserBubbleBackgroundChange}
            />
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Background color')}</IbTypography.Paragraph>
            <IbColorPicker
              enableCustomColor
              color={settings.chat.background}
              colorList={BACKGROUND_COLOR_LIST}
              onChange={onChatBackgroundChange}
            />
          </div>
        </IbTabPane>
        <IbTabPane
          key={LiveChatSettingsTabKeys.WIDGET_BUTTON}
          tab={t('Widget button')}
          tabKey={LiveChatSettingsTabKeys.WIDGET_BUTTON}
        >
          <div className={FORM_GROUP_CLASS_NAME}>
            <div className={FORM_ITEM_CLASS_NAME}>
              <IbTypography.Paragraph type="secondary">{t('Bottom indent')}</IbTypography.Paragraph>
              <IbInput value={settings.toggleButton.offset.bottom} onChange={onToggleButtonOffsetBottomChange} />
            </div>
            <div className={FORM_ITEM_CLASS_NAME}>
              <IbTypography.Paragraph type="secondary">{t('Right indent')}</IbTypography.Paragraph>
              <IbInput value={settings.toggleButton.offset.right} onChange={onToggleButtonOffsetRightChange} />
            </div>
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Widget open button size')}</IbTypography.Paragraph>
            <IbInput value={settings.toggleButton.size} onChange={onToggleButtonSizeChange} />
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Widget button color')}</IbTypography.Paragraph>
            <IbColorPicker
              enableCustomColor
              color={settings.toggleButton.background}
              colorList={DESIGN_COLOR_LIST}
              onChange={onToggleButtonBackgroundChange}
            />
          </div>
        </IbTabPane>
        <IbTabPane key={LiveChatSettingsTabKeys.SECURITY} tab={t('Security')} tabKey={LiveChatSettingsTabKeys.SECURITY}>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('CORS')}</IbTypography.Paragraph>
            <div className={FORM_ITEM_SWITCH_CLASS_NAME}>
              <IbSwitch checked={corsValidationRule.enabled} onChange={onCorsValidationRuleEnabledChange} />
              <IbTypography.Paragraph type="secondary">
                {corsValidationRule.enabled ? t('Enabled') : t('Disabled')}
              </IbTypography.Paragraph>
            </div>
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Only specified domains are allowed')}</IbTypography.Paragraph>
            <IbInput
              placeholder={t('Enter a list of domains separated by commas')}
              value={corsValidationRule.value}
              onChange={onCorsValidationRuleValueChange}
            />
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Token authorization')}</IbTypography.Paragraph>
            <div className={FORM_ITEM_SWITCH_CLASS_NAME}>
              <IbSwitch checked={tokenValidationRule.enabled} onChange={onTokenValidationRuleEnabledChange} />
              <IbTypography.Paragraph type="secondary">
                {tokenValidationRule.enabled ? t('Enabled') : t('Disabled')}
              </IbTypography.Paragraph>
            </div>
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('Validation of an external token')}</IbTypography.Paragraph>
            <IbSelect
              options={[
                { label: t('by secret'), value: LiveChatSecurityValidationRuleType.ValidateTokenBySecret },
                { label: t('by public key'), value: LiveChatSecurityValidationRuleType.ValidateTokenByPublicKey },
                { label: t('by JWKS'), value: LiveChatSecurityValidationRuleType.ValidateTokenByJwks },
              ]}
              value={tokenValidationRule.type}
              onChange={onTokenValidationRuleTypeChange}
            />
            <IbTypography.Paragraph disabled type="descriptor">
              {tokenValidationRule.type === LiveChatSecurityValidationRuleType.ValidateTokenBySecret ? (
                t('Secret word used when encrypting an external system token')
              ) : tokenValidationRule.type === LiveChatSecurityValidationRuleType.ValidateTokenByPublicKey ? (
                t('Contents of the public key of the certificate used when encrypting the external system token')
              ) : tokenValidationRule.type === LiveChatSecurityValidationRuleType.ValidateTokenByJwks ? (
                <>
                  {t(
                    'Url that returns a set of public keys of the external system authorization server in JWKS format'
                  )}
                  <IbButton icon={<IbIcon iconName="help" />} type="link" onClick={onJwksHelpClick} />
                </>
              ) : null}
            </IbTypography.Paragraph>
          </div>
          <div className={FORM_ITEM_CLASS_NAME}>
            {tokenValidationRule.type === LiveChatSecurityValidationRuleType.ValidateTokenByPublicKey ? (
              <IbTextArea
                placeholder={t('Enter the public key content')}
                value={tokenValidationRule.value}
                onChange={onTokenValidationRuleValueChange}
              />
            ) : (
              <IbInput
                placeholder={
                  tokenValidationRule.type === LiveChatSecurityValidationRuleType.ValidateTokenBySecret
                    ? t('Enter the secret word')
                    : 'https://server.example.com/jwks.json'
                }
                value={tokenValidationRule.value}
                onChange={onTokenValidationRuleValueChange}
              />
            )}
          </div>
        </IbTabPane>
        <IbTabPane
          key={LiveChatSettingsTabKeys.TRANSPORT}
          tab={t('Transport')}
          tabKey={LiveChatSettingsTabKeys.TRANSPORT}
        >
          <div className={FORM_ITEM_CLASS_NAME}>
            <IbTypography.Paragraph type="secondary">{t('WebSockets')}</IbTypography.Paragraph>
            <div className={FORM_ITEM_SWITCH_CLASS_NAME}>
              <IbSwitch
                checked={settings.transport === HttpTransportType.WebSockets}
                onChange={onWebSocketsEnabledChange}
              />
              <IbTypography.Paragraph type="secondary">
                {settings.transport === HttpTransportType.WebSockets ? t('Enabled') : t('Disabled')}
              </IbTypography.Paragraph>
            </div>
          </div>
        </IbTabPane>
      </IbTabs>
    </div>
  );

  const renderMobilePreviewToggle = () => (
    <div className={PREVIEW_MOBILE_TOGGLE_CLASS_NAME} onClick={onMobilePreviewToggleClick}>
      {previewMobileVisible ? (
        <IbIcon color={getTextColor(settings.chat.primaryColor)} iconName={'close-small'} />
      ) : (
        <>
          <IbTypography.Paragraph disabled type="secondary">
            {t('Widget')}
          </IbTypography.Paragraph>
          <IbIcon iconName="right" />
        </>
      )}
    </div>
  );

  return (
    <div className={LIVECHAT_CLASS_NAME}>
      {renderSettings()}
      {renderMobilePreviewToggle()}
      <LiveChatPreview previewMobileVisible={previewMobileVisible} settings={settings} />
    </div>
  );
};

export default LiveChatSettings;
