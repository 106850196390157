import React from 'react';
import moment from 'moment/moment';

import SbIcon from '../../../../components/common/SbIcon';

const MAIN_CLASS_NAME = 'sb-dialogs-card__content__panel-container__messages';
const SESSION_DATE_FORMAT = 'DD.MM.YYYY в HH:mm';

interface ISessionInfoProps {
  timestamp: string;
  isClosed?: boolean;
}

const SessionInfo: React.FC<ISessionInfoProps> = ({ timestamp, isClosed }) => (
  <div className={`${MAIN_CLASS_NAME}__session-message`}>
    <div
      className={`${MAIN_CLASS_NAME}__session-message__text ${
        isClosed ? `${MAIN_CLASS_NAME}__session-message__text_closed` : ''
      }`}
    >
      {isClosed ? (
        <>
          <SbIcon iconName="lock" size={12} /> Беседа закрыта {moment(timestamp).format(SESSION_DATE_FORMAT)}
        </>
      ) : (
        `Начало сессии ${moment(timestamp).format(SESSION_DATE_FORMAT)}`
      )}
    </div>
  </div>
);

export default SessionInfo;
