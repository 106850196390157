import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { AlignType } from 'rc-table/lib/interface';
import { useRecoilState, useRecoilValue } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';
import { SelectValue } from 'antd/es/select';

import './index.less';

import SbModal from '../../../../simple-bot/components/common/SbModal';
import SbButton from '../../../../simple-bot/components/common/SbButton';
import SbTabs from '../../../../simple-bot/components/common/SbTabs';
import SbTabPane from '../../../../simple-bot/components/common/SbTabPane';
import SbSearch from '../../../../simple-bot/components/common/SbSearch';
import SbTable from '../../../../simple-bot/components/common/SbTable';
import SbPanel from '../../../../simple-bot/components/common/SbPanel';
import SbList from '../../../../simple-bot/components/common/SbList';
import SbTypography from '../../../../simple-bot/components/common/SbTypography';
import SbIcon from '../../../../simple-bot/components/common/SbIcon';
import SbScroll from '../../../../simple-bot/components/common/SbScroll';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
  scenarioStructuresSelector,
  systemIntentGroupsSelector,
  systemIntentsSelector,
} from '../../../../recoil/scenarioStructure';
import { getIgnoreCaseStringComparer, includesIgnoreCase } from '../../../../utils/stringUtil';
import {
  DefaultTriggerGroupSchema,
  IntentContentSchema,
  IntentEntryModel,
  IntentReferenceSchema,
  SchemaKind,
  SystemIntentReferenceSchema,
  UserIntentReferenceSchema,
} from '../../../../../api';
import {
  generateId,
  generateIntentId,
  instanceOfIntentTriggerSchema,
  instanceOfUserIntentReferenceSchema,
} from '../../utils';
import IntentEditor from '../IntentEditor';
import SbTag from '../../../../simple-bot/components/common/SbTag';
import SbSelect from '../../../../simple-bot/components/common/SbSelect';
import { INTENT_CATEGORY_TAG_COLOR } from '../../constants';
import { intentEditionApi } from '../../../../apis';

interface IIntentSelectorProps {
  caption: string;
  visible: boolean;
  onClose: () => void;
  onSelect: (intentReference: IntentReferenceSchema) => void;
}

const IntentType = {
  System: 'system',
  User: 'user',
};

interface IIntentFilter {
  groupEntryId?: string;
  search?: string;
}

const MODAL_HEIGHT = 650;
const MODAL_WIDTH = 866;
const TABLE_HEIGHT = 484;

const isSystemIntent = (intent: IntentEntryModel | IntentContentSchema): intent is IntentEntryModel =>
  'groupCode' in intent && 'code' in intent && 'currentSemVer' in intent;

const isUserIntent = (intent: IntentEntryModel | IntentContentSchema): intent is IntentContentSchema =>
  '$kind' in intent && intent.$kind === SchemaKind.IntentContent;

const IntentSelector: React.FC<IIntentSelectorProps> = ({ caption, visible, onClose, onSelect }) => {
  // TODO: Получение и фильтрация с сервера?
  // TODO: Добавить мемоизацию для внутренних функций?
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const systemIntents = useRecoilValue(systemIntentsSelector);
  const systemIntentGroups = useRecoilValue(systemIntentGroupsSelector);
  const userIntents = scenarioStructure?.intents || [];
  const scenarioStructures = useRecoilValue(scenarioStructuresSelector);

  const [intentFilter, setIntentFilter] = useState<IIntentFilter>();
  const [intentType, setIntentType] = useState(IntentType.System);
  const [selectedSystemIntentIds, setSelectedSystemIntentIds] = useState([] as string[]);
  const [selectedUserIntentIds, setSelectedUserIntentIds] = useState([] as string[]);
  const [editorVisible, setEditorVisible] = useState(false);
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState(false);

  const filterSystemIntents = () =>
    systemIntents
      .filter(
        (intent) =>
          (!intentFilter?.search ||
            includesIgnoreCase(intent.name, intentFilter.search) ||
            includesIgnoreCase(intent.description, intentFilter.search)) &&
          (!intentFilter?.groupEntryId || intentFilter?.groupEntryId === intent.groupEntryId)
      )
      .sort(getIgnoreCaseStringComparer((v) => v.name));

  const filterUserIntents = () =>
    userIntents.filter((intent) => !intentFilter?.search || includesIgnoreCase(intent.name, intentFilter.search));

  const filterSelectedSystemIntents = () => {
    return selectedSystemIntentIds.length
      ? filterSystemIntents().filter((intent) => selectedSystemIntentIds.includes(intent.id))
      : [];
  };

  const filterSelectedUserIntents = () => {
    return selectedUserIntentIds.length
      ? filterUserIntents().filter((intent) => selectedUserIntentIds.includes(intent.id))
      : [];
  };

  const filterSelectedSystemIntentIds = () => {
    return selectedSystemIntentIds.length
      ? filterSelectedSystemIntents().map((intent) => intent.id)
      : selectedSystemIntentIds;
  };

  const filterSelectedUserIntentIds = () => {
    return selectedUserIntentIds.length
      ? filterSelectedUserIntents().map((intent) => intent.id)
      : selectedUserIntentIds;
  };

  const tryGetSelectedSystemIntent = () => {
    if (intentType !== IntentType.System) {
      return null;
    }

    const intents = filterSelectedSystemIntents();
    return intents.length ? intents[0] : null;
  };

  const tryGetSelectedUserIntent = () => {
    if (intentType !== IntentType.User) {
      return null;
    }

    const intents = filterSelectedUserIntents();
    return intents.length ? intents[0] : null;
  };

  const tryGetSelectedIntent = () => {
    if (intentType === IntentType.System) {
      return tryGetSelectedSystemIntent();
    }

    if (intentType === IntentType.User) {
      return tryGetSelectedUserIntent();
    }

    throw new Error(`Неизвестный тип интента '${intentType}'.`);
  };

  const selectIntentReference = () => {
    const selectedIntent = tryGetSelectedIntent();
    if (!selectedIntent) {
      return;
    }

    if (isSystemIntent(selectedIntent)) {
      const systemIntentReference: SystemIntentReferenceSchema = {
        $kind: SchemaKind.SystemIntentReference,
        id: generateId('INR'),
        groupCode: selectedIntent.groupCode,
        intentCode: selectedIntent.code,
        semVer: selectedIntent.currentSemVer,
      };
      onSelect(systemIntentReference);
      return;
    }

    if (isUserIntent(selectedIntent)) {
      const userIntentReference: UserIntentReferenceSchema = {
        $kind: SchemaKind.UserIntentReference,
        id: generateId('INR'),
        intentId: selectedIntent.id,
      };
      onSelect(userIntentReference);
      return;
    }

    throw new Error(`Неизвестный тип интента '${intentType}'.`);
  };

  const onModalOk = () => {
    selectIntentReference();
    onClose();
  };

  const onModalCancel = () => {
    onClose();
  };

  const onSelectButtonClick = () => {
    selectIntentReference();
    onClose();
  };

  const tryGetSystemIntent = async (reference: IntentEntryModel) => {
    const response = await intentEditionApi.searchIntentEditions(
      '',
      reference.groupCode,
      reference.code,
      reference.currentSemVer
    );

    return response.data.items?.length ? response.data.items[0] : undefined;
  };

  const onCopyButtonClick = async () => {
    if (!scenarioStructure) {
      return;
    }

    const selectedIntent = tryGetSelectedSystemIntent();
    if (!selectedIntent) return;

    const currentSystemIntent = await tryGetSystemIntent(selectedIntent);
    if (!currentSystemIntent) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const newUttrances = currentSystemIntent.utterances;

    const newUserIntent: IntentContentSchema = {
      $kind: SchemaKind.IntentContent,
      id: generateIntentId(selectedIntent.name, scenarioStructures),
      name: selectedIntent.name,
      utterances: [...newUttrances],
    };

    if (!newScenarioStructure) return;
    newScenarioStructure.intents.push(newUserIntent);
    setScenarioStructure(newScenarioStructure);

    setIntentFilter(undefined);
    setIntentType(IntentType.User);
    setSelectedUserIntentIds([newUserIntent.id]);
  };

  const onCreateButtonClick = () => {
    if (!scenarioStructure) {
      return;
    }

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const intentName = `Новый интент ${scenarioStructure.intents.length + 1}`;
    const newUserIntent: IntentContentSchema = {
      $kind: SchemaKind.IntentContent,
      id: generateIntentId(intentName, scenarioStructures),
      name: intentName,
      utterances: [],
    };

    newScenarioStructure.intents.push(newUserIntent);
    setScenarioStructure(newScenarioStructure);
    setIntentFilter(undefined);
    setIntentType(IntentType.User);
    setSelectedUserIntentIds([newUserIntent.id]);
    setEditorVisible(true);
  };

  const onEditButtonClick = () => {
    setEditorVisible(true);
  };

  const onCancelButtonClick = () => {
    onClose();
  };

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIntentFilter({
      ...intentFilter,
      search: event.target.value,
    });
  };

  const onSearchClear = () => setIntentFilter({ ...intentFilter, search: undefined });

  const onTabPaneChange = (activeKey: string) => {
    setIntentType(activeKey);
  };

  const onSelectSystemIntentKeys = (rowKeys: string[]) => {
    setSelectedSystemIntentIds(rowKeys);
  };

  const onSelectUserIntentKeys = (rowKeys: string[]) => {
    setSelectedUserIntentIds(rowKeys);
  };

  const onEditorClose = () => {
    setEditorVisible(false);
  };

  const canSelectIntent = () => {
    const intent = tryGetSelectedIntent();
    return !!intent;
  };

  const canEditIntent = () => {
    const intent = tryGetSelectedUserIntent();
    return !!intent;
  };

  const canDeleteIntent = () => {
    const intent = tryGetSelectedUserIntent();
    return !!intent;
  };

  const renderEmptyIntent = () => {
    return <SbPanel sbType="info" />;
  };

  const userIntentColumns = [
    {
      title: 'Название интента',
      render: (record: IntentContentSchema) => {
        const hasIssue = !!scenarioValidation?.issueMap.get(record.id)?.length;
        return (
          <div className="sb-user-intent-selector-table-column-name">
            <span title={record.name}>{record.name}</span>
            {hasIssue && <SbIcon iconName="attention" size={16} />}
          </div>
        );
      },
      key: 'name',
      width: '300px',
    },
    {
      title: 'Кол-во фраз',
      render: (record: IntentContentSchema) => record.utterances.length,
      key: 'utterances',
      width: 'auto',
      align: 'right' as AlignType,
    },
  ];

  const systemIntentColumns = [
    {
      title: 'Название интента',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, record: IntentEntryModel) => {
        const group = systemIntentGroups.find((g) => g.id === record.groupEntryId);
        return (
          <div className="sb-system-intent-table-row">
            <div className="sb-system-intent-table-row__cell">
              <p>
                <b>{record.name}</b>
              </p>
              <p>{record.description}</p>
            </div>
            <div className="sb-system-intent-table-row__cell">
              <SbTag color={INTENT_CATEGORY_TAG_COLOR} text={group?.name || ''} />
            </div>
          </div>
        );
      },
    },
  ];

  const resetState = () => {
    if (!visible) {
      return;
    }

    setIsDeleteConfirmationModalVisible(false);
    setIntentType(IntentType.System);
    setIntentFilter(undefined);
    setSelectedSystemIntentIds([]);
    setSelectedUserIntentIds([]);
  };
  useEffect(resetState, [visible]);

  const onGroupFilterChange = (value: SelectValue) =>
    setIntentFilter({ ...intentFilter, groupEntryId: value as string });

  const renderTabBarExtraContent = () => {
    if (intentType !== IntentType.System) return null;

    const options = systemIntentGroups
      .map((g) => ({ value: g.id, label: g.name }))
      .sort(getIgnoreCaseStringComparer((v) => v.label));
    return (
      <SbSelect
        allowClear
        className="sb-intent-selector-group-filter"
        options={options}
        placeholder="Выберите категорию"
        sbSize="small"
        sbType="light"
        value={intentFilter?.groupEntryId}
        onChange={onGroupFilterChange}
      />
    );
  };

  const renderSystemIntent = (intent: IntentEntryModel) => {
    return (
      <SbPanel sbType="info">
        <SbTypography>
          <h4>Описание интента</h4>
          <p>{intent.description || intent.name}</p>
        </SbTypography>
      </SbPanel>
    );
  };

  const renderUserIntent = (intent: IntentContentSchema) => {
    return (
      <SbPanel sbType="info">
        <SbScroll height={TABLE_HEIGHT}>
          <SbTypography>
            <h4>Содержание интента</h4>
            <SbList
              dataSource={intent.utterances}
              emptyText={
                <SbTypography>
                  <small>Фразы отсутствуют</small>
                </SbTypography>
              }
            />
          </SbTypography>
        </SbScroll>
      </SbPanel>
    );
  };

  const renderSelectedIntent = () => {
    const selectedIntent = tryGetSelectedIntent();
    if (!selectedIntent) {
      return renderEmptyIntent();
    }

    if (isSystemIntent(selectedIntent)) {
      return renderSystemIntent(selectedIntent);
    }

    if (isUserIntent(selectedIntent)) {
      return renderUserIntent(selectedIntent);
    }

    throw new Error(`Неизвестный тип интента '${intentType}'.`);
  };

  const renderIntentEditor = () => {
    const selectedUserIntent = tryGetSelectedUserIntent();
    if (!selectedUserIntent) {
      return null;
    }
    return <IntentEditor intent={selectedUserIntent} visible={editorVisible} onClose={onEditorClose} />;
  };

  const renderUserIntentsEmptyText = () => (intentFilter?.search ? 'Интенты не найдены' : 'Интенты отсутствуют');

  const onDeleteButtonClick = () => {
    setIsDeleteConfirmationModalVisible(true);
  };

  const selectedUserIntentIsUsed = () => {
    if (!scenarioStructure) return false;

    const selectedIntent = tryGetSelectedUserIntent();
    if (!selectedIntent) return false;

    return (scenarioStructure.triggerGroup as DefaultTriggerGroupSchema).triggers.some(
      (trigger) =>
        instanceOfIntentTriggerSchema(trigger) &&
        instanceOfUserIntentReferenceSchema(trigger.intentReference) &&
        trigger.intentReference.intentId == selectedIntent.id
    );
  };

  const onConfirmDeletion = () => {
    setIsDeleteConfirmationModalVisible(false);
    if (!scenarioStructure) return;

    const selectedIntent = tryGetSelectedUserIntent();
    if (!selectedIntent) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    newScenarioStructure.intents = newScenarioStructure.intents.filter((i) => i.id !== selectedIntent.id);

    const triggerGroup = newScenarioStructure?.triggerGroup as DefaultTriggerGroupSchema;
    triggerGroup.triggers = triggerGroup.triggers.filter(
      (trigger) =>
        !instanceOfIntentTriggerSchema(trigger) ||
        !instanceOfUserIntentReferenceSchema(trigger.intentReference) ||
        trigger.intentReference.intentId !== selectedIntent.id
    );

    setScenarioStructure(newScenarioStructure);
  };

  const onDeleteConfirmationModalClose = () => setIsDeleteConfirmationModalVisible(false);

  return (
    <SbModal
      footer={[
        <SbButton
          key="select"
          disabled={!canSelectIntent()}
          sbSize="medium"
          sbType="primary"
          onClick={onSelectButtonClick}
        >
          {caption}
        </SbButton>,
        intentType === IntentType.System ? (
          <SbButton
            key="select"
            disabled={!canSelectIntent()}
            sbSize="medium"
            sbType="secondary"
            onClick={onCopyButtonClick}
          >
            Скопировать
          </SbButton>
        ) : null,
        intentType === IntentType.User ? (
          <SbButton
            key="edit"
            disabled={!canEditIntent()}
            sbSize="medium"
            sbType="secondary"
            onClick={onEditButtonClick}
          >
            Редактировать
          </SbButton>
        ) : null,
        intentType === IntentType.User ? (
          <SbButton
            key="delete"
            disabled={!canDeleteIntent()}
            sbSize="medium"
            sbType="secondary"
            onClick={onDeleteButtonClick}
          >
            Удалить
          </SbButton>
        ) : null,
        <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onCancelButtonClick}>
          Отмена
        </SbButton>,
      ]}
      height={MODAL_HEIGHT}
      sbSize="small"
      title="Все интенты"
      visible={visible}
      width={MODAL_WIDTH}
      onCancel={onModalCancel}
      onOk={onModalOk}
    >
      <Row className="sb-modal__header" gutter={[20, 20]}>
        <Col>
          <SbButton sbSize="medium" sbType="secondary" onClick={onCreateButtonClick}>
            Создать новый интент
          </SbButton>
        </Col>
        <Col flex="auto">
          <SbSearch
            placeholder="Поиск"
            sbSize="small"
            value={intentFilter?.search}
            onChange={onSearchValueChange}
            onClear={onSearchClear}
          />
        </Col>
      </Row>
      <Row className="sb-modal__row-stretch" gutter={[20, 20]}>
        <Col className="sb-modal__col-main" span={intentType === IntentType.System ? 24 : 14}>
          <SbTabs activeKey={intentType} tabBarExtraContent={renderTabBarExtraContent()} onChange={onTabPaneChange}>
            <SbTabPane key={IntentType.System} tab="Системные">
              <SbTable
                columns={systemIntentColumns}
                dataSource={filterSystemIntents()}
                emptyText="Интенты не найдены"
                header={false}
                rowKey="id"
                selectedRowKeys={filterSelectedSystemIntentIds()}
                tableLayout="fixed"
                onSelectRowKeys={onSelectSystemIntentKeys}
              />
            </SbTabPane>
            <SbTabPane key={IntentType.User} tab="Пользовательские">
              <SbTable
                columns={userIntentColumns}
                dataSource={filterUserIntents()}
                emptyText={renderUserIntentsEmptyText()}
                height={TABLE_HEIGHT}
                rowKey="id"
                selectedRowKeys={filterSelectedUserIntentIds()}
                tableLayout="fixed"
                onSelectRowKeys={onSelectUserIntentKeys}
              />
              <SbModal
                footer={[
                  <SbButton key="delete" sbSize="medium" sbType="primary" onClick={onConfirmDeletion}>
                    Удалить
                  </SbButton>,
                  <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onDeleteConfirmationModalClose}>
                    Отмена
                  </SbButton>,
                ]}
                sbSize="small"
                title="Подтвердите удаление интента"
                visible={isDeleteConfirmationModalVisible}
                onCancel={onDeleteConfirmationModalClose}
                onOk={onConfirmDeletion}
              >
                {selectedUserIntentIsUsed() ? (
                  <div>Интент используется как триггер в текущем сценарии бота.</div>
                ) : undefined}
                <div>
                  Вы действительно хотите удалить интент <b>{tryGetSelectedUserIntent()?.name}</b>?
                </div>
              </SbModal>
            </SbTabPane>
          </SbTabs>
        </Col>
        {intentType !== IntentType.System && <Col span={10}>{renderSelectedIntent()}</Col>}
      </Row>
      {renderIntentEditor()}
    </SbModal>
  );
};

export default IntentSelector;
