import React from 'react';
import { Select } from 'antd';

import './index.less';

import SbIcon from '../SbIcon';

const MAIN_CLASS_NAME = 'sb-tags-select';
const DROPDOWN_CLASS_NAME = 'sb-tags-select__dropdown';

interface ISbTagsSelectProps {
  value: string[];
  options: { value: string; label: string }[];
  onChange?: (value: string[]) => void;
}

const SbTagsSelect: React.FC<ISbTagsSelectProps> = ({ value, onChange = () => {}, options }) => {
  const classes = [MAIN_CLASS_NAME];

  return (
    <Select
      className={classes.join(' ')}
      dropdownClassName={DROPDOWN_CLASS_NAME}
      menuItemSelectedIcon={<SbIcon iconName="check" size={16} />}
      mode="multiple"
      optionFilterProp="label"
      options={options}
      removeIcon={<SbIcon iconName="close" size={14} />}
      value={value}
      onChange={onChange}
    />
  );
};

export default SbTagsSelect;
