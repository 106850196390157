import React, { useEffect, useState } from 'react';
import { Button, Divider, PageHeader, Spin, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { DownloadOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import downloadFile from 'js-file-download';
import { useSetRecoilState } from 'recoil';

import { AlertTypes } from '../../../constants';
import { draftKbApi, knowledgeSourceKbApi } from '../../../apis';
import { DraftModel, KnowledgeSourceModel } from '../../../../kb-api';
import DataEntryList from '../../../components/DataEntryList';
import { binarySourceFormatToFileExtension } from '../../../utils/fileUtil';
import { alertsSelectorAdd } from '../../../recoil/alerts';

import DraftStatusView from './DraftStatusView';

const { Paragraph } = Typography;

const DraftCard: React.FC = () => {
  const { push } = useHistory();
  const { id, draftId } = useParams<{ id: string; draftId: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const [draft, setDraft] = useState<DraftModel>();
  const [knowledgeSource, setKnowledgeSource] = useState<KnowledgeSourceModel>();

  const loadDraftAsync = async () => {
    try {
      const response = await draftKbApi.getDraft(draftId);
      setDraft(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке черновика',
        error: e,
      });
    }
  };
  const loadDraft = () => {
    loadDraftAsync();
  };
  useEffect(loadDraft, [draftId]);

  const loadKnowledgeSourceAsync = async () => {
    if (!draft?.editionId) return;

    try {
      // NOTE: пока для черновика поддерживается один источник данных
      const response = await knowledgeSourceKbApi.searchKnowledgeSources(undefined, draft.editionId, undefined, 0, 10);
      setKnowledgeSource(response.data?.items?.pop());
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке источника базы знаний',
        error: e,
      });
    }
  };
  const loadKnowledgeSource = () => {
    loadKnowledgeSourceAsync();
  };
  useEffect(loadKnowledgeSource, [draft]);

  if (!draft) {
    return <Spin />;
  }

  const backToKnowledgeBase = () => {
    push(`/knowledge-bases/${id}`);
  };

  const onKnowledgeSourceParsed = () => {
    loadKnowledgeSourceAsync();
  };

  const onDownloadClick = async () => {
    if (!knowledgeSource?.binarySource) return;
    const { format } = knowledgeSource.binarySource;
    const response = await knowledgeSourceKbApi.getKnowledgeSourceFile(knowledgeSource.id, { responseType: 'blob' });
    const fileNameParts = [draft.name, moment(draft.createdOn).format('HH.mm_DD.MM.YYYY')];
    const fileName = `${fileNameParts.join('__')}${binarySourceFormatToFileExtension(format)}`;
    downloadFile(response.data, fileName);
  };

  const onGoToKnowledgeSourceUrl = () => {
    if (!knowledgeSource?.url) return;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(knowledgeSource?.url);
  };

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="back" icon={<LeftOutlined />} onClick={backToKnowledgeBase}>
            Назад
          </Button>,
        ]}
        title={draft.name}
      />
      <Paragraph>{draft.description || 'Без описания'}</Paragraph>
      {!!knowledgeSource?.binarySource && (
        <Button icon={<DownloadOutlined />} type="default" onClick={onDownloadClick}>
          Скачать исходный файл
        </Button>
      )}
      {!!knowledgeSource?.url && (
        <Button type="link" onClick={onGoToKnowledgeSourceUrl}>
          Перейти к исходному документу
        </Button>
      )}
      <Divider />
      <DraftStatusView
        draftId={draft.id}
        testingChatTitle={draft.name}
        onKnowledgeSourceParsed={onKnowledgeSourceParsed}
      />
      {draft.editionId && <DataEntryList editionId={draft.editionId} knowledgeSource={knowledgeSource} />}
    </div>
  );
};

export default DraftCard;
