import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';

import './index.less';

import IbTypography from '../common/IbTypography';
import IbChatListItem from '../IbChatList/IbChatListItem';
import { InboxChatModel, InboxDirection } from '../../../../api';
import NoChatsImage from '../../assets/no-chats.png';
import { EmptySearchLogo } from '../../assets';
import { SUBJECT_ROLES } from '../../../constants';
import { IEntityItem, IEntityRange, IEntityScroll, IEntityStats, StatsStatus } from '../../storages';
import { formatDateTimeAdaptive, formatDateTimeFull } from '../../../utils/stringUtil';
import { IbSocialType } from '../common/IbSocial';

export enum ChatTabKeys {
  Queue = 'queue',
  My = 'my',
  Closed = 'closed',
  All = 'all',
}

const MAIN_CLASS_NAME = 'ib-chat-list';
const EMPTY_CLASS_NAME = `${MAIN_CLASS_NAME}_empty`;
const SCROLL_CLASS_NAME = `${MAIN_CLASS_NAME}__scroll`;
const CANVAS_CLASS_NAME = `${SCROLL_CLASS_NAME}__canvas`;
const WINDOW_CLASS_NAME = `${CANVAS_CLASS_NAME}__window`;

const tryGetUserParticipant = (chat: InboxChatModel, userId?: string) => {
  return chat.participants?.find((p) => p.subject.id === userId && p.subject.role === SUBJECT_ROLES.operator);
};

const getChatUnreadMessagesCount = (chat: InboxChatModel, userId?: string) => {
  const userParticipant = tryGetUserParticipant(chat, userId);
  return userParticipant ? userParticipant.statistics.unreadMessageCount : chat.statistics.inboundMessageCount;
};

const getChatHasUnreadMentions = (chat: InboxChatModel, userId?: string) => {
  const userParticipant = tryGetUserParticipant(chat, userId);
  return userParticipant?.statistics.hasUnreadMentions ?? false;
};

export interface IIbChatListProps {
  items: (IEntityItem<InboxChatModel> | undefined)[];
  stats?: IEntityStats;
  range?: IEntityRange;
  tabKey?: ChatTabKeys;
  searchText?: string;
  currentUserId?: string;
  selectedChatId?: string;
  mentionLinksEnabled: boolean;
  onScroll?: (scroll: IEntityScroll) => void;
  onChatSelected?: (chatId?: string) => void;
}

const IbChatList: React.FC<IIbChatListProps> = ({
  items,
  stats,
  range,
  tabKey,
  searchText,
  currentUserId,
  selectedChatId,
  mentionLinksEnabled,
  onScroll,
  onChatSelected,
}) => {
  const { t, i18n } = useTranslation();

  const [scrolling, setScrolling] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const onUserScroll = () => {
    // NOTE: Отмечаем что scroll выполняется пользователем и позицию нужно сохранить в range.
    setScrolling(true);
  };

  const getItemKey = (index: number) => {
    const item = items[index];
    return item ? item.entity.id : index;
  };

  const estimateSize = () => {
    return 90;
  };

  const getScrollElement = () => scrollRef.current || null;

  const isLoading = stats?.status === StatsStatus.Unknown;

  const virtualizer = useVirtualizer({
    overscan: 5,
    count: stats?.itemCount || items.length || (isLoading ? 3 : 0),
    initialOffset: range?.scrollOffset,
    initialMeasurementsCache: range?.virtualItems,
    getItemKey,
    estimateSize,
    getScrollElement,
  });

  useEffect(() => {
    // NOTE: При монтировании компонента происходит scroll с offset = 0 поэтому игнорируем его.
    if (!scrolling) {
      return;
    }

    if (!onScroll) {
      return;
    }

    const scroll: IEntityScroll = {
      virtualItems: virtualizer.getVirtualItems(),
      scrollOffset: virtualizer.scrollOffset,
      totalSize: virtualizer.getTotalSize(),
    };
    onScroll(scroll);
  }, [virtualizer.scrollOffset]);

  const getNoChatsMessage = () => {
    switch (tabKey) {
      case ChatTabKeys.Queue:
        return t("You don't have any new chats at the moment");
      case ChatTabKeys.My:
        return t("You don't have any assigned chats");
      case ChatTabKeys.Closed:
        return t("You don't have any closed chats");
      case ChatTabKeys.All:
        return t("You don't have any chats yet");
      default:
        return t('Nothing found');
    }
  };

  if (!isLoading && !virtualizer.options.count) {
    return (
      <div className={`${MAIN_CLASS_NAME} ${EMPTY_CLASS_NAME}`}>
        <div>{tabKey ? <img alt={getNoChatsMessage()} src={NoChatsImage} /> : <EmptySearchLogo />}</div>
        <IbTypography.Paragraph type="secondary">{getNoChatsMessage()}</IbTypography.Paragraph>
      </div>
    );
  }

  const renderItem = (virtualItem: VirtualItem) => {
    const chat = items[virtualItem.index]?.entity;

    if (!chat) {
      return (
        <div key={virtualItem.key} ref={virtualizer.measureElement} data-index={virtualItem.index}>
          <IbChatListItem
            loading
            content={{ text: '', type: '' }}
            date={''}
            fullDate={''}
            hasUnreadMentions={false}
            mentionLinksEnabled={false}
            mentions={[]}
            title={''}
            unreadMessagesCount={0}
          />
        </div>
      );
    }

    const lastMessageOn = chat.statistics.lastMessage.anyOn;

    const senderOperatorSubject = chat.participants?.find(
      (p) => p.id === chat.statistics.lastMessage.senderParticipantId && p.subject.role === SUBJECT_ROLES.operator
    )?.subject;

    const senderSubject = chat.participants?.find((p) => p.id === chat.statistics.lastMessage.senderParticipantId)
      ?.subject;

    const contact = chat.participants?.find((p) => p.subject.role === SUBJECT_ROLES.user);

    return (
      <div key={virtualItem.key} ref={virtualizer.measureElement} data-index={virtualItem.index}>
        <IbChatListItem
          avatarImgSrc={contact?.subject.person.avatar || undefined}
          avatarMetadataUid={contact?.subject.id}
          chatId={chat.id}
          content={chat.statistics.lastMessage.content ?? { text: '', type: '' }}
          date={formatDateTimeAdaptive(lastMessageOn, i18n.language, 'short')}
          fullDate={formatDateTimeFull(lastMessageOn, i18n.language)}
          hasUnreadMentions={getChatHasUnreadMentions(chat, currentUserId)}
          mentionLinksEnabled={mentionLinksEnabled}
          mentions={chat.statistics.lastMessage?.mentions ?? []}
          searchText={searchText}
          selected={selectedChatId === chat.id}
          sender={
            chat.statistics.lastMessage.direction === InboxDirection.Outbound
              ? senderOperatorSubject
                ? senderOperatorSubject.person.name.givenName?.split(' ')[0] ??
                  senderOperatorSubject.person.name.fullName?.split(' ')[0] ??
                  undefined
                : senderSubject?.role === SUBJECT_ROLES.botAccount
                ? t('Bot')
                : undefined
              : undefined
          }
          social={chat.channelId as IbSocialType}
          status={chat.status}
          title={contact?.subject.person.name.fullName ?? chat.id}
          unreadMessagesCount={getChatUnreadMessagesCount(chat, currentUserId)}
          onSelect={onChatSelected}
        />
      </div>
    );
  };

  return (
    <div className={MAIN_CLASS_NAME}>
      <div ref={scrollRef} className={SCROLL_CLASS_NAME} onScroll={onUserScroll}>
        <div className={CANVAS_CLASS_NAME} style={{ height: `${virtualizer.getTotalSize()}px` }}>
          <div
            className={WINDOW_CLASS_NAME}
            style={{ transform: `translateY(${virtualizer.getVirtualItems()[0]?.start || 0}px)` }}
          >
            {virtualizer.getVirtualItems().map(renderItem)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IbChatList;
