import React from 'react';
import { DatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/';

import './index.less';
import SbIcon from '../SbIcon';

import SbRangePicker, { ISbRangePickerProps } from './SbRangePicker';

type ISbDatePickerProps = DatePickerProps;

const SbDatePicker: React.FC<ISbDatePickerProps> & { Range: React.FC<ISbRangePickerProps> } = (props) => {
  return (
    <DatePicker
      allowClear={false}
      className="sb-date-picker"
      dropdownClassName="sb-date-picker__dropdown"
      suffixIcon={<SbIcon iconName="calendar-three" size="20" />}
      {...props}
    />
  );
};

SbDatePicker.Range = SbRangePicker;

export default SbDatePicker;
