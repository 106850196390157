import React from 'react';
import { Tabs } from 'antd';
import { TabPaneProps } from 'antd/lib/tabs';

import './index.less';

const { TabPane } = Tabs;

interface ISbTabPaneProps extends TabPaneProps {
  key?: string;
  tab?: React.ReactNode;
}

const SbTabPane: React.FC<ISbTabPaneProps> = ({ key, tab, children, ...otherProps }) => (
  <TabPane key={key} className="sb-tab-pane" tab={tab} {...otherProps}>
    {children}
  </TabPane>
);

export default SbTabPane;
