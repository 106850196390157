import React, { memo } from 'react';
import { Typography } from 'antd';

import { DefaultTriggerGroupSchema, UnknownIntentTriggerSchema } from '../../../../../../api';
import TriggerWrapper from '../TriggerWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';

const { Text } = Typography;

interface IUnknownIntentTriggerProps {
  index: number;
  trigger: UnknownIntentTriggerSchema;
  group: DefaultTriggerGroupSchema;
}

const UnknownIntentTrigger: React.FC<IUnknownIntentTriggerProps> = ({ index, trigger, group }) => {
  return (
    <TriggerWrapper
      group={group}
      icon={<SbIcon iconName="help" size={20} />}
      index={index}
      menuItems={[]}
      title="Неизвестный интент"
      trigger={trigger}
    >
      <Text>Этот сценарий запустится при распознавании неизвестного интента.</Text>
    </TriggerWrapper>
  );
};

export default memo(UnknownIntentTrigger);
