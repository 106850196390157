import React, { useEffect, useState } from 'react';
import { Avatar, Button, Divider, Row, Space, Spin, Table } from 'antd';
import { useParams, useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { UserOutlined, MailOutlined, PhoneOutlined, TeamOutlined } from '@ant-design/icons';
import { useAsync } from 'react-async-hook';
import { useSetRecoilState } from 'recoil';

import InviteState from '../../components/InviteState';
import SendInviteButton from '../../components/SendInviteButton';
import { AccountContactModel, PersonAccountModel, PersonModel } from '../../../api';
import { personApi } from '../../apis';
import { AlertTypes, PERSON_UPDATED } from '../../constants';
import { getChannelName } from '../../utils/stringUtil';
import SubscriptionState from '../../components/SubscriptionState';
import ConversationWidget from '../../components/ConversationWidget';
import { hubConnections } from '../../utils/socketsUtil';
import { alertsSelectorAdd } from '../../recoil/alerts';

const PersonCard: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);
  const [loading, setLoading] = useState(false);
  const [person, setPerson] = useState({} as PersonModel);
  const history = useHistory();

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const response = await personApi.getPerson(id);
      setPerson(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке карточки работника',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync();
  };
  useEffect(loadData, []);

  const personAccounts = person.personAccounts || [];

  const personEventHandler = (args: { person: PersonModel }) => {
    if (id !== args.person.id) {
      return;
    }

    setPerson(args.person);
  };
  const subscribe = () => {
    if (!person?.id || !conn) return;

    conn.on(PERSON_UPDATED, personEventHandler);

    return () => {
      conn.off(PERSON_UPDATED, personEventHandler);
    };
  };
  useEffect(subscribe, [conn, person?.id]);

  const goToPersonGroups = () => {
    history.push(`/persons/${person.id}/groups`);
  };

  interface IGroupedData {
    [index: string]: AccountContactModel[];
  }
  const groupedData: IGroupedData = (person.accountContacts || []).reduce(
    (acc: IGroupedData, cur: AccountContactModel) => {
      if (!cur.agentStageId) return acc;

      if (acc[cur.agentStageId]) {
        acc[cur.agentStageId].push(cur);
      }
      acc[cur.agentStageId] = [cur];
      return acc;
    },
    {} as IGroupedData
  );

  const tableData = Object.keys(groupedData).map((agentStageId: string) => {
    let name = (person.agents || []).find((a) => a.productionAgent.id === agentStageId)?.name ?? '';
    if (name) {
      name = `${name} (Релиз)`;
    } else {
      name = (person.agents || []).find((a) => a.stagingAgent.id === agentStageId)?.name ?? '';
      name = name ? `${name} (Тест)` : '-';
    }

    const result: {
      agentName: string;
      [channelId: string]: AccountContactModel | string;
    } = {
      agentName: name,
    };

    personAccounts.forEach((pa: PersonAccountModel) => {
      const accountContact = groupedData[agentStageId].find((item) => item.channelId === pa.channelId);

      result[pa.channelId] = accountContact || '';
    });

    return result;
  });

  const renderTableData = () => (
    <Table dataSource={tableData.map((item, index) => ({ key: index, ...item }))} pagination={false}>
      <Table.ColumnGroup title="Канал:">
        <Table.Column dataIndex="agentName" render={(agentName: string) => agentName} title="Статус:" />
      </Table.ColumnGroup>
      {personAccounts.map((pa: PersonAccountModel) => (
        <Table.ColumnGroup key={pa.channelId} title={getChannelName(pa.channelId, pa.externalInfo.fullName)}>
          <Table.Column
            dataIndex={pa.channelId}
            render={(ac: AccountContactModel) => <SubscriptionState state={ac.state} />}
            title={<InviteState state={pa.state} />}
          />
        </Table.ColumnGroup>
      ))}
    </Table>
  );

  const renderGroups = () => {
    const groupsCount = person.groups?.length ?? 0;
    return (
      <h4>
        Группы сотрудника:{' '}
        {person.groups?.map((group, i) => (
          <span key={i}>
            <Link to={{ pathname: `/groups/${group.id}` }}>{group.name}</Link>
            {i !== groupsCount - 1 ? <span>, </span> : ''}
          </span>
        ))}
      </h4>
    );
  };

  if (loading)
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  return (
    <div>
      <Row align="top" justify="space-between">
        <Space>
          <Avatar icon={<UserOutlined />} size={64} />
          <Space direction="vertical">
            <span>{person.fullName}</span>
            <span>Работник</span>
          </Space>
        </Space>
        <SendInviteButton person={person} />
      </Row>
      <br />
      <Row>
        {tableData.length ? (
          renderTableData()
        ) : (
          <h4>Подписки отсутствуют, для добавления подписки нажмите на кнопку &quot;Отправить приглашение&quot;</h4>
        )}
      </Row>
      <Divider />
      <Row>{person.groups?.length ? renderGroups() : <h4>Сотрудник не состоит в группах.</h4>}</Row>
      <Button icon={<TeamOutlined />} type="link" onClick={goToPersonGroups}>
        Настроить группы
      </Button>
      <Divider />
      <Space direction="vertical">
        <Space size="middle">
          <PhoneOutlined />
          {person.primaryTel}
        </Space>
        <Space size="middle">
          <MailOutlined />
          {person.eMail}
        </Space>
      </Space>
      <Divider />
      <ConversationWidget caption="Все беседы сотрудника" personId={person.id} />
    </div>
  );
};

export default PersonCard;
