import { concatMap, filter, Observable, OperatorFunction, Subject, Unsubscribable } from 'rxjs';

import { EventType, IEventItem, SyncEventHandler, AsyncEventHandler } from './types';

export interface IEventBus {
  emit<T>(type: string, data?: T): void;
  onSync<T>(type: string, handler: SyncEventHandler<T>): Unsubscribable;
  onAsync<T>(type: string, handler: AsyncEventHandler<T>): Unsubscribable;
}

export class EventBus implements IEventBus {
  private static s_instance?: EventBus;

  private readonly _subject: Subject<IEventItem<unknown>>;

  public constructor() {
    this._subject = new Subject();
  }

  public static get instance(): EventBus {
    if (!EventBus.s_instance) {
      EventBus.s_instance = new EventBus();
    }

    return EventBus.s_instance;
  }

  public emit<TData>(type: string, data?: TData): void {
    const item = { type, data: data || {} };
    this._subject.next(item);
  }

  public onSync<T>(
    type: string,
    handler: SyncEventHandler<T>,
    ...operations: OperatorFunction<IEventItem<unknown>, IEventItem<unknown>>[]
  ): Unsubscribable {
    let observable = this.useFilter(type);
    observable = this.useOperations(observable, operations);
    return observable.subscribe((item) => handler(item.data as T, item.type));
  }

  public onAsync<T>(
    type: string,
    handler: AsyncEventHandler<T>,
    ...operations: OperatorFunction<IEventItem<unknown>, IEventItem<unknown>>[]
  ): Unsubscribable {
    let observable = this.useFilter(type);
    observable = this.useOperations(observable, operations);
    return observable.pipe(concatMap(async (item) => await handler(item.data as T, item.type))).subscribe();
  }

  private useFilter(type: string): Observable<IEventItem<unknown>> {
    const observable = this._subject.asObservable();
    if (!type || type === EventType.All) {
      return observable;
    }

    if (!type.includes(EventType.Any)) {
      return observable.pipe(filter((item) => item.type === type));
    }

    const expectedParts = type.split('.');
    return observable.pipe(
      filter((item) => {
        const givenParts = item.type.split('.');
        if (expectedParts.length > givenParts.length) {
          return false;
        }

        for (let index = 0; index < givenParts.length; index++) {
          const expectedPart = expectedParts[index];
          if (expectedPart === EventType.All) {
            return true;
          }
          if (expectedPart !== EventType.Any && expectedPart !== givenParts[index]) {
            return false;
          }
        }

        return true;
      })
    );
  }

  private useOperations(
    observable: Observable<IEventItem<unknown>>,
    operations: OperatorFunction<IEventItem<unknown>, IEventItem<unknown>>[]
  ): Observable<IEventItem<unknown>> {
    for (const operation of operations) {
      observable = observable.pipe(operation);
    }

    return observable;
  }
}
