import { Activity, ChannelAccount } from 'botframework-schema';
import i18next from 'i18next';
import { BehaviorSubject, Observable } from 'rxjs';

const USER = {
  id: 'user',
  name: 'user',
  role: 'user',
} as ChannelAccount;

const BOT = {
  id: 'bot',
  name: 'bot',
  role: 'bot',
} as ChannelAccount;

const DEFAULT_MESSAGE_ACTIVITY = {
  text: '',
  type: 'message',
  callerId: '',
  localTimezone: '',
  serviceUrl: '',
  channelId: '',
  label: '',
  from: USER,
  conversation: {
    isGroup: false,
    conversationType: '',
    id: 'conversationId',
    name: '',
  },
  valueType: '',
  recipient: BOT,
  listenFor: [],
} as Activity;

enum ConnectionStatus {
  Uninitialized,
  Connecting,
  Online,
  ExpiredToken,
  FailedToConnect,
  Ended,
}

interface IBotConnection {
  connectionStatus$: BehaviorSubject<ConnectionStatus>;
  activity$: Observable<Activity>;
  end(): void;
  referenceGrammarId?: string;
  postActivity(activity: Activity): Observable<string>;
  getSessionId?: () => Observable<string>;
}

export class LiveChatPreviewDirectLine implements IBotConnection {
  public connectionStatus$ = new BehaviorSubject(ConnectionStatus.Online);
  public activity$: Observable<Activity>;

  constructor() {
    this.activity$ = this.pollingGetActivity$();
  }

  end(): void {}

  getSessionId(): Observable<string> {
    return new Observable<string>((subscriber) => {
      subscriber.next('sessionId');
      subscriber.complete();
    });
  }

  postActivity(activity: Activity): Observable<string> {
    return new Observable<string>((subscriber) => {
      subscriber.next(activity.id);
      subscriber.complete();
    });
  }

  private pollingGetActivity$(): Observable<Activity> {
    return new Observable<Activity>((subscriber) => {
      subscriber.next({
        ...DEFAULT_MESSAGE_ACTIVITY,
        id: 'id-1',
        text: i18next.t('Hello! Can I help you?'),
        recipient: USER,
        from: BOT,
        timestamp: new Date('2023-09-19T12:00:00.000Z'),
      });
      subscriber.next({
        ...DEFAULT_MESSAGE_ACTIVITY,
        id: 'id-2',
        text: i18next.t('Hello. We can’t figure it out, can you help?'),
        recipient: BOT,
        from: USER,
        timestamp: new Date('2023-09-19T12:01:00.000Z'),
      });
      subscriber.next({
        ...DEFAULT_MESSAGE_ACTIVITY,
        id: 'id-3',
        text: i18next.t('Certainly'),
        recipient: USER,
        from: BOT,
        timestamp: new Date('2023-09-19T12:03:00.000Z'),
      });
      subscriber.next({
        ...DEFAULT_MESSAGE_ACTIVITY,
        id: 'id-4',
        text: i18next.t('Please describe the problem'),
        recipient: USER,
        from: BOT,
        timestamp: new Date('2023-09-19T12:04:00.000Z'),
      });
      subscriber.next({
        ...DEFAULT_MESSAGE_ACTIVITY,
        id: 'id-5',
        text: i18next.t('Here is the bug report'),
        recipient: BOT,
        from: USER,
        timestamp: new Date('2023-09-19T12:05:00.000Z'),
      });
      subscriber.complete();
    });
  }
}
