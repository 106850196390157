import React, { useEffect, useState } from 'react';
import { Button, Divider, PageHeader, Spin, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import downloadFile from 'js-file-download';
import { useSetRecoilState } from 'recoil';

import { AlertTypes } from '../../../constants';
import { versionKbApi, knowledgeSourceKbApi } from '../../../apis';
import { KnowledgeSourceModel, VersionModel } from '../../../../kb-api';
import DataEntryList from '../../../components/DataEntryList';
import VersionStatusView from '../../../components/VersionStatusView';
import { binarySourceFormatToFileExtension } from '../../../utils/fileUtil';
import { alertsSelectorAdd } from '../../../recoil/alerts';

const { Paragraph } = Typography;

const VersionCard: React.FC = () => {
  const { push } = useHistory();
  const { id, versionId } = useParams<{ id: string; versionId: string }>();

  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [version, setVersion] = useState<VersionModel>();
  const [knowledgeSource, setKnowledgeSource] = useState<KnowledgeSourceModel>();

  const loadVersionAsync = async () => {
    try {
      const response = await versionKbApi.getVersion(versionId);
      setVersion(response.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке версии',
        error: e,
      });
    }
  };
  const loadVersion = () => {
    loadVersionAsync();
  };
  useEffect(loadVersion, [versionId]);

  const loadKnowledgeSourceAsync = async () => {
    if (!version?.editionId) return;

    try {
      // NOTE: пока для редакции поддерживается один источник данных
      const response = await knowledgeSourceKbApi.searchKnowledgeSources(
        undefined,
        version.editionId,
        undefined,
        0,
        10
      );
      setKnowledgeSource(response.data?.items?.pop());
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке источника базы знаний',
        error: e,
      });
    }
  };
  const loadKnowledgeSource = () => {
    loadKnowledgeSourceAsync();
  };
  useEffect(loadKnowledgeSource, [version]);

  if (!version) {
    return <Spin />;
  }

  const backToKnowledgeBase = () => {
    push(`/knowledge-bases/${id}`);
  };

  const onKnowledgeSourceParsed = () => {
    loadKnowledgeSourceAsync();
  };

  const onDownloadClick = async () => {
    if (!knowledgeSource?.binarySource) return;
    const { format } = knowledgeSource.binarySource;
    const response = await knowledgeSourceKbApi.getKnowledgeSourceFile(knowledgeSource.id, { responseType: 'blob' });
    const fileNameParts = [version.name, moment(version.createdOn).format('HH.mm_DD.MM.YYYY')];
    const fileName = `${fileNameParts.join('__')}${binarySourceFormatToFileExtension(format)}`;
    downloadFile(response.data, fileName);
  };

  const onGoToKnowledgeSourceUrl = () => {
    if (!knowledgeSource?.url) return;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(knowledgeSource?.url);
  };

  return (
    <div>
      <PageHeader
        extra={[
          <Button key="back" icon={<LeftOutlined />} onClick={backToKnowledgeBase}>
            Назад
          </Button>,
        ]}
        title={version.name}
      />
      <Paragraph>{version.description || 'Без описания'}</Paragraph>
      {!!knowledgeSource?.binarySource && (
        <Button icon={<DownloadOutlined />} type="default" onClick={onDownloadClick}>
          Скачать исходный файл
        </Button>
      )}
      {!!knowledgeSource?.url && (
        <Button type="link" onClick={onGoToKnowledgeSourceUrl}>
          Перейти к исходному документу
        </Button>
      )}
      <Divider />
      <VersionStatusView
        detailed
        testingChatTitle={version.name}
        versionId={version.id}
        onKnowledgeSourceParsed={onKnowledgeSourceParsed}
      />
      {version.editionId && <DataEntryList editionId={version.editionId} knowledgeSource={knowledgeSource} />}
    </div>
  );
};

export default VersionCard;
