import React, { MutableRefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnType } from 'antd/lib/table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ExpandableConfig } from 'antd/lib/table/interface';
import { Flags } from 'react-feature-flags';

import IbTypography from '../../components/common/IbTypography';
import IbButton from '../../components/common/IbButton';
import IbIcon from '../../components/common/IbIcon';
import { OperatorGroupsEmptyLogo } from '../../assets';
import IbTable, { MOBILE_MAIN_CELL_CLASS_NAME } from '../../components/common/IbTable';
import {
  InboxOperatorGroupCreationRequest,
  InboxOperatorGroupModel,
  InboxOperatorGroupUpdatingRequest,
} from '../../../../api';
import {
  operatorGroupListFilterSelector,
  operatorGroupListSelector,
  participantItemsSelector,
  participantRangeSelector,
  participantStatsSelector,
} from '../../recoil';
import { FeatureFlagNames, SUBJECT_ROLES } from '../../../constants';
import { inboxOperatorGroupApi } from '../../../apis';
import IbConfirmModal from '../../components/common/IbConfirmModal';
import IbOperatorGroupEditModal from '../../components/IbOperatorGroupEditModal';
import IbAvatar from '../../components/common/IbAvatar';
import { InboxParticipantFilter } from '../../storages/participants';
import { StatsStatus } from '../../storages';
import { includesIgnoreCase } from '../../../utils/stringUtil';

import {
  ACTIONS_CLASS_NAME,
  ACTIONS_DATA_INDEX,
  CONTENT_CLASS_NAME,
  EMPTY_CONTENT_CLASS_NAME,
  EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME,
  EXPANDED_ROW_ACTIONS_CLASS_NAME,
  OPERATORS_CLASS_NAME,
  TABLE_CLASS_NAME,
  WITH_OPERATOR_LIST_CLASS_NAME,
} from './const';

export interface IGroupsTabRef {
  showAddModal: () => void;
}

interface IGroupsTabProps {
  tabRef: MutableRefObject<IGroupsTabRef | undefined>;
  searchText: string;
}

const GroupsTab: React.FC<IGroupsTabProps> = ({ tabRef, searchText }) => {
  const { t } = useTranslation();

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteGroup, setDeleteGroup] = useState<InboxOperatorGroupModel>();
  const [editGroup, setEditGroup] = useState<InboxOperatorGroupModel>();
  const [deleting, setDeleting] = useState(false);

  const setGroupListFilter = useSetRecoilState(operatorGroupListFilterSelector);
  const groupList = useRecoilValue(operatorGroupListSelector);
  const filteredGroupList = groupList.filter((g) => includesIgnoreCase(g.name, searchText));
  const groupListIsEmpty = !groupList.length;

  const [operatorListSearch, setOperatorListSearch] = useState('');
  const searchFilter = new InboxParticipantFilter(operatorListSearch, undefined, [SUBJECT_ROLES.operator]);
  // NOTE: в participantRangeSelector происходит начальная загрузка данных
  useRecoilValue(participantRangeSelector(searchFilter));
  const operatorListStats = useRecoilValue(participantStatsSelector(searchFilter));
  const operatorList = useRecoilValue(participantItemsSelector(searchFilter));
  const operatorListLoading =
    operatorListStats.status === StatsStatus.Unknown || (!operatorList.length && !!operatorListStats.itemCount);

  const closeEditModal = () => {
    setEditModalVisible(false);
    setEditGroup(undefined);
  };

  const openEditModal = (group: InboxOperatorGroupModel) => {
    setEditGroup(group);
    setEditModalVisible(true);
  };

  const resetFilterPage = () => setGroupListFilter({});

  const onOperatorsSearch = (search?: string) => {
    setOperatorListSearch(search || '');
  };

  const onEditModalCancel = () => closeEditModal();

  const onGroupDelete = (group: InboxOperatorGroupModel) => () => setDeleteGroup(group);

  const onTableRowClick = (group: InboxOperatorGroupModel, column: ColumnType<InboxOperatorGroupModel>) => {
    if (column.dataIndex === ACTIONS_DATA_INDEX) {
      return;
    }

    openEditModal(group);
  };

  const onEditButtonClick = (group: InboxOperatorGroupModel) => () => openEditModal(group);

  const onGroupAdd = async (request: InboxOperatorGroupCreationRequest) => {
    try {
      await inboxOperatorGroupApi.createInboxOperatorGroup(request);
      resetFilterPage();
    } catch (e) {
      // empty
    }
  };

  const onGroupEdit = async (request: InboxOperatorGroupUpdatingRequest) => {
    if (!editGroup) {
      return;
    }

    try {
      await inboxOperatorGroupApi.updateInboxOperatorGroup(editGroup.id, request);
      resetFilterPage();
    } catch (e) {
      // empty
    }
  };

  const onDeleteConfirm = async () => {
    if (!deleteGroup) {
      return;
    }

    setDeleting(true);
    try {
      await inboxOperatorGroupApi.deleteInboxOperatorGroup(deleteGroup.id);
      resetFilterPage();
    } catch (e) {
      // empty
    }
    setDeleting(false);

    setDeleteGroup(undefined);
    closeEditModal();
  };

  const onDeleteModalCancel = () => setDeleteGroup(undefined);

  const onAddButtonClick = () => setEditModalVisible(true);

  const onRefPropChange = () => {
    if (tabRef) {
      tabRef.current = {
        showAddModal: () => setEditModalVisible(true),
      };
    }
  };
  useEffect(onRefPropChange, [tabRef]);

  const columns: ColumnType<InboxOperatorGroupModel>[] = [
    {
      className: MOBILE_MAIN_CELL_CLASS_NAME,
      title: t('Name'),
      render: (_, record) => <IbTypography.Paragraph type="secondary">{record.name}</IbTypography.Paragraph>,
    },
    {
      title: t('Description'),
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">{record.description || '—'}</IbTypography.Paragraph>
      ),
    },
    {
      title: t('Structure'),
      render: (_, record) => (
        <IbTypography.Paragraph type="secondary">
          {record.operators.length ? t('PersonWithCount', { count: record.operators.length }) : '—'}
        </IbTypography.Paragraph>
      ),
    },
    {
      title: t('Operators'),
      render: (_, record) => (
        <div className={OPERATORS_CLASS_NAME}>
          {record.operators.length
            ? record.operators.map((o) => (
                <IbAvatar
                  key={o.operator.id}
                  metadata={{ uid: o.operator.id, text: o.operator.fullName }}
                  size="x-small"
                  tooltipContent={o.operator.fullName}
                />
              ))
            : '—'}
        </div>
      ),
    },
    {
      className: ACTIONS_CLASS_NAME,
      title: '',
      render: (_, record) => (
        <IbButton icon={<IbIcon iconName="delete" />} type="icon" onClick={onGroupDelete(record)} />
      ),
      dataIndex: ACTIONS_DATA_INDEX,
    },
  ];

  const expandableForMobile: ExpandableConfig<InboxOperatorGroupModel> = {
    expandedRowRender: (record) => {
      return (
        <>
          <ul>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Description')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">{record.description || '—'}</IbTypography.Paragraph>
            </li>
            <li>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Structure')}
              </IbTypography.Paragraph>
              <IbTypography.Paragraph type="secondary">
                {record.operators.length ? t('PersonWithCount', { count: record.operators.length }) : '—'}
              </IbTypography.Paragraph>
            </li>
            <li className={record.operators.length ? WITH_OPERATOR_LIST_CLASS_NAME : ''}>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Operators')}
              </IbTypography.Paragraph>
              <div className={OPERATORS_CLASS_NAME}>
                {record.operators.length
                  ? record.operators.map((o) => (
                      <IbAvatar
                        key={o.operator.id}
                        metadata={{ uid: o.operator.id, text: o.operator.fullName }}
                        size="x-small"
                        tooltipContent={o.operator.fullName}
                      />
                    ))
                  : '—'}
              </div>
            </li>
          </ul>
          <div className={EXPANDED_ROW_ACTIONS_CLASS_NAME}>
            <IbButton icon={<IbIcon iconName="edit" />} type="link" onClick={onEditButtonClick(record)}>
              {t('Edit')}
            </IbButton>
            <IbButton icon={<IbIcon iconName="delete" />} type="link" onClick={onGroupDelete(record)}>
              {t('Delete')}
            </IbButton>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <div className={`${CONTENT_CLASS_NAME} ${groupListIsEmpty ? EMPTY_CONTENT_CLASS_NAME : ''}`}>
        {groupListIsEmpty ? (
          <div className={EMPTY_CONTENT_PLACEHOLDER_CLASS_NAME}>
            <OperatorGroupsEmptyLogo />
            <IbTypography>
              <IbTypography.Paragraph disabled type="secondary">
                {t('Add operator groups')}
                <br />
                {t('for priority routing')}
                <br />
                {t('in connected channels')}
              </IbTypography.Paragraph>
            </IbTypography>
            <IbButton icon={<IbIcon iconName="add-one" />} onClick={onAddButtonClick}>
              {t('Add')}
            </IbButton>
          </div>
        ) : (
          <IbTable
            className={TABLE_CLASS_NAME}
            columns={columns}
            dataSource={filteredGroupList}
            emptyText={<IbTypography.Paragraph disabled>{t('Nothing found')}</IbTypography.Paragraph>}
            expandableForMobile={expandableForMobile}
            onRowClick={onTableRowClick}
          />
        )}
      </div>
      <Flags authorizedFlags={[FeatureFlagNames.INBOX_SUPERVISOR]}>
        <IbOperatorGroupEditModal
          allowDelete
          editGroup={editGroup}
          operatorList={operatorList.map((o) => o.entity)}
          operatorListLoading={operatorListLoading}
          visible={editModalVisible}
          onAdd={onGroupAdd}
          onCancel={onEditModalCancel}
          onDelete={editGroup ? onGroupDelete(editGroup) : () => {}}
          onEdit={onGroupEdit}
          onOperatorsSearch={onOperatorsSearch}
        />
        <IbConfirmModal
          footer={
            <>
              <IbButton disabled={deleting} onClick={onDeleteConfirm}>
                {t('Delete')}
              </IbButton>
              <IbButton type="secondary" onClick={onDeleteModalCancel}>
                {t('Cancel')}
              </IbButton>
              <IbButton type="fill" onClick={onDeleteModalCancel}>
                {t('Cancel (verb)')}
              </IbButton>
            </>
          }
          title={t('Delete group?')}
          visible={!!deleteGroup}
          onCancel={onDeleteModalCancel}
        >
          <IbTypography.Paragraph type="secondary">
            {t('All data associated with this group will be lost')}
          </IbTypography.Paragraph>
        </IbConfirmModal>
      </Flags>
    </>
  );
};

export default GroupsTab;
