import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { MentionPluginStore, MentionPluginTheme, PopperOptions } from '@draft-js-plugins/mention';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'usehooks-ts';

import './index.less';

import IbAvatar from '../../common/IbAvatar';
import IbTypography from '../../common/IbTypography';
import { getPopperReferenceElement } from '..';

const MAIN_CLASS_NAME = 'no-operators-popover';
const ENTRY_CLASS_NAME = `${MAIN_CLASS_NAME}__entry`;

export interface INoOperatorsPopoverProps {
  store: MentionPluginStore;
  popperOptions?: PopperOptions;
  theme: MentionPluginTheme;
}

export default function NoOperatorsPopover({
  store,
  theme,
  popperOptions = { placement: 'top-start' },
}: INoOperatorsPopoverProps): ReactElement {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const [className, setClassName] = useState(() => clsx(theme.mentionSuggestions, theme.mentionSuggestionsPopup));
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(
    getPopperReferenceElement(store, windowSize.width),
    popperElement,
    popperOptions
  );

  const classes = [className, MAIN_CLASS_NAME];

  useEffect(() => {
    requestAnimationFrame(() =>
      setClassName(clsx(theme.mentionSuggestions, theme.mentionSuggestionsPopup, theme.mentionSuggestionsPopupVisible))
    );
  }, [theme]);

  return (
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      className={classes.join(' ')}
      role="listbox"
    >
      <div className={ENTRY_CLASS_NAME}>
        <IbAvatar iconName="headset-one" size="x-small" />
        <IbTypography.Paragraph disabled type="secondary">
          {t('No operators added')}
        </IbTypography.Paragraph>
      </div>
    </div>
  );
}
