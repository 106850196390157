import React from 'react';

import './index.less';

import SbTypography from '../common/SbTypography';
import SbModal from '../common/SbModal';
import SbSpin from '../common/SbSpin';
import SbIcon from '../common/SbIcon';

import {
  CONTENT_CLASS_NAME,
  CONTENT_HEADER_CLASS_NAME,
  CONTENT_SPIN_CLASS_NAME,
  MAIN_CLASS_NAME,
  CONTENT_ICON_CLASS_NAME,
  CONTENT_ICON_SUCCESS_CLASS_NAME,
  CONTENT_ICON_ERROR_CLASS_NAME,
} from './const';

export enum SbProgressStatusModalStatus {
  InProgress = 'InProgress',
  Success = 'Success',
  Error = 'Error',
}

const HEADER_DEFAULT = undefined;
const VISIBLE_DEFAULT = false;
const CLOSABLE_DEFAULT = true;
const STATUS_DEFAULT = SbProgressStatusModalStatus.InProgress;

export interface ISbProgressStatusModalProps {
  header?: string;
  visible?: boolean;
  closable?: boolean;
  status?: SbProgressStatusModalStatus;
  onCancel?: () => void;
}

const SbProgressStatusModal: React.FC<ISbProgressStatusModalProps> = ({
  children,
  header = HEADER_DEFAULT,
  visible = VISIBLE_DEFAULT,
  closable = CLOSABLE_DEFAULT,
  status = STATUS_DEFAULT,
  onCancel,
}) => {
  const iconClasses = [CONTENT_ICON_CLASS_NAME];

  switch (status) {
    case SbProgressStatusModalStatus.Success:
      iconClasses.push(CONTENT_ICON_SUCCESS_CLASS_NAME);
      break;
    case SbProgressStatusModalStatus.Error:
      iconClasses.push(CONTENT_ICON_ERROR_CLASS_NAME);
      break;
  }

  const renderStatusVisual = () => {
    switch (status) {
      case SbProgressStatusModalStatus.InProgress:
        return <SbSpin className={CONTENT_SPIN_CLASS_NAME} />;
      case SbProgressStatusModalStatus.Success:
        return <SbIcon className={iconClasses.join(' ')} iconName="check-one" size={64} />;
      case SbProgressStatusModalStatus.Error:
        return <SbIcon className={iconClasses.join(' ')} iconName="attention" size={64} />;
    }
  };

  return (
    <SbModal
      className={MAIN_CLASS_NAME}
      closable={closable}
      footer={[]}
      sbSize="small"
      visible={visible}
      width={356}
      onCancel={onCancel}
    >
      <div className={CONTENT_CLASS_NAME}>
        {renderStatusVisual()}
        <SbTypography>
          <div className={CONTENT_HEADER_CLASS_NAME}>
            <h2>{header}</h2>
          </div>
        </SbTypography>
        {children}
      </div>
    </SbModal>
  );
};

export default SbProgressStatusModal;
