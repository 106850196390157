import React, { memo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useRecoilState, useRecoilValue } from 'recoil';

import { DefaultActionGroupSchema, ScriptActionSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import {
  currentScenarioStructureSelector,
  currentScenarioValidationResultSelector,
} from '../../../../../recoil/scenarioStructure';
import { EntityFieldPath, getElementId, tryGetElementById } from '../../../utils';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import EditButton from '../../common/EditButton';
import OutputConnection from '../../common/OutputConnection';

import ScriptEditor from './ScriptEditor';

import './index.less';

interface IScriptActionProps {
  index: number;
  action: ScriptActionSchema;
  group: DefaultActionGroupSchema;
}

const ScriptAction: React.FC<IScriptActionProps> = ({ action, index, group }) => {
  const scenarioValidation = useRecoilValue(currentScenarioValidationResultSelector);
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [editorVisible, setEditorVisible] = useState(false);

  const onEditButtonClick = () => {
    setEditorVisible(true);
  };

  const onScriptEditorChange = (content: string) => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);
    const foundAction = tryGetElementById(newScenarioStructure, action.id);
    if (!foundAction) return;

    foundAction.content = content;

    setScenarioStructure(newScenarioStructure);
  };

  const onScriptEditorClose = () => {
    setEditorVisible(false);
  };

  return (
    <ActionWrapper
      showScriptActionSettingsMenu
      action={action}
      group={group}
      icon={<SbIcon iconName="code-brackets" size={20} />}
      index={index}
      title="Скрипт"
    >
      <EditButton buttonHandler={onEditButtonClick} title="Редактировать" />
      <div className="sb-script-action__failure-connection">
        <div className="sb-script-action__failure-connection__title">Переход в случае ошибки:</div>
        <OutputConnection
          entity={action}
          groupId={group.id}
          id={getElementId(action.id, EntityFieldPath.OutputBindingId)}
          isValid={!scenarioValidation?.hasIssue(action.id, EntityFieldPath.OutputBindingId)}
        />
      </div>
      <ScriptEditor
        content={action.content}
        visible={editorVisible}
        onChange={onScriptEditorChange}
        onClose={onScriptEditorClose}
      />
    </ActionWrapper>
  );
};

export default memo(ScriptAction);
