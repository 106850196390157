import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { interval, throttle } from 'rxjs';

import { currentOperatorSelector } from '../../recoil';
import { InboxChatModel, InboxChatParticipantStatus } from '../../../../api';
import { EventBus, EventType, IDomainEvent } from '../../events';
import IbSound, { IIbSoundRef } from '../../components/common/IbSound';

const THROTTLE_INTERVAL = 5000;

interface IChatAlarmProps {
  chatId: string;
}

const ChatAlarm: React.FC<IChatAlarmProps> = ({ chatId }) => {
  const bus = EventBus.instance;

  const currentOperator = useRecoilValue(currentOperatorSelector);

  const soundRef = useRef<IIbSoundRef>(null);

  const notifications = currentOperator?.entity.settings.notifications;

  const isSameChat = (chat: InboxChatModel) => chat.id === chatId;

  const isAssignedChat = (chat: InboxChatModel) => {
    if (!currentOperator) {
      return false;
    }

    return chat.participants.some(
      (p) => p.id === currentOperator.entity.id && p.status === InboxChatParticipantStatus.Assigned
    );
  };

  const onChatQueued = (event: IDomainEvent<InboxChatModel>) => {
    if (isSameChat(event.entity)) {
      return;
    }

    soundRef.current?.play(notifications?.chatQueuedSound);
  };

  const onChatAssigned = (event: IDomainEvent<InboxChatModel>) => {
    if (isSameChat(event.entity)) {
      return;
    }

    if (!isAssignedChat(event.entity)) {
      return;
    }

    soundRef.current?.play(notifications?.chatAssignedSound);
  };

  const onMessageReceived = (event: IDomainEvent<InboxChatModel>) => {
    if (isSameChat(event.entity)) {
      return;
    }

    if (!isAssignedChat(event.entity)) {
      return;
    }

    soundRef.current?.play(notifications?.messageReceivedSound);
  };

  useEffect(() => {
    const subscriptions = [
      bus.onSync(
        EventType.DomainInboxChatQueued,
        onChatQueued,
        throttle(() => interval(THROTTLE_INTERVAL))
      ),
      bus.onSync(
        EventType.DomainInboxChatOperatorAssigned,
        onChatAssigned,
        throttle(() => interval(THROTTLE_INTERVAL))
      ),
      bus.onSync(
        EventType.DomainInboxChatMessageReceived,
        onMessageReceived,
        throttle(() => interval(THROTTLE_INTERVAL))
      ),
    ];
    return () => subscriptions.forEach((s) => s.unsubscribe());
  }, [
    chatId,
    notifications?.enabled,
    notifications?.chatQueuedSound,
    notifications?.chatAssignedSound,
    notifications?.messageReceivedSound,
  ]);

  return <IbSound ref={soundRef} disabled={!notifications?.enabled} />;
};

export default ChatAlarm;
