import React from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

import './index.less';

type SbSwitchProps = SwitchProps & {
  label?: string;
};

const SbSwitch: React.FC<SbSwitchProps> = ({ label, ...otherProps }) => (
  <div className="sb-switch">
    <Switch className="sb-switch__switch" {...otherProps} />
    {label && <span className="sb-switch__label">{label}</span>}
  </div>
);

export default SbSwitch;
