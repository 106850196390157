import React, { memo, useState, KeyboardEvent } from 'react';
import { useRecoilState } from 'recoil';
import cloneDeep from 'lodash/cloneDeep';

import { DefaultActionGroupSchema, SendCustomEventSchema } from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';
import { getElementId, tryGetElementById } from '../../../utils';
import SbInput from '../../../../../simple-bot/components/common/SbInput';

interface ISendCustomEventProps {
  index: number;
  action: SendCustomEventSchema;
  group: DefaultActionGroupSchema;
}

const SendCustomEvent: React.FC<ISendCustomEventProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);
  const [eventName, setEventName] = useState(action.eventName);

  const onEventNameChange = (value: string) => setEventName(value);

  const onEventNameBlur = () => {
    if (!eventName) {
      setEventName(action.eventName);
      return;
    }

    if (!scenarioStructure || eventName === action.eventName) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id) as SendCustomEventSchema;
    foundAction.eventName = eventName;

    setScenarioStructure(newScenarioStructure);
  };

  const onEventNamePressEnter = (e: KeyboardEvent<HTMLInputElement>) => e.currentTarget?.blur();

  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="people-plus" size={20} />}
      index={index}
      title="Пользовательское событие"
    >
      <SbInput
        isValid
        allowClear={false}
        id={getElementId(action.id, 'eventName')}
        value={eventName}
        onBlur={onEventNameBlur}
        onChange={onEventNameChange}
        onPressEnter={onEventNamePressEnter}
      />
    </ActionWrapper>
  );
};

export default memo(SendCustomEvent);
