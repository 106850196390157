import React, { memo } from 'react';
import { useRecoilState } from 'recoil';
import { Menu } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import {
  DefaultActionGroupSchema,
  SendSuccessfullServiceEventSchema,
  SuccessfullServiceEventName,
} from '../../../../../../api';
import ActionWrapper from '../ActionWrapper';
import SbIcon from '../../../../../simple-bot/components/common/SbIcon';
import { currentScenarioStructureSelector } from '../../../../../recoil/scenarioStructure';
import { getElementId, tryGetElementById } from '../../../utils';
import EditorSelect from '../../common/EditorSelect';

const getTitleBySuccessfullServiceEventName = (eventName: SuccessfullServiceEventName): string => {
  switch (eventName) {
    case SuccessfullServiceEventName.UserAnsweredYes:
      return 'Да';
    case SuccessfullServiceEventName.UserAnsweredNo:
      return 'Нет';
  }
};

interface ISendSuccessfullServiceEventProps {
  index: number;
  action: SendSuccessfullServiceEventSchema;
  group: DefaultActionGroupSchema;
}

const SendSuccessfullServiceEvent: React.FC<ISendSuccessfullServiceEventProps> = ({ action, index, group }) => {
  const [scenarioStructure, setScenarioStructure] = useRecoilState(currentScenarioStructureSelector);

  const onSuccessfullServiceEventNameChange = (eventName: SuccessfullServiceEventName) => () => {
    if (!scenarioStructure) return;

    const newScenarioStructure = cloneDeep(scenarioStructure);

    const foundAction = tryGetElementById(newScenarioStructure, action.id) as SendSuccessfullServiceEventSchema;
    foundAction.eventName = eventName;

    setScenarioStructure(newScenarioStructure);
  };

  const menuContent = (
    <Menu>
      <Menu.Item
        key={SuccessfullServiceEventName.UserAnsweredYes}
        onClick={onSuccessfullServiceEventNameChange(SuccessfullServiceEventName.UserAnsweredYes)}
      >
        {getTitleBySuccessfullServiceEventName(SuccessfullServiceEventName.UserAnsweredYes)}
      </Menu.Item>
      <Menu.Item
        key={SuccessfullServiceEventName.UserAnsweredNo}
        onClick={onSuccessfullServiceEventNameChange(SuccessfullServiceEventName.UserAnsweredNo)}
      >
        {getTitleBySuccessfullServiceEventName(SuccessfullServiceEventName.UserAnsweredNo)}
      </Menu.Item>
    </Menu>
  );

  return (
    <ActionWrapper
      action={action}
      group={group}
      icon={<SbIcon iconName="success" size={20} />}
      index={index}
      title="Событие успешного обслуживания"
    >
      <EditorSelect
        isValid
        id={getElementId(action.id, 'eventName')}
        menuContent={menuContent}
        title={getTitleBySuccessfullServiceEventName(action.eventName)}
      />
    </ActionWrapper>
  );
};

export default memo(SendSuccessfullServiceEvent);
