import React, { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Table } from 'antd';

import './index.less';

import { PrompterModeEventsPerDayModel } from '../../../../../api';
import { analyticsReportsApi } from '../../../../apis';
import { AlertTypes } from '../../../../constants';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { ANALYTICS_DATE_FORMATS } from '../../../const';
import { formatAnalyticsDate, formatAnalyticsDateCompact } from '../../../utils/render';
import { AnalyticsDateFilter, AnalyticsViewModes } from '../../../types';

const CHART_SETTINGS = {
  barColors: {
    botAnswerCount: '#BCBCBC',
    rejectedCount: '#D5403C',
    acceptedCount: '#53A6FF',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC<any> = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const data = payload[0].payload as PrompterModeEventsPerDayModel;
  if (data.botAnswerCount <= 0) {
    return null;
  }

  return (
    <div className="tooltip-content">
      <div>
        Дата: <b>{formatAnalyticsDate(label)}</b>
      </div>
      <div>
        Всего ответов бота: <b>{data.botAnswerCount}</b>
      </div>
      <div>
        Принято ответов: <b>{data.acceptedCount}</b>
      </div>
      <div>
        Отклонено ответов: <b>{data.rejectedCount}</b>
      </div>
    </div>
  );
};

interface ISbPrompterModeEventsAnalyticsProps {
  isAnimationActive: boolean;
  agentStageId: string;
  filter: AnalyticsDateFilter;
  viewMode: AnalyticsViewModes;
}

const SbPrompterModeEventsAnalytics: React.FC<ISbPrompterModeEventsAnalyticsProps> = ({
  isAnimationActive,
  agentStageId,
  filter,
  viewMode,
}) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);

  const [loading, setLoading] = useState(false);
  const [eventsPerDayList, setEventsPerDayList] = useState<PrompterModeEventsPerDayModel[]>([]);

  const loadDataAsync = async () => {
    setLoading(true);
    try {
      const formattedFromDate = filter.fromDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);
      const formattedToDate = filter.toDate.startOf('d').format(ANALYTICS_DATE_FORMATS.parse);

      const eventsPerDayResponse = await analyticsReportsApi.getPrompterModeEventsPerDayList(
        agentStageId,
        formattedFromDate,
        formattedToDate
      );

      setEventsPerDayList(eventsPerDayResponse.data);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке данных аналитики по событиям суфлера',
        error: e,
      });
    }
    setLoading(false);
  };
  const loadData = () => {
    loadDataAsync().finally();
  };
  useEffect(loadData, [agentStageId, filter]);

  return (
    <div className="sb-prompter-mode-events-analytics">
      <div className="sb-analytics-card__content__block">
        <div className="sb-analytics-card__content__block__title">
          <h3>События суфлера</h3>
        </div>
        <div
          className={`sb-analytics-card__content__block__content ${
            viewMode === AnalyticsViewModes.TABLE ? 'sb-analytics-card__content__block__content_with-table' : ''
          } ${loading ? 'sb-analytics-card__content__block__content_loading' : ''}`}
        >
          {viewMode == AnalyticsViewModes.TABLE ? (
            <Table
              bordered
              columns={[
                {
                  title: 'Дата',
                  dataIndex: 'date',
                  key: 'date',
                  render: (_, record) => formatAnalyticsDate(record.date),
                },
                { title: 'Всего ответов бота', dataIndex: 'botAnswerCount', key: 'botAnswerCount' },
                { title: 'Принято ответов', dataIndex: 'acceptedCount', key: 'acceptedCount' },
                { title: 'Отклонено ответов', dataIndex: 'rejectedCount', key: 'rejectedCount' },
              ]}
              dataSource={eventsPerDayList}
              pagination={false}
            />
          ) : (
            <ResponsiveContainer height="100%" width="100%">
              <BarChart data={eventsPerDayList}>
                <XAxis
                  dataKey={(e: PrompterModeEventsPerDayModel) => e.date}
                  tickFormatter={formatAnalyticsDateCompact}
                />
                <YAxis tickCount={3} />
                <Tooltip content={<TooltipContent />} cursor={false} isAnimationActive={false} />
                <Legend iconType="circle" />
                <Bar
                  dataKey={(e: PrompterModeEventsPerDayModel) => e.botAnswerCount}
                  fill={CHART_SETTINGS.barColors.botAnswerCount}
                  isAnimationActive={isAnimationActive}
                  name="Всего ответов бота"
                />
                <Bar
                  dataKey={(e: PrompterModeEventsPerDayModel) => e.acceptedCount}
                  fill={CHART_SETTINGS.barColors.acceptedCount}
                  isAnimationActive={isAnimationActive}
                  name="Принято ответов"
                />
                <Bar
                  dataKey={(e: PrompterModeEventsPerDayModel) => e.rejectedCount}
                  fill={CHART_SETTINGS.barColors.rejectedCount}
                  isAnimationActive={isAnimationActive}
                  name="Отклонено ответов"
                />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SbPrompterModeEventsAnalytics;
