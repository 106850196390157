import React from 'react';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import './index.less';

import {
  BinaryConditionOperator,
  ConditionSchema,
  OperandSchema,
  UnaryConditionOperator,
  VariableType,
} from '../../../../../../../../api';
import { currentScenarioStructureSelector } from '../../../../../../../recoil/scenarioStructure';
import {
  instanceOfBinaryConditionSchema,
  instanceOfConstantOperandSchema,
  instanceOfUnaryConditionSchema,
  instanceOfVariableOperandSchema,
} from '../../../../../utils';
import { getVariableListSelectItems, tryGetVariableType } from '../utils';

interface IConditionViewProps {
  condition: ConditionSchema;
  index: number;
}

const ConditionOperators = [
  { value: UnaryConditionOperator.Empty, label: 'Пустое:' },
  { value: UnaryConditionOperator.NotEmpty, label: 'Не пустое:' },
  { value: BinaryConditionOperator.Equals, label: '=' },
  { value: BinaryConditionOperator.NotEquals, label: '!=' },
  { value: BinaryConditionOperator.Greater, label: '>' },
  { value: BinaryConditionOperator.GreaterOrEquals, label: '>=' },
  { value: BinaryConditionOperator.Less, label: '<' },
  { value: BinaryConditionOperator.LessOrEquals, label: '<=' },
];

const ConditionView: React.FC<IConditionViewProps> = ({ condition, index }) => {
  const scenarioStructure = useRecoilValue(currentScenarioStructureSelector);
  const variableSelectItems = getVariableListSelectItems(scenarioStructure?.variables);

  const className = 'main-case-action-condition-view';

  const renderOperator = (value?: string) => {
    const operator = value ? ConditionOperators.find((o) => o.value === value) : null;
    const operatorText = operator?.label || '?';
    return <div className={`${className}__operator`}>{operatorText}</div>;
  };
  const renderConstant = (content: string) => (
    <div className={`${className}__constant`} title={content}>
      {content}
    </div>
  );
  const renderVariable = (content: string) => (
    <div className={`${className}__variable`} title={content}>
      {content}
    </div>
  );

  const renderOperand = (operand: OperandSchema) => {
    if (instanceOfConstantOperandSchema(operand)) {
      const variableType = tryGetVariableType(condition, variableSelectItems);
      if (variableType === VariableType.String) {
        const constantText = '' + operand.value || '[Пустая строка]';
        return renderConstant(constantText);
      }
      if (variableType === VariableType.Number) {
        const constantText = '' + operand.value;
        return renderConstant(constantText);
      }
      if (variableType === VariableType.Boolean) {
        const constantText = operand.value ? '[Да]' : '[Нет]';
        return renderConstant(constantText);
      }
      if (variableType === VariableType.DateTime) {
        const momentValue = operand.value ? moment('' + operand.value) : moment();
        const constantText = momentValue.format('DD.MM.YYYY');
        return renderConstant(constantText);
      }
      const constantText = '' + (operand.value ?? '[Не указано]');
      return renderConstant(constantText);
    }

    if (instanceOfVariableOperandSchema(operand)) {
      if (!operand.variableId) {
        return renderVariable('[Не указана]');
      }
      const variableSelectValue = operand.propertyPath
        ? `${operand.variableId}.${operand.propertyPath}`
        : operand.variableId;
      const variableSelectItem = variableSelectItems.find((v) => v.value === variableSelectValue);
      const variableSelectLabel = variableSelectItem?.label || '[Не указана]';
      return renderVariable(variableSelectLabel);
    }

    throw new Error(`Неизвестный тип операнда '${operand.$kind}'.`);
  };

  const classNames = [className];
  index === 0 && classNames.push(`${className}_top`);

  if (instanceOfUnaryConditionSchema(condition)) {
    return (
      <div className={classNames.join(' ')}>
        {renderOperator(condition.operator)}
        {renderOperand(condition.operand)}
      </div>
    );
  }

  if (instanceOfBinaryConditionSchema(condition)) {
    return (
      <div className={classNames.join(' ')}>
        {renderOperand(condition.leftOperand)}
        {renderOperator(condition.operator)}
        {renderOperand(condition.rightOperand)}
      </div>
    );
  }

  throw new Error(`Неизвестный тип условия '${condition.$kind}'.`);
};

export default ConditionView;
